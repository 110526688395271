var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"module-content my-entry-block more-service-section"},[_c('div',{staticClass:"module-content-title"},[_c('h3',{attrs:{"tabindex":"0"}},[_vm._v("\n      "+_vm._s(_setup.gbLanguage.SHEIN_KEY_PWA_15172)+"\n    ")]),_vm._v(" "),_c('span',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '1-25-11-34',
        data: {}
      }),expression:"{\n        id: '1-25-11-34',\n        data: {}\n      }"},{name:"enterkey",rawName:"v-enterkey"}],attrs:{"tabindex":"0","role":"button"},on:{"click":_setup.clickViewAll}},[_vm._v("\n      "+_vm._s(_setup.gbLanguage.SHEIN_KEY_PWA_15040)+"\n      "),_c(_setup.Icon,{attrs:{"name":"sui_icon_more_right_12px_2","size":"12px"}})],1)]),_vm._v(" "),_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-25-11-15',
      data: {
        orderstatus_num: _setup.orderStatusReport,
      }
    }),expression:"{\n      id: '1-25-11-15',\n      data: {\n        orderstatus_num: orderStatusReport,\n      }\n    }"}],staticClass:"me-list fsp-element"},_vm._l((_setup.showOrderEntries),function(item){return _c('div',{directives:[{name:"enterkey",rawName:"v-enterkey"}],key:item.id,class:`me-li waves-btn waves-btn-auto waves-effect waves-classic me-li-item-${item.id}`,style:({ width: _setup.showOrderEntries.length > 4 ? '20%' : '25%'}),attrs:{"role":"button","tabindex":"0","da-event-click":item.dataEventId || '',"data-param":item.iconNum,"data-text":item.title,"data-label":item.usLabel},on:{"click":() => _setup.handleMyOrdersEntry(item)}},[_c('div',[_c(_setup.iconMap[item.icon],{tag:"component",staticClass:"me-li-icon",attrs:{"size":"24px"}}),_vm._v(" "),(item.iconNum)?_c('em',{staticClass:"order-num"},[_vm._v("\n          "+_vm._s(item.iconNum > 99 ? '99+' : item.iconNum)+"\n        ")]):_vm._e()],1),_vm._v(" "),_c('p',{staticClass:"me-li__title"},[_vm._v("\n        "+_vm._s(_setup.htmlDecode({ text: item.title }))+"\n      ")])])}),0),_vm._v(" "),(_setup.hasOrderInfo)?_c(_setup.OrderPanel,{attrs:{"is-over-four":_setup.showOrderEntries?.length > 4,"order-group":_setup.myOrderService?.orderGroup || {},"order-popup":_setup.props.baseOtherInfo?.orderRetention || {}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }