/**
 *  加车弹窗数据同步(仅商详使用) 
 *  快速加车弹窗跟商详页的数据同步都在插件内处理，回调中处理埋点、业务逻辑
 */
export const syncDetailsDataPlugin = () => {
  const store = window?._gb_app_?.$store || {}
  let pluginContext = null

  // 校验是否主商品(主商品才需要数据同步)
  const checkIsMainGoods = () => {
    // 主商品
    const mainGoodsId = store.getters['productDetail/common/goods_id']
    const productInfo = pluginContext?.useModules?.()?.productInfo.value || {}
    return mainGoodsId === productInfo?.goods_id
  }

  // 待同步的快车数据
  const QuickAddCartState = new Map()
  let isUpdated = false

  // 校验是否SPU
  const checkSpu = () => {
    const productRelationId = store.getters['productDetail/common/product_relation_id']
    const productInfo = pluginContext?.useModules?.()?.productInfo.value || {}
    return productRelationId === productInfo?.productRelationID
  }

  return {
    name: 'syncDetailsData',
    register: (context) => {
      pluginContext = context
    },
    callbacks: {
      // 次级销售属性数据同步
      onSubAttrChange: (data) => {
        if (!isUpdated) {
          QuickAddCartState.set('onSubAttrChange', data)
          return
        } 
        const { value = {} } = data
        const { attrs, skuInfo, externalSizeInfoIndex } = value
        const addToBagFormReal = store.state?.newProductDetail?.common?.addToBagForm
        addToBagFormReal.attrs = attrs
        if (window.vBus) {
          window.vBus.$emit('changeWakeupData', {
            sku_code: skuInfo?.sku_code || '',
            selected_attr_value_list: attrs.map((_) => String(_.attr_value_id)) || [],
          })
        }
        store.commit('newProductDetail/common/updateSkuInfo', skuInfo, { root: true })
        store.commit('newProductDetail/common/updateAddToBagForm', addToBagFormReal, { root: true })
        store.commit('newProductDetail/common/updateExternalSizeInfoIndex', externalSizeInfoIndex, { root: true })
      },
      // 次级销售属性点击
      onSubAttrClick: ({ type = '', value = {} }) => {

        if (type === 'localChange') {
          store.commit('newProductDetail/common/updateCurrentLocalCountry', value, { root: true })
        }

        if (!checkIsMainGoods()) return 
        
        if (type === 'perfectFitShowStatus') {
          return store.commit('newProductDetail/SizeBox/updateGlobalPerfectFitShow', value, { root: true })
        }

        if (type === 'sizeRecommend') {
          const rule_type = store.getters['productDetail/SizeGuide/rule_type']
          if (rule_type === '1' ) {
            store.dispatch('productDetail/SizeBox/open_bra_size_recommend_pop', null, { root: true })
          } else {
            store.dispatch('productDetail/SizeBox/open_size_recommend_pop', null, { root: true })
          }
          return
        }
      },
      // 色块数据同步
      onMainAttrChange: (mainAttr = {}, immediateUpdate) => {
        if (!isUpdated ) {
          // spu一致 或 大小码切换spu不一致，但组件抛出标识
          if((checkSpu() || immediateUpdate)) {
            QuickAddCartState.set('onMainAttrChange', mainAttr)
          }
          return
        }
        appEventCenter.$emit('close-popover-add-bag', mainAttr)
      },
      // mallCode 数据同步
      onMallClick: (mallInfo = {}) => {
        if (!isUpdated) {
          QuickAddCartState.set('onMallClick', mallInfo)
          return
        } 
        const { mallCode } = mallInfo
        store.commit('newProductDetail/SizeBox/updateSwitchMallCode', mallCode, { root: true })
      },
      // Quantity 数据同步
      onQuantityChange: (quantity = 1) => {
        if (!isUpdated) {
          QuickAddCartState.set('onQuantityChange', quantity)
          return
        } 
        const add_to_bag_form = store.getters['productDetail/common/add_to_bag_form'] || {}
        store.commit('newProductDetail/common/updateAddToBagForm', {
          ...add_to_bag_form,
          quantity: quantity === 0 ? 1 : quantity,
        }, { root: true })
      },
      // 收藏状态同步(无须存到待更新状态，接口会请求最新收藏状态)
      onFavoriteStatusChange: ({ wishStatus }) => {
        if (!checkIsMainGoods()) return 
        const initExposeStatus = store.state.newProductDetail.common?.initExposeStatus
        store.commit('newProductDetail/common/updateSaveStatus', wishStatus, { root: true })
        store.dispatch('newProductDetail/common/updateExposeStatus', {
          addWishStatus: wishStatus && 1,
          addBagStatus: initExposeStatus?.addBagStatus || 0,
        }, { root: true })
      },
      // 点击价格
      onPriceClick: () => {
        store.dispatch('productDetail/price/quick_add_click_estimated_tag', { skcInfo: QuickAddCartState.get('onMainAttrChange') }, { root: true })
      },
      // 加车成功
      onAddCartComplete: ({ isSuccess }) => {
        if (!checkIsMainGoods() || !isSuccess) return 

        store.commit('newProductDetail/common/updateAddCompleteStatus', true, { root: true })
        store.commit('newProductDetail/common/updateAddSkcCompleteStatus', true, { root: true })

        // 加车成功后存储当前商品的主要信息
        const skuInfo = pluginContext?.useModules?.()?.skuInfo?.value || {}
        store.dispatch('newProductDetail/common/updateAddToBagInfo', { sku_code: skuInfo?.sku_code }, { root: true })

        setTimeout(() => {
          window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
        }, 2000)

        const initExposeStatus = store.state.newProductDetail.common?.initExposeStatus
        store.dispatch('newProductDetail/showOutfitContent', null, { root: true })
        store.dispatch('newProductDetail/common/updateExposeStatus', {
          addBagStatus: 1,
          addWishStatus: initExposeStatus?.addWishStatus || 0,
        }, { root: true })
      },
      // 弹窗状态
      // 这里需要用到this，所以不能用箭头函数
      onDrawerStatusChange: function (isShow) {
        store.commit('productDetail/addBag/update_quick_add_state', !!isShow, { root: true })
        // store.commit('newProductDetail/common/updateQuickAddState', !!isShow, { root: true })
        if (isShow) {
          // 重新打开重置
          QuickAddCartState.clear()
          isUpdated = false
          return
        }

        // 关闭弹窗后同步数据
        // todo: 优化同步逻辑，后续应该将快车数据直接同步到商详数据中，而不用发起请求
        isUpdated = true
        // 和商详主商品为同一个关联id 或 有进行过主销售属性选择
        if(checkSpu() || QuickAddCartState.get('onMainAttrChange')) {
          
          if (QuickAddCartState.get('onMallClick') || QuickAddCartState.get('onMainAttrChange')) {
            // 监听数据准备完成（用于同步商详数据有异步请求操作时）
            window.vBus && window.vBus.$on('allDateReady', () => {
              if (QuickAddCartState.get('onSubAttrChange')) {
                this.onSubAttrChange(QuickAddCartState.get('onSubAttrChange'))
              }
              if (QuickAddCartState.get('onQuantityChange')) {
                this.onQuantityChange(QuickAddCartState.get('onQuantityChange'))
              }
              window.vBus.$off('allDateReady')
            })
          }

          // 必须先同步mallCode，再同步主销售属性
          if (QuickAddCartState.get('onMallClick')) {
            this.onMallClick(QuickAddCartState.get('onMallClick'))
          }
          if (QuickAddCartState.get('onMainAttrChange')) {
            this.onMainAttrChange(QuickAddCartState.get('onMainAttrChange'), true)
          }
          if (QuickAddCartState.get('onSubAttrChange')) {
            this.onSubAttrChange(QuickAddCartState.get('onSubAttrChange'))
          }
          if (QuickAddCartState.get('onQuantityChange')) {
            this.onQuantityChange(QuickAddCartState.get('onQuantityChange'))
          }
        }

        // 更新 local unit
        store.dispatch('productDetail/SizeBox/update_local_unit', null, { root: true })
      },
      // 领券后
      onCouponBindFinish: (data) => {
        const { isSuccess, mallCode, productInfo } = data || {}
        if(isSuccess) {
          // 刷新商详页券列表
          store.dispatch('newProductDetail/asyncHotModles', {
            goods_id: productInfo?.goods_id,
            mallCode,
          }, { root: true })
        }
      }
    }
  }
}
