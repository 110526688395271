const toMap = (by, to, array) => array.reduce((acc, cur) => (acc[cur[by]] = cur[to], acc), {});

/**
 * 闪购
 */
export const FLASH_SALE = 'FLASH_SALE'
export const FLASH_SALE_ID = 4
export const FLASH_SALE_NAME = 'flash_sale'

/**
 * 即将售罄
 */
export const ALMOST_SOLD_OUT = 'ALMOST_SOLD_OUT'
export const ALMOST_SOLD_OUT_ID = 1
export const ALMOST_SOLD_OUT_NAME = 'oos'

/**
 * 加车后降价
 */
export const PRICE_REDUCTION = 'PRICE_REDUCTION'
export const PRICE_REDUCTION_ID = 2
export const PRICE_REDUCTION_NAME = 'mark_down'

/**
 * 购物车勾选商品折扣
 */
export const DISCOUNT = 'DISCOUNT'
export const DISCOUNT_ID = 9
export const DISCOUNT_NAME = 'discount'

/**
 * 免邮
 */
export const FREE_SHIPPING = 'FREE_SHIPPING'
export const FREE_SHIPPING_ID = 10
export const FREE_SHIPPING_NAME = 'freeshipping'

/**
 * 赠品
 */
export const GIFT = 'GIFT'
export const GIFT_ID = 18
export const GIFT_NAME = 'gift'

/**
 * 最优券
 */
export const CART_COUPON = 'CART_COUPON'
export const CART_COUPON_ID = 15
export const CART_COUPON_NAME = 'coupon'

/**
 * 下单返券
 */
export const ORDER_RETURN_COUPON = 'ORDER_RETURN_COUPON'
export const ORDER_RETURN_COUPON_ID = 19
export const ORDER_RETURN_COUPON_NAME = 'order_return_coupon'

/**
 * 利诱点列表
 */
export const INCENTIVE_MAP = [
  {
    id: FLASH_SALE_ID,
    name: FLASH_SALE_NAME,
    type: FLASH_SALE,
  },
  {
    id: ALMOST_SOLD_OUT_ID,
    name: ALMOST_SOLD_OUT_NAME,
    type: ALMOST_SOLD_OUT,
  },
  {
    id: PRICE_REDUCTION_ID,
    name: PRICE_REDUCTION_NAME,
    type: PRICE_REDUCTION,
  },
  {
    id: DISCOUNT_ID,
    name: DISCOUNT_NAME,
    type: DISCOUNT,
  },
  {
    id: FREE_SHIPPING_ID,
    name: FREE_SHIPPING_NAME,
    type: FREE_SHIPPING,
  },
  {
    id: GIFT_ID,
    name: GIFT_NAME,
    type: GIFT,
  },
  {
    id: CART_COUPON_ID,
    name: CART_COUPON_NAME,
    type: CART_COUPON,
  },
  {
    id: ORDER_RETURN_COUPON_ID,
    name: ORDER_RETURN_COUPON_NAME,
    type: ORDER_RETURN_COUPON,
  }
]

/**
 * 利诱点 TYPE -> ID 映射
 */
export const INCENTIVE_POINT_MAP = toMap('type', 'id', INCENTIVE_MAP)
/**
 * 利诱点 ID -> NAME 映射
 */
export const INCENTIVE_POINT_ID_NAME_MAP = toMap('id', 'name', INCENTIVE_MAP)
/**
 * 利诱点 ID -> TYPE 映射
 */
export const INCENTIVE_POINT_ID_TYPE_MAP = toMap('id', 'type', INCENTIVE_MAP)
/**
 * 购物车楼层接口数据缓存 KEY
 */
export const CART_FLOOR_API_DATA_CACHE_KEY = Symbol('CART_FLOOR_API_DATA_CACHE_KEY')
/**
 * 购物车楼层监听购物车数据变化状态
 */
export const CART_FLOOR_CHANGE_CART_LISTEN = Symbol('CART_FLOOR_CHANGE_CART_LISTEN')
