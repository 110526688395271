import { ref, onUnmounted, onDeactivated } from 'vue'
import { onMountedOrActivated } from './onMountedOrActivated'
import ResizeObserverManager from '../utils/ResizeObserverManager'

export function useResizeHeight({
  el,
  onHeightChange,
  immediate = true,
} = {}) {
  const height = ref(0)
  let targetElement = null

  const handleHeightChange = (newHeight) => {
    height.value = newHeight
    onHeightChange?.(newHeight)
  }

  const initObserver = () => {
    targetElement = document.querySelector(el)
    if (!targetElement) {
      console.warn(`[useResizeHeight] Element with class "${el}" not found`)
      return
    }

    const manager = ResizeObserverManager.getInstance()
    manager.addListener(targetElement, handleHeightChange)

    if (immediate) {
      handleHeightChange(targetElement.clientHeight)
    }
  }

  const cleanupObserver = () => {
    if (targetElement) {
      const manager = ResizeObserverManager.getInstance()
      manager.removeListener(targetElement, handleHeightChange)
      targetElement = null
    }
  }

  onUnmounted(() => cleanupObserver())
  onDeactivated(() => cleanupObserver())
  onMountedOrActivated(() => initObserver())

  return {
    height
  }
}
