import { replaceHrefSpecChar } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

const { langPath } = gbCommonInfo

/**
 * 更新rootState
 */
export function updateRootStateCartInfo({ carts = [], sum = 0, info = {} } = {}) {
  window._gb_app_?.$store?.commit('changeCartInfo', { carts, sum, info })
}

/**
 * 批量删除
 * @param {Array, String, Number} ids 需要删除的商品的cartId
 */
export const batchDelete = ({ ids = [], filterCheck } = {}) => {
  const batchIds = [].concat(ids)
  return schttp({
    url: '/api/cart/batchDelete/update',
    params: {
      ids: batchIds.join(',')
    },
    data: {
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then(res => {
    if ( res.code == 0 && res.info ) {
      if (filterCheck == 1 && res.info?.carts?.length) {
        // 下单页无库存弹窗删除删除商品更新购物车时，在下单页过滤掉未选中的商品
        res.info.carts = res.info.carts.filter(item => item.is_checked == 1)
      }
      updateRootStateCartInfo({ carts: res.info.carts, sum: res.info.cartSumQuantity, info: res.info })
    }
    return res
  })
}

/**
 * 批量收藏
 * @param {Array, String, Number} ids 需要收藏的商品的cartId
 */
export const batchWishGoods = ({ ids = [], filterCheck } = {}) => {
  const batchIds = [].concat(ids)
  return schttp({
    url: '/api/cart/batchWishGoods/update',
    method: 'POST',
    data: {
      ids: batchIds,
      'ids[]': batchIds, // 兼容写法 上线后可移除
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then(res => {
    if ( res.code == 0 && res.info ) {
      if (filterCheck == 1 && res.info?.carts?.length) {
        // 下单页无库存弹窗删除删除商品更新购物车时，在下单页过滤掉未选中的商品
        res.info.carts = res.info.carts.filter(item => item.is_checked == 1)
      }
      updateRootStateCartInfo({ carts: res.info.carts, sum: res.info.cartSumQuantity, info: res.info })
    }
    return res
  })
}

/**
 * 通过选品id，获取选品链接
 *
 * @export
 * @param {string} [scid='']
 * @return {Promise}
 */
export function getSelectInfoByScId(scid = '') {
  return schttp({
    url: '/api/productInfo/selectInfoByScId/get',
    params: {
      sc_id: scid
    }
  }).then(res => {
    const scInfo = res?.info?.scInfo || []
    if ( scInfo && scInfo.length ) {
      const {
        sc_url_id,
        select_name,
        select_type_name,
        sc_url
      } = res.info.scInfo[0]
      let url = ''
      if ( sc_url ) {
        url = `${ langPath }${ sc_url }`
      } else if ( sc_url_id && select_name && select_type_name ) {
        url = `${ langPath }/${ select_type_name }/${ select_name }-sc-${ sc_url_id }.html`
      }
      return replaceHrefSpecChar({ href: url })
    } else {
      return ''
    }
  })
}

// 批量查询用户信息
export function getMembersInfo(memberIds) {
  return schttp({
    url: '/api/cart/batchMemberInfo/get',
    method: 'POST',
    data: {
      memberIds,
    }
  })
}

/**
 * 根据多语言key获取多语言
 * @param {object} data
 * @param {array<string>} data.languageKeys 多语言key
 * @example getLanguage({ languageKeys: ['SHEIN_KEY_PWA_17315', 'SHEIN_KEY_PWA_17316'] })
 * @returns 
 */
export const getLanguage = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo

  return schttp({
    method: 'POST',
    url: '/system/configs/multi_language',
    data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
    },
    useBffApi: true
  })
}
