<script lang="jsx">
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { markPoint } from 'public/src/services/mark/index.js'
import ClientOnly from 'vue-client-only'

import MainPicture from 'public/src/pages/goods_detail_v2/innerComponents/top/MainPicture/index.vue'
import GoodsNameV2 from 'public/src/pages/goods_detail_v2/innerComponents/top/GoodsName/index.vue'
import PriceContainer from 'public/src/pages/goods_detail_v2/innerComponents/top/PriceContainer/index.vue'
import PriceTips from 'public/src/pages/goods_detail_v2/innerComponents/top/PriceTips/index.vue'
// import NewUserCouponFloor from 'public/src/pages/goods_detail_v2/innerComponents/top/NewUserCouponFloor/index.vue'
import PlaceholderGoodsInfo from 'public/src/pages/goods_detail_v2/innerComponents/top/PlaceholderGoodsInfo/index.vue'
import MainSaleAttr from 'public/src/pages/goods_detail_v2/innerComponents/top/MainSaleAttr/index.vue'

const TopOther = () => import(/* webpackChunkName: "detail_top_other" */ 'public/src/pages/goods_detail_v2/innerComponents/top/TopOther/index.vue')

const NewSerialProducts = () =>
  import(/* webpackChunkName: "NewSerialProducts" */ 'public/src/pages/goods_detail_v2/innerComponents/top/SerialProducts/index.vue')

const MultiPropertyProducts = () =>
  import(/* webpackChunkName: "MultiPropertyProducts" */ 'public/src/pages/goods_detail_v2/innerComponents/top/MultiPropertyProducts/index.vue')
  
const MultiPropertyUnderSize = () =>
  import(/* webpackChunkName: "MultiPropertyUnderSize" */ 'public/src/pages/goods_detail_v2/innerComponents/top/MultiPropertyProducts/underSizeIndex.vue')

const ExtraVoucherFloor = () =>
  import(/* webpackChunkName: "ExtraVoucherFloor" */ 'public/src/pages/goods_detail_v2/innerComponents/top/ExtraVoucherFloor/index.vue')

const SheinClubPromotionFloor = () =>
  import(
    /* webpackChunkName: "SheinClubPromotionFloor" */ 'public/src/pages/goods_detail_v2/innerComponents/top/SheinClubPromotionFloor/index.vue'
  )


const ProductItemRankingListNew = () =>
  import(
    /* webpackChunkName: "ProductItemRankingListNew" */ 'public/src/pages/goods_detail_v2/innerComponents/top/ProductItemRankingList/index.vue'
  )

const DesignInfoEnter = () =>
  import(/* webpackChunkName: "DesignInfoEnter" */ 'public/src/pages/goods_detail_v2/innerComponents/top/DesignInfoEnter/index.vue')

const GeeneeArBox = () =>
  import(
    /* webpackChunkName: "GeeneeArBox" */ 'public/src/pages/geenee_ar_box/container.vue'
  )


const QuickShipFloor = () =>
  import(
    /* webpackChunkName: "QuickShipFloor" */ 'public/src/pages/goods_detail_v2/innerComponents/top/QuickShipFloor/index.vue'
  )

const TopBelt = () =>
  import(
    /* webpackChunkName: "TopBelt" */ 'public/src/pages/goods_detail_v2/innerComponents/top/Belt/index.vue'
  )

const QuickShipDrawer = () =>
  import(
    /* webpackChunkName: "QuickShipDrawer" */ 'public/src/pages/goods_detail_v2/innerComponents/top/QuickShipFloor/QuickShipDrawer.vue'
  )
  
const PromotionEnterNew = () => import(/* webpackChunkName: "PromotionEnterNew" */ 'public/src/pages/goods_detail_v2/innerComponents/top/PromotionEnter/index.vue')
const PromotionDrawerNew = () => import(/* webpackChunkName: "PromotionDrawerNew" */ 'public/src/pages/goods_detail_v2/innerComponents/drawer/PromotionDrawer/index.vue')

daEventCenter.addSubscriber({ modulecode: '1-6-1' })

export default {
  name: 'TopContent',
  components: {
    GeeneeArBox,
    GoodsNameV2,
    PriceContainer,
    PriceTips,
    PlaceholderGoodsInfo,
    TopOther,
    ClientOnly,
    PromotionEnterNew,
  },
  props: {
    isClientRender: {
      type: Boolean,
      default: false
    },
    topContentReady: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // goodsName 相关
      isTitleEllipsis: true,
      sharePanel: false,
      sharePopupReady: false,
      goodsLink: '',
      isGetAgain: false, // 是否再次领取
      isTouchMovedSellpoint: false,
      quickshipDrawerVisible: false
    }
  },
  computed: {
    ...mapGetters('productDetail/promotion', ['new_user_coupon_info_floor', 'shein_club_promotion_floor_info', 'is_flash']),
    ...mapGetters('productDetail/common', [
      'ranking_list_info', 
      'language_v2', 
      'cat_id', 
      'goods_id',
      'sku_code',
    ]),
    ...mapGetters('productDetail/MainSaleAttr', [
      'skc_prepose_val',
      'is_show_Main_Attr',
    ]),
    ...mapGetters('productDetail/RankingInfo', [
      'show_ranking_info_link',
      'rank_new_style',
      'ranking_list_icon',
      'has_ranking_link',
      'get_ranking_link',
      'ranking_analysis_data',
    ]),
    
    ...mapState('newProductDetail/common', ['goodsReady', 'realTimeFirstReady', 'realTimeReady', 'fixedRatio', 'isLoginChange']), // 商详公共状态
    ...mapState('newProductDetail/PromotionEnter', [
      'viewPromotionStatus' // 状态 (之后处理，涉及多个文件)
    ]),
    ...mapState('productDetail/Middle', ['protect_rank_id']),
    ...mapState('productDetail/common', ['show_try_on_ar_content']),
    ...mapGetters('newProductDetail/ShippingEnter', [
      'independfloorInfo', // 物流数据，问 seb
    ]),
    ...mapGetters('newProductDetail/Top', [
      // 'abtPriceLocatTop',
      // 'isShowDesignerInfo',
      'lowestPriceTipsConfig', // tsp 相关
      // 'designerInfo'
    ]),
    ...mapGetters('newProductDetail/common', [
      'showPlaceholderGoodsInfo',
      'newUserCouponInfo',
    ]),
    ...mapGetters('newProductDetail/Price', ['estimatedInfo']), // abt 相关，修改后可直接迁移
    ...mapGetters('productDetail/SerialProducts', ['show_serial_products']),
    ...mapGetters('productDetail/Recommend', ['under_photo_multi_property_products', 'under_size_multi_property_products', 'has_multi_property_products']),
    ...mapGetters('productDetail/promotionEnter', [
      'show_promotion_enter',
    ]),
    ...mapGetters('productDetail/promotion', ['extra_voucher_promotion_info']),
    ...mapGetters('productDetail/Reviews', [
      'comment_overview',
    ]),
    ...mapGetters('productDetail/Description', ['trends_info']),
    ...mapGetters('productDetail/DetailSignBoard', ['is_brand_has_target_tag', 'is_store_business_brand']),

    ...mapGetters('productDetail/DesignInfoEnter', ['is_show_designer_info', 'designer_info', 'designer_title']),
  },
  watch: {
    goodsReady: {
      handler(val) {
        if (typeof window !== 'undefined') {
          if (val) {
            //切换商品时也需要重新获取优惠卷数据
            this.isTitleEllipsis = true
            this.isTouchMovedSellpoint = false
            this.getRadioLoaded()
            this.quickshipDrawerVisible = false
          }
        }
      },
      immediate: true,
    },
    realTimeReady: {
      handler(val) {
        if (val) {
          // 通知 header 商详信息
          this.setGoodsDetailInfoForRoot({
            goodsId: this.goods_id,
            catId: this.cat_id || '',
          })
        }
      },
      immediate: true,
    },
    'lowestPriceTipsConfig.hitLowestPrice': {
      handler(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          daEventCenter.triggerNotice({
            daId: '1-6-1-172',
            extraData: { 
              priceday: newVal,
            },
          })
        }
      },
      immediate: true
    },
    isLoginChange(val) {
      if (val) {
        // todo: 更新旧 vuex 接入 hot 可删除
        if (!(typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD)) {
          this.updateEstimatedCouponList({ isOldCouponList: !this.estimatedInfo?.isEstimatedMatch })
        }
        if (this.isGetAgain) {
          // this.getNewUserCoupon()
          this.isGetAgain = false
        }
      }
    },
    has_multi_property_products(val){
      if(val){
        daEventCenter.triggerNotice({
          daId: '1-6-1-256'
        })
      }
    }
  },
  mounted() {
    window.vBus && window.vBus.$on('addToBagSuccess', this.addToBagSuccess)
    // 色块加载完成后更新top内容加载状态
    this.getRadioLoaded()
    appEventCenter.$on('close-popover-add-bag', this.switchColor)
  },
  beforeDestroy() {
    window.vBus && window.vBus.$off('addToBagSuccess', this.addToBagSuccess)
    appEventCenter.$off('close-popover-add-bag', this.switchColor)
  },
  methods: {
    ...mapMutations(['setGoodsDetailInfoForRoot']),
    ...mapMutations('newProductDetail/common', ['updateColorsReady', 'updateIsLoginChange']), // 好改
    ...mapMutations('newProductDetail/PromotionEnter', [
      'updateViewPromotionStatus',
    ]),
    ...mapActions('newProductDetail', ['showLoginModal', 'updateEstimatedCouponList']),

    // 新数据流
    ...mapActions('productDetail/common', ['switch_color']),
    ...mapMutations('productDetail/common', ['update_show_try_on_ar_content']),
    ...mapMutations('productDetail/Middle', ['update_pv_clicked_rank_id']),
    // --end
    loginHandle() {
      if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD) {
        this.showLoginModal({
          params: {
            from: 'other',
          }
        })
        return
      }
      SHEIN_LOGIN.show({
        show: true,
        from: 'other',
        cb: () => {
          this.updateIsLoginChange(true)
        }
      })
    },
    // getNewUserCoupon() {
    //   // 点击领取新人券
    //   daEventCenter.triggerNotice({
    //     daId: '1-6-1-159',
    //     extraData: {
    //       operation_results: 1
    //     }
    //   })
    //   const { newUserCoupon } = this.newUserCouponInfo
    //   const isReceived = newUserCoupon?.bind_status === 1
    //   const isBindCoupon = newUserCoupon?.is_bind === 1
    //   if (isBindCoupon && !isReceived) {
    //     if (!isLogin()) {
    //       // 判断是否登录
    //       this.isGetAgain = true
    //       this.loginHandle()
    //       return
    //     }
    //     this.updateViewPromotionStatus({ status: true, viewPromotionAnchoringNewUserCoupon: newUserCoupon?.coupon_code  })
    //     schttp({
    //       method: 'POST',
    //       url: '/api/productInfo/bindCoupon/post',
    //       data: {
    //         coupon_codes: [newUserCoupon?.coupon_code]
    //       }
    //     }).then(res => {
    //       this.updateEstimatedCouponList() // 更新到手价相关数据
    //       if (!res) return
    //       if (res.code == '0') {
    //         const success = res.info?.successList?.length
    //         if (res.info?.failureList?.length) { // 绑定失败
    //           const errorCode = res.info.failureList[0].code
    //           this.$toast({ content: this.language_v2[errorCode] })
    //         }
    //         if (success) { // 绑定成功
    //           this.$toast(this.language_v2.SHEIN_KEY_PWA_20962)
    //         }
    //         daEventCenter.triggerNotice({
    //           daId: '1-6-4-42',
    //           extraData: {
    //             coupon_id: newUserCoupon?.coupon_code,
    //             status: success ? 1 : 0,
    //             abtest: abtUserAnalysis({ posKeys: 'EstimatedPrice' })?.sa
    //           }
    //         })
    //       }
    //     })
    //   } else {
    //     this.updateViewPromotionStatus({ status: true, viewPromotionAnchoringNewUserCoupon: newUserCoupon?.coupon_code })
    //   }
    // },
    switchColor (item) {
      // TODO: 如果是新中间层融合走新的action
      if (item) {
        this.switch_color({
          ...item,
          jumpCallBack: (url) => {
            this.$router.replace(url)
          }
        })
        return
      }
      // --end
    },
    /** Top */
    addToBagSuccess() {
      const activeElem = document.activeElement
      const elem = document.querySelector('#add-to-bag-success') || document.createElement('div')
      elem.setAttribute('id', 'add-to-bag-success')
      elem.setAttribute('aria-label', this.language_v2?.SHEIN_KEY_PWA_15021)
      elem.setAttribute('tabindex', '0')
      elem.setAttribute('type', 'text')
      elem.setAttribute('role', 'text')
      elem.style.cssText = 'position: absolute;visibility: hidden;'
      try {
        document.body.append(elem)
      } catch (e) {
        document.body.appendChild(elem)
      }
      setTimeout(() => {
        elem.style.top = document.scrollingElement.scrollTop + 100 + 'px'
        elem.focus()
        setTimeout(() => {
          activeElem.focus()
          setTimeout(() => {
            try {
              document.body.removeChild(elem)
            } catch (e) {
              console.log()
            }
          }, 2000)
        }, 1000)
      }, 3000)
    },
    /** Top 结束 */
    toogleGoodsNameEllipsis() {
      this.isTitleEllipsis = !this.isTitleEllipsis
    },
    touchMoveSellpoint() {
      // 商品标签sellpoint滑动上报
      if(!this.isTouchMovedSellpoint){
        this.isTouchMovedSellpoint = true
        daEventCenter.triggerNotice({
          daId: '1-6-1-226',
        })
      }
    },
    closeSharePopup() {
      this.sharePanel = false
    },
    /** goodsName 结束 */

    openExtraVoucherFloorPromotion() {
      this.updateViewPromotionStatus({ status: true, viewPromotionAnchoringExtraVoucher: true })
      daEventCenter.triggerNotice({
        daId: '1-6-4-94',
        extraData: {
          location: 1
        }
      })
    },
    /** promotionEnter 结束 */

    /** 加载完成后更新colorReady */
    getRadioLoaded() {
      try {
        const radislist = document.querySelectorAll('.S-radio-color__inner') || []
        if (!radislist.length) {
          this.updateColorsReady(true)
          return
        }
        const imgs = []
        radislist.forEach((item) => {
          const imgBgSrc = item.getAttribute('style')
          imgBgSrc.replace(/url\(\"?\/\/(.+?)\"?\)/, (_, p1) => {
            imgs.push(`//${p1}`)
          })
        })

        if (!imgs.length) {
          this.updateColorsReady(true)
          return
        }

        const len = imgs.length
        let count = 0
        new Promise((resolve) => {
          imgs.forEach((item) => {
            const img = new Image()
            img.src = item
            const add = () => {
              count++
              if (count === len) {
                resolve()
              }
            }

            img.addEventListener('load', () => {
              add()
            })

            img.addEventListener('error', () => {
              add()
            })
          })
        }).then(() => {
          this.updateColorsReady(true)
        })
      } catch (err) {
        this.updateColorsReady(true)
        console.log(err)
      }
    },
    goToDesignerPage() {
      if (!this.designer_info.sheinxDesignId) return
      const { langPath } = gbCommonInfo
      daEventCenter.triggerNotice({ daId: '1-6-4-70' })
      const href = `${langPath}/designer/${this.designer_info.sheinxDesignId}`
      markPoint('toNextPageClick', 'public')
      this.$router.push(href)
    },
    clickRankingList() {
      daEventCenter.triggerNotice({
        daId: '1-6-1-119',
        extraData: this.ranking_analysis_data,
      })

      // 强制获取最新的链接，tab_page_id有可能有变化
      const newLink = this.get_ranking_link
      if (newLink) {
        const currRankId = this.ranking_list_info.contentCarrierId
        console.log('==>protect_rank_id', this.protect_rank_id)
        console.log('==>this.ranking_list_info.contentCarrierId', currRankId)
        // 即将跳转至榜单，此时需要增加skc纬度的榜单过滤记录. 若已提前曝光过，则不再记录过滤。
        if(!this.protect_rank_id.includes(currRankId)){
          this.update_pv_clicked_rank_id(currRankId)
        }
        if(this.$router){
          this.$router.push(newLink)
        }else{
          location.href = newLink
        }
      }
    },
    clickQuickShipFloor() {
      this.quickshipDrawerVisible = true
      daEventCenter.triggerNotice({
        daId: '1-6-1-236',
        extraData: {
          keyinformation: this.independfloorInfo.keyinformation
        }
      })
    }
  },
  render() {
    const {
      // common
      showPlaceholderGoodsInfo,
      goodsReady,
      // abtPriceLocatTop,
      show_serial_products,
      under_photo_multi_property_products,
      under_size_multi_property_products,
      // goodsName
      // isShowDesignerInfo,
      // designerInfo,
      // promotionEnter
      viewPromotionStatus,
      independfloorInfo,
      isClientRender,
      sku_code,
      is_flash,
      topContentReady, // TODO 新数据流
      extra_voucher_promotion_info,
      is_show_designer_info,
      designer_info,
      designer_title,
      language_v2,
      goods_id,
      cat_id
    } = this
    const getRankingAnalysis = {
      directives: [
        {
          name: 'expose',
          value: {
            id: '1-6-1-118',
            data: this.ranking_analysis_data
          }
        }
      ]
    }

    const renderMainSaleAttrDefault = () => {
      let isSupportCropImage = false
      if (typeof gbCommonInfo !== 'undefined') {
        isSupportCropImage = gbCommonInfo?.RESOURCE_SDK?.isSupportCropImage
      }
      return this.is_show_Main_Attr ? <MainSaleAttr isSupportCropImage={isSupportCropImage} />  : null
    }

    const renderMainSaleAttrPreposeAOrC = () => {
      const val = ['A', 'C']
      return val.includes(this.skc_prepose_val) ? <MainSaleAttr /> : null
    }

    const renderGoodsName = () => {
      return <GoodsNameV2 />
    }
    const renderPromotionEnter = () => {
      const { show_promotion_enter } = this
      if (!show_promotion_enter) return
      return <PromotionEnterNew />
    }

    const renderPromotionDrawer = () => {
      if (!viewPromotionStatus) return null
      return <PromotionDrawerNew class="bff-new"
        show={viewPromotionStatus}
        language={language_v2}
        coupon-language={language_v2}
      />
    }

    // 商品主图
    const renderMainPicture = () => {
      return <MainPicture topContentReady={topContentReady}/>
    }

    const renderBelt = () => {
      if (!this.topContentReady) return null
      return <TopBelt />
    }

    return (
      <div
        class="goods-detail-top"
        data-floor-tab="goods"
      >
        <div class="goods-detail-top__CarouselsBeltBox">
          
          {/* 商品主图入口 */}
          { isClientRender ?  null : renderMainPicture()}

          { renderBelt() }
          { !showPlaceholderGoodsInfo && renderMainSaleAttrPreposeAOrC() }
          {is_flash ? (<PriceContainer />) : null}

          {(!isClientRender && this?.show_try_on_ar_content) ?
            <div class="goods-detail-top__arContent">
              <GeeneeArBox
                skuCode={sku_code}
                vOn:close={() => {
                  this.update_show_try_on_ar_content(false)
                }}
                vOn:careBearsStarted={() => {
                  daEventCenter.triggerNotice({ daId: '1-6-1-165' })
                }}
                vOn:careBearsFinished={() => {
                  daEventCenter.triggerNotice({ daId: '1-6-1-166' })
                }}
              />
            </div>
            : null}
        </div>
        <div class={[
          'goods-detail-top__info-container',
          { 'pdt-0': is_flash }
        ]}>
          {(!isClientRender) && showPlaceholderGoodsInfo ? (
            <PlaceholderGoodsInfo />
          ) : (
            <div>
              { under_photo_multi_property_products && <MultiPropertyProducts /> }
              {/* 套装数据全部来自client storage 完全放置前端渲染就可以 */}
              {
                <ClientOnly>{goodsReady && show_serial_products && <NewSerialProducts />}</ClientOnly>
              }

              {!is_flash ? <PriceContainer /> : null}
              <PriceTips />

              {/* 促销入口 */}
              {renderPromotionEnter()}
              {/* {this.new_user_coupon_info_floor ? <NewUserCouponFloor new-user-coupon-info-floor={this.new_user_coupon_info_floor} vOn:clickNewUserCouponFloor={this.getNewUserCoupon}/> : null} */}


              <ClientOnly>
                {extra_voucher_promotion_info && extra_voucher_promotion_info.isExtraVoucherFloorType ? (
                  <ExtraVoucherFloor
                    extraVoucherPromotionInfo={extra_voucher_promotion_info}
                    nativeOnClick={this.openExtraVoucherFloorPromotion}
                  />
                ) : null}
              </ClientOnly>
              {/* 付费会员 */}
              <ClientOnly>
                {!!this.shein_club_promotion_floor_info ? <SheinClubPromotionFloor shein-club-promotion-floor-info={this.shein_club_promotion_floor_info} /> : null}
              </ClientOnly>
              { renderGoodsName() }
              <ClientOnly>
                {
                  independfloorInfo.show && <QuickShipFloor language={language_v2} independfloorInfo={independfloorInfo} v-on:clickFloor={ this.clickQuickShipFloor }/>
                }
              </ClientOnly>
              <ClientOnly>
                {
                  independfloorInfo.show && this.quickshipDrawerVisible &&
                    <QuickShipDrawer 
                      independfloorInfo={independfloorInfo}
                      detail={{ goods_id, cat_id }}
                      language={language_v2}
                      v-on:updateVisible={(val) => { this.quickshipDrawerVisible = val }}
                    />
                }
              </ClientOnly>
              {/* {isShowDesignerInfo && (
                <DesignInfoEnter
                  designer-info={designerInfo}
                  position-style="underName"
                  language={language}
                  vOn:goToDesignerPage={this.goToDesignerPage}
                />
              )} */}
              {is_show_designer_info && (
                <DesignInfoEnter
                  designer-info={designer_info}
                  position-style="underName"
                  title={designer_title}
                  vOn:goToDesignerPage={this.goToDesignerPage}
                />
              )}
              {/* <!-- 榜单 --> */}
              {
                this.ranking_list_info && this.show_ranking_info_link && (
                  <div {...getRankingAnalysis}>
                    <ProductItemRankingListNew
                      rank-new-style={this.rank_new_style}
                      ranking-list={this.ranking_list_info}
                      icon-link={this.ranking_list_icon}
                      link={this.has_ranking_link}
                      language={language_v2}
                      vOn:clickLink={this.clickRankingList}
                    />
                  </div>)
              }
              {/* <!-- 色块组件(jsx) --> */}
              { renderMainSaleAttrDefault() }
            

              <ClientOnly>
                <TopOther />
              </ClientOnly>
              {
                under_size_multi_property_products && <MultiPropertyUnderSize />
              }
            </div>
          )}
        </div>

        {/* <!-- todo后面改造到goods_detail 页面级去，用全局state控制， 不要放在top里 --> */}
        <ClientOnly>
          {renderPromotionDrawer()}
        </ClientOnly>

        <div class="goods-detail__recommend-drawer"></div>
      </div>
    )
  },
}
</script>

<style lang="less">
.goods-detail-top {
  padding: 0 0.32rem;
  background-color: #fff;

  .goods-detail-top__info-container {
    // padding-top: 0.2133rem;
    margin-top: 0.2703rem;
  }

  .goods-detail-top__CarouselsBeltBox {
    position: relative;
    background-color: #fff;
  }

  .goods-detail-top__arContent {
    z-index: @zindex-modal-mask;
    position: absolute;
    top: 0;
    left: -0.32rem;
    width: calc(100% + 0.64rem);
    height: 100%;
  }

  .pdt-0 {
    padding-top: 0;
  }
}
</style>
