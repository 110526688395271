import { template } from '@shein/common-function'

function isPlainObject(value) {
  if (typeof value !== 'object' || value === null) return false
  const prototype = Object.getPrototypeOf(value)
  return prototype === null || prototype === Object.prototype
}

function isDOMNode(obj) {
  return obj && typeof obj === 'object' && typeof obj.nodeType === 'number' && typeof obj.nodeName === 'string'
}

function deepClone(target, ...sources) {
  if (!isPlainObject(target) && !Array.isArray(target)) {
    target = {}
  }

  for (const source of sources) {
    if (isPlainObject(source) || Array.isArray(source)) {
      Object.keys(source).forEach(key => {
        if (isDOMNode(source[key])) {
          target[key] = source[key]
        } else if (isPlainObject(source[key]) || Array.isArray(source[key])) {
          target[key] = deepClone(target[key] || (Array.isArray(source[key]) ? [] : {}), source[key])
        } else {
          target[key] = source[key]
        }
      })
    }
  }

  return target
}

export function mergeOptions(...options) {
  return deepClone({}, ...options)
}

export const salePriceWordSize = ({
  priceStr: priceStr = '',
  priceShowStyle: priceShowStyle = '',
  mainSize: mainSize = 20,
  otherSize: otherSize = 11,
}) => {
  if (!priceStr) return ''
  const coverStringWithSpan = (str, size) => {
    return `<span style="font-size: ${size}px">${str}</span>`
  }
  if (priceShowStyle) {
    const tempStr = priceStr.replace(priceShowStyle, coverStringWithSpan(priceShowStyle, mainSize))
    return `<span style="font-size: ${otherSize}px">${tempStr}</span>`
  }
  // 同时有 , 和 .
  const hasCommaAndPeriod = priceStr.includes(',') && priceStr.includes('.')
  const price = priceStr.replace(/[^0-9.,]/ig, '')
  let integer
  if (hasCommaAndPeriod) {
    integer = price.split('.')[0]
  } else {
    integer = parseInt(price)
  }
  const tempMark = '##'
  const replaceStr = priceStr.replace(String(integer), tempMark)
  const coverSmallSize = coverStringWithSpan(replaceStr, otherSize)
  const coverBigSize = coverStringWithSpan(integer, mainSize)
  return coverSmallSize.replace(tempMark, coverBigSize)
}


export enum ESTIMATED_STYLE_TYPES {
  OLD, // 到手价胶囊样式
  OLD_NO_THRESHOLD, // 凑单到手价胶囊
  OLD_N_ESTIMATED, // N 件到手价
  NEW, // 到手价折扣百分比样式
  NO_THRESHOLD_FLOOR, // 凑单到手价吸底楼层样式
  NEW_AND_NO_THRESHOLD_FLOOR, // 主价格楼层到手价百分比样式合凑单到手价吸底楼层样式同时
}

export const ESTIMATED_DATA_TYPES = {
  SATISFY: 1, // 满足门槛到手价
  NO_SATISFY: 2, // 未满足门槛到手价
  AB: 3, // AB 价
  AB_OVERLAP_SATISFY: 4, // AB 价叠加满足门槛到手价
  AB_OVERLAP_NO_SATISFY: 5, // AB 价叠加未满足门槛到手价
  N_ESTIMATED: 6, // N 件到手价
}

export const getEstimatedInfo = (arg) => {
  const {
    skcPriceInfo: skcPriceInfo = null,
    skuPriceInfo: skuPriceInfo = null,
    isSoldOut: isSoldOut = false,
    abts: abts = {},

    otherInfo: otherInfo = {
      exclusiveInfo: null,
      sheinClubPromotionInfo: null,
      isPaidUser: false,
      complianceMode: false,
      complianceModeDe: false,
      isQuickAdd: true,
      isFlash: false,
      isNormalFlash: false, // isNormalFlash isSpecialFlash isBrandFlash 三个同时生效一个
      isSpecialFlash: false,
      isBrandFlash: false,
      language: {},
      hasLink: false, // 快速加车用回调，商详固定true
      goodsSn: '',
      GB_cssRight: false,
    },
  } = arg
  if (isSoldOut) return null
  const { EstimatedShowType, EstimatedNothreShowType, middleast, EstimatedPrice, AllAreaPcsPrice } = abts || {}
  // 到手价生效ABT调整 (?pageId=1493558035)
  if (EstimatedPrice?.p?.DetailEstimatedPrice === 'unshow') return null
  if (!!otherInfo.exclusiveInfo && !otherInfo.exclusiveInfo.isS3newpriceAbtNew) return null
  let price = skuPriceInfo || skcPriceInfo
  if (otherInfo.exclusiveInfo) price = skcPriceInfo || {}
  const { estimatedPriceInfo, npcsEstimatedPriceInfo, estimatedPriceCalculateProcess, retailPrice } = price
  const hasUnitPrice = !!(estimatedPriceInfo?.unitCouponPrice || price.unitSalePrice) && ['showtype1', 'showtype2'].includes(AllAreaPcsPrice?.p?.DetailAreaPcsPrice)
  const { estimatedPrice, convertCouponPromotionInfo, couponDiscount, estimatedPriceOverlayAbTestPrm, needPrice } = estimatedPriceInfo || {}
  const { isShowAddMoreEntrance } = estimatedPriceCalculateProcess || {}
  const { language, complianceMode, complianceModeDe, GB_cssRight } = otherInfo
  let countdownEndTime = skcPriceInfo?.estimatedPriceCalculateProcess?.countdownEndTime || ''
  if (complianceMode || complianceModeDe) countdownEndTime = ''

  const assignOtherInfo = (result) => {
    return {
      couponCode: estimatedPriceCalculateProcess?.multiCouponInfos?.[0]?.couponCode,
      ...result,
      otherInfo,
      abts,
    }
  }

  /**
   * N 件到手价
   * 有就取，不看estimatedPriceInfo
   * 不用判断库存，不用判断段端配，不用判断活动限购
   * singleEstimatedPrice 单件价
   * purchasePcs 件数
  */
  if (npcsEstimatedPriceInfo?.singleEstimatedPrice?.amountWithSymbol && !hasUnitPrice) {
    return assignOtherInfo({
      value: npcsEstimatedPriceInfo.singleEstimatedPrice,
      purchasePcs: npcsEstimatedPriceInfo.purchasePcs,
      styleType: ESTIMATED_STYLE_TYPES.OLD_N_ESTIMATED,
      dataType: ESTIMATED_DATA_TYPES.N_ESTIMATED,
      buyMultipleText: {
        left: template(npcsEstimatedPriceInfo.singleEstimatedPrice.amountWithSymbol, language.SHEIN_KEY_PWA_31767 || '{0}/Pc'),
        right: template(
          `<span style="font-weight: 700">${template(npcsEstimatedPriceInfo.purchasePcs, language.SHEIN_KEY_PWA_31915 || '{0}Prc')}</span>`,
          language.SHEIN_KEY_PWA_31914 || 'With {0} purchase',
        ),
      },
    })
  }
  if (!estimatedPriceInfo?.estimatedPrice?.amountWithSymbol) return null
  const discountPercent = GB_cssRight ? `%${couponDiscount}-` : `-${couponDiscount}%`
  const discountPercentNoMinusSign = GB_cssRight ? `%${couponDiscount}` : `${couponDiscount}%`
  /**
   * AB 价
   * convertCouponPromotionInfo 判断是 ab 价
   * estimatedPrice,couponDiscount
   *
   * 叠加满足门槛不关注
   *
   * 叠加未满足门槛 - 看estimatedPriceOverlayAbTestPrm.estimatedPriceType == 1
   * 底部未满足门槛的读 estimatedPriceOverlayAbTestPrm 里的 needPrice,estimatedPrice
   *
  */
  if (convertCouponPromotionInfo?.typeId == 32) {
    let noThresholdFloor
    let dataType = ESTIMATED_DATA_TYPES.AB
    if (
      estimatedPriceOverlayAbTestPrm?.estimatedPrice?.amountWithSymbol &&
      estimatedPriceOverlayAbTestPrm?.estimatedPriceType == 1
    ) {
      if (['New1', 'New2'].includes(EstimatedNothreShowType?.p?.EstimatedNothreShowType) && !hasUnitPrice) {
        noThresholdFloor = {
          value: estimatedPriceOverlayAbTestPrm?.estimatedPrice,
          needPrice: estimatedPriceOverlayAbTestPrm?.needPrice,
          discountPercent: discountPercentNoMinusSign,
          thresholdPrice: estimatedPriceOverlayAbTestPrm?.thresholdPrice,
          endTime: countdownEndTime || null,
          isShowAddMore: isShowAddMoreEntrance || false,
          EstimatedNothreShowType: EstimatedNothreShowType?.p?.EstimatedNothreShowType,
        }
      }
      dataType = ESTIMATED_DATA_TYPES.AB_OVERLAP_NO_SATISFY
    } else if (estimatedPriceOverlayAbTestPrm?.estimatedPriceType == 2) {
      dataType = ESTIMATED_DATA_TYPES.AB_OVERLAP_SATISFY
    }
    return assignOtherInfo({
      value: estimatedPrice,
      text: middleast?.p?.middleast == 'new' ? (language.SHEIN_KEY_PWA_33261 || 'with special coupon') : language.SHEIN_KEY_PWA_26364,
      discountPercent,
      styleType: noThresholdFloor ? ESTIMATED_STYLE_TYPES.NEW_AND_NO_THRESHOLD_FLOOR : ESTIMATED_STYLE_TYPES.NEW,
      dataType,
      noThresholdFloor,
      endTime: countdownEndTime || null,
      retailPrice,
      retailPriceStyle: {
        color: otherInfo.isBrandFlash && !otherInfo.isQuickAdd ? 'rgba(255, 255, 255, 0.8)' : '',
      },
      couponCode: estimatedPriceOverlayAbTestPrm?.couponCode,
    })
  }
  const isEstimatedShowTypeNew = EstimatedShowType?.p?.EstimatedShowType === 'New'
  if (estimatedPriceInfo.isSatisfied) {
    let text = middleast?.p?.middleast == 'new' ? language.SHEIN_KEY_PWA_32736 : language.SHEIN_KEY_PWA_28408
    if (complianceMode || complianceModeDe) text = language.SHEIN_KEY_PWA_30800
    if (isEstimatedShowTypeNew) text = language.SHEIN_KEY_PWA_26364
    return assignOtherInfo({
      value: estimatedPrice,
      discountPercent,
      endTime: countdownEndTime || null,
      styleType: isEstimatedShowTypeNew ? ESTIMATED_STYLE_TYPES.NEW : ESTIMATED_STYLE_TYPES.OLD,
      retailPrice,
      retailPriceStyle: {
        color: otherInfo.isBrandFlash && !otherInfo.isQuickAdd? 'rgba(255, 255, 255, 0.8)' : '',
      },
      dataType: ESTIMATED_DATA_TYPES.SATISFY,
      text,
    })
  } else if (!hasUnitPrice) {
    if (['New1', 'New2'].includes(EstimatedNothreShowType?.p?.EstimatedNothreShowType)) {
      return assignOtherInfo({
        value: estimatedPrice,
        needPrice: estimatedPriceOverlayAbTestPrm?.needPrice,
        endTime: countdownEndTime || null,
        dataType: ESTIMATED_DATA_TYPES.NO_SATISFY,
        styleType: ESTIMATED_STYLE_TYPES.NO_THRESHOLD_FLOOR,
        noThresholdFloor: {
          value: estimatedPrice,
          needPrice: needPrice,
          discountPercent: discountPercentNoMinusSign,
          thresholdPrice: estimatedPriceOverlayAbTestPrm?.thresholdPrice,
          endTime: countdownEndTime || null,
          isShowAddMore: isShowAddMoreEntrance || false,
          EstimatedNothreShowType: EstimatedNothreShowType?.p?.EstimatedNothreShowType,
        },
      })
    }
    const text = estimatedPriceInfo?.estimatedPriceMultiLang
    return assignOtherInfo({
      value: estimatedPrice,
      needPrice: couponDiscount,
      text, // 中间层已处理直接使用
      endTime: countdownEndTime || null,
      styleType: ESTIMATED_STYLE_TYPES.OLD_NO_THRESHOLD,
      dataType: ESTIMATED_DATA_TYPES.NO_SATISFY,
    })
  }
}

export const DISCOUNT_PERCENT_STYLE_MAP = {
  normal: {
    color: '#FA6338',
    background: 'fff',
    border: '1px solid #FA6338',
    'border-radius': '2px',
  },
  normalFlash: {
    background: '#FA6338',
    color: '#FFF',
    border: 'none',
    'border-radius': '0px',
  },
  specialFlash: {
    background: '#FA6338',
    color: '#fff',
    border: 'none',
    'border-radius': '0px',
  },
  brandFlash: {
    background: '#FFF',
    color: '#FF3D00',
    'font-weight': 700,
    'border-radius': '2px',
  },
}

export const checkEstimatedWithConfig = (estimatedInfo, featureConfig) => {
  // closeEstimatedAndAbPrice: 只有商详，后端测关闭到手价不展示券（谷歌爬虫）
  // showNoSatisfied: 只有商详主商品展示凑单到手价
  // showEstimatedPrice: 是否展示到手价
  // showAbPrice: 是否展示 ab 价
  // showNEstimatedPrice: 是否展示N件到手价a
  if (!estimatedInfo) return null
  if (!featureConfig) return null
  let showAbPrice = featureConfig?.showAbPrice
  const {closeEstimatedAndAbPrice, showNoSatisfied, showEstimatedPrice, showNEstimatedPrice} = featureConfig || {}
  // eslint-disable-next-line no-extra-boolean-cast
  if (!Boolean(showAbPrice)) showAbPrice = true
  const { dataType } = estimatedInfo || {}
  const { SATISFY, NO_SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY, N_ESTIMATED } = ESTIMATED_DATA_TYPES
  if (closeEstimatedAndAbPrice) return null
  if (dataType === SATISFY && showEstimatedPrice) return estimatedInfo
  if (dataType === AB && showAbPrice) return estimatedInfo
  if (dataType === N_ESTIMATED && showNEstimatedPrice) return estimatedInfo
  if (dataType === NO_SATISFY && showNoSatisfied) return estimatedInfo
  if (dataType === AB_OVERLAP_NO_SATISFY && showAbPrice && showNoSatisfied) return estimatedInfo
  if (dataType === AB_OVERLAP_SATISFY && showAbPrice && showEstimatedPrice) return estimatedInfo
  return null
}


export const getUnitPriceValue = ({
  price, estimatedInfo, isShowPrice,
}) => {
  if (estimatedInfo) {
    if (estimatedInfo.abts?.EstimatedShowType?.p?.EstimatedShowType === 'New') return price.estimatedPriceInfo?.unitCouponPrice
    return null
  }
  if (isShowPrice) {
    return price.unitShowPrice
  }
  return price.unitSalePrice
}
