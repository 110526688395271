import { isFunction } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'
export { analysis } from './filter'
import { SA } from './sa'
import { SC } from './sc'
const daEventExpose = daEventCenter.getExposeInstance()

export const PIC_TOP_NAV_PREFIX = 'picTopNav'

function matchBreadcrumbs(node) {
  const data = node.cat_url_name || ''
  return node.children?.length
    ? [data, ...matchBreadcrumbs(node.children[0])]
    : [data]
}
export class ListAnalytics {
  static containerExposeCode = 'plv2_exposeCode'
  listExposeCode = 'plv2_list_exposeCode'

  // 不随商品点击曝光上报的场景
  #EXCLUDES_SCENE = [
    // 'ListExpandPop', // 已推全-写死值: pop
    // 'SearchExpandPop', // 已推全-写死值: pop
    'CodeReminder',
    // 'CccListFlow',  // 已推全-写死值: ShowOrderApplication
    'SearchPageLimitNum',
    // 'AttrLinkCategory', // 废弃
    'Featuredreviews',
    'FeaturedreviewsRecommend',
    'EstimatedPrice'
  ]
  #SRCH_KEY_MAP = {
    8: 'SearchSuggestwordFeedback',
    6: 'SearchHotNew',
    3: 'SearchDefaultNew',
    4: 'SearchSuggestNew'
  }

  constructor(locals) {
    this.IS_RW = locals.IS_RW

    this.SA = new SA(locals)
    this.SC = new SC()
  }

  resetExposeStatus(isBack) {
    daEventExpose.reset(ListAnalytics.containerExposeCode)
    daEventExpose.reset(this.listExposeCode)
    daEventExpose?.resetAll?.('listContentBody') // 列表内容体（时尚店铺、趋势）重发pv时，重置曝光，

    if (!isBack) {
      daEventExpose?.resetAll?.('picTopNav')
      daEventExpose?.resetAll?.('picTopNavTab')
    }
    // if (isBack) {
    // analysis.exposeCategory()
    // analysis.exposeFilter(componentState.FilterBar.filterData)
    // reset指令式曝光
    // daEventExpose?.resetAll?.(PIC_TOP_NAV_PREFIX)
    // }
  }

  subscribeExpose(compsCode) {
    daEventExpose.subscribe({
      keycode: `${ListAnalytics.containerExposeCode}\`${compsCode}`,
    })
  }

  subscribeListExpose(compsCode, exposeCb) {
    daEventExpose.subscribe({
      keycode: `${this.listExposeCode}\`${compsCode}`,
      type: 'list',
      hooks: {
        afterExpose: ({ targets, daId }) => {
          isFunction(exposeCb) && exposeCb({ targets, daId })
        }
      }
    })
  }

  updatePageData(updateValue) {
    this.SA.updatePageParam(updateValue)
  }

  resetPageData(results, noSend = false) {
    const ana_scenes = this.getItemsScenes(results)

    this.SA.resetPageParam(results)
    this.SC.resetPageName(results)

    if(!noSend) {
      // send PV
      appEventCenter.$emit('pageOnload', {
      })
    }

    // send tpm
    window.TPM?.publish('viewlist', {
      cat_info: results.catInfo,
      goods: results.goods,
      searchKeywords: results.searchKeywords,
    })
    return {
      exposeCode: this.listExposeCode,
      scenes: ana_scenes.join(),
      traceId: this.refreshTraceId(results),
      saDimension: results.tracking?.singleCatType && results.catInfo.sortScene
        ? `${this.IS_RW ? 'R' : 'S'}M${results.catInfo.sortScene}\`${results.tracking.singleCatType}\`${results.tracking.singleCatId}`
        : '',
      request_ext: Object.keys(results.request_ext)
        .map(key => `${key}_${results.request_ext[key]}`)
        .join('|'),
    }
  }

  refreshTraceId({ listAbtResult, catInfo }) {
    gbExposeTraceid('refreshComponent', { componentName: 'goodslist' })

    if (catInfo.search_redir || catInfo.type === 'search') {
      const { result_type, result_word } = searchWordsGlobalAnalysis.get()
      const srch_abt_info = listAbtResult[this.#SRCH_KEY_MAP[result_type]]

      const params = result_type && result_word
        ? srch_abt_info?.child?.[0]?.bid
          ? `search\`${result_type}\`${result_word}\`${srch_abt_info.child[0].bid}`
          : `search\`${result_type}\`${result_word}`
        : ''

      params && gbExposeTraceid('setComponent', { componentName: 'goodslist', params })
    }

    return gbExposeTraceid('getComponent', { componentName: 'goodslist' })
  }

  // 商品点击曝光埋点携带的 scenes, ABT由服务端直出
  getItemsScenes({ listAbtResult }) {
    return Object.keys(listAbtResult).filter(_ => listAbtResult[_].sceneStr && !this.#EXCLUDES_SCENE.includes(_))
  }

  // 重置图文曝光参数
  resetPicTopNavExpose() {
    daEventExpose?.resetAll?.('picTopNav')
    daEventExpose?.resetAll?.('picTopNavTab')
  }
}
