const { currency } = gbCommonInfo

export function checkoutStateFactory() {
  return {
    prerenderSsr: false,
    isSSR: false,
    locals: null,
    placeOrderRiskId: '', //风控risk_id
    language: {},
    checkout: {},
    adrTitleTipsShow: true,
    storeData: '',
    defaultShipping: [],
    initializeDefaultShipping: {}, // 初始化记录默认运输方式, 后续不再随运输方式的选择而变化
    paymentList: {
      showLoading: false, // 是否出现加载动画（超过1s加载时间时出现）
      loading: false // 目前是否正在请求支付列表
    },
    codDisplay: false,
    notSupportCodReason: '',
    price: {},
    worldpay_ideal_error: false,
    apacpay_thbanktransfer_error: false,
    apacpay_vnbanktransfer_error: false,
    showDiscountLimitTip: false,
    showDiscountLimitDrawer: false,
    showFlashLimitTip: false,
    showCartLimitTip: false,
    shownLimitTipsStatus: {},
    p65TipsShow: false,
    p65TipsShowText: '',
    walletFreez: {
      restTxt: false,
      unpaidTxt: false,
      showReason: false,
      list: null	// 冻结记录
    },
    hasElseWallet: false,	// 是否有其它钱包货币
    showCartNullDialog: false, // 购物车为空dialog
    nullDialogTip: '', // 购物车为空提示语
    nullCartDialog: { // 空购物车弹窗
      show: false,
      tip: ''
    },
    updatingPrice: false,
    paytm_newtbank_error: false,
    status: {
      cardPay: ''
    },
    mallShippingMethods: {},
    mallInsuranceCheck: [],
    coupon: {
      val: '',
      show: false,
      err: '',
      cost: '',
      costShow: false,
      apply: false, // 点击了apply
      success: '',
      applyCoupons: [], // 使用的券
      applyCouponsSuccess: [], // 使用成功的券
      lastCoupon: '', // 最后使用成功的一张券
      lastAddCoupon: '', // 最后一次手动输入成功的券 就算删掉也需要在券列表展示
      isAutoUse: false, // 是否为自动用券
      autoAnchoredCoupon: '', // 进入券列表时自动锚定的券，目前默认为可用券
      errorCoupon: '', // 错误的券
      errorCouponList: [], // 错误的券列表
    },
    autoCouponInfo: {
      coupon: [],
      couponGift: null,
      show: false,
      alreadyShowed: false,
    },
    bestCouponInfo: {
      show: false,
      alreadyShowed: false,
      alreadyExposeCouponInfo: false,
    },
    canInitPayGuide: false,
    couponPrivacyItem: false, // 买券列表勾选隐私政策
    confirmUseCoupon: false, // 展示券确认弹窗
    wallet: {
      val: '',
      show: false,
      err: '',
      costShow: false,
      apply: false, // 点击了apply
      success: '',
      isApplyWalletSuccess: false
    },
    point: {
      val: '',
      show: false,
      err: '',
      costShow: false,
      apply: false, // 点击了apply
      success: '',
      isApplyPointSuccess: false
    },
    pageStatus: { // 右侧滑出页面
      chooseCouponDrawer: false, // 滑出优惠券选择页
      giftCardDrawer: false, // 滑出礼品卡页
      couponGiftAddItem: false, // 滑出优惠券赠品页
      couponPrivacyItem: false, // 优惠券隐私弹框
      showPointInputPanel: false, // 
      showWalletInputPanel: false,
      autoFillMaxAvailablePoint: false, // 自动填充最大可用积分 
    },
    giftcard: {
      cardNo: '',
      errorText: '',
      giftcardUsedList: false
    },
    codConfirm: {
      couponCb: '',
      pointCb: '',
      res: '',
      show: false,
      content: '',
      applyType: '',
      codCoupon: ''
    },
    pointCheck: 0, // 是否使用积分(已废弃)
    now_currency: currency,
    currencyChangeTips: {
      show: false,
      content: ''
    },
    clickTwice: 0, // 避免触发两次,
    showtip: false,
    codCurrencyTip: '',	// cod支付后端强制转换货币提示
    GB_out_stock: 0,
    soldOutModalShow: false,
    walletInfo: {}, // 钱包总额
    addresslist: [],
    addresslistgray: [],
    selectAddress: {},
    blackMask: {
      isLoaded: false,
      showMask: false,
      text: '',
      cb: '',
      billno: '',
      errorCode: '',
      type: 0 // 1: 买券提示弹窗, addressError：ppga地址异常提示
    },
    stroeAddressShow: false,
    // 优惠券相关
    usableCouponList: [], // 未用券
    disabledCouponList: [], // 已用券
    bestCoupon: null, // 算法最优券
    shoppingBagModalShow: false,
    showSfsProductsDrawer: false,
    paytmUPI: {
      payerAccount: '',
      showError: false
    },
    paymentOption: {
      'Paytm-UPI': {
        payerAccount: '',
        showError: false
      },
      'cashfree-upi': {
        payerAccount: '',
        showError: false
      }
    },
    showPaymentCoupon: false,
    paymentCouponList: false,
    confirmUsePaymentCouponShow: false,
    nowPaymentCoupon: null,
    lastSelectePayment: null,	// 切换支付时前一次选中的支付
    noSelectPayment: false,
    currencySupport: [],
    // 礼品卡页面获取到的优惠券报错
    isShowCouponError: false,
    beforeChangedCurrency: '',
    showAddrPage: false,
    fullWalletNoCurr: false,
    couponGift: null,
    soldoutType: null, //0：无货弹窗，1：商品限制弹窗
    couponsApply: false,
    isCheckSpecialGoods: false, // 南非校验特殊商品下单地址是否存在身份id
    specialGoodsModalShow: false, // 是否显示南非特殊商品下单限制滑出层
    noticeHeight: 0, // 下单页公高高度
    loading: false,
    loadingParam: {
      timer: null,
      count: 0,
      loading: false,
    },
    cartModalMallCode: '',
    changeShopType: false,
    cartPromotionMap: {},
    freeShipTip: {
      coupon: [],
      point: [],
      other: [],
      otherType: ''
    },	// 使用券 积分 导致免邮差额提示
    freezTipsAndGoods: { // 钱包&礼品卡使用限制抽屉
      show: false,
      type: '',
    },
    // 无库存商品
    newOutStockCarts: [],
    walletLimit: [],
    giftCardLimitGoods: [],
    pointLimitGoods: [], // 限制使用积分的商品，根据不同的积分比例聚合不同的商品
    couponLimit: {
      totalPriceWithSymbol: '', // 当前适用商品的总金额，带货币符号
      needPriceWithSymbol: '', // 差额，带货币符号
      goods: [], // 适用商品
      applicationTips: '' // 适用范围提示
    },
    primeProductList: [], // 付费会员用户可购买产品信息
    lowPrimeProduct: {}, // 当前付费会员产品包中的价格最低的产品包
    selectdPrimeIndex: null, // 当前选中产品包index
    usedPrimeIndex: -1, // 最终使用的产品包index
    usedPrimeCode: '',
    rightConfigList: [], // 当前选中产品包权益列表
    primeRightCouponList: [], // 当前选中产品包权益列表下的运费权益中的券列表信息
    isAgreePrime: false, // 用户是否同意会员权益
    showPrimeDetail: false,
    primePopFrom: '', // 记录prime弹框触发的地方  1-随单购模块、2-物流运输方式、3-下单页挽留弹窗
    calCbHasErrTip: false, //防止价格计算接口重复错误提示，true表示价格计算接口的回调cb里有处理错误内容或者提示
    showMarkedPrice: 0, // 展示闪购划线运费价
    coupon_payment_code_unique: '', // 选中过的支付方式
    isShowQuickPop: false,
    descQuickShipTime: '', // 购物车袋中quickship时效信息
    couponAvaliableTip: '', // abt控制coupon优惠信息
    isPrimeMember: false, // 是否是付费会员
    is_manual_usedCoupon: 0, // 是否手动使用过券
    showShipSwitch: false, // 是否展示自动使用会员券消息提醒
    canAutoUsePrime: false, // 随单购场景是否可以自动用券
    autoPrimeReadCoupon: [], // 相同自动用券提示后 不在提示
    autoXtraReadCoupon: [], // 相同自动用券提示后 不在提示
    paymentLogoInfo: {}, // 支付图标
    checkoutGuideLogoList: [], // 下单页引导图标
    showShippingDayPercent: false,
    shipPercentMethods: [],
    dayPercentInx: 0, // 时效百分比默认选中运输方式inx
    isClickedQsFreight: false, // 是否手动点击过qs运费，供缓存使用
    qsFreightSelected: false, // 当前是否选中购买qs运费,优先于用户上次选择结果
    isShowQsFreight: false,
    qsFreightTips: '',
    checkoutAbtInfo: {
      ShowStoreItem: {},
      BuyTc: {}
    },
    cacuMallParams: [], // 聚合当前shem_caculate_data中的mall_params
    prevCalcuMallParams: [], // 最近一次成功使用计价的mall_params参数
    integrityPromotionInfo: {}, // 全场活动信息
    promotionList: [],
    asyncComponentLoadedStatus: { // 按需加载组件
      soldOutIsLoaded: false,
      specialGoodsIsLoaded: false,
      addressCommonIsLoaded: false,
      shippingDayPercentIsLoaded: false,
      myCouponListIsLoaded: false,
      myCouponPrivacyItemIsLoaded: false,
      giftCardIsLoaded: false,
      couponGiftAddItemIsLoaded: false,
      addressListPageIsLoaded: false,
      shoppingBagModalIsLoaded: false,
      sfsProductsDrawerIsLoaded: false,
      paymentCouponIsLoaded: false,
      loginConfirmIsLoaded: false,
      freezTipsAndGoodsIsLoaded: false,
      pointIsLoaded: false,
      WalletIsLoaded: false,
      primeDetailIsLoaded: false,
      cardBinDiscountArticleIsLoaded: false,
      checkoutDialogIsLoaded: false,
      retainDialogIsLoaded: false,
      shippingFeeIsLoaded: false,
      xtraCouponIsLoaded: false,
      shippingLimitGoodsIsLoaded: false,
      shippingLimitAddressIsLoaded: false,
      shippingLimitStoreIsLoaded: false,
      shippingTypeDialogIsLoaded: false,
      shoppingBagRetainDialogIsLoaded: false,
      prePayCompsIsLoaded: false,
      discountLimitDrawerIsLoaded: false
    },
    soldOutOrSpecialGoodsModal: {
      productLimitCarts: [],
      hintType: 0, //商品限制类型hint_type   0：不涉及商品，1：删除全部商品，2：删除部分商品
      productLimitTip: ''
    },
    mallCacuTime: new Date().getTime(), // 最后一次成功请求计价接口时间
    showEnvironMental: false,
    alreadyShowWalletPanel: false, // 是否展示过钱包提示弹窗
    showShippingFeeDrawer: false,
    shippingFeeRuleInfo: {},
    showXtraCoupon: false,
    xtraDiscountInfo: {},
    xtraAllProductBagInfo: {}, // 超省卡模块的所有信息，包括卡包、劵等
    xtraProductInfo: {}, // 当前显示的超省卡信息（用户没有选，默认第一个）
    xtraProductBagInfo: [], // 超省卡的所有产品包信息
    xtraCouponList: [], // 当前显示超省卡的券信息，比较纯粹的劵信息
    xtraDisplayCouponInfo: {}, // 当前显示超省卡的最优券信息
    xtraAggregatedCouponList: [], // 超省卡的所有楼层券
    usedXtraCode: '',
    xtraProductCodeInDrawer: '', // 在内页存在多包的时候，选择的code，需要和外页面的usedXtraCode区分开，只有在点击底部按钮的时候才会更新为要用的usedXtraCode
    isShowKnivesDialog: false, // 点击place order是否弹出刀具-年龄弹框
    adult: '',  // 刀具年龄 1成年  0未成年
    isCancelXtra: false,
    primeSavingSummary: {},
    showPrimeLimitModal: false,
    showBagTax: false,
    taxCartsInfo: {
      list: [],
      mallCode: ''
    },
    showBagTaxDialog: false,
    togetherBuy: {
      togetherBuyType: 0,
      togetherShoppingNav: 0,
      togetherAddCart: 0, // 加车完保存状态
      currentCartList: [],
      mallData: [],
      quickViewCartData: {}, // 加车后的数据
      addCartProducs: [], //加车后的顺序id
      recommendProducts: [], // 推荐商品
      exposedGoodsId: '',
      mallLacation: [],
      quickViewCartDataMallCode: 0,
      quickViewQuickShip: '',
      newProductCard: '',
      detailgoodsCardAbt: ''
    }, // 已曝光的商品id
    showShippingLimitGoods: false, // 是否展示下单规则限制前置 - 商品修改弹窗
    shippingLimitInfo: {
      hint: '',
      goodsList: [],
      shippingMethod: {},
      avaliableStoreTransportInfo: {}, // 可切换的可用店配运输方式
      mallCode: '',
      isStoreAddress: false,
      checkUpdateShippingMethod: false,
      orderLimitType: null, // 不涉及商品-0,删除全部商品-1,删除部分商品-2,需修改地址-3,店配地址修改-7
      shippingLimitModalCount: 0,
    },
    showShippingLimitAddress: false, // 是否展示下单规则限制前置 - 地址修改弹窗
    showShippingLimitStore: false, // 是否展示下单规则限制前置 - 店配地址修改弹窗
    // 省市区第二语言
    secondLanguage: {
      state: '',
      city: '',
      district: ''
    },
    // 第二语言数据缓存
    secondLanguageCache: {},
    isLoadingSecondLanguage: false,
    shippingTypeDialog: false,
    shippingTypeChangeInfo: {
      shippingMethod: {},
      mallCode: '',
      prevShippingMethod: {}
    },
    selectStoreAddressShippingInfo: {
      shippingMethod: {},
      mallCode: '',
      isAdd: false
    },
    // 是否触发强制刷新运输方式且需要带即将选中的运输方式
    forceUpdateShippingMethod: {
      shippingMethod: {},
      mallCode: '',
      isUpdate: false,
    },
    createOrderLoading: false,
    showSureDelModel: -1,
    showShoppingBagRetain: false,
    showRecheckQuickship: false,
    editCartInfo: {
      item: {}, // 当前修改的商品
      type: '', // 删除商品挽留弹窗（retain）、qs库存不足二次确认（quickship）
      isCancel: false,
      isQsConfirm: false,
      quickshipInfo: {
        type: -1, // 点击的何处qs商品（购物袋内部不拆分qs库存不足或非qs商品-1；购物袋外部拆分 点击qs足够1、 点击qs不足 0）
        qsQuantity: 0,
        noQsQuantity: 0,
      },
      isDelete: false,
      isMoveWhislist: false,
      isLargeShipGoods: false, // 操作的商品是否是大件海陆运商品
      currentValue: 0,
      oldValue: 0,
      source: ''
    },
    editCartLoading: false, // 当前是否正在修改商品
    mallCartsList: [],
    virtualCouponInfo: {
      xtraSceneDiscount: 0, // 存储超省卡模块场景
      virtualXtraCoupon: {}, // 超省卡会费券信息
      newXtraProductInfo: {}, // 重组数据
      status: {},
      apiLock: 0,

      visibleMyCouponListMadal: false, // 记录是否打开了券列表
      newPrimeProductList: [], // 重组数据
      currentClickType: '', // 超省卡 xtra 付费会员 prime
      couponVirtualCode: [], // 付费会员包会费券收集
    },
    isInitPromotion: false,
    buyNowNo: '', // 立即购单号（为空默认走正常下单逻辑）
    buyNowFromUrl: '', // 立即购跳转回商详链接
    autoPayList: [], //自动续费会员包，可以有哪些支付方式
    autoCulateApi: false, // 切包发起的计价接口,成功
    defaultSelectTokenPay: {}, // 默认选中的 token 支付类型
    isShowTokenPay: false,  // 卡支付是否展示为 token 支付
    tokenCvv: '', // token 支付的 cvv
    isOrderTotalOut: false, // 订单明细的orderTotal是否在可视区域
    showPaymentMethodsDialog: false, // 是否展示支付方式列表弹窗
    shippingContentInfo: {}, // 运输--免邮查询获取的信息，触发更新逻辑稍微有点绕（/v2/order/Shipping/freeMall）
    shippingAddStatusInfo: { // 是否完成运输方式凑单信息
      getStatus: false, // 是否成功获取缓存状态
      addStatus: false, // 凑单完成状态
      transportType: '', // 哪种运输方式拉起的当前凑单组件
    },
    cardBinDiscountInfo: {},  // token 卡 bin 优惠信息
    createOrderBin: '', // 生成订单传入的卡 bin
    createOrderBinDialogShow: false, // 生成订单传入的卡 bin失败弹窗
    handlerSelectBin: '', // 手动选中的 卡 bin
    isShowTokenBinDiscountBlock: false, // 是否展示卡bin优惠
    tokenBinDiscountDesc: '', // 卡bin优惠内容
    tokenBinDiscountType: 0, // 卡bin优惠类型
    isNoNeedCardBin: false, // 生成订单不需要 cardBin
    paymentMethodsProps: {}, // 支付方式参数
    isShowHomogenizationDrawer: false, // 是否展示同质化支付方式列表抽屉
    isShowBinCouponsDrawer: false, // 卡 bin 可用券抽屉是否展示
    isShowBinCouponsDrawerInNormalPay: false, // 卡 bin 可用券抽屉是否展示, 非token支付时
    tokenBinList: [], // 卡 bin 列表
    lastSelectBin: '', // 上次选中的卡 bin
    lastSelectInstallmentBin: '', // 上次选中的卡分期 bin
    isSelectCouponsBinError: false, // 选中卡 bin 是否报错
    isShowPriceDetail: false, // 是否打开订单明细抽屉
    isHasBeenNoAddressDrawerShow: false, // 在无地址的情况下，控制当前地址弹窗是否已经展示，防止用户点击后，弹窗再出现
    isAddressCommonDone: false, // 控制address_common组件是否加载完成
    isHasAddressInEnterCheckout: false, // 当无地址进入下单页面的时候，填写地址后，需要将该值变为true
    collectShippingTimeText: {}, // 运输方式的时效文案
    isShowNoAddressTipsDialog: false, // 在无地址的时候，是否展示地址填写弹窗提醒
    isShowSelectShippingAddressTips: false, // 在无地址的时候，选择某个运输方式碰到了限制规则，弹窗文案之类的信息需要变化
    isStoreShipAtShowAddressDialog: false, // 在无地址的时候，命中一些规则，比如is_available_msg = 9的时候，需要唤醒填写地址弹窗，区分选择的运输方式是店配还是宅配
    isShowInstallmentTokenDrawer: false, // 是否展示分期支付的 token 弹窗
    isShowInstallmentListDrawer: false, // 是否展示分期支付的分期列表弹窗
    installmentSelectBin: '', // 分期支付选中的卡 bin
    installmentSelectToken: {}, // 分期支付选中的卡 bin
    isShowTokenFrontInstallment: false, // 是否展示 token 分期支付
    selectInstallment: {}, // 选中的分期
    isShowCvvDrawer: false, // 是否展示 cvv 弹窗
    prePayForCheckoutReady: false, // 卡前置支付是否准备好
    visiblePrePay: false, // 是否展示卡前置支付弹窗
    prePayUnPayParams: {}, // 卡前置支付未支付订单信息
    isMAESTRO: false, // 是否是 maestro 卡
    isOfflineStatus: false, // 是否是下线的状态，控制部分toast不提示等
    isMallOrderCreateFail: false, // 下单失败
    isUseNewCard: false, // 是否使用新卡
    couponBinList: [], // 券支持的卡bin
    newCheckoutLoadingType: '', // 下单loading类型
    // 墨西哥税费优惠展示
    mxTaxNotice: {
      dialogVisible: false, // 弹窗
      fixedNoticeVisible: false // 固定通知栏
    },
    quickShipInfoTag: {}, // 下单quickship Tag标签
    binInstallmentListInfo: [], // 分期支付列表信息
    isShowNoInstallmentDialog: false, // 是否展示无分期支付弹窗
    qsMallList: {},
    // 底部高度
    footerHeight: 0,
    // 是否没有权益楼层数据
    noRewardData: false,
    coBrandQualificationInfo: {}, //联名卡信息查询
    isShowNewGiftCardDrawer: false// 新礼品卡控制弹窗, 
  }
}
