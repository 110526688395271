import schttp from 'public/src/services/schttp'
import { getLanguageByPageApi } from 'public/src/services/api/index.js'
import { ApiCache } from '../../ApiCache/index'
import { handleGoodsImgLessen, deadcodeCCCCleaner } from '../utils.js'
import { NOT_CACHE_KEYS, PRODUCT_LIST_REQUEST_URL_MAP } from '../constant.js'
import { preloadImg } from '../../../utlis/prefetch.js'
import { customMarkPoint } from 'public/src/services/mark/index.js'
import { createPerformanceTrack } from '../performanceTrack.js'
import { createApiCache } from './createApiCache.js'

const { SEARCH, REAL, SELECT, SELLING, PICKED, SHEIN_PICKS, COMMON, USER_BEHAVIOR, FEEDBACK_REC } = PRODUCT_LIST_REQUEST_URL_MAP

/**
 * ==================================================================================
 * ================= 这里代码过于重复,后续新增相似请使用 createApiCache ===================
 * ==================================================================================
 * 参数
 * export const { apiCache, abortionInstance, clear } = createApiCache({ url: '', cacheTime: 1 * 60 * 1000, eventName: 'api_request' })
 * 
 * 例如
 * export const { apiCache: cccFeedbackApiCache, abortionInstance: cccFeedbackAbortionInstance } = createApiCache({
 *   url: COMMON.cccFeedback,
 *   cacheTime: 10 * 60 * 1000, // 缓存时间 10 分钟
 *   eventName: 'api_request_feedback'
 * })
 */

const deleteUselessParams = (params) => {
  if (params?._type) {
    delete params._type
  }

  if (params?._show_daily) {
    delete params._show_daily
  }

  if (params?._requestType) {
    delete params._requestType
  }

  return params
}

// 多语言
// export let languageAbortionInstance = null
// export const languageApiCache = new ApiCache({
//   cacheTime: 60 * 1000, // 缓存时间 1 分钟
//   request: async ({ pages }) => {
//     if (languageAbortionInstance) {
//       languageAbortionInstance.abort()
//     }
//     languageAbortionInstance = new SchttpAbortCon()

//     const res = await getLanguageByPageApi({ pages, signal: languageAbortionInstance.signal })

//     return res
//   },
//   notCacheKeys: [],
// })

// 多语言
export const { apiCache: languageApiCache, abortionInstance: languageAbortionInstance } = createApiCache({
  fetchApiFunction: getLanguageByPageApi,
})

// ccc seo
export let cccSeoAbortionInstance = null
export const cccSeoApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (cccSeoAbortionInstance) {
      cccSeoAbortionInstance.abort()
    }
    cccSeoAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.cccSeo })
    const schttpOptions = {
      method: 'GET',
      url: COMMON.cccSeo,
      params: options.params,
      signal: cccSeoAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true,
    }
    try {
      const { data } = await schttp(schttpOptions)
      markApiEnd()
      return  {
        pageTitle: data.info?.title || '',
        pageDescription: data.info?.description || '',
        pageKeywords: data.info?.keyWords || ''
      }
    } catch (err) {
      return {}
    }
  },
  notCacheKeys: NOT_CACHE_KEYS,
})

// ccc opeartion
export let cccOPeartionAbortionInstance = null
export const cccOPeartionApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (cccOPeartionAbortionInstance) {
      cccOPeartionAbortionInstance.abort()
    }
    cccOPeartionAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.cccSeo })
    const schttpOptions = {
      method: 'GET',
      url: COMMON.cccOperation,
      params: options.params,
      signal: cccOPeartionAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true,
    }
    try {
      const { data } = await schttp(schttpOptions)
      markApiEnd()
      return  deadcodeCCCCleaner(data.info?.cccOperations || {})
    } catch (err) {
      return {}
    }
  },
  notCacheKeys: NOT_CACHE_KEYS,
})

// 负反馈
export let cccFeedbackAbortionInstance = null
export const cccFeedbackApiCache = new ApiCache({
  cacheTime: 10 * 60 * 1000, // 缓存时间 5 分钟
  request: async () => {
    if (cccFeedbackAbortionInstance) {
      cccFeedbackAbortionInstance.abort()
    }
    cccFeedbackAbortionInstance = new SchttpAbortCon()

    const res = await schttp({
      url: COMMON.cccFeedback,
      params: {},
      signal: cccFeedbackAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 商品列表
export let goodsAbortionInstance = null
export const goodsApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (goodsAbortionInstance) {
      goodsAbortionInstance.abort()
    }
    if (!options.withoutAbort) {
      goodsAbortionInstance = new SchttpAbortCon()
    } else {
      goodsAbortionInstance = null
    }

    const isSearch = options.type === 'search'
    const isPicked = options.type === 'picked'
    const isFeedbackRec = options.type === 'feedbackRec'

    let url = options.url || ''
    if (isSearch) {
      url = SEARCH.goods
    } else if (options.type === 'selection') {
      url = SELECT.goods
    } else if (options.type === 'entity') {
      url = REAL.goods
    } else if (options.type === 'selling') {
      url = SELLING.goods
      options.method = 'GET'
    } else if (options.type === 'sheinPicks') {
      url = SHEIN_PICKS.goods
    } else if (isPicked) {
      url = PICKED.goods
    } else if (isFeedbackRec) {
      url = FEEDBACK_REC.goods
    } else if (options.type === 'userBehavior') {
      url = USER_BEHAVIOR.goods
    }

    const isListPage = ['selection', 'entity', 'sheinPicks'].includes(options.type)

    const markApiEnd = customMarkPoint({ eventName: url })
    let schttpOptions = {}
    if (options.type === 'userBehavior') {
      schttpOptions = {
        method: 'POST',
        url,
        data: options.params,
        // data: {
        //   page: 1,
        //   size: 20,
        //   carrierSubType: '85',
        //   contentCarrierId: '2908100',
        //   entranceGoodsId: '26219580'
        // },
        useBffApi: true
      }
    } else {
      schttpOptions = {
        method: options.method || 'POST',
        url,
        params: options.params,
        isPreLoad: true,
        useBffApi: true,
        ...(isListPage ? { featureKey: 'click_refresh' } : {}),
        ...(isSearch ? { featureKey: 'search_cycle' } : {}),
        ...(isPicked && options.addUserBehaviorParams ? { featureKey: 'information_flow' } : {})
      }
    }
    if (!options.withoutAbort) {
      schttpOptions.signal = goodsAbortionInstance.signal
    }
    const res = await schttp(schttpOptions)

    markApiEnd()

    function handlePreloadImgs() {
      // 对 goods 中的前 4 张商品主图 (包括 spu 图) 进行预取
      const prefetchGoods = res?.info?.products?.slice(0, 4) || []
      const mainImages = prefetchGoods.map(item => item.goods_img)
      const spuImages = prefetchGoods.map(item => item.spu_images?.spu_images?.[0])
      // 取搜索店铺卡片图片
      // const searchStoreCardImgs = res?.searchBannerCard?.searchStoreCard?.products?.map(item => item.goods_img).filter(v => v) || []
      // 取搜索趋势卡片店铺图片
      // const trendCargBg = 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/45/17162772482320df6564cdf819da0dcf50402ffdba.png'
      // const trendCardProductImg = res.searchBannerCard?.searchTrendCard?.mianImg // 商品图
      // const trendCardLineChat = res.searchBannerCard?.searchTrendCard?.growthLabel?.growthImage // 折线图
      // const trendCardImgs = [trendCardProductImg, trendCardLineChat]
      // if (trendCardImgs.length) {
      //   trendCardImgs.push(trendCargBg)
      // }

      Promise.all([
        handleGoodsImgLessen({ mainImages, prefetchGoods }),
        // 338 这个图片设计尺寸来自于列表双列布局下, 商品主图的设计稿尺寸, 参考 Item.vue 中的变量: columnDesignWidthMap
        preloadImg({ imgs: [...spuImages], designWidth: 338 }),
        // 这里设计尺寸传 0, 是因为不用裁切, 只是做 webp 的替换
        // preloadImg({ imgs: [...searchStoreCardImgs, ...trendCardImgs], designWidth: 0 })
      ]).finally(() => {})
    }
    handlePreloadImgs()
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})
export const clearGoodsApiCache = () => goodsApiCache.clear()


// 上位词商品列表
export let hypernymGoodsAbortionInstance = null
export const hypernymGoodsApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (hypernymGoodsAbortionInstance) {
      hypernymGoodsAbortionInstance.abort()
    }
    hypernymGoodsAbortionInstance = new SchttpAbortCon()

    let url = '/product/get_search_upper_recommend_products'
    const markApiEnd = customMarkPoint({ eventName: url })
    const schttpOptions = {
      method: 'GET',
      url,
      params: options.params,
      signal: hypernymGoodsAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true,
    }

    const res = await schttp(schttpOptions)
    markApiEnd()

    function handlePreloadImgs() {
      // 对 goods 中的前 4 张商品主图 (包括 spu 图) 进行预取
      const prefetchGoods = res?.info?.products?.slice(0, 4) || []
      const mainImages = prefetchGoods.map(item => item.goods_img)
      const spuImages = prefetchGoods.map(item => item.spu_images?.spu_images?.[0])

      Promise.all([
        handleGoodsImgLessen({ mainImages, prefetchGoods }),
        preloadImg({ imgs: [...spuImages], designWidth: 338 }),
      ]).finally(() => {})
    }
    handlePreloadImgs()
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})
export const clearHypernymGoodsApiCache = () => hypernymGoodsApiCache.clear()

// 搜索筛选
export let searchFilterAbortionInstance = null
export const searchFilterApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (searchFilterAbortionInstance) {
      searchFilterAbortionInstance.abort()
    }
    searchFilterAbortionInstance = new SchttpAbortCon()

    const searchFilterPerformanceEnd = createPerformanceTrack({ url: SEARCH.filters })
    const endFilterPoint = customMarkPoint({ eventName: SEARCH.filters })
    const res = await schttp({
      url: SEARCH.filters,
      params: options.params,
      signal: searchFilterAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    searchFilterPerformanceEnd?.()
    endFilterPoint?.()
    return res?.code === '0' ? res.info : {}
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})
export const clearSearchFilterApiCache = () => searchFilterApiCache.clear()

// 筛选项以及tsp标签
export let selectionAndEntityFilterAbortionInstance = null
export const selectionAndEntityFilterApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (selectionAndEntityFilterAbortionInstance) {
      selectionAndEntityFilterAbortionInstance.abort()
    }

    selectionAndEntityFilterAbortionInstance = new SchttpAbortCon()

    let url = ''
    if (params._type === 'selection') {
      url = SELECT.filters
    } else if (params._type === 'entity') {
      url = REAL.filters
    } else if (params._type === 'sheinPicks') {
      url = SHEIN_PICKS.filters
    } else if (params._type === 'selling') {
      url = SELLING.filters
    } 

    const markApiEnd = customMarkPoint({ eventName: url })
    const res = await schttp({
      url,
      params: deleteUselessParams(params),
      signal: selectionAndEntityFilterAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})
export const clearSelectionAndEntityFilterApiCache = () => selectionAndEntityFilterApiCache.clear()

export let dailyNewFilterAbortionInstance = null
export const dailyNewFilterApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (dailyNewFilterAbortionInstance) {
      dailyNewFilterAbortionInstance.abort()
    }
    dailyNewFilterAbortionInstance = new SchttpAbortCon()

    const url = PRODUCT_LIST_REQUEST_URL_MAP.DAILY_NEW.filters
    const markApiEnd = customMarkPoint({ eventName: url })
    const res = await schttp({
      url,
      params: deleteUselessParams(params),
      signal: dailyNewFilterAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 图文导航
export let selectionAndEntityPicNavAbortionInstance = null
export const selectionAndEntityPicNavApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (params._requestType !== 'firstload') {
      return {}
    }

    if (selectionAndEntityPicNavAbortionInstance) {
      selectionAndEntityPicNavAbortionInstance.abort()
    }

    selectionAndEntityPicNavAbortionInstance = new SchttpAbortCon()

    let url = ''
    if (['selection', 'entity'].includes(params._type)) {
      url = COMMON.picNav
    }

    const markApiEnd = customMarkPoint({ eventName: url })
    const res = await schttp({
      url,
      params: deleteUselessParams(params),
      signal: selectionAndEntityPicNavAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})


// 标签云
export let selectionAndEntityCloudTagAbortionInstance = null
export const selectionAndEntityCloudTagApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (selectionAndEntityCloudTagAbortionInstance) {
      selectionAndEntityCloudTagAbortionInstance.abort()
    }

    selectionAndEntityCloudTagAbortionInstance = new SchttpAbortCon()

    let url = ''
    if (params._type === 'selection') {
      url = SELECT.cloudTag
    } else if (params._type === 'entity') {
      url = REAL.cloudTag
    } else if (params._type === 'sheinPicks') {
      url = SHEIN_PICKS.cloudTag
    } else if (params._type === 'selling') {
      url = SELLING.cloudTag
    } 

    const markApiEnd = customMarkPoint({ eventName: url })
    const res = await schttp({
      url,
      params: deleteUselessParams(params),
      signal: selectionAndEntityCloudTagAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})
export const clearSelectionAndEntityCloudTagApiCache = () => selectionAndEntityCloudTagApiCache.clear()

export let dailyNewCloudTagAbortionInstance = null
export const dailyNewCloudTagApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (dailyNewCloudTagAbortionInstance) {
      dailyNewCloudTagAbortionInstance.abort()
    }

    dailyNewCloudTagAbortionInstance = new SchttpAbortCon()

    const url = PRODUCT_LIST_REQUEST_URL_MAP.DAILY_NEW.cloudTags

    const markApiEnd = customMarkPoint({ eventName: url })
    const res = await schttp({
      url,
      params: deleteUselessParams(params),
      signal: dailyNewCloudTagAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 标签云中的date标签数据
export let categoryDayTagAbortionInstance = null
export const categoryDayTagApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (params._show_daily !== '1') {
      return {}
    }

    if (categoryDayTagAbortionInstance) {
      categoryDayTagAbortionInstance.abort()
    }

    categoryDayTagAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.categoryDay })
    const res = await schttp({
      url: COMMON.categoryDay,
      params: deleteUselessParams(params),
      signal: categoryDayTagAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 标签云中的date标签数据
export let showRecentDaysTagAbortionInstance = null
export const showRecentDaysTagApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (showRecentDaysTagAbortionInstance) {
      showRecentDaysTagAbortionInstance.abort()
    }

    showRecentDaysTagAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.showRecentDays })
    const res = await schttp({
      url: COMMON.showRecentDays,
      params: deleteUselessParams(params),
      signal: showRecentDaysTagAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 榜单插坑
export let rankListComponentAbortionInstance = null
export const rankListComponentApiCache = new ApiCache({
  cacheTime: 0, // 不缓存
  request: async (params) => {
    if (rankListComponentAbortionInstance) {
      rankListComponentAbortionInstance.abort()
    }

    rankListComponentAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.rankListComponent })
    const res = await schttp({
      url: COMMON.rankListComponent,
      params: deleteUselessParams(params),
      signal: rankListComponentAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    if (res?.info && params.delivery_place) {
      res.info.delivery_place = params.delivery_place - 1
    }
    markApiEnd()

    return res
  },
  notCacheKeys: [],
})

// 调查问卷插坑
export let surveyComponentAbortionInstance = null
export const surveyComponentApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (surveyComponentAbortionInstance) {
      surveyComponentAbortionInstance.abort()
    }

    surveyComponentAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.surveyComponent })
    const res = await schttp({
      url: COMMON.surveyComponent,
      params: params,
      signal: surveyComponentAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    const { info = {} } = res
    return info
  },
  notCacheKeys: [],
})

export let cccCommonComponentAbortionInstance = null
export const cccCommonComponentApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (cccCommonComponentAbortionInstance) {
      cccCommonComponentAbortionInstance.abort()
    }
    cccCommonComponentAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.cccCommonComponent })
    const res = await schttp({
      url: COMMON.cccCommonComponent,
      params: params,
      signal: cccCommonComponentAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  notCacheKeys: [],
})

// 折扣频道插坑
export let discountChannelCompAbortionInstance = null
export const discountChannelCompApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    if (discountChannelCompAbortionInstance) {
      discountChannelCompAbortionInstance.abort()
    }
    discountChannelCompAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: COMMON.discountChannelComp })
    try {
      const res = await schttp({
        url: COMMON.discountChannelComp,
        params: params,
        signal: discountChannelCompAbortionInstance.signal,
        isPreLoad: true,
        useBffApi: true
      })
      markApiEnd()
  
      return res
    } catch {
      return {}
    }
  },
  notCacheKeys: [],
})

// 信息流落地页筛选项以及tsp标签
export let pickedFilterAbortionInstance = null
export const pickedFilterApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (pickedFilterAbortionInstance) {
      pickedFilterAbortionInstance.abort()
    }

    pickedFilterAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: 'api_request_filter_goods' })

    const res = await schttp({
      url: PICKED.filters,
      params: options.params,
      signal: pickedFilterAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 信息流落地页标签云
export let pickedCloudTagAbortionInstance = null
export const pickedCloudTagApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (pickedCloudTagAbortionInstance) {
      pickedCloudTagAbortionInstance.abort()
    }

    pickedCloudTagAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: 'api_request_filter_tagCloud' })

    const res = await schttp({
      url: PICKED.cloudTags,
      params: options.params,
      signal: pickedCloudTagAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})


// shein_picks
export let sheinPicksNavTabAbortionInstance = null
export const sheinPicksNavTabApiCache = new ApiCache({
  cacheTime: 10 * 60 * 1000, // 缓存时间 10 分钟
  request: async (options) => {
    if (sheinPicksNavTabAbortionInstance) {
      sheinPicksNavTabAbortionInstance.abort()
    }

    sheinPicksNavTabAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: 'api_request_sheinPicks_navTab' })

    const res = await schttp({
      url: SHEIN_PICKS.navTabs,
      params: options.params,
      signal: sheinPicksNavTabAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()
  
    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})
export const clearSheinPicksNavTabApiCache = () => sheinPicksNavTabApiCache.clear()

// 实时反馈落地页筛选项以及tsp标签

export let feedbackRecFilterAbortionInstance = null
export const feedbackRecFilterApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (feedbackRecFilterAbortionInstance) {
      feedbackRecFilterAbortionInstance.abort()
    }

    feedbackRecFilterAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: 'api_request_filter_goods' })

    const res = await schttp({
      url: FEEDBACK_REC.filters,
      params: options.params,
      signal: feedbackRecFilterAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 实时反馈落地页标签云
export let feedbackRecCloudTagAbortionInstance = null
export const feedbackRecCloudTagApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (feedbackRecCloudTagAbortionInstance) {
      feedbackRecCloudTagAbortionInstance.abort()
    }

    feedbackRecCloudTagAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: 'api_request_filter_tagCloud' })

    const res = await schttp({
      url: FEEDBACK_REC.cloudTags,
      params: options.params,
      signal: feedbackRecCloudTagAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})

// 搜索结果页顶部卡片
export let searchBannerCardAbortionInstance = null
export const searchBannerCardApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (options) => {
    if (searchBannerCardAbortionInstance) {
      searchBannerCardAbortionInstance.abort()
    }

    searchBannerCardAbortionInstance = new SchttpAbortCon()

    const markApiEnd = customMarkPoint({ eventName: 'api_request_searchBannerCard' })

    const res = await schttp({
      url: SEARCH.bannerCard,
      params: options.params,
      signal: searchBannerCardAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    })
    markApiEnd()

    return res
  },
  // 配置过滤掉不参加缓存的参数key TODO:
  notCacheKeys: NOT_CACHE_KEYS,
})
export const clearSearchBannerCardApiCache = () => searchBannerCardApiCache.clear()

/**
 * Creates a singleton instance of ApiCache with specified configurations.
 *
 * @returns {ApiCache} An instance of ApiCache with custom configurations.
 *
 * @typedef {Object} RequestOptions
 * @property {string} url - The request URL.
 * @property {Object} params - The request parameters.
 * @property {string} method - The HTTP method (default is 'GET'). Optional.
 * @property {string} eventName - The event name. Optional.
 * @property {boolean} isPreLoad - Indicates if the request is a pre-load request (default is true). Optional.
 * @property {boolean} useBffApi - Indicates if the request should use bffApi (default is true). Optional.
 */
const ApiCacheSingleton = () => {
  return new ApiCache({
    cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
    abortionInstance: null,
    /**
     * 请求方法
     * @param {RequestOptions} options 请求参数
     */
    async request(options) {
      if (this.abortionInstance) {
        this.abortionInstance.abort()
      }

      this.abortionInstance = new SchttpAbortCon()

      const markApiEnd = customMarkPoint({ eventName: options.eventName })

      const res = await schttp({
        url: options.url,
        params: options.params,
        method: options.method || 'GET',
        signal: this.abortionInstance.signal,
        isPreLoad: options.isPreLoad ?? true,
        useBffApi: options.useBffApi ?? true,
      })
      markApiEnd()

      return res
    },
    // 配置过滤掉不参加缓存的参数key TODO:
    notCacheKeys: NOT_CACHE_KEYS,
  })
}

export const discountPageInfoApiCache = ApiCacheSingleton()

// sheinClubInfo
export const { apiCache: sheinClubInfoCache, abortionInstance: sheinClubInfoInstance } = createApiCache({
  url: COMMON.sheinClubInfo,
  notCacheKeys: NOT_CACHE_KEYS,
})

// breadcrumb
export const { apiCache: breadcrumbCache, abortionInstance: breadcrumbInstance } = createApiCache({
  url: COMMON.breadcrumb,
  notCacheKeys: NOT_CACHE_KEYS,
})
