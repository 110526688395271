const PRODUCT_LIST_API_URL_MAP = {
  'product-list-v2': '/api/productList/info/get',
  'search-product-list': '/api/productList/info/get',
  'shein-picks': '/api/productList/picksInfo/get',
  'picked-info-list': '/api/recProductList/recInfo/get',
  'feedback-rec-list': '/api/recProductList/recInfo/get',
  'discount-channel-list': '/api/discountChannelList/disInfo/get',
  'picked-products': '/api/discountChannelList/disInfo/get',
  'page_store': '/api/store/items/get',
}

// 属于泛列表的路由名字: 一般列表, 信息流列表 (包括大卡片), 实时反馈列表
const PRODUCT_LIST_ROUTE_NAME = [
  'product-list-v2',
  'search-product-list',
  'picked-info-list',
  'feedback-rec-list',
  // !不支持预请求，参数依赖 bff 接口
  // 'discount-channel-list',
  'shein-picks',
  'picked-products'
]

const NOT_CACHE_KEYS = ['scici', 'src_module', 'src_identifier', 'src_tab_page_id', 'new_src_tab_page_id', 'src_identifier_pre_search', '_type', '_show_daily', '_requestType']

const PRODUCT_LIST_REQUEST_URL_MAP = {
  // 搜索结果页
  SEARCH: {
    goods: '/product/get_products_by_keywords', // 商品数据
    filters: '/search/get_aggregation_filters', // 分类，属性，tsp筛选，图文导航，标签云
    bannerCard: '/search/card/non_category_by_keywords', // 搜索页顶部卡片
  },
  // 真实分类页
  REAL: {
    goods: '/category/real_category_goods_list', // 商品数据
    filters: '/category/real_category_attr_filter', // 分类，属性，tsp筛选
    cloudTag: '/category/real_category_tags', // 标签云
  },
  // 选品分类页
  SELECT: {
    goods: '/category/get_select_product_list', // 商品数据
    filters: '/category/select_category_attr_filter', // 分类，属性，tsp筛选
    cloudTag: '/category/select_category_tags', // 标签云

  },
  SELLING: {
    goods: '/category/get_tags_goods_list', // 商品数据
    filters: '/category/get_tags_goods_list_filter', // 分类，属性，tsp筛选
    cloudTag: '/category/selling_category_tags', // 标签云
  },
  // 信息流落地页
  PICKED: {
    goods: '/product/recommend/information_flow_selected_products', // 商品数据
    filters: '/product/recommend/information_flow_selected_filter', // 分类，属性，tsp筛选
    cloudTags: '/product/recommend/information_flow_selected_tags', // 标签云
  },
  // shein picks
  SHEIN_PICKS: {
    goods: '/category/get_products_by_discount_list',  // 商品数据
    filters: '/category/get_discount_list_filter',   // 分类，属性，tsp筛选
    cloudTag: '/category/discount_category_tags',   // 标签云
    navTabs: '/category/get_discount_cat',  // tab数据
  },
  USER_BEHAVIOR: {
    goods: '/category-api/recommend/get-user-behavior-products', // 商品数据
  },
  FEEDBACK_REC: {
    goods: '/product/recommend/feedback_products', // 商品数据
    filters: '/product/recommend/feedback_filter', // 分类，属性，tsp筛选
    cloudTags: '/product/recommend/feedback_tags', // 标签云
  },
  DAILY_NEW: {
    goods: '/category/category_daily_new', // 商品数据
    filters: '/category/get_daily_new_filter', // 分类，属性，tsp筛选
    cloudTags: '/category/daily_new_category_tags', // 标签云
  },
  SELLING_POINT: {
    goods: '/category/get_tags_goods_list', // 商品数据
    filters: '/category/get_tags_goods_list_filter', // 分类，属性，tsp筛选
    cloudTags: '/category/selling_category_tags', // 标签云
  },
  COMMON: {
    categoryDay: '/category/select_category_day',
    showRecentDays: '/category/v2/category_daily_show_recent_days',
    picNav: '/category/nav_tab_index', // 图文导航
    cccFeedback: '/ccc/communal/switch',
    buyboxEntrance: '/recommend/buybox/buybox_entrance',  // buybox入口
    rankListComponent: '/category/rank_list_component', // 榜单坑位请求
    surveyComponent: '/category/position/voucher_survey', // 问卷坑位请求
    cccCommonComponent: '/ccc/common_component', // 公共组件
    discountChannelComp: '/recommend/discount/channel_component',
    cccSeo: '/category-api/web/ccc-seo',
    cccOperation: '/category-api/web/ccc-operation',
    sheinClubInfo: '/user-api/prime/info',
    breadcrumb: '/category-api/web/get-category-info', // 面包屑 parentCats
  },
  DISCOUNT: {
    goods: '/recommend/discount/channel_landing_page',
    tabs: '/recommend/discount/landing_page_discount_tabs',
  },
}

const ROUTE_REGEX = {
  search: /pdsearch\/([^\/]+)\/?$/,
  real: /-c-(\d+)\.html$/,
  select: /((\w+)\/)?[^/]+-sc-([A-Za-z0-9]+)\.html$/,
  selling: /sellingpoint-(\d+)-(\d+)-.+\.html$/,
  picked: /picked-info-products\.html$/,
  pickedProducts: /picked-products\.html$/,
  sheinPicks: /\/([a-z0-9]+-picks|romwe-[a-z]+|promo-discount)\.html$/,
  feedbackRec: /feedback-rec-products\.html$/,
  daily_new: /daily-new\.html$/,
}

export {
  PRODUCT_LIST_ROUTE_NAME,
  PRODUCT_LIST_API_URL_MAP,
  NOT_CACHE_KEYS,
  PRODUCT_LIST_REQUEST_URL_MAP,
  ROUTE_REGEX
}
