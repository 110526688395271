import schttp from 'public/src/services/schttp'

export function fetchUnderXPage(params) {
  return schttp({
    url: '/api/under-x/get-under-x',
    method: 'GET',
    params: {
      selectId: params?.selectId,
      realId: params?.realId,
      adp: params?.adp
    }
  })
}

export async function fetchUnderXProducts(params) {
  return schttp({
    url: '/api/under-x/list/query',
    method: 'GET',
    params: {
      priceRange: params?.priceRange,
      userPriceRange: params?.userPriceRange,
      realId: params?.realId,
      page: params?.page,
      limit: params?.limit,
      adp: params?.adp,
      selectId: params?.selectId,
      jsonRuleId: params?.jsonRuleId,
      filterOptions: {
        noABPriceGoods: true
      },
      productControls: {
        handlerUnitPrice: true,
      }
    }
  })
}

export function prefetchUnderXPageData(query) {
  const params = {
    selectId: query?.selectId,
    // realId: getQueryString('realId'),
    adp: query?.adp
  }

  const paramsChange = JSON.stringify(params) !== JSON.stringify(prefetchUnderXPageData.__params__)

  prefetchUnderXPageData.paramsChange = paramsChange

  // 参数不同，重新请求
  if (paramsChange) {
    prefetchUnderXPageData.__params__ = params
    window.__underXPageData__ = fetchUnderXPage(params)
  }

  // 无数据时，请求数据
  if (!window.__underXPageData__) {
    prefetchUnderXPageData.__params__ = params
    window.__underXPageData__ = fetchUnderXPage(params)
  }

  return window.__underXPageData__
}
