<template>
  <div class="bsc-gc-checkout">
    <SDrawer
      v-expose="{
        id: 'expose_giftcard_pop:simple',
      }"
      class="bsc-gc-checkout__drawer"
      append-to-body
      v-bind="props.drawerProps"
      @close-from-icon="onClose"
      @close-from-mask="onClose"
    >
      <template #top>
        <div
          class="bsc-gc-checkout__header"
          @click="() => emits('goScroll', 0)">
          <span class="title">{{ language?.SHEIN_KEY_PWA_24759 }}</span>
          <span class="subtitle">{{ language?.SHEIN_KEY_PWA_35098 }}</span>
        </div>
      </template>
      <TabList
        ref="tabListRef"
        :is-show-no-card-icon="!isAvailableCard && cardList?.length === 0"
        @updateTabIndex="updateTabIndex"
        @updateCardList="updateCardList"
      >
        <AddCard
          ref="addCardRef"
          :abtType="props?.extraData?.abtType"
          :isAvailableCard="isAvailableCard"
          :isEmptyCard="cardList.length === 0"
          @submit="handleBindCard"
        />
      </TabList>
      <div
        v-if="cardList.length > 0"
        class="bsc-gc-checkout__card--wrap"
        :style="{
          height: `calc(100% - ${props.extraData?.discountPriceHeight || 0})` // 解决 pwa 下单页价格未展示全问题
        }"
      >
        <div
          :class="['bsc-gc-checkout__card--block', props.scrollWrapClass]"
        >
          <Card
            v-for="(item, index) in cardList"
            :key="`${item.card_no}${item.card_status_tip}${index}`"
            v-tap="{
              id: 'click_listgiftcard:simple',
              data: {
                sequence: index,
                is_available: isAvailableCard ? 1 : 0,
                is_linked: !!item?.bind_card_tip ? 1 : 0
              }
            }"
            v-expose="{
              id: 'expose_listgiftcard:simple',
              data: {
                sequence: index,
                is_available: isAvailableCard ? 1 : 0,
                is_linked: !!item?.bind_card_tip ? 1 : 0
              }
            }"
            :enableCopy="false"
            :isSelect="item.card_no === props?.extraData?.selectedNo"
            :enableGoDetail="false"
            :readonly="!isAvailableCard"
            :class="['card', props.scrollItemClass]"
            :item="item"
            cardHeight="3.3333rem"
            @click="() => onSelectedCard(item)"
          />
        </div>

        <div
          v-if="!!extraData?.discountPrice && isAvailableCard"
          class="bsc-gc-checkout__discount"
        >
          <span class="label">
            {{ language?.SHEIN_KEY_PWA_24759 }}
          </span>
          <span class="price">
            -{{ extraData.discountPrice }}
          </span>
        </div>
      </div>

      <template #footer>
        <slot name="footer"></slot>
      </template>
    </SDrawer>
  </div>
</template>
<script setup lang="ts">
import { inject, ref, computed, type Ref } from 'vue'
import { Drawer as SDrawer } from '@shein/sui-mobile'
import AddCard from './AddCard.vue'
import Card from './Card.vue'
import TabList from './TabList.vue'
import { type C_GiftCard } from '../../../types/index.ts'

const cardList = ref<C_GiftCard.CardItem[]>([])
const tabIndex = ref<number>(0)
const addCardRef: Ref = ref(null)

const props = defineProps<{
  drawerProps: Record<string, string>
  scrollWrapClass: string
  scrollItemClass: string,
  extraData?: Record<string, any>
}>()

const emits = defineEmits(['goScroll', 'custom-event'])

const language: C_GiftCard.language= inject('language')!

const isAvailableCard = computed(() => tabIndex.value === 0)

const updateCardList = (list) => {
  cardList.value = list
}
const updateTabIndex = (index: number) => {
  tabIndex.value = index
}

const onClose = () => {
  emits('custom-event', { key: 'close' })
}

const onSelectedCard = (item) => {
  emits('custom-event',
  {
    key: 'select',
    data: {
      item: item.card_no === props?.extraData?.selectedNo ? null : item,
    },
  })
}
const handleBindCard = (v) => {
  emits('custom-event', {
    key: 'bind-card',
    data: v,
  })
}

</script>

<style lang="less">
.bsc-gc-checkout {
  &__drawer {
    overflow: hidden;
    .S-drawer__body {
      overflow: hidden;
    }
    .S-drawer__header {
      min-height: auto;
    }
  }
  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: .24rem 0 .2133rem;
    .title {
      color: #767676;
      text-align: center;
      font-family: "SF Pro";
      font-size: .3733rem;
      font-style: normal;
      font-weight: 590;
      line-height: normal;
    }
    .subtitle {
      color: #959595;
      text-align: center;
      font-family: "SF Pro";
      font-size: .2667rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0 .32rem;
    }
  }
  &__card--wrap{
    padding: .32rem;
    position: relative;
    padding-bottom: .4rem;
  }
  &__card--block{
    position: relative;
    overflow-y: scroll;
    padding-bottom: .2667rem;
    height: 100%;
    .card {
      margin-bottom: .32rem;
    }
  }
  &__discount {
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: .2133rem .32rem;
    background-color: #fff;
    z-index: 10;
    .label {
      color: #222;
      font-size: .3733rem;
      font-weight: 700;
    }
    .price {
      color: #FA6338;
      text-align: right;
      font-size: .3733rem;
      font-weight: 700;
    }
  }
}
</style>
