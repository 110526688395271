
import schttp from 'public/src/services/schttp'
import { bffHttp } from './common'

const BASE_URL = '/api/user/wishlist_v2'

const http = async (data) => {
  let result = null
  try {
    const res = await schttp({
      ...data,
      url: data.url.includes(BASE_URL) ? data.url : BASE_URL + data.url
    })
    const { info, code } = res || {}
    if (typeof code === 'undefined' || code == 1002) { // 1002: 未登录， 400427: 超出限制
      result = res
    } else {
      result = `${code}` === '0' ? info : null
    }
  } catch(e) {
    console.warn(e, '接口错误', data)
  }
  return result
}

/* 获取收藏列表 */
export const getWishListsApi = (params, loadingConfig = {}) => {
  // /wishlist_v2/query routes/v2/api/user/wishlist.js
  return http({
    url: '/query',
    method: 'get',
    params,
    schttp: {
      loadingConfig
    }
  })
}

// 获取收藏同类推荐：（中间层融合BFF接口）
export const getBffWishListSameRecommendApi = async (data) => {
  return schttp({
    url: '/user/wishlist/same/recommend',
    method: 'post',
    data,
    useBffApi: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
// 获取筛选收藏列表接口：（中间层融合BFF接口）
export const getBffWishProductsApi = async (params, loadingConfig = {}) => {
  return schttp({
    url: '/user/get_wishlist_by_filter',
    method: 'get',
    params,
    useBffApi: true,
    schttp: {
      loadingConfig
    }
  })
}

// 获取筛选收藏的Filter参数列表接口：（中间层融合BFF接口）
export const getBffWishFiltersApi = async (params, loadingConfig = {}) => {
  return schttp({
    url: '/user/get_wishlist_filter',
    method: 'get',
    params,
    useBffApi: true,
    schttp: {
      loadingConfig
    }
  })
}

// 获取筛选收藏的付费会员信息接口：（中间层融合BFF接口）
export const getBffWishSheinClubApi = async (params, loadingConfig = {}) => {
  return schttp({
    url: '/user/get_wishlist_uvip_discount',
    method: 'get',
    params,
    useBffApi: true,
    schttp: {
      loadingConfig
    }
  })
}


// 批量删除收藏页商品
export const removeBatchWishApi = ({ wishlistIds = [] }) => {
  return bffHttp({
    url: '/user/multi_wishlist_remove',
    method: 'post',
    data: { wish_list_ids: wishlistIds.join(',') }
  })
}

// 获取当前商品收藏状态
export function getWishlistStatusApi() {
  return bffHttp({
    url: '/user/wishlist/status',
    method: 'post',
  })
}

// 添加收藏商品
export function addWishlistGoodsApi (data) {
  if (!data.goods_id) return Promise.resolve({
    code: -1,
    msg: 'miss params: goods_id'
  })

  return http({
    method: 'post',
    url: `/create`,
    data,
  })
}

// 获取收藏加强降价商品模块数据
// 获取收藏降价模块：（中间层融合BFF接口）
export const getBffWishPriceCutListApi = async (data) => {
  if(!data?.firstPage) {
    return
  }
  return schttp({
    url: '/user/wishlist/same/recommend',
    method: 'post',
    data: {
      page: 1,
      limit: 30,
      scene: 'priceReductionModule',
      // stockEnough: '1', //过滤库存
      // reducePrice: '1'  // 降价筛选
    },
    useBffApi: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}


/**
 * 个人中心-足迹用
 * 接口文档： https://soapi.sheincorp.cn/application/1406/versions/test39/post_user_product_recent_visit_add_stock_is_sold_out/doc
 * @param {*} data 
 * goodIds 商品id,逗号分割	
 * mall_code_list 逗号分割
 * skc 逗号分割，	
 * @returns 
 */
export const getBffRecentVisitProductInfoApi = async (data) => {
  return schttp({
    url: '/user/product/recent_visit/add_stock_is_sold_out',
    method: 'post',
    data,
    useBffApi: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
