import { ref, ComponentPublicInstance, computed, onBeforeUnmount } from 'vue'
import Vue from 'vue'
import type { C_BenefitFloor } from '../../../types'

const benefitDrawerComp = ref<ComponentPublicInstance | null>(null)
const benefitDrawerVisible = ref(false)

export const useBenefitDrawer = () => {

  const importComponent = async () => {
    if (benefitDrawerComp.value) {
      return benefitDrawerComp.value
    } else {
      const benefitDrawer = await import(
        /* webpackChunkName: "benefit-drawer" */ '../components/BenefitDrawer/Index.vue'
      ).catch(() => '')
      if (typeof benefitDrawer === 'string') return
      const instance = new Vue({
        render: h => h(benefitDrawer.default, {
          props: {
            visible: benefitDrawerVisible.value,
          },
          on: {
            'update:visible': visible => benefitDrawerVisible.value = visible,
          },
        }),
      })
      instance.$mount()
      document.body.appendChild(instance.$el)
      benefitDrawerComp.value = instance as any
      return instance
    }
  }

  const openDrawer = async ({ data, footer, drawerBottom, language }: {
    data: C_BenefitFloor.PopUpData,
    footer: any,
    drawerBottom?: string,
    language: C_BenefitFloor.LanguageType
  }) => {
    const instance = await importComponent() as any
    if (!instance) return
    const el = instance.$children[0]
    if (footer) el.$slots.footer = [footer()]
    el?.openDrawer?.({ data, bottom: drawerBottom, language })
    benefitDrawerVisible.value = true
  }

  const closeDrawer = () => {
    benefitDrawerVisible.value = false
  }

  const destroyDrawer = () => {
    closeDrawer()
    benefitDrawerComp.value?.$destroy?.()
    const componentEle = benefitDrawerComp.value?.$el
    if (componentEle && componentEle?.parentNode) {
      componentEle.parentNode.removeChild(componentEle)
    }
    benefitDrawerComp.value = null
  }

  onBeforeUnmount(() => {
    destroyDrawer()
  })

  return {
    openDrawer,
    closeDrawer,
    drawerVisible: computed(() => benefitDrawerVisible.value),
  }
}

export default useBenefitDrawer
