import { getDiscountGenerateBffParams } from '../bffParamsHandle/index.js'
import {
  goodsApiCache,
  discountPageInfoApiCache,
} from '../api/bffApi.js'
import { PRODUCT_LIST_REQUEST_URL_MAP } from '../constant.js'

const getDiscountRequestQueue = ({ requestInfo }) => {
  const {
    goodsParams,
    tabsParams
  } = getDiscountGenerateBffParams(requestInfo)

  return {
    // 商品列表
    goods: goodsApiCache.request({
      url: PRODUCT_LIST_REQUEST_URL_MAP.DISCOUNT.goods,
      params: goodsParams,
      method: 'GET',
      eventName: 'api_request_discount_goods',
    }),
    // tabs
    tabs: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, null)
      const result = await discountPageInfoApiCache.request({
        url: PRODUCT_LIST_REQUEST_URL_MAP.DISCOUNT.tabs,
        params: tabsParams,
        method: 'GET',
        eventName: 'api_request_discount_tabs',
      })
      cb(null, result)
    },
  }
}

export {
  getDiscountRequestQueue
}
