<template>
  <section
    class="index-footer mshe-z-header j-index-footer"
    :data-aaa="tabActive || ''"
    :style="tabColor"
  >
    <template v-if="footerData">
      <a
        v-tap="{
          id: '2-15-1',
          data: {
            index: 1,
            tab_name: 'Shop'
          },
        }"
        :data-href="homeInfo.href"
        class="ft-li j-index-tab-list j-index-tab-list-home waves-btn waves-btn-auto waves-effect waves-classic"
        :class="homeInfo.class"
        tabindex="0"
        role="link"
        :aria-label="homeInfo.label"
        data-title="home"
      >
        <template v-if="homeInfo.defaultImgSrc && homeInfo.selectedImgSrc">
          <div class="index-footer__ccc-skin-warp">
            <div
              tabindex="-1"
              aria-hidden="true"
              role="none"
              class="index-footer__ccc-skin-icon"
              :style="{
                backgroundImage: `url(${homeInfo.defaultImgSrc})`
              }"
            ></div>
            <div
              tabindex="-1"
              aria-hidden="true"
              role="none"
              class="index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active"
              :style="{
                backgroundImage: `url(${homeInfo.selectedImgSrc})`
              }"
            ></div>
          </div>
        </template>
        <template v-else>
          <div class="tabbar-icon">
            <sui_icon_tab_shop_30px size="26px" />
          </div>
          <div class="tabbar-icon_active">
            <sui_icon_tab_shop_selected_30px size="26px" />
          </div>
        </template>
        {{ homeInfo.label }}
      </a>
      <a
        v-tap="{
          id: '2-15-1',
          data: {
            index: 2,
            tab_name: 'Category'
          },
        }"
        :data-href="categoryInfo.href"
        class="ft-li j-index-tab-list j-index-tab-list-category waves-btn waves-btn-auto waves-effect waves-classic"
        :class="categoryInfo.class"
        data-title="category"
        tabindex="0"
        role="link"
        :aria-label="categoryInfo.label"
      >
        <template v-if="categoryInfo.defaultImgSrc && categoryInfo.selectedImgSrc">
          <div class="index-footer__ccc-skin-warp">
            <div
              tabindex="-1"
              aria-hidden="true"
              role="none"
              class="index-footer__ccc-skin-icon"
              :style="{
                backgroundImage: `url(${categoryInfo.defaultImgSrc})`
              }"
            ></div>
            <div
              tabindex="-1"
              aria-hidden="true"
              role="none"
              class="index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active"
              :style="{
                backgroundImage: `url(${categoryInfo.selectedImgSrc})`
              }"
            ></div>
          </div>
        </template>
        <template v-else>
          <div class="tabbar-icon">
            <sui_icon_tab_category_30px size="26px" />
          </div>
          <div class="tabbar-icon_active">
            <sui_icon_tab_category_selected_30px size="26px" />
          </div>
        </template>
        {{ categoryInfo.label }}
      </a>
      <a
        v-tap="{
          id: '2-15-1',
          data: {
            index: 3,
            tab_name: 'New'
          },
        }"
        :data-href="newInfo.href"
        class="ft-li j-index-tab-list j-index-tab-list-activity waves-btn waves-btn-auto waves-effect waves-classic"
        :class="newInfo.class"
        data-title="new"
        tabindex="0"
        role="link"
        :aria-label="newInfo.label"
      >
        <template v-if="newInfo.defaultImgSrc && newInfo.selectedImgSrc">
          <div class="index-footer__ccc-skin-warp">
            <div
              tabindex="-1"
              aria-hidden="true"
              role="none"
              class="index-footer__ccc-skin-icon"
              :style="{
                backgroundImage: `url(${newInfo.defaultImgSrc})`
              }"
            ></div>
            <div
              tabindex="-1"
              aria-hidden="true"
              role="none"
              class="index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active"
              :style="{
                backgroundImage: `url(${newInfo.selectedImgSrc})`
              }"
            ></div>
          </div>
        </template>
        <template v-else>
          <div class="tabbar-icon">
            <sui_icon_tab_new_30px size="26px" />
          </div>
          <div class="tabbar-icon_active">
            <sui_icon_tab_new_selected_30px size="26px" />
          </div>
        </template>
        {{ newInfo.label }}
      </a>
      <Cart
        ref="refCart"
        :bag-info="bagInfo"
        :footer-data="footerData"
      />
      <a
        v-tap="{
          id: '2-15-1',
          data: {
            index: 5,
            tab_name: 'Me'
          },
        }"
        :data-href="meInfo.href"
        class="ft-li j-index-tab-list j-index-tab-list-user waves-btn waves-btn-auto waves-effect waves-classic"
        :class="meInfo.class"
        tabindex="0"
        role="link"
        :aria-label="meInfo.label"
        @click="toUserIndexPage"
      >
        <template v-if="meInfo.defaultImgSrc && meInfo.selectedImgSrc">
          <div class="index-footer__ccc-skin-warp">
            <div
              tabindex="-1"
              aria-hidden="true"
              role="none"
              class="index-footer__ccc-skin-icon"
              :style="{
                backgroundImage: `url(${meInfo.defaultImgSrc})`
              }"
            ></div>
            <div
              tabindex="-1"
              aria-hidden="true"
              role="none"
              class="index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active"
              :style="{
                backgroundImage: `url(${meInfo.selectedImgSrc})`
              }"
            ></div>
          </div>
        </template>
        <template v-else>
          <div class="tabbar-icon">
            <sui_icon_tab_me_30px size="26px" />
          </div>
          <div class="tabbar-icon_active">
            <sui_icon_tab_me_selected_30px size="26px" />
          </div>
        </template>
        {{ meInfo.label }}
      </a>
      <div class="j-sidebar-icon-mask"></div>
    </template>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { daEventCenter } from '@/public/src/services/eventCenter'
import {
  sui_icon_tab_shop_30px,
  sui_icon_tab_shop_selected_30px,
  sui_icon_tab_category_30px,
  sui_icon_tab_category_selected_30px,
  sui_icon_tab_new_30px,
  sui_icon_tab_new_selected_30px,
  sui_icon_tab_me_30px,
sui_icon_tab_me_selected_30px,
} from '@shein-aidc/icon-vue2'

import Cart from './cart/index.vue'
import { markPoint } from 'public/src/services/mark/index.js'

daEventCenter.addSubscriber({ modulecode: '2-15-1' })

const getRGBA = (color, defaultColor) => {
  const { r, g, b, a } = color || {}
  if(r && g && b && a){
    return `rgba(${r},${g},${b},${a})`
  }
  return defaultColor
}

export default {
  name: 'AppFooter',
  components: {
    Cart,
    sui_icon_tab_shop_30px,
    sui_icon_tab_shop_selected_30px,
    sui_icon_tab_category_30px,
    sui_icon_tab_category_selected_30px,
    sui_icon_tab_new_30px,
    sui_icon_tab_new_selected_30px,
    sui_icon_tab_me_30px,
    sui_icon_tab_me_selected_30px,
  },
  computed: {
    ...mapState('footer', [
      'footerData'
    ]),
    tabColor(){
      const { cccSkinFooterColorDefault, cccSkinFooterColorSelected } = this.footerData || {}
      return {
        '--footer-tab-color': getRGBA(cccSkinFooterColorDefault, '#767676'),
        '--footer-tab-active-color': getRGBA(cccSkinFooterColorSelected, '#222')
      }
    },
    tabActive() {
      return this.footerData?.tabActive || ''
    },
    homeInfo() {
      const { langPath, language, tabActive, cccSkinFooterIcon } = this.footerData || {}
      return {
        href: `${langPath}/`,
        class: tabActive == 'index' && 'title-active',
        label: language?.SHEIN_KEY_PWA_23957,
        defaultImgSrc: cccSkinFooterIcon?.[0]?.defaultImgSrc,
        selectedImgSrc: cccSkinFooterIcon?.[0]?.selectedImgSrc,
      }
    },
    categoryInfo() {
      const { langPath, language, tabActive, cccSkinFooterIcon } = this.footerData || {}
      return {
        href: `${langPath}/category`,
        class: tabActive == 'category' && 'title-active',
        label: language?.SHEIN_KEY_PWA_14980,
        defaultImgSrc: cccSkinFooterIcon?.[1]?.defaultImgSrc,
        selectedImgSrc: cccSkinFooterIcon?.[1]?.selectedImgSrc,
      }
    },
    newInfo() {
      const { langPath, language, tabbarNew, cccSkinFooterIcon } = this.footerData || {}
      const SKIP_TYPE_MAP = {
        'activity': 'campaign', // 旧专题
        'activityNew': 'campaigns' // 新专题
      }
      const campaignPath = SKIP_TYPE_MAP[tabbarNew?.skipType] || 'campaign'
      const { query, name } = this.$route || {}
      const isNewTab = query?.from_tabbar_new === '1' && name === 'campaigns'
      return {
        href: `${langPath}/${campaignPath}/${tabbarNew?.jump_url || `${langPath}/`}?from_tabbar_new=1`,
        class: isNewTab && 'title-active',
        label: language?.SHEIN_KEY_PWA_15661,
        defaultImgSrc: cccSkinFooterIcon?.[2]?.defaultImgSrc,
        selectedImgSrc: cccSkinFooterIcon?.[2]?.selectedImgSrc,
      }
    },
    bagInfo() {
      const { langPath, language, tabActive, cccSkinFooterIcon } = this.footerData || {}
      return {
        href: `${langPath}/cart?b=m&f=btb`,
        class: tabActive == 'cart' && 'title-active',
        label: language?.SHEIN_KEY_PWA_16035,
        defaultImgSrc: cccSkinFooterIcon?.[3]?.defaultImgSrc,
        selectedImgSrc: cccSkinFooterIcon?.[3]?.selectedImgSrc,
      }
    },
    meInfo() {
      const { langPath, language, tabActive, cccSkinFooterIcon } = this.footerData || {}
      return {
        href: `${langPath}/user/index?page_from_me=1`,
        class: tabActive == 'userPage' && 'title-active',
        label: language?.SHEIN_KEY_PWA_16036,
        defaultImgSrc: cccSkinFooterIcon?.[4]?.defaultImgSrc,
        selectedImgSrc: cccSkinFooterIcon?.[4]?.selectedImgSrc,
      }
    }
  },
  mounted() {
    window._gb_footer_ = this
    if (!this.footerData?.isSSR) {
      this.updateFooterData({
        isSSR: false,
        ...window._footer_data_
      })
    }
  },
  methods: {
    ...mapMutations('footer', ['updateFooterData']),
    markPoint,
    toUserIndexPage() {
      markPoint('toNextPageClick', 'public')
      window.appEventCenter?.$emit('clickFooterUserIndexEntry')
    },
    // getTabStyle(tabName) {
    //   const { tabActive } = this
    //   const { cccSkinFooterColorDefault: {
    //     r: dR,
    //     g: dG,
    //     b: dB,
    //     a: dA,
    //   } = {}, cccSkinFooterColorSelected: {
    //     r: sR,
    //     g: sG,
    //     b: sB,
    //     a: sA,
    //   } = {} } = this.footerData || {}
    //   if (tabActive === tabName) {
    //     return {
    //       color: `rgba(${sR},${sG},${sB},${sA})`
    //     }
    //   } else {
    //     return {
    //       color: `rgba(${dR},${dG},${dB},${dA})`
    //     }
    //   }
    // },
    clear() {
      this.$refs.refCart.endtime()
    },
  }
}
</script>

<style lang="less">
.index-footer {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  width: 10rem;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 50px;
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  border-top: 1px solid #ccc;
  background-color: #fff;
  font-size: 12px;
  .zindex-translatez(13, translate3d(0, 0, 13px));
}
.no-fullscreen .index-footer {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.ipx-fullscreen .index-footer {
  padding-bottom: 34px;
}
.index-footer__ccc-skin-warp {
  position: relative;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  width: 26px;
  height: 26px;
}
.index-footer__ccc-skin-warp > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
.index-footer__ccc-skin-icon {
  visibility: visible;
}
.index-footer__ccc-skin-icon_active {
  visibility: hidden;
}
.index-footer .ft-li.title-active .index-footer__ccc-skin-icon { /* stylelint-disable-line */
  visibility: hidden;
}
.index-footer .ft-li.title-active .index-footer__ccc-skin-icon_active { /* stylelint-disable-line */
  visibility: visible;
}
.index-footer .ft-li {
  height: 47px;
  padding-top: 3px;
  position: relative;
  display: inline-block;
  width: 25%;
  text-align: center;
  color: var(--footer-tab-color);
  text-decoration: none;
  // svg 会被外层的color覆盖，需要单独设置
  .tabbar-icon {
    color: #767676;
  }
  .tabbar-icon_active {
    color: #222;
  }
}
.index-footer .ft-li.title-active {
  color: var(--footer-tab-active-color);
}

.index-footer .ft-li.title-active .cart-num { /* stylelint-disable-line */
  background: #E64545;
}
.index-footer .ft-li.title-active .tabbar-icon { /* stylelint-disable-line */
  display: none;
}
.index-footer .ft-li.title-active .tabbar-icon_active { /* stylelint-disable-line */
  display: block;
  height: 26px;
}
.index-footer .ft-li .cart-num {
  position: absolute;
  top: 0.1rem;
  min-width: 0.4rem;
  height: 0.4rem;
  padding: 0.1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 10px;
  display: block;
  border-radius: 0.4rem;
  text-align: center;
  line-height: 0.2rem;
  background: #FE3B30;
  color: #fff;
}
[mir=ltr] .index-footer .ft-li .cart-num { /* stylelint-disable-line */
  left: 1.3rem;
}
[mir=rtl] .index-footer .ft-li .cart-num { /* stylelint-disable-line */
  right: 1.3rem;
}
.index-footer .ft-li .tabbar-icon {
  display: block;
  height: 26px;
}
.index-footer .ft-li .tabbar-icon_active {
  display: none;
}
</style>
