/**
 * 推荐
 */
const mutations = {
  updateShowNewGoodsRecommend(state, payload) {
    state.recommendState.showNewGoodsRecommend = payload
  },
  updateNewRecommendInfo(state, payload) {
    state.recommendState.newRecommendInfo = payload
  },
  updateIsShowNewGoodsRecommendPopover(state, payload) {
    state.recommendState.isShowNewGoodsRecommendPopover = payload
  }
}

export default mutations
