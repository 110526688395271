<template>
  <li
    v-enterkey
    class="c-new-giftcard"
    tabindex="0"
    @click.stop="handleOpen"
  >
    <div class="bar__wrap">
      <span class="bar__left">
        {{ language.SHEIN_KEY_PWA_15846 }}
      </span>
      <div class="bar__right">
        <template v-if="selectedItem">
          <div class="info">
            <span class="info__price">-{{ getDiscountPrice }}</span>
            <span class="info__balance">{{ getBalancePrice }}</span>
          </div>
        </template>
        <template v-else>
          <span class="tip">{{ getNewGiftCard?.guide_tip }}</span>
          <div 
            v-if="+getNewGiftCard?.red_dot === 1 && isShowRedDot"
            class="dot" 
          ></div>
        </template>
        <Icon
          :name="!GB_cssRight ? 'sui_icon_more_right_16px' : 'sui_icon_more_left_16px'"
          class="icon-arrow"
          color="#959595"
        />
      </div>
    </div>

    <!-- giftCard 滑出层 -->
    <ClientOnly>
      <BGiftCard
        v-if="apiCardsMap"
        ref="giftCardRef"
        :drawer-props="{
          size: '80%',
          visible: isShowNewGiftCardDrawer,
          modal: false,
          style: { bottom: `${footerHeight}px` },
        }"
        :extraData="{
          discountPrice: getDiscountPrice,
          selectedNo: selectedItem?.card_no,
          abtType: +getNewGiftCard?.gift_card_abt,
          discountPriceHeight: '2.75rem'
        }"
        scene="checkout"
        :card-data="getCardsMap"
        @custom-event="onCustomEvent"
      />
    </ClientOnly>
  </li>
</template>

<script setup>
/* globals shem_caculate_data */
import { ref, computed } from 'vue'
import { BGiftCard } from '@shein-aidc/bs-gift-card-mobile-vue2'
import ClientOnly from 'vue-client-only'
import { useMapActions, useMapMutations } from '@/public/src/pages/checkout/hooks'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { Icon } from '@shein-aidc/icon-vue2'
import { Toast } from '@shein/sui-mobile'
import verifyManager from '@shein-risk/si_verify'
import schttp from 'public/src/services/schttp'
import { template } from '@shein/common-function'
import { handleLimitGoods } from '../../config/tools'
// import Footer from '../../../../footer/Index.vue'

const { GB_cssRight } = gbCommonInfo || {}
verifyManager.init({
  axiosInstance: schttp, 
})

const { checkout, language, price, footerHeight, isShowNewGiftCardDrawer } = useMapState(['checkout', 'language', 'price', 'footerHeight', 'isShowNewGiftCardDrawer'])
const { mallCacuTotal, getNewGiftCardInfo } = useMapActions(['mallCacuTotal', 'getNewGiftCardInfo'])
const { assignState, changeParamsStatus } = useMapMutations(['assignState', 'changeParamsStatus'])

const getCardsMap = computed(() =>  ({
  available_card_list: [].concat(cardCache.value, apiCardsMap.value?.available_card_list).filter(Boolean),
  unavailable_card_list: [].concat(apiCardsMap.value?.unavailable_card_list).filter(Boolean),
}))
const getNewGiftCard = computed(() => checkout.value?.newGiftcard)
const getDefaultAddress = computed(() => checkout.value?.default_address)

const getDiscountPrice = computed(() => {
  return price.value?.giftcard?.giftCardUsedPrice?.amountWithSymbol || ''
})

const getBalancePrice = computed(() => template(selectedItem.value?.balance?.amountWithSymbol, language.value.SHEIN_KEY_PWA_35106))

const apiCardsMap = ref(null)
const selectedItem = ref(null)
const cardCache = ref([])
const giftCardRef = ref(null)
const isShowRedDot = ref(true)
const isLoading = ref(false)

const getCardListData = async() => {
  isLoading.value = true
  const countryId = await UserInfoManager.get({ key: 'Country', actionType: 'getCardList' })?.countryId || getDefaultAddress.value?.countryId

  const res = await schttp({
    url: '/user-api/gift_card/query',
    method: 'POST',
    data: {
      query_zero_balance: 1,
      country_id: countryId,
      scene: 'checkout'
    },
    useBffApi: true
  })
  isLoading.value = false
  return res?.info || {}
}

const getGiftLimitDrawer = () => {
  const { giftCardLimitGoods = [] } = handleLimitGoods({ mallStoreInfos: checkout?.value.mall_caculate_info?.mall_store_infos || [], carts: checkout.value?.results?.carts?.carts || [] })
  if (giftCardLimitGoods.length) {
    assignState({
      giftCardLimitGoods: giftCardLimitGoods,
    })
    changeParamsStatus({
      type: 'freezTipsAndGoods',
      params: {
        show: true,
        type: 'giftCard'
      }
    })
  }
}

const updateCheckoutPrice = ({ cardNo = '', cardPin = '' }) => 
  new Promise(resolve => {
    isLoading.value = true
    shem_caculate_data.gift_card_no = cardNo
    shem_caculate_data.gift_card_pin = cardPin
    shem_caculate_data.use_card_scene = !!cardPin ? 0 : 1
    mallCacuTotal({
      opt: {
        ...shem_caculate_data,
      },
      loadingInfo: {
        show: true
      },
      cb: async(res) => {
        console.log('🐬🐬🐬 es: 计价 res', res)
        const { msg, code } = res
        const { cardInfo } = await getNewGiftCardInfo({ cardNo: cardNo, cardPin: cardPin })
        if (+code === 0) {
          cardInfo && onSelectCard(cardInfo, true)
          getGiftLimitDrawer()
          closeBindCardDrawer()
        } else if(+code === 300369){
          const { isAvailable } = onFindCard({ cardNo: cardInfo.card_no, skipValuation: true })
          Toast({ content: isAvailable ? language.value.SHEIN_KEY_PWA_35168 : language.value.SHEIN_KEY_PWA_35169 })
          closeBindCardDrawer()
        } else {
          msg && Toast({ content: msg })
        }
        isLoading.value = false
        resolve(res)
      }
    })
  })

const handleRisk = (resData) => {
  // 风控挑战（极验、短信、邮箱验证)
  const cb = res => {
    const { isSuccess, msg } = res
    if (Boolean(isSuccess)) {
      const ref = giftCardRef.value?.$refs.componentRef?.$refs.addCardRef?.$refs?.bindGiftCardRef
      if (ref) {
        ref.checkGiftCard({
          reskId: resData?.info?.risk_id,
          validate_token: resData?.info?.extend_info?.validate_token
        })
      }
    } else {
      Toast({ content: msg })
    }
    console.log('----result', res)
  }
  verifyManager.doVerify(resData?.info?.extend_info, cb)
}

const setVisible = (visible) => {
  assignState({
    isShowNewGiftCardDrawer: visible,
  })
}

const handleOpen = async() => {
  apiCardsMap.value = await getCardListData()
  giftCardRef.value?.goTab(0)
  setVisible(true)
  isShowRedDot.value = false
  document.body.style.overflow = 'hidden'
}

const onFindCard = ({ cardNo, tabIndexs = [0, 1], skipValuation = false }) => {
  const { available_card_list = [], unavailable_card_list = [] } = getCardsMap.value
  for(let i = 0; i < tabIndexs.length; i++) {
    const list = i === 0 ? available_card_list : unavailable_card_list
    const index = list.findIndex(item => item.card_no === cardNo)
    if (index !== -1) {
      giftCardRef.value?.goTab(i)
      giftCardRef.value?.goScroll(index)
      i === 0 && onSelectCard(list[index], skipValuation)
      return {
        isFind: true,
        isAvailable: i === 0
      }
    }
  }
  return {
    isFind: false,
    isAvailable: false
  }
}

const onBindCard = async(res) => {
  const { card_no, card_pin = '', isBindCard = false, resData = {} } = res
  if (isBindCard) {
    const { code, msg, info = {} } = resData
    msg && Toast({ content: msg })
    if (+code === 0){
      apiCardsMap.value = await getCardListData() 
      cardCache.value = cardCache.value.filter((item => item.card_no !== info.card_no))
      onFindCard({ cardNo: info.card_no })
    } else if (+code === 331802) { // 已经被用户绑定
      onFindCard({ cardNo: card_no })
    } else if (+code === 331809 && info?.extend_info != null ) {
      handleRisk(resData)
    }
  } else {
    const { msg, cardInfo } = await getNewGiftCardInfo({ cardNo: card_no, cardPin: card_pin })
    Toast({ content: msg })
    if (cardInfo) {
      cardCache.value = cardCache.value.filter((item => item.card_no !== cardInfo.card_no))
      const { isFind } = onFindCard({ cardNo: cardInfo.card_no, tabIndexs: [0] })
      if (!isFind) {
        cardCache.value.unshift(cardInfo)
        onSelectCard(cardInfo)
      }
    }
  }
}

const closeBindCardDrawer = () => {
  // 绑卡成功关闭绑卡弹窗
  const ref = giftCardRef.value?.$refs.componentRef?.$refs.addCardRef
  if (ref) {
    ref?.closeDrawer()
  }
}
  
const onSelectCard = async(item, skipValuation = false) => {
  console.log('🐬🐬🐬 es: select card', item)
  const { card_no, card_pin } = item || {}
  if (skipValuation) {
    selectedItem.value = item
  } else {
    const { code } = await updateCheckoutPrice({
      cardNo: card_no,
      cardPin: card_pin
    })
    if (+code === 0) {
      selectedItem.value = item
    }
  }
}

const onClose = () => {
  document.body.style.overflow = ''
  setVisible(false)
}

const onCustomEvent = ({ key, data = {} } = {}) => {
  console.log('🐬🐬🐬 es: onCustomEvent', key, data)
  if (isLoading.value) return
  switch(key) {
    case 'select':
      onSelectCard(data.item)
      return
    case 'bind-card':
      onBindCard(data)
      break
    case 'close':
      onClose()
      break
    default:
      console.log('no event')
  }
}

</script>


<style lang="less">
.c-new-giftcard {
  .bar__wrap {
    display: flex;
    justify-content: space-between;
  }
  .bar__left {
    color: #000;
    font-size: .3733rem;
    white-space: nowrap;
    vertical-align: middle;
  }
  .bar__right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: #767676;
    margin-right: .1333rem;
    .info {
      display: flex;
      flex-direction: column;
      &__price {
        color: #FA6338;
        text-align: right;
        font-size: .3733rem;
        font-weight: 700;
        line-height: 1.2;
      }
      &__balance {
        color: #767676;
        font-size: .3733rem;
        font-weight: 400;
        line-height: 1.2;
      }
    }
    .tip {
      font-size: .3733rem;
      font-weight: 400;
    }
    .dot {
      margin-left: .1067rem;
      border: .094rem solid #FA6338;
      border-radius: 50%;
      width: .1867rem;
      height: .1867rem;
    }

  }
  &__header {
    display: flex;
    flex-direction: column;
    padding: 0 .32rem;
    margin-bottom: .2133rem;
    .title {
      color: #767676;
      text-align: center;
      font-size: .3733rem;
      font-weight: 600;
    }
    .subTitle {
      line-height: 1.1;
      color: #959595;
      text-align: center;
      font-size: .2667rem;
      font-weight: 400;
    }
  }
}
</style>

