<template>
  <div
    class="advertising-recommend-list j-da-event-box"
    :da-expose-code="exposeCode"
    data-active-from="rec_goods_list"
    data-module="1"
    :data-tab="tab"
    :data-poskey="`AdLandingRecommendCate${recSwitchCate ? '' : 'Json'},AdLandingRecommendOther${recSwitchOther ? '' : 'Json'},AdLandingRecommend`"
  >
    <WaterFall
      :items="products"
    >
      <template 
        slot="default"
        slot-scope="{ item, index }"
      >
        <!-- 这里设置的dataSet是给钩子商品使用的： data-active-from -->
        <div
          class="advertising-recommend-list__item"
          :class="{
            'j-da-event-box': item.isHookGood,
          }"
          data-active-from="goods_list"
          data-module="1"
          :data-tab="tab"
          :data-poskey="`AdLandingRecommendCate${recSwitchCate ? '' : 'Json'},AdLandingRecommendOther${recSwitchOther ? '' : 'Json'},AdLandingRecommend`"
        >
          <ProductItem
            :ref="`goodsItem_${item.goods_id}`"
            :key="`${item.goods_id}-${index}`"
            :style="{
              '--product-card-radius': item.isHookGood ? 0 : '4px'
            }"
            :config="getProductItemConfig(item)"
            :item="item"
            :index="index"
            :constant-data="constantData"
            :language="localLanguage"
            is-water-fall-list
            show-selling-points-on-label
            @openQuickAdd="handleOpenQuickAdd"
          >
            <template #imgTop>
              <div
                v-if="item.isHookGood"
                class="recently-viewed__banner"
              >
                <i class="suiiconfont sui_icon_hot_12px"></i>
                <span>{{ localLanguage.SHEIN_KEY_PWA_25653 }}</span>
              </div>
            </template>
          </ProductItem>
        </div>
      </template>
    </WaterFall>
  </div>
</template>
<script>
import { storeModuleName } from '../../../product_app/store/modules/advertising'
import { mapState, mapGetters } from 'vuex'
import WaterFall from 'public/src/pages/components/product/WaterFall'
import ProductItem from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { HOOK_GOOD_CLICK_ID, HOOK_GOOD_EXPOSE_ID } from '../../analysis/index'
import { debounce } from '@shein/common-function'
import { getQuickAddCartInstance, canUseNewQuickAdd } from 'public/src/pages/components/product/quick_add_v3/common.js'


let daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'RecommendList',
  components: {
    WaterFall,
    ProductItem
  },
  
  props: {
    products: {
      type: Array,
      default() {
        return []
      }
    },
    itemConfig: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default: () => ({})
    },
    tab: {
      type: Number,
      default: 0
    },
    pageType: {
      type: String,
      default: 'A'
    },
    constantData: {
      type: Object,
      default: () => ({})
    }
  },
  provide() {
    return {
      isProductPage: true, // 向商卡传递该变量 否则商卡前6张图片会触发懒加载
    }
  },
  inject: ['advertisingAnalysis'],
  data() {
    return {
      exposeCode: 'advertisingRecommendList',
      localLanguage: Object.freeze(this.language)
    }
  },
  computed: {
    ...mapState(storeModuleName, ['hooksGoods', 'suggestedSalePriceType']),
    ...mapGetters(storeModuleName, ['recSwitchOther', 'recSwitchCate']),
    listItemConfig() {
      return {
        ...this.itemConfig,
        sa: {
          ...this.itemConfig.sa,
          activity_from: 'rec_goods_list',
          tab: `${this.tab}`,
          code: this.exposeCode
        }
      }
    }
  },
  watch: {
    products: {
      handler(val) {
        if (!val.length) return
        // 声明式曝光埋点
        const time = setTimeout(() => {
          clearTimeout(time)
          val.forEach(_ => {
            // 钩子商品另外上报
            // if (_.isHookGood) return 
            const goodsId = _.goods_id
            const itemInstance = this.$refs?.[`goodsItem_${goodsId}`]
            itemInstance?.setElAttr()
          })
          const { itemDAEventExposeId } = this.itemConfig || {}
          if (itemDAEventExposeId && daEventExpose) {
            this.asyncFunc(() => daEventExpose.subscribe({
              keycode: `${this.exposeCode}\`${itemDAEventExposeId},${this.exposeCode}\`${HOOK_GOOD_EXPOSE_ID}`,
              type: 'list',  // 当前code是属于列表类型的吗
            }), 200)
          }
        })
      },
      immediate: true
    },
  },
  created() {
    this.quickAddInstance = null
  },
  destroyed() {
    this.quickAddInstance = null
  },
  methods: {
    // 打开快速加车

    async handleOpenQuickAdd({ item, index, target }) {
      const status = canUseNewQuickAdd('list')
      if (status) {
        const { goods_id, mall_code, goods_img, isHookGood, mobileVerticalView } = item
        getQuickAddCartInstance().open({
          goods_id,
          mallCode: mall_code,
        }, {
          featureConfig: {
            showEstimatedPrice: !Boolean(this.suggestedSalePriceType),
            needClickToDetail: true
          },
          extendedParam: {
            mainImg: goods_img,
          },
          analysisConfig: {
            code: 'advertisingRecommendList',
            sourceTarget: target || null,
            sa: {
              mobileVerticalView,
              activity_from: isHookGood ? 'goods_list' : 'rec_goods_list',
            },
            
          },
          callbacks: {
            onAddCartComplete: params => {
              if (params.isSuccess) {
                this.debounceIt({
                  target: this.$refs?.[`goodsItem_${item.goods_id}`]?.$el
                })
              }
            },
          },
          plugins: []
        })
      } else {
        this.handleOpenQuickOld({ item, index, target }) // 推全后删除这个方法即可
      }
    },
    async handleOpenQuickOld({ item, index, target }) {
      const { goods_id, mall_code, goods_img, isHookGood, mobileVerticalView } = item
      this.quickAddInstance = await this.$quickAdd.open({
        index,
        goods_id,
        // lockmall: !!this.catInfo.lockMall,
        mallCode: mall_code,
        mainImg: goods_img,
        showBestDealLabel: true,
        showFollowBeltByOrigin: true,
        showEstimatedPrice: !Boolean(this.suggestedSalePriceType),
        needCartTagTipsUpdate: false, // TR-17838增加, 不需要快加车内部去触发利诱相关的update方法
        analysisConfig: {
          code: 'advertisingRecommendList',
          sourceTarget: target || null,
          sa: {
            mobileVerticalView,
            activity_from: isHookGood ? 'goods_list' : 'rec_goods_list',
          },
          
        }
      })

      if (this.quickAddInstance) {
        this.quickAddInstance?.$on('addToBagSuccess', this.debounceIt)
        // this.quickAddInstance?.$on('addToBagSuccess', ({ target }) => {
        //   this.$refs?.cartBag?.drop?.(target)
        //   this.quickAddInstance.$off('addToBagSuccess')
        // })
        // this.isOnAddToBagSuccess = true
      }
    },
    // 处理重复注册addToBagSuccess， 导致的问题
    debounceIt: debounce({ func: function ({ target }) {
      this.$refs?.cartBag?.drop?.(target)
      this.quickAddInstance && this.quickAddInstance.$off('addToBagSuccess')
    }, wait: 20 }),
    getProductItemConfig (item) {
      const config = { ...this.listItemConfig }

      if (item.isHookGood) {
        config.itemDAEventExposeId = HOOK_GOOD_EXPOSE_ID
        config.itemDAEventClickId = HOOK_GOOD_CLICK_ID
      }
      // 命中价格合规
      if(!!this.suggestedSalePriceType) {
        config.standardPrice = true
      }

      return config
    },
    resetAnalysis () {
      daEventExpose.reset(this.exposeCode)
    },
    asyncFunc (fn, timeout) {
      if ( typeof window?.requestIdleCallback === 'function' ) {
        requestIdleCallback(() => fn(), { timeout })
      } else {
        setTimeout(() => fn(), timeout)
      }
    }
  }
}
</script>
<style lang="less">
.advertising-recommend-list {
  padding: 0 .16rem;
  &__item {
    margin-bottom: .4267rem;
  }
}
.recently-viewed__banner {
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, #FFE3AD 0%, #ffffff 100%);
  color: #FA6338;
  font-size: .2667rem;
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: .0533rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  > i {
    margin: 0 .0533rem;
  }
}
</style>
