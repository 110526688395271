import type { AS_CheckoutPrice } from '../../../types'

const standard: AS_CheckoutPrice.AnalysisFuncs = {
  // TODO
  'click_your_sa_event_name.comp_checkout-price': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_price_detail.comp_checkout-price': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
