const en = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/10/16/3f/17290588455afccb09369939b05d893fb9c99db0a2.png',
  imgWidth: 44
}
const ar = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/10/16/6d/17290476591379f60e78f884a0376711d47c1b73a6.png',
  imgWidth: 37
}
const elgr = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/10/16/cf/17290500681a542a2ab0a517b02eddfa4195b9d321.png',
  imgWidth: 44
}
const fr = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/10/16/cf/1729047250b2df6677c0e97e5e37b71cc4caf9ccee.png',
  imgWidth: 62
}
const it = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/10/16/06/17290474588e1a3c5168fefff39d5c7e0a014782f7.png',
  imgWidth: 57
}
const ja = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/10/16/1a/1729050255cddf0fb89ee22453fb1e680af32bba72.png',
  imgWidth: 44
}
const pt = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/10/16/9b/1729046251a0655601d2958350c8d23e6e166d2ef9.png',
  imgWidth: 63
}
const ro = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/10/16/f2/17290503847d80eb9ac40b4bb173f1e3b783ecf70b.png',
  imgWidth: 54
}
const hu = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/07/25/28/1721893301bef08c1e225ffa02e980a4e07760d20b.png',
  imgWidth: 54
}
const bg = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/07/25/8e/172189330445d42a3e8959c2c204fce58e7f283aa6.png',
  imgWidth: 63
}
const sv = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/10/16/6b/17290581847ce9687d125a693f93c230ad1f490efb.png',
  imgWidth: 52
}
const plcsczsk = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/10/16/b2/1729048203d0e57221d38373d9cfc60dc1795b1139.png',
  imgWidth: 41
}
const th = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/12/26/fd/1735196018d39acff574f7870b7a7f78b57d2e9fc7.png',
  imgWidth: 48
}
const il = {
  imgUrl: '//img.ltwebstatic.com/images3_ccc/2024/12/11/a4/1733885888113a4b7775493d531200c2c8f0e9513f.png',
  imgWidth: 41
}
module.exports = {
  en,
  us: en,
  de: en,
  es: en,
  el: en,
  nl: en,
  ru: en,
  'en_au': en,
  'es-mx': en,
  'zh-tw': en,
  ko: en,
  pt,
  'pt-br': pt,
  'pt-pt': pt,
  'br': pt,
  fr,
  it,
  ar,
  pl: plcsczsk,
  'cs-cz': plcsczsk,
  'el-gr': elgr,
  ja,
  ro,
  uses: en,
  quqssk: plcsczsk,
  euqshu: hu,
  eurbg: bg,
  sk: plcsczsk,
  hu,
  bg,
  sv,
  sesv: sv,
  th,
  he: il,
}
// export default {
//   en: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/17107416807f90c9c39527ddae495ef0ed8998fa23.png',
//   de: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/17107416807f90c9c39527ddae495ef0ed8998fa23.png',
//   es: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/17107416807f90c9c39527ddae495ef0ed8998fa23.png',
//   pt: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/1710741680996cace2a617fddc46b1842116d5c432.png',
//   'pt-br': '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/1710741680996cace2a617fddc46b1842116d5c432.png',
//   'pt-pt': '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/1710741680996cace2a617fddc46b1842116d5c432.png',
//   fr: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/17107416800a3bc11f70d929699e41e63583cb0814.png',
//   it: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/1710741680c234cd53e604ea7ff1831e7b6af3db69.png',
//   ar: '//img.ltwebstatic.com/images3_ccc/2024/03/18/b3/17107417036d8cff8b0cdaa10559eaac02ce50dd37.png',
// }
