// 收藏商品项公共配置
const itemConfigCommon = {
  itemDAEventExposeId: '2-3-15',
  itemDAEventClickId: '2-3-1',
  sa: {
    from: 'wishlist',
    activity_from: 'wishlist',
    pageType: 'page_wishlist'
  },
  showTitle: true,
  showPromotion: true,
  showDeleteBtn: true,
  showAddBagBtn: true, // 加车按钮
  showReducePrice: true,
  showFindSimilarBtn: true,
  showPlusSize: true,
  showLeftStock: true,
  showSeriesBrand: true,
  showLocalSeller: true,
  showSelectOnLongClick: true,      // 长按选中
  // showSheinClubDiscountValue: true, // 付费会员 改造通过filter
  showNewFlashPriceOnSale: true,
  showNewFlashLabelUpPrice: true,
  showPromoLabel: true, 
  showBeltLabel: true,         // 腰带
  showUserBehaviorLabel: true, // 行为标签
  showUserPreferencesLabel: true, // 偏好标签
  showUserCommentKeywordsLabel: true, // 用户评论关键词标签
  showRankingList: true,       // 榜单

  // 以下abt实验控制
  hideRetailPrice: false, // 隐藏划线价
  hideDiscountBadge: false, // 隐藏左上角折扣标签
  showPriceCut: false, // 降价角标 足迹使用
  showQuickShip: false, // AllListQuickShip  快速发货标签 
  showAddToBoardBtn: false,  // ItemAddToBoard // 加组弹框
  showBadge: false,     // PageShowMark 
  showSellingPoint: false,    // SellingPoint  // 卖点
  showSaleAttr: false, // 'WishItemSize'  // 销售属性
  showDiscountLabelAfterPrice: false, // 价格右边折扣标签
  hideSpecialDeDiscountLabel: true,
  configReady: false, // 控制加载数据完成
  showNewDiscountLabel: false, // 价格右边折扣标签ABT
  showGreySellingPoint: false, // 卖点标签ABT
  autoCropImage: false, // 默认不开启方图, 只有瀑布流的地方才开启
}

/**
 * @param originConfig[Object] 页面商品项配置项 优先级大于公共配置最高
 * @param abtData[Object] abt实验数据
*/
function getWishItemConfig(originConfig = {}, abtData = {}) {
  const { imageLabel, ItemAddToBoard, SellingPoint, WishItemSize, AllListQuickShip, discountLabel, greysellingPoint } = abtData

  const getData = (data = {}) => data.p || data.param // 兼容中间层和客户端获取的abt数据

  const DiscountLabel = getData(discountLabel)
  const GreysellingPoint = getData(greysellingPoint)
  const abtConfig = {
    showFollowLabel: false,
    showLocateLabels: imageLabel?.p?.imageLabel,
    hideRetailPrice: true,
    hideDiscountBadge: true,
    showAddToBoardBtn: gbCommonInfo?.WISHLIST_ABT_COMPLETE === 'on' ? false : getData(ItemAddToBoard) === 'type=B',
    showBadge: true,
    showSellingPoint: getData(SellingPoint) === 'type=sellingpoint',
    showSaleAttr: gbCommonInfo?.WISHLIST_ABT_COMPLETE === 'on' ? true : getData(WishItemSize) !== 'type=B',
    showQuickShip: getData(AllListQuickShip) === 'type=B', // 快速发货标签
    showPriceCut: false,
    showDiscountLabelAfterPrice: true,
    hideSpecialDeDiscountLabel: true,
    configReady: true, // 控制加载数据完成
    showNewDiscountLabel: DiscountLabel?.discountLabel === 'Label', // 价格右边折扣标签ABT
    showGreySellingPoint: GreysellingPoint?.greysellingPoint === 'grey', // 卖点标签ABT
    showLabelCarousel: true, //是否展示收藏氛围标签轮播
  }
  return Object.assign({}, itemConfigCommon, originConfig, abtConfig)
}

export default getWishItemConfig
