<template>
  <div
    :class="{
      'ptn-list__container': true,
      'ptn-list__container-small': isSmallStatus,
    }"
  >
    <SwitchContainer
      :list="formatList"
      :origin-list="list?.[0]?.navs || []"
      :is-config-active="!isToQuery"
      :active-value="activeValue"
      :is-show-icon="isShowIcon"
      :shape="shape"
      :on-change="onChange"
    />
    <div
      v-show="formatList.length && !isSmallStatus"
      class="pictn-bottom"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'LayoutList',
}
</script>

<script setup>
import { inject, computed, getCurrentInstance } from 'vue'
import SwitchContainer from './slide-expand/SwitchContainer.vue'

import { useSearchFilterWords } from 'public/src/pages/components/FilterBar/hooks/useVuex.js'
import { useRoute } from 'public/src/pages/components/FilterBar/hooks/useRouter.js'
import { FILTER_RESULT_TYPE_MAP, PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'

import { handlePicTopNavParams, getTopNavigationSource, getFromPageCateId } from 'public/src/pages/components/FilterBar/PicTopNav/utils/index.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import {
  getIsToQuery,
  onChangeByToQuery
} from 'public/src/pages/components/FilterBar/PicTopNav/utils/toQuery.js'
import { getPicTopNavAnalysisData } from 'public/src/pages/components/FilterBar/PicTopNav/utils/analysis.js'
import { RESTET_QUERY_PARAMS } from 'public/src/pages/components/FilterBar/utils/const.js'
import useToRealAndSelectPage from 'public/src/pages/components/FilterBar/PicTopNav/utils/useToRealAndSelectPage.js'

const emits = defineEmits(['change'])
const list = inject('list', {})
const locals = inject('locals', {})
const catInfo = inject('catInfo', {})
const queryParams = inject('queryParams', {})
const listAbtResult = inject('listAbtResult', {})
const isSmallStatus = inject('isSmallStatus', false)
const isUseSearch = inject('isUseSearch', false)

const activeValue = computed(() => queryParams.value?.pic_nav_id || '')

const formatList = computed(() => {
  const tabItem = list.value?.[0] || {}
  const { navs = [] } = tabItem
  return navs.map((navItem, navIndex) => ({
    id: navIndex,
    value: navItem.nav_id,
    label: navItem.nav_name,
    src: navItem.nav_img,
    isLazyLoad: navIndex > 5,
    lazyImg: locals.value.LAZY_IMG,
    analysisData: getPicTopNavAnalysisData({
      tabItem,
      navItem,
      navIndex,
      noClearParams: true,
      catInfo,
      isUseSearch: isUseSearch.value,
    })
  }))
})
const route = useRoute()
const currentInstance = getCurrentInstance()
const instance = computed(() =>
  Object.assign(currentInstance.proxy, {
    catInfo: catInfo.value,
    isUseSearch: isUseSearch.value,
  })
)
const searchFilterWords = useSearchFilterWords()
const isToQuery = computed(() =>
  getIsToQuery(unrefs({ catInfo, listAbtResult, isUseSearch }))
)
const toRealAndSelectPage = useToRealAndSelectPage({
  list,
  catInfo,
  extraParams: {
    pageName: catInfo.value.pageName
  }
})
const shape = computed(() => {
  if (['search', 'entity', 'selection'].includes(catInfo.value.type) && catInfo.value.sortScene !== 'TrendLandingPage' && catInfo.value.pageName !== 'page_store') {
    return PIC_TOP_NAV_SHAPE.CIRCLE
  }
  return isToQuery.value ? PIC_TOP_NAV_SHAPE.CIRCLE : PIC_TOP_NAV_SHAPE.RECT
})

const isShowIcon = computed(() => !['search', 'entity', 'selection'].includes(catInfo.value.type))

const onChange = (navIndex, isSelect) => {
  const tabItem = list.value[0]
  const isToRealAndSelectPage = ['4', '5'].includes(tabItem?.tabType)
  if (isToRealAndSelectPage) {
    toRealAndSelectPage({ tabIndex: 0, navIndex })
    return
  }

  const analysisParams = handlePicTopNavParams(
    unrefs({
      list,
      tabIndex: 0,
      navIndex,
      noClearParams: true,
      extraParams: {
        nav_to_query: isToQuery.value ? 1 : 0,
        pageName: catInfo.value.pageName,
        isUseSearch: isUseSearch.value,
      }
    })
  )

  if (['page_real_class', 'page_select_class'].includes(catInfo.value.pageName)) {
    analysisParams.pic_tab_jump_type = false
    analysisParams.from_page_cate_id =  catInfo.value.from_page_cate_id || '-'
  }

  analysis.clickPicTopNav(analysisParams)

  if (isToQuery.value) {
    // getTopNavigationSource 的入参取值
    const top_navigation_source = getTopNavigationSource({
      ...catInfo.value,
      ...analysisParams,
      // 列表跳搜索页时，埋点需要上报 from_page_cate_id
      ...(isUseSearch.value ? {
        from_page_cate_id: getFromPageCateId(catInfo.value),
      } : {})
    })
    onChangeByToQuery(
      unrefs({
        list,
        route,
        tabIndex: 0,
        navIndex,
        searchFilterWords,
        instance,
        isSelect,
        extraQuery: {
          top_navigation_source,
        },
        listAbtResult
      })
    )
    return
  }

  const params = handlePicTopNavParams(
    unrefs({ list, tabIndex: 0, navIndex, isSelect, businessReport: true })
  )
  // 针对 change 事件，需要重新计算 top_navigation_source
  params.top_navigation_source = getTopNavigationSource({
    ...catInfo.value,
    ...params,
    replace: true
  })
  emits('change', {
    params: {
      ...RESTET_QUERY_PARAMS,
      ...params
    },
    filterResult: {
      type: FILTER_RESULT_TYPE_MAP.PIC_TOP_NAV,
      label: params?.pic_nav_name || '',
      value: params?.pic_nav_id || ''
    }
  })
}
</script>

<style lang="less" scoped>
.ptn-list {
  &__container {
    padding: 14px 12px; // @flag-pic-padding 改之前搜一下这个标记
    padding-bottom: 0;
    background: #fff;
  }
  &__container-small {
    padding: 6px;
  }
}

.pictn-bottom {
  width: 10rem; // document.body最大宽度是10rem
  height: 6px;
  background: #f5f5f5;
  margin-top: 6px;
  margin-left: -12px; // @flag-pic-padding 改之前搜一下这个标记
}
</style>
