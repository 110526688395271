import { PaypalGaManager } from '../checkstand/tools/PaypalGaManager'
import { PaypalVenmoManager } from '../checkstand/tools/PaypalVenmoManager'
import { PaidyPayManager } from '../checkstand/tools/PaidyPayManager'
import { AfterPayCashAppManager } from '../checkstand/tools/AfterPayCashAppManager'
import { CodPayManager } from '../checkstand/tools/CodPayManager'
import { SelfBuiltPagePayManager } from '../checkstand/tools/SelfBuiltPagePayManager'
import { GooglePayManager } from '../checkstand/tools/GooglePayManager'
import { ApplePayManager } from '../checkstand/tools/ApplePayManager'
import { KlarnaInlineManager } from '../checkstand/tools/KlarnaInlineManager'
import { FrontTokenPayManager } from '../checkstand/tools/FrontTokenPayManager'
import { type PaymentProcessorStore } from '../store'
import { debuggerLog } from '../utils'
import { GOOGLE_PAY_CHANNEL } from '../config/google_pay'
import applePayConfig from '../config/apple_pay'

interface InitializePay {
    store: PaymentProcessorStore;
}

export const useInitializePay = (initializeOption: InitializePay) => {
    const { store } = initializeOption
    const { apolloInfo } = store.getState()

    const { applePayCodeList: apolloConfigApPayCodeList = [], googlePayCodeList: apolloConfigGpPayCodeList = [] } = apolloInfo || {}

    const googlePayCodeList = Array.isArray(apolloConfigGpPayCodeList) && apolloConfigGpPayCodeList.length > 0 ? apolloConfigGpPayCodeList : GOOGLE_PAY_CHANNEL
    const applePayCodeList = Array.isArray(apolloConfigApPayCodeList) && apolloConfigApPayCodeList.length > 0 ? apolloConfigApPayCodeList : applePayConfig.applePayChannel

    const initializeCod = () => {
      const { abtInfo = {} } = store.getState()
      const { CodFreeOptimization } = abtInfo
      debuggerLog('initializeCod======', CodFreeOptimization)
      CodPayManager.initCod({
        codFreeShow: CodFreeOptimization?.param?.CodFreeShow,
      })
    }

    const initializeVenmo = (paySessionInfo) => {
        const venmoSessionInfo = paySessionInfo?.[PaypalVenmoManager?.PAYMENT_CODE ?? ''] || {}
        debuggerLog('initializeVenmo======', venmoSessionInfo)
        if (venmoSessionInfo.clientToken) {
          PaypalVenmoManager.initVenmoPay({
            clientToken: venmoSessionInfo.clientToken,
            profileId: venmoSessionInfo.profileId,
          })
        }
    }

    const initializePPGA = (paySessionInfo) => {
      const ppgaSessionInfo = paySessionInfo?.[PaypalGaManager?.PAYMENT_CODE ?? ''] || {}
      debuggerLog('initializePPGA======', ppgaSessionInfo)
      if (ppgaSessionInfo.clientToken) {
        PaypalGaManager.initPPGAPay({
          clientToken: ppgaSessionInfo.clientToken,
          profileId: ppgaSessionInfo.profileId,
        })
      }
    }

    const initializeKlarna = (paySessionInfo) => {
        const sessionInfo = paySessionInfo?.[KlarnaInlineManager?.PAYMENT_CODE ?? ''] || {}
        debuggerLog('initializeKlarna======', sessionInfo)
        if (sessionInfo.clientToken) {
          KlarnaInlineManager.initializeAndLoadKlarna({
            client_token: sessionInfo.clientToken || '',
            payment_method_category: sessionInfo.paymentMethodCategory || '',
          })
        }
    }

    const initializeApplePay = () => {
        // TODO 这里可以优先处理routepay-applepay的逻辑
        const { apolloInfo, abtInfo = {} } = store.getState()

        const { applePayConfig, paymentCardLogoConfig, applePayCodeList: apolloConfigApPayCodeList = [] } = apolloInfo || {}
        const applePayCodeList = Array.isArray(apolloConfigApPayCodeList) && apolloConfigApPayCodeList.length > 0 ? apolloConfigApPayCodeList : applePayConfig.applePayChannel

        const { ApplepayDropoffOptimization } = abtInfo

        debuggerLog('initializeApplePay======', {
          applePayCodeList,
          cardLogoConfig: paymentCardLogoConfig?.apple_pay_map,
          applePayTokenChannel: applePayConfig?.applePayTokenChannel,
          mapChannelToApplePaySupport: applePayConfig?.mapChannelToApplePaySupport,
          applePayCashShow: ApplepayDropoffOptimization?.param?.ApplepayCashShow,
        })

        ApplePayManager.initApplePay({
          applePayCodeList,
          cardLogoConfig: paymentCardLogoConfig?.apple_pay_map,
          applePayTokenChannel: applePayConfig?.applePayTokenChannel,
          mapChannelToApplePaySupport: applePayConfig?.mapChannelToApplePaySupport,
          applePayCashShow: ApplepayDropoffOptimization?.param?.ApplepayCashShow,
        })

    }

    const initializeGooglePay = () => {
        // TODO 这里可以优先处理routepay-googlepay的逻辑
        const { apolloInfo, bsPayConfig } = store.getState()

        const { googlePayConfig, paymentCardLogoConfig, googlePayCodeList: apolloConfigGpPayCodeList, googleReadyToPayRequest, payConfigSwitch } = apolloInfo || {}

        const googlePayCodeList = Array.isArray(apolloConfigGpPayCodeList) && apolloConfigGpPayCodeList.length > 0 ? apolloConfigGpPayCodeList : GOOGLE_PAY_CHANNEL

        debuggerLog('initializeGooglePay======', {
          googlePayCodeList,
          cardLogoConfig: paymentCardLogoConfig?.google_pay_map,
          useApollo: payConfigSwitch == '1',
          readyToPayRequestData: googleReadyToPayRequest,
          mapChannelToGooglePaySupport: googlePayConfig,
        })

        GooglePayManager.initGooglePay({
          googlePayCodeList,
          cardLogoConfig: paymentCardLogoConfig?.google_pay_map,
          useApollo: payConfigSwitch == '1',
          readyToPayRequestData: googleReadyToPayRequest,
          mapChannelToGooglePaySupport: googlePayConfig,
          environment: bsPayConfig.environment,
        })
    }

    const initializePaymentCustomizeFlowPaymentMethods = () => {
      const { abtInfo = {} } = store.getState()
      const paymentCustomizeFlow = abtInfo?.PaymentCustomizeFlow?.param?.PaymentCustomizeShow || ''
      debuggerLog('initializePaymentCustomizeFlowPaymentMethods======', paymentCustomizeFlow)
      SelfBuiltPagePayManager.initPaymentCustomizeFlowPaymentCodes(paymentCustomizeFlow)
    }

    const initializeAfterPayCashAppSDK = () => {
      const bsPayConfig = store.getState().bsPayConfig
      AfterPayCashAppManager.initSDK({ environment: bsPayConfig.environment })
    }

    const initializedPayObj = {
      [PaypalVenmoManager?.PAYMENT_CODE ?? '']: initializeVenmo,
      [PaypalGaManager?.PAYMENT_CODE ?? '']: initializePPGA,
      [KlarnaInlineManager?.PAYMENT_CODE ?? '']: initializeKlarna,
      [AfterPayCashAppManager?.PAYMENT_CODE ?? '']: initializeAfterPayCashAppSDK,
      [PaidyPayManager?.PAYMENT_CODE ?? '']: PaidyPayManager.initPaidySdk,
      [CodPayManager?.PAYMENT_CODE ?? '']: initializeCod,
      ['PayPal-bnpl']: initializePPGA,
      ['routepay-card']: FrontTokenPayManager.initChannelSDK,
      ['routepay-cardinstallment']: FrontTokenPayManager.initChannelSDK,
    }

    // applePayCode 映射
    applePayCodeList.forEach((item) => {
      initializedPayObj[item] = initializeApplePay
    })

    // googlePayCode 映射
    googlePayCodeList.forEach((item) => {
      initializedPayObj[item] = initializeGooglePay
    })

    const initializePay = () => {
      const { paySessionInfo = {}, selectedPayment } = store.getState()
      debuggerLog('initializePay==selectedPayment====', selectedPayment)
      if (Object.keys(initializedPayObj).includes(selectedPayment?.payment_code)) {
          const initializeFunction = initializedPayObj[selectedPayment?.payment_code]
          if (initializeFunction) {
              initializeFunction(paySessionInfo)
          }
      }

      // 初始化获取需跳转自建页面的支付方式
      initializePaymentCustomizeFlowPaymentMethods()
    }

    return {
        initializePay,
    }
}
