import { mergeQueryString, getQueryString } from '@shein/common-function'
import bsUtils from 'public/src/pages/common/handlePay/bsLib.js'
import { usePayInstance } from './usePayInstance.js'
import useVuex from 'public/src/pages/user/hooks/useVuex.js'

import { computed } from 'vue'

const { host, langPath } = typeof window === 'undefined' ? {} : gbCommonInfo

const getSiteUrl = () => {
  return `${host || ''}${langPath || ''}`
}

const getOrderDetailLink = ({ order = {}, extraParams = {} }) => {
  const mockSkipJump = getQueryString({ key: 'skipJump' })
  const siteUrl = getSiteUrl()
  const billno = order?.relation_billno || order?.billno || ''
  if (mockSkipJump) return ''
  if (!billno) return `${siteUrl}/user/orders/list`

  let link = `${siteUrl}/user/orders/detail/${billno}`
  if (order.is_virtual_order) {
    link = `${siteUrl}/user/orders/virtualDetail/${billno}`
  }

  return mergeQueryString({
    url: link,
    mergeObj: extraParams || {},
  })
}

const formatOrderInfo = (orderInfo = {}) => {
  const address = orderInfo?.shippingaddr_info || orderInfo?.shipping_address || {}
  const formatedOrder = {
    ...orderInfo,
    country_code: orderInfo.countryCode || address.shipping_country_code || '',
    currency_code: orderInfo.orderCurrency?.code || orderInfo.currency_code || '',
    country_id: orderInfo.country_id || address.shipping_country_id || '',
  }
  return formatedOrder
}

// 支付方法
export const usePay = ({ orderInfo, extraCb = {}, storeNameSpace }) => {
  const { useMapMutations: useRootMapMutations } = useVuex()
  const { changeRootStatus  } = useRootMapMutations(['changeRootStatus'])
  const { useMapGetters } = useVuex(`${storeNameSpace}/payAgain`)

  const { paymentCheckoutInfo } = useMapGetters(['paymentCheckoutInfo'])

  const { paymentInstance } = usePayInstance()
  const mockSkipJump = getQueryString({ key: 'skipJump' })

  const nowOrderInfo = computed(() => orderInfo.value)

  const billno = computed(() => nowOrderInfo.value?.relation_billno || nowOrderInfo.value?.billno)
  // const cancelUrl = computed(() => `${host}${langPath}/user/orders/detail/${billno.value}`)
  const successUrl = computed(() => `${host}${langPath}/pay/result/success?billno=${billno.value}`)
  const failureUrl = computed(() => `${host}${langPath}/pay/result/fail?billno=${billno.value}`)

  const handlePaySuccess = (res, extraData) => {
    const mockSkipJump = getQueryString({ key: 'skipJump' })
    extraCb?.onComplete?.(res)

    // replacePaySuccessReferer()

    setTimeout(() => {
      const mergeSuccessUrl = mergeQueryString({
        url: successUrl.value,
        mergeObj: Object.keys(extraData?.mergeQuery || {}).length
          ? extraData.mergeQuery
          : bsUtils.checkLtspcPaymentMethod(nowOrderInfo.value?.payment_method)
            ? {
              gatewayPayNo: res?.info?.gatewayPayNo || '',
            }
            : {},
      })
      if (mockSkipJump == 1) return
      location.href = mergeSuccessUrl
    }, 1500)
  }

  const handlePayFail = res => {
    console.log('order_new====handlePayFail', res)
    extraCb?.onComplete?.(res)
    if (extraCb?.onFail && typeof extraCb?.onFail === 'function') {
      extraCb.onFail(res)
      return
    }

    if (mockSkipJump == 1) return
    // location.href = cancelUrl.value
  }

  const handlePayCancel = () => {
    console.log('order_new====handlePayCancel')
    extraCb?.onComplete?.()
    if (mockSkipJump == 1) return
    // location.href = cancelUrl.value
  }

  const handlePayError = (res, extraData) => {
    console.log('order_new====handlePayError', res)
    extraCb?.onComplete?.(res)
    if (extraCb?.onError && typeof extraCb?.onError === 'function') {
      extraCb.onError(res)
      return
    }

    if (mockSkipJump == 1) return
    const failUrl = mergeQueryString({
      url: failureUrl.value,
      mergeObj: {
        code: res?.info?.error_code || '',
        paymentCode: res?.info?.paymentCode || '',
        is_guide: 1,
        ...(extraData?.mergeQuery || {}),
      },
    })
    // replacePaySuccessReferer()
    location.href = failUrl
  }

  const handleCreatePayment = ({ paymentInfo, extraPayInfo = {} }) => {

    console.log('正在发起支付.........', paymentInfo, extraPayInfo)
    // console.log('正在发起支付...paymentCheckoutInfo', paymentCheckoutInfo.value)

    const coverData = {}
    const card_token_info = {
      ...(paymentInfo?.card_token_info || {}),
    }

    if (extraPayInfo?.coverData?.bin?.length === 0) {
      coverData.cardBin = extraPayInfo?.coverData?.bin || ''
      card_token_info.card_bin = extraPayInfo?.coverData?.bin || ''
    }

    if (extraPayInfo?.coverData?.installments) {
      extraPayInfo.installments = extraPayInfo?.coverData?.installments || ''
    }

    if (extraPayInfo?.coverData?.cvv) {
      extraPayInfo.cvv = extraPayInfo?.coverData?.cvv || ''
    }

    paymentInstance?.createPayment({
      orderInfo: formatOrderInfo(nowOrderInfo.value),
      paymentInfo: {
        ...paymentInfo,
        card_token_info,
      },
      extraParams: {
        ...(extraPayInfo || {}),
        sortedPriceInfo: paymentCheckoutInfo.value.sortedPriceInfo || [],
        vipOrderSource: paymentCheckoutInfo.value.vipOrderSource == '1' ? 1 : 0,
        failureUrl: failureUrl.value,
        cancelUrl: getOrderDetailLink({ order: nowOrderInfo.value }),
        coverData,
      },
      paymentLoadingAction: ({ show }) => {
        changeRootStatus?.({
          loading: !!show
        })
      },
      onSuccess: handlePaySuccess,
      onError: handlePayError,
      onFail: handlePayFail,
      onCancel: handlePayCancel,
    })
  }

  return {
    handleCreatePayment,
  }
}
