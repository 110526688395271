import { transfromAndCutImg } from '@/public/src/services/resource/index'

export default {
  provide() {
    return {
      cutImg: this.cutImg,
      isProductPage: true,
    }
  },
  methods: {
    cutImg(imgUrl, designWidth, exp) {
      const { RESOURCE_SDK = {} } = this.locals || {}
      const {
        deviceData = '',
        isSupportWeb = '',
        isSupprotCut = false,
        sceneMap = {},
      } = RESOURCE_SDK || {}
    
      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        sceneMap,
        exp,
      }
    
      return transfromAndCutImg(cutData)
    }
  }
}
