import { daEventCenter } from 'public/src/services/eventCenter/index'
import sizeGuideFromSceneMap from 'public/src/pages/goods_detail_v2/utils/size-guide-from-scene-map.js'

export default {
  
  // ⬇️ 旧的
  /** 用户手册弹窗 */
  openUserManualPop({ commit, rootState }) {
    const { userManualReady } = rootState.newProductDetail || {}
    if (!userManualReady) {
      commit('newProductDetail/updateUserManualReady', true, { root: true })
    }
    commit('newProductDetail/common/updateUserManualStatus', true, { root: true })
  },
  closeUserManualPop({ commit }) {
    commit('newProductDetail/common/updateUserManualStatus', false, { root: true })
  },
  /** 描述弹窗 */
  openDescriptionPop({ commit, rootState }) {
    const { descriptionReady } = rootState.newProductDetail || {}
    if (!descriptionReady) {
      commit('newProductDetail/updateDescriptionReady', true, { root: true })
    }
    commit('newProductDetail/common/updateDescriptionStatus', true, { root: true })
  },
  closeDescriptionPop({ commit }) {
    commit('newProductDetail/common/updateDescriptionStatus', false, { root: true })
  },
  // ⬆️ 旧的

  /** 尺码引导弹窗 */
  openSizeGuidePop({ commit, rootState, rootGetters }, config = {}) {
    const { sizeGuideReady, checkSizeAndSizeGuideReady } = rootState.newProductDetail || {}
    const showCheckSizeAndSizeGuide = rootGetters['newProductDetail/SizeBox/showCheckSizeAndSizeGuide']
    // 如果尺码推荐和尺码引导都存在，且命中实验则展示新弹窗
    if (showCheckSizeAndSizeGuide) {
      if (!checkSizeAndSizeGuideReady) {
        commit('newProductDetail/updateCheckSizeAndSizeGuideReady', true, { root: true })
      }
      commit('newProductDetail/SizeGuide/updateCheckSizeAndSizeGuide', true, { root: true })
      commit('newProductDetail/SizeGuide/updateSizeActivityFrom', 'sizeGuide', { root: true })
    } else {
      if (!sizeGuideReady) {
        commit('newProductDetail/updateSizeGuideReady', true, { root: true })
      }
      commit('newProductDetail/SizeGuide/updateShowSizeGuideDrawer', true, { root: true })
    }

    daEventCenter.triggerNotice({
      daId: '1-6-1-227',
      extraData: {
        from: sizeGuideFromSceneMap?.[config?.fromScene]
      }
    })
  },
  /** 尺码推荐（鞋子）弹窗 */
  openSizeRecommendPop({ commit, rootState, rootGetters }) {
    const { sizeRecommandReady, checkSizeAndSizeGuideReady } = rootState.newProductDetail || {}
    const showCheckSizeAndSizeGuide = rootGetters['newProductDetail/SizeBox/showCheckSizeAndSizeGuide']
    if (showCheckSizeAndSizeGuide) {
      if (!checkSizeAndSizeGuideReady) {
        commit('newProductDetail/updateCheckSizeAndSizeGuideReady', true, { root: true })
      }
      commit('newProductDetail/SizeGuide/updateCheckSizeAndSizeGuide', true, { root: true })
      commit('newProductDetail/SizeGuide/updateSizeActivityFrom', 'checkMySize', { root: true })
    } else {
      if (!sizeRecommandReady) {
        commit('newProductDetail/updateSizeRecommandReady', true, { root: true })
      }
      commit('newProductDetail/SizeGuide/updateSizeRecommand', true, { root: true })
    }
  },
  /** 尺码推荐（内衣）弹窗 */
  openBraSizeRecommendPop({ commit, rootState, rootGetters }) {
    const { braSizeRecommendReady, checkSizeAndSizeGuideReady } = rootState.newProductDetail || {}
    const showCheckSizeAndSizeGuide = rootGetters['newProductDetail/SizeBox/showCheckSizeAndSizeGuide']
    if (showCheckSizeAndSizeGuide) {
      if (!checkSizeAndSizeGuideReady) {
        commit('newProductDetail/updateCheckSizeAndSizeGuideReady', true, { root: true })
      }
      commit('newProductDetail/SizeGuide/updateCheckSizeAndSizeGuide', true, { root: true })
      commit('newProductDetail/SizeGuide/updateSizeActivityFrom', 'checkMySize', { root: true })
      
    } else {
      if (!braSizeRecommendReady) {
        commit('newProductDetail/updateBraSizeRecommendReady', true, { root: true })
      }
      commit('newProductDetail/SizeGuide/updateBraSizeRecommend', true, { root: true })
    }
  }
}
