<template>
  <div :class="[position === 'inner' ? 'reviews-item-user' : '']">
    <div class="review-item__info">
      <div class="review-item__info-item">
        <h4 aria-hidden="true">
          {{ userName }}
        </h4>
        <div
          v-ada="{ level: 1, pos: 0 }"
          :aria-label="`${language.SHEIN_KEY_PWA_15332}${commentRank}`"
          class="star-html"
        >
        <Star :grade="commentRank" />
      </div>

        <!-- free tips -->
        <span
          v-if="comment.reportId"
          class="report"
          :class="{ 'clamp1': isSlideStyle }"
          aria-hidden="true"
        >
          {{ language.SHEIN_KEY_PWA_15207 }}
        </span>
      </div>
      <div
        class="review-item__info-time"
        :aria-label="comment.comment_time"
        role="text"
      >
        {{ formatTime(comment) }}
      </div>
    </div>

    <div 
      v-if="goodsAttr"
      class="review-item__line-box"
    >
      <!-- goods attr -->
      <div class="review-item__attr">
        <span
          class="comment-size"
          tabindex="1"
          role="text"
          :aria-label="goodsAttr"
        >
          {{ goodsAttr }}
        </span>
        <span 
          v-if="isShowHotSoldOut"
          class="comment-hottips"
        >{{ commentHotOrSoldOutLabel }}</span>
      </div>
      <div 
        v-if="isShowAddToCart"
        class="comment-addcart" 
        :class="{ 'light': isShowHotSoldOut }"
        @click.stop="addCart"
      >
        <Icon 
          name="sui_icon_addtocart_20px" 
          size="14px" 
        />
        <span>{{ language.SHEIN_KEY_PWA_29463 }}</span>
      </div>
      <!-- local tag -->
      <div
        v-if="localTagShow"
        v-expose="{ id: position === 'outer' ? '1-6-7-58' : '1-6-2-58' }"
        class="review-item__local-tag"
      >
        <i class="suiiconfont sui_icon_location_15px location_12px"></i>
        <span>{{ language.SHEIN_KEY_PWA_22717 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Star from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Reviews/components/Star/index.vue'
import { calOldGoodsAttr } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { expose } from 'public/src/pages/common/analysis/directive.js'
const { SiteUID } = gbCommonInfo
export default {
  name: 'CommentBase',
  directives: { expose },
  inject: ['COMMENT_INSTANCE'],
  components: {
    Star
  },
  props: {
    // 单条评论是否需要展示本地标记
    markSingleLocalComment: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShowAddToCart() {
      return this.COMMENT_INSTANCE.isShowAddToCart
    },
    isShowHotSoldOut() {
      if(this.COMMENT_INSTANCE.displayLimit) {
        return this.comment.isFirstGoodsId && this.commentHotOrSoldOutLabel
      }
      return this.comment.commentHotOrSoldOutLabel
    },
    commentHotOrSoldOutLabel() {
      return this.comment.commentHotOrSoldOutLabel
    },
    goodsAttr() {
      return this.comment.goods_attr || this.calOldGoodsAttr(this.comment, this)
    }, 
    isSlideStyle() {
      return this.COMMENT_INSTANCE.isSlideStyle
    },
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    language() {
      return this.COMMENT_INSTANCE.language
    },
    position() {
      return this.COMMENT_INSTANCE.position
    },
    showGoodsId() {
      return this.COMMENT_INSTANCE.showGoodsId
    },
    index() {
      return this.COMMENT_INSTANCE.index
    },
    commentDateDisplayNew() {
      return this.COMMENT_INSTANCE.commentDateDisplayNew
    },
    commentRank() {
      const { comment = {} } = this
      const { comment_rank = 0 } = comment
      return Math.min(comment_rank, 5)
    },
    localTagShow() {
      const { markSingleLocalComment, comment } = this
      if (!markSingleLocalComment) {
        return false
      }
      return comment.local_flag === 1
    },
    /**
     * 用户名加密
     * @param {*} txt
     * @returns
     */
    userName() {
      const { comment } = this
      const { user_name, username, isOutStoreReview } = comment
      if (isOutStoreReview) {
        return username
      }
      let txt = user_name || username || ''
      if (txt.indexOf('*') === 1 && txt.lastIndexOf('*') === txt.length - 2) {
        txt = txt.split('*')
        txt = `${txt[0]}***${txt[txt.length - 1]}`
      }
      return txt
    }
  },
  methods: {
    addCart() {
      this.COMMENT_INSTANCE.EVENT_CENTER('addCart', this.comment)
    },
    /**
     * JP time 格式处理
     * @param {*} timeStr
     * @returns
     */
    formatTime(comment) {
      if(this.commentDateDisplayNew && comment.commentTimeNewStyle) return comment.commentTimeNewStyle
      const timeStr = comment.comment_time
      if (SiteUID.includes('jp')) {
        return timeStr.replace(/-/g, '/')
      }
      return timeStr?.split(' ')?.[0]
    },
    /**
     * click callback
     */
    clickGoodsId({ goods_id, jump_goods_id, is_check_skc }) {
      const { COMMENT_INSTANCE, index } = this
      COMMENT_INSTANCE.EVENT_CENTER('clickGoodsId', {
        goods_id: jump_goods_id || goods_id,
        index,
        is_check_skc
      })
    },
    calOldGoodsAttr,
    GB_SHEIN_counting_star_suiicon: typeof window !== 'undefined' ? window.GB_SHEIN_counting_star_suiicon : () => {}
  }
}
</script>
<style lang="less">
.review-item__info-item {
  .star-html {
    transform: perspective(400px);
    display: flex;
    align-items: center;
  }
  .clamp1 {
    max-width: 2.213rem;
    text-overflow: ellipsis;
    overflow: hidden;
     /* stylelint-disable-next-line */
    display: block !important;
    white-space: nowrap;
  }
}
.review-item__line-box {
  .review-item__attr {
    display: flex;
    flex-wrap: nowrap;
    .comment-size {
      -webkit-line-clamp: 1;
      word-break: break-all;
    }
  }
  .comment-hottips {
    color: var(---sui_color_discount, #FA6338);
    font-family: "SF Pro";
    font-size: 0.26667rem;
    font-weight: 400;
    line-height: normal;
    padding: 0 0.11rem;
    flex-shrink: 0;
  }
  .comment-addcart {
    display: flex;
    flex-shrink: 0;
    padding: 0.08rem 0.16rem;
    justify-content: center;
    align-items: center;
    gap: 0.05333rem;
    border-radius: 2.66667rem;
    border: 1px solid var(---sui_color_gray_light2, #BBB);
    color: var(---sui_color_gray_dark3, #767676);
    text-align: center;
    font-family: "SF Pro";
    font-size: 0.26667rem;
    font-style: normal;
    font-weight: 510;
    line-height: normal;
    text-transform: capitalize;
    &.light {
    color: #FA6338;
    border: 0.5px solid rgba(250, 99, 56, 0.80);
  }
  }

}
</style>
