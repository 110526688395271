<template>
  <div :class="prefixCls('sp-footer_wrapper')">
    <div
      v-for="item in renderData"
      :key="item.id"
      v-expose="item.expose"
      :class="prefixCls('sp-footer')"
    >
      <img
        v-if="item.img"
        :class="prefixCls('sp-footer_img')"
        :src="item.img"
      />
      <Icon
        v-else
        name="sui_icon_caution_12px_2"
        size="12px"
        color="#999"
      />
      <div 
        v-if="item.isDefault" 
        v-html="item.text"
      ></div>
      <CollapsibleText
        v-else
        :text="item.text"
        :style="item?.textStyle || {}"
      />
    </div>
    <!-- 定制商品 -->
    <CustomContent :data="data" />
  </div>
</template>

<script setup name="ShoppingBagsFooter">
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

import CollapsibleText from '@/public/src/pages/checkout_new/components/CollapsibleText.vue'
import CustomContent from 'public/src/pages/checkout_new/pages/shopping_bags/components/single_product/components/CustomContent.vue'

import useAttribute from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useAttribute.js'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

const { seaLandBelt } = useAttribute(props?.data)

// ---------- computed ----------
/**
 * @description 渲染数据
 * */
const renderData = computed(() => {
  const { product, return_flag_msg } = props.data
  const list = [
    // 积分[额外积分不可退]
    {
      visible: product?.double_status === '0' && !!product?.doubleStatusTip,
      text: product?.doubleStatusTip,
      expose: {
        id: 'expose_not_in_points_activities:simple',
        data: { skc_code: props.data?.product?.goods_sn },
      },
    },
    // 不可退
    {
      visible: !!return_flag_msg,
      text: return_flag_msg,
    },
    // 海陆运
    {
      visible: seaLandBelt.value.visible,
      text: `${seaLandBelt.value?.data?.outsideBeltTip || ''}:${
        seaLandBelt.value?.data?.seaLandInfo?.deliveryDesc || ''
      }`,
      textStyle: {
        color: '#c44a01'
      },
      isDefault: true,
      img: seaLandBelt.value?.data?.seaLandInfo?.icon || ''
    }
  ]
  return list.filter(item => item.visible)
})
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}sp-footer {
  padding-top: 16/75rem;
  color: #999;
  font-size: 10px;
  display: flex;
  align-items: flex-start;
  gap: 0.10666667rem;
  &_img {
    width: 12px;
    height: 12px;
  }
}
</style>
