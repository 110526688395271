import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { loadChannelSdk } from '../../channel/initSdk'
import { debuggerLog } from '../../utils'
import { type BraintreeError, type Venmo } from 'braintree-web'
import type { Trade_PayLibs } from '@shein-aidc/types-trade'
import { InitPaypalSdk } from '../helpers/InitPaypalSdk'
import { MonitorReportAbnormal } from '../helpers/MonitorReport'
import { venmoInstanceCache } from '../caches'
import { PAYMENT_ACTION_TYPE } from '../../../types'

// export interface PaidyPayManagerConstructor extends BasicPayManagerConstructor { }

export interface LoadPaypalVenmoButtonOptions {
  clientToken: string;
  profileId: string;
}

export interface InitVenmoPayOptions extends LoadPaypalVenmoButtonOptions {
  onSuccess: (data: any) => void;
  onError?: ((error: BraintreeError) => void) | undefined;
}

export class PaypalVenmoManager extends BasicPayManager implements AbstractMethods {

  public static PAYMENT_CODE = 'PayPal-Venmo'

  public paymentCode: string = PaypalVenmoManager.PAYMENT_CODE

  private static paypalVenmoInstance: Venmo | undefined = undefined

  private resolve

  private reject

  private static instance: PaypalVenmoManager

  constructor(params: BasicPayManagerConstructor) {
    super(params)

    this.resolve = null
    this.reject = null

    PaypalVenmoManager.instance = this
  }

  private static _sendReport = (scene = '', params = {}) => {
    debuggerLog('useBsPay===_sendReport===', scene, params)
  }

  private static teardownCache = async (caches = venmoInstanceCache, cacheKey = '') => {
    if (cacheKey) {
      const singleClientInstance = caches.get(cacheKey)
      singleClientInstance?.teardown?.(() => {})
      caches?.delete?.(cacheKey)
    } else {
      for (const key in caches) {
        if (caches.has(key)) {
          const clientInstance = caches.get(key)
          clientInstance?.teardown?.(() => {})
          caches.delete(key)
        }
      }
    }
  }

  private onVenmoSuccess (data){
    debuggerLog('useBsPay===initVenmoPay===onSuccess---', data)
    this.paymentLoadingAction?.({ show: true })
    this.updateUnifiedPayParams({ sessionId: data?.nonce || '' })
    this.handleUnifiedPay().then(this.resolve).catch(this.reject)
  }

  private onVenmoError (error) {
    debuggerLog('useBsPay===initVenmoPay===onError---', error)

    MonitorReportAbnormal.metric({
      scene: 'paypal_venmo_sdk_content_error',
      extraTags: {
        failure_type: 'sdk',
        payment_code: PaypalVenmoManager.PAYMENT_CODE,
      },
      extraParams: {
        client_url: '/third/sdk/error',
        description: 'PayPal-Venmo获取SDK对应nonce失败',
        error_msg: error?.message,
        sdk_error_code: error?.code,
        payment_action_type: PAYMENT_ACTION_TYPE.VENMO_SDK,
      },
    })
    if(error?.code === 'VENMO_CANCELED'){
      this.onFail?.(error)
    } else {
      this.reject?.(error)
    }
  }

  private static initPaypalVenmoInstnace = async ({ clientToken, profileId }) => {
    const cacheKey = clientToken + profileId
    if (venmoInstanceCache.has(cacheKey)) {
      this.paypalVenmoInstance = venmoInstanceCache.get(cacheKey)
      return this.paypalVenmoInstance
    }

    this.teardownCache(venmoInstanceCache)

    try {
      const instance = await InitPaypalSdk.initPaypalVenmoSdk({ clientToken, profileId })
      venmoInstanceCache.set(cacheKey, instance)
      this.paypalVenmoInstance = instance as Venmo
      return instance
    } catch (error) {

      MonitorReportAbnormal.metric({
        scene: 'paypal_venmo_sdk_content_error',
        extraTags: {
          payment_code: PaypalVenmoManager.PAYMENT_CODE,
          failure_type: 'sdk',
        },
        extraParams: {
          client_url: '/third/sdk/error',
          description: 'venmo SDK初始化失败',
          payment_action_type: PAYMENT_ACTION_TYPE.VENMO_SDK,
          error_msg: (error || {} as any)?.message || '',
        },
      })
    }
  }

  public static getInstance = () => {
    return PaypalVenmoManager.instance
  }

  public static initVenmoSdk = async () => {
    await loadChannelSdk.initVenmoSdk()
  }

  public static initVenmoPay = async ({
    clientToken,
    profileId,
  }) => {
    PaypalVenmoManager.paypalVenmoInstance = undefined

    const instance = await this.initPaypalVenmoInstnace({ clientToken, profileId })
    debuggerLog('paypalVenmoInstance===', instance, instance?.hasTokenizationResult?.())
    // this.paypalVenmoInstance = instance
    if (instance?.hasTokenizationResult?.()) {
      instance.tokenize().then(data => {
        const managerInstance = this.getInstance()
        managerInstance.onVenmoSuccess(data)
      }).catch(err => {
        const managerInstance = this.getInstance()
        managerInstance?.onVenmoError?.(err)
      })
    }
  }

  private createVenmoPayment = async ({
    onSuccess,
    onError,
  }) => {
    if (PaypalVenmoManager.paypalVenmoInstance) {
      PaypalVenmoManager.paypalVenmoInstance?.tokenize().then(onSuccess).catch(onError)
    }
  }

  public static loadPaypalVenmoBtn = async ({
    clientToken,
    profileId,
  }: LoadPaypalVenmoButtonOptions) => {
    const paypalVenmoInstance = await this.initPaypalVenmoInstnace({ clientToken, profileId })
    // const deviceData = await getPaypalDeviceInfo({ clientToken })
    // this.paypalVenmoDeviceId = dataCollectorInstance?.deviceData
    // daEventCenter?.triggerNotice?.({
    //   daId: '2-31-5',
    //   extraData: {
    //     payment_method: 'PayPal-Venmo',
    //     return_status: 'sdkjsapi_return_success'
    //   }
    // })

    // // At this point, you should access the deviceData value and provide it
    // // to your server, e.g. by injecting it into your form as a hidden input
    // debuggerLog('Got device data:', dataCollectorInstance.deviceData)

    // // Verify browser support before proceeding.
    if (!paypalVenmoInstance?.isBrowserSupported?.()) {
      // this.venmoState.backupClientToken = null

      MonitorReportAbnormal.metric({
        scene: 'paypal_venmo_not_support_sdk',
        extraTags: {
          failure_type: 'sdk',
          payment_code: PaypalVenmoManager.PAYMENT_CODE,
        },
        extraParams: {
          client_url: '/third/sdk/error',
          description: 'venmo SDK初始化失败',
          payment_action_type: PAYMENT_ACTION_TYPE.VENMO_SDK,
        },
      })
      throw new Error('Browser does not support Venmo')

      // this.sendErrorReport('paypal_venmo_init_sdk_error', {
      //   failure_type: 'sdk',
      //   client_url: '/third/sdk/error',
      //   description: 'venmo SDK初始化失败',
      //   paymentActionType: 'venmo_sdk'
      // })
    }

    return {
      paypalVenmoInstance,
    }
  }

  public async createPayment (): Promise<any> {
    return new Promise((resolve, reject) => {
      // this.resolve = resolve
      this.resolve = (res) => {
        this.paymentLoadingAction?.({ show: false })
        if (res.status === 'success') {
          this.onSuccess?.(res.result as Trade_PayLibs.UnifiedPayRes)
        } else if (res.status === 'error') {
          this.onError?.(res.result as Trade_PayLibs.UnifiedPayRes)
        } else if (res.status === 'catchError') {
          this.onFail?.(res.result as any)
        }
        resolve(res)
      }
      this.reject = (error) => {
        this.paymentLoadingAction?.({ show: false })
        this.onError?.(error)
        reject(error)
      }
      // this.reject = reject
      const { channelExtraInfo } = this.payData
      InitPaypalSdk.getPaypalDeviceInfo({ clientToken: channelExtraInfo.clientToken }).then(info => {
        this.updateUnifiedPayParams({ channelDeviceFingerId: info || '' })
      })

      debuggerLog('venmo---createPayment', PaypalVenmoManager.paypalVenmoInstance)

      this.createVenmoPayment({
        onSuccess: this.onVenmoSuccess.bind(this),
        onError: this.onVenmoError.bind(this),
      })
    })
  }
}

// const paidyPay = new PaidyPayManager({
//   params: {
//     tokenId: '123',
//     routeId: '456',
//   } as Trade_PayLibs.UnifiedPayParams,
//   payProductType: PRODUCT_TYPE.NORMAL,
//   payCheckoutType: CHECKOUT_TYPE.NORMAL,
// })

// PaidyPayManager.getChannelConfig({
//   order: {
//     billno: '123',
//   },
//   cancelCb: () => { },
//   completeCb: () => { },
//   errorCb: () => { },
//   getChannelConfigCb: () => { },
// })
