<template>
  <div class="benefit-drawer-main">
    <div
      v-for="rewardItem in rewardList"
      :key="rewardItem.type"
      class="benefit-drawer-main__item"
    >
      <div class="benefit-drawer-main__header">
        <span class="benefit-drawer-main__header-title">
          {{ rewardItem.title }}
        </span>
        <span
          v-if="rewardItem.totalValueTip"
          class="benefit-drawer-main__header-value"
        >
          {{ rewardItem.totalValueTip }}
        </span>
        <BenefitTag
          v-for="tag in rewardItem.tagList"
          :key="tag.type"
          class="benefit-drawer-main__header-tag"
          :type="tag.type"
          :text="tag.text"
        />
      </div>
      <component
        :is="getComponentConfig(rewardItem)?.name"
        class="benefit-drawer-main__benefit"
        v-bind="getComponentConfig(rewardItem)?.props"
      />
    </div>
  </div>
</template>
<script name="BenefitDrawerMain" setup lang="ts">
import CreditList from '../Credit/CreditList.vue'
import CouponList from '../BenefitCoupon/coupon-list.vue'
import PointList from '../BenefitPoint/List.vue'
import FreeGiftList from '../BenefitFreeGift/gift-list.vue'
import BenefitTag from '../BenefitTag.vue'
import { E_BenefitFloor, type C_BenefitFloor } from '../../../../types'

defineProps<{
  rewardList: C_BenefitFloor.PopUpRewardItem[]
}>()

const getComponentConfig = (rewardItem) => {
  switch(rewardItem.type) {
    case E_BenefitFloor.BenefitType.ReturnCredit:
      return {
        name: CreditList,
        props: { returnCredit: rewardItem.returnCredit },
      }
    case E_BenefitFloor.BenefitType.ReturnCoupon:
      if(rewardItem.returnCoupon?.showType == 'nonAggregate') {
        return {
          name: CouponList,
          props: {
            couponItem: rewardItem.returnCoupon,
            showType: rewardItem.returnCoupon?.showType,
          },
        }
      } else if(rewardItem.returnCoupon?.showType == 'aggregate') {
        return {
          name: CouponList,
          props: {
            showType: rewardItem.returnCoupon?.showType,
            couponItem: rewardItem.returnCoupon,
          },
        }
      }
      break
    case E_BenefitFloor.BenefitType.Point:
      return {
        name: PointList,
        props: { pointItem: rewardItem.point },
      }
    case E_BenefitFloor.BenefitType.FreeGift:
      return {
        name: FreeGiftList,
        props: { giftItem: rewardItem.freeGift },
      }
    default:
      return {} as any
  }
}

</script>
<style lang="less" scoped>
.benefit-drawer-main {
  padding: 0 16/75rem 20/75rem;
  background-color: #F6F6F6;
  overflow-y: scroll;
  flex: 1;

  &__item {
    background-color: #fff;
    margin-bottom: 20/75rem;
    padding: 24/75rem 0 24/75rem 20/75rem;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 0px rgba(173, 173, 173, 0.25);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    line-height: 1.2;
  }

  &__header-title {
    color: #000;
    font-size: 14px;
    font-weight: 700;
  }

  &__header-value {
    color: #666;
    font-size: 12px;
    font-weight: 510;
    margin-left: 16/75rem;
    padding-left: 16/75rem;
    border-left: 1/75rem solid #CCC;
  }

  &__benefit {
    margin-top: 10/37.5rem;
  }
}
</style>
