import orderLogic from 'public/src/pages/common/orderLogic'
import { defaultReturnReason } from './defaultText'
import { parseQueryString, stringifyQueryString, cloneDeep } from '@shein/common-function'
import Clipboard from 'clipboard'
import { isFunction } from '@shein/common-function'

//追加退货渠道用于区分普通渠道的key
const APPEND_KEY = '-APPEND'

const iconUrl = 'https://img.ltwebstatic.com/images3_acp/2024/03/04/c7/170955512104c7c7066b88e9b5da457367847dc301.png'

function getAttrValue(attrArr = [], valueKey) {
  if (!attrArr?.length) {
    return ''
  }
  let mapList = attrArr.map(item => item[valueKey])
  return mapList.join('/')
}

export function refundCountryPaymentCheck ({ COUNTRY_PAYMENT_METHOD_CONFIG, receiver_country, status, payment }) {
  const conditionsMap = new Map([
    ['Japan', ['adyen-konbini']],
    ['Thailand', ['adyen-fpx', 'ksher-promptpay']],
    ['Brazil', ['ebanx-boleto', 'dlocal-boleto', 'adyen-boleto']],
    ['Mexico', ['dlocal-oxxo', 'ebanx-oxxo']]
  ])
  if (Object.prototype.toString.call(COUNTRY_PAYMENT_METHOD_CONFIG) == '[object Object]') {
    for(let key in COUNTRY_PAYMENT_METHOD_CONFIG){
      if (COUNTRY_PAYMENT_METHOD_CONFIG.hasOwnProperty(key) && conditionsMap.has(key)) {
        conditionsMap.set(key, COUNTRY_PAYMENT_METHOD_CONFIG[key])
      }
    }
    // conditionsMap.set('Brazil', BR_PAYMENT_METHOD_CONFIG)
  }

  const conditionItem = conditionsMap.get(receiver_country)
  // let isPass = Boolean(conditionItem && conditionItem.includes(payment))
  // 若订单的支付方式与运输国家在阿波罗配置中存在匹配数据，则无论账户是否可退，即视为满足维护打款账户；
  let isPass = Boolean(conditionItem && conditionItem.includes(payment)) && COUNTRY_PAYMENT_METHOD_CONFIG.hasOwnProperty(receiver_country)
  //非以上场景若账户（refund_path_tip.user_card.status=1）且满足wallet的3个条件中任意一个条件时（前置判断了）视为满足打款帐户
  if (!COUNTRY_PAYMENT_METHOD_CONFIG.hasOwnProperty(receiver_country)) {
    // 账户（refund_path_tip.user_card.status=1）时可退
    const isCanRefund = status == 1
    isPass = isPass && isCanRefund
  }

  return isPass
}

// 查询列表尺码是否可合并
export function hasSizeMerge({ item, list }) {
  return list.filter(unobj => {
    let itemAttrValue = getAttrValue(
      item.skuInfoList || item.sku_info_list || item.sku_sale_attr,
      'attr_value_name_en'
    )
    let unObjAttrValue = getAttrValue(
      unobj.skuInfoList || unobj.sku_info_list || unobj.sku_sale_attr,
      'attr_value_name_en'
    )
    if (itemAttrValue || unObjAttrValue) {
      return unobj.goods_sn == item.goods_sn && unObjAttrValue == itemAttrValue
    }
    return unobj.goods_sn == item.goods_sn && unobj.size == item.size
  })
}

export const formatReasonEvt = (data = {}) => {
  const { goods_sn, business_model, newReturnReason = [], language = {}, actual_skc = '' } = data
  if (Array.isArray(newReturnReason) && newReturnReason.length) {
    const firstReturnReasonList = newReturnReason.map(item => {
      if(!item.return_goods_reason_list?.length) {
        item.return_goods_reason_list = defaultReturnReason(language)
      }
      return item
    })
      .filter(item => {
        return `${item.business_model}` == `${business_model}` && (goods_sn == item.sku || actual_skc == item.sku)
      })
    return {
      firstReturnReasonList: firstReturnReasonList?.[0]?.return_goods_reason_list || [],
      questionList: firstReturnReasonList?.[0]?.question_list || []
    }
  } else {
    return {
      firstReturnReasonList: defaultReturnReason(language),
      questionList: []
    }
  }
}

export function handleReturnInfo(returnInfo, type) {
  const returnable = []
  const unreturnable = []
  returnInfo &&
    returnInfo.forEach((item, index) => {
      returnInfo[index]['return_reason'] = []
      returnInfo[index]['itemImgs'] = []
      returnInfo[index]['showError'] = false
      returnInfo[index]['showReasonError'] = false
      returnInfo[index]['selectedReasonId'] = ''
      returnInfo[index]['selectedReasonText'] = ''
      returnInfo[index]['reasonExplanation'] = ''
      returnInfo[index]['returnDesc'] = ''
      returnInfo[index]['selectedSecondReasonIds'] = []
      returnInfo[index]['itemVideos'] = []
      returnInfo[index]['imageExplanation'] = ''
      returnInfo[index]['imageList'] = []
      returnInfo[index]['clientNeedImage'] = ''
      returnInfo[index]['disabled'] = false

      const quantity = item.quantity
      if (item.returnable) {
        const returnableItem = hasSizeMerge({ item, list: returnable })
        if (returnableItem.length) {
          returnableItem[0].order_goods_id_quantity.push({
            order_goods_id: item.order_goods_id,
            quantity: quantity,
            tags: item.tags
          })
          returnableItem[0].quantity = 1
          returnableItem[0].returnable_max_number += Number(quantity)
        } else {
          item.checked = false
          item.returnable_max_number = Number(quantity)
          item.order_goods_id_quantity = [
            { 
              order_goods_id: item.order_goods_id, 
              quantity: quantity,
              tags: item.tags
            }
          ]
          returnable.push(item)
        }
      } else if (item.reason != 3) {
        const returnableItem = hasSizeMerge({ item, list: unreturnable })
        if (returnableItem.length) {
          returnableItem[0].quantity += quantity
        } else {
          unreturnable.push(item)
        }
      }
    })

  // 如果是跨订单都是不可退 则返回空
  if (type && returnable.length == 0) return []

  returnInfo = returnable.concat(unreturnable)
  return returnInfo
}

const getFileName = (file) => {
  if (file && typeof file === 'string') {
    const lastIndex = file.lastIndexOf('.')
    const extension = file.slice(lastIndex + 1)
    return extension.toUpperCase()
  }
  return ''
}

// 免费退货订单处理
export function handleFreeReturn (data = {}) {
  const {
    goodsReturnList, // 可退货列表 
    freeReturnList, // 免费退货列表
    newReturnReason, // 退货原因
    orderInfo,
    language
  } = data
  // 根据以合并的退货商品聚合免费退的商品
  const newFreeReturnList = []
  goodsReturnList.forEach(item => {
    const orderGoodsIds = (item.order_goods_id_quantity || []).map(i => i.order_goods_id)
    let list = freeReturnList.filter(i => orderGoodsIds.includes(i.order_goods_id))
    if (list.length) {
      newFreeReturnList.push({ 
        ...list[0], 
        order_goods: list,
        quantity: list.length 
      })
    }
  })
  const newList = cloneDeep(goodsReturnList)
  newFreeReturnList.forEach(child => {
    const returnIndex = newList.findIndex(item => {
      const orderGoodsIds = (item.order_goods_id_quantity || []).map(i => i.order_goods_id)
      return orderGoodsIds.includes(child.order_goods_id)
    })
    const returnableItem = newList[returnIndex]

    if (returnableItem && returnableItem.returnable == 1) {
      // 免费退货数量小于最大可退货数量，进行拆分
      if (child.quantity < returnableItem.returnable_max_number) {
        const orderGoodsList = returnableItem.order_goods_id_quantity || []
        const freeOrderGoodsIds = child.order_goods.map(j => j.order_goods_id)
        const newItem = cloneDeep(returnableItem)
        const equalList = [], notEqualList = []
        
        orderGoodsList.forEach(i => {
          if (freeOrderGoodsIds.includes(i.order_goods_id)) {
            equalList.push(i)
          } else {
            notEqualList.push(i)
          }
        })
        // 当前商品可能是聚合后的非免费退商品，重置为免费退order_goods_id
        if (equalList.length) {
          const order_goods_id = equalList[0].order_goods_id
          returnableItem.id = order_goods_id
          returnableItem.order_goods_id = order_goods_id
        }
        // 拆分商品赋值与免费退货商品不一样的order_goods_id
        if (notEqualList.length) {
          const order_goods_id = notEqualList[0].order_goods_id
          newItem.id = order_goods_id
          newItem.order_goods_id = order_goods_id
        }
        newItem.returnable_max_number = returnableItem.returnable_max_number - child.quantity
        newItem.order_goods_id_quantity = notEqualList
        // 拆分的商品插入到免费退货商品后面
        newList.splice(returnIndex + 1, 0, newItem)
        // 重置拆分免费退货商品的最大可退货数量
        returnableItem.returnable_max_number = child.quantity
        returnableItem.order_goods_id_quantity = equalList
      }

      // 售后自主标识(0-否；1-是)
      returnableItem.sale_after_own_flag = 1
      returnableItem.checked = true
      returnableItem.disabled = true
      returnableItem.quantity = child.quantity
      const itemImgs = [], itemVideos = []

      // 获取免费退货list中选中原因并且已经上传图片、视频的商品
      let bestItem = child
      if (child.order_goods.length > 1) {
        for (let freeItem of child.order_goods) {
          const picUrls = freeItem.pic_urls || []
          if (freeItem.one_goods_control_enum && picUrls.length) {
            bestItem = freeItem
            break
          }
        }
      }

      const videoExtensions = ['MOV', 'MP4', 'AVI', 'WMV', 'ASF', 'ASX', 'RM', 'RMVB', 'M4V', 'DAT', 'MKV', 'FLV', 'VOB']
      if (Array.isArray(bestItem.pic_urls)) {
        bestItem.pic_urls.forEach(url => {
          const extension = getFileName(url)
          if (videoExtensions.includes(extension)) {
            itemVideos.push(url)
          } else {
            itemImgs.push(url)
          }
        })
      }
      // 增加免费退是否上传图片或者视频标识
      returnableItem.isUploadTag = !!(itemImgs.length || itemVideos.length)
      if (itemImgs.length) {
        returnableItem.itemImgs = itemImgs
      }
      if (itemVideos.length) {
        returnableItem.itemVideos = itemVideos
      }

      if (bestItem.one_goods_control_enum) {
        let { firstReturnReasonList = [] } = formatReasonEvt({
          goods_sn: returnableItem.goods_sn, 
          actual_skc: returnableItem?.actual_skc || '',
          business_model: orderInfo.business_model, 
          newReturnReason: newReturnReason, 
          language: language
        })
        // 查询是否有匹配的一级原因
        const firstReasonItem = firstReturnReasonList.find(item => {
          const qualityTagInfoRespList = item.quality_tag_info_resp_list 
          if (Array.isArray(qualityTagInfoRespList)) {
            return qualityTagInfoRespList.some(i => i.first_tag == bestItem.one_goods_control_enum)
          }
          return false
        })
        if (firstReasonItem) {
          const secondReasonList = firstReasonItem.second_return_goods_reason_list || []
          returnableItem.return_reason = [firstReasonItem.first_reason_code]
          returnableItem.selectedReasonId = firstReasonItem.first_reason_code
          returnableItem.selectedReasonText = firstReasonItem.language_copy
          returnableItem.reasonExplanation = firstReasonItem.reason_explanation || ''
          returnableItem.imageExplanation = firstReasonItem.image_explanation || ''
          returnableItem.secondReturnReason = secondReasonList
          returnableItem.imageList = firstReasonItem.image_list || []
          returnableItem.clientNeedImage = firstReasonItem.client_need_image || ''
          // 查询是否有匹配的二级原因
          if (bestItem.two_goods_control_enum && Array.isArray(secondReasonList) && secondReasonList.length) {
            const secondReasonItem = secondReasonList.find(val => {
              const qualityTagInfoRespList = val.quality_tag_info_resp_list 
              if (Array.isArray(qualityTagInfoRespList)) {
                return qualityTagInfoRespList.some(i => i.second_tag == bestItem.two_goods_control_enum)
              }
              return false
            })
            if (secondReasonItem) {
              returnableItem.selectedSecondReasonIds.push(secondReasonItem.second_reason_code)
            }
          }
        }
      }
    }
  })

  const freeList = [], notFreeList = []
  newList.forEach(item => {
    if (item.disabled) {
      freeList.push(item)
    } else {
      notFreeList.push(item) 
    }
  })
  
  return freeList.concat(notFreeList)
}

export function getShippingAddress ({ addressData, orderInfo }) {
  let returnAddress = {}
  const { 
    shipping_address_1 = '',
    shipping_address_2 = '',
    shipping_street = '',
    shipping_district = '',
    shipping_city = '',
    shipping_province = '',
    shipping_country_name = '',
    shipping_postcode = '',
    shipping_country_id = '',
  } = addressData || orderInfo
  returnAddress['address_first'] = shipping_address_1
  returnAddress['address_second'] = shipping_address_2
  returnAddress['city'] = shipping_city
  returnAddress['country_id'] = shipping_country_id
  returnAddress['country_name'] = shipping_country_name
  returnAddress['distict'] = shipping_district
  returnAddress['post_code'] = shipping_postcode
  returnAddress['state'] = shipping_province
  returnAddress['street'] = shipping_street
  
  return returnAddress
}

// 获取退货渠道参数
export function getChannelAddressData({ addressData, orderInfo, returnOrdersInfoList, freeReturnList }) {
  const returnChannelAddress = getShippingAddress({ addressData, orderInfo })
  returnChannelAddress['union_return_channel_bill_info_list'] = getReturnRefundData({
    returnOrdersInfoList,
    freeReturnList,
    isChannel: true
  })
  return returnChannelAddress
}

// 判断是否是免费退货商品
export function isFreeReturnGoods (tags) {
  if (typeof tags === 'string' && tags) {
    return tags.includes('03')
  }
  return false
}

// 拆分免费退货商品
export function splitFreeReturn(goodsReturnList) {
  if (!Array.isArray(goodsReturnList)) {
    return goodsReturnList
  }
  // 判断免费退货商品是否达到最大可退数量，没有达到进行拆分
  const needSplit = goodsReturnList.some(item => {
    const total = (item.order_goods_id_quantity || []).filter(child => isFreeReturnGoods(child.tags)).length
    return total >= 1 && total < item.returnable_max_number
  })
  if (needSplit) {
    let newList = []
    goodsReturnList.forEach(item => {
      const orderGoodsList = item.order_goods_id_quantity || []
      const total = orderGoodsList.filter(child => isFreeReturnGoods(child.tags)).length
      if (total >= 1 && total < item.returnable_max_number) {
        const equalList = [], notEqualList = []
        orderGoodsList.forEach(i => {
          if (isFreeReturnGoods(i.tags)) {
            equalList.push(i)
          } else {
            notEqualList.push(i)
          }
        })
        const cloneItem = cloneDeep(item)
        // 拆分商品赋值与免费退货商品不一样的order_goods_id
        if (notEqualList.length) {
          const order_goods_id = notEqualList[0].order_goods_id
          cloneItem.id = order_goods_id
          cloneItem.order_goods_id = order_goods_id
        }
        if (typeof cloneItem.tags === 'string' && cloneItem.tags) {
          // 去掉免费退货标识
          cloneItem.tags = cloneItem.tags.replace('03', '')
        }
        cloneItem.returnable_max_number = item.returnable_max_number - total
        cloneItem.order_goods_id_quantity = notEqualList
        item.returnable_max_number = total
        item.order_goods_id_quantity = equalList
        newList.push(item, cloneItem)
      } else {
        newList.push(item)
      }
    })
    return newList
  }
  return goodsReturnList
}

// 免费退货商品排序最前面
export const sortReturnList = (goodsReturnList = []) => {
  if (Array.isArray(goodsReturnList) && goodsReturnList.some(item => item.disabled)) {
    const freeList = [], notFreeList = []
    goodsReturnList.forEach(item => {
      if (item.disabled) {
        freeList.push(item)
      } else {
        notFreeList.push(item) 
      }
    })
    return freeList.concat(notFreeList)
  }
  return goodsReturnList
}

// 获取退货渠道 退款方式 合并退参数
export function getReturnRefundData({ returnOrdersInfoList, freeReturnList, isChannel = false }) {
  let union_return_channel_bill_info_list = []
  const freeList = (freeReturnList || []).map(item => item.order_goods_id)

  returnOrdersInfoList.forEach(i => {
    const { orderInfo, returnOrderGoods } = i
    const { billno, main_order_flag, payment_method } = orderInfo
    const order_goods_id_list = returnOrderGoods.map(j => j.order_goods_id)
    const order_goods_list = returnOrderGoods.map(item => {
      const isFree = freeList.some(orderGoodsId => orderGoodsId == item.order_goods_id)
      return {
        after_sales_return_free_flag: isFree ? 1 : 0,
        order_goods_id: item.order_goods_id,
        return_reason_list: item.return_reason,
      }
    })

    const returnBillData = {
      billno,
      main_order_flag,
      order_good_id_list: order_goods_id_list, // 退货商品id
      order_goods_list,
      payment_method,
    }
    if (isChannel) {
      returnBillData.order_goods_id_list = order_goods_id_list
    }
    union_return_channel_bill_info_list.push(returnBillData)
  })

  return union_return_channel_bill_info_list
}

export function getRefundLanguage (language = {}, refundPath) {
  if (!language || !refundPath) return ''
  const refundLanguage = {
    1: language.SHEIN_KEY_PWA_16402,
    2: language.SHEIN_KEY_PWA_16289,
    3: language.SHEIN_KEY_PWA_14924,
  }
  return refundLanguage[refundPath]
}

/**
 * 初始化可退款渠道
 * info
 * receiver_country
 * payment
 * language
 *
 * @type {totalRefundUsd} 可退总金额
 * @type {giftcardUsd} 礼品卡金额
 * @type {usercardUsd} 账户金额
 * @type {walletUsd} 钱包金额
 * @type {overTwoWay} 多种支付方式集合，两种不为0表示混合支付
 */
export function initRefundChannel({ info, receiver_country, payment, language }) {
  // 接口数据
  let {
    omsData: { price = {} } = {},
    refund_path_tip = {},
    company_id,
    store_code,
    order_related_amount = {},
    refund_goods_amount = {},
    refund_cal_mark = '',
    orderCurrency = {}
  } = info || {}
  let {
    giftcard_refundable = {},
    usercard_refundable = {},
    wallet_refundable = {},
    total_refund = {},
    company_can_refund_wallet
  } = price || {}
  let { gift_card = {}, user_card = {}, wallet = {} } = refund_path_tip || {}
  // 退款方式数据
  let { invalid: walletInvalid = [], valid: walletValid = [] } = wallet || {}
  let { invalid: accountInvalid = [], valid: accountValid = [] } = user_card || {}
  let { invalid: giftCardInvalid = [], valid: giftCardValid = [] } = gift_card || {}
  // 可退款方式金额
  let totalRefundUsd = Number(total_refund?.usd || 0)
  let walletUsd = Number(wallet_refundable?.usd || 0)
  let giftcardUsd = Number(giftcard_refundable?.usd || 0)
  let usercardUsd = Number(usercard_refundable?.usd || 0)
  let overTwoWay = [walletUsd, giftcardUsd, usercardUsd].filter(usd => !!usd)

  // 金额明细展示
  let refundAmountDetail = order_related_amount || {}
  let refundGoodsAmount = refund_goods_amount || {}
  let refundCalMark = refund_cal_mark || ''

  // 退款方式
  const formatData = {
    company_id,
    receiver_country
  }
  let walletData = {
    name: 'wallet',
    refund_method: getRefundLanguage(language, 1),
    refund_method_number: 1,
    tips: walletInvalid?.[0]?.text || '',
    validTips: formatValidText({
      text: walletValid?.[0]?.text || '',
      ...formatData
    }),
    desc: '',
    disabled: !(wallet?.status == 1),
    status: wallet?.status,
    refundAmountDetail,
    refundGoodsAmount,
    refundCalMark
  }
  let accountData = {
    name: 'Payment Account',
    refund_method: getRefundLanguage(language, 2),
    refund_method_number: 2,
    tips: accountInvalid?.[0]?.text || '',
    validTips: formatValidText({
      text: accountValid?.[0]?.text || '',
      ...formatData
    }),
    desc: '',
    disabled: !(user_card?.status == 1),
    status: user_card?.status,
    isRefundAccount: 0,
    refundAmountDetail,
    refundGoodsAmount,
    refundCalMark
  }
  let giftCardData = {
    name: 'Gift Card',
    refund_method: getRefundLanguage(language, 3),
    refund_method_number: 3,
    tips: giftCardInvalid?.[0]?.text || '',
    validTips: formatValidText({
      text: giftCardValid?.[0]?.text || '',
      ...formatData
    }),
    desc: '',
    disabled: !(gift_card?.status == 1),
    status: gift_card?.status,
    refundAmountDetail,
    refundGoodsAmount,
    refundCalMark
  }

  // 支付情况
  const isTotalUsd = totalRefundUsd > 0
  const isWalletUsd = walletUsd <= 0
  const isCardUsd = usercardUsd <= 0
  const isGiftCardUsd = giftcardUsd <= 0
  const G_F_Payment = !isGiftCardUsd
  // 纯礼品卡支付
  const isGiftCardMethod = isTotalUsd && isWalletUsd && isCardUsd
  // 纯钱包支付
  const isWalletMethod = isTotalUsd && isGiftCardUsd && isCardUsd
  // 混合支付
  const isMixMethod = isTotalUsd && overTwoWay.length >= 2
  // 多主体新加只展示账户
  const isAccountMethod = isTotalUsd && isWalletUsd && isGiftCardUsd
  const isCod = payment == 'cod'
  const isHideMethod = true
  const mixData = {
    G_F_Payment,
    isHideMethod
  }
  // 返回数据
  let newRefundData = {
    // 退款列表
    list: [accountData],
    // 店铺code
    store_code,
    // 线下打款列表
    offlinePaymentsList: [],
    // 默认选中退款方式
    refundDefaultPath: '',
    // 记录初始可选中退款方式
    initRefundDefaultPath: '',
    // 确认选中的退款方式
    refundCheckPath: '',
    // 退款明细
    price,
    company_id,
    orderCurrency
  }

  if (isWalletMethod) {
    // 纯钱包支付
    newRefundData.list = isCod ? [walletData] : [walletData, { ...accountData, disabled: true }]
  } else if (isGiftCardMethod) {
    // 纯礼品卡支付
    newRefundData.list = [giftCardData]
  } else if (isMixMethod) {
    // 可混合退款
    let newWalletData = {
      ...walletData,
      ...mixData
    }
    newRefundData.list = isCod
      ? [newWalletData]
      : [
        newWalletData,
        {
          ...accountData,
          ...mixData
        },
        giftCardData
      ]
  } else if (isAccountMethod) {
    // 多主体新加只展示账户
    newRefundData.list = [{ ...accountData, isHideMethod }]
  }

  //  线下打款逻辑 旧主体钱包不可退且非纯礼品卡支付、只展示账号可退
  if (
    (company_can_refund_wallet == 2 ||
      orderLogic.isApolloCountryPayment({ payment, receiver_country })) &&
    !isGiftCardMethod
  ) {
    newRefundData.offlinePaymentsList = [
      {
        ...accountData,
        refund_method:
          receiver_country == 'Brazil'
            ? language.SHEIN_KEY_PWA_26117
            : language.SHEIN_KEY_PWA_19492,
        disabled: false,
        G_F_Payment,
        isHideMethod: false,
        isRefundAccount: 1
      }
    ]
    // 巴西支持打款且钱包字段company_can_refund_wallet=1增加钱包
    if (company_can_refund_wallet == 1) {
      let newWalletFrom = newRefundData.list.find(item => item.name == 'wallet')
      if (newWalletFrom) {
        newRefundData.offlinePaymentsList.push(newWalletFrom)
      }
    }
  }

  // 默认选中支付方式 1: 礼品卡  2: 钱包 3: 卡支付  空: 没有默认支付选项
  if (gift_card.is_default_path == 1 && gift_card.status == 1) {
    newRefundData.refundDefaultPath = 3
  } else if (user_card.is_default_path == 1 && user_card.status == 1) {
    newRefundData.refundDefaultPath = 2
  } else if (wallet.is_default_path == 1 && wallet.status == 1) {
    newRefundData.refundDefaultPath = 1
  }

  return newRefundData
}

export function formatValidText({ text = '', receiver_country, company_id }) {
  if (!text) return ''
  const isRuMainPart =
    ['Russian Federation', 'Belarus'].includes(receiver_country) && company_id !== 13
  return text.replace(/\{\$(.+)\$\}/, isRuMainPart ? '$1' : '')
}

export function checkRefundDisabled({
  returnOrdersInfoList,
  refundAccountInfo,
  type,
  curCheckBillno,
  showAccountRequired,
  checkedFrontRefundPath,
}) {
  const { show, selected } = refundAccountInfo
  if (curCheckBillno) {
    returnOrdersInfoList = returnOrdersInfoList?.filter(i => i?.orderInfo?.billno == curCheckBillno)
  }

  return returnOrdersInfoList.some(i => {
    const { payment_method: payment, shipping_country_name: receiver_country } = i.orderInfo || {}
    const { refundDefaultPath, refundCheckPath, list } = i?.refundChannelList || {}
    const path = checkedFrontRefundPath || (type ? refundDefaultPath : refundCheckPath)
    if (type) {
      return !path
    } else {
      if (!path)  return true 
      const isApolloPayment = orderLogic.isApolloCountryPayment({ payment, receiver_country })
      const isRefundAccount = list.some(j => j.isRefundAccount == 1)
      if (showAccountRequired) {
        return path == 2 && show && !isApolloPayment && isRefundAccount
      }
      return path == 2 && show && !(selected || isApolloPayment) && isRefundAccount
    }
  })
}

export function checkRefundAccount ({ 
  returnOrdersInfoList, 
  refundAccountInfo,
  checkedFrontRefundPath,
  curCheckBillno,
}) {
  const { show, selected } = refundAccountInfo
  if (curCheckBillno) {
    returnOrdersInfoList = returnOrdersInfoList?.filter(i => i?.orderInfo?.billno == curCheckBillno)
  }
  
  return returnOrdersInfoList.some(i => {
    const { payment_method: payment, shipping_country_name: receiver_country } = i.orderInfo || {}
    const { refundCheckPath, list } = i?.refundChannelList || {}
    const path = checkedFrontRefundPath || refundCheckPath
    const isApolloPayment = orderLogic.isApolloCountryPayment({ payment, receiver_country })
    const isRefundAccount = list.some((j) => j.isRefundAccount == 1)
    return path == 2 && show && isApolloPayment && isRefundAccount && !selected
  })
}

// 获取 提交合并退货 单参数
export function getReturnOrderSubmitData({ returnOrdersInfoList, newRefundCheckPath }) {
  return returnOrdersInfoList.map((i = {}) => {
    const { orderInfo, returnOrderGoods, refundChannelList } = i
    returnOrderGoods.forEach(item => {
      if (item?.actual_skc) {
        item.goods_sn = item.actual_skc
      }
    })
    const {
      whetherRefundFreight: is_refund_shipping_fee,
      whetherRefundFreightInsurance: is_refund_insurance,
      billno,
      main_order_flag
    } = orderInfo
    const { store_code } = refundChannelList
    return {
      billno,
      is_refund_insurance,
      is_refund_shipping_fee,
      main_order_flag,
      refund_path: newRefundCheckPath || refundChannelList.refundCheckPath,
      return_goods_list: returnOrderGoods,
      store_code
    }
  })
}

// 判断退货渠道是否禁用
export function isDisabledReturnMethod({ method = {}, order = {}, returnOrdersInfoList = [] }) {
  return (
    method.displayMethod == 1 ||
    method.limitConditionConfig == 2 ||
    limitWeight({
      method: method.return_shipping_type,
      country: order.shipping_country_name,
      returnOrdersInfoList
    }) ||
    [1, 2].includes(method.weightUnreachable)
  )
}
// 重量限制
function limitWeight({ method, country, returnOrdersInfoList }) {
  const limitList = {
    'ca-post': { Canada: 2000 }, // 单位g
    UBIT: { Canada: 2000 }, // 单位g
    UBIC: { Canada: 2000 }, // 单位g
    TAUP: { Australia: 20000 }, // 单位g
    AUTH: { Australia: 20000 } // 单位g
  }
  if (limitList[method] && limitList[method][country]) {
    let totalWeight = 0
    returnOrdersInfoList.forEach(i => {
      const { returnOrderGoods } = i
      returnOrderGoods.forEach(j => {
        totalWeight += j.weight
      })
    })

    if (totalWeight > limitList[method][country]) {
      return true
    }
  }
  return false
}

export function isShowUploadRuler(item = {}) {
  let { selectedSecondReasonIds, imageExplanation, imageList, secondReturnReason } = item || {}
  if (selectedSecondReasonIds?.length == 1) {
    let curSecondReason =
      secondReturnReason?.find(list => {
        return list.second_reason_code == selectedSecondReasonIds?.[0]
      }) || {}
    return (
      !!(curSecondReason?.image_explanation || imageExplanation) &&
      (imageList?.length || curSecondReason?.image_list?.length)
    )
  }
  return imageList?.length && !!imageExplanation
}

export function isUploadReasonOrFileEvt(item = {}) {
  // 1: 没有选择一级原因
  // 2: 选择了一级原因但是一级原因对应的图片或者视频没有上传
  // 3: 选择了二级原因但是二级原因对应的图片或者视频没有上传
  let {
    selectedReasonId = '',
    selectedSecondReasonIds = [],
    secondReturnReason = [],
    itemImgs = [],
    itemVideos = [],
    clientNeedImage = ''
  } = item || {}
  if (!selectedReasonId) return 1
  if (!!selectedReasonId && clientNeedImage == 1 && !itemImgs.length && !itemVideos.length) {
    return 2
  }
  if (!!selectedSecondReasonIds?.length) {
    // 只要存在一个二级原因需要上传附件, 就提示要上传附件
    selectedSecondReasonIds = selectedSecondReasonIds?.map(item => +item)
    let curSelectedReasonList = secondReturnReason?.filter(
      item =>
        selectedSecondReasonIds?.includes(+item.second_reason_code) &&
        item.client_need_image == 1
    )
    if (curSelectedReasonList?.length && !itemImgs.length && !itemVideos.length) {
      return 3
    }
  }
  return 0
}

//获取渠道原始名称
export function getChannelName(name) {
  if (typeof name === 'string') {
    return name.replace(APPEND_KEY, '')
  }
  return name
}

//追加合并退订单拆分合并
export function handlerUnionReturn (unionReturnList = []) {
  const unionChannelList = [], unionChannelCardList = []

  // 可追加退货单的退货渠道列表
  unionReturnList.forEach(unionItem => {
    if (Array.isArray(unionItem.return_order_info_list)) {
      unionItem.return_order_info_list.forEach(i => {
        // 根据return_order_no和shipping_type分类
        let cardItem = unionChannelCardList.find(child => {
          return (
            child.shipping_type == i.shipping_type &&
            child.return_order_no == i.return_order_no &&
            child.union_return_no == unionItem.union_return_no
          )
        })
        if (cardItem) {
          cardItem.list.push(i)
        } else {
          unionChannelCardList.push({
            union_return_no: unionItem.union_return_no,
            shipping_type: i.shipping_type,
            return_order_no: i.return_order_no,
            refund_path: i.refund_path,
            list: [i],
          })
        }
        // 获取所有追加渠道
        if (i.shipping_type && !unionChannelList.includes(i.shipping_type)) {
          unionChannelList.push(i.shipping_type)
        }
      })
    }
  })

  return { unionChannelList, unionChannelCardList }
}

// 累加勾选的商品数量
const sumByReturnList = (returnList) => {
  if (!Array.isArray(returnList)) return 0
  const list = []
  returnList.forEach(item => {
    if (Array.isArray(item.returnOrderGoods)) {
      const quantityList = item.returnOrderGoods.map(child => {
        const num = Number(child.quantity)
        return isNaN(num) ? 0 : num
      })
      list.push(...quantityList)
    }
  })
  return list.reduce((total, num) => total + num, 0)
}

// 排序同标题下面的渠道
export const sortChannelList = (list = []) => {
  if (list.length <= 1) return list
  let checkedList = [], unCheckedList = []
  list.forEach(item => {
    // 可选的排序在前面，保持基础顺序不变
    if (item.limitConditionConfig != 2 && !item.displayMethod && !item.weightUnreachable) {
      checkedList.push(item)
    } else {
      unCheckedList.push(item)
    }
  })
  // 存在可选渠道-限量渠道，则渠道展示优先级默认最低（但是高于不可选渠道-超重、不可选渠道-限量的退货渠道）
  if (checkedList.length > 1 && checkedList.some(i => i.limitConditionConfig == 1)) {
    const frontList = [], postposeList = []
    checkedList.forEach(item => {
      if (item.limitConditionConfig == 1) {
        postposeList.push(item)
      } else {
        frontList.push(item)
      }
    })
    checkedList = [...frontList, ...postposeList]
  }
  return [...checkedList, ...unCheckedList]
}

const sortTitleList = (list = []) => {
  const checkedList = [], unCheckedList = []
  list.forEach((item, index) => {
    item.sortIndex = index
    // 可选的排序在前面。保持基础顺序不变
    if (item.hasDisabled) {
      unCheckedList.push(item)
    } else {
      checkedList.push(item)
    }
  })
  // 将单个限量的渠道后置
  checkedList.sort((a, b) => {
    if (a.isSingleLimit === b.isSingleLimit) {
      return a.sortIndex - b.sortIndex
    }
    return a.isSingleLimit ? 1 : -1
  })
  return [...checkedList, ...unCheckedList].map(i => i.title)
}

// 退货渠道排序，追加退货排在最前
export function handlerSortChannel (data = {}) {
  let { 
    logisticsChannel = [],
    unionChannelList = [], 
    unionChannelCardList = [],
    batch_return_channel_bill_info_list = [],
    HAPPY_RETURN_CHANNEL,
    showTmsRuleSortChannel = false 
  } = data
  // 过滤不可选的渠道
  logisticsChannel = (logisticsChannel || []).filter(item => !item.displayMethod)
  logisticsChannel.forEach((item, index) => {
    // 由于追加渠道和普通渠道的channelName相同，又要显示普通渠道，使用别名channelNameAlias保持唯一
    item.channelNameAlias = item.channelName
    // 记录当前排序号
    item.sortNum = showTmsRuleSortChannel ? index : item.priority
  })
  if (!showTmsRuleSortChannel) {
    // 普通渠道根据priority字段排序，字段值最小的优先级最高
    logisticsChannel.sort((a, b) => a.priority - b.priority)
  }
  // 后端返回的渠道排序列表
  const channelList = logisticsChannel.map(item => item.channelName)
  if (Array.isArray(unionChannelList) && unionChannelList.length) {
    const topSortList = [], noSortList = [], sortList = [], selfSendingList = []
    const currencyItem = (batch_return_channel_bill_info_list || []).find(
      item => item.currency_code
    )
    // 可追加退货单的退货渠道不排序直接放最前面
    logisticsChannel.forEach(item => {
      const channelName = item.channelName
      // 如果HappyReturn所在分组存在可追加渠道，则屏蔽追加渠道不展示
      if (unionChannelList.includes(channelName)) {
        //复制一份普通渠道的数据，排在追加渠道后面
        const cloneItem = cloneDeep(item) 
        if (channelName != HAPPY_RETURN_CHANNEL) {
          sortList.push(cloneItem)
        }
        //追加渠道名字重新命名，加上追加标识
        item.channelNameAlias = `${channelName}${APPEND_KEY}`
        // 将可追加退货单的商品放在对应的渠道下
        item.unionReturnList = unionChannelCardList.filter(
          child => child.shipping_type == channelName
        )
        item.currency_code = currencyItem?.currency_code || ''
        item.isAppendReturn = true
        if (channelName == HAPPY_RETURN_CHANNEL) {
          topSortList.push(item, cloneItem)
        } else {
          noSortList.push(item)
        }
      } else if (item.returnType == 4) {
        selfSendingList.push(item)
      } else {
        sortList.push(item)
      }
    })
    // 根据追加接口返回的渠道优先级排序追加渠道
    noSortList.sort((a, b) => {
      return channelList.indexOf(a.channelName) - channelList.indexOf(b.channelName)
    })
    // 如果存在happy_return渠道，并且勾选数量<7，将happy_return渠道放在第一位
    logisticsChannel = topSortList.concat(noSortList).concat(sortList).concat(selfSendingList)
  } else {
    const selfIndex = logisticsChannel.findIndex(item => item.returnType == 4)
    if (selfIndex > -1) {
      const selfSendingList = logisticsChannel.splice(selfIndex, 1)
      logisticsChannel = logisticsChannel.concat(selfSendingList)
    }
    const happyReturnIndex = logisticsChannel.findIndex(item => item.channelName == HAPPY_RETURN_CHANNEL)
    if (happyReturnIndex > -1) {
      const happyReturnList = logisticsChannel.splice(happyReturnIndex, 1)
      logisticsChannel = happyReturnList.concat(logisticsChannel)
    }
  }
  return logisticsChannel
}

// 退货渠道分组标题排序
export function handlerSortChannelTitle (data = {}) {
  let { 
    logisticsChannel = [],
    HAPPY_RETURN_CHANNEL,
    returnList = [],
    returnMethodTitle = [],
  } = data
  returnMethodTitle = [...returnMethodTitle]
  if (returnMethodTitle.length <= 1) {
    return returnMethodTitle
  }
  let topSortList = [], noSortList = [], sortList = [], selfSendingList = []
  returnMethodTitle.forEach(title => {
    const channelList = logisticsChannel.filter(j => j.title == title)
    if (channelList.some(item => item.channelName === HAPPY_RETURN_CHANNEL)) {
      topSortList.push(title)
    } else if (channelList.some(item => item.isAppendReturn)) {
      noSortList.push(title)
    } else if (channelList.some(item => item.returnType == 4)) {
      selfSendingList.push(title)
    } else {
      sortList.push({ 
        title, 
        // 标题下面是单个限量渠道
        isSingleLimit: channelList.length == 1 && channelList[0].limitConditionConfig == 1,
        hasDisabled: channelList.some(item => {
          // limitConditionConfig - 0-不限量 1-可勾选 2-置灰
          return !!item.weightUnreachable || item.limitConditionConfig == 2
        })
      })
    }
  }) 
  sortList = sortTitleList(sortList) //将有超重的排序放到后面
  returnMethodTitle = topSortList.concat(noSortList).concat(sortList).concat(selfSendingList)

  if (topSortList.length) {
    const total = sumByReturnList(returnList)
    if (total >= 7) {
      const happyReturnList = returnMethodTitle.splice(0, 1)
      // 如果存在happy_return渠道，并且勾选数量>=7，将happy_return渠道放在第二位
      returnMethodTitle.splice(1, 0, ...happyReturnList)
    } 
  }
  return returnMethodTitle
}

// 转换distance
export function formatDistance(value) {
  if (!value) return ''
  const newValue = parseFloat(value)
  if (typeof newValue === 'number') {
    const transferValue = Math.max(0.01, newValue * 0.00062137119223733) 
    return transferValue.toFixed(2)
  }
  return '' 
}

export function formatAddressList (list = []) {
  list.forEach(item => {
    if (!item.image) {
      item.image = iconUrl
    }
  })
  return list
}

// 过滤特殊字符
export const filterSpecialString = (s) => {
  if (!s || typeof s !== 'string') return ''
  const pattern = new RegExp('[`#^&*|{}\'\'\\[\\]<>￥……&*（）——|{}【】‘；：”“\'。，、？]', 'g')
  
  return s.replace(pattern, '')
}

// 过滤url中的参数并替换url
export const replaceLocationUrl = (filterKeys = []) => {
  let str = location.pathname
  const query = parseQueryString(location.search)
  filterKeys.forEach(key => {
    delete query[key]
  })
  const newSearch = stringifyQueryString({ queryObj: query })
  if (newSearch) {
    str = `${str}?${newSearch}`
  }
  history.replaceState(null, null, str)
}

// 获取退货商品列表 0-退货商品 1-不退货商品
export const getGoodsReturnList = (returnOrderList = [], type) => {
  if (!Array.isArray(returnOrderList)) return []
  const filterList = returnOrderList.filter(item => item.order_no_return == type)
  if (filterList.length) {
    return filterList.map(item => {
      return {
        billno: item.order_no,
        storeName: orderLogic.isSheinStore(item.store_code) ? 'SHEIN' : item.store_name || '',
        returnOrderGoods: (item.return_order_goods_list || []).filter(
          child => child.goods_no_return == type
        )
      }
    })
  }
  return []
}

export const mergeReturnList = (goodsList = []) =>{
  const aggregatedData = goodsList.reduce((acc, current) => {
    const { storeName, billno, returnOrderGoods } = current

    // 如果累积器中没有同名 storeName，创建一个新的条目
    if (!acc[storeName]) {
      acc[storeName] = {
        storeName,
        returnOrderGoods: [],
      }
    }

    // 将当前的 returnOrderGoods 合并到对应的 storeName 下
    acc[storeName].returnOrderGoods.push({
      billno,
      goods: returnOrderGoods
    })

    return acc
  }, {})

  const result = Object.values(aggregatedData)
  result.forEach(item => {
    item.returnOrderGoods.forEach(child => {
      child.total = child.goods.reduce((acc, current) => {
        return acc + current.quantity
      }, 0)
    })
  })
  // 店铺层级按照相同店铺下不同商品sku_code的数量由大到小排序；
  result.forEach(item => {
    item.returnOrderGoods.sort((a, b) => b.total - a.total)
  })
  return result
}

// 获取当前支持的退款方式
export const getRefundStatus = (list = []) => {
  const refundStatus = {
    wallet: false,
    account: false,
    giftCard: false,
    isRefundAccount: false
  }
  list.forEach(method => {
    let isShow = !(method.isHideMethod && method.disabled)
    if (method.status == 1) {
      isShow = true
    }
    if (method.refund_method_number == 1 && isShow) {
      refundStatus.wallet = true
    } else if (method.refund_method_number == 2 && isShow) {
      refundStatus.account = true
      if (method.isRefundAccount) {
        refundStatus.isRefundAccount = true
      }
    } else if (method.refund_method_number == 3 && isShow) {
      refundStatus.giftCard = true
    }
  })
  return refundStatus
}

export const getUnions = ({
  returnUnions = [], 
  listProperty,
  mainBillno = '',
  sortProperty = ''
}) => {
  const newList = returnUnions.reduce((prev, cur) => {
    if (cur[listProperty].length) {
      prev.push({ ...cur })
    }
    return prev
  }, [])
  if (sortProperty) {
    newList.sort((a, b) => b[sortProperty] - a[sortProperty])
  }
  if (mainBillno) {
    const mainOrderIndex = newList.findIndex(item => item.billno == mainBillno)
    if (mainOrderIndex > -1) {
      const mainOrder = newList.splice(mainOrderIndex, 1)
      newList.unshift(...mainOrder)
    }
  }
  return newList
}

// 获取退货商品总数 0-退货商品 1-不退货商品
export const getGoodsReturnTotal = (returnOrderList = [], type) => {
  let total = 0
  if (!Array.isArray(returnOrderList)) return total
  returnOrderList.forEach(item => {
    if (item.order_no_return == type) {
      (item.return_order_goods_list || []).forEach(child => {
        if (child.goods_no_return == type) {
          total += Number(child.quantity) || 0
        }
      })
    }
  })
  return total
}

// 判断数组是否有重复选项
export const hasDuplicates = (arr) => {
  if (arr.length <= 1) return false
  return new Set(arr).size !== arr.length
}

// 判断是否有不同尺码的商品
export const hasDifferentSize = (arrList = []) => {
  if (arrList.length <= 1) return false
  let firstAttr = arrList[0]
  return arrList.some(item => item != firstAttr)
}

// 根据sn码聚合属性值列表
export const getAttrMap = (goodsReturnList = []) => {
  return (goodsReturnList || []).reduce((prev, cur) => {
    prev[cur.goods_sn] = prev[cur.goods_sn] || []
    const attrValue = (cur.sku_sale_attr || []).map(i => {
      return i.attr_value_name_en || ''
    })
    prev[cur.goods_sn].push(attrValue.join('-'))
    return prev
  }, {})
}

/**
 * 复制文案到粘贴板
 * @param {String} trigger 触发dom选择器
 * @param {} config 配置
 */
export function useClipboard(trigger, config) {
  const { text, onSuccess, onError } = config

  const clipboard = new Clipboard(trigger, {
    text: () => text
  })

  isFunction(onSuccess) && clipboard.on('success', (e) => {
    onSuccess.call(null, e)
    e.clearSelection()
    clipboard.destroy()
  })

  isFunction(onError) && clipboard.on('error', (e) => {
    onError.call(null, e)
    e.clearSelection()
    clipboard.destroy()
  })
}
