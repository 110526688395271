import { promiseAsyncAuto } from '../asyncAuto.js'
import { getPickedGenerateBffParams } from '../bffParamsHandle/index.js'
import {
  goodsApiCache,
  pickedFilterApiCache,
  pickedCloudTagApiCache,
} from '../api/bffApi.js'
import { generateRealSelectBffFilterInfo } from '../utils'
import { getCccFeedbackData, getSheinClubInfo } from './common.js'

const getPickedRequestQueue = ({ requestInfo, newSrcTabPageId }) => {
  const { goodsParams, filterParams, cloudTagParams } = getPickedGenerateBffParams(requestInfo, newSrcTabPageId)
  const requestQueue = {
    // 负反馈
    ...getCccFeedbackData(),
    // 付费会员
    ...getSheinClubInfo(),
    // 商品列表
    goods: goodsApiCache.request({
      type: 'picked',
      params: goodsParams,
      addUserBehaviorParams:
        requestInfo.query?.channelIndex === '0' &&
        ![12, 13].includes(+requestInfo.query?.carrierSubType) &&
        requestInfo.query?.addUserBehaviorParams,
      withoutAbort: requestInfo.query?.withoutAbort,
    }),
    // 筛选
    filterData: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, {})
      const result = await promiseAsyncAuto({
        bffCateAttrResult: pickedFilterApiCache.request({ params: filterParams }), // 分类/属性/tsp筛选
        bffCloudTagResult: pickedCloudTagApiCache.request({ params: cloudTagParams }), // 标签云
      })
      cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
    }
  }
  return requestQueue
}

export {
  getPickedRequestQueue
}
