import mutations from '../product_list_v2/mutations'

export default {
  ...mutations,
  // 搜索反馈入口
  // initSearchFeedback({ ComponentState }, { language, cccOperation: { mobile_feedback_entry }, catInfo }) {
  //   if (!mobile_feedback_entry) return
  //   if (catInfo.requestType === 'firstload') {
  //     ComponentState.SearchFeedback = {
  //       showTips: false,
  //       lock: false,
  //       hrefTarget: mobile_feedback_entry.hrefTarget,
  //       text: mobile_feedback_entry.text,
  //       sendLang: language.SHEIN_KEY_PWA_16977,
  //       thankLang: language.SHEIN_KEY_PWA_15692
  //     }
  //   } else if (+catInfo.page === 3 && catInfo.type === 'search' && ComponentState.SearchFeedback) {
  //     ComponentState.SearchFeedback.showTips = true
  //   }
  // },
}
