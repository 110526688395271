export default {
  // 腰带文案最小字号
  beltTextMinFontSize({ abtInfo }) {
    if (abtInfo.shortstockUI?.param?.shortstockUI === 'plus') {
      return 9
    }
    return 8
  },
  shoppingBagHeadInfoMap({ checkout }) {
    return checkout?.shopping_bag_head_info?.reduce((acc, item) => {
      acc[item.mall_code] = item
      return acc
    }, {})
  }
}
