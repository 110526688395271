<template>
  <swiper-container
    ref="swiperRef"
    :style="swiperStyle"
    init="false"
    :destroy-on-disconnected="destroyOnDisconnected"
  >
    <slot :virtual-data="virtualData"></slot>
  </swiper-container>
</template>

<script>
import { register } from 'swiper/element'
import { Virtual } from 'swiper/modules'
// import expose from 'public/src/services/expose/index.js'

if (typeof window !== 'undefined') {
  register()
}

export default {
  name: 'VirtualSwiper',
  props: {
    // virtual scroll 完整数据源
    sourceData: {
      type: Array,
      default: () => []
    },
    swiperContainerStyleBeforeInit: {
      type: Object,
      default: () => ({})
    },
    /**
     * 开启virtual scroll时初始渲染条数（主要用于ssr直出渲染)
     * @default 6
     */
    initVirtualNum: {
      type: Number,
      default: 6
    },
    /**
     * 组件激活时是否更新swiper
     * @default true
     */
    activatedUpdate: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default: () => ({})
    },
    withGap: {
      type: Boolean,
      default: false
    },
    changeActiveIndex: {
      type: Function,
      default: null
    },
    destroyOnDisconnected: {
      type: String,
      default: 'true'
    }
  },
  data() {
    return {
      obInstance: null,
      virtualData: this.sourceData.slice(0, this.initVirtualNum),
      // 记录切换页面前的activeIndex
      lastActiveIndex: 0,
      isInstanceInited: false,
      rem: 0,
    }
  },
  computed: {
    sourceDataLength() {
      return this.sourceData.length
    },
    swiperStyle() {
      // 服务端渲染时的样式 with-gap
      const styleBeforeInitMap = {}
      // ssr时的样式占位，width&margin, 使得slider-item初始化前的宽度calc(100%-xx/yy)能正常进行
      if (this.withGap) {
        styleBeforeInitMap['width'] = '100%'
        styleBeforeInitMap['margin-left'] = '0.27rem'
        styleBeforeInitMap['overflow'] = 'hidden'
      }
      return this.isInstanceInited
        ? {}
        : {
          // 控制virtual-swiper初始化前的样式, minHeight
          ...this.swiperContainerStyleBeforeInit,
          ...styleBeforeInitMap,
        }
    }
  },
  mounted() {
    if(this.withGap){
      this.rem = parseFloat(document.querySelector('html').offsetWidth / 10)
      window.addEventListener('resize', this.resetRem)
    }
    this.initSwiperInstance()
    // this.listenInstanceExpose()
  },
  activated() {
    this.initSwiperInstance()
    this.activatedUpdate && this._asyncUpdateSwiper()
  },
  beforeDestroy() {
    this.withGap && window.removeEventListener('resize', this.resetRem)
    this.$nextTick(() => {
      this.destroySwiperInstance()
    })
  },
  methods: {
    initSwiperInstance() {
      if (!this.swiperInstance) {
        const swiperEl = this.$refs.swiperRef
        if(!swiperEl) return
        const swiperOptions = {
          slidesOffsetBefore: this.withGap ? 0.27 * this.rem : 0,
          slidesOffsetAfter: this.withGap ? 0.27 * this.rem : 0,
          ...this.options,
          modules: [Virtual],
          on: {
            beforeDestroy: swiper => {
              this.lastActiveIndex = swiper.activeIndex
            },
            activeIndexChange: (swiper) => {
              this.changeActiveIndex?.(swiper)
            }
          },
          virtual: {
            slides: this.sourceData,
            renderExternal: data => {
              const { slides, from, to } = data
              const intToIdx = parseInt(to)
              // ps:设置临时数据是为了只更新一次响应式数据virtualData, 避免多次更新
              let casualVirtualDataLength = this.virtualData.length
              const casualVirtualData = []

              if (
                casualVirtualDataLength >= this.sourceDataLength ||
                casualVirtualDataLength > intToIdx
              ) {
                return
              }

              slides.forEach((item, index) => {
                if (from + index >= this.virtualData.length) {
                  casualVirtualData.push(item)
                  casualVirtualDataLength++
                }
              })

              if (casualVirtualData.length) {
                this.virtualData.push(...casualVirtualData)
              }
            }
          }
        }
        Object.assign(swiperEl, swiperOptions)
        this.isInstanceInited = true
        swiperEl.initialize()
        this.swiperInstance = swiperEl.swiper
      } else {
        if (this.swiperInstance.destroyed) {
          const swiperEl = this.$refs.swiperRef
          if(!swiperEl) return
          Object.assign(swiperEl, {
            initialSlide: this.lastActiveIndex
          })
          swiperEl.initialize()
          this.swiperInstance = swiperEl.swiper
        } else {
          console.warn('swiperInstance已存在')
        }
      }
    },
    resetRem(){
      this.rem = parseFloat(document.querySelector('html').offsetWidth / 10)
    },
    //@Deprecated 监控视口方法,
    // listenInstanceExpose(){
    //     if(!this.obInstance){
    //          this.obInstance = new expose({ observeHide: true })
    //         this.obInstance.observe({
    //             elements: this.$el,
    //             once: false,
    //         }, ({ exposeDoms }) => {
    //             if (!this.swiperInstance) return

    //             if (exposeDoms.length) {
    //                 // 进入视口
    //                 console.log("🚀 ~ file: VirtualSwiper.vue:107 ~ listenInstanceExpose ~ 进入视口:")
    //                 this.initSwiperInstance()
    //             } else {
    //                 // 离开视口
    //                 console.log("🚀 ~ file: VirtualSwiper.vue:107 ~ listenInstanceExpose ~ 离开视口:")
    //                 // do nothing
    //             }
    //         })
    //     }
    // },
    /**
     * 手动更新使用virtual scroll swiper实例（一般为数据更新后，手动更新）
     */
    updateVirtualSwiperInstance() {
      if (this.swiperInstance?.virtual) {
        this.swiperInstance.virtual.slides = this.sourceData
      }
      this.swiperInstance?.virtual?.update()
      const intToIdx = parseInt(this.swiperInstance?.virtual?.to) + 1 || this.initVirtualNum
      this.virtualData = this.sourceData.slice(0, intToIdx)

    },
    /**
     * 去除sourceData 中某个数据使用 updateVirtualSwiperInstance 更新存在说虚拟列表展示为空白的情况，
     * 在组件使用处手动更新 sourceData 数据后，手动更新使用 virtualData 来暂时避免空白出现
     */
    filterVirtualData(goods_id) {
      this.virtualData = this.virtualData.filter(item => item.goods_id !== goods_id)
    },
    destroySwiperInstance() {
      this.swiperInstance?.destroy()
      delete this.swiperInstance
      // 曝光的销毁
      this.obInstance?.destroy?.()
      this.obInstance = null
    },
    _asyncUpdateSwiper(){
      requestIdleCallback(() => {
        this._updateSwiper()
      })
    },
    // update loop,nav,pagination
    _updateSwiper() {
      if (this.autoUpdate && this.swiperInstance) {
        this._autoReLoopSwiper()
        this.swiperInstance?.update?.()
        // this.swiperInstance.navigation?.update?.()
        // this.swiperInstance.pagination?.render?.()
        // this.swiperInstance.pagination?.update?.()
      }
    },
    // loop(轮播项无限滚动)为true时， vue组件update时 需要reLoop
    _autoReLoopSwiper() {
      if (this.swiperInstance && this.swiperOptions.loop) {
        // this.swiperInstance?.loopDestroy?.()
        // this.swiperInstance?.loopCreate?.()
      }
    },
  }
}
</script>
