<template>
  <div class="productEstiamtedTag">
    <EstimatedNoThresholdFloor
      v-if="isShowNoThresholdFloor"
      :estimated-info="estimatedInfo"
      @clickEstimatedTag="clickEstimatedTag({isAddOn: true})"
    />
    <EstimatedTagNew
      v-else-if="isNew"
      :estimated-info="estimatedInfo"
      :has-link="hasLink"
      :is-wrap="isWrap"
      @clickEstimatedTag="clickEstimatedTag"
    />
    <EstimatedTagOld
      v-else-if="isOld"
      :estimated-info="estimatedInfo"
      :has-link="hasLink"
      @clickEstimatedTag="clickEstimatedTag"
    />
  </div>
</template>

<script setup name="EstiamtedTag">
import { onMounted, watch, computed, nextTick } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import EstimatedTagNew from './components/EstimatedTagNew.vue'
import EstimatedTagOld from './components/EstimatedTagOld.vue'
import EstimatedNoThresholdFloor from './components/EstimatedNoThresholdFloor.vue'

import { ESTIMATED_STYLE_TYPES, ESTIMATED_DATA_TYPES } from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'

daEventCenter.addSubscriber({ modulecode: '1-6-1' })

const emits = defineEmits(['clickEstimatedTag', 'loaded'])
const props = defineProps({
  estimatedInfo: {
    type: Object,
    default: () => ({})
  },
  isNoThresholdFloor: {
    type: Boolean,
    default: false
  },
  isFloorType: {
    type: Boolean,
    default: false
  },
  isWrap: {
    type: Boolean,
    default: false
  }
})

const isNew = computed(() => {
  let { NEW, NEW_AND_NO_THRESHOLD_FLOOR } = ESTIMATED_STYLE_TYPES
  return [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(props.estimatedInfo.styleType)
})

const goodsSn = computed(() => props.estimatedInfo.otherInfo.goodsSn)

const hasLink = computed(() => props.estimatedInfo.otherInfo.hasLink)

const showNoSatisfied = computed(() => props.estimatedInfo.otherInfo.showNoSatisfied)

const isOld = computed(() => {
  let { OLD, OLD_NO_THRESHOLD, OLD_N_ESTIMATED } = ESTIMATED_STYLE_TYPES
  if (props.estimatedInfo.styleType == OLD_NO_THRESHOLD) {
    return showNoSatisfied.value
  }
  return [OLD, OLD_N_ESTIMATED].includes(props.estimatedInfo.styleType)
})

const isShowNoThresholdFloor = computed(() => {
  return props.isNoThresholdFloor
})

const analysisData = computed(() => {
  let { estimatedInfo } = props || {}
  let { dataType, otherInfo, value, endTime, noThresholdFloor } = estimatedInfo || {}
  let { NO_SATISFY, AB_OVERLAP_NO_SATISFY } = ESTIMATED_DATA_TYPES
  let { isQuickAdd } = otherInfo
  let nothroshowtype = null
  if ([NO_SATISFY, AB_OVERLAP_NO_SATISFY].includes(dataType)) {
    nothroshowtype = !!noThresholdFloor ? 1 : 0
  }
  return {
    threshold: dataType,
    estimated_price: value.usdAmount,
    timelimit: !!(noThresholdFloor?.endTime || endTime) ? 1 : 0,
    showtype: isNew.value ? 1 : 0,
    clubprice: 0,
    location: isQuickAdd ? 'popup' : 'page', // 商详 page 加车弹窗 popup
    ...(nothroshowtype === null ? {} : { nothroshowtype })
  }
})

const triggerExpose = () => {
  if (props.isFloorType) return
  if (!isShowNoThresholdFloor.value && !isOld.value && !isNew.value) return
  window._exposeEstimatedTagFn_?.({
    daId: '1-6-1-143',
    extraData: analysisData.value
  })
}

onMounted(() => {
  triggerExpose()
  nextTick(() => {
    emits('loaded')
  })
})


watch(
  () => goodsSn.value,
  val => {
    if (val) triggerExpose()
  }
)

const clickEstimatedTag = (params) => {
  daEventCenter.triggerNotice({
    daId: '1-6-1-142',
    extraData: analysisData.value
  })
  emits('clickEstimatedTag', params)
}

</script>

<style lang="less">
.productEstiamtedTag {
  display: flex;
}
</style>
