<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-goods-price">
    <!-- 价格顶部区域 -->
    <div class="bsc-cart-item-goods-price__top">
      <!-- 顶部左侧 -->
      <!-- 放价格 -->
      <div
        :class="leftClasses"
      >
        <div
          class="bsc-cart-item-goods-price__left-before"
          :class="{
            'bsc-cart-item-goods-price__hide-other-price': isShowPricePrefixText,
          }"
        >
          <span class="bsc-cart-item-goods-price__promo-price">
            <!-- 营销图标 -->
            <PromotionIcon
              v-if="isShowPromotionIcon"
              class="bsc-cart-item-goods-price__promotion-icon bsc-cart-item-goods-price_mask"
              :is-show-premium-member-icon="isShowPremiumMemberIcon"
              :is-show-time-icon="isShowTimeIcon"
              :is-show-app-only-icon="isShowAppOnlyIcon"
              :is-show-brand-sale-icon="isShowBrandSaleIcon"
              :is-show-flash-sale-icon="isShowFlashSaleIcon"
              :premium-member-icon-color="premiumMemberIconColor"
              :time-icon-color="timeIconColor"
              :app-only-icon-color="appOnlyIconColor"
              :brand-sale-icon-color="brandSaleIconColor"
              :flash-sale-icon-color="flashSaleIconColor"
            />
            <SheinVip
              v-if="isShowSheinVipIcon"
              class="bsc-cart-item-goods-price__shein-vip-icon bsc-cart-item-goods-price_mask"
            />
            <span
              v-if="salePrice"
              class="bsc-cart-item-goods-price__main"
            >
              <ResizeFont
                v-if="isShowPricePrefixText"
                class="bsc-cart-item-goods-price__prefix"
              >
                <ResizeFontText
                  :init-font-size="12"
                  :resize-font-min="10"
                  :resize-font-step="1"
                >
                  {{ pricePrefixText }}
                </ResizeFontText>
              </ResizeFont>
              <span
                v-if="!isSalePriceResetSize"
                class="bsc-cart-item-goods-price__sale-price bsc-cart-item-goods-price_mask"
                v-html="salePriceFormat"
              ></span>
              <ResizeFont
                v-else
                :is-multiline="salePriceResetIsMultiline"
                :multiline-num="salePriceResetMultilineNum"
                text-align="start"
                word-break="break-all"
                class="bsc-cart-item-goods-price__sale-price bsc-cart-item-goods-price_reset bsc-cart-item-goods-price_mask"
              >
                <ResizeFontText
                  v-for="(item, index) in salePriceFormat"
                  :key="index"
                  :init-font-size="item.maxSize"
                  :resize-font-min="item.minSize"
                  :resize-font-step="1"
                >{{ item.text }}</ResizeFontText>
              </ResizeFont>
            </span>
          </span>
          <span :class="otherPriceClasses">
            <span
              v-if="isShowOriginalPrice"
              class="bsc-cart-item-goods-price__original-price bsc-cart-item-goods-price_mask"
            >
              <del>{{ originalPrice }}</del>
              <span v-if="isShowOriginalPriceText">&nbsp;{{ originPriceText }}</span>
              <span
                v-if="isShowOriginalPricePopover"
                class="bsc-cart-item-goods-price__original-price-popover">
                <PricePopover
                  :content="originPricePopoverText"
                  :gap-target="pricePopoverConfig.gapTarget"
                  :listen-target="pricePopoverConfig.listenTarget"
                />
              </span>
            </span>
          </span>
        </div>
        <div class="bsc-cart-item-goods-price__left-after">
          <!-- 由插入的组件自己控制 mask -->
          <slot
            v-if="discountTagPosition === 'original-price-after'"
            name="discount-tag">
          </slot>
          <slot name="price-after"></slot>
          <!-- 由插入的组件自己控制 mask -->
        </div>
      </div>
      <!-- 顶部右侧 -->
      <!-- 放商品操作：比如数量变更 -->
      <div class="bsc-cart-item-goods-price__right">
        <slot name="top-right"></slot>
      </div>
    </div>
    <!-- 价格底部区域 -->
    <!-- 放 30 天最低价提示文案 -->
    <div class="bsc-cart-item-goods-price__bottom">
      <span
        v-if="isShowSuggestPrice"
        class="bsc-cart-item-goods-price__suggest-price"
      >
        <del>{{ suggestPrice }}</del>
        <span v-if="isShowSuggestPriceText">&nbsp;{{ suggestPriceText }}</span>
      </span>
      <slot
        v-if="discountTagPosition === 'suggest-price-after'"
        name="discount-tag">
      </slot>
    </div>
  </div>
</template>

<script name="GoodsPrice" setup lang="ts">
import { computed } from 'vue'
import ResizeFont from '../../components/resize-font/resize-font.vue'
import ResizeFontText from '../../components/resize-font/resize-font-text.vue'
import PromotionIcon from './components/promotion-icon.vue'
import PricePopover from './components/price-popover.vue'
import SheinVip from './components/shein-vip.vue'

/**
 * 商品行价格组件
 */

interface GoodsPricePopover {
  /**
   * 是否展示
   */
  isShow?: boolean,
  /**
   * 销售价格
   */
  salePrice?: string
  /**
   * 需要放大的销售价格数字
   */
  salePriceShowStyle?: string
  /**
   * 是否需要缩放销售价格字体大小
   */
  isSalePriceResetSize?: boolean
  /**
   * 放大的销售价格数字最大字体大小
   */
  salePriceResetZoomMaxSize?: number
  /**
   * 放大的销售价格数字最小字体大小
   */
  salePriceResetZoomMinSize?: number
  /**
   * 销售价格数字最大字体大小（除放大数字的其他文本）
   */
  salePriceResetMaxSize?: number
  /**
   * 销售价格数字最小字体大小（除放大数字的其他文本）
   */
  salePriceResetMinSize?: number
  /**
   * 销售价格是否需要换行
   */
  salePriceResetIsMultiline?: boolean
  /**
   * 销售价格换行行数
   */
  salePriceResetMultilineNum?: number
  /**
   * 销售价格颜色
   */
  salePriceColor?: string
  /**
   * price-after slot 是否换行
   * 不换行时: 内容无法完整展示时, 会被隐藏
   */
  isAfterWrap?: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask?: boolean
  /**
   * 是否展示划线价格
   */
  isShowOriginalPrice?: boolean
  /**
   * 是否展示划线价格提示文案
   */
  isShowOriginalPriceText?: boolean
  /**
   * 是否展示划线价格气泡
   */
  isShowOriginalPricePopover?: boolean
  /**
   * 划线价格
   */
  originalPrice?: string
  /**
   * 划线价格文案
   */
  originPriceText?: string
  /**
   * 划线价格颜色
   */
  originPriceColor?: string
  /**
   * 划线价格是否划线
   */
  originPriceCrossed?: boolean
  /**
   * 划线价格气泡提示文案
   */
  originPricePopoverText?: string
  /**
   * 是否展示建议零售价
   */
  isShowSuggestPrice?: boolean
  /**
   * 是否展示建议零售价文案
   */
  isShowSuggestPriceText: boolean
  /**
   * 建议零售价
   */
  suggestPrice?: string
  /**
   * 建议零售价文案
   */
  suggestPriceText?: string
  /**
   * 建议零售价颜色
   */
  suggestPriceColor: string
  /**
   * 建议零售价是否划线
   */
  suggestPriceCrossed: boolean
  /**
   * 是否展示SHEIN会员图标
   */
  isShowSheinVipIcon?: boolean
  /**
   * 是否展示促销图标
   */
  isShowPromotionIcon?: boolean
  /**
   * 是否展示付费会员图标
   */
  isShowPremiumMemberIcon?: boolean
  /**
   * 是否展示限时促销图标
   */
  isShowTimeIcon?: boolean
  /**
   * 是否展示APP专享图标
   */
  isShowAppOnlyIcon?: boolean
  /**
   * 是否展示品牌特卖图标
   */
  isShowBrandSaleIcon?: boolean
  /**
   * 是否展示闪购图标
   */
  isShowFlashSaleIcon?: boolean
  /**
   * 付费会员图标颜色
   */
  premiumMemberIconColor?: string
  /**
   * 限时促销图标颜色
   */
  timeIconColor?: string
  /**
   * APP专享图标颜色
   */
  appOnlyIconColor?: string
  /**
   * 品牌特卖图标颜色
   */
  brandSaleIconColor?: string
  /**
   * 闪购图标颜色
   */
  flashSaleIconColor?: string
  /**
   * 折扣标签位置
   * original-price-after 原价后边
   * suggest-price-after 30天最低价后边
   */
  discountTagPosition?: 'original-price-after' | 'suggest-price-after'
  /**
   * 价格气泡配置
   */
  pricePopoverConfig?: {
    gapTarget?: string
    listenTarget?: string
  }
  /**
   * 是否展示价格前缀文案
   */
  isShowPricePrefixText: boolean
  /**
   * 价格前缀文案
   */
  pricePrefixText: string
  /**
   * 价格前缀文案颜色
   */
  pricePrefixTextColor: string
}
// props
const props = withDefaults(defineProps<GoodsPricePopover>(), {
  isShow: true,
  salePrice: '',
  isSalePriceResetSize: false,
  salePriceResetZoomMaxSize: 17,
  salePriceResetZoomMinSize: 17,
  salePriceResetMaxSize: 12,
  salePriceResetMinSize: 10,
  salePriceResetIsMultiline: true,
  salePriceResetMultilineNum: 2,
  salePriceColor: '#000',
  isShowMask: false,
  isShowOriginalPrice: false,
  isShowOriginalPriceText: false,
  isShowOriginalPricePopover: false,
  originalPrice: '',
  originPriceText: '',
  originPriceColor: '',
  originPriceCrossed: false,
  originPricePopoverText: '',
  isShowSuggestPrice: false,
  isShowSuggestPriceText: false,
  suggestPrice: '',
  suggestPriceText: '',
  suggestPriceColor: '',
  suggestPriceCrossed: false,
  isShowSheinVipIcon: false,
  isShowPromotionIcon: false,
  isShowPremiumMemberIcon: false,
  isShowTimeIcon: false,
  isShowAppOnlyIcon: false,
  isShowBrandSaleIcon: false,
  isShowFlashSaleIcon: false,
  premiumMemberIconColor: '#000000',
  timeIconColor: '#FA6338', // 写死即可
  appOnlyIconColor: '#FA6338', // 写死即可
  brandSaleIconColor: '#FFCD94', // 写死即可
  flashSaleIconColor: '#FFCD94', // 写死即可
  discountTagPosition: 'original-price-after', // 'original-price-after' | 'suggest-price-after'
  pricePopoverConfig() {
    return {
      listenTarget: '',
      gapTarget: '',
    }
  },
  isShowPricePrefixText: false,
  pricePrefixText: '',
  pricePrefixTextColor: '#FA6338',
})

// data

// computed
const styles = computed(() => ({
  '--sale-price-color': props.salePriceColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--original-price-color': props.originPriceColor,
  '--original-price-crossed': props.originPriceCrossed ? 'line-through' : 'none',
  '--suggest-price-color': props.suggestPriceColor,
  '--suggest-price-crossed': props.suggestPriceCrossed ? 'line-through' : 'none',
  '--price-prefix-text-color': props.pricePrefixTextColor,
}))

const leftClasses = computed(() => [
  'bsc-cart-item-goods-price__left',
  props.isAfterWrap ? 'bsc-cart-item-goods-price_after-wrap' : 'bsc-cart-item-goods-price_after-no-wrap',
])

const otherPriceClasses = computed(() => ({
  'bsc-cart-item-goods-price__other-price': true,
  // 不展示气泡时提示图标时整体文案进行溢出隐藏
  'bsc-cart-item-goods-price__other-price_overflow': !props.isShowOriginalPricePopover,
}))

const getHtmlPrice = (amountWithSymbol, priceShowStyle) => `<b style="${priceShowStyle ? 'font-size:10px' : 'font-weight:500'}">${amountWithSymbol?.replace(priceShowStyle, `<em style="font-size:17px;line-height:1">${priceShowStyle}</em>`)}</b>`

const salePriceFormat = computed(() => {
  if (props.isSalePriceResetSize) {
    if (props.salePriceShowStyle) {
      const regExp = new RegExp(`(?<left>.*?)(${props.salePriceShowStyle})(?<right>.*)`)
      const { groups } = props.salePrice.match(regExp) || {}
      const res = [{
        text: props.salePriceShowStyle,
        maxSize: props.salePriceResetZoomMaxSize,
        minSize: props.salePriceResetZoomMinSize,
      }]
      if (groups?.left) {
        res.unshift({
          text: groups.left,
          maxSize: props.salePriceResetMaxSize,
          minSize: props.salePriceResetMinSize,
        })
      }
      if (groups?.right) {
        res.push({
          text: groups.right,
          maxSize: props.salePriceResetMaxSize,
          minSize: props.salePriceResetMinSize,
        })
      }
      return res
    } else {
      return [{
        text: props.salePrice,
        maxSize: props.salePriceResetMaxSize,
        minSize: props.salePriceResetMinSize,
      }]
    }
  } else {
    return getHtmlPrice(props.salePrice, props.salePriceShowStyle)
  }
})

</script>

<style lang="less">
@height: 16/37.5rem;

.bsc-cart-item-goods-price {
  line-height: 1;

  &_after-wrap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4/37.5rem;
    column-gap: 2/37.5rem;
    .bsc-cart-item-goods-price__left-before {
      flex-shrink: 0;
    }
    .bsc-cart-item-goods-price__left-after {
      flex-shrink: 1;
    }
  }

  &_after-no-wrap {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    height: @height;
    .bsc-cart-item-goods-price__left-before {
      flex: 1;
    }
  }

  &__top {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__left {
    min-width: 0;
  }
  &__left-before {
    display: inline-flex;
    align-items: baseline;
    flex: 1;
    width: 100%;
    flex-wrap: nowrap;
    &.bsc-cart-item-goods-price__hide-other-price {
      flex-wrap: wrap;
      height: @height;
      line-height: @height;
      overflow: hidden;
      flex: auto;
      width: initial;
      max-width: 100%;

      .bsc-cart-item-goods-price__other-price {
        flex: none;
      }
      .bsc-cart-item-goods-price__promo-price {
        flex: 0;
      }
    }
  }

  &__promo-price {
    display: inline-flex;
    align-items: baseline;
    max-width: 100%;
  }
  &__left-after {
    min-width: 0;
  }
  &_mask {
    opacity: var(--mask-opacity);
  }
  &__right {
    flex-shrink: 0;
    margin-left: 4/37.5rem;
  }
  &__bottom {
    opacity: var(--mask-opacity);
  }
  &__promotion-icon {
    align-self: center;
    margin-right: 2/37.5rem;
    font-size: 0;
    flex-shrink: 0;
  }

  &__shein-vip-icon {
    align-self: baseline;
    flex-shrink: 0;
  }
  &__main {
    display: inline-flex;
    align-items: baseline;
    overflow: hidden;
  }
  &__prefix {
    color: var(--price-prefix-text-color);
    font-size: 12px;
    font-weight: 590;
    direction: ltr;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 2/37.5rem;
    flex: 1;
    line-height: 14/37.5rem;
  }

  &__sale-price {
    font-size: 12px;
    color: var(--sale-price-color);
    direction: ltr;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    font-weight: 500;
  }

  &_reset {
    font-weight: 700;
  }

  &__other-price {
    display: inline-flex;
    overflow: hidden;
    flex: 1;

    &:not(.bsc-cart-item-goods-price__other-price_overflow) {
      .bsc-cart-item-goods-price__original-price {
        width: fit-content;
        overflow: hidden;
      }

      .bsc-cart-item-goods-price__original-price del {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_overflow {
      display: inline;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .bsc-cart-item-goods-price__original-price {
        display: inline;
      }
    }
  }

  &__original-price {
    display: inline-flex;
    margin-left: 2/37.5rem;
    font-size: 10px;
    line-height: 12px;
    color: var(--original-price-color, #767676);
    del {
      text-decoration: var(--original-price-crossed);
    }
  }

  &__original-price-popover {
    margin-left: 2/37.5rem;
    width: 14/37.5rem;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    .S-popover {
      font-size: 0;
    }
  }

  .bsc-cart-item-goods-price__suggest-price {
    display: inline;
    font-size: 10px;
    color: var(--suggest-price-color, #767676);
    del {
      text-decoration: var(--suggest-price-crossed);
    }
  }
}
</style>
