import * as standard from './sources/standard'
import { organizeDataSource, createFsDataRunnerBySource } from '@shein-aidc/utils-data-source-toolkit'
import type { DS_CheckoutPrice } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_CheckoutPrice.SourceParams): DS_CheckoutPrice.APIS {
  return organizeDataSource<DS_CheckoutPrice.APIS, DS_CheckoutPrice.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_CheckoutPrice.FS_SourceParams) {
  const { api1 } = getSource(sourceParams)
  return createFsDataRunnerBySource<DS_CheckoutPrice.FS_APIS, DS_CheckoutPrice.FS_DATA>({
    api1,
  })
}
