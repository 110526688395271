import {
  CombinationMode,
  CurrentQuickShipStatus,
  RealQuickShipStatus
} from '@/public/src/pages/checkout_new/utils/constant'
import {
  LargeShipKeys,
  QsFreightType
} from '@/public/src/pages/checkout_new/pages/shopping_bags/utils/constant'

/**
 * @description 商品排序【大件在前，不论是否大件均按照从大到小排序】
 * @param {Array}
 * @return {Array}
 * */
export const sortProduct = (list = [], productIds = []) => {
  // 来自于随手购的商品
  const buyTogetherShip = []

  // 大件商品
  const largeShip = []

  // 其他商品
  const normalShip = []

  list.forEach(item => {
    if (productIds.includes(item.id)) {
      buyTogetherShip.push(item)
    } else {
      if (LargeShipKeys.includes(item.large_ship)) {
        largeShip.push(item)
      } else {
        normalShip.push(item)
      }
    }
  })
  // buyTogetherShip.length &&
  //   buyTogetherShip.sort((a, b) => +b?.quantity - +a?.quantity)
  largeShip.length && largeShip.sort((a, b) => +b?.quantity - +a?.quantity)
  normalShip.length && normalShip.sort((a, b) => +b?.quantity - +a?.quantity)
  return [...buyTogetherShip, ...largeShip, ...normalShip]
}

/**
 * @description 是否是多mall
 * @isMultiMall {boolean} true 多mall
 * @isMultiMall {boolean} false 单mall
 * */
export const setIsMultiMall = (state, payload) => {
  Object.assign(state, {
    isMultiMall: payload
  })
}

/**
 * @description 布局模式
 * @layoutMode {string} 聚合模式
 * @layoutMode {string} 分离模式
 * */
export const setLayoutMode = (state, { cartsInfo, abtInfo }) => {
  const shoppingBagPosition =
    abtInfo.Shoppingbagposition?.param?.shoppingbag_position
  const isAbtOld = shoppingBagPosition === 'old'
  const mode =
    !cartsInfo.is_multi_mall && isAbtOld
      ? CombinationMode.Separation
      : CombinationMode.Combination

  Object.assign(state, {
    layoutMode: mode
  })
}

/**
 * @description 商品项信息
 * @normal {Array} 非QS商品列表
 * @quickShip {Array} QS商品列表
 * */
export const setBagsInfo = (state, { cartsInfo, checkout }) => {
  const { PartQuickShip, NormalShip, AllQuickShip } = CurrentQuickShipStatus
  const status =
    checkout?.quick_shipping?.current_quick_ship_status || NormalShip

  // 商品项信息 用于渲染
  const bagsInfo = {
    mall: [],
    all: []
  }

  // 数据扁平化
  const goodsList =
    cartsInfo?.good_by_mall
      ?.map(sl => sl.storeList)
      ?.flat()
      ?.map(gd => gd.goods)
      ?.flat() || []

  //下单 所有的商品数据
  bagsInfo.all = goodsList

  // mall维度数据
  const goodByMall = cartsInfo?.good_by_mall || []
  goodByMall.forEach((m, mIdx) => {
    const itemMall = {
      ...m,
      all: [],
      normal: [],
      quickShip: [],
      totalQuantity: {
        normal: 0,
        quickShip: 0
      },
      isRenderSingleProduct: false
    }

    // 当前处理中的mall的所有商品【数据扁平化】
    const currentMallGoods =
      m?.storeList
        ?.flat()
        ?.map(gd => gd.goods)
        ?.flat() || []

    // 所有商品
    itemMall.all = currentMallGoods

    // 处理数据映射
    currentMallGoods.forEach(item => {
      // 区分 QS 数据和 Normal 数据
      if (status === PartQuickShip) {
        if (item.real_quick_ship === RealQuickShipStatus.Normal) {
          itemMall.normal.push(item)
          itemMall.totalQuantity.normal += ~~item.quantity
        } else if (item.real_quick_ship === RealQuickShipStatus.AllQuick) {
          itemMall.quickShip.push(item)
          itemMall.totalQuantity.quickShip += ~~item.quantity
        } else {
          const qsStock = +item.product.qs_stock

          if (qsStock > 0) {
            // qs
            const cacheQuantity = +item.quantity
            const cacheQuickShipItem = { ...item }
            const cacheNormalItem = { ...item }
            if (cacheQuantity > qsStock) {
              const restNormal = cacheQuantity - qsStock
              cacheQuickShipItem.quantity = qsStock
              itemMall.quickShip.push(cacheQuickShipItem)
              itemMall.totalQuantity.quickShip += qsStock

              cacheNormalItem.quantity = cacheQuantity - qsStock
              itemMall.normal.push(cacheNormalItem)
              itemMall.totalQuantity.normal += restNormal
            } else {
              itemMall.quickShip.push(item)
              itemMall.totalQuantity.quickShip += ~~item.quantity
            }
          }
        }
      } else if (status === NormalShip) {
        // 全部非QS
        itemMall.normal.push(item)
        itemMall.totalQuantity.normal += ~~item.quantity
      } else if (status === AllQuickShip) {
        // 全部QS
        itemMall.quickShip.push(item)
        itemMall.totalQuantity.quickShip += ~~item.quantity
      }

      /**
       *
       * @description 组件渲染【singleProduct OR multiProduct】
       * @doc public/src/pages/checkout_new/pages/shopping_bags/README.md
       * */
      if (status === PartQuickShip) {
        // 数据重置
        itemMall.isRenderSingleProduct = false
        itemMall.isRenderSingleProductByNormal = itemMall.normal.length === 1
        itemMall.isRenderSingleProductByQuickShip = itemMall.quickShip.length === 1
      } else if (status === AllQuickShip) {
        itemMall.isRenderSingleProduct = itemMall.quickShip.length === 1
      } else {
        itemMall.isRenderSingleProduct = itemMall.normal.length === 1
      }
    })

    // 阻止购物袋排序[交互时不需要排序]
    const { isBlockingSort, bagsInfo: oldBagsInfo } = state.shoppingBagsState
    if (isBlockingSort) {
      const newNormal = []
      const newQuickShip = []
      const oldNormal = oldBagsInfo?.mall?.find(item => item.mall_code === itemMall.mall_code)?.normal
      const oldQuickShip = oldBagsInfo.mall.find(item => item.mall_code === itemMall.mall_code)?.quickShip

      const restMallNormal = itemMall.normal?.filter(item => !oldNormal.some(_ => _.id === item.id)) || []
      const restMallQuickShip = itemMall.quickShip?.filter(item => !oldQuickShip.some(_ => _.id === item.id)) || []

      oldNormal.forEach(item => {
        const newItem = itemMall.normal.find(_ => _.id === item.id)
        if (!!newItem) {
          newNormal.push(newItem)
        }
      })
      oldQuickShip.forEach(item => {
        const newItem = itemMall.quickShip.find(_ => _.id === item.id)
        if (!!newItem) {
          newQuickShip.push(newItem)
        }
      })
      itemMall.normal = [...restMallNormal, ...newNormal]
      itemMall.quickShip = [...restMallQuickShip, ...newQuickShip]
    } else {
      // 排序
      const sortNormal = sortProduct(
        itemMall.normal,
        state.togetherBuy.addCartProducs
      )
      const sortQuickShip = sortProduct(
        itemMall.quickShip,
        state.togetherBuy.addCartProducs
      )
      itemMall.normal = sortNormal
      itemMall.quickShip = sortQuickShip
    }

    bagsInfo.mall.push(itemMall)
  })

  const storeList =
    bagsInfo.all?.filter(item => item.business_model === '1') || []
  const isAllStore = bagsInfo.all?.length === storeList.length
  const isShopType = isAllStore.length ? (isAllStore ? 1 : 2) : 0

  // 更新state
  Object.assign(state.shoppingBagsState, {
    bagsInfo,
    isShopType
  })
}

export const setQsFreightStatus = (state, { checkout }) => {
  const qsFreightStatus =
    checkout?.quick_shipping?.switch_qs?.switch_qs_selected ||
    QsFreightType.false

  // 更新state
  Object.assign(state.shoppingBagsState, {
    qsFreightStatus
  })
}
