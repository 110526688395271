import TMG from '@shein/time-management-guru'
import qs from 'query-string'

import schttp from 'public/src/services/schttp'
import other from './other'
import popup from './popup'
import addToBag from './addToBag'
import recommend from './recommend'

import {
  getGoodsDetailParams
} from 'public/src/pages/product_app/store/modules/product_detail/utils/getPrefetchParams.js'
import switchColorCache from 'public/src/pages/goods_detail_v2/utils/switchColorCache.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { getLocalStorage } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
const { DETAIL_SERIAL_QUERY_PV_INIT } = gbCommonInfo || {}

// 获取商品主数据
const getGoodsDetailMasterData = async (isSwitchColor, params) => {
  let result
  if (isSwitchColor) {
    result = switchColorCache.get(params.goods_id, 'cold')
    if (result) return result
  }
  result = await TMG.useQuickRequest(
    `pageGoodsDetail/getGoodsDetail/${params.goods_id}`,
    params,
    { onlyFirstUseQuick: true }
  )
  return result
}

const getGoodsDetailRealTimeData = async (isSwitchColor, params) => {
  let result
  if (isSwitchColor) {
    result = switchColorCache.get(params.goods_id, 'hot')
    if (result) return result
  }
  const data = await TMG.useQuickRequest(
    `pageGoodsDetail/getGoodsDetailRealTime/${params.goods_id}`,
    params,
    { onlyFirstUseQuick: true }
  )
  result = data?.data
  return result
}

const getGoodsDetailLanguageData = async () => {
  let result = await TMG.useQuickRequest(
    'pageGoodsDetailLanguage/getGoodsDetailLanguage'
  )
  return result
}

const reportMasterDataError = (error, params, beforeXhrExtra) => {
  const paramsStr = qs.stringify(params)
  const url = `/api/productInfo/productDetail/get?${paramsStr}`
  const { beforeDownlink, beforeOnline, beforeTime } = beforeXhrExtra
  const afterTime = Date.now()
  window.ErrorJs &&
    window.ErrorJs.sendError(
      'error',
      `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`,
      {
        errorMark: 'ProductXhrBrowserPwaError',
        errorInfo: JSON.stringify({
          status: error?.status || '',
          status2: error?.status,
          responseText: error?.responseText || '',
          responseURL: error?.responseURL || '',
          readyState: error?.readyState,
          responseXML: error?.responseXML,
          message: error?.message || '',
          url,
          params,
          errType: Object.prototype.toString.call(error),
          textStatus: error?.textStatus || '',
          errorThrown: error?.errorThrown || '',
          beforeDownlink,
          afterDownlink: navigator?.connection?.downlink || 999,
          beforeOnline,
          afterOnline: navigator?.onLine || 999,
          beforeTime,
          afterTime,
          disTime: afterTime - beforeTime
        })
      }
    )
}

export default {
  /**
   * @name fetchFSInfo 获取商品详情 首屏数据
   * @param {*} param0
   * @param {*} payload
   */
  async fetchFSInfo(
    { commit, rootState, rootGetters, dispatch },
    { goods_id, isSwitchColor, callback, mallCode, realTimeConfig, err404 }
  ) {
    return new Promise(resolve => {
      // ssr报错排查
      if (typeof window === 'undefined') {
        throw new Error(
          JSON.stringify({
            err: 'product_detail_ssr_build_inconsistent',
            goods_id,
            detail: rootGetters['newProductDetail/common/detail']
          })
        )
      }
      
      const language = rootGetters['newProductDetail/common/language']
      const params = getGoodsDetailParams({
        withI18n: Object.keys(language).length ? 0 : 1,
      })
      if (params?.goods_id) {
        const beforeXhrExtra = {
          beforeDownlink: navigator?.connection?.downlink || 999,
          beforeOnline: navigator?.onLine || 999,
          beforeTime: Date.now()
        }

        // 商品主数据 所有状态都以此接口为准
        markPoint({ eventName: 'waitPageData', measureFrom: 'routeChangeEnd' })
        if (!isSwitchColor) {
          getGoodsDetailLanguageData().then(result => {
            commit('updatePageInfoLanguageField', result)
          })
        }

        const setPageInfo = () => {
          SaPageInfo.page_param.sellprice = rootGetters['productDetail/price_info'].salePrice.usdAmount
          let recommendRetailPrice = rootGetters['productDetail/price/recommend_retail_price']
          if (recommendRetailPrice && !recommendRetailPrice.skuCode) {
            SaPageInfo.page_param.recommend_retail_price = recommendRetailPrice.usdAmountWithSymbol || 0
          }
          let unitPriceInfo = rootGetters['productDetail/price/unit_price_info']
          if (unitPriceInfo) {
            let { pc_price, pc } = unitPriceInfo.analysisData || {}
            SaPageInfo.page_param.pc_price = pc_price || 0
            SaPageInfo.page_param.pc = pc || 0
          }

          const is_some_sku_sold_out = rootGetters['productDetail/common/is_some_sku_sold_out']
          const is_spu_sold_out = rootGetters['productDetail/common/is_spu_sold_out']

          if (is_some_sku_sold_out || is_spu_sold_out) {
            SaPageInfo.page_param.spusoldout = is_spu_sold_out ? 1 : 0
          }

          const locate_labels_data = rootGetters['productDetail/MainPicture/locate_labels_data']
          SaPageInfo.page_param.is_include_picturelabel = locate_labels_data?.length ? 1 : 0

          if (DETAIL_SERIAL_QUERY_PV_INIT) callback && callback()
        }


        getGoodsDetailMasterData(isSwitchColor, params)
          .then(result => {
            markPoint({
              eventName: 'pageDataReady',
              measureFrom: 'waitPageData'
            })
            // 请求回来时可能不在详情页了
            if (
              typeof window !== 'undefined' &&
              window._gb_app_ &&
              window._gb_app_.$route.name != 'page_goods_detail'
            ) {
              return resolve('stop')
            }

            // 商品不存在特殊处理
            if (result?.code === 200301 && err404 instanceof Function) {
              err404()
              return
            }
            // 新数据链路
            commit('initCold', result)
            commit('newProductDetail/SizeBox/updateRealDataMap', null, {
              root: true
            })
            commit('newProductDetail/SizeBox/updateRealSkuAllInfo', null, {
              root: true
            })
            commit('common/updateDetailPageOnloadStatus', true)
            commit('common/updateDetailFetchMoreInfoStatus', true)
            commit('common/updateGoodsReady', true)
            commit('common/updateFirstPageDataReady', true)
            markPoint({
              eventName: 'setPageData',
              measureFrom: 'pageDataReady'
            })

            Vue.nextTick(() => {
              
              markPoint({
                eventName: 'setPageDataNextTick',
                measureFrom: 'setPageData'
              })
              markPoint({ eventName: 'nextTickToFsp', measureTo: 'fsp' })
            })
            
            Promise.resolve().then(() => {
              // 获取实时数据
              getGoodsDetailRealTimeData(isSwitchColor, {
                goods_id: paths[1],
                mallCode,
                realTimeConfig
              }).then(hotModules => {
                commit('updateHot', hotModules || {})
                setPageInfo()
              })

              if (!DETAIL_SERIAL_QUERY_PV_INIT) callback && callback()
              
              const asyncScrollStatus =
                rootState.newProductDetail.common?.asyncScrollStatus
              !asyncScrollStatus &&
                commit(
                  'changeRootSLoadingStatus',
                  { show: false },
                  { root: true }
                )
              switchColorCache.setBuffer(goods_id, 'cold', result)
            })
            return resolve()
          })
          .catch(error => {
            reportMasterDataError(error, params, beforeXhrExtra)
          })
      }
    })
  },
  /**
   * @name asyncHotModles 同步动态模型 主链路动态模型
   * @param {*} param0
   * @param {*} payload
   */
  async asyncHotModles({ commit, rootGetters, rootState, dispatch }, payload) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']

    // bff_fs todo_del
    if (is_use_bff_fs) {
      return dispatch('productDetail/async_hot_modules', payload, { root: true })
    }

    const { goods_id, mallCode, noUpdateRecommend } = payload

    const _useDebug =
      typeof gbCommonInfo !== 'undefined'
        ? gbCommonInfo?.NODE_SERVER_ENV !== 'production'
        : false

    let addressCookie = getLocalStorage('addressCookie') || {}
    let address_cache = getLocalStorage('address_cache') || {}
    let { addressId, countryId, stateId, cityId, districtId, isUserHandle } = addressCookie
    let state = address_cache[stateId]
    let city = address_cache[cityId]
    let district = address_cache[districtId]
    let shippingParams = {
      countryId,
      state,
      city,
      district
    }
    if(isUserHandle != 1){
      // isUserHandle == 1 为用户主动，非用户主动操作，传addressId
      shippingParams.addressId = addressId
    }
    const [res, BFF_INFO] = await Promise.all([
      schttp({
        method: 'POST',
        url: '/api/productInfo/realTimeData/query',
        data: {
          goods_id,
          mallCode,
          realTimeConfig: {
            specialSceneType: !!rootGetters[
              'newProductDetail/closeEstimatedAndAbPrice'
            ]
              ? 1
              : 0,
            estimatedPriceInfo:
              !rootState.newProductDetail.common?.fromSwitchColor
          }
        }
      }),
      schttp({
        method: 'GET',
        url: '/product/get_goods_detail_realtime_data',
        params: {
          goods_id: goods_id,
          mallCode: mallCode,
          // web不需要接口自动切换mall
          isUserSelectedMallCode: 1,
          // isPaidMember  是否付费会员 0-否 1-是
          
          // 可以识别用户纬度缓存不用重复请求
          // isQueryIsPaidMember 是否查询用户是否付费会员，web使用
          isQueryIsPaidMember: 1,
          isQueryCanTrail: isLogin() ? 1 : 0,
          isHideEstimatePriceInfo: location.search.includes('landing_page_id') ? 1 : 0,
          specialSceneType: location.search.includes('landing_page_id') ? 1 : 0,
          ...shippingParams
        },
        headers: _useDebug ? {
          'sheindebug': 676 // 非正式环境传入debug参数方便中间层调试 TODO
        } : {},
        useBffApi: true,
      }).catch((err) => {
        console.error('get_goods_detail_realtime_data error:', err)
        return null
      })
    ])
    if (res && res.code === 0) {
      if (typeof res.data === 'object') {
        res.data.noUpdateRecommend = noUpdateRecommend
      }
      if (res.data && +BFF_INFO?.code === 0) {
        res.data.BFF_INFO = BFF_INFO?.info
      }
      commit('updateHot', res.data)
      if (typeof res.data === 'object') {
        delete res.data.noUpdateRecommend
      }
      return res.data
    }
  },
  getApolloConfig({}, key) {
    return new Promise(resolve => {
      schttp({
        url: '/api/config/apolloConfig/query',
        method: 'POST',
        data: { apolloKeys: key }
      }).then(res => {
        resolve(res?.info || {})
      })
    })
  },
  ...other,
  ...popup, // 弹窗相关
  ...addToBag, // 加车
  ...recommend // 推荐相关
}
     
