import schttp from 'public/src/services/schttp'
const BFF_ABT_URL = '/abt/merge/get_abt_by_poskey'
const NODE_ABT_URL = '/api/abt/userinfo/query'
const NODE_ABT_TEST_URL = '/api/abt/test/query'
import { getPrefixPoskeyObj } from './utils.js'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import { getCookie } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

async function nodeAbtReqeust(params) {
  const result = await schttp({
    url: NODE_ABT_URL,
    method: 'post',
    data: params,
  })
  const formatClientData = data => {
    Object.keys(data).forEach(key => {
      const item = data[key] || {}
      item.posKey = item.poskey
      item.param = item.p
      item.sceneStr = key
    })
    return data
  }
  return formatClientData(result.params)
}

/**
 * @description: bffAbtReqeust
 * @param {string} posKeys 新的posKeys
 * @param {string} oldPsoKeys 旧的posKeys
*/
async function bffAbtReqeust(posKeys = '', oldPsoKeys = '') {
  if (typeof posKeys !== 'string') {
    throw new Error('posKeys must be a string')
  }

  let oldPoskeyMap = {}
  if (oldPsoKeys) {
    const oldPosKeys = oldPsoKeys.split(',')
    oldPoskeyMap = getPrefixPoskeyObj(oldPosKeys)
    posKeys = `${posKeys},${Object.keys(oldPoskeyMap).join(',')}`
  }

  const formData = new FormData()

  const ugid = await UserInfoManager.get({ key: 'ugid' })
  const uid = UserInfoManager.get({ key: 'memberId', actionType: 'editPassword' })
  const query = {
    posKeys,
    cid: getCookie('cookieId'),
    ugid,
    uid,
    terminal: 'Shein-Pwa',
    closeRule: 1,
    site: gbCommonInfo?.SiteUID, // "pwus"
    lan: gbCommonInfo?.appLanguage,
    ua: `${navigator?.userAgent}`.toLowerCase(),
    ip: gbCommonInfo?.ip
  }

  Object.keys(query).forEach(key => {
    query[key] && formData.append(key, query[key])
  })

  const res = await schttp({
    url: BFF_ABT_URL,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'post',
    useBffApi: true,
  }).catch(e => {
    // report log error
    SILogger.logInfo({
      tag: 'abt_bff_fetch_error',
      message: 'abt_bff_fetch_error',
      params: e
    })
    throw e
  })
  if (`${res.code}` !== '0') {
    SILogger.logInfo({
      tag: 'abt_bff_fetch_error_info',
      message: 'abt_bff_fetch_error_info',
      params: res
    })
  }
  const abtInfo = res?.info?.pos || {}
  return {
    data: Object.keys(abtInfo).reduce((prev, key) => {
      const item = abtInfo[key]
      const originKey = oldPoskeyMap[key] || key // 如果是旧的poskey，就替换成新的poskey
      if (!item) {
        prev[originKey] = {}
        return prev
      }
      const value = item.p || item.param
      prev[originKey] = {
        sceneStr: key,
        posKey: originKey,
        poskey: originKey,
        param: value,
        p: value,
        child: item.child || [],
        version: item.version,
      }
      return prev
    }, {}),
    originData: res,
  }
}

async function nodeAbtTestReqeust(params) {
  if (!gbCommonInfo.isDebug) {
    const res = await nodeAbtReqeust(params)
    return { data: res }
  }
  const { response } = await schttp({
    url: NODE_ABT_TEST_URL,
    method: 'post',
    data: params,
  })
  return response
}

export { nodeAbtReqeust, bffAbtReqeust, nodeAbtTestReqeust }
