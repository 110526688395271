var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"category_recommend_dynamic_bff__container"},[(_vm.isShow && _vm.slidesData.length > 0)?_c('div',{staticClass:"category_recommend_dynamic_bff__root",style:(_vm.rootStyle)},[(_vm.compMetaData.mainTitle)?_c('header',{staticClass:"category_recommend_dynamic_bff__header",style:({ 'color': _vm.compMetaData.mainTitleColor })},[_vm._v("\n      "+_vm._s(_vm.compMetaData.mainTitle || '')+"\n    ")]):_vm._e(),_vm._v(" "),_c('swiper-container',{ref:"swiperInstanceRef",class:_vm.swiperContainerClassName,attrs:{"init":"false","destroy-on-disconnected":"false"}},_vm._l((_vm.virtualData),function(col,colIndex){return _c('swiper-slide',{key:colIndex,staticClass:"category_recommend_dynamic_bff__slide",class:{
          'category_recommend_dynamic_bff__nocutImg': _vm.sbcInfoSummary.imageShape === 'nocut',
          'is-need-scrollbar': Number(_vm.sbcInfoSummary.spacing) % 1 === 0 // 一屏为整数列时，需要滚动条
        },style:({ width: _vm.swiperSlideWidth })},[_vm._l((col),function(item,rowIndex){return [(item)?_c('div',{key:rowIndex,staticClass:"category_recommend_dynamic_bff__item",class:{
              'not-fsp-element': item._FE_isAnimationImg_
            },staticStyle:{"position":"relative"},attrs:{"data-eid":`category_recommend_dynamic_bff__${rowIndex}-${colIndex}`,"data-loc-tag":item._FE_itemLoc_},on:{"click":function($event){return _vm.handleClickItem(item)}}},[(item.image)?_c('BaseImg',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.getDataForMetrics(item, 'tap', rowIndex, colIndex)),expression:"getDataForMetrics(item, 'tap', rowIndex, colIndex)"},{name:"expose",rawName:"v-expose",value:(_vm.getDataForMetrics(item, 'expose', rowIndex, colIndex)),expression:"getDataForMetrics(item, 'expose', rowIndex, colIndex)"}],staticClass:"category_recommend_dynamic_bff__img",class:{ 
                'category_recommend_dynamic_bff__circularImg': _vm.sbcInfoSummary.imageShape === 'circle',
                'category_recommend_dynamic_bff__nocutImg': _vm.sbcInfoSummary.imageShape === 'nocut',
                'category_recommend_dynamic_bff__nonNoCut': _vm.sbcInfoSummary.imageShape !== 'nocut',
                'category_recommend_dynamic_bff__stroke': _vm.sbcInfoSummary.stroke === 'true',
                'category_recommend_dynamic_bff__stroke_2': _vm.sbcInfoSummary.stroke === 'true_2.0',
              },style:(_vm.imageStyle),attrs:{"brand":_vm.brand,"img-src":item.image.src,"placeholder":{
                width: _vm.sbcInfoSummary.imageShape === 'nocut' ? 76 : 58,
                height: _vm.sbcInfoSummary.imageShape === 'nocut' ? 56 : 58,
              },"first-screen":_vm.isFirstPage && (colIndex < 5),"imgDesignWidth":144,"comp-src":'category-recommend-placeholder',"ratio":_vm.sbcInfoSummary.imageShape === 'nocut' ? 1.357 : 1,"fit":"cover","imgDataExp":{
                unCut: item._FE_isUnCut_,
              }}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowBelt && item.beltText),expression:"isShowBelt && item.beltText"}],staticClass:"category_recommend_dynamic_bff__belt",style:(_vm.beltStyle)},[_vm._v("\n              "+_vm._s(item.beltText || '')+"\n            ")]),_vm._v(" "),(item.markImage)?_c('div',{staticClass:"category_recommend_dynamic_bff__corner_mark",style:(_vm.getCornerMarkStyle(item))}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"category_recommend_dynamic_bff__name",class:_vm.lineClampClass,style:(_vm.cateNameStyle)},[_vm._v("\n              "+_vm._s(item.smallTitle || '')+"\n            ")])],1):_vm._e()]})],2)}),1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }