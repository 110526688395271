import { computed, reactive } from 'vue'
import { template, timeTransformer } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { C_MONTH_FORMAT_MAP, C_YEAR_FORMAT_MAP }  from 'public/src/pages/common/biz_helper/timer_format/dictionary'
import { 
  TitleConfig,
  HeaderTitleType, 
  htt2mlt,
  middleLayerType,
  SaTypeMap,
  titleColorConfig,
  newSaTypeMap
} from '@/public/src/pages/checkout_new/pages/header/utils/constant'

export default (isNewAutoTitle) => {
  const { SiteUID } = gbCommonInfo

  // --------- useMap_**** --------
  const { language, checkout, cartsInfo } = useMapState(['language', 'checkout', 'cartsInfo'])

  // --------- computed --------
  const pageHeadlineList = computed(() => {
    if (isNewAutoTitle) {
      return checkout?.value?.pageHeadlineList
        .concat(cartsInfo.value?.pageHeadlineList || [])
    }
    return checkout?.value?.pageHeadlineList ?? []
  })

  // --------- methods --------
  /**
   * @description: 获取最快送达时间的文案
   * @param {Object} headlineItem
   * @param {object} specialConfig
   * @return {*}
   */  
  const handleDeliveryFastTime = ({ headlineItem, specialConfig }) => {
    const { timeForFillInfo = [] } = headlineItem
    if(!timeForFillInfo.length) {
      return {
        special: template(`<font color="${TitleConfig[HeaderTitleType.Shipping].animation.color}">${headlineItem.fillInfo}</font>`, headlineItem.headline)
      }
    }
  
    let timeStr = ''
    const curYear = new Date().getFullYear()
    
    // 单个时间
    if(timeForFillInfo.length === 1){
      const timeStamp = Number(timeForFillInfo[0]) * 1000 || 0
      const sameYear = new Date(timeStamp).getFullYear() === curYear
      
      timeStr = timeTransformer({
        time: timeStamp,
        sDateMap: sameYear ? C_MONTH_FORMAT_MAP : C_YEAR_FORMAT_MAP,
        defaultCode: sameYear ? 'D-3' : 'A-3'
      }, { SiteUID })
      
      return {
        special: template( `<font color="${specialConfig.color}">${timeStr}</font>`, headlineItem.fillInfo)
      }
    }
    
    // 多个时间
    if(timeForFillInfo.length === 2){
      const [ startTimeStr, endTimeStr ] = timeForFillInfo
      const [ startTimeStamp, endTimeStamp ] = [Number(startTimeStr) * 1000, Number(endTimeStr) * 1000]
      const sameYear = new Date(startTimeStamp).getFullYear() === new Date(endTimeStamp).getFullYear() && new Date(startTimeStamp).getFullYear() === curYear
       
      const timeTextOne = `<font color="${specialConfig.color}">${timeTransformer({
        time: startTimeStamp || 0,
        sDateMap: sameYear ? C_MONTH_FORMAT_MAP : C_YEAR_FORMAT_MAP,
        defaultCode: sameYear ? 'D-3' : 'A-3'
      }, { SiteUID })}</font>`
  
      const timeTextTwo =  `<font color="${specialConfig.color}">${timeTransformer({
        time: endTimeStamp || '',
        sDateMap: sameYear ? C_MONTH_FORMAT_MAP : C_YEAR_FORMAT_MAP,
        defaultCode: sameYear ? 'D-3' : 'A-3'
      }, { SiteUID })}</font>`
  
      return {
        special: template(`${timeTextOne} ~ ${timeTextTwo}`, headlineItem.headline)
      }
    }
  
    return {}
  }

  /**
   * @description: 获取支付安全保障的提示语 securityGuarantee
   * @param {Object} headlineItem
   * @return {*}
   */  
  const handleSecurityGuarantee = (headlineItem) => {
    return {
      special: template(`<font color="${TitleConfig[HeaderTitleType.Payment].highlight.color}">${headlineItem.fillInfo}</font>`)
    }
  }

  /**
   * @description: 获取组合优惠文案
   * @param {Object} headlineItem 
   * @return {*}
   */  
  const handleSaveTotalPrice = (headlineItem) => {
    return {
      special: template(
        `<font color="${TitleConfig[HeaderTitleType.Saved].animation.color}">${headlineItem.fillInfo}</font>`,
        headlineItem.headline
      )
    }
  }

  // 获取中间层类型
  const getMiddleLayerType = (titleType) => {
    const mlt = htt2mlt[titleType]
    if(!mlt) {
      return 
    }
    return pageHeadlineList.value?.find(item => item.type === mlt)
  }

  /**
   * @description: 当副标题切换时，上报埋点
   * @param {String} type 类型
   * @return {*}
   */  
  const handleScrollTitleReport = (type, isNewAutoTitle) => {
    if (isNewAutoTitle) {
      // 新版的标题上报只上报一次
      handleNewScrollTitleReport(type)
      return
    }
    daEventCenter.triggerNotice({
      id: 'expose_headline_tips:simple',
      data: {
        headline_type: SaTypeMap[type]
      }
    })
  }

  const reportedType = reactive({})
  /**
   * @description: 当标题切换时，上报埋点，仅上报一次
   * @param {String} type 类型
   * @return {*}
   */  
  const handleNewScrollTitleReport = (type) => {
    if (reportedType[type]) return
    let headlineType = newSaTypeMap[type]
    const pageHeadlineInfo = pageHeadlineList.value?.find(item => item.type === type) || {}
    const { countdownTime } = pageHeadlineInfo
    if (type === HeaderTitleType.Saved && countdownTime) headlineType += '_timeline'
    reportedType[type] = true
    daEventCenter.triggerNotice({
      id: 'expose_headline_tips:simple',
      data: {
        headline_type: headlineType
      }
    })
  }

  /**
   * @description: 获取副标题文案（新版：数据完全由中间层提供）
   * @param {Number} index 
   * @param {Object} modelData
   * @return {*}
   */ 
  const getNewTitleConfig = (index, modelData) => {
    const { titleType } = modelData.titles[index]
    if (titleType === HeaderTitleType.Normal) {
      return { before: language.value.SHEIN_KEY_PWA_15245 }
    }
    const pageHeadlineInfo = pageHeadlineList.value?.find(item => item.type === titleType) || {}
    const { type, headline, headlineForMerge, iconUrl, countdownTime } = pageHeadlineInfo
    
    let beforeText = headline
    if (type === HeaderTitleType.FreeShipping) {
      const isFreeReturn = pageHeadlineList.value?.find(item => item.type === HeaderTitleType.FreeReturn)
      if (isFreeReturn) beforeText = headlineForMerge
    }

    return {
      iconUrl,
      countdownTime,
      before: beforeText,
      style: {
        color: titleColorConfig[titleType] || ''
      }
    }
  }

  /**
   * @description: 获取副标题文案
   * @param {Number} index 
   * @param {Object} modelData
   * @return {*}
   */  
  const useGetTitleConfig = (index, modelData) => {
    if (isNewAutoTitle) return getNewTitleConfig(index, modelData)

    const { titleType } = modelData.titles[index]
    const config = TitleConfig[titleType]
    if(!config) {
      return {}
    }
    const titleText =  language.value[config.languageKey]
    let specialConfig
      
    // 目前动效文字就是高亮文字，不存在动效文字和高亮文字不是同一个多语言key的
    if (config.animation) {
      specialConfig = config.animation
    } else if (config.highlight) {
      specialConfig = config.highlight
    }
      
    if (!specialConfig) return { before: titleText }
      
    const pageHeadline = getMiddleLayerType(titleType)
    if(pageHeadline){
      const pageHeadlineType = pageHeadline.type
      const pageHeadlineMap = {
        [middleLayerType.deliveryFastTime]: () => handleDeliveryFastTime({ headlineItem: pageHeadline, specialConfig }),
        [middleLayerType.securityGuarantee]: () => handleSecurityGuarantee(pageHeadline),
        [middleLayerType.saveTotalPrice]: () => handleSaveTotalPrice(pageHeadline)
      }
      const pageHeadlineMapfn = pageHeadlineMap[pageHeadlineType]
        
      if(pageHeadlineMapfn){
        return pageHeadlineMapfn()
      }
    }
    // FreeReturn 免费退货 特殊处理
    if(titleType === HeaderTitleType.FreeReturn){
      // TOTO
    }
    return { }    
  }

  /**
   * @description: 获取轮播列表数据
   * @return {Array}
   */ 
  const getAutoCycleList = () => {
    let list = pageHeadlineList.value

    // 有免邮类型时，将免费退过滤，仅展示免邮类型中的拼接标题
    if (list.find(item => item.type === HeaderTitleType.FreeShipping)) {
      list = list.filter(item => item.type !== HeaderTitleType.FreeReturn)
    }
    return list.sort((a, b) => a.priority - b.priority)
  }

  return {
    useGetTitleConfig,
    getMiddleLayerType,
    handleScrollTitleReport,
    getAutoCycleList
  }
}
