import * as standard from './sources/standard'
import { organizeDataSource, createFsDataRunnerBySource } from '@shein-aidc/utils-data-source-toolkit'
import type { Libs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_BenefitFloor } from '../../types'

export function getSource(dataSource: DS_BenefitFloor.DataSource = {}, appConfigs: Libs.AppConfigs): DS_BenefitFloor.APIS {
  return organizeDataSource<DS_BenefitFloor.APIS, DS_BenefitFloor.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(dataSource: DS_BenefitFloor.DataSource = {}, appConfigs: Libs.AppConfigs) {
  const { langs } = getSource(dataSource, appConfigs)
  return createFsDataRunnerBySource<DS_BenefitFloor.FS_APIS, DS_BenefitFloor.FS_DATA>({
    langs,
  })
}
