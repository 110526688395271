export function getDeviceInfo() {
  return {
    colorDepth: window.screen.colorDepth,
    javaEnabled: window.navigator.javaEnabled() ? 1 : 0,
    language: window.navigator.language,
    screenHeight: window.screen.height || 0,
    screenWidth: window.screen.width || 0,
    timeZoneOffset: new Date().getTimezoneOffset(),
    userAgent: window.navigator.userAgent,
    origin: window.location.origin
  }
}

/**
 * 整合处理二次下单页url
 *
 * @export
 * @param {*} [{ billno = '' }={}]
 * @return {{
 *  pc: string,
 *  pwa: string
 * }} 
 */
export function getCheckoutAgainUrl({
  billno = '',
  checkoutType = ''
} = {}) {
  const { host, langPath } = gbCommonInfo
  const originSite = host + langPath
  const productType = getProductType({ type: checkoutType })
  if (productType === 'gift_card') {
    return {
      pc: `${originSite}/giftcard/checkout/checkout_again/${billno}`,
      pwa: `${originSite}/giftcard/checkout/again/${billno}`,
    }
  }
  if (productType === 'paid_membership') {
    return {
      pc: `${originSite}/buyprime_again/${billno}`,
      pwa: `${originSite}/user/orders/virtualDetail/${billno}`,
    }
  }
  if (productType === 'paid_shein_saver') {
    return {
      pc: `${originSite}/xtra_again/${billno}`,
      pwa: `${originSite}/user/orders/virtualDetail/${billno}`,
    }
  }
  return {
    pc: `${originSite}/checkout/checkout_again/${billno}`,
    pwa: `${originSite}/user/orders/detail/${billno}`,
  }
}

export function getProductType({ type = '' } = {}) {
  if (type === 'giftcard') return 'gift_card'
  if (type === 'buyprime') return 'paid_membership'
  if (type === 'xtra') return 'paid_shein_saver'
  if (type === 'ocp') return 'one_click_pay'
  return 'normal'
}

export function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  if (gbCommonInfo?.NODE_SERVER_ENV === 'production') return
  try {
    console.info(
      '%cPay Debug Info:',
      'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
      ...args,
      '\n'
    )
  } catch (e) {
    console.log(...args)
  }
}

export function makeForm (params, action, method) {
  if (typeof window == 'undefined') return

  $(`form[name='pay_jump_form']`).remove()
  method = method || 'post'
  let $form = $('<form name="pay_jump_form" action="' + action + '" method="' + method + '"></form')
  for (const key in params) {
    let $input = $('<input type="hidden" />')
    $input.prop('name', key)
    $input.val(params[key])
    $form.append($input)
  }
  $form.append('<input type="submit" value="Continue" style="display:none;" />')
  $('body').append($form)
  document.pay_jump_form.submit()
}

// 倒计时器，用于处理ddc超时，超过2秒不等ddc返回，执行下一次操作
let timer = null
/**
 * 通用发送ddc请求
 * @param {*} param.action 请求地址
 * @param {*} param.method 请求方式
 * @param {*} param.params 请求参数
 * @param {*} param.timeout 超时时间，默认2000ms
 * @param {*} param.cb 回调函数
 * @returns 
 */
export function commonSendDdc({ action, method = 'POST', params = '{}', timeout = 2000, cb } = {}) {
  if (!action) {
    return
  }

  // #region 重置操作
  clearTimeout(timer)
  // #endregion
  
  // 获取host，用于匹配ddc返回的host
  let host = ''
  try {
    var urlObj = new URL(action)
    host = urlObj.protocol + '//' + urlObj.host
  } catch (e) {
    var reg =
        /((https?):\/\/)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+\.[-A-Za-z+]+\/+/g
    var hostUrl = action.match(reg)[0]
    host = hostUrl ? hostUrl.substring(0, hostUrl.length - 1) : ''
  }

  if (typeof params === 'string') {
    params = JSON.parse(params)
  }

  // ddc收集完成回调和超时回调只处理第一个执行的回调，后续的不再处理
  let hasRunCallback = false
  // 结果回调
  function returnCallback(data) {
    if (hasRunCallback) return
    hasRunCallback = true
    cb && cb(data)
    window.removeEventListener('message', addDdcEvent, false)
  }

  // 添加ddc监听事件
  function addDdcEvent(event) {
    if (event.origin === host) {
      returnCallback(event.data || {})
    }
  }
  window.addEventListener('message', addDdcEvent, false)

  // 创建iframe和form
  const commonDdcIframeId = 'commonDdcIframe'
  const commonDdcFormId = 'commonDdcForm'
  let commonDdcIframe = document.getElementById(commonDdcIframeId)
  let commonDdcForm = document.getElementById(commonDdcFormId)

  if (!commonDdcIframe) {
    commonDdcIframe = document.createElement('iframe')
    commonDdcIframe.id = commonDdcIframeId
    commonDdcIframe.name = commonDdcIframeId
    commonDdcIframe.style.display = 'none'
    document.body.appendChild(commonDdcIframe)
  }

  if (!commonDdcForm) {
    commonDdcForm = document.createElement('form')
    commonDdcForm.id = commonDdcFormId
    document.body.appendChild(commonDdcForm)
  }
  
  commonDdcForm.target = commonDdcIframeId
  commonDdcForm.method = method
  commonDdcForm.action = action
  let inputsStr = ''
  for (const key in params) {
    inputsStr += `<input type="hidden" name="${key}" value="${params[key]}" />`
  }
  commonDdcForm.innerHTML = inputsStr
  commonDdcForm.submit()

  // 超时处理
  timer = setTimeout(() => {
    returnCallback()
  }, timeout)
}

export function isSafariVersionGTE15() {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') return false
  const userAgent = navigator.userAgent || navigator.vendor

  // 判断是否为 Safari 浏览器 (排除 Chrome、Edge 等基于 WebKit 的浏览器)
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)
  
  if (isSafari) {
    // 提取 Safari 版本号
    const match = userAgent.match(/Version\/(\d+)\.(\d+)?/)
    if (match && match[1]) {
      const majorVersion = parseInt(match[1], 10)
      return majorVersion >= 15 // 判断主版本号是否大于等于 15
    }
  }

  return false // 不是 Safari 或无法解析版本号
}
