var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{directives:[{name:"enterkey",rawName:"v-enterkey"}],class:[_setup.prefixCls('new-gift-card')],attrs:{"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();return _setup.handleOpen.apply(null, arguments)}}},[_c('div',{staticClass:"input-icon"},[_c('span',{staticClass:"bar__left"},[_vm._v("\n      "+_vm._s(_setup.language.SHEIN_KEY_PWA_15846)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"bar__right"},[(_setup.selectedItem)?[_c('div',{staticClass:"info"},[_c('span',{staticClass:"info__price"},[_vm._v("-"+_vm._s(_setup.getDiscountPrice))]),_vm._v(" "),_c('span',{staticClass:"info__balance"},[_vm._v(_vm._s(_setup.getBalancePrice))])])]:[_c('span',{staticClass:"tip"},[_vm._v(_vm._s(_setup.getGiftCard?.guide_tip))]),_vm._v(" "),(+_setup.getGiftCard?.red_dot === 1 && _setup.isShowRedDot)?_c('div',{staticClass:"dot"}):_vm._e()],_vm._v(" "),_c(_setup.Icon,{staticClass:"icon-arrow",attrs:{"name":!_setup.GB_cssRight ? 'sui_icon_more_right_16px' : 'sui_icon_more_left_16px',"color":"#959595"}})],2)]),_vm._v(" "),_c(_setup.ClientOnly,[(_setup.apiCardsMap)?_c(_setup.BGiftCard,{ref:"giftCardRef",attrs:{"drawer-props":{
        size: '80%',
        visible: _setup.virtualAssetsState?.newGiftCardDrawer?.visible,
      },"extraData":{
        discountPrice: _setup.getDiscountPrice,
        selectedNo: _setup.selectedItem?.card_no,
        abtType: +_setup.getGiftCard.gift_card_abt,
        discountPriceHeight: '3.75rem'
      },"scene":"checkout","card-data":_setup.getCardsMap},on:{"custom-event":_setup.onCustomEvent},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c(_setup.Footer,{attrs:{"isSimple":true}})]},proxy:true}],null,false,189235078)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }