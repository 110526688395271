import {
  dataVisorCb,
  formatThirdLoginOptions,
  reloadOneTrustBanner,
  getFuzzyDefaultLocation,
  phoneCodeCache
} from './util'
import { InitFtoken } from '../common/ftoken/index'
import schttp from 'public/src/services/schttp/index'
import { setAuthUserInfo } from '@login/utils/auth.js'

const { SiteUID } = typeof gbCommonInfo === 'object' ? gbCommonInfo : {}

async function requestPromise(type, url, params, headers = {}) {
  const method = type.toLowerCase()
  const options = {
    method,
    url,
    headers: { 'x-ftoken': window?.gbCommonInfo?.ftoken, ...headers }
  }
  if (method == 'get') options.params = params
  else options.data = params

  const data = await schttp(options).catch(err => ({ code: -1, msg: err.message }))

  return data
}

export async function request(type, url, params, headers) {
  await getFtoken()
  const data = await requestPromise(type, url, params, headers)
  return data
}

// 登录注册统一处理入口
export function commonDealWith(res, options = {}) {
  if (res && res.code == 0) {
    if(window.fetchCartContentInfo !== null) {
      window.fetchCartContentInfo = undefined
    }
    const { type = '', params } = options
    try {
      setAuthUserInfo(res)
      reloadOneTrustBanner(res)
    } catch (error) {
      console.log('onetrust:' + error)
    }
    if (type == 'phone' && params?.area_abbr) phoneCodeCache.set(params.area_abbr, 1)
  }
}

/**
 * 获取ftoken
 */
export function getFtoken() {
  return new Promise(resolve => {
    if (window?.gbCommonInfo?.ftoken) return resolve(window.gbCommonInfo.ftoken)
    InitFtoken(
      () => {
        resolve(window.gbCommonInfo.ftoken)
      },
      { notSendFtoken: true }
    )
  })
}

/**
 * 登录方法
 * @param userData 用户数据 { email, password, activity_name?, g_recaptcha_response? }
 * @param cb 回调函数(nodejs 风格，第一个参数为error, 第二个参数为response)
 */
export async function login(userData, cb) {
  const data = await loginSer(userData)
  cb(null, data)
}

export async function loginSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  const timestap = Date.now()
  params.timestap = timestap
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const headers = {
    canvas: gbCommonInfo._loginCanvas,
    webgl: gbCommonInfo._loginWebgl
  }
  const data = await request('post', `/api/auth/emailSignin/update`, params, headers)
  commonDealWith(data)
  return data
}

// 邮箱注册
export async function emailSignupSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  dataVisorCb(params, () => {})
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', `/api/auth/emailSignup/create`, params)
  commonDealWith(data)
  return data
}

/**
 * 注册方法
 * @param userData 注册数据
 * @param userData.email 邮箱
 * @param userData.password 密码
 * @param userData.password_confirm 再次确认密码
 * @param userData.register_type 注册类型
 * @param userData.activity_name 活动名称
 * @param cb  回调函数(nodejs 风格，第一个参数为error, 第二个参数为response)
 */
export function register(userData, cb) {
  emailRegisterSer(userData).then(res => {
    cb(null, res)
  })
}

export async function emailRegisterSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  dataVisorCb(params, () => {})
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', '/api/auth/emailSignup/create', params)
  commonDealWith(data)
  return data
}

/**
 * 忘记密码发送邮件
 * @param email
 * @param cb 回调函数(nodejs 风格，第一个参数为error, 第二个参数为response)
 */
export function forgetPasswordEmail(data, cb) {
  forgetPasswordEmailSer(data).then(res => cb(null, res))
}

export async function forgetPasswordEmailSer(params) {
  const data = request('post', `/api/auth/forgetPassword/get`, params)
  return data
}

/**
 * Facebook登录方法
 * @param reqData 用户数据
 * @param cb 回调函数, nodejs回调函数风格
 */
export function fbLogin(reqData, cb) {
  fbLoginSer(reqData).then(res => cb(null, res))
}

export async function fbLoginSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', '/api/auth/fbLogin/update', params)
  commonDealWith(data)
  return data
}

/**
 * Google登录方法
 * @param reqData 用户数据
 * @param cb 回调函数, nodejs回调函数风格
 */
export function googleLogin(reqData, cb) {
  googleLoginSer(reqData).then(res => cb(null, res))
}

export async function googleLoginSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', '/api/auth/googleLogin/update', params)
  commonDealWith(data)
  return data
}

/**
 * vk登录方法
 * @param reqData 用户数据
 * @param cb 回调函数, nodejs回调函数风格
 */
export function vkLogin(reqData, cb) {
  vkLoginSer(reqData).then(res => cb(null, res))
}

export async function vkLoginSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', '/api/auth/vkLogin/update', params)
  commonDealWith(data)
  return data
}

export async function lineLoginSer(params) {
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', `/api/auth/lineLogin/update`, params)
  commonDealWith(data)
  return data
}

export async function kakaoLoginSer(params) {
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', `/api/auth/kakaoLogin/update`, params)
  commonDealWith(data)
  return data
}

export async function naverLoginSer(params) {
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', `/api/auth/naverLogin/update`, params)
  commonDealWith(data)
  return data
}

/**
 * 三方登录统一方式，为了简化流程，将参数统一一致
 * @type type 三方登录类型 7（邮箱），12（google），11（facebook）,13(vk)
 * @param reqData 用户数据
 * @param cb 回调函数, nodejs回调函数风格
 * @param isNeedFormat 是否需要重新格式化参数
 */
export async function loginThird(type, reqData, cb, isNeedFormat = true) {
  loginThirdSer(type, reqData, isNeedFormat).then(res => cb(null, res))
}

export async function loginThirdSer(type, reqData, isNeedFormat = true) {
  let options = reqData
  if (isNeedFormat) {
    options = formatThirdLoginOptions(type, reqData)
  }
  dataVisorCb(options, () => {})
  options.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', `/api/auth/loginThird/update?type=${type}`, options)
  commonDealWith(data)
  return data
}

/**
 * 绑定邮箱和社交方式
 * @param {Object} options 参数
 * @param {string} options.email 邮箱
 * @param {string} options.type 绑定的登录方式(7: 邮箱, 12: Google, 11: FaceBook, 13: VK)
 * @param {string?} options.password 密码
 * @param {string?} options.social_id 第三方社交渠道id
 * @param {string?} options.access_token 第三方鉴权token
 * @param {function} cb 回调函数
 */
export function loginBind(options, cb) {
  loginBindSer(options).then(res => cb(null, res))
}

export async function loginBindSer(params) {
  const data = await request('post', '/api/auth/loginBind/update', params)
  return data
}

export function sendMailForRegister(options, cb) {
  sendMailForRegisterSer(options).then(res => cb(null, res))
}

export async function sendMailForRegisterSer(params) {
  const data = await request('post', '/api/auth/sendMailForRegister/update', params)
  return data
}

export async function thirdLoginLocate(params) {
  const data = await request('post', '/api/auth/thirdLocate/update', params)
  return data
}

export async function thirdLoginCodeSend(params) {
  const iswathsapp = params.send_type == 1
  // whatsapp暂时不走新接口，
  if (!iswathsapp) {
    params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
    const data = await request('post', `/api/auth/sendUicsCode/get`, params)
    return data
  }
  const data = request('POST', `/api/auth/sendCode/get`, params)
  return data
}

export async function codeVerfiyLogin(params) {
  const data = await request('POST', `/api/auth/codeVerify/get`, params)
  commonDealWith(data)
  return data
}

function sortPhoneArea(list) {
  if (list.length == 0) return
  list.sort((v1, v2) => {
    let name1 = v1?.name || ''
    let name2 = v2?.name || ''

    if (!name1 || !name2) return 0
    const len = name1.length

    let flag = 0

    for (let i = 0; i < len; i++) {
      const ch1 = name1.charAt(i)
      const ch2 = name2.charAt(i)

      if (ch1 > ch2) {
        flag = 1
        break
      }
      if (ch1 < ch2) {
        flag = -1
        break
      }
    }
    return flag
  })
}

function formatSupportsPhoneAreaCodes(data) {
  const list = []
  const { PHONE_SUPPORT_COUNTRYS } = gbCommonInfo
  PHONE_SUPPORT_COUNTRYS.forEach(k => {
    const v = data.find(v1 => v1.value == k)
    if (v) {
      list.push({
        code: v.phone_code?.replace('+', ''),
        abbr: v.value,
        value: v.value,
        name: v.country,
        siteUid: v.siteUid || ''
      })
    }
  })
  sortPhoneArea(list)
  return list
}

function formatAllPhoneAreaCodes(data) {
  const list = []
  data.forEach(item => {
    list.push({
      code: item.phone_code?.replace('+', ''),
      abbr: item.value,
      name: item.country
    })
  })
  return list
}

// 获取手机国家号区码
export async function getPhoneAreaCodeList({ countryList } = {}) {
  const data = countryList || window.SHEIN_LOGIN?.__pre_data?.countryList || []
  return {
    all: data,
    support: formatSupportsPhoneAreaCodes(data || []),
    allFormat: formatAllPhoneAreaCodes(data || [])
  }
}

// 手机号码登陆
export async function phoneLoginSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  const timestap = Date.now()
  params.timestap = timestap
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', `/api/auth/phoneSignin/update`, params)
  commonDealWith(data, { type: 'phone', params })
  return data
}

// 手机号码注册
export async function phoneRegisterSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  const headers = { 'x-ftoken': gbCommonInfo.ftoken }
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', `/api/auth/phoneSignup/create`, params, headers)
  commonDealWith(data, { type: 'phone', params })
  return data
}

// 手机号码检测
export async function phoneCheckSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  const headers = { 'x-ftoken': gbCommonInfo.ftoken }
  const data = await request('post', `/api/auth/phoneValidate/get`, params, headers)
  return data
}

export async function initLoginAsyncInfo() {
  const queues = [
    getPhoneAreaCode()
  ]
  const [phoneArea] = await Promise.all(queues)
  return { phoneArea }
}

export async function initLoginAsyncInfoList() {
  const queues = [
    getPhoneAreaCodeList()
  ]
  const [phoneArea] = await Promise.all(queues)
  return { phoneArea }
}

// 手机号码绑定
export async function phoneBindSer(params) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  const headers = { 'x-ftoken': gbCommonInfo.ftoken }
  const data = await request('post', `/api/auth/aliasBind/update`, params, headers)
  return data
}

// 手机号码忘记密码
export async function phoneForgetSer(params) {
  const headers = { 'x-ftoken': gbCommonInfo.ftoken }
  const data = await request('post', `/api/auth/phoneForget/get`, params, headers)
  return data
}

// 重置密码
export async function resetPasswordSer(params) {
  const headers = { 'x-ftoken': gbCommonInfo.ftoken }
  const data = await request('post', `/api/auth/resetPassword/update`, params, headers)
  return data
}

export async function getMemeberList() {
  const data = await request('post', `/api/auth/accountList/query`)
  const res =  {
    account_list: data?.info?.account_list || [],
    subscribe_status: data?.info?.subscribe_status || ''
  }
  return res
}


// 查询已登录用户条款
export async function checkMemeberCluaseSer(params) {
  const data = await request('post', `/api/auth/clause/get`, params)
  return data
}

// 查询对应国家id的条款
export async function checkLocationClauseSer(params) {
  const data = await request('post', `/api/auth/clause/checkCountry/get`, params)
  return data
}

export async function checkUserGeoSer() {
  const data = await request('post', `/api/auth/userGeo/get`)
  return data
}

// 获取当前站点支持的国家列表
export function getSiteUidSupportsLocation() {
  const list = window.SHEIN_LOGIN?.__pre_data?.countryList || []
  const supportCountry = list.filter(v => {
    if (SiteUID == 'm') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'm'
    }
    if (SiteUID == 'rwm') {
      return typeof v.siteUid == 'undefined' || v.siteUid == 'm'
    }
    return v.siteUid == SiteUID
  })
  return supportCountry
}

/**
 * 获取当前用户的默认地区
 */
export async function getDefaultLocationWithIp() {
  const geo = await checkUserGeoSer()
  const locationId = geo?.info?.location?.country_id || ''
  const supports = await getSiteUidSupportsLocation()

  // 当前ip区域是否在当前站点支持的地区列表
  let target = supports.find(v => v.id == locationId)

  const defaultLocation = getFuzzyDefaultLocation(supports) || supports[0] || {}

  const location = {
    geo: geo?.info?.location || {}, // ip
    def: defaultLocation || {}, // default
    code: target?.value || defaultLocation?.value || '', // target
    id: target?.id || defaultLocation?.id || '',
    issupport: !!target
  }
  return location
}

export async function getMixPrivacyInfo() {
  const { id, issupport } = await getDefaultLocationWithIp()

  const { info = {} } = await checkLocationClauseSer({ locationId: id })

  return {
    id,
    issupport,
    privacyType: info?.clause_country_type || 0
  }
}

// 风控验证码发送
export async function riskSendCodeSer(params) {
  const data = await request('post', `/api/user/center/sendRiskCode/get`, params)
  return data
}

// 风控验证码验证
export async function riskCodeVerifySer(params) {
  // verifyRiskCode
  const data = await request('post', `/api/user/center/verifyRiskCode/update`, params)
  return data
}

// 风控挑战更新
export async function updateRiskChalleageSer(params) {
  const data = await request('post', `/api/auth/riskChalleage/update`, params)
  return data
}

// 检测关联账号一致性
export async function checkRelationAccSer(params) {
  const data = await request('post', `/api/auth/relationCheck/get`, params)
  return data
}

export async function googleOnetabSer(params) {
  dataVisorCb(params, () => {})
  const data = await request('post', `/api/auth/googleOneTabRegister/update`, params)
  commonDealWith(data)
  return data
}

// 新版手机号邮箱绑定接口
export async function bindAliasSer(params) {
  const data = await request('post', `/api/auth/aliasBind/update`, params)
  return data
}

// 定位接口
export async function aliasLocationSer(params) {
  const timestap = Date.now()
  params.timestap = timestap
  const data = await request('post', `/api/auth/location/get`, params)
  return data
}

// 获取手机国家号区码(个人中心)
export async function getPhoneAreaCode() {
  const JumpCountry = (await import('public/src/pages/common/orderLogic/jumpCountry')).default
  const data = await JumpCountry.getLocationList()
  return {
    all: data,
    support: formatSupportsPhoneAreaCodes(data || []),
    allFormat: formatAllPhoneAreaCodes(data || [])
  }
}
