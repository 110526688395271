/**
 * 全场景购物车入口丰富利诱点信息
 * wiki.pageId=1184179869
 */

import { cartTagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

class CartTagTips {
  constructor() {
    this.cartTagTip = cartTagTip
    this.abtCartentranceinfo = this.getAbt()
  }

  async initialize() {
    const abt = await this.abtCartentranceinfo
    const isManualCall = await this.isManualCall()
    this.cartTagTip.initialize({
      disabledTagTip: abt.switch !== 'on',
      needUpdateCartNum: false,
      autoUseCoupon: abt.autoCouponSwitch === 'on',
      disabledFirstTimeTip: isManualCall,
      getCountryId() {
        let addressCookie = localStorage.getItem('addressCookie')
        let countryId = ''
        try {
          addressCookie = JSON.parse(addressCookie)
          countryId = addressCookie?.countryId ?? ''
        } catch {
          return countryId
        }
        return countryId
      },
    })
  }

  // 业务层通过调用 window._gb_cart_tag_tips_.update() 触发此方法
  async update(config = {
    excludeScroll: false,
    noTips: false,
    cartInfo: null,
    needUpdateCartNum: false, // BFF 新增
    callback: null, // BFF 新增
  }) {
    try {
      if (typeof window === 'undefined') return 'window is not defined'

      if (await this.isManualCall()) return '当前页面不允许调用 update'

      const abt = await this.abtCartentranceinfo
      if (abt.switch !== 'on' && abt.listSwitch !== 'on') return 'abt Cartentranceinfo is off'

      return cartTagTip.update({
        ...config,
        needUpdateCartNum: false, // 统一由 vuex mutations changeCartInfo 更新
      })
    } catch (error) {
      return error
    }
  }

  async showtime(config = {}) {
    if (typeof window === 'undefined') {
      config.callback?.()
      return 'window is not defined'
    }

    const abt = await this.abtCartentranceinfo
    if (abt.switch !== 'on') {
      config.callback?.()
      return 'abt Cartentranceinfo is off'
    }

    if (!await this.isManualCall()) {
      config.callback?.()
      return '当前页面不允许调用 showtime'
    }

    return cartTagTip.showtime(config)
  }

  /**
   * 利诱数据预取
   * 当调用 preRequest 后, 再调用 showtime 时, showtime 不再调用 getApi,
   * 而是拿 preRequest 预取的数据
   */
  async preRequest(params) {
    return cartTagTip.preRequest(params)
  }

  // 获取最高利诱信息
  async getCurrentTag() {
    return cartTagTip.getCurrentTag()
  }

  getLure() {
    return cartTagTip.getLure()
  }

  // 返回埋点需要字段值信息，针对于0305需求：MR-15042 新增埋点参数添加
  async getExposeData() {
    return cartTagTip.getExposeData()
  }

  async getAbt() {
    try {
      if (typeof window === 'undefined') {
        return {
          autoCouponSwitch: 'off',
          switch: 'off',
          listSwitch: 'off',
        }
      }
      // !!!!!经列表产品确认，列表悬浮购物车有值时，值保持和 顶部和底部的 配置值一致
      const { Cartshowcoupon, Cartentranceinfo, CartEntranceInfoSwitchNew, CartEntranceInfoNewuser, listcartinfoSwitch } = await getUserAbtData()
      let switchNew = CartEntranceInfoSwitchNew?.param?.cart_entrance_info_switch_new
      if (!switchNew || switchNew === 'off') switchNew = 'home=on'
      switchNew = switchNew.split(',').reduce((pre, cur) => {
        const [key, value] = cur.split('=')
        if (!pre[key]) pre[key] = value || 'off'
        return pre
      }, { home: 'on' })
      return {
        autoCouponSwitch: Cartshowcoupon?.param?.cart_show_coupon_switch || 'off',
        switch: Cartentranceinfo?.param?.cart_entrance_info_switch || 'off',
        switchNew,
        newuserStyle: CartEntranceInfoNewuser?.param?.cart_entrance_info_newuser || 'off',
        priority: Cartentranceinfo?.param?.cart_entrance_info_priority?.split(',') || null,
        listSwitch: listcartinfoSwitch?.param?.list_entrance_info_switch || 'off',
      }
    } catch (error) {
      return {
        autoCouponSwitch: 'off',
        switch: 'off',
        switchNew: { home: 'on' },
        listSwitch: 'off',
      }
    }
  }

  async refresh() {
    this.cartTagTip.refresh({ needUpdateCartNum: false })
  }

  async isManualCall() {
    const abt = await this.abtCartentranceinfo
    const map = {
      config_index: 'home',
      page_category: 'category',
      campaigns: 'new',
      UserIndex: 'me',
    }
    const key = map[_gb_app_.$route.name]

    // 不是底部导航栏的四个页面时，则判断当前页面是否有新的悬浮购物车
    if (!key) {
      return !!document.querySelector('.j-quick-cart-container')
      // return !!getNewQuickCart()
    }

    if (abt.switchNew === 'off') return false

    return abt.switchNew[key] === 'on'
  }

  /**
   * 当用户首次访问网页时，显示一次利诱气泡
   */
  async onceTip() {}
}

const cartTagTips = new CartTagTips()

export default cartTagTips
