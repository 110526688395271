<template>
  <div
    ref="beltRef"
    :style="{
      ...beltInfo.style
    }"
    :class="[prefixCls('common_belt'), beltInfo.cls]"
  >
    <ClientOnly>
      <img
        v-if="!!beltInfo.icon"
        :class="prefixCls('common_belt-icon')"
        :src="beltInfo.icon"
        alt=""
      />
      <CommonCountDown
        v-if="beltInfo.isCountDown"
        v-slot="{ timer }"
        ref="countDownRef"
        :end-time-stamp="beltInfo.endTimeStamp"
        @finish="handleTimerFinished"
      >
        {{ timer.timeObj.H }}:{{ timer.timeObj.M }}:{{ timer.timeObj.S }}
      </CommonCountDown>
      <span
        v-else
        :class="prefixCls('common_belt-text')"
      >
        {{ beltInfo.text }}
      </span>
    </ClientOnly>
  </div>
</template>

<script setup>
import ClientOnly from 'vue-client-only'
import CommonCountDown from 'public/src/pages/checkout_new/components/CommonCountDown.vue'

import { updateCartsAndCheckout } from '@/public/src/pages/checkout_new/utils/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import useBeltInfo from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useBeltInfo.js'

const props = defineProps({
  // 商品项数据
  data: {
    type: Object,
    default: () => ({})
  },
  // 容器宽度
  renderWidth: {
    type: Number,
    default: 0
  }
})

const { beltInfo } = useBeltInfo(props)

const handleTimerFinished = () => {
  // 闪购倒计时结束，需要更新数据
  updateCartsAndCheckout()
}
</script>

<style lang="less">
@import '../../variables.less';

.@{prefixCls}common_belt {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 36/75rem;
  font-size: 12px;
  padding: 4/75rem 8/75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: initial;
  line-height: normal;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  &-add-tips {
    background: #fcf5ef;
    color: #ad633d;
  }
  &-gift-tips {
    background: #fb7751;
    color: #fff;
  }
  &-addition-good {
    background: #fcf5ef;
    color: #ad633d;
  }
  &-stock {
    background: @sui_color_black;
    opacity: 0.6;
    color: #fff;
  }
  &-gift {
    color: @sui_color_white;
    background-color: @sui_color_club_rosegold_dark1;
  }
  &-transport {
    color: @sui_color_white_alpha96;
    background: @sui_color_gray_alpha60;
  }
}

.@{prefixCls}common_belt-icon {
  width: 24/75rem;
  height: 24/75rem;
}

.@{prefixCls}common_belt-text {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
