<template>
  <div 
    class="xtra-item"
    :class="{
      'xtra-item_small': !entryCouponStyle?.showCouponLimit
    }"
    :style="{
      ...couponConfig[couponType],
      '--coupon-circle-bg-color': entryCouponStyle?.couponCircleBgColor
    }"
  >
    <div 
      ref='lightEffectRef'
      class="xtra-item__wrapper"
    >
      <!-- 已勾选icon -->
      <div 
        v-if="coupon.selected"
        class="xtra-item__selected"
      >
        <Icon 
          name="sui_icon_selected_12px" 
          size="12px" 
          color="#F82854"
        />
      </div>
      <!-- 新人券标签 -->
      <div
        v-if="coupon?.activityPrivilegeTip"
        class="xtra-item__gifts"
        :class="{
          'xtra-item__gifts_ar': GB_cssRight
        }"
      >
        <Icon 
          name="sui_icon_giftbox_12px"
          size="12px"
          color="#fff"
        />
        <div class="xtra-item__gifts-text">
          <div ref="scrollTextRef">
            {{ coupon.activityPrivilegeTip }}
          </div>
        </div>
      </div>
      <!-- 数量角标（新人券不展示） -->
      <div
        v-else-if="(+coupon.sameCouponNum > 1) && entryCouponStyle.showCouponNumber"
        class="xtra-item__quantity" 
      >
        X{{ coupon.sameCouponNum }}
      </div>
      <div class="xtra-item__content">
        <!-- 券面额 -->
        <div
          class="xtra-item__discount"
          :class="{
            'xtra-item__discount_shipping': couponType === XtraCouponType.Shipping,
          }"
        >
          <SAdapterText
            min-size="10"
            lines="2"
            :text="discount"
          />
        </div>
        <!-- 券门槛 -->
        <SAdapterText 
          v-if="showLimit"
          class="xtra-item__rule"
          min-size="10"
          :text="rule"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, computed } from 'vue'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { couponConfig, XtraCouponType } from './shared/config.js'

const { GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const props = defineProps({
  coupon: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  entryCouponStyle: {
    type: Object,
    default: () => {}
  },
  lightEffect: {
    type: Boolean,
    default: false
  }
})

const otherRule = computed(() => {
  return props.coupon?.otherCouponRule[0] || {}
})

const discount = computed(() => {
  return GB_cssRight ?
  `${otherRule.value.couponFaceValueTip} ${otherRule.value.couponFaceValue}` :
  `${otherRule.value.couponFaceValue} ${otherRule.value.couponFaceValueTip}`
})

const rule = computed(() => {
  return otherRule.value?.freeCouponThresholdTip
})

const couponType = computed(() => {
  return props.coupon?.applyForType == 'DISCOUNT' ? XtraCouponType.Product : XtraCouponType.Shipping
})

const showLimit = computed(() => {
  // 若组件未配置，则展示
  if (props.entryCouponStyle?.showCouponLimit == null) return true
  return props.entryCouponStyle?.showCouponLimit
})


const scrollTextRef = ref(null)

const updateScrollTextStyle = () => {
  if (!scrollTextRef.value) return
  const { scrollWidth, parentElement } = scrollTextRef.value || {}
  const parentWidth = parentElement.clientWidth
  if (scrollWidth <= parentWidth) {
    scrollTextRef.value.style.animation = 'none'
  } else {
    const time = Math.floor(+scrollWidth * (3 / 100))
    scrollTextRef.value.style.animationDuration = `${time}s`
  }
}

watch(
  () => props.coupon,
  (newVal, oldVal) => {
    if(!window || JSON.stringify(oldVal) === JSON.stringify(newVal)) return
    nextTick(() => {
      updateScrollTextStyle()
    })
  },
  {
    immediate: true,
    deep: true
  }
)

const lightEffectRef = ref(null)
watch(
  () => props.lightEffect, (nVal) => {
    if (nVal) {
      lightEffectRef.value?.classList?.add('light-slide-effect', GB_cssRight ? 'light-slide-effect-rtl' : 'no-light-slide')

      setTimeout(() => {
        lightEffectRef.value?.classList?.remove('light-slide-effect', 'light-slide-effect-rtl')
      }, 600)
    }
  }
)
</script>

<style lang="less" scoped>
/* 定义动画 */
@keyframes scrollText {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
    transform: translateX(-100%);
  }
}
// 阿拉伯语言滚动
@keyframes scrollTextAr {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
    transform: translateX(-100%);
  }
}

.xtra-item {
  width: 200/75rem;
  height: 122/75rem;
  position: relative;
  border-radius: 8/75rem;
  padding-top: 10/75rem;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -2/75rem;
    width: 24/75rem;
    height: 24/75rem;
    background-color: var(--coupon-circle-bg-color, #fff);
    border: 2/75rem solid var(--borderColor);
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &::after {
    left: auto;
    right: -2/75rem;
    transform: translate(50%, -50%);
  }

  &__wrapper {
    position: relative;
    width: 100%;
    // height: 112/75rem;
    height: 100%;
    border-radius: 8/75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2/75rem solid var(--borderColor);
    background: linear-gradient(180deg, var(--bgStartColor) 33.04%, var(--bgEndColor) 176.79%);
  }

  &__selected {
    position: absolute;
    left: 0;
    top: 10/75rem;
    border-radius: 8/75rem 0 22/75rem;
    background-color: #FDD2DB;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2/75rem 6/75rem 2/75rem 2/75rem;
  }

  &__gifts {
    max-width: 146/75rem;
    position: absolute;
    top: 0;
    right: 0;
    .font-dpr(20px);
    line-height: 1.2;
    color: #fff;
    border-radius: 22/75rem 12/75rem 0 22/75rem;
    opacity: 0.9;
    background: linear-gradient(270deg, #8F0CE8 -31.37%, #FF4EAD 34.38%);
    display: flex;
    align-items: center;
    padding: 2/75rem 12/75rem;

    &-text {
      width: 98/75rem;
      height: 24/75rem;
      margin-left: 4/75rem;
      overflow: hidden;
      white-space: nowrap;
      position: relative;

      > div {
        font-family: "SF Pro";
        font-weight: 700;
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
        animation: scrollText 5s linear infinite;
      }
    }

    &_ar {
      .xtra-item__gifts-text > div {
        animation: scrollTextAr 5s linear infinite;
      }
    }
  }

  &__quantity {
    position: absolute;
    right: 0;
    top: 10/75rem;
    padding: 2/75rem 12/75rem;
    border-radius: 0 8/75rem;
    color: #F82854;
    background-color: #FDD2DB;
    font-family: "SF Pro";
    .font-dpr(20px);
    font-weight: 510;
    line-height: 1.2;
  }

  &__content {
    max-width: 160/75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.2;
    text-align: center;
  }

  &__discount {
    width: 100%;
    max-height: 68/75rem;
    position: relative;
    .font-dpr(28px);
    font-weight: 800;
    color: var(--discountColor);
    margin-top: 14/75rem;

    &_shipping {
      .font-dpr(24px);
    }

  }

  &__rule {
    width: 100%;
    .font-dpr(20px);
    color: var(--ruleColor);
  }
}
.xtra-item_small {
  height: 102/75rem;

  .xtra-item__discount {
    margin-top: 0;
  }
}

.light-slide-effect::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: -150%; /* 初始位置在左边外，待动画滑入 */
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.1) 100%);
  transform: skewX(-30deg); /* 倾斜角度 */
  animation: shiny 0.6s linear infinite;
} 

.light-slide-effect-rtl::before {
  animation: shiny-ar 0.6s linear infinite !important;
}

/* 定义动画关键帧实现左到右滑动 */
@keyframes shiny {
  0% {
    left: -150%;
  }
  // 50% {
  //   left: 150%;
  // }
  100% {
    left: 150%;
  }
}

@keyframes shiny-ar {
  0% {
    right: -150%;
  }
  // 50% {
  //   left: 150%;
  // }
  100% {
    right: 150%;
  }
}
</style>
