import { nextTick } from 'vue'

export const useGooglePayDialog = (paymentsStore) => {
  let googlePayDialogCloseCb = () => {}

  const openGooglePayDialog = ({ handleOpen, handleClose }) => {
    if (typeof handleClose === 'function') {
      googlePayDialogCloseCb = handleClose
    }
    paymentsStore?.updateState('showGooglePayDialog', true)
    nextTick(() => {
      handleOpen?.()
    })
    const autoCloseDialog = () => {
      paymentsStore?.updateState('showGooglePayDialog', false)
      nextTick(() => {
        handleClose?.('autoClose')
      })
    }
    return autoCloseDialog
  }

  const handleCloseGooglePayDialog = () => {
    googlePayDialogCloseCb()
    paymentsStore?.updateState('showGooglePayDialog', false)
  }

  return {
    openGooglePayDialog,
    handleCloseGooglePayDialog,
  }
}
