import { computed, ref } from 'vue'
import useApis from './useApis'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { usePaymentsStore, type PaymentsStore } from './usePayments'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { debuggerLog } from '../utils'

const defaultConfig = {
  needCollectEmail: false,
  needCollectPhoneNumber: false,
  paymentCode: '',
  countryCode: '',
  countryId: '',
  emailRule: {
    reg: /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/,
    copywrite: '',
  },
  phoneCountryNum: '',
  needContinuePay: false,
  visible: false,
  type: 'dialog',
}

const defaultCapitecDialogData = {
  phone: '',
  phoneError: 0,
  isPhoneChange: false,
  email: '',
  emailError: 0,
  isEmailChange: false,
}

// 推荐手机号
const recommendPhone = ref('')
// 推荐手机号是否显示
const recommendPhoneVisible = ref(false)
// 推荐手机号显示定时器
let recommendPhoneShowTimer: any = null
// 倒计时1s显示推荐手机号
const recommendPhoneShowTime = 1000
// 推荐手机号隐藏定时器
let recommendPhoneHideTimer: any = null
// 倒计时4s隐藏推荐手机号
const recommendPhoneHideTime = 4000

const capitecDialogData = ref<Trade_PayToolKit.ICapitecDialogData>(defaultCapitecDialogData)

export default function useCapitecDialog(paymentStore?: PaymentsStore) {
  const { getPaymentRelatedInfoApi, getTelCountryListApi, checkAliasApi } = useApis()
  const appConfigs = useAppConfigs()
  if (!paymentStore) {
    paymentStore = usePaymentsStore()
  }
  const dialogConfig = computed(() => paymentStore.capitecDialogConfig)
  const addressInfo = computed(() => paymentStore.checkoutInfo?.addressInfo || {})
  const capitecVisible = computed({
    get: () => dialogConfig.value.visible,
    set: (val) => {
      paymentStore.mergeState('capitecDialogConfig', {
        visible: val,
      })
    },
  })

  const fetchUserPaymentRelatedInfo = async ({
    countryCode,
    paymentCode,
  }: Pick<Trade_PayToolKit.ICapitecDialogConfig, 'countryCode' | 'paymentCode'>) => {
    if (!countryCode || !paymentCode) {
      return
    }

    debuggerLog(
      'fetchUserPaymentRelatedInfo===??====',
      paymentStore.checkoutInfo,
      addressInfo.value,
      capitecDialogData.value,
      capitecDialogData.value.phone,
    )
    try {
      const res = await getPaymentRelatedInfoApi({
        data: { countryCode, paymentCode },
        currency: paymentStore.checkoutInfo.orderCurrencyInfo.code,
        appLanguage: appConfigs.$envs.appLanguage,
      })
      if(res.code === '0'){
        const {isNewPhoneShow, bubbleTip, endorsementSafetyTips, phoneCheckRule, paymentRelatedInfo} = res.info || {}
        // isNewPhoneShow 等于 1 ，使用新手机号推荐交互
        if (isNewPhoneShow == '1') {
          if (addressInfo.value?.tel && !capitecDialogData.value.phone) {
            recommendPhone.value = paymentRelatedInfo?.phoneNumber || addressInfo.value?.tel || ''
            // 自动显示
            clearTimeout(recommendPhoneShowTimer)
            recommendPhoneShowTimer = setTimeout(() => {
              recommendPhoneVisible.value = true
            }, recommendPhoneShowTime)
            // 自动隐藏
            clearTimeout(recommendPhoneHideTimer)
            recommendPhoneHideTimer = setTimeout(() => {
              recommendPhoneVisible.value = false
            }, recommendPhoneShowTime + recommendPhoneHideTime)
          }
        } else {
          capitecDialogData.value.phone = addressInfo.value?.tel || ''
        }

        paymentStore.mergeState('capitecDialogConfig', {
          isNewPhoneShow,
          bubbleTip,
          endorsementSafetyTips,
          phoneCheckRule: phoneCheckRule?.result?.regex_list || [],
        })
      }
    } catch (error) {
      //
    }
    capitecDialogData.value.email = paymentStore.userInfoGetters.email || ''
  }

  // 关闭推荐手机号气泡
  const hideRecommendPhone = () => {
    clearTimeout(recommendPhoneShowTimer)
    clearTimeout(recommendPhoneHideTimer)
    recommendPhoneVisible.value = false
  }

  const fetchTelCountryList = async ({ countryId }: Pick<Trade_PayToolKit.ICapitecDialogConfig, 'countryId'>) => {
    countryId = countryId || ''
    const res = await getTelCountryListApi({
      appLanguage: appConfigs?.$envs?.appLanguage,
      currency: appConfigs?.$envs?.currency,
    })
    console.log('fetchTelCountryList===??====', res, countryId)
    if (res?.code == '0' && res?.info?.country?.item_cates?.length) {
      const countryInfo = res.info.country.item_cates.find(item => item.id === countryId)

      if (countryInfo) {
        const phoneCountryNum = `${countryInfo.value}+${countryInfo.countrynum}`
        paymentStore.mergeState('capitecDialogConfig', {
          phoneCountryNum,
        })
      }
    }
  }

  const openCapitecDialog = async (config: Partial<Trade_PayToolKit.ICapitecDialogConfig>) => {
    paymentStore.updateState('capitecDialogConfig', {
      ...defaultConfig,
      ...config,
    })
    capitecDialogData.value = defaultCapitecDialogData
    await Promise.all([
      fetchUserPaymentRelatedInfo({ countryCode: config.countryCode, paymentCode: config.paymentCode }),
      fetchTelCountryList({ countryId: config.countryId }),
    ])
    paymentStore.mergeState('capitecDialogConfig', {
      visible: true,
    })
  }

  const closeCapitecDialog = () => {
    paymentStore.mergeState('capitecDialogConfig', {
      visible: false,
    })
  }

  const genReportConfirmReason = () => {
    const reasonArr = new Set()
    const reasonTextArr = ['', 'unfilled', 'incorrect_format']
    if (capitecDialogData.value.phoneError) {
      reasonArr.add(reasonTextArr[capitecDialogData.value.phoneError])
    }
    if (capitecDialogData.value.emailError) {
      reasonArr.add(reasonTextArr[capitecDialogData.value.emailError])
    }
    return [...reasonArr].join()
  }

  const needValidCapitec = () => {
    const selectedPayment = paymentStore.selectedPayment

    const needCollectEmail = selectedPayment.collect_email == '1'
    const needCollectPhone = selectedPayment.collect_phone_number == '1'

    return (needCollectEmail && !selectedPayment?.pay_email) || (needCollectPhone && !selectedPayment?.pay_phone)
  }

  const validateByPay = (): Trade_PayToolKit.ValidateByPayType => {
    const selectedPayment = paymentStore.selectedPayment

    const needCollectEmail = selectedPayment.collect_email == '1'
    const needCollectPhone = selectedPayment.collect_phone_number == '1'
    const isMbwayPayment = ['adyen-mbway', 'routepay-mbway'].includes(selectedPayment.code)

    if (needValidCapitec()) {
      openCapitecDialog({
        countryCode: addressInfo.value?.countryCode || '',
        paymentCode: selectedPayment.code,
        countryId: addressInfo.value.countryId || '',
        needCollectEmail: needCollectEmail && !selectedPayment?.pay_email,
        needCollectPhoneNumber: needCollectPhone && !selectedPayment?.pay_phone,
        needContinuePay: true,
        // type: isMbwayPayment ? 'drawer' : 'dialog',
        type: 'dialog',
      })

      let metric_scene = 'payment_phone_email_empty'
      let result_reason = ''
      if (isMbwayPayment) {
        metric_scene = 'payment_mbway_phone_empty'
        result_reason = '1_7'
      }
      if (selectedPayment.code?.indexOf?.('boleto') > 0) {
        metric_scene = 'payment_boleto_phone_empty'
        result_reason = '1_8'
      }
      if (selectedPayment.code === 'ebanx-nequi') {
        metric_scene = 'payment_ebanx_nequi_phone_empty'
        result_reason = '1_17'
      }

      return {
        validateResult: false,
        scene: 'open_capitec_dialog',
        metric_scene,
        saParams: {
          result_reason,
        },
      }
    }
    return {
      validateResult: true,
      scene: '',
    }
  }


  return {
    capitecVisible,
    capitecDialogData,
    dialogConfig,
    genReportConfirmReason,
    checkAliasApi,
    addressInfo,
    openCapitecDialog,
    closeCapitecDialog,
    validateByPay,
    needValidCapitec,
    recommendPhone,
    recommendPhoneVisible,
    hideRecommendPhone,
  }
}
