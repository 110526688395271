<script lang="jsx">
import { sui_icon_more_up_16px_2, sui_icon_more_down_16px_2 } from '@shein-aidc/icon-vue2'


export default {
  name: 'DropdownLabel',
  functional: true,
  render(h, { props, listeners }) {
    const className = `dropdown-label__container ${props.className || ''}`
    const renderIcon = !props.isShowIcon 
      ? null
      : (
        <span class={{ 
          'dropdown-label__suffix-icon': true,
          'dropdown-label__suffix-icon-visible': props.visible,
          'dropdown-label__suffix-icon-selected': props.selected,
        }}>
          {
            props.visible
              ? <sui_icon_more_up_16px_2 size="12px" />
              : <sui_icon_more_down_16px_2 size="12px" />
          }
        </span>
      )

    return (
      <div
        class={className}
        onClick={listeners.click}
      >
        <div
          class={{
            'dropdown-label__content': true,
            'dropdown-label__content-selected': props.selected,
            'dropdown-label__content-visible': props.visible
          }}
          style={props.styles}
        >
          {
            props.icon 
              ? (
                <span class="dropdown-label__prefix-icon">
                  <img src={props.icon} />
                </span>
              )
              : null
          }

          <span class="dropdown-label__label">{props.label}</span>

          { renderIcon }
        </div>
      </div>
    )
  }
}
</script>

<style scoped lang="less">
.dropdown-label {
  &__container {
    display: inline-block;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
  }
  &__content-visible {
    font-weight: bold;
    color: #000 /* rw: #FC4070 */;
  }
  &__content-selected {
    font-weight: bold;
    color: #000 /* rw: #FC4070 */;
  }

  &__prefix-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    font-size: 16px;
    
    img {
      width: 16px;
      height: 16px;
      display: flex;
      vertical-align: middle;
    }
  }

  &__suffix {
    flex-shrink: 0;
    margin-left: 4px;
    color: rgba(0,0,0,0.8);
  }

  &__suffix-icon {
    margin-left: 4px;
    color: rgba(0,0,0,0.8);
  }

  &__suffix-icon-active, &__suffix-icon-selected {
    color: #222;
  }
}
</style>
