<template>
  <div class="benefit-drawer">
    <!-- 蒙层 -->
    <transition
      v-if="needBottom"
      name="S-animation-modal__drawer"
    >
      <div
        v-if="visible"
        ref="benefitDrawerMaskRef"
        key="mask"
        class="benefit-drawer__mask"
        :style="{
          bottom: drawerBottom,
          zIndex: 9999
        }"
        @click.stop="emits('update:visible', false)"
      ></div>
    </transition>
    <SDrawer
      ref="benefitDrawerRef"
      class="benefit-drawer__main"
      :visible.sync="drawerVisible"
      :modal="!needBottom"
      append-to-body
      no-header
      :max-size="`calc(80% - ${drawerBottom})`"
      :style="{ bottom: drawerBottom }"
      @beforeOpen="handleBeforeOpen"
      @opened="handleOpened"
      @beforeClose="handleBeforeClose"
    >
      <Header
        :language="langs"
        :title="drawerData.title"
        :total-value-tip="drawerData.totalValueTip"
        :end-time="drawerData.endTime"
        @close="emits('update:visible', false)"
      />

      <Main
        :reward-list="drawerData.rewardList"
      />

      <template #footer>
        <slot name="footer"></slot>
      </template>
    </SDrawer>
  </div>
</template>
<script name="BenefitDrawer" setup lang="ts">
import { computed, type Ref, ref } from 'vue'
import { Drawer as SDrawer } from '@shein/sui-mobile'
import Header from './Header.vue'
import Main from './Main.vue'
import type { C_BenefitFloor } from '../../../../types'

const props = defineProps<{
  visible: boolean
}>()

const emits = defineEmits(['update:visible'])

const benefitDrawerMaskRef: Ref = ref(null)
const benefitDrawerRef: Ref = ref(null)
const drawerData = ref({} as C_BenefitFloor.PopUpData)
const drawerBottom = ref('0')
const langs = ref({} as C_BenefitFloor.LanguageType)

const needBottom = computed(() => drawerBottom.value !== '0')

const drawerVisible = computed({
  get: () => props.visible,
  set: () => emits('update:visible', false),
})

const openDrawer = ({ data, bottom, language }: {
  data: C_BenefitFloor.PopUpData,
  bottom?: string,
  language: C_BenefitFloor.LanguageType
}) => {
  drawerData.value = data
  drawerBottom.value = bottom || '0'
  langs.value = language
}

const handleBeforeOpen = () => {
  if (typeof document !== 'undefined') {
    document.body.style.overflow = 'hidden'
  }
}

const handleOpened = () => {
  if (+drawerBottom.value === 0) return
  const { zIndex = 10000 } = benefitDrawerRef.value?.$el?.style || {}
  benefitDrawerMaskRef.value.style.zIndex = `${+zIndex - 1}`
}

const handleBeforeClose = () => {
  if (typeof document !== 'undefined') {
    document.body.style.overflow = ''
  }
}

defineExpose({
  openDrawer,
})

</script>
<style lang="less" scoped>
.benefit-drawer {
  box-sizing: border-box;

  &__main {

    /deep/ .S-drawer__container {
      border-radius: 8px 8px 0 0;
      box-shadow: none;
      overflow: visible;
    }

    /deep/ .S-drawer__body {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 8px 8px 0 0;
      background-color: #F6F6F6;
    }
  }

  &__mask {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
  }
}
</style>
