<template>
  <div
    :class="[
      prefixCls('ht__auto'),
    ]"
  >
    <slot></slot>
    <ClientOnly>
      <AnimationCarousel
        ref="AnimationCarouselRef"
        :class="[
          prefixCls('ht__auto-animation'),
          isShowTwoRowsTitle
            ? ''
            : prefixCls('ht__auto-animation_single')
        ]"
      >
        <template
          v-for="(_, index) in [0, 1]"
          :slot="`item${index}`"
        >
          <h2
            :key="index"
            :class="prefixCls('ht__aa_item')"
            :style="useGetTitleConfig(index, modelData).style || {}"
          >
            <!-- 使用中间层返回的 icon -->
            <img 
              v-if="useGetTitleConfig(index, modelData).iconUrl"
              :class="prefixCls('ht__aa-icon')"
              :src="useGetTitleConfig(index, modelData).iconUrl"
              :style="{
                width: isShowTwoRowsTitle ? '0.32rem' : '0.48rem',
                height: isShowTwoRowsTitle ? '0.32rem' : '0.48rem'
              }"
            />
            <template v-else>
              <Icon
                v-for="(icon, iconIndex) in allIcons"
                v-show="TitleConfig[modelData.titles[index]?.titleType]?.icon?.name === icon.name"
                :key="iconIndex"
                :name="icon.name"
                :color="icon.color"
                :size="isShowTwoRowsTitle ? '12px' : icon.size"
              />
            </template>
            <div 
              :class="[
                prefixCls('ht__aa-text'),
                isShowTwoRowsTitle
                  ? prefixCls('ht__aa-text_vice')
                  : prefixCls('ht__aa-text_single')
              ]"
            >
              <span
                v-if="useGetTitleConfig(index, modelData).before"
                :class="prefixCls('ht__aa_txt-normal')"
                v-html="useGetTitleConfig(index, modelData).before"
              ></span>
              <span
                v-if="useGetTitleConfig(index, modelData).special"
                :class="prefixCls('ht__aa_txt-normal')"
                v-html="useGetTitleConfig(index, modelData).special"
              ></span>
              <span
                v-if="useGetTitleConfig(index, modelData).after"
                :class="prefixCls('ht__aa_txt-normal')"
                v-html="useGetTitleConfig(index, modelData).after"
              ></span>
              <CountDown
                v-if="useGetTitleConfig(index, modelData).countdownTime"
                :class="[prefixCls('ht__aa-timer'), isShowTwoRowsTitle && prefixCls('ht__aa-timer_small')]"
                :end-time-stamp="useGetTitleConfig(index, modelData).countdownTime"
                :small="isShowTwoRowsTitle"
              />
            </div>
          </h2>
        </template>
      </AnimationCarousel>
    </ClientOnly>
  </div>
</template>

<script setup name="AutoScrollUpTitle">
import { onMounted, onUnmounted, computed, ref,  reactive, toRefs  } from 'vue'
import ClientOnly from 'vue-client-only'
import { Icon } from '@shein-aidc/icon-vue2'

import AnimationCarousel from '@/public/src/pages/checkout_new/pages/header/components/Carousel.vue'
import CountDown from '@/public/src/pages/checkout_new/pages/header/components/CountDown.vue'
import useTitleConfig from '../hooks/useGetTitleConfig'
import {
  TitleConfig,
  HeaderTitleType,
  allIcons
} from '@/public/src/pages/checkout_new/pages/header/utils/constant'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/header/utils'
import { exposeScenesAbtAggregatorBff as exposeScenesAbtAggregator } from 'public/src/pages/checkout/tools/EventAggregator.js'

// ---------- props ----------
const props = defineProps({
  isShowTwoRowsTitle: {
    type: Boolean,
    default: false
  },
  isNewAutoTitle: {
    type: Boolean,
    default: false
  }
})

// ---------- state ----------
const AnimationCarouselRef = ref(null)
const interTimer = ref(null)
const actualIndex = ref(0)
const isInit = ref(true)
const modelData = reactive({
  titles: [{}, {}], // 两个DOM需要的数据，titles永远只有两个
  actualUpdateData: [{ titleType: HeaderTitleType.Payment, value: '', passed: true }], // 实际需要滚动的数据，可能有很多个
  temporaryTotalType: [HeaderTitleType.Payment]
})

// -------- useMap_**** --------
const { useGetTitleConfig, getMiddleLayerType, handleScrollTitleReport, getAutoCycleList } = useTitleConfig(props.isNewAutoTitle)

// --------- computed ---------
const currentIndex = computed(() => {
  return AnimationCarouselRef.value?.currentIndex ?? 0
})

const nextIndex = computed(() => {
  return AnimationCarouselRef.value?.nextIndex ?? 0
})

// ---------- method ----------
/**
 * @description: 整理需要循环播放的数据
 * @param {Object} options
 * @return {*}
 */
const handleAutoCycleData = (options) => {
  const { titleType, passed, value } = options
  const initOrder = [HeaderTitleType.Shipping, HeaderTitleType.Payment, HeaderTitleType.Saved] // 初始展示的顺序
  const index = modelData.actualUpdateData.findIndex(item => item.titleType === titleType)

  if(index > -1){
    if(passed){
      modelData.actualUpdateData = { ...toRefs(modelData.actualUpdateData[index]), value }
    } else {
      modelData.actualUpdateData.splice(index, 1)
      // 如果在watch的actualUpdateData中关闭定时器，当用户在即将变化的时候操作不满足条件的标题，会有快速闪烁或者标题重叠问题
      if (modelData.actualUpdateData.actualUpdateData.length === 1 && interTimer.value) {
        clearInterval(interTimer.value)
        interTimer.value = null
      }
    }
  } else {
    if(passed){
      modelData.actualUpdateData.push({ titleType, passed, value })
    }
  }

  // 第一次展示的时候顺序是“最快送达时间”、“支付安全”、“组合优惠”
  if(isInit.value){
    modelData.actualUpdateData.sort((i, j) => initOrder.indexOf(i.titleType) - initOrder.indexOf(j.titleType))
    !modelData.temporaryTotalType.includes(titleType) && modelData.temporaryTotalType.push(titleType)

    if (modelData.temporaryTotalType.length === initOrder.length) {
      isInit.value = false
      handleHeadlineReport()
    }
  }
}

/**
 * @description: 整理需要循环播放的数据（新版: 数据完全由中间层提供）
 * @param {Object} options
 * @return {*}
 */
const handleNewAutoCycleData = () => {
  if (!props.isShowTwoRowsTitle) {
    // 单行标题，开始轮播前先展示普通标题
    modelData.titles = [{
      titleType: HeaderTitleType.Normal,
    }, {}]
  }
  modelData.actualUpdateData = getAutoCycleList().map(item => {
    return {
      titleType: item.type,
      passed: true,
    }
  })
}

/**
 * @description: 第一次展示的时候，上报埋点
 * @return {*}
 */
const handleHeadlineReport = () => {
  const modules = Object.values(HeaderTitleType)
  const hasLabel = modelData.actualUpdateData.filter(item => modules.includes(item.titleType) && item.passed)

  exposeScenesAbtAggregator.addEvent({
    id: 'expose_scenesabt:simple',
    data: {
      scenes: 'headline_tips',
      type: hasLabel ? 3 : 1
    }
  })
}

/**
 * @description: 标题切换后触发相关的动画
 * @return {*}
 */
const playTitleAnimation = () => {
  const len = modelData.actualUpdateData.length
  const config = [...toRefs(modelData.titles)]
  actualIndex.value = actualIndex.value >= len ? 0 : actualIndex.value

  const currentChangeTitle = modelData.actualUpdateData[actualIndex.value]
  const { titleType, value } = currentChangeTitle
  const currentTitleType = config[currentIndex.value]?.value?.titleType

  // 如果当前的标题和新的标题是一样的，禁止滚动
  if (currentTitleType === titleType) {
    return
  }

  config[nextIndex.value] = {
    titleType,
    ...(value ? { value: [value] } : {})
  }
  modelData.titles = config
  actualIndex.value++
  AnimationCarouselRef.value?.playAnimation()
  handleScrollTitleReport(titleType, props.isNewAutoTitle)
}

// ---------- 生命周期 ----------
onMounted(() => {
  if (props.isNewAutoTitle) {
    handleNewAutoCycleData()
  } else {
    const initHeaderTitleType = [HeaderTitleType.Shipping, HeaderTitleType.Saved].filter(item => Boolean(getMiddleLayerType(item)))
    initHeaderTitleType.forEach( item => handleAutoCycleData({ titleType: item, passed: true }) )
  }

  handleScrollTitleReport(HeaderTitleType.Normal, props.isNewAutoTitle)
  interTimer.value = setInterval(() => {
    playTitleAnimation()
  }, 2000)
})

onUnmounted(() => {
  clearInterval(interTimer.value)
})

</script>

<style lang="less">
@import '../variables.less';

.@{prefixCls}ht {
  &__auto-animation {

    &_single {
      height: 40/75rem !important;
      .font-dpr(28px);
    }

    .@{prefixCls}ht__aa {
      &_item {
        display: flex;
        justify-items: center;
        align-items: center;
        color: inherit;
      }

      &_text-normal {
        font-weight: 400;
      }
    }

    .@{prefixCls}ht__aa-text {
      display: flex;
      align-items: center;
      text-transform: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      margin-left: 4/75rem;
      line-height: 1.2;
      height: 26/75rem;
      .font-dpr(22px);

      &_vice {
        height: 26/75rem;
        font-size: 11px;
      }

      &_single {
        height: 40/75rem;
        font-size: 14px;
      }
    }

    .@{prefixCls}ht__aa-timer {
      margin-left: 12/75rem;
      align-self: baseline;

      &_small {
        margin-left: 6/75rem;
      }
    }
  }
}
</style>
