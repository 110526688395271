<template>
  <!-- 部分quickShip，不需要qs运费 -->
  <div
    v-if="quickShipFreeShow"
    class="bag-quick-time"
    @click.stop="showQuickPop"
  >
    <div class="qsLabel-container">
      <quickShipLabel
        :show-line="true"
        :styleConfig="{
          'font-size': '12px',
          'font-weight': '590'
        }"
      />
    </div>
    <span 
      :ref="muliLinesRef"
      class="qsLabel-text"
      :style="{lineHeight: `${muliLinesLineHeight}px`, color: '#198055'}"
      v-html="quickShipFreeShow"
    >
    </span>
    <div
      v-if="qsMallList.qs_time_same == 0"
      class="dialog-detail-bag"
      @click.prevent="dialogDetail"
    >
      {{ language.SHEIN_KEY_PWA_34721 }}
      <Icon 
        name="sui_icon_more_right_16px" 
        size="10px" 
      />
    </div>
  </div> 
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import quickShipLabel from 'public/src/pages/checkout/page_tpls/components/atom/quickShipLabel.vue'
import { Icon } from '@shein-aidc/icon-vue2'
// import { useGetGuickShip } from 'public/src/pages/checkout/page_tpls/hook/useQuickShip.js'
import { template } from '@shein/common-function'

export default {
  name: 'QuickShipFreeTips',
  components: {
    quickShipLabel,
    Icon
  },
  data: function () {
    return {
      muliLinesRef: 'qsLabel-text',
      muliLinesLineHeight: 14,
      muliLinesFontSizeSmall: '10px',
    }
  },
  computed: {
    ...mapState('checkout', [
      'language',
      'qsMallList',
      'checkout'
    ]),
    ...mapGetters('checkout', ['iconPrefix', 'quickShipCheckoutStatus']),
    quickShipFreeShow(){
      let { isAllCartsQuickShip, showQsFreight, quickShipStyle, descQuickShipTime } = this.quickShipCheckoutStatus || {}
      const { qs_time_same, last_quickship_tag, combine_transport_time_desc_replace  } = this.qsMallList
      const qsReplenishDesAbt = this.checkout.abtInfo?.TradeTransStoreTest?.param?.TradeTransStoreTestStatus || 0
      descQuickShipTime = descQuickShipTime.replace(/{QSname}/g, `${last_quickship_tag}`)

      // 对部分qs时效提示进行内容组装
      let result = ''
      if(qs_time_same == 0){
        result = qsReplenishDesAbt == 0 ? template(last_quickship_tag, this.language.SHEIN_KEY_PWA_34722)  : descQuickShipTime
      } else {
        result = descQuickShipTime || ''
      }

      // 不受店铺时效是否存在差异的影响，为2直接取字段
      result = qsReplenishDesAbt == 2 ? combine_transport_time_desc_replace || result || '' : result
      
      return (!isAllCartsQuickShip && !showQsFreight && quickShipStyle && last_quickship_tag) && result
    }
  },
  watch: {
    'quickShipCheckoutStatus.descQuickShipTime': {
      handler (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.muliLinesStyle()
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('checkout', ['assignState']),
    showQuickPop() {
      this.assignState({
        isShowQuickPop: true
      })
    },
    dialogDetail(){
      event.stopPropagation()
      this.$emit('openModal')
    },
    muliLinesStyle() {
      const domNode = this.$refs[this.muliLinesRef]
      // v-for中ref获取的是数组
      if (Array.isArray(domNode) && domNode?.length > 0) {
        domNode.forEach((item) => {
          if (item.offsetHeight > this.muliLinesLineHeight) { item.style.fontSize = this.muliLinesFontSizeSmall }
        })
      } else if (domNode) {
        if (domNode.offsetHeight > this.muliLinesLineHeight) { domNode.style.fontSize = this.muliLinesFontSizeSmall }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.bag-quick-time{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8/75rem 10/75rem;
  background: var(---sui_color_safety_bg, #F5FCFB);
  border: 1px solid #BADECD;
  margin: 12/75rem 0; 
  border-radius: 3px;
  // margin-right: .32rem;
  .qsLabel-container{
    display: flex;
    align-items: center;
    max-width: 45%;
    margin-right: 6px;
  }
  .qsLabel-text {
    flex: 1;
    overflow: hidden;  
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .dialog-detail-bag{
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    margin-left: 3/37.5rem;
  }
}
</style>
