/**
 * 并发限制
 * 支持取消任务、修改优先级
 */
export default class TaskQueue {
  constructor(limit) {
    this.limit = limit
    this.queue = []
    this.executing = []
    this.executingUnwarppedTask = []
    this.stoped = false
  }

  addTask({ task, priority = 0 }) {
    const controller = new AbortController()
    const signal = controller.signal

    const wrappedTask = () => {
      return task(signal).then(result => {
        if (!signal.aborted) {
          return result
        }
        throw new Error('Task was aborted')
      })
    }

    const taskPromise = new Promise((resolve, reject) => {
      this.queue.push({ task: wrappedTask, unwarppedTask: task, resolve, reject, priority })
      this.queue.sort((a, b) => b.priority - a.priority) // 根据优先级排序
      this.schedule()
    })

    return taskPromise
  }

  schedule() {
    if (this.executing.length >= this.limit || this.queue.length === 0 || this.stoped) {
      return
    }

    const { task, unwarppedTask, resolve, reject } = this.queue.shift()
    const p = task()

    this.executingUnwarppedTask.push(unwarppedTask)
    this.executing.push(p)

    p.then(resolve)
      .catch(reject)
      .finally(() => {
        this.executing.splice(this.executing.indexOf(p), 1)
        this.executingUnwarppedTask.splice(this.executingUnwarppedTask.indexOf(unwarppedTask), 1)
        // 在循环推入任务时，等待调整优先级
        setTimeout(() => {
          this.schedule()
        })
      })
  }

  adjustPriority(taskPromise, newPriority) {
    const executingUnwarppedTaskIndex = this.executingUnwarppedTask.findIndex(item => item === taskPromise) // 执行中的任务
    const queueTaskIndex = this.queue.findIndex(item => item.unwarppedTask === taskPromise) // 等待中的任务
    if (queueTaskIndex !== -1) {
      this.queue[queueTaskIndex].priority = newPriority
      this.queue.sort((a, b) => b.priority - a.priority) // 根据优先级重新排序
    }
    return queueTaskIndex !== -1 || executingUnwarppedTaskIndex !== -1
  }

  stop() {
    this.stoped = true
  }
  resume() {
    if (this.stoped) {
      this.stoped = false
      this.schedule()
    }
  }
}
