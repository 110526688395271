import { formatTree, filterSingleLink, getDropdownDataList } from 'public/src/pages/components/filter_new/common/format'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { TAG_ITEM_TYPE, FILTER_BAR_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'
import { getSourceInsideFilterOrCloudTagPanel } from './index.js'


function cloneObject (obj = {}) { 
  const newObj = { ...obj }
  obj?.children?.length && (newObj.children = cloneList(obj.children))
  
  return newObj
}

function cloneList (list = []) { 
  return list.map(cate => cloneObject(cate))
}

function getIsNewCategory (catInfo, listAbtResult) {
  return  catInfo?.type === 'search'
    ? listAbtResult?.SearchCategory?.p?.NewSearchCategory === 'B'
    : listAbtResult?.ListCategory?.p?.NewListCategory === 'B'
}

function getFormateCate (cates) { 
  return formatTree({
    cat_name: 'label',
    cat_id: 'value',
    children: 'children'
  }, cates, false)
}

function getDropdownSelectedDataList (curValue, isNewCategory, formatedCate) {
  if (!isNewCategory) return []
  if (!curValue) return formatedCate

  return getDropdownDataList(cloneList(formatedCate), curValue)
}

export function getCateList ({ catInfo, filterBar, listAbtResult, queryParams, language, filterBarType, queryParamsKey = 'child_cat_id', showNum = true }) {
  const curValue = queryParams?.[queryParamsKey] || ''
  const { filterCates = [] } = filterBar?.filterData || {}
  const cates = filterSingleLink(filterCates, curValue, queryParams?.choose_ids)
  if (cates.length === 0) return []

  const isNewCategory = getIsNewCategory(catInfo, listAbtResult)
  const formatedCate = getFormateCate(cates)
  let label = language.SHEIN_KEY_PWA_14980 + ((curValue && showNum) ? '(1)' : '')

  if (filterBarType == FILTER_BAR_MAP.CLOUD_TAGS) { 
    const config = (filterBar?.filterConfig?.tagCloudSlotConfig || []).find(item => item.tagId == 14002)
    const labelConfig = config?.displayContent?.labelLang || config?.tagName  || ''
    labelConfig && (label = (curValue && showNum) ? `${labelConfig}(1)` : labelConfig)
  }

  return [{
    curValue,
    data: {
      curValue,
      data: formatedCate,
      dataType: 'cate',
      step: catInfo?.pageName === 'page_search' ? 20 : 0, // 搜索页逐步展示
      dropdownSelectedDataList: getDropdownSelectedDataList(curValue, isNewCategory, formatedCate)
    },
    isNewCategory,
    value: 'cate',
    label,
    selectedId: curValue,
    selectedNum: curValue ? 1 : 0,
    tagItemType: TAG_ITEM_TYPE.CATEGORY,
  }]
}

export function handleCategoryParams ({ data, paths = [], index, doAnalysis, sourceFrom, isCancel, clickType, fhContext, googleContext, filterCates = [] }) {
  const { value, parent_id, originalValue } = data
  const isSideFilter = sourceFrom === 'sideFilter'
  // 埋点
  doAnalysis && analysis.clickCate({
    index,
    clickType,
    catId: value || originalValue,
    parentId: parent_id,
    isSide: isSideFilter,
    facet: fhContext?.categories,
    gfilter: googleContext?.gfilter,
    isCancel: isCancel ? 0 : 1,
  })
  // const chooseList = paths.slice(0, paths.length - 1)
  // const choose_ids = paths.map(item => item.value).join(',')

  return { 
    choose_ids: paths.map(item => item.value).join(','),
    child_cat_id: value, 
    parent_cat_id: parent_id,
    pic_cate_id: '', 
    showAllCategory: paths.length === 1 && !data.children?.length ? '1' : '',
    last_parent_cat_id: data.children?.length === 0 && data.parent_id || '',
    ...getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active: !isCancel })
  }
}


