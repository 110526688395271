<template>
  <BsCategory 
    ref="bsCatRef"
    :layout-top="layoutTop"
  />
</template>

<script>
/* globals */
import { nextTick } from 'vue'
import { mapState, mapMutations } from 'vuex'
import { isSupportPassive, getQueryString } from '@shein/common-function'
import { branchCookie } from 'public/src/pages/common/banner/branchCookie.js'
import BsCategory from './BsCategory.vue'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'

const isBrowser = typeof window !== 'undefined'
const UA = isBrowser ? window.navigator.userAgent.toLowerCase() : ''
const isIOS =
  !!(UA && /iphone|ipad|ipod|ios/.test(UA)) ||
  (isBrowser && window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)
let lastHeight = isBrowser ? window.innerHeight : 675
export default {
  name: 'CategoryContainer',
  components: {
    BsCategory
  },
  asyncData ({ store, context }) {
    if (context && context.ssrPageType === 'category') {
      // ssr 客户端和服务端都会执行
      const data = JSON.parse(JSON.stringify(context))
      // console.log('fsData:',data )
      store.state['category'] = Object.assign(store.state['category'], data)
    }
  },

  data () {
    return {
      layoutTop: 0,
      heightTypes: [],
    }
  },
  computed: {
    ...mapState(['hasNewTopBanner']),
    ...mapState('category', [ 'locals', 'ssrPageType'])
  },
  watch: {
    hasNewTopBanner(val){
      this.initCategoryTop()
      this.$refs.bsCatRef?.refreshLayout()
    }
  },
  beforeRouteEnter (to, from, next) {
    if (typeof window !== 'undefined') {
      // 修复部分机型safari浏览器在切换页面时（其他页面滑动距离后）,category页面底部tab没出现在视图
      window?.resourceSdkCase?.updateCutScene?.('category')
    }
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (typeof window !== 'undefined') {
      window?.resourceSdkCase?.updateCutScene?.('')
    }
    next()
  },

  created () {
    this.resetLocals()
    this.initCategoryTop() // 初始化分类顶部距离
  },

  mounted () {
    this.handlePageWindowLayout()
    requestIdleCallback(() => {
      // 在首页预请求用户即将访问的其他页面的 js 资源
      this.prefetchResources()
    })
    setTimeout(() => {
      this.handlePagePv()
    }, 500)
  },

  async activated () {
    const diff = Math.abs(lastHeight - window.innerHeight)
    console.log('浏览器高度变化:',diff);
    if(diff > 10){
      // 浏览器高度有变动，需要刷新滚动区域
      this.$refs.bsCatRef?.refreshLayout()
    }
    lastHeight = window.innerHeight
    
    if(isIOS){
      nextTick(() => {
        document.body.style.height = '100.1vh'
        window?.scrollTo(0, 1)
      })
    }
    this.activeTimer = setTimeout(() => {
      this.initCategoryTop() 
    }, 500)
    // 禁止滚动条滚动
    document.addEventListener('touchmove', this.scrollStop, isSupportPassive() ? {
      capture: false,
      passive: false
    } : false)

    requestIdleCallback(() => {
      const curPath = this.$route.path
      // console.log('curPath',curPath, window.scrollY);
      if(curPath.indexOf('/category') === -1){
        // 不在导航页面的时候不做复位操作
        document.removeEventListener('touchmove', this.scrollStop)
        return
      } 
      this.checkBottomBar()

      if (!this.notFristPage) {
        this.notFristPage = true
      } else {
        // 二次进入页面才发送 pv
        this.handlePagePv()
      }
    })
  },
  deactivated () {
    // 销毁滚动条滚动
    if(isIOS){
      document.body.style.height = 'unset'
    }
    document.removeEventListener('touchmove', this.scrollStop)
    appEventCenter.$emit('pageUnload')
  },
  destroyed () {
    this.$store.unregisterModule('category')
    this.activeTimer && clearTimeout(this.activeTimer)
  },
  methods: {
    ...mapMutations('category', [
      'resetLocals',
    ]),
    scrollStop (e) {
      if (!e) return
      e.preventDefault()
    },
    // 检查一下底部tab的状态是否触发现在在当前页面上，兼容全面屏返回时底部tab未更新的问题
    checkBottomBar () {
      const tabEl = document.querySelector('.j-index-tab-list-category')
      const activeClassName = 'title-active'
      if (tabEl && !tabEl.classList.contains(activeClassName)) { // 删除其他tab的选中状态，添加当前tab的选中状态
        [...tabEl.parentNode.children].forEach(item => {
          if (item !== tabEl) {
            item.classList.remove(activeClassName)
          } else {
            item.classList.add(activeClassName)
          }
        })
      }
    },
    prefetchResources () {
      // el 必须是渲染完的
      prefetchResource.listen({
        el: this.$el,
        delay: 2000, // 默认两秒
        prefetchList: [
          {
            chunkName: 'plv2_container',
            relType: 'prefetch'
          },
          // 个人中心子路由容器
          {
            chunkName: 'user',
            relType: 'prefetch'
          },
          {
            chunkName: 'user-index',
            relType: 'prefetch'
          },
        ],
      })

      setTimeout(() => {
        const loadComponent = prefetchResource.importAsyncComponent({
          chunkName: 'plv2_container', // 跟webpackChunkName保持一致
          componentFactory: () => import( /* webpackChunkName: 'plv2_container' */ 'public/src/pages/product_list_v2/container.vue'),
        })
        loadComponent?.()
      }, 2000)
    },

    initCategoryTop () {
      const isSsrPage = this.ssrPageType === 'category'
      this.heightTypes = ['header']
      const isServer = typeof window === 'undefined'

      if (isSsrPage) {
        if (this.locals.showBranch) {
          this.heightTypes.push('branch')
        }
      } else if (!isServer) { // 切页面进行判断branch是否显示
        const { cookieValue = '' } = branchCookie() || {}
        if (cookieValue && cookieValue.includes('/category')) {
          this.heightTypes.push('branch')
        }
      }

      if (isServer && this.locals?.hasTopBanner) {
        this.heightTypes.push('topBanner')
      } else if (!isServer && this.hasNewTopBanner){
        this.heightTypes.push('topBanner')
      }
      this.handleCalcTopHeight()

    },

    handleCalcTopHeight () {
      const heightMap = {
        branch: 2.0267,    // branch component
        header: 1.17,      // header component
        topBanner: 1.04,   //topBanner component
        policyBanner: 1.31368  // policyBanner component
      }
      this.layoutTop = this.heightTypes.reduce((total, type) => {
        total += heightMap[type] || 0
        return total
      }, 0)
    },

    handlePageWindowLayout () {
      const changeLayout = ({ type, value }) => {
        if (this.$route.name !== 'page_category') return

        if (value && !this.heightTypes.includes(type)) {
          this.heightTypes.push(type)
        }
        if (!value) {
          this.heightTypes = this.heightTypes.filter(item => item !== type)
        }
        this.handleCalcTopHeight()
      }

      const handleBranchShow = () => {
        changeLayout({ type: 'branch', value: true })
      }
      const handleBranchHide = () => {
        changeLayout({ type: 'branch', value: false })
      }
      // const handleCccBannerShow = ({ type = 'topBanner' } = {}) => {
      //   changeLayout({ type, value: true })
      // }
      // const handleCccBannerHide = ({ type } = {}) => {
      //   changeLayout({ type, value: false })
      // }

      const handleWindowResize = () => {
        // 新人注册弹窗进入输入状态时会滑动到底部，需要重置滚动条
        if (this.$route.name !== 'page_category') return
        document.scrollingElement.scrollTop = 0
      }

      window.vBus?.$on('onBranchHide', handleBranchHide)
      window.vBus?.$on('onBranchShow', handleBranchShow)
      // appEventCenter.$on('cccBannerShow', handleCccBannerShow)
      // appEventCenter.$on('cccBannerHide', handleCccBannerHide)
      appEventCenter.$on('quickRegisterDialogEvent', handleWindowResize)
      this.$once('hook:beforeDestroy', () => {
        window.vBus?.$off('onBranchHide', handleBranchHide)
        window.vBus?.$off('onBranchShow', handleBranchShow)
        // appEventCenter.$off('cccBannerShow', handleCccBannerShow)
        // appEventCenter.$off('cccBannerHide', handleCccBannerHide)
        appEventCenter.$off('quickRegisterDialogEvent', handleWindowResize)
      })
    },
    handlePagePv () {
      const pagefrom = getQueryString({ key: 'pf' })
      const src_module = getQueryString({ key: 'src_module' }) || '-'
      const src_identifier = getQueryString({ key: 'src_identifier' }) || '-'
      const src_tab_page_id = getQueryString({ key: 'src_tab_page_id' }) || '-'
      let page_param = {
        src_module,
        src_identifier,
        src_tab_page_id
      }

      // FR-7889 补充统计参数 pagefrom
      if (pagefrom) {
        page_param.pagefrom = pagefrom
      }

      window.SaPageInfo = {
        page_id: 9,
        page_name: 'page_category',
        page_param,
        start_time: Date.now()
      }
      window.PageGroupOverview = '首页'
      appEventCenter.$emit('pageOnload')
    }
  }
}
</script>
