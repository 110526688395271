import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { debuggerLog } from '../../utils'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { Trade_PayLibs } from '@shein-aidc/types-trade'
import { mergeQueryString } from '@shein/common-function'
import { CreateNewWindow } from '../helpers/CreateNewWindow'
import { MonitorReportAbnormal } from '../helpers/MonitorReport'
import { PAYMENT_ACTION_TYPE } from '../../../types'
// import { CHECKOUT_TYPE, PRODUCT_TYPE } from '../../../types'

export interface LtspcPayManagerConstructor extends BasicPayManagerConstructor { }

export class LtspcPayManager extends BasicPayManager implements AbstractMethods {

  public static PAYMENT_CODE = ''

  public static ltspcUrl: string = ''

  constructor(params: LtspcPayManagerConstructor) {
    super(params)
  }

  public static async preInit ({
    bsUtils,
    scene,
    useInline,
    inlineUrl,
    isPrePay,
    paymentCode,
    currencyCode,
  }: {
    bsUtils: Trade_PayLibs.OutsideBsUtils;
    scene: string;
    useInline: Trade_PayLibs.BsPayConfig['useInline'];
    inlineUrl?: string;
    isPrePay?: boolean;
    paymentCode: string;
    currencyCode?: string;
  }) {

    this.ltspcUrl = ''
    inlineUrl = inlineUrl || location.href
    const appConfigs = useAppConfigs()
    const { lang, host, langPath, currency, siteUID } = appConfigs.$envs
    debuggerLog('LtspcPayManager preInit', isPrePay, { payCode: paymentCode, lang, host, langPath, currency: currencyCode || currency || '', siteUID, checkoutScene: scene, useInline: useInline ? 1 : 0, inlineUrl })

    if (isPrePay) {
      await bsUtils?.updateLtspcPciEnv?.({ currency: currencyCode || currency || '' })
    }

    if (!isPrePay && bsUtils?.checkLtspcPaymentMethod?.(paymentCode)) {
      await bsUtils?.bsLtspcInit?.({ payCode: paymentCode, lang, host, langPath, currency: currencyCode || currency || '', siteUID, checkoutScene: scene, useInline: useInline ? 1 : 0, inlineUrl }).then((ltspcRes) => {
        if (ltspcRes?.url) {
          this.ltspcUrl = ltspcRes.url
        } else {
          MonitorReportAbnormal.metric({
            scene: 'ltspc_init_url_error',
            extraTags: {
              payment_code: paymentCode,
            },
            extraParams: {
              payment_action_type: PAYMENT_ACTION_TYPE.CARD,
              req_info: JSON.stringify({ payCode: paymentCode, lang, host, langPath, currency: currencyCode || currency || '', siteUID, checkoutScene: scene, useInline: useInline ? 1 : 0, inlineUrl }),
            },
          })
        }
      })
    }
  }

  private async handleJumpPciPay () {
    if (!LtspcPayManager.ltspcUrl) {
      await LtspcPayManager.preInit({
        scene: this.payCheckoutType,
        useInline: this.bsPayConfig.useInline,
        inlineUrl: location.href,
        currencyCode: this.payData.channelExtraInfo.currencyCode,
        paymentCode: this.paymentCode,
        bsUtils: this.bsUtils,
        isPrePay: false,
      })
    }
    const ltspcUrl = LtspcPayManager.ltspcUrl
    this.paymentLoadingAction?.({ show: false })
    if (ltspcUrl) {
      LtspcPayManager.ltspcUrl = ''
      const { relation_billno, billno } = this.unifiedPayParams
      let url = `${ltspcUrl}${relation_billno || billno}`
      const checkout_from = this.appConfigs.$getters.SaPageInfo?.page_param?.checkout_from || ''
      if (checkout_from) {
        url = mergeQueryString({
          url,
          mergeObj: {
            checkout_from: checkout_from,
          },
        })
      }
      if (this.bsPayConfig?.useInline) {
        CreateNewWindow.continueRedirect({ actionUrl: url })
      } else {
        window.location.href = url
      }
    } else {
      MonitorReportAbnormal.metric({
        scene: 'ltspc_jump_get_url_error',
        extraTags: {
          payment_code: this.paymentCode,
        },
        extraParams: {
          billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
          payment_action_type: PAYMENT_ACTION_TYPE.CARD,
        },
      })
      if (this.unifiedPayParams.cancelUrl) {
        window.location.href = this.unifiedPayParams.cancelUrl
      } else {
        location.reload()
      }
    }
  }


  public async createPayment (): Promise<any> {
    this.paymentLoadingAction?.({ show: true })
    return this.handleJumpPciPay()
  }
}
