import SourceBufferControl from './source-buffer-control'
import TaskQueue from './task-queue'

// 控制视频分片播放策略
export class AutoPlayVideoControl {
  constructor({ limit = 2 } = {}) {
    // 并发任务限制
    this.takQueue = new TaskQueue(limit)
  }
  /**
   * @params TaskQueue
   */
  takQueue = null
  // 视频任务映射
  VideoTaskMap = new Map()
  currentGoodsId = null

  // 添加视频任务
  handleAddVideoTask({ sourceBufferControl, priority, goodsId }) {
    const task = sourceBufferControl.downloader?.awaitFetch
    if (!task) {
      return
    }
    this.takQueue.addTask({ task, priority }).then(() => {
      if (goodsId === this.currentGoodsId) {
        if (!navigator?.onLine) {
          setTimeout(() => {
            this.handleAddVideoTask({ sourceBufferControl, priority, goodsId })
          }, 3000)
        } else {
          this.handleAddVideoTask({ sourceBufferControl, priority, goodsId })
        }
      }
    })
  }
  // 添加视频
  addVideo({ goodsId, videoUrl, videoPlayer, onLoadData, onChunkLoadCallback }) {
    const sourceBufferControl = new SourceBufferControl({
      videoUrl,
      videoPlayer,
      onLoadMeta: () => {
        // 如果视频为moov后置，加载Metadata后需要重置回到第二分片进行加载
        // 在加载元数据后继续请求
        this.handleAddVideoTask({
          sourceBufferControl,
          priority: 1,
          goodsId,
        })
      },
      onLoadData, // 加载完视频资源后回调
      onChunkLoadCallback,
    })
    sourceBufferControl.initPromise.then(sourceBufferControl =>
      this.handleAddVideoTask({
        sourceBufferControl,
        goodsId,
      }),
    )
    this.VideoTaskMap.set(goodsId, sourceBufferControl)
  }
  // 调整优先级
  adjustPriority(goodsId, newPriority) {
    const sourceBufferControl = this.VideoTaskMap.get(goodsId)
    const awaitFetch = sourceBufferControl?.downloader?.awaitFetch
    if (!awaitFetch) {
      return {
        awaitFetch: null,
        sourceBufferControl: null,
        adjustSuccess: false,
      }
    }
    const adjustSuccess = this.takQueue.adjustPriority(awaitFetch, newPriority)
    return { awaitFetch, sourceBufferControl, adjustSuccess }
  }
  // 增加优先级（播放）
  increasePriority(goodsId) {
    this.currentGoodsId = goodsId
    const { sourceBufferControl, awaitFetch, adjustSuccess } =
      this.adjustPriority(goodsId, 1)
    // 存在后续任务且未在队列中
    if (!adjustSuccess && awaitFetch) {
      this.handleAddVideoTask({ sourceBufferControl, priority: 1, goodsId })
    }
  }
  // 减少优先级（暂停）
  decreasePriority(goodsId) {
    this.currentGoodsId = null
    this.adjustPriority(goodsId, -1)
  }
  // 暂停后续请求
  stopRequest() {
    this.takQueue.stop()
  }
  // 恢复请求
  resumeRequest() {
    this.takQueue.resume()
  }
}
