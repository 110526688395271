import Vue from 'vue'
import * as monitor from '@shein-aidc/client-monitor-union-log/async-exports'
import * as originMonitor from './origin-index'

// Vue 错误处理
monitor.useVueErrorHandler(Vue)

const ENABLE_MONITOR_UNION = gbCommonInfo && gbCommonInfo.ENABLE_MONITOR_UNION

export const SILogger = ENABLE_MONITOR_UNION ? monitor.SILogger : originMonitor.SILogger
export const SIMetric = ENABLE_MONITOR_UNION ? monitor.SIMetric : originMonitor.SIMetric

export default ENABLE_MONITOR_UNION ? monitor.SILog : originMonitor.default
