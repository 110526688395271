<script lang="jsx">
// utils
import { columnToWidth, views } from './js/constant'

// mixins
import productCardMixin from './mixins/productCardMixin'

import ProductCardOneClickAddBagBtn from './components/ProductCardAddBagBtn/ProductCardOneClickAddBagBtn.vue'

import SuggestedSalePriceContainer from './components/ProductCardPrice/SuggestedSalePriceContainer.vue'

export default {
  name: 'MultipleImageRowCard',
  components: {
    ProductCardOneClickAddBagBtn,
    SuggestedSalePriceContainer
  },
  mixins: [productCardMixin],
  props: {
    /**
     * @param {import('../types').ProductCardConfig} config
    */
    config: {
      type: Object,
      default: () => ({})
    },
    // 一行几图
    column: {
      type: [Number, String],
      default: 2
    },
    // 来源组件
    compSrc: {
      type: String,
      default: ''
    },
    compImgDesignWidth: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      labelsFromKey: views.LIST_MOBILE_VERTICAL_VIEW
    }
  },
  render(h) {
    // config
    const { showCardLabelsContainer = true, hideSoldOut, autoCropImage, showAddBagBtnBottomRight, showHorseRaceLabel, showLabelCarousel = false, showCategoryViewStyle = false } = this.config || {}

    const showAddBagBtn = this.config.showAddBagBtn

    // 列数的配置
    const multiColumn = this.multiColumn

    const {
      itemAdaOptions,
      mainImg,
      showOperatinPanel,
      isSoldOut,
      showMoreBtn,
      hasFeedback,
      cccFeedback,
      comingSoon,
      showBestDeal,
      followLabelVisible,
      beltLabel,
      showStoreBar,
      exclusivePromotionPrice,
      storeInfo,
      showDeliveryWords,
      showCamelPrice,

      cornerPropertyUpsell,
      ItemLocateLabels,
      ProductCardImgLeftTop,
      ProductCardImgRightTop,
      ProductCardImgLeftBottom,
    } = this.handleRender(h)

    // 是否展示一键购加车按钮样式 start
    // 不命中 倒计时过期 倒计时未过期 三种样式
    const showOneClickAddBagBtn = !!this.item?.oneClickPay?.oneClick || this.item?.showAddButtonLabelStyle === 'oneClick'  || false
    // 是否展示一键购加车按钮样式 end
    const buyBoxCount = Number(this.item?.buyBoxCount?.count)
    const buyBoxPrice = this.item?.buyBoxPrice?.price
    // 购物车加车数量
    const checkoutNum = this.item?.checkoutNum
    // 是否展示 checkout
    const showCheckout = this.item?.showCheckout

    // 展示checkout时需要计算价格宽度，决定checkout按钮的最大宽度
    if (showCheckout) {
      this.checkoutEllipsis()
    }

    const renderCart = () => {
      if(this.$slots.addCartBtn){
        return this.$slots.addCartBtn
      }
      if(showAddBagBtn && !showAddBagBtnBottomRight && !showOneClickAddBagBtn){
        return <ProductCardAddBagBtn
          class={['price-wrapper__addbag-btn', showCheckout ? 'price-wrapper__addbag-checkout' : '']}
          loading={this.loading}
          checkoutNum={checkoutNum}
          showCheckout={showCheckout}
          vOn:openQuickAdd={this.handleOpenQuickAdd}
          vOn:clickCheckout={this.handleClickCheckout}
        />
      }
      return null
    }
    // @tips pages/user_wishlist/utils/scrollProductsNum.js使用了product-card这个css类

    // 合规价三列以上商卡不显示服务标签
    let showCardLabelsContainerValue = !(this.finalPriceAndDiscountInfo.suggestedSalePriceData?.type && multiColumn) && showCardLabelsContainer

    // 建议零售价 RRP价
    // 一行二图使用
    const priceBottomRrpEl = (() => {
      const { priceBottomRrp, priceBottom } = this.finalPriceAndDiscountInfo || {}

      if (priceBottomRrp?.show && priceBottom?.show) {
        return (
          <SuggestedSalePriceContainer
            class="product-card__price-rrp"
            goodsInfo={this.item}
            priceData={priceBottomRrp}
          ></SuggestedSalePriceContainer>
        )
      }
    })()

    const renderPoint = (color = '') => {
      // cookie 设置 refreshListDebug 才可以开启
      if (!this.refreshListDebug) return null

      return (
        <span class="point-wrapper" style={{ '--point-color': `var(--${color})` }}>
          <span class="point"></span>
        </span>
      )
    }

    return (
      <div
        v-show={this.status.showSelf}
        ref="productCardRef"
        class="product-card multiple-row-item"
        style={{
          '--column-num': columnToWidth[this.column] ? `${columnToWidth[this.column]}rem` : 'auto',
          '--product-card-radius': multiColumn ? '0' : '4px'
        }}
      >
        {/* 为区分是否为点刷商品的调试代码 红点为 request_early 绿点为 request_early_back */}
        {/* 点推商品为蓝色 */}

        {
          this.curData.divideTime === 'recommend' ? renderPoint('blue') : null 
        }
        {
          this.curData.divideTime === 'request_early' ? renderPoint('red') : null 
        }
        {
          this.curData.divideTime === 'request_early_back' ? renderPoint('green') : null 
        }
        
        {/* 主图顶部的插槽 */}
        {this.$slots.imgTop}
        {/* 主图区域 */}
        <div
          class="product-card__top-wrapper"
          {...itemAdaOptions}
          vOn:touchstart={this.touchstart}
          vOn:touchmove={this.touchmove}
          vOn:touchend={this.touchend}
          vOn:contextmenu_prevent={() => {}}
          vOn:click_prevent={(e) => this.clickItem(e, { from: 'mainImg' } )}
          vOn:pointerover_prevent={this.handlePointerover}
        >
          {/* 主图区域 */}
          <div class="top-wrapper__main-img">
            {/* 主图区域 */}
            <ProductCardImg
              mainImg={mainImg}
              column={this.column}
              index={this.index}
              autoCropImage={autoCropImage}
              cropRate={this.cropRate}
              lcp={this.lcp}
              vOn:imgLoadFinish={this.onImgLoadFinish}
              reportMetrics={this.reportMetrics}
              compSrc={this.compSrc}
              compImgDesignWidth={this.compImgDesignWidth}
            />
            {/* 主图蒙层 */}
            <ProductCardMaskLayer
              mainImg={mainImg}
              column={this.column}
              cropRate={this.cropRate}
              autoCropImage={autoCropImage}
            />
            
            {/* 蒙层操作面板 */}
            {showOperatinPanel ? (
              <ProductItemOperationPanel
                v-model={this.status.showOperationPanel}
                language={this.language}
                config={this.config}
                index={this.index}
                cur-operate-index={this.curOperateIndex}
                cur-data={this.curData}
                is-sold-out={isSoldOut}
                vOn:clickAddToBoard={(payload) => this.$emit('clickAddToBoard', payload)}
                vOn:clickDelete={(payload) => this.$emit('clickDelete', payload)}
              />
            ) : null}
            <ClientOnly>
              {/* 定位角标 */}
              { ItemLocateLabels() }

              {/* 负反馈弹窗: 搜索场景长按商卡项出来，收藏场景无此场景 */}
              {
                hasFeedback && !this.config.showMainImgCarousel ? (
                  <ProdcutItemFeedback
                    v-model={this.status.showFeedback}
                    language={this.language}
                    cur-data={this.curData}
                    feedback={cccFeedback}
                    cur-operate-index={this.curOperateIndex}
                    index={this.index}
                    config={this.config}
                    is-sold-out={isSoldOut}
                    scroll-timers={this.scrollTimers}
                    img-ratio={this.imgRatio}
                    vOn:hideSelf={this.hideSelf}
                  />
                ) : null
              }
            </ClientOnly>
          </div>

          {/* 主图下方区域 */}
          <div class="top-wrapper__bottom">
            <div class="top-wrapper__bottom-middletop">
              {/* 主图左下区域 */}
              <div class="bottom-middletop__top-left">
                <ClientOnly>
                  {ProductCardImgLeftBottom()}
                </ClientOnly>
              </div>

              {/* 撑开左右两个节点 */}
              {
                <div class="bottom-middletop__top-middle"></div>
              }

              {/* 主图右下区域 */}
              <div class="bottom-middletop__top-right">
                {
                  showAddBagBtnBottomRight && !showOneClickAddBagBtn ?
                    (
                      <ProductItemAddToBag
                        box-shadow={false}
                        size="small"
                        custom-style={this.addToBagStyle}
                        is-rw={this.IS_RW}
                        vOn:openQuickAdd={this.handleOpenQuickAdd}
                      />
                    )
                    : (
                      <ProductCardImgRightBottom
                        beltLabel={beltLabel}
                        getCornerPropertyUpsell={cornerPropertyUpsell}
                        relatedColor={this.relatedColor}
                      />
                    )
                }
              </div>
            </div>

            {/* 主图下方中位区域 */}
            <div class="top-wrapper__bottom-middle">
              <ClientOnly>
                <ProductCardImgBottom
                  coming-soon={comingSoon}
                  is-sold-out={isSoldOut}
                  show-best-deal={showBestDeal}
                  follow-label-visible={followLabelVisible}
                  belt-label={beltLabel}
                  save-amount={this.saveAmount}
                  ref="ProductCardImgBottomRef"
                >
                  {/* 图片底部slot(嵌套) */}
                  <template slot="imgBottom">{this.$slots.imgBottom}</template>
                  {/* 图片底部slot(盖在腰带之上) */}
                  <template slot="imgBottomInTop">{this.$slots.imgBottomInTop}</template>
                </ProductCardImgBottom>
              </ClientOnly>
            </div>
          </div>

          {/* 主图左上区域 */}
          <div class="top-wrapper__top-left">
            <ClientOnly>
              {ProductCardImgLeftTop()}
              { showLabelCarousel ? null : this.$slots.imgTopLeft }
            </ClientOnly>
          </div>

          {/* 主图右上区域 */}
          <div class="top-wrapper__top-right">
            <ClientOnly>
              {ProductCardImgRightTop()}
            </ClientOnly>
          </div>

          {/** 自动播放视频 */}
          {this.config.showAutoplayVideo && (
            <ProductCardAutoplayVideo
              ref='ProductCardAutoplayVideoRef'
              index={this.index}
              mainImg={mainImg}
              column={this.column}
              cropRate={this.cropRate}
              autoCropImage={autoCropImage}
              vOn:videoMounted={payload => this.$emit('videoMounted', payload)}
            />
          )}
          {/** 视频图标 */}
          {this.config.showAutoplayVideoIcon && this.item?.video_infos?.[0]?.url && (
            <PlayVideoIcon />
          )}
          
          {/* 售罄背景slot，默认展示Sold Out */}
          {
            this.$slots.soldout  || (
              !hideSoldOut && isSoldOut && !comingSoon && !this.soldoutOperationPanelShow
                ? (
                  <ProductItemSoldout
                    cur-data={this.curData}
                    language={this.language}
                    constant-data={this.constantData}
                    config={this.config}
                    is-sold-out={isSoldOut}
                    showSimilarPopup={this.showSimilarPopup}
                    index={this.index}
                    show-select={this.showSelect}
                    vOn:clickAddToBoard={(payload) => this.$emit('clickAddToBoard', payload)}
                    vOn:clickDelete={(payload) => this.$emit('clickDelete', payload)}
                    vOn:select={this.handleSelect}
                    vOn:clickItem={() => this.clickItem(null, { from: 'soldOut' } )}
                    vOn:pointerover_prevent={this.handlePointerover}
                  />
                )
                : null
            )
          }
        </div>

        {/* 主要信息区域（价格，标题，标签等）*/}
        { <div
          class={[
            'product-card__bottom-wrapper',
            {
              'product-card__bottom-wrapper_soldout': isSoldOut && !showCategoryViewStyle,
              'product-card__bottom-wrapper_show-store': showStoreBar,
              'product-card__bottom-wrapper_categoryviewsoldout': isSoldOut && showCategoryViewStyle,
              'product-card__bottom-wrapper_categoryview': showCategoryViewStyle,
            }
          ]}
          vOn:click_prevent={(e) => this.clickItem(e, { from: 'cardInfo' } )}
          vOn:pointerover_prevent={this.handlePointerover}
        >

          {/* 趋势词: 标题上 */}
          {showDeliveryWords && <DeliveryWords

            goodsId={this.curData?.goods_id}
            catId={this.curData?.cat_id}
            extendToChannel={this.extendToChannel}
            language={this.language}
            deliveryInfo={this.curData?.deliveryInfo ?? {}}
            storeInfo={this.curData?.storeInfo ?? {}}
            index={this.index}
            customCateIds={this.curData?.customCateIds ?? ''} // 这个字段是趋势频道混入进来的
            isModStoreTag={this.curData?.storeInfo?.isModStore}
            isChoiceStoreTag={this.curData?.storeInfo?.isChoiceStore}
            config={this.config}
          ></DeliveryWords>}

          {/* 标题上方插槽 */}
          { this.$slots.goodsTitleTop }
          { this.$slots.cardTitleTop }
          {/* 标题区域 */}
          <ProductItemTitleContainer>
            <template slot="beforeTitle">{this.$slots.beforeTitle}</template>
          </ProductItemTitleContainer>

          {/* 榜单卖点信息区域 或 赛马标签信息区域 */}
          {
            this.showSelling && showHorseRaceLabel ? (
              <div class="bottom-wrapper__selling-info">
                <ProductCardHorseRaceLabelContainer />
              </div>
            ) : null
          }

          {
            this.showSelling && !showHorseRaceLabel ? (
              <div class="bottom-wrapper__selling-info">
                <ProductCardSellingPropositionContainer />
              </div>
            ) : null
          }

          { this.$slots.cardPriceTop }

          {this.$slots.price ||
            <div class="bottom-wrapper__price-wrapper">
              {/* 价格 */}
              <ProductCardPrices
                class="price-wrapper__price"
                show-camel-price={showCamelPrice}
                exclusive-promotion-price={exclusivePromotionPrice}
                has-feedback={hasFeedback}
                show-best-deal={showBestDeal}
                belt-label={beltLabel}
                support-vue-debug={this.supportVueDebug}
              />

              {/* 加车 */}
              {renderCart()}

              {
                showCategoryViewStyle ?
                  showMoreBtn && this.$slots.moreInfoBtn ? (
                    <div
                      class="more-btn-wrapper"
                      vOn:click_stop={this.handleClickMoreBtn}
                    >
                      {this.$slots.moreInfoBtn}
                    </div>
                  ) : null : null
              }
            </div>
          }

          {/* 为保持加车按钮居中，合规价显示时此处显示 */}
          {/* 建议零售价 */}
          {
            priceBottomRrpEl
          }

          <div class="bottom-wrapper__labels">
            {this.$slots.reducePrice || null}
            {
              showCategoryViewStyle ? null :
                (
                  <div class="labels-row-container">
                    {
                      showLabelCarousel ?
                        <div class="left-block-item">{this.$slots.labelCarousel}</div> :
                        [this.$slots.wishNum, this.$slots.stockLeft]
                          .filter(_ => _)
                          .map(slotItem => <div class="left-block-item">{slotItem}</div>)
                    }
                    {
                      showMoreBtn && this.$slots.moreInfoBtn ? (
                        <div
                          class="more-btn-wrapper"
                          vOn:click_stop={this.handleClickMoreBtn}
                        >
                          {this.$slots.moreInfoBtn}
                        </div>
                      ) : null
                    }
                  </div>
                )
            }
            {/* 服务类标签 和 分布式筛选标签 */}
            {showCardLabelsContainerValue && !showOneClickAddBagBtn ? <ProductCardLabelsContainer
              vOn:clickSearchFilter={ (e) => { this.$emit('clickSearchFilter', e) } }
            >
              <template slot="goodsServiceTag">{this.$slots.goodsServiceTag}</template>
            </ProductCardLabelsContainer> : null}
          </div>
          {/* 商铺跳转链接 */}
          {
            showStoreBar && !showOneClickAddBagBtn && <ProductCardStoreLink catId={this.curData?.cat_id} goodsId={this.curData?.goods_id} storeInfo={storeInfo} />
          }
          {/* 商品底部插槽 */}
          { this.$slots.goodsBottom }
          {/* 一键购加车按钮 */}

          {
            showOneClickAddBagBtn ?
              (
                <ProductCardOneClickAddBagBtn
                  vOn:openQuickAdd={this.handleOpenQuickAdd}
                />
              )
              : null
          }

          {/* buy box */}
          {
            buyBoxCount > 0
              ? (
                <ProductCardBuyBoxEntrance
                  count={buyBoxCount}
                  price={buyBoxPrice}
                  language={this.language}
                  constant-data={this.constantData}
                  goods-id={this.curData?.goods_id}
                  vOn:openBuyBoxDrawer={this.openBuyBoxDrawer} />
              )
              : null
          }
          {/* 主信息最下方插槽 */}
          {
            this.$slots.cardBottom || null
          }
        </div>
        }

        <div class="all-wrapper__bottom-top">
          <ClientOnly>
            {this.$slots.cardBottomTop}
          </ClientOnly>
        </div>

        {/* 商品主体slot */}
        { this.$slots.goodsMain || null }
      </div>
    )
  },
}
</script>

<style lang="less">
@import url(./style/checkBox.less);
@import url('./style/icon.less');

.multiple-row-item {
  width: var(--column-num);
  background: var(--product-card-bg, #fff);  // 实时反馈用
  background-image: var(--product-card-bg-image, none);
  padding-bottom: var(--product-card-padding-bottom, 0); // 点后推搜索词需求添加
  max-height: var(--product-card-max-height, 100%); // 点后推搜索词需求添加
  // 多个border-radius为实现允许部分元素超出显示不被overflow: hidden裁剪
  border-radius: var(--product-card-img-radius, 4px);

  z-index: var(--product-card-z-index, auto);
  overflow: hidden;
  position: relative;

  // 主图区域
  .product-card__top-wrapper {
    position: relative;
    overflow: hidden;

    border-radius: var(--product-card-img-radius, 0);
    border-top-left-radius: var(--product-card-img-radius, var(--product-card-radius, 4px));
    border-top-right-radius: var(--product-card-img-radius, var(--product-card-radius, 4px));

    .top-wrapper {
      // 主图下方区域
      &__bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      // 主图下方中位区域上
      &__bottom-middletop {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      // 主图左上区域
      &__top-left {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100%;
      }
      // 主图右上区域
      &__top-right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  // 整商卡遮盖插槽
  .all-wrapper__bottom-top {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  // 主要信息
  .product-card__bottom-wrapper {
    position: relative;
    padding: var(--product-card__bottom-wrapper, 0.16rem 0.213rem 0.266rem 0.213rem); // 实时反馈用


    border-top-left-radius: var(--product-card-img-radius, var(--product-card-radius, 4px));
    border-top-right-radius: var(--product-card-img-radius, var(--product-card-radius, 4px));

    &_soldout > div, &_soldout > section {
      opacity: 0.3;
    }
    &_categoryview {
      .more-btn-wrapper {
        position: absolute;
        right: 0;
        top: 0.12rem;
      }
    }
    &_categoryviewsoldout {
      .bottom-wrapper__price-wrapper {
        /* stylelint-disable-next-line selector-max-specificity */
        .price-wrapper__price {
          opacity: 0.3;
        }
      }
      .more-btn-wrapper {
        opacity: 1;
      }
      .productcard-belowlabel {
        opacity: 0.3;
      }
      .bottom-wrapper__labels {
        opacity: 0.3;
      }
    }

    &_show-store {
      padding-bottom: 0;
    }

  }

  // 卖点信息区域
  .bottom-wrapper__selling-info {
    text-align: center;
  }
  // 价格区域
  .bottom-wrapper__price-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // 价格布局
    .price-wrapper {
      &__price {
        flex: 1 1 auto;
        width: 0;
      }
      &__addbag-btn {
        flex: 0 0 auto;
      }
      &__addbag-checkout {
        flex-grow: 0;
        flex-shrink: 0;
        width: auto;
        padding: 6px 8px;
        max-width: 2.5rem;
      }
    }
  }

  // RRP价
  .product-card__price-rrp {
    max-width: 100%;
  }
  
  // 服务类和分步式筛选标签
  .bottom-wrapper__labels {
    text-align: center;
  }


  .labels-row-container {
    display: flex;
    align-items: center;

    /* stylelint-disable-next-line */
    .left-block-item + .left-block-item {
      /* stylelint-disable-next-line */
      &::before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 10px;
        background-color: #bbb;
        margin-left: 3px;
        margin-right: 3px;
      }
    }

    .more-btn-wrapper {
      margin-left: auto;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  // 为区分是否为点刷商品的调试代码
  .point-wrapper {
    --led-size: 10px;
    --color-gray-300: hsl(210deg 10% 30% / 1);
    --green: 150deg 100% 50%;
    --red: 0deg 100% 50%;
    --blue: 240deg 100% 50%;
    position: absolute;
    left: 10px;
    top: 10px;
    display: grid;
    place-content: center;
    height: var(--led-size);
    width: var(--led-size);
    background: var(--color-gray-300);
    border-radius: 1000px;
    z-index: 99;
  }
  .point {
    --opacity: 1;
    --hover-opacity: 1;
    --hover-transform: scale(1.33);
    --transition-duration: 0ms;
    position: relative;
    width: calc(var(--led-size) - 2px);
    height: calc(var(--led-size) - 2px);
    background: hsl(var(--point-color));
    border-radius: 1000px;
    box-shadow: inset 0px -1px 1px hsl(var(--point-color)), inset 0px -1px 3px hsl(var(--point-color)), 0px 0px 2px black, 0px 0px 4px black, 0px 0px 8px black, 0px 0px 6px hsl(var(--point-color)), 0px 0px 12px hsl(var(--point-color));
    transition: opacity var(--transition-duration) cubic-bezier(0, 0.69, 0.41, 0.99), transform 300ms cubic-bezier(0, 0.69, 0.41, 0.99);
    opacity: var(--opacity);
  }
  .point::before {
    content: "";
    position: absolute;
    background: white;
    width: 3px;
    height: 1.5px;
    border-radius: 1000px;
    top: 1.5px;
    left: 1.5px;
    transform: rotate(-20deg);
  }
}
</style>

