<template>
  <div
    class="advertising-home-icon"
    :class="{
      'advertising-home-icon__cover': isScrolling
    }"
    @click="goHome"
  >
    <i class="suiiconfont sui_icon_tab_shop_30px"></i>
    <p>{{ languageHome || 'Home' }}</p>
  </div>
</template>
<script>
let scrollStopFunc = null
export default {
  props: {
    languageHome: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isScrolling: false,
    }
  },
  mounted() {
    // 等待空闲再挂载滚动侦听器
    const initScroll = () => window.addEventListener('scroll', this.handleScroll, {
      passive: true
    })
    if (window.requestIdleCallback) {
      requestIdleCallback(() => initScroll(), {
        timeout: 1000
      })
    } else {
      setTimeout(() => initScroll(), 1000)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.isScrolling = true
      if (scrollStopFunc) clearTimeout(scrollStopFunc)
      scrollStopFunc = setTimeout(() => {
        this.isScrolling = false
      }, 100)
    },
    goHome() {
      const { langPath } = gbCommonInfo
      this.$router.push(`${langPath}/`)
    },
  }
}
</script>
<style lang="less">
.advertising-home-icon {
  width: 1.4933rem;
  height: 1.4933rem;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: rgba(238, 238, 238, 0.60);
  color: #222;
  text-align: center;
  backdrop-filter: blur(4px);
  position: fixed;
  bottom: 3.1467rem;
  right: .2133rem;
  transition: right .8s;
  z-index: @zindex-task-tips;
  transform: translateZ(10px); // 解决大卡片 .mini-btn__container图层更高的原因
  > i {
    font-size: .8rem;
    line-height: 1;
    display: inline-block;
    margin-top: .0533rem;
  }
  > p {
    font-size: 11px;
    line-height: 80%;
    font-family: Arial;
  }
  &__hidden {
    visibility: hidden;
  }
  &__cover {
    right: -0.5867rem;
  }
}
</style>
