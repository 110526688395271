import type { DS_SizeGuide } from '../../../types'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

export const getCommentSizes: DS_SizeGuide.APIS_THIS['getCommentSizes'] = async function (params: {
  spu_id: string,
  skc: string
}) {
  const result = await this.$schttp<DS_SizeGuide.CommentSizeResponse>({
    url: `${window.location.origin}${useAppConfigs().$envs.langPath}/api/productInfo/commentSizes/get`,
    params,
  })
  return result.data
}

export const updateBusinessCache: DS_SizeGuide.APIS_THIS['updateBusinessCache'] = async function(data) {
  const result = await this.$schttp({
    url: `${window.location.origin}${useAppConfigs().$envs.langPath}/api/common/busineseCache/update`,
    method: 'post',
    data,
  })
  return result.data
}
