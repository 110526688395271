const state = () => {
  return {
    context: {},
    hasRequestChannelIds: new Set(), // 已经请求过的 tabId
    list: {}, // 存放首页所有 Tab 对应数据
    isH1R56: true, // HomePageTerminal.p.H1PictureProcess === 'H1_picture' 显示H1商品容器为5:6，否则商品容器3:4
    isHomeR56: true, // HomePageTerminal.p.HomePictureProcess === 'Home_picture' 其他商品图容器为5:6,否则3:4
    freeShppingCon: {
      bindPropsData: {},
      dependData: {},
    }
  }
}

export default state
