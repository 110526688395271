import qs from 'query-string'
import schttp from 'public/src/services/schttp'
import TMG from '@shein/time-management-guru'
import { prefetchRequests } from 'public/src/pages/goods_detail_v2/prefetch/index.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
// import pageContextConstant from 'public/src/pages/goods_detail/utils/pageContextConstant.js'
import { getLocalStorage } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { getDetailMultiLanguageBff } from 'public/src/pages/goods_detail_v2/utils/fs.js'
import { isUseBffFs } from 'public/src/pages/goods_detail_v2/utils/fs.js'
import { VERSION, getGoodsDetailParams, getDetailParamsByUrl } from 'public/src/pages/product_app/store/modules/product_detail/utils/getPrefetchParams.js'

const API_LOCATION = {
  // OLD NODE
  productDetail: '/api/productInfo/productDetail/get',
  productDetailAsync: '/api/productInfo/productDetailAsync/get',
  productDetailRealTime: '/api/productInfo/realTimeData/query',
  // BFF
  PRODUCT_DETAIL_FS_ABT: '/products-api/get_detail_abt_info',
  PRODUCT_DETAIL_COLD: '/product/get_goods_detail_static_data',
  PRODUCT_DETAIL_REAL_TIME: '/product/get_goods_detail_realtime_data'
}

const { currency } = gbCommonInfo
const _isNotProd = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo?.NODE_SERVER_ENV !== 'production' : false



// 商详公共请求参数
const generateGoodsDetailParams = (forListPreFetch = false, oneGoodsParams = {}) => {
  try {
    // const language = _gb_app_?.$store?.state?.['productDetail']?.language || {}
    // const withI18n = Object.keys(language).length ? 0 : 1
    const withI18n = 0
    if (forListPreFetch) {
      const { goods_id, imgRatio, mallCode, showFeedbackRec } = oneGoodsParams
      const oneGoodsDetailParams = {
        currency,
        fromSpa: 1,
        goods_id,
        imgRatio,
        mallCode,
        showFeedbackRec,
        template: 0,
        version: VERSION,
        withI18n
      }
      return oneGoodsDetailParams
    }
    const res = getGoodsDetailParams()
    return res
  } catch (err) {
    return {}
  }
}

const getGoodsDetailRealTimeParams = (
  forListPreFetch = false,
  oneGoodsParams = {},
  extParams = {}
) => {
  try {
    if (forListPreFetch) {
      const { goods_id, mallCode } = oneGoodsParams
      const oneGoodsDetailParams = {
        goods_id,
        mallCode
      }
      return oneGoodsDetailParams
    }
    const {
      goods_id,
      expData
    } = getDetailParamsByUrl()
    return {
      goods_id: goods_id,
      mallCode: expData?.mallCode || extParams?.mallCode,
      billno: expData?.billno,
      realTimeConfig: { 
        estimatedPriceInfo: true,
        ...(extParams?.realTimeConfig || {})
      },
      // TODO： 临时拓展参数，目前传参规则较乱，需梳理
    }
  } catch (err) {
    return {}
  }
}

const fetchGoodsDetailBff = async params => {
  const { goods_id, mallCode: mall_code } = params || {}
  if (
    typeof gbCommonInfo !== 'undefined' 
  ) {
    return schttp({
      url: `${API_LOCATION.PRODUCT_DETAIL_COLD}`,
      headers: _isNotProd ? {
        'sheindebug': 676 // 非正式环境传入debug参数方便中间层调试 TODO
      } : {},
      params: {
        goods_id,
        mall_code,
        isUserSelectedMallCode: 1,
        isHideEstimatePriceInfo: location.search.includes('landing_page_id') ? 1 : 0,
        specialSceneType: location.search.includes('landing_page_id') ? 1 : 0,
      },
      useBffApi: true,
      isPreLoad: true,
    }).catch((err) => {
      console.log('fetchGoodsDetailBff error:', err)
    })
  }
  return null
}

const _fetchGoodsDetailBffFs = async params => {
  let res = {}
  try {
    const [BFF_RES] = await Promise.all([
      fetchGoodsDetailBff(params),
    ])
    res = BFF_RES
  } catch (err) {
    return res
  }

  return res
}

const fetchGoodsDetailFsAbt = async params => {
  const { goods_id } = params || {}
  const data = await schttp({
    url: API_LOCATION.PRODUCT_DETAIL_FS_ABT,
    params: {
      goodsIds: goods_id,
    },
    useBffApi: true,
  })
  const result = data?.info?.detailAbtInfoList?.find?.(item => item?.goodsId == goods_id)
  return result?.webDetailAbtInfo || {}
}

const fetchGoodsDetail = async params => {
  if (isUseBffFs()) return _fetchGoodsDetailBffFs(params)

  let res = {}
  try {
    const paramsStr = qs.stringify(params)
    const [result, BFF_INFO] = await Promise.all([
      schttp({
        url: `${API_LOCATION.productDetail}?${paramsStr}`,
        isPreLoad: true
      }),
      fetchGoodsDetailBff(params),
    ])
    res = result
    if (res.modules && +BFF_INFO?.code === 0) {
      res.modules.BFF_INFO = BFF_INFO?.info
    }
  } catch (err) {
    return res
  }

  return res
}

const fetchGoodsDetailRealTimeBff = async params => {
  const { goods_id, mallCode, billno = '' } = params || {}
  if (
    typeof gbCommonInfo !== 'undefined' 
  ) {
    let addressCookie = getLocalStorage('addressCookie') || {}
    let address_cache = getLocalStorage('address_cache') || {}
    let { addressId, countryId, stateId, cityId, districtId } = addressCookie
    let state = address_cache[stateId]
    let city = address_cache[cityId]
    let district = address_cache[districtId]
    let shippingParams = {
      countryId,
      addressId,
      state,
      city,
      district
    }
    const _hideEstimatePriceValue = location.search.includes('landing_page_id') ? 1 : 0  // 隐藏到手价反爬
    return schttp({
      url: `${API_LOCATION.PRODUCT_DETAIL_REAL_TIME}`,
      params: {
        goods_id,
        mallCode,
        // web不需要接口自动切换mall
        isUserSelectedMallCode: 1,
        // isQueryIsPaidMember 是否查询用户是否付费会员，web使用
        isQueryIsPaidMember: 1,
        isQueryCanTrail: isLogin() ? 1 : 0,
        isHideEstimatePriceInfo: _hideEstimatePriceValue,
        specialSceneType: _hideEstimatePriceValue,
        billno,
        ...shippingParams
      },
      headers: _isNotProd ? {
        'sheindebug': 676 // 非正式环境传入debug参数方便中间层调试 TODO
      } : {},
      useBffApi: true,
      isPreLoad: true,
    })
      .catch((err) => {
        console.log('fetchGoodsDetailRealTimeBff error:', err)
      })
  }
  return null
}
const _fetchGoodsDetailRealTimeBffFs = async params => {
  try {
    const [BFF_INFO, assistRes] = await Promise.all([
      fetchGoodsDetailRealTimeBff(params),
      schttp({
        method: 'POST',
        url: '/api/productInfo/assist/get',
        data: params
      })
    ])
    let { info, code } = BFF_INFO
    if (typeof code === 'undefined' || !assistRes?.data) return {}
    // mockreal info = {}
    return  {
      code: Number(code), // 状态码等
      BFF_INFO: info,
      ASSIST_DATA: assistRes?.data,
    }
  } catch (err) {
    return {}
  }
}

const fetchGoodsDetailRealTime = async params => {
  if (isUseBffFs()) return _fetchGoodsDetailRealTimeBffFs(params)

  let res = {}
  try {
    const [result, BFF_INFO] = await Promise.all([
      schttp({
        method: 'POST',
        url: API_LOCATION.productDetailRealTime,
        data: params
      }),
      fetchGoodsDetailRealTimeBff(params),
    ])
    res = result
    if (res.data && +BFF_INFO?.code === 0) {
      res.data.BFF_INFO = BFF_INFO?.info
    }
  } catch (err) {
    return res
  }

  return res
}

/**
 * @name 单个商品详情预请求 - 用于商品列表
 * @param {*} params[]   params: { goods_id, imgRatio, mallCode, showFeedbackRec }
 */
export const goodsDetailTMGTriggerForList = params => {
  params.forEach(oneGoods => {
    const oneGoodsParams = {
      goods_id: oneGoods.goods_id,
      imgRatio: oneGoods.imgRatio,
      mallCode: oneGoods.mallCode,
      showFeedbackRec: oneGoods.showFeedbackRec
    }
    if (oneGoods.goods_id) {
      TMG.triggerQuickRequests('pageGoodsDetail', {
        quickRequestNames: [
          `getGoodsDetail/${oneGoods.goods_id}`,
          `getGoodsDetailRealTime/${oneGoods.goods_id}`
        ],
        triggerParams: { from: 'list', oneGoodsParams: oneGoodsParams },
        throttle: true
      })
    }
  })
}

const preFetchFSDetail = (goodsList = []) => {
  // 请求去重
  const cachePretchFSInfoMap = window.__detail_fs_info_cache__?.cachePretchFSInfo
  const list = goodsList.filter(item => {
    const hasCache = cachePretchFSInfoMap.has(+item.goods_id)
    if(hasCache) window.__detail_fs_info_cache__.renewalSet(+item.goods_id)
    return !hasCache
  })
  prefetchRequests(list)
}

let hadFetchDetailLanguage = false
export const preFetchDetailLanguage = () => {
  if (hadFetchDetailLanguage) return
  requestIdleCallback(() => {
    TMG.triggerQuickRequests('pageGoodsDetailLanguage')
    hadFetchDetailLanguage = true
  })
}

// 列表页商品曝光时触发
export const handleGoodsDetailTrigger = async ({ goods, exposeGoodIds }) => {
  preFetchDetailLanguage()
  const list = goods.filter(item => exposeGoodIds.includes(item.goods_id + ''))
  if (!list.length) return

  // list, 去做一下事情...

  // 实验判断
  const { IsPerformance } = await getUserAbtData()
  const performance_open = IsPerformance?.param?.IsPerformance === 'A'
  if (!performance_open) return

  // const { mainImgs, secondImgs } = els.reduce(
  //   (acc, cur) => {
  //     const mainImg = cur.getAttribute('data-main-img')
  //     const secondImg = cur.getAttribute('data-second-img')
  //     if (mainImg) acc.mainImgs.push(mainImg)
  //     if (secondImg) acc.secondImgs.push(secondImg)

  //     return acc
  //   },
  //   { mainImgs: [], secondImgs: [] }
  // )

  // preloadImgs({ imgs: secondImgs })
  // const imgRatios = await Promise.all(
  //   mainImgs.map(img => (img ? getImageRatio(transformImg({ img })) : ''))
  // )
  const itemCallback = el => {
    const { goods_id, mall_code, goods_sn, cat_id } = el || {}
    return {
      goods_id,
      mallCode: mall_code,
      sku: goods_sn,
      cateId: cat_id
    }
  }
  const d = list.map((item, i) => itemCallback(item, i))
  preFetchFSDetail(d)
  // goodsDetailTMGTriggerForList(d)
  if (gbCommonInfo.isDebug) {
    console.log(
      '%c [preload request] List page prerequest starts the detailed interface',
      'background: #000; color: #ca6bc6; padding: 4px;',
      d
    )
  }
}

export const pageGoodsDetailRequests = {
  quickRequests: {
    getGoodsDetail: {
      generateParams: ({ triggerParams }) => {
        const forListPreFetch =
          triggerParams.from && triggerParams.from === 'list'
        const oneGoodsParams = triggerParams?.oneGoodsParams || {}
        const params = generateGoodsDetailParams(forListPreFetch, oneGoodsParams)
        const goods_id = params?.goods_id
        return { [goods_id]: params }
      },
      request: fetchGoodsDetail,
      // 设置会话缓存过期时间
      sessionExpired: 1000 * 60 * 10
    },
    getGoodsDetailRealTime: {
      generateParams: ({ triggerParams }) => {
        const forListPreFetch =
          triggerParams.from && triggerParams.from === 'list'
        const oneGoodsParams = triggerParams?.oneGoodsParams || {}
        const params = getGoodsDetailRealTimeParams(
          forListPreFetch,
          oneGoodsParams,
          triggerParams?.extParams
        )
        const goods_id = params?.goods_id
        return { [goods_id]: params }
      },
      request: fetchGoodsDetailRealTime,
      // 设置会话缓存过期时间
      sessionExpired: 1000 * 60 * 110
    },
    getGoodsDetailFsAbt: {
      generateParams: ({ triggerParams }) => {
        const forListPreFetch = triggerParams.from && triggerParams.from === 'list'
        const oneGoodsParams = triggerParams?.oneGoodsParams || {}
        const params = generateGoodsDetailParams(forListPreFetch, oneGoodsParams)
        // abt单独添加登录态做缓存
        params.isLogin = isLogin() ? 1 : 0
        const goods_id = params?.goods_id
        return { [goods_id]: params }
      },
      request: fetchGoodsDetailFsAbt,
      // 设置会话缓存过期时间
      sessionExpired: 1000 * 60 * 10
    },
  }
}

export const pageGoodsDetailLanguageRequests = {
  quickRequests: {
    getGoodsDetailLanguage: {
      params: () => {},
      request: async () => {
        // 使用bff多语言
        if (isUseBffFs()) return getDetailMultiLanguageBff()

        return Promise.all([
          schttp({
            url: `/api/common/language/get`,
            params: {
              page: 'product_detail'
            }
          }),
          schttp({
            url: `/api/common/language/get`,
            params: {
              page: 'coupon_item'
            }
          })
        ]).then(([product_detail, coupon_item]) => {
          return Object.assign(
            {},
            product_detail?.language || {},
            coupon_item?.language || {}
          )
        })
      },
      // 设置会话缓存过期时间
      sessionExpired: 1000 * 60 * 30
    }
  }
}
