import schttp from 'public/src/services/schttp'

let postModifyCartCheckStatusAbortController
export const modifyCartCheckStatus = (params = {}) => {
  postModifyCartCheckStatusAbortController?.abort()
  postModifyCartCheckStatusAbortController = new SchttpAbortCon()
  if(params.editCartId) delete params.editCartId
  return schttp({
    url: `/api/cart/postModifyCartCheckStatus/update`,
    method: 'POST',
    data: {
      ...params,
      cart_prime_product_code: window.cart_prime_product_code || ''
    },
    signal: postModifyCartCheckStatusAbortController?.signal
  }).catch(err => {
    if (err.code === 'ERR_CANCELED') return err
    return { code: -1 }
  })
}
