import type { ExtractPropTypes, PropType } from 'vue'
import type { DS_QuickAddCartStandard } from '../../../../types'

export const BImgModalProps = {
  value: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  index: {
    type: Number as PropType<number>,
    default: 0,
  },
  productInfo: {
    type: Object as PropType<DS_QuickAddCartStandard.ProductInfo>,
    default: () => ({}),
  },
  fsAbt: {
    type: Object as PropType<DS_QuickAddCartStandard.FsAbt>,
    default: () => ({}),
  },
  imgs: {
    type: Array as PropType<DS_QuickAddCartStandard.GoodsImages[]>,
    default: () => [],
  },
  willSoldOutTipsInfo: {
    type: Object as PropType<{
      tip: string
      lowStockType: any
      scene: string
    }>,
    default: () => ({}),
  },
}

export type TBImgModalProps = ExtractPropTypes<typeof BImgModalProps>
