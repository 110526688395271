import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { useEditablePaymentInfo } from './useEditablePaymentInfo'
import type { PaymentItemProps } from '../types/props'
import { debuggerLog } from '../utils'
import { type AS_PayToolkit } from '../types'
import { inject } from 'vue'

interface UseGetInstallmentsParams {
    paymentCode?: string,
    cardBin?: string,
    cardType?: string,
    billno?: string,
    orderCurrency?: string,
    cb?: (info: any) => void,
}

interface ChangePayStoreInstallmentsParams {
    pay: PaymentItemProps,
    installments: Record<string, any>,
}


// 获取分期信息
export const useGetInstallments = ({ paymentStore }) => {
    const { triggerNotice } = (inject('analysisInstance') || {}) as AS_PayToolkit.AnalysisInstance
    const appConfigs = useAppConfigs()
    const { handleEditableInfoChange } = useEditablePaymentInfo()

    const getInstallments = async (useGetInstallmentsParams: UseGetInstallmentsParams) => {
        const { checkoutInfo = {} } = paymentStore
        try {
            const info = await appConfigs.$schttp({
                url: '/pay/paycenter/installments',
                method: 'POST',
                params: {
                    orderAmount: checkoutInfo?.totalPriceInfo?.amount || '',
                    paymentCode: useGetInstallmentsParams?.paymentCode || '',
                    cardBin: useGetInstallmentsParams?.cardBin || '',
                    cardType: useGetInstallmentsParams?.cardType || '',
                    orderCurrency: useGetInstallmentsParams?.orderCurrency || checkoutInfo?.currencyCode || checkoutInfo?.orderCurrencyInfo?.code || appConfigs?.$envs?.currency || '',
                    countryCode: checkoutInfo?.addressInfo?.countryCode || '',
                    billno: useGetInstallmentsParams?.billno || '',
                 },
            })

            if (info?.data?.code == 0) {
                triggerNotice({
                    id: 'expose_scenesabt.comp_pay-toolkit',
                    data: {
                        scenes: 'installmentRecommend',
                        installment_recommend_num: info?.data?.info?.recommend_installments || '',
                        installmentRecommendReason: info?.data?.info?.recommend_reason || '',
                        installmentRecommendChosen: (info?.data?.info?.installments || [])?.some(item => item?.default_choose) ? '1' : '0',
                    },
                })

                paymentStore.updateState('installmentInfo', info?.data?.info || {})
            }

            useGetInstallmentsParams?.cb?.(info)
        } catch(err) {
            debuggerLog('err >>>>>>>', err)
        }
    }

    const changePayStoreInstallments = (changePayStoreInstallmentsParams: ChangePayStoreInstallmentsParams) => {
        handleEditableInfoChange({
            changeInfo: {
                [changePayStoreInstallmentsParams?.pay?.code]: {
                    ...(paymentStore?.editablePaymentInfo?.[changePayStoreInstallmentsParams?.pay?.code] || {}),
                    ...(changePayStoreInstallmentsParams?.installments || {}),
                },
            },
            needEmitChangeEvent: true,
        })
    }

    return {
        getInstallments,
        changePayStoreInstallments,
    }
}
