<template>
  <div
    class="checkout-price-item"
    :style="priceItemBorder"
  >
    <div class="checkout-price-item__left">
      <img
        v-if="item.icon"
        class="checkout-price-item__icon"
        :src="item.icon"
      />
      <div>
        <div class="checkout-price-item__main">
          <span
            class="checkout-price-item__main-name"
          >
            {{ item.local_name }}
          </span>
          <Icon
            v-if="tipsIconVisible"
            name="sui_icon_doubt_12px_2"
            size="16px"
            color="#959595"
            @click.stop="handleTipsClicked"
          />
          <span
            v-if="item.description"
            class="checkout-price-item__main-desc"
          >
            {{ item.description }}
          </span>
        </div>
        <div
          v-if="item.below_desc?.length"
          class="checkout-price-item__below-desc"
        >
          <span
            v-for="(below, belowIndex) in item.below_desc"
            :key="belowIndex"
            :style="{
              color: '#767676',
              textDecoration: below.show_type?.includes('delete_line') ? 'line-through' : 'none',
            }"
          >
            {{ below.desc }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="checkout-price-item__value"
      @click="openDetailsDrawer"
    >
      <del
        v-if="item.origin_price_with_symbol"
        class="checkout-price-item__value-origin"
      >
        {{ item.origin_price_with_symbol }}
      </del>
      <span
        class="checkout-price-item__value-price"
        :style="priceStyle"
      >
        {{ priceWithSymbol }}
      </span>
      <Icon
        v-if="item.interaction_info"
        class="checkout-price-item__value-arrow"
        :is-rotate="cssRight"
        name="sui_icon_more_right_16px"
        size="16px"
        color="#959595"
      />
    </div>
  </div>
</template>

<script name="CheckoutPriceItem" setup lang="ts">
// 数据源：
import { type C_CheckoutPrice, E_CheckoutPrice } from '../../../types'
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { useAnalysis } from '../../../common/analysisSource'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const analysisInstance = useAnalysis()
const { triggerNotice } = analysisInstance
const appConfigs = useAppConfigs()
const { cssRight = false } = appConfigs.$envs || {}

const props = defineProps<{
  isShowShippingFee?: boolean,
  item: C_CheckoutPrice.PriceItemInfo
}>()

const emits = defineEmits(['show-tips', 'open-details-drawer'])

const isShippingTips = computed(() => (
  props.isShowShippingFee &&
    props.item.type === E_CheckoutPrice.PriceItemType.Shipping
))

const tipsIconVisible = computed(() => {
  return isShippingTips.value || props.item.tip || props.item.des
})

// 价格负号
const priceWithSymbol = computed(() => {
  let price = props.item.price_with_symbol
  if (props.item.show_type?.includes(E_CheckoutPrice.PriceStyleType.NegativePrice)) {
    price = `-${price}`
  }
  return price
})

const priceStyle = computed(() => {

  const style = { color: '#222', textDecoration: 'none' }
  const colorMap = {
    [E_CheckoutPrice.PriceStyleType.GrayShow]: '#767676',
    [E_CheckoutPrice.PriceStyleType.RedShow]: '#FA6338',
    [E_CheckoutPrice.PriceStyleType.GreenShow]: '#198055',
    [E_CheckoutPrice.PriceStyleType.PrimeColorShow]: '#C96E3F',
  }
  props.item.show_type?.forEach(type => {
    if (type === E_CheckoutPrice.PriceStyleType.DeleteLine) {
      // 价格的删除样式
      style.textDecoration = 'line-through'
    } else if (colorMap[type]) {
      // 价格颜色
      style.color = colorMap[type]
    }
  })

  return style
})

// 是否展示下边框
const priceItemBorder = computed(() => {
  return props.item.show_type?.includes(E_CheckoutPrice.PriceStyleType.UnderlineSplit) ? {
    'border-bottom': '1px solid #e5e5e5',
    'padding-bottom': '8px',
  } : {
    'border-bottom': 'none',
  }
})

const handleTipsClicked = () => {
  let event = E_CheckoutPrice.TipsClickedEvent.ShowTips
  if (isShippingTips.value) event = E_CheckoutPrice.TipsClickedEvent.EmitEvent
  emits('show-tips', { event, tips: props.item.tip || props.item.des })
}

const openDetailsDrawer = () => {
  if (!props.item.interaction_info) return
  emits('open-details-drawer', props.item)
  handleClickedReport()
}

const handleClickedReport = () => {
  const { type, promotion_saved, club_saved, coupons_num, coupons_saved } = props.item.interaction_info?.track_info || {}
  if (!type) return
  triggerNotice?.({
    id: 'click_price_detail.comp_checkout-price',
    data: {
      item: type,
      promotions_saved: promotion_saved || '-',
      club_saved: club_saved || '-',
      coupons_num: coupons_num || '-',
      coupons_saved: coupons_saved || '-',
    },
  })
}
</script>

<style lang="less" scoped>
.checkout-price-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #000;
  line-height: 1.2;
  gap: 24/75rem;
  font-size: 14px;
  padding: 16/75rem 0;

  &:first-child {
    padding-top: 0;
  }

  &__left {
    font-family: "SF Pro";
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8/75rem;
  }

  &__icon {
    width: 40/75rem;
    height: 40/75rem;
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 8/75rem;
  }

  &__main-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__main-desc {
    color: #959595;
    font-size: 12px;
    white-space: nowrap;
    align-self: center;
  }

  &__below-desc {
    color: #767676;
    font-size: 12px;
    margin-top: 8/75rem;
  }

  &__value {
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__value-origin {
    color: #959595;
    margin-right: 6/75rem;
  }
}
</style>
