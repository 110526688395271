var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_setup.prefixCls('part-quick-shipping_wrapper'),on:{"click":_setup.handleLayoutArea}},[(!!_setup.shoppingBagsState.bagsInfo?.mall?.[_setup.mallIndex]?.quickShip?.length)?_c('div',{class:_setup.prefixCls('pqs_item')},[_c(_setup.QuickLabel,{attrs:{"mallCode":_setup.shoppingBagsState.bagsInfo?.mall?.[_setup.mallIndex]?.mall_code}}),_vm._v(" "),(
        _setup.shoppingBagsState.bagsInfo?.mall?.[_setup.mallIndex]?.isRenderSingleProductByQuickShip
      )?_c(_setup.SingleProduct,{attrs:{"data":_setup.shoppingBagsState.bagsInfo?.mall?.[_setup.mallIndex]?.quickShip?.[0]}}):_c(_setup.MultiProduct,{attrs:{"data":_setup.shoppingBagsState.bagsInfo?.mall?.[_setup.mallIndex]?.quickShip}})],1):_vm._e(),_vm._v(" "),(!!_setup.shoppingBagsState.bagsInfo?.mall?.[_setup.mallIndex]?.normal?.length)?_c('div',{class:_setup.prefixCls('pqs_item')},[_c('div',{class:_setup.prefixCls('pqs_label')},[_vm._v("\n      "+_vm._s(_setup.total)+"\n    ")]),_vm._v(" "),(
        _setup.checkout.quick_shipping?.shop_bag_other_shipping_desc
      )?_c('div',{class:_setup.prefixCls('pqs_shop_bag_shipping')},[_vm._v("\n      "+_vm._s(_setup.checkout.quick_shipping.shop_bag_other_shipping_desc)+"\n    ")]):_vm._e(),_vm._v(" "),(
        _setup.shoppingBagsState.bagsInfo?.mall?.[_setup.mallIndex]?.isRenderSingleProductByNormal
      )?_c(_setup.SingleProduct,{attrs:{"data":_setup.shoppingBagsState.bagsInfo?.mall?.[_setup.mallIndex]?.normal?.[0]}}):_c(_setup.MultiProduct,{attrs:{"data":_setup.shoppingBagsState.bagsInfo?.mall?.[_setup.mallIndex]?.normal}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }