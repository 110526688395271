<template>
  <SlideExpand
    v-if="showSlideExpand && showFromEntry"
    :list="list"
    :reverse="locals.GB_cssRight"
    :is-small-status="isSmallStatus"
    :is-config-active="isConfigActive"
    :shape="shape"
    :is-show-icon="isShowIcon"
    :active-value="activeValue"
    :two-line-style="twoLineStyle"
    :constant-data="constantData"
    :active-index="activeIndex"
    :set-active-index="setActiveIndex"
    @change="onChange"
  />
  <PicNavList
    v-else
    :list="list"
    :space-between="isShapeRect ? 8 : spaceBetween"
    :reverse="locals.GB_cssRight"
    :is-small-status="isSmallStatus"
    :is-config-active="isConfigActive"
    :is-show-icon="isShowIcon"
    :active-value="activeValue"
    :shape="shape"
    @change="onChange"
  />
</template>

<script>
/**
 * 是否渲染左滑展开两行组件
 */

import { defineComponent, computed, inject, ref, onMounted } from 'vue'

import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import { useSlideActiveIndex } from 'public/src/pages/components/FilterBar/PicTopNav/hooks'
import { calcItemPadding, getTwoLineStyle, showTwoLineFromEntry } from '../tools'

import PicNavList from 'public/src/pages/components/FilterBar/PicTopNav/components/PicNavList.vue'
import SlideExpand from './Container.vue'

export default defineComponent({
  name: 'SwitchContainer',
  components: {
    PicNavList,
    SlideExpand,
  },
  props: {
    list: { type: Array, default: () => [] },
    originList: { type: Array, default: () => [] },
    isConfigActive: { type: Boolean, default: true }, // 是否配置激活，如可跳转图文，不需要激活，点击直接跳转页面
    isShowIcon: { type: Boolean, default: true },
    shape: { type: String, default: PIC_TOP_NAV_SHAPE.RECT },
    firstRendering: { type: Boolean, default: true },
    activeValue: { type: [String, Number], default: '' }, // 当前选中的值
    onChange: { type: Function, default: function () {} },
  },
  setup (props) {
    const locals = inject('locals', {})
    const isSmallStatus = inject('isSmallStatus', false)
    const catInfo = inject('catInfo', {})
    const listAbtResult = inject('listAbtResult', {})
    const constantData = inject('constantData', {})

    const { activeIndex, setActiveIndex } = useSlideActiveIndex(props)

    const showFromEntry = computed(() => showTwoLineFromEntry({
      catInfo,
      listAbtResult,
    }))

    const isShapeRect = computed(() => props.shape === PIC_TOP_NAV_SHAPE.RECT)
    const twoLineStyle = computed(() => getTwoLineStyle({ listAbtResult, catInfo }))
    const showSlideExpand = computed(() => {
      if (isSmallStatus.value || props.shape === PIC_TOP_NAV_SHAPE.RECT) {
        return false
      }
      const total = props.list.length
      return twoLineStyle.value ?
        (twoLineStyle.value === 'FirstScreen' ? total >= 8 : total >= 13) :
        false
    })

    const itemPadding = ref(6)
    const spaceBetween = computed(() => isSmallStatus.value ? 6 : itemPadding.value)

    onMounted(() => {
      itemPadding.value = calcItemPadding()
    })

    return {
      activeIndex,
      setActiveIndex,
      isShapeRect,
      locals,
      isSmallStatus,
      showSlideExpand,
      showFromEntry,
      twoLineStyle,
      constantData,
      spaceBetween,
    }
  }
})
</script>
