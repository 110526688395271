<template>
  <div class="price-details-header">
    <div class="price-details-header__title">
      {{ item.interaction_info?.title }}
    </div>
    <PriceItem
      v-if="item.interaction_info?.type === E_CheckoutPrice.PopupType.Tax"
      class="price-details-header__price-item"
      :item="taxItem"
      @show-tips="(data) => emits('show-tips', data)"
    />
    <div
      v-else
      class="price-details-header__subtitle"
    >
      <div class="price-details-header__subtitle-text">
        {{ item.interaction_info?.total_save_title }}
      </div>
      <div class="price-details-header__subtitle-value">
        {{ item.interaction_info?.total_save_text }}
      </div>
    </div>

    <Icon
      class="price-details-header__close"
      name="sui_icon_close_16px"
      size="18px"
      color="#666666"
      @click="emits('close')"
    />
  </div>
</template>
<script name="PriceDetailsDrawerHeader" setup lang="ts">
import { computed } from 'vue'
import { omitAttr } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import { C_CheckoutPrice, E_CheckoutPrice } from '../../../../types'
import PriceItem from '../price-item.vue'

const props = defineProps<{
  item: C_CheckoutPrice.PriceItemInfo,
}>()

const emits = defineEmits(['show-tips', 'close'])

const taxItem = computed(() => {
  return omitAttr(
    props.item as any,
    ['interaction_info', 'show_type'],
  ) as C_CheckoutPrice.PriceItemInfo
})

</script>
<style lang="less" scoped>
.price-details-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    max-width: 566/75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    font-size: 16px;
    font-weight: 510;
    padding: 18/75rem 0;
  }

  &__price-item {
    padding: 20/75rem 24/75rem 16/75rem;

    /deep/ .checkout-price-item__value {
      font-size: 20px;
      font-weight: 700;
    }
  }

  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20/75rem 24/75rem 16/75rem;
    color: #FA6338;
    font-size: 14px;
    font-weight: 590;
  }

  &__subtitle-value {
    font-size: 20px;
    font-weight: 700;
  }

  &__close {
    position: absolute;
    top: 18/75rem;
    right: 16/75rem;
  }

}
</style>
