<template>
  <div class="page__login_multi-header">
    <sui_icon_nav_back_24px
      class="icon-back"
      :is-rotate="publicLocalData.GB_cssRight"
      size="24px" 
      @click="handleGoBack"/>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@login/hooks/utils'
import { sui_icon_nav_back_24px } from '@shein-aidc/icon-vue2'
import { urlRedirection, sleep } from '@login/util'
import useRetainModalHook from '@login/hooks/useRetainModalHook.js'

const store = useStore()

const { handleCloseRetainModal } = useRetainModalHook()

const isAsComponent = computed(() => store.state.login.isAsComponent)
const publicLocalData = computed(() => store.state.login.publicLocalData)
const showPointRetainModal = computed(() => store.state.login.showPointRetainModal)

const handleGoBack = () => {
  handleCloseRetainModal()
  sleep(0)
  if (showPointRetainModal.value == true) return
  store.commit('login/setShowIndex', false)
  urlRedirection(true, {}, isAsComponent.value)
  SHEIN_LOGIN.instanceActions.pop()
}

// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'MultiAccountHeader'
}
</script>

<style lang="less" scoped>
.page__login_multi-header{
  padding-top: 33px;
  position: relative;
  .icon-back {
    position: absolute;
    top: 5px;
    left: -10px;
    color: #333;
    text-decoration: none;
    font-size: 24px;
    &:active, &:focus, &:hover {
      text-decoration: none;
    }
  }
}
 
</style>
