<template>
  <div 
    class="xtra-item xtra-item-new"
    :class="{
      'xtra-item-multiple': +coupon.sameCouponNum > 1
    }"
    :style="{
      ...couponConfig[couponType],
      '--coupon-circle-bg-color': entryCouponStyle?.couponCircleBgColor
    }"
  >
    <div 
      ref="lightEffectRef"
      class="xtra-item__wrapper"
      :style="{
        backgroundImage: `url(${couponBgImage})`
      }"
    >
      <!-- 已勾选icon -->
      <div 
        v-if="coupon.selected"
        class="xtra-item__selected"
        :class="{
          'xtra-item__selected-multiple': +coupon.sameCouponNum > 1
        }"
        :style="{
          backgroundColor: couponType === XtraCouponType.Shipping ? '#D2ECD1' : '#FDD2DB'
        }"
      >
        <Icon 
          name="sui_icon_selected_12px" 
          size="12px" 
          :color="couponType === XtraCouponType.Shipping ? '#169E00' : '#F82854'"
        />
      </div>
      <div
        class="xtra-item__content"
        :style="{
          color: couponType === XtraCouponType.Shipping ? '#169E00' : '#F82854'
        }"
      >
        <!-- 券面额 -->
        <!-- <SAdapterText
          min-size="10"
          lines="2"
          :text="discountText"
        /> -->
        <div
          class="discount-text"
          v-html="discount"
        ></div>
      </div>
      <div
        class="xtra-item__amount"
        :class="{
          'new-user-amount': coupon.privilegeAttributeId && +coupon.privilegeAttributeId !== 1
        }"
      >
        <!-- 券数量 -->
        {{ getAmountText(coupon) }}
        <!-- <SAdapterText
          min-size="8"
          lines="1"
          :text="getAmountText(coupon)"
        /> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { couponConfig, XtraCouponType } from './shared/config.js'
const { GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
import { template } from '@shein/common-function'

const props = defineProps({
  coupon: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  entryCouponStyle: {
    type: Object,
    default: () => ({})
  },
  lightEffect: {
    type: Boolean,
    default: false
  }
})

const scrollTextRef = ref(null)
// const { couponType } = useXtraCoupon(props)

const couponType = computed(() => {
  return props.coupon?.applyForType == 'DISCOUNT' ? XtraCouponType.Product : XtraCouponType.Shipping
})

const updateScrollTextStyle = () => {
  if (!scrollTextRef.value) return
  const { scrollWidth, parentElement } = scrollTextRef.value || {}
  const parentWidth = parentElement.clientWidth
  if (scrollWidth <= parentWidth) {
    scrollTextRef.value.style.animation = 'none'
  } else {
    const time = Math.floor(+scrollWidth * (3 / 100))
    scrollTextRef.value.style.animationDuration = `${time}s`
  }
}

const getAmountText = (coupon) => {
  if (coupon.privilegeAttributeId && +coupon.privilegeAttributeId !== 1) {
    // 新人券
    const res = coupon.activityPrivilegeTip ? coupon.activityPrivilegeTip : props.language.SHEIN_KEY_PWA_34457
    return res + (coupon.sameCouponNum > 1 ? ' ×' + coupon.sameCouponNum : '')
  }
  return 'x' + ' ' + coupon.sameCouponNum
}

const otherRule = computed(() => {
  return props.coupon?.otherCouponRule[0] || {}
})

const discount = computed(() => {
  let text = ''
  if (['PARTIAL_FREE_SHIPPING', 'DISCOUNT'].includes(props.coupon?.applyForType)) {
    // 折扣券
    text = GB_cssRight ?
    `${otherRule.value.couponFaceValueTip}</br><span>${otherRule.value.couponFaceValue}</span>` :
    `<span>${otherRule.value.couponFaceValue}</span></br>${otherRule.value.couponFaceValueTip}`
  } else {
    // 免邮券
    text = otherRule.value.couponFaceValueTip
  }
  return text
})

const couponBgImage = computed(() => {
  const coupon = props.coupon || {}
  if (couponType.value === XtraCouponType.Shipping) {
    // 免邮券
    return +coupon.sameCouponNum > 1
      ? 'https://shein.ltwebstatic.com/svgicons/2024/10/09/17284762861809239916.svg'
      : 'https://shein.ltwebstatic.com/svgicons/2024/10/09/17284661473616415372.svg'
  } else if (coupon.privilegeAttributeId && +coupon.privilegeAttributeId !== 1) {
    // 活动商品券（新人券）
    return +coupon.sameCouponNum > 1
      ? 'https://shein.ltwebstatic.com/svgicons/2024/10/09/1728466206652856867.svg'
      : 'https://shein.ltwebstatic.com/svgicons/2024/10/09/17284763242041701758.svg'
  } else if (couponType.value === XtraCouponType.Product && !coupon.newUserLabelText) {
    // 常规商品券
    return +coupon.sameCouponNum > 1
      ? 'https://shein.ltwebstatic.com/svgicons/2024/10/09/17284662392255923342.svg'
      : 'https://shein.ltwebstatic.com/svgicons/2024/10/09/1728476345286010203.svg'
  }
  // 常规商品券
  return 'https://shein.ltwebstatic.com/svgicons/2024/10/09/1728476345286010203.svg'
})

watch(
  () => props.coupon,
  (newVal, oldVal) => {
    if(!window || JSON.stringify(oldVal) === JSON.stringify(newVal)) return
    nextTick(() => {
      updateScrollTextStyle()
    })
  },
  {
    immediate: true,
    deep: true
  }
)

const lightEffectRef = ref(null)
watch(
  () => props.lightEffect, (nVal) => {
    if (nVal) {
      lightEffectRef.value?.classList?.add('light-slide-effect', GB_cssRight ? 'light-slide-effect-rtl' : 'no-light-slide')

      setTimeout(() => {
        lightEffectRef.value?.classList?.remove('light-slide-effect', 'light-slide-effect-rtl')
      }, 600)
    }
  }
)
</script>

<style lang="less" scoped>
@vw: 375/100vw;

/* 定义动画 */
@keyframes scrollText {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
    transform: translateX(-100%);
  }
}
// 阿拉伯语言滚动
@keyframes scrollTextAr {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
    transform: translateX(-100%);
  }
}

.xtra-item {
  height: 58 / @vw;
  position: relative;
  margin-top: 3/@vw;
  overflow: hidden;

  &__wrapper {
    position: relative;
    border-top-left-radius: 21/75rem;
    border-top-right-radius: 21/75rem;
    overflow: hidden;

    width: 100%;
    height: 100%;
    border-radius: 8/75rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__selected {
    position: absolute;
    left: 2/@vw;
    border-radius: 8/75rem 0 22/75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2/75rem 6/75rem 2/75rem 2/75rem;

    &-multiple {
      top: 4/@vw;
    }
  }

  &__quantity {
    position: absolute;
    right: 0;
    top: 10/75rem;
    padding: 2/75rem 12/75rem;
    border-radius: 0 8/75rem;
    color: #F82854;
    background-color: #FDD2DB;
    font-family: "SF Pro";
    .font-dpr(20px);
    font-weight: 510;
    line-height: 1.2;
  }

  &__content {
    width: 100%;
    height: 42/@vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--saver-red, #F82854);
    font-weight: 800;
    line-height: normal;

    .discount-text {
      width: 80%;
      height: fit-content;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      font-size: 10/37.5rem;

      /deep/ span {
        font-size: 14/37.5rem;
      }

      /deep/ div {
        font-size: 12/37.5rem;
      }
    }
  }

  &__amount {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 12/37.5rem;

    padding: 0 3/@vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // .S-adapterText {}
  }

  .new-user-amount {
    font-size: 10/37.5rem;
  }

  &__rule {
    width: 100%;
    .font-dpr(20px);
    color: var(--ruleColor);
  }
}

.xtra-item-multiple {
  height: 61 / @vw;
  margin-top: 0/@vw;

  .xtra-item__content {
    padding-top: 6/@vw;
  }

  .xtra-item__amount {
    margin-top: 3/@vw;
  }
}

.light-slide-effect::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: -150%; /* 初始位置在左边外，待动画滑入 */
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.1) 100%);
  transform: skewX(-30deg); /* 倾斜角度 */
  animation: shiny 0.6s linear infinite;
} 

.light-slide-effect-rtl::before {
  animation: shiny-ar 0.6s linear infinite !important;
}

/* 定义动画关键帧实现左到右滑动 */
@keyframes shiny {
  0% {
    left: -150%;
  }
  // 50% {
  //   left: 150%;
  // }
  100% {
    left: 150%;
  }
}

@keyframes shiny-ar {
  0% {
    right: -150%;
  }
  // 50% {
  //   left: 150%;
  // }
  100% {
    right: 150%;
  }
}
</style>
