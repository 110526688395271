<template>
  <div
    class="tab-pic-nav-background"
    :style="{
      height: isTowRow ? '9.12rem' : '4.3rem'
    }"
  >
    <img
      src="//img.ltwebstatic.com/images3_ccc/2024/09/03/ae/1725345080c569df9fb23dbbab4b4c876e042f1890.png"
      alt="bg-img"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick, watch, getCurrentInstance } from 'vue'
import { debounce } from '@shein/common-function'
const props = defineProps({
  extendData: {
    type: Object,
    default: () => ({})
  }
})

const vm = getCurrentInstance()

const useStore = () => {
  if (!vm) return console.warn('useStore must be used after setup')
  return vm?.proxy?.$store
}
const { commit } = useStore()

const isTowRow = ref(false)
let nav = null
let resizeObserver = null
// 判断是否是两行图文导航
const changeIsRow = debounce({
  func: () => {
    isTowRow.value = nav?.offsetHeight > 200
  },
  wait: 300
})
watch(() => props.extendData?.catInfo?.cat_id, () => {
  if (typeof window === 'undefined') return
  const bodyElement = document.body
  const isFixed = bodyElement.style.position === 'fixed'
  const positionY = window.scrollY
  // 控制顶部背景色
  if (positionY > 10 || isFixed) {
    commit('changeHomeImmersiveInfo', {
      commonHeaderBg: {
        showBlock: false,
        searchInputBgColor: ''
      }
    })
  } else {
    commit('changeHomeImmersiveInfo', {
      commonHeaderBg: {
        showBlock: true,
        topTextColor: '#222',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        searchInputBgColor: 'rgba(255, 255, 255, 0.8)',
      }
    })
  }
})
onMounted(() => {
  nextTick(() => {
    nav = document.querySelector('#scroll-top-pic-top-nav')
    resizeObserver = new ResizeObserver(() => changeIsRow())
    if (nav) {
      resizeObserver.observe(nav)
    }
  })
})
onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})
</script>

<style lang="less">
.tab-pic-nav-background {
  width: 100%;
  background-color: #edf4f6;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
