import { getQueryString } from '@shein/common-function'
import { searchWordsGlobalAnalysis } from '@/public/src/pages/common/biz_helper/gb_sw_common'
import { ListAnalytics } from '@/public/src/pages/product_list_v2/analytics/index.js'
import { getListPageInfo } from '@/public/src/pages/product_list_v2/js/utils.js'
import { exposeSearchFilterAna } from '@/public/src/pages/components/product/item_v2/analysis'
import { loadDetailComponent } from './usePrefetch'

// 搜索结果页曝光,用于通知店铺搜索页头部外露曝光
const notifySearchExposure = (vm) => {
  const { isStoreSearch } = getListPageInfo({ search_type: vm.catInfo.search_type })
  if (isStoreSearch) {
    appEventCenter.$emit('pdsearchExposure')
  }
}

const initListAnalysis = (vm) => {
  if (!vm.ListAnalytics) vm.ListAnalytics = new ListAnalytics(vm.locals)
}

const initAnalysis = (vm) => {
  // search only
  if (getQueryString({ key: 'word_type' }) === '20') {
    searchWordsGlobalAnalysis.set({
      result_type: 20,
      pagefrom: 'AdsSearch'
    })
  }
  initListAnalysis(vm)

  // 发页面PV
  vm.ComponentState.ProductList.analysisData = vm.ListAnalytics.resetPageData({
    listAbtResult: vm.listAbtResult,
    catInfo: vm.catInfo,
    goods: vm.goods,
    searchKeywords: vm.searchKeywords,
    tracking: vm.tracking,
    request_ext: vm.request_ext,
    // hypernymRequestExt: vm.hypernymProductInfo?.request_ext || {},
    sum: vm.sum,
    currentCat: vm.currentCat,
    parentCats: vm.parentCats,
    goodsCrowId: vm.goodsCrowId,
    fhContext: vm.fhContext,
    googleContext: vm.googleContext,
    listFilterAbt: vm.listFilterAbt,
    extraData: {
      priceInput: vm.Request.query.price_input,
      sortType: vm.ComponentState?.FilterBar?.filterConfig?.sortType,
      ...(
        vm.catInfo.type === 'search' 
          ? { Searchboxform: getQueryString({ key: 'Searchboxform' }) }
          : {}
      ),
    },
    searchTraceId: vm.PageState.searchTraceId,
    language: vm.language,
    filterCates: vm.ComponentState?.FilterBar?.filterData?.filterCates,
    isTopTrendResultPage: vm.isTopTrendResultPage,
    searchRequestStatus: vm.searchRequestStatus
  })

  // branch曝光
  window.JOURNEY_BRANCH?.pageView(vm)

  // 重置一些曝光
  if (vm.ListAnalytics && vm.ListAnalytics?.resetExposeStatus) {
    const isBack = window.appRouteExtData.direction === -1
    vm.$nextTick(() => vm.ListAnalytics?.resetExposeStatus(isBack, vm.ComponentState))
    vm.$emit('ListAnalyticsReady')
  }

  // 等页面埋点信息初始化发送通知头部店铺外露埋点
  notifySearchExposure(vm)
}

// 注册主商品列表曝光
const subscribeMainProductsExpose = (vm, { exposeIds }) => {
  const { config } = vm.ComponentState.ProductList
  onSubscribeListExpose(vm, exposeIds, ({ targets, daId }) => {
    // 悬浮购物车下单引导
    targets.forEach(el => {
      const itemIndex = el.getAttribute('data-index')
      vm.$refs.ProductList?.listExposeTriggerCartBagTip(itemIndex)
    })

    if (daId === config.itemDAEventExposeId) {
      const exposeGoodIds = []
      targets.forEach(el => {
        // 曝光分布式筛选 search only
        const searchFilter = el.getAttribute('data-search-filter')
        searchFilter && exposeSearchFilterAna(searchFilter, el)
        const dataId = el.getAttribute('data-id')
        if (dataId) {
          exposeGoodIds.push(dataId)
        }
      })
      // 一些策略 让商详更快上屏
      loadDetailComponent(vm, { exposeGoodIds })
    }
  })
}

const subscribeFn = (vm, { newData }) => {
  const { requestType } = newData.cat_info || {}

  const compsCode = vm.$refs.ProductList?.setAnalysisData(requestType)
  subscribeMainProductsExpose(vm, { exposeIds: compsCode })
}

// 初始化页面PV埋点
const refreshAnalysisData = (vm) => {
  if (window.requestIdleCallback) {
    window.requestIdleCallback(
      () => {
        initAnalysis(vm)
      },
      { timeout: 2000 }
    )
  } else {
    setTimeout(() => {
      initAnalysis(vm)
    }, 2000)
  }
}

// 更新埋点数据
const updatePageData = (vm, updateValue) => {
  initListAnalysis(vm)
  vm.ListAnalytics.updatePageData(updateValue)
}

// 初始化主商品列表曝光
const initListExposeAnalysis = (vm, { newData }) => {
  // 在设置vm.goods之前记录上一次的goods长度
  if (vm.ListAnalytics) {
    subscribeFn(vm, { newData })
  } else {
    vm.$once('ListAnalyticsReady', () => {
      subscribeFn(vm, { newData })
    })
  }
}

// 注册非商品列表元素的曝光
const onSubscribeExpose = (vm, exposeIds) => {
  if (vm.ListAnalytics) {
    vm.ListAnalytics.subscribeExpose(exposeIds)
  } else {
    vm.$once('ListAnalyticsReady', () => {
      vm.ListAnalytics.subscribeExpose(exposeIds)
    })
  }
}

// 注册商品列表曝光
const onSubscribeListExpose = (vm, exposeIds, callback = () => {}) => {
  if (vm.ListAnalytics) {
    vm.ListAnalytics.subscribeListExpose(exposeIds, ({ targets, daId }) => callback({ targets, daId }))
  } else {
    vm.$once('ListAnalyticsReady', () => {
      vm.ListAnalytics.subscribeListExpose(exposeIds, ({ targets, daId }) => callback({ targets, daId }))
    })
  }
}

export {
  refreshAnalysisData,
  updatePageData,
  initListExposeAnalysis,
  onSubscribeExpose,
  onSubscribeListExpose,
}
