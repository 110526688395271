var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.storeReviewInfo.shop_tag?.length >= 2)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: _vm.sourcePage === 'page_goods_review' ? '1-6-2-104' : '1-6-2-102',
    data: {
      revnum: _vm.storeReviewInfo.shopCmtNum,
      keywordnum: _vm.storeReviewInfo.shop_tag?.length,
      benefit: _vm.storeReviewInfo?.has_benefit_point ? 1 : 0
    }
  }),expression:"{\n    id: sourcePage === 'page_goods_review' ? '1-6-2-104' : '1-6-2-102',\n    data: {\n      revnum: storeReviewInfo.shopCmtNum,\n      keywordnum: storeReviewInfo.shop_tag?.length,\n      benefit: storeReviewInfo?.has_benefit_point ? 1 : 0\n    }\n  }"}],staticClass:"view-review__store-content",class:{ 'in_review_list': _vm.sourcePage === 'page_goods_review' },on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"view-review__store-header",on:{"click":function($event){$event.stopPropagation();return _vm.enterStoreReview.apply(null, arguments)}}},[_c('b',{staticClass:"left-store-nums"},[_vm._v(_vm._s((_vm.language_v2.SHEIN_KEY_PWA_35090 || 'Store Reviews'))+" ("+_vm._s(_vm.storeReviewInfo.shopCmtNum)+")")]),_vm._v(" "),_c('div',{staticClass:"right-arrow-text"},[_c('span',{staticClass:"right-text-content"},[_vm._v(_vm._s(_vm.storeReviewInfo.storeCommentTips))]),_vm._v(" "),_c('Icon',{staticClass:"more-right-icon",attrs:{"name":"sui_icon_more_right_12px","size":".32rem","is-rotate":_vm.GB_cssRight}})],1)]),_vm._v(" "),_c('div',{staticClass:"view-review__store-tag-list"},_vm._l((_vm.storeReviewInfo.shop_tag || []),function(item){return _c('div',{staticClass:"view-review__store-tag-item"},[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.enterStoreReview(item)}}},[_vm._v(_vm._s(`${item.tagName}(${item.tagCmtNum})`))])])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }