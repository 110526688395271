<template>
  <SBusinessCart
    ref="cartRef"
    size="80"
    :type="type"
    :scene="scene"
    :state="state"
    :promotion-id="promotionId"
    :base-config="baseConfig"
    :locals="locals"
    :ui-config="uiConfig"
    :bottom-placeholder="true"
    :address="addressInfo"
    :user-local-size-country="userLocalSizeCountry"
    :feeds-add-items="feedsAddItems"
    :theme="theme"
    :theme-vars="themeVars"
    :quick-add-carts-standard="quickAddCartsStandard"
    @cartUpdated="onCartUpdated"
    @re-pick="handleRePick"
  >
    <template #bottom>
      <SPopover
        v-model="popoverShow"
        class="business-cart-popover"
        trigger-type="user"
        show-close-icon
        :append-to-body="false"
        :prop-style="{ width: '90vw'}"
        placemen="top"
      >
        <div
          v-expose="{
            id: '1-8-4-21',
            data: { state: state }
          }"
          v-tap="{
            id: '1-8-4-20',
            data: { state: state }
          }"
          class="unchecked-items"
          @click="openBusinessCart({
            id: unCheckedGoodsList[0]?.id,
          })"
        >
          <div class="goods">
            <PopoverGoods
              :support-item-info="supportItemInfo"
            />
          </div>
          <div class="unchecked-tips">
            {{ supportItemInfo.tipText }}
          </div>
          <div class="pick-btn">
            {{ supportItemInfo.pickText }}
          </div>
        </div>
        <template slot="reference">
          <div
            class="business-cart-foot"
            :style="{
              ...styleConfig,
            }"
          >
            <section class="top">
              <p
                class="add-on-tips"
                v-html="tips"
              >
              </p>
              <div
                v-if="showProgress"
                class="progress"
              >
                <slot name="progress"></slot>
              </div>
            </section>
            <slot name="top"></slot>
            <section class="bottom">
              <div class="left">
                <SBadge
                  type="danger"
                  :value="cartCheckedNum"
                  :max="99"
                  :badge-style="{
                    bottom: '72%',
                    [GB_cssRight ? 'right' : 'left']: '90%',
                  }"
                >
                  <img
                    v-expose="{
                      id: '1-8-4-19',
                      data: { state: state }
                    }"
                    v-tap="{
                      id: '1-8-4-18',
                      data: { state: state }
                    }"
                    class="j-add-on-business-cart"
                    src="https://img.ltwebstatic.com/images3_acp/2024/03/15/a4/1710507571e64bf0c57e6ee9e2f3321242b1cb00f3.png"
                    width="60px"
                    @click="openBusinessCart({ clickFrom: 'non_standard_cart_icon' })"
                  />
                </SBadge>
                <div class="price">
                  <PriceCountTo
                    v-if="totalPrice.price"
                    class="price-total"
                    :style="{
                      'color': totalPrice.color,
                    }"
                    :amount="totalPrice.price.amount"
                  />
                  <p
                    v-if="showDiscountPrice"
                    class="price-saved"
                    :style="{
                      'color': savedPrice.color,
                    }"
                  >
                    <span>{{ language.SHEIN_KEY_PWA_29458 }} </span>
                    <b>
                      <PriceCountTo
                        :amount="savedPrice.price.amount"
                      />
                    </b>
                  </p>
                </div>
              </div>
              <div class="right">
                <s-button
                  :type="['H80PX', 'primary']"
                  class="go-checkout"
                  :max-width="260/75"
                  :class="{
                    'scan-lights': status > 0,
                  }"
                  @click="$emit('checkoutClick')"
                >
                  {{ language.SHEIN_KEY_PWA_27304 }}
                </s-button>
                <IncentivePoint
                  class="incentive-point"
                  :tags="tags"
                  :bubble="bubble"
                />
              </div>
            </section>
          </div>
        </template>
      </SPopover>
    </template>
  </SBusinessCart>
</template>

<script>
import { SBusinessCart } from '@shein/business-cart-bff'
import schttp from 'public/src/services/schttp'
import { Popover as SPopover, Badge as SBadge } from '@shein/sui-mobile'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'
import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const { lang, currency, appLanguage, GB_cssRight, SiteUID, currencies, RESOURCE_SDK } = gbCommonInfo
import PopoverGoods from './PopoverGoods.vue'
import PriceCountTo from 'public/src/pages/common/add-on/comps/base/PriceCountTo.vue'
import { mapState } from 'vuex'
import incentivePoint from 'public/src/pages/common/add-on/mixins/incentivePoint.js'
import { getLocalStorage } from '@shein/common-function'
import { EventViewTap } from 'public/src/pages/common/add-on/utils/event.js'

import { usePitProduct } from 'public/src/pages/common/add-on/hooks/usePitProduct.js'
import { getStaticOptionsByScene } from 'public/src/pages/components/product/quick_add_v3/optionsConfig.js'

export default {
  name: 'BusinessCartFoot',
  setup() {
    const { setPitProductsInfo } = usePitProduct()
    return {
      setPitProductsInfo,
    }
  },
  components: {
    SPopover,
    SBadge,
    SBusinessCart,
    PopoverGoods,
    PriceCountTo,
  },
  mixins: [incentivePoint],
  props: {
    type: { // 非标车类型
      type: String,
      default: 'coupon',
    },
    scene: { // 非标车场景
      type: String,
      default: 'couponAddItemPopup',
    },
    promotionId: { // 非标车ID
      type: [Number, String],
      required: true,
    },
    state: { // 场景只值
      type: String,
      default: '',
    },
    abtType: { // 样式方案(abt控制) A; B; C;
      type: String,
      default: 'A',
    },
    feedsType: { // 插坑方式 off | on_A | on_B
      type: String,
      default: '',
    },
    tips: { // 凑单提示语
      type: String,
      default: '',
    },
    status: { // 凑单状态: 0 - 全部未满足; 1 - 全部满足; 2 - 部分满足
      type: [Number, String],
      default: null,
    },
    autoUseCoupon: { // 自动用券
      type: String,
      default: '',
    },
    autoUsePoint: { // 自动用积分
      type: String,
      default: '',
    },
    theme: { // default 默认主题（优惠券非标和附属品活动非标在用） promotion 促销活动主题
      type: String,
      default: 'default',
    },
    themeVars: { // 顶替theme下的主题变量
      type: Object,
      default: () => ({})
    },
    addItemType: {
      type: [String, Number],
      default: '',
    },
    config: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      baseConfig: {
        project: 'PWA',
        schttp,
        abtservice,
        daEventCenter,
        daEventABC: '1-8-10',
      },
      locals: {
        lang,
        currency,
        appLanguage,
        GB_cssRight,
        SiteUID,
        currencies,
        RESOURCE_SDK,
      },
      cartInfo: {},
      popoverShow: false,
      isFirst: true, // 是否首次获取到非标购物车数据

      addressInfo: {},
      userLocalSizeCountry: '',
      feedsAddItems: 1,
    }
  },
  computed: {
    ...mapState(['language']),
    styleConfig() {
      if (this.abtType === 'B' && this.status > 0) {
        return {
          '--top-bg': 'url(https://img.ltwebstatic.com/images3_acp/2024/03/15/81/171050752991547ff45076a2d0685a321bb4deb987.png)',
          '--tips-color': '#FFF',
          '--tips-em-color': '#FFF',
        }
      }
      if (this.abtType === 'C') {
        return this.status > 0 ? {
          '--top-bg': 'url(https://img.ltwebstatic.com/images3_acp/2024/03/15/81/171050752991547ff45076a2d0685a321bb4deb987.png)',
          '--tips-color': '#FAE9D8',
          '--tips-em-color': '#FFF',
        } : {
          '--top-bg': 'url(https://img.ltwebstatic.com/images3_acp/2024/03/15/54/1710507546d3d8b779f3d3e1999ac919f7ff31e48e.png)',
          '--tips-color': '#000',
          '--tips-em-color': '#FA6338',
        }
      }
      return {
        '--top-bg': 'linear-gradient(180deg, rgba(255, 255, 255, 0.79) 0%, #FFF 100%)',
        '--tips-color': '#000',
        '--tips-em-color': '#FA6338',
      }
    },
    showProgress() {
      if (this.abtType === 'B') return this.status === 0
      if (this.abtType === 'C') return false
      return true
    },
    unCheckedGoodsList() {
      return this.cartInfo?.groups?.[0]?.content?.filter((v) => v.is_checked === '0') || []
    },
    supportItemInfo() {
      return this.cartInfo?.supportItemInfo || {}
    },
    cartCheckedNum () {
      return this.cartInfo?.metaInfo?.checkedCartSumQuantity || 0
    },
    totalPrice() {
      return this.cartInfo?.metaInfo?.totalPrice || {}
    },
    savedPrice() {
      return this.cartInfo?.metaInfo?.savedPrice || {}
    },
    showDiscountPrice() {
      return this.savedPrice?.price?.amount > 0
    },
    uiConfig() {
      return {
        showMorePromotion: '1', // 是否展示 more promotion 标签 '1'展示 '0' 不展示
        showFreeShip: '1', // 是否展示免邮标签 '1' 展示 '0' 不展示
        showQuickShip: '1', // 是否展示快速发货标签 '1' 展示 '0' 不展示
        showRepick: '1', // 是否展示重选按钮 '1' 当前凑单的附属品活动的已加车附属品的商品行上展示该按钮 '0' 不展示
        autoUseCoupon: this.autoUseCoupon, // 是否自动用券 '1' 中间层还需判断自动用券 abt 是否为开，开则自动用券，关则不自动用券，以防止非标车价格和下单页价格不一致 '0' 不自动使用
        autoUsePoint: this.autoUsePoint, // 是否自动用积分 '1' 中间层还需判断自动用积分 abt 是否为开，开则自动用积分，关则不自动用积分，以防止非标车价格和下单页价格不一致 '0' 不自动使用
      }
    },
    quickAddCartsStandard() {
      return {
        ...getStaticOptionsByScene()
      }
    }
  },
  created() {
    this.getAddressInfo()
    this.userLocalSizeCountry = this.getUserLocalSizeCountry()
  },
  methods: {
    getAddressInfo() {
      let addressCookie = getLocalStorage('addressCookie') || {}
      let localCache = JSON.parse(localStorage.getItem('localCache')) || {}
      this.addressInfo = {
        district: localCache?.district || '',
        state: localCache?.state || '',
        city: localCache?.city || '',
        postcode: localCache?.postcode || '',
        addressId: addressCookie?.addressId || '',
        countryId: addressCookie?.countryId || '',
      }
    },
    getUserLocalSizeCountry() {
      return typeof window === 'undefined' ? '' : (window.localStorage.getItem('last_select_country') || '')
    },
    openBusinessCart(config) { // 打开非标购物车
      EventViewTap.notify({ type: 'CLICK_BUSINESS_CART_ENTRY' })
      this.closePopover()
      this.userLocalSizeCountry = this.getUserLocalSizeCountry()
      this.feedsAddItems = config?.feedsAddItems ?? 0

      // 等待 非标的 feedsAddItems 更新
      this.$nextTick(() => {
        this.refetchCarts({ isShowSkeleton: true })
        this.$refs.cartRef?.open?.({
          id: config?.id,
          highlightAnimate: config?.highlightAnimate,
          clickFrom: config?.clickFrom,
        })
      })
    },
    refetchCarts(config) { // 刷新非标购物车
      this.closePopover() // 关闭气泡
      this.$refs.cartRef?.refetchData?.(config)
    },
    onCartUpdated(info) {
      this.cartInfo = info || {} // 更新底部车数据
      this.handlePopover() // 弹出凑单商品气泡
      this.$emit('cartUpdated', info)
    },
    handlePopover() {
      // 首次获取非标车，判断是否弹出povover
      if (!this.isFirst) return
      this.isFirst = false
      const { feedsAddItemsInfo } = this.cartInfo || {}
      this.handleExposeScenesabt(feedsAddItemsInfo)
      if (this.feedsType && this.feedsType !== 'off') {
        this.setPitProductsInfo(feedsAddItemsInfo)
        return
      }

      // 未满足最高档且有未勾选商品
      if (this.status != 1 && this.supportItemInfo?.num > 0) {
        this.popoverShow = true
        this.timer = setTimeout(() => {
          this.closePopover()
        }, 5000)
      }
    },
    handleExposeScenesabt(feedsAddItemsInfo) {
      let scenes = ''
      const len = feedsAddItemsInfo?.addItemList?.length
      if (len) {
        scenes += 'additems'
        if (len > 3) scenes += ',additems_four'
      }
      if (!scenes) return
      daEventCenterV2.triggerNotice({
        id: 'expose_scenesabt.comp_add-item',
        data: {
          type: '-',
          scenes,
        },
      })
    },
    closePopover() {
      this.popoverShow = false
      clearTimeout(this.timer)
    },
    handleRePick() {
      this.$emit('re-pick')
    }
  },
}
</script>

<style lang="less" scoped>
.business-cart-foot {
  position: relative;
  width: 100%;
  border-radius: 12px 12px 0 0;
  background-color: #fff;
}
.top {
  min-height: 40/37.5rem;
  padding: 6/37.5rem 12/37.5rem;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  &::before {
    content: ' ';
    position: absolute;
    background: var(--top-bg);
    background-size: cover;
    left: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    opacity: 0.9;
  }
  .add-on-tips {
    line-height: 14/37.5rem;
    color: var(--tips-color);
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    z-index: 1;
    // /deep/ font {
    //   font-weight: 700;
    //   font-size: 14px;
    //   color: var(--tips-em-color);
    // }
  }
  .progress {
    margin-top: 4/37.5rem;
    z-index: 1;
  }
}
.bottom {
  background: #fff;
  padding: 6/37.5rem 12/37.5rem;
  display: flex;
  align-items: center;
  .left {
    flex-grow: 1;
    margin-right: 12/37.5rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    .price {
      margin-left: 6/37.5rem;
      overflow: hidden;
    }
  }
  .right {
    position: relative;
    .go-checkout {
      padding: 0 12/37.5rem;
    }
    .incentive-point {
      position: absolute;
      right: 0;
      bottom: calc(100% - 0.05333333rem);
      z-index: @zindex-hack;
    }
  }

  .price-total {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /deep/ .price-decimal {
      font-size: 10px;
    }
    /deep/ .price-amount {
      font-size: 17px;
    }
  }
  .price-saved {
    font-size: 10px;
    line-height: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.business-cart-popover {
  display: inline-block; // 解决spopover在safari的定位问题
  width: 100%;
}
.business-cart-popover /deep/ .S-popover__main-content {
  padding: 12/37.5rem;
  .unchecked-items {
    display: flex;
    align-items: center
  }
  .unchecked-tips {
    font-size: 11px;
    padding: 0 8/37.5rem 0 4/37.5rem;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .pick-btn {
    font-size: 12px;
    padding: 2/37.5rem 14/37.5rem;
    border-radius: 12px;
    color: #fff;
    background-color: @sui_color_discount;
  }
  .goods {
    display: flex;
  }
}
</style>
