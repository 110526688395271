import { nodeAbtTestReqeust, bffAbtReqeust } from '../core/abt_fetch.js'
import abtPoskeysList from '../config/index.js'
import { getLocalStorage, setLocalStorage } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { isEqual } from 'lodash'
import { SIMetric, SILogger } from 'public/src/pages/common/monitor/index.js'

const metricCount = function (value) {
  SIMetric.metricCount({
    metric_name: 'web_abt_bff_test',
    tags: {
      status: `${value}`
    },
  })
}

function isAbtTest() {
  if (typeof window === 'undefined') return false
  if (gbCommonInfo.isDebug) return true
  const LOCAL_STORAGE_KEY = 'abtPoskeysListTest'
  const diffValue = getLocalStorage(LOCAL_STORAGE_KEY)
  const value = `${UserInfoManager.get({ key: 'memberId' }) ? '1' : '0'}_test`
  if (diffValue === value) return false // 无需再次请求
  setLocalStorage({
    key: LOCAL_STORAGE_KEY,
    value,
    expire: 1000 * 60 * 60 * 24 * 1,
  })
  return true
}

function getPoskeyList() {
  const { newPosKeys, posKeys } =  abtPoskeysList.reduce((prev, curr) => {
    if (Array.isArray(curr.newPosKeys)) {
      prev.newPosKeys.push(...curr.newPosKeys)
    }
    if (Array.isArray(curr.posKeys)) {
      prev.posKeys.push(...curr.posKeys)
    }
    return prev
  }, { newPosKeys: [], posKeys: [] })
  const randomPosKeys = (v) => v.sort(() => Math.random() - 0.5).slice(0, 50)
  return {
    newPosKeys: randomPosKeys(newPosKeys),
    posKeys: randomPosKeys(posKeys)
  }
}

function diffData(nodeData, bffData) {
  // 进行比对结果，返回的key维度进行比对：实验参数p
  const keys = Object.keys(nodeData) // 以nodeData为基准
  let matchResult = {}
  keys.forEach(key => {
    if (!nodeData[key] && !bffData[key]) return
    const nodeP = nodeData[key]?.p || ''
    const bffP = bffData[key]?.p || ''
    if (!isEqual(nodeP, bffP)) {
      matchResult[key] = {
        nodeP,
        bffP,
      }
    }
  })
  return Object.keys(matchResult).length ? matchResult : null
}

const log = {
  error(data, { bffTraceId, nodeCurl, nodeTraceId } = {}) {
    console.warn('=========ABT验证失败=======', data)
    metricCount(0)
    SILogger.logInfo({
      tag: 'abt_diff_data',
      message: 'abt_diff_data_message',
      params: {
        data,
        bffTraceId,
        nodeCurl,
        nodeTraceId
      }
    })
  },
  success(data) {
    metricCount(1)
    if (typeof gbCommonInfo !== 'undefined' && gbCommonInfo.isDebug) {
      console.info('=========ABT验证通过=======')
      SILogger.logInfo({
        tag: 'abt_diff_data_success',
        message: 'abt_diff_data_message_success',
        params: data
      })
    }
  }
}

/**
 * @description: ABT验证测试 只在两个都请求成功的情况进行比对
*/
async function bffVerifyTest() {
  if (!isAbtTest()) return 
  const { newPosKeys, posKeys } = getPoskeyList()
  try {
    const [nodeAbtResut, bffAbtResult] = await Promise.all([nodeAbtTestReqeust({ newPosKeys: newPosKeys.join(','), posKeys: posKeys.join(',') }), bffAbtReqeust(newPosKeys.join(','), posKeys.join(','))])
    const bffData = bffAbtResult?.data || {}
    const nodeData = nodeAbtResut?.data || {}
    const errData = diffData(nodeData, bffData)
    errData ? log.error(errData, { 
      bffTraceId: bffAbtResult?.originData?.extraInfo?.traceId, 
      nodeCurl: nodeAbtResut?.originData?.curlCommand,
      nodeTraceId: nodeAbtResut?.originData?.traceId
    }) : log.success({ nodeData, bffData })
  } catch(e) {
    // 
  }
}

export { bffVerifyTest }
