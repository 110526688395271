import payAgain from '../sub_modules/pay-again'

const state = {
  pageStatus: {
    msg: false,
    paymentDetailDrawer: false,
    showPaymentsDrawer: false
  }
}

const mutations = {
  changePageStatus (state, payload) {
    state.pageStatus = { ...state.pageStatus, ...payload }
  },
  handleEditPayment (state) {
    state.pageStatus.showPaymentsDrawer = true
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  modules: {
    payAgain
  }
}
