<template>
  <div
    class="checkout-discount-item"
  >
    <div class="checkout-discount-item__imgs">
      <img
        v-for="(img, inx) in child.cart_id_imgs"
        :key="inx"
        :src="transformImg({ img })"
        alt=""
      />
    </div>
    <div class="checkout-discount-item__detail">
      <span
        v-if="child.display_type == 'coupon'"
        class="checkout-discount-item__coupon"
      >
        {{ child.display_title }}
      </span>
      <span
        v-else
      >
        {{ child.display_title }}
        <SPopover
          v-model="showExplainPopover"
          show-close-icon
          trigger-type="user"
          :append-to-body="appendToBody"
          :prop-style="{
            maxWidth: '240px',
            width: 'max-content'
          }"
        >
          <Icon
            v-if="!!child.tip"
            slot="reference"
            name="sui_icon_doubt_16px_2"
            size="14px"
            class="summary-discount-item__doubt-icon"
            @click="handleShowPopover"
          />
          <div class="voucher-popover-content">
            {{ child.tip }}
          </div>
        </SPopover>
        |
      </span>
      <div
        v-if="child.display_icon"
        class="saver-icon"
      >
        <img
          :src="child.display_icon"
          alt="Tag Icon"
        />
      </div>
      <span>
        {{ child.display_price_text }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { Popover as SPopover } from '@shein/sui-mobile'
import { transformImg } from '@shein/common-function'

defineProps({
  child: {
    type: Object,
    default: () => ({}),
  },
  appendToBody: {
    type: Boolean,
    default: true,
  },
})

const showExplainPopover = ref(false)
const handleShowPopover = () => {
  showExplainPopover.value = !showExplainPopover.value
}
</script>

<style lang="less">
.checkout-discount-item {
  margin-top: 32/75rem;
  &:first-child {
    margin-top: 0;
  }

  .checkout-discount-item__imgs {
    margin-top: -16/75rem;
    img {
      width: 64/75rem;
      height: 64/75rem;
      margin-right: 16/75rem;
      margin-top: 16/75rem;
      object-fit: cover;
    }
  }

  .checkout-discount-item__coupon {
    padding: 2/75rem 8/75rem 2/75rem 20/75rem;
    margin-right: 8/75rem;
    background-repeat: no-repeat;
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/09/09/17/17258814476461505fc769f877a2d0fb323d68a6b5.png');
    background-size: 100% 100%;
    color: #FA6338;
  }

  .checkout-discount-item__detail {
    margin-top: 16/75rem;
    display: flex;
    align-items: center;

    .saver-icon {
      display: inline-block;
      height: 100%;
      padding: 0px 4px 0px 0px;
      gap: 3.5px;
      border-radius: 2px;

      img {
        height: 40/75rem;
      }
    }
  }
}
</style>
