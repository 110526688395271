<template>
  <SDrawer
    class="token-pay-drawer new-security-drawer"
    :visible.sync="isShowTokenDrawer"
    :direction="'btt'"
    :append-to-body="true"
    @close="close"
  >
    <template #top>
      <div class="header">
        <span class="header-title">{{ language.BS_KEY_PAY_1013 }}</span>
        <div
          class="all-encrypted"
          @click="showPrivacyDrawer"
        >
          <Icon
            name="sui_icon_guarantee_18px"
            size="18px"
            :is-rotate="cssRight"
          />
          <span>{{ language.BS_KEY_PAY_1014 }}</span>
          <Icon
            name="sui_icon_more_right_12px_2"
            size="12px"
            :is-rotate="cssRight"
          />
        </div>
      </div>
    </template>
    <div class="token-content">
      <div
        v-if="isShowUseNewCard"
        class="new-card-block"
        @click="useNewCard"
      >
        <div class="new-card">
          <div class="new-card-img"></div>
          <div class="new-card-text">
            {{ language.BS_KEY_PAY_1078 }}
          </div>
          <Icon
            name="sui_icon_more_right_16px"
            size="16px"
            class="left-icon"
            style="color: #959595"
            :is-rotate="cssRight"
          />
        </div>
      </div>
      <TokenList
        :language="language"
        :payment-list="paymentList"
        :is-show="isShowTokenDrawer"
        :payment-config="paymentConfig"
        @close="close"
      />
      <AllPrivacy :language="language" />
    </div>
    <SLazyMount>
      <PrivacyDrawer
        :visible.sync="isShowPrivacyDrawer"
        :language="language"
      >
        <template #privacy>
          <NewPrivacy
            :language="language"
            :logo-list="logoList"
          />
        </template>
      </PrivacyDrawer>
    </SLazyMount>
  </SDrawer>
</template>

<script name="ChooseTokenDrawer" setup lang="ts">
/**
 * 卡 token 选择抽屉
 */
import { Drawer as SDrawer, LazyMount as SLazyMount } from '@shein/sui-mobile'
import { ref, computed } from 'vue'
import TokenList from './TokenList.vue'
import type { PaymentItemProps } from '../../../../types/props'
import { Icon } from '@shein-aidc/icon-vue2'
import AllPrivacy from '../privacy/all_privacy.vue'
import NewPrivacy from '../privacy/new_privacy.vue'
import PrivacyDrawer from '../privacy/privacy_drawer.vue'
import { usePaymentsStore } from '../../../../hooks/usePayments'
import { useEditablePaymentInfo } from '../../../../hooks/useEditablePaymentInfo'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'

interface ChooseTokenDrawerProps {
    language: Record<string, string>;
    paymentList: PaymentItemProps['payments'];
    visible: boolean;
    paymentConfig?: Trade_PayToolKit.PaymentConfig;
}

const props = withDefaults(defineProps<ChooseTokenDrawerProps>(), {
    language: () => ({}),
    paymentList: () => [],
})

const emit = defineEmits(['update:visible'])

const { cssRight } = useAppConfigs()?.$envs || {}

const paymentState = usePaymentsStore()
const { handleEditableInfoChange } = useEditablePaymentInfo()

const isShowTokenDrawer = computed({
  get: () => props.visible,
  set: (val) => emit('update:visible', val),
})
const isShowPrivacyDrawer = ref(false)

const isShowUseNewCard = computed(() => paymentState?.showTokenDrawerPay === 'routepay-cardinstallment')

const logoList = computed(() => {
  return paymentState?.paySafeIconsList || []
})

const close = () => {
    isShowTokenDrawer.value = false
}

const showPrivacyDrawer = () => {
    isShowPrivacyDrawer.value = true
}

const useNewCard = () => {
  close()
  handleEditableInfoChange({
      changeInfo: {
          [paymentState?.showTokenDrawerPay]: {
              ...(paymentState?.editablePaymentInfo?.[paymentState?.showTokenDrawerPay] || {}),
              card_token_info: {},
              isUseNewCard: true,
          },
      },
      needEmitChangeEvent: true,
      extraInfo: {
        isNeedPostCheckout: true,
      },
  })
}
</script>

<style lang="less" scoped>
.header {
  line-height: 34/75rem;
  padding: 18/75rem 0 14/75rem 0;

  .header-title {
    color: #767676;
    font-size: 14px;
    font-family: SF Pro;
    font-weight: 590;
    word-wrap: break-word;
  }

  .all-encrypted {
    height: 36/75rem;
    line-height: 36/75rem;
    color: #198055;
    font-size: 12px;
    font-family: SF Pro;
    font-weight: 400;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.token-content {
  background-color: #f6f6f6;
  .new-card-block {
    background-color: white;
    margin-bottom: 8 * 2/75rem;
    padding: 12 * 2/75rem;

    .new-card {
      width: 100%;
      border-radius: 4px;
      border: 1px #999999 dotted;
      padding: 18 * 2/75rem 12 * 2/75rem;
      display: flex;
      align-items: center;
    }

    .new-card-img {
      width: 30px;
      height: 20px;
      background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/14/aa/1718352340ff2421c5efcedb6a17bb10bd997ca733.png);
      background-size: cover;
    }

    .new-card-text {
      color: black;
      font-size: 14px;
      font-family: SF UI Text;
      font-weight: 400;
      word-wrap: break-word;
      margin-left: 8 * 2/75rem;
      flex: 1;
    }
  }
}

.new-security-drawer {
  /deep/.S-drawer__header_normal {
    min-height: unset;
  }
}
</style>
