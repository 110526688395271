import { markPoint } from 'public/src/services/mark/index.js'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index.js'
import { USE_BFF_API_KEYS } from 'public/src/pages/cart_v2/utils/constants.js'

export const isNormalType = (type) => {
  return ['freeshipping', 'save', 'gift'].includes(type)
}

const typeMap = {
  'oss': 'oos',
  'mark_down': 'mark_down',
  'flash_sale': 'flash_sale',
  'coupon': 'coupon',
  'gift': 'gift',
  'order_return_coupon': 'returncoupon',
}
const typeMapBff = {
  'oss': '1',
  'mark_down': '2',
  'flash_sale': '11',
  'coupon': 'coupon',
  'gift': 'gift',
  'order_return_coupon': 'returncoupon', // 5:折扣券 6:金额券 7:免邮券 8:免邮金额抵扣券/运费券
}

export const jumpCart = async (data, appStyleControlStyle, isExpose = true) => {
  if (isExpose) {
    window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
      daEventCenter.triggerNotice({
        daId: '2-15-3',
        extraData,
      })
    })
  }
  const { type, lurePointItem } = data
  const { identify, hasChecked, cartIds, bubble } = lurePointItem || {}
  const { goodsNum } = bubble || {}

  const newJump =  ['gift', 'oos', 'flash_sale', 'mark_down'].includes(type)

  if (appStyleControlStyle === '1' && newJump) {
    if (goodsNum > 1) {
      markPoint('toNextPageClick', 'public')
      window._gb_app_.$router.push({
        path: `${window?.gbCommonInfo?.langPath ?? ''}/cart`,
        query: {
          from: 'footer_cart',
          topType: type,
        },
      })
      return
    }
    if (type === 'gift') {
      // 选择了赠品
      if (hasChecked === '1') {
        markPoint('toNextPageClick', 'public')
        // 锚定到指定赠品
        window._gb_app_.$router.push({
          name: 'cart',
          params: {
            cartId: cartIds[0]
          }
        })
      } else {
        markPoint('toNextPageClick', 'public')
        // 打开赠品选择弹窗
        window._gb_app_.$router.push({
          name: 'cart',
          params: {
            type: type,
            promotionId: identify,
          }
        })
      }
      return
    }
  }

  if (
    identify && (
      type === 'gift' && hasChecked == '0' ||
      type !== 'gift'
    )
  ) {
    markPoint('toNextPageClick', 'public')
    window._gb_app_.$router.push({
      name: 'cart',
      params: {
        type,
        identify,
      }
    })
    return
  }

  const isBff = await isSwitchBffApiVersion([USE_BFF_API_KEYS[0]])[USE_BFF_API_KEYS[0]]
  const toCartData = {
    type: isBff ? typeMapBff[type] : typeMap[type],
    products: cartIds?.join(',')
  }
  if (!toCartData.type) {
    markPoint('toNextPageClick', 'public')
    window._gb_app_.$router.push({
      name: 'cart',
    })
    return
  }
  // 单赠品不锚定+高亮
  if (toCartData.type !== 'gift' && cartIds?.length === 1) {
    markPoint('toNextPageClick', 'public')
    // 跳转滚动到购物车商品行且高亮
    window._gb_app_.$router.push({
      name: 'cart',
      params: {
        cartId: cartIds[0]
      }
    })
    return
  }
  markPoint('toNextPageClick', 'public')
  window._gb_app_.$router.push({
    name: 'cart',
    params: toCartData
  })
}
