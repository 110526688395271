<script lang="jsx">
import { SHEINCountingStarSuiicon } from 'public/src/pages/goods_detail_v2/utils/common.js'

export default {
  name: 'ProductItemStarComment',
  functional: true,
  props: {
    curData: {
      type: Object,
      default: () => ({})
    },
    showStarRating: {
      type: Boolean,
      default: false
    },
    numStyle: {
      type: Object,
      default: () => {
      }
    },
    starStyle: {
      type: Object,
      default: () => {
      }
    }
  },
  render(h, { props }) {
    const commentNum = props.curData?.starComment?.comment_num_str
    return props.curData.starComment
      ? (
        <div class="product-item__comment">
          <span
            className="comment__num"
            style={props.numStyle}>
            {commentNum}
          </span>
          <span
            class="comment__star"
            style={props.starStyle}
            domPropsInnerHTML={SHEINCountingStarSuiicon(commentNum, 12, 2)}
          ></span>
        </div>
      )
      : null
  },
}
</script>

<style lang="less" scoped>
.product-item {
  &__comment {
    .flexbox();
    .align-center();
    min-height: 0.4rem;
    height: 0.4rem;
    line-height: 0.4rem;
    margin-top: 0.1067rem;

    .suiiconfont {
      color: @sui_color_honor;
    }
    
    .comment {
      &__star {
        display: inline-block;
        width: fit-content;
        margin-left: 0.0267rem;
        color: @sui_color_honor;
        text-align: left /* rtl:left */;
      }
      
      &__num {
        color: @sui_color_gray_light1;
        opacity: 0.8;
      }
    }
  }
}
</style>
