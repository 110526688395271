<template>
  <div class="bsg-form-container">
    <SForm
      ref="cardForm"
      :model="cardFormData"
      :validate-on-rule-change="false"
    >
      <div class="bsg-form-container__hintgroup">
        <label
          for="cardNo"
          class="bsg-form-container__hintgroup_origin-label"
        >
          {{ language?.SHEIN_KEY_PWA_35110 }}
        </label>
        <input
          id="cardNo"
          v-model.trim="cardFormData.cardNo"
          class="bsg-form-container__hintgroup_origin-input"
          type="text"
          :maxlength="25"
          :autocomplete="isNeedRecordsHistory ? 'off' : 'on'"
          placeholder="0000 0000 0000 0000"
          @input="cardNoBlur"
          @focus="handleCardNoFocus"
          @blur="handleCardNoBlur"
        />
        <div
          v-show="recordsListVisible"
          v-expose="recordsExpose()"
          class="bsg-form-container__hintgroup_records-contain"
        >
          <div class="bsg-form-container__hintgroup_records-wrap">
            <Icon
              class="bsg-form-container__hintgroup_records-close"
              name="sui_icon_close_12px_2"
              size="16px"
            />
            <span class="bsg-form-container__hintgroup_records-up"></span>
            <div class="bsg-form-container__hintgroup_records-title">
              {{ language?.SHEIN_KEY_PWA_29480 }}
            </div>
            <div
              v-for="(item, index) in recordsList"
              :key="index"
              class="bsg-form-container__hintgroup_records-item"
              @click="handleRecordsClick(item)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="bsg-form-container__hintgroup bsg-form-container__mt12">
        <label
          for="cardPin"
          class="bsg-form-container__hintgroup_origin-label"
        >
          {{ language?.SHEIN_KEY_PWA_35111 }}
        </label>
        <input
          id="cardPin"
          v-model.trim="cardFormData.cardPin"
          class="bsg-form-container__hintgroup_origin-input"
          type="password"
          placeholder="0000"
          autocomplete="off"
          @input="filterNumeric"
        />
        <p
          v-show="pin.errorTip"
          class="text-error"
          v-html="pin.errorTip"
        ></p>
      </div>
      <div
        v-if="isShowSwitch"
        v-expose="swtichExpose()"
        class="bsg-form-container__mt12 bsg-form-container__swtich-container"
      >
        <span>{{ language?.SHEIN_KEY_PWA_35112 }}</span>
        <SCheckbox
          v-model="cardFormData.switchVal"
          v-tap="tapSwtichExpose()"
        />
      </div>
    </SForm>
    <SButton
      :type="['primary']"
      :loading="false"
      :disabled="!cardFormData.cardNo || !cardFormData.cardPin"
      class="bsg-form-container__btn"
      @click="submit"
    >
      {{ linkBtnTxt || language?.SHEIN_KEY_PWA_35127 || 'Link card' }}
    </SButton>

    <!-- 二次确认弹框 -->
    <SDialog
      class="bsg-form-container__dialog"
      :visible="dialogVisible"
      append-to-body
      :show-close="false"
      :close-on-click-modal="false"
      @close="dialogVisible = false"
    >
      <template #title>
        {{ language?.SHEIN_KEY_PWA_35113 }}
      </template>
      <p class="bsg-form-container__dialog_text">
        {{ language?.SHEIN_KEY_PWA_35114 }}
      </p>
      <template #footer>
        <SButton
          :type="['H72PX', 'primary']"
          :width="'100%'"
          :loading="btnLoading"
          @click="checkGiftCard"
        >
          {{ language?.SHEIN_KEY_PWA_35115 }}
        </SButton>
        <SButton
          :type="['H72PX']"
          :width="'100%'"
          class="bsg-form-container__dialog_cancel"
          @click="handleCancel"
        >
          {{ language?.SHEIN_KEY_PWA_34593 }}
        </SButton>
      </template>
    </SDialog>
  </div>
</template>

<script name="BBindGiftCard" setup lang="ts">
import { computed, ref, watch, inject } from 'vue'
import type { DS_BindGiftCard } from '../../../types'
import {
  Form as SForm,
  Button as SButton,
  Toast as SToast,
  Dialog as SDialog,
  CheckboxSwitch as SCheckbox,
} from '@shein/sui-mobile'
import useApis from '../hooks/useApis'

const {triggerNotice, vTap, vExpose} = inject('analysisInstance') as DS_BindGiftCard.AnalysisInstance

const props = defineProps<{
  linkBtnTxt?: string
  language?: any
  sence?:any
  isNeedRecordsHistory?: boolean
  giftCardList?: any
  isShowSwitch?: boolean
  visible?: boolean
}>()

const emit = defineEmits([
  'bind-card-cb',
])

const { postBindGiftCardApi } = useApis()
const cardForm = ref<InstanceType<typeof SForm> | null>(null)
const dialogVisible = ref(false)
const btnLoading = ref(false)
const isRecordsListVisible = ref(false)
const recordsList = ref<string[]>([])
const cardFormData = ref({
  cardNo: '',
  cardPin: '',
  switchVal: true,
})
const pin = ref({
  pinInfo: '',
  errorTip: '',
})

watch(() => props.visible, (n) => {
  if (!n) {
    cardFormData.value = {
      cardNo: '',
      cardPin: '',
      switchVal: true,
    }
  } else {
    recordsList.value = JSON.parse(sessionStorage?.getItem('bind-gift-card-records') || '[]') || []
  }
}, {
  immediate: true,
})

watch(() => isRecordsListVisible.value, (n) => {
  if (n) {
    filterRecordsList()
  }
}, {
  immediate: true,
})

const recordsListVisible = computed(() => {
  return (isRecordsListVisible.value && recordsList.value?.length > 0 && props.isNeedRecordsHistory) ? true : false
})

const online = computed(() => {
  return typeof navigator !== 'undefined' ? navigator.onLine : true
})

const handleRecordsClick = (item: string) => {
  isRecordsListVisible.value = true
  cardFormData.value.cardNo = item
  cardFormData.value.cardPin = ''
  props?.sence == 'checkout' && triggerNotice && triggerNotice({
    id: 'click_stored_giftcard_list.comp_bind-gift-card',
  })
}

const handleCardNoFocus = () => {
  isRecordsListVisible.value = true
}

const handleCardNoBlur = () => {
  setTimeout(() => {
    isRecordsListVisible.value = false
  }, 200)
}

const recordsExpose = () => {
  if(props?.sence == 'checkout') {
    return {
      id: 'expose_stored_giftcard_list.comp_bind-gift-card',
    }
  }
}

const swtichExpose = () => {
  if(props?.sence == 'checkout') {
    return {
      id: 'expose_bind_card_swtich.comp_bind-gift-card',
      data: {
        switch_status: cardFormData.value?.switchVal ? 'on' : 'off',
      },
    }
  }
}

const tapSwtichExpose = () => {
  return {
    id: 'click_bind_card_swtich.comp_bind-gift-card',
    data: {
      switch_status: cardFormData.value?.switchVal ? 'off' : 'on',
    },
  }
}

const cardNoBlur =() => {
  cardFormData.value.cardNo = cardFormData.value.cardNo
    .replace(/\s/g, '')
    // .replace(/(\w{4})/g, '$1 ')
    .match(/.{1,4}/g)?.join(' ') || ''
}
const filterNumeric = () => {
  cardFormData.value.cardPin = cardFormData.value?.cardPin?.replace(/[^\d]/g, '')
}

const saveRecordsList = (data) => {
  sessionStorage.setItem('bind-gift-card-records', JSON.stringify(data))
}
const submit = async function(){
  if (!online.value) {
    SToast(props.language?.SHEIN_KEY_PWA_31762)
    return
  }
  // if (!isLogin()) {
  //   this.goLogin()
  //   return
  // }
  let result = await cardForm.value?.validate()
  if(result.valid){
    pin.value.errorTip = ''
    const { cardNo = '', cardPin = '', switchVal } = cardFormData.value
    if(recordsList.value?.length >= 10) {
      recordsList.value?.pop()
    }
    if(recordsList.value.filter(item => item == cardNo)?.length <= 0) {
      recordsList.value?.unshift(cardNo)
    }
    saveRecordsList(recordsList.value)
    if((props?.isShowSwitch && !cardFormData.value?.switchVal) || (!props?.isShowSwitch && props?.sence == 'checkout')) {
      const card_no = cardNo.replace(/\s/g, '')
      emit('bind-card-cb', {
        card_no,
        card_pin: cardPin,
        isBindCard: props?.isShowSwitch ? switchVal : false,
      })

      triggerNotice && triggerNotice({
        id: 'click_card_confirm.comp_bind-gift-card',
        data: {
          use_type: 'used',
        },
      })
    } else {
      dialogVisible.value = true
      if(props?.sence == 'person') {
        triggerNotice && triggerNotice({
          id: 'expose_giftcard_link_check.comp_bind-gift-card',
        })
      }
      if(props?.sence == 'checkout') {
        triggerNotice && triggerNotice({
          id: 'click_card_confirm.comp_bind-gift-card',
          data: {
            use_type: 'bind',
          },
        })
      }
    }
  }
}

const filterRecordsList = () => {
  if(props?.giftCardList?.length > 0 && recordsList.value?.length > 0) {
    props?.giftCardList.forEach(item => {
      recordsList.value?.forEach((record:string, index) => {
        if(record?.replace(/\s/g, '') == item.card_no) {
          recordsList.value?.splice(index,1)
          saveRecordsList(recordsList.value)
        }
      })
    })
  }
}

const checkGiftCard = async function(data) {
  try {
    const {reskId = null, validate_token = null } = data
    btnLoading.value = true
    const { cardNo = '', cardPin = '', switchVal } = cardFormData.value
    const card_no = cardNo.replace(/\s/g, '')

    let params: DS_BindGiftCard.BindApiParams = {
      card_no: card_no,
      card_pin: cardPin,
      scene: props?.sence,
    }
    reskId && (params.risk_id = reskId)
    validate_token && (params.validate_token = validate_token)
    postBindGiftCardApi('/user-api/gift_card/bind', params).then((res) => {
      btnLoading.value = false
      dialogVisible.value = false
      emit('bind-card-cb', {
        resData: res,
        card_no,
        card_pin: cardPin,
        isBindCard: props?.isShowSwitch ? switchVal : false,
      })
      if(res.code == 0) {
        cardFormData.value = {
          cardNo: '',
          cardPin: '',
          switchVal: true,
        }
      }
    })
  } catch (error) {
    btnLoading.value = false
  }
}

const handleCancel = () => {
  !btnLoading.value && (dialogVisible.value = false)
}
defineExpose({
  checkGiftCard,
})
</script>
<style lang="less">
.bsg-form-container .S-Field__endblock{
  flex: 0;
}
.bsg-form-container {
  &__hintgroup {
    position: relative;
    border: 1px solid #E5E5E5;
    &_origin-label {
      display: block;
      font-size: 14px;
      padding: 11/37.5rem 12/37.5rem 0;
    }
    &_origin-input {
      display: block;
      width: 100%;
      padding: 4/37.5rem 12/37.5rem 11/37.5rem;
      border: none;
      line-height: .4533333333rem;
      font-size: 14px;
      color: #222;
    }
    &_records-contain {
      position: absolute;
      width: 184/37.5rem;
      max-height: 166/37.5rem;
      overflow-y: scroll;
      left: 0;
      top: 56/37.5rem;
      padding-top: 15/37.5rem;
      z-index: 10;
    }
    &_records-item {
      margin-top: 18/37.5rem;
      font-family: "SF UI Text";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &_records-wrap {
      padding: 12/37.5rem;
      position: relative;
      background-color: #ffffff;
      border: 2px solid #e5e5e5;
      border-radius: 2px;
      z-index: 10;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
    }
    &_records-title {
      color: #666;
      font-family: "SF UI Text";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &_records-up {
      position: absolute;
      width: 14px;
      height: 14px;
      border-style: solid;
      border-color: var(--el-border-color);
      background: #fff;
      left: 15%;
      transform: translate(-50%) rotate(-135deg);
      border-width: 0 2px 2px 0;
      color: #e5e5e5;
      top: -8px;
    }
    &_records-close {
      position: absolute;
      top: 3/37.5rem;
      right: 3/37.5rem;
    }
    &_fileld&_block {
      display: block;
    }
    &_fileld&_block::after {
      display: none;
    }
    label {
      color: #767676 !important; /* stylelint-disable-line declaration-no-important */
    }
    input {
      &::-webkit-input-placeholder {
        color: #bbbbbb;
      }
      &::-moz-placeholder {
        color: #bbbbbb;
      }
      &:-ms-input-placeholder {
        color: #bbbbbb;
      }
    }
    .text-error {
      padding: 0 0.32rem;
      color: @sui_color_unusual;
      margin-top: 16/75rem;
    }
  }
  &__mt12 {
    margin-top: 12/37.5rem;
  }
  &__swtich-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__btn {
    width: 100%;
    height: 40/37.5rem;
    margin-top: 20/37.5rem;
  }
  &__dialog {
    &_text {
      margin: 12/37.5rem 0 0;
      color: #000;
      text-align: center;
      font-family: "SF UI Text";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &_cancel {
      margin-top: 12/37.5rem;
    }
  }
}
</style>
