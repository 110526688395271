import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_SearchBox } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getSearchBoxFsDataRunner(sourceParams?: Partial<DS_SearchBox.FS_SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}

export { default, default as BSearchBox } from './search-box.vue'

export { default as DefaultSearchBox } from './components/DefaultSearchBox'
export { default as ActiveSearchBox } from './components/ActiveSearchBox'
export { default as FocusedSearchBox } from './components/FocusedSearchBox'