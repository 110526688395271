import { ref, computed, onMounted, onUnmounted } from 'vue'
import { ICON_SCROLL_DISTANCE } from 'public/src/pages/components/app_header/components/LegacyHeader/utils/const.js'
import { useProxyHosts } from '@shein-aidc/bs-sdk-libs-manager'

const ProxyHosts = useProxyHosts()
// 店铺结果页老样式 没有...,有❤️
// 店铺选品页老样式 ... 和❤️都有
// 二者新样式 ...和❤️都没有
export default function useSave({ pageScene, searchStickyInfo, isShowInput, route }) {
  const wishListEntry = ref(null)

  const isTrendPage = () => {
    const { query = {}, meta = {} } = route.value || {}
    const { search_type: searchType } = query || {}
    const { pageType } = meta || {}
    if (pageType === 'productList' && searchType === 'pageTrend') {
      return true
    }
    return false
  }

  const showSave = computed(() =>{
    if (route.value?.meta?.notNeedCommonHeader) {
      return false
    }

    // v.趋势搜索结果页屏蔽该功能
    if (isTrendPage()) {
      return false
    }

    if (searchStickyInfo.value.isSearchSticky && searchStickyInfo.value.searchSrollHeight > ICON_SCROLL_DISTANCE && isShowInput.value) {
      return false
    }
    if (['storeSearchResult', 'storeSelectionList'].includes(pageScene.value)) {
      return false
    }
    return !['goodsDetail', 'storePage'].includes(
      pageScene.value
    )
  })

  const saveIconStyle = computed(() => {
    return {
      opacity: isShowInput.value ? (searchStickyInfo.value.isSearchSticky ? (1 - searchStickyInfo.value.searchSrollHeight / ICON_SCROLL_DISTANCE) : 1) : 1,
      willChange: 'opacity',
      transition: 'opacity 0.2s ease-in-out',
    }
  })
  onMounted(async () => {
    if(ProxyHosts && ProxyHosts.CommonHeader){        
      ProxyHosts.CommonHeader.subscribe('BHeaderSave', {
        pageOnload() {
          showSave.value && wishListEntry.value?.updateRedPointStatus({ cb: ({ redpoint }) => { wishListEntry.value?.exposeReport({ redpoint }) } })
        },
      })
    }
    // appEventCenter.$on('pageOnload', () => {
    //   showSave.value && wishListEntry.value?.updateRedPointStatus({ cb: ({ redpoint }) => { wishListEntry.value?.exposeReport({ redpoint }) } })
    // })
  })
  onUnmounted(() => {
    if(ProxyHosts && ProxyHosts.CommonHeader){
      ProxyHosts.CommonHeader.describe('BHeaderSave')
    }
  })
  return {
    showSave,
    saveIconStyle,
    wishListEntry
  }
}
