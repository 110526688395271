/**
 * 模块功能: 收集首屏公共 performance 数据并上报 SLS
 */
import { SILogger } from 'public/src/pages/common/monitor/index'

/** 上报首屏性能数据至sls */
export const reportPerformance2Sls = ({ config, fspOptions }) => {
  const { siteUID = '', performanceMetricSls = {} } = config
  const { renderType = '', pageName = '' } = fspOptions
  // 1. fsp 状态检查, 进上报落地页相关数据
  if (renderType !== 'ssr' || !pageName || pageName === 'Other') {
    return
  }

  // 2. 对应站点未开启则不处理
  if (!performanceMetricSls[siteUID]) {
    return
  }

  // 3. 未命中采样则不上报 [随机数处理]
  const randomNum = Math.floor(Math.random() * 100)
  const isSupportReport = randomNum >= 0 && randomNum <= Number(performanceMetricSls[siteUID])
  if (!isSupportReport) {
    return
  }

  // 4. 上报数据处理
  const reportData = getPerformanceReportData(fspOptions)

  // 5. 上报数据
  reportData2Sls(reportData)
}

/** 获取上报数据 */
const getPerformanceReportData = (fspOptions) => {
  try {
    const { fsp, renderType, fspClass, pageName, cid, pageFrom } = fspOptions || {}
    const performanceV1 = window.performance.timing || {}
    const performanceV2 = window.performance.getEntriesByType('navigation')[0] || {}
    const performanceNavtrack = window.navtrack || {}
    const ttfb = Math.max(performanceV1.responseStart - performanceV1.navigationStart, 0)
    const ttfbLevel = getTTFBLevel(ttfb)
    const host = window.location.host
    const reffer = document.referrer

    const reportData = {
      performanceV1: JSON.stringify(performanceV1),
      performanceV2: JSON.stringify(performanceV2),
      performanceNavtrack: JSON.stringify(performanceNavtrack),
      fsp,
      ttfb,
      ttfbLevel,
      renderType,
      fspClass,
      pageName,
      host,
      reffer,
      cid,
      pageFrom
    }

    return reportData
  } catch (err) {
    return null
  }
}

/** 获取TTFB分级 */
const getTTFBLevel = (ttfb) => {
  if (ttfb >= 0 && ttfb <= 800) {
    return 1
  } else if (ttfb > 800 && ttfb <= 1500) {
    return 2
  } else if (ttfb > 1500 && ttfb <= 3000) {
    return 3
  } else {
    return 4
  }
}

/** 上报sls */
const reportData2Sls = (reportData) => {
  if (!reportData) {
    return
  }

  const { fsp, ttfb, ttfbLevel, renderType, fspClass, pageName, cid, pageFrom } = reportData
  const message = `web_performance_metric pageName: ${pageName}, fsp: ${fsp}, ttfb: ${ttfb}, ttfbLevel: ${ttfbLevel}, renderType: ${renderType}, fspClass: ${fspClass}, cid: ${cid}, pageFrom: ${pageFrom}`
  // SDK 已经加了 pageName 无需自行补充
  delete reportData.pageName
  SILogger.logInfo({
    tag: 'web_performance_metric',
    message,
    params: {
      ...reportData
    }
  }, true)
}
