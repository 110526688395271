let currentName = ''

// 监听路由变化，并把当前name保存
export function listenRouteChange(router) {
  if (!router) return
  router.beforeEach((to, from, next) => {
    currentName = to.name
    next()
  })
}

/**
 * @description: 检测是否当前页面水合， 返回一个回调函数，用于判断的是否当前页面水合
 * @param {Object} { name } 传入的name
 **/
export function listenFetchSpaSsrPromise({ name } = {}) {
  if (!name) {
    throw new Error('name is required')
  }
  return function isCurrentSpaSsr() {
    if (gbCommonInfo.isDebug) {
      console.log('currentName', currentName)
      console.log('name', name)
    }
    return currentName === name
  }
}
