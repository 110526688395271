<template>
  <div
    ref="checkoutSummaryFooterRef"
    class="j-checkout-footer checkout-summary-footer"
  >
    <ClientOnly>
      <div>
        <!-- 悬浮气泡 START -->
        <!-- 优先级：自动用券＞未使用利益点＞随手购模块提示＞卡支付气泡＞利诱点气泡＞独立收银台（app） -->

        <!-- 自动用券成功提示 -->
        <CouponAutoTips 
          v-if="(showCouponAutoTips || isResultAutoUsePoints) && !unusedBenefitSwitchisOn && !isShowPickupBuyTips"
          v-expose="{ id: '1-11-1-146' }"
          v-tap="{ id: '1-11-1-147' }"
          class="checkout-auto-coupon"
          :show-coupon-auto-tips="showCouponAutoTips"
          :is-auto-use-points="isResultAutoUsePoints"
          :auto-use-resources-price="autoUseResourcesPrice"
          @click-auto-tips="handleScrollCoupon"
        />
        
        <!-- 未使用利益点提示 -->
        <UnusedBenefitTips
          v-if="!isHidenUnusedBenefitTips && isShowUnSedBenefitTips && unuseBenefitResult.htmlstr"
          class="checkout-auto-coupon"
          :result-tips="unuseBenefitResult"
          @click-hiden-benefit="hidenBenefit"
          @click-benefit="handleBenefitClick"
        />
        
        <!-- 随手购模块 -->
        <PickupBuyTips
          v-if="isShowPickupBuyTips && !isHidenPickBuyTips"
          :tpis-text="pickupBuyTips"
          class="checkout-auto-coupon"
          @click-hiden-pickBuyTips="hidenPickBuyTips"
        />
        
        <!-- 满足利益点优先展示利益点 -->
        <PayGuideModule
          v-if="visiblePayGuide && (!isResultAutoUsePoints && !showCouponAutoTips && !isShowUnSedBenefitTips && !isShowPickupBuyTips)" 
          class="checkout-pay-guide"
          :guide-logo-list="checkoutGuideLogoList"
          :title="language.SHEIN_KEY_PWA_23556"
          @on-click="handleScrollToPayment"
        />
        <!-- 悬浮气泡 END -->

        <!-- 固定楼层 START -->
        <!-- 优先级：烟草类提示(可以与支付方式楼层共存)＞买x免y（app）＞权益楼层＞订单返券（app）＞支付方式楼层 -->

        <!-- 违禁品提示，暂时只是商品中含有烟草类的商品 -->
        <ContrabandTips v-if="isShowContrabandTips" />

        <!-- 权益楼层悬浮条 -->
        <div 
          v-else-if="rewardFloorConfig.rewardBarVisible"
          id="checkout-footer-dom"
          ref="checkoutBenefitBarRef"
          class="checkout-benefit-bar"
        ></div>

        <!-- 底部内容 - 选择支付方式 -->
        <footer-select-payment
          v-if="isFooterSelectPayment"
          ref="footerSelectPaymentRef"
          :checkout="checkout"
          :banks-config="banksConfig"
          :language="language"
          :status="status"
          :price="price"
          :locals="locals"
          :icon-prefix="iconPrefix"
          :paymentMethodsProps="paymentMethodsProps"
          :guide-logo-list="checkoutGuideLogoList"
          @payment-options-oper="handlePaymentOptionsOper"
          @create-order="handlerDrawerPayConfirm"
        >
          <SummaryCheckoutBtn
            v-if="showPaymentMethodsDialog || isShowSpecialPayBtn"
            :paypal-btn-show="paypalBtnShow"
            :paypal-bnpl-btn-show="paypalBnplBtnShow"
            :paymentMethodsProps="paymentMethodsProps"
            :paypal-venmo-btn-show="paypalVenmoBtnShow"
            :gov-tax-price="govTaxPrice"
            :is-drawer-btn="true"
            :isShowFreePriceTip="false"
            @createOrder="handleFooterSelectPayment"
            class="footer-summary-btn"
          />
        </footer-select-payment>

        <!-- 营销裂变 -->
        <s-alert
          v-if="extraPromotionTip && !isShowContrabandTips && !isFooterSelectPayment && !rewardFloorConfig.rewardBarVisible"
          type="warning"
        >
          <div v-html="extraPromotionTip"></div>
        </s-alert>

        <!-- 固定楼层 END -->

        <!-- cod支付后端强制转换货币提示 -->
        <div
          v-if="codCurrencyTip"
          class="cod-currency-tip"
        >
          <s-popover
            placemen="top-end"
            trigger-type="user"
            :value="!!codCurrencyTip"
            :append-to-body="false"
            :show-close-icon="true"
            theme="light"
            :prop-style="{'width': '9.36rem', 'left': '0', 'right': '0'}"
          >
            <em class="tips">{{ codCurrencyTip }}</em>
            <div slot="reference"></div>
          </s-popover>
        </div>
      </div>
    </ClientOnly>
   
    <div class="checkout-footer-container">
      <div 
        v-show="isShowPriceDetail || drawerVisible"
        class="checkout-footer-container__space"
      ></div>
      <!-- 底部内容（左侧内容、右侧按钮） -->
      <SummaryEffiency
        ref="footerRef"
        class="j-summary-effiency"
        :check-show-total-fee="checkShowTotalFee"
        :gov-tax-price="govTaxPrice"
        :is-show-ca-g-s-t="isShowCaGST"
        :pre-pay-un-pay-params="prePayUnPayParams"
        @totalFeeMaskTips="totalFeeMaskTips"
        @clickMaskGst="clickMaskGst"
        @toggleTotal="toggleTotal"
      >
        <s-popover
          class="checkout-activity-popover__wrap not-fsp-element"
          :class="[
            isShowNewActivityPopverLayoutByAbt ? 'checkout-activity-popover__wrap-new-layout': '',
            'checkout-activity-popover__wrap-expansion',
            {
              'checkout-activity-popover__wrap-ar': GB_cssRight
            }
          ]"
          placemen="top-end"
          :append-to-body="false"
          :show-close-icon="true"
          :value="showActivityPopover"
          trigger-type="user"
          :prop-style="{
            minWidth: '132px',
            maxWidth: isShowNewActivityPopverLayoutByAbt ? '300px' : '260px',
            background: `rgba(0, 0, 0, 0.8)`,
            top: getActivityPopoverTop
          }"
          :outside-close="false"
          @opened="openActivityPopover"
          @handleCloseIconClick="handlePopoverCloseIconClick"
          @closed="closeActivityPopover"
        >
          <ActivityPopover
            class="not-fsp-element"
            :checkout="checkout"
            :language="language"
            :activityPopoverType="activityPopoverType"
            :integrityPromotionInfo="integrityPromotionInfo"
            :promotionList="promotionList"
            :underPriceAbtValue="underPriceAbtValue"
            :flashSaleGroupList="flashSaleGroupList"
            :earliestExpiryCoupon="earliestExpiryCoupon"
            :lowestPriceBySomeDays="lowestPriceBySomeDays"
            :lowStockCartsData="lowStockCartsInfo"
            :primeClubPromotion="primeClubPromotion"
            :saverCardPromotion="saverCardPromotion"
            :bubbleCopyWritingByAbt="bubbleCopyWritingByAbt"
            :isShowNewActivityPopverLayoutByAbt="isShowNewActivityPopverLayoutByAbt"
            @handleActivityPopoverBehavior="handleActivityPopoverBehavior"
          />
          <SummaryCheckoutBtn
            slot="reference"
            ref="summaryCheckoutBtnRef"
            :paypal-btn-show="paypalBtnShow"
            :paypal-bnpl-btn-show="paypalBnplBtnShow"
            :paypal-venmo-btn-show="paypalVenmoBtnShow"
            :paymentMethodsProps="paymentMethodsProps"
            :gov-tax-price="govTaxPrice"
            @createOrder="createOrder"
            @hanldFreeShippingTipShow="(val) => isHasFreeShippingTip = val"
          />
        </s-popover>
      </SummaryEffiency>
    </div>

    <!-- 蒙层 -->
    <transition name="mask">
      <div
        v-if="isShowPriceDetail"
        class="price-detail-mask"
        :style="{ bottom: `${footerHeight}px` }"
        @click="hangeleChangePriceDetail"
      ></div>
    </transition>
    <!-- 价格订单明细抽屉 -->
    <s-drawer
      ref="priceDetailRef"
      class="price-detail-drawer j-summary-mask"
      :visible.sync="isShowPriceDetail"
      :show-close="false"
      no-header
      append-to-body
      :modal="false"
      :max-size="drawerMaxHeight"
      :style="{ bottom: `${footerHeight}px` }"
    >
      <!-- <transition name="detail"> -->
      <div>
        <s-sticky>
          <div class="price-detail-drawer__header">
            <span class="price-detail-drawer__header-text">{{ language.SHEIN_KEY_PWA_24380 }}</span>
            <i
              class="suiiconfont sui_icon_close_16px_2 price-detail-drawer__header-close"
              @click.stop="hangeleChangePriceDetail"
            ></i>
          </div>
        </s-sticky>

        <price-info
          v-if="isClient"
          type="checkout_footer"
          :pre-pay-un-pay-params="prePayUnPayParams"
        />
      </div>
    <!-- </transition> -->
    </s-drawer>
  </div>
</template>

<script>
import { useBenefitDrawer } from '@shein-aidc/bs-benefit-floor-mobile-vue2'
import ClientOnly from 'vue-client-only'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { scrollFix } from 'public/src/pages/common/utils/index.js'
import { usePaymentBenefit } from '@/public/src/pages/checkout/hooks/usePaymentBenefit.js'
import { Drawer as SDrawer } from '@shein/sui-mobile'

import { transformExtraPromotionTip } from '../../utils'

// components
import SummaryEffiency from './SummaryEffiency.vue'
import SummaryCheckoutBtn from './SummaryCheckoutBtn.vue'
import CouponAutoTips from '../coupon_auto_tips.vue'
import UnusedBenefitTips from '../unused_benefit_tips.vue'
import PickupBuyTips from '../pickup_buy_tips.vue'
import ContrabandTips from '../ContrabandTips.vue'
import ActivityPopover from 'public/src/pages/checkout/vue_tpls/activity_popover/Index.vue'
import { Popover as SPopover } from '@shein/sui-mobile'
import FooterSelectPayment from '../../page_tpls/footer_payment/footer_select_payment.vue'
import applePayConfig from 'public/src/pages/common/apple_pay/index.js'
import { handleBenefitsReflectionType, handlePromotionBenefitsType } from './tools.js'

const PAY_GUIDE_STORAGE_KEY = 'PAY_GUIDE_STORAGE_KEY_CHECKOUT'
const UNUSEDENEFIT_HISTORY_SHOW_KEY = 'UNUSEDENEFIT_HISTORY_SHOW'
const PICKBUY_HISTORY_SHOW_KEY = 'PICKBUY_HISTORY_SHOW_KEY'
daEventCenter.addSubscriber({ modulecode: '1-11-1' })
import { exposeScenesAbtAggregator } from 'public/src/pages/checkout/tools/EventAggregator.js'


const { GB_cssRight } = gbCommonInfo

export default {
  name: 'CheckoutFooter',
  components: {
    ClientOnly,
    SPopover,
    PayGuideModule: () => import('../PayGuideModule.vue'),
    SummaryEffiency,
    SummaryCheckoutBtn,
    priceInfo: () => import('../summary/PriceInfo.vue'),
    CouponAutoTips,
    ContrabandTips,
    UnusedBenefitTips,
    ActivityPopover,
    FooterSelectPayment,
    PickupBuyTips,
    SDrawer,
  },
  inject: ['showPaymentByCode'],
  props: {
    paypalBtnShow: {
      type: Boolean,
      default: false
    },
    paypalBnplBtnShow: {
      type: Boolean,
      default: false
    },
    paypalVenmoBtnShow: {
      type: Boolean,
      default: false
    },
    status: {
      type: Object,
      default: () => {}
    },
    banksConfig: {
      type: Object,
      default: () => {}
    },
    paymentMethodsProps: {
      type: Object,
      default: () => {}
    },
    isFooterSelectPayment: {
      type: Boolean,
      default: false
    },
    prePayUnPayParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visiblePayGuide: false,
      windowScrollY: 0,
      isAlreadyGuide: true,
      scroll_fix: null,
      isResultAutoUsePoints: false,
      isCloseActivityPopover: false,
      isManualOprateClosePopover: false, // 用户手动关闭的气泡
      activePopoverTimer: null, // 用于引用定时器，重新计时使用
      activityPopoverCache: [],
      alreadyExposeScene: false,
      isClient: false,
      GB_cssRight,
      summaryCheckoutBtnRef: null,
      activityPopoverType: '',
      isHasFreeShippingTip: false,
      isHidenUnusedBenefitTips: false,
      isHidenPickBuyTips: false,
      pickBuyIsCached: false,
      curBenefit: '',
      drawerMaxHeight: '80%',
      footerRef: null
    }
  },
  computed: {
    ...mapState('checkout', [
      'price',
      'language',
      'codCurrencyTip',
      'checkout',
      'primeProductList',
      'abtInfo',
      'checkoutGuideLogoList',
      'locals',
      'buyNowNo',
      'togetherBuy',
      'autoCouponInfo',
      'bestCouponInfo',
      'canInitPayGuide',
      'showShippingFeeDrawer',
      'asyncComponentLoadedStatus',
      'integrityPromotionInfo',
      'isInitPromotion',
      'promotionList',
      'xtraDiscountInfo',
      'xtraAllProductBagInfo',
      'iconPrefix',
      'isShowPriceDetail',
      'buyNowNo',
      'usedPrimeIndex',
      'usedXtraCode',
      'isShowHomogenizationDrawer',
      'showPaymentMethodsDialog',
      'footerHeight',
    ]),
    ...mapGetters('checkout', [
      'isShowSupportInfo',
      'showSaveAmount',
      'isShowTotalFee',
      'showOrderRulesByAbt',
      'showOrderBubbleSort',
      'underPriceAbtValue',
      'flashSaleGroupList',
      'earliestExpiryCoupon',
      'lowestPriceBySomeDays',
      'primeClubPromotion',
      'saverCardPromotion',
      'bubbleCopyWritingByAbt',
      'isShowNewActivityPopverLayoutByAbt',
      'lowStockCartsInfo',
      'rewardFloorConfig',
      'isShowContrabandTips'
    ]),
    isShowSpecialPayBtn() {
      return ['PayPal-GApaypal', 'PayPal-Venmo', 'PayPal-bnpl'].includes(this.status?.cardPay) || this.applePayBtnShow
    },
    SiteUID(){
      return this.locals?.SiteUID ?? gbCommonInfo.SiteUID
    },
    IS_RW() {
      return this.locals?.IS_RW ?? gbCommonInfo.IS_RW
    },
    checkShowTotalFee () {
      return this.isShowTotalFee &&
      this.price?.pay_method_gov_tax_info?.pay_method_gov_tax_rate > 0 &&
      Number(this.price?.pay_method_gov_tax_info?.pay_method_gov_tax_amount?.amount || '0') > 0
    },
    govTaxPrice () {
      return this.price?.pay_method_gov_tax_info?.pay_method_gov_tax_amount?.amountWithSymbol || 0
    },
    // 判断是否展示ca gst 提示文案
    isShowCaGST () {
      const { countryId } = this.checkout && this.checkout.default_address || {}
      return (countryId == '38' || this.SiteUID == 'mca')
    },
    extraPromotionTip () {
      const { language } = this
      return transformExtraPromotionTip({ language, extraPromotion: this.price.extraPromotion })
    },
    showCouponAutoTips() {
      return this.autoCouponInfo.show
    },
    isAutoUsePoint() {
      return this.checkout?.abtInfo?.['orderautopoints']?.param?.isAutoPoint != 0 && this.checkout?.mall_caculate_info?.pointPrice?.amount > 0
    },
    isAutoUseWallet() {
      return this.checkout?.results?.autoWalletBalanceAbt?.param?.isAuto != 0 && this.checkout?.mall_caculate_info?.walletPrice?.amount > 0
    },
    // 自动使用了积分/钱包
    isAutoUsePointsOrWallet() {
      return this.isAutoUseWallet || this.isAutoUsePoint
    },
    autoUseResourcesPrice() {
      return this.checkout?.mall_caculate_info?.auto_use_resources_price?.amountWithSymbol
    },
    // 积分
    taskPoints(){

      const isOpenTaskPoints = this.checkout?.TASK_POINTS_START == 1
      const taskPoints = isOpenTaskPoints ? this.checkout?.results.carts.cartInfo?.ug_benefit_info?.prizeValue : 0
      return !!+taskPoints ? +taskPoints : 0
    },
    // 添加对应利诱点
    activityPopoverInfo() {
      const { promotion_discount_info = [] } = this.checkout.results?.carts?.cartInfo || {}

      // 当上次命中的是超省卡、付费会员的时候，又取消掉的时候，需要关闭气泡。如果命中其他，重新唤醒
      if (this.bubbleCopyWritingByAbt && (this.activityPopoverType == 'sheinclubpromotion' && this.usedPrimeIndex == -1) || (this.activityPopoverType == 'saverpromotion' && !this.usedXtraCode)) {
        this.handleActivityPopoverBehavior(true)
      }

      return {
        promotion: !!(promotion_discount_info.find(promotion => +promotion.discount_price?.amount > 0)),
        inventory: !!this.lowStockCartsInfo.lowStockProducts.length,
        coupon: !!this.earliestExpiryCoupon,
        taskpoints: !!this.taskPoints,
        lowestprice: this.bubbleCopyWritingByAbt && !!this.lowestPriceBySomeDays?.lowestPriceData?.length,
        saverpromotion: this.bubbleCopyWritingByAbt && !!this.usedXtraCode && !!this.saverCardPromotion?.length,
        sheinclubpromotion: this.bubbleCopyWritingByAbt && this.usedPrimeIndex != -1 && !!this.primeClubPromotion?.length,
      }
    },
    activityPopoverList() {
      if(!this.isClient || !this.showOrderRulesByAbt) return []

      let popoverList = []
      Object.entries(this.activityPopoverInfo).forEach(([key, val]) => {
        if(val) popoverList.push(key)
      })

      return popoverList
    },
    isVisibleGuide() {
      return this.showCouponAutoTips || this.isResultAutoUsePoints || this.visiblePayGuide
    },
    // 判断使用原始的按钮还是气泡组件的按钮
    showActivityPopover() {
      if (!this.isClient) {
        return false
      }

      // 当用户手动关闭，并且ABT bubbleCopyWriting打开 的时候，即使 activityPopoverList 有数值，也不会展示
      const closeStatus =  this.bubbleCopyWritingByAbt ? (this.isManualOprateClosePopover || this.isCloseActivityPopover) : this.isCloseActivityPopover
      // isInitPromotion(促销信息异步请求，气泡展示抖动)
      return !!this.activityPopoverList.length &&
        !closeStatus &&
        !this.isVisibleGuide &&
        this.isInitPromotion &&
        !this.isShowUnSedBenefitTips &&// 添加利益气泡不展示的时候再展示
        !!this.activityPopoverType
    },
    xtraAllProductBagData(){
      return Object.values(this.xtraAllProductBagInfo) || []
    },
    unuseBenefitResult() {
      
      if (!this.isClient || this.recommendPaymentInfoisLoading) {
        return {
          htmlstr: '',
          benefits_type: '',
          curShowBenefitsType: ''
        }
      }
      let htmlstr = ''
      let benefits_type = ''
      let curShowBenefitsType = ''
      const filterShowedBenefit = this.getUnusedBenefitCache()
      
      const getUnusedPoints = () => {
        const maxAvailablePoint = this.checkout?.mall_caculate_info?.maxAvailablePoint
        const APOLLO_UNUSED_BUBBLE = this.checkout?.APOLLO_UNUSED_BUBBLE
        if(this.isUnusedPointsOn && Number(maxAvailablePoint) >= APOLLO_UNUSED_BUBBLE){
          benefits_type += 'UnusedPoints、' // 满足利益点
         
          if(!htmlstr && !filterShowedBenefit.includes('UnusedPoints')) { // 优先级高的才展示气泡
            curShowBenefitsType = 'UnusedPoints'
            htmlstr = this.template(maxAvailablePoint, this.checkout?.mall_caculate_info?.maxAvailablePointPrice?.amountWithSymbol, this.language.SHEIN_KEY_PWA_33346 )
          }


        }
      }
      // 付费会员优惠
      const getSheinClubPromotion = () => {
        const sheinClubPromotion = Number(this.checkout?.abtInfo?.SHEINClubProportion?.p?.SHEINClubProportion)
        // 付费会员节省金额 
        const discountPrice = Number(this.checkout?.mall_caculate_info?.prime_trial_total_discount_price?.amount)
        // 付费会员包费用
        const numberSpecialPrice = this.primeProductList[0]?.product_price_info?.special_price
        const sheinClubPromotionRate = discountPrice / Number(numberSpecialPrice)
        const showSHEINClubPromotion =  sheinClubPromotionRate >= sheinClubPromotion

        
        if(showSHEINClubPromotion && (discountPrice !== 0 && Number(numberSpecialPrice) !== 0) ) {
          benefits_type += 'SHEINClubPromotion、' // 满足利益点

          if(!htmlstr && !filterShowedBenefit.includes('SHEINClubPromotion')){ // 优先级高 展示气泡
            curShowBenefitsType = 'SHEINClubPromotion'
            // 是否回本
            const isCostRecouped = discountPrice >= Number(numberSpecialPrice) 
            // 是否有预算返现金额
            const hasRewardPrice = Number(this.checkout?.mall_caculate_info?.prime_trial_credit_reward_price?.amount) > 0
            let sheinClubKey = ''
            if(isCostRecouped){
              sheinClubKey = this.language.SHEIN_KEY_PWA_33347 // 回本并且没有预算返现金额
              if(hasRewardPrice){
                sheinClubKey = this.language.SHEIN_KEY_PWA_33558
              }
            }else{
              sheinClubKey = this.language.SHEIN_KEY_PWA_33348 // 没回本且没有预算返现金额
              if(hasRewardPrice){
                sheinClubKey = this.language.SHEIN_KEY_PWA_33559
              }
            }
            htmlstr = this.template(this.checkout?.mall_caculate_info?.prime_trial_total_discount_price?.amountWithSymbol, sheinClubKey )
          }

        }
      }
      // 超省卡优惠 
      const getSaverPromotion = () => {
        const saverProportion = Number(this.checkout?.abtInfo?.SaverProportion?.p?.SaverProportion)
        const isShowAutoRenewBag = this.checkout?.abtInfo?.SheinSaverRenew?.param?.SheinSaverRenewStatus == 1
        // 超省卡节省金额
        const totalDiscount = Number(this.xtraDiscountInfo?.total_discount?.amount)
        // 第一个超省卡的包
        let firstXtraBagInfo = {}
        if (isShowAutoRenewBag) {
          firstXtraBagInfo = this.xtraAllProductBagData?.find(_ => _.productInfo?.product_activity_type == 1) || {}
        }
        if (!isShowAutoRenewBag || !Object.keys(firstXtraBagInfo)?.length) {
          firstXtraBagInfo = this.xtraAllProductBagData?.find(_ => _.productInfo?.product_activity_type == 0) || {}
        }

        const xtraAllProductBagSpecialPrice = Number(firstXtraBagInfo?.productInfo?.product_price_info?.special_price)
        const saverProportionRate = totalDiscount / xtraAllProductBagSpecialPrice
        const showSaverPromotion =  saverProportionRate >= saverProportion

        if(showSaverPromotion && (totalDiscount !== 0 && xtraAllProductBagSpecialPrice !== 0)){
          benefits_type += 'SaverPromotion、' // 满足利益点

          if(!htmlstr && !filterShowedBenefit.includes('SaverPromotion')){ // 优先级最高 展示气泡
            curShowBenefitsType = 'SaverPromotion'
            htmlstr =  this.template(this.xtraDiscountInfo?.total_discount?.amountWithSymbol, totalDiscount >= xtraAllProductBagSpecialPrice ? this.language.SHEIN_KEY_PWA_33349 : this.language.SHEIN_KEY_PWA_33350 )
          }
        }
      }
     
      const getPaymentInstallment = () => {
        const grandTotalPrice = this.price?.grandTotalPrice?.amount

        if(this.recommendPaymentInfo !== null && Number(grandTotalPrice) >= Number(this.checkout?.APOLLO_HIGH_UNIT_PRICE) ){

          benefits_type += 'PaymentInstallment、' // 满足利益点
          const logo_url =  this.recommendPaymentInfo?.paymentInfo?.logo_url
          const { isFreeInstallment, isTokenPay, amountPerTerm, cardToKenInfo, stage } = this.recommendPaymentInfo || {}
          const imgStr = logo_url ? `<img style='width:0.64rem;height:0.426rem;margin-left:0.1066rem;' src="${logo_url}" />` : ''
          if(!htmlstr && !filterShowedBenefit.includes('PaymentInstallment')){ // 优先级高展示气泡

            curShowBenefitsType = 'PaymentInstallment'

            // 免息
            if(isFreeInstallment){
              if(!isTokenPay){
                htmlstr = `<div>${this.template(stage, amountPerTerm, this.language.SHEIN_KEY_PWA_33361)}${imgStr}</div>`
              }else{
                htmlstr = `<div>${this.template(stage, amountPerTerm, cardToKenInfo?.card_no || '', this.language.SHEIN_KEY_PWA_33345)} </div>`
              }

            }else{
              if(!isTokenPay){
                htmlstr = `<div>${this.template(amountPerTerm, this.language.SHEIN_KEY_PWA_33360)}${imgStr}</div>`
              }else{
                htmlstr = `<div>${this.template(amountPerTerm, cardToKenInfo?.card_no || '', this.language.SHEIN_KEY_PWA_33344)}</div>`
              }
            }
          }
        }
      }
      
      this.showBenefitAbtKey.map((benefitKey) => { 
        if(benefitKey === 'UnusedPoints'){
          
          getUnusedPoints()
        }
        if(benefitKey === 'SHEINClubPromotion'){
          getSheinClubPromotion()
        }
        if(benefitKey === 'SaverPromotion'){
          getSaverPromotion()
        }
        if(benefitKey === 'PaymentInstallment'){
          getPaymentInstallment()
        }
      })
     
      if(benefits_type) {
        benefits_type = benefits_type.slice(0, -1) // 去掉最后一个多余的、上报埋点参数
      }
      return {
        htmlstr,
        benefits_type,
        curShowBenefitsType
      }
    },
    showBenefitAbtKey(){
  
      return this.checkout?.abtInfo?.UnusedBenefit?.p?.UnusedBenefit?.split(',')?.filter(key => ['PaymentInstallment', 'UnusedPoints', 'SHEINClubPromotion', 'SaverPromotion'].includes(key)) || []
    },
   
    isManualUse(){
      return this.checkout?.abtInfo?.UesPointsStyle?.p?.UesPointsStyle === 'manualUse'
    },
    isAutomaticUse(){
      return this.checkout?.abtInfo?.UesPointsStyle?.p?.UesPointsStyle === 'automaticUse'
    },
    isUnusedPointsOn(){
      return  this.checkout?.abtInfo?.orderautopoints?.p?.isAutoPoint === '0'
    },
    unusedBenefitSwitchisOn(){
      return this.checkout?.abtInfo?.UnusedBenefit?.p?.UnusedBenefitSwitch === 'on'
    },  
    
    isShowUnSedBenefitTips(){
      return this.unusedBenefitSwitchisOn && this.unuseBenefitResult.curShowBenefitsType !== ''
    },
    // 随手购abt 打开
    pickupBuyAbtIsOn(){
      return this.checkout?.abtInfo?.UnusedBenefit?.p?.UnusedBenefit_PickupBuy === 'on'
    },
    // 是否展示随手购模块
    isShowTogetherBuy(){
      if (this.checkout?.abtInfo?.NewUserCasualBuy?.p?.NewUserCasualBuy == 'new') {
        return false
      }
      const togetherBuyLocation = !this.IS_RW && !!+this.togetherBuy?.togetherBuyType
        ? +this.togetherBuy?.OrderGoodsLocation
        : 0 || 0
      return   togetherBuyLocation && (!!this.buyNowNo ? this.checkout.CHECKOUT_SWITCH_BUY_TOGETHER_NOW == 'on' : true)
    },
    // 是否展示随手购气泡
    isShowPickupBuyTips(){
      if(typeof window == 'undefined') return false
      // 并且有随手购模块
      const { recommendProducts = [] } = this.togetherBuy || {}
      
      const isShowTogetherBuy =  this.isShowTogetherBuy && recommendProducts.length
      // 利益点气泡没展示 && 展示了随手购模块
      const res = !this.recommendPaymentInfoisLoading && !this.isShowUnSedBenefitTips &&  isShowTogetherBuy && this.pickupBuyAbtIsOn && (!Boolean(this.getPickBuyCache()) || this.pickBuyIsCached)
      
      return res
    },
    // 随手购展示内容
    pickupBuyTips(){
      const { recommendProducts = [] } = this.togetherBuy || {}
      if(recommendProducts.length){
        return this.template( recommendProducts[0].salePrice?.amountWithSymbol, this.language.SHEIN_KEY_PWA_33351)
      }
      return ''
    },
    isShowFooterSelectPaymentByAbt() {
      if (this.paymentMethodsProps?.nowPageType !== 1) return false
      const { orderpaylistbottomdefault = {}, orderpaylistbottomnodefault = {} } = this.checkout?.abtInfo || {}
      return this.paymentMethodsProps?.nowPageType === 1 && (
        orderpaylistbottomdefault?.param?.orderpaylistbottomstatus == 1 ||
        orderpaylistbottomnodefault?.param?.orderpaylistbottomnodefaultstatus === 'bottom'
      )
    },
    getActivityPopoverTop() {
      // 没有使用bottom（110/75）的原因是：当左侧税费换行的时候，会出现右侧按钮上移，然后覆盖气泡
      if (this.isHasFreeShippingTip) {
        return this.isShowNewActivityPopverLayoutByAbt ? `${-146 / 75}rem` : `${-170 / 75}rem`
      }
      return this.isShowNewActivityPopverLayoutByAbt ? `${-132 / 75}rem` : `${-156 / 75}rem`
    },
    applePayMethods () {
      if (this.checkout?.APPLE_PAY_CODE_LIST?.length) return this.checkout.APPLE_PAY_CODE_LIST
      return applePayConfig?.applePayChannel || []
    },
    applePayBtnShow() {
      const apolloInfo = this.applePayMethods || []
      const applepayDropoffOptimizationAbt = this.checkout?.abtInfo?.ApplepayDropoffOptimization?.p?.ApplepayButtonShow === 'Show'
      if(apolloInfo?.includes(this.status?.cardPay) && applepayDropoffOptimizationAbt && this.isClient){
        return true
      }
      return false
    },
  },
  watch: {
    isShowPriceDetail: {
      handler(v) {
        if(typeof window == 'undefined') return
        if(v) {
          $('.j-uptt').css('visibility', 'hidden')
        } else {
          $('.j-uptt').css('visibility', 'visible')
          $('.j-uptt').css('z-index', 10)
        }
        this.scroll_fix?.(v)
      },
      immediate: true
    },
    showShippingFeeDrawer: {
      handler(v) {
        if(v) {
          this.assignState({ isShowPriceDetail: false })
          this.scroll_fix?.(false)
        }
      },
      immediate: true
    },
    showCouponAutoTips: {
      handler(v) {
        if(v) this.initCouponTipsStatus()
      },
      immediate: true
    },
    showCouponUseGuideTips: {
      handler(v) {
        if(v) this.initCouponGuideUse()
      },
      immediate: true
    },
    canInitPayGuide: {
      handler(v) {
        if(v) this.initPayGuideStatus()
      },
      immediate: true
    },
    activityPopoverList: {
      handler(newVal, oldVal) {
        if(!!newVal?.length && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.getActivityPopoverCache()
          // this.handleActivityPopoverBehavior()
        }
      },
      deep: true
    },
    activityPopoverInfo: {
      handler(newVal, oldVal) {
        if(newVal && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          
          this.handleActivityPopoverType()
          this.exposeScene()
        }
      },
      deep: true,
      immediate: true
    },
    unuseBenefitResult: {
      handler(newVal, oldVal) {
        if(typeof window == 'undefined') return 
        
        if(this.recommendPaymentInfoisLoading) return // recommendPaymentInfo 结果还没回来
        this.setAlreadyGuide()
        this.initPayGuideStatus()
        this.setPickBuyCache()
        if(this.curBenefit == '' && JSON.stringify(newVal) !== JSON.stringify(oldVal)){
          this.curBenefit = newVal.curShowBenefitsType
          this.setUnusedBenefitCache(newVal.curShowBenefitsType)
          
          exposeScenesAbtAggregator.addEvent({
            daId: '1-11-1-172',
            extraData: {
              scenes: 'unusedBenefits',
              benefits_type: newVal.benefits_type,
            }
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  setup(){
    const { recommendPaymentInfo, isLoading  } = usePaymentBenefit()
    const { closeDrawer, drawerVisible } = useBenefitDrawer()

    return {
      recommendPaymentInfo,
      recommendPaymentInfoisLoading: isLoading,
      closeDrawer,
      drawerVisible,
    }
  },
  mounted () {
    this.isClient = true
    this.scroll_fix = scrollFix()
    this.observeFooter()
   

    // 需要设置底部区域宽度，因为是固定定位，不设置宽度会出现底部区域超出主体内容的范围
    const containerWidth = document.body.clientWidth || document.documentElement.clientWidth
    this.$refs.checkoutSummaryFooterRef.style.width = `${containerWidth}px` || '100%'

    if (this.isAutoUsePointsOrWallet) {
      this.isResultAutoUsePoints = true

      window.addEventListener('scroll', this.pageScrollListener)
    }

    document.addEventListener('click', () => {
      this.isResultAutoUsePoints = false

    })

    this.getActivityPopoverCache()
    if(!!this.activityPopoverCache.length) this.isCloseActivityPopover = true

    window?.appEventCenter.$on('applepay-create-order', () => {
      this.createOrder()
    })

    window?.appEventCenter?.$on('capitecBankSaved', () => {
      if(!this.isShowHomogenizationDrawer) {
        this.createOrder()
      }
    })
    this.$nextTick(() => {
      const element = this.$refs.checkoutBenefitBarRef
      if (element) {
        this.$emit('onload-benefit-bar', true)
      }
    })
   
  },
 
  methods: {
    ...mapMutations('checkout', ['showMaskTips', 'changeParamsStatus', 'assignState']),
    ...mapActions('checkout', ['orignMallCacuTotal', 'mallCacuTotal']),
    observeFooter() {
      this.$nextTick(() => {
        const footer = this.$refs.footerRef?.$el
        if (ResizeObserver && footer) {
          const ro = new ResizeObserver(entries => {
            for (let entry of entries) {
              if (entry.target === footer) {
                const footerH = footer.getBoundingClientRect().height || 56
                const spaceH = (parseFloat(window.document?.documentElement?.style?.fontSize) || 37.5) * (16 / 75)
                const windowHeight = window.innerHeight || 0
                const maxHeight = windowHeight * 0.8
                const footerTotalHeight = footerH + spaceH
                this.drawerMaxHeight = `${maxHeight - footerTotalHeight}px`
                this.assignState({ footerHeight: footerTotalHeight })
                break
              }
            }
          })
          ro.observe(footer)
          this.ro = ro
        }
      })
    },
    hangeleChangePriceDetail() {
      this.assignState({ isShowPriceDetail: !this.isShowPriceDetail })
    },
    handleActivityPopoverType() {
      if (this.bubbleCopyWritingByAbt && this.isManualOprateClosePopover) {
        return
      }

      let type = ''

      // 当超省卡、付费会员满足展示条件，优先级最高
      if (this.activityPopoverInfo.sheinclubpromotion && this.showOrderBubbleSort.includes('sheinclubpromotion') && !this.activityPopoverCache.includes('sheinclubpromotion')) {
        type = 'sheinclubpromotion'
      } else if (this.activityPopoverInfo.saverpromotion && this.showOrderBubbleSort.includes('saverpromotion') && !this.activityPopoverCache.includes('saverpromotion')) {
        type = 'saverpromotion'
      } else{
        let popoverList = []
        Object.entries(this.activityPopoverInfo).forEach(([key, val]) => {
          if (val && (!this.bubbleCopyWritingByAbt || (this.bubbleCopyWritingByAbt && !this.activityPopoverCache.includes(key)))) {
            popoverList.push(key)
          }
        })

        // 当同时满足多个利益点，则按 abt 优先级进行展示
        type = this.showOrderBubbleSort.find(value => popoverList.includes(value)) || ''
      }

      if (this.bubbleCopyWritingByAbt) {
        if (this.showActivityPopover) {
          // 当前气泡是其他的，命中超省卡、付费会员的时候，需要关闭气泡，重新打开
          if(['sheinclubpromotion', 'saverpromotion'].includes(type)) {
            this.handleActivityPopoverBehavior(true)
          } else if (!['sheinclubpromotion', 'saverpromotion'].includes(type)) {
            return
          }
        }
      }
      this.activityPopoverType = type
      this.$nextTick(() => {
        this.handleActivityPopoverBehavior()
      })
    },
    exposeScene() {
      if(typeof window == 'undefined') return
      if(this.alreadyExposeScene) return

      if(!!this.activityPopoverType) {
        exposeScenesAbtAggregator.addEvent({
          daId: '1-11-1-172',
          extraData: {
            scenes: 'placeorderBenefits',
            benefits_type: handleBenefitsReflectionType(this.activityPopoverType),
          }
        })
        this.alreadyExposeScene = true
      }
    },
    setActivityPopoverCache() {
      let popoverList = typeof sessionStorage.getItem('checkout_activity_popover') === 'string' ? JSON.parse(sessionStorage.getItem('checkout_activity_popover')) : []
      if(!!this.activityPopoverType && !popoverList.includes(this.activityPopoverType)) {
        popoverList.push(this.activityPopoverType)
        sessionStorage.setItem('checkout_activity_popover', JSON.stringify(popoverList))
        this.activityPopoverCache = popoverList
      }
    },
    getActivityPopoverCache() {
      if(typeof window == 'undefined') return

      let popoverList = typeof sessionStorage.getItem('checkout_activity_popover') === 'string' ? JSON.parse(sessionStorage.getItem('checkout_activity_popover')) : []
      this.activityPopoverCache = popoverList
    },
    getUnusedBenefitCache(){
      let list = []
      if(typeof window == 'undefined') return list
      const unuseBenefitHistroyShow = sessionStorage.getItem(UNUSEDENEFIT_HISTORY_SHOW_KEY)
      try{
        list = (unuseBenefitHistroyShow && typeof unuseBenefitHistroyShow === 'string') ? JSON.parse(unuseBenefitHistroyShow) : []
      }finally{
        return list
      }
    },
    setUnusedBenefitCache(key){
      if(typeof window == 'undefined') return
      const list = this.getUnusedBenefitCache()
      if(key !== '' && !list.includes(key)){
        list.push(key)
      }
      sessionStorage.setItem(UNUSEDENEFIT_HISTORY_SHOW_KEY, JSON.stringify(list))
    },
    getPickBuyCache(){
      if(typeof window == 'undefined') return 
      return sessionStorage.getItem(PICKBUY_HISTORY_SHOW_KEY)
    },
    setPickBuyCache(){
      if(typeof window == 'undefined') return
      // 如果展示了随手购模块则设置sessionStorage
      if(this.isShowPickupBuyTips){
        this.pickBuyIsCached = true
        sessionStorage.setItem(PICKBUY_HISTORY_SHOW_KEY, 1)
      }
    },
    /**
     * @description: 控制气泡是否是打开还是关闭
     * @param {Boolean} flag 标志位，用于及时关闭气泡，清除定时器
     * @return {*}
     */
    handleActivityPopoverBehavior(flag = false) {
      if (flag) {
        this.isCloseActivityPopover = true
        clearTimeout(this.activePopoverTimer)
        return
      }
      if (!!this.activityPopoverCache.length && !this.activityPopoverCache.includes(this.activityPopoverType)) {
        if (this.bubbleCopyWritingByAbt) {
          this.isCloseActivityPopover = false
        } else {
          this.isCloseActivityPopover = true
        }
      }
    },
    openActivityPopover() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-188',
        extraData: {
          benefits_type: handleBenefitsReflectionType(this.activityPopoverType),
          promotiontype: handlePromotionBenefitsType({
            activityPopoverType: this.activityPopoverType,
            integrityPromotionInfo: this.integrityPromotionInfo,
            promotionList: this.promotionList
          })
        }
      })

      this.setActivityPopoverCache()


      const { CHECKOUT_ACTIVITY_POPOVER_TIME = 8 } = this.checkout || {}
      this.activePopoverTimer = setTimeout(() => {
        this.isCloseActivityPopover = true
      }, +CHECKOUT_ACTIVITY_POPOVER_TIME * 1000)
    },
    /**
     * @description: 手动关闭气泡
     * @return {*}
     */
    handlePopoverCloseIconClick() {
      if (this.bubbleCopyWritingByAbt) {
        this.isManualOprateClosePopover = true
      }
      this.closeActivityPopover()
    },
    closeActivityPopover() {
      this.isCloseActivityPopover = true
      daEventCenter.triggerNotice({
        daId: '1-11-1-189',
      })
    },
    // 注意！！！此处不要有任何return事件，统一收口至最尾节点处理！！！下单按钮点击事件需要上报埋点及监控！！！
    createOrder() {
      setTimeout(() => this.closeDrawer(), 600)
      this.assignState({ isShowPriceDetail: false })
      this.$emit('create-order')
      this.scroll_fix(false)
    },
    clickMaskGst() {
      this.showMaskTips(this.language.SHEIN_KEY_PWA_20400)
    },
    totalFeeMaskTips () {
      this.showMaskTips(this.language.SHEIN_KEY_PWA_18954)
    },
    pageScrollListener () {
      this.windowScrollY = window.scrollY
      window.requestAnimationFrame(() => {
        if (this.windowScrollY > 15) {
          this.isAlreadyGuide = 1
          this.visiblePayGuide = false

          this.changeParamsStatus({
            type: 'autoCouponInfo',
            params: {
              show: false,
            }
          })
          this.isResultAutoUsePoints = false

          this.changeParamsStatus({
            type: 'bestCouponInfo',
            params: {
              show: false
            }
          })
          window.removeEventListener('scroll', this.pageScrollListener)
        }
      })
    },
    // 滚动到支付方式列表
    handleScrollToPayment () {
      const topH = document.querySelector('.payment-options-container').offsetTop
      const headH = document.querySelector('#checkoutHeader').clientHeight
      window.scrollTo({
        top: topH - headH,
        behavior: 'smooth',
      })
    },
    initPayGuideStatus () {
      if(typeof window == 'undefined') return

      const { checkout = {}, price = {}, isShowSupportInfo } = this
      if (!Object.keys(checkout).length || !Object.keys(price).length || !isShowSupportInfo) return
     
      
      // 阿波罗配置 - 是否需要隐藏引导悬浮窗
      if (gbCommonInfo?.HIDDEN_PAY_GUIDE_TIPS) return
     
      this.$nextTick(() => {
        // 判断支付方式列表是否在窗口中露出，未露出才需要提示
        // 支付方式列表dom距离顶部距离
        const paymentMethodsContainerTop = document.querySelector('.mcheo-payment__methods')?.offsetTop || 0
        // 底部按钮距离顶部距离
        const summaryFooterTop = document.querySelector('.checkout-summary-footer')?.offsetTop || 0
       
       
        if (paymentMethodsContainerTop > summaryFooterTop) {
          if (!this.isAlreadyGuide && !this.visiblePayGuide) {
           
            this.visiblePayGuide = true
            this.windowScrollY = 0
            window.addEventListener('scroll', this.pageScrollListener)
          }
        }
      })
    },
    toggleTotal() {
      this.assignState({ isShowPriceDetail: !this.isShowPriceDetail })
    },
    initCouponGuideUse() {
      if(this.bestCouponInfo.alreadyShowed) return

      this.changeParamsStatus({
        type: 'bestCouponInfo',
        params: {
          alreadyShowed: true
        }
      })
      if(typeof window == 'undefined') return
      window.addEventListener('scroll', this.pageScrollListener)
    },
    initCouponTipsStatus() {
      if(this.autoCouponInfo.alreadyShowed) return

      this.changeParamsStatus({
        type: 'autoCouponInfo',
        params: {
          alreadyShowed: true
        }
      })

      if(typeof window == 'undefined') return
      window.addEventListener('scroll', this.pageScrollListener)
    },
    hidenBenefit() {
      this.isHidenUnusedBenefitTips = true
    },
    hidenPickBuyTips(){
      this.isHidenPickBuyTips = true
    },
    // 滚动到券列表入口
    handleScrollCoupon() {
      const topH = document.querySelector('.checkout-coupon__entry')?.offsetTop || 0
      const headH = document.querySelector('#checkoutHeader')?.clientHeight || 0
      const noticeH = document.querySelector('.checkout-notice')?.clientHeight || 0
      window.scrollTo({
        top: topH - (headH + noticeH),
        behavior: 'smooth',
      })
    },
    
    handlePaymentOptionsOper(data) {
      this.$emit('payment-options-oper', data)
    },
    handlerDrawerPayConfirm () {
      this.$refs?.summaryCheckoutBtnRef?.handlerClick()
    },
    handleFooterSelectPayment(evt) {
      this.$refs?.footerSelectPaymentRef?.handlePaymentSelectConfirm?.(evt)
    },
    handleBenefitClick() {
      const headH = document.querySelector('#checkoutHeader')?.clientHeight || 0
      const noticeH = document.querySelector('.checkout-notice')?.clientHeight || 0
      let targetTop = 0
      this.isHidenUnusedBenefitTips = true
      
      if(this.unuseBenefitResult.curShowBenefitsType === 'PaymentInstallment'){
        
        this.showPaymentByCode(this.recommendPaymentInfo?.paymentInfo?.code)

      }
      if(this.unuseBenefitResult.curShowBenefitsType === 'SHEINClubPromotion'){

        targetTop = document.querySelector('.checkout-prime-new__wrap')?.offsetTop
      }
      if(this.unuseBenefitResult.curShowBenefitsType === 'SaverPromotion'){

        targetTop = document.querySelector('.checkout-xtra__wrap')?.offsetTop
      } 
      // 手购气泡实验开始
      if(this.unuseBenefitResult.curShowBenefitsType === 'UnusedPoints' || this.pickupBuyAbtIsOn) {
        
        if(this.isManualUse){ // 手动
        // 打开积分使用弹框，自动填入最高可以使用积分
          this.changeParamsStatus({ type: 'pageStatus', params: { showPointInputPanel: true, autoFillMaxAvailablePoint: true } })
        }
        if(this.isAutomaticUse){ // 自动
          // 点击后自动使用，调用计价接口使用积分，积分使用成功后toast提示

          

          shem_caculate_data.auto_use_point = this.checkout?.abtInfo?.orderautopoints?.p?.isAutoPoint === '0' ? 1 : 0
          shem_caculate_data.auto_use_point_threshold = this.checkout?.abtInfo?.orderautopoints?.p?.isAutoPoint?.split('_')?.[1] || '0'
         
          const maxAvailablePoint = Number(this.checkout?.mall_caculate_info?.maxAvailablePoint) || 0
          this.mallCacuTotal({
            opt: {
              ...window?.shem_caculate_data,
              points: maxAvailablePoint,
              type: 'points',
            },
            cb: (res) => {
              if(res?.code == 0 && Number(this.price?.pointPrice?.amount) > 0 ) {
                this.$toast(this.template(this.language?.SHEIN_KEY_PWA_16456), 3000)
              }

            }
          })
        }
        return false
      }
      window.scrollTo({
        top: targetTop - (headH + noticeH),
        behavior: 'smooth',
      })
    },
    /**
     *  @desc 利益点气泡优先级比支付浮窗高,等利异步请求益点数据回来在执行支付浮窗
     * 
     * **/
    setAlreadyGuide(){

      if(this.unuseBenefitResult.curShowBenefitsType !== ''){
        return
      }
      this.isAlreadyGuide = !!window.localStorage?.getItem?.(PAY_GUIDE_STORAGE_KEY)
      // 下单页支付方式楼层置底展示, 则线上的辅助决策信息将全部干掉
      if (!this.isAlreadyGuide && window.localStorage?.setItem && !this.isShowFooterSelectPaymentByAbt) {
        window.localStorage.setItem(PAY_GUIDE_STORAGE_KEY, 1)
      } 
    }
  }
}
</script>

<style lang="less" scoped>
.checkout-auto-coupon {
  margin: 0 0.32rem;
}
.checkout-pay-guide {
  margin: 0 0.32rem;
}

.checkout-summary-footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	border-top: 1px solid #e5e5e5;
	box-shadow: 0 -0.1rem 0.1rem rgba(0,0,0,.06);
	z-index: @zindex-attrmenusize;

  .checkout-benefit-bar {
    background-color: #fff;
    padding-bottom: 16/75rem;
  }

	.cod-currency-tip {
		width: 100%;
		position: absolute;
		top: 0;
		.left(0);
    transform: translateY(-100%);
		padding: 0 0.32rem;
		margin-top: -0.13333rem;
	}

  .checkout-footer-container {
    position: relative;

    &__space {
      background-color: #fff;
      width: 100%;
      height: 16/75rem;
      position: absolute;
      top: -16/75rem;
    }
  }
}
// 订单明细抽屉内容样式
.price-detail-drawer {
  /deep/ .S-drawer__container {
    box-shadow: none;
  }
  // position: fixed;
  // left: 0;
  // width: 100%;
  // background: #fff;
  // border-bottom: 1px solid @sui_color_gray_weak1;
  // overflow-y: scroll;
  // z-index: @zindex-hack;
  &__header {
    position: relative;
    width: 100%;
    padding: 24/75rem 48/75rem;
    border-bottom: 1px solid @sui_color_gray_weak1;
    text-align: center;
    background: #fff;
    z-index: @zindex-out;
  }
  &__header-text {
    color: @sui_color_gray_dark3;
    .font-dpr(28px);
  }
  &__header-close {
    position: absolute;
    top: 4/75rem;
    right: 4/75rem;
    color: @sui_color_gray_light1;
  }
}
// 订单明细抽屉的动画
.detail-enter-active {
  transition: transform 0.3s;
}
.detail-leave-active {
  transition: transform 0.25s;
}
.detail-enter, .detail-leave-to {
  transform: translateY(100%);
}
.detail-leave-from {
  transform: translateY(0%);
}

// 订单明细抽屉的遮罩
.price-detail-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.mask-enter, .mask-enter-active {
  animation: mask-fade-in 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.mask-leave-leave, .mask-leave-active {
  animation: mask-fade-out 0.25s cubic-bezier(0, 0, 0.2, 1) forwards;
}

@keyframes mask-fade-in {
  0% {
    background: rgba(0,0,0,0);
  }
}

@keyframes mask-fade-out {
  100% {
    background: rgba(0,0,0,0);
  }
}
</style>

<style lang="less">
.checkout-activity-popover__wrap {
  .S-popover__main {
    /* stylelint-disable-next-line declaration-no-important */
    left: auto !important;
    /* stylelint-disable-next-line declaration-no-important */
    right: 0 !important;
  }
  .S-popover__main-content {
    background: unset;
    padding-top: 24/75rem;
    padding-bottom: 24/75rem;
  }
  .S-popover__main-dark::before {
    background: unset;
    border: 5px solid rgba(0, 0, 0, .8);
    border-left-color: transparent;
    border-top-color: transparent;
  }
}
.checkout-activity-popover__wrap-new-layout {
  .S-popover__close {
    display: flex;
    align-items: center;
    height: 32/75rem;
    /* stylelint-disable-next-line declaration-no-important */
    font-size: 8px !important;
  }
  .S-popover__main-content {
    padding: 12/75rem 32/75rem 12/75rem 12/75rem;
  }
}
.checkout-activity-popover__wrap-ar {
  .S-popover__main-dark::before {
    background: unset;
    border: 5px solid rgba(0, 0, 0, .8);
    border-right-color: transparent;
    border-top-color: transparent;
  }
}
.checkout-activity-popover__wrap-expansion {
  flex: 1;
  min-width: 0;
}
</style>
