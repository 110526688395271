function changeHash(value) {
  location.hash = value ? '#' + value : ''
}
const backClass = '.j-icon-back, .check-size-panel .icon-close, .c-drawer .sui_icon_nav_back_24px, .icon-back, .mshe-mask, .j-clear-history-hash, .S-modal.S-modal__drawer, .S-drawer:not(.keep-hash)'


let reportPopoverComponent

function backClassClickHandler (e) {
  let target = e.target
  let count = 1
  while (target != null) {
    if (target.matches && target.matches(backClass)) {
      if (target.matches('.j-header-bag')) return
      const _isDrawer = target.classList.contains('S-drawer')
      if (_isDrawer && count !== 1) return 

      // 快速加车的弹窗关闭不会退hash
      const _isQuickAdd = _isDrawer && (target?.children?.[0]?.className?.indexOf('c-quick-add-container') > -1 || target?.children?.[0]?.className?.indexOf('bsc-quick-add-cart-standard-drawer') > -1)
      if (_isQuickAdd ) return

      // 举报蒙层关闭不会退hash
      const isReportDrawer = _isDrawer && target?.className?.indexOf('report-drawer-container') > -1

      // 尺码选择弹窗关闭不会退hash
      const isLocalSizeSelectDrawer = _isDrawer && target.classList.contains('c-drawer-local-size-select')

      // 评论翻译弹窗关闭不会退hash
      const isCommentTranslateDrawer = _isDrawer && target.classList.contains('comment-translate__pop-container')

      if (isReportDrawer || isLocalSizeSelectDrawer || isCommentTranslateDrawer) return

      if (location.hash) {
        history.go(-1)
        e.preventDefault()
        return false
      }

      break
    }
    count = count + 1
    target = target.parentElement
  }
}

function pushHistoryHashClickHandler (e) {
  let target = e.target
  while (target != null) {
    if (target.matches && target.matches('.j-push-history-hash')) {
      var state = target.getAttribute('state')
      changeHash(state)
      break
    }
    target = target.parentElement
  }
}

function handleHash() {

  function getReportPopoverComponent() {
    if (reportPopoverComponent) {
      return Promise.resolve(reportPopoverComponent)
    }
    return import('public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Reviews/components/Comment/components/CommonReport/report/index.js').then((res) => {
      reportPopoverComponent = res.default
      return reportPopoverComponent
    })
  }
  document.addEventListener('click', backClassClickHandler)


  document.addEventListener('click', pushHistoryHashClickHandler)


  const hashChangeCallback = (function () {
    var hashValue = location.hash
    hashValue = hashValue.replace('#', '')
    switch (hashValue) {
      case 'delivery':
        this.update_drawer_shipping_main_drawer_open(true)
        break
      case 'cod-policy':
        this.updateCodPolicyStatus(true)
        break

      case 'return-policy_new':
        this.update_drawer_return_cod_open(1)
        break
      case 'cod-policy_new':
        this.update_drawer_return_cod_open(2)
        break
      case 'report':
        this.updateReportStatus(true)
        this.updateReportFormStatus(false)
        break
      case 'report_new':
        this.update_drawer_report_open(true)
        this.update_drawer_report_form_status(false)
        break
      case 'reportOld':
        this.updateReportStatusOld(true)
        break
      case 'reportUs_new':
        this.update_drawer_report_us_open(true)
        break
      case 'reportDas_new':
        this.update_drawer_report_das_open(true)
        this.update_drawer_report_form_status(false)
        break
      case 'reportDsa':
        this.updateReportStatusDsa(true)
        this.updateReportFormStatus(false)
        break
      case 'reportForm':
        this.updateReportFormStatus(true)
        break
      case 'reportForm_new':
        this.update_drawer_report_form_status(true)
        break
      case 'sizeGuide':
        this.updateShowSizeGuideDrawer(true)
        break
      case 'description':
        this.updateDescriptionStatus(true)
        break
      case 'userManual':
        this.update_user_manual_status(true)
        break
      case 'lookbook':
        this.updateShoplookLightbox(true)
        break
      case 'comment-all':
        this.open_view_comment_pop()
        this.update_show_sort_list(false)
        this.update_show_color_size_list(false)
        this.update_show_rating_list(false)
        // 评论反馈返回评论页时关闭评论弹窗
        getReportPopoverComponent().then((c) => {
          c.close()
        })
        break
      case 'customer-show':
        this.update_customer_show && this.update_customer_show(true)
        this.updateCustomerShow(true)
        break
      case 'guarantee':
        this.updateGuaranteeStatus(true)
        break
      case 'guarantee_new':
        this.update_drawer_shipping_guarantee_open(true)
        break
      case 'goods-video':
        this.updatePlayVimeoStatus(true)
        // BFF 新数据流
        this.update_play_vimeo_status(true)
        break
      case 'detailFPos':
        this.update_detail_f_pos_status(true)
        break
    }

    if (!hashValue) {
      this.close_view_comment_pop()
      this.update_show_sort_list(false)
      this.update_show_color_size_list(false)
      this.update_show_rating_list(false)
      this.update_show_review_imgbox(false)
      this.update_drawer_filter_show(false)
      this.update_customer_show(false)

      this.updateDescriptionStatus(false)
      this.updateUserManualStatus(false)
      this.updateViewPromotionStatus({
        status: false,
        isQuickAdd: false
      })
      this.updateShoplookLightbox(false)
      this.updateSimilarItemsStatus(false)
      this.update_drawer_shipping_main_drawer_open(false)
      this.updateShowSizeGuideDrawer(false)
      this.updateCheckSizeAndSizeGuide?.(false)
      this.updateReportStatus(false)
      this.updateReportStatusOld(false)
      this.updateReportStatusDsa(false)
      this.updateReportFormStatus(false)
      this.update_drawer_report_form_status(false)
      this.update_drawer_report_open(false)
      this.update_drawer_report_us_open(false)
      this.update_drawer_report_das_open(false)
      this.update_drawer_shipping_guarantee_open(false)
      this.update_drawer_return_cod_open(0)
      this.updateCodPolicyStatus(false)
      this.updateDetailOPos(false)
      this.updateDetailFPos(false)
      this.updateShowSortList(false)
      this.updateShowColorSizeList(false)
      this.updateShowRatingList(false)
      this.updateShowReviewImgbox(false)
      this.closeViewCommentPop()
      this.updateDrawerFilterShow(false)
      this.updateGuaranteeStatus(false)
      if (reportPopoverComponent) {
        reportPopoverComponent.close()
      }

      this.updateCustomerShow(false)
      this.updatePlayVimeoStatus(false)
      // BFF 新数据流返回退出
      this.update_play_vimeo_status(false)
      document.querySelectorAll('.check-size-panel').forEach(function(element) {
        element.classList.remove('slideInUp-modal', 'block')
      })


    }
  }).bind(this)

  // hash change start
  window.addEventListener('hashchange', hashChangeCallback)
  return {
    hashChangeCallback
  }
}

function hashUnBind({ hashChangeCallback }) {
  document.removeEventListener('click', backClassClickHandler)
  document.removeEventListener('click', pushHistoryHashClickHandler)
  window.removeEventListener('hashchange', hashChangeCallback)
}

export {
  handleHash,
  hashUnBind
}
