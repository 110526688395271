<template>
  <div
    id="footer-select-pay"
    class="footer-pay"
  >
    <footer-had-pay
      v-if="isSelect"
      :checkout="checkout"
      :banks-config="banksConfig"
      :language="language"
      :status="status"
      :get-img-url="getImgUrl"
      :is-text-overflow="isTextOverflow"
      :price="price"
      :locals="locals"
      :icon-prefix="iconPrefix"
      :paymentMethodsProps="paymentMethodsProps"
      :is-show-token-payment-by-abt="isShowTokenPaymentByAbt"
      :defaultSelectTokenPay="defaultSelectTokenPay"
      @payment-options-oper="handlePaymentOptionsOper"
      @show-select-drawer="showSelectDrawer"
      @handle-toggle-ppga-type="handleTogglePpgaType"
      @handle-toggle-afterpay-type="handleToggleAfterpayType"
      @handle-toggle-signup-type="handleToggleSignupType"
    />
    <footer-no-pay
      v-else
      :checkout="checkout"
      :banks-config="banksConfig"
      :language="language"
      :get-img-url="getImgUrl"
      :is-text-overflow="isTextOverflow"
      :guide-logo-list="checkoutGuideLogoList"
      @show-select-drawer="showSelectDrawer"
    />

    <ClientOnly>
      <payment-select-drawer
        ref="paymentSelectDrawerRef"
        :visible="showPaymentMethodsDialog"
        :language="language"
        :paymentMethodsProps="paymentMethodsProps"
        :paymentMethods="paymentMethods"
        :isSelect="isSelect"
        @change-visible="showSelectDrawer(false)"
        @create-order="createOrder"
        @confirm-ppgv-type="confirmPPGVType"
        @handle-toggle-ppga-type="handleTogglePpgaType"
        @handle-toggle-afterpay-type="handleToggleAfterpayType"
        @handle-toggle-signup-type="handleToggleSignupType"
      >
        <slot></slot>
      </payment-select-drawer>
    </ClientOnly>
  </div>
</template>

<script setup>
// components
import FooterNoPay from './footer_no_pay.vue'
import FooterHadPay from './footer_had_pay.vue'
import PaymentSelectDrawer from './payment_select_drawer'
import ClientOnly from 'vue-client-only'

// utils
import { computed, defineEmits, watch, ref } from 'vue'
import { transformImg, template } from '@shein/common-function'
import {
  useStore,
  useMapGetters,
  useMapMutations
} from 'public/src/pages/checkout/hooks/index.js'
import { Toast as $toast } from '@shein/sui-mobile'
import {
  PPGA_CHECK_TYPE,
  AFTERPAY_CHECK_TYPE,
  SIGNUP_CHECK_TYPE
} from 'public/src/pages/common/constants'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { CO_BRAND_CARD_LOGO, CO_BRAND_LEVEL_LIST } from '../token_pay/constant'

const props = defineProps({
  checkout: { type: Object, default: () => ({}) },
  banksConfig: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  status: {
    type: Object,
    default: () => ({})
  },
  price: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({})
  },
  iconPrefix: {
    type: String,
    default: ''
  },
  paymentMethodsProps: {
    type: Object,
    default: () => {}
  },
  checkoutGuideLogoList: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['payment-options-oper', 'create-order'])

const store = useStore()
const { isSelectInstallmentTokenPay } = useMapGetters([
  'isSelectInstallmentTokenPay'
])
const { assignState } = useMapMutations(['assignState'])

const paymentSelectDrawerRef = ref()

const xtraAutoController = computed(() => {
  return store?.getters?.['checkout/xtraAutoController']
})

// 是否选择支付方式
const isSelect = computed(() => {
  return props.status?.cardPay?.length > 0
})

const paymentMethods = computed(() => {
  const { paymentMethods } = props.checkout.results || {}
  return paymentMethods || []
})

const productAutoInfo = computed(() => {
  return store?.getters?.['checkout/productAutoInfo']
})

const showPaymentMethodsDialog = computed(() => {
  return store?.state?.checkout?.showPaymentMethodsDialog
})

const routerPay = computed(() => {
  return paymentMethods.value?.find(
    item => item.code === 'routepay-card'
  )
})

const defaultSelectTokenPay = computed(() => {
  return store?.state?.checkout?.defaultSelectTokenPay
})

const installmentSelectBin = computed(() => {
  return store?.state?.checkout?.installmentSelectBin
})

const isUseNewCard = computed(() => {
  return store?.state?.checkout?.isUseNewCard
})

const paypalGaVault = computed(() => {
  return props.paymentMethodsProps?.paypalGaVault
})

const afterPayVault = computed(() => {
  return props.paymentMethodsProps?.afterPayVault
})

const signupPayVault = computed(() => {
  return props.paymentMethodsProps?.signupPayVault
})

// abt 是否展示 token 支付
const isShowTokenPaymentByAbt = computed(() => {
  return true  // 11.04已推全
})

const nowIsShowTokenPayment = computed(() => {
  // 后端字段是否展示 token 支付
  const isShowTokenPaymentByBackEnd = routerPay.value?.shieldAddCard === 0 // 0代表b不屏蔽，则走abt的判断逻辑
  // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
  return isShowTokenPaymentByBackEnd && isShowTokenPaymentByAbt.value && (routerPay.value?.card_token_list || [])?.length > 0 
})

watch(() => nowIsShowTokenPayment.value, (val) => {
  if (val) {
    assignState({
      isShowTokenPay: val
    })
  }
}, {
  immediate: true
})

const nowInstallmentTokenPayment = payment => {
  const bin =
    installmentSelectBin.value || ''
  return (
    isSelectInstallmentTokenPay.value &&
    ((payment?.card_token_list || [])?.find(item => item?.card_bin === bin) ||
      (payment?.card_token_list || [])?.[0])
  )
}

const nowShowTokenPayment = payment => {
  return (
    isShowTokenPayment(payment) &&
    (payment?.card_token_list || [])?.find(
      item => item.id === defaultSelectTokenPay.value?.id
    )
  )
}

const isShowTokenPayment = payment => {
  // 后端字段是否展示 token 支付
  const isShowTokenPaymentByBackEnd =
    payment?.code === 'routepay-card' && payment?.shieldAddCard === 0 // 0代表b不屏蔽，则走abt的判断逻辑
  // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
  return (
    isShowTokenPaymentByBackEnd &&
    isShowTokenPaymentByAbt.value &&
    (payment?.card_token_list || [])?.length > 0
  )
}

// 获取支付方式图片
const getImgUrl = payment => {
  let imgUrl =
    props.banksConfig?.[payment?.code]?.bankLogo || payment?.logo_url || ''

  let tempPay = nowShowTokenPayment(payment)
  if (isShowTokenPayment(payment) && tempPay) {
    if(CO_BRAND_LEVEL_LIST.includes(tempPay.co_brand_level)){
      imgUrl = CO_BRAND_CARD_LOGO
    }else{
      imgUrl = tempPay?.app_logo
    }
  }

  let tempInstallment = nowInstallmentTokenPayment(payment)
  if (isSelectInstallmentTokenPay.value && tempInstallment) {
    if (isUseNewCard.value) {
      imgUrl =  'https://img.ltwebstatic.com/images3_ccc/2024/06/14/aa/1718352340ff2421c5efcedb6a17bb10bd997ca733.png'
    }else if(CO_BRAND_LEVEL_LIST.includes(tempInstallment.co_brand_level)){
      imgUrl = CO_BRAND_CARD_LOGO
    } else {
      imgUrl = tempInstallment?.app_logo
    }
  }

  return transformImg({
    img: imgUrl
  })
}

// 检测是否文本溢出
const isTextOverflow = (element, cb) => {
  if (!element) return false
  // 检测元素的滚动宽度是否大于其可视宽度
  if (element.scrollWidth > element.offsetWidth) {
    cb && cb()
  }
}

const handlePaymentOptionsOper = data => {
  emits('payment-options-oper', data)
}

const showSelectDrawer = (value = true) => {
  daEventCenter.triggerNotice({
    daId: '1-11-1-211'
  })
  store.commit('checkout/updateState', {
    key: 'showPaymentMethodsDialog',
    value: value
  })
}

const createOrder = () => {
  emits('create-order')
}

const handleTogglePpgaType = () => {
// 1. 当前有续费包 2. 当前选中的是afterPay
  const orderActivity = props?.checkout?.results?.primeActivityType == 1
  const { limitedPayTypeList: limitedPayTypeListPrime, currentProduct, currentProductCycleDays } = productAutoInfo.value || {}
  const { currentXtraIsAuto, xtraCurrentAutoPayLimit } = xtraAutoController.value || {}

  const currentPrimeisAuto = currentProduct?.product_activity_type == 1 && currentProductCycleDays ? true : false
  // 1.成单提示 2.用户同时选择了会员续费产品包和超省卡续费产品包提示 3.区分付费会员或者超省卡提示 
  let tostLanguage = ''
  if(orderActivity){
    tostLanguage = props.language?.SHEIN_KEY_PWA_31535 
  } else {
    if(currentXtraIsAuto && currentPrimeisAuto){
      tostLanguage = props.language?.SHEIN_KEY_PWA_31535
    } else {
      tostLanguage = currentXtraIsAuto ? props.language?.SHEIN_KEY_PWA_31535 : template(currentProduct?.product_name_language, props.language?.SHEIN_KEY_PWA_29576)
    }
  }
  const paymentLimitIncludes = limitedPayTypeListPrime?.some(s => s.code === window?.shem_caculate_data?.payment_code_unique) || xtraCurrentAutoPayLimit
  const currentIsAfterPay = window?.shem_caculate_data?.payment_code_unique == 'PayPal-GApaypal'
  const isProdctAuto = currentPrimeisAuto || currentXtraIsAuto
  // 当前支付是afterpay-card && (paymentLimitIncludes && isProdctAuto)当前支付在快捷里且当前有产品包是自动续费 || orderActivity成单或者随单 && 选中状态
  if(currentIsAfterPay && ((paymentLimitIncludes && isProdctAuto) || orderActivity) && paypalGaVault.value?.type == 1){
    return $toast( tostLanguage )
  }

  paypalGaVault.value.radioType =
    paypalGaVault.value.radioType === PPGA_CHECK_TYPE.CHECKED
      ? PPGA_CHECK_TYPE.UNCHECKED
      : PPGA_CHECK_TYPE.CHECKED
  props.paymentMethodsProps.confirmPPGVType()
}

const confirmPPGVType = () => {
  props.paymentMethodsProps.confirmPPGVType()
}

const handleToggleAfterpayType = () => {
  // 1. 当前有续费包 2. 当前选中的是afterPay
  const orderActivity = props?.checkout?.results?.primeActivityType == 1
  const { limitedPayTypeList: limitedPayTypeListPrime, currentProduct, currentProductCycleDays } = productAutoInfo.value || {}
  const { currentXtraIsAuto, xtraCurrentAutoPayLimit } = xtraAutoController.value || {}

  const currentPrimeisAuto = currentProduct?.product_activity_type == 1 && currentProductCycleDays ? true : false
  // 1.成单提示 2.用户同时选择了会员续费产品包和超省卡续费产品包提示 3.区分付费会员或者超省卡提示 
  let tostLanguage = ''
  if(orderActivity){
    tostLanguage = props.language?.SHEIN_KEY_PWA_31535 
  } else {
    if(currentXtraIsAuto && currentPrimeisAuto){
      tostLanguage = props.language.SHEIN_KEY_PWA_31535
    } else {
      tostLanguage = currentXtraIsAuto ? props.language?.SHEIN_KEY_PWA_31535 : template(currentProduct?.product_name_language, props.language?.SHEIN_KEY_PWA_29576)
    }
  }
  const paymentLimitIncludes = limitedPayTypeListPrime?.some(s => s.code === window?.shem_caculate_data?.payment_code_unique) || xtraCurrentAutoPayLimit
  const currentIsAfterPay = window?.shem_caculate_data?.payment_code_unique == 'afterpay-card'
  const isProdctAuto = currentPrimeisAuto || currentXtraIsAuto
  // 当前支付是afterpay-card && (paymentLimitIncludes && isProdctAuto)当前支付在快捷里且当前有产品包是自动续费 || orderActivity成单或者随单 && 选中状态
  if(currentIsAfterPay && ((paymentLimitIncludes && isProdctAuto) || orderActivity) && afterPayVault.value?.type == 1){
    return $toast( tostLanguage )
  }

  afterPayVault.value.radioType =
    afterPayVault.value.radioType === AFTERPAY_CHECK_TYPE.CHECKED
      ? AFTERPAY_CHECK_TYPE.UNCHECKED
      : AFTERPAY_CHECK_TYPE.CHECKED
  props.paymentMethodsProps.confirmAfterPayType()
}

const handleToggleSignupType = (paymentCode) => {
  if (signupPayVault.value[paymentCode]) {
    signupPayVault.value[paymentCode].radioType =
      signupPayVault.value[paymentCode].radioType === SIGNUP_CHECK_TYPE.CHECKED
        ? SIGNUP_CHECK_TYPE.UNCHECKED
        : SIGNUP_CHECK_TYPE.CHECKED
  }
  props.paymentMethodsProps.confirmSignupPayType(paymentCode)
}

// 使用 defineExpose 将方法暴露给父组件
defineExpose({
  showSelectDrawer,
  handlePaymentSelectConfirm: (evt) => {
    return paymentSelectDrawerRef.value?.handlerConfirm?.(evt)
  }
})
</script>

<style lang="less" scoped>
.footer-pay {
  border-bottom: 1/75rem solid #e5e5e5;
}
</style>
