<template>
  <div
    v-if="show"
    class="checklist-cart"
  >
    <div class="checklist-cart__list">
      <ProductImg
        v-for="(product, index) in products"
        :key="product.id"
        :src="product.goods_img"
        :border-mask="product.maskLayerImg"
        :belt="product.productImgBelt"
        :checkbox-disabled="product.goodType != '1'"
        :cart-id="product.id"
        :product-map="newProductMap"
        :index="index"
        @click="onClickProductImg"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import ProductImg from './ProductImg.vue'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['click-product-img'])

const products = ref([])
const show = computed(() => !!products.value.length)
const productMap = computed(() => products.value.reduce((acc, cur) => {
  acc[cur.id] = cur
  return acc
}, {}))

const newProductMap = computed(() => props.item?.products?.reduce((acc, cur) => {
  acc[cur.id] = cur
  return acc
}, {}))

const onClickProductImg = (data) => {
  emit('click-product-img', data)
}

watch(() => props.item.products, (v) => {
  if (products.value.length) {
    const addProducts = v.filter(f => !productMap.value[f.id])
    products.value = [...products.value.map(m => newProductMap[m.id] || m), ...addProducts]
  } else {
    products.value = v
  }
}, { immediate: true })
</script>

<style lang="less" scoped>
.checklist-cart {
  margin-bottom: 12/37.5rem;
  &__list {
    display: flex;
    gap: 6px;
    overflow-x: auto;
    scrollbar-width: none;
  }
}
</style>
