var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.iconLists.length)?_c('div',{staticClass:"module-content my-entry-block more-service__wrap"},[_c('div',{staticClass:"module-content-title"},[_c('h3',{attrs:{"tabindex":"0"}},[_vm._v("\n      "+_vm._s(_setup.myServiceInfo?.titleGroup?.title)+"\n    ")])]),_vm._v(" "),_c('swiper-container',{ref:"swiperRef",staticClass:"more-service__list",attrs:{"init":"false","pagination":"true","slides-per-view":_setup.myServiceInfo.iconsGroup?.rowCount}},_vm._l((_setup.iconLists),function(item,index){return _c('swiper-slide',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '1-25-11-21',
        data: _setup.baseAnalysisData(item, index)
      }),expression:"{\n        id: '1-25-11-21',\n        data: baseAnalysisData(item, index)\n      }"},{name:"tap",rawName:"v-tap",value:({
        id: '1-25-11-22',
        data: _setup.baseClickData(item, index)
      }),expression:"{\n        id: '1-25-11-22',\n        data: baseClickData(item, index)\n      }"}],key:item.type,staticClass:"more-service__item",attrs:{"data-param":_setup.isLogin() && item?.iconAttributes?.isShow ? 1 : 0},on:{"click":() => _setup.handleSectionItemClick(item, _setup.isLogin() && item?.iconAttributes?.isShow ? 1 : 0)}},[(item.iconObject?.iconType === 2)?_c('img',{staticClass:"more-service__item-img",attrs:{"src":_setup.transformImg({ img: item.iconObject?.iconUrl || '' })}}):(item.iconObject?.iconType === 3)?_c('i',{class:`more-service__item-img ${item.iconObject?.iconUrl}`}):_c(_setup.Icon,{attrs:{"name":item.iconObject?.iconUrl,"size":"24px"}}),_vm._v(" "),(_setup.showItemReminder(item))?_c('span',{staticClass:"red-point",style:([2,3].includes(Number(item.iconAttributes?.type)) && _setup.styleConfig[_setup.GB_cssRight? 'cssRightStyle': 'cssLeftStyle'])},[([2,3].includes(Number(item.iconAttributes?.type)))?[_vm._v("\n          "+_vm._s(item.iconAttributes?.value || '')+"\n        ")]:_vm._e()],2):_vm._e(),_vm._v(" "),_c('p',{staticClass:"more-service__item-title"},[_vm._v("\n        "+_vm._s(_setup.htmlDecode({ text: item.title }))+"\n      ")])],1)}),1),_vm._v(" "),_c('div',{staticClass:"more-service-pagination"})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }