<template>
  <div
    :class="{
      'nav-bar__filter': true,
    }"
    :aria-label="language.SHEIN_KEY_PWA_15044"
    role="tab"
    @click="onClick"
    @focus="$emit('navFocus', $event)"
  >
    <span
      :class="{
        'nav-bar__filter-label': true,
        'nav-bar__filter-label-highlight': filterNum,
      }"
    >
      {{ language.SHEIN_KEY_PWA_15044 }}
    </span>

    <!-- icon -->
    <span
      class="nav-bar__filter-icon"
      :style="{
        color: filterNum ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.6)'
      }"
    >
      <sui_icon_filter_16px
        size="12px"
      />
    </span>

    <!-- filterNum -->
    <span
      v-if="filterNum"
      class="nav-bar__filter-filter-num"
    >
      {{ filterNum }}
    </span>

    <NavBarFilterDrawer 
      v-if="isLoadSlide"
      ref="refNavBarFilterDrawer"
      :visible.sync="visible"
      :menu-bar-config="menuConfig.menuBarConfig"
      :menu-items-config="menuConfig.menuItemsConfig"
      @sidePanelChange="onSidePanelChange"
      @resetAll="onResetAll"
      @closeSidePanel="close"
      @close="close"
      @clickDone="close"
    />
  </div>
</template>

<script>
export default {
  name: 'NavBarFilter',
}
</script>

<script setup>
import { unref, watch, toRefs, inject, defineAsyncComponent } from 'vue'
import { sui_icon_filter_16px } from '@shein-aidc/icon-vue2'

// hooks
import { useVisible, useIsLoadSlide } from 'public/src/pages/components/FilterBar/components/dropdown/hooks/index.js'

import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { FILTER_RESULT_TYPE_MAP, RESTET_QUERY_PARAMS } from 'public/src/pages/components/FilterBar/utils/const.js'
import { handlePricesParams } from 'public/src/pages/components/FilterBar/utils/prices.js'
import { handleCategoryParams } from 'public/src/pages/components/FilterBar/utils/category.js'
import { handleParamsBySideFilter } from 'public/src/pages/components/FilterBar/utils/attr.js'
import { analysisClickFilterEntrance } from 'public/src/pages/components/FilterBar/NavBar/utils/analysis.js'
import { unrefs, getSourceInsideFilterOrCloudTagPanel } from 'public/src/pages/components/FilterBar/utils/index.js'
import { isFilterByKids } from 'public/src/pages/components/FilterBar/utils/kids.js'
const NavBarFilterDrawer = defineAsyncComponent(() => import('./NavBarFilterDrawer.vue'))
const { currencies, lang, currency } = gbCommonInfo

const props  = defineProps({
  menuConfig: { type: Object, default: () => ({}) },
  filterNum: { type: Number, default: 0 },
})

const { menuConfig, filterNum } = toRefs(props)

// inject
const sortMap = inject('sortMap', [])
const topTwoList = inject('topTwoList', [])
const language = inject('language', {})
const filterBar = inject('filterBar', {})
const queryParams = inject('queryParams', {})
const fhContext = inject('fhContext', {})
const googleContext = inject('googleContext', {})
const autoStickyTime = inject('autoStickyTime', 0) 
const isConfigAutoSticky = inject('isConfigAutoSticky', false) 

const { visible, toggle, close } = useVisible()
const isLoadSlide = useIsLoadSlide(visible)
const emits = defineEmits(['change'])
watch(visible, flag => emits('visibleChange', flag))
const toStickyByClick = () => emits('toStickyByClick')
const onClick = () => {
  if (!isConfigAutoSticky.value) {
    analysisClickFilterEntrance(unrefs({ sortMap, topTwoList, queryParams }))
    toggle()
    return
  }

  toStickyByClick()
  const timer = setTimeout(() => {
    clearTimeout(timer)
    analysisClickFilterEntrance(unrefs({ sortMap, topTwoList, queryParams }))
    toggle()
  }, autoStickyTime.value)
}

const openSideFilter = () => {
  onClick()
}

// events
const onResetAll = () => {
  analysis.sideClickReset()
  const { tag_ids }  = queryParams.value

  emits('change', { 
    type: 'clearAll',
    rightFilterFalg: true,
    params: { 
      ...RESTET_QUERY_PARAMS,
      tag_ids: isFilterByKids(tag_ids) ? tag_ids : ''
    },
    filterResult: {
      operation: 'resetAll',
    }
  })
}

const onShopTypeChange = ({ data = {} }) => {
  const { value, mall_tag } = data
  const index = (filterBar.value?.filterData?.cloudTags || []).findIndex(tag => tag.value == value)
  // 埋点
  analysis.clickCloudTag({
    tagId: value,
    tagIndex: index + 1,
    facet: fhContext?.value?.tag_id,
    gfilter: googleContext?.value?.gfilter,
    clickType: 'filter',
  })

  emits('change', {
    data,
    type: 'shopType',
    rightFilterFalg: true,
    params: { 
      tag_ids: value,
      mall_tag: value ? mall_tag : '',
      pic_cate_id: '', 
      source: 'insideFilter', 
      source_status: value ? 1 : 0 
    },
    filterResult: {
      type: FILTER_RESULT_TYPE_MAP.CLOUD_TAGS,
      label_id: value,
      label: data.label,
      isDelete: !value,
    }
  })
}

const onCategoryChange = ({ data, cancel, paths }) => {
  const params = handleCategoryParams({
    data,
    paths,
    doAnalysis: true,
    facet: fhContext?.value?.categories,
    gfilter: googleContext?.value?.gfilter,
    isCancel: cancel,
    sourceFrom: 'sideFilter',
    filterCates: filterBar.value?.filterData?.filterCates
  })

  emits('change', {
    data,
    cancel,
    type: 'cate',
    rightFilterFalg: true,
    params,
    filterResult: {
      paths,
      label: data.label,
      isDelete: !params.child_cat_i,
      label_id: params.child_cat_id,
      type: FILTER_RESULT_TYPE_MAP.CATEGORY,
    }
  })
}

const onPriceChange = ({ minPrice, maxPrice, startMinPrice, startMaxPrice, priceInput }) => {
  const params = handlePricesParams({
    minPrice,
    maxPrice,
    priceInput,
    clickType: 'filter',
    startMinPrice,
    startMaxPrice,
    fhContext: unref(fhContext),
    googleContext: unref(googleContext),
    sourceFrom: 'sideFilter',
  })

  const { symbol_left = '',  symbol_right = '' } = currencies?.[lang]?.[currency] || {}
  emits('change', {
    type: 'price',
    rightFilterFalg: true,
    params,
    filterResult: {
      type: FILTER_RESULT_TYPE_MAP.PRICE,
      label_id: 'price',
      label: `${symbol_left}${startMinPrice}${symbol_right}-${symbol_left}${startMaxPrice}${symbol_right}`,
    }
  })
}

const onAttrChange = ({ data, operation, curSelectedItem, curSelectedNum, }) => {
  curSelectedNum && emits('saveCurrAttr', curSelectedItem?.id || curSelectedItem?.value, 'attr')  

  // 处理自定义筛选中配置tsp
  if (curSelectedItem?.nodeType == 7) {
    const tspId = `${curSelectedItem.id}_${curSelectedItem.mix_value}`
    const list = menuConfig.value.menuItemsConfig
      .filter(item => item.type == 'tsp')
      .reduce((acc, cur) => {
        return acc.concat(cur.data?.data?.filter(obj => obj.active && tspId != obj.id) || [])
      }, [])
    
    list.forEach(obj => {
      const splits = obj.id.split('_')
      data.push({
        nodeType: 7,
        id: splits[0],
        mix_value: splits[1],
      })
    })
  }

  const results = handleParamsBySideFilter({
    data,
    operation,
    clickType: 'Filter',
    curSelectedItem,
    curSelectedNum,
    queryParams: queryParams.value,
    isCccxFilterConfig: filterBar.value?.filterConfig?.isCccxFilterConfig,
    sourceFrom: 'sideFilter',
  })

  emits('change', {
    type: 'attr',
    rightFilterFalg: true,
    params: results.params,
    filterResult: results.filterResult
  })
}

const onTspChange = ({ tspData, operation, curSelectedItem, curSelectedNum }) => {
  const tspId = curSelectedNum ? curSelectedItem?.id?.split('_')?.[0] || '' : ''
  emits('saveCurrAttr', tspId, 'tsp')
  let tspIds = tspData.map(item => item.id)

  // 处理自定义筛选中配置tsp
  if (filterBar.value?.filterConfig?.isCccxFilterConfig) {
    const list = menuConfig.value.menuItemsConfig
      .filter(item => item.type == 'multi')
      .reduce((acc, item) => {
        const childList = item.data.data.reduce((acc2, cur2) => {
          return acc2.concat(cur2.data?.filter(obj => obj.active && obj.nodeType == 7 && !tspIds.includes(`${obj.id}_${obj.mix_value}`)) || [])
        }, [])

        return acc.concat(childList)
      }, [])
    tspIds = tspIds.concat(list.map(item => `${item.id}_${item.mix_value}`))
  }

  operation != 'reset' && analysis.clickTsps({ tsps: tspIds, clickType: `Filter`, curSelectedItem })

  emits('change', {
    type: 'tsp',
    rightFilterFalg: true,
    params: {
      tsp_ids: tspIds.join(','),
      ...getSourceInsideFilterOrCloudTagPanel({ sourceFrom: 'sideFilter', active: curSelectedItem.active })
    },
    filterResult: {
      type: FILTER_RESULT_TYPE_MAP.TSP,
      isDelete: !curSelectedItem.active,
      label_id: curSelectedItem?.id,
      label: curSelectedItem.label,
      ids: tspIds,
    }
  })
}

const onSidePanelChange = (params) => {
  if(params?.dataType == 'shopType') return onShopTypeChange(params)
  if (params?.dataType == 'cate') return onCategoryChange(params)
  if (params?.type === 'price') return onPriceChange(params)
  if (['multi', 'img'].includes(params?.type)) return onAttrChange(params)
  if (params?.type == 'tsp') return onTspChange(params)

  emits('change', {
    params,
    type: 'other',
    rightFilterFalg: true,
  })
}

defineExpose({ openSideFilter })
</script>

<style scoped lang="less">
.nav-bar {
  &__filter {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    line-height: 14px;
    padding: 12px 16px 6px 0;
}

  &__filter-label {
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: rgba(0,0,0,0.6);
    padding-left: 16px;
    border-left: 1px solid #e5e5e5;
  }

  &__filter-icon{
    margin-left: 2px;
    display: flex;
    width: 12px;
    height: 12px;
    align-items: center;
    justify-content: center;
  }

  &__filter-label-highlight {
    font-weight: 700;
    color: rgba(0,0,0,1);
  }

  &__filter-filter-num{
    position: absolute;
    top:  8px;
    right: 8px;
    width: 12px;
    height: 12px;
    text-align: center;
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    font-size: 8px;
    line-height: 12px;
    z-index: @zindex-hack;
    font-weight: normal;
    font-weight: 600;
  }
}
</style>
