import * as standard from './sources/standard'
import {
  organizeDataSource,
  createFsDataRunnerBySource,
} from '@shein-aidc/utils-data-source-toolkit'
import type { DS_CommonHeader } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_CommonHeader.SourceParams): DS_CommonHeader.APIS {
  return organizeDataSource<DS_CommonHeader.APIS, DS_CommonHeader.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_CommonHeader.FS_SourceParams) {
  const { api1 } = getSource(sourceParams)
  return createFsDataRunnerBySource<
    DS_CommonHeader.FS_APIS,
    DS_CommonHeader.FS_DATA
  >({
    api1,
  })
}
