<template>
  <div class="bs-payment-item">
    <PaymentItemBase
      :title="paymentTitle"
      :payment-code="paymentItem.code"
      :enabled="tokenEnabled"
      :logo-url="paymentLogo"
      :is-selected="isSelected"
      @select="handleSelect"
    >
      <template #center-bottom>
        <DiscountBlock
          :payments-preferential-tips="nowPaymentsPreferentialTips"
          :payment-item="paymentItem"
          :now-pay-bin="nowPayBin"
          :coupon-list-state="couponListState"
          scene="payment_list"
          @click-tip="handleShowCardBinArticle"
          @handle-bs-payment-action="tempHandleBsPaymentAction"
        />

        <!-- 不支持卡bin优惠券提示文案 -->
        <PaymentWarningInfo
          v-if="isShowPaymentWarningInfo"
          :warning="tokenItem?.preferentialTipsOfToken?.unablePayWithCardBinCouponTip"
        />
      </template>
    </PaymentItemBase>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, watch } from 'vue'
import { type LangKeys } from '../../../../common/languages'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import PaymentItemBase from './PaymentItemBase.vue'
import DiscountBlock from './DiscountBlock.vue'
import { usePaymentsStore } from '../../../../hooks/usePayments'
import { debuggerLog } from '../../../../utils'
import PaymentWarningInfo from './PaymentWarningInfo.vue'

const IS_CAN_USE = {
  ENABLED: '1',
  UN_ENABLED: '0', // 不可用（卡bin优惠券不可用）
}

interface BTokenPayPaymentItemProps {
  paymentItem?: Trade_PayToolKit.PaymentInfoItem
  tokenItem?: Record<string, any>
  isSelected: boolean
  language: Record<LangKeys, string>
  couponListState?: Record<string, any>
}

const props = withDefaults(defineProps<BTokenPayPaymentItemProps>(), {
  paymentItem: () => ({} as Trade_PayToolKit.PaymentInfoItem),
  isSelected: false,
})

const emits = defineEmits(['select', 'handle-bs-payment-action'])

const paymentsStore = usePaymentsStore()

const paymentTitle = computed(() => {
  debuggerLog('paymentItem==|||=====props.tokenItem', props.tokenItem, props.tokenItem?.card_no)
  if (props.tokenItem?.isUseNewCard) {
    return props.language.BS_KEY_PAY_1030
  }
  return props.tokenItem?.card_no || ''
})

const paymentLogo = computed(() => {
  if (props.tokenItem?.isUseNewCard) {
    return 'https://img.ltwebstatic.com/images3_ccc/2025/01/07/cd/1736253668edb38c4613ce46f1ac8b507b43131631.png'
  }

  // 联名卡显示联名卡logo
  if (props.tokenItem?.co_brand_card_tag === '1' && props.tokenItem?.tokenCoBrandedLogoUrl) {
    return props.tokenItem?.tokenCoBrandedLogoUrl
  }

  return props.tokenItem?.web_logo
})

const nowPayBin = computed(() => {
  return props.tokenItem?.card_bin || ''
})

const nowPaymentsPreferentialTips = computed(() => {
  return props.tokenItem?.preferentialTipsOfToken?.paymentsPreferentialTips || []
})

const tokenEnabled = computed(() => {
  const isEnabled = props.tokenItem?.preferentialTipsOfToken?.enabled !== IS_CAN_USE.UN_ENABLED
  return isEnabled ? IS_CAN_USE.ENABLED : IS_CAN_USE.UN_ENABLED
})

const isInstallment = computed(() => props.paymentItem?.code === 'routepay-cardinstallment')

const tempTitle = computed(() => isInstallment.value ? `${paymentTitle.value}${props.language.BS_KEY_PAY_1031}` : paymentTitle.value)

const isShowPaymentWarningInfo = computed(() => {
  return props.tokenItem?.preferentialTipsOfToken?.unablePayWithCardBinCouponTip
})

const handleSetOrderShowInfo = () => {
  paymentsStore?.paymentEventBus?.setOrderShowInfo?.emit({
    paymentTitle: props.tokenItem?.isUseNewCard ? props.paymentItem?.title : tempTitle.value,
    paymentLogo: props.tokenItem?.isUseNewCard ? props.paymentItem?.logo_url : paymentLogo.value,
  })
}

const handleSelect = () => {
  if (tokenEnabled.value === IS_CAN_USE.UN_ENABLED) return
  debuggerLog('handleSelect==|||=====', props.tokenItem)

  emits('select', { tokenItem: props.tokenItem })
  handleSetOrderShowInfo()
}

const handleShowCardBinArticle = (articleId: string) => {
  // 如果是链接，直接跳转
  const linkReg = /^https?:\/\//
  if (linkReg.test(articleId)) {
    location.href = articleId
    return
  }

  paymentsStore?.updateState('cardBinArticleInfo', {
    visible: true,
    articleId,
  })
}

const tempHandleBsPaymentAction = data => {
  emits('handle-bs-payment-action', data)
}

watch(() => tempTitle.value, () => {
  if (props.isSelected) {
      handleSetOrderShowInfo()
    }
})

onMounted(() => {
  nextTick(() => {
    if (props.isSelected) {
      handleSetOrderShowInfo()
    }
  })
})
</script>
