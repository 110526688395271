import type { ReducePriceHint } from '../../../../types/mobile/index.ts'

/**
 * ReducePriceHint 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): ReducePriceHint.Props {
  const reduceTag = data.value.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'priceReduction') || null
  const isInvalid = data.value.isInvalid == 1
  const isChecked = data.value.is_checked == 1
  return {
    isShow: !!reduceTag && !isInvalid && !isChecked,
    prefixIcon: reduceTag?.view?.prefixIcon || '',
    hintText: reduceTag?.view?.text || '',
    hintTextColor: reduceTag?.view?.textColor || '#C44A01',
  }
}
