import { load as loadProductConfigManager } from 'public/src/pages/goods_detail_v2/utils/ProductConfigManager/index.js'

export default {
  async loadProductConfigManager({ rootGetters }, payload) {
    const isPaidUser = rootGetters['newProductDetail/isPaidUser']

    const isDebug = typeof location !== 'undefined' && location.search.includes('debugger')
    loadProductConfigManager({
      ...(payload?.productConfig || {}),
      isPaidUser,
      isDebug,
    })
  }
}
