var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"xtra-item",class:{
    'xtra-item_small': !_vm.entryCouponStyle?.showCouponLimit
  },style:({
    ..._setup.couponConfig[_setup.couponType],
    '--coupon-circle-bg-color': _vm.entryCouponStyle?.couponCircleBgColor
  })},[_c('div',{ref:"lightEffectRef",staticClass:"xtra-item__wrapper"},[(_vm.coupon.selected)?_c('div',{staticClass:"xtra-item__selected"},[_c(_setup.Icon,{attrs:{"name":"sui_icon_selected_12px","size":"12px","color":"#F82854"}})],1):_vm._e(),_vm._v(" "),(_vm.coupon?.activityPrivilegeTip)?_c('div',{staticClass:"xtra-item__gifts",class:{
        'xtra-item__gifts_ar': _setup.GB_cssRight
      }},[_c(_setup.Icon,{attrs:{"name":"sui_icon_giftbox_12px","size":"12px","color":"#fff"}}),_vm._v(" "),_c('div',{staticClass:"xtra-item__gifts-text"},[_c('div',{ref:"scrollTextRef"},[_vm._v("\n          "+_vm._s(_vm.coupon.activityPrivilegeTip)+"\n        ")])])],1):((+_vm.coupon.sameCouponNum > 1) && _vm.entryCouponStyle.showCouponNumber)?_c('div',{staticClass:"xtra-item__quantity"},[_vm._v("\n      X"+_vm._s(_vm.coupon.sameCouponNum)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"xtra-item__content"},[_c('div',{staticClass:"xtra-item__discount",class:{
          'xtra-item__discount_shipping': _setup.couponType === _setup.XtraCouponType.Shipping,
        }},[_c(_setup.SAdapterText,{attrs:{"min-size":"10","lines":"2","text":_setup.discount}})],1),_vm._v(" "),(_setup.showLimit)?_c(_setup.SAdapterText,{staticClass:"xtra-item__rule",attrs:{"min-size":"10","text":_setup.rule}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }