<template>
  <div
    class="bsc-gc-personal">
    <SDrawer
      v-expose="{
        id: 'page_view:simple',
        data: {
          from: 'card_home',
          $options: {
            page_name: 'page_linked_giftcard_list',
          },
        },
      }"
      class="bsc-gc-personal__drawer"
      append-to-body
      v-bind="props.drawerProps"
      :show-close="false"
    >
      <template #top>
        <div class="bsc-gc-personal__header">
          <Icon
            class="left-icon"
            :style="{
              transform: GB_cssRight ? 'rotateY(180deg) translateY(-50%)' : 'translateY(-50%)'
            }"
            color="#000"
            name="sui_icon_more_left_16px"
            size="24px"
            :is-rotate="GB_cssRight"
            @click="onClose" />
          <div @click="() => emits('goScroll', 0)">
            {{ language?.SHEIN_KEY_PWA_24759 }}
          </div>
        </div>
      </template>
      <TabList
        ref="tabListRef"
        :is-show-no-card-icon="cardList?.length === 0"
        @updateTabIndex="updateTabIndex"
        @updateCardList="updateCardList"
      />
      <div :class="['bsc-gc-personal__card--block', props.scrollWrapClass]">
        <Card
          v-for="item in cardList"
          :key="`${item.card_no}${item.card_status_tip}`"
          :backgroundGray="tabIndex === 1"
          :enableCopy="true"
          :enableGoDetail="true"
          :readonly="true"
          :class="['card', props.scrollItemClass]"
          :item="item"
          cardHeight="3.04rem"
          @goDetail="goDetail"
        />
      </div>
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </SDrawer>
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { Drawer as SDrawer } from '@shein/sui-mobile'
import Card from './Card.vue'
import TabList from './TabList.vue'
import { type C_GiftCard } from '../../../types'

const { GB_cssRight } = gbCommonInfo

const cardList = ref<C_GiftCard.CardItem[]>([])
const tabIndex = ref<number>(0)

const props = defineProps<{
  drawerProps: Record<string, string>
  scrollWrapClass: string
  scrollItemClass: string,
}>()

const emits = defineEmits(['goScroll', 'custom-event'])

const language: C_GiftCard.language= inject('language')!

const updateCardList = (list) => {
  cardList.value = list
}
const updateTabIndex = (index: number) => {
  tabIndex.value = index
}

const goDetail = (no) => {
  emits('custom-event', { key: 'go-detail', data: no })
}

const onClose = () => {
  emits('custom-event', { key: 'close' })
}

</script>

<style lang="less">
.bsc-gc-personal {
  &__drawer {
    overflow: hidden;
    .S-drawer__body {
      overflow: hidden;
    }
    .S-drawer__normal-footer {
      margin-top: 0;
      height: auto;
      line-height: normal;
      padding: .2133rem .32rem 1.12rem .32rem;
    }
  }
  &__header {
    position: relative;
    color: #000;
    font-weight: 700;
    font-size: .4533rem;
    .left-icon {
      position: absolute;
      top: 50%;
      left: .32rem;
    }
  }
  &__card--block{
    position: relative;
    // overflow: hidden;
    overflow-y: scroll;
    padding: .32rem;
    height: calc(100% - 1.28rem);
    .card {
      margin-bottom: .32rem;
    }
  }
}
</style>
