import type { AS_MessageBar } from '../../../types'

const standard: AS_MessageBar.AnalysisFuncs = {
  'click_news.comp_message-bar': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_news.comp_message-bar': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
