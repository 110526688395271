import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
// import { debuggerLog } from '../../utils'
import { GOOGLE_PAY_CHANNEL, DEFAULT_CARD_NETWORKS, DEFAULT_AUTH_METHODS } from '../../config/google_pay'
import { MonitorReportAbnormal, MonitorReportBusiness } from '../helpers/MonitorReport'
import { asyncLoadFile } from '@shein/common-function'
import { debuggerLog } from '../../utils'
import { ThreeDSecureManager } from '../helpers/ThreeDSecureManager'
import { PAYMENT_ACTION_TYPE } from '../../../types'
// import { useEventBus } from './useEventBus'

interface IOrderInfo {
  billno: string
  currency_code?: string
  currencyCode?: string
  countryCode?: string
  payment_method: string
  totalPriceAmount: string
  totalPriceWithGovTaxAmount?: string
}

interface IGooglePayParams {
  order: IOrderInfo
  // applePay前置路由信息
  preRoutingInfo?: {
    googlePayMerchantId: string
    brand: string
    googleKey: string
  },
  cardLogoList?: string[]
}

interface IGooglePayInfoParams {
  paymentCode: string
  billno: string
}

interface IGooglePayInfo {
  googlepayMerchantId?: string
  merchantId?: string
  brand?: string
  worldpayMerchantId?: string
  gatewayMerchantId?: string
}

interface IGooglePayInfoRes {
  code: string
  info: IGooglePayInfo
}

interface IGooglePayConfig {
  googlePayCodeList?: string[]
  useApollo?: boolean
  cardLogoConfig?: Record<string, {
    logo: string
    name: string
  }>
  readyToPayRequestData?: any
  mapChannelToGooglePaySupport?: Record<string, {
    gateway: string
    allowedAuthMethods: string[]
    type: string
  }>;
  environment?: 'PRODUCTION' | 'TEST'
}

interface ISchttpEventHandle {
  success: (data: any) => void
  error: (error: any) => void
}

const renderId = 'bs-sdk-google-container'

interface GooglePayManagerConstructor extends BasicPayManagerConstructor { }

export class GooglePayManager extends BasicPayManager implements AbstractMethods {
  public static PAYMENT_CODE = ''

  public static ltspcUrl: string = ''

  private static paymentsClient

  private static payConfig: IGooglePayConfig = {}

  private static openDialogMethod: (({ handleOpen, handleClose }) => () => void) | null = null

  private static apiVersionCache = 2
  private static apiVersionMinorCache = 0
  private merchantIdCache = ''
  private merchantNameCache = ''
  private gatewayMerchantIdCache = ''
  private cancelDialogMethod: (() => void) | null = null

  private hasCreateBtn = false

  // private resolve

  // private reject

  constructor(params: GooglePayManagerConstructor) {
    super(params)

    // this.resolve = null
    // this.reject = null
  }

  private _sendReport = () => { }

  static get googlePayCodeList () {
    return this.payConfig.googlePayCodeList || GOOGLE_PAY_CHANNEL
  }

  private getGooglePayInfoByCode (params: IGooglePayInfoParams, handles: ISchttpEventHandle) {
    const url = params.paymentCode === 'worldpay-googlepay' ? '/api/pay/worldpay/gp/config/get' : '/api/checkout/newGooglepayConfig/get'
    return this.appConfigs.$schttpSync<IGooglePayInfoRes>({
      url,
      method: 'GET',
      params: { billno: params.billno, paymentCode: params.paymentCode },
      baseURL: this.appConfigs.$envs?.langPath,
      success: (res: any) => {
        handles.success(res)
        if (+res?.code !== 0 || !res?.info) {
          MonitorReportAbnormal.metric({
            scene: 'google_pay_config_error',
            extraTags: {
              failure_type: 'api',
              payment_code: params?.paymentCode,
              status_code: res?.code,
            },
            extraParams: {
              billno: params?.billno,
              client_url: url,
              description: `获取gp配置信息失败 ${res?.tips || res.msg || ''}`,
              payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
            },
          })
        }
      },
      error: (error: any) => {
        handles.error(error)
        MonitorReportAbnormal.metric({
          scene: 'google_pay_config_error',
          extraTags: {
            failure_type: 'api',
            payment_code: params?.paymentCode,
          },
          extraParams: {
            billno: params?.billno,
            client_url: url,
            description: `获取gp配置信息失败 ${error?.message || ''}`,
            payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
          },
        })
      },
    })
  }

  private static getGooglePaymentsClient () {
    if (!window) return null
    if (!this.paymentsClient && typeof (window as any).google !== 'undefined') {
      // const enviroment = ['localhost', 'debug'].includes('') ? 'TEST' : 'PRODUCTION'
      this.paymentsClient = new google.payments.api.PaymentsClient({
        environment: this.payConfig.environment || 'PRODUCTION',
      })

    }
    return this.paymentsClient
  }

  private static loadGooglePayFile = () => {
    if ((window as any).google?.payments) return Promise.resolve()
    return new Promise(resolve => {
      asyncLoadFile({
        label: 'script',
        attrs: {
          src: 'https://pay.google.com/gp/p/js/pay.js',
        },
      }).then(() => {
        const loadGoogleInterval = setInterval(() => {
          if (google.payments?.api) {
            resolve(true)
          }
          window.clearInterval(loadGoogleInterval)
        }, 200)
      })
    })
  }

  static getGoogleIsReadyToPayRequest () {
    const { useApollo, readyToPayRequestData } = this.payConfig
    if (useApollo && readyToPayRequestData) {
      return readyToPayRequestData
    } else {
      return {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [{
          type: 'CARD',
          parameters: {
            allowedAuthMethods: DEFAULT_AUTH_METHODS,
            allowedCardNetworks: DEFAULT_CARD_NETWORKS,
          },
        }],
      }
    }
  }

  _getGatewayName (paymentCode: string) {
    const { useApollo, mapChannelToGooglePaySupport } = GooglePayManager.payConfig
    if (useApollo && mapChannelToGooglePaySupport && mapChannelToGooglePaySupport[paymentCode]) {
      return mapChannelToGooglePaySupport[paymentCode].gateway
    } else {
      switch (paymentCode) {
        case 'adyen-googlepay':
          return 'adyen'
        case 'nuvei-googlepay':
          return 'nuveidigital'
        case 'worldpay-googlepay':
          return 'worldpay'
        default:
          break
      }
    }
  }

  _getAllowedCardNetworks (cardLogoList?: string[]) {
    const { useApollo, cardLogoConfig } = GooglePayManager.payConfig
    if (useApollo) {
      const supportsCard: string[] = []
      cardLogoList?.forEach(item => {
        const cardInfo = cardLogoConfig?.[item]
        if (cardInfo && cardInfo.name) {
          supportsCard.push(cardInfo.name)
        }
      })
      if (supportsCard.length) {
        return supportsCard
      } else {
        return DEFAULT_CARD_NETWORKS
      }
    } else {
      return DEFAULT_CARD_NETWORKS
    }
  }

  getGooglePaymentDataRequest (orderInfo: IOrderInfo, cardLogoList?: string[]) {
    const { useApollo } = GooglePayManager.payConfig

    let totalPrice = orderInfo.totalPriceAmount
    if (orderInfo.totalPriceWithGovTaxAmount) {
      totalPrice = orderInfo.totalPriceWithGovTaxAmount
    }

    const merchantInfo = {
      merchantId: this.merchantIdCache,
      merchantName: this.merchantNameCache,
    }

    const transactionInfo = {
      totalPriceStatus: 'FINAL',
      totalPrice: '' + totalPrice,
      countryCode: orderInfo.countryCode || 'US',
      currencyCode:
        orderInfo.currency_code || orderInfo.currencyCode || '',
    }

    if (useApollo) {
      const apiVersion = GooglePayManager.apiVersionCache
      const apiVersionMinor = GooglePayManager.apiVersionMinorCache
      const { allowedAuthMethods = DEFAULT_AUTH_METHODS, type = 'CARD' } = GooglePayManager.payConfig?.mapChannelToGooglePaySupport || {}
      const allowedCardNetworks = this._getAllowedCardNetworks(cardLogoList)
      return {
        apiVersion,
        apiVersionMinor,
        merchantInfo,
        allowedPaymentMethods: [
          {
            type,
            parameters: {
              allowedAuthMethods,
              allowedCardNetworks,
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: this._getGatewayName(orderInfo.payment_method),
                gatewayMerchantId: this.gatewayMerchantIdCache,
              },
            },
          },
        ],
        transactionInfo,
      }
    } else {
      return {
        apiVersion: 2,
        apiVersionMinor: 0,
        merchantInfo,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: DEFAULT_AUTH_METHODS,
              allowedCardNetworks: DEFAULT_CARD_NETWORKS,
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: this._getGatewayName(orderInfo.payment_method),
                gatewayMerchantId: this.gatewayMerchantIdCache,
              },
            },
          },
        ],
        transactionInfo,
      }
    }
  }

  private async processPayment (paymentData, params: IGooglePayParams) {
    const { order } = params
    const res = paymentData
    let token = res?.paymentMethodData?.tokenizationData?.token

    if (order.payment_method == 'nuvei-googlepay') {
      try {
        token = JSON.stringify(res?.paymentMethodData || {})
      } catch{
        return ''
      }
    }

    if (token) {
      const formData: any = {
        googlePayInfo: token,
      }

      if (order.payment_method == 'nuvei-googlepay') {
        Object.assign(formData, { javaScriptEnabled: 1 })
      }

      this.updateUnifiedPayParams({ ...formData })
      this.paymentLoadingAction?.({ show: true })
      const { status, result } = await this.handleUnifiedPay()
      if (status === 'success') {
        this.onSuccess?.(result as any)
      } else if (status === 'continue') {
        const { action, paramList, actionUrl } = result?.info || {}
        if (action === 'render' && paramList && actionUrl) {
          ThreeDSecureManager.makeForm({
            params: paramList,
            action: actionUrl,
          })
          MonitorReportBusiness.metric({
            scene: 'googlepay_pay_payment_continue_render',
            extraTags: {
              payment_code: this.unifiedPayParams.paymentCode,
            },
            extraParams: {
              billno: this.unifiedPayParams.billno,
              actionUrl,
              paramList: JSON.stringify(paramList || {}),
              action,
              payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
            },
          })
        } else {
          MonitorReportAbnormal.metric({
            scene: 'googlepay_pay_payment_continue_error',
            extraTags: {
              payment_code: this.unifiedPayParams.paymentCode,
            },
            extraParams: {
              billno: this.unifiedPayParams.billno,
              error_msg: '支付状态为continue',
              actionUrl,
              paramList: JSON.stringify(paramList || {}),
              action,
              payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
            },
          })
        }
      } else {
        this.onError?.(result as any)
      }
      this.paymentLoadingAction?.({ show: false })
    } else {
      this.paymentLoadingAction?.({ show: false })
      this.onFail?.('google pay token is empty')
      MonitorReportAbnormal.metric({
        scene: 'google_pay_token_empty',
        extraTags: {
          failure_type: 'api',
          payment_code: this.unifiedPayParams.paymentCode,
        },
        extraParams: {
          billno: this.unifiedPayParams.relation_billno || this.unifiedPayParams.billno,
          payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
        },
      })
    }
  }

  handleGooglePayPayment (params: IGooglePayParams) {
    window.sa && window.sa?.('send', {
      page_name: 'page_checkout_again',
      activity_name: 'click_google_pay_popup_goto',
      activity_param: '',
    })

    const { order, preRoutingInfo, cardLogoList } = params

    const paymentsClient = GooglePayManager.getGooglePaymentsClient()

    if (!paymentsClient) {
      MonitorReportAbnormal.metric({
        scene: 'google_pay_sdk_error',
        extraTags: {
          failure_type: 'sdk',
          payment_code: order.payment_method,
        },
        extraParams: {
          client_url: '/third/sdk/error',
          description: 'googlePay paymentsClient找不到，调起sdk失败',
          billno: order.billno,
          payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
        },
      })
      this.onCancel?.()
      return
    }

    this.cancelDialogMethod?.()

    this.onPayContinued?.()

    if (preRoutingInfo?.googleKey && preRoutingInfo?.brand && preRoutingInfo?.googlePayMerchantId) {
      this.merchantIdCache = preRoutingInfo.googleKey
      this.merchantNameCache = preRoutingInfo.brand
      this.gatewayMerchantIdCache = preRoutingInfo.googlePayMerchantId
    } else {
      this.getGooglePayInfoByCode({
        paymentCode: order.payment_method,
        billno: order.billno,
      }, {
        success: (res: IGooglePayInfoRes) => {
          if (res && res.code == '0' && res.info) {
            const { googlepayMerchantId = '', merchantId = '', brand = '', worldpayMerchantId = '', gatewayMerchantId = '' } = res.info
            this.merchantIdCache = googlepayMerchantId || merchantId
            this.merchantNameCache = brand
            this.gatewayMerchantIdCache = worldpayMerchantId || gatewayMerchantId
          }
        },
        error: (err) => {
          this.onError?.(err)
        },
      })
    }

    const paymentDataRequest = this.getGooglePaymentDataRequest(order, cardLogoList)

    debuggerLog('google pay debug info------>>>>:', paymentDataRequest)

    paymentsClient
      .loadPaymentData(paymentDataRequest)
      .then((paymentData) => {
        this.processPayment(paymentData, params)
      })
      .catch(err => {
        if (err && err.statusCode == 'CANCELED') {
          window.sa && window.sa?.('send', {
            activity_name: 'click_google_pay_close',
            activity_param: '',
          })

          this.onCancel?.()
          MonitorReportAbnormal.metric({
            scene: 'google_pay_pay_resultcode_cancel',
            extraTags: {
              failure_type: 'web',
              payment_code: order?.payment_method,
            },
            extraParams: {
              billno: order?.billno,
              client_url: '/third/sdk/error',
              description: 'googlePay从google支付第三方sdk页面返回时statusCode为 CANCELED',
              error_msg: err?.message || '',
              payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
            },
          })
        } else {
          console.error(err)
          // todo_sendErrorReport()
          this.onError?.(err)
          MonitorReportAbnormal.metric({
            scene: 'google_pay_pay_resultcode_fail',
            extraTags: {
              failure_type: 'web',
              payment_code: order?.payment_method,
            },
            extraParams: {
              billno: order?.billno,
              client_url: '/third/sdk/error',
              description: `googlePay从google支付第三方sdk页面返回时statusCode为 ${err?.statusCode || 'unknown'}`,
              error_msg: err?.message || '',
              payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
            },
          })
        }
      })
  }

  private startGooglePay (params: IGooglePayParams) {
    debuggerLog('startGooglePay', params, GooglePayManager.openDialogMethod)
    if (!GooglePayManager.openDialogMethod) return
    this.cancelDialogMethod = GooglePayManager.openDialogMethod({
      handleOpen: () => {
        this.addGooglePayButton(params)
        MonitorReportBusiness.metric({
          scene: 'google_pay_open_dialog',
          extraTags: {
            failure_type: 'web',
            payment_code: params?.order?.payment_method,
          },
          extraParams: {
            billno: params?.order?.billno,
            description: 'googlePay弹窗打开',
            payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
          },
        })
      },
      handleClose: (scene = '') => {
        // 自动关闭的弹窗场景，如用户点击gp按钮后自动关闭弹窗
        // 其他场景下目前只有用户主动点击关闭按钮，所以需要执行onCancel和端侧上报
        if (scene === 'autoClose') return
        this.onCancel?.()
        MonitorReportBusiness.metric({
          scene: 'google_pay_click_dialog_close',
          extraTags: {
            failure_type: 'web',
            payment_code: params?.order?.payment_method,
          },
          extraParams: {
            billno: params?.order?.billno,
            description: '用户点击关闭googlePay弹窗',
            payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
          },
        })
      },
    })
  }

  private addGooglePayButton (params: IGooglePayParams) {
    this.paymentLoadingAction?.({ show: false })
    if (this.hasCreateBtn || typeof window == 'undefined') return
    const paymentsClient = GooglePayManager.getGooglePaymentsClient()
    if (paymentsClient) {
      const button = paymentsClient.createButton({
        onClick: this.handleGooglePayPayment.bind(this, params),
        buttonType: 'short',
      })
      const container = document.getElementById(renderId)
      debuggerLog('打印google pay弹窗', container)
      if (container) {
        container.innerHTML = ''
        container.appendChild(button)
        this.hasCreateBtn = true
        // this.onPayPaused?.()
      } else {
        MonitorReportAbnormal.metric({
          scene: 'google_pay_btn_dom_error',
          extraTags: {
            failure_type: 'web',
            payment_code: params?.order?.payment_method,
          },
          extraParams: {
            billno: params?.order?.billno,
            description: 'googlePay按钮找不到容器节点',
            payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
          },
        })
      }
    } else {
      MonitorReportAbnormal.metric({
        scene: 'google_pay_sdk_error',
        extraTags: {
          failure_type: 'sdk',
        },
        extraParams: {
          client_url: '/third/sdk/error',
          description: 'googlePay paymentsClient找不到，调起sdk失败',
          error_scene: 'addGooglePayButton',
          payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
        },
      })
    }
  }

  isGooglePay (paymentCode: string) {
    const googlePayMethods = GooglePayManager.payConfig.googlePayCodeList || GOOGLE_PAY_CHANNEL
    return googlePayMethods.includes(paymentCode)
  }

  static isSupportGooglePay = (cb) => {
    const paymentsClient = this.getGooglePaymentsClient()
    const baseInfo = this.getGoogleIsReadyToPayRequest()
    const { apiVersion = 2, apiVersionMinor = 0 } = baseInfo
    this.apiVersionCache = apiVersion
    this.apiVersionMinorCache = apiVersionMinor
    if (paymentsClient) {
      paymentsClient.isReadyToPay(baseInfo)
        .then((response) => {
          if (response.result) {
            cb && cb(true)
          } else {
            cb && cb(false)
          }
        })
        .catch(err => {
          console.error(err)
          cb && cb(false)
          MonitorReportAbnormal.metric({
            scene: 'google_pay_sdk_error_ready',
            extraTags: {
              failure_type: 'sdk',
            },
            extraParams: {
              client_url: '/third/sdk/error',
              error_msg: err?.message || '',
              payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
            },
          })
        })
    } else {
      cb && cb(false)
      MonitorReportAbnormal.metric({
        scene: 'google_pay_sdk_error',
        extraTags: {
          failure_type: 'sdk',
        },
        extraParams: {
          client_url: '/third/sdk/error',
          description: 'googlePay paymentsClient找不到，调起sdk失败',
          error_scene: 'isSupportGooglePay',
          payment_action_type: PAYMENT_ACTION_TYPE.GOOGLE_PAY,
        },
      })
    }
  }

  static async initGooglePay <T extends IGooglePayConfig>(config: T) {
    await this.loadGooglePayFile()
    const { googlePayCodeList, cardLogoConfig, useApollo, readyToPayRequestData, mapChannelToGooglePaySupport } = config
    this.payConfig.googlePayCodeList = googlePayCodeList || GOOGLE_PAY_CHANNEL,
    this.payConfig.useApollo = useApollo || false
    this.payConfig.cardLogoConfig = cardLogoConfig || {}
    this.payConfig.readyToPayRequestData = readyToPayRequestData
    this.payConfig.mapChannelToGooglePaySupport = mapChannelToGooglePaySupport
    this.payConfig.environment = config.environment === 'TEST' ? 'TEST' : 'PRODUCTION'
  }

  static bindGooglePayDialogMethod (method: ({ handleOpen, handleClose }) => () => void) {
    this.openDialogMethod = method
  }

  public createPayment(): Promise<any> {
    this.paymentLoadingAction?.({ show: true })
    const { unifiedPayParams, channelExtraInfo } = this.payData || {}
    this.startGooglePay({
      order: {
        billno: unifiedPayParams.relation_billno || unifiedPayParams.billno || '',
        currencyCode: channelExtraInfo.currencyCode,
        countryCode: channelExtraInfo.countryCode,
        payment_method: unifiedPayParams.paymentCode,
        totalPriceAmount: channelExtraInfo.orderAmount || '',
      },
      cardLogoList: channelExtraInfo?.card_logo_list || [],
      preRoutingInfo: {
          googlePayMerchantId: this.preRoutingInfo?.merchantInfo?.googlePayMerchantId,
          brand: this.preRoutingInfo?.merchantInfo?.brand,
          googleKey: this.preRoutingInfo?.merchantInfo?.googleKey,
      },
    })
    return Promise.resolve()
  }
}
