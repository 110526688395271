export const withResolvers = () => {
  if(typeof Promise.withResolvers !== 'function'){
    let resolve
    const promise = new Promise(res => {
      resolve = res
    })
    return {
      promise,
      resolve,
    }
  }
  return Promise.withResolvers()
}
