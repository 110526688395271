export const LOCAL_COMMENT_ABT_FLAG_MAP = new Map()
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=B', 1)
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=C', 2)
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=D', 3)
LOCAL_COMMENT_ABT_FLAG_MAP.set('type=E', 4)

// 评论未登陆状态下最多显示数量
export const COMMENT_NO_LOGIN_SHOW_LIMIT = 150

// 评论详情查询数量上限
export const COMMENT_DETAIL_COUNT_UPPER_LIMIT = 1000

export const validStoreCode = store_code => {
  const code = parseInt(+store_code)
  return code && code !== 1
}

export const makeFreeList = list => {
  if (!Array.isArray(list) || !list.length) return []
  return list.map(item => {
    const comment_image = item.imgList.fullImg.concat(item.imgList.detailImg).map(image => {
      return {
        ...image,
        comment_id: 'rp' + image.reportId,
        member_image_middle: image.imgMiddle
      }
    })
    const member_size = {}
    if (item.memberMeasurement) {
      for (const attr in item.memberMeasurement) {
        if (
          [
            'member_weight',
            'member_height',
            'member_bust',
            'member_waist',
            'member_hips',
            'member_brasize'
          ].includes(attr)
        ) {
          member_size[attr] = item.memberMeasurement[attr]
        }
      }
    }

    return {
      ...item,
      page: '1',
      comment_id: 'rp' + item.reportId,
      language_flag: 'en',
      user_name: item.name,
      comment_rank: item.score,
      comment_time: item.addTime,
      like_num: item.likeCount,
      current_member_zan: item.memberIsLike,
      size: item.attrValue,
      color: item.color,
      comment_image,
      member_size,
      member_cat_size: {
        size_info: (item.memberMeasurement && item.memberMeasurement.cat_size) || [],
        member_overall_fit: item.memberOverallFit
      },
      goods_attr: item.sku_sale_attr?.length ? item.attr_language_value : ''
    }
  })
}

/* 评论利益点映射关系 */
// 评价类: https://shein.ltwebstatic.com/svgicons/2024/11/26/17326025651853624134.svg
// 库存类: https://shein.ltwebstatic.com/svgicons/2024/11/26/17326038243247117653.svg
// 价格利益点: https://shein.ltwebstatic.com/svgicons/2024/11/26/17326038572654332422.svg
// quickship: https://shein.ltwebstatic.com/svgicons/2024/11/26/17326039141569076942.svg
// freeship: https://shein.ltwebstatic.com/svgicons/2024/11/26/17326039404162677886.svg
// freereturn noreturn : https://shein.ltwebstatic.com/svgicons/2024/11/26/17326042503948681550.svg
// 火: https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg
export const REVIEW_BENEFIT_MAP = {
  1: {
    color: '',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326039141569076942.svg',
  },
  2: {
    color: '',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326039404162677886.svg',
  },
  3: {
    color: '',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326042503948681550.svg',
  }, // 商品保障类 end
  4: {
    color: '#A86104',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326025651853624134.svg',
  },
  5: {
    color: '#A86104',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326025651853624134.svg',
  },
  6: {
    color: '#A86104',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326025651853624134.svg',
  },
  7: {
    color: '#A86104',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326025651853624134.svg',
  }, // 商品评价类 end
  8: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326038572654332422.svg',
  },
  9: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326038572654332422.svg',
  },
  10: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326038572654332422.svg',
  }, // 商品价格类 end
  11: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326038243247117653.svg',
  },
  12: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326038243247117653.svg',
  }, // 商品库存类 end
  13: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg',
  },
  14: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326042503948681550.svg',
  },
  15: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg',
  },
  16: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg',
  },
  17: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg',
  },
  18: {
    color: '#FA6338',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg',
  } // 用户行为类 end
}
