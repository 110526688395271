<script lang="jsx">
import EmptyNode from './emptyNode'
import ProductItem from 'public/src/pages/components/ccc/home-bff-components/components/product-item-simple/index.vue'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin'
import useProductItemMixin from 'public/src/pages/components/ccc/components/mixins/use-product-item'
import { getAnalysisData } from './utils'

export default {
  name: 'ImmersiveProduct',
  mixins: [mixins, useProductItemMixin],
  props: {
    context: {
      type: Object,
      default: () => {}
    },
    itemConfig: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    positionTypeMap: {
      type: Object,
      default: () => {}
    },
    showRealPrice: {
      type: Function,
      default: () => {}
    },
    textBtnColor: {
      type: Function,
      default: () => {}
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
    isR56: {
      type: Boolean,
      default: false,
    },
    clickProduct: {
      type: Function,
      default: () => {}
    },
    analysisProductItem: {
      type: Function,
      default: () => {}
    },
    bannerIns: {
      type: Object,
      default: () => {}
    }
  },
  render() {
    const { item, index, positionTypeMap, textBtnColor, cssRight, itemConfig, itemLanguage, constantData, context, isR56, clickProduct, analysisProductItem, bannerIns } = this
    const ratio = isR56 ? '5-6' : '3-4'
    const transformStyle = cssRight ? 'rotate(180deg)' : 'none'
    let productList

    if (item?.productsV2) {
      productList = item.productsV2 || []
      // 同时删掉旧数据
      delete item.product_data
    } else {
      // 兜底
      productList = item.product_data?.products ?? []
    }

    return Number(item.bannerType) === 2 ? (
      <div
        class="c-immersiveBanner__products"
        style={{ 'direction': cssRight ? 'rtl' : 'ltr' }}
      >
        {
          positionTypeMap?.[`${item.positionType}`].map((positionKey, typeIndex) => (
            <EmptyNode>
              {
                positionKey === 'text' && (
                  <div
                    key={`${index}_${positionKey}_${typeIndex}`}
                    data-key={`${index}_${positionKey}_${typeIndex}`}
                    class="c-immersiveBanner__text"
                  >
                    {item.subtitle && (
                      <p
                        class="mainTitle"
                        style={{ 'color': textBtnColor(item) }}
                      >
                        { item.subtitle }
                      </p>
                    )}
                    {
                      item.title && (
                        <p
                          class="sub"
                          style={{ 'color': textBtnColor(item), '--sub-font-color': textBtnColor(item) }}
                        >
                          { item.title }
                        </p>
                      )
                    }
                    {
                      item.showButton && item.buttonText && (
                        <p
                          key={`${index}_${positionKey}_${typeIndex}`}
                          data-key={`${index}_${positionKey}_${typeIndex}`}
                          class="btn"
                          style={{ 'color': textBtnColor(item), 'border': `1px solid ${textBtnColor(item)}` }}
                        >
                          <span>{ item.buttonText } </span>
                          <i
                            class="suiiconfont sui_icon_more_right_white_12px"
                            style={{ 'color': textBtnColor(item), 'fontSize': '24px', 'transform': transformStyle }}
                          ></i>
                        </p>
                      )
                    }
                  </div>
                )
              }
              {
                positionKey === 'p1' && productList.length >= 1 && (
                  <div
                    key={`${index}_${positionKey}_${typeIndex}`}
                    data-key={`${index}_${positionKey}_${typeIndex}`}
                    class={{ 'c-immersiveBanner__product': true, 'needMr': typeIndex === 0 || typeIndex === 1, isR56 }}
                  >
                    <ProductItem
                      ref="goodsItem"
                      item={ productList[0] }
                      index={ index }
                      config={ { ...itemConfig, hideAllBottomPrices: !Boolean(+item.showPrice) } }
                      goods-item-info={this.goodsItemInfo}
                      language={ context?.language || {} }
                      constant-data={ constantData }
                      column={ `4.1l` }
                      comp-img-design-width={192}
                      context={ context }
                      css-right={ cssRight }
                      crop-rate={ ratio }
                      lcp={ index === 0 }
                      comp-src={'immersiveBanner'}
                      class={{ 'list-style1__base-avatar': true,
                        'c-immersiveBanner__price-color-black': item.homeDiscountNew === 'noshow' || item.homeDiscountNew === 'compliance',
                        'not-fsp-element': index > 0
                      }}
                      onClickItem={clickProduct}
                      nativeOn={{
                        click: e => e.stopPropagation()
                      }}
                      v-tap={getAnalysisData.call(bannerIns, '2-22-4', { item: analysisProductItem(item), index, useBffApi: true, isJumpToList: true, product: productList[0] })}
                    >
                    </ProductItem>
                  </div>
                 
                )
              }
              {
                positionKey === 'p2' && productList.length >= 2 && (
                  <div
                    key={`${index}_${positionKey}_${typeIndex}`}
                    data-key={`${index}_${positionKey}_${typeIndex}`}
                    class={{ 'c-immersiveBanner__product': true, isR56 }}
                  >
                    <ProductItem
                      ref="goodsItem"
                      item={ productList[1] }
                      index={ index }
                      config={ { ...itemConfig, hideAllBottomPrices: !Boolean(+item?.showPrice) } }
                      goods-item-info={this.goodsItemInfo}
                      language={ context?.language || {} }
                      constant-data={ constantData }
                      column={ `4.1l` }
                      context={ context }
                      css-right={ cssRight }
                      crop-rate={ ratio }
                      lcp={ index === 0 }
                      comp-src={'immersiveBanner'}
                      comp-img-design-width={192}
                      class={{ 'list-style1__base-avatar': true,
                        'c-immersiveBanner__price-color-black': item.homeDiscountNew === 'noshow' || item.homeDiscountNew === 'compliance',
                        'not-fsp-element': index > 0
                      }}
                      onClickItem={clickProduct}
                      nativeOn={{
                        click: e => e.stopPropagation()
                      }}
                      v-tap={getAnalysisData.call(bannerIns, '2-22-4', { item: analysisProductItem(item), index, useBffApi: true, isJumpToList: true, product: productList[1] })}
                    >
                    </ProductItem>
                  </div>
                )
              }
            </EmptyNode>
          ))
        }
      </div>
    ) : ''
  }
}
</script>

