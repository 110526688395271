<!-- eslint-disable vue/no-deprecated-slot-attribute-->
<template>
  <div
    v-if="isShow"
    ref="root"
    class="bsc-cart-item-more-tag"
    :style="style"
    @click="handleShowPopover"
  >
    <template v-if="isClick">
      <SPopover
        v-model="showPopover"
        trigger-type="user"
        show-close-icon
        append-to-body
        :placemen="placemen"
        :prop-style="propStyle"
      >
        <span
          slot="reference"
          class="bsc-cart-item-more-tag__wrap"
        >
          <span class="bsc-cart-item-more-tag__text">{{ text }}</span>
          <sui_icon_doubt_16px_1
            class="bsc-cart-item-more-tag__icon"
            size="12px"
          />
        </span>
        <MoreTagContent
          v-bind="moreTagContentProps"
        />
      </SPopover>
    </template>
    <template v-else>
      <span class="bsc-cart-item-more-tag__wrap">
        <span class="bsc-cart-item-more-tag__text">{{ text }}</span>
        <sui_icon_doubt_16px_1
          class="bsc-cart-item-more-tag__icon"
          size="12px"
        />
      </span>
    </template>
  </div>
</template>

<script name="MorePromotionTag" setup lang="ts">

import { ref, computed, watch, nextTick } from 'vue'
import SPopover from '@shein/sui-mobile/lib/popover'
import sui_icon_doubt_16px_1 from '../../../components/icon/sui_icon_doubt_16px_1.vue'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'
import { useScrollParent } from '../../../hooks/useScrollParent'
import { useEventListener } from '../../../hooks/useEventListener'
import MoreTagContent from './components/more-tag-content.vue'

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['onBeforeClick', 'onExpose'])

interface IProps {
  /**
   * 是否展示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 是否可点击
   */
  isClick: boolean
  /**
   * 文案
   */
  text: string
  /**
   * Popover标题
   */
  popoverTitle: string
  /**
   * 优惠信息
   */
  items: any[]
  // popover props
  placemen?: string
  propStyle?: any
  /**
   * 滚动的父元素
   */
  scroller?: HTMLElement
  /**
   * 最大宽度
   */
  maxWidth?: string
}

const props = withDefaults(defineProps<IProps>(), {
  isShow: false,
  isShowMask: false,
  isClick: true,
  text: '',
  popoverTitle: '',
  items: () => ([]),
  propStyle() {
    return {
      width: '64vw',
      maxWidth: '345px',
      zIndex: Number.MAX_SAFE_INTEGER,
      transform: `translate3d(0,0,${Number.MAX_SAFE_INTEGER}px)`,
    }
  },
  maxWidth: '100%',
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))

const moreTagContentProps = computed(() => ({
  content: {
    title: props.popoverTitle,
    list: props.items,
  },
}))

const showPopover = ref(false)
const handleShowPopover = async () => {
  emit('onBeforeClick', { show: showPopover.value })
  await nextTick()
  showPopover.value = !showPopover.value
  console.log('placemen', props.placemen)
}

const root = ref<HTMLElement>()
const scrollParent = useScrollParent(root)
const scroller = computed(() => props.scroller || scrollParent.value)

const check = () => {
  if (showPopover.value) showPopover.value = false
}

useEventListener('scroll', check, {
  target: scroller,
  passive: true,
})

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('onExpose')
      }
      registerExpose('MoreTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-more-tag {
  display: inline-flex;
  align-items: center;
  height: 16px;
  line-height: 1;
  font-size: 10px;
  color: @sui_color_discount_dark;
  background: rgba(250, 240, 238, 0.71);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  .S-popover {
    max-width: 100%;
  }

  &__wrap {
    display: inline-flex;
    align-items: center;
    padding: 0 6px;
    max-width: 100%;
  }

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__icon {
    margin-left: 2px;
    height: 12px;
    cursor: pointer;
    color: @sui_color_discount_dark;
    opacity: 0.4;
  }

  &__icon-disabled {
    cursor: auto;
  }
}
</style>
