const state = {
  bnplCheckoutInfo: null, // bnpl信息
  renderPaypalBtnStatus: -1, //paypal按钮渲染状态 -1:未渲染 0:渲染失败 1:渲染成功 
  checkoutLabelList: [], // 支付前置标签列表
  cartLureInfo: {}, // 所有购物车下单按钮气泡诱导信息
  assetPopupInfo: {}, // 利诱资产弹窗信息
  currentCartLure: {}, // 当前购物车下单按钮诱导信息
  overLimitTipOnBottom: '', // 购物车底部超限提示
  creditRewardPopupInfo: null,
  showCheckoutBtnPopover: false, // 结算按钮利诱气泡
  showCheckoutFilterBtnPopover: false, // 结算按钮券筛选气泡
}
export default state
