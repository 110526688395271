<template>
  <div
    :class="['recommend-tabs', !tabList.length && $slots.default && 'recommend-tabs__not-tab']"
  >
    <SSticky
      :class="['recommend-tabs__sticky']"
      :type="'fixed'"
      :offset="tabOffsetComp"
      :style="{
        'z-index': 9
      }"
      :fixed-work="fixedWork"
    >
      <div>
        <slot></slot>
        <STab
          v-if="tabList.length"
          v-model="activeTabIndex"
          class="recommend-tabs__body"
          type="auto"
          align="left"
          position="content"
          un-selected-color="#767676"
          @change="handleChange"
        >
          <STabItem
            v-for="(item, index) in tabList"
            :id="item && item.cat_id"
            :key="index"
            v-expose="{id:'1-65-1-4', data: {tab: item && item.cat_id ? String(item.cat_id) : '-'}}"
          >
            {{ item && item.cat_name }}
          </STabItem>
        </STab>
      </div>
    </SSticky>
  </div>
</template>

<script>
import { Tab as STab, TabItem as STabItem, Sticky as SSticky } from '@shein/sui-mobile'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const daEventExpose = daEventCenter.getExposeInstance()
daEventCenter.addSubscriber({ modulecode: '1-65-1' })
export default {
  name: 'RecommendFlowTabs',
  components: {
    STab,
    STabItem,
    SSticky
  },
  directives: {
    expose,
  },
  inject: ['advertisingAnalysis'],
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      }
    },
    isShowSearchInput: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      isFirst: true,
      isMounted: false,
    }
  },
  computed: {
    advertisingAnalysis() {
      return this.$root.advertisingAnalysis
    },
    // 水合之前只展示5个tab
    tabList() {
      return this.isMounted ? this.tabs : this.tabs.slice(0, 5)
    },
    // 参见需求:pageId=1500482628
    tabOffsetComp() {
      // 当页面里有搜索框时，新Offset值计算 (8px（上间距） + 34px(搜索框高))/37.5rem + 1.17rem
      // if(this.isShowSearchInput) {
      //   return ((8 + 34) / (parseFloat(window.document?.documentElement?.style?.fontSize) || 75)) + 1.17
      //   // return `${8 + 34 + (1.17 * parseFloat(window.document.documentElement.style.fontSize))}px`
      // }
      return 1.17
    }
  },
  watch: {
    'tabs.length': {
      handler(val) {
        if (val) {
          this.activeTabIndex = this.tabs[0]?.cat_id
        }
      },
      immediate: true,
    },
  },
  activated() {
    // 重进页面重新曝光
    !this.isFirst && daEventExpose?.resetAll?.()
    this.isFirst = false
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    handleChange({ val }) {
      // 点击埋点
      this.advertisingAnalysis.clickRecommendTabs({
        tab: val || '-',
      })
      this.$emit('tabChange', val)
    },
    fixedWork(work) {
      this.$emit('changeSticky', !!work)
    }
  }
}
</script>

<style lang="less">
.recommend-tabs {
  &__sticky {
    // 组件内部用了style-component设置了z-index=1 意义为何
    z-index: @zindex-header !important; /* stylelint-disable-line declaration-no-important */
  }

  &__not-tab {
    padding-bottom: .21rem;
  }
}
</style>
