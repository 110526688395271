<template>
  <s-drawer
    :visible="show"
    class="discount-detail__drawer"
    @close-from-mask="onClose"
  >
    <div class="discount-detail__body">
      <div class="discount-detail__header">
        <Icon
          name="sui_icon_close_20px"
          size="0.533333rem"
          color="#666"
          class="discount-detail__close"
          @click="onClose"
        />
        <div class="discount-detail__header-content">
          <div class="discount-detail__header-title">
            {{ data.title }}
          </div>
          <div
            v-if="data.titleDesc"
            class="discount-detail__header-sub"
          >
            {{ data.titleDesc }}
          </div>
        </div>
      </div>
      <div class="discount-detail__content">
        <div class="discount-detail__display">
          <div>
            {{ data.estimatedTip }}
            <span class="discount-detail__display-price">{{ data.estimatedPrice }}</span>
          </div>
          <div
            v-if="showCountdown"
            class="discount-detail__display-timer"
          >
            <span class="label">{{ data.endTip }}</span>
            <CartCountdown
              not-hide
              not-reload
              :time-stamp="+data.endTimeStamp"
              font-size="12px"
            />
          </div>
        </div>
        <div
          v-if="show"
          class="discount-detail__checklist"
        >
          <div
            v-if="data.subInfo.desc"
            class="discount-detail__checklist-desc"
          >
            {{ data.subInfo.desc }}
          </div>
          <template
            v-for="(item, index) in data.subInfo.items"
          >
            <component
              :is="CompMap[item.type]"
              :key="index"
              left-tip-bold
              :data="item.data"
              @open-tip="onOpenTip"
            />
          </template>
        </div>

        <UnlockMoreDeals
          v-if="show && unlockMoreDealsData"
          :data="unlockMoreDealsData"
          :sa-data="params.saData"
        />
      </div>
    </div>

    <s-dialog
      :visible.sync="dialogShow"
      append-to-body
    >
      <template slot="title">
        <div v-html="dialogContent"></div>
      </template>
      <template
        slot="footer"
      >
        <s-button
          width="100%"
          :type="['primary', 'H72PX']"
          @click="dialogShow =! dialogShow"
        >
          {{ language.SHEIN_KEY_PWA_15146 }}
        </s-button>
      </template>
    </s-dialog>
  </s-drawer>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from '../../hooks/useStore'
import CartCountdown from '../common/CartCountDownTime.vue'
import ChecklistCell from './components/ChecklistCell.vue'
import ChecklistDropdown from './components/ChecklistDropdown.vue'
import { useRouterLevel } from 'public/src/pages/cart_v2/hooks/useRouterLevel.js'
import { useUpdateModalState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateModalState.js'

const UnlockMoreDeals = () => import(/* webpackChunkName: "cartv2-discount-detail-unlock-more-deals" */'./components/UnlockMoreDeals.vue')

const CompMap = {
  2: ChecklistCell,
  3: ChecklistDropdown,
}

const { state, commit } = useStore()

const currentDate = ref(0)

const isLoaded = ref(false)
const language = computed(() => state.language || {})
const params = computed(() => state.discountDetailDrawerParams)
const data = computed(() => params.value?.data)
const show = computed(() => isLoaded.value && params.value.show)
const showCountdown = computed(() => !!data.value?.endTimeStamp && data.value.endTimeStamp * 1000 > currentDate.value)

const { register, unregister } = useRouterLevel()
const { registerModalState, updateModalState } = useUpdateModalState()
watch(() => show.value, (value) => {
  if (value) {
    registerModalState()
    currentDate.value = Date.now()
    register(() => {
      onClose()
    })
  } else {
    unregister()
    updateModalState()
  }
})

const unlockMoreDealsData = computed(() => {
  return data.value?.subInfo.items.find(item => item.type === '5')?.data
})

const onClose = () => {
  commit('cart_v2/changeDiscountDetailDrawerParams', {
    show: false,
  })
}

const dialogShow = ref(false)
const dialogContent = ref('')
const onOpenTip = (text) => {
  dialogContent.value = text
  dialogShow.value = true
}

onMounted(() => {
  commit('cart_v2/updateState', {
    key: 'loadingShow',
    value: false,
  })
  isLoaded.value = true
})

</script>

<style lang="less" scoped>
.discount-detail {
  &__drawer {
    ::v-deep .S-drawer__container {
      border-top-left-radius: 8/37.5rem;
      border-top-right-radius: 8/37.5rem;
    }
    ::v-deep .S-drawer__body {
      height: 70vh;
      overflow: hidden;
    }
  }

  &__body {
    position: relative;
    padding-bottom: calc(constant(safe-area-inset-bottom));
    padding-bottom: calc(env(safe-area-inset-bottom));
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__close {
    position: absolute;
    top: 8/37.5rem;
    right: 8/37.5rem;
  }

  &__header {
    flex: none;
  }

  &__header-content {
    padding: 12/37.5rem 0;
  }

  &__header-title {
    font-size: 16px;
    font-weight: 510;
    line-height: 20px;
    color: #000;
    text-align: center;
  }

  &__header-sub {
    margin-top: 4/37.5rem;
    padding: 0 8/37.5rem;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #ababab;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__content {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
  }

  &__display {
    margin: 0 auto;
    padding: 7/37.5rem 12/37.5rem;
    width: max-content;
    font-size: 12px;
    line-height: 1;
    color: #FA6338;
    background: #FFECE9;
  }

  &__display-price {
    margin-left: 4/37.5rem;
    font-size: 18px;
    font-weight: 700;
  }

  &__display-timer {
    display: flex;
    align-items: center;
    margin: 4/37.5rem auto 0;
    width: max-content;
    font-size: 10px;
    text-align: center;
    .label {
      margin-right: 4/37.5rem;
    }

    ::v-deep .count-number {
      height: 16/37.5rem;
      line-height: 16/37.5rem;
      border-radius: 2/37.5rem;
      background-color: rgba(#fff, .88);
    }
  }

  &__checklist {
    position: relative;
    margin: 20/37.5rem 16/37.5rem;
    padding: 12/37.5rem 10/37.5rem;
    background: #F6F6F6;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      margin-top: -8/37.5rem;
      margin-left: -8/37.5rem;
      width: 16/37.5rem;
      height: 16/37.5rem;
      background: #F6F6F6;
      transform: rotateZ(45deg);
    }
  }

  &__checklist-desc {
    font-size: 14px;
    font-weight: 400;
    color: #666;
    line-height: normal;
  }
}
</style>
