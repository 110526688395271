<template>
  <div 
    class="product-card__price-suggested"
    :class="{
      'price-right': flipText,
      'price-left': !flipText
    }"
  >
    <template v-if="flipText">
      <span class="suggested-label">{{ labelText || '' }}:</span>

      <del 
        v-if="priceDeleteStyle" 
        class="suggested-price"
      >{{ value && value.amountWithSymbol }}</del>
      <span 
        v-else 
        class="suggested-price"
      >{{ value && value.amountWithSymbol }}</span>
    </template>
    <template v-else>
      <del 
        v-if="priceDeleteStyle" 
        class="suggested-price"
      >{{ value && value.amountWithSymbol }}</del>
      <span 
        v-else
        class="suggested-price"
      >{{ value && value.amountWithSymbol }}</span>

      <span class="suggested-label">{{ labelText || '' }}</span>
    </template>
  </div>
</template>
<script lang="jsx">

/**
 * @file 合规价/划线价
 */

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SuggestedSalePrice',
  functional: true,
  props: {
    value: {
      type: [Object, null],
      required: true,
    },
    labelText: {
      type: String,
      default: '',
    },
    flipText: {
      type: Boolean,
      default: false,
    },
    // 价格是否使用删除线样式
    priceDeleteStyle: {
      type: Boolean,
      default: false,
    }
  },
  render(h, { props }) {
    const { value, labelText, flipText, priceDeleteStyle } = props || {}

    const containerClass = ['product-card__price-suggested']
    if (flipText) {
      containerClass.push('price-right')
    } else {
      containerClass.push('price-left')
    }

    const suggestedPriceDom = () => {
      if (priceDeleteStyle) {
        return (
          <del 
            class="suggested-price"
          >{ value && value.amountWithSymbol }</del>
        )
      } else {
        return (
          <span 
            class="suggested-price"
          >{ value && value.amountWithSymbol }</span>
        )
      }
    }

    const contentDom = () => {
      if (props.flipText) {
        return (
          <div 
            class={containerClass.join(' ')}
          >
            <span class="suggested-label">{ labelText || '' }:</span>

            { suggestedPriceDom() }
          </div>
        )
      } else {
        return (
          <div 
            class={containerClass.join(' ')}
          >
            { suggestedPriceDom() }

            <span class="suggested-label">{ labelText || '' }</span>
          </div>
        )
      }
    }

    
    return contentDom()
  }
})
</script>
<script>
/**
 * @file 合规价/划线价
 */

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SuggestedSalePrice',
  props: {
    value: {
      type: [Object, null],
      required: true,
    },
    labelText: {
      type: String,
      default: '',
    },
    flipText: {
      type: Boolean,
      default: false,
    },
    // 价格是否使用删除线样式
    priceDeleteStyle: {
      type: Boolean,
      default: false,
    }
  },
  setup() {},
})

</script>

<style lang="less" scoped>
.product-card__price-suggested {
  line-height: 1.2em;

  font-size: .266rem;
  font-weight: 400;
  color: #767676;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.price-left .suggested-price{
  padding-right: .053rem;
}
</style>
