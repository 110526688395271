import Vue, { onMounted, ref, defineComponent, h, getCurrentInstance, computed, onUnmounted, watch } from 'vue'
import BBenefitFloor, { BenefitComponentType, useBenefitDrawer } from '@shein-aidc/bs-benefit-floor-mobile-vue2'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'
import Footer from '../../footer/Index.vue'

/**
* 
* @param { viewId } 视窗 id 
* @param { bottomId } 底部组件 id
* @returns 
*/

const BenefitFloorHoc = () => defineComponent({
  props: {
    viewId: {
      type: String,
      default: ''
    },
    bottomId: {
      type: String,
      default: ''
    },
    benefitFloorVisible: {
      type: Boolean,
      default: false
    },
  },

  setup(props) {
    let observer = null
    const { checkout, footerState } = useMapState(['checkout', 'footerState'])
    const { rewardFloorConfig } = useMapGetters(['rewardFloorConfig'])
    const { closeDrawer } = useBenefitDrawer()

    const instance = getCurrentInstance()
    const isTop = ref(false)
    const isVisualArea = ref(false)
    const isMounted = ref(false)
    const isFirstReport = ref(true)
    

    const data = computed(() => checkout.value?.rewardFloor || {})
    const drawerFooterDom = computed(() => (
      h('template', { slot: 'footer' }, [
        h(Footer, { 
          props: { isSimple: true },
        })
      ])
    ))
    const exposedData = computed(() => {
      const { satisfiedRewardList, satisfiedRewardValue } = data.value?.buriedPoint || {}
      if (!satisfiedRewardList?.length) return {}
      return {
        id: 'expose_order_rewards:simple',
        data: {
          orderRewardsType: satisfiedRewardList?.join('_'),
          orderRewardsDetailValue: satisfiedRewardValue
        }
      }
    })

    onMounted(() => {
      isMounted.value = true
      handleBenefitReport()
    })

    onUnmounted(() => {
      document.removeEventListener('scroll', handleIsTop, true)
      if (observer) {
        observer.disconnect()
      }
    })

    const handleBenefitReport = () => {
      const { allRewardList, allRewardValue } = data.value.buriedPoint || {}
      if (!allRewardList?.length) return
      daEventCenter.triggerNotice(({
        id: 'expose_scenesabt:simple',
        data: {
          scenes: 'orderRewards',
          orderRewardsType: allRewardList?.join('_'),
          orderRewardsDetailValue: allRewardValue,
          orderRewardsOriginPath: 'cart'
        }
      }))
    }

    const handleDrawerOpened = () => {
      const { satisfiedRewardList, satisfiedRewardValue } = data.value.buriedPoint || {}
      daEventCenter.triggerNotice(({
        id: 'click_order_rewards:simple',
        data: {
          orderRewardsType: satisfiedRewardList?.join('_'),
          orderRewardsDetailValue: satisfiedRewardValue
        }
      }))
    }

    const addEventListener = () => {
      document.addEventListener('scroll', handleIsTop, true)
    }

    const handleIsTop = () => {
      isTop.value = document.getElementById(props.viewId).getBoundingClientRect().top === 0
    }

    const createStyle = (dom) => {
      const styleElement = document.createElement('style')
      styleElement.innerHTML = `
        .checkout-benefit-fade-enter-active {
          animation: bounce-in .3s;
        }
        .checkout-benefit-fade-leave-active {
          animation: bounce-in .3s;
        }
        @keyframes bounce-in {
          0% {
            opacity: 0;
            // transform: scale(0); 
          }
          50% {
            opacity: 0.5; 
            // transform: scale(1.5);
          }
          100% {
            opacity: 1; 
            // transform: scale(1);
          }
        }
      `
      dom?.appendChild(styleElement)
    }

    const createElement = () => {
      const divDom = document.createElement('div')
      const bottomDom = document.getElementById(props.bottomId)
      const el = new Vue({
        render: h =>
          h('transition', {
            props: { name: 'checkout-benefit-fade' },
            name: 'bounce'
          }, [
            isVisualArea.value ? null :
              h(BBenefitFloor, {
                props: {
                  type: componentType.value,
                  data: data.value,
                },
                style: {
                  display: 'flex',
                  width: '100%',
                  '--arrow-right': '3.05rem'
                },
                on: {
                  afterDrawerOpen: handleDrawerOpened
                },
                directives: [
                  {
                    name: 'expose',
                    value: exposedData.value
                  }
                ]
              },
              [drawerFooterDom.value])
          ])
      }).$mount(divDom).$el
      createStyle(bottomDom)
      bottomDom?.appendChild(el)
    }

    const setVisualArea = (observerDom) => {
      observer = new IntersectionObserver((entries) => {
        isVisualArea.value = isOnlyShowBar.value ? false : (entries[0]?.isIntersecting || false)
      }, {
        threshold: 1
      })
      observer.observe(observerDom)
    }

    watch(
      () => data.value,
      (val) => {
        if (typeof window === 'undefined' || isFirstReport) return
        isFirstReport.value = false
        const { satisfiedRewardList, satisfiedRewardValue } = val?.buriedPoint || {}
        if (!satisfiedRewardList?.length) return
        daEventCenter.triggerNotice({
          id: 'expose_order_rewards:simple',
          data: {
            orderRewardsType: satisfiedRewardList?.join('_'),
            orderRewardsDetailValue: satisfiedRewardValue
          }
        })
      },
      {
        immediate: true,
        deep: true
      }
    )

    watch(
      () => [isMounted.value, footerState.value.isFooterMounted],
      ([isMounted, isFooterMounted]) => {
        if (isMounted && isFooterMounted) {
          const el = instance?.proxy?.$el || null
          handleIsTop()
          addEventListener()
          setVisualArea(el)
          createElement()
        }
      },
      { immediate: true }
    )

    const getComponentType = () => {
      if (isTop.value) {
        return BenefitComponentType.Bar // 1
      } else if (isVisualArea.value) {
        return BenefitComponentType.Floor // 2
      } else {
        return BenefitComponentType.MiniBar // 0
      }
    }

    const componentType = computed(() => getComponentType())
    // 只显示吸底
    const isOnlyShowBar = computed(() => (rewardFloorConfig.value.rewardBarVisible && !rewardFloorConfig.value.rewardFloorVisible))

    // 单一利益点，展示吸底样式
    const floorType = computed(() => {
      if (data.value.guide?.rewardList?.length === 1) return BenefitComponentType.Bar
      return BenefitComponentType.Floor
    })

    return rewardFloorConfig.value.rewardFloorVisible
      ? () => h(
        BBenefitFloor,
        {
          props: {
            type: floorType.value,
            data: data.value,
          },
          on: {
            afterDrawerOpen: handleDrawerOpened
          },
          directives: [
            {
              name: 'expose',
              value: exposedData.value
            }
          ]
        },
        [drawerFooterDom.value]
      ) : isOnlyShowBar.value
        ? () => h('div')
        : null 
  },
})
export default BenefitFloorHoc
