import generateParams from '../generateParams'
import { goodsApiCache } from '../api/bffApi.js'

const getUserBehaviorRequestQueue = ({ requestInfo, newSrcTabPageId }) => {
  const params1 = generateParams(requestInfo, newSrcTabPageId)
  const params = {
    page: params1?.page || 1,
    size: 20 || +params1?.size,
    carrierSubType: params1?.carrierSubType || '',
    contentCarrierId: params1?.contentCarrierId || '',
    entranceGoodsId: params1?.entranceGoodsId || '',
    // _t: Date.now() // 防止获取缓存数据
  }
  const requestQueue = {
    // 商品列表
    goods: goodsApiCache.request({
      type: 'userBehavior',
      params: params
    }),
  }
  return requestQueue
}

export {
  getUserBehaviorRequestQueue
}
