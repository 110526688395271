var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"review-label",class:{
    'main': _vm.styleType == 'main',
    'loading': _vm.loading
  }},[_c('div',{ref:"reviewLabelContent",staticClass:"review-label__container j-expose__review-label__container",class:{
      [`review-label__container-${_vm.reviewTagLine}`]: true,
      'review-label__container-down': _vm.dropdown,
      'show-store-review': _vm.showStoreReview
    },on:{"~transitionend":function($event){return _vm.transitionend.apply(null, arguments)},"~webkitTransitionEnd":function($event){return _vm.transitionend.apply(null, arguments)}}},[_vm._l((_vm.tagList),function(item,index){return [(index < 10)?_c('span',{key:index,ref:"reviewLabelItem",refInFor:true,staticClass:"review-label__item j-expose__review-label__item",class:{ 
          'active': _vm.activeId == item.tagId && !_vm.showLabelColor , 
          'active-label': _vm.activeId == item.tagId && _vm.showLabelColor , 
          'is-ar': _vm.activeId == item.tagId && _vm.cssRight,
          'review-label__item-color': _vm.showLabelColor 
        },attrs:{"data-id":item.tagId,"data-expose-id":`review-label__${item.tagId}`},on:{"click":function($event){$event.stopPropagation();return _vm.handleLabelClick(item.tagId)}}},[_c('span',{staticClass:"j-expose__review-label__con review-label__item-text"},[_vm._v(_vm._s(item.tagName)+" ("+_vm._s(item.tagCmtNumShow)+")")]),_vm._v(" "),_c('transition',{attrs:{"name":"morebtnfade"}},[(index === _vm.currentPositonIndex && _vm.showMoreIconRight)?_c('span',{staticClass:"review-label__new-more",on:{"click":function($event){$event.stopPropagation();return _vm.handleClickMore.apply(null, arguments)}}},[_c('Icon',{attrs:{"name":_vm.dropdown ? 'sui_icon_more_up_16px' : 'sui_icon_more_down_16px',"size":"16px","is-responsive-name":""}})],1):_vm._e()])],1):_vm._e()]})],2),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMoreIcon),expression:"showMoreIcon"}],staticClass:"review-label__more"},[_c('i',{staticClass:"review-label__more-icon iconfont",class:_vm.dropdown ? 'icon-shang' : 'icon-down',on:{"click":_vm.handleClickMore}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }