export const defaultColloctUserInfoDialog = {
  visible: false,
  billno: '', // 缓存订单号，切换订单号时需要重置
  recommendPhoneVisible: false,
  recommendPhone: '',
  phone: '',
  needCollectPhoneNumber: false,
  phoneRules: [],
  paymentCode: '',
  countryCode: '',
  countryId: '',
  phoneCountryNum: '',
}

const state = {
  collectUserDialog: JSON.parse(JSON.stringify(defaultColloctUserInfoDialog)),
}

export default state
