<template>
  <button
    ref="addBtnRef"
    v-ada="{ level: 0, pos: 0 }"
    data-poskey="QuickShow"
    class="productAddBtn j-da-event-box j-add-to-bag add-cart__normal-btn"
    :style="btnStyle"
    @click="handleClick"
  >
    <p
      v-if="addTextInfo.positionTop"
      class="productAddBtn__addText"
      :style="addTextInfo.style"
    >
      {{ addTextInfo.value }}
    </p>
    <div
      v-if="realTimeReady"
      ref="contentRef"
      class="productAddBtn__content"
      :style="{
        ...contentStyle,
        ...contentMargin
      }"
    >
      <p
        v-if="priceInfo"
        class="productAddBtn__price"
        :style="{
          color: priceInfo.style.color,
          fontSize: priceInfo.style.fontSize
        }"
      >
        <span
          v-if="priceInfo.beforeText"
          class="productAddBtn__price-before"
        >
          {{ priceInfo.beforeText }}
        </span>
        <span
          class="productAddBtn__price-price"
          :style="{
            fontWeight: priceInfo.style.fontWeight
          }"
        >
          {{ priceInfo.value }}
        </span>
      </p>

      <p
        v-if="retailPriceinfo"
        v-show="showRetailPrice"
        class="productAddBtn__retailPrice"
      >
        {{ retailPriceinfo }}
      </p>

      <div
        v-if="benefitInfo"
        class="productAddBtn__benefit"
        :style="benefitInfo.style"
      >
        <img
          v-if="showBenefitImg"
          class="productAddBtn__benefit-img"
          :src="benefitInfo.imgSrc"
        />
        <p class="productAddBtn__benefit-text">
          {{ benefitValue }}
        </p>
      </div>
    </div>

    <p
      v-if="addTextInfo.positionBottom"
      class="productAddBtn__addText"
      :style="addTextInfo.style"
    >
      {{ addTextInfo.value }}
    </p>
  </button>
</template>

<script setup name="AddBtn">
import { ref, computed, watch, nextTick } from 'vue'
import { asyncAbtUserAnalysis } from '@shein-aidc/basis-abt-router'
import { daEventCenter } from 'public/src/services/eventCenter'
import { BENEFIT_BENEFI_MAP } from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/constant.js'
import { useMapActions, useMapGetters } from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import { useCountDown } from 'public/src/pages/goods_detail_v2/utils/countDown'
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

const props = defineProps({
  addBtnInfo: {
    type: Object,
    default: () => ({})
  },
  isReview: {
    type: Boolean,
    default: false
  },
})

const { get_now_time } = useMapActions('productDetail/addBag', ['get_now_time'])
const { isShowCountDownMillSec } = useMapGetters('productDetail/price', ['isShowCountDownMillSec'])
const realTimeReady = computed(() => props.addBtnInfo.realTimeReady)
const addTextInfo = computed(() => props.addBtnInfo.addTextInfo)
const priceInfo = computed(() => props.addBtnInfo.priceInfo)
const retailPriceinfo = computed(() => props.addBtnInfo.retailPriceinfo)
const benefitInfo = computed(() => props.addBtnInfo.benefitInfo)
const isCustomization = computed(() => props.addBtnInfo.isCustomization)
const btnStyle = computed(() => props.addBtnInfo.btnStyle)

let timer = ref(null)
const showBenefitImg = computed(() => {
  if (benefitInfo.value?.type === BENEFIT_BENEFI_MAP.countdown) {
    if (timer.value) return !!benefitInfo.value.imgSrc
    return false
  }
  return !!benefitInfo.value.imgSrc
})
const benefitValue = computed(() => {
  if (benefitInfo.value?.type == 'countdown') {
    if (timer.value) {
      let { H, M, S } = timer.value.timeObj
      const secondHundred = timer.value.secondHundred
      return  isShowCountDownMillSec.value ? `${H}:${M}:${S}:${secondHundred}` : `${H}:${M}:${S}`
    }
    return ''
  }
  return benefitInfo.value.value
})

const setBenefitCountDown = async () => {
  await nextTick()
  let endTime = benefitInfo.value.value
  let nowTime = benefitInfo.value.nowTime
  timer.value = useCountDown({ endTime, nowTime, isShowCountDownMillSec: isShowCountDownMillSec.value })
}

watch(() => props.addBtnInfo.goodsId, (val) => {
  if(val) get_now_time()
}, { immediate: true })

watch(() => benefitInfo.value?.type, (val) => {
  if(val === 'countdown') setBenefitCountDown()
}, { immediate: true })

const exposeMainBtn = async () => {
  let abtest = await asyncAbtUserAnalysis({ posKeys: 'QuickShow' })
  daEventCenter.triggerNotice({
    daId: '1-6-1-128',
    bindData: [{
      abtest: abtest.sa,
      review_location: props.isReview ? 'page_review' : '-',
      interestpoints: benefitInfo?.value?.type
    }]
  })
}
watch(() => realTimeReady.value, async (val) => {
  if (val) {
    exposeMainBtn()
    await nextTick()
    computeUi()
  }
}, { immediate: true })

const emits = defineEmits(['clickAddBtn'])
const handleClick = (e) => {
  if (isCustomization.value) {
    daEventCenter.triggerNotice({
      daId: '1-6-1-168',
      bindData: [{ activity_from: 'main' }]
    })
  }
  emits('clickAddBtn', e, benefitInfo.value?.type)
}

const addBtnRef = ref(null)
const contentRef = ref(null)
const contentStyle = ref({
  opacity: 0
})
const showRetailPrice = ref(true)
const computeUi = () => {
  if (benefitInfo.value) {
    contentStyle.value = {
      opacity: 1
    }
    return
  }
  let contentWidth = contentRef.value.offsetWidth
  if (!contentWidth) {
    contentStyle.value = {
      opacity: 1
    }
    return
  }
  let boxWidth = addBtnRef.value?.offsetWidth
  let { paddingLeft, paddingRight } =  window.getComputedStyle(addBtnRef.value)
  let boxPadding = parseInt(paddingLeft) + parseInt(paddingRight)
  if (boxWidth <= boxPadding + contentWidth) { // 主按钮容不下价格dom
    if (priceInfo.value.beforeText) {
      contentStyle.value = {
        display: 'none',
        opacity: 1
      }
      return
    }
    showRetailPrice.value = false
  }
  contentStyle.value = {
    opacity: 1
  }
}
const contentMargin = computed(() => {
  if (addTextInfo.value.positionTop) return { marginTop: '.0533rem' }
  return { marginBottom: '.0533rem' }
})

</script>

<style lang="less">
.productAddBtn {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 1.0667rem;
  padding: 0 .4rem;
  border: none;
  &__addText {
    max-width: 100%;
    line-height: 1;
    font-weight: bold;
    text-align: center;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
  &__content {
    display: flex;
    flex-wrap: nowrap;
    line-height: 1;
  }
  &__price {
    margin-right: .11rem;
    white-space: nowrap;
  }
  &__price-before {
    margin-right: 2px;
  }
  &__price-price {

  }
  &__retailPrice {
    color: @sui_color_gray_light2;
    font-size: 12px;
    text-decoration-line: line-through;
  }
  &__benefit {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 400;
    opacity: 0.80;
  }
  &__benefit-img {
    width: 0.32rem;
    height: 0.32rem;
    margin-left: .16rem;
    margin-right: .0533rem;
  }
  &__benefit-text {

  }
}
</style>
