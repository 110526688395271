var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"low-stock-banner"},[_c('img',{staticClass:"low-stock-banner__icon",attrs:{"src":"https://img.ltwebstatic.com/images3_ccc/2024/12/16/e4/1734316697a93f08d2899f8523ac44f594a8da85a1.png","alt":"low-stock-icon"}}),_vm._v(" "),_c('p',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-6-1-235',
      data: {
        goods_level: _vm.will_sold_out_tips_info.scene,
        label_type: _vm.will_sold_out_tips_info.lowStockType == 1 ? 2 : 1,
        location: 'bottomBanner',
      },
    }),expression:"{\n      id: '1-6-1-235',\n      data: {\n        goods_level: will_sold_out_tips_info.scene,\n        label_type: will_sold_out_tips_info.lowStockType == 1 ? 2 : 1,\n        location: 'bottomBanner',\n      },\n    }"}],staticClass:"low-stock-banner__content"},[_c('span',{staticClass:"low-stock-banner__text"},[_vm._v(_vm._s(_vm.selectedSalesAttr || _vm.language_v2?.SHEIN_KEY_PWA_35553 || 'This item is'))]),_vm._v(" "),_c('span',{staticClass:"low-stock-banner__tips"},[_vm._v("\n      "+_vm._s(_vm.will_sold_out_tips_info.tip)+"\n    ")])]),_vm._v(" "),_c('div',{ref:"refAddBtn",staticClass:"low-stock-banner__button-area",on:{"click":_vm.handleClickButtonArea}},[_c('div',{staticClass:"low-stock-banner__button"},[_c('span',[_vm._v(_vm._s(_vm.language_v2?.SHEIN_KEY_PWA_29463 || 'Add Now'))])])]),_vm._v(" "),_c('div',{staticClass:"low-stock-banner__close",on:{"click":_vm.handleClickClose}},[_c('Icon',{attrs:{"name":"sui_icon_nav_close_24px","color":"#C1C1C1","width":"14px","height":"14px"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }