<template>
  <div
    class="bsc-free-gift"
    :class="{'bsc-free-gift-mini': isMini, 'bsc-free-gift-entry': !isShowEntryBlock}"
  >
    <div
      class="bsc-free-gift__container"
    >
      <FreeGiftItem
        v-for="(item, index) in rewardItem?.freeGift?.goodList"
        :key="index"
        :style="calcuTranslate(index)"
        :giftItem="item"
        :isMini="isMini"
      />
      <div class="bsc-free-gift__container_corner-num">{{ rewardItem?.freeGift?.goodNum }}</div>
    </div>
    <div
      v-if="isShowEntryBlock"
      class="bsc-free-gift__desc"
    >{{ rewardItem?.desc }}</div>
  </div>
</template>
<script setup lang="ts">
  import {computed} from 'vue'
  import { template as _commonTemplate } from '@shein/common-function'
  import FreeGiftItem from './free-gift-item.vue'
  import { E_BenefitFloor, C_BenefitFloor } from '../../../../types'
  type propsType = {
    rewardItem?: C_BenefitFloor.RewardItem,
    isInDrawer?: boolean,
    isMini?: boolean,
    benefitComponentType?: E_BenefitFloor.BenefitComponentType,
  }
  const props = withDefaults(defineProps<propsType>(), {
    isInDrawer: false,
    isMini: false,
    benefitComponentType: E_BenefitFloor.BenefitComponentType.Bar,
  })

  const isShowEntryBlock = computed(() => {
    return props?.benefitComponentType === E_BenefitFloor.BenefitComponentType.Floor
  })

  const calcuTranslate = (index) => {
    const leftNum = (props.isMini ? 4 / 37.5 : 6 / 37.5) * index
    const position = `
      transform: translate3d(0, 0, 0) rotate(0deg) scale(${1 - 0.086 * index});
      position: absolute;
      left: ${leftNum}rem;
      top: 0;
      z-index: ${index == 0 ? 10 : 10 - index};
      opacity: ${index == 1 ? 0.7 : index == 2 ? 0.6 : 1}
    `
    return position
  }

</script>
<style lang="less">
  .bsc-free-gift {
    display: flex;
    width: 134/37.5rem;
    height: 56/37.5rem;
    padding: 8/37.5rem;
    justify-content: center;
    align-items: center;
    gap: 8/37.5rem;
    border-radius: 8px;
    border: 1/37.5rem solid rgba(255, 255, 255, 0.90);
    background: linear-gradient(97deg, rgba(255, 197, 130, 0.20) 9.36%, rgba(255, 255, 255, 0.20) 91.53%), #FFF;
    box-shadow: 0px 2px 4px 0px rgba(140, 8, 0, 0.08);
    &__container{
      flex: 1;
      position: relative;
      height: 100%;
      &_corner-num {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 12;
        width: 10/37.5rem;
        height: 10/37.5rem;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2.79/37.5rem 0;
        background: #873C00;
        color: #FFF;
        text-shadow: 0px 0px 2.571/37.5rem rgba(228, 53, 14, 0.63);
        font-family: "SF Pro";
        font-size: 9px;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
      }
    }
    &__desc {
      flex: 1;
      color: #FA6338;
      font-family: "SF Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 1000;
      line-height: normal;
      line-height: 1;
    }
  }
  .bsc-free-gift-entry {
    padding: 0;
    width: 45/37.5rem;
    height: 36/37.5rem;
    border: none;
    box-shadow: none;
    background: none;
  }
  .bsc-free-gift-mini {
    padding: 0;
    width: 30/37.5rem;
    height: 24/37.5rem;
    border: none;
    box-shadow: none;
    background: none;
  }
</style>
