<script lang="jsx">
/**
 * @file 主图信息角标 - 左上、左下、右上、右下
 */

const seriesLabels = ['series_image', 'series_jointly_image']
const brandLabels = ['brand_image', 'brand_jointly_image']
const ignoreLabels = ['color_information', 'lower_right_attr_labels', undefined]

export default {
  name: 'ItemLocateLabels',
  inject: {
    constantData: {
      default: () => ({}),
    },
    multiColumn: {
      default: false
    }
  },
  props: {
    lazyImg: {
      type: String,
      default: '',
    },
    imgDesignWidth: {
      type: Number,
      default: 0,
    },
    locateLabels: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    wrapperCls() {
      return {
        'product-card-is-multi': this.multiColumn,
      }
    },
    processedLabel() {
      return this.getLabelItems(this.locateLabels)
    },
  },
  methods: {
    getLabelItems(labels) {
      const { GB_cssRight } = this.constantData
      const data = labels || {}
      const result = {
        upperLeft: {
          key: 'upperLeft',
          list: [],
          cls: { 'product-card-upperleft': true, 'locate-labels-layout': true },
        },
        upperRight: {
          key: 'upperRight',
          list: [],
          cls: { 'product-card-upperright': true, 'locate-labels-layout': true },
        },
        lowerLeft: {
          key: 'lowerLeft',
          list: [],
          cls: { 'product-card-lowerleft': true, 'locate-labels-layout': true },
        },
        lowerRight: {
          key: 'lowerRight',
          list: [],
          cls: { 'product-card-lowerright': true, 'locate-labels-layout': true },
        },
      }
      Object.keys(data).forEach(key => {
        if (!Object.values(data[key] || {}).length) {
          return
        }
        const materialValueKey = data[key].materialValueKey
        const isImgType = data[key].subscriptType === 'image' || [...seriesLabels, ...brandLabels].includes(materialValueKey)
        let icon = data[key].icon || ''

        // 忽略的标签 如: color_information 色块信息
        if (ignoreLabels.includes(materialValueKey)) {
          return
        }

        if (seriesLabels.includes(materialValueKey)) {
          icon = GB_cssRight ? data[key].corner_img_right : data[key].corner_img_left
        } else if (brandLabels.includes(materialValueKey)) {
          icon = data[key].corner_img_left
        }
        const labelText = data[key].labelLang || data[key].labelLangEn
        const labelObj = {
          ...data[key],
          key,
          isImgType,
          icon,
          labelText,
          // 是否可以显示兜底插槽的内容
          showBackup: isImgType ? !icon : !labelText
        }
        switch (key) {
          case 'UPPER_LEFT_LABEL':
            result.upperLeft.list.push(labelObj)
            isImgType && (result.upperLeft.cls['product-locatelabels-img-wrapper'] = true)
            break
          case 'UPPER_RIGHT_LABEL':
            result.upperRight.list.unshift(labelObj)
            isImgType && (result.upperRight.cls['product-locatelabels-img-wrapper'] = true)
            break
          case 'upperRightFlexibleLabel':
            // upperRightFlexibleLabel固定在UPPER_RIGHT_LABEL后面
            result.upperRight.list.push(labelObj)
            isImgType && (result.upperRight.cls['product-locatelabels-img-wrapper'] = true)
            break
          case 'LOWER_LEFT_LABEL':
            result.lowerLeft.list.push(labelObj)
            isImgType && (result.lowerLeft.cls['product-locatelabels-img-wrapper'] = true)
            break
          case 'LOWER_RIGHT_LABEL':
            result.lowerRight.list.push(labelObj)
            isImgType && (result.lowerRight.cls['product-locatelabels-img-wrapper'] = true)
            break
          default:
            break
        }
      })
      return Object.values(result).filter(item => item.list.length)
    },
    getTextStyle(item) {
      const style = item.borderColor ? {
        border: `1px solid ${item.borderColor}`,
      } : {}
      return {
        ...style,
        backgroundColor: item.backgroundColor,
        color: item.fontColor,
      }
    },
    getTextCls(item) {
      return {
        'product-locatelabels-text': true,
        'product-locatelabels-member': true,
        // 'ul-flexible-label': 'upperLeftFlexibleLabel' === item.key,
        'ur-flexible-label': 'upperRightFlexibleLabel' === item.key,
      }
    },
  },
  render() {

    const renderItem = (item) => {
      return item.showBackup 
        ? this.$scopedSlots?.[item.key]?.(item)
        : (item.isImgType ?
          <img
            key={item.key + 'img'}
            src={this.lazyImg}
            data-src={item.icon}
            data-design-width={this.imgDesignWidth}
            class="lazyload product-locatelabels-img product-locatelabels-member"
            tabindex="0"
            role="img"
          /> : <div
            key={item.key + 'text'}
            class={this.getTextCls(item)}
            style={this.getTextStyle(item)}
          >
            {item.labelText}
          </div>)
    }
    const renderList = (labelItem) => {
      return <div key={labelItem.key} class={labelItem.cls}>
        {labelItem.list.map(item => renderItem(item))}
      </div>
    }

    return <div class={this.wrapperCls} data-utid="product-card-locate-labels">
      {this.processedLabel.map(labelItem => renderList(labelItem))}
    </div>
  },
}
</script>

<style lang="less" scoped>
.locate-labels-layout {
  display: flex;
  flex-direction: column;

  .product-locatelabels-member + .product-locatelabels-member {
    margin-top: 4px;
  }

  .ur-flexible-label {
    border-radius: 30px;
    padding: 0 4px;
    margin-right: 4px;
  }
}

.product-card-upperleft {
  position: absolute;
  top: 4px;
  left: 0
    /* rtl: ignore */
  ;
}

.product-card-upperright {
  position: absolute;
  top: 4px;
  right: 0
    /* rtl: ignore */
  ;
  pointer-events: none;
  align-items: flex-end;

  .product-locatelabels-img + .ur-flexible-label {
    // 122/375 * 0.7 * 100%
    margin-top: -12%;
  }
}

.product-card-lowerleft {
  position: absolute;
  bottom: 4px;
  left: 0
    /* rtl: ignore */
  ;
}

.product-card-lowerright {
  position: absolute;
  bottom: 4px;
  right: 0
    /* rtl: ignore */
  ;
}

.product-locatelabels-text {
  display: inline-block;
  line-height: 1.35;
  font-size: 10px;
  text-align: center;
  padding: 2px 5px;
  background-color: #333;
  color: #fff;
  min-width: 0.89rem;
}

/* stylelint-disable-next-line selector-class-pattern */
.product-card-is-multi {
  .product-locatelabels-text {
    font-size: 9px;
    padding: 1px 2px;
  }
}

.product-locatelabels-img {
  width: 100%;
  height: auto;
  pointer-events: none;
}

/* stylelint-disable-next-line selector-class-pattern */
.product-locatelabels-img-wrapper {
  &.product-card-lowerright {
    bottom: 0px;
  }
  &.product-card-lowerleft {
    bottom: 0px;
  }
  &.product-card-upperright {
    top: 0px;
  }
  &.product-card-upperleft {
    top: 0px;
  }
}
</style>
