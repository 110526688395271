export default {
  updateAddressBarState({ addressBarState }, payload) {
    Object.assign(addressBarState, payload)
  },
  // 请求checkout接口后，需要更新地址的信息
  updateAddressStateAfterCheckout({ addressBarState }, payload) {
    const { address = {}, address_tip, nationalIdTip, addressCount = 0 } = payload.checkout || {}
    
    Object.assign(addressBarState, {
      addressCount,
      nationalIdTip,
      defaultAddress: address,
      addressTip: address_tip,
    })
  },
}
