<template>
  <s-drawer
    :visible="visible"
    max-size="80vh"
    direction="btt"
    append-to-body
    :close-on-click-modal="false"
    @close-from-mask="closeModal"
  >
    <div class="terms-modal__content">
      <TermsBanner :language="language" />

      <div class="terms-modal__form">
        <div class="terms-modal__form--title">
          {{ language?.SHEIN_KEY_PWA_35499 || `Wath's your age` }}
        </div>

        <div class="terms-modal__form--age">
          <SPicker
            ref="refPicker"
            :columns="pickerColumns"
            :default-index="1"
            @change="onPickerChange"
          />
        </div>

        <TermsVerify
          ref="refTermsVerify"
          :language="language"
          :status="pickerStatus"
        />
      </div>
    </div>

    <div class="terms-modal__footer">
      <TermsAgree 
        :language="language"
        :is-agree="isAgree"
        :status="pickerStatus"
        :child-terms="childTerms"
        @change="onAgreeChange"
      />
      <div class="terms-modal__footer--btn">
        <s-button
          class="cancel-btn"
          :type="['H80PX']"
          @click="closeModal"
        >
          {{ language.SHEIN_KEY_PWA_16092 }}
        </s-button>
        <s-button
          class="save-btn"
          :type="['primary', 'H80PX']"
          :show-disabled="isDisabledBtn"
          @click="onSave"
        >
          {{ language.SHEIN_KEY_PWA_32897 }}
        </s-button>
      </div>
    </div>
  </s-drawer>
</template>

<script>
export default {
  name: 'TermsModal'
}
</script>

<script setup name="TermsModal">
import { ref, computed } from 'vue'
import { Button as SButton, Picker as SPicker, Toast as $toast } from '@shein/sui-mobile'
import TermsBanner from './TermsBanner.vue'
import TermsVerify from './TermsVerify.vue'
import TermsAgree from './TermsAgree.vue'
import { PICKER_STATUS } from './const.js'
import { addChildInfo } from 'public/src/pages/components/FilterBar/components/kids-form-modal/api.js'

const props = defineProps({
  childList: { type: Array, default: () => [] },
  language: { type: Object, default: () => ({}) },
  childTerms: { type: Object, default: () => ({}) },
})
const emits = defineEmits(['close', 'save'])

const visible = ref(true)
const refTermsVerify = ref(null)

const pickerIndex = ref(1)
const pickerStatus = computed(() => {
  if (pickerIndex.value == 0) return PICKER_STATUS.ERROR
  if (pickerIndex.value == 1) return PICKER_STATUS.DEFAULT
  
  return PICKER_STATUS.SUCCESS
})

const pickerColumns = computed(() => {
  const maxAge = 99 // 最大99
  const minAge = Number(props.childTerms?.adult_age || 18)
  const columns = [
    `${props.language.SHEIN_KEY_PWA_35500 || 'Under'} ${minAge}`,
    '--',
    ...Array.from({ length: maxAge - minAge + 1 }, (_, i) => i + minAge)
  ]

  return columns
})
const onPickerChange = ([{ value, index }]) => {
  pickerIndex.value = index
  refTermsVerify.value?.onHidePopover?.()
}

const isAgree = ref(false)
const onAgreeChange = flag => isAgree.value = flag
const closeModal = () => {
  visible.value = false
  emits('cancel')
}

const isDisabledBtn = computed(() => pickerStatus.value !== PICKER_STATUS.SUCCESS || !isAgree.value)
const onSave = async () => {
  if (isDisabledBtn.value) {
    if (pickerStatus.value !== PICKER_STATUS.SUCCESS) {
      $toast(props.language.SHEIN_KEY_PWA_35623 || `Sorry, the age is invalid.`)
    } else if (!isAgree.value) {
      $toast(props.language.SHEIN_KEY_PWA_35515 || `Before we process your children's data, we need to obtain your consent.	`)
    }
    
    return
  }

  const adult_age = pickerColumns.value[pickerIndex.value]
  const data = await addChildInfo({ 
    adult_age,
    child_list: props.childList,
  })

  const { code, msg } = data
  // 新增合规条款 & 儿童信息都失败 - 不关闭当前弹窗
  if (code != 0) {
    $toast(props.language.SHEIN_KEY_PWA_32889)
    return
  }

  // 新增合规条款 & 儿童信息都成功
  if (code == 0 && msg === 'ok') {
    visible.value = false
    data?.info?.child_id_list?.forEach?.((id, index) => props.childList[index].child_id = id)
    emits('success', { adult_age })
    return
  } 

  // 新增合规条款成功，儿童信息失败
  if (code == 0 && msg !== 'ok') {
    $toast(props.language.SHEIN_KEY_PWA_35512)
    emits('error', { adult_age })
    visible.value = false
    return
  }
}
</script>

<style scoped lang="less">
.terms-modal {
  &__content {
    width: 100%;
    background: linear-gradient(to bottom, #ebfaf2, #fafafa);
    padding: 0 12px 18px 12px;
  }

  &__form {
    margin-top: 18px;
    background: #fff;
    padding: 24px 0px 18px 0;
  }

  &__form--title {
    color: var(---sui_color_gray_dark1, #000);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__form--age {
    /deep/ .S-picker-column__item {
      height: 21px;
      padding: 2px 0;
      color: var(---sui_color_black_alpha30, rgba(0, 0, 0, 0.30));
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      margin: 0 auto;

    }
    /deep/ .S-picker-column__item.active{
      height: 32px;
      padding: 6px 0;
      color: var(---sui_color_brand, #000);
      font-size: 16px;
      font-weight: 700; 
    }
    /deep/ .S-picker__frame {
      height: 32px;
      width: 238px;
      margin: 0 auto;

      &::after {
        border: none;
        width: 238px;
        background: rgba(0, 0, 0, 0.04);
        transform: scale(1);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__footer {
    padding: 8px 12px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    background: #fff;
  }

  &__footer--btn {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;


    .cancel-btn {
      width: calc(50% - 6px);
    }

    .save-btn {
      width: calc(50% - 6px);
    }
  }
}
</style>
