var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'nav-bar__button': true,
    'nav-bar__button-active': _vm.active,
  },style:(_vm.styles),attrs:{"role":"tab","aria-label":_vm.label},on:{"click":_setup.onClick}},[_c('span',{staticClass:"nav-bar__label"},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),(_vm.icon)?_c('i',{class:[
      _vm.icon,
      'suiiconfont',
      'nav-bar__button-icon'
    ]}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }