<template>
  <div
    class="productEstiamtedTagOld"
    :style="{
      ...strategyStyle,
      ...containerStyle
    }"
    @click.stop="emits('clickEstimatedTag')"
  >
    <!-- N件到手价 -->
    <template v-if="isNEstiamted">
      <p
        class="productEstiamtedTagOld__font-12px"
      >
        {{ buyMultipleText.left }}
      </p>
      <div
        class=" productEstiamtedTagOld__vertical"
        :style="{ background: strategyStyle.color }"
      ></div>
      <p
        class="productEstiamtedTagOld__font-10px"
        v-html="buyMultipleText.right"
      >
      </p>
    </template>

    <!-- 合规划线价到手价（满足\凑单） + 满足门槛到手价 -->
    <template v-else-if="isCompliance || isSatisfy">
      <p
        class="productEstiamtedTagOld__font-14px"
        v-html="mainPrice"
      ></p>
      <div
        class=" productEstiamtedTagOld__vertical"
        :style="{
          background: strategyStyle.color
        }"
      ></div>
      <p class="productEstiamtedTagOld__font-10px productEstiamtedTagOld__text-overflow2">
        {{ text }}
      </p>
    </template>

    <!-- 凑单到手价 -->
    <template v-else-if="isNoSatisfy">
      <p class="productEstiamtedTagOld__font-12px">
        {{ mainPrice }}
      </p>
      <!-- 竖线 -->
      <div
        class=" productEstiamtedTagOld__vertical"
        :style="{
          background: strategyStyle.color
        }"
      ></div>
      <p class="productEstiamtedTagOld__font-10px productEstiamtedTagOld__text-overflow">
        {{ text }}
      </p>
    </template>

    <!-- 倒计时 -->
    <div
      v-if="countdownTipHtml"
      class="productEstiamtedTagOld__countdown-wrap"
    >
      {{ language.SHEIN_KEY_PWA_25725 }} 
      <div
        class="productEstiamtedTagOld__countdown-time"
        v-html="countdownTipHtml"
      ></div>
    </div>

    <!-- 可跳转箭头 -->
    <i
      v-if="hasLink"
      class="suiiconfont sui_icon_more_right_12px_2 productEstiamtedTagOld__icon-link"
    ></i>
  </div>
</template>

<script setup name="EstiamtedTagOld">
import { ref, watch, computed } from 'vue'
import { CountDown, debounce } from '@shein/common-function'
import { ESTIMATED_DATA_TYPES } from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'
import salePriceWordSize from 'public/src/pages/goods_detail_v2/utils/sale-price-word-size.js'

const emits = defineEmits(['clickEstimatedTag'])
const props = defineProps({
  estimatedInfo: {
    type: Object,
    default: () => ({})
  }
})

/* 倒计时相关 start*/ 
const timer = ref(null)
const countdownTipHtml = computed(() => {
  let timeObj = timer.value?.timeObj
  if (!timeObj) return null
  const { H, M, S } = timeObj || {}
  let spanTxt = txt => `<span>${txt}</span>`
  return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
})
const initCountDown = () => {
  const endTime = props.estimatedInfo.endTime
  if(!endTime) return
  timer.value = new CountDown()
  const beginTime = endTime * 1000 - new Date().getTime()
  if (beginTime <= 0) {
    beginTime == 0
  }
  timer.value?.start({
    seconds: beginTime / 1000,
    endFunc: debounce({
      func: () => {
        location.reload()
      }
    })
  })
}

watch(() => props.estimatedInfo?.endTime, (val) => {
  val && initCountDown()
}, { immediate: true })
/* 倒计时相关 end */ 


/* 种类区分 start */
const otherInfo = computed(() => props.estimatedInfo.otherInfo)
const language = computed(() => otherInfo.value.language)
const hasLink = computed(() => otherInfo.value.hasLink)

const isCompliance = computed(() => {
  let { complianceMode, complianceModeDe } = otherInfo.value
  return complianceMode || complianceModeDe
})
const isNEstiamted = computed(() => props.estimatedInfo.dataType == ESTIMATED_DATA_TYPES.N_ESTIMATED)
const isSatisfy = computed(() => props.estimatedInfo.dataType == ESTIMATED_DATA_TYPES.SATISFY)
const isNoSatisfy = computed(() => props.estimatedInfo.dataType == ESTIMATED_DATA_TYPES.NO_SATISFY)
/* 种类区分 end */


/* 内容展示 start */
const mainPrice = computed(() => {
  if (props.estimatedInfo.abts.middleast.p.middleast === 'new' && (isCompliance.value || isSatisfy.value)) {
    return salePriceWordSize({
      priceStr: props.estimatedInfo.value.amountWithSymbol,
      priceShowStyle: props.estimatedInfo.value.priceShowStyle,
      gate: props.estimatedInfo.abts.middleast.p.middleast === 'new' && (isCompliance.value || isSatisfy.value),
      mainSize: 17 
    })
  }
  return props.estimatedInfo.value.amountWithSymbol
})

const text = computed(() => props.estimatedInfo.text)

const buyMultipleText = computed(() => props.estimatedInfo.buyMultipleText)

/* 内容展示 end */

/* 样式处理 start */

// 常规、加车普通：粉底红字
// 特殊闪购、闪购腰带v1、加车闪购：红底白字
// 品牌闪购：白底红字
// 悬浮：白底红字

const ESTIMATED_TAG_OLD_STYLE = {
  normal: {
    color: '#FA6338',
    background: '#FFF4F1'
  },
  darkRed: {
    color: '#FFF',
    background: '#FA6338'
  },
  whiteRed: {
    color: '#FA6338',
    background: '#FFF'
  },
  normalVip: {
    color: '#873C00',
    background: '#FFECE9'
  },
  normalFlash: {
    color: '#FA6338',
    background: 'rgba(255, 255, 255, 0.87)'
  },
  quickAddNormalFlash: {
    color: '#000000',
    background: '#FFF0B0'
  },
  s3Brown: {
    color: '#806208',
    background: '#F1E7D4'
  }
}
const strategyStyle = computed(() => {
  const {
    sheinClubPromotionInfo,
    isPaidUser,
    isQuickAdd,
    isFlash,
    isSpecialFlash,
    isBrandFlash,
    isFloorType,
    exclusiveInfo
  } = otherInfo.value
  let { normal, darkRed, whiteRed, normalFlash, normalVip, quickAddNormalFlash, s3Brown } = ESTIMATED_TAG_OLD_STYLE
  if (!!exclusiveInfo) return s3Brown
  if (isFlash) {
    if (isSpecialFlash) return darkRed
    if (isQuickAdd) {
      if (isBrandFlash) return darkRed
      return quickAddNormalFlash
    }
    if (isBrandFlash) {
      if (isFloorType) return normal
      return whiteRed
    }
    return normalFlash
  }

  if (!!sheinClubPromotionInfo && isPaidUser) return normalVip

  return normal
})
const containerStyle = (() => {
  let { complianceMode, complianceModeDe } = otherInfo.value
  let result = {
    height: '.6133rem'
  }
  if (!complianceMode && !complianceModeDe && !isSatisfy.value) {
    result.height = '0.48rem' 
  }
  return result
})
/* 样式处理 end */

</script>

<style lang="less">
.productEstiamtedTagOld {
  display: flex;
  align-items: center;
  height: .6133rem;
  padding: 0 .16rem;
  border-radius: 2px;
  max-width: 100%;
  &__price {
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    line-height: normal;
    & > span {
      vertical-align: middle;
    }
  }
  &__icon-link {
    margin-left: 2px;
  }
  &__vertical {
    width: 1px;
    height: .32rem;
    opacity: .5;
    margin: 0 .1067rem;
  }
  &__font-10px {
    .font-dpr(20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__text-overflow {
    max-width: 6.9333rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__text-overflow2 {
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    line-clamp:2;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    white-space: normal;
    line-height: 9px;
  }
  &__font-14px {
    .font-dpr(28px);
    font-weight: 700;
    line-height: 17px;
    flex: 0 0 auto;
  }
  &__font-12px {
    .font-dpr(24px);
    font-weight: 700;
    line-height: 18px;
    flex: 0 0 auto;
  }
  &__countdown-wrap{
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-left: 12px;
  }
  &__countdown-time{
    display: flex;
    align-items: center;
    margin-left: 2px;
    span {
      .font-dpr(20px);
      width: 0.427rem;
      height: 0.4rem;
      line-height: 0.4rem;
      background-color: #fff;
      text-align: center;
      margin: 0 2px;
      border-radius: 2px;
    }
  }
}
</style>
