<template>
  <div 
    class="nav-bar__price"
    role="tab"
    :aria-label="label"
    @click="onClick"
  >
    <span
      :class="{
        'nav-bar__price-label': true,
        'nav-bar__price-label-active': isActive,
      }"
    >
      {{ label }}
    </span>

    <span class="nav-bar__icon">
      <span 
        class="nav-bar__icon-asc" 
        :style="{ color: value == ascValue ? 'rgba(0,0,0,0.6)' : 'rgba(0,0,0,1)' }"
      >
        <sui_icon_sort_down_12px
          size="12px"
        />
      </span>

      <span 
        class="nav-bar__icon-desc"
        :style="{ color: value == descValue ? 'rgba(0,0,0,0.6)' : 'rgba(0,0,0,1)' }"
      >
        <sui_icon_sort_up_12px
          size="12px"
        />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'NavBarPrice',
}
</script>

<script setup>
import { watch, ref, onMounted, inject, } from 'vue'
import { sui_icon_sort_up_12px, sui_icon_sort_down_12px } from '@shein-aidc/icon-vue2'
import { emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { getClickType, analysisClickSort } from 'public/src/pages/components/FilterBar/NavBar/utils/analysis.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'

const emits = defineEmits(['click', 'change'])
const props = defineProps({
  label: { type: String, default: '' },
  value: { type: [String, Number], default: '' },
  ascValue: { type: [String, Number], default: '' },
  descValue: { type: [String, Number], default: '' },
})

const sortMap = inject('sortMap', [])
const topTwoList = inject('topTwoList', [])
const isConfigAutoSticky = inject('isConfigAutoSticky', false)
// const isActive = computed(() => props.value == props.ascValue || props.value == props.descValue)
const isActive = ref(false)

const setActive = () => {
  isActive.value = props.value == props.ascValue || props.value == props.descValue
}
watch(() => props.value, () => {
  setActive()
})

onMounted(() => {
  setActive()
})

const toStickyByClick = () => emits('toStickyByClick')
const onClick = () => {
  emitCloseDropdown()
  isConfigAutoSticky.value && toStickyByClick()
  const sort = props.value == props.ascValue ? props.descValue : props.ascValue
  analysisClickSort({
    sort,
    sortType: props.label,
    clickType: getClickType(unrefs({ sort, sortMap, topTwoList })),
  })
  emits('change', { params: { sort } })
}
</script>

<style scoped lang="less">
.nav-bar {
  &__price {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 3.36rem;
    padding: 12px 6px 6px 6px;
    margin-right: 0;
    box-sizing: border-box;
  }

  &__price-label {
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: rgba(0,0,0,0.6);
  }

  &__price-label-active {
    font-weight: 700;
    color: #000;
  }

  &__icon {
    width: 12px;
    height: 12px;
    display: inline-block;
    position: relative;
    margin-left: 2px;

    span {
      width: 12px;
      height: 12px;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }

  }
}

</style>
