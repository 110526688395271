<template>
  <div class="free-shipping-footer">
    <section class="free-shipping-footer__content">
      <div
        class="free-shipping-footer__tips"
        v-html="promotion.progressTip"
      >
      </div>
      <ProgressBar
        animation
        :style-config="{
          FIRST_RANGE_BORDER: 'none',
          FIRST_RANGE_BG: 'repeating-linear-gradient(115deg, #198055 0%, #198055 1%, #6ABD8C 1%, #6ABD8C 2%)',
        }"
        :thresholds="thresholds"
      />
    </section>
    
    <div class="free-shipping-footer__right">
      <s-button
        :type="['H80PX', 'primary']"
        :class="{
          'free-shipping-footer__btn_light': isRangeUp,
        }"
        :max-width="260/75"
        @click="handleBack"
      >
        {{ promotion.goToCheckoutTip }}
      </s-button>
      <IncentivePoint
        v-show="!config.isCloseIncentivePoint"
        class="free-shipping-footer__incentive-point"
        :tags="tags"
        :bubble="bubble"
      />
    </div>
  </div>
</template>

<script>
import incentivePoint from 'public/src/pages/common/add-on/mixins/incentivePoint.js'

export default {
  components: {
    ProgressBar: () => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/ProgressBar.vue'),
  },
  mixins: [incentivePoint],
  props: {
    promotion: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    },
    thresholds: {
      type: Array,
      default: () => ([])
    },
    isRangeUp: {
      type: Boolean,
      default: false,
    },
    addItemType: {
      type: [String, Number],
      default: '',
    },
  },
  methods: {
    handleBack() {
      this.$emit('back')
    },
  },
}
</script>

<style lang="less" scoped>
.free-shipping-footer {
  display: flex;
  align-items: center;
  padding: 12/37.5rem;
  width: 100%;
  height: 100%;
  &__content {
    flex: 1;
    margin-right: 17/37.5rem;
    line-height: normal;
  }
  &__tips {
    margin-bottom: 6/37.5rem;
    width: 100%;
    word-break: break-word;
    .line-camp(2);
    // /deep/ font {
    //   font-size: 14px;
    //   color: #FA6338;
    // }
  }
  &__btn_light {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to right, transparent, rgba(255,255,255,.6), transparent);
      animation: ScanLights 6s linear infinite;
    }
    @keyframes ScanLights {
      0% {
          transform: translate(-100%);
      }
      10% {
          transform: translate(100%);
      }
      100% {
          transform: translate(100%);
      }
    }
  }
  &__right {
    position: relative;
  }

  &__incentive-point {
    position: absolute;
    right: 0;
    bottom: calc(100% - 0.05333333rem);
    z-index: @zindex-hack;
  }
}
</style>
