import type { GoodsPrice } from '../../../../types/mobile/index.ts'

/**
 * GoodsPrice 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsPrice.Props {
  // 是否新人专享商品
  const isNewUserGoods = data.value.aggregateProductBusiness?.showNewUsersBonus == '1'
   // 是否赠品/加价购附属品
  const isAdditionGoods = (data.value.aggregateProductBusiness?.isAdditionGoods == 1 || data.value?.isPayMemberGift === '1') && data.value.is_checked == '0'
  // 是否失效商品
  const isInvalidGoods = data.value.isInvalid == '1'

  // 赠品/附属品提示文案
  const additionGoodsView = data.value.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView

  let salePriceShowStyle = ''
  // 当到手价展示到到手价标签上时，salePrice 展示[券前价]且不加粗放大
  if (!data.value.aggregateProductBusiness?.estimatedData?.estimatedPrice) {
    salePriceShowStyle = data.value.aggregateProductBusiness?.priceData?.unitPrice?.price?.priceShowStyle
  }

  const isShowSuggestPrice = !!data.value.aggregateProductBusiness?.priceData?.lowestPrice
  const isShowEstimatedPrice = !!data.value.aggregateProductBusiness?.estimatedData?.style && !!data.value.aggregateProductBusiness?.estimatedData?.estimatedTip

  const validPriceTags = data.value.aggregateProductBusiness?.priceData?.priceTags?.filter?.(item => item.show == '1') || []
  const isShowPricePrefixText = validPriceTags.length > 0
  const pricePrefixText = validPriceTags[0]?.view?.text
  const pricePrefixTextColor = validPriceTags[0]?.view?.textColor

  return {
    isShow: additionGoodsView?.additionGoodsBottomTip ? false : true,
    salePrice: data.value.aggregateProductBusiness?.priceData?.unitPrice?.price?.amountWithSymbol,
    salePriceShowStyle,
    salePriceColor: data.value.aggregateProductBusiness?.priceData?.unitPrice?.color,
    isAfterWrap: !isShowSuggestPrice && !isShowEstimatedPrice, // 没有 到手价标签 和 suggest price 时，才换行
    isShowMask: isNewUserGoods || isAdditionGoods || isInvalidGoods,
    isShowOriginalPrice: !!data.value.aggregateProductBusiness?.priceData?.originalPrice,
    isShowOriginalPriceText: !!data.value.aggregateProductBusiness?.priceData?.originalPrice?.description,
    isShowOriginalPricePopover: !!data.value.aggregateProductBusiness?.priceData?.originalPrice?.tip,
    originalPrice: data.value.aggregateProductBusiness?.priceData?.originalPrice?.price?.amountWithSymbol,
    originPriceText: data.value.aggregateProductBusiness?.priceData?.originalPrice?.description || '',
    originPriceColor: data.value.aggregateProductBusiness?.priceData?.originalPrice?.color || '',
    originPriceCrossed: data.value.aggregateProductBusiness?.priceData?.originalPrice?.crossed === '1',
    originPricePopoverText: data.value.aggregateProductBusiness?.priceData?.originalPrice?.tip?.desc,
    isShowSuggestPrice: !!data.value.aggregateProductBusiness?.priceData?.lowestPrice,
    isShowSuggestPriceText: !!data.value.aggregateProductBusiness?.priceData?.lowestPrice?.description || false,
    suggestPrice: data.value.aggregateProductBusiness?.priceData?.lowestPrice?.price?.amountWithSymbol,
    suggestPriceText: data.value.aggregateProductBusiness?.priceData?.lowestPrice?.description,
    suggestPriceColor: data.value.aggregateProductBusiness?.priceData?.lowestPrice?.color,
    suggestPriceCrossed: data.value.aggregateProductBusiness?.priceData?.lowestPrice?.crossed === '1',
    isShowSheinVipIcon: !!data.value.aggregateProductBusiness?.priceIcon?.s3Icon,
    isShowPromotionIcon: !isShowPricePrefixText,
    isShowPremiumMemberIcon: !!data.value.aggregateProductBusiness?.priceData?.priceIcon?.clubIcon,
    isShowTimeIcon: !!data.value.aggregateProductBusiness?.priceData?.priceIcon?.timeIcon,
    isShowAppOnlyIcon: false,
    isShowBrandSaleIcon: !!data.value.aggregateProductBusiness?.priceData?.priceIcon?.dealsIcon,
    isShowFlashSaleIcon: !!data.value.aggregateProductBusiness?.priceData?.priceIcon?.flashIcon,
    premiumMemberIconColor: data.value.aggregateProductBusiness?.priceData?.unitPrice?.color,
    timeIconColor: '#FA6338', // 写死即可
    appOnlyIconColor: '#FA6338', // 写死即可
    brandSaleIconColor: '#FFCD94', // 写死即可
    flashSaleIconColor: '#FFCD94', // 写死即可
    discountTagPosition: data.value.aggregateProductBusiness?.discountSubscript?.style === '3' ? 'suggest-price-after' : 'original-price-after',
    pricePopoverConfig: {
      listenTarget: '',
      gapTarget: '',
    },
    isShowPricePrefixText,
    pricePrefixText,
    pricePrefixTextColor,
  }
}
