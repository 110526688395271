import { withResolvers } from '../../utils/withResolvers'

class CartGuide {
  constructor({ priority } = {}) {
    const { promise, resolve } = withResolvers()
    this.initialized = false
    this._initialized = promise
    this._resolve = resolve

    this.isEnd = false
    this.isSkip = false
    this.priority = priority ?? 999 // 根据自己诉求设置优先级

    this.start = null
    this.end = () => {
      this.isEnd = true
      this.isSkip = false
      window.__cartGuideManager__?.currentGuide?.close?.()
      window.__cartGuideManager__.showNextGuide(false)
    }
    this.skip = () => {
      this.isEnd = false
      this.isSkip = true
      window.__cartGuideManager__.showNextGuide(true)
    }
    this.isCompleted = null
  }

  initialize({ start, close, isCompleted }) {
    this.initialized = true

    this.isSkip = false
    this.start = start
    this.close = close
    this.isCompleted = isCompleted

    this._resolve(true)
  }

  isInitialized() {
    return this._initialized
  }
}

export default CartGuide
