/* eslint-disable @typescript-eslint/no-namespace */
import type { Libs } from '@shein-aidc/bs-sdk-libs-manager'

// 枚举
export namespace E_BenefitFloor {
  export enum BenefitComponentType {
    MiniBar,
    Bar,
    Floor
  }

  // 取值与中间层保持一致
  export enum BenefitType {
    ReturnCoupon = 'returnCoupon',
    ReturnCredit = 'returnCredit',
    Point = 'point',
    FreeGift = 'freeGift',
  }

  // 取值与中间层保持一致
  export enum TagType {
    NewUser = 'new_user',
    Prime = 'prime',
  }

  export enum ReturnCouponType {
    Amount = 'amount',
    Discount = 'discount',
    FreeShipping = 'freeShipping'
  }

  export enum FreeGiftType {
    Prime = 'prime',
    NewUserLuckBag = 'newUserLuckBag',
    MarketingActivity = 'marketingActivity'
  }

  export enum PopUpPointType {
    // 常规积分
    RegularPoint = 'regularPoint',
    // Extra Point
    ExtraPoint = 'extraPoint',
    // SHEIN Card Point
    CardPoint = 'cardPoint'
  }
}

// 组件内的类型
export declare namespace C_BenefitFloor {
  type TopRightTag = {
    text: string,
    type: string
  }
  type ReturnCoupon = {
    couponList: ReturnCouponItem[],
    topRightTag?: TopRightTag
  }

  type ReturnCouponItem = {
    displayText?: string[],
    // 枚举值
    type?: E_BenefitFloor.ReturnCouponType,
    num?: number
  }

  type ReturnCredit = {
    displayText: string[],
    hasCreditMoneyDisplay: '0' | '1',
    isLock?: '0' | '1',
  }

  type PopUpReturnCredit = {
    creditList: ReturnCredit[],
    lockTip?: string
  }

  type Point = {
    icon: string,
    point: string,
    pointTip: string
  }

  type PopUpPoint = {
    pointList: {
      title: string,
      desc: string,
      type: E_BenefitFloor.PopUpPointType,
      pointTip: string,
      endTime: string,
      subPointBgColor: string,
      tailIconList: {
        text: string,
        icon: string
      }[],
      subPointList: {
        title: string,
        icon: string,
        pointTip: string
      }[]
    }[]
  }

  type FreeGift = {
    goodList: FreeGiftItem[],
    goodNum?: number
  }

  type FreeGiftItem = {
    imgUrl: string,
    beltTip: string,
    type: E_BenefitFloor.FreeGiftType
  }

  type PopCouponOrder = {
    showType?: string,
    returnCouponNonAggregate?: ReturnCoupon
    returnCouponAggregate?: ReturnCoupon
  }

  type PopReturnCouponItem = {
    couponList: ReturnCouponItem[]
  }

  type PopReturnCoupon = {
    returnCouponAggregate: PopReturnCouponItem,
    topRightTag?: TopRightTag
  }

  type RewardItem = {
    type?: E_BenefitFloor.BenefitType,
    desc?: string,
    goodList?: any[],
    [E_BenefitFloor.BenefitType.ReturnCoupon]?: ReturnCoupon,
    [E_BenefitFloor.BenefitType.ReturnCredit]?: ReturnCredit,
    [E_BenefitFloor.BenefitType.Point]?: Point,
    [E_BenefitFloor.BenefitType.FreeGift]?: FreeGift
  }

  type PopUpRewardItem = {
    type: E_BenefitFloor.BenefitType,
    title: string,
    totalValueTip: string,
    tagList?: Tag[],
    [E_BenefitFloor.BenefitType.ReturnCoupon]?: PopReturnCoupon,
    [E_BenefitFloor.BenefitType.ReturnCredit]?: PopUpReturnCredit,
    [E_BenefitFloor.BenefitType.Point]?: PopUpPoint,
    [E_BenefitFloor.BenefitType.FreeGift]?: FreeGift
  }

  type Tag = {
    text: string,
    type: E_BenefitFloor.TagType
  }

  type GuideData = {
    title: string,
    compressedTitle: string,
    totalValueTip: string,
    endTime?: string,
    tag: Tag,
    rewardList: RewardItem[],
    showDetailPopup: '0' | '1'
  }

  type PopUpData = {
    title: string,
    totalValueTip: string,
    endTime?: string,
    rewardList: PopUpRewardItem[]
  }

  type Data = {
    guide: GuideData,
    popUp: PopUpData
  }

  type Config = {
    drawerBottom?: string
    hideBorder?: boolean
  }

  type LangKeys = 'CountDownText' | 'ValueText'
  type Langs = Record<LangKeys, string>
  type LanguageType = {
    [key in LangKeys]: string
  }
}
// 接口
export declare namespace DS_BenefitFloor {
  type TYPES = 'standard'

  type APIS = {
    langs: () => Promise<C_BenefitFloor.Langs>
  }

  type FS_APIS = Pick<APIS, 'langs'>

  type FS_DATA = Libs.MatchApisData<FS_APIS>

  type APIS_THIS = Libs.AddThis<APIS>

  type DataSource = Libs.DataSourceInterface<TYPES, APIS_THIS>
}
// 埋点
export declare namespace AS_BenefitFloor {
  // TODO edit you Events
  type Events = 'click_your_sa_event_name.comp_benefit-floor'

  type AnalysisFuncs = Libs.AnalysisFuncs<Events>

  type AnalysisSource = Libs.AnalysisSourceInterface<Events>

  type AnalysisInstance = Libs.AnalysisInstance<Events>
}
