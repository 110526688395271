// bff中间层融合 阿波罗配置key
export const USE_BFF_API_KEYS = [
  'wish_user_index_bff',
  'wishlistGroup_v1',
  // 'wishlistGroup_v2', apollo推全下线相关逻辑（注： 旧中间层代码暂时先不删）
  'wishlistProducts_v1',
  'wishlistGroup_v3',
  'wishlistShare_v1_bff', // 收藏分享落地页&&分组详情页&&分组选择页融合,在这里声明但是不用，目的是为了显示的突出 当前还有哪些融合key在用需要处理
]

