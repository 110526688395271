import { ref, onMounted } from 'vue'
import popoverManager from './PopoverManager'

/**
 * 气泡管理器 Vue 胶水代码
 * @param {String} type - 气泡类型
 * @param {String} isAsync - 是否依赖异步数据的气泡（需要手动调updateAsyncPopoverState更新状态）
 * @param {String} isKeep - 是否常驻气泡（会监听其他气泡打开事件用于关闭自己）
 * @param {String} initValue - 初始化状态（设置isActivePopover默认值)
 * @returns 
 */
export function usePopoverManager({ type, isAsync = false, isKeep = false, initValue = false }) {
  // 打开的时候这个值是异步更新的
  const isActivePopover = ref(initValue)
 
  // 打开气泡
  const activatePopover = (onComplete) => {
    return new Promise((resolve) => {
      popoverManager.addTask({
        type,
        complete: (res) => {
          changeActive(true)
          resolve(res)
          onComplete?.(res)
        }
      })
    })
  }

  // 关闭气泡
  const deactivatePopover = () => {
    popoverManager.closePopover(type, () => {
      changeActive(false)
    })
  }

  // 注册气泡
  const registerPopover = () => {
    popoverManager.registerPopover({
      type,
      isAsync,
      isKeep,
      isActive: initValue,
    })
  }

  const changeActive = (value) => {
    isActivePopover.value = value
  }

  // 更新气泡加载状态
  const updateAsyncPopoverState = () => {
    popoverManager.updateAsyncPopoverState(type)
  }

  onMounted(() => {
    if (isKeep) {
      popoverManager.addListener(() => changeActive(false))
    }
  })

  return {
    // 暴露给外部的状态
    isActivePopover: isActivePopover,

    // 暴露给外部的方法
    activatePopover,
    deactivatePopover,
    registerPopover,
    updateAsyncPopoverState,
  }
}
