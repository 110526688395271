
import { REDIRECT_SELF_PAGE_PAYMENT_METHODS, REDIRECT_THIRD_PARTY_PAYMENT_METHODS, REDIRECT_THIRD_PARTY_PAYMENT_CHANNEL, SPECIAL_SDK_PAYMENT_METHODS, SPECIAL_PAYMENT_TYPES } from '../constants'

export function nextIdle() {
  return new Promise<void>(resolve => {
    if (typeof window === 'undefined') return
    const rc = window.requestIdleCallback || window.setTimeout
    rc(() => resolve())
  })
}

export function getDeviceInfo() {
  return{
    //acceptHeader: '',
    colorDepth: window.screen.colorDepth,
    javaEnabled: window.navigator.javaEnabled() ? 1 : 0,
    language: window.navigator.language,
    device_language: window.navigator.language,
    screenHeight: window.screen.height || 0,
    screenWidth: window.screen.width || 0,
    timeZoneOffset: new Date().getTimezoneOffset(),
    userAgent: window.navigator.userAgent,
    origin: window.location.origin,
  }
}

export function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  try {
    const enableDebugLog = sessionStorage?.getItem?.('__bs__enable_debug_log')
    if (!enableDebugLog) return
    console.info(
      '%cBS Pay Libs Debug Info:',
      'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
      ...args,
      '\n',
    )
  } catch (e) {
    console.log(...args)
  }
}

export const checkPreOpenWinRedirectThirdParty = (paymentMethod: string) => {
  const [channel, method] = paymentMethod.split('-')
  if (REDIRECT_THIRD_PARTY_PAYMENT_METHODS.includes(paymentMethod)) return true
  if (REDIRECT_THIRD_PARTY_PAYMENT_CHANNEL.includes(channel)) {
    if (
      !REDIRECT_SELF_PAGE_PAYMENT_METHODS.includes(paymentMethod) &&
      !SPECIAL_SDK_PAYMENT_METHODS.includes(paymentMethod) &&
      !SPECIAL_PAYMENT_TYPES.includes(method)
    ) {
      return true
    }
  }
  return false
}
