<template>
  <swiper-container
    ref="benefitsSwiperRef"
    class="benefit-floor-benefits"
    init="false"
    destroy-on-disconnected="false"
  >
    <swiper-slide
      v-for="rewardItem in rewardList"
      :key="rewardItem.type"
      class="benefit-floor-benefits__slide"
    >
      <component
        :is="getComponent(rewardItem.type)"
        class="benefit-floor-benefits__item"
        :reward-item="rewardItem"
        :benefit-component-type="benefitComponentType"
      />
    </swiper-slide>
  </swiper-container>
</template>

<script name="BenefitFloorBenefits" setup lang="ts">
import { type Ref, ref, onMounted } from 'vue'
import { register } from 'swiper/element'
import BenefitCoupon from '../BenefitCoupon/index.vue'
import BenefitFreeGift from '../BenefitFreeGift/index.vue'
import CreditFloor from '../Credit/CreditFloor.vue'
import PointFloor from '../BenefitPoint/Floor.vue'
import { E_BenefitFloor, type C_BenefitFloor } from '../../../../types'

typeof window !== 'undefined' && register()

defineProps<{
  rewardList: C_BenefitFloor.RewardItem[],
  benefitComponentType: E_BenefitFloor.BenefitComponentType,
}>()

const benefitsSwiperRef: Ref = ref(null)

const getComponent = (type) => {
  switch(type) {
    case E_BenefitFloor.BenefitType.ReturnCoupon:
      return BenefitCoupon
    case E_BenefitFloor.BenefitType.FreeGift:
      return BenefitFreeGift
    case E_BenefitFloor.BenefitType.ReturnCredit:
      return CreditFloor
    case E_BenefitFloor.BenefitType.Point:
      return PointFloor
    default:
      return null
  }
}

const initSwiper = () => {
  if (typeof window === 'undefined') return
  const swiper = benefitsSwiperRef.value
  if (!swiper) return
  const swiperParams = {
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    allowTouchMove: true,
    enabled: true,
    spaceBetween: 8,
    slidesOffsetBefore: 8,
    slidesOffsetAfter: 8,
    observeParents: true,
    observeSlideChildren: true,
    observer: true,
  }
  Object.assign(swiper, swiperParams)
  swiper.initialize()
}

onMounted(() => {
  initSwiper()
})
</script>

<style lang="less" scoped>
.benefit-floor-benefits {
  z-index: 0;
  &__slide {
    width: auto;
  }
}
</style>
