<template>
  <div class="bsc-gc-addc">
    <div
      v-if="!(props.isEmptyCard && props.isAvailableCard)"
      v-tap="{
        id: 'click_newgiftcard_entrance:simple',
        data: {
          tab_type: props.isAvailableCard ? 'available' : 'unavailable'
        }
      }"
      class="bsc-gc-addc__bar"
      @click="handleOpen"
    >
      <span>
        {{ language?.SHEIN_KEY_PWA_35100 }}
      </span>
      <Icon
        name="sui_icon_more_right_16px"
        size="16px"
        color="#959595"
        :is-rotate="GB_cssRight"
      />
    </div>
    <div
      v-if="!isNeedDrawer"
      class="bsc-gc-addc__title"
    >
      {{ language?.SHEIN_KEY_PWA_35100 }}
    </div>
    <BBindGiftCard
      v-if="isBindGiftCardVisible"
      ref="bindGiftCardRef"
      sence="checkout"
      :giftCardList="giftCardList"
      :isShowSwitch="isShowSwitch"
      :isShowStepText="false"
      :linkBtnTxt="language?.SHEIN_KEY_PWA_28846"
      :is-need-drawer="isNeedDrawer"
      :visible="isBindGiftCardVisible"
      @bind-card-cb="onSubmit"
      @handle-close="() => visible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, inject, computed, type Ref } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { E_SCENE, type C_GiftCard } from '../../../types/index.ts'
import BBindGiftCard from '@shein-aidc/bs-bind-gift-card-mobile-vue2'
// import BBindGiftCard from '../../../../bind-gift-card/mobile-vue2/src/index'

const language: C_GiftCard.language = inject('language')!
const cardData: Ref<C_GiftCard.CardData> = inject('cardData')!
const scene: Ref<`${E_SCENE}`> = inject('scene')!
const {triggerNotice } = inject('analysisInstance') as any


const { GB_cssRight } = gbCommonInfo

const props = withDefaults(defineProps<{
  isEmptyCard: boolean
  abtType: number
  isAvailableCard: boolean
}>(), {
  isEmptyCard: true,
})

const visible = ref(false)
const bindGiftCardRef = ref(null)
const giftCardList = computed(() => Object.values(cardData.value).reduce((sum: any, item) => sum.concat(item), []))
const isShowSwitch = computed(() => props.abtType === 2)
const isNeedDrawer = computed(() => !(props.isEmptyCard && props.isAvailableCard))
const isBindGiftCardVisible = computed(() => (props.isEmptyCard && props.isAvailableCard) ? true : visible.value)

const emits = defineEmits(['submit'])

const handleOpen = () => {
  visible.value = true
}

const onSubmit = (v) => {
  const { resData: { code } = {}, isBindCard } = v
  if (scene.value === E_SCENE.Checkout && isBindCard) {
    const isSuccess = [0].includes(+code)
    const extraData = {
      result: isSuccess ? 1 : 0,
    }
    if (!isSuccess) {
      extraData['result_reason'] = code
    }
    triggerNotice?.({
      id: 'click_card_confirm_result:simple',
      extraData,
    })
  }
  emits('submit', v)
}

defineExpose({
  closeDrawer: () => visible.value = false,
})

</script>
<style lang="less">
.bsc-gc-addc {
  margin: .32rem .32rem 0;
  &__bar {
    display: flex;
    padding: .48rem .32rem;
    justify-content: space-between;
    align-items: center;
    border-radius: .1067rem;
    border: 1px dashed #999;
    background-color: #fff;
    color: #000;
    font-size: .3733rem;
    font-weight: 400;
    line-height: .032rem;
  }
  &__title {
    color: #000;
    margin-bottom: .24rem;
    font-size: .32rem;
    font-weight: 500;
  }
}
</style>

