import { useFetch } from './useFetch'

import { useAppConfigs, getLangs } from '@shein-aidc/bs-sdk-libs-manager'

import { bsPayEventBus } from './useBsPayCommon'

import { initializeStore, type PaymentProcessorStore } from '../store'

import { useInitializePay } from './useInitializePay'

import { debuggerLog, checkPreOpenWinRedirectThirdParty, nextIdle } from '../utils'
import { type C_Libs_Pay } from '../../types'
import { type Trade_PayToolKit, type Trade_PayLibs } from '@shein-aidc/types-trade'
import { type CheckstandModalSetup, CheckstandModal } from '../checkstand/CheckstandModal'
import { type CheckstandManagerConstructor, CheckstandManager } from '../checkstand/CheckstandManager'
import { LtspcPayManager } from '../checkstand/tools/LtspcPayManager'
import { CreateNewWindow } from '../checkstand/helpers/CreateNewWindow'
import { CodPayManager } from '../checkstand/tools/CodPayManager'
import { GooglePayManager } from '../checkstand/tools/GooglePayManager'
import { ABT_POS_KEYS, APOLLO_KEYS, LANG_KEYS_MAP, type LangKeys, type ApolloKeysTypes } from '../constants'
import { ApplePayManager } from '../checkstand/tools/ApplePayManager'
import { ThreeDSecureManager } from '../checkstand/helpers/ThreeDSecureManager'
import { MonitorReport, MonitorReportAbnormal, MonitorReportBusiness } from '../checkstand/helpers/MonitorReport'
import { type PayManagerActionMethods } from '../checkstand/BasicPayManager'
import { KlarnaInlineManager } from '../checkstand/tools/KlarnaInlineManager'

export interface CompatNewPayment extends Partial<Trade_PayLibs.UnifiedPayCbEvt>,PayManagerActionMethods {
  paymentInfo: CheckstandModalSetup['paymentInfo'];
  orderInfo: CheckstandModalSetup['orderInfo'];
  extraParams: CheckstandModalSetup['extraParams'];
  payProductType?: CheckstandManagerConstructor['payProductType'];
  payCheckoutType?: CheckstandManagerConstructor['payCheckoutType'];
}

export type BsPayConstructor = Trade_PayLibs.BsPayConfig

export interface UseBsPay extends BsPayConstructor {
  bsUtils?: Trade_PayLibs.OutsideBsUtils
}


export class BsPay {

  static bsUtils: Trade_PayLibs.OutsideBsUtils | undefined

  public store: PaymentProcessorStore

  private appConfigs: ReturnType<typeof useAppConfigs>

  private fetches: ReturnType<typeof useFetch> = {} as any

  private static fetchStatusMap = new Map()

  private static fetchPromiseMap = new Map()

  constructor({
    useInline,
    environment,
    checkoutType,
    productType,
  }: BsPayConstructor) {
    debuggerLog('useBsPay===constructor---', useInline, environment, checkoutType, productType)
    BsPay.fetchStatusMap.clear()
    BsPay.fetchPromiseMap.clear()

    this.store = initializeStore()
    this.appConfigs = useAppConfigs()
    this.initialize({
      useInline,
      environment,
      checkoutType,
      productType,
    })
  }

  private initialize = (info: BsPayConstructor) => {
    CreateNewWindow.destroy?.()

    const environment = info.environment || (['localhost', 'debug'].includes(this.appConfigs.$envs?.NODE_SERVER_ENV) ? 'TEST' : 'PRODUCTION')

    this.fetches = useFetch()
    this.store.setState({
      scene: info.checkoutType,
      bsPayConfig: {
        useInline: info.useInline || false,
        environment,
        checkoutType: info.checkoutType,
        productType: info.productType,
      },
      bsUtils: BsPay.bsUtils,
    })

    MonitorReport.initMonitor({
      getMonitorManager: () => BsPay.bsUtils?.monitorManager?.() as any,
      productType: info.productType,
      checkoutType: info.checkoutType,
      useInline: info.useInline,
    })

    nextIdle().then(() => {
      this.initializeData()
      BsPay.bsUtils?.initRiskSdk?.()
    })

    this.store.subscribe((state) => {
      debuggerLog('useBsPay===state---', state, this.store.getState())
    })
  }
  /**
   * 初始化数据，仅执行一次
   *
   * @private
   * @memberof BsPay
   */
  private initializeData = async () => {
    if (BsPay.fetchStatusMap.get('initializeData') === 'fulfilled') return true
    if (!BsPay.fetchPromiseMap.get('initializeData')) {
      BsPay.fetchPromiseMap.set('initializeData', this.createInitializeData())
    }
    return BsPay.fetchPromiseMap.get('initializeData')
  }

  private createInitializeData = () => {
    return Promise.allSettled([
      this.initializeLibsLang(),
      this.initializePaymentApollo(),
      this.initializePaymentAbtInfo(),
    ]).then(() => {
      BsPay.fetchStatusMap.set('initializeData', 'fulfilled')
      debuggerLog('useBsPay===initializeData===complete---')
      return true
    }).catch((error) => {
      BsPay.fetchStatusMap.set('initializeData', 'rejected')
      debuggerLog('useBsPay===initializeData===error---', error)
      MonitorReportAbnormal.metric({
        scene: 'bs_initialize_data_error',
        extraTags: {
          failure_type: 'api',
        },
        extraParams: {
          errorMessage: error?.message,
        },
      })
      return error
    })
  }

  private initializeLibsLang = async () => {
    const result = await getLangs<LangKeys>(LANG_KEYS_MAP, this.appConfigs.$schttp)
    this.store.setState({
      language: result,
    })
  }

  private initializePaymentApollo = async () => {
    const res = await this.appConfigs.$schttp<{ code: string | number; info?: Record<ApolloKeysTypes, any> }>({
      baseURL: this.appConfigs.$envs?.langPath,
      method: 'POST',
      url: '/api/config/apolloConfig/query',
      data: {
        apolloKeys: APOLLO_KEYS.join(','),
      },
    })
    if (res?.data?.code == 0 && res?.data?.info) {
      this.store.setState({
        apolloInfo: {
          googlePayCodeList: res.data.info.GOOGLE_PAY_CODE_LIST,
          applePayCodeList: res.data.info.APPLE_PAY_CODE_LIST,
          applePayConfig: res.data.info.APPLE_PAY_CONFIG,
          paymentCardLogoConfig: res.data.info.PAYMENT_CARD_LOGO_CONFIG,
          googleReadyToPayRequest: res.data.info.GOOGLE_READY_TO_PAY_REQUEST,
          googlePayConfig: res.data.info.GOOGLE_PAY_CONFIG,
          payConfigSwitch: res.data.info.PAY_CONFIG_SWITCH,
          PRE_ROUTING_PAYMENT_METHODS: res.data.info.PRE_ROUTING_PAYMENT_METHODS,
          DLOCAL_PAYMENT_METHODS: res.data.info.DLOCAL_PAYMENT_METHODS,
        },
      })
    }
  }

  private initializePaymentAbtInfo = async () => {
    const posKeys = ABT_POS_KEYS
    const stringifyPosKeys = posKeys.join(',')
    const res = await this.appConfigs.$schttp<{
      code: string | number;
      info?: {
        pos: Record<keyof typeof ABT_POS_KEYS, Trade_PayToolKit.AbtInfo>;
      };
    }>({
      method: 'GET',
      url: '/abt/merge/get_abt_by_poskey',
      params: { posKeys: stringifyPosKeys },
    })
    if (res?.data?.code == 0 && res?.data?.info) {
      const abtInfo = res.data.info.pos || {}
      this.store.setState({
        abtInfo,
      })
    }
  }

  private initializePayment = () => {
    const { initializePay } = useInitializePay({
      store: this.store,
    })
    initializePay()
  }
  /**
   * 选择支付方式后调用该方法
   *
   * @param {{
   *     [key: string]: any;
   *     payment_code: string;
   *   }} data
   * @memberof BsPay
   */
  selectPayment = (data: Trade_PayToolKit.AssembledToOutsidePaymentInfo) => {
    debuggerLog('useBsPay==methods===selectPayment===data---', data)
    this.store.setState({
      selectedPayment: data,
    })
    // 确保初始化数据完成后再执行支付方式初始化，因初始化数据仅执行一次，所以不会重复请求接口
    this.initializeData().then(() => {
      this.initializePayment()
    })
    if (BsPay.bsUtils?.checkLtspcPaymentMethod?.(data.payment_code)) {
      const { encryptConfig } = this.store.getState()
      !encryptConfig.encryptValid && BsPay.bsUtils?.getPubKey().then(res => {
        this.store.setState({
          encryptConfig: {
            encryptValid: res.encryptValid,
            publicKeyObj: res.publicKeyObj,
            encryptObj: res.encryptObj,
          },
        })
        MonitorReportBusiness.metric({
          scene: 'bs_get_pub_key',
          extraTags: {
            payment_code: data.payment_code,
          },
          extraParams: {
            encryptValid: res.encryptValid ? '1' : '0',
          },
        })
      })
    }

    MonitorReportBusiness.metric({
      scene: 'bs_select_payment',
      extraTags: {
        payment_code: data.payment_code,
      },
    })
  }
  /**
   * 更新支付方式信息
   * 调用时机
   *
   * 1. 选择支付方式后，重新调用计价后，需传入最新的金额信息
   * 2. 币种转换后，需传入最新的币种信息
   * 3. 地址变更后，需传入最新的国家信息
   *
   * @param {{
   *     [key: string]: any;
   *     payment_code?: string;
   *     countryCode?: string;
   *     orderAmount?: string;
   *     orderCurrency?: string;
   *     payMethodList?: string[];
   *     channelSession?: string;
   *   }} data
   * @memberof BsPay
   */
  updatePayment = async (data: {
    [key: string]: any;
    payment_code?: string;
    countryCode?: string;
    orderAmount?: string;
    orderCurrency?: string;
    payMethodList?: string[];
    channelSession?: string;
  }) => {
    debuggerLog('useBsPay==methods===updatePayment===data---', data, Array.isArray(data?.channelSession))
    if (data?.payMethodList?.length) {
      let parseChannelSession = {} as any
      try {
        parseChannelSession = Array.isArray(data?.channelSession) ? {
          orderDetails: data?.channelSession,
        } : JSON.parse(data?.channelSession || '{}')
      } catch (error) {
        debuggerLog('useBsPay===updatePayment===error---', error)
        MonitorReportAbnormal.metric({
          scene: 'bs_parse_channel_session_error',
          extraTags: {
            payment_code: data.payment_code,
          },
          extraParams: {
            error_message: (error as any)?.message,
            channelSession: data.channelSession,
          },
        })
      }

      try {
        const sessionInfoParams = {
          shipCountry: data.countryCode || '',
          orderAmount: data.orderAmount || '',
          orderCurrency: data.orderCurrency || '',
          payMethodList: (data?.payMethodList || []).filter(item => item),
          ...parseChannelSession,
        }

        const sessionInfo = await this.fetches.bffGetNewChannelSessionApi(sessionInfoParams)
        const sessions = (sessionInfo?.data || sessionInfo || {}).info?.sessions || []
        // 创建session接口返回结果曝光
        window?.sa && window?.sa('send', {
          activity_name: 'expose_session_result',
          activity_param: {
            failure_msg: sessions?.length ? sessionInfo?.data?.code : '',
            payment_method: 'PayPal-GApaypal',
            return_status: sessions?.length
                  ? 'sessionapi_return_clientToken'
                  : 'sessionapi_return_failure',
          },
        })

        this.store.setState({
          sessionInfoParams,
          paySessionInfo: sessions?.reduce((acc, cur) => {
            acc[cur.payMethod] = cur || {}
            return acc
          }, {}),
        })
      } catch(err: any){
        window?.sa && window?.sa('send', {
          activity_name: 'expose_session_result',
          activity_param: {
            failure_msg: err?.message || '',
            payment_method: 'PayPal-GApaypal',
            return_status: 'sessionapi_not_response',
          },
        })
      }
    }
    // 确保初始化数据完成后再执行支付方式初始化，因初始化数据仅执行一次，所以不会重复请求接口
    await this.initializeData()
    this.initializePayment()

    // 通知更新数据
    bsPayEventBus.updatePaymentInfo.emit({
      paySessionInfo: this.store.getState().paySessionInfo,
      currency: data.orderCurrency,
      countryCode: data.countryCode,
    })
  }

  bindComponentMethods = (info) => {
    debuggerLog('useBsPay===bindComponentMethods---', info)
    const {
      openApplePayDialog = null,
      openGooglePayDialog = null,
      openCodFreeVerifyDialog = null,
      showCodBackTips = null,
    } = info
    if (openApplePayDialog) {
      // this.applePay?.bindApplePayDialogMethod(openApplePayDialog)
      ApplePayManager.bindApplePayDialogMethod(openApplePayDialog)
    }
    if (openGooglePayDialog) {
      GooglePayManager.bindGooglePayDialogMethod(openGooglePayDialog)
    }
    if (openCodFreeVerifyDialog) {
      // this.cod?.bindCodFreeVerifyDialogMethod(openCodFreeVerifyDialog)
      CodPayManager.bindCodFreeVerifyDialogMethod(openCodFreeVerifyDialog)
    }
    if (showCodBackTips) {
      CodPayManager.bindCodBackTipsMethod(showCodBackTips)
    }
    if (info.handleThreeDSecureModal) {
      ThreeDSecureManager.bindThreeDSecureMethod({
        handleThreeDSecureModal: info.handleThreeDSecureModal,
      })
    }
  }

  handleSdkBridgeAction = () => {
    // debuggerLog('useBsPay===handleSdkBridgeAction---', info)
  }

  /**
   * 创建支付流程，创单成功后调用
   *
   * @param {C_Libs_Pay.UnifiedPayOrgs<C_Libs_Pay.UnifiedPayParams>} data
   * @memberof BsPay
   */

  public createPayment = async (data: CompatNewPayment) => {
    debuggerLog('useBsPay==methods===createPayment===data---', data)

    const bsUtils = BsPay.bsUtils as Trade_PayLibs.OutsideBsUtils
    const { encryptConfig, bsPayConfig, paySessionInfo } = this.store.getState()
    const sessionInfo = paySessionInfo?.[data.paymentInfo?.payment_code] || {}

    const payData = await CheckstandModal.init({
      orderInfo: data.orderInfo,
      paymentInfo: data.paymentInfo,
      extraParams: { ...(data?.extraParams || {}), ...{
        clientToken: sessionInfo.clientToken,
        channelSessionInfo: sessionInfo,
      } },
      bsUtils,
      encryptConfig,
      abtInfo: this.store.getState().abtInfo,
      apolloInfo: this.store.getState().apolloInfo,
      bsPayConfig,
    })

    debuggerLog('useBsPay===CheckstandModal.init===payData---', payData)

    CheckstandManager.setup({
      bsUtils,
      bsPayConfig: bsPayConfig,
      encryptConfig,
      paymentCode: data.paymentInfo.payment_code,
      payData,
      payProductType: data.payProductType,
      payCheckoutType: data.payCheckoutType,
      onCancel: data.onCancel,
      onError: data.onError,
      onFail: data.onFail,
      onPayContinued: data.onPayContinued,
      onPayPaused: data.onPayPaused,
      onRequestCompleted: data.onRequestCompleted,
      onSuccess: data.onSuccess,
      paymentLoadingAction: data.paymentLoadingAction,
    })

    CheckstandManager.pay()
    MonitorReportBusiness.metric({
      scene: 'bs_create_payment',
      extraTags: {
        payment_code: data.paymentInfo.payment_code,
      },
    })
  }

  /**
   * 预创建支付流程，创单成功前调用
   *
   * @param {{
   *     selectedPaymentInfo: Trade_PayToolKit.SelectedPaymentInfo,
   *     orderInfo,
   *     verifyOptions: Trade_PayToolKit.PreCreatePaymentVerifyOptions
   *   }} {
   *     selectedPaymentInfo,
   *     orderInfo,
   *     verifyOptions,
   *   }
   * @memberof BsPay
   */
  preCreatePayment = ({
    selectedPaymentInfo,
    orderInfo,
  }: {
    selectedPaymentInfo: Trade_PayToolKit.SelectedPaymentInfo,
    orderInfo,
  }) => {
    const paymentCode = selectedPaymentInfo.payment_code
    debuggerLog('useBsPay==methods===preCreatePayment===data---', { selectedPaymentInfo, orderInfo })

    if (paymentCode) {

      this.handleInlineInitializeWinInstance({ paymentInfo: selectedPaymentInfo })

      const checkLtspcPrePay = () => {
        if (BsPay.bsUtils?.checkLtspcPaymentMethod?.(paymentCode) && selectedPaymentInfo.card_token_info?.id) {
          return true
        }
        return false
      }
      const isLtspcPrePay = checkLtspcPrePay()
      const bsPayConfig = this.store.getState().bsPayConfig
      LtspcPayManager.preInit({
        scene: bsPayConfig.checkoutType,
        useInline: bsPayConfig.useInline,
        bsUtils: BsPay.bsUtils as Trade_PayLibs.OutsideBsUtils,
        inlineUrl: location.href,
        paymentCode,
        currencyCode: orderInfo.currency_code || orderInfo.currencyCode,
        isPrePay: isLtspcPrePay,
      }).catch((error) => {
        debuggerLog('useBsPay===preCreatePayment===error---', error)
        MonitorReportAbnormal.metric({
          scene: 'ltspc_pre_init_error',
          extraTags: {
            payment_code: paymentCode,
          },
          extraParams: {
            is_pre_pay: isLtspcPrePay ? '1' : '0',
          },
        })
      })

      if (KlarnaInlineManager.needRefreshInlineSession({
        paymentCode,
      })) {
        this.refreshInlineSession(KlarnaInlineManager.PAYMENT_CODE)
      }
    }
  }

  /**
   * 更新BsPay配置
   *
   * eg. useInline - 收银台在风控场景自动发起创建订单，需重置useInline为false，优化用户交互流程
   *
   * @param {(Partial<Pick<BsPayConstructor, 'useInline' | 'checkoutType' | 'productType'>>)} info
   * @memberof BsPay
   */
  updateBsPayConfig = (info: Partial<Pick<BsPayConstructor, 'useInline' | 'checkoutType' | 'productType'>>) => {
    const bsPayConfig = this.store.getState().bsPayConfig
    const { useInline = bsPayConfig.useInline, checkoutType = bsPayConfig.checkoutType, productType = bsPayConfig.productType } = info || {}
    this.store.setState({
      scene: checkoutType,
      bsPayConfig: {
        ...bsPayConfig,
        useInline,
        checkoutType,
        productType,
      },
    })

    MonitorReport.updateCommonParams({
      checkoutType,
      productType,
      useInline: useInline ? '1' : '0',
    })
  }

  // 方法无效了，兼容老逻辑
  handlerInitializeWinInstance () {
    // console.log('useBsPay===handlerInitializeWinInstance---')
  }

  handleInlineInitializeWinInstance ({ paymentInfo }: { paymentInfo: Trade_PayToolKit.SelectedPaymentInfo } ) {
    const { payment_code, card_token_info } = paymentInfo || {} as Trade_PayToolKit.SelectedPaymentInfo
    const bsPayConfig = this.store.getState().bsPayConfig
    const checkLtspcPrePay = () => BsPay.bsUtils?.checkLtspcPaymentMethod?.(payment_code) && card_token_info?.id
    const isLtspcPrePay = checkLtspcPrePay()
    if (!bsPayConfig.useInline) return
    if ((checkPreOpenWinRedirectThirdParty(payment_code) || (BsPay.bsUtils?.checkLtspcPaymentMethod?.(payment_code) && !isLtspcPrePay))) {

      CreateNewWindow.initializeWinInstance({
        closeCb: () => {
          this.resetPayment?.()
          MonitorReportBusiness.metric({
            scene: 'bs_close_inline_win',
            extraTags: {
              payment_code,
            },
          })
        },
      })
    }
  }

  /**
   * 重置支付流程
   *
   * @memberof BsPay
   */
  resetPayment = () => {
    CreateNewWindow?.destroy?.()
    const unifiedPayOptions = this.store.getState().unifiedPayOptions
    unifiedPayOptions.params = {} as C_Libs_Pay.UnifiedPayParams
    this.store.setState({
      selectedPayment: {} as Trade_PayToolKit.AssembledToOutsidePaymentInfo,
      unifiedPayOptions: unifiedPayOptions as C_Libs_Pay.UnifiedPayOrgs<C_Libs_Pay.UnifiedPayParams>,
    })
  }

  getPaymentProcessInfo = ({
    paymentInfo,
    extraParams,
  }: Pick<CompatNewPayment, 'paymentInfo' | 'extraParams'>) => {
    const isGooglePay = GooglePayManager.googlePayCodeList.includes(paymentInfo.payment_code)
    const isApplePay = +paymentInfo.is_apple_pay === 1
    const isPreTokenPay = BsPay.bsUtils?.checkLtspcPaymentMethod?.(paymentInfo.payment_code) && paymentInfo.card_token_info?.id
    const isPrePay = BsPay.bsUtils?.checkLtspcPaymentMethod?.(paymentInfo.payment_code) && extraParams.routeId && extraParams.cardBin
    return {
      isGooglePay,
      isApplePay,
      isPreTokenPay,
      isPrePay,
    }
  }

  private refreshInlineSession = async (paymentCode: string) => {
    const { paySessionInfo, sessionInfoParams = {} as any } = this.store.getState()
    const params = {
      ...sessionInfoParams,
      payMethodList: [paymentCode],
    }
    if (paymentCode === KlarnaInlineManager.PAYMENT_CODE) {
      paySessionInfo[paymentCode] = {
        ...paySessionInfo[paymentCode],
        sessionId: '',
      }
    }
    const sessionInfo = await this.fetches.bffGetNewChannelSessionApi(params)
    const sessions = (sessionInfo?.data || sessionInfo || {}).info?.sessions || []
    const newSession = sessions.find(v => v.payMethod === paymentCode) || {}
    this.store.setState({
      sessionInfoParams,
      paySessionInfo: {
        ...(paySessionInfo || {}),
        [paymentCode]: newSession,
      },
    })
  }
}
