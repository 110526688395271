import { promiseAsyncAuto } from '../asyncAuto.js'
import { getDailyNewGenerateBffParams } from '../bffParamsHandle/index.js'
import {
  goodsApiCache,
  dailyNewFilterApiCache,
  dailyNewCloudTagApiCache,
  showRecentDaysTagApiCache,
} from '../api/bffApi.js'
import { generateRealSelectBffFilterInfo } from '../utils'
import { PRODUCT_LIST_REQUEST_URL_MAP } from '../constant.js'
import { getCommonRequestQueue } from './common.js'

const getDailyNewRequestQueue = ({ requestInfo, newSrcTabPageId }) => {
  const { goodsParams, filterParams, cloudTagParams, categoryDayTagParams, cccSeoParams } = getDailyNewGenerateBffParams(requestInfo, newSrcTabPageId)
  const requestQueue = {
    // 一些公共请求（多语言等）
    ...getCommonRequestQueue({ cccSeoParams }),
    // 商品列表
    goods: goodsApiCache.request({
      url: PRODUCT_LIST_REQUEST_URL_MAP.DAILY_NEW.goods,
      params: goodsParams,
      withoutAbort: requestInfo.query?.withoutAbort,
    }),
    // 筛选
    filterData: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, {})

      const result = await promiseAsyncAuto({
        bffCateAttrResult: dailyNewFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
        bffCloudTagResult: dailyNewCloudTagApiCache.request(cloudTagParams), // 标签云
        bffDailyDatesResult: showRecentDaysTagApiCache.request(categoryDayTagParams) // 标签云中的date标签数据
      })
      cb(null, generateRealSelectBffFilterInfo(result))
    },
  }
  return requestQueue
}

export {
  getDailyNewRequestQueue
}
