<script lang="jsx">
import CamelCasePrice from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/CamelCasePrice.vue'
import SuggestedSalePrice from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/SuggestedSalePrice.vue'
import { handlePriceHtml } from 'public/src/pages/components/product/item_v3/js/utils.js'
import { apolloSuggestedSaleConfig } from 'public/src/pages/components/product/item_v3/js/constant.js'

export default {
  name: 'HomeBffPrice',
  functional: true,
  props: {
    goodsInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    suggestedSaleType: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: () => ({
        showCamelPrice: false,
        camelPriceResizeFontsize: false,
        showDiscountLabelAfterPrice: false,
        showRetailPrice: false,
        showSuggestedSalePrice: false,
      }),
    },
    priceFontSize: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, { props }) {
    const { goodsInfo, suggestedSaleType, config, priceFontSize } = props
    const { GB_cssRight, lang } = gbCommonInfo
    let { salePrice, singleItemPrice, singleItemPriceText } = goodsInfo
    if (singleItemPrice?.amountWithSymbol && singleItemPriceText) { // 单件价
      singleItemPrice.amountWithSymbol = singleItemPriceText // 单件价单位拼接
      salePrice = singleItemPrice
    }
    const originString = salePrice?.amountWithSymbol || ''
    if (!originString) return null

    // 价格组件所需数据转换
    const getCamelComponentsData = () => {
      const { style = {}, showCamelPrice, camelPriceResizeFontsize } = config

      let camelPriceStyle = style?.camelPriceStyle || {}
      let currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix

      if (showCamelPrice) {
        let digitalSize = priceFontSize?.digitalSize
        if (digitalSize) {
          digitalSize = digitalSize + 'px'
        } else {
          digitalSize = camelPriceStyle?.before?.fontSize || '13px'
        }
        let decimalSize = priceFontSize?.decimalSize
        if (decimalSize) {
          decimalSize = decimalSize + 'px'
        } else {
          decimalSize = camelPriceStyle?.after?.fontSize || '9px'
        }
        camelPriceStyle = {
          before: {
            fontSize: digitalSize,
          },
          after: {
            fontSize: decimalSize,
          },
        }
        ;({ currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(
          originString,
          salePrice?.amount || '',
        ))
      }

      return {
        showCamelPrice,
        camelPriceResizeFontsize,
        originString,
        camelPriceStyle,
        currencySymbolPrefix,
        numberBeforeDot,
        numberAfterDot,
        currencySymbolSuffix,
      }
    }

    const isPromotionFun = () => {
      let isPromotionSalePrice = Number(goodsInfo?.unit_discount || 0) > 0 // 以折扣为准，展示促销色。
      let isOrigin =
        goodsInfo?.isPromotionPrice === 1 || goodsInfo?.estimatedPriceInfo?.isSatisfied == 1 || isPromotionSalePrice
      if (suggestedSaleType) {
        // 合规价
        // 合规时，判断折扣是否为0，返回0时，表示有很小的折扣，但是需要展示促销色。无折扣时返回为空或者null
        isPromotionSalePrice =
          isPromotionSalePrice || goodsInfo?.unit_discount === 0 || goodsInfo?.unit_discount === '0'
        if (suggestedSaleType === apolloSuggestedSaleConfig.SPECIALDE) {
          // 德国 价格右边显示原价
          const show =
            Number(
              goodsInfo?.suggestedSalePriceInfo?.suggestedSalePrice?.amount ||
                goodsInfo?.retailSuggestPrice?.amount ||
                goodsInfo?.retail_suggest_price?.amount ||
                0,
            ) > 0
          isOrigin = isPromotionSalePrice && show // 有30天最低价且有折扣
        } else {
          isOrigin =
            isPromotionSalePrice && Number(goodsInfo?.suggestedSalePriceInfo?.suggestedSalePrice?.amount || 0) > 0 // 有30天最低价且有折扣
        }
      }
      return isOrigin
    }

    const getContainerClassName = () => {
      let finalPriceClass = ''
      const isOrigin = isPromotionFun()
      const priceClass = {
        // 会员色
        brown: goodsInfo?.isPaidPrice === 1,
        // 促销色
        origin: isOrigin,
        // s3会员色
        darkGold: false, // s3会员价，后续接s3会员用
      }
      finalPriceClass = Object.keys(priceClass).find(key => {
        return !!priceClass[key]
      })
      return finalPriceClass || ''
    }

    // 折扣标签
    const getUnitDiscountString = () => {
      const { showDiscountLabelAfterPrice } = config ?? {}
      if (showDiscountLabelAfterPrice && Number(goodsInfo?.unit_discount || 0) > 0) {
        let _unitDiscountString = `${lang !== 'il' ? '-' : ''}${goodsInfo?.unit_discount}%`
        if (GB_cssRight) {
          _unitDiscountString = _unitDiscountString.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1')
        }
        return _unitDiscountString
      }
      return ''
    }

    // 合规价与原价处理
    const getPriceSuggested = () => {
      const { retailPrice, suggestedSalePriceInfo, retailSuggestPrice } = goodsInfo
      const priceRight = {
        show: false,
        price: {},
        labelText: '',
      }
      const priceBottom = {
        show: false,
        price: {},
        labelText: '',
        flipText: false,
      }
      if (!config.showSuggestedSalePrice) {
        return {
          priceRight,
          priceBottom,
        }
      }
      // 合规价
      const suggestedSalePrice = suggestedSalePriceInfo?.suggestedSalePrice || {}
      // 文案
      const description = suggestedSalePriceInfo?.description
      if (suggestedSaleType === apolloSuggestedSaleConfig.NORMAL) {
        // 法国 价格右边显示划线价
        priceRight.show = Number(suggestedSalePrice?.amount || 0) > 0
        priceRight.price = suggestedSalePrice ?? {}
        priceRight.labelText = description
      } else if (suggestedSaleType === apolloSuggestedSaleConfig.SPECIAL) {
        // 波兰 isSpecial 只显示一个划线价 放价格下边
        priceBottom.show = Number(suggestedSalePrice?.amount || 0) > 0
        priceBottom.price = suggestedSalePrice ?? {}
        priceBottom.labelText = description
      } else if (suggestedSaleType === apolloSuggestedSaleConfig.SPECIALDE) {
        // 德国 价格右边显示原价  价格下边显示划线价
        priceRight.show = Number(retailSuggestPrice?.amount || 0) > 0
        priceRight.price = retailSuggestPrice ?? {}
        priceBottom.show = Number(suggestedSalePrice?.amount || 0) > 0
        priceBottom.price = suggestedSalePrice ?? {}
        priceBottom.labelText = description
        priceBottom.flipText = true
      } else if (config.showRetailPrice) {
        // 显示原价
        priceRight.show = Number(retailPrice?.amount || 0) > 0
        priceRight.price = retailPrice ?? {}
      }
      return {
        priceRight,
        priceBottom,
      }
    }

    const camelComponentsData = getCamelComponentsData()
    const containerClassName = getContainerClassName()
    const unitDiscountString = getUnitDiscountString()
    const priceSuggested = getPriceSuggested()

    // :style="{ boxSizing: containerClassName === 'origin' ? 'border-box' : 'border-box'}" 写死。解决：返回首页，商品数据刷新时，containerClassName值已更改，但是模板未更新导致样式错误问题。原因未知
    return (
      <div
        class={['bff-price-container', 'not-fsp-element', containerClassName]}
        style={{ boxSizing: 'border-box' }}
      >
        <div class='bff-price-container__top'>
          {goodsInfo.salePrice && (
            <CamelCasePrice
              class='prices-info__sale-price'
              {...{ props: camelComponentsData }}
              salePriceStyle={config?.salePriceStyle || {}}
            />
          )}

          {unitDiscountString && <div class='discount-label'>{{ unitDiscountString }}</div>}

          {priceSuggested.priceRight.show && (
            <SuggestedSalePrice
              priceDeleteStyle
              value={priceSuggested.priceRight.price}
              labelText={priceSuggested.priceRight.labelText}
            />
          )}
        </div>

        {priceSuggested.priceBottom.show && (
          <SuggestedSalePrice
            priceDeleteStyle
            value={priceSuggested.priceBottom.price}
            labelText={priceSuggested.priceBottom.labelText}
            flipText={priceSuggested.priceBottom.flipText}
          />
        )}
      </div>
    )
  },
}
</script>

<style lang="less" scoped>
.bff-price-container {
  display: flex;
  flex-direction: column;
  color: var(--font-color, #333);
  padding: 2px 0;
  &.brown {
    --font-color: @sui_color_club_rosegold_dark1;
    --discount-label-color: @sui_color_club_rosegold_dark1;
  }
  &.origin {
    --font-color: @sui_color_discount;
    --discount-label-color: @sui_color_discount;
  }
  &.brown .prices-info__sale-price {
    color: @sui_color_club_rosegold_dark1;
  }
  &.origin .prices-info__sale-price {
    color: @sui_color_discount;
  }
  &.darkGold {
    --font-color: #806208;
    --discount-label-color: #806208;
  }
  // 折扣标签
  .discount-label {
    margin-left: 0.107rem;
    padding: 0 0.0533rem;
    align-self: center;
    height: 0.3733rem;
    line-height: 0.3467rem;
    direction: ltr /* rtl: ignore */;
    text-align: center;
    border: 1px solid var(--discount-label-color, @sui_color_discount);
    border-radius: 2px;
    color: @sui_color_discount;
    unicode-bidi: plaintext; // ar 站 rtl 只针对 ar 语言，一旦 英文与阿拉伯文混排，new 标签会加入英文的方向，导致显示问题
  }
  .bff-price-container__top {
    display: flex;
    align-items: baseline;
    font-weight: bold;
    max-width: 100%;
    height: 0.5333rem;
    flex-wrap: wrap;
    overflow: hidden; // 折扣标签换行后隐藏
  }
  :deep(.product-card__no-camel-case-price) {
    flex: 0 1 fit-content;
  }
}
</style>
