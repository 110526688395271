import schttp from 'public/src/services/schttp'
import { getQueryString } from '@shein/common-function'
import { getFeedbackRecInSession } from 'public/src/pages/product_list_v2/js/utils'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'


function getParams() {
  const attr_ids = getQueryString({ key: 'attr_ids' })
  const { ymal, similar, goodsId } = getFeedbackRecInSession() || {}

  return { attr_ids, ymal, similar, goodsId }
}
// 实时反馈用的
export async function getFeedBackListGoods({ cate_ids, goodsId, triggerEvent, discountRate, salePrice, isPaid, language }, requestInstance = null) {
  const { attr_ids, ymal, similar } = getParams()
  requestInstance?.abort()
  requestInstance = new SchttpAbortCon()
  const result = await schttp({
    method: 'GET',
    url: '/product/recommend/feedback_products',
    signal: requestInstance.signal,
    params: {
      triggerEvent: triggerEvent,
      goods_id: goodsId,
      goods_cate_id: cate_ids,
      goods_discount: discountRate,
      goods_price: salePrice,
      filter_goods_yaml: ymal ?? '',
      filter_goods_similar: similar ?? '',
      click_nav_attr: attr_ids ?? '',
      filter: attr_ids ?? '',
      limit: 10, // 默认最多10个
      noNeedBuyBox: 1,
    },
    useBffApi: true,
  })
  // 过滤售罄商品展示
  const isSoldOut = (item) => +item.stock === 0 || +item.is_on_sale === 0
  let goods = result?.info?.products.filter(item => !isSoldOut(item))?.slice(0, 30) || []
  let goodList = (goods || []).map(v => {
    return goodsTransformation(v, {
      language,
      lang: gbCommonInfo.lang ?? 'us',
      isPaid: !!isPaid,
      inStock: true, // 经BFF接口 确定返回的绝对是在售商品
    })
  })
  return goodList
}
