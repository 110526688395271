<template>
  <div :class="['klarna-inline-widget', randomClass]"></div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue'
import { KlarnaInlinePayment } from '@shein-aidc/bs-sdk-libs-pay'

const randomClass = `klarna-widget-${Math.random().toString(36).slice(2)}`

onMounted(() => {
  // console.log('klarna inline widget mounted', randomClass)
  KlarnaInlinePayment.setInstance(randomClass)
})

onBeforeUnmount(() => {
  KlarnaInlinePayment.removeInstance(randomClass)
})

</script>

<style lang="less" scoped>
.klarna-inline-widget {
  margin-top: 0.32rem;
  position: relative;
  z-index: 1;
}
</style>
