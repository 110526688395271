import schttp from 'public/src/services/schttp'



// 获取未读消息数
export const getUnreadCount = async ({ params }) => {
  const res = await schttp({ 
    url: '/user/cschat/get_unread_count',
    method: 'POST',
    data: params,
    useBffApi: true,
  })
  const { code, info } = res || {}
  const { tk_unread_count = 0, total_unread_count = 0, store_tk_unread_count = 0 } = info || {}
  return {
    code,
    info: {
      tk_unread_count,
      chat_unread_count: total_unread_count,
      store_tk_unread_count
    }
  }
}

// 获取渠道入口开关
export const getBotSwitch = () => {
  return schttp({ 
    url: '/ticket/robot_customer_chan',
    useBffApi: true,
  })
}
