import { iteratePayments } from '@/public/src/pages/checkout_new/utils/index.js'

export default {
  paymentInfo: (state) => {
    const { paymentSuggestion, isUsedThisPayment } = state.checkout?.payment_info || {}
    return Object.assign({}, state.checkout?.payment_info, {
      // bnpl场景，如果isUsedThisPayment为1，且当前入参有支付方式，则默认选中
      paymentSuggestion: +isUsedThisPayment === 1 && state.checkoutParams.payment_code && state.urlPaymentCode ? state.checkoutParams.payment_code : paymentSuggestion
    })
  },
  selectedPaymentInfo: (state) => {
    // typeof window !== 'undefined' && console.log('pay>>getter==selectedPaymentInfo===getter====', state.paymentState, state.checkout?.payment_info)
    return state.paymentState.selectedPaymentInfo
  },
  // paypal 支付按钮显示状态
  isShowPaypalVenmoBtn(state, getter) {
    // console.log('pay>>getter====isShowPaypalVenmoBtn====', getter.selectedPaymentInfo, getter.selectedPaymentInfo?.payment_code === 'PayPal-Venmo')
    return getter.selectedPaymentInfo?.payment_code === 'PayPal-Venmo'
  },
  isShowApplePayBtn(state, getter) {
    return +getter?.selectedPaymentInfo?.is_apple_pay === 1 && !!getter?.selectedPaymentInfo.show_channel_btn
  },
  isShowPaypalGaButton(state, getter) {
    return getter.selectedPaymentInfo?.payment_code === 'PayPal-GApaypal' && getter.selectedPaymentInfo?.show_channel_btn
  },
  isShowPaypalBnplButton(state, getter) {
    return getter.selectedPaymentInfo?.payment_code === 'PayPal-bnpl'
  },
  isSupportApplePay (state, getter) {
    const payments = getter.paymentInfo?.payments || []
    return payments.some((item) => +item.is_apple_pay === 1 && +item.enabled === 1) || getter.isShowApplePayBtn
  },
  paymentCheckoutInfo (state) {
    const { prime_product_code, save_card_product_code } = state.checkoutParams || {}
    const { has_platform_goods = '0', address = {}, channelSession = '', orderCurrency = {}, sorted_price = [], total_price_info = {}, coBrandedCardIntegral = 0 } = state.checkout || {}
    return {
      has_platform_goods,
      channelSession,
      addressInfo: address,
      orderCurrencyInfo: orderCurrency,
      sortedPriceInfo: sorted_price,
      totalPriceInfo: total_price_info?.grandTotalPrice || {},
      virtualOrderScene: (prime_product_code || save_card_product_code) ? 2 : undefined, // 虚拟商品（付费会员or超省） 1-单独购 2-随单购 不传或其他值-代表无虚拟商品
      coBrandedCardIntegral, // 联名卡积分
    }
  },
  paymentDiscountInfo (state, getter) {
    const payments = getter.paymentInfo?.payments || []
    let paymentsPreferentialTips = []
    iteratePayments(payments, payment => {
      if (payment?.paymentsPreferentialTips) {
        paymentsPreferentialTips.push(...payment.paymentsPreferentialTips)
      }
    })
    return paymentsPreferentialTips
  },
  hasPaymentDiscount (state, getter) {
    return getter.paymentDiscountInfo.length > 0
  },
  hasCardBinDiscount (state, getter) {
    return getter.paymentDiscountInfo.some(item => item?.type === '1' || item?.type === '4')
  },
  /**
   * 当前选中的支付方式信息
   *
   * @param {*} state
   * @param {*} getter
   */
  selectedPaymentItemStatus (state, getter) {
    const recordPaymentOptionsConfig = state.paymentState.recordPaymentOptionsConfig || {}
    const { folded_position = '', paymentSuggestion = '', payments = [] } = getter.paymentInfo || {}
    const { signupFlag, payment_code, use_one_time_sign } = getter.selectedPaymentInfo || {}

    const foldedPosition = recordPaymentOptionsConfig?.foldedPosition != null ? recordPaymentOptionsConfig?.foldedPosition : folded_position

    const paymentIndex = payments?.findIndex?.(item => item.code === payment_code) || -1
    const foldedPosIndex = +(foldedPosition || 1) - 1

    let is_vaulting = '-'
    let is_choose_foldedpayment = paymentIndex > foldedPosIndex ? '1' : '0'
    let is_default = paymentSuggestion === payment_code ? '1' : '0'
    let token_status = use_one_time_sign ? 'token_onetime' : 'token_status'

    if (payment_code === 'PayPal-GApaypal') {
      is_vaulting = (+signupFlag === 1 || !use_one_time_sign) ? '1' : '0'
    }

    return {
      is_vaulting,
      is_choose_foldedpayment,
      is_default,
      token_status
    }
  },
  injectFsData (state) {
    return {
      abtInfo: state.abtInfo,
      language: state.payBsLanguage
    }
  },
  isShowChannelBtn (state, getter) {
    return getter.isShowPaypalVenmoBtn || getter.isShowApplePayBtn || getter.isShowPaypalGaButton || getter.isShowPaypalBnplButton
  }
}
