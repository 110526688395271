import { useEventBus, type UseEventBusReturn } from '@shein-aidc/bs-sdk-libs-pay'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'

// type Constants = Record<'selectedFinish', UseEventBusReturn<string, any>>
export type {
  UseEventBusReturn,
}


export const createEventBus = () => {
  return {
    // 通知触发对外事件select
    selectedFinish: useEventBus<unknown>(Symbol('selected-finish')),
    // 通知触发payment-info-change
    paymentInfoChange: useEventBus<Trade_PayToolKit.AssembledToOutsidePaymentInfo>(Symbol('payment-info-change')),
    setSelectedPaymentByCode: useEventBus<Trade_PayToolKit.PaymentInfoItem>(Symbol('manual-set-payment')),
    // 列表内容增加删除list-content-change
    listContentChange: useEventBus<Trade_PayToolKit.PaymentInfoItem[]>(Symbol('list-content-change')),

    // checkout 接口触发后关于支付的埋点事件
    paymentValidSendBi: useEventBus<unknown>(Symbol('PAYMENT_VALID_SEND_BI')),
    setOrderShowInfo: useEventBus<unknown>(Symbol('SET_ORDER_SHOW_INFO')),
    // // 通知计价接口调用完成
    // cacuTotalFinish: useEventBus<string>('cacu-total-finish'),
    // // 通知创建订单
    // createOrder: useEventBus<string>('create-order'),
    // // 通知显示或隐藏默认卡bin优惠信息
    // showDefaultCardBinDiscount: useEventBus<string>('show-default-card-bin-discount'),
    // // 重置表单数据
    // : useEventBus<string>('reset-form-fields'),
  }
}

// /**
//  * 提供
//  * @param {Object} constants
//  */
// export const useProvidePaymentEventBus = () => {
//   provide<Constants>(PAY_TOOLKIT_EVENT_BUS_INJECT_KEY, paymentEventBus)
//   return paymentEventBus
// }

// /**
//  * 注入
//  */
// export const useInjectPaymentEventBus = () => {
//   return inject<Constants>(PAY_TOOLKIT_EVENT_BUS_INJECT_KEY, paymentEventBus)
// }
