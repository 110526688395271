import Vue from 'vue'
import KidsTermsModal from './Container.vue'

const Comp = Vue.extend(KidsTermsModal)

export const showKidsTermsModal = (propsData = {}) => {
  if (typeof window === 'undefined') return

  const instance = new Comp({ propsData }).$mount()

  const onDdestroy = () => {
    window.setTimeout(() => {
      instance.$destroy()
    }, 100)
  }

  return new Promise((resolve) => {
    instance.$on('success', (data) => {
      resolve({ action: 'success', data })
      onDdestroy()
    })

    instance.$on('error', (data) => {
      resolve({ action: 'error', data })
      onDdestroy()
    })

    instance.$on('cancel', () => {
      // 无操作，关闭
      resolve({ action: 'cancel' })
      onDdestroy()
    })
  })
}
