<!-- 当前文件为主入口 -->
<!-- 任何能收敛到对应业务组件的逻辑都不要写到这里来 -->
<!-- 组件命名 A、命名语义化； B、命名使用动宾短语 -->
<template>
  <div
    id="checkout-app"
    v-expose="{
      id: 'expose_scenesabt:simple',
      data: {
        scenes: +checkout.burying_point.hasEffectIgnoreAbt === 1 ? 'holidayDeliveryShow' : ''
      }
    }"
    class="checkout__container"
  >
    <!-- header -->
    <Header />

    <!-- 吸顶容器[公告，税减免公告，无地址，断网] -->
    <StickyArea
      v-if="visibleStickArea"
    />

    <!-- 地址栏 -->
    <AddressBar class="checkout__container-item" />

    <ClientOnly>
      <!-- 在地址下方，购物袋上方 -->
      <BenefitFloor
        class="checkout__container-item"
        :show-bar="isOnlyShowBenefitBar"
        :location="0"
      />
    </ClientOnly>

    <!-- 运输方式 -->
    <ShippingMethod
      v-if="layoutMode === CombinationMode.Separation"
      class="checkout__container-item"
      :layout="layoutMode"
    />

    <div
      v-if="layoutMode === CombinationMode.Combination"
      class="checkout__container-item"
    >
      <div
        v-for="(_, index) in shoppingBagsState?.bagsInfo?.mall"
        :key="index"
      >
        <!-- 购物袋 -->
        <ShoppingBags
          :mallIndex="index"
        />

        <!-- 运输方式 -->
        <ShippingMethod
          :mallIndex="index"
          :layout="layoutMode"
        />
      </div>
    </div>

    <!-- 随手购如果融合，则使用如下 -->
    <!-- <BuyTogether /> -->
    <BuyTogetherOld
      class="checkout__container-item"
      :location="1"
    />

    <ClientOnly>
      <!-- 在支付方式上方 -->
      <BenefitFloor
        class="checkout__container-item"
        :location="1"
      />
    </ClientOnly>

    <!-- 支付方式列表 -->
    <PaymentOptions
      v-if="!isShowBottomPaymentSelector.show"
      class="checkout__container-item"
      @selected-payment="handleSelectedPayment"
    />

    <ClientOnly>
      <!-- 付费会员 -->
      <CheckoutPrime
        v-if="abtState.LocationPrimeSaverStatus"
        class="checkout__container-item"
      />

      <!-- 超省卡 -->
      <CheckoutSaver
        v-else
        class="checkout__container-item"
      />
    </ClientOnly>

    <!-- 随手购如果融合，则使用如下 -->
    <!-- <BuyTogether /> -->
    <BuyTogetherOld
      class="checkout__container-item"
      :location="2"
    />

    <!--虚拟资产-->
    <VirtualAssets class="checkout__container-item" />

    <ClientOnly>
      <!-- 超省卡 -->
      <CheckoutSaver
        v-if="abtState.LocationPrimeSaverStatus"
        class="checkout__container-item"
      />

      <!-- 付费会员 -->
      <CheckoutPrime
        v-else
        class="checkout__container-item"
      />
    </ClientOnly>

    <!-- 随手购如果融合，则使用如下 -->
    <!-- <BuyTogether /> -->
    <BuyTogetherOld
      class="checkout__container-item"
      :location="3"
    />

    <!-- 购物袋 -->
    <ShoppingBags
      v-if="layoutMode === CombinationMode.Separation"
      class="checkout__container-item"
    />

    <ClientOnly>
      <!-- 在价格明细上方 -->
      <BenefitFloor
        class="checkout__container-item"
        :location="2"
      />
    </ClientOnly>

    <!-- 价格明细 -->
    <PriceInfo
      class="checkout__container-item"
      :is-in-drawer="false"
    />

    <!-- 积分利诱点 -->
    <RewardPoints
      class="checkout__container-item"
    />

    <!--订单返券-->
    <ReturnCoupon
      class="checkout__container-item"
    />

    <!--付费会员回本信息-->
    <PrimeBenefit
      class="checkout__container-item"
    />

    <!--辅助信息-->
    <SupportInfo
      class="checkout__container-item"
      scene="Checkout"
      :assistPolicy="assistPolicy"
      :language="language"
      :paymentSafetyExperimentAbt="abtState.paymentSafetyExperimentAbt"
      :GB_cssRight="GB_cssRight"
    />

    <!--隐私权益-->
    <AgreePolicyTip
      :isShowNewTcPpTip="abtState.isShowNewTcPpTip"
      :language="language"
    />

    <!-- footer -->
    <Footer />

    <ClientOnly>
      <ShipMethodPopup />

      <CheckoutDialog />

      <SwitchCardDrawer
        v-if="primeSaverState.switchCardDrawer.show"
      />

      <MyCouponList />

      <s-loading
        type="curpage"
        :mask-transparent="true"
        :show="checkoutLoading.loading"
      />

      <PaymentLoading
        v-if="abtState.isUseNewCheckoutLoading"
        :language="language"
      />

      <BackTips
        v-if="selectedPaymentInfo?.payment_code === 'cod'"
        ref="paymentBackTipsRef"
        @back-tips-mounted="handleBackTipsMounted"
      />
    </ClientOnly>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { stateFactory } from './store/state'
import { exposeScenesAbtAggregatorBff as exposeScenesAbtAggregator } from 'public/src/pages/checkout/tools/EventAggregator.js'

export default defineComponent({
  asyncData({ store }) {
    // 仅用于判定曾使用过 state
    if (store.state.checkout.ssrPageType) {
      store.state.checkout = stateFactory()
    }
    store.commit('checkout/asyncDataMutations', window.gbCheckoutSsrData)
  },
  beforeMount() {
    if (this.$store.state.checkout.prerenderSsr) {
      this.$el = document.querySelector('#prerender-app #checkout-app')
    }
  },
  beforeRouteEnter(to, from, next) {
    if (typeof window === 'undefined') return next()

    next(vm => {
      vm.reportPvAnalysis(from)
    })
  },
  beforeRouteLeave(to, from, next) {
    window.gbCheckoutSsrData = {}

    next(vm => {
      vm.handlePageScreenInViewArea()
    })
  }
})
</script>

<script setup>
import { onBeforeMount, onMounted, nextTick, computed } from 'vue'
import ClientOnly from 'vue-client-only'
import { Toast as $toast } from '@shein/sui-mobile'

import Header from './pages/header/Index.vue'
import StickyArea from './pages/sticky/Index.vue'
import ShoppingBags from './pages/shopping_bags/Index.vue'
import Footer from './pages/footer/Index.vue'
import ShippingMethod from './pages/shipping_method/Wrapper.vue'
import PrimeBenefit from './pages/prime_benefit/index.vue'
import AgreePolicyTip from './pages/agree_policy/index.vue'
import SupportInfo from './pages/support_info/index.vue'
import RewardPoints from './pages/reward_points/Index.vue'
import ReturnCoupon from './pages/return_coupon/index.vue'
import VirtualAssets from './pages/virtual_assets/Index.vue'
import AddressBar from './pages/address_bar/index.vue'
import PaymentOptions from './pages/payment_options/index.vue'
import CheckoutPrime from './pages/prime_saver/PrimeIndex.vue'
import CheckoutSaver from './pages/prime_saver/SaverIndex.vue'
import PriceInfo from './pages/price/Index.vue'
import MyCouponList from '@/public/src/pages/checkout_new/components/MyCouponList.vue'
import BenefitFloor from './pages/benefit_floor/Index.vue'

import { AsyncComponents } from './pages/asyncComponents'

import {
  useMapState,
  useMapGetters,
  useMapMutations,
  useMapActions
} from '@/public/src/pages/checkout_new/hooks/store'

import { useRouter } from '@/public/src/pages/checkout_new/hooks/useRouter.js'
import { usePayment } from '@/public/src/pages/checkout_new/hooks/usePayment'
import { usePaymentBackTips } from '@/public/src/pages/checkout_new/hooks/usePaymentBackTips'
import { useNetworkStatus } from '@/public/src/pages/checkout_new/hooks/useNetworkStatus.js'
import usePvReport from '@/public/src/pages/checkout_new/hooks/usePvReport.js'
import useBindEvents from '@/public/src/pages/checkout_new/hooks/useBindEvents.js'
import useMixPrivacy from '@/public/src/pages/checkout_new/hooks/useMixPrivacy.js'
import useReportAnalysis from '@/public/src/pages/checkout_new/hooks/useReportAnalysis.js'
import { useJudgeNextWork } from '@/public/src/pages/checkout_new/hooks/useJudgeNextWork.js'
import useCoupon from '@/public/src/pages/checkout_new/hooks/useCoupon.js'
import { useTogether } from '@/public/src/pages/checkout_new/hooks/useTogether'
import { CombinationMode } from '@/public/src/pages/checkout_new/utils/constant.js'


const router = useRouter()
useTogether()

/**
 * @description 板块异步组件
 * @description 非首屏，not-fsp-element，条件触发的都在此引入
 * */
const {
  // 待融合,r
  // BuyTogether, 如果融合，则使用如下
  // 融合前
  BuyTogetherOld,
  CheckoutDialog,
  SwitchCardDrawer,
  ShipMethodPopup,
  PaymentLoading,
  BackTips,
} = AsyncComponents

// --------- useMap_**** --------
const {
  layoutMode,
  checkout,
  shoppingBagsState,
  checkoutLoading,
  abtState,
  language,
  primeSaverState,
  checkoutNotice,
  assistPolicy,
  GB_cssRight,
} = useMapState([
  'layoutMode',
  'checkout',
  'shoppingBagsState',
  'checkoutLoading',
  'abtState',
  'language',
  'primeSaverState',
  'checkoutNotice',
  'assistPolicy',
  'GB_cssRight',
])
const { isHasAddress, hasTaxNotice, isShowBottomPaymentSelector, selectedPaymentInfo, rewardFloorConfig } = useMapGetters(['isHasAddress', 'hasTaxNotice', 'isShowBottomPaymentSelector', 'selectedPaymentInfo', 'rewardFloorConfig'])
const { handleRapidInfo, updateTaxReliefInfo, checkoutComplete } = useMapMutations(['handleRapidInfo', 'updateTaxReliefInfo', 'checkoutComplete'])

const { exchangingSpaScene, updateCheckoutParams, updateStock } = useMapActions([
  'exchangingSpaScene',
  'updateCheckoutParams',
  'updateStock'
])

const { paymentBackTipsRef, handleBackTipsMounted } = usePaymentBackTips()

// ------------- Hooks ----------
const { handleSelectedPayment } = usePayment()
const { handleNetworkEventAdd, handleNetworkEventRemove } = useJudgeNextWork()
const { reportPvAnalysis } = usePvReport()
const { bindEvents } = useBindEvents()
const { handleMixPrivacy } = useMixPrivacy()
const {
  handleAutoUseCoupon,
  handleAutoUsePoint,
  handleAutoUseWallet,
  handleSingleBag,
  handleQuickShipping,
  handleInterestsData,
  handlePageScreenInViewArea,
  handleShippingAnalysis,
  handleNotSupportCodTipsCode
} = useReportAnalysis()
const { useGetCouponList } = useCoupon()
const { isOnline } = useNetworkStatus()

// ----------- computed ---------
const visibleStickArea = computed(() => {
  return !isOnline ||
  !isHasAddress.value ||
  !!checkoutNotice.value?.content ||
  hasTaxNotice.value
})

const isOnlyShowBenefitBar = computed(() => rewardFloorConfig.value.rewardBarVisible && !rewardFloorConfig.value.rewardFloorVisible)

// ------------- method ---------
const initData = () => {
  reportAnalysis()

  updateStock({
    outStockCarts: checkout.value?.outStockCarts,
    outStockCartsTip: checkout.value?.outStockCartsTip
  })
  handleRapidInfo()
  updateTaxReliefInfo({ checkout: checkout.value })


  // 将创建订单需要的fingerprint提前
  window._GB_DeviceFingerPrint.callFunc(() => {})

  // 初始化一站多合规信息
  handleMixPrivacy()

  bindEvents()

  // 网络状态监听
  handleNetworkEventAdd()
  handleNetworkEventRemove()

  // 获取券列表数据
  nextTick(() => {
    useGetCouponList()
  })

  // 监听回退事件
  window.addEventListener(
    'popstate',
    () => {
      if (!router && !location.href.includes('/checkout')) {
        location.reload()
      }
    },
    false
  )

  if (
    !!checkout.value?.bottomFloatingHint?.hintTip &&
    +checkout.value?.unUsedBenefitInfo?.unusedBenefit === 1
  )
    $toast({
      htmlString: checkout.value?.bottomFloatingHint?.hintTip, // 存在富文本
      duration: 3000
    })
}

// 初始时埋点上报
const reportAnalysis = () => {
  handleAutoUseCoupon()
  handleAutoUsePoint()
  handleAutoUseWallet()
  handleShippingAnalysis()
  handleSingleBag()
  handleQuickShipping()
  handleInterestsData()
  handleNotSupportCodTipsCode()
}

defineExpose({
  reportPvAnalysis,
  handlePageScreenInViewArea
})

onBeforeMount(() => {
  updateCheckoutParams({ checkout: checkout.value, isInit: true })
  checkoutComplete({ scene: 'checkoutInit', status: 'success' })

  // 销毁聚合埋点实例
  exposeScenesAbtAggregator.flush()
  exposeScenesAbtAggregator.destroy()
})

onMounted(() => {
  exchangingSpaScene()
  if (!router) reportPvAnalysis()

  initData()

  // 依赖初始数据只需处理一次的事件
  import(
    /* webpackChunkName: "checkout-js" */ 'public/src/pages/checkout/components/checkout.js'
  )
})
</script>

<style lang="less">
.checkout__container {
  background: #f6f6f6;
  // 等同于原来 mshe-top-pad 的字体设置
  font-size: 14px;
  padding-top: 1.1733rem;
  padding-bottom: 112/75rem;

  .checkout__container-item {
    margin-bottom: 16/75rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  // .S-alert {
  //   padding: 0.32rem;
  // }
}

body {
  margin: 0 auto;

  * {
    box-sizing: border-box;
  }

  .mshe-z-returnTop {
    z-index: 99;
  }
  // 输入框获取焦点时禁用点击事件,反之亦然
  &.disabled-checkout__body-click{
    pointer-events: none;
  }
}
</style>
