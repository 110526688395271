<!-- 老数据流大部分是跟 sizeGuide 有关 -->
<template>
  <div
    class="product-middle j-direct-box product-middle__show"
  >
    <template v-if="allDataReady">
      <BuyBoxV2 />
    </template>

    <!-- 品牌集成店移到顶部  -->
    <div
      v-if="is_show_new_store_and_brand"
      class="product-middle__wrap"
    >
      <StoreBusinessBrandEntrace
        :config="{ v2: true }"
      />
    </div>

    <CatSelectionTagEnterV2
      v-if="!category_selection_floor_new"
      :categorySelectionFloorBo="category_selection_floor_bo" 
    />

    <div 
      v-if="shipAndDetailSortType == 2 || (shipAndDetailSortType != 2 && notIN && hasShippingData)"
      class="product-middle__wrap"
    >
      <template v-if="shipAndDetailSortType != 2 && notIN">
        <ShippingEnterContainer v-if="realTimeReady" />
      </template>
      <MiddleAttr v-if="shipAndDetailSortType == 2" />
    </div>

    <TrendsEnter 
      v-if="goodsReady && shipAndDetailSortType != 2 && trends_info.show" 
      :trendsInfo="trends_info"
      :goodsId="goods_id"
      :catId="cat_id"
    />

    <template v-if="goodsReady && shipAndDetailSortType == 2">
      <NewParallelImport
        v-if="show_parallel_import"
        :language="language_v2"
        :router="$router"
        :exposeInfo="{
          goods_id: goods_id,
          mall_code: mall_code,
        }"
      />
    </template>
    <div class="j-direct-box">
      <div 
        v-if="shipAndDetailSortType != 2 || (shipAndDetailSortType == 2 && notIN && hasShippingData)"
        class="product-middle__wrap"
      >
        <template v-if="shipAndDetailSortType == 2 && notIN">
          <ShippingEnterContainer v-if="realTimeReady" />
        </template>
        <MiddleAttr v-if="shipAndDetailSortType != 2" />
      </div>

      <TrendsEnter 
        v-if="goodsReady && shipAndDetailSortType == 2 && trends_info.show" 
        :trendsInfo="trends_info"
        :goodsId="goods_id"
        :catId="cat_id"
      />

      <template v-if="goodsReady && shipAndDetailSortType != 2">
        <NewParallelImport
          v-if="show_parallel_import"
          :language="language_v2"
          :router="$router"
          :exposeInfo="{
            goods_id: goods_id,
            mall_code: mall_code,
          }"
        />
      </template>

      <DetailsPictures
        v-if="detail_pic_box.show"
        :value="detail_pic_fold"
        :content="detail_pic_box"
        @input="update_detail_pic_fold($event)"
      />

      <!-- Sign Board: Store/Brand/Series -->
      <!-- 依赖推荐位B类型时(p1) Store/Brand/Series 放置于 Reviews 下方 -->
      <DetailSignBoard
        v-if="allDataReady && detailSignBoardPosition === 'p1' && sign_data"
        :config="sign_config"
        :sign-data="sign_data"
      />

      <!-- 上方组合购 -->
      <!-- <FrequentBoughtTogether v-if="allDataReady && frequentlyBoughtLayout[0]" /> -->
      <NewReviews 
        v-if="show_reviews"
        ref="reviews"
        class="product-middle__reviews"
      />
      <!-- 评论图集模块 -->
      <div v-else-if="galleyLayout !== 'None'">
        <NewSHEINerZone mustShowTitle />
      </div>
      <CartEntry v-if="isReviewBelow && allDataReady" />
      
      <!-- Sign Board: Store/Brand/Series -->

      <DetailSignBoard
        v-if="allDataReady && detailSignBoardPosition === 'p2' && sign_data"
        :config="sign_config"
        :sign-data="sign_data"
      />
      <!-- CCC Banner 位 -->
      <!-- <CccBanner /> -->
      <CccBannerBff />
      <!-- New Get The Look -->
      <NewGetTheLook v-if="allDataReady && isShowNewGetTheLook" />
      <!-- 新组合购，和旧组合购互斥 -->
      <template v-if="allDataReady">
        <ComboBuyBff />
      </template>
      <!-- 下方组合购 -->
      <!-- <FrequentBoughtTogether v-if="allDataReady && frequentlyBoughtLayout[1]" /> -->
      <!-- <GetTheLook v-if="allDataReady && !hideOldGtlAndOutfit && (gtlAloneByAbt || !recommendWithSoleType)" /> -->
      <StyleToMatch v-if="matching_style_block_show" />
      <RankRecommendEnter 
        v-if="rank_recommend_show"
      />
      <CartEntry v-if="isRecommendAbove && allDataReady" />
    </div>

    <!-- 以下为各类弹窗 ⬇️ 后续优化方向为 弹窗都放到顶级组件 container 中作用 如果不涉及页面交互的话 最好做成实例调用形式（js指令调用） -->
    <template v-if="sizeGuidePopReady">
      <SizeGuideDrawer
        :key="goods_id"
        v-model="showSizeGuideDrawerProxy"
        :show-size-guide-drawer="showSizeGuideDrawerProxy"
        :language="language_v2"
        :tspShowBuySizeTable="tspShowBuySizeTable"
        :pageComponents="pageComponents"
        :sizeGuidPlanA="true"
        :modelContent="models_info"
        :currentLocalCountry="currentLocalCountry"
        :modelRelativesConfig="model_relatives_config"
        :localsize="localsize"
        :country="currentCountry"
        :skc-sale-attr="skc_sale_attr"
        :size-info-des="sizeInfoDes"
        :sizepriority="sizeConfig?.sizepriority"
        :size-guide-fit="sizeConfig?.sizeguidefit"
        :detail="detail"
        :is-new-size-local="isNewSizeLocal"
        @changeSizeUnit="updateCurrentLocalUnit"
        @changeLocalSize="updateLocalSize"
      />
    </template>
    <!-- 评论弹窗  切换商品重新渲染 -->
    <template v-if="view_comment_ready">
      <NewCommentPop />
      <NewCommentImgPop v-if="show_review_imgbox" />
    </template>

    <!-- 鞋子尺码推荐 -->
    <template v-if="sizeRecommandPopReady">
      <SizeRecommend
        v-model="sizeRecommand"
        :country="currentCountry"
        :is-new-size-local="isNewSizeLocal"
      />
    </template>
    <!--内衣自有尺码推荐-->
    <template v-if="braSizeRecommendPopReady">
      <BraSizeRecommend v-model="braSizeRecommend" />
    </template>
    <!--check my size和size guide混合入口推荐-->
    <template v-if="checkSizeAndSizeGuidePopReady">
      <checkSizeAndSizeGuide 
        :key="goods_id" 
        v-model="checkSizeAndSizeGuide" 
      />
    </template>
    <CartBag
      v-if="showFloatCartIcon"
      ref="detailCartBag"
      :goods-id="goods_id"
      :class="{ 'product-middle__cart-bag': existEstimatedPrice }"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import NewSHEINerZone from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/CustomerGallery/SHEINerZone.vue'
import NewReviews from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Reviews/index.vue'
import BuyBoxV2 from 'public/src/pages/goods_detail_v2/innerComponents/recommend/BuyBox/index.vue'
import CartBag from 'public/src/pages/product_list_v2/components/CartBag/index.vue'
// todo 优化 不挂在进this 每个使用实例单独引用
import { selectionPopup } from 'public/src/pages/goods_detail_v2/innerComponents/drawer/select_quick_add'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// 旧的组件
// import DetailsPicturesV1 from './innerComponents/DetailsPictures/index.vue'
// 新 BFF 的组件


if (typeof window !== 'undefined') {
  Vue.prototype.$selectionPopup = selectionPopup
}

export default {
  name: 'MiddleContent',
  components: {

    // 新 BFF 的组件
    DetailsPictures: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleDetailsPictures',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleDetailsPictures" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/DetailsPictures/index.vue')
    }),
    StoreBusinessBrandEntrace: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleStoreBusinessBrandEntrace',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleStoreBusinessBrandEntrace" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/DetailSignBoard/StoreBusinessBrandEntrace.vue')
    }),
    ComboBuyBff: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleComboBuyBff',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleComboBuyBff" */ 'public/src/pages/goods_detail_v2/innerComponents/recommend/ComboBuy/index.vue')
    }),
    BuyBoxV2,
    NewParallelImport: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleNewParallelImport',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleNewParallelImport" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Parallelimport/index.vue')
    }),
    TrendsEnter: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleTrendsEnter',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleTrendsEnter" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/TrendsEnter/index.vue')
    }),
    ShippingEnterContainer: prefetchResource.importAsyncComponent({
      chunkName: 'ShippingEnterContainer',
      componentFactory: () => import(/* webpackChunkName: "ShippingEnterContainer" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/ShippingEnterContainer/index.vue')
    }),
    MiddleAttr: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleAttr',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleAttr" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/MiddleAttr/index.vue')
    }),
    DetailSignBoard: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleDetailSignBoard',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleDetailSignBoard" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/DetailSignBoard/index.vue')
    }),
    NewSHEINerZone,
    NewReviews,
    NewGetTheLook: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleNewGetTheLook',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleNewGetTheLook" */ 'public/src/pages/goods_detail_v2/innerComponents/recommend/NewGetTheLook/index.vue')
    }),
    StyleToMatch: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleStyleToMatch',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleStyleToMatch" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/style-to-match/index.vue')
    }),
    RankRecommendEnter: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleRankRecommendEnter',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleRankRecommendEnter" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/RankRecommendEnter/index.vue')
    }),
    CatSelectionTagEnterV2: prefetchResource.importAsyncComponent({
      chunkName: 'goodsDetailMiddleCatSelectionTagEnterV2',
      componentFactory: () => import(/* webpackChunkName: "goodsDetailMiddleCatSelectionTagEnterV2" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/CatSelectionTagEnter/index.vue')
    }),
    CartBag,
    // 下面为弹窗
    SizeGuideDrawer: prefetchResource.importAsyncComponent({
      chunkName: 'SizeGuideDrawer',
      componentFactory: () =>
        import(
          /* webpackChunkName: "SizeGuideDrawer" */ 'public/src/pages/goods_detail_v2/legacy/SizeGuide/SizeGuideDrawer.vue'
        )
    }),
    NewCommentPop: prefetchResource.importAsyncComponent({
      chunkName: 'NewCommentPop',
      componentFactory: () =>
        import(
          /* webpackChunkName: "NewCommentPop" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Reviews/components/Pop/CommentPop.vue'
        )
    }),
    NewCommentImgPop: prefetchResource.importAsyncComponent({
      chunkName: 'NewCommentImgPop',
      componentFactory: () =>
        import(
          /* webpackChunkName: "NewCommentImgPop" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Reviews/components/Pop/CommentImgPop.vue'
        )
    }),
    SizeRecommend: () =>
      import(
        /* webpackChunkName: "SizeRecommend" */ 'public/src/pages/goods_detail_v2/legacy/SizeRecommend/SizeRecommend.vue'
      ),
    BraSizeRecommend: () =>
      import(
        /* webpackChunkName: "BraSizeRecommend" */ 'public/src/pages/goods_detail_v2/legacy/SizeRecommend/BraSizeRecommend.vue'
      ),
    checkSizeAndSizeGuide: () =>
      import(
        /* webpackChunkName: "checkSizeAndSizeGuide" */ 'public/src/pages/goods_detail_v2/legacy/CheckSizeAndSizeGuide/index.vue'
      ),
    CartEntry: () =>
      import(
        /* webpackChunkName: "CartEntry" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/cart-floor/CartEntry.vue'
      ),
    CccBannerBff: () =>
      import(
        /* webpackChunkName: "CccBannerBff" */ 'public/src/pages/goods_detail_v2/innerComponents/bottom/innerComponents/CccBannerBff.vue'
      ),
    
  },
  data() {
    const { lang } = gbCommonInfo
    const notIN = lang != 'in' // 印度地区不现实shippingEnter
    return {
      differencePrice: 0,
      showSelectionDrawer: false,
      notIN,
      showSizeGuideDrawerProxy: false,
    }
  },
  computed: {
    // BFF新链路
    ...mapGetters('productDetail/Middle', ['show_parallel_import', 'matching_style_block_show', 'rank_recommend_show']),
    ...mapState('productDetail/Recommend', ['show_outfit_alone']),
    ...mapGetters('productDetail/common', [
      'language_v2', 
      'goods_id', 
      'mall_code',
      'category_selection_floor_bo',
      'category_selection_floor_new',
      'fs_abt',
      'cat_id',
      'skc_sale_attr',
    ]),
    ...mapGetters('productDetail/DetailSignBoard', [
      'sign_data',
      'sign_config',
      'sign_board_style',
    ]),
    ...mapGetters('productDetail/MiddleAttr', [
      'model_relatives_config',
    ]),
    ...mapGetters('productDetail/Description', [
      'trends_info',
    ]),
    // 弹窗状态
    ...mapState('productDetail/CommentPopup', ['view_comment_ready', 'show_review_imgbox']),
    //⬆️

    ...mapState('newProductDetail/common', [ // 状态
      'goodsReady',
      'currentLocalCountry', // current country 变量，后续统一修改
      'realTimeReady'
    ]),
    ...mapState('newProductDetail', [ // sizeguide 是否展示状态，迁移 sizegudie 处理
      'sizeGuideReady',
      'checkSizeAndSizeGuideReady',
      'sizeRecommandReady',
      'braSizeRecommendReady'
    ]),
    ...mapState('newProductDetail/SizeGuide', [ // sizeguide 弹窗状态
      'showSizeGuideDrawer',
      'checkSizeAndSizeGuide',
      'braSizeRecommend',
      'sizeRecommand'
    ]),
    ...mapGetters('newProductDetail/SizeBox', ['sizeConfig']), // 只跟 abt 有关，可以直接修改
    ...mapGetters('newProductDetail/common', [
      'tsp', // sizeguide 相关联，是否有平替
      'detail', // 传入 sizeguideDrawer，后续改造 
      'currentCountry', // 跟随 sizeGuide 统一改造
      'isNewSizeLocal', // 跟随 sizeGuide 统一改造
      'allDataReady', // 商详状态
    ]),
    ...mapGetters('newProductDetail/Price', ['estimatedInfo']), // 能否平替
    ...mapGetters('newProductDetail/SizeGuide', ['localsize']), // 跟随 sizeGuide 统一改造
    ...mapGetters('newProductDetail/MiddleAttr', ['sizeInfoDes', 'pageComponents']), // 跟随 sizeGuide 统一改造, 以及一些 ccc 的配置
    ...mapGetters('newProductDetail/BagData', ['isReviewBelow', 'isRecommendAbove']),

    // BFF新链路 ===============
    ...mapState('productDetail/DetailsPictures', ['detail_pic_fold']), // 细节图模型
    ...mapGetters('productDetail/Middle', ['show_parallel_import']),
    ...mapGetters('productDetail/DetailsPictures', ['detail_pic_box']),  // 细节图模型
    ...mapGetters('productDetail/Reviews', ['show_reviews']),
    ...mapGetters('productDetail/DetailSignBoard', ['is_show_new_store_and_brand']),
    ...mapGetters('productDetail/ShippingEnter', [
      'shipping_content'
    ]),
    ...mapGetters('productDetail/Recommend', [
      'get_the_look_info'
    ]),
    ...mapGetters('productDetail/MiddleAttr', [
      'models_info',
    ]),
    parallelimportExposeInfo() {
      return {
        goods_id: this.goods_id,
        mall_code: this.mall_code,
      }
    },
    tspShowBuySizeTable(){
      return !!this.tsp?.[600029050]
    },
    shipAndDetailSortType() {
      return this.pageComponents?.modulesSortConf?.shipAndDetailSortType || null
    },
    detailSignBoardPosition() {
      return this.sign_board_style.position
    },
    showFloatCartIcon() {
      return this.fs_abt?.hovercart?.p?.hovercart === 'show'
    },
    existEstimatedPrice() {
      const { estimatedCompProps = {} } = this.estimatedInfo || {}
      const poskey = this.fs_abt?.EstimatedNothreShowType?.p?.EstimatedNothreShowType || ''
      return (
        ['New1', 'New2'].includes(poskey) &&
        estimatedCompProps?.price?.addOnAmountWithSymbol &&
        estimatedCompProps?.price?.needPrice
      )
    },
    hasShippingData() {
      // 运费和时效都没有就不展示
      return this.shipping_content?.shipPriceHtml || this.shipping_content?.shipTimeHtml
    },
    // gtlAloneByAbt() {
    //   return this.unifiedRecommend?.gtlAloneByAbt || false
    // },
    // 以下状态待优化 为弹窗状态 ⬇️
    sizeGuidePopReady() {
      return this.sizeGuideReady
    },
    sizeRecommandPopReady() {
      return this.sizeRecommandReady
    },
    braSizeRecommendPopReady() {
      return this.braSizeRecommendReady
    },
    checkSizeAndSizeGuidePopReady() {
      return this.checkSizeAndSizeGuideReady
    },
    // // 判断是否展示独立品牌-新样式（位于物流模块上方）
    // isShowNewStoreAndBrand() {
    //   return this.brandMapInfo && /** 只有命中tag或品牌集成店 */(this.isBrandHasTargetTag || this.isStoreBusinessBrand)
    // },
    isShowNewGetTheLook(){
      return this.show_outfit_alone
    },

    galleyLayout() {
      return this.fs_abt?.Reviewchange?.param?.Layout_Review
    }
  },
  watch: {
    goods_id: {
      handler() {
        this.handleHeelHeightRange()
        this.$nextTick(() => {
          // this.showPromotionEnterLayer && daEventCenter.triggerNotice({ daId: '1-6-4-40' })
          if(this.showFloatCartIcon) {
            this.$refs?.detailCartBag?.exposeDetailCarIcon?.()
          }

          this.update_detail_pic_fold(true) // 更新新 BFF 详情图片折叠状态
        })
      },
      immediate: true
    },
    showSizeGuideDrawerProxy(newVal) {
      this.updateShowSizeGuideDrawer(newVal)
    },
    showSizeGuideDrawer(newVal) {
      this.showSizeGuideDrawerProxy = newVal
    },
  },
  mounted() {
    this.prefetchResource()
    if (this.isShowDesignerInfo) {
      daEventCenter.triggerNotice({ daId: '1-6-4-69' })
    }
    window.vBus && window.vBus.$on('triggerAddCompletedFloatCart', this.triggerAddCompletedFloatCart)
    
  },
  destroyed() {
    window.vBus &&
      window.vBus.$off('triggerAddCompletedFloatCart', this.triggerAddCompletedFloatCart)
  },
  methods: {
    ...mapMutations('productDetail', ['assignState']),
    ...mapMutations('newProductDetail/common', [
      'updateCurrentLocalCountry', // 公共的改变 local country 方法，后续统一修改
      'updateCurrentLocalUnit' //  公共的改变 local unit 方法，后续统一修改
    ]),
    ...mapMutations('newProductDetail/SizeBox', [ // 跟随 sizeGuide 统一改造
      'updateHeelHeightStatus',
      'updateHeelHeight',
      'updateCmInchInfo'
    ]),
    ...mapMutations('newProductDetail/SizeGuide', ['updateShowSizeGuideDrawer']),


    // NEW BFF
    ...mapMutations('productDetail/DetailsPictures', ['update_detail_pic_fold']),
    parallelimportClick() {
      daEventCenter.triggerNotice({ daId: '1-6-1-175', bindData: this.parallelimportExposeInfo })
      const { langPath } = gbCommonInfo
      this.$router?.push?.(`${langPath}/product/article/2008`)
    },
    prefetchResource() {
      // 预加载资源 评论弹窗
      if (this.show_reviews) {
        prefetchResource.listen({
          el: this.$el,
          prefetchList: [
            {
              chunkName: 'CommentPop',
              relType: 'prefetch'
            }
          ],
          delay: 3000,
          // prefetchCallback: ({ status, info }) => {
          //   console.log('prefetchCallback', status, info)
          // }
        })
      }
    },
    updateLocalSize(val) {
      // this.isNewSizeLocal && 
      this.updateCurrentLocalCountry(val)
    },
    handleSelectionDrawer(status) {
      if (status) {
        daEventCenter.triggerNotice({ daId: '1-6-4-72' })
        daEventCenter.triggerNotice({ daId: '1-6-4-73' })
      }
      this.showSelectionDrawer = status
    },
    triggerAddCompletedFloatCart({ animation }) {
      if (!this.showFloatCartIcon) return
      if (animation) {
        this.$refs?.detailCartBag?.drop?.(document.querySelector('#banner-detail'))
      } else {
        this.$refs?.detailCartBag?.updateCartNum?.()
      }
    },
    async handleHeelHeightRange() {
      // 指定的shein和romwe类目，命中ABT，且含有跟高(Heel Height)属性时，才展示Heel Hight强化
      const sheinRomweCateArr = [
        '1751',
        '1750',
        '1748',
        '3181',
        '2517',
        '3182',
        '3193',
        '1749',
        '715',
        '700',
        '699',
        '1981',
        '1486',
        '1985',
        '2065',
        '749'
      ]
      const descriptionList = this.pageComponents?.descriptionConf?.descriptionList ?? {}

      let dimensionAttrInfo = this.sizeInfoDes?.dimensionAttrInfo ?? []
      const heelHeightMap = dimensionAttrInfo.find(item => {
        // {attr_name:"Heel Height"attr_name_en:"Heel Height"},通过attr_name的值（多语言）去尺码表拿数据
        return item?.attr_name_en?.includes('Heel Height')
      })
      let showSizeRange = true // 返回的尺码数据是否本身包含范围，如果有，则不拼接tips展示
      // 处理尺寸范围
      const getSizeInfoRange = (sizeArr = [], separator = '') => {
        const result = []
        sizeArr.forEach(item => {
          Object.keys(item).some(key => {
            if (key.includes(heelHeightMap?.attr_name)) {
              let heelHeight = item[key]?.trim()?.split(separator)?.[0]?.trim()
              if (isNaN(heelHeight)) {
                showSizeRange = false
              }
              heelHeight && result.push(heelHeight)
            }
          })
        })
        if (result.length === 0) showSizeRange = false
        let min = Math.min(...result)
        const max = Math.max(...result)
        return (min === max ? min : `${min}-${max}`) + separator
      }
      const sizeInfoRange = getSizeInfoRange(this.sizeInfoDes?.sizeInfo, 'cm')
      const sizeInfoInchRange = getSizeInfoRange(this.sizeInfoDes?.sizeInfoInch, 'inch')
      const heelHeight = descriptionList['45']?.value ?? ''
      // 外露场景heel height
      const textAttr = this.pageComponents?.descPosConf?.fPosData?.textAttr ?? []
      textAttr.forEach(item => {
        if (heelHeightMap?.attr_name?.includes(item.name)) {
          item.value += ` (${sizeInfoRange}/${sizeInfoInchRange})`
        }
      })
      // attr_id: 45代表跟高属性
      let hasHeelHeight = Object.keys(descriptionList).some(key => key.includes('45'))
      // 实验值为Heelhight = true时才展示
      if (
        sheinRomweCateArr.includes(this.cat_id) &&
        hasHeelHeight
      ) {
        showSizeRange && this.updateHeelHeightStatus(true)
      }
      this.updateHeelHeight(heelHeight)
      this.updateCmInchInfo(` (${sizeInfoRange}/${sizeInfoInchRange})`)
    },
  }
}
</script>

<style lang="less">
.product-middle {
  position: relative;
  height: 2rem;
  overflow: hidden;
  margin: 0.213333rem 0;

  &__reviews {
    margin-top: 0.213333rem;
    &.review-lookbook {
      padding-top: 0.22rem;
    }
    // 合并间距
    &.no_margin {
      margin: 0;
    }
  }

  &__show {
    height: auto;
    overflow: visible;
  }
  &__fold {
    height: 4rem;
  }
  &__wrap {
    .padding-l(0.32rem);
    .padding-r(0.32rem);
    background: #fff;
    margin-bottom: 0.213333rem;
  }
  &__more {
    position: absolute;
    left: 0 /*rtl:ignore*/;
    right: 0 /*rtl:ignore*/;
    bottom: 0;
    height: 1rem;
    line-height: 1rem;
    color: #666;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0 -18px 15px 0 #fff;
    box-shadow: 0 -18px 15px 0 #fff;
    font-weight: 700;
    z-index: @zindex-hack;
    [class*='iconfont'] {
      display: inline-block;
      margin-left: 0.11rem /*rtl:ignore*/;
      vertical-align: middle;
      color: #999;
      font-weight: 400;
      .font-dpr(32px);
    }
  }
  &__cart-bag {
    .cart-bag-wrapper {
      bottom: 2.8267rem;
    }
  }
  .no-margin-bottom {
    margin-bottom: 0;
  }
  .brand-border-bottom {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
