/**
 * abt页面配置文件
 * 当前页面的路由name来配置abt的poskey
 * */

import home from './home.js'
import category from './category.js'
import productsList from './productsList.js'
import goodsDetail from './goodsDetail.js'
import wishlist from './wishlist.js'
import flashSale from './flashSale.js'
import cart from './cart.js'
import cartShareSelect from './cartShareSelect.js'
import user from './user.js'
import robot from './robot.js'
import live from './live.js'
import preSearch from './preSearch.js'
import advertising from './advertising.js'
import store from './store.js'
import reviewCenter from './reviewCenter.js'
import rankProducts from './rankProducts.js'
import orderReturn from './orderReturn.js'
import brandZone from './brandZone.js'
import brandZonePromotion from './brandZonePromotion.js'
import giftCardDetail from './giftCardDetail.js'
import storeFollow from './storeFollow.js'
import support from './support'
import points from './points.js'
import customs from './customs.js'
import trendOutfits from './trendOutfits.js'
import thrifty_find from './thrifty_find.js'

export default [
  home,
  category,
  ...productsList,
  goodsDetail,
  ...wishlist,
  ...flashSale,
  cart,
  cartShareSelect,
  user,
  ...robot,
  live,
  preSearch,
  advertising,
  store,
  reviewCenter,
  rankProducts,
  orderReturn,
  brandZone,
  brandZonePromotion,
  giftCardDetail,
  storeFollow,
  support,
  points,
  trendOutfits,
  thrifty_find,
  customs
]
