<template>
  <div
    v-if="data"
    class="discount-detail__all"
  >
    <div class="discount-detail__header">
      <div class="discount-detail__header-content">
        <div class="discount-detail__header-title">
          {{ data.title }}
        </div>
        <div
          v-if="data.titleDesc"
          class="discount-detail__header-sub"
        >
          {{ data.titleDesc }}
        </div>
        <div 
          v-if="show && lureShowStyle == 2"
          class="discount-detail__header-induce"
        >
          <InduceItems :info="discountTopInfo" />
        </div>
      </div>
    </div>

    <div
      v-if="show"
      class="discount-detail__content"
    >
      <DiscountTop
        :info="discountTopInfo" 
        :locals="locals"
        :showStyle="lureShowStyle"
      />
      <template v-for="(item, index) in data.items">
        <component
          :is="CompMap[item.type]"
          :key="index"
          :ref="`${item.benefitType}Ref`"
          left-tip-bold
          :item="item"
          :data="item.data"
          @open-tip="onOpenTip"
          @fold="isOpen => onFold(isOpen, item.benefitType)"
          @click-product-img="onClickProductImg"
        >
          <template #item-top="slotProps">
            <div
              v-if="slotProps.data.products && slotProps.data.products.length"
              class="discount-detail__product-list"
            >
              <ProductImg
                v-for="product in slotProps.data.products"
                :key="product.id"
                :src="product.goods_img"
                size="mini"
              />
            </div>
          </template>
        </component>
      </template>
    </div>

    <LazyMount>
      <s-dialog
        :visible.sync="dialogShow"
        append-to-body
      >
        <template slot="title">
          <div v-html="dialogContent"></div>
        </template>
        <template
          slot="footer"
        >
          <s-button
            width="100%"
            :type="['primary', 'H72PX']"
            @click="dialogShow =! dialogShow"
          >
            {{ language.SHEIN_KEY_PWA_15146 }}
          </s-button>
        </template>
      </s-dialog>
    </LazyMount>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { LazyMount } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useStore } from '../../../hooks/useStore'
import ProductImg from '../components/ProductImg.vue'
import ChecklistCart from '../components/ChecklistCart.vue'
import ChecklistCell from '../components/ChecklistCell.vue'
import ChecklistDropdown from '../components/ChecklistDropdown.vue'
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus'
import { triggerHighlightAnimation } from '../../../utils/index.js'
import DiscountTop from '../components/DicountTop.vue'
import InduceItems from '../components/InduceItems'
import { sendSaExposeScenesabtByDetailTips } from 'public/src/pages/cart_v2/components/discountDetailDrawer/utils/analysis.js'

const CompMap = {
  1: ChecklistCart,
  2: ChecklistCell,
  3: ChecklistDropdown,
}

const { state } = useStore()

const language = computed(() => state.language || {})
const locals = computed(() => state.locals)

const allDiscountDetailDrawerParams = computed(() => state.allDiscountDetailDrawerParams)
const showDiscountDetails = computed(() => allDiscountDetailDrawerParams.value.show)
const data = computed(() => state.cartInfo?.promotionDetailPopUp)
const hasFlashSale = computed(() => {
  const aClassPromotions = data.value?.items?.find(item => item.benefitType === 'aClassPromotions')
  const bClassPromotions = data.value?.items?.find(item => item.benefitType === 'bClassPromotions')
  return aClassPromotions?.data?.twoLevelData?.some(item => item.typeId == '10' && !!item.questionMark) || bClassPromotions?.data?.twoLevelData?.some(item => item.typeId == '10' && !!item.questionMark)
})

const discountTopInfo = computed(() => {
  return data.value?.items?.find(item => item.type == 1)
})
const lureShowStyle = computed(() => {
  return discountTopInfo.value?.lureInfo?.style
})

const show = ref(false)
watch(() => showDiscountDetails.value, (val) => {
  setTimeout(async () => {
    show.value = val
    if (val) {
      sendSaExposeScenesabtByDetailTips({
        type: discountTopInfo.value?.lureInfo?.biType
      })
    }
    await nextTick()
    handleAnchorType()
  }, 0)
}, { immediate: true })

const dialogShow = ref(false)
const dialogContent = ref('')
const onOpenTip = (text) => {
  dialogContent.value = text
  dialogShow.value = true
}

const onFold = (isOpen, benefitType) => {
  let type = ''
  if (['aClassPromotions', 'bClassPromotions'].includes(benefitType)) {
    type = 'promotion'
  }
  if (benefitType === 'coupon') {
    type = 'coupon'
  }

  daEventCenter.triggerNotice({
    daId: '1-8-7-64',
    extraData: {
      is_open: +isOpen,
      type,
    },
  })
}

const { modifyCartCheckStatus } = useModifyCartCheckStatus()
const onClickProductImg = data => {
  const { id, checked } = data
  modifyCartCheckStatus(
    { cartIdList: [id], operationType: checked ? 1 : 2, type: 'item' },
    {
      click_select_someproduct: (defaultData) => {
        return {
          ...defaultData,
          position: 'popup',
        }
      }
    })
}

const couponRef = ref(null)
const aClassPromotionsRef = ref(null)
const bClassPromotionsRef = ref(null)

const handleCouponAnchor = () => {
  const targetRef = couponRef.value?.[0]
  if (!targetRef) return
  targetRef.onClick?.()
  setTimeout(() => {
    triggerHighlightAnimation(targetRef.$el.querySelector('.j-checklist-dropdown__content'))
  }, 300)
}
const handleGiftAnchor = (promotionRef) => {
  const targetRef = promotionRef.value?.[0]
  if (!targetRef) return false
  const giftDoms = targetRef.$el.querySelector('.j-checklist-dropdown__item-2, .j-checklist-dropdown__item-4, .j-checklist-dropdown__item-28')
  if (giftDoms) {
    targetRef.onClick?.()
    setTimeout(() => {
      triggerHighlightAnimation(giftDoms)
    }, 300)
  }
  return !!giftDoms
}
const handleAnchorType = () => {
  const type = allDiscountDetailDrawerParams.value.anchorType
  switch (type) {
    case 'coupon':
      handleCouponAnchor()
      break
    case 'gift':
      // eslint-disable-next-line no-case-declarations
      let res = handleGiftAnchor(aClassPromotionsRef)
      if (!res) handleGiftAnchor(bClassPromotionsRef)
      break
    default:
      break
  }
}

onMounted(() => {
  if(hasFlashSale.value){
    daEventCenter.triggerNotice({
      daId: '1-8-7-59',
    })
  }
})
</script>

<style lang="less" scoped>
@padding: 12/37.5rem;
@margin: 16/37.5rem;

.discount-detail {
  &__all {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    border-top-left-radius: 8/37.5rem;
    border-top-right-radius: 8/37.5rem;
    overflow: hidden;

    ::v-deep .checklist-cart__bar {
      margin-bottom: @margin;
    }
    ::v-deep .checklist-cell {
      margin-top: @margin;
      margin-bottom: @margin;
    }
    ::v-deep .checklist-cell__sub {
      font-size: 12px;
    }
    ::v-deep .checklist-dropdown__bar {
      margin-top: @margin;
      margin-bottom: @margin;
    }
    ::v-deep .checklist-cell .checklist-cell__right-price {
      font-size: 16px;
    }
    ::v-deep .checklist-dropdown__content {
      margin-top: @margin;
      margin-bottom: @margin;
      margin-left: -@padding;
      margin-right: -@padding;
      background: #f6f6f6;
    }
    ::v-deep .checklist-dropdown__item {
      padding-left: @padding;
      padding-right: @padding;
      background: transparent;
    }
  }

  &__close {
    position: absolute;
    top: 8/37.5rem;
    right: 8/37.5rem;
  }

  &__header {
    flex: none;
  }

  &__header-content {
    padding: 12/37.5rem 0;
  }

  &__header-title {
    font-size: 16px;
    font-weight: 510;
    line-height: 20px;
    color: #000;
    text-align: center;
  }

  &__header-sub {
    margin-top: 4/37.5rem;
    padding: 0 8/37.5rem;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #ababab;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__header-induce {
    margin-top: 5/37.5rem;
    padding: 0 12/37.5rem;
    overflow: hidden;
  }

  &__content {
    padding: 0 @padding;
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
  }

  &__product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 7/37.5rem;
    margin-bottom: 8/37.5rem;
  }

  &__product-img {
    display: flex;
    align-items: center;
    width: 32/37.5rem;
    height: 32/37.5rem;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}
</style>
