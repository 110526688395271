<script lang="jsx">
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import HomeBffPrice from './HomeBffPrice.vue'

export default {
  name: 'ProductItemLite',
  functional: true,
  components: { CropImageContainer, HomeBffPrice },
  inheritAttrs: false,
  props: {
    lcp: {
      type: Boolean,
      default: false,
    },
    showViewMore: {
      type: Boolean,
      default: false,
    },
    viewMoreText: {
      type: String,
      default: '',
    },
    context: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {},
    },
    showGoodsBottom: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
    column: {
      type: [String, Number],
      default: '',
    },
    constantData: {
      type: Object,
      default: () => {},
    },
    goodsItemInfo: {
      type: Object,
      default: () => {},
    },
    language: {
      type: Object,
      default: () => {},
    },
    brand: {
      type: String,
      default: 'shein',
    },
    cssRight: {
      // GB_cssRight存在不准确的情况，所以传入cssRight兼容
      type: Boolean,
      default: false,
    },
    // 来源组件
    compSrc: {
      type: String,
      default: '',
    },
    cropRate: {
      type: String,
      default: '3-4',
    },
    suggestedSaleType: {
      type: String,
      default: '',
    },
    // bff返回驼峰字体大小
    priceFontSize: {
      type: Object,
      default: () => {},
    },
    // 用于控制首页组件的裁切
    compImgDesignWidth: {
      required: true,
      type: Number,
      default: 192,
    },
    hiddenPrice: {
      type: Boolean || Number,
      default: false,
    },
  },
  render(h, { props = {}, data = {} }) {
    const {
      item,
      lcp,
      cropRate,
      compSrc,
      constantData,
      compImgDesignWidth,
      config,
      suggestedSaleType,
      hiddenPrice,
      priceFontSize,
    } = props

    const className = []
    if (lcp) {
      className.push('product-card--bg-gray')
    }

    return (
      <div
        class='product-item-simple__lite product-bff'
        {...data}
      >
        <CropImageContainer
          class={className}
          ignoreMask
          imgSrc={item.goods_img}
          fixedRatio={cropRate}
          transformImgProps={{
            imgClassName: `fsp-element product-card__main-img ${compSrc ? 'fsp__' + compSrc : ''}`,
            loadImg: constantData?.LAZY_IMG_SOLID_COLOR,
            isFirstPage: lcp,
            imgDesignWidth: compImgDesignWidth,
            alt: item.goods_name,
            fetchpriority: 'high',
          }}
        />

        {hiddenPrice ? null : (
          <HomeBffPrice
            goodsInfo={item}
            config={{ ...config, camelPriceResizeFontsize: false }}
            suggestedSaleType={suggestedSaleType}
            priceFontSize={priceFontSize}
          />
        )}
      </div>
    )
  },
}
</script>

<style lang="less">
.product-item-simple__lite {
  width: 100%;
  padding-bottom: 2px;
  background-color: transparent; // 背景透明，避免白色造成四角未到圆角
  position: relative;

  .crop-image-container .product-card__main-img {
    border-radius: 0.055rem;
  }
  .prices-info__sale-price {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

.product-card--bg-gray {
  background-color: @sui_color_gray_weak1a;
}
</style>
