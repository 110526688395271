import { Abt } from '@shein-aidc/basis-abt'
import { nodeAbtReqeust } from './abt_fetch.js'

export default function createInstance() {
  return new Abt({ schttp: async (data) => {
    if (typeof window === 'undefined') {
      // 这里服务端场景，不清楚， ssr时 有问题 触发。
      return {}
    }
    try {
      return await nodeAbtReqeust(data)
    } catch(e) {
      console.error(e)
      return {}
    }
  } })
}

