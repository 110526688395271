/** 只做动静融合 */
export default {
  /** 价格相关 */
  getPrice(state, getters, rootState) {
    if (getters.cacheScreenData) {
      const { retailPrice, salePrice, mallCode: mall_code, promotionInfo, unit_discount, sheinClubPromotionInfo = {} } = getters.cacheScreenData
      const { discount_amount = {}, discountValue = 0, price = {} } = sheinClubPromotionInfo
      return {
        mall_code,
        promotionInfo,
        unit_discount,
        mall_price: [{
          retailPrice,
          mall_code,
          salePrice,
          unit_discount,
          discountValue,
          showPrice: price,
          vipDiscountPrice: discount_amount,
          calDiscount: true
        }]
      }
    }
    // 为了防止hotModule更新晚于coldModules（csr切换商品的时候）校验本次的goodsId 与 实际的是否一致
    const realTimeReady = rootState.newProductDetail.common?.realTimeReady
    if (!realTimeReady) return state.coldModules.productInfo?.getPrice
    return state.hotModules.productInfo?.getPrice || state.coldModules.productInfo?.getPrice
  },
  /** 分期付款相关 */
  afterpayInfo(state) {
    return state.hotModules.pay?.afterpayInfo
  },
  /** 是否shein付费会员 */
  isPaidUser(state, getters, rootState, rootGetters) {
    return rootGetters['productDetail/common/is_paid_user']
    // return state.hotModules.user?.isPaidUser
  },
  /** 是否shein免费试用会员 */
  isFreeReceiveUser(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootGetters['productDetail/common/is_can_trial']

    return state.hotModules.user?.isFreeReceiveUser
  },
  /** 付费会员详情 */
  paidDetail(state) {
    return state.hotModules.user?.paidDetail
  },
  /** 支付方式相关 */
  paymentInfoContent(state) {
    return state.hotModules.pay?.paymentInfo || state.coldModules.pay?.paymentInfo
  },
  /** 促销相关 */
  exclusivePromotionPrice(state) {
    return (
      state.hotModules.promotion?.exclusivePromotionPrice ||
      state.coldModules.promotion?.exclusivePromotionPrice
    )
  },
  /** 闪购相关 */
  skcNewFlashPromotion(state) {
    return (
      state.hotModules.promotion?.newFlashPromotion ||
      state.coldModules.promotion?.newFlashPromotion
    )
  },
  countryInfo(state) {
    return state.hotModules.shipping?.countryInfo || state.coldModules.shipping?.countryInfo
  },
  goodsFreeShippingInfo(state) {
    return state.hotModules.shipping?.goodsFreeShippingInfo || state.coldModules.shipping?.goodsFreeShippingInfo
  },
  primeShipping(state) {
    return state.hotModules.shipping?.primeShipping || {}
  },
  /** 优惠券相关 */
  storeCouponList(state) {
    return state.hotModules.coupon?.storeCouponList
  },
  showCouponsUnusedCommend(state) {
    return state.hotModules.coupon?.showCouponsUnusedCommend
  },
  /** getTheLook */
  getTheLook(state) {
    return state.hotModules.getTheLook?.getTheLook
  },
  isSsr (state,  getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootState.productDetail.hybridModules?.is_ssr

    return state.coldModules.pageInfo?.isSsr || false
  },
  closeEstimatedAndAbPrice (state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootState.productDetail.common.close_estimated_and_ab_price

    const { closeEstimatedAndAbPrice = false } = state.coldModules.pageInfo
    return closeEstimatedAndAbPrice
  },
  /** abt信息 */
  fsAbt(state, getters, rootState, rootGetters) {
    const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return rootGetters['productDetail/common/fs_abt']

    const { isSsr, fsAbt = {} } = state.coldModules.pageInfo // 防止重复渲染
    const { isLogin } = state
    let result = {}
    if (isSsr && !isLogin) {
      result = fsAbt
    } else {
      result = Object.keys(state.hotModules.pageInfo?.fsAbt ?? {}).length ? state.hotModules.pageInfo?.fsAbt : fsAbt
    }

    // 部分 AB 默认值按推全处理
    if (result.DetailBuynow?.p) {
      result.DetailBuynow.p.quickprice = result.DetailBuynow.p.quickprice || 'Ceiling'
    }

    return result
  },
  /** CCC 视频位置 */
  cccVideoPosition(state) {
    return state.hotModules.ccc?.cccVideoPosition || 0
  },
  cccBrandZoneLink(state) {
    return state.hotModules.ccc?.cccBrandZoneLink || state.coldModules.ccc?.cccBrandZoneLink || {}
  },
  // cccCatSelectionTag (state) {
  //   return state.hotModules.ccc?.cccCatSelectionTag || state.coldModules.ccc?.cccCatSelectionTag || {}
  // },
  commentOverView(state) {
    return state.hotModules.comment.commentOverView || {}
  },
  commentOverViewLocal(state) {
    return state.hotModules.comment.commentOverViewLocal || {}
  },
  /** 首屏评论 */
  comments(state) {
    return state.hotModules.comment.comments || {}
  },
  /** 推荐统一位  */
  unifiedRecommend(state) {
    return state.hotModules.recommend?.unifiedRecommend || {}
  },
  /** 收藏状态 */
  productSaveStatus(state) {
    return !!state.hotModules.user.saveStatus
  },
  /** 性能怪兽模式 👾 */
  isPerformance(state) {
    const { performanceOpen = false } = state.common
    return performanceOpen
  },
  /** 当前是否使用缓存 */
  isUseCache(state, getters) {
    return !!getters.cacheScreenData
  },
  /** 列表过来的缓存数据 */
  cacheScreenData(state, getters, rootState) {
    const { cacheScreenData, goodsReady } = rootState.newProductDetail.common
    if (!getters.isPerformance || goodsReady || cacheScreenData?.noPrefetch) return null
    return cacheScreenData
  }
}
