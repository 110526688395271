// 首页配置
export const INFORMATIONFLOW_POSKEY = [
  'listflashSale',
  'HomeRecommendNew',
  'HomeRecommendOtherNew',
  'discountLabel',
  'greysellingPoint',
  'carddiscountLabel',
  'homepageKeyAttribute', // 评论关键字
  'homePageStarReview', // 星际评论
  'hrankinglabelclick',
  'BigCard',
  'FlashSaleCountDown',
  'listattributeLabels',
  'EstimatedPrice',
  'listtagSorting',
  'listrankingTag',
  'listquickship',
  'listquickshipKey',
  'listnewuserOnly', // 新客腰带
  'rcmdtrendTag', // 趋势标签
  'flowInterestRecoEntry', // 信息流【入口场景】点后推
  'RealtimeRecommendEntry', // 信息流【入口场景】点后推 推荐策略入参
  'listwithCoupon', // 到手价新样式
  'FlowClickReco',
  'InfoFlowShowVideo', // 信息流播放视频abt（用于屏蔽点后推搜索词）
  'InfoflowInteresteGoods',
  'listRRP',
  'detailrrpprice',
  'AIFeatureSrv', // 信息流端智能参数夹带
  'InfoFlowDivideTime', // 信息流入口点后刷新机制
  'InfoFlowPageDivideTime', // 信息流落地页后刷新机制
]
export default {
  routeName: 'config_index',
  preload: true,
  newPosKeys: [
    'HomepageCouponCountdown',
    'FlashSaleUITest',
    'SearchBorderNew',
    'HomePageTerminal',
    'FlashSaleSubtype',
    'FlashShowForm', 
    'FlashSaleCountDown',
    'HomeEstimatedPrice',
    'FSEstimatedPrice',
    'BGEstimatedPrice',
    'CouponBlock', 
    'HomePageFloatingIconExp',
    'CCCHomePageDynamic',
    'FlashSaleCategory',
    'HomeIconIfRec',
    'HomeIconRecImageType',
    'HomeItemRec',
    'HomeBottomPopup',
    'SuperDealsPerformanceOptimization',  
    ...INFORMATIONFLOW_POSKEY
  ],
  posKeys: [
    'CccAppIcon',
    'AllListQuickShip',
    'newPrivacy',
    'Movepoints',
    'CccWomenHomepage',
    'shipping',
    'Flashsale',
  ],
}

