<template>
  <div class="payment-items-container">
    <template v-if="isHadTokenPay">
      <div
        class="list-type__title">
        {{ nowCardPayment.title }}
      </div>
      <TokenItem
        v-for="item in nowCardPaymentTokenList"
        :key="item.id"
        :payment-item="nowCardPayment"
        :token-item="item"
        :is-selected="isSelectedToken(item)"
        :language="language"
        @editable-info-change="handleUpdateEditableInfo"
        @select="handleSelectToken"
      />
      <div
        class="list-type__title">
        {{ language.BS_KEY_PAY_1219 }}
      </div>
    </template>
    <PaymentItem
      v-for="(item, index) in paymentList"
      ref="paymentItemRef"
      :key="item.id"
      :payment-item="item"
      :sign-up-info="signUpInfo[item.code]"
      :is-selected="isSelectedPay(item)"
      :is-folded="index > foldedPositionIndex"
      :payment-index="index"
      :language="language"
      :paypal-message-show="paypalMessageShow"
      :total-price-amount="totalPriceAmount"
      @editable-info-change="handleUpdateEditableInfo"
      @select="handleSelect"
    />
    <ViewMore
      v-if="!isClickViewMore && foldPositionPayments.length"
      :language="language"
      :icon-list="viewMoreIconList"
      :folded-position="paymentItemsConfig?.foldedPosition"
      @click="handleClickViewMore"
    />

    <ClientOnly>
      <LazyMount>
        <CardBinDiscountArticle
          :visible.sync="cardBinArticleInfo.visible"
          :article-id="cardBinArticleInfo.articleId"
          @change-visible="changeArticleVisible"
        />
      </LazyMount>
    </ClientOnly>
  </div>
</template>

<script name="MainPayments" setup lang="ts">
import { provide, computed, defineAsyncComponent, onMounted, nextTick } from 'vue'
import { usePaymentItems } from '../../../hooks/usePaymentItems'
import PaymentItem from './cell/PaymentItem.vue'
import TokenItem from './cell/TokenItem.vue'
import ViewMore from './cell/ViewMore.vue'
import type { PaymentItemSelectType, PaymentItemsConfig } from '../../../types'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { usePaymentsStore } from '../../../hooks/usePayments'
import { usePaypalBnplMessage } from '../../../hooks/usePaypalBnplMessage'
import { useAnalysis } from '../../../common/analysisSource'
import ClientOnly from 'vue-client-only'
import { LazyMount } from '@shein/sui-mobile'
import type { PaymentItemProps } from '../../../types/props'
import { debuggerLog } from '../../../utils'

const analysisInstance = useAnalysis()
provide('analysisInstance', analysisInstance)

const CardBinDiscountArticle = defineAsyncComponent(() => import('./cell/CardBinDiscountArticle.vue'))

interface PaymentItemsProps {
  language: Record<string, string>;
  payments: Trade_PayToolKit.PaymentInfoItem[];
  selectedPayment: Trade_PayToolKit.SelectedPaymentInfo;
  signUpInfo: Record<string, {
    use_one_time_sign: boolean;
    signupFlag: string;
  }>;
  paymentItemsConfig?: PaymentItemsConfig;
  paymentInfo?: {
    fpxShowUpgradationBank?: string[];
    paySafeIconTip?: string;
    paySafeIcons?: string[];
    payments: PaymentItemProps[];
    is_has_oceapay?: number | null;
    paymentSuggestion?: string;
    isUsedThisPayment?: number | null;
    folded_position?: string;
    finalSelectPaymentCode?: string;
    pay_with_title?: string | null;
    force_remember_card?: string | null;
    force_remember_card_tip?: string | null;
    checkout_force_remember_card_tip?: string | null;
  },
  paymentConfig?: Trade_PayToolKit.PaymentConfig;
  selectedTokenInfo?: Record<string, any>;
  checkoutInfo?: Trade_PayToolKit.CheckoutInfo;
  bsPaymentAction: (info: { action: Trade_PayToolKit.BsPaymentAction; payload: Record<string, any> }) => void;
}

const props = withDefaults(defineProps<PaymentItemsProps>(), {
  payments: () => ([]),
  language: () => ({}),
  selectedPayment: () => ({} as Trade_PayToolKit.SelectedPaymentInfo),
  signUpInfo: () => ({}),
  paymentItemsConfig: () => ({}),
  selectedTokenInfo: () => ({}),
  bsPaymentAction: () => ({}),
})

const emit = defineEmits<{
  (event: 'select', options: { type: PaymentItemSelectType; paymentItem: Trade_PayToolKit.PaymentInfoItem; tokenItem?: Record<string, any> }): void
  (event: 'editable-info-change', info: { [key: Trade_PayToolKit.PAYMENT_CODE_KEY]: Trade_PayToolKit.EditablePaymentItem }): void
}>()

const paymentsStore = usePaymentsStore()

const {
  isClickViewMore,
  foldPositionPayments,
  paymentList,
  viewMoreIconList,
  foldedPositionIndex,
  handleClickViewMore,
  nowCardPayment,
  isHadTokenPay,
  nowCardPaymentTokenList,
  isListHadTokenId,
} = usePaymentItems(props)

const isShowSwitchPayTip = computed(() => {
  debuggerLog('MainPayments====isShowSwitchPayTip', isListHadTokenId.value, paymentsStore?.selectedPayment, nowCardPayment.value)
  return !isListHadTokenId.value
    && props?.checkoutInfo?.token_id
    && props?.checkoutInfo?.token_id != 0
    && paymentsStore?.selectedPayment
    && nowCardPayment.value
    && paymentsStore?.selectedPayment?.code === nowCardPayment.value?.code
})

const paypalBnplCurrencySupportList = computed(() => props.payments?.find(item => item.code === 'PayPal-bnpl')?.currencySupportList || [])

const cardBinArticleInfo = computed(() => paymentsStore?.cardBinArticleInfo)

const { paypalMessageShow, totalPriceAmount } = usePaypalBnplMessage({
  paymentsStore,
  paypalBnplCurrencySupportList,
})

const handleSelectEvt = ({ type, paymentItem, tokenItem }: {
  type: PaymentItemSelectType;
  paymentItem: Trade_PayToolKit.PaymentInfoItem,
  tokenItem?: Record<string, any>,
}) => {
  emit('select', { type, paymentItem, tokenItem })
}


const handleSelect = ({ paymentItem }: { paymentItem: Trade_PayToolKit.PaymentInfoItem; }) => {
  handleSelectEvt({ type: 'select-payment', paymentItem })
  paymentsStore?.updateState('selectedTokenInfo', {})
}

const handleSelectToken = ({ tokenItem }: { tokenItem: Record<string, any>; }) => {
  debuggerLog('handleSelectToken===', tokenItem)
  handleSelectEvt({ type: 'select-token', tokenItem, paymentItem: nowCardPayment.value })
}

const handleUpdateEditableInfo = ({ key, info }: { key: Trade_PayToolKit.PAYMENT_CODE_KEY, info: Trade_PayToolKit.EditablePaymentItem }) => {
  emit('editable-info-change', { [key]: info })
}

const changeArticleVisible = () => {
  paymentsStore?.updateState('cardBinArticleInfo', {
    visible: false,
    articleId: '',
  })
}

const isSelectedPay = (item) => {
  return props.selectedPayment?.code === item.code
}

const isSelectedToken = (item) => {
  return props.selectedTokenInfo?.[nowCardPayment.value?.code]?.id === item?.id
}

onMounted(() => {
  nextTick(() => {
    if (isShowSwitchPayTip.value) {
      props.bsPaymentAction({
          action: 'setIsShowSwitchPayTip',
          payload: {},
      })
    }

  })
})
</script>

<style scoped lang="less">
.payment-items-container {
  .list-type__title {
    color: #666666;
    font-size: 12px;
    font-family: SF Pro;
    font-weight: 400;
    line-height: 17px;
    word-wrap: break-word;
    padding: 8*2/75rem 0 4*2/75rem 0;
  }
}
</style>
