import type { AS_PayToolkit } from '../../../types'

const standard: AS_PayToolkit.AnalysisFuncs = {
  'expose_scenesabt.comp_pay-toolkit': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_bnpl_component.comp_pay-toolkit': ({ extraData }) => {
    return {
      payment_method: extraData.payment_method || '-', // 上报该组件支付方式编码
      installments_number: extraData.installments_number || '-', // 分期数，根据后端返回字段【分期数】上报数值
    }
  },
  'expose_cod_freeverifypopup.comp_cod_verify_free_popup': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_cod_freeverifypopup.comp_cod_verify_free_popup': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_cod_freeverifypopup_close.comp_cod_verify_free_popup': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_cod_detail.comp_cod_tip': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_cod_detail.comp_cod_tip': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_payment_method.comp_pay-toolkit': ({ extraData }) => {
    return {
      payment_method: extraData.payment_method || '',
      // 是否已签约
      is_vaulting: extraData.is_vaulting || '-',
      // is_folded：是否折叠 若加载结算页后默认展示的支付方式被曝光，则上报0
      // 若是点击「View More」之后才被曝光的支付方式，则上报1。
      is_folded: extraData.is_folded || '-',
      // 0：无支付方式优惠; 1：有支付方式优惠
      is_pay_promotion: extraData.is_pay_promotion || '0', // 是否参与支付促销 0 不参与 1 参与
      // 0:没有默认选中;1:默认选中
      is_default: extraData.is_default || '-',
      // 0：无默认选中埋;1: 接口返回;2：cache 缓存
      default_type: extraData.default_type || '-',
      sequence: extraData.sequence || '-', // 该支付方式在支付方式列表中的顺序
    }
  },
  'expose_payment_method.comp_pay-toolkit': ({ extraData }) => {
    return {
      payment_method: extraData.payment_method || '',
      // 是否已签约
      is_vaulting: extraData.is_vaulting || '-',
      // is_folded：是否折叠 若加载结算页后默认展示的支付方式被曝光，则上报0
      // 若是点击「View More」之后才被曝光的支付方式，则上报1。
      is_folded: extraData.is_folded || '-',
      // 0：无支付方式优惠; 1：有支付方式优惠
      is_pay_promotion: extraData.is_pay_promotion || '0', // 是否参与支付促销 0 不参与 1 参与
      // 0:没有默认选中;1:默认选中
      is_default: extraData.is_default || '-',
      // 0：无默认选中埋;1: 接口返回;2：cache 缓存
      default_type: extraData.default_type || '-',
      sequence: extraData.sequence || '-', // 该支付方式在支付方式列表中的顺序
    }
  },
  'expose_info_popup.comp_capitec_dialog': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_popup_telephonenumber.comp_capitec_drawer': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_confirm.comp_capitec_dialog': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_close.comp_capitec_dialog': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_input.comp_capitec_dialog': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_google_pay_popup_disappear.comp_googlepay_dialog': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_front_card_payment.comp_pre_pay': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_remember_thiscard.comp_pre_pay': ({ extraData }) => {
    const defaultParam = {
      is_remember: 0, // 记住卡号状态
    }
    return {
      ...defaultParam,
      ...extraData,
    }
  },
  'click_rememberthiscard_close.comp_pre_pay': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_remembercard_status.comp_pre_pay': ({ extraData }) => {
    const defaultParam = {
      edit_status: 'still_cancel',
    }
    return {
      ...defaultParam,
      ...extraData,
    }
  },
  'expose_popup_rememberthiscard.comp_pre_pay': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_rememberthiscard_ok.comp_pre_pay': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_token_onetime.comp_signup_one_time_drawer': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_token_onetime.comp_signup_one_time_drawer': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_safety_flow.comp_pay_loading': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_token_contine.comp_installment_list_drawer': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_token_installment.comp_installment_list_drawer': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_token_installment.comp_installment_list_drawer': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_cardno_modify.comp_coun-bin': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_unfolded_payment_method.comp_pay-toolkit': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_pay_mtd_confirm.comp_pay-toolkit': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_popup_ppacount.comp_ppga_type_drawer': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_is_vaulting.comp_pay-toolkit': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_unfolded_payment_method.comp_pay-toolkit': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_folded_payment_method.comp_pay-toolkit': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_installment.comp_pay-toolkit': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
