import { useHideCompMonitor } from '../../composables/use-hide-comp-monitor'
import { useHomeSlsLog, SLS_LOG_LEVEL } from '../../composables/use-home-sls-log'

/**
 * SBC 被隐藏时的上报
 * 
 * @param {{
 *   vuePropsPropData: import('vue').Ref<any>,
 *   vuePropsSceneData: import('vue').Ref<any>,
 *   vuePropsIndex: import('vue').Ref<number>,
 * }} param
 */
export function reportSbcHidden({
  vuePropsPropData,
  vuePropsSceneData,
  vuePropsIndex,
}) {
  // 触发告警
  const reportReason = 'items 数量少于 5 个导致 SBC 被隐藏，请关注！'
  useHideCompMonitor({
    tags: {
      reason: reportReason,
    }
  }, {
    propData: vuePropsPropData.value,
  })
  // 上报 SLS 日志
  useHomeSlsLog({
    logLevel: SLS_LOG_LEVEL.ERROR,
    tag: 'web_client_home_error_total',
    message: 'CCCShownFilterError',
    reason: reportReason,
  }, {
    propData: vuePropsPropData.value,
    sceneData: vuePropsSceneData.value,
    index: vuePropsIndex.value,
  })
}
