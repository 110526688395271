import { getRankingLink, isHasRankingLink } from 'public/src/pages/goods_detail_v2/utils/productDetail'
import { getQueryString } from '@shein/common-function'

export default {
  namespaced: true,
  getters: {
    show_ranking_info_link(state, getters, rootState, rootGetters) {
      const rankingListInfo = rootGetters['productDetail/common/ranking_list_info'] || {}
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      if (cacheScreenData) return true
      // 仅当链接有效才展示榜单排名，针对 cmp 异常数据的处理
      let { score, rankingTypeText, composeIdText } = rankingListInfo ?? {}
      return score && rankingTypeText && composeIdText
    },
    rank_new_style(state, getters, rootState, rootGetters){
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      return fs_abt?.Rankingoptiz?.p?.Rankingoptiz
    },
    ranking_list_icon() {
      const iconKey = 'ranking_default_medal'
      const { IMG_LINK } = gbCommonInfo || {}
      return IMG_LINK?.[iconKey] ?? ''
    },
    has_ranking_link(state, getters, rootState, rootGetters) {
      const rankingListInfo = rootGetters['productDetail/common/ranking_list_info'] || {}
      const { title, carrierSubType } = rankingListInfo
      // 用于判断榜单是否有链接，实际跳转链接在点击时计算
      return isHasRankingLink({ title, carrierSubType }) ? '/' : ''
    },
    get_ranking_link(state, getters, rootState, rootGetters) {
      const goods_id = rootGetters['productDetail/common/goods_id']
      const MAIN_BFF_APOLLO = rootState.productDetail.MAIN_BFF_APOLLO || {}
      const rankingListInfo = rootGetters['productDetail/common/ranking_list_info'] || {}

      if (typeof window === 'undefined') return ''
      let ici = getQueryString({ key: 'ici' }) || ''
      let { contentCarrierId, itemInfoId, carrierTempId, title, carrierSubType, cateIds, composeGoodsId, score, src_identifier } = rankingListInfo
      return getRankingLink({
        __srcIdentifierListExtend: (MAIN_BFF_APOLLO?.v4 && src_identifier?.length) ? src_identifier : null,
        contentCarrierId,
        itemInfoId: itemInfoId, // 新数据无
        carrierTempId, // 新数据无
        goods_id,
        title,
        carrierSubType,
        cateIds,
        composeGoodsId, // 新数据无
        tab_page_id: window.getSaPageInfo?.tab_page_id || '',
        ici,
        score
      })
    },
    ranking_analysis_data(state, getters, rootState, rootGetters) {
      const ranking_list_info = rootGetters['productDetail/common/ranking_list_info'] || {}
      const rankingListInfo = rootGetters['newProductDetail/TopOther/rankingListInfo']
      const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
      const { 
        src_identifier,
        board_generate_type,
        productSelectUrlId,
        mobileIdentifier,
      } = ranking_list_info || {}

      let content_list = ''
      if (is_use_bff_fs) {
        const { contentCarrierId, carrierSubType } = ranking_list_info || {}
        content_list = `${carrierSubType}\`${contentCarrierId}`
      } else {
        const { contentCarrierId, carrierSubType } = rankingListInfo || {}
        content_list = `${carrierSubType}\`${contentCarrierId}`
      }

      return {
        content_list,
        src_module: 'recommend',
        from: 1,
        src_identifier: src_identifier?.join?.('`') || '-',
        board_generate_type: board_generate_type || '-',
        content_id: productSelectUrlId || '-',
        carriersubtype: mobileIdentifier || '-',
      }
    }
  }
}
