import orderLogic from 'public/src/pages/common/orderLogic'
import bankSelect from '../sub_modules/bank-select'
import { isObject, getQueryString, parseQueryString } from '@shein/common-function'
import { markPoint } from 'public/src/services/mark/index.js'
import { prefetchOrdersListPageData } from 'public/src/pages/user/child_pages/orders/common/utils.js'
import payAgain from '../sub_modules/pay-again'


const setStatusLoading = (state, { v }) => {
  if (state.pageLoading) {
    return
  }
  state.status.loading = v
}

const state = {
  language: {},
  giftCard: { // 礼品卡列表使用
    cards: {},
    orderCards: {}
  },
  orderSearchMsg: {
    show: false,
    content: '',
    isSearchList: false,
    page: 1
  },
  status_type: 0, // 订单分状态(1待付款订单, 2待发货, 3待收货, 4售后/退款, 5可评论)
  pageLoading: false,
  orderListNotice: '',
  orderStatusTextList: [],

  /** todo */
  specialOutgoingOrderList: [],
  abnormalOrderList: [],
  abnormalOrderListType: 'old', // 暂时兼容旧字段，是否为旧回参类型
  /** todo end */

  abtInfos: {
    customerItemSyncToUserAddressAbt: {},
    findOrderAbt: {},
    myPackageAbt: {},
    orderListAbtInfo: [],
    syncToUseraddressAbt: {},
    unpaidOrderTestAbt: {},
    abTrackTestConfig: {},
    SFSTipShow: {},
    orderRepurchaseParam: {},
    markOrderParams: {},
    abTestConfig: {}
  },
  apolloInfo: {},
  isDemotion: false, // 黑五降级
  orders: [],
  page: 1,
  reviewPromptTip: '',
  status: {
    loading: false,
    msg: false,
    msgLoading: false,
    historyExtra: false,
    historyStatus: false,
    historyShow: false,
    showHistory: false,
    showAddCart: false
  },
  noMoreData: false,
  extraInfo: { // todo
    waitSendReturnOrder: []
  },
  isSsr: false,
  publicLocalData: {
    GB_cssRight: false,
    langPath: '',
    PUBLIC_CDN: '',
    SiteUID: '',
    WEB_CLIENT: '',
    lang: '',
    appLanguage: '',
    dateLangMap: {}
  },
  is_have_gfcard_order: '',
  orderStatusList: [],
  isShowPackage: '',
  unionData: {},
  topNoticeTips: '',
  relationAccount: false, // 是否存在邮箱手机号双账号关联
  historyOrders: {}, // 首屏历史订单数据
  pageStatus: {
    showPaymentsDrawer: false
  }
}

const getters = {
  ordersStatus: state => {
    const { language } = state
    return {
      class: orderLogic.orderStatusColor(),
      text: orderLogic.orderStatus(language)
    }
  },
  giftCardOrdersStatus: state => {
    const { language } = state
    return {
      class: orderLogic.giftOrderStatusColor(),
      text: orderLogic.giftOrderStatus(language)
    }
  },
  returnStatusText: state => {
    const { language } = state
    return {
      0: language.SHEIN_KEY_PWA_15755,
      1: language.SHEIN_KEY_PWA_15242,
      2: language.SHEIN_KEY_PWA_15231,
      3: language.SHEIN_KEY_PWA_15214,
      4: language.SHEIN_KEY_PWA_15310,
    }
  }
}

const mutations = {
  assignOrderListState(state, payload = {}) {
    const { key, value } = payload
    if (!key && isObject(payload)) {
      Object.entries(payload).forEach(([item, itemValue]) => {
        if (isObject(state[item]) && isObject(itemValue)) {
          state[item] = {
            ...state[item],
            ...itemValue
          }
        } else {
          state[item] = itemValue
        }
      })
      return
    }
    if (isObject(state[key]) && isObject(value)) {
      state[key] = {
        ...state[key],
        ...value,
      }
    } else {
      state[key] = value
    }
  },
  assignLanguage (state, payload) {
    state.language = { ...state.language, ...payload }
  },
  changePageStatus (state, payload) {
    state.pageStatus = { ...state.pageStatus, ...payload }
  },
  handleEditPayment (state) {
    state.pageStatus.showPaymentsDrawer = true
  },
  updateGiftCard (state, payload) {
    const { cards, orderCards } = payload
    state.giftCard.cards = cards
    state.giftCard.orderCards = orderCards
  },
  setAbtInfoData(state, payload) {
    state.abtInfos.moreStyle = payload?.ReturnStyle?.param?.MoreStyle || ''
    state.abtInfos.findOrderAbt = payload?.FindOrder?.param || {}
    state.abtInfos.customerItemSyncToUserAddressAbt = payload?.CustomerItemSyncToUserAddress?.param || {}
    state.abtInfos.syncToUseraddressAbt = payload?.SyncToUseraddress?.param || {}
    state.abtInfos.SFSTipShow = payload?.SFSTipShow || {}

    if (payload['Orderlist'] && payload['Orderlist'].p) {
      state.abtInfos.orderListAbtInfo = payload['Orderlist'].p.split('&')
      state.abtInfos.uploadReportShow = state.abtInfos.orderListAbtInfo.indexOf('upload_report=on') > -1
      state.abtInfos.isCodAddressEditEnable = !state.abtInfos.orderListAbtInfo.includes('CodAddress=off')
      state.abtInfos.isOpenRiskInfoByAbt = state.abtInfos.orderListAbtInfo.includes('riskInfo=on')
      // abt是否显示查看发票按钮
      // 如果是off，则关闭入口，isOpenViewInvoicesByAbt为false，其他情况都为true
      state.abtInfos.isOpenViewInvoicesByAbt = !state.abtInfos.orderListAbtInfo.includes('invoice=off')
    } else {
      state.abtInfos.orderListAbtInfo = ['address=on', 'pay=on', 'verify=on', 'paytime=on', 'returnitem=on']
      state.abtInfos.isCodAddressEditEnable = true
      state.abtInfos.isOpenViewInvoicesByAbt = true
    }

    // 如果是off，则不允许，其他情况都为true

    state.abtInfos.isInfoFrontAbt = payload?.IsInfoFront?.p == 'on'
    state.abtInfos.isCanRefundAbt = payload?.IsCanRefund?.p || 'BBC=on&Shop=on'
    state.abtInfos.openIsInfoFrontAbt = payload?.IsInfoFront?.p === 'on'
    state.abtInfos.isOpenShopUrl = payload?.ShopUrl?.p != 'off'
    state.abtInfos.isShowOrderResellEntrance = payload?.orderResellEntrance?.p !== 'conceal_resellorder'
    state.abtInfos.isShowUrgeshipmentAbt = payload?.SpeedUp?.param?.SpeedUp_index === 'on'
    state.abtInfos.isShowExchangeAbt = payload?.ExchangeFunction?.param?.ExchangeFunction != 'off'
    // abt 是否可以展示卡bin优惠信息
    state.abtInfos.isShowCardBinDiscountABT = payload?.BinDiscountdiscount?.param?.BinDiscountShow === 'Show'
    // abt 是否可以展示卡bin优惠随机立减
    state.abtInfos.isShowBinRandomDiscountABT = payload?.BinRandomDiscount?.param?.BinRandomShow === 'Show'
    state.abtInfos.showCommentRewardAbt = payload?.Commentcoupon?.param?.Commentcoupon == 'coupon'
    state.abtInfos.isNewStoreIcon = payload?.storeiconchange?.param?.storeiconchange === 'new'
    state.abtInfos.orderRepurchaseParam = payload?.orderRepurchaseParam || {}
    state.abtInfos.showModifyReturnApp = payload?.showModifyReturnApp || false
    state.abtInfos.markOrderParams = payload?.markOrderParams || {}
    state.abtInfos.isReturnOff = payload?.ReturnItem?.p === 'ON'
    state.abtInfos.thirtyLoginAbt = payload?.thirtyLoginAbt || {}

    // 关联账号abt
    const accountAbt = payload?.OrderlistQueryRelatedAccounts?.p || 'off'
    if (['related', 'free'].includes(accountAbt)) {
      state.abtInfos.relationAccountAbt = accountAbt
    }

    // 双账号关联按钮展示方式abt开关
    state.abtInfos.orderlistRelatedIconAbt = payload.OrderlistRelatedIcon?.p || 'off'
    state.abtInfos.codRiskOrderAbt = payload?.CODRiskOrder?.param?.CODRiskOrder == 'on'
    state.abtInfos.batchModifyAddressAbt = payload?.BatchModifyAddress?.param?.order_list_address == 'on'
    state.abtInfos.isShowUrgeDeliveryAbt = payload?.UrgeDelivery?.param?.UrgeDelivery_index == 'on'
    state.abtInfos.timeCanDelivery = payload?.TimeCanDelivery?.p == 'on'
    state.abtInfos.isReturnStatusFrontOn = payload?.['returnStatusFront']?.param?.['returnStatusFront'] == 'on'
    state.abtInfos.showEditOrderAddress = payload?.EditOrderAddress?.param?.OrderListAddress == 'on'
    state.abtInfos.myPackageAbt = payload?.MyPackage?.param || {}
    state.abtInfos.unpaidOrderTestAbt = payload?.unpaidOrderTest?.param || {}

    state.abtInfos.showPriceGuaranteeAbt = payload?.ShowPriceGuarantee?.param?.ShowPriceGuarantee == 'on'
    state.abtInfos.isRejectedReasonOn = payload?.RejectedReason?.param != 'off'
    state.abtInfos.abTrackTestConfig = payload?.TrackPrepose?.param || {}
    state.abtInfos.abTestConfig = parseQueryString(payload?.['OrderlistOldFunc']?.p || '') || {}

    state.abtInfos.showApplepayDropoffOptimization = payload?.ApplepayDropoffOptimization?.param?.ApplepayButtonShow == 'Show'
    state.abtInfos.showConfirmDeliveryPage = payload?.ConfirmDeliveryPage?.param != 'none'
    state.abtInfos.isShowReturnAndRefund = payload?.isShowReturnAndRefund
  },
  // 带状态订单
  updateByStatusOrderList (state, payload) {
    state.page = state.page + 1

    let orders = payload.list
    if (orders?.length < 10) {
      state.noMoreData = true
      if (state.status_type === 0) {
        state.status.historyStatus = true
      }
    }

    if (orders?.length) {
      state.isDemotion = payload.isDemotion
      // 存在卡bin优惠金额时，应付金额应为不含卡bin优惠金额的金额
      orders = orders.map(order => {
        if (order?.card_bin_discount_amount?.amount > 0 && order?.no_bin_discount_pay_amount?.amount > 0) {
          order.totalPrice = order.no_bin_discount_pay_amount
        }
        return order
      })
      state.orders = [...state.orders, ...orders]

      if (state.pageLoading) {
        return
      }
    }
    state.topNoticeTips = payload.topNoticeTips
    state.is_have_gfcard_order = payload.is_have_gfcard_order || ''
    state.isShowPackage = payload.isShowPackage || ''
    state.status.loading = false
  },
  // 更新某个订单
  updateSomeOrderInfo (state, payload) {
    const { orderInfo = {} } = payload || {}
    state.orders?.forEach(item => {
      if (item.billno == orderInfo.billno) {
        Object.assign(item, orderInfo)
      }
    })
  },
  // 所有订单
  updateAllOrderList (state, payload) {
    const { isSearchList } = state.orderSearchMsg || {}
    if (isSearchList) {
      state.orderSearchMsg = {
        ...state.orderSearchMsg,
        page: state.orderSearchMsg.page + 1
      }
    } else {
      state.page = state.page + 1
    }
    let orders = payload.list
    if (!orders?.length) {
      state.noMoreData = true
      state.status.historyStatus = true
    } else {
      state.orders = [...state.orders, ...orders]
    }
    if (orders?.length) {
      state.isDemotion = payload.isDemotion
      // 定义初始免费退变量
      orders.forEach(item => {
        if (typeof item.isFreeRefund !== 'boolean') {
          item.isFreeRefund = false
        }
      })
    }
    setStatusLoading(state, { v: false })
  },
  // 历史订单
  updateHistoryOrderList (state, payload) {
    const { data = {}, page = 1 } = payload || {}
    if (data.code == 0) {
      state.noMoreData = false
      // 隐藏history show more
      if (page * 10 >= data?.info?.sum) {
        state.noMoreData = true
        state.status.historyExtra = true
      } else {
        state.status.showHistory = true
      }
      // BFF增加历史订单标记
      data.info?.order_list?.forEach(item => item.isOldOrder = true)
      state.orders = [...state.orders, ...data.info?.order_list || []]
    }
    setStatusLoading(state, { v: false })
  },
  // 过滤某个订单
  filterDeleteOrderEvt(state) {
    const deletedBillNo = getQueryString({ key: 'deletedBillNo' }) || ''
    if (state.orders?.length && deletedBillNo) {
      const orders = state.orders.filter(item => item.billno !== deletedBillNo)
      state.orders = orders
    }
  }
}

const actions = {
  investData({ commit, state }, { unionData, orderStatusText }) {
    const { apolloInfo, isSsr, locals, i18n, abtInfos, orderStatusList, is_have_gfcard_order, order_list, isDemotion, reviewPromptTip, isShowPackage, orderListNotice = '', relationAccount, topNotice, historyOrders } = unionData

    console.log('unionData>>>>>>', unionData)
       
    commit('assignLanguage', i18n)
    commit('assignOrderListState', {
      pageLoading: false,
      relationAccount: relationAccount == 1,
      orderStatusTextList: orderStatusText?.length ? orderStatusText : [],
      apolloInfo,
      publicLocalData: locals,
      isSsr,

      // bff字段
      historyOrders,
      unionData,
      orderStatusList,
      is_have_gfcard_order,
      reviewPromptTip,
      isShowPackage,
      orderListNotice
    })
    commit('setAbtInfoData', abtInfos)
    if (state.status_type) {
      commit('updateByStatusOrderList', {
        list: order_list,
        isDemotion,
        topNoticeTips: topNotice?.tip || '',
        is_have_gfcard_order: is_have_gfcard_order || '',
        isShowPackage: isShowPackage || '',
      })
    } else {
      commit('updateAllOrderList', {
        list: order_list,
        isDemotion
      })
    }
    // 是否展示历史订单
    if (historyOrders?.info?.sum > 0) {
      state.status.showHistory = true
    }
    commit('filterDeleteOrderEvt')
  },
  async initSsrData ({ dispatch, commit, state }, { context, route, statusType, from }) {
    const isSsrPage = !from?.name
    if (isSsrPage && context?.ssrPageType === 'ordersList') {
      dispatch('investData', { unionData: context, orderStatusText: [] })
      return
    }
    if (typeof window !== 'undefined') {
      commit('assignOrderListState', { pageLoading: true })
      let unionData = {}
      let orderStatusText = []
      const orderListPrefetch = route.meta.orderListPrefetch
      if (orderListPrefetch) {
        markPoint({ eventName: 'waitPageData', measureFrom: 'pageAsyncDataHook' })
        route.meta.orderListPrefetch = null
        const [prefetchUnionData, prefetchOrderStatusText] = await Promise.resolve(orderListPrefetch)
        markPoint({ eventName: 'pageDataReady', measureFrom: 'waitPageData' })
        unionData = prefetchUnionData || {}
        orderStatusText = prefetchOrderStatusText || []
      } else {
        markPoint({ eventName: 'apiFetchMainData', tag: 'begin' })
        const [unionPageData, orderStatusTextData] = await prefetchOrdersListPageData({ statusType, orderSearchMsg: state.orderSearchMsg })
        markPoint({ eventName: 'apiFetchMainData', tag: 'end' })
        unionData = unionPageData || {}
        orderStatusText = orderStatusTextData || []
      }
      dispatch('investData', { unionData, orderStatusText })
      markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    bankSelect,
    payAgain
  }
}
