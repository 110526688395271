import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { E_BenefitFloor } from '../../types'
import type { DS_BenefitFloor } from '../../types'
import { getFsDataRunner } from '../../common/dataSource'

export function getBenefitFloorFsDataRunner(dataSource?: DS_BenefitFloor.DataSource) {
  return getFsDataRunner(dataSource, useAppConfigs())
}

export { default, default as BBenefitFloor } from './benefit-floor.vue'
export type * from '../../types'
export import BenefitComponentType = E_BenefitFloor.BenefitComponentType
export * from './hooks/useBenefitDrawer'
