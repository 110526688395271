import { setFeedbackRecInfo } from '@/public/src/pages/product_list_v2/js/utils.js'

const hideFeedbackRecPopup = (vm, { factor = true } = {}) => {
  factor && vm.$refs.ProductList?.hideFeedbackRecPopup()
}

export {
  setFeedbackRecInfo, // 先暂时从列表引入，后续优化后，统一此hooks管理。
  hideFeedbackRecPopup
}
