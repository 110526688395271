import type { DS_QuickAddCartStandard } from '../../../../types/index'
import { computed } from 'vue'
import { useModules } from '../../modules/useModules.ts'
import { template } from '@shein/common-function'
import { callHook } from './utils.ts'
import Logger from '../../utils/logger.ts'
import { showToast } from '../../utils/toastQueue.ts'

import { IHandlerResult } from './types.ts'

interface IHandler {
    code: string | string[]
    handler: () => IHandlerResult
}
export const useCartStatusHandler = (props, emit) => {
    const { fsAbt, saleAttr } = useModules()

    const onClose = (isSuccess: boolean) => {
        callHook(props.callbacks, 'onAddCartComplete', {
            target: props.analysisConfig?.sourceTarget,
            isSuccess,
            featureConfig: props.featureConfig,
        })
    }
    const isShowLowStockTips = computed(() => {
        if (fsAbt && fsAbt.value) {
            return fsAbt.value.outofstocktips?.param?.addtocarttoastlowstocktips === 'on'
        }
        return false
    })

    const getProductInventoryBySku = (skuCode?: string) => {
        if (saleAttr && skuCode) {
            return (saleAttr.value?.multiLevelSaleAttribute?.sku_list || []).find(i => i.sku_code === skuCode)
        }
        return undefined
    }

    function handler(data: DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GoodsAddCartResponse>, formData: DS_QuickAddCartStandard.PostAddToCartParams, options: {
        hiddenAddCartTips?: boolean,
        reportSa: (type: string, saParam: any) => void
    }): IHandlerResult {

        const { code, tips, msg } = data
        const { reportSa } = options

        const { promotion_type } = formData

        const continueAddingProducts: IHandler
            = {
            code: '300410',
            handler: () => {
                Logger.log('handler continueAddingProducts', data)
                // 添加加价购商品
                callHook(props.callbacks, 'onCartTagTipsUpdate')
                return { isSuccess: true }
            },
        }

        const modifyMsg: IHandler = {
            code: ['300402', '500301', '200401'],
            handler: () => {
                Logger.log('handler modifyMsg', data)
                const res = { isSuccess: false, errorMsg: '' }
                if (code === '300402') {
                    res.errorMsg = props.language.SHEIN_KEY_PWA_15230
                } else {
                    res.errorMsg = props.language.SHEIN_KEY_PWA_14967
                }
                return res
            },
        }

        const limitPurchase: IHandler = {
            code: ['500304', '500305'],
            handler: () => {
                Logger.log('handler limitPurchase', data)
                emit('onChangeLimitType', code == '500304' ? 1 : 2)
                return { isSuccess: false, isHiddenDefaultTip: true }
            },
        }

        const commonSuccess: IHandler = {
            code: '0',
            handler: () => {
                const language = props.language
                Logger.log('handler commonSuccess', data)
                const { sku_code: skuCode } = formData
                let sucTip: string | undefined = ''
                if (promotion_type) {
                    sucTip = [2, 4, 28].includes(+promotion_type) ? language.SHEIN_KEY_PWA_27811 : [13, 22].includes(+promotion_type) ? language.SHEIN_KEY_PWA_27812 : ''
                    if (sucTip) {
                        showToast({
                            content: sucTip,
                            onClose: () => onClose(true),
                        })
                        return { isSuccess: true, isHiddenDefaultTip: true, cartTotalQuantity: data.info?.quantity }
                    }
                }
                // 如果有plugin调用 afterAddCartApi 的hook，修改hiddenAddCartTips，则不显示默认的toast
                if (!options.hiddenAddCartTips && isShowLowStockTips.value) {
                    const skuInventory = getProductInventoryBySku(skuCode)
                    if (skuInventory && skuInventory?.selected_will_sold_out_tips) {
                        const tips: string = `${template(skuInventory.selected_will_sold_out_tips, language.SHEIN_KEY_PWA_35405 || 'This item is {0}')}`
                        if (tips) {
                            showToast({
                                htmlString: `<div class="low-stock-tips__addCart">
                                <div class="success-tips-content">
                                  <i class='tips-icon sh_m_sui_icon_hourglass_12px_2'></i>
                                  ${tips}
                                </div>
                                <div>${language.SHEIN_KEY_PWA_33089}</div>
                                </div>`,
                                onClose: () => onClose(true),
                                duration: 2200,
                            })
                            // 进行低库存提示的曝光上报
                            reportSa('lowStockTips', skuInventory)
                            return { isSuccess: true, isHiddenDefaultTip: true, cartTotalQuantity: data.info?.quantity }
                        }
                    }
                }

                return { isSuccess: true, isHiddenDefaultTip: options?.hiddenAddCartTips, cartTotalQuantity: data.info?.quantity }
            },
        }
        const handlers = [commonSuccess, continueAddingProducts, modifyMsg, limitPurchase]

        const resultHandler = handlers.find(i => i.code === code || i.code.includes(code))

        const defaultErrorMsg = tips || msg || props.language.SHEIN_KEY_PWA_15282 || 'error'
        if (!resultHandler) {
            Logger.warn('Error handler not found ', data)
            return { isSuccess: false, errorMsg: defaultErrorMsg }
        }
        const result = resultHandler.handler()

        if (!result.isSuccess && !result.errorMsg) {
            result.errorMsg = defaultErrorMsg
        }
        return result



    }

    return handler
}
