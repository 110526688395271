import Vue from 'vue'
/** 
 * Third-Party-Events-Skip
 * 
 * 【通过全局捕获事件拦截三方全局埋点发送】
 * 【不会影响常规冒泡事件】
 * 【转场 dom 使用 tpes-click (tpes-click.native for comp) 替代 click】
 * 【注意保留 click (click.native for comp) 事件，发生意外错误时可以降级】
 * 【受 apollo 控制】
 * 
 * 已知收益
 * 
 * [mousedown]
 * LinkedIn 
 * https://snap.licdn.com/li.lms-analytics/insight.min.js
 * case Ln.EVENTS.MOUSE_DOWN:
 * 30ms
 * 
 * [click]
 * Facebook
 * https://connect.facebook.net/en_US/fbevents.js
 * f.ensureModuleRegistered("signalsFBEventsMakeSafe"
 * 64ms
 * 
 * GTM(M4JHLK)
 * https://www.googletagmanager.com/gtm.js?id=GTM-M4JHLK
 * var e = Cz(a, "individualElementIds", []);
 * 10ms
 * 
 */
class TPES {
    _currentPage = ''
    _pageList = new Map()
    _eventName = 'tpes-click'
    _whiteList = null

    constructor() {
        if (typeof window === 'undefined') return
        this._whiteList = new Set(Array.isArray(gbCommonInfo.TPES_WHITELIST) ? gbCommonInfo.TPES_WHITELIST : [])
        window.addEventListener('click', this.stopPropagation.bind(this), true)
        window.addEventListener('mousedown', this.stopPropagation.bind(this), true)
    }

    switchPage(page) {
        this._currentPage = page
    }

    bindDom(dom, page, action) {
        if (!page || !dom || !action) return

        const domlist = this._pageList.get(page)
        if (domlist?.has(dom)) return

        dom.tpes_action = action
        domlist
            ? domlist.add(dom)
            : this._pageList.set(page, new Set([dom]))

        console.log('TPES: bindDom', { dom, page });
    }

    unbindDom(dom, page) {
        if (!dom || !page) return

        const domlist = this._pageList.get(page)
        domlist?.delete(dom)

        console.log('TPES: unbindDom', { dom, page });
    }

    clear(page) {
        if (!page) return
        this._pageList.delete(page)
    }

    stopPropagation(e) {
        if (!this._currentPage || !this._whiteList.has(this._currentPage)) return

        const domlist = this._pageList.get(this._currentPage)?.entries() || [];
        [...domlist].some(([dom]) => {
            if (dom?.contains?.(e?.target) && typeof dom?.tpes_action === 'function') {
                e.stopPropagation()
                e.type === 'click' && dom.tpes_action(new Event(this._eventName, { bubbles: true, cancelable: false }))
                console.log('=== TPES StopPropagation ===', e.type, e.forwardedTouchEvent ? 'from fastclick' : '');
                return true
            }
        })
    }
}
export const __tpes__ = new TPES()

Vue.directive('tpes', {
    bind: function (el, binding, vnode) {
        __tpes__.bindDom(el, binding?.arg, vnode?.data?.on?.click)
    },
    unbind: function (el, binding) {
        __tpes__.unbindDom(el, binding?.arg)
    }
})
