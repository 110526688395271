<template>
  <div class="bsc-common-header">
    <div
      v-show="headerStatus.showBg"
      class="bsc-common-header__bg"
      :style="commonHeaderBgStyle"
    ></div>

    <header
      :class="`bsc-common-header__wrap ${headerStatus.wrapClassName}`"
      :style="commonHeaderWrapStyle"
    >
      <div
        class="bsc-common-header__left"
        :style="headerStatus.leftSlotStyle"
      >
        <slot
          name="left"
          :commonHeaderContext="commonHeaderContext"
        > </slot>
      </div>
      <div
        class="bsc-common-header__center"
        :style="headerStatus.centerSlotStyle"
      >
        <slot
          name="center"
          :commonHeaderContext="commonHeaderContext"
        > </slot>
      </div>
      <div
        class="bsc-common-header__right"
        :style="headerStatus.rightSlotStyle"
      >
        <slot
          name="right"
          :commonHeaderContext="commonHeaderContext"
        > </slot>
      </div>
    </header>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed, provide, reactive, onUnmounted, defineExpose, StyleValue } from 'vue'
import useSticky from './composables/useSticky'
import { ProxyHost } from '@shein-aidc/bs-sdk-libs-manager'
import { assignGlobalEventProxyHost } from '@shein-aidc/bs-sdk-libs-manager'

import {
  C_CommonHeader,
  CommonHeaderContextProvide,
  CommonHeaderEventProxyProvide,
  CommonHeaderProps,
} from '../../types'
const props: CommonHeaderProps = defineProps({
  headerStatus: {
    type: Object,
    default: () => ({}),
  },
  headerOptions: {
    type: Object,
    default: () => ({}),
  },
})

const commonHeaderEventProxy: C_CommonHeader.EventProxyHost =
  new ProxyHost<C_CommonHeader.Events>()
provide<C_CommonHeader.EventProxy>(
  'COMMON_HEADER_EVENT_PROXY_PROVIDE' as CommonHeaderEventProxyProvide,
  commonHeaderEventProxy,
)

const commonHeaderContext = reactive(useSticky(props.headerOptions, props.headerStatus))
provide(
  'COMMON_HEADER_CONTEXT_PROVIDE' as CommonHeaderContextProvide,
  commonHeaderContext,
)

defineExpose({
  commonHeaderContext,
})

assignGlobalEventProxyHost('CommonHeader', commonHeaderEventProxy)

onUnmounted(() => {
  commonHeaderEventProxy.describe('CommonHeader')
})

const commonHeaderWrapStyle = computed(() => {
  let {
    wrapStyle = {},
  } = props.headerStatus
  let style: StyleValue = {
    position: 'relative',
    height: '11.73vw',
    width: '100%',
    padding: '0 3.2vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: '11',
    transform: 'translate3d(0, 0, 11px)',
    background: '',
    borderBottom: 'none',
    ...wrapStyle,
  }
  return style
})

const commonHeaderBgStyle = computed(() => {
  let {
    bgStyle = {},
  } = props.headerStatus
  let basicStyle: StyleValue = {
    position: 'absolute',
    height: '0px',
    zIndex: 1,
    top: 0,
    width: '100%',
    'background-repeat': 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPosition: '0 0',
    backgroundColor: '',
    background: '',
    backgroundImage: '',
    ...bgStyle,
  }
  return basicStyle
})


</script>

<style lang="less">
.bsc-common-header {
  position: relative;
  &__bg {
    height: 11.73vw;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    z-index: -1;
  }
  &__left,
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__left {
    justify-content: flex-start;
  }
  &__right {
    justify-content: flex-end;
  }
  &__center {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    min-height: 100%;
  }
}
</style>
