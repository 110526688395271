<template>
  <li
    v-enterkey
    :class="[prefixCls('new-gift-card')]"
    tabindex="0"
    @click.stop="handleOpen"
  >
    <div class="input-icon">
      <span class="bar__left">
        {{ language.SHEIN_KEY_PWA_15846 }}
      </span>

      <div class="bar__right">
        <template v-if="selectedItem">
          <div class="info">
            <span class="info__price">-{{ getDiscountPrice }}</span>
            <span class="info__balance">{{ getBalancePrice }}</span>
          </div>
        </template>
        <template v-else>
          <span class="tip">{{ getGiftCard?.guide_tip }}</span>
          <div 
            v-if="+getGiftCard?.red_dot === 1 && isShowRedDot"
            class="dot" 
          ></div>
        </template>
        <Icon
          :name="!GB_cssRight ? 'sui_icon_more_right_16px' : 'sui_icon_more_left_16px'"
          class="icon-arrow"
          color="#959595"
        />
      </div>
    </div>

    <!-- giftCard 滑出层 -->
    <ClientOnly>
      <BGiftCard
        v-if="apiCardsMap"
        ref="giftCardRef"
        :drawer-props="{
          size: '80%',
          visible: virtualAssetsState?.newGiftCardDrawer?.visible,
        }"
        :extraData="{
          discountPrice: getDiscountPrice,
          selectedNo: selectedItem?.card_no,
          abtType: +getGiftCard.gift_card_abt,
          discountPriceHeight: '3.75rem'
        }"
        scene="checkout"
        :card-data="getCardsMap"
        @custom-event="onCustomEvent"
      >
        <template #footer>
          <Footer 
            :isSimple="true"
          />
        </template>
      </BGiftCard>
    </ClientOnly>
  </li>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue'
import { BGiftCard } from '@shein-aidc/bs-gift-card-mobile-vue2'
import ClientOnly from 'vue-client-only'
import { useMapState, useMapActions, useMapMutations, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'
import { Icon } from '@shein-aidc/icon-vue2'
import { Toast } from '@shein/sui-mobile'
import verifyManager from '@shein-risk/si_verify'
import schttp from 'public/src/services/schttp'
import { template } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { VirtualAssetsTypes } from '@/public/src/pages/checkout_new/utils/constant'
import { prefixCls } from '../utils'
import Footer from '../../footer/Index.vue'


const { language, GB_cssRight, virtualAssetsState, checkout, initialSsrParamsInfo } = useMapState(['language', 'GB_cssRight', 'virtualAssetsState', 'checkout', 'initialSsrParamsInfo'])
const { updateCheckoutData } = useMapActions(['updateCheckoutData'])
const { updateVirtualAssetsPopupState } = useMapMutations(['updateVirtualAssetsPopupState'])
const { limitGoods } = useMapGetters(['limitGoods'])

const apiCardsMap = ref(null)
const selectedItem = ref(null)
const cardCache = ref([])
const giftCardRef = ref(null)
const isShowRedDot = ref(true)
const isLoading = ref(false)

verifyManager.init({
  axiosInstance: schttp, 
})

const getGiftCard = computed(() => checkout.value.giftcard)
const getSortedPrice = computed(() => checkout.value.sorted_price)
const getNoAddressInfo = computed(() => checkout.value.no_address_calculate_address_info)
const getCardsMap = computed(() => ({
  available_card_list: [].concat(cardCache.value, apiCardsMap.value?.available_card_list).filter(Boolean),
  unavailable_card_list: [].concat(apiCardsMap.value?.unavailable_card_list).filter(Boolean),
}))

const getDiscountPrice = computed(() => {
  const price = getSortedPrice.value?.find(item => item.type === 'giftCard')?.price_with_symbol
  return +price?.replace(/\D/g, '') === 0 ? 0 : price
})
const getBalancePrice = computed(() => template(selectedItem.value?.balance?.amountWithSymbol, language.value.SHEIN_KEY_PWA_35106))


const getCardListData = async() => {
  isLoading.value = true
  const countryId = await UserInfoManager.get({ key: 'Country', actionType: 'getCardList' })?.countryId || getNoAddressInfo.value?.country_id || initialSsrParamsInfo.value?.country_id
  const res = await schttp({
    url: '/user-api/gift_card/query',
    method: 'POST',
    data: {
      query_zero_balance: 1,
      country_id: countryId,
      scene: 'checkout'
    },
    useBffApi: true
  })
  isLoading.value = false
  return res?.info || {}
}

const updateCheckoutPrice = ({ cardNo = '', cardPin = '' }) => 
  new Promise(resolve => {
    isLoading.value = true
    updateCheckoutData({
      opts: {
        card_no: cardNo,
        card_pin: cardPin,
        is_bind_gift_card: !!cardPin ? 0 : 1
      },
      cb: (res) => {
        const { msg, code } = res
        const showToash = !!cardNo 
        if (+code === 0) {
          nextTick(() => {
            // 展示不可使用商品
            if (limitGoods.value.giftCard.length && cardNo) {
              updateVirtualAssetsPopupState({
                freezeLimitGoods: {
                  visible: true,
                  type: VirtualAssetsTypes.GIFT_CARD,
                }
              })
            }
          })
          showToash && Toast({ content: language.value.SHEIN_KEY_PWA_35190 })
        } else {
          // 300364 301711 301717 300365 300368 301716
          Toast({ content: msg })
        }
        isLoading.value = false
        resolve(res)
      }
    })
  })

const handleRisk = (resData) => {
  // 风控挑战（极验、短信、邮箱验证)
  const cb = res => {
    const { isSuccess, msg } = res
    if (Boolean(isSuccess)) {
      const ref = giftCardRef.value?.$refs.componentRef?.$refs.addCardRef?.$refs?.bindGiftCardRef
      if (ref) {
        ref.checkGiftCard({
          reskId: resData?.info?.risk_id,
          validate_token: resData?.info?.extend_info?.validate_token
        })
      } 
    } else {
      Toast({ content: msg })
    }
    console.log('----result', res)
  }
  verifyManager.doVerify(resData?.info?.extend_info, cb)
}

const setVisible = (visible) => {
  updateVirtualAssetsPopupState({
    newGiftCardDrawer: {
      visible
    }
  })
}

const handleOpen = async() => {
  apiCardsMap.value = await getCardListData()
  giftCardRef.value?.goTab(0)
  setVisible(true)
  isShowRedDot.value = false
}

const onFindCard = ({ cardNo, tabIndexs = [0, 1], skipValuation = false }) => {
  const { available_card_list = [], unavailable_card_list = [] } = getCardsMap.value
  for(let i = 0; i < tabIndexs.length; i++) {
    const list = i === 0 ? available_card_list : unavailable_card_list
    const index = list.findIndex(item => item.card_no === cardNo)
    if (index !== -1) {
      giftCardRef.value?.goTab(i)
      giftCardRef.value?.goScroll(index)
      i === 0 && onSelectCard(list[index], skipValuation)
      return true
    }
  }
  return false
}

const onBindCard = async(res) => {
  const { card_no, card_pin = '', isBindCard = false, resData = {} } = res
  if (isBindCard) {
    const { code, msg, info = {} } = resData
    if (+code === 0){
      closeBindCardDrawer()
      const { card_no: resCardNo } = info
      cardCache.value = cardCache.value.filter((item => item.card_no !== resCardNo))
      apiCardsMap.value = await getCardListData() 
      onFindCard({ cardNo: resCardNo })
    } else if (+code === 331802) { // 已经被用户绑定
      closeBindCardDrawer()
      Toast({ content: msg })
      onFindCard({ cardNo: card_no })
    } else if (+code === 331809 && info?.extend_info != null ) {
      handleRisk(resData, res)
    } else {
      Toast({ content: msg })
    }
  } else {
    const { code, info } = await updateCheckoutPrice({ cardNo: card_no, cardPin: card_pin, isBindCard: false })
    cardCache.value = cardCache.value.filter((item => item.card_no !== card_no))
    const card = info?.giftcard?.card_info
    if (+code === 0 && card) {
      closeBindCardDrawer()
      cardCache.value.unshift(card)
      onFindCard({ cardNo: card.card_no, tabIndexs: [0], skipValuation: true })
    } else if(+code == 300369) {
      // 已经被用户绑定
      closeBindCardDrawer()
      onFindCard({ cardNo: card_no, skipValuation: true })
    }
  }
}

const closeBindCardDrawer = () => {
  // 绑卡成功关闭绑卡弹窗
  const ref = giftCardRef.value?.$refs.componentRef?.$refs.addCardRef
  if (ref) {
    ref?.closeDrawer()
  }
}

const onSelectCard = async(item, skipValuation = false) => {
  console.log('🐬🐬🐬 es: select card', item)
  const { card_no, card_pin } = item || {}
  if (skipValuation) {
    selectedItem.value = item
  } else {
    const { code } = await updateCheckoutPrice({ 
      cardNo: card_no,
      cardPin: card_pin
    })
    if (+code === 0) {
      selectedItem.value = item
    }
  }
}

const onClose = () => {
  setVisible(false)
}

const onCustomEvent = ({ key, data = {} } = {}) => {
  console.log('🐬🐬🐬 es: onCustomEvent', key, data)
  if (isLoading.value) return
  switch(key) {
    case 'select':
      onSelectCard(data.item)
      return
    case 'bind-card':
      onBindCard(data)
      break
    case 'close':
      onClose()
      break
    default:
      console.log('no event')
  }
}
</script>


<style lang="less">
@import '../variables.less';
.S-drawer__normal-footer { // 底部 footer 弹窗内容
  text-align: left;
}
.@{prefixCls}new-gift-card {
  display: flex;
  justify-content: space-between;
  
  .bar__left {
    color: #000;
    font-size: .3733rem;
    white-space: nowrap;
    vertical-align: middle;
  }
  .bar__right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: #767676;
    margin-right: .5333rem;
    .info {
      display: flex;
      flex-direction: column;
      &__price {
        color: #FA6338;
        text-align: right;
        font-size: .3733rem;
        font-weight: 700;
        line-height: 1.2;
      }
      &__balance {
        color: #767676;
        font-size: .3733rem;
        font-weight: 400;
        line-height: 1.2;
      }
    }
    .tip {
      font-size: .3733rem;
      font-weight: 400;
    }
    .dot {
      margin-left: .1067rem;
      border: .094rem solid #FA6338;
      border-radius: 50%;
      width: .1867rem;
      height: .1867rem;
    }

  }
  &__header {
    display: flex;
    flex-direction: column;
    padding: 0 .32rem;
    margin-bottom: .2133rem;
    .title {
      color: #767676;
      text-align: center;
      font-size: .3733rem;
      font-weight: 600;
    }
    .subTitle {
      line-height: 1.1;
      color: #959595;
      text-align: center;
      font-size: .2667rem;
      font-weight: 400;
    }
  }
}
</style>

