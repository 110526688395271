import { nextTick } from 'vue'
import { omitAttr } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useMapState, useMapMutations, useMapActions } from 'public/src/pages/checkout_new/hooks/store'
import useCoupon from 'public/src/pages/checkout_new/hooks/useCoupon.js'
import { CardType } from '../utils/constant.js'

export default function useUpdateCardData() {

  const { prime_info, coupon_list, checkoutParams } = useMapState(['checkout.prime_info', 'checkout.coupon_list', 'checkoutParams'])
  const { selectPrime, selectSaver } = useMapMutations(['selectPrime', 'selectSaver'])
  const { updateCheckoutData, updateCartsInfoData } = useMapActions(['updateCheckoutData', 'updateCartsInfoData'])
  const { filterCoupon, useGetCouponList } = useCoupon()

  const parseCardType = (cardType) => {
    const isPrime = [CardType.PRIME, CardType.BOTH].includes(cardType)
    const isSaver = [CardType.SAVE, CardType.BOTH].includes(cardType)

    return { isPrime, isSaver }
  }

  // 取消勾选包时，需要过滤相关券
  const filterCardCoupon = (cardType, chekcoutParams) => {
    const { isPrime, isSaver } = parseCardType(cardType)
    let filteredCoupons = coupon_list.value
    if (isPrime && chekcoutParams.prime_product_code === '') {
      filteredCoupons = filterCoupon(filteredCoupons, CardType.PRIME)
    }
    if (isSaver && chekcoutParams.save_card_product_code === '') {
      filteredCoupons = filterCoupon(filteredCoupons, CardType.SAVE)
    }
    return filteredCoupons
  }

  // 格式化入参
  const formatCheckoutParams = (cardType, chekcoutParams) => {
    const params = omitAttr(
      chekcoutParams,
      ['prime_product_code', 'save_card_product_code']
    )
    const { isPrime, isSaver } = parseCardType(cardType)
    let currentAction = ''
    if (isPrime) {
      params.prime_product_code = chekcoutParams.prime_product_code
      currentAction = 'add_prime'
      if (!params.prime_product_code) currentAction = 'cancel_prime'
    }
    if (isSaver) {
      params.save_card_product_code = chekcoutParams.save_card_product_code
      currentAction = 'add_save_card'
      if (!params.save_card_product_code) currentAction = 'cancel_save_card'
    }
    
    const filteredCoupons = filterCardCoupon(cardType, params)
    return {
      ...params,
      current_action: currentAction,
      coupon_list: filteredCoupons.map(item => item.couponCode)
    }
  }

  // 上报埋点：选择付费会员
  const reportData = (options) => {
    const { primeIndex, primeCode, selectPrimeLocation } = options
    const productInfo = prime_info.value.prime_products[primeIndex]
    daEventCenter.triggerNotice({ 
      id: 'click_prime_select:simple',
      data: {
        select_flag: !!primeCode ? '1' : '0',
        prime_product_id: productInfo.product_goods_id,
        sequence: primeIndex > -1 ? primeIndex + 1 : '',
        // 1-月卡;2-季卡;3-年卡;4-半年卡;5-周卡;6-半月卡;7-自定义周期
        prime_level: productInfo.product_cycle_type || 0, 
        // 0-普通产品;1-自动续费;2-买赠奖品
        select_product_activity_type: `${productInfo?.product_activity_type}` || '', 
        // 触发付费会员勾选的地方：1-随单购;2-详情弹窗;3-切包弹窗;4-下单页挽留弹窗(已废弃)
        location: selectPrimeLocation
      }
    })
  }

  const setCardStatus = (cardType, params) => {
    const { isPrime, isSaver } = parseCardType(cardType)
    if (isPrime) selectPrime(params.prime_product_code)
    if (isSaver) selectSaver(params.save_card_product_code)
  }

  const updateCardData = (options) => {
    const { cardType, primeIndex, selectPrimeLocation, params, paymentInfo } = options
    // 先设置勾选状态
    setCardStatus(cardType, params)
    const handleCheckoutCompleted = (res) => {
      if (+res.code === 0) {
        nextTick(() => useGetCouponList())
      } else {
        // 接口报错，勾选状态需要回退
        setCardStatus( cardType, checkoutParams)
      }
    }
    const formattedParams = formatCheckoutParams(cardType, params)
    if (paymentInfo) {
      appEventCenter.$emit('set-selected-payment', {
        paymentInfo,
        changeCheckData: { 
          data: formattedParams,
          cb: handleCheckoutCompleted
        }
      })
    } else {
      updateCheckoutData({ 
        opts: formattedParams,
        cb: handleCheckoutCompleted
      })
    }
    if (cardType === CardType.SAVE) return
    // 仅付费会员需更新购物车数据
    updateCartsInfoData({ prime_product_code: formattedParams.prime_product_code })
    reportData({ primeIndex, selectPrimeLocation, primeCode: formattedParams.prime_product_code })
  }

  return { updateCardData }
}
