import {  FILTER_BAR_MAP } from 'public/src/pages/components/FilterBar/utils/const'

// 当前吸顶类型
export function getStickyType ({ filterbar, picTopNav }) {
  if (filterbar?.filterConfig?.isStickyCloudTags) return FILTER_BAR_MAP.CLOUD_TAGS
  if (filterbar?.filterConfig?.isStickyPicToNav && picTopNav?.length) return FILTER_BAR_MAP.PIC_TOP_NAV
      
  return FILTER_BAR_MAP.NAV_BAR
}

// 获取是否吸顶搜索框
export function getStickySearch ({ catInfo, listAbtResult }) {  
  return catInfo?.type === 'search' || listAbtResult?.param?.PListshowsearch === 'show'
}

export function getStickyConfigZIndex (isSticky, isStickySearch) {
  let zIndex = isStickySearch ? 8 : 12

  return isSticky ? ++zIndex : zIndex
}
