<template>
  <!-- 政策条需要等弹窗关闭再出现；若没有弹窗，直接展示政策条 -->
  <s-slide
    :visible="taxRelief.showNotice"
    :duration="!taxRelief.showDialog && taxRelief.hasNotice ? 0 : 800"
  >
    <div 
      ref="taxNoticeRef"
      class="tax-relief-notice"
    >
      <img 
        class="tax-relief-notice__icon"
        :src="taxRelief.noticeInfo.icon"
      />
      <div v-html="taxRelief.noticeInfo.title"></div>
    </div>
  </s-slide>
</template>
<script setup>
import { watch } from 'vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { Slide as SSlide } from '@shein/sui-mobile'
import { useMapState, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'

const { taxRelief } = useMapState(['commonDialogState.taxRelief'])
const { updateCommonDialogState } = useMapMutations(['updateCommonDialogState'])

const handleReport = () => {
  if (taxRelief.value.isNoticeShown) return
  updateCommonDialogState({
    taxRelief: {
      ...taxRelief.value,
      isNoticeShown: true
    }
  })
  daEventCenter.triggerNotice({ 
    id: 'expose_tax_show:simple',
    data: { location: 'bar' }
  })
}

watch(
  () => taxRelief.value.showNotice,
  (visible) => {
    if (visible) handleReport()
  },
  { immediate: true }
)
</script>
<style lang="less">
.tax-relief-notice {
  padding: 20/75rem;
  background: #FFF3D3;
  font-size: 12px;
  font-family: Arial;
  color: #000;
  display: flex;
  align-items: center;
  line-height: 1.2;

  &__icon {
    width: 32/75rem;
    height: 32/75rem;
    margin-right: 8/75rem;
  }
}
</style>
