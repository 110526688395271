import { parseQueryString } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

export const reportPoints = (metrics = [], isSSR) => {
  if (typeof window === 'undefined') {
    return
  }
  if (!metrics || metrics.length === 0) {
    return
  } 
  const {
    url_from = '',
    aff_id = '',
    onelink = '',
    scene = '',
    page_version = ''
  } = parseQueryString(location.search)
  const isMarketing = url_from || aff_id || onelink || scene === 'onelink'

  const pageFrom = isMarketing ? 'Marketing' : 'Home'
  const resource = isSSR ? 'ssr-landing-page' : 'spa-landing-page'
  const renderType = resource === 'ssr-landing-page' ? 'ssr' : 'spa'
  const pageVersion = page_version || 'Other'
  const pageName = window?.SaPageInfo?.page_name || ''
  const data = {
    resource,
    page_from: pageFrom,
    page_version: pageVersion,
    render_type: renderType,
    data: (metrics || []).map(metric => ({
      key_path: metric.key,
      values: {
        num: metric.value,
      },
    })),
    // 1. 固定 page_name 防止上报时 page_name 已经被篡改
    ...(pageName ? { page_name: pageName } : {}),
  }
  window.TPM?.run({
    marketing: 'ClientAnalytics',
    method: '_defineTrack',
    params: {
      data,
      options: {
        random: 1,
        immediate: true,
      },
    },
  })
}



let timer
const displayData = (data) => {
  clearTimeout(timer)
  timer = setTimeout(() => {
    const arr = data
    const divEl = document.createElement('div')
    divEl.style.position = 'fixed'
    divEl.style.top = '10%'
    divEl.style.left = '10%'
    divEl.style.backgroundColor = 'rgba(0,0,0,0.5)'
    divEl.style.color = '#fff'
    divEl.style.padding = '10px'
    divEl.style.zIndex = '9999'
    divEl.style.transform = `translate3d(0,0,9999px)`
    divEl.style.borderRadius = '5px'

    const innerHTML = arr
      .map(item => {
        return `<div style="padding:2px 0;font-size: 12px;">${item.key}: ${item.value}</div>`
      })
      .join('')

    divEl.innerHTML = innerHTML
    document.body.appendChild(divEl)
    setTimeout(() => {
      document.body.removeChild(divEl)
    }, 5000)
  }, 1000)
}






export const printLogger = (data = []) => {
  if (typeof window === 'undefined') {
    return
  }
  const { ___detailLoggers = [] } = gbCommonInfo?.contextForSSR || {}
  if (!Array.isArray(___detailLoggers) || ___detailLoggers.length === 0) return
  // 需要命中AB
  getUserAbtData().then(({ PerformanceLogNodeTestFetch }) => {
    if (PerformanceLogNodeTestFetch?.p?.PerformanceLogNodeTestFetch === 'YES') {
      displayData([...data, ...___detailLoggers])
    }
  })
}
