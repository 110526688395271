<template>
  <CommonCountDown
    v-slot="{ timer }"
    ref="countDownRef"
    :class="[prefixCls('timer'), small && prefixCls('timer_small')]"
    :end-time-stamp="endTimeStamp"
    @finish="handleTimerFinished"
  >
    <span :class="prefixCls('timer-item')">{{ timer.timeObj.H }}</span>
    <span :class="prefixCls('timer-item')">:</span>
    <span :class="prefixCls('timer-item')">{{ timer.timeObj.M }}</span>
    <span :class="prefixCls('timer-item')">:</span>
    <span :class="prefixCls('timer-item')">{{ timer.timeObj.S }}</span>
  </CommonCountDown>
</template>

<script setup name="TitleCountDown">
import { prefixCls } from '@/public/src/pages/checkout_new/pages/header/utils'
import CommonCountDown from '@/public/src/pages/checkout_new/components/CommonCountDown.vue'
import { updateCartsAndCheckout } from '@/public/src/pages/checkout_new/utils/store'

// ---------- props ----------
defineProps({
  endTimeStamp: {
    type: [Number, String],
    default: 0
  },
  small: {
    type: Boolean,
    default: false
  }
})

/**
 * @description 倒计时结束，需要刷新数据
 */
const handleTimerFinished = () => {
  updateCartsAndCheckout()
}

</script>

<style lang="less">
@import '../variables.less';

.@{prefixCls}timer {
  .@{prefixCls}timer-item {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.2;

    &:nth-child(odd) {
      padding: 2/75rem 4/75rem;
      background-color: #FA6338;
      color: #fff;
      border-radius: 4/75rem;
    }

    &:nth-child(even) {
      color: #FA6338;
      margin: 0 4/75rem;
      font-weight: 400;
    }
  }

  &_small {
    .@{prefixCls}timer-item:nth-child(odd) {
      padding: 0 2/75rem;
      border-radius: 2/75rem;
    }
  }
}
</style>
