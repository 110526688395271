import { transformImg } from '@shein/common-function'
import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'
// import LOCAL_STYLE_MAP from 'public/src/pages/product_app/store/modules/product_detail_new/utils/localStoreStyleMap.js'
// import { getLocalStorage } from '@shein/common-function'
export default {
  namespaced: true,
  state: {
    store_follow_info_state: {
      // abt命中才展示
      show: false,
      // 用户关注状态是否变更中
      followStateChanging: false,
      // 店铺关注数量
      followCount: '',
      // 店铺30日销量（tsp）
      salesVolume: '',
      // 用户关注状态，-1: 未登录，0: 未关注，1: 已关注
      userFollowState: -1,
      // 埋点信息
      abtAnaInfo: '',
    }, // 店铺关注信息
  },
  getters: {
    store_code(state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/common/store_code']
    },
    store_info(state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/common/store_info']
      
    },
    label_list(state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/common/label_list']
    },
    show_store(state, getters) {
      const storeStatus = getters.store_info.storeStatus
      const storeType = getters.store_info.storeType
      // 1: 平台店铺, 2: 自营店铺
      const showStore = !!([1, 2, 6].includes(+storeType) && storeStatus)
      return showStore
    },
    
    sign_data(state, getters, rootState, rootGetters) {
      const { show_store, brand_series_detail, store_detail } = getters
      const isStoreBusinessBrand = rootGetters['productDetail/common/is_store_business_brand']
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const hasStoreData = Object.keys(store_detail).length > 0
      const showStoreAndIsNotBrand = show_store && fs_abt?.BrandCollectionAdjust?.p?.BrandCollectionAdjust !== 'old'
      const hasBrandSeriesDetailData = Object.keys(brand_series_detail).length > 0
      const goods_img = rootGetters['productDetail/common/goods_img']
      const goods_thumb = rootGetters['productDetail/common/goods_thumb']
      const goods_id = rootGetters['productDetail/common/goods_id']
      const cat_id = rootGetters['productDetail/common/cat_id']
      const goods_detail = {
        goods_id,
        cat_id,
        goods_img,
        goods_thumb
      }

      if (hasStoreData && (isStoreBusinessBrand || showStoreAndIsNotBrand)) {
        // 显示店铺类型的信息 否则显示品牌或系列信息
        // 如果是品牌集成店铺 这里应该是展示 店铺信息
        return { ...store_detail, type: 'store', ...goods_detail }
      } else if (hasBrandSeriesDetailData) {
        return { ...brand_series_detail, ...goods_detail }
      } else {
        return hasStoreData ? { ...store_detail, ...goods_detail } : void 0
      }
    },
    has_brand_series_detail_data(state, getters) {
      const { brand_series_detail } = getters || {}
      return brand_series_detail && Object.keys(brand_series_detail).length > 0
    },
    sign_board_style(state, getters, rootState, rootGetters) {
      const { is_quality_store, is_in_fashion_store } = getters

      const fs_abt = rootGetters['productDetail/common/fs_abt']
      // 品牌集成店
      const isStoreBusinessBrand = rootGetters['productDetail/common/is_store_business_brand']
      // 品质店铺
      const isStoreQualityTag = is_quality_store
      // 时尚/趋势店铺
      const isInFashionStore = is_in_fashion_store
      const isGoodUp = isStoreBusinessBrand || isStoreQualityTag || isInFashionStore
        
      let position = 'p2'
        
      const storeup = fs_abt.storeup?.param?.storeup || ''
      if (storeup === 'allup') position = 'p1'
      if ((storeup === 'goodup') && isGoodUp) position = 'p1'
      if (storeup === 'noup') position = 'p2'

      return {
        position, // p1 位置不动 p2 评论下方
        style: 'A2'
      }
    },
    show_short_brand_enter(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const storeshort = fs_abt.storeup?.param?.storeshort === 'short'

      return storeshort
    },
    store_price_in(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const priceIn = fs_abt.storeup?.param?.storeprice === 'in'
      return priceIn
    },
    sign_config(state, getters, rootState, rootGetters) {
      const isStoreBusinessBrand = rootGetters['productDetail/common/is_store_business_brand']
      const signBoardStyle = getters.sign_board_style
      return {
        showStoreBrand: isStoreBusinessBrand,
        position: signBoardStyle.position,
        style: signBoardStyle.style,
        abtest: abtUserAnalysis({ posKeys: 'Storegoodspicture' }).sa
      }
    },
    store_recommend_info(state, getters, rootState, rootGetters) {
      const signData = getters.sign_data
      const storeBrandSeriesRecommendGroup = rootGetters['productDetail/common/store_brand_series_recommend_group']
      if (!storeBrandSeriesRecommendGroup.loaded) return {}
  
      const isStore = signData?.type === 'store'
      return (isStore ? storeBrandSeriesRecommendGroup.store : storeBrandSeriesRecommendGroup.brandSeries) || {}
    },
    // show_store_new_arrival(state, getters, rootState, rootGetters){
    //   const showStoreNewArrival = rootGetters['newProductDetail/common/showStoreNewArrival']
    //   return showStoreNewArrival
    // },
    store_detail(state, getters) {
      const { store_code, store_info } = getters
      const hasStoreData = Object.keys(store_info || {}).length > 0
      const { title, logo, productsNum, descriptions } = store_info
      if(!hasStoreData){
        return store_info || {}
      }
      return Object.assign({}, store_info, {
        id: store_code,
        uid: store_code,
        type: 'store',
        logo: transformImg({ img: logo }),
        name: title || '',
        totals: productsNum,
        desc: descriptions, // 门店和品牌系列描述字段保持统一
        link: `/store/home?store_code=${store_code}`,
      })
    },
    
    series_map_info(state, getters, rootState, rootGetters) {
      const seriesDetailInfo = rootState.productDetail.coldModules.seriesDetailInfo || {}
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const {
        id,
        series_name,
        image_url,
        series_description,
        designerDetailUrl,
        designer_id,
        productRecommendByGroup,
        secondSeriesId
      } = seriesDetailInfo
      const { sc_url: series_sc_url, sc_url_id: series_sc_url_id } =
        seriesDetailInfo?.relate_select?.[0] || {}
      const link = designerDetailUrl ? designerDetailUrl : series_sc_url
      let newSeriesDetail = null
      if (image_url && series_name && series_description && link) {
        // 系列接口有返回次级系列组ID，且是否按次级推荐=是，则按系列推荐
        const CollectionGoodsRecIsNew = fs_abt.CollectionGoodsRec?.param?.CollectionGoodsRec === 'new'
        const isSeriesRecommend =
        secondSeriesId &&
            productRecommendByGroup
        const seriesGroupId = isSeriesRecommend && CollectionGoodsRecIsNew
          ? secondSeriesId
          : ''
        const designerId = designerDetailUrl && designer_id
        newSeriesDetail = {
          id,
          uid: '',
          series_group_id: seriesGroupId,
          type: 'series',
          title: language_v2.SHEIN_KEY_PC_20343,
          logo: image_url ? transformImg({ img: image_url }) : '',
          name: series_name,
          desc: series_description,
          desc_bak: language_v2.SHEIN_KEY_PC_17600,
          scId: series_sc_url_id,
          designerId,
          link,
          isSeriesRecommend
        }
      }
      return newSeriesDetail
    },
    brand_map_info(state, getters, rootState, rootGetters) {
      const isStoreBusinessBrand = rootGetters['productDetail/common/is_store_business_brand']
      const brandDetailInfo = rootState.productDetail.coldModules.brandDetailInfo
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const { brand_logo, brand_code, name, brand_introduction, brand_select_info, brandId } =
        brandDetailInfo || {}
      const { sc_url: brand_sc_url, sc_url_id: brand_sc_url_id } = brand_select_info || {}
      if (brand_logo || (isStoreBusinessBrand && name)) {
        return {
          id: brand_code,
          uid: brandId || '',
          type: 'brand',
          logo: brand_logo ? transformImg({ img: brand_logo }) : '',
          originLogo: brand_logo,
          name,
          desc: brand_introduction,
          desc_bak: language_v2.SHEIN_KEY_PWA_17426,
          link: brand_sc_url,
          scId: brand_sc_url_id,
          logoTips: isStoreBusinessBrand,
          brand_code,
        }
      }
      return null
    },
    // 品牌系列数据（合并为一 有优先级）
    brand_series_detail(state, getters, rootState, rootGetters) {
      const { show_store, series_map_info, brand_map_info } = getters
      const store_brand_series_recommend_group = rootGetters['productDetail/common/store_brand_series_recommend_group']
      const isSeriesRecommend = !getters.series_map_info?.isSeriesRecommend
      let brandSeriesDetail = brand_map_info || series_map_info
      if (!brandSeriesDetail) return {}
      if (show_store && isSeriesRecommend) {
      // 有店铺信息时显示相关品牌与系列
        return brandSeriesDetail
      }
      if (store_brand_series_recommend_group?.brandSeries) {
        return store_brand_series_recommend_group.brandSeries.listType === 'brand' ? brand_map_info : series_map_info
      }
      return brandSeriesDetail
    },
    
    // 品牌集成店独立小入口
    store_brand_entry_box(state, getters) {
      return getters.brand_map_info || {}
    },

    // 判断是否展示独立品牌-老样式（位于description模块上方）
    show_store_and_brand(state, getters, rootState, rootGetters) {
      const isStoreBusinessBrand = rootGetters['productDetail/common/is_store_business_brand']
      const { show_store, brand_map_info } = getters
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      // 普通店铺，并且BrandCollectionAdjust命中new需要展示
      let isStore = fs_abt?.BrandCollectionAdjust?.p?.BrandCollectionAdjust === 'new' &&
      show_store && !isStoreBusinessBrand
      return brand_map_info && isStore
     
    },
    // 新品牌集成店独立样式
    is_show_new_store_and_brand(state, getters, rootState, rootGetters) {
      const isStoreBusinessBrand = rootGetters['productDetail/common/is_store_business_brand']
      const { brand_map_info, is_brand_has_target_tag } = getters
      /** 只有命中tag或品牌集成店 */
      return brand_map_info && (is_brand_has_target_tag || isStoreBusinessBrand)
    },

    // 展示品牌馆入口
    show_brands_zone() {

    },
    // 店铺开始
    shop_real_time(state, getters, rootState) {
      return rootState.productDetail.hotModules.storeInfo
    },
    shop_rank(state, getters) {
      return getters.shop_real_time?.shopRank
    },
    sign_board_text(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      return {
        '3PSellerLang': language_v2.SHEIN_KEY_PWA_23428,
        'localSellerLang': language_v2.SHEIN_KEY_PWA_23139,
        'marketPlaceLang': language_v2.SHEIN_KEY_PWA_23140,
        'followLang': language_v2.SHEIN_KEY_PWA_24068,
        'followIngLang': language_v2.SHEIN_KEY_PWA_24072,
        'authenticLang': language_v2.SHEIN_KEY_PWA_26778,
        'productsLang': language_v2.SHEIN_KEY_PWA_20745,
        'ratingLang': language_v2.SHEIN_KEY_PWA_20750,
        'zoneLang': language_v2.SHEIN_KEY_PWA_27388,
        'newBrandsLang': language_v2.SHEIN_KEY_PWA_27389,
        'flashSaleLang': language_v2.SHEIN_KEY_PWA_30008,
        'newLang': language_v2.SHEIN_KEY_PWA_30036,
        'storeFlashLang': language_v2.SHEIN_KEY_PWA_31988,
        'allItemLang': language_v2.SHEIN_KEY_PWA_26175,
        'preferredLang': language_v2.SHEIN_KEY_PWA_20758,
        'brandLang': language_v2.SHEIN_KEY_PWA_24180,
        'collectionLang': language_v2.SHEIN_KEY_PWA_24318,
        'followesLang': language_v2.SHEIN_KEY_PWA_24069,
        'followTitleLang': language_v2.SHEIN_KEY_PWA_24070,
        'followDescriptionLang': language_v2.SHEIN_KEY_PWA_24071,
        'followingLang': language_v2.SHEIN_KEY_PWA_24072,
        'meLang': language_v2.SHEIN_KEY_PWA_16036,
        'okLang': language_v2.SHEIN_KEY_PWA_24059,
        'followLimitFailLang': language_v2.SHEIN_KEY_PWA_24077,
        'followLimitLang': language_v2.SHEIN_KEY_PWA_24076,
        'unFollowFailLang': language_v2.SHEIN_KEY_PWA_24075,
        'barndLang': language_v2.SHEIN_KEY_PWA_24319,
        'trendsLang': language_v2.SHEIN_KEY_PWA_30166,
      }
    },
    // 需要展示100%正品标签
    is_brand_has_target_tag(state, getters, rootState) {
      const brandDetailInfo = rootState.productDetail.coldModules.brandDetailInfo
      return brandDetailInfo?.authenticBrand == 1
    },
    is_in_fashion_store(state, getters) {
      return getters.store_info.isFashionLabel == 1 || getters.show_temporary_fashion_store
    },
    // 临时时尚店铺（展示时尚店铺相关资源位）
    show_temporary_fashion_store(state, getters, rootState, rootGetters){
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      return getters.store_info.temporaryFashionLabel == 1 && fs_abt?.TemporaryTrendStore?.p?.TemporaryTrendStore === 'B'
    },
    // 品质店铺
    is_quality_store(state, getters) {
      return getters.store_info.showChoiceStoreLabel == 1
    },
    // 品质店铺图标
    in_quality_store_img(state, getters) {
      const choiceStoreIcon = getters.store_info?.choiceStoreIcon || {}
      const imgLink = choiceStoreIcon?.svgImgUrl || 'https://shein.ltwebstatic.com/svgicons/2024/07/09/17205135461310578037.svg'
      return imgLink
    },
    in_fashion_store_img(state, getters) {
      return getters.store_info.inFashionLabel?.trendIpImg?.imgUrl
    },
    in_brand_store_img(state, getters, rootState) {
      const { GB_cssRight } = rootState.productDetail.coldModules.commonPageInfo || {}
      return GB_cssRight ? 
        'https://img.ltwebstatic.com/images3_ccc/2024/12/17/0d/173441710770e8501d0890d61e15efbe50c1aae917.png' : 
        'https://img.ltwebstatic.com/images3_ccc/2024/12/17/0d/17344171074a9da6bb0b1602c52697574767888eb6.png'
    },
    store_atmosphere_info(state, getters, rootState, rootGetters) {
      // 降级措施: bff的get_goods_detail_realtime_data接口请求取不到数据时，或降级未调用bff接口时。
      // 则数据默认为空数组。店铺人气氛围功能就不展示了。
      const store_atmosphere_info = rootGetters['productDetail/common/store_atmosphere_info']
      return store_atmosphere_info || []
    },
    is_show_store_atmosphere(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const { show_store, is_quality_store, is_in_fashion_store } = getters
      // 品牌集成店
      const isStoreBusinessBrand = rootGetters['productDetail/common/is_store_business_brand']
      // 普通店铺
      const showStore = show_store
      // 品质店铺
      const isStoreQualityTag = is_quality_store
      // 时尚/趋势店铺
      const isInFashionStore = is_in_fashion_store

      const storehotnews = fs_abt?.storehotnews?.p?.storehotnews
      const hotnewslimited = fs_abt?.storehotnews?.p?.hotnewslimited 
      // 所有店铺都展示
      const allShow = hotnewslimited === 'all' && showStore
      // 仅时尚/品牌/品质店铺展示
      const limitShow = hotnewslimited === 'limited' && (isStoreBusinessBrand || isStoreQualityTag || isInFashionStore)
      
      return !!(storehotnews && storehotnews !== 'none' && ( allShow || limitShow ) && getters.store_atmosphere_info.length)
    },
    storehotnews_type(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      return getters.is_show_store_atmosphere ? fs_abt?.storehotnews?.param?.storehotnews : ''
    },
    // 品牌实时信息
    brand_real_time(state, getters, rootState) {
      return rootState.productDetail.hotModules.brandDetailInfo
    },
    brand_zone_link(state, getters) {
      return getters?.brand_real_time?.brandZoneCccResp || {}
    },
    brand_zone_status(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const { brand_zone_link } = getters
      return fs_abt?.brandzone?.p?.brandzone === 'show' && brand_zone_link?.content?.hrefType
    },
    store_follow_info(state, getters) {
      const { store_follow_info_state } = state
      
      if (!store_follow_info_state?.show || !getters.store_code ) return {}
      let salesVolume = getters.store_info.productsNum
      return {
        ...store_follow_info_state,
        salesVolume,
      }
    },
    // 店铺闪购信息
    store_flash_sale_info(state, getters, rootState){
      return {
        countdownToTheStore: +(rootState.productDetail.asyncModules.countdownToTheStore || ''),
        whetherEligibleForTheStore: rootState.productDetail.asyncModules.whetherEligibleForTheStore || ''
      }
    },
    // 是否展示店铺的闪购活动入口
    is_show_flash_entry(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || {}
      return fs_abt?.storeflash?.param?.storeflash !== 'none' && getters.store_flash_sale_info.whetherEligibleForTheStore == 1
    },
    flash_entry_type(state, getters, rootState, rootGetters){
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || {}
      return (getters.is_show_flash_entry && fs_abt?.storeflash?.param?.storeflash) || ''
    },
    flash_entry_countdown(state, getters, rootState, rootGetters){
      const timeStamp = getters.store_flash_sale_info.countdownToTheStore
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || {}
      const nowStamp = new Date().getTime()
      const endTimestamp = timeStamp * 1000
      const isEndTimeIn24 = endTimestamp - nowStamp > 0 && endTimestamp - nowStamp < 60 * 60 * 24 * 1000
      // 展示得下但abt【FlashSaleCountDown=Hide】命中倒计时不展示，或倒计时大于24h，则右侧均不展示倒计时
      if(fs_abt?.FlashSaleCountDown?.p?.FlashSaleCountDown !== 'Show' || !isEndTimeIn24) {
        return 0
      }
      return endTimestamp - nowStamp
    },
    // 是否展示品类聚合推荐
    is_show_store_category(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || {}
      if (fs_abt?.storecate?.param?.storecate !== 'show' || !getters.is_in_fashion_store)  return false
      return rootState.newProductDetail.Signboard?.storeCategoriesSale?.length > 0 && rootState.newProductDetail.Signboard?.storeCategoryRecommend?.length > 4
    },
    // 店铺&品牌&系列商品推荐
    shop_rec_products(state, getters, rootState) {
      return rootState.productDetail.asyncModules.shopRecProducts || []
    },
    // 优秀店铺卖点信息
    store_quality_list(state, getters, rootState) {
      const { productLabel } = rootState.productDetail.hotModules || {}
      return productLabel?.storeQualityList || []
    },
    store_quality_label(state, getters) {
      const tagTypeStr = (getters.store_quality_list || []).map(item => item.tagType).join(',')
      return tagTypeStr || '0'
    },
    is_show_store_quality_list(state, getters) {
      const { store_quality_list } = getters
      return !!store_quality_list.length
    },
    hasUnFollowDialog(state, getters, rootState, rootGetters){
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || {}
      return fs_abt?.dontunfollow?.param?.dontunfollow === 'B'
    },
    unFollowDialogLang(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      return {
        'SHEIN_KEY_PWA_35339': language_v2.SHEIN_KEY_PWA_35339 || 'Are you sure you want to unfollow?',
        'SHEIN_KEY_PWA_35338': language_v2.SHEIN_KEY_PWA_35338 || 'After unfollowing, you will no longer receive these notifications:',
        'SHEIN_KEY_PWA_35341': language_v2.SHEIN_KEY_PWA_35341 || 'Promotions',
        'SHEIN_KEY_PWA_35342': language_v2.SHEIN_KEY_PWA_35342 || 'New Arrivals',
        'SHEIN_KEY_PWA_35340': language_v2.SHEIN_KEY_PWA_35340 || 'Unfollow',
        'SHEIN_KEY_PWA_35337': language_v2.SHEIN_KEY_PWA_35337 || 'Think again',
        'SHEIN_KEY_PWA_24074': language_v2.SHEIN_KEY_PWA_24074 || 'Unfollow Success',
      }
    },
    /**
     * 针对固定标签的商品，展示该商品的品牌信息 wiki:pageId=1200449474
     */
    // is_brand_has_target_tag(state, getters, rootState, rootGetters) {
    //   const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']

    //   if (is_use_bff_fs) {
    //     const brandMapInfo = rootState.productDetail.coldModules.brandDetailInfo
    //     return brandMapInfo?.authenticBrand === '1'
    //   }
    //   /**
    //    * 本逻辑跟原来有单独品牌模块的逻辑不冲突，相当于是该标签商品就有单独的品牌模块，原来品牌集成店的商品走了某abt分支也有单独的品牌模块，互不相干
    //    */
    //   const TAG_ID = '600012606'
    //   return rootState.newProductDetail.coldModules?.tsp?.tspLabels?.tag?.includes?.(TAG_ID)
    // },
  },
  mutations: {
    update_store_follow_info(state, payload) {
      state.store_follow_info_state = payload
    }
  },
  actions: {
    // initStoreFollowInfo
    init_store_follow_info({ commit, getters }) {
      const { storeAttention, storeDaysSale, storeAttentionStatus } = getters.shop_real_time || {}

      commit('update_store_follow_info', {
        // abt命中才展示
        show: true,
        // 用户关注状态是否变更中
        followStateChanging: false,
        // 店铺关注数量
        followCount: storeAttention,
        // 店铺30日销量（tsp）
        salesVolume: storeDaysSale,
        // 用户关注状态，-1: 未登录，0: 未关注，1: 已关注
        userFollowState: Number(storeAttentionStatus),
        // 埋点信息
        abtAnaInfo: abtUserAnalysis({ posKeys: 'storewishlist' })?.sa,
      })
    }
  }
}
