import {
  ref,
  reactive,
} from 'vue'
import {
  CountDown,
  debounce,
} from '@shein/common-function'

const millSecCountDownTimer = ref(null)
const secondHundred = ref(0)
const timerMap = reactive(new Map())

const initMillSecCountDown = () => {
  millSecCountDownTimer.value = setInterval(() => {
    secondHundred.value--
    if (secondHundred.value < 0) secondHundred.value = 9
  }, 100);
  (window).__DETAIL_SECOND_HUNDRED__ = secondHundred;
  (window).__MILL_SEC_COUNT_DOWN_TIMER__ = millSecCountDownTimer
}

function getMillSecCountDownTimer() {
  if ((window)?.__MILL_SEC_COUNT_DOWN_TIMER__?.value) {
    return (window).__MILL_SEC_COUNT_DOWN_TIMER__?.value
  }
  return millSecCountDownTimer.value
}

function getSecondHundred() {
  return (window)?.__DETAIL_SECOND_HUNDRED__
}

/**
 * 获取倒计时map
 * @description 用于存储倒计时实例
 * @description 用于统一管理倒计时实例（包含主站），避免倒计时不统一
 */
function getTimerMap() {
  if ((window)?.__DETAIL_PRICE_TIMER_MAP__) {
    return (window)?.__DETAIL_PRICE_TIMER_MAP__
  }
  (window).__DETAIL_PRICE_TIMER_MAP__ = timerMap
  return timerMap
}

export function useMillSecCountDown() {
  if (!getMillSecCountDownTimer()) {
    initMillSecCountDown()
  }
  return {
    secondHundred: getSecondHundred(),
  }
}

const initTimer = (endTime, nowTime = new Date().getTime() / 1000) => {
  const timer = new CountDown()
  getTimerMap().set(endTime, timer)
  const beginTime = endTime * 1000 - nowTime * 1000
  if (beginTime <= 0) {
    beginTime == 0
  }
  // 初始化倒计时
  timer?.start({
    seconds: beginTime / 1000,
    endFunc: debounce({
      func: () => {
        location.reload()
      },
    }),
  })
}

export function useCountDown({ endTime, nowTime, isShowCountDownMillSec }) {
  if (getTimerMap()?.get(endTime) === undefined) {
    initTimer(endTime, nowTime)
  }
  if (isShowCountDownMillSec && !getMillSecCountDownTimer()) {
    initMillSecCountDown()
  }
  return {
    timeObj: getTimerMap()?.get(endTime)?.timeObj,
    secondHundred: isShowCountDownMillSec ? getSecondHundred() : null,
  }
}
