
import { ref, watch } from 'vue'
import { isPreferredSeller, cloudTagsFilterInner, handleCloudTagsByCccConfig, cloudTagsFilterByCccLanguage } from 'public/src/pages/components/FilterBar/utils/index.js'
import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'

// 标签云过滤 - 标签接入ccc配置
function cloudTagsFilterByCccConfig (cloudTags, listAbtResult) {
  return cloudTags.filter(item => {
    if (item.local_mall === 0) {
      return false
    }

    return cloudTagsFilterInner(item, listAbtResult.value)
  })
}

// 标签云过滤 - 非ccc配置
function cloudTagsFilter({ cloudTags, filterConfig, locals, listAbtResult }) {
  const isShowPromotionLabel = filterConfig.hasPicNavTop && !locals.value?.IS_RW // 是否展示促销标签【非门店搜索结果页 & shein下】

  return cloudTags.filter(item => {
    if (Number(item.local_mall) === 1 || item.quickship_tag) return true // 本地mall || qs
    if (isPreferredSeller(item.tag_id, listAbtResult.value)) return true // 优选卖家
    // 促销标签
    if (
      isShowPromotionLabel
      && filterConfig.promotion_label
      && Number(item.tag_type) === 100
    ) return true

    return false
  })
}


// 格式化标签云数据
function formatCloudTagList (list) {
  return list.map(item => ({
    ...item,
    tagItemType: TAG_ITEM_TYPE.CLOUD_TAG
  }))
}

export function useCloudTags ({ filterBar, listAbtResult, locals }) { 
  const list = ref([])

  watch(filterBar, () => {
    const { filterData = {}, filterConfig = {} } = filterBar.value || {}

    const cloudTags = filterData?.cloudTags || []

    // v.趋势搜索结果页屏蔽该功能
    const filteredCloudTags = filterConfig.isTopTrendResultPage ? cloudTags.filter(tag => tag.value !== '600037254') : cloudTags

    if (!filteredCloudTags.length) {
      list.value = []
      return
    }
    const { productTypeSortConfig = [] } = filterConfig
    const filterList = productTypeSortConfig.length
      ? handleCloudTagsByCccConfig({
        filterType: 'isExposed',
        cloudTags: cloudTagsFilterByCccConfig(filteredCloudTags, listAbtResult),
        productTypeSortConfig
      })
      : cloudTagsFilter({ cloudTags: filteredCloudTags, filterConfig, locals, listAbtResult })
    
    list.value = formatCloudTagList(cloudTagsFilterByCccLanguage(filterList))
  }, { immediate: true })

  return list
}

