<template>
  <div 
    class="multi-line__quarter-container"
    :style="[{ padding: '.2133rem .0533rem 0' }]"
  >
    <slot name="multiLineBg"></slot>
    <MultiLineHeader 
      :css-right="cssRight"
      :prop-data="propData"
      :is-first-page="isFirstPage"
      :index="index"
      :container-index="containerIndex"
      :cate-links="cateLinks"
      :products-list="[productItem]"
      :home-adp-num="homeAdpNum"
      :use-front-cate-id="useFrontCateId"
      :is-early-request0903="isEarlyRequest0903"
      :early-tmg-request1210="earlyTmgRequest1210"
      :scene-data="sceneData"
      :context="context"
      :analysis-code="analysisCode"
    />
    <template v-if="hasProducts">
      <LazyHydrationWrapper :when-visible="true">
        <component
          :is="currentComponent"
          :ref="`${templateType}-0.25-${containerIndex}`"
          :index="index"
          :context="context"
          :language="language"
          :prop-data="propData"
          :product-item-config="productItemConfig"
          :product-item="productItem"
          :products-list="[productItem]"
          :product-item-index="0"
          :container-index="containerIndex"
          :css-right="cssRight"
          :is-first-page="isFirstPage"
          :scene-data="sceneData"
          :cate-links="cateLinks"
          :home-discount-new="homeDiscountNew"
          :home-adp-num="homeAdpNum"
          :use-front-cate-id="useFrontCateId"
          :crop-rate="cropRate"
          :is-early-request0903="isEarlyRequest0903"
          :early-tmg-request1210="earlyTmgRequest1210"
          :products-num="1"
          :suggested-sale-type="suggestedSaleType"
          :analysis-code="analysisCode"
        />
      </LazyHydrationWrapper>
    </template>
    <PlaceholderItem
      v-else
      :num="1"
      :brand="brand"
      :is-small-item="true"
      :horizontal-item-width="76"
    />
  </div>
</template>

<script>
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import PlaceholderItem from 'public/src/pages/components/ccc/base/PlaceholderItem.vue'
import MultiLineHeader from '../components/Header.vue'
import Shop from '../components/Shop.vue'
import OtherTemplate from '../components/OtherTemplate.vue'
import { LazyHydrationWrapper } from '@shein-aidc/utils-lazy-hydration'
import { CompTemplateType } from '../constants.js'

export default {
  name: 'QuarterContainer',
  components: {
    MultiLineHeader,
    LazyHydrationWrapper,
    Shop,
    OtherTemplate,
    PlaceholderItem,
  },
  props: {
    ...commonProps,
    // 商品项配置
    productItemConfig: {
      type: Object,
      default: () => {}
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    isFirstPage: {
      type: Boolean,
      default: true,
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0,
    },
    // 置顶商品abt
    homeAdpNum: {
      type: Number,
      default: 0
    },
    // 是否使用频道所对应的cate_id
    useFrontCateId: {
      type: Boolean,
      default: false
    },
    cropRate: {
      type: String,
      default: '3-4'
    },
    // 不贵落地页早期tmg请求标志
    isEarlyRequest0903: {
      type: Boolean,
      default: false
    },
    // abt控制是否展示折扣标签，合规价
    homeDiscountNew: {
      type: String,
      default: ''
    },
    // 合规类型
    suggestedSaleType: {
      type: String,
      default: ''
    },
    // 趋势落地页参数判断
    earlyTmgRequest1210: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const {
      props: { metaData: { templateType } = {} } = {},
    } = this.propData || {}
    return {
      templateType,
    }
  },
  computed: {
    analysisCode() {
      return `multi_line_${this.index + 1}_${this.containerIndex + 1}_0.25_${this.templateType}`
    },
    hasProducts() {
      const products = this.propData?.props?.metaData?.products || []
      return products.length > 0
    },
    productItem() {
      let products = this.propData?.props?.metaData?.products || []
      return products?.length > 0 ? products[0] : {}
    },
    currentComponent() {
      if (this.templateType == CompTemplateType.shop) {
        return 'Shop'
      }
      return 'OtherTemplate'
    },
  },
}
</script>

<style lang="less">
.multi-line__quarter-container {
  width: 24.25%;
  background: #fff;
  /* 保证商品项占位的宽度与商品一致。避免与1/4同行显示占位是高低不同 */
  .product-item__horizontal {
    padding: 0;
    .product-item__horizontal-item {
      margin: 0;
      flex-grow: 1;
      border: none;
    }
  }
}
</style>
