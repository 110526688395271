<template>
  <div
    v-if="productPackageList.length > 0"
    v-expose="{
      id: '2-42-1',
      data: xtraOptionAnalysis
    }"
    class="checkout-xtra__wrap bff-xtra-wrap"
    :style="{
      ...xtraWrapBg,
      '--title-color': cardBaseStyle?.titleColor,
      '--title-place-color': cardBaseStyle?.titlePlaceColor,
      '--sub-title-color': cardBaseStyle?.subTitleColor,
      '--sub-title-place-color': cardBaseStyle?.subTitlePlaceColor,
      '--protocal-color': cardBaseStyle?.protocolColor,
    }"
  >
    <div class="checkout-xtra__logo_container">
      <img
        class="checkout-xtra__wrap-logo"
        :src="xtraLogoImg"
        alt=""
      />
    </div>


    <div
      class="checkout-xtra__wrap-body"
    >
      <div class="checkout-xtra__wrap-content">
        <p
          class="checkout-xtra__NewWrapDesTitle"
          :class="{'disabled': notSupport}"
          v-html="productTitle"
        >
        </p>
        <p
          v-if="showSubTitle"
          class="checkout-xtra__wrap-des"
          :class="{'disabled': notSupport}"
          v-html="productSubTitle"
        >
        </p>

        <!-- 入口券列表 -->
        <EntryCouponList
          class="checkout-xtra__wrap-list" 
          :light-effect="lightEffect"
          :couponListInfo="couponListInfo"
          :language="language"
          :entryCouponStyle="cardBaseStyle"
          :showNewStyle="currentProductPackage?.isNewCardCouponStyle == 1"
          @clickDetails="clickDetails"
        />
        <!-- 产品包选项 -->
        <ProductBagSelected
          ref="xtraRadioSelectedRef"
          :language="language"
          :productPackageList="productPackageList"
          :selectedCode="selectedCode"
          :notSupport="notSupport"

          @clickDiscountTips="clickDiscountTips"
          @changeBagEvent="changeBagEvent"
          @autoRenewdDrawerShow="autoRenewdDrawerShow"
        />
        <p
          class="checkout-xtra__wrap-policy"
          :class="{'disabled': notSupport}"
        >
          <span 
            @click.stop="clickPolicy" 
            v-html="policyTips"
          >
          </span>
          |
          <span
            v-if="policyDetail"
            class="auto-renewed-protocol"
            @click.stop="autoRenewalDialogVisible = true"
          >{{ policyDetail }}</span>
          <template v-else>
            <span 
              class="polict-detail" 
              @click.stop="clickDetails"
            >{{ language.SHEIN_KEY_PWA_25965 }}</span>
          </template>
        </p>

        <p
          v-if="notSupport"
          class="checkout-xtra__wrap-disabled"
        >
          <i class="suiiconfont sui_icon_caution_12px_2"></i>
          {{ disableText }}
        </p>
      </div>
    </div>

    <!-- 券弹窗 -->
    <SaverCouponDrawer 
      v-if="showXtraCoupon"
      :disabled="notSupport"
      :showXtraCoupon="showXtraCoupon"
      :productDrawerList="productDrawerList"
      :productPackageList="productPackageList"
      :currentCode="currentProductPackage?.saveCardProductCode"
      :language="language"
      :selectedCodeInEntry="selectedCode"
      @closeCouponDrawer="closeCouponDrawer"
      @clickDiscountTips="clickDiscountTips"
      @autoRenewdDrawerShow="autoRenewdDrawerShow"
      @changeBagEvent="changeBagEvent"
    />

    <!-- 自动续费协议弹窗 -->
    <AutoRenewedDialog
      v-if="autoRenewalDialogVisible"
      :visible="autoRenewalDialogVisible"
      :language="language"
      :protocolTips="protocolTips"
      @closeDialog="autoRenewalDialogVisible = false"
    />

    <!-- 合规法务弹窗 -->
    <DiscountTipDialog
      :visible="discountTipsVisible"
      :dialogData="currentDiscountTip"
      :language="language"
      @closeDialog="closeDialog"
    />

    <!-- 费用明细弹窗 -->
    <DiscountDetailDialog
      :visible="virtunalDiscountVisible"
      :dialogData="currentDiscountDetail"
      :logo="xtraLogoImg"
      :language="language"
      @showTip="showDiscountTip"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import EntryCouponList from './EntryCouponList.vue'
import ProductBagSelected from './ProductBagSelected.vue'
import SaverCouponDrawer from './SaverCouponDrawer.vue'
import { template, debounce } from '@shein/common-function'
import DiscountDetailDialog from './dialogs/DiscountDetailDialog.vue'
import DiscountTipDialog from './dialogs/DiscountTipDialog.vue'
import { saverDialogMap } from './shared/config.js'
import { transformProudctPackageStyle } from './shared/utils.js'
import { handleTotalPriceAnimate } from '../../shared/utils.js'


const { PUBLIC_CDN, GB_cssRight, langPath, currency, currencies, lang } = gbCommonInfo

daEventCenter.addSubscriber({ modulecode: '2-42' })

export default {
  name: 'SaverProducgPackage',
  components: {
    EntryCouponList,
    ProductBagSelected,
    SaverCouponDrawer,
    DiscountDetailDialog,
    DiscountTipDialog,
    AutoRenewedDialog: () => import(/* webpackChunkName: "checkout-xtra-autoRenewedDialog" */'./AutoRenewedDialog'),
  },
  props: {
    saveCardInfo: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    disableText: {
      type: String,
      default: ''
    },
    // 选中的产品包
    selectedCode: {
      type: String,
      default: ''
    },
    abt: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      autoRenewalDialogVisible: false,
      virtunalDiscountVisible: false,
      discountTipsVisible: false,
      showXtraCoupon: false,
      clickTipProductCode: '', // 点击选项框 tips 的 Code
      currentDiscountDetail: {}, // 优惠明细弹窗数据
      currentDiscountTip: '', // 法务弹窗数据
      lightEffect: false,
      hasSlide: false,
    }
  },
  computed: {
    // 新样式
    showNewStyle() {
      return this.abt?.saverOrderModule?.param?.saverordercouponstyle === 'highvalue'
    },
    showSlideEffect() {
      return this.abt?.saverOrderModule?.param?.saverorderminiaction === 'on'
    },
    notSupport() {
      return !!this.disableText
    },
    productPackageList() {
      // return this.saveCardInfo?.products || []
      // 兜底处理产品包逻辑，确保最多只存在两个产品包
      const products = this.saveCardInfo?.products || []
      return [
        products.find(item => item?.saveCardProductInfo.isAutoRenewProduct == '1') || null,
        products.find(item => item?.saveCardProductInfo.isAutoRenewProduct !== '1') || null
      ].filter(Boolean)
    },
    defaultProductPackage() {
      if (this.productPackageList.length == 1) return this.productPackageList[0]?.saveCardProductInfo
      return this.productPackageList.find(item => item.saveCardProductInfo.isAutoRenewProduct !== '1')?.saveCardProductInfo
    },
    // 当未选中选项框，则取默认包
    currentProductPackage() {
      return this.selectedCode ? this.saveCardInfo?.products.find(item => item.isShowProduct == '1')?.saveCardProductInfo : this.defaultProductPackage
    },
    productDrawerList() {
      const drawerInfo = {}
      this.productPackageList.forEach(item => {
        drawerInfo[item.saveCardProductInfo.saveCardProductCode] = item
      })
      return drawerInfo
    },
    xtraWrapBg() {
      const styleConfig = this.cardBaseStyle
      if (styleConfig?.cardBgImg) {
        return {
          background: `url(${this.cardBaseStyle.cardBgImg}) 0% 0% / 100% 100% no-repeat`,
        }
      }
      return !GB_cssRight ? {
        background: `url(${PUBLIC_CDN}/pwa_dist/images/checkout/sui_img_sheinsaver_blurbg-59b498594b.png) right bottom no-repeat #fff`,
        backgroundSize: '96px auto'
      } : {
        background: `url(${PUBLIC_CDN}/pwa_dist/images/checkout/sui_img_sheinsaver_blurbg_ar-044e8f6c1c.png) left bottom no-repeat #fff`,
        backgroundSize: '96px auto'
      }
    },
    xtraLogoImg() {
      return this.currentProductPackage?.logo
    },
    productTitle() {
      const componentTitle = this.currentProductPackage?.saveCardTip?.componentTitle
      const tip = componentTitle?.tip
      let fillInfos = componentTitle?.fillInfos
      fillInfos = fillInfos?.map(v => `<em class="${this.showSlideEffect ? 'saver-title-animation' : ''}">${v}</em>`)
      const res = fillInfos?.length ? `${template(...fillInfos, `${tip}`)}` : `${tip}`
      return res
    },
    productSubTitle() {
      const componentOpenGuide = this.currentProductPackage?.saveCardTip?.componentOpenGuide
      const couponNum = componentOpenGuide?.couponNum
      const tip = componentOpenGuide?.tip
      const validDate = componentOpenGuide?.validDate
      
      return validDate ?
      `${template(`<em>${couponNum}</em>`, `<em>${validDate}</em>`, `${tip}`)}` : 
      `${template(`<em>${couponNum}</em>`, `${tip}`)}`
    },
    showSubTitle() {
      // 若组件未配置，则展示
      if (this.cardBaseStyle?.showSubTitle == null) return true
      // 若组件配置开关打开，且勾选，则展示
      return this.cardBaseStyle?.showSubTitle
    },
    cardBaseStyle() {
      return transformProudctPackageStyle(this.currentProductPackage?.style_info)
    },
    policyTips() {
      const componentProtocol = this.currentProductPackage?.saveCardTip?.componentProtocol
      
      const holderLink = componentProtocol?.holderLink
      const holderText = componentProtocol?.holderText
      const tip = componentProtocol?.tip

      const url = `${langPath}${holderLink}`

      const linker = this.notSupport ? 
      `<a style='color: #2D68A8;text-decoration: none; pointer-events:none;' href=\"${url}\" target=\"_blank\">${holderText}</a>` :
      `<a style='color: #2D68A8;text-decoration: none;' href=\"${url}\" target=\"_blank\">${holderText}</a>`

      return `${template(`<em>${linker}</em>`, `${tip}`)}`
    },
    policyDetail(){
      const componentProtocol = this.currentProductPackage?.saveCardTip?.componentProtocol
      return componentProtocol?.extendTip
    },
    protocolTips() {
      return this.currentProductPackage?.saveCardTip?.protocolTips
    },
    xtraOptionAnalysis() {
      const product = this.currentProductPackage
      return { original_price: product?.priceLocalWithSymbol || '-', special_price: product?.specialPriceWithSymbol || '-', select_flag: product?.isShowProduct }
    },
    couponListInfo() {
      return this.currentProductPackage?.saveCardCouponList || []
    },
  },
  
  watch: {
    couponListInfo: {
      handler: debounce({
        func: function(nVal) {
          if (!Array.isArray(nVal)) return
          // 是否存在超省卡勾选券
          const saverCouponUse = nVal.find(coupon => coupon.selected)
          this.$nextTick(() => {
            if (!this.selectedCode || !this.showSlideEffect) return
            if (saverCouponUse && this.productTitle?.includes('saver-title-animation')) {
              this.startPriceRolling()
            }
          })
        },
        wait: 500
      }),
      deep: true,
    },
    saveCardInfo: {
      handler: debounce({
        func: function(n, o) {
          if (!this.selectedCode || !this.showSlideEffect || this.hasSlide) return
          this.$nextTick(() => {
            setTimeout(() => {
              setTimeout(() => {
                this.lightEffect = true
                this.hasSlide = true
              }, 400)
              
              setTimeout(() => {
                this.lightEffect = false
              }, 1200)
            }, 500)
          })
        },
        wait: 500
      }),
      deep: true,
    },
  },
  methods: {
    startPriceRolling() {
      this.$nextTick(() => {
        const regx = /\D*([\d.,]+)/
        const els = document.getElementsByClassName('saver-title-animation')
        const el = Array.from(els)?.filter(el => el.innerText?.match(regx)[1] != el.innerText)?.[0] // 则判断存在货币符号，为金额数据
        const priceWithSymbol = el?.innerText
        const price = el?.innerText?.match(regx)[1]
        const currencyRule = window.getCurrencyRule()

        if (!currencyRule) return

        const amount = price
          .replaceAll(currencyRule.thousands_sep_symbol, '')
          .replace(currencyRule.dec_point_symbol, '.')

        handleTotalPriceAnimate(
          {
            amount,
            amountWithSymbol: priceWithSymbol
          },
          { amount: '0.00' },
          newTotalPrice => {
            if (el) {
              el.textContent = newTotalPrice?.amountWithSymbol
            }
          },
          currencies,
          currency,
          lang
        )
      })
    },
    getTipsDialogData(productCode) {
      return this.productPackageList.find(item => item.saveCardProductInfo?.saveCardProductCode == productCode)?.saveCardProductInfo?.reducePriceLabel
    },
    clickDetails() {

      daEventCenter.triggerNotice({
        daId: '2-42-3',
        extraData: {
          original_price: this.xtraOptionAnalysis.original_price,
          special_price: this.xtraOptionAnalysis.special_price
        }
      })
      this.showXtraCoupon = true

      daEventCenter.triggerNotice({
        daId: '2-42-4',
        extraData: {
          original_price: this.xtraOptionAnalysis.original_price,
          special_price: this.xtraOptionAnalysis.special_price
        }
      })
    },
    radioSelectClickDisountCb(e, productCode){
      this.clickTipProductCode = productCode
      this.currentDiscountDetail = this.getTipsDialogData(productCode)
      
      this.clickDiscountTips(e, productCode)
    },
    showDiscountTip(tip) {      
      this.discountTipsVisible = true
      this.currentDiscountTip = tip
    },
    clickDiscountTips(e, productCode) {
      this.clickTipProductCode = productCode
      this.currentDiscountDetail = this.getTipsDialogData(productCode)
      const action = this.currentDiscountDetail?.action || ''      
      
      // 去券列表
      if(action == 'couponList'){
        // 券列表埋点

        // 前往券列表
        e.stopPropagation()
        return this.$emit('goCouponPage')
      }

      e.stopPropagation()
      this[saverDialogMap[action]] = true
    },
    closeDialog(type) {
      this[saverDialogMap[type]] = false
    },
    closeCouponDrawer(visible) {
      this.showXtraCoupon = visible
    },
    clickPolicy(e) {
      if(this.notSupport) e.preventDefault()

      if(e.target?.tagName == 'A') {
        daEventCenter.triggerNotice({
          daId: '2-42-6',
          extraData: {
            scene: 'entrance'
          }
        })
      }
    },
    autoRenewdDrawerShow(productCode) {
      this.$emit('autoRenewdDrawerShow', productCode)
    },
    changeBagEvent(productCode) {
      if (this.notSupport) return
      this.$emit('changeBagEvent', productCode)
      
      const product = this.productPackageList.find(item => item.saveCardProductInfo?.saveCardProductCode == productCode)?.saveCardProductInfo

      daEventCenter.triggerNotice({
        daId: '2-42-2',
        extraData: {
          original_price: product?.priceLocalWithSymbol || '-', special_price: product?.specialPriceWithSymbol || '-', select_flag: product?.isShowProduct
        }
      })
    },
    updateFailed()  {
      this.selectedCode = ''
      this.$emit('updateFailed')
    }
  }
}
</script>

<style lang="less" scoped>
.header-bg-container{
  position: absolute;
  top: .85rem;
  // left: 19%;
  font-size: 16px;
  text-align: center;
  width: 100%;
  font-weight: 600;
  .header-num{
    font-size: 24px;
    font-weight: 600;
  }
}
.pickColor_xtra{
  color: #F82854
}
.text-xtra_discount{
  line-height: 1.5;
  font-size: 24px;
}
.checkout-xtra__wrap {
  margin-top: 16/75rem;
  background-color: var(--background-color, #fff);

  .checkout-xtra__wrap-logo {
    height: 36/75rem;
    margin-left: 24/75rem;
  }

  .checkout-xtra__logo_container {
    width: 100%;
    height: 52/75rem;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, rgba(249, 38, 84, 0.11) 0%, rgba(249, 38, 84, 0.00) 61.2%);
  }

  .checkout-xtra__wrap-body {
    display: flex;
    align-content: space-between;
    align-items: flex-start;
    padding: 16/75rem 24/75rem;
    position: relative;
  }

  .checkout-xtra__wrap-content {
    width: 100%;
    min-width: 0;
  }

  .checkout-xtra__wrap-des {
    // margin-top: 8/75rem;
    .font-dpr(24px);
    color: var(--sub-title-color, #999999);
    /deep/ em {
      color: var(--sub-title-place-color, #F82854);
    }
  }
  .checkout-xtra__NewWrapDesTitle{
    font-family: "SF Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--title-color, #000);
    /deep/ em {
      font-weight: 860;
      color: var(--title-place-color, #F82854);
    }
  }
  .checkout-xtra__NewWrapDes{
    color: var(---sui_color_gray_dark3, #767676);
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /deep/ em {
      color: #F82854;
    }
  }
  .xtra-hearder{
    font-family: "SF Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 590;
    line-height: normal;
    /deep/ em {
      color: #F82854;
      font-weight: 860;
    }
  }

  .checkout-xtra__wrap-list {
    margin-top: 6/75rem;
  }

  .checkout-xtra__wrap-coupon {
    margin-top: 8/75rem;
  }

  .checkout-xtra__wrap-policy {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    margin-top: 16/75rem;
    .font-dpr(20px);
    color: var(--protocal-color, @sui_color_gray_light1);

    .polict-detail {
      color: @sui_color_link;
    }
    .auto-renewed-protocol {
      color: #2D68A8;
      cursor: pointer;
    }
  }

  .checkout-xtra__wrap-disabled {
    .font-dpr(24px);
    color: @sui_color_unusual;
    i {
      .margin-r(8/75rem);
    }
    margin-top: 16/75rem;
  }

  .disabled {
    opacity: 0.3;
  }
}
.virXrt_container{
  margin-bottom: 0.7rem;
  margin-top: -0.8rem;
  font-size: 14px;
  .virXrt_line{
    display: flex;
    justify-content: space-between;
    margin-top: 10/70rem;
  }
  .virXrt-line_left{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.virXrt-lineLeft_tipsXtra{
  margin-right: 5/70rem;
}
</style>
