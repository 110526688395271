import HolidayDeliveryTag from './holiday-delivery-tag.vue'
import HolidayDeliveryTagV2 from './holiday-delivery-tag-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useHolidayDeliveryTag = createHelper('HolidayDeliveryTag', getProps)

export {
  HolidayDeliveryTag,
  HolidayDeliveryTagV2,
  useHolidayDeliveryTag,
}
