
import schttp from 'public/src/services/schttp'
import dayjs from 'dayjs'
import { getQueryString } from '@shein/common-function'
import { selectionAndEntityFilterApiCache, selectionAndEntityCloudTagApiCache, selectionAndEntityPicNavApiCache, categoryDayTagApiCache } from 'public/src/pre_requests/modules/productList/api/bffApi'
import { getCommonParams } from 'public/src/pre_requests/modules/productList/bffParamsHandle/common'
import { cleanNullValueObjectFirstLevel } from 'public/src/pre_requests/modules/productList/bffParamsHandle/utils'
import { generateRealSelectBffFilterInfo } from 'public/src/pre_requests/modules/productList/utils'

const prefix = '/api/trend'
/**
 * 获取首屏数据
 */
export const getPageData = (params, loadingConfig = {}) => {
  const { contentCarrierId_adp = '', cccCatId = '', time = '' } = params
  return schttp({
    method: 'GET',
    url: `${prefix}/pageData`,
    params: {
      contentCarrierId_adp,
      cccCatId,
      time,
    },
    timeout: 10000,
    schttp: {
      loadingConfig,
    }
  })
}

/**
 * 获取词落地页首屏幕数据
 */
export const getLandingPageData = (params, loadingConfig = {}) => {
  const { trendId = '', selectId = '', selectUrlId = '', adp = '', cccCatId = '', catId = '', channelId = '', time = '' } = params
  return schttp({
    method: 'GET',
    url: `${prefix}/landingPageData`,
    params: {
      trendId, 
      selectId, 
      selectUrlId,
      adp,
      cccCatId,
      catId,
      channelId,
      time,
    },
    timeout: 10000,
    schttp: {
      loadingConfig,
    }
  })
}

/**
 * 获取搭配页首屏数据
 */
export const getOutfitsPageData = (params, loadingConfig = {}) => {
  const adp = getQueryString({ key: 'adp' }) || ''
  return schttp({
    method: 'GET',
    url: `${prefix}/outfitsPageData`,
    params: {
      adp,
      ...params,
    },
    timeout: 10000,
    schttp: {
      loadingConfig,
    }
  })
}

/**
 * 获取搜索底纹词
 */
export const getSearchKeyWords = (params, loadingConfig = {}) => {
  return schttp({
    method: 'POST',
    url: '/product/search/v3/get_keywords',
    useBffApi: true,
    params,
    timeout: 10000,
    schttp: {
      loadingConfig,
    }
  })
}

/**
 * 获取趋势词内容体
 */
export const getTrendingWrodRecommend = (params, loadingConfig = {}) => {
  return schttp({
    method: 'GET',
    url: `/product/trending_channel/trending_word_recommend`,
    useBffApi: true,
    params,
    timeout: 10000,
    schttp: {
      loadingConfig,
    }
  })
}

/**
 * 获取店铺内容体数据
 */
export const getStoreContentRecommend = (params, loadingConfig = {}) => {
  return schttp({
    method: 'GET',
    url: `/recommend/trending_channel/store_content_recommend`,
    useBffApi: true,
    params,
    timeout: 10000,
    schttp: {
      loadingConfig,
    }
  })
}

/**
 * 获取词落地页筛选数据
 */
export const getLandingPageFilterData = (params, loadingConfig = {}) => {
  return schttp({
    method: 'POST',
    url: `${prefix}/landing/filterData`,
    params,
    timeout: 10000,
    schttp: {
      loadingConfig,
    }
  })
}



const getPicNavParams = (query) => {
  const {
    filterMallCode,
    urlMallCode,
  } = getCommonParams(query)

  let params = {
    cate_id: query?.selectUrlId || '',
    cate_type: '1',
    scene: 'trendChannel',
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    _type: 'selection',
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 筛选相关
const getFilterParams = (query) => {
  const {
    quickship,
    filter,
    catId,
    brandId,
    date,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    filterMallCode,
    urlMallCode,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    // lastParentCatId,
    chooseIds,
    filterBrandIds,
  } = getCommonParams(query)

  let params = {
    select_id: query?.selectUrlId || '',
    brand_id: brandId,
    date,
    quickship,
    filter,
    filterBrandIds,
    cat_ids: query.cat_ids,
    cat_id: catId,
    last_parent_cat_id: query.last_parent_cat_id,
    choosed_ids: chooseIds,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    sort: query.sort || '',
    styleType: query.styleType || '',
    showAllCategory: query.showAllCategory || '',
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
    _type: 'selection'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云相关
const getCloudTagParams = (query) => {
  const {
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    urlMallCode,
    filterMallCode,
    default_child_id,
    default_child_sex,
    default_child_birthday,
  } = getCommonParams(query)

  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag || ''

  let choosed_tag = ''
  if (isQuickShip) {
    choosed_tag = 'quickship'
  } else if (!isMallTag) {
    choosed_tag = tagIds
  }

  let params = {
    select_id: query?.selectUrlId || '',
    filter,
    default_child_id,
    default_child_sex,
    default_child_birthday,
    cat_ids: query.cat_ids,
    child_cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    choosed_tag,
    choosed_mall_code: filterMallCode,
    mall_code_list: urlMallCode,
    sort: query.sort || '',
    page: query.page || 1,
    limit: query.limit || 10,
    kidRandom: query.kid_random || '', // 避免缓存
    styleType: query.styleType || '',
    from_store: Number(query?.pageType === 'store'),
    _type: 'selection'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云中的date标签数据
const getCategoryDayTagParams = (query) => {
  const {
    quickship,
    filter,
    catId,
    choosedNavId,
    choosedNavType,
    filterTagIds,
    maxPrice,
    minPrice,
    filterMallCode,
    urlMallCode,
    tagIds
  } = getCommonParams(query)

  const time = dayjs(Date.now())
  const today = time.format('YYYY-MM-DD')

  let params = {
    cat_type: '1',
    select_id: query?.selectUrlId || '',
    today,
    quickship,
    filter,
    cat_ids: query.cat_ids,
    child_cat_id: catId,
    choosed_nav_id: choosedNavId,
    choosed_nav_type: choosedNavType,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    styleType: query.styleType || '',
    tag_ids: tagIds,

    _show_daily: query.show_daily || '',
    _type: 'selection'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

const requestPicNav = (requestInfo) => {
  const picNavParams = getPicNavParams({
    ...requestInfo.query,
  })
  //NOTE: 首屏才会请求图文导航
  const needFetch = false
  if (!needFetch) {
    return new Promise(resolve => {
      resolve({})
    })
  }

  return selectionAndEntityPicNavApiCache.request(picNavParams)
}

/**
 * 获取词落地页筛选数据bff
 */
export const getLandingPageFilterDataBff = async (requestInfo) => {
  const filterParams = getFilterParams({
    ...requestInfo.query,
  })
  const cloudTagParams = getCloudTagParams({
    ...requestInfo.query,
  })
  const categoryDayTagParams = getCategoryDayTagParams({
    ...requestInfo.query,
  })
  const requestQueue = [
    // 分类/属性/tsp筛选
    selectionAndEntityFilterApiCache.request(filterParams),
    // 图文导航
    requestPicNav(requestInfo),
    // 标签云
    selectionAndEntityCloudTagApiCache.request(cloudTagParams),
    // 标签云中的date标签数据  没有show_daily不会请求
    categoryDayTagApiCache.request(categoryDayTagParams),
  ]
  const [
    {
      value: bffCateAttrResult,
    } = {},
    {
      value: bffPicNavResult,
    } = {},
    {
      value: bffCloudTagResult,
    } = {},
    {
      value: bffDailyDatesResult,
    } = {},
  ] = await Promise.allSettled(requestQueue)
  const filterData = generateRealSelectBffFilterInfo({
    bffCateAttrResult,
    bffPicNavResult,
    bffCloudTagResult,
    bffDailyDatesResult,
  }, requestInfo.query)
  // TODO: 删选数据依赖ccc目前还不行
  // eslint-disable-next-line no-console
  console.log('getLandingPageFilterDataBff', filterData, requestInfo.query)
}
