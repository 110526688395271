import { getBubbleInfo } from 'public/src/pages/goods_detail_v2/utils/size-info-desc.js'
import { mapRootGetters } from 'public/src/pages/goods_detail/utils/storeUtils.js'
import { CVAttrId } from 'public/src/pages/goods_detail/utils/descriptionConfig.js'
import { contentIncludeSpecialFeatures } from 'public/src/pages/goods_detail_v2/utils/productDetail'
import { getModuleAttribute } from 'public/src/pages/product_app/store/modules/product_detail/utils/cccTemplateDeal.js'
export default {
  namespaced: true,
  state: {
    sizeInfoStrFilterField: [
      'size',
      'attr_id',
      'attr_name',
      'attr_value_id',
      'attr_value_name',
      'attr_value_name_en',
    ],
  },
  getters: {
    pageComponents(state, getters, rootState) {
      return rootState.newProductDetail.coldModules?.ccc?.pageComponents || {}
    },
    sizeInfoDes(state, getters, rootState) {
      return rootState.newProductDetail.coldModules?.productInfo?.sizeInfoDes || {}
    },
    bubbleInfo(state, getters, rootState) {
      const { pageInfo, productInfo } = rootState.newProductDetail.coldModules
      const { language } = pageInfo || {}
      const { sizeInfoDes, sizeFormatData } = productInfo || {}
      return getBubbleInfo(sizeFormatData?.saleAttrList?.skcSaleAttr, sizeInfoDes, [], language)
    },
    // showSizeGuide(state, getters, rootState, rootGetters) {
    //   const currentUnit = rootGetters['newProductDetail/SizeBox/currentUnit']
    //   // const { sizeUnit } = rootState.productDetail // TODO yidier 数据要用心的

    //   const bubbleInfo = getters.bubbleInfo?.[currentUnit]
    //   for (let i = 0; i < bubbleInfo.length; i++) {
    //     if (bubbleInfo[i].list.length) {
    //       for (let j = 0; j < bubbleInfo[i].list.length; j++) {
    //         const keys = Object.keys(bubbleInfo[i].list[j])
    //         if (keys.filter((_) => !state.sizeInfoStrFilterField.includes(_)).length > 0) {
    //           return true
    //         }
    //       }
    //     }
    //   }
    //   return false
    // },
    // showIngredientEnter(state, getters, rootState) {
    //   return !!rootState.newProductDetail.coldModules?.productInfo?.beautyGoodsDesc?.goodsComponent
    // },
    model(state, getters, rootState) {
      return rootState.newProductDetail.coldModules?.productInfo?.modelInfo || {}
    },
    showModelIntro(state, getters, rootState) {
      return JSON.stringify(rootState.newProductDetail.coldModules?.productInfo?.modelInfo) !== '{}'
    },
    newDetailsArr(state, getters, rootState, rootGetters) {
      let { newDescription } = mapRootGetters(rootGetters, 'common', ['newDescription'])
      if (!newDescription?.length) return []
      return newDescription.length < 4
        ? newDescription
        : newDescription
          .sort((val1, val2) => (Number(val1.score) < Number(val2.score) ? 1 : -1))
          .slice(0, 3)
    },
    detailsArr(state, getters, rootState) {
      const { productInfo } = rootState.newProductDetail.coldModules || {}
      const { productDetailsObj } = productInfo || {}
      let arr_101_length =
        productDetailsObj?.['101'] && productDetailsObj?.['101']?.value.split(',').length
      let arr_62_length = productDetailsObj?.['62'] && productDetailsObj?.['62'].value.split(',').length
      if (arr_62_length >= 1 && arr_101_length >= 1) {
        return [
          productDetailsObj?.['101']?.value.split(',')[0],
          productDetailsObj?.['62']?.value.split(',')[0],
        ]
      } else if (!arr_62_length && arr_101_length >= 1) {
        const str = productDetailsObj?.['101']?.value.split(',').slice(0, 2).join(',')
        return [str, '']
      } else if (!arr_101_length && arr_62_length >= 1) {
        const str = productDetailsObj?.['62']?.value.split(',').slice(0, 2).join(',')
        return ['', str]
      } else if (!arr_62_length && !arr_101_length) {
        return []
      }
      return []
    },
    multiDescription(state, getters, rootState) {
      const { productInfo } = rootState.newProductDetail.coldModules || {}
      const { detail } = productInfo || {}
      const { isMultiPartProduct, multiPartInfo } = detail || {}

      let partNameList = {}
      let attrNameList = {}
      let attrSortList = {}
      let sameKeyList = {}
      let sameKeyListEvolve = {}
      let multiList = []
      // https://wiki.dotfashion.cn/pages/viewpage.action?pageId=791120422
      // 同一属性项下，部件间的属性、属性值不完全一致时，即判断各部件“attr_value”不完全一致，不进行合并。各部件单独展示：部件名+属性项：属性值（如bra composition：100% cotton）
      // 同一属性项下，部件间的属性、属性值完全一致时，即判断各部件所有“attr_value”完全一致，合并各部件展示：部件名1&部件名2&...+属性项：属性值（如bra composition：100% cotton）
      // 部件不进行排序
      if (isMultiPartProduct && multiPartInfo?.length) {
        // 1. 同一部件相同属性项合并
        multiPartInfo.forEach((item) => {
          const { attributeList, multiPartCode, multiPartName } = item || {}
          attributeList?.forEach?.((multiItem) => {
            const { attr_id, attr_name, attr_sort, attr_value } = multiItem || {}
            sameKeyList[multiPartCode] = sameKeyList[multiPartCode] || {}
            sameKeyList[multiPartCode][attr_id] = sameKeyList[multiPartCode]?.[attr_id] || []
            partNameList[multiPartCode] = multiPartName
            attrNameList[attr_id] = attr_name
            if (!attrSortList[attr_id] || attrSortList[attr_id] > attr_sort) {
              attrSortList[attr_id] = attr_sort
            }
            sameKeyList[multiPartCode][attr_id].push(attr_value)
          })
        })
        // 2. 不同部件相同属性项属性值合并
        Object.keys(sameKeyList).forEach((partItem) => {
          for (const partItemKey in sameKeyList[partItem]) {
            if (sameKeyListEvolve[partItemKey]) {
              let combineIndex
              sameKeyListEvolve[partItemKey].forEach((multiItem, multiIndex) => {
                if (combineIndex !== undefined) return
                let bakValArrEvolve = multiItem.value
                if (sameKeyList[partItem][partItemKey].length == bakValArrEvolve.length) {
                  combineIndex = multiIndex
                  sameKeyList[partItem][partItemKey].forEach((item) => {
                    if (!bakValArrEvolve.includes(item)) {
                      combineIndex = undefined
                    }
                  })
                } else {
                  combineIndex = undefined
                }
              })

              if (combineIndex !== undefined) {
                // 合并
                if (
                  !sameKeyListEvolve[partItemKey][combineIndex].combinePartName.includes(
                    partNameList[partItem]
                  )
                ) {
                  sameKeyListEvolve[partItemKey][combineIndex].combinePartName.push(
                    partNameList[partItem]
                  )
                }
              } else {
                // 不合并
                sameKeyListEvolve[partItemKey].push({
                  combinePartName: [partNameList[partItem]],
                  value: sameKeyList[partItem][partItemKey],
                })
              }
            } else {
              // 第一个
              sameKeyListEvolve[partItemKey] = []
              sameKeyListEvolve[partItemKey].push({
                combinePartName: [partNameList[partItem]],
                value: sameKeyList[partItem][partItemKey],
              })
            }
          }
        })
        // 3.不同部件相同属性项属性值合并
        for (var evolAttrId in sameKeyListEvolve) {
          sameKeyListEvolve[evolAttrId].forEach(evolMulti => {
            multiList.push({
              name:
                  evolMulti.combinePartName.join(' & ') +
                  ' ' +
                  attrNameList[evolAttrId],
              value: evolMulti.value.join(', '),
              attr_sort: attrSortList[evolAttrId]
            })
          })
        }
      }
      return multiList
    },
    descriptionList(state, getters, rootState) {
      const { showHeelHeight, heelHeight } = rootState?.newProductDetail?.SizeBox || {}
      const { pageComponents, multiDescription } = getters
      const { descPosConf, descriptionConf } = pageComponents || {}
      const levelledList = []
      const result = []
      const filterId = descPosConf?.filterId?.concat?.(
        descriptionConf.description27 ? '27' : ''
      )
      for (let k in descriptionConf?.descriptionList) {
        if (!filterId.includes(k)) {
          let itDescp = descriptionConf.descriptionList[k]
          if (!levelledList[itDescp.attr_sort]) {
            levelledList[itDescp.attr_sort] = []
          }
          levelledList[itDescp.attr_sort].push(itDescp)
        }
      }
      multiDescription.forEach(item => {
        if (!levelledList[item.attr_sort]) {
          levelledList[item.attr_sort] = []
        }
        levelledList[item.attr_sort].push(item)
      })
      levelledList.forEach((item) => {
        if (item) {
          result.push(...item)
        }
      })
      result.forEach(item => {
        // 跟高属性
        if(item.attr_id == '45' && showHeelHeight) {
          item.value = ' ' + heelHeight
        }
      })
      return result
    },
    orginAttrArray(state, getters, rootState, rootGetters) { // 未被过滤的
      const { pageComponents, descriptionList } = getters
      const { productInfo } = rootState.newProductDetail.coldModules || {}
      const { detail } = productInfo || {}
      const { descPosConf, descriptionConf } = pageComponents || {}
      const { detailFPosContent } = mapRootGetters(rootGetters, 'common', ['detailFPosContent'])
      let textAttr = [
        ...descriptionList,
        { name: 'SKU', value: detail?.goods_sn || '' },
        ...(descriptionConf?.sizeAttrList || []),
      ]
      let imgAttr = descPosConf?.descriptionImg || []
      const hasCVTextAttr = textAttr.some(item => CVAttrId.includes(Number(item.attr_id || 0)))
      const hasCVImgAttr =  imgAttr.some(item => {
        const attr_id = item?.[0]?.attr_id || 0
        return CVAttrId.includes(Number(attr_id))
      })
      const hasCVFPos = detailFPosContent?.hasCVAttr || false
      return {
        orginTextAttr: textAttr,
        orginImgAttr: imgAttr,
        hasCVAttr: hasCVTextAttr || hasCVImgAttr || hasCVFPos,
      }
    },
    drawerContent(state, getters, rootState, rootGetters) {
      const { orginAttrArray } = getters
      const { orginTextAttr = [], orginImgAttr = [], hasCVAttr } = orginAttrArray
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const needFilter = fsAbt?.cvdescription?.p?.cvdescription === 'noshow'
      let textAttr = [], imgAttr = []
      if(needFilter) { // 过滤CV属性
        textAttr = orginTextAttr.filter(item => !CVAttrId.includes(Number(item.attr_id || 0)))
        imgAttr = orginImgAttr.filter(item => {
          const attr_id = item?.[0]?.attr_id || 0
          return !CVAttrId.includes(Number(attr_id))
        })
      } else {
        textAttr = orginTextAttr
        imgAttr = orginImgAttr
      }
      return {
        textAttr,
        hasCVAttr,
        imgAttr,
      }
    },
    showEvoluFloor(state, getters, rootState, rootGetters){
      // Evolushein楼层TSP标签
      const TAG_ID = '60008057'
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      return rootState.newProductDetail.coldModules?.tsp?.tspLabels?.tag?.includes?.(TAG_ID) && fsAbt?.evolushein?.p?.evolushein === 'show'
    },
    showEvoluEntry(state, getters, rootState, rootGetters) {
      // 是否在属性弹框中展示evoluSHEIN跳转入口
      const { drawerContent } = getters
      const { detailFPosContent } = mapRootGetters(rootGetters, 'common', ['detailFPosContent'])
      const hasSpecialFeature = contentIncludeSpecialFeatures(drawerContent?.imgAttr)
      return contentIncludeSpecialFeatures(detailFPosContent?.imgAttr) || contentIncludeSpecialFeatures(detailFPosContent?.textAttr) || hasSpecialFeature
    },
    isAbtCccdescription(state, getters, rootState, rootGetters){
      const { commonInfo } = rootState.newProductDetail.coldModules || {}
      const { IS_RW } = commonInfo || {}
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      return IS_RW || fsAbt?.cccdescription?.p?.ccc === 'on'
    },
    cccDescAttrList(state, getters, rootState) {
      const { isAbtCccdescription, pageComponents, descriptionList } = getters
      if (!isAbtCccdescription) return []
      const { ccc, productInfo } = rootState.newProductDetail.coldModules || {}
      const { cccConfig } = ccc || {}
      const { detail } = productInfo || {}
      // 最少且不超过3个
      const LEN = 3
      const { descShow = [], descAttrBlack = [] } = cccConfig || {}

      let results = []
      const { productDetails } = detail || {}
      const descriptionImg = pageComponents?.descPosConf?.descriptionImg || []
      const filterId = pageComponents?.descPosConf?.filterId || []

      const attrBlackIds = [...descAttrBlack, ...filterId]
      const _descAttrBlack = [...descAttrBlack]
      if (descShow.length) {
        const _descShow = descShow.map(d => {
          // 白名单，为空则默认全部
          const _whiteAttrGroups = d.dataShow === 'all' || !d.attrGroups?.length ? [] : d.attrGroups.map(d => ({
            attribute_id: d.attrValueId,
            attribute_name: d.attrValueName,
          }))

          return {
            attribute_id: d.attrId,
            attribute_name: d.attrName,
            _whiteAttrGroups
          }
        })
        results = getModuleAttribute(_descShow, _descAttrBlack, productDetails, attrBlackIds)?.textAttr
      }

      const lackLen = LEN - results.length
      const imgAttrList = []
      descriptionImg?.forEach?.(item => {
        if (item?.length) imgAttrList.push(...item)
      })
      // 若ccc配置且本商品符合的属性项＜3个，则缺x个就取description弹窗里面的前x个属性值，缺3个就取前3
      if (lackLen > 0) {
        const _descriptionDrawerList = [...(imgAttrList || []), ...(descriptionList || [])].filter(d => !_descAttrBlack.some(id => id == d.attr_id))
        return [...results, ..._descriptionDrawerList.slice(0, lackLen)]
      } else {
        return results.slice(0, LEN)
      }
    },
  },
}
