
<template>
  <div
    v-if="insuranceInfo && +insuranceInfo.displayInsurance === 1 && insuranceInfo.insurancePrice.hint"
    v-expose="{
      id: 'expose_shipping_insurance:simple',
      data: saObj
    }"
    :class="[prefixCls('insurance__wrapper')]"
  >
    <div>
      <span class="shipping-title">
        <!-- 使用SHEIN标语 -->
        {{ insuranceInfo.insurancePrice.hint }}
      </span>
      <span
        v-if="insuranceInfo.insurancePrice?.amount_rich_text"
        v-html="insuranceInfo.insurancePrice.amount_rich_text"
      ></span>
      <Icon
        style="line-height: inherit;"
        name="sui_icon_doubt_16px_2"
        @click="onInsuranceTip"
      />
    </div>
    <s-checkbox-switch
      :value.sync="isChecked"
      tabindex="0"
      :aria-label="(insuranceInfo?.insurancePrice?.hint || '') + insuranceInfo.insurancePrice?.amountWithSymbol"
      :disabled="isForceTick"
      role="menuitemradio"
      @click.native="onClickInsurance"
    />
  </div>
</template>

<script setup name="ShippingInsurance">
import { ref, watch, computed, nextTick } from 'vue'
import { CheckboxSwitch as SCheckboxSwitch } from '@shein/sui-mobile'
import { prefixCls } from './utils/index'
import { useMapState, useMapActions, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { Icon } from '@shein-aidc/icon-vue2'

const { selectedShippingList } = useMapState(['shippingMethodState.selectedShippingList'])
const { updateCheckoutData } = useMapActions(['updateCheckoutData'])
const { updateShipPopupState } = useMapMutations( ['updateShipPopupState'])

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  insuranceInfo: {
    type: Object,
    default: () => ({})
  }
})

const isForceTick = computed(() => +props.insuranceInfo?.is_force_tick === 1)
const isCheckedFromProps = computed(() => isForceTick.value || +props.insuranceInfo?.is_use_insurance === 1)
const isChecked = ref(isCheckedFromProps.value)

const saObj = computed(() => ({
  mall_code: props.mallCode,
  select_type: +isChecked.value,
  is_forced: isForceTick.value ? 1 : 0,
  insurance_type: props.insuranceInfo?.insurancePrice.insurance_type,
  default_value: props.insuranceInfo?.insurancePrice.isDefault
}))

const onInsuranceTip = () => {
  updateShipPopupState({
    insurance: {
      visible: true,
      content: props.insuranceInfo?.insurancePrice?.description
    }
  })

  daEventCenter.triggerNotice({
    id: 'expose_shipping_insurance_window:simple',
    data: {
      mall_code: props.mallCode,
    }
  })
}

const onClickInsurance = async () => {
  if(!props.insuranceInfo) return false

  nextTick(() => {
    daEventCenter.triggerNotice({
      id: 'click_shipping_insurance:simple',
      data: saObj.value
    })
  })

  if(isForceTick.value) {
    onInsuranceTip()
    return
  }

  const oldVal = isChecked.value
  isChecked.value = !oldVal

  // 切换运费险
  const newShippingMethods = selectedShippingList.value.map(item => {
    if(+item.mall_code === +props.mallCode) {
      return {
        ...item,
        use_insurance: isChecked.value ? 1 : 0
      }
    }
    return item
  })

  try {
    const res = await updateCheckoutData({
      opts: {
        shipping_methods: newShippingMethods,
      }
    })

    if(+res.code !== 0) {
      isChecked.value = !isChecked.value
    }
  } catch (e) {
    // eslint-disable-next-line require-atomic-updates
    isChecked.value = oldVal

    console.error(e)
  }
}

watch(isCheckedFromProps, (newVal, oldVal) => {
  if(newVal !== oldVal) {
    isChecked.value = !!newVal
  }
}, {
  immediate: true
})
</script>
<style lang="less">
@import "./variables";

.@{prefixCls}insurance {
  &__wrapper {
    display: flex;
    justify-content: space-between;

    margin: 0 @g_ship_pad_hor_offset;
    padding: 20/75rem 0 20/75rem 0;
    border-top: 1px solid #e5e5e5;
  }
}
</style>
