import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'

type ComponentProps = {
  text?: string
  context: DS_QuickAddCartStandard.FooterBarContext
  updateCallback?: (context: DS_QuickAddCartStandard.FooterBarContext) => Promise<void>
}

export function Button(props: ComponentProps) {
  const { context, text, updateCallback } = props

  async function handleClick() {
    const { checkSelected, setLoading, lockPage, removeLockPage, closeQuickAddCart } = context
    if (!checkSelected()) return
    try {
      // 锁定页面
      lockPage()
      setLoading(true)
      if (updateCallback) {
        await updateCallback(context)
      }
      setLoading(false)
      // 解锁页面
      removeLockPage()
      closeQuickAddCart()
    } catch (e) {
      console.log('salesAttrUpdatePlugin updateCallback error:', e)
      setLoading(false)
      // 解锁页面
      removeLockPage()
      closeQuickAddCart()
    }
  }

  return (
    <button
      class="bsc-quick-add-cart-standard__button_black"
      role="button"
      aria-label="Update"
      onClick={() => handleClick()}
    >
      {text || 'Update'}
    </button>
  )
}
