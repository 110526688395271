import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
class BffAdapter {
  constructor({
    contextForSSR
  }) {
    this.contextForSSR = contextForSSR
  }
  get #lang() {
    let lang = 'us'
    if (this.constantData?.lang) {
      lang = this.constantData.lang
    }
    return lang
  }
  get #isBff() {
    return this.contextForSSR?.isBff
  }
  get #language() {
    return this.contextForSSR?.language
  }
  get #sheinClubInfo() {
    return this.contextForSSR?.sheinClubInfo
  }
  get #suggestedSalePriceType() {
    return this.contextForSSR?.suggestedSalePriceType
  }
  get #hookGoods() {
    return this.contextForSSR?.hooksGoods ?? {}
  }
  adaptGoods(goods) {
    if (!this.#isBff) {
      return goods
    }
    return goods.map((v, i) => {
      const goodsInfo = goodsTransformation(v, {
        suggestedSaleType: this.#suggestedSalePriceType,
        language: this.#language ?? {},
        lang: this.#lang,
        isPaid: !!this.#sheinClubInfo?.isPaid
      })
      // 钩子商品只可能出现在第一个
      if (i === 0) {
        goodsInfo.isHookGood = v.goods_id == this.#hookGoods.goods_id
      }
      return goodsInfo
    })
  }

  adaptSsrData(data) {
    if (!this.#isBff) {
      return data
    }
    return {
      ...data,
      goods: {
        ...data.goods,
        list: this.adaptGoods(data.goods.list)
      },
    }
  }
  
}

export default BffAdapter
