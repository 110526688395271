import { computed, toRefs } from 'vue'


/**
 * 绿色通道入口文案逻辑统一 wiki.pageId=1565119520
 * @param {Array} customsInterceptionInfo 绿色通道异常包裹
 * @param {Object} language 
 */
export function useCustomsInterception(options) {
  const {
    customsInterceptionInfo = [],
    language
  } = options
  const {
    SHEIN_KEY_PWA_22334,
    SHEIN_KEY_PWA_34321,
    SHEIN_KEY_PWA_22142,
    SHEIN_KEY_PWA_34322,
    SHEIN_KEY_PWA_34583,
    SHEIN_KEY_PWA_35391,
  } = language

  // 是否是逆向待处理异常
  const isReturnAbnormal = (item) => {
    // 兼容两种命名方式,接口给的命名风格不一致
    const confirmStatus = item.confirm_status !== undefined ? item.confirm_status : item.confirmStatus
    const unionReturnNo = item.union_return_no !== undefined ? item.union_return_no : item.unionReturnNo

    return confirmStatus == 1 && unionReturnNo
  }

  // 是否正向待处理异常
  const isOrderAbnormal = (item) => {
    const confirmStatus = item.confirm_status !== undefined ? item.confirm_status : item.confirmStatus
    const unionReturnNo = item.union_return_no !== undefined ? item.union_return_no : item.unionReturnNo

    return confirmStatus == 1 && !unionReturnNo
  }

  // 是否已处理完成
  const isProcessed = (item) => {
    const confirmStatus = item.confirm_status !== undefined ? item.confirm_status : item.confirmStatus

    return confirmStatus != 1
  }

  // 根据confirm_status分类已处理异常
  const determineOrderProceedMessage = (confirmStatus) => {
    switch (confirmStatus) {
      case 11:
        // 巴西清关
        return SHEIN_KEY_PWA_34322
      default:
        return SHEIN_KEY_PWA_35391
    }
  }

  // 根据type分类正向"待处理"异常
  const determineOrderAbnormalTypeMessage = (type) => {
    switch (type) {
      case 6:
        // 丢件
        return SHEIN_KEY_PWA_22334
      case 15:
        // 巴西清关
        return SHEIN_KEY_PWA_34321
      default:
        return SHEIN_KEY_PWA_22142
    }
  }

  const customsMessages = computed(() => {
    let messages = []
    const packageList = customsInterceptionInfo.filter(item => item?.displayStatus == 1)
    const returnAbnormals = packageList.filter(isReturnAbnormal) // 逆向待处理
    const orderAbnormals = packageList.filter(isOrderAbnormal) // 正向待处理
    const allProcessed = packageList.every(isProcessed) // 是否全部已处理
    const uniqueTypes = new Set(orderAbnormals.map(item => item.type))
    const uniqueConfirmStatus = new Set(
      packageList.map(item => 
        item.confirm_status !== undefined ? item.confirm_status : item.confirmStatus
      )
    )

    if (allProcessed) {
      // 如果全部已处理
      if (uniqueConfirmStatus.size > 1) {
        messages.push(SHEIN_KEY_PWA_35391)
      } else {
        // 如果已处理异常类型相同
        const typeMessage = determineOrderProceedMessage(uniqueConfirmStatus.values().next().value)
        messages.push(typeMessage)
      }
    } else {
      if (orderAbnormals.length) {
        // 如果存在多个正向待处理异常但类型不同
        if (uniqueTypes.size > 1) {
          messages.push(SHEIN_KEY_PWA_22142)
        } else {
          // 如果所有正向待处理异常类型相同
          const typeMessage = determineOrderAbnormalTypeMessage(orderAbnormals[0].type)
          messages.push(typeMessage)
        }
      }
      if (returnAbnormals.length) {
        messages.push(SHEIN_KEY_PWA_34583)
      }
    }

    return messages
  })

  return {
    customsMessages: customsMessages.value
  }
}
