// 全局单例 ResizeObserver 管理器
export default class ResizeObserverManager {
  static instance
  observer
  listeners

  constructor() {
    this.listeners = new Map()
    this.observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const callbacks = this.listeners.get(entry.target)
        if (callbacks) {
          const height = entry.contentRect.height
          callbacks.forEach(callback => callback(height))
        }
      }
    })
  }

  static getInstance() {
    if (!ResizeObserverManager.instance) {
      ResizeObserverManager.instance = new ResizeObserverManager()
    }
    return ResizeObserverManager.instance
  }

  addListener(element, callback) {
    if (!this.listeners.has(element)) {
      this.listeners.set(element, new Set())
      this.observer.observe(element)
    }
    this.listeners.get(element).add(callback)
  }

  removeListener(element, callback) {
    const callbacks = this.listeners.get(element)
    if (callbacks) {
      callbacks.delete(callback)
      if (callbacks.size === 0) {
        this.listeners.delete(element)
        this.observer.unobserve(element)
      }
    }
  }
}
