class Sa {
  viewTips () {
    sa('send', {
      activity_name: 'expose_filling_personal_profile',
      activity_param: ''
    })
  }

  clickMyAddress () {
    sa('send', {
      activity_name: 'click_my_address',
      activity_param: ''
    })
  }

  clickMyCoupons (activity_param = '') {
    sa('send', {
      activity_name: 'click_my_coupons',
      activity_param
    })
  }

  clickMyWallet (activity_param = '') {
    sa('send', {
      activity_name: 'click_my_wallet',
      activity_param,
    })
  }

  clickSettings () {
    sa('send', {
      activity_name: 'click_settings',
      activity_param: ''
    })
  }

  clickTips () {
    sa('send', {
      activity_name: 'click_filling_profile_banner',
      activity_param: ''
    })
  }

  clickOrders () {
    sa('send',
      {
        activity_name: 'click_orders',
        activity_param: ''
      })
  }
	
  clickCoupon () {
    sa('send',
      {
        activity_name: 'click_coupon',
        activity_param: ''
      })
  }

  clickGiftcard (activity_param = '') {
    sa('send',
      {
        activity_name: 'click_gift_card',
        activity_param,
      })
  }

  clickPoints(activity_param = '') {
    sa('send', {
      activity_name: 'click_shein_points',
      activity_param,
    })
  }
}
export default Sa
