
const routes = [
  {
    path: 'return/:billno',
    name: 'orderReturnNew',
    component: () => import('../../user/child_pages/order_return/returns/container.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true
    }
  },
  {
    path: 'returnSuccess/:billno',
    name: 'returnSuccess',
    component: () => import('../../user/child_pages/order_return/labels/orderReturnSuccess.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true
    }
  },
  {
    path: 'returnLabelNew/:billno',
    name: 'returnLabelNew',
    component: () => import('../../user/child_pages/order_return/labels/orderReturnLabelNew.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true,
    }
  },
  {
    path: 'refundLabel/:billno',
    name: 'refundLabel',
    component: () => import('../../user/child_pages/order_return/labels/orderRefundLabel.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true,
    }
  },
  {
    path: 'returnRefundLabel/:billno',
    name: 'returnRefundLabel',
    component: () => import('../../user/child_pages/order_return/labels/orderReturnRefundLabel.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true,
    }
  },
  {
    path: 'returnPackageList',
    name: 'returnPackageList',
    meta: {
      needLogin: true,
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import( /* webpackChunkName: "return_package_list" */ '../../user/child_pages/order_return/returnPackageList/container.vue'),
  },
  {
    path: 'orderRevokePower',
    name: 'orderRevokePower',
    component: () => import('../../user/child_pages/order_revoke_power/container.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true,
      pageName: 'privacycenter'
    }
  },

  {
    path: 'orderRevokePowerForm',
    name: 'orderRevokePowerForm',
    component: () => import('../../user/child_pages/order_revoke_power/containerForm.vue'),
    // meta: {
    //   needLogin: true
    // }
  },

  {
    path: 'orderRevokePowerSuccess',
    name: 'orderRevokePowerSuccess',
    component: () => import('../../user/child_pages/order_revoke_power/successPage.vue'),
    // meta: {
    //   needLogin: true
    // }
  },
  {
    path: `price_guarantee`,
    name: 'priceGuarantee',
    component: () => import('../../price_guarantee/container.vue'),
    meta: {
      notNeedCommonHeader: true
    }
  },
  {
    path: `selfExchange`,
    component: () => import(/* webpackChunkName: "selfExchange" */'../../user/child_pages/orderSelfExchange/container.vue'),
    name: 'selfExchange',
    meta: {
      isCssInVue: true,
      notNeedCommonHeader: true,
      // keepAlive: false
    }
  },
  {
    path: 'trackTemp',
    name: 'ordersTrackSpa',
    meta: {
      pageType: 'ordersTrack',
      monitorTag: 'orders-track',
      isCssInVue: true,
      needLogin: true,
      notNeedCommonHeader: true,
    },
    component: () => import(/* webpackChunkName: "orders_track" */ '../../trackNew/container.vue') 
  },
  {
    path: 'packageDelay',
    component: () => import(/* webpackChunkName: "orderPackageDelay" */ '../../user/child_pages/orderPackageDelay/container.vue'),
    name: 'packageDelay',
    meta: {
      isCssInVue: true,
      needLogin: true,
      notNeedCommonHeader: true,
    }
  },
]

function transfromRoutes (routes) {
  return routes.map(route => {
    let { path, component, children = [], name = '', meta = {}, ...props } = route
    if (children && children.length) {
      children = transfromRoutes(children, path)
    }
    // 根据路径转化为驼峰
    name = name || path.replace(/(\/|-)(\w)/ig, (m, p1, p2) => p2.toUpperCase()).replace('/', '')

    meta.pageType = 'ordersPage'
    if (typeof meta.notNeedCommonHeader === 'undefined') meta.notNeedCommonHeader = true

    return {
      path: path,
      name,
      component,
      meta,
      children,
      ...props
    }
  })
}
const ordersRoutes = transfromRoutes(routes)

export {
  ordersRoutes
}
