import './libs_env_injection'
import './sw'
import './shein_common/core'
import 'public/src/services/eventCenterV2'
import '../../services/TPM'

import './globalCheck'

import schttp from '../../services/schttp'
import './ugidHandle'
import './variable'
import './common_cookie_manager'
import './scroll_top'
import './analysis'
import '../login/asyncIndex'
import '../signup_fast/asyncIndex'
import '../components/globalPrivacy/asyncIndex'
import './devices'
import './infp'
import './performance'
import './performance_safe'
import { Drawer, Loading } from '@shein/sui-mobile'
import { parseQueryString } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import './abtPageAll'
// import './encrypt'
import { loadGlobalPopModule } from 'public/src/pages/common/glob_popup_modal'
import { markPoint } from 'public/src/services/mark/index.js'
import { markPoint as performanceMark } from 'public/src/services/mark/markPoint.js'
import './monitor'
import './ip_address_report'
import './switchBffForTest'
// TODO MYTEST pre_requests 需要延后加载并执行，接口预加载转场时才会用到
import 'public/src/pre_requests/index'
import 'public/src/pages/goods_detail/utils/prefetchRealTime.js'
import { loadDetailComponent } from 'public/src/pages/product_app/router/goods-detail-router.js'
const { PUBLIC_CDN, lang, WEB_CLIENT, SiteUID, NODE_SERVER_ENV } = gbCommonInfo
window.__webpack_public_path__ = `${PUBLIC_CDN}/pwa_dist/assets/`

Vue.config.performance = NODE_SERVER_ENV !== 'production'
Vue.component(Drawer.name, Drawer)
Vue.component(Loading.name, Loading)

// pv事件
// 新增 params 入参
appEventCenter.$on('pageOnload', (params = {}) => {
  // 当页面加载完成之后,触发pageView营销埋点
  window.TPM?.publish('pageview', {
    page_name: window.SaPageInfo?.page_name || '',
  })
  if ($('.waves-btn-click').length) $('.waves-btn-click').remove()
})

$(() => {

  loadGlobalPopModule()

  requestIdleCallback(() => {
    $(document).on('click', '.waves-effect', function () {
      $(this).append('<div class="waves-btn-click"></div>').find('.waves-btn-click').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
        $(this).remove()
      })
    })

    //更新csrf_token
    schttp.updateXCsrfToken()

    // init fastclick
    // eslint-disable-next-line no-undef
    FastClick.attach(document.body)
    // eslint-disable-next-line no-undef
    FastClick.prototype.focus = function (targetElement) {
      targetElement.focus()
    }
  })
})

$(window).on('load', () => {
  requestIdleCallback(() => {
    // es跳转mx
    if (lang === 'es') {
      import('./es_jump_mx')
    }
    if (SiteUID === 'pwus' && WEB_CLIENT === 'shein') {
      import('./attentive/index')
    }
  })
  !isLogin() && import('./googleOneTab')
  isLogin() && import('./bindMessenger.js')

  const { needPopLogin } = parseQueryString(location.search)
  if (needPopLogin == 1 && !isLogin()) {
    SHEIN_LOGIN.show({ show: true, activelyPopLogin: true })
  }
})

markPoint({ eventName: 'commonJsLoaded', measureTo: 'productAppJsLoaded' })
performanceMark({ eventName: 'commonJsLoaded' })
if (gbCommonInfo?.tabActive === 'productDetail') {
  loadDetailComponent()
}
