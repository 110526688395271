// import { isLogin } from 'public/src/pages/common/utils/index.js'

export const getNum = (val, isLogin = true) => {
  if(isLogin) {
    return val || 0
  }
  return val || null
}


export const reExpose = (daIds, extraDatas) => {
  daIds.forEach((daId, i) => {
    daEventCenter.triggerNotice({
      daId,
      extraData: extraDatas[i]
    })
  }) 
}
