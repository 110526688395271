<template>
  <s-drawer
    class="token-pay-drawer"
    :class="[{
      'new-security-drawer': isUseNewPaymentSecurity,
    }]"
    :visible.sync="isShow"
    :direction="'btt'"
    :append-to-body="true"
    @close="close"
  >
    <template slot="top">
      <div class="header">
        <span class="header-title">{{ language.SHEIN_KEY_PWA_23430 }}</span>
        <div
          v-if="isUseNewPaymentSecurity"
          class="all-encrypted"
          @click="showPrivacyDrawer"
        >
          <Icon
            name="sui_icon_guarantee_18px"
            size="18px"
          />
          <span>{{ language.SHEIN_KEY_PWA_30725 }}</span>
          <Icon
            name="sui_icon_more_right_12px_2"
            size="12px"
          />
        </div>
      </div>
    </template>
    <div class="drawer-content">
      <CoBrandCardTips :tips="coBrandedCardBenefits" />
      <div class="pay-list-block a1">
        <div
          v-for="item in tokenList"
          :key="item.id"
          class="card-block"
          :class="[
            {
              'card-block__disabled': 'isGray' in item
            }
          ]"
        >
          <div class="card-block-content">
            <div class="item-left">
              <s-radio
                v-model="nowRadio"
                class="payment-item-radio"
                :value="item.id"
                :label="item.id"
                :disabled="'isGray' in item"
                @change="handlerCheck(item)"
              />
            </div>
            <div class="item-right">
              <div class="option-item">
                <div class="card-img-wrap">
                  <img
                    v-if="CO_BRAND_LEVEL_LIST.includes(item.coBrandLevel)"
                    class="card-img-bg"
                    :src="CO_BRAND_CARD_LOGO"
                  />
                  <img
                    v-else-if="
                      item.cardMetadata &&
                        item.cardMetadata.content &&
                        item.cardMetadata.content.imageUrl
                    "
                    class="card-img-bg"
                    :src="item.cardMetadata.content.imageUrl"
                  />
                  <template v-else>
                    <img
                      class="card-img-bg"
                      :src="defaultCardBg"
                    />
                    <img
                      v-show="item.webLogo"
                      :src="item.webLogo"
                      alt=""
                      class="card-img-logo"
                    />
                  </template>
                  <span
                    v-if="!CO_BRAND_LEVEL_LIST.includes(item.coBrandLevel)"
                    class="card-number"
                  >{{ item.cardNo }}</span>

                  <div
                    v-if="'isGray' in item"
                    class="img-gray"
                  ></div>
                </div>
                <div class="card-detail-wrap">
                  <span class="card-detail-number">{{ item.cardNo }}</span>
                </div>
                <i
                  class="option-item-icon suiiconfont sui_icon_delete_18px"
                  @click="handleDel(item)"
                ></i>
              </div>

              <div
                v-if="'isGray' in item"
                class="disabled-tip"
              >
                <Icon
                  name="sui_icon_caution_12px_2"
                  size="12px"
                />
                <span>
                  {{ language.SHEIN_KEY_PWA_23440 }}
                </span>
              </div>
            </div>
          </div>

          <discount-tips
            :item="paymentItem"
            :language="language"
            :card-bin="item.cardBin"
            :co-brand-level="item.coBrandLevel"
            @payment-options-oper="handlePaymentOptionsOper"
          />
        </div>
      </div>

      <all-privacy
        :language="language"
        :logo-list="logoList"
      />
    </div>
    <s-dialog
      :visible="isShowDelDialog"
      :show-close="true"
      :append-to-body="true"
      class="token-dialog"
      @close="closeDialog"
    >
      <div class="token-pay-del">
        <span>
          {{ language.SHEIN_KEY_PWA_19690 }}
        </span>
      </div>

      <template #footer>
        <SButton
          :type="['H72PX']"
          width="45%"
          @click="closeDialog"
        >
          {{ language.SHEIN_KEY_PWA_14830 }}
        </SButton>
        <SButton
          :type="['primary', 'H72PX']"
          width="45%"
          @click="onClickConfirm"
        >
          {{ language.SHEIN_KEY_PWA_14847 }}
        </SButton>
      </template>
    </s-dialog>

    <s-loading
      type="curpage"
      :show="loadingShow"
    />
    
    <privacy-drawer 
      v-model="isShowPrivacyDrawer"
      :language="language" 
    >
      <template slot="privacy">
        <new-privacy
          :language="language"
          :logo-list="privacyLogoList"
        />
      </template>
    </privacy-drawer>
  </s-drawer>
</template>

<script setup>
/*
 * 支付方式组件: token 抽屉
 *
 * */

// components
import {
  Drawer as SDrawer,
  Radio as SRadio,
  Dialog as SDialog,
  Button as SButton,
  Loading as SLoading,
  Toast as $toast
} from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import DiscountTips from './token_pay/payment_discount.vue'
import AllPrivacy from './components/all_privacy.vue'
import PrivacyDrawer from 'public/src/pages/components/privacy_drawer.vue'
import NewPrivacy from 'public/src/pages/components/new_privacy.vue'

// utils
import { ref, watch, defineEmits, onMounted, computed } from 'vue'
import { deletePaymentSignUp } from 'public/src/services/api/pay/payment'
import { useStore, useMapMutations } from 'public/src/pages/checkout/hooks/index.js'
import { CO_BRAND_CARD_LOGO, CO_BRAND_LEVEL_LIST } from './token_pay/constant'
import useCoBrandedCardPaymentToken from '../hooks/useCoBrandedCardPaymentToken'
import CoBrandCardTips from './token_pay/CoBrandCardTips.vue'
const PAYMENT_CODE = 'routepay-card'
const { PUBLIC_CDN } = gbCommonInfo

const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => {}
  },
  defaultSelectTokenPay: {
    type: Object,
    default: () => {}
  },
  logoList: {
    type: Array,
    default: () => []
  }
})

const nowRadio = ref('')
const isShow = ref(false)
const tokenList = ref({})
const defaultCardBg = ref(
  `${PUBLIC_CDN}/pwa_dist/images/user/payment-default-card-83cb0dac07.png`
)
const isShowDelDialog = ref(false)
const nowDelItem = ref({})
const loadingShow = ref(false)
// const goods_img_placeholder = ref(LAZY_IMG)
const isShowPrivacyDrawer = ref(false)

const store = useStore()
const { assignState } = useMapMutations(['assignState'])
const { coBrandedCardBenefits } = useCoBrandedCardPaymentToken(store, { value: PAYMENT_CODE }, props.language)

const checkout = computed(() => {
  return store?.state?.checkout?.checkout
})

const privacyLogoList = computed(() => {
  return store?.state?.checkout?.paymentLogoInfo?.info?.logoList || []
})

const handlerSelectBin = computed(() => {
  return store?.state?.checkout?.handlerSelectBin
})

const paymentItem = computed(() => {
  const { results = {} } = store?.state?.checkout?.checkout
  const { paymentMethods = {} } = results
  return paymentMethods.find(item => item?.code === PAYMENT_CODE)
})

const isUseNewPaymentSecurity = computed(() => {
  return (
    checkout.value?.abtInfo?.PaymentSafetyExperiment?.param?.PaymentSecurity ===
    'Type_A'
  )
})

watch(
  () => props.value,
  () => {
    if (props.value !== isShow.value) {
      isShow.value = props.value
    }
  },
  {
    immediate: true
  }
)

watch(
  () => isShow.value,
  () => {
    nowRadio.value = props.defaultSelectTokenPay?.id
  },
  {
    deep: true,
    immediate: true
  }
)

watch(
  () => tokenList.value,
  () => {
    Array.isArray(tokenList.value) && assignState({
      tokenBinList: tokenList.value?.filter(item => item?.cardBin?.length > 0) || []
    })
  },
  {
    deep: true,
    immediate: true
  }
)

const emit = defineEmits([
  'input',
  'changeDefaultSelectTokenPay',
  'handlePaymentOptionsOper'
])


const close = () => {
  emit('input', false)
  isShow.value = false
}

const initData = () => {
  tokenList.value = checkout.value?.memberPreCardTokenInfo?.info?.tokenList
}

const handlerCheck = item => {
  assignState({
    lastSelectBin: props.defaultSelectTokenPay?.card_bin || handlerSelectBin.value || '',
    handlerSelectBin: item?.cardBin || item?.card_bin || '',
    isNoNeedCardBin: false
  })

  appEventCenter.$emit('checkout-update-payment', {
    request_card_token: 1,
    token_id: item?.id,
    isNeedMallCal: true
  })

  const nowSelectedItem = paymentItem.value?.card_token_list?.find(tokenItem => tokenItem?.id === item.id)

  emit('changeDefaultSelectTokenPay', nowSelectedItem)
  close()
}

const handleDel = item => {
  console.log('item >>>', item)
  nowDelItem.value = item
  isShowDelDialog.value = true
}

const closeDialog = () => {
  isShowDelDialog.value = false
}

const onClickConfirm = () => {
  loadingShow.value = true
  const id = nowDelItem.value?.id
  const isDelNowSelect = id === props.defaultSelectTokenPay?.id // 解绑的token是否是用户选中的

  deletePaymentSignUp({ id })
    .then(res => {
      if (res && res.code == 0) {
        tokenList.value = tokenList.value.filter(item => item.id !== id)
      } else {
        $toast(props.language.SHEIN_KEY_PWA_19689)
      }
    })
    .catch(() => {
      $toast(props.language.SHEIN_KEY_PWA_19689)
    })
    .finally(() => {
      loadingShow.value = false
      closeDialog()

      if (isDelNowSelect) {
        close()
        appEventCenter.$emit('checkout-update-payment', {
          request_card_token: 1,
          isNeedMallCal: true
        })
      }
    })
}

const handlePaymentOptionsOper = type => {
  emit('handlePaymentOptionsOper', type)
}

const showPrivacyDrawer = () => {
  isShowPrivacyDrawer.value = true
}

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.token-pay-drawer {
  .payment-item-radio {
    margin-right: 28/75rem;
  }
  .card-block {
    display: flex;
    flex-direction: column;
    padding: 12 * 2/75rem 12 * 2/75rem 0 12 * 2/75rem;
  }

  .card-block-content {
    display: flex;
  }
  .card-block:last-child {
    padding-bottom: 12 * 2/75rem;
  }
  .option-item {
    width: 100%;
    .flexbox();
    .align-center();
  }
  .card-img-wrap {
    position: relative;
    z-index: @zindex-out;
    width: 94 * 2/75rem;
    img.card-img-bg {
      width: 100%;
      border-radius: 12/75rem;
    }
    .card-img-logo {
      position: absolute;
      right: 14/75rem;
      top: 14/75rem;
      width: 36/75rem;
      z-index: @zindex-out + 1;
    }
    .card-number {
      position: absolute;
      z-index: @zindex-out + 1;
      text-align: left;
      bottom: 12/75rem;
      left: 12/75rem;
      .font-dpr(24px);
      color: @sui_color_white;
      line-height: 1.2 * 0.66666;
      transform: scale(0.6666);
      -webkit-transform: scale(0.6666);
      transform-origin: left bottom;
      -webkit-transform-origin: left bottom;
    }

    .img-gray {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 94 * 2/75rem;
      height: 100%;
      background: linear-gradient(251deg, #ababab 0%, #8f8f8f 100%);
      border-radius: 4.06px;
      opacity: 0.9;
    }
  }
  .card-detail-wrap {
    flex: 1;
    .flexbox();
    .pack-center();
    flex-direction: column;
    margin-left: 16/75rem;
    overflow: hidden;
    line-height: 1.2;
    .font-dpr(24px);
    .card-detail-type {
      font-weight: 700;
      padding-bottom: 16/75rem;
      font-size: 16px;
    }
    .card-detail-number {
      word-break: break-all;
      color: black;
      font-size: 14px;
      font-family: SF UI Text;
      font-weight: 400;
      word-wrap: break-word;
    }
  }

  .option-item-icon {
    padding-left: 12/75rem;
    .font-dpr(32px);
    color: #222222;
  }

  .card-block__disabled {
    .card-detail-number {
      color: #767676;
    }

    .option-item-icon {
      color: #767676;
    }
  }

  .item-left {
    padding-top: 50/75rem;
  }

  .item-right {
    width: 100%;
  }

  .disabled-tip {
    margin-top: 12/75rem;
    color: #767676;
    font-size: 12px;
    font-family: SF UI Text;
    font-weight: 400;
    word-wrap: break-word;
    display: flex;
    align-items: center;

    .sui-icon-common__wrap {
      margin-right: 4px;
    }
  }
}

.token-dialog {
  .token-pay-del {
    text-align: center;
  }

  .S-dialog__footer {
    display: flex;
    justify-content: space-around;
  }
}

.drawer-content {
  background-color: #f6f6f6;
}

.pay-list-block {
  background-color: white;
}

.header {
  line-height: 34/75rem;
  padding: 18/75rem 0 14/75rem 0;

  .header-title {
    color: #767676;
    font-size: 14px;
    font-family: SF Pro;
    font-weight: 590;
    word-wrap: break-word;
  }

  .all-encrypted {
    height: 36/75rem;
    line-height: 36/75rem;
    color: #198055;
    font-size: 12px;
    font-family: SF Pro;
    font-weight: 400;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.new-security-drawer {
  /deep/.S-drawer__header_normal {
    min-height: unset;
  }
}
</style>
