import schttp from '@/public/src/services/schttp'
import UserInfoManager from 'public/src/services/UserInfoManager'
import { setCurrency } from '@/public/src/pages/user/child_pages/setting_new/utils/getBffLangAndCurrency.js'
import { Toast as $toast } from '@shein/sui-mobile'
import { useRouter } from '@/public/src/pages/checkout_new/hooks/useRouter.js'
import useReportAnalysis from '@/public/src/pages/checkout_new/hooks/useReportAnalysis.js'
import { exposeScenesAbtAggregatorBff as exposeScenesAbtAggregator } from 'public/src/pages/checkout/tools/EventAggregator.js'

import {
  setLocalStorage,
} from '@shein/common-function'
import { SIMetric } from 'public/src/pages/common/monitor/index.js'

import shoppingBags from './shoppingBags'
import shippingMethod from './shippingMethod'
import virtualAssets from './virtualAssets'
import restriction from './restriction'

let updateCheckoutAbortController

// checkout接口异常处理逻辑
// !!!此处错误码不可重复，有耦合逻辑请统一放置common中使用一个函数处理!!!
export const ExceptionCodeMap = {
  common: {
    codes: [301111],
    actionName: 'handleCommonResp'
  },
  shippingMethod: {
    codes: [
      10126003,
      10126005,
      402502, 402501, 403370, 401906, 401973, 400322, 401972,
      10126001, 10126002
    ],
    actionName: 'handleShippingMethodResp'
  },
  virtualAssets: {
    codes: [300366, 301714],
    actionName: 'handleVirtualAssetsResp'
  },
  carts: {
    codes: [300401, 300407],
    actionName: 'handleCartsResp'
  },
  // 处理checkout各种优惠限制场景报错（积分、券、钱包、礼品卡、支付方式）
  restriction: {
    codes: [300511, 302458, 300627],
    actionName: 'handleRestrictionResp'
  },
}

export default {
  // 单页水合根提交
  exchangingSpaScene({ commit }) {
    if (window._gb_app_) {
      commit('exchangingSpaScene', false, { root: true })
    }
  },
  // 购物袋actions【更新购物车数据】
  ...shoppingBags,

  // 运输方式
  ...shippingMethod,
  
  // 虚拟资产
  ...virtualAssets,

  // 处理checkout各种优惠限制场景报错（积分、券、钱包、礼品卡、支付方式）
  ...restriction,

  /**
   * 无任何副作用操作的获取checkout接口数据
   *
   * 1. 卡分期前置中成单失败二次校验对比时需要用一份新的checkout数据，这里仅作对比，不做数据更新
   *
   * @param {*} { state }
   * @param {*} [{ opts }={}]
   * @return {*}
   */
  getCheckoutData({ state }, { opts } = {}) {
    const params = {
      ...state.checkoutParams,
      ...opts
    }
    return schttp({
      method: 'POST',
      url: '/order/order/checkout',
      data: params,
      useBffApi: true,
    })
  },

  // 更新checkout接口（注：各场景自行拼接好opts之后传参，尽量不要在公共方法中进行参数拼接；非公共的回调逻辑类似）
  /**
   *
   * @param {*} opts checkout接口入参
   * @param {*} cb checkout接口成功回调，没有可不传（非公共回调逻辑）
   * @param {*} scene 由何场景触发（address、coupon、point、shipping）
   * @param {*}
   */
  updateCheckoutData({ commit, dispatch, state }, { opts, cb, scene, conf: _conf } = {}) {
    updateCheckoutAbortController?.abort?.()
    updateCheckoutAbortController = new SchttpAbortCon()
    const params = {
      ...state.checkoutParams,
      ...opts
    }

    const conf = {
      loading: true,
      noDefTips: false,
      ..._conf
    }
    
    commit('changeCheckoutLoading', { config: conf, setLoading: true })

    return schttp({
      method: 'POST',
      url: '/order/order/checkout',
      data: params,
      useBffApi: true,
      signal: updateCheckoutAbortController?.signal
    }).then(async res => {
      const code = +res.code

      SIMetric.metricCount({
        metric_name: 'fe_checkout_response', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          productType: 'normal',
          checkoutType: !!state.buyNowNo ? 'buynow' : 'normal',
          errorCode: String(code)
        }
      })

      // 此处只更新 只要checkout接口调用就需要更新的入参（目前只识别到is_manual_used_coupon）
      commit('assignState', { checkoutParams: {
        ...state.checkoutParams,
        is_manual_used_coupon: params?.is_manual_used_coupon ?? state.checkoutParams?.is_manual_used_coupon
      } })

      if(code === 0) {
        commit('assignState', { checkout: res.info })
        // 此处更新 checkout接口调用成功需要更新的入参
        dispatch('updateCheckoutParams', { checkout: res.info, params })
        commit('checkoutComplete', { scene, status: 'success' })
        
        // 运输方式初始化
        commit('initShippingSelectedMethod', { checkout: res.info })
        
        commit('updateAddressBarState', { defaultAddressCache: null })

        // 更新地址相关的信息
        commit('updateAddressStateAfterCheckout', { checkout: res.info })
        
        // 更新免税弹窗状态
        commit('updateTaxReliefInfo', { checkout: res.info })
        // 设置缓存
        dispatch('setCheckoutCacheCookie', { opts: params, cacheAbtValid: state.cacheInfo?.cacheAbtValid, isClickedQsFreight: state.isClickedQsFreight, address: res.info.address })

        // 货币切换
        if(!!res.info?.auto_change_currency_tips?.needCurrency) {
          dispatch('changeCurrency', { currency: res.info.auto_change_currency_tips.needCurrency, info: res.info.auto_change_currency_tips })
        }

        // 修改权益楼层数据初始状态
        if (!res.info?.rewardFloor?.guide) {
          commit('updateFooterState', { noRewardData: true })
        }

        dispatch('handleReportAnalysis')
        
        cb && cb(res)
        return res
      }

      commit('checkoutComplete', { scene, status: 'fail' })
      
      let hasOtherErr = false
      for(const { actionName, codes, when } of Object.values(ExceptionCodeMap)) {
        if(codes ? codes.includes(code) : when && when(code, res.info, res)) {
          hasOtherErr = true

          const wrapObj = {
            code: code,
            res,
            info: res.info,
            params,
            scene
          }
          
          dispatch(actionName, wrapObj)
        }
      }
      
      const showToast = !conf.noDefTips && !hasOtherErr
      
      if(showToast) {
        $toast(res.msg)
      }
      
      // cod支付，待修改，handleCod 修改人@Xin Liu 逻辑无效？？？
      cb && cb(res)
      return res
    }).finally(() => {
      // 状态的更新render完成后关闭loading
      commit('changeCheckoutLoading', { config: conf, setLoading: false })
    })
  },
  // 需要等待checkout结果后上报的埋点
  handleReportAnalysis() {
    const {
      handleQuickShipping,
      handleNotSupportCodTipsCode,
    } = useReportAnalysis()
    
    handleQuickShipping()
    handleNotSupportCodTipsCode()
  },
  handleCommonResp({ state }, { code }) {
    if(code === 301111) {
      const redirectUrl = (!!state.buyNowNo && !!state.buyNowFromUrl) ? state.buyNowFromUrl : `${gbCommonInfo?.langPath}/cart`
      const router = useRouter()
      if(router) {
        router.push(redirectUrl)
      } else {
        window.location.href = redirectUrl
      }

      return
    }
  },
  updateCheckoutParams({ commit, state }, { checkout, params, isInit }) {
    commit('handleCheckoutParams', params)
    commit('handleCheckoutAddressParams', checkout || state.checkout)
    commit('handleCheckoutShippingParams', checkout || state.checkout)
    
    commit('handleCheckoutFreeShippingAddParams', checkout || state.checkout)
    commit('handleCheckoutOtherParams', { checkout: checkout || state.checkout, isInit })
    commit('handleCheckoutPaymentParams', { params, checkout: checkout || state.checkout, isInit })
  },
  async setCacheBySetLocalStorage({}, { data, type, expire }) {
    const res = await schttp({
      method: 'POST',
      url: '/api/common/busineseCache/update',
      data: {
        cacheData: data,
        scanType: type,
        expire
      }
    })

    if (res?.code == '0' && type === 'addrcache') {
      setLocalStorage({
        key: 'addressCookie',
        value: data
      })
    }
  },
  /**
   * 设置checkout入参缓存逻辑
 * @param {*} opts checkout接口入参
 * @param {*} cacheAbtValid checkout是否开启缓存开关
 * @param {*} isClickedQsFreight 用户是否点击过qs跨境运费勾选
 * @param {*} address 当前地址
 */
  async setCheckoutCacheCookie({ dispatch }, { opts, cacheAbtValid = '', isClickedQsFreight = '', address } = {}) {
    const user = (await UserInfoManager.get({ key: 'UserInfo', actionType: 'userInfo'  })) ?? {}
    if(cacheAbtValid){
      // 记录价格计算成功的入参缓存
      const { address_id, shipping_methods } = opts
      
      let user_shipping_methods = []
      shipping_methods?.forEach(mall => {
        user_shipping_methods.push({
          mall_code: mall.mall_code,
          transport_type: mall.transport_type,
          use_insurance: mall.use_insurance,
          switch_qs_flag: isClickedQsFreight ? mall.switch_qs_flag : 0, // 用户手动点击过qs运费才记录缓存，且当前值为0时不使用
        })
      })

      const checkoutCache = [user?.member_id, user?.login_time_last, gbCommonInfo?.SiteUID, address_id, JSON.stringify(user_shipping_methods)].join(';')
      // 半小时缓存时间
      dispatch('setCacheBySetLocalStorage', { data: checkoutCache, type: 'checkcache2', expire: 30 * 60 * 1000 })
    }

    if(address){
      const { addressId, countryId, cityId, stateId } = address
      const { member_id, login_time_last } = user || {}
      const addressData = {
        addressId, countryId, cityId, stateId,
        memberId: member_id,
        loginTimeLast: login_time_last,
        createdTime: +new Date(),
        siteUid: gbCommonInfo?.SiteUID,
      }
      setTimeout(() => {
        dispatch('setCacheBySetLocalStorage', { data: addressData, type: 'addrcache' })
      }, 200)
    }
  },
  // 判断库存
  updateStock ({ commit }, { outStockCarts = [], outStockCartsTip } ) {
    if (!!outStockCarts?.length) {
      commit('updateCommonDialogState', {
        soldOutDrawer: {
          show: true,
          tip: outStockCartsTip,
          soldOutList: outStockCarts
        }
      })
    }
  },
  // 货币切换
  async changeCurrency({ commit, dispatch, state }, { currency, info }) {
    if(!currency) return

    commit('assignState', { nowCurrency: currency })

    if(+info?.is_show_tips === 1 && !!info?.tips) {
      commit('updateCommonDialogState', {
        currencyChangeDialog: {
          show: true,
          tip: info.tips,
        }
      })
    }

    exposeScenesAbtAggregator.addEvent({
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'walletBalance',
        type: state.checkout.wallet_balance?.subtractPrice?.amount > 0 ? 1 : 0,
        auto_wallet_result: state.checkout.wallet_balance?.amount > 0 ? 1 : 0,
      }
    })


    await setCurrency(currency, true)

    // 切换货币后， 需要用新货币更新购物袋、随手购

    // 更新 购物袋
    dispatch('updateCartsInfoData')

    // 更新 随手购
    if (state.togetherBuy.togetherBuyType) appEventCenter.$emit('update-recommend-data')
  },
  // 随手购数据客户端请求
  async getTogetherBuy({ state, commit }){
    const carts = state?.shoppingBagsState?.bagsInfo?.all
    const countryData = await UserInfoManager.get({ key: 'Country', actionType: 'token_bind/container' }) ?? {}
    const countryCode = countryData?.countryId || ''
    const abt = {}
    const needAbtPoskey = ['AddToOrderGoodsLocation', 'AddToOrderGoodsShow', 'listwithS3', 'listRRP', 'detailrrpprice', 'AddToOrderGoods', 'listquickship', 'listquickshipKey', 'PickupRec', 'buyNewCard', 'detailgoodsCard', 'FeaturePriorWeb', 'FastAddWeb', 'recmultiCard', 'listquickshipLanguage']
    Object.entries(state.abtInfo).forEach(([key, value]) => {
      if(needAbtPoskey.includes(key)){
        abt[key] = value
      }
    })
    const params = {
      carts: carts.map(item=> {
        const { real_quick_ship, mall_code, product } = item
        return { real_quick_ship, mall_code, product: { goods_id: product.goods_id } }
      }),
      abt,
      countryId: countryCode
    }
    const togetherInfo = await schttp({
      method: 'POST',
      url: '/api/checkout/togetherModules/get',
      data: params
    })
    const info = {
      togetherInitFinish: true,
      productsDrawerVisible: false
    }
    
    if(togetherInfo.code == 0){
      Object.assign(info, {
        ...togetherInfo.info
      })
    } else {
      console.error('togetherInfo error')
    }
    commit('assignState', {
      togetherBuy: info,
    })
  },
}
