<template>
  <div 
    class="single-tree-select__breadCrumb"
  >
    <span 
      ref="allLangPosition"
      class="single-tree-select__breadCrumb_allLangPosition"
    >
      {{ language.SHEIN_KEY_PWA_15623 }}&nbsp;&nbsp;>&nbsp;&nbsp;
      <template v-for="(item, index) in cateLinks">
        <span :key="item.label">{{ item.label }}</span>
        <span
          :key="index + 'arrow'"
          v-html="'&nbsp;&nbsp;>&nbsp;&nbsp;'"
        ></span>
      </template>
    </span>
    <div
      ref="catebreadcrumbs"
      class="single-tree-select__breadCrumb_items"
    >
      <span
        v-if="!cateLinks.length"
        class="single-tree-select__breadCrumb_title"
        :class="{
          'single-tree-select__breadCrumb_bold': needBold
        }"
      >
        {{ title }}
      </span>
      <span
        v-else 
        @click.stop="handleClickItem({type:'all'})"
      >
        {{ language.SHEIN_KEY_PWA_15623 }}
      </span>
      <template v-for="(item, index) in cateLinks">
        <span
          :key="index + 'arrow'"
          v-html="'&nbsp;&nbsp;>&nbsp;&nbsp;'"
        ></span>
        <span
          :key="item.label"
          :class="{'active': item.value == selected}"
          @click.stop="handleClickItem({type:'link', data: item, index})"
        >
          {{ item.label }}
          <i 
            v-show="(item.value == selected) && !isRw" 
            class="single-tree-select__breadCrumb_cancel"
          ></i>
        </span>
      </template>
    </div>
    <div
      ref="cateAnimItem"
      class="single-tree-select__breadCrumb_anim"
    >
      {{ animItemText }}
      <i 
        v-if="!isRw"
        class="single-tree-select__breadCrumb_cancel"
      ></i>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeaderLink',
  inject: {
    constantData: {
      default: () => ({})
    }
  },
  props: {
    cateLinks: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: [String, Number],
      default: ''
    },
    animItemText: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    needBold: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () { 
    return {
      observer: null
    }
  },
  computed: {
    isRw () {
      return this.constantData.IS_RW
    }
  },
  watch: {
    cateLinks: {
      handler () {
        this.resetObserve()
      },
      immediate: true,
    },
  },
  mounted () {
    this.initObserver()
  },
  methods: {
    handleClickItem (payload) {
      if (this.disabled) return
      this.$emit('clickItem', payload)
    },
    // 监听元素可见时，滚动到最后一个元素
    initObserver () {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) return

          const timer = setTimeout(() => {
            clearTimeout(timer)
            entry.target.scrollTo({ left: 1000, behavior: 'smooth' })
            observer.unobserve(entry.target) // 仅第一次触发
          }, 300)
        })
      })

      observer.observe(this.$refs.catebreadcrumbs)
      this.observer = observer
    },

    resetObserve () {
      if (!this.observer) return
      this.observer.disconnect()
      this.observer.observe(this.$refs.catebreadcrumbs)
    }
  }
}
</script>

<style lang="less" scoped>
  .single-tree-select {
    &__breadCrumb {
      display: block;
      flex: 1;
      font-weight: 700;
      .font-dpr(24px);
      position: relative;
      min-height: 22px;
      line-height: 1.7;
      &_allLangPosition {
        .flexbox();
        position: absolute;
        visibility: hidden;
        font-size: 12px;
      }
      &_items {
        .flexbox();
        .align-center();
        width: 100%;
        position: absolute;
        overflow-x: auto;
        white-space: nowrap;
        color: @sui_color_main;
        .active {
          position: relative;
          padding: 0 .32rem;
          text-align: center;
          border: 1px solid @sui_color_main;
        }
      }
      &_title {
        color: #999;
        font-weight: normal;
      }
      &_bold {
        font-weight: bold;
        color: #222;
        font-size: 14px;
      }
      &_anim {
        position: absolute;
        z-index: @zindex-hack;
        white-space: nowrap;
        padding: 0 .2rem;
        font-size: 12px;
        color: #222;
        line-height: 20px;
        background-color: #fff;
        visibility: hidden;
        /* sh:begin */
        border: 1px solid #222;
        /* rw:begin */
        border: 1px solid #ff696e;
        color: #ff696e;
      }
      &_cancel {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 16px 16px 0;
        border-color: transparent @color_brand transparent transparent;
        &::before {
          position: absolute;
          top: -1px;
          .right(-18px);
          line-height: 1;
          content: 'x';
          width: 12px;
          height: 12px;
          line-height: 12px;
          color: #fff;
          text-align: center;
          transform: scale(0.8);
        }
      }
    }

  }
</style>
