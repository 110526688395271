<template>
  <!-- 商品区域 -->
  <ProductItem
    ref="goodsItem"
    :key="`${analysisCode}_${shopProduct?.goods_id}`"
    v-tap="getTapAnalysis(shopProduct, productItemIndex)"
    v-expose="getExposeAnalysis(shopProduct, productItemIndex)"
    :item="shopProduct"
    :index="productItemIndex"
    :config="productItemConfig"
    :language="language"
    :constant-data="constantData"
    :column="productsNum"
    :da-rid="false"
    :context="context"
    :css-right="cssRight"
    :crop-rate="cropRate"
    :lcp="isFirstPage && productItemIndex < 5"
    :comp-src="'multi-line-shop'"
    :comp-img-design-width="192"
    :price-font-size="priceFontSize"
    :suggested-sale-type="suggestedSaleType"
    :class="{ 'product-simple__suggested_label': isShowLowestPriceLabelLang }"
    class="multi-line-shop-item"
    @clickItem="clickItem()"
  >
    <template #imgBottom>
      <div
        :style="beltBgStyle(shopProduct.homeBelt)"
        class="multi-line-shop-item__bottom"
      >
        <SwiperSlide
          v-if="swiperList.length"
          ref="productTagSwiper"
          :item-length="swiperList.length"
          :intersectionObserver="true"
          observerLength
          :loop="swiperList.length > 1"
          :autoplay="2000"
          :vertical="true"
          style="height: 14px; width: 100%; padding-top: 2px"
        >
          <SwiperSlideItem
            v-for="(swiperItem, swiperIndex) in swiperList"
            :key="swiperIndex"
            class="swiper-slide"
            :class="swiperItem == 'storeWishCount' ? 'sales' : ''"
          >
            <template v-if="swiperItem == 'storeAttentionStatus'">
              <span class="follow">
                {{ productItem?.storeAttentionText || 'Following' }}
              </span>
            </template>
            <template v-if="swiperItem == 'storeWishCount'">
              {{ productItem.storeWishCount }}
            </template>
            <template v-if="swiperItem == 'storeRating'">
              <ProductItemStarComment
                :num-style="{
                  color: 'rgba(255, 255, 255, 0.8)',
                }"
                :star-style="{
                  transform: cssRight ? 'scaleX(-1)' : 'scaleX(1)',
                }"
                :cur-data="{
                  starComment: {
                    comment_num_str:
                      Number(productItem.storeRating) >= 5
                        ? 5
                        : Number(productItem.storeRating),
                  },
                }"
              />
            </template>
          </SwiperSlideItem>
        </SwiperSlide>
        <div class="multi-line-shop-item__storeinfo">
          <div class="left">
            {{ productItem.title }}
          </div>
          <Icon
            name="sui_icon_more_right_12px"
            class="icon-more-right"
            color="#FFF"
            size="10"
            :is-rotate="cssRight"
          />
        </div>
      </div>
    </template>
  </ProductItem>
</template>
<script>
import { stringifyQueryString } from '@shein/common-function'
import ProductItem from 'public/src/pages/components/ccc/home-bff-components/components/product-item-simple/index.vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin'
import useProductItemMixin from 'public/src/pages/components/ccc/components/mixins/use-product-item'
import ProductItemStarComment from 'public/src/pages/components/ccc/base/ProductItemStarComment.vue'
import { transfromAndCutImg } from 'public/src/services/resource'
import SwiperSlide from 'public/src/pages/components/swiperSlide/SwiperSlide.vue'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue'
export default {
  name: 'MultiLineShop',
  components: {
    ProductItem,
    ProductItemStarComment,
    SwiperSlide,
    SwiperSlideItem,
  },
  mixins: [mixins, useProductItemMixin],
  props: {
    ...commonProps,
    isFirstPage: {
      type: Boolean,
      default: true,
    },
    // 商品项配置
    productItemConfig: {
      type: Object,
      default: () => {},
    },
    productsList: {
      type: Array,
      default: () => [],
    },
    productItem: {
      type: Object,
      default: () => ({}),
    },
    // 商品项索引
    productItemIndex: {
      type: Number,
      default: 0,
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0,
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
    cropRate: {
      type: String,
      default: '3-4',
    },
    // 商品数量
    productsNum: {
      type: Number,
      default: 0,
    },
    // abt控制是否展示折扣标签，合规价
    homeDiscountNew: {
      type: String,
      default: '',
    },
    // 合规类型
    suggestedSaleType: {
      type: String,
      default: '',
    },
    // 埋点analysisCode
    analysisCode: {
      type: String,
      default: '',
    },
  },
  data() {
    const {
      metaData: { componentSize, templateType, priceFontSize = {} } = {},
    } = this.propData?.props || {}
    return {
      componentSize: Number(componentSize),
      templateType,
      priceFontSize,
    }
  },
  computed: {
    shopProduct() {
      let { shopRecProducts = [], shopRecProductsV2 = [] } =
        this.productItem ?? {}
      if (shopRecProductsV2?.length) {
        shopRecProducts = shopRecProductsV2
      }
      const productList = shopRecProducts?.slice(0, 1) || []
      const product = productList[0] || {}
      if (product?.homeBelt?.text) {
        product.homeBelt.text = ''
      }
      return {
        ...product,
      }
    },
    cate() {
      return {
        markMap: this.productItem.markMap, // 每个店铺位单独上报
        hrefType: 'storeHome',
        categoryIds: this.context?.curChannelInfo?.realCateIds || '',
        mallCodes: this.shopProduct?.mall_code || '',
        compIndex: this.index,
        hrefTarget: this.productItem?.store_code,
        item_loc: `${this.containerIndex + 1}_${this.productItemIndex + 1}`,
        component_area: this.componentSize, // 组件面积
        template: this.templateType, // 组件模板
        content_type: {
          homeBelttype: 'shop',
          homeBelttext: this.productItem?.title || '',
          homeBadgetype: this.productItem?.homeBadge?.type || '',
          homeBadgetext: this.productItem?.homeBadge?.text || '',
        },
      }
    },
    isShowLowestPriceLabelLang() {
      if (
        this.componentSize != 1 ||
        this.homeDiscountNew !== 'compliance' ||
        !this.shopProduct?.suggestedSalePriceInfo?.description
      )
        return false
      return true
    },
    swiperList() {
      const list = []
      if (Number(this.productItem.storeAttentionStatus) == 1) {
        list.push('storeAttentionStatus')
      }
      if (this.productItem.storeWishCount) {
        list.push('storeWishCount')
      }
      if (Number(this.productItem.storeRating) >= 4) {
        list.push('storeRating')
      }
      return list
    },
  },
  methods: {
    getTapAnalysis(item, i) {
      return this.getProductAnalysis('2-22-4', {
        index: i,
        item, //相当于埋点里面的product
        cate: {
          // 相当于埋点的item
          ...this.cate,
          extData: {
            product_select_id: item?.product_select_id ?? '-',
          },
          content_list: `storeHome_${item?.store_code ?? '-'}`,
        },
        useBffApi: true,
        setCode: `${this.analysisCode}_code`,
      })
    },
    getExposeAnalysis(item, i) {
      return this.getProductAnalysis('2-22-74', {
        type: 'expose',
        index: i,
        item,
        cate: {
          ...this.cate,
          product_select_id: item?.product_select_id ?? '-',
          content_list: `storeHome_${item?.store_code ?? '-'}`,
        },
        useBffApi: true,
        setCode: `${this.analysisCode}_code`,
      })
    },
    clickItem() {
      const params = {
        item: {
          ...this.metaData,
          hrefType: this.cate?.hrefType,
          hrefTarget: this.cate?.hrefTarget,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      const queryObj = {
        adp: this.shopProduct?.goods_id || '',
        main_goods_id: this.shopProduct?.goods_id || '',
        main_cate_id: this.shopProduct?.cat_id || '',
        store_code: this.productItem?.store_code || '',
        tabId: 'items',
        isStoreTabSticky: 1,
        src_store_select_id: this.productItem?.product_select_id || '-',
      }
      const qs = stringifyQueryString({ queryObj })
      url = `${url}${url.indexOf('?') > -1 ? '&' : '?'}${qs}`
      this.jumpLink({ url, hrefType: 'storeHome' })
    },
    cutImg(imgUrl, forceTransform = false) {
      const {
        deviceData = '',
        isSupportWeb = '',
        sceneMap = {},
        isSupprotCut = '',
      } = this.context?.RESOURCE_SDK || {}
      const cutData = {
        deviceData,
        isSupportWebp: forceTransform ? true : Boolean(isSupportWeb),
        isSupprotCut: forceTransform ? true : Boolean(isSupprotCut),
        imgUrl,
        designWidth: 950,
        sceneMap,
        exp: 'unCut=0#max=950',
      }
      return transfromAndCutImg(cutData)
    },
    beltBgStyle(homeBelt) {
      return {
        backgroundImage: `url(${this.cutImg(homeBelt?.bgImage?.src, true)})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
      }
    },
  },
}
</script>
<style lang="less" scoped>
.multi-line-shop-item {
  position: relative;
  &__bottom {
    width: 100%;
    padding: 0 2px;
  }
  &__storeinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .left {
      display: flex;
      color: #fff;
      overflow: hidden;
      align-items: center;
      font-size: 0.24rem;
      .text-overflow();
    }
    .icon-more-right {
      height: 10px;
    }
  }
}

.swiper-container {
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  height: 14px; /* 设置明确的高度 */
}

.swiper-slide {
  text-align: left;
  height: 14px;
  width: 100%;
  font-size: 0.2133rem;
  /deep/ .product-item__comment {
    margin: 0;
    height: 14px;
    line-height: 14px;
    min-height: unset;
    .suiiconfont {
      font-size: 0.2667rem;
    }
  }
}

.follow {
  display: inline-block;
  height: 12px;
  font-size: 9px;
  line-height: 10px;
  padding: 1px 2px;
  width: fit-content;
  background: rgba(255, 255, 255, 30%);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
}

.sales {
  color: rgba(255, 255, 255, 0.8);
  line-height: 14px;
  .text-overflow();
}
swiper-container {
  display: flex;
  flex-wrap: nowrap;
}
</style>
