
<script lang="jsx">
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

export default {
  name: 'BBackIcon',
  functional: true,
  render(h, context) {
    const { listeners } = context || {}
    const appConfigs = useAppConfigs()
    const { cssRight } = appConfigs.$envs || {}
    const ariaLabel = appConfigs.$language.BBackIcon?.Back || 'Back'
    const styles = {
      position: 'relative',
      display: 'inline-block',
      'text-decoration': 'none',
      'line-height': 1,
      'z-index': 1,
    }

    const handleClickBack = () => {
      listeners['goBack'] && listeners['goBack']()
      const langPath = appConfigs.$envs?.langPath
      if (location.pathname.indexOf('/shop-multiple-sets-') > -1 && (document.referrer && document.referrer.indexOf('shein') == -1)) { // 站外落地页为shoplook
        location.href = langPath
      } else {
        setTimeout(function () {
          history.back()
        }, 100)
      }
    }

    return (
      <a
        class="j-spa-goback"
        href="javascript:;"
        role="button"
        tabindex="0"
        aria-label={ariaLabel}
        style={styles}
        onClick={() => handleClickBack()}
      >
        <svg
          style={
            {
            width: 'auto',
            height: '1em',
            verticalAlign: 'middle',
            display: 'inline-block',
            lineHeight: 0,
            fontSize: '24px',
            transform: cssRight ? 'rotateY(180deg)' : 'none',
          }
          }
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.4871 3.95288L16.513 5.04719L9.09603 12L16.513 18.9529L15.4871 20.0472L6.90344 12L15.4871 3.95288Z"
            fill="var(--shein-common-header-icon-color, #000)"
          />
        </svg>
      </a>
    )
  },
}
</script>
