import type { Ref } from 'vue'
import { watch } from 'vue'
import { useScrollLock } from './use-scroll-lock'

let scrollbarPadding: number
let scrollbarWidth = 0

export function useLockBody(
  visible: Ref<boolean | undefined>,
  enableLock: Ref<boolean | undefined>,
) {
  const [locked, elLocked] = useScrollLock(
    () => (typeof window !== 'undefined' ? document.body : null),
    visible.value && enableLock.value,
  )

  watch([visible, enableLock], ([visibleValue, enableLockValue]) => {
    locked.value = (visibleValue && enableLockValue) || false
  })

  // 有滚动条宽度时，避免内容闪烁
  watch(
    elLocked,
    (value) => {
      if (!enableLock.value) return

      if (value) {
        scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
        if (scrollbarWidth) {
          scrollbarPadding = parseInt(window.getComputedStyle(document.body).paddingRight, 10)
          document.body.style.paddingRight = `${scrollbarPadding + scrollbarWidth}px`
        }
        return
      }
      if (scrollbarWidth) {
        document.body.style.paddingRight = scrollbarPadding ? `${scrollbarPadding}px` : ''
      }
    },
    { flush: 'sync' },
  )
}
