<template>
  <div class="module-content my-entry-block more-service-section">
    <div class="module-content-title">
      <h3 tabindex="0">
        {{ gbLanguage.SHEIN_KEY_PWA_15172 }}
      </h3>
      <span 
        v-expose="{
          id: '1-25-11-34',
          data: {}
        }"
        v-enterkey
        tabindex="0"
        role="button"
        @click="clickViewAll"
      >
        {{ gbLanguage.SHEIN_KEY_PWA_15040 }}
        <Icon
          name="sui_icon_more_right_12px_2"
          size="12px"
        />
      </span>
    </div>

    <div
      v-expose="{
        id: '1-25-11-15',
        data: {
          orderstatus_num: orderStatusReport,
        }
      }"
      class="me-list fsp-element"
    >
      <div
        v-for="(item) in showOrderEntries"
        :key="item.id"
        v-enterkey
        :class="`me-li waves-btn waves-btn-auto waves-effect waves-classic me-li-item-${item.id}`"
        :style="{ width: showOrderEntries.length > 4 ? '20%' : '25%'}"
        role="button"
        tabindex="0"
        :da-event-click="item.dataEventId || ''"
        :data-param="item.iconNum"
        :data-text="item.title"
        :data-label="item.usLabel"
        @click="() => handleMyOrdersEntry(item)"
      >
        <div>
          <component
            :is="iconMap[item.icon]"
            class="me-li-icon"
            size="24px"
          />
          <em 
            v-if="item.iconNum"
            class="order-num"
          >
            {{ item.iconNum > 99 ? '99+' : item.iconNum }}
          </em>
        </div>
        <p class="me-li__title">
          {{ htmlDecode({ text: item.title }) }}
        </p>
      </div>
    </div>
    <!-- orderPanel 楼层-->
    <OrderPanel
      v-if="hasOrderInfo"
      :is-over-four="showOrderEntries?.length > 4"
      :order-group="myOrderService?.orderGroup || {}"
      :order-popup="props.baseOtherInfo?.orderRetention || {}"
    />
  </div>
</template>
<script setup>
import { defineEmits, computed } from 'vue'
import { JUMP_URL } from '../consts'
import { htmlDecode } from '@shein/common-function'
import { Icon, sui_icon_me_unpaid_24px, sui_icon_me_processing_24px, sui_icon_me_shipped_24px, sui_icon_me_review_24px, sui_icon_me_returns_24px } from '@shein-aidc/icon-vue2'
import OrderPanel from './OrderPanel.vue'
import { getNum } from 'public/src/pages/user_index/analysis/utils'
import { isLogin } from 'public/src/pages/common/utils/index.js'

const emits = defineEmits(['routerPush'])
const props = defineProps({
  personalInfo: { type: Object, default: () => {} },
  dynamicService: { type: Object, default: () => {} },
  baseOtherInfo: { type: Object, default: () => {} },
})

const {
  language: gbLanguage,
} = gbCommonInfo

const iconMap = {
  sui_icon_me_unpaid_24px,
  sui_icon_me_processing_24px,
  sui_icon_me_shipped_24px,
  sui_icon_me_review_24px,
  sui_icon_me_returns_24px,
}
const reortOrderMap = {
  sui_icon_me_unpaid_24px: 'unpaid',
  sui_icon_me_processing_24px: 'processing',
  sui_icon_me_shipped_24px: 'shipped',
  sui_icon_me_review_24px: 'review',
  sui_icon_me_returns_24px: 'returns',
}

/* computed */
const myOrderService = computed(() => props.dynamicService.serviceList?.find(item => item.type === 'myOrder'))
const hasOrderInfo = computed(() => !!myOrderService.value?.orderGroup && !!props.baseOtherInfo?.orderRetention)
const myOrders = computed(() => [{
  id: 'unpaid',
  statusType: 1,
  title: gbLanguage.SHEIN_KEY_PWA_15309,
  icon: 'sui_icon_me_unpaid_24px',
  dataEventId: '',
  iconNum: props.personalInfo?.waiting_payment,
  scence: 'orders',
  usLabel: 'Unpaid', // 埋点需要us语言
}, {
  id: 'processing',
  statusType: 2,
  title: gbLanguage.SHEIN_KEY_PWA_15215,
  icon: 'sui_icon_me_processing_24px',
  dataEventId: '',
  iconNum: props.personalInfo?.processing_order,
  scence: 'orders',
  usLabel: 'Processing',
}, {
  id: 'shipped',
  statusType: 3,
  title: gbLanguage.SHEIN_KEY_PWA_15213,
  icon: 'sui_icon_me_shipped_24px',
  dataEventId: '',
  iconNum: props.personalInfo?.waiting_receive,
  scence: 'orders',
  usLabel: 'Shipped',
}, {
  id: 'review',
  statusType: 5,
  title: gbLanguage.SHEIN_KEY_PWA_15162,
  icon: 'sui_icon_me_review_24px',
  dataEventId: '',
  link: '/user/review_center',
  iconNum: props.personalInfo?.waiting_review,
  scence: 'reviews',
  usLabel: 'Review',
}, {
  id: 'returns',
  statusType: 4,
  title: gbLanguage.SHEIN_KEY_PWA_15626,
  icon: 'sui_icon_me_returns_24px',
  dataEventId: '',
  iconNum: props.personalInfo?.after_sale,
  scence: 'orders',
  usLabel: 'Returns',
}])

const bLogin = isLogin()

const showOrderEntries = computed(() => myOrders.value.filter(({ id }) => id )) // 之前会根据 abt 过滤 id !== 'review' 现在全量展示
const orderStatusReport = computed(() => showOrderEntries.value.map(item => `${item.title.toLowerCase()}_${getNum(item.iconNum, bLogin)}`).join(','))

/* methods */
const handleMyOrdersEntry = (item) => {
  daEventCenter.triggerNotice({
    daId: '1-25-11-1018',
    extraData: {
      order_status: reortOrderMap[item.icon],
      num: getNum(item.iconNum, bLogin),
    }
  })
  if (item.link) {
    emits('routerPush', { target: item.link, orderStatusType: '', scence: item.scence })
  } else {
    emits('routerPush', { target: JUMP_URL.USER_ORDERS_LIST, orderStatusType: item.statusType, scence: item.scence })
  }
}

// 点击view all
const clickViewAll = () => {
  // 埋点
  daEventCenter.triggerNotice({
    daId: '1-25-11-1017',
  })
  emits('routerPush', { target: JUMP_URL.USER_ORDERS_LIST, orderStatusType: 0, scence: 'orders' })
}

</script>

<style lang="less" scoped>
.more-service-section {
  overflow: hidden;
}

.my-entry-block {
  min-height: 2.606667rem;
}

.module-content {
  border-bottom: 20/75rem solid #f6f6f6;
  background: #fff;
  padding: 0.32rem 0 0;
  position: relative;
  z-index: 1;

  .module-content-title {
    .flexbox();
    .space-between();
    padding: 0 0.32rem;

    h3 {
      .font-dpr(28px);
      font-weight: bold;
      color: #222222;
    }

    span {
      color: @sui_color_gray_dark3;
      .font-dpr(24px);
      display: flex;
      align-items: center;

      [class*='iconfont'] {
        .flip();
      }
    }
  }

  .me-list {
    flex-flow: row nowrap;
    padding: 0 8/75rem 24/75rem;
    background-color: #fff /* rw:ignore */;

    .me-li {
      position: relative;
      display: inline-block;
      width: 25%;
      text-align: center;
      .font-dpr(26px);
      word-break: break-word;
      padding: 24/75rem 8/75rem 0;
      vertical-align: top;

      .me-li-icon {
        display: block;
        .font-dpr(48px);
        margin-bottom: 0.1rem;
        height: 38/75rem;
        line-height: 38/75rem;
      }

      .order-num {
        position: absolute;
        top: 0.2rem;
        left: 50%;
        min-width: 0.32rem;
        height: 0.4rem;
        padding: 0 0.1rem;
        box-sizing: border-box;
        .font-dpr(20px);
        display: inline-block;
        border: 1px solid #222;
        border-radius: 0.4rem;
        text-align: center;
        line-height: 0.4rem;
        background: #fff;
        color: #222;
      }

      &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        .font-dpr(24px);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .me-li-item-processing {
      vertical-align: top;
    }
  }
}
</style>
