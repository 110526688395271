import { ref, computed, watch } from 'vue'
import { useModules } from '../modules/useModules.ts'
import { getEstimatedInfo, checkEstimatedWithConfig } from './utils.ts'


export const useCommonInfo = (arg) => {

  const {
    featureConfig,
    isSoldOut,
    currentStock,
    skuInfo,
  } = arg

  const {
    language,
    productInfo,
    priceInfo,
    detailPromotionFloor,
    userInfo,
    gbCommonInfo,
    fsAbt,
  } = useModules()

  const goodsId = computed(() => productInfo?.value?.goods_id)
  const goodsSn = computed(() => productInfo?.value?.goods_sn)
  const mallSoldOut = computed(() => currentStock.value == 0)

  const skcPromotionInfo = computed(() => detailPromotionFloor?.value?.detailPromotionInfo || [])

  const flashPromotion = computed(() => skcPromotionInfo?.value?.find?.(item => item.typeId == 10) || null)
  const flashType = computed(() => flashPromotion?.value?.flashType || null)
  const newFlashPromotion = computed(() => !!flashPromotion.value && skcPromotionInfo?.value?.find?.(item => item.typeId == 10 && [2, 3, 4].includes(Number(item.flashType))) || null)
  const isFlash = computed(() => !!flashPromotion?.value)
  const isNormalFlash = computed(() => [1, 4, 5].includes(Number(flashType?.value)))
  const isSpecialFlash = computed(() => [2, 3].includes(Number(flashType?.value)))
  const isBrandFlash = computed(() => flashType.value == 6)

  const isPaidUser = computed(() => userInfo?.value?.isPaidMember == 1)
  const sheinClubPromotionInfo = computed(() => skcPromotionInfo.value?.find?.(item => item.typeId == 29) || null)

  const exclusiveInfo = computed(() => {
    if (Number(priceInfo?.value?.showTypeId) == 12 && priceInfo?.value?.showPrice) {
      const { SHEIN_KEY_PWA_24353, SHEIN_KEY_PWA_17305, SHEIN_KEY_PWA_15146 } = language?.value || {}
      return {
        isS3newpriceAbtNew: fsAbt?.value?.EstimatedPrice?.p?.S3newprice == 'new',
        imgLink: 'https://img.ltwebstatic.com/images3_ccc/2024/07/23/00/1721706854eda1168bf47805172a56be15e10d076c.png',
        amountWithSymbol: priceInfo?.value?.showPrice?.amountWithSymbol,
        text: SHEIN_KEY_PWA_24353,
        introText: SHEIN_KEY_PWA_17305,
        btnText: SHEIN_KEY_PWA_15146,
      }
    }
    return null
  })

  const isShowPrice = computed(() => (isPaidUser.value && !!sheinClubPromotionInfo.value) || (isSpecialFlash.value || flashType.value == 4) || (!!exclusiveInfo?.value && exclusiveInfo?.value?.isS3newpriceAbtNew))

  const price = computed(() => newFlashPromotion.value ? priceInfo?.value : (skuInfo?.value?.priceInfo || priceInfo?.value || {}))
  const finalPrice = isShowPrice.value ? price.value?.showPrice : price.value?.salePrice

  const complianceMode = computed(() => featureConfig?.value?.needComplianceModel?.complianceMode)
  const complianceTipsMode = computed(() => featureConfig?.value?.needComplianceModel?.complianceTipsMode)
  const complianceModeDe = computed(() => featureConfig?.value?.needComplianceModel?.complianceModeDe)
  const isHitComplianceMode = computed(() => {
    const { complianceMode, complianceTipsMode, complianceModeDe } = featureConfig?.value?.needComplianceModel || {}
    return complianceMode || complianceTipsMode || complianceModeDe
  })

  const isInversion = computed(() => price.value?.suggestedSalePriceInfo?.isInversion)

  const cssRight = computed(() => gbCommonInfo?.value?.cssRight)

  return {
    goodsId,
    goodsSn,
    isSoldOut,
    mallSoldOut,
    skcPromotionInfo,
    flashPromotion,
    flashType,
    newFlashPromotion,
    isFlash,
    isNormalFlash,
    isSpecialFlash,
    isBrandFlash,
    isPaidUser,
    sheinClubPromotionInfo,
    exclusiveInfo,
    isShowPrice,
    price,
    finalPrice, // 最终接口/埋点取这个
    complianceMode,
    complianceTipsMode,
    complianceModeDe,
    isHitComplianceMode,
    isInversion,
    cssRight,
  }
}

/* ======= 价格 ======= */
export const useEstimatedInfo = (arg) => {
  const {
    language,
    priceInfo,
    fsAbt,
  } = useModules()
  const {
    isSoldOut,
    currentStock,
    featureConfig,
    skuInfo,
  } = arg
  const {
    goodsSn,
    mallSoldOut,
    isFlash,
    isNormalFlash,
    isSpecialFlash,
    isBrandFlash,
    isPaidUser,
    sheinClubPromotionInfo,
    exclusiveInfo,
    complianceMode,
    complianceModeDe,
    cssRight,
  } = useCommonInfo({
    featureConfig,
    isSoldOut,
    currentStock,
    skuInfo,
  })
  const skcEstimatedInfo = ref(null)
  watch(() => priceInfo?.value, (val) => {
    if (!val) return
    const { EstimatedPrice, EstimatedShowType, EstimatedNothreShowType, middleast, AllAreaPcsPrice } = fsAbt?.value || {}
    let result = getEstimatedInfo({
      skcPriceInfo: priceInfo?.value,
      skuPriceInfo: null,
      isSoldOut: isSoldOut?.value || mallSoldOut?.value,
      abts: {
        EstimatedPrice,
        EstimatedShowType,
        EstimatedNothreShowType,
        middleast,
        AllAreaPcsPrice,
      },
      otherInfo: {
        exclusiveInfo: exclusiveInfo.value,
        sheinClubPromotionInfo: sheinClubPromotionInfo.value,
        isPaidUser: isPaidUser.value,
        complianceMode: complianceMode.value,
        complianceModeDe: complianceModeDe.value,
        isFlash: isFlash.value,
        isNormalFlash: isNormalFlash.value, // isNormalFlash isSpecialFlash isBrandFlash 三个同时只会生效一个
        isSpecialFlash: isSpecialFlash.value,
        isBrandFlash: isBrandFlash.value,
        language: language?.value,
        goodsSn: goodsSn.value,
        isQuickAdd: true,
        hasLink: featureConfig?.value?.estimatedTagCanClick,
        GB_cssRight: cssRight.value,
      },
    })
    result = checkEstimatedWithConfig(result, featureConfig?.value)
    skcEstimatedInfo.value = result
  }, { immediate: true })
  const estimatedInfo = computed(() => {
    if (featureConfig?.value?.closeEstimatedAndAbPrice) return null
    const { EstimatedPrice, EstimatedShowType, EstimatedNothreShowType, middleast, AllAreaPcsPrice } = fsAbt?.value || {}
    if (skcEstimatedInfo?.value && !skuInfo?.value?.sku_code) return skcEstimatedInfo.value
    let result = getEstimatedInfo({
      skcPriceInfo: priceInfo?.value,
      skuPriceInfo: skuInfo?.value?.priceInfo,
      isSoldOut: isSoldOut.value || mallSoldOut.value,
      abts: {
        EstimatedPrice,
        EstimatedShowType,
        EstimatedNothreShowType,
        middleast,
        AllAreaPcsPrice,
      },
      otherInfo: {
        exclusiveInfo: exclusiveInfo.value,
        sheinClubPromotionInfo: sheinClubPromotionInfo.value,
        isPaidUser: isPaidUser.value,
        complianceMode: complianceMode?.value,
        complianceModeDe: complianceModeDe?.value,
        isFlash: isFlash.value,
        isNormalFlash: isNormalFlash.value, // isNormalFlash isSpecialFlash isBrandFlash 三个同时只会生效一个
        isSpecialFlash: isSpecialFlash.value,
        isBrandFlash: isBrandFlash.value,

        language: language?.value,
        goodsSn: goodsSn.value,
        isQuickAdd: true,
        hasLink: featureConfig?.value?.estimatedTagCanClick,
        GB_cssRight: cssRight.value,
      },
    })
    result = checkEstimatedWithConfig(result, featureConfig?.value)
    return result
  })
  return {
    skcEstimatedInfo,
    estimatedInfo,
  }
}
/* ======= 价格 ======= */


/* ======= 次级销售属性 ======= */

/* ======= 次级销售属性 ======= */

