<script lang="jsx">
import ClientOnly from 'vue-client-only'
import Lite from './Lite.vue'
import Enhance from './Enhance.vue'

export default {
  name: 'ProductItemSimpleEnhance',
  components: {
    Lite,
    Enhance,
  },
  inheritAttrs: false,
  props: {
    lcp: {
      type: Boolean,
      default: false,
    },
    showViewMore: {
      type: Boolean,
      default: false,
    },
    viewMoreText: {
      type: String,
      default: '',
    },
    context: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {},
    },
    showGoodsBottom: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
    column: {
      type: [String, Number],
      default: '',
    },
    constantData: {
      type: Object,
      default: () => {},
    },
    goodsItemInfo: {
      type: Object,
      default: () => {},
    },
    language: {
      type: Object,
      default: () => {},
    },
    brand: {
      type: String,
      default: 'shein',
    },
    cssRight: {
      // GB_cssRight存在不准确的情况，所以传入cssRight兼容
      type: Boolean,
      default: false,
    },
    // 来源组件
    compSrc: {
      type: String,
      default: '',
    },
    cropRate: {
      type: String,
      default: '3-4',
    },
    suggestedSaleType: {
      type: String,
      default: '',
    },
    // bff返回驼峰字体大小
    priceFontSize: {
      type: Object,
      default: () => {},
    },
    // 用于控制首页组件的裁切
    compImgDesignWidth: {
      required: true,
      type: Number,
      default: 192,
    },
    hiddenPrice: {
      type: Boolean || Number,
      default: false,
    },
  },
  render(h) {
    const enhanceCtx = {
      props: this.$props,
      attrs: this.$attrs,
      on: this.$listeners,
      slots: this.$slots,
      scopedSlots: this.$scopedSlots,
    }

    // 未启用 abt 或者客户端渲染时，直接渲染 Enhance
    if (this.context?.abtInfoObj?.ProductCardSwitch !== '1' || this.context?.clientRender) {
      return <Enhance {...enhanceCtx} />
    }

    const isSSR = typeof window === 'undefined'

    return (
      <div class="product-item-simple-enhance-container">
        <Lite v-show={isSSR} props={this.$props} />

        <ClientOnly>
          <Enhance
            class="not-fsp-element"
            {...enhanceCtx}
          />
        </ClientOnly>
      </div>
    )
  },
}
</script>

<style lang="less">
.product-item-simple-enhance-container {
  width: 100%;
}
</style>
