<template>
  <div :class="prefixCls('sp-footer_wrapper')">
    <s-alert
      v-if="customContentData.visible"
      v-tap="{
        id: 'click_custom_content:simple',
        data: customContentTap,
      }"
      :class="prefixCls('sp-footer_alert')"
      type="info"
      jumpable
      @click.native.stop="openPreviewDialog"
      >{{ customContentData.text }}
    </s-alert>
  </div>
</template>
<script>
export default {
  name: 'customContent',
}
</script>
<script setup>
import { computed, ref, defineAsyncComponent } from 'vue'
import { Alert as SAlert } from '@shein/sui-mobile'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import customDetailDialog from 'public/src/pages/common/customDetailDialog/index.js'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

// ----------- method -----------
const openPreviewDialog = () => {
  customDetailDialog.open({
    ...props.data?.product?.customization_info?.preview,
  })
}

// ---------- computed ----------

/**
 * @description 渲染数据
 * */
const customContentData = computed(() => {
  const { aggregateProductBusiness } = props.data
  const customContent =
    aggregateProductBusiness?.customizationPopInfo?.customContent

  return {
    visible: !!customContent,
    text: customContent,
  }
})
/**
 * @description 埋点数据集
 * */
const customContentTap = computed(() => {
  const { product } = props.data
  const preview = product?.customization_info?.preview

  return {
    images: preview?.images?.length || 0,
    preview: preview?.effects?.length || 0,
    texts: preview?.texts?.length || 0,
  }
})
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}sp-footer {
  &_alert {
    background: #f6f6f6;
    padding: 0.16rem;
    margin-top: 0.21333333rem;
    width: 100%;
  }
}
</style>
