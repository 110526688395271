/* eslint-disable require-atomic-updates */
import { getCurrentInstance, ref, onMounted, onUnmounted, computed } from 'vue'
import { abtservice } from 'public/src/services/abt'
import { useGlobalMapState } from './store.js'
import { useProxyHosts } from '@shein-aidc/bs-sdk-libs-manager'

const ProxyHosts = useProxyHosts()

export default function useSearchIcon({ route, pageScene }) {
  const { proxy: vm = {} } = getCurrentInstance()
  const { goodsDetailInfo } = useGlobalMapState(['goodsDetailInfo'])
  
  // 是否展示搜索框，false展示icon，true展示框
  const isShowInput = ref(false)
  const handleIsShowInput = (isShow) => {
    isShowInput.value = isShow
  }

  const extendsReport = { // 扩展埋点字段用
    result_content: '-'
  }

  const goPresearch = function () {
    if (vm.$route.mate) {
      vm.$route.mate.isNewPage = true
    }
    if (vm.$route.params) {
      vm.$route.params.isNewPage = true // 加不了mate ，就加在params里
    }
  }

  const searchAbtResult = ref('')
  const preSearchEntry = ref(null)
  const currentPageName = ref('')
  const diaplay = computed(() => {
    return !isShowInput.value && !route.value?.meta?.notNeedCommonHeader
  })
  const routeQuery = computed(() => {
    return {
      pageType: 'all',
      pageName: currentPageName.value,
      pagefrom: currentPageName.value,
      ...(['goodsDetail'].includes(pageScene.value) && goodsDetailInfo.value?.goodsId ? { goods_id: goodsDetailInfo.value?.goodsId } : null),
      ...(['goodsDetail'].includes(pageScene.value) && goodsDetailInfo.value?.goodsId ? { cate_id: goodsDetailInfo.value?.goodsId } : null),
      Searchboxform: 1,
    }
  })

  const fetchAbt = async () => {
    const newPosKeys = ['SearchSuggestNew', 'SearchDefaultNew', 'SearchHotGD', 'SearchHotNew', 'MoreSearchWord', 'NewPicSearch', 'HomeSearch']
    const posKeys = ['SearchSuggestwordFeedback', 'PicSearch']
    const posKeysParams = {
      newPosKeys: newPosKeys.join(),
      posKeys: posKeys.join()
    }
    if (!searchAbtResult.value) {
      await abtservice.getUserAbtResult(posKeysParams)
      // @ts-ignore
      searchAbtResult.value = abtservice.getUserAbtResultForAnalysis({
        posKeys: [...newPosKeys, ...posKeys].join()
      }).sa
    }
  }

  // onMounted(() => {
  //   // 获取埋点实验
  //   appEventCenter.$on('pageOnload', async () => {
  //     if (!searchAbtResult.value) {
  //       await fetchAbt()
  //     }
  //     currentPageName.value = window.SaPageInfo?.page_name
  //     diaplay.value && preSearchEntry.value?.exposeReport({
  //       abtest: searchAbtResult.value
  //     })
  //   })
  // })
  onMounted(async () => {
    // ssr 进来没有触发pageOnload
    currentPageName.value = window.SaPageInfo?.page_name
    if(ProxyHosts && ProxyHosts.CommonHeader){        
      ProxyHosts.CommonHeader.subscribe('BHeaderSearchIcon', {
        async pageOnload() {
          if (!searchAbtResult.value) {
            await fetchAbt()
          }
          currentPageName.value = window.SaPageInfo?.page_name
          diaplay.value && preSearchEntry.value?.exposeReport({
            abtest: searchAbtResult.value
          })
        },
      })
    }
  })
  onUnmounted(() => {
    if(ProxyHosts && ProxyHosts.CommonHeader){
      ProxyHosts.CommonHeader.describe('BHeaderSearchIcon')
    }
  })
  return {
    isShowInput,
    goPresearch,
    preSearchEntry,
    searchAbtResult,
    handleIsShowInput,
    searchIconQuery: routeQuery,
    searchIconExtendsReport: extendsReport,
  }
}
