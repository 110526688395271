<template>
  <div>
    <s-drawer
      :visible="visible"
      append-to-body
      show-close
      class="select-address-type"
      @update:visible="closeDrawer"
    >
      <template slot="top">
        <div>
        </div>
      </template>
      <div>
        <div class="select-address-type__title">
          {{ shippingAddressChangeInfo.title }}
        </div>
        <div>
          <div class="odd-option">
            <s-radio
              v-model="selectValue"
              label="odd"
            >
              <span class="odd-title">{{ oddInfo.title }}</span>
              <span v-if="oddInfo.tag_list">
                <span
                  v-for="(item, index) in oddInfo.tag_list"
                  :key="index"
                  class="green-tag"
                >
                  {{ item.text }}
                </span>
              </span>
            </s-radio>
            <div class="sub-container">
              <div class="sub-title">
                {{ oddInfo.sub_title }}
              </div>
              <div class="benefit-content">
                <div class="benefit-content-title">
                  {{ oddInfo.lure_point_title }}
                </div>
                <div
                  v-for="(item, index) in oddInfo.lure_point"
                  :key="index"
                  class="benefit-text"
                >
                  <Icon
                    name="sui_icon_success_18px"
                    size="16px"
                    color="#198055"
                  />
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
          <div class="shein-option">
            <s-radio
              v-model="selectValue"
              label="shein"
            >
              <span class="shein-title">{{ sheinAddressInfo.title }}</span>
            </s-radio>
            <div class="sub-container">
              <div class="sub-title">
                {{ sheinAddressInfo.sub_title }}
              </div>
              <div class="benefit-content">
                <div class="benefit-content-title">
                  {{ sheinAddressInfo.lure_point_title }}
                </div>
                <div
                  v-for="(item, index) in sheinAddressInfo.lure_point"
                  :key="index"
                  class="benefit-text"
                >
                  <Icon
                    name="sui_icon_failed_14px"
                    size="16px"
                    color="#BBBBBB"
                  />
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <s-button
            :type="[ 'primary', 'H72PX' ]"
            width="100%"
            @click="handleConfirm"
          >
            {{ shippingAddressChangeInfo.button }}
          </s-button>
        </div>
      </template>
    </s-drawer>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'

export default {
  name: 'EditAddressSelect',
  components: {
    Icon,
  },
  props: {
    language: {
      type: Object,
      default: function () {
        return {}
      },
    },
    order: {
      type: Object,
      default: function () {
        return {}
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectValue: 'odd'
    }
  },
  computed: {
    shippingAddressChangeInfo () {
      return this.order?.shipping_address_change_info || {}
    },
    oddInfo () {
      return this.shippingAddressChangeInfo?.support_edit_address_list_info?.find(item => item.type == 'odd')
    },
    sheinAddressInfo () {
      return this.shippingAddressChangeInfo?.support_edit_address_list_info?.find(item => item.type == 'shein')
    }
  },
  mounted() {
    const { billno = '' } = this.order
    daEventCenterV2.triggerNotice({
      id: 'expose_odd_address:simple',
      data: {
        orderid: billno
      }
    })
  },
  methods: {
    closeDrawer() {
      this.$emit('update:visible', false)
    },
    handleConfirm() {
      const { billno = '' } = this.order
      daEventCenterV2.triggerNotice({
        id: 'click_odd_address:simple',
        data: {
          orderid: billno,
          button: this.selectValue
        }
      })
      if (this.selectValue == 'shein') {
        this.$emit('select-shein-edit')
      } else {
        this.$emit('select-odd-edit')
      }
    },
  }
}
</script>

<style lang="less">
.odd-option,
.shein-option {
  .S-radio_normal {
    margin-right: 0;
  }
}
.select-address-type {
  .S-drawer__header_normal {
    border-bottom: none;
  }
  .S-drawer__normal-footer {
    padding: 24/75rem;
    height: 100%;
    margin-top: 0;
  }
}
</style>
<style lang="less" scoped>
.select-address-type {
  &__title {
    .font-dpr(28px);
    font-weight: bold;
    text-align: center;
  }
}
.green-tag {
  color: @sui_color_safety;
  background: @sui_color_safety_bg;
  .font-dpr(20px);
  padding: 8/75rem;
  margin-left: 8/75rem;
}
.shein-title,
.odd-title {
  .font-dpr(28px);
}
.sub-title {
  color: @sui_color_gray_dark3;
  .font-dpr(24px);
}
.benefit-content {
  padding: 16/75rem 24/75rem;
  background: #F6F6F6;
}
.benefit-content-title,
.benefit-text {
  color: @sui_color_gray_dark2;
}
.odd-option,
.shein-option {
  padding: 16/75rem 24/75rem;
}
.sub-container {
  padding-left: 50/75rem;
}
</style>
