<template>
  <div
    class="benefit-bar"
    :class="{
      'benefit-bar_mini': isMiniBar
    }"
    :style="barStyle"
  >
    <component
      :is="mainComponent"
      class="benefit-bar__main"
      :title="guideData.title"
      :compressed-title="guideData.compressedTitle"
      :end-time="guideData.endTime"
      :config="config"
      :language="language"
    />
    <div
      class="benefit-bar__click-area"
      @click="emits('openDrawer')"
    >
      <div
        class="benefit-bar__benefits"
        :class="{
          'benefit-bar__benefits_ar': GB_cssRight
        }"
      >
        <Benefits
          :is-mini="isMiniBar"
          :benefit-component-type="type"
          :reward-list="guideData.rewardList"
        />
      </div>
      <sui_icon_more_right_12px
        v-if="+guideData.showDetailPopup"
        :is-rotate="GB_cssRight"
        class="benefit-bar__details"
        size="12px"
        color="#767676"
      />
    </div>

    <BenefitTag
      v-if="guideData.tag"
      class="benefit-bar__tag"
      v-bind="guideData.tag"
    />
  </div>
</template>

<script name="BenefitBar" setup lang="ts">
import { computed } from 'vue'
// @ts-ignore 该库未提供类型定义
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue2'

import Main from './Main.vue'
import MiniMain from './MiniMain.vue'
import Benefits from './Benefits.vue'
import BenefitTag from '../BenefitTag.vue'
import { E_BenefitFloor, type C_BenefitFloor } from '../../../../types'

const { GB_cssRight = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const props = withDefaults(
  defineProps<{
    language: C_BenefitFloor.LanguageType,
    type: E_BenefitFloor.BenefitComponentType,
    data: C_BenefitFloor.Data,
    config?: C_BenefitFloor.Config
  }>(),
  {
    type: E_BenefitFloor.BenefitComponentType.Bar,
  },
)
const emits = defineEmits(['openDrawer'])

const barStyle = computed(() => {
  let style = { '--arrow-display': 'block' } as any
  if (props.config?.hideBorder) {
    style = {
      ...style,
      '--arrow-display': 'none',
      borderBottom: 'none',
    }
  }
  return style
})
const isMiniBar = computed(() => {
  return props.type === E_BenefitFloor.BenefitComponentType.MiniBar
})
const mainComponent = computed(() => {
  if (isMiniBar.value) return MiniMain
  return Main
})
const guideData = computed(() => props.data.guide || {})

</script>

<style lang="less" scoped>
.benefit-bar {
  width: 100%;
  height: 90/75rem;
  padding-left: 24/75rem;
  background-color: #fff;
  background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/11/19/ea/1731986778ad2faf9910acc4148570e2de975ddf2e.webp');
  background-size: 100% 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 2/75rem solid #FFBAC1;
  border-image: linear-gradient(to right, #FFD2A8 0%, #FFBAC1 72.9259%, #FFBAC1 100%) 1;
  box-sizing: border-box;

  &__main {
    flex: 1;
  }

  &__benefits {
    margin-left: 16/75rem;
    max-width: 6.08rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &_ar::after {
      transform: rotate(180deg);
    }

    &::after {
      width: 26/75rem;
      height: 100%;
      content: '';
      background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/11/19/88/1731985205e3e9069bfc2090809c2f71ca94233517.webp');
      background-size: 100% 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  &__details {
    padding-left: 8/75rem;
  }

  &__click-area {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 16/75rem;
  }

  &__tag {
    position: absolute;
    left: -16/75rem;
    top: 0;
    transform: translateY(-50%);
  }

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 15/75rem solid transparent;
    border-right: 15/75rem solid transparent;
    border-top: 13/75rem solid #FFBAC1;
    position: absolute;
    right: var(--arrow-right, 191/75rem);
    bottom: -12/75rem;
    display: var(--arrow-display);
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 15/75rem solid transparent;
    border-right: 15/75rem solid transparent;
    border-top: 13/75rem solid #FEEBEC;
    position: absolute;
    right: var(--arrow-right, 191/75rem);
    bottom: -10/75rem;
    display: var(--arrow-display);
  }
}

.benefit-bar_mini {
  height: 64/75rem;

  .benefit-bar__main {
    flex: 1;
    min-width: 264/75rem;
  }

  .benefit-bar__benefits {
    max-width: 5.12rem;

    // &::before {
    //   width: 60/75rem;
    //   height: 100%;
    //   content: '';
    //   background: linear-gradient(
    //     to right,
    //     rgba(255, 241, 235, 0) 0%,
    //     rgba(255, 240, 234, 0.7) 40%,
    //     rgba(255, 240, 233, 1) 50%,
    //     rgba(255, 239, 232, 1) 100%
    //   );
    //   position: absolute;
    //   left: -60/75rem;
    //   top: 0;
    //   z-index: 1;
    // }
  }
}
</style>
