var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isShowPaypalChannelBtn)?_c('div',{staticClass:"order-pay-btn"},[(_vm.orderInfo.orderStatus == 0 || _vm.orderInfo.orderStatus == 12)?[(_vm.isShowApplepay)?_c('PaymentBtnNew',{key:"paypal_pay_now",staticClass:"order-pay-btn--channel",attrs:{"paypal-style":_vm.footerPaypalStyle,"apple-pay-style":_vm.applePayStyle,"store-name-space":_vm.moduleName,"before-start-pay":_vm.beforeStartPay},on:{"create-payment":_vm.createPayment,"create-order":function($event){return _vm.payNowOrVerifyNowEvt({ payType: 1 })}}}):_c('s-button',{directives:[{name:"expose",rawName:"v-expose",value:({ 
        id: '1-19-1-139', 
        data: {
          order_no: _vm.orderInfo.billno,
          pay_button_text: _vm.payNowText,
        } 
      }),expression:"{ \n        id: '1-19-1-139', \n        data: {\n          order_no: orderInfo.billno,\n          pay_button_text: payNowText,\n        } \n      }"},{name:"tap",rawName:"v-tap",value:({ 
        id: '1-19-1-140', 
        data: {
          order_no: _vm.orderInfo.billno,
          pay_button_text: _vm.payNowText,
        } 
      }),expression:"{ \n        id: '1-19-1-140', \n        data: {\n          order_no: orderInfo.billno,\n          pay_button_text: payNowText,\n        } \n      }"}],attrs:{"type":['H56PX', 'primary'],"DA-view-type":"ordinary","DA-type":"syncClick"},on:{"click":function($event){$event.stopPropagation();return _vm.payNowOrVerifyNowEvt({ payType: _vm.isCashPaymentType ? 2 : 1 })}}},[_vm._v("\n      "+_vm._s(_vm.payNowText)+"\n    ")])]:_vm._e(),_vm._v(" "),(_vm.orderInfo.orderStatus == 13)?_c('s-button',{directives:[{name:"expose",rawName:"v-expose",value:({ 
      id: '1-19-1-139', 
      data: {
        order_no: _vm.orderInfo.billno,
        pay_button_text: _vm.language.SHEIN_KEY_PWA_30858,
      } 
    }),expression:"{ \n      id: '1-19-1-139', \n      data: {\n        order_no: orderInfo.billno,\n        pay_button_text: language.SHEIN_KEY_PWA_30858,\n      } \n    }"},{name:"tap",rawName:"v-tap",value:({ 
      id: '1-19-1-140', 
      data: {
        order_no: _vm.orderInfo.billno,
        pay_button_text: _vm.language.SHEIN_KEY_PWA_30858,
      } 
    }),expression:"{ \n      id: '1-19-1-140', \n      data: {\n        order_no: orderInfo.billno,\n        pay_button_text: language.SHEIN_KEY_PWA_30858,\n      } \n    }"}],staticClass:"order-detail-footer__btn",attrs:{"DA-view-type":"ordinary","DA-type":"syncClick","type":['H56PX', 'primary'],"disabled":!_vm.disabledCodPayNow},on:{"click":function($event){$event.stopPropagation();_vm.disabledCodPayNow ? _vm.payNowOrVerifyNowEvt({ payType: 2 }) : ''}}},[_vm._v("\n    "+_vm._s(_vm.language.SHEIN_KEY_PWA_30858)+"\n  ")]):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }