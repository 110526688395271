
import ClientOnly from 'vue-client-only'
import InformationFlowCcc from '../components/information-flow-ccc/Index'
import HomeToptab from '../components/HomeToptabNew.vue'
import FloatingIcon from '../components/floatingIcon.vue'
import BottomSuspensionIcon from '../components/BottomSuspensionIcon.vue'
import bffBottomSuspensionIcon from 'public/src/pages/components/ccc/home-bff-components/bottom-suspension-icon/index.vue'

export const commonComponents = {
  ClientOnly,
  HomeToptab, // 新ccc使用
  InformationFlowCcc,
  FloatingIcon, // 首页悬浮新ccc
  BottomSuspensionIcon,
  bffBottomSuspensionIcon, //底部悬浮icon bff组件 
}
