import { reactive, readonly, provide, computed, inject, toRef, ref } from 'vue'
import useApis from './useApis'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { PAY_TOOLKIT_PRE_PAY_INJECTION_KEY } from '../common/constants'
import { useStoreAction } from './useStoreAction'
import { usePaymentsStore } from './usePayments'
// import { useSelectBank } from './useSelectBank'
// import { useDdc } from './useDdc'
import { Toast } from '@shein/sui-mobile'
import type { StoreAction } from './useStoreAction'
import { type PaymentsStore } from './usePayments'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { usePrePayRearOrderFlow } from './usePrePayRearOrderFlow'
import { usePaymentConfig } from './usePaymentsConfig'
import { type C_PayToolkit, AS_PayToolkit } from '../types'
import { PRODUCT_TYPE, CHECKOUT_TYPE } from '@shein-aidc/bs-sdk-libs-pay'
import { debuggerLog } from '../utils'

interface PrePayUnPayParams {
  cardBin: string;
  routeId: string;
  cardLastFour: string;
  cardNumber: string;
  countryCode: string;
  expireMonth: string;
  expireYear: string;
  cvv: string;
  cpfNumber: string;
  cardHolderName: string;
  rememberCard: number;
  paymentCode: string;
  installments: number;
  cardType: string;
  installmentsInfo: Record<string, any>;
  isSelectedInstallment: boolean;
  isHadBinDiscount: boolean;
  cardBinDiscountDesc: string;
  autoBindCard: string; // 用户未主动选择了记住卡号
  challengeWindowSize?: string;
  // isShowCardBinDiscountDescInPriceDetail: boolean;
}

export type PrePayHandleAction = Trade_PayToolKit.BsPaymentAction & 'showDefaultCardBinDiscount' | 'visiblePayFailGuide' | 'showBinFreezeFailConfig' | 'startRearOrderFlow' | 'closePrePayModal'

export const getDefaultPrePayUnPayParams = () => ({
  cardBin: '',
  routeId: '',
  cardLastFour: '',
  cardNumber: '',
  countryCode: '',
  expireMonth: '',
  expireYear: '',
  cvv: '',
  cpfNumber: '',
  cardHolderName: '',
  rememberCard: 0,
  paymentCode: '',
  installments: 0,
  cardType: '',
  installmentsInfo: {},
  isSelectedInstallment: true,
  isHadBinDiscount: false,
  cardBinDiscountDesc: '',
  autoBindCard: '', // 用户未主动选择了记住卡号
  challengeWindowSize: 'fullPage',
  // isShowCardBinDiscountDescInPriceDetail: false,
})

export interface PrePayState {
  visiblePrePay: boolean;
  installmentList: Trade_PayToolKit.InstallmentInfoItem[];
  initInstallmentList: Trade_PayToolKit.InstallmentInfoItem[];
  preFetchInfo: {
    payDomainLogoList: string[] | { id: number; url: string; sort: number }[];
  };
  prePayCheckoutInfo: {
    orderAttributesList: Trade_PayToolKit.OrderAttributesItem[];
    orderCurrency: string;
    orderUsdAmount: string;
    orderAmount: string;
    shipCountry: string;
    shipAddressId: string;
    virtualOrderScene?: Trade_PayToolKit.CheckoutInfo['virtualOrderScene'];
    routeCode: string;
    onlinePayDiscountInfo: Record<string, any>;
    cardBinDiscountInfo: Record<string, any>;
    mallCaculateInfo: Record<string, any>;
    bin_discount_list: any[];
    bin_discount_info: any;
    isAutoRenewal: boolean;
    taxNumber: string;
    billno: string;
  };
  cardBinRoutingInfo: Record<string, any>;
  displayCardBinDiscountInfo: Record<string, any>;
  ddcFormParams: {
    jwt: string;
    formActionUrl: string;
    bin: string;
  };
  prePayUnPayParams: PrePayUnPayParams;
  installmentInfo: any[];
  installmentResInfo: Record<string, any>;
  prePayFailGuide: {
    visible: boolean;
    failText: string;
    type: string;
    hasGuide: boolean;
    closeCb: () => void;
    confirmCb: () => void;
  };
  orderInfo: Record<string, any>;
  cardBinFreezeParams: {
    billno: string;
    bin: string;
    payment_code_unique: string;
    v_tips: number;
  };
  pageReadyTime: number;
  // 埋点属性
  clickContinueResultInfo: {
    failure_reason: string;
    failure_type: string;
    instalment_num: string;
    is_remember: string;
    order_id_list: string;
    order_no: string;
    payment_method: string;
    prime_deduction: string;
    product_type: string;
    scene: string;
    status: string;
    pay_response_time: string;
    stay_time: string;
    token_id: string;
    uorder_id: string;
    is_cvvnolimit: string;
  };
  isShowInstallmentTipsShow: boolean;
  shouldSecondaryVerification: boolean;
  orderCardBinDiscountInfo: Trade_PayToolKit.PaymentInfoItem['binDiscountCardToken'];
  recommendInstallments: number;
  getInstallmentInfo: (useGetInstallmentsParams: Partial<InstallmentsParams>) => Promise<any>;
  handlePrePayBinChangeForUnPay: (bin: string) => void;
}

export interface PrePayStore extends StoreAction<keyof PrePayState>, PrePayState {}

const defaultPaymentsStoreInfo: PrePayStore = {
  visiblePrePay: false,
  installmentList: [],
  initInstallmentList: [],
  preFetchInfo: {
    payDomainLogoList: [],
  },
  prePayCheckoutInfo: {
    orderAttributesList: [],
    orderCurrency: '',
    orderUsdAmount: '',
    orderAmount: '',
    shipCountry: '',
    shipAddressId: '', // 用于信息对比
    virtualOrderScene: null as any,
    routeCode: '',
    onlinePayDiscountInfo: {},
    cardBinDiscountInfo: {},
    mallCaculateInfo: {},
    bin_discount_list: [],
    bin_discount_info: {},
    isAutoRenewal: false, // TODO 是否选择了自动续费产品包
    taxNumber: '', // 税号，用于自动填充
    billno: '', // 订单号，用于单后场景处理
  },
  cardBinRoutingInfo: {},
  displayCardBinDiscountInfo: {}, // 展示在卡号输入框下方的卡bin优惠信息
  ddcFormParams: {
    jwt: '',
    formActionUrl: '',
    bin: '',
  },
  prePayUnPayParams: { ...getDefaultPrePayUnPayParams() },
  installmentInfo: [],
  installmentResInfo: {},
  prePayFailGuide: {
    visible: false,
    failText: '',
    type: '',
    hasGuide: true,
    closeCb: () => {},
    confirmCb: () => {},
  },
  orderInfo: {},
  cardBinFreezeParams: {
    billno: '',
    bin: '', // 新卡取用户输入的卡号前八位，卡token取接口返回的bin
    payment_code_unique: '',
    v_tips: 0, // 前端展示了卡bin优惠信息，冻结失败则需要弹窗提示
  },
  pageReadyTime: 0, // 页面加载完成时间
  // 埋点属性
  clickContinueResultInfo: {
    failure_reason: '', // 失败原因 属性值格式为：{code:xxx,result:xxx,type:xxx}，其中result（哪里出错）和type（什么错误类型）两部分的规则如下： 当卡号错误时，result的值为card_num
    failure_type: '', // 失败类型 front_end/rear_end
    instalment_num: '',
    is_remember: '',
    order_id_list: '', // 每个子订单号之间用 隔开
    order_no: '',
    payment_method: '',
    prime_deduction: '', // 付费会员抵扣金额
    product_type: '', // 产品类型 单独购付费会员发membership； 单独购超省卡发sheinsaver； 其他订单类型发commontype
    scene: '', // 场景类型 新卡上报new，非新卡上报token
    status: '',
    pay_response_time: '',
    stay_time: '', // 单位是“秒” 记录从进入卡支付页面到点击continue按钮的时长
    token_id: '', // token_id
    uorder_id: '', // 关联订单号
    is_cvvnolimit: '0', // 是否需要cvv 1 需要 0 不需要
  },
  isShowInstallmentTipsShow: false,
  shouldSecondaryVerification: false, // 是否需要二次验证 - 成单失败，再次点击continue时需要二次验证，看是否有变更，无变更则直接发起成单
  orderCardBinDiscountInfo: {}, // 单后卡bin优惠信息
  recommendInstallments: 0,
  mergeState: () => {},
  updateState: () => {},
  getInstallmentInfo: () => Promise.resolve([]),
  handlePrePayBinChangeForUnPay: () => {},
}

const formatPrePayCheckoutInfo = ({ checkoutInfo, selectedPayment }: Pick<PaymentsStore, 'checkoutInfo' | 'selectedPayment'>) => {
  const addressInfo = checkoutInfo?.addressInfo || {}

  let orderAttributesList = [] // 订单主体id+业务模式集合 需去重
  let orderCurrency = '' // 下单币种
  let orderUsdAmount = '' // 美金币种应付金额
  let orderAmount = '' // 下单币种应付金额	计算价格最终的实付金额（扣除券、积分、礼品卡、钱包等虚拟资产和活动的整车价格）
  let shipCountry = '' // 下单页的地址收货国家
  let shipAddressId = '' // 下单页的地址id
  const virtualOrderScene = checkoutInfo.virtualOrderScene || '' // 虚拟商品（付费会员or超省） 1-单独购 2-随单购 不传或其他值-代表无虚拟商品

  orderUsdAmount = checkoutInfo.totalPriceInfo?.usdAmount || 0
  orderAmount = checkoutInfo.totalPriceInfo?.amount || 0
  orderCurrency = checkoutInfo?.orderCurrencyInfo?.code || ''
  shipCountry = addressInfo?.countryCode || ''
  shipAddressId = addressInfo?.addressId || ''
  try {
    orderAttributesList = (JSON.parse(checkoutInfo?.channelSession || {})?.orderDetails || [])
  } catch (error) {
    console.error('formatPrePayCheckoutInfo error', error)
  }

  return {
    orderAttributesList,
    orderCurrency,
    orderUsdAmount,
    orderAmount,
    shipCountry,
    shipAddressId,
    virtualOrderScene,
    routeCode: selectedPayment.code,
    taxNumber: addressInfo?.taxNumber || '',
  }
}


interface InstallmentsParams {
  orderAmount: string;
  paymentCode: string;
  countryCode: string;
  orderCurrency: string;
  cardBin?: string;
  cardType?: string;
  billno?: string; // 单后，优先传关联订单号
}

const usePrePayFetch = ({ paymentsStore, props }: { paymentsStore: PaymentsStore; props }) => {
  const { triggerNotice } = (inject('analysisInstance') || {}) as AS_PayToolkit.AnalysisInstance
  const { fetchPrePayRoutingForCheckoutApi } = useApis()
  const appConfigs = useAppConfigs()

  const getInstallmentInfo = async (useGetInstallmentsParams?: Partial<InstallmentsParams>) => {
    const currencyCode = paymentsStore.checkoutInfo?.orderCurrencyInfo?.code || appConfigs?.$envs?.currency
    const totalPriceInfo = paymentsStore.checkoutInfo?.totalPriceInfo || {}
    const paymentCode = paymentsStore.selectedPayment.code

    const getInstallmentsParams = {
      orderAmount: totalPriceInfo?.amount,
      paymentCode,
      countryCode: paymentsStore?.checkoutInfo?.addressInfo?.countryCode || '',
      orderCurrency: currencyCode,
    } as InstallmentsParams

    if (useGetInstallmentsParams?.cardBin) {
      getInstallmentsParams.cardBin = useGetInstallmentsParams?.cardBin || ''
    }
    if (useGetInstallmentsParams?.cardType) {
      getInstallmentsParams.cardType = useGetInstallmentsParams?.cardType || ''
    }

    // 单后场景请求分期信息
    if (useGetInstallmentsParams?.billno) {
      getInstallmentsParams.billno = useGetInstallmentsParams?.billno || ''
      getInstallmentsParams.orderAmount = useGetInstallmentsParams?.orderAmount || ''
      getInstallmentsParams.countryCode = useGetInstallmentsParams?.countryCode || ''
    }

    const tempRes = await appConfigs.$schttp({
        url: '/pay/paycenter/installments',
        method: 'POST',
        params: getInstallmentsParams,
    })
    debuggerLog('getInstallmentInfo===res===', tempRes)

    if (tempRes?.data?.code == 0) {
      triggerNotice({
          id: 'expose_scenesabt.comp_pay-toolkit',
          data: {
              scenes: 'installmentRecommend',
              installment_recommend_num: tempRes?.data?.info?.recommend_installments || '',
              installmentRecommendReason: tempRes?.data?.info?.recommend_reason || '',
              installmentRecommendChosen: (tempRes?.data?.info?.installments || [])?.some(item => item?.default_choose) ? '1' : '0',
          },
      })
    }

    const installments = tempRes?.data?.info?.installments || [
      {
        stage_num: 1,
        rate: 0,
        installment_amount: totalPriceInfo.amountWithSymbol,
        installment_fee: '',
        installment_fee_numeric: 0,
        total_amount: totalPriceInfo.amountWithSymbol,
        total_amount_numeric: totalPriceInfo.amount,
      },
    ]

    return {
      ...(tempRes?.data?.info || {}),
      installments,
    }
  }
  const getPrePayRoutingInfo = ({
    orderAttributesList,
    orderCurrency,
    orderUsdAmount,
    orderAmount,
    shipCountry,
    virtualOrderScene,
    routeCode,
    bin = '',
  }: C_PayToolkit.FetchPrePayRoutingForCheckoutData = {} as C_PayToolkit.FetchPrePayRoutingForCheckoutData) => {

    const params: C_PayToolkit.FetchPrePayRoutingForCheckoutData = {
      bin,
      orderAttributesList,
      orderCurrency,
      orderUsdAmount,
      orderAmount,
      shipCountry,
      routeCode,
    }
    if (virtualOrderScene) params.virtualOrderScene = virtualOrderScene
    return fetchPrePayRoutingForCheckoutApi(params).then(data => {
      const routingInfo = data?.info || {}
      return routingInfo
    })
  }

  const getCheckoutInfo = (params = {}) => {
    return new Promise((resolve, reject) => {
      let timeoutFlag = false
      props?.bsPaymentAction({
        action: 'updateCheckout',
        payload: {
          params: {
            ...params,
          },
          cb: ({ status, result }) => {
            timeoutFlag = true
            if (status === 'success') {
              resolve(result)
            } else {
              reject(result)
            }
          },
        },
      })
      setTimeout(() => {
        if (!timeoutFlag) {
          reject('timeout')
        }
      }, 20 * 1000)
    })
  }

  return {
    getInstallmentInfo,
    getPrePayRoutingInfo,
    getCheckoutInfo,
  }
}

const checkSupportPaymentFront = ({ paymentCode, paymentConfig }: { paymentCode: string; paymentConfig: Trade_PayToolKit.PaymentConfig }) => {
  const supportPaymentCodes = ['routepay-cardinstallment']
  let supportPaymentFront = true
  if ([PRODUCT_TYPE.PAID_MEMBERSHIP, PRODUCT_TYPE.PAID_SHEIN_SAVER].includes(paymentConfig?.productType?.value)) {
    supportPaymentFront = false
  }

  // 二次下单不支持前置
  if (paymentConfig?.checkoutType?.value?.includes(CHECKOUT_TYPE?.AGAIN_TYPE)) {
    supportPaymentFront = false
  }
  if (typeof paymentConfig.supportPaymentFront?.value !== 'undefined') {
    supportPaymentFront = paymentConfig?.supportPaymentFront.value
  }
  return supportPaymentFront && supportPaymentCodes.includes(paymentCode)
}

const usePrePayValidation = ({ prePayStore, paymentStore, props }: { prePayStore: PrePayStore; paymentStore: PaymentsStore; props }) => {
  const { getInstallmentInfo, getPrePayRoutingInfo, getCheckoutInfo } = usePrePayFetch({ paymentsStore: paymentStore, props })
  // 校验必要参数是否有变化
  const checkPrePayParamsChange = async () => {
    // 订单及商品维度 地址（地址id）、业务模式、companyid、总价
    // 支付维度 卡号、有效期、cvv、持卡人姓名、税号、分期信息

    const oldPrePayCheckoutInfo = prePayStore.prePayCheckoutInfo

    // 先调前置路由接口，获取卡bin信息
    const currentRoutingInfo = await getPrePayRoutingInfo({
      orderAttributesList: oldPrePayCheckoutInfo.orderAttributesList,
      orderCurrency: oldPrePayCheckoutInfo.orderCurrency,
      orderUsdAmount: oldPrePayCheckoutInfo.orderUsdAmount,
      orderAmount: oldPrePayCheckoutInfo.orderAmount,
      shipCountry: oldPrePayCheckoutInfo.shipCountry,
      virtualOrderScene: oldPrePayCheckoutInfo.virtualOrderScene,
      routeCode: paymentStore.selectedPayment?.code || '',
      bin: prePayStore.prePayUnPayParams?.cardNumber?.substring?.(0, 8) || prePayStore.prePayUnPayParams?.cardBin || '',
    })
    // 再调checkout接口，获取总金额等信息
    await getCheckoutInfo({
      usedCardBin: currentRoutingInfo?.bin || '',
      cardPaymentFront: 1,
    })

    const tempInstallmentInfo = await getInstallmentInfo({
      cardBin: currentRoutingInfo?.bin || '',
      cardType: currentRoutingInfo?.cardType || '',
    })

    const { recommend_installments } = tempInstallmentInfo || {}
    prePayStore.updateState('recommendInstallments', recommend_installments)
    prePayStore.updateState?.('installmentResInfo', tempInstallmentInfo || {})

    // 最后调分期接口，获取分期信息
    const currentInstallmentList = prePayStore.prePayUnPayParams.installments ? (tempInstallmentInfo?.installments || []) : []

    let checkResult = false

    const {
      orderAttributesList,
      orderCurrency,
      orderUsdAmount,
      shipAddressId,
    } = formatPrePayCheckoutInfo(paymentStore)

    const isEqualAttributesList = (nowList, oldList) => {
      if (nowList.length !== oldList.length) return false
      const nowHash = nowList.reduce((pre, cur) => ({ ...pre, [`${cur.businessModel}-${cur.companyId}`]: true }), {})
      return oldList.every(item => nowHash[`${item.businessModel}-${item.companyId}`])
    }
    // 校验商品属性信息是否有变化
    if (!isEqualAttributesList(orderAttributesList, oldPrePayCheckoutInfo.orderAttributesList)) {
      debuggerLog('校验商品属性信息是否有变化==', orderAttributesList, oldPrePayCheckoutInfo.orderAttributesList)
      checkResult = true
    }
    // 订单币种是否有变化
    if (orderCurrency !== oldPrePayCheckoutInfo.orderCurrency) {
      debuggerLog('订单币种是否有变化==', orderCurrency, oldPrePayCheckoutInfo.orderCurrency)
      checkResult = true
    }
    // 金额是否有变化
    if (orderUsdAmount !== oldPrePayCheckoutInfo.orderUsdAmount) {
      debuggerLog('金额是否有变化==', orderUsdAmount, oldPrePayCheckoutInfo.orderUsdAmount)
      checkResult = true
    }
    // 收货地址（id）是否有变化
    if (shipAddressId !== oldPrePayCheckoutInfo.shipAddressId) {
      debuggerLog('收货地址（id）是否有变化', shipAddressId, oldPrePayCheckoutInfo.shipAddressId)
      checkResult = true
    }

    // 选择的支付方式是否有变化
    if (paymentStore.selectedPayment?.code !== oldPrePayCheckoutInfo.routeCode) {
      debuggerLog('选择的支付方式是否有变化==', paymentStore.selectedPayment?.code, oldPrePayCheckoutInfo.routeCode)
      checkResult = true
    }
    // 路由返回的cardBin是否有变化
    if (currentRoutingInfo.bin !== prePayStore.prePayUnPayParams.cardBin) {
      debuggerLog('路由返回的cardBin是否有变化==', currentRoutingInfo.bin, prePayStore.prePayUnPayParams.cardBin)
      checkResult = true
    }

    // 路由到的支付方式是否有变化
    if (currentRoutingInfo.payMethod !== prePayStore.prePayUnPayParams.paymentCode) {
      debuggerLog('路由到的支付方式是否有变化==', currentRoutingInfo.payMethod, prePayStore.prePayUnPayParams.paymentCode)
      checkResult = true
    }
    // 需要税号是否有变化
    if (currentRoutingInfo.isDocument !== prePayStore.cardBinRoutingInfo.isDocument) {
      debuggerLog('需要税号是否有变化==', currentRoutingInfo.isDocument, prePayStore.cardBinRoutingInfo.isDocument)
      checkResult = true
    }
    // 需要持卡人姓名是否有变化
    if (currentRoutingInfo.isCardHoldName !== prePayStore.cardBinRoutingInfo.isCardHoldName) {
      debuggerLog('需要持卡人姓名是否有变化==', currentRoutingInfo.isCardHoldName, prePayStore.cardBinRoutingInfo.isCardHoldName)
      checkResult = true
    }
    // 原选择的分期数是否在新的分期信息列表中
    // 新返回的分期列表与原列表是否有变化
    if (currentInstallmentList.length !== prePayStore.installmentInfo?.length) {
      debuggerLog('新返回的分期列表与原列表是否有变化==', currentInstallmentList.length, prePayStore.installmentInfo?.length)
      checkResult = true
    }
    for (let i = 0; i < (prePayStore.installmentInfo?.length || 0); i++) {
      const item = prePayStore.installmentInfo?.[i] || {}
      if (item.stage_num !== currentInstallmentList?.[i]?.stage_num) {
        debuggerLog('新返回的分期列表与原列表是否有变化==222====', item.stage_num, currentInstallmentList?.[i]?.stage_num)
        checkResult = true
        break
      }
    }

    // 判断是否返回卡bin优惠
    if (paymentStore.selectedPayment.binDiscountInfo?.discount_type != null) checkResult = true

    debuggerLog('checkPrePayParamsChange==checkResult==', checkResult, paymentStore.selectedPayment.binDiscountInfo)

    prePayStore.updateState('cardBinRoutingInfo', currentRoutingInfo || {})
    prePayStore.mergeState('prePayUnPayParams', {
      cardBin: currentRoutingInfo.bin,
      routeId: currentRoutingInfo.routeId,
      paymentCode: currentRoutingInfo.payMethod,
      countryCode: currentRoutingInfo.orderCountry || '',
      cardType: currentRoutingInfo.cardType,
    })
    prePayStore.updateState('installmentList', currentInstallmentList)
    prePayStore.updateState('initInstallmentList', currentInstallmentList)
    debuggerLog('checkPrePayParamsChange==checkResult==', checkResult, currentRoutingInfo, currentInstallmentList)

    return {
      checkResult,
      cardBinRoutingInfo: currentRoutingInfo,
      installments: currentInstallmentList,
    }
  }

  return {
    checkPrePayParamsChange,
  }
}

// eslint-disable-next-line max-lines-per-function
export const usePrePay = ({ props }, { emit }) => {
  const { triggerNotice } = (inject('analysisInstance') || {}) as AS_PayToolkit.AnalysisInstance
  const paymentsStore = usePaymentsStore()

  const { getInstallmentInfo } = usePrePayFetch({ paymentsStore, props })
  const { getPaymentPageLogoApi } = useApis()
  const { getPaymentConfig } = usePaymentConfig()
  const paymentConfig = getPaymentConfig()
  debuggerLog('props', emit, props, paymentsStore, paymentConfig)

  const prePayModalRef = ref()

  const prePayStore = reactive<PrePayStore>({
    ...defaultPaymentsStoreInfo,
    getInstallmentInfo,
  })

  const { mergeState, updateState } = useStoreAction<keyof PrePayState>(prePayStore)
  prePayStore.mergeState = mergeState
  prePayStore.updateState = updateState

  provide<PrePayStore>(PAY_TOOLKIT_PRE_PAY_INJECTION_KEY, readonly(prePayStore) as any)

  const { handlePrePayRearOrderFlow } = usePrePayRearOrderFlow({ prePayStore })
  const { checkPrePayParamsChange } = usePrePayValidation({ prePayStore, paymentStore: paymentsStore, props })

  const isUsePrePay = computed(() => {
    const showCardinstallmentPaymentFrontAbt = paymentsStore.abtInfo?.cardinstallmentPaymentFront?.param?.cardinstallmentPaymentFrontStatus == 1
    const checkRes = checkSupportPaymentFront({ paymentCode: paymentsStore.selectedPayment?.code, paymentConfig })
    // debuggerLog('isUsePrePay====', paymentsStore.selectedPayment?.code, showCardinstallmentPaymentFrontAbt, checkRes)
    return showCardinstallmentPaymentFrontAbt && checkRes
  })

  const updatePrePaySetCheckoutInfo = (prePayCheckoutInfo: Partial<PrePayState['prePayCheckoutInfo']>) => {
    mergeState('prePayCheckoutInfo', prePayCheckoutInfo)
  }

  // 卡bin冻结失败是否需要展示卡bin优惠信息 - abt开启，且有展示卡bin信息
  const shouldShowBinDiscountFreezeFailTips = computed(() => {
    const { cardTokenList = [] } = paymentsStore.checkoutInfo || {}
    const binDiscountAbtShow = paymentsStore.abtInfo?.BinDiscountdiscount?.param?.BinDiscountShow === 'Show'
    return cardTokenList?.length && binDiscountAbtShow
  })

  const fetchPreInfo = async () => {
    try {
      const paymentCode = paymentsStore.selectedPayment.code
      const [instamentDataResult, paymentPageLogoResult] = await Promise.allSettled([
        getInstallmentInfo({}),
        getPaymentPageLogoApi({
          payment_code: paymentCode,
          page_type: 4,
        }),
      ])
      debuggerLog('fetchPreInfo===instamentDataResult===', instamentDataResult)
      const instamentData = instamentDataResult.status === 'fulfilled' ? instamentDataResult.value : null
      const { recommend_installments } = instamentData || {}
      prePayStore.updateState('recommendInstallments', recommend_installments)
      prePayStore.updateState?.('installmentResInfo', instamentData || {})
      const paymentPageLogo = paymentPageLogoResult.status === 'fulfilled' ? paymentPageLogoResult.value : null
      updateState('installmentList', instamentData?.installments || [])
      updateState('initInstallmentList', instamentData?.installments || [])
      prePayStore.preFetchInfo.payDomainLogoList = paymentPageLogo?.info?.logoList || []
      return { instamentData: instamentData?.installments || [], paymentPageLogo }
    } catch (error) {
      console.error('fetchPreInfo error', error)
    }
  }

  const handlePrePayForCheckout = ({ checkOnly = false } = {}) => {
    const formValid = prePayModalRef.value?.formValidator?.()
    let installmentValid = true
    if (prePayStore.prePayCheckoutInfo.routeCode === 'routepay-cardinstallment') {
      installmentValid = prePayModalRef?.value?.installmentValidator?.()
    }
    let isSelectedInstallment = true
    if (formValid.result && !installmentValid) {
      isSelectedInstallment = false
      if (!checkOnly) {
        Toast(paymentsStore.language?.BS_KEY_PAY_1071)
        prePayModalRef.value.scrollToInstallment()
      }
    }
    prePayStore?.mergeState('prePayUnPayParams', {
      ...prePayStore?.prePayUnPayParams,
      isSelectedInstallment,
    })
    if (formValid.result && installmentValid) {
      // prePayStore.visiblePrePay = false
      if (prePayStore.prePayCheckoutInfo?.billno) {
        prePayStore.updateState('cardBinFreezeParams', {
          billno: prePayStore.prePayCheckoutInfo.billno,
          bin: prePayStore.prePayUnPayParams?.cardNumber?.substring?.(0, 8) || prePayStore.prePayUnPayParams?.cardBin || '',
          payment_code_unique: prePayStore.prePayCheckoutInfo?.routeCode,
          v_tips: shouldShowBinDiscountFreezeFailTips.value ? 1 : 0,
        })
      }
      return {
        validateResult: true,
        prePayUnPayParams: prePayStore.prePayUnPayParams,
        freezeParamsStr: JSON.stringify(prePayStore.cardBinFreezeParams || {}),
        failReasonResults: [],
      }
    } else {
      return {
        validateResult: false,
        prePayUnPayParams: prePayStore.prePayUnPayParams,
        failReasonResults: formValid.failReasonResults,
      }
    }
  }

  const validateByPay = async (): Trade_PayToolKit.ValidateByPayType => {
    let metric_scene = 'pre_pay_checkout_dialog_visible'
    if (!isUsePrePay.value) return { validateResult: true }
    if (!prePayStore.visiblePrePay) {
      const checkCardinstallmentInfoStatusAbt = +paymentsStore.abtInfo?.checkCardinstallmentInfo?.param?.checkCardinstallmentInfoStatus === 1 // 卡分期需要二次check
      if (prePayStore.shouldSecondaryVerification) {
        const { checkResult } = await checkPrePayParamsChange()
        prePayStore.shouldSecondaryVerification = false
        if (checkCardinstallmentInfoStatusAbt) {
          if (!checkResult) {
            return { validateResult: true, scene: 'pre_create_pay' }
          }
        }
        updatePrePaySetCheckoutInfo(formatPrePayCheckoutInfo(paymentsStore))
      } else {
        fetchPreInfo().then(() => {
          const prePayCheckoutInfo = formatPrePayCheckoutInfo(paymentsStore)
          updatePrePaySetCheckoutInfo(prePayCheckoutInfo)
        })
        metric_scene = 'pre_pay_checkout_secondary_dialog_visible'
      }
      prePayStore.visiblePrePay = true

      triggerNotice?.({
        id: 'click_front_card_payment:simple',
      })
      return {
        validateResult: false,
        scene: 'pre_create_pay',
        metric_scene,
        saParams: {
          result_reason: '1_12',
          payment_method_id: paymentsStore.selectedPayment?.payment_id,
        },
      }
    } else {
      const { validateResult: preValidateResult, failReasonResults } = handlePrePayForCheckout()
      metric_scene = !preValidateResult ? prePayStore.prePayCheckoutInfo?.billno ? 'pre_un_pay_vaild_fail_scene' : 'pre_pay_card_vaild_fail' : ''
      return { validateResult: preValidateResult,
          metric_scene,
          scene: 'pre_create_pay',
          saParams: !preValidateResult ? {
            result_reason: '1_12',
            payment_method_id: paymentsStore.selectedPayment?.payment_id,
          } : {},
          extraInfo: {
            failReasonResults,
            prePayUnPayParams: prePayStore.prePayUnPayParams,
            prePayCheckoutInfo: prePayStore.prePayCheckoutInfo,
          },
          // 订单埋点额外需要的字段数据
          payAnalysisInfo: {
            is_installment_default: (prePayStore?.installmentResInfo?.installments || [])?.some(item => item?.default_choose && item?.stage_num === prePayStore.prePayUnPayParams?.installments),
            instalment_num: prePayStore.prePayUnPayParams?.installments,
          },
      }
    }
  }

  const handlePrePayNow = async (cb) => {
    const { validateResult, failReasonResults } = handlePrePayForCheckout({ checkOnly: true })
    return cb?.({
      validateResult,
      scene: 'pre_create_pay',
      metric_scene: 'pre_un_pay_vaild_fail_scene',
      orderInfo: {
        sub_billnos: prePayStore.orderInfo?.sub_billnos || [],
        payment_method: prePayStore.orderInfo?.payment_method,
      },
      failReasonResults,
      prePayUnPayParams: prePayStore.prePayUnPayParams,
      billno: prePayStore.prePayCheckoutInfo?.billno,
      freezeParamsStr: prePayStore.prePayCheckoutInfo?.billno ? JSON.stringify(prePayStore.cardBinFreezeParams || {}) : {},
    })
  }

  const handlePrePayAction = ({ action, payload }: {action: PrePayHandleAction; payload: any}) => {
    debuggerLog('handlePrePayAction===', action, payload)
    switch (action) {
      // case 'showDefaultCardBinDiscount':
      //   prePayModalRef.value?.showDefaultCardBinDiscount?.(payload)
      //   break
      case 'visiblePayFailGuide':
        debuggerLog('visiblePayFailGuide===', payload)
        mergeState('prePayFailGuide', Object.assign({}, { visible: true }, payload))
        break
      case 'startRearOrderFlow':
        handlePrePayRearOrderFlow(payload)
        break
      case 'showBinFreezeFailConfig':
        prePayModalRef.value?.showBinFreezeFailConfig?.(payload)
        break
      case 'closePrePayModal':
        prePayStore.mergeState('visiblePrePay', false)
        prePayStore.updateState('shouldSecondaryVerification', true)
        break
      default:
        break
    }
  }

  return {
    prePayModalRef,
    prePayFailGuide: toRef(prePayStore, 'prePayFailGuide'),
    visiblePrePay: toRef(prePayStore, 'visiblePrePay'),
    prePayStore,
    isUsePrePay,
    validateByPay,
    handlePrePayForCheckout,
    handlePrePayNow,
    mergeState,
    updateState,
    handlePrePayAction,
  }
}

export const usePrePayStore = () => {
  return inject<PrePayStore>(PAY_TOOLKIT_PRE_PAY_INJECTION_KEY, { ...defaultPaymentsStoreInfo })
}


