var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{directives:[{name:"enterkey",rawName:"v-enterkey"},{name:"expose",rawName:"v-expose",value:({
    id: 'expose_asset_coupon:simple',
    data: {
      is_choosed: _setup.coupon_list?.length > 0 ? 1 : 0,
      is_folded: _vm.isFolded,
    }
  }),expression:"{\n    id: 'expose_asset_coupon:simple',\n    data: {\n      is_choosed: coupon_list?.length > 0 ? 1 : 0,\n      is_folded: isFolded,\n    }\n  }"}],staticClass:"checkout-virtual-assets__coupon fn-coupon-code-hash",attrs:{"tabindex":"0"},on:{"click":_setup.openCouponCodePop}},[_c('div',{staticClass:"checkout-virtual-assets__coupon-body"},[_c('div',{staticClass:"input-icon input-coupon"},[_c('span',{staticClass:"input-icon-cell",staticStyle:{"white-space":"nowrap"}},[_vm._v("\n        "+_vm._s(_setup.language.SHEIN_KEY_PWA_15095)+":\n        "),(_setup.couponInfo?.allUsedCouponTip && !_setup.couponInfo.right_icon)?_c('span',{staticClass:"coupon-prime-tips",domProps:{"innerHTML":_vm._s(_setup.couponInfo?.allUsedCouponTip)}}):_vm._e()]),_vm._v(" "),_c('div',{class:[_setup.prefixCls('dynamic-comp-item__coupon-right'), 'input-icon-cell']},[(_setup.isShowCouponError)?_c('span',{staticClass:"use-red"},[_vm._v("\n          "+_vm._s(_setup.language.SHEIN_KEY_PWA_17965)+"\n        ")]):_c('div',{staticClass:"use-coupon-item",class:{
            'use-free-tip__new': _setup.coupon_price.amount > 0 || _setup.freeShippingTip || _setup.couponInfo.selectCouponTip,
            'use-red': _setup.coupon_price.amount > 0 || _setup.freeShippingTip || _setup.couponInfo.selectCouponTip,
          }},[(_setup.coupon_list?.length > 0)?[(+_setup.coupon_price.amount > 0)?[_c('div',{staticClass:"icon-price-container"},[(_setup.couponInfo.right_icon)?_c('span',{staticClass:"saver-coupon-icon"},[_c('img',{attrs:{"src":_setup.couponInfo.right_icon,"alt":"Tag Icon"}})]):_vm._e(),_vm._v("\n                -"+_vm._s(_setup.coupon_price.amountWithSymbol)+"\n              ")])]:_vm._e(),_vm._v(" "),(_setup.freeShippingTip)?_c('div',{class:{'coupon-prime': !!_setup.couponInfo.freeShippingPrimeIcon}},[(_setup.couponInfo.freeShippingPrimeIcon)?_c('img',{staticClass:"coupon-prime-icon",attrs:{"src":_setup.couponInfo.freeShippingPrimeIcon,"alt":""}}):_vm._e(),_vm._v("\n              "+_vm._s(_setup.freeShippingTip)+"\n            ")]):_vm._e()]:[(_setup.couponInfo.selectCouponTip)?[_vm._v("\n              "+_vm._s(_setup.couponInfo.selectCouponTip)+"\n            ")]:(_setup.couponInfo.bestCombinationCouponTip)?[_vm._v("\n              "+_vm._s(_setup.couponInfo.bestCombinationCouponTip)+"\n            ")]:_vm._e(),_vm._v(" "),(+_setup.couponInfo?.redDot === 1 && !_setup.isDrawerClicked)?_c('em',{staticClass:"available-dot"}):_vm._e()]],2),_vm._v(" "),_c(_setup.Icon,{staticClass:"icon-arrow",attrs:{"name":!_setup.GB_cssRight ? 'sui_icon_more_right_16px' : 'sui_icon_more_left_16px',"color":"#959595"}})],1)])]),_vm._v(" "),(_setup.freeShippingTipsList?.length)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: 'expose_popup_notfreeshipping:simple',
    }),expression:"{\n      id: 'expose_popup_notfreeshipping:simple',\n    }"}],staticClass:"price-coupon-diff"},_vm._l((_setup.freeShippingTipsList),function(item){return _c('div',{key:item.mall_code,staticClass:"price-diff-tip"},[(item.not_free_shipping_tip?.trim() && +item.not_free_shipping_diff.amount > 0)?[(_setup.isMultiMall)?_c('strong',[_vm._v(_vm._s(item.mall_name)+": ")]):_vm._e(),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(item.not_free_shipping_tip)}})]:_vm._e()],2)}),0):_vm._e(),_vm._v(" "),(_setup.couponGift)?_c(_setup.PickCouponGift,{attrs:{"hasCouponGift":_setup.hasCouponGift}}):_vm._e(),_vm._v(" "),_c(_setup.ClientOnly,[(_setup.couponGiftDrawer.visible)?_c(_setup.CouponGiftsAddDrawer,{attrs:{"visible":_setup.couponGiftDrawer.visible,"language":_setup.language},on:{"onClose":_setup.onCloseGiftDrawer}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }