import { set } from 'vue'
import { defaultColloctUserInfoDialog } from './state'
const mutations = {
  initColloctUserInfoDialog(state) {
    updateState(state, 'collectUserDialog', JSON.parse(JSON.stringify(defaultColloctUserInfoDialog)))
  },
  updateColloctUserInfoDialog(state, payload) {
    updateState(state, 'collectUserDialog', payload)
  },
  mergeColloctUserInfoDialog(state, payload) {
    const newValue = {
      ...(state.collectUserDialog || {}),
      ...payload,
    }
    updateState(state, 'collectUserDialog', newValue)
  },
  handleCollectUserInfoDialogEvent(){
    window?.appEventCenter?.$emit('collectUserInfoSuccess')
  }
}

function updateState(state, key, newValue) {
  if (Vue.version?.startsWith?.('3')) {
    state[key] = newValue
  } else {
    // Vue2.x
    set?.(state, key, newValue)
  }
}

export default mutations
