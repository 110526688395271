import { getQueryString } from '@shein/common-function'
import { getTopNavigation } from 'public/src/pages/product_app/store/modules/product_list_v2/utils'

// 判断是否全部是品类图文
export function getIsAllCategoryType ({ picTopNav }) {
  if (!picTopNav.length) return false

  return picTopNav
    .reduce((arr, item) => {
      arr.push(...item.navs)
      return arr
    }, [])
    .every(_ => _.nav_type == '1')
}

export function handlePicTopNavParams ({ list, tabIndex = 0, navIndex, extraParams = {}, isSelect, noClearParams, businessReport }) {
  const tabItem = list[tabIndex]
  const navItem = list[tabIndex].navs[navIndex]
  const pic_tab_pos = tabIndex + 1
  const { nav_id, nav_type, nav_name, goodsId = '', rec_type } = navItem
  navItem.index = navIndex

  const params = {
    pic_nav_adp: goodsId,
    pic_nav_id: nav_id,
    pic_nav_type: nav_type,
    pic_nav_name: nav_name,
    pic_nav_pos: navIndex + 1,
    pic_rec_type: rec_type,
    pic_tab_id: tabItem.tabId,
    pic_tab_type: tabItem.tabType,
    pic_tab_pos: isNaN(pic_tab_pos) ? '' : pic_tab_pos,
    ...extraParams
  }

  // 非取值埋点参数时，需要走取消选中清空参数的逻辑
  if (!noClearParams) !isSelect && Object.keys(params).forEach(key => params[key] = '')

  // 业务指标上报
  if (businessReport) Object.assign(params, { source: 'imgNav', source_status: isSelect ? 1 : 0 })

  return params
}

/**
 * 处理点击图文跳转后续页面必带的参数
 */
export function handlePicTopNavQueryChain(catInfo) {
  const queryChain = {}

  // 如果没有参数，则不处理，兼容搜索场景
  if (catInfo) {
    queryChain.top_navigation_source = getTopNavigationSource(catInfo)
  }

  const entranceType = getQueryString({ key: 'entranceType' }) || ''
  const fromPageType = getQueryString({ key: 'fromPageType' }) || ''

  if (entranceType) {
    queryChain.entranceType = entranceType
  }

  if (fromPageType) {
    queryChain.fromPageType = fromPageType
  }

  return queryChain
}

/**
 * 获取图文埋点 top_navigation_source 的值。
 * 优先从 url 参数中获取，如果没有则根据 catInfo 生成。
 */
export function getTopNavigationSource(catIno = {}) {
  const queryValue = catIno.top_navigation_source || getQueryString({ key: 'top_navigation_source' }) || ''

  // 如果 url 参数中有值，则直接返回，需要解码
  if (queryValue) {
    return decodeURIComponent(queryValue)
  }

  const isValidPageName = ['page_real_class', 'page_select_class'].includes(catIno.pageName)
  const isJumpAble = catIno?.replace?.toString() !== 'true' // 是否可以跳转
  const shouldPassToQueryChain = isValidPageName && isJumpAble
  // 如果是 page_real_class 或 page_select_class 页面，且 url 参数中没有值，则传递给 queryChain
  if (shouldPassToQueryChain) {
    const topNavigation = getTopNavigation(catIno)
    return topNavigation?.top_navigation_jump || topNavigation?.top_navigation || ''
  }

  // 其他情况返回空字符串
  return ''

}

/**
 * 获取上个页面的分类id
 */
export function getFromPageCateId(catInfo) {
  const { pageName, entity_id, select_id } = catInfo

  if (catInfo.from_page_cate_id) {
    return catInfo.from_page_cate_id
  }

  let from_page_cate_id = '' // 上个页面的分类id
  if (['page_real_class', 'page_select_class'].includes(pageName)) {
    from_page_cate_id = entity_id || select_id || '-'
  }
  return from_page_cate_id
}
