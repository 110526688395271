import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
import {
  selectionAndEntityPicNavApiCache,
  languageApiCache,
  cccFeedbackApiCache,
  sheinClubInfoCache,
  cccSeoApiCache,
  cccOPeartionApiCache
} from '../api/bffApi.js'

const SHEIN_CLUB_INFO_V1 = 'shein_club_info_v1'
const PRODUCT_LIST_LANGUAGE = 'product_list_language'
const CCC_OPERATION = 'ccc_operation'
const CCC_SEO = 'ccc_seo'

const bffSwitchVersion = isSwitchBffApiVersion([
  SHEIN_CLUB_INFO_V1,
  PRODUCT_LIST_LANGUAGE,
  CCC_OPERATION,
  CCC_SEO
])

const isSheinClubInfoSwitchBff = bffSwitchVersion?.[SHEIN_CLUB_INFO_V1]
const isProductListLanguageSwitchBff = bffSwitchVersion?.[PRODUCT_LIST_LANGUAGE]
const isCccOperationSwitchBff = bffSwitchVersion?.[CCC_OPERATION]
const isCccSeoSwitchBff = bffSwitchVersion?.[CCC_SEO]

// 图文导航
const requestPicNav = ({ requestInfo, picNavParams }) => {
  const hidePicNav = requestInfo.query.is_hide_image_nav === '1'
  if (hidePicNav) {
    return new Promise(resolve => {
      resolve({})
    })
  }

  return selectionAndEntityPicNavApiCache.request(picNavParams)
}

// 负反馈
const getCccFeedbackData = () => {
  return {
    cccFeedbackData: async (cb) => {
      const result = await cccFeedbackApiCache.request({ v: '1.0' })
      cb(null, result)
    }
  }
}

// 多语言
const getBffLanguage = (pages, noNeedLanguage) => {
  return {
    bffLanguage: async (cb) => {
      if (noNeedLanguage || !isProductListLanguageSwitchBff) {
        return cb(null, {})
      }

      const result = await languageApiCache.request({ pages })
      
      const bffLanguage = result?.code == '0' && result?.info?.result || {}
      bffLanguage._fromBff = true
      cb(null, bffLanguage)
    }
  }
}

// 付费会员
const getSheinClubInfo = () => {
  return {
    sheinClubInfo: async (cb) => {
      if (isSheinClubInfoSwitchBff) {
        const res = await sheinClubInfoCache.request({
          params: {}
        })
        const { is_paid = 0 } = res.info || {}

        const result = {
          sheinClubType: 3,
          reqSheinClub: true,
          isPaid: is_paid,
          _from: 'bff',
        }

        cb(null, result)
      } else {
        cb(null, {})
      }
    }
  }
}

// ccc Seo 
const getCCCSeo = (params) => {
  return {
    cccSeo: async (cb) => {
      if (!params || !isCccSeoSwitchBff) {
        return cb(null, {})
      }

      const result = await cccSeoApiCache.request({
        params
      })
      cb(null, result || {})
    }
  }
}
// ccc operation
const getCCCOpeartion = (params) => {
  return {
    cccOperationSeo: async (cb) => {
      if (!params || !isCccOperationSwitchBff) {
        return cb(null, {})
      }

      const sceneKeys = ['mobile_seo_text']
      const result = await cccOPeartionApiCache.request({
        params: {
          ...params,
          sceneKey: sceneKeys.join(),
          listsId: '',
          isPreview: 0
        }
      })
      cb(null, result || {})
    }
  }
}

const getCommonRequestQueue = ({ noNeedLanguage, cccSeoParams, cccOperationPaeams } = {}) => {
  return {
    // 多语言
    ...getBffLanguage(['filter', 'product_list', 'product_item', 'coupon', 'coupon_item', 'survey_question'], noNeedLanguage),
    // 负反馈
    ...getCccFeedbackData(),
    // 付费会员
    ...getSheinClubInfo(),
    ...getCCCOpeartion(cccOperationPaeams),
    ...getCCCSeo(cccSeoParams)
  }
}

export {
  requestPicNav,
  getSheinClubInfo,
  getCccFeedbackData,
  getCommonRequestQueue,
}
