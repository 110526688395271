/* eslint-disable no-mixed-spaces-and-tabs */
import schttp from 'public/src/services/schttp'
import { getBffVersion } from 'public/src/pages/user/child_pages/user_bff_version/index.js'


const defaultOptions = {
  excludes: {
    // userAgent: true,
    // webdriver: true,
    // deviceMemory: true,
    // hardwareConcurrency: true,
    // timezone: true,
    // cpuClass: true,
    // doNotTrack: true,
    // plugins: true,
    // canvas: true,
    // webgl: true,
    // webglVendorAndRenderer: true,
    // touchSupport: true,
    // fonts: true,
    // fontsFlash: true,
    // audio: true,
    enumerateDevices: true
    // language: true,
    // adBlock: true,
    // openDatabase: true,
  }
}

class makeFingerPrint {
  notSendFtoken = false
  cacheValid = false
  expTime = 1000 * 60 * 60 * 24	* 1 //1天过期

  constructor({ cb, notSendFtoken } = {}){
	  let cacheValid = false
	  let ftokenInfo = null
	  let nowDate = +new Date()

	  this.notSendFtoken = notSendFtoken

	  if( window.localStorage && localStorage.getItem && localStorage.getItem('ftokenInfo')){
	    try {
	      ftokenInfo = localStorage.getItem('ftokenInfo')
	      ftokenInfo = JSON.parse(ftokenInfo)
	      if( ftokenInfo && ftokenInfo.ftoken && nowDate - ftokenInfo.expDate < this.expTime ){
	        cacheValid = true
	      }
	    } catch(e){
	      console.log('ftokenInfo error: ', e.message)
	    }
	  }
	  this.cacheValid = cacheValid
	  this.init(cb, ftokenInfo)
  }
  async init(cb, ftokenInfo){
	  if( this.cacheValid && ftokenInfo ){
	    gbCommonInfo.ftoken = ftokenInfo.ftoken
	    gbCommonInfo._loginCanvas = ftokenInfo.canvas
	    gbCommonInfo._loginWebgl = ftokenInfo.webgl

	    this.updateFtokenInfo()
	    cb && cb(gbCommonInfo.ftoken)
	  } else {
      async function bffGetIpSer () {
        const ipResult = await schttp({ url: '/user-api/setting/get_ip_info', useBffApi: true })
        return ipResult?.info || {}
      }
	    Promise.all([
	      getBffVersion('userTop20_v1')
          ? bffGetIpSer()
          : schttp({ url: '/api/common/ipInfo/get' }),
	      import(/* webpackChunkName: "fingerprintjs2" */'fingerprintjs2')
	    ]).then(result => {
	      gbCommonInfo.ipHash = result[0]?.ipHash || ''
		  this.fp2 = result[1].default || []
	      this.fp2.get(defaultOptions, async (components) => {
	        this.makeFtoken(components)
	        this.updateFtokenInfo()
	        cb && cb(gbCommonInfo.ftoken)
	      })
	    })
	  }
  }

  //生成需要值
  makeFtoken(components){
	  let canvas = ''
	  let webgl = ''
	  const values = components.map(component => {
	    if (component.key === 'canvas') canvas = component.value
	    if (component.key === 'webgl') webgl = component.value
	    return component.value
	  })

	  values.push({
	    key: 'ip',
	    value: gbCommonInfo.ipHash
	  })

	  const ftoken = this.fp2.x64hash128(values.join(''), 31)

	  if (canvas instanceof Array) { // canvas 字段
	    canvas.some((v) => {
	      const index = v.indexOf('base64,')
	      if (index > -1) { canvas = v.slice(index + 87, index + 119); return true }
	      return false
	    })
	  }
		
	  if (webgl instanceof Array) { // wegl 字段
	    webgl.some((v) => {
	      const index = v.indexOf('base64,')
	      if (index > -1) { webgl = v.slice(index + 87, index + 119); return true }
	      return false
	    })
	  }

	  gbCommonInfo.ftoken = ftoken
	  gbCommonInfo._loginCanvas = canvas
	  gbCommonInfo._loginWebgl = webgl
		
	  if( window.localStorage && localStorage.setItem ){
	    const ftokenInfo = {
	      ftoken, canvas, webgl,
	      expDate: +new Date()
	    }
	    localStorage.setItem('ftokenInfo', JSON.stringify(ftokenInfo))
	  }
  }

  //更新session里的值
  updateFtokenInfo(){
	  if (getBffVersion('userTop20_v1')) {
      this.bffUpdateSer()
      return
    }
    this.updateSer()
  }

  updateSer () {
    //用在未登录用户
	  !this.notSendFtoken && schttp({
	    url: `/api/user/user_index/send_ftoken/get`,
	    method: 'POST',
	    data: { ftoken: gbCommonInfo.ftoken },
	  })

	  //用在已登录用户，更新session的ftoken
	  this.notSendFtoken && schttp({
	    url: `/api/user/user_index/updateFtoken/update`,
	    method: 'POST',
	    data: { ftoken: gbCommonInfo.ftoken },
	  })
  }

  bffUpdateSer () {
	  schttp({
		  method: 'POST',
      url: '/user-api/account/update_ftoken',
      useBffApi: true,
      data: { ftoken: gbCommonInfo.ftoken }
    })
  }
}

export const InitFtoken = (cb, { notSendFtoken } = {}) => {
  const fingerprint = new makeFingerPrint({ cb, notSendFtoken })
  return fingerprint
}

export function InitFtokenPromise (notSendFtoken) {
  return new Promise((resolve) => {
    InitFtoken((ftoken) => {
      resolve(ftoken)
	  }, { notSendFtoken })
  })
}
