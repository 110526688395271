import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { parseQueryString } from '@shein/common-function'

export const reg = /\/([a-z]+-picks|romwe-[a-z]+|promo-discount)\.html/

// export function transformPromodiscountUrl(url, PICKS_LINKS = {}) {
//   const query = url.split('?')[1]
//   const match = url.match(reg)?.[0]
//   if (!match) return url

//   const newURL = PICKS_LINKS[parseQueryString(query || '').hrefTarget] || 'shein-picks'
//   url = url
//     .replace(match, `/${newURL}.html`)
//     .replace(/&?hrefTarget=\d+/, '')
//   return url
// }

export function getLink({ item = {}, cateLinks = {} }) {
  const { langPath } = useAppConfigs()?.$envs || {}
  if(item.webClickUrl){
    // 跳转链接bff接口返回，switch case 后续删除
    return item.webClickUrl
  }
  let link = 'javascript:;'
  const itemHrefType = item.hrefType
  const itemHrefTarget = item.hrefTarget
  switch (itemHrefType) {
    // 不跳转
    case 'noJump':
      link = 'javascript:;'
      break
    // Web 链接
    case 'webLink':
      // isInternalLink
      if (item.isInternalLink == 1) {
        // 内部链接
        link = `${langPath}${itemHrefTarget}`
      } else {
        // 外部链接
        link = itemHrefTarget
      }
      break
    // 自定义链接
    case 'customJump':
      try {
        const targetObj = JSON.parse(itemHrefTarget)
        if (targetObj.pwa) {
          // isInternalLink
          if (targetObj.pwa.isInternalLink == 1) {
            // 内部链接
            link = `${langPath}${targetObj.pwa.hrefTarget}`
          } else {
            // 外部链接
            link = targetObj.pwa.hrefTarget
          }
        }
      } catch (error) {
        console.error(error)
      }
      break
    case 'skuList':
    case 'real':
    case 'sku':
    case 'frontCat':
    case 'itemPicking':
      {
        const attrKey = itemHrefType === 'skuList' ? 'sku' : itemHrefType
        let cateLink = cateLinks[`${attrKey}_${itemHrefTarget}`] || ''
        if (cateLink.url) cateLink = cateLink.url
        const urlLink = cateLink || item.relativeUrl
        if (urlLink) {
          link = `${langPath}${urlLink}`
        }
      }
      break
    // 新专题 （专题新CCC数据路由）
    case 'activityNew':
      link = `${langPath}/campaigns/${itemHrefTarget}`
      // 检查是否为一级路径
      try {
        const list = JSON.parse(localStorage.getItem('campaigns_tiny_url_list'))
        const path = list?.value?.filter(i => i.identity === itemHrefTarget)[0]?.tinyUrl
        if (path) {
          link = `${langPath}/${path}`
        }
      } catch (e) {
        console.error(e)
      }
      break
    // 原专题 （专题旧CCC数据路由）
    case 'activityOld':
      link = `${langPath}/campaign/${itemHrefTarget}`
      break
    case 'flashSale': // [shein]
    case 'lightningDeal': // [rowme]
      link = `${langPath}/flash-sale.html`
      break
    // [shein]
    case 'sheinPicks':
      // link = transformPromodiscountUrl(`${langPath}/shein-picks.html?hrefTarget=${itemHrefTarget}`)
      link = `${langPath}/shein-picks.html?hrefTarget=${itemHrefTarget}`
      break
    case 'dailyNew':
      link = `${langPath}/daily-new.html`
      break
    // todo: 待确认
    case 'thriftyFind':
      link = `${langPath}/super-deals`
      break
    case 'category':
      link = `${langPath}/category/`
      break
    // 问卷调查
    case 'survey':
      link = `${langPath}/survey/${itemHrefTarget}`
      break
    // 签到页
    // case 'checkInPage':
    //   link = `${langPath}/checkInPage/${itemHrefTarget}`
    //   break
    //   // LookBook
    // case 'LookBook':
    //   link = `${langPath}/lookbook/${itemHrefTarget}`
    //   break
    //   // Category
    // case 'Category':
    //   link = `${langPath}/category/${itemHrefTarget}`
    // break
    // 试用中心
    case 'trial':
      {
        const urlLink = cateLinks[itemHrefType] || item.webClickUrl
        if (urlLink && !(urlLink.indexOf('javascript:;') > -1)) {
          link = `${langPath}${urlLink}`
        }
      }
      break
    // 试用列表
    case 'lookBook':
      link = `${langPath}/shop-multiple-sets-${itemHrefTarget}.html`
      break
    // 文章页
    case 'article':
      {
        if (item.relativeUrl) {
          link = `${langPath}/${item.relativeUrl}.html`
        } else {
          const linkName = item.selectTypeName || 'article'
          link = `${langPath}/${linkName}-a-${itemHrefTarget}.html`
        }
      }
      break
    // 游戏
    case 'game':
      link = item.gameType ? `${langPath}/game/${item.gameType}/${itemHrefTarget}` : 'javascript:;'
      break
    // 第三方店铺列表 [shein]
    case 'thirdPartyStoreList':
    case 'thirdPartyStoreHome':
      {
        let storeLink = cateLinks[`store_${itemHrefTarget}`] || item.webClickUrl
        if (storeLink) {
          link = `${langPath}${storeLink}`
        }
      }
      break
    // 设计师招募页 [shein]
    case 'sheinxDesignerRecruit':
      link = `${langPath}/sheinx`
      break
    // 设计师详情页 [shein]
    case 'sheinxDesignerInformation':
      link = `${langPath}/designer/${itemHrefTarget}`
      break
    // [romwe] 折扣列表
    case 'promoDiscount':
      // link = transformPromodiscountUrl(`${langPath}/promo-discount.html?hrefTarget=${itemHrefTarget}`)
      link = `${langPath}/promo-discount.html?hrefTarget=${itemHrefTarget}`
      break
    // 礼品卡
    case 'giftCard':
      link = `${langPath}/giftcard.html`
      break
    // 付费会员落地页
    case 'prime':
      link = `${langPath}/user/prime`
      break
    // 一口价商品列表页
    case 'underPrice':
      link = `${langPath}/under-prices-products.html`
      break
    // 设计师列表页
    case 'sheinxDesignerList':
      link = `${langPath}/designer_list`
      break
    // 信息流落地页
    case 'informationFlow':
      link = `${langPath}/picked-info-products.html`
      break
    // LoverRomwe
    case 'loveromwe':
      link = `${langPath}/member-image-list-outfit.html`
      break
    // 优惠券中心
    case 'couponCenter':
      link = `${langPath}/user/coupon`
      break
    // 个人中心 - 积分
    case 'pointsCenter':
      link = `${langPath}/user/points`
      break
    // 个人信息编辑页
    case 'userInfoEdit':
      link = `${langPath}/user/account_setting`
      break
    // 直播回放
    case 'singleReplay':
      link = `${langPath}/live/${itemHrefTarget}`
      break
    // 直播预告
    case 'singlePreview':
      link = `${langPath}/live/${itemHrefTarget}`
      break
    // 正在直播
    case 'galsLiveEntrance':
      link = `${langPath}/live/${itemHrefTarget}`
      break
    // case 'galsLiveList':
    //   link = `${langPath}/live/${itemHrefTarget}`
    //   break
    // // 社区视频直播
    // case 'galsVideoLive':
    //   link = `${langPath}/live/${itemHrefTarget}`
    //   break
    // 第三方应用唤起
    case 'deepLink':
      link = itemHrefTarget
      break
    // 老带新页面
    case 'oldWithNew':
      link = `${langPath}/shareandearn`
      break
    // 原生品类落地页（二级首页）
    case 'homepageChannel':
      link = `${langPath}/secondaryhome`
      break
    // 品牌馆
    case 'brandZone':
      link = `${langPath}/brand-zone`
      break
    // 好看频道(跳转趋势频道页面)
    case 'trendsChannel':
      link = `${langPath}/top-trend`
      break
    // 跳店铺页面
    case 'storeHome':
      link = `${langPath}/store/home`
      break
    // 专题new页面
    case 'newChannel':
      link = `${langPath}/campaigns/shein_new`
      break
    default:
      break
  }
  return link || 'javascript:;'
}

// const generateProductSource  = ({ cate = {}, cateType = '', channel, oneCateInfo }) => {
//     // generate product source for analysis
//     const gaInfoArr = (cate?.contentAnalysis?.analysisInfo?.gaInfo || '-`').split('-\`')
//     const isWebLink = cate?.analysisInfo?.webLink
//     return `${cateType}-${gaInfoArr[0]}-${channel?.channelName || channel?.name }-${oneCateInfo?.enName || oneCateInfo?.name || oneCateInfo?.firstFloorContent?.props?.metaData?.categoryName || ''}-${isWebLink ? encodeURIComponent(gaInfoArr[1]) : gaInfoArr[1]}`
// }
export function getGenerateLLinkParams({
  cate,
  cateType /*, channel, channelIndex, oneCateContent, compIndex, oneCate*/,
}) {
  const { SaPageInfo } = useAppConfigs().$getters || {}
  const src_tab_page_id = `${SaPageInfo?.page_name || ''}${SaPageInfo?.start_time || ''}`
  const { query, userpath, userpathFrom = {}, productsource } = cate?.contentAnalysis?.locationInfo || {}
  // const item_loc = cate?.contentAnalysis?.item_loc || 0
  // const oneCateName = oneCateContent?.firstFloorContent?.props?.metaData?.categoryName
  // const ps = getGenerateSaPs({ position: item_loc, active: { ...channel, channelIndex: channelIndex }, activeParentCatePosition: oneCate, activeChildCatePosition: compIndex, isChildCate: false })
  // console.log('cate?.contentAnalysis',cate?.contentAnalysis);
  const userpathquery = userpath
    ? `&srctype=category&userpath=${
        (cateType === 'cat' ? userpathFrom.cate || '' : userpathFrom.shop || '') + userpath
      }`
    : ''
  const src_identifier = cate?.contentAnalysis?.content_list
  const params = Object.assign(
    {},
    parseQueryString(query + userpathquery),
    { src_module: cateType },
    { src_identifier },
    { src_tab_page_id },
    { productsource: `${cateType}-${productsource}` },
    cate.mallCodes ? { mallCode: cate.mallCodes } : {},
    cate.hrefType || cate?.categoryType?.hrefType ? { fromPageType: 'category' } : {},
  )
  return params
}

export function isInSpa({ hrefType }) {
  return !['webLink', 'deepLink', 'survey', 'article', 'giftCard', 'game', 'sheinxDesignerList', 'customJump'].includes(hrefType)
}

export const preFetchOrGetPageUrl = ({ url, options = {} } = {}) => {
  if (!url) return
  try {
    const { isReturnUrl = false } = options || {}
    // const { host, SW_FETCH_HTML_CONFIG = {} } = window.gbCommonInfo || {}
    // 是否有顶部banner
    // const hasTopBanner = window.gbCommonInfo?.hasTopBanner || (window.sheinBanner?.topBanner?.Local?.setFlag && window.sheinBanner?.topBanner?.Local?.setFlag !== 'hide')
    // if (hasTopBanner) {
    //   url = `${url}${url.indexOf('?') > -1 ? `&hasTopBanner=1` : `?hasTopBanner=1` }`
    // }
    // const { enableSceneMap = [], preFetchSceneMap = [] } = SW_FETCH_HTML_CONFIG || {}
    // // 是否开启sw页面代理
    // if (!enableSceneMap.includes(scene)) return url
    // sw页面代理url
    const requestUrl = `${url}${url.indexOf('?') > -1 ? '&proxy_enable=1' : '?proxy_enable=1'}`
    if (isReturnUrl) return requestUrl
  } catch (error) {
    return url
  }
}
export const jumpLink = ({ url, hrefType, routeType = 'push', scene = 'cccx' }, router, emits) => {
  if (url.indexOf('javascript:;') > -1) return
  if (isInSpa({ hrefType }) && hrefType !== 'activityNew') {
    emits && emits('markPoint', 'toNextPageClick', 'public')
    if (window?._gb_app_) {
      router[routeType](url)
    } else {
      // 兼容多页存在无_gb_app_实例case
      location.href = url
    }
  } else {
    sessionStorage.setItem('navtrack_to', 'page_campaigns')
    sessionStorage.setItem('navtrack_point', Date.now())
    location.href = preFetchOrGetPageUrl({ url, options: { isReturnUrl: true, scene } })
  }
}
