import type { DS_CheckoutPrice } from '../../../types'

// TODO write your apis
export const api1: DS_CheckoutPrice.APIS_THIS['api1'] = async function (x, y, z) {
  const result = await this.$schttp<Array<number>>({
    url: '/api/*',
    params: { x, y, z },
  })
  return result.data
}
