<template>
  <div v-if="isNeedDrawer">
    <SDrawer
      v-expose="exposeFormOnGiftCardList()"
      :visible="visible"
      :show-close="true"
      append-to-body
      :close-on-click-modal="true"
      @close-from-icon="handleClose"
      @close-from-mask="handleClose"
    >
      <template #top>{{ language?.SHEIN_KEY_PWA_35126 }}</template>
      <div class="bsg-drawer-content">
        <GiftForm
          ref="giftFormRef"
          :isShowSwitch="isShowSwitch"
          :language="language"
          :sence="sence"
          :visible="visible"
          :isNeedRecordsHistory="isNeedRecordsHistory"
          :linkBtnTxt="linkBtnTxt"
          :giftCardList="giftCardList"
          @bind-card-cb="bindCardCb"
        />
        <UseageProcess
          v-if="isShowStepText"
          :language="language"
        />
      </div>
    </SDrawer>
  </div>
  <div v-else>
    <GiftForm
      ref="giftFormRef"
      v-expose="exposeFormOnGiftCardList()"
      :isShowSwitch="isShowSwitch"
      :language="language"
      :sence="sence"
      :isNeedRecordsHistory="isNeedRecordsHistory"
      :linkBtnTxt="linkBtnTxt"
      @bind-card-cb="bindCardCb"
    />
    <UseageProcess
      v-if="isShowStepText"
      :language="language"
    />
  </div>
</template>

<script name="BBindGiftCard" setup lang="ts">
import GiftForm from './components/gift-form.vue'
import UseageProcess from './components/useage-process.vue'
import { useAnalysis } from '../../common/analysisSource'
import { provide, onMounted, ref, withDefaults } from 'vue'

import {
  Drawer as SDrawer,
} from '@shein/sui-mobile'
import useApis from './hooks/useApis'

interface GiftFormInstance {
  checkGiftCard: (object) => void;
}

type propsType = {
  sence: string
  isShowSwitch?: boolean
  isShowStepText?: boolean
  isNeedRecordsHistory?: boolean
  linkBtnTxt?: string
  isNeedDrawer?: boolean
  visible?: boolean
  giftCardList?: Array<any> | null
}

const props = withDefaults(defineProps<propsType>(), {
  sence: 'person',
  isShowSwitch: false,
  isShowStepText: true,
  linkBtnTxt: '',
  isNeedRecordsHistory: true,
  giftCardList: () => [],
  isNeedDrawer: true,
  visible: false,
})

const giftFormRef = ref<GiftFormInstance | null>(null)

const emit = defineEmits([
  'handle-close',
  'bind-card-cb',
])
const analysisInstance = useAnalysis()
provide('analysisInstance', analysisInstance)
const { vExpose } = analysisInstance
// 埋点源: END
const { langs } = useApis()
const language = ref()

onMounted(() => {
  getLangs()
})

const getLangs = () => {
  langs().then(res => {
    language.value = res
  })
}

const exposeFormOnGiftCardList = () => {
  let obj = {}
  if(props?.sence == 'checkout') {
    obj = {
      id: 'expose_input_giftcard_pop.comp_bind-gift-card',
    }
  }
  return obj
}

const bindCardCb = (data) => {
  emit('bind-card-cb', data)
}
const handleClose = () => {
  emit('handle-close')
}
const checkGiftCard = (data) => {
  if (giftFormRef.value) {
    giftFormRef.value?.checkGiftCard(data)
  }
}

defineExpose({
  checkGiftCard,
})
</script>
<style lang="less">
.bsg-drawer-content {
  padding: 8/37.5rem 12/37.5rem;
}
</style>
