import schttp from 'public/src/services/schttp'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { ApiCache } from './ApiCache/index'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { getStoreCodeFromUrl } from 'public/src/pages/store_pages/js/utils.js'
import { getQueryString } from '@shein/common-function'
import { getHistoryData } from 'public/src/pages/common/search_words/utils/searchHistoryLocalStorage.js'
import abtPosKeys from './config/abt'
import languageKeys from './config/language'
import { cleanNullValueObjectFirstLevel } from './productList/bffParamsHandle/utils'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index.js'

export const rankBgIconTop3Map = [
  '/pwa_dist/images/search/rank_1-3692b60124.svg',
  '/pwa_dist/images/search/rank_2-0761c3f98a.svg',
  '/pwa_dist/images/search/rank_3-20863f21b2.svg'
]
export const trendDefaultSearchIcon = '/pwa_dist/images/search/rank_other-5a804fff61.svg'
export const trendTitielIcon = '/pwa_dist/images/search/icon_crown-d4cb48eb41.svg'

const { IS_RW } = gbCommonInfo
const usBff = isSwitchBffApiVersion(['preSearch_v2'])?.['preSearch_v2']

async function fetchSearchPageDataBff(params) {
  let abtInfo = null
  if (usBff) {
    const abtDta = await fetchPresearchAbt()
    abtInfo = abtDta.info.pos
  } else {
    abtInfo = await getUserAbtData()
  }
  const {
    SearchTrendNew = {},
  } = abtInfo

  let wordsParamsExtend = null
  if ((Object.keys(params).length === 3 && params.scene === 'home' && !params.goods_id && !params.cate_id) || params.scene === 'detail') {
    // 通过参数确定是首页场景的预请求
    const historyData = getHistoryData('all').splice(0, 5)
    const wordIds = []
    const keywords = []
    historyData.forEach( i => {
      wordIds.push(i?.word_id || 0)
      if (typeof i === 'string') {
        keywords.push(i)
      } else {
        keywords.push(i.word?.replace(',', ' '))
      }
    })
    wordsParamsExtend = {
      recent_search_words: keywords.join(),
      recent_search_word_ids: wordIds.join(),
    }
  }

  const [defaultRes, hotTrendRes, languageABtRes, bffLang] = await Promise.all([
    fetchPresearchWord({
      word_type: 1,
      ...params,
      ...wordsParamsExtend
    }), // 底纹词
    fetchPresearchWord({
      word_type: 2,
      ...params,
      ...wordsParamsExtend
    }), // 热词 + 趋势榜 + 趋势词趋势榜(type2才有)
    fetchSearchPageDataPwa({ ...params, onlyLanguage: true }), // 多语言
    fetchPresearchLang()
  ])
  if (defaultRes?.code != 0 || hotTrendRes?.code != 0) {
    // 底纹词和热词数据有问题，手动抛出，走pwa中间层
    throw new Error(JSON.stringify({
      defaultRes,
      hotTrendRes,
    }))
  }
  const trendData = hotTrendRes?.info?.multiTrendKeywords
  const language = usBff ? bffLang?.info?.result : languageABtRes?.info?.language
  const abtInfoSearchTrendNew = SearchTrendNew?.p || {}
  const defaultWords = defaultRes?.info?.keywords || []
  let defaultWordsHKTagOn = false
  for (let i = 0; i < defaultWords.length; i++ ) {
    if ( ['trend', 'hot', 'sold', 'like'].includes(defaultWords[i].wordLabel?.labelType)) {
      defaultWordsHKTagOn = true
      break
    }
  }
  const isGoodsDetail = params.goods_id && !!Number(params.goods_id,)
  const goodsId = (isGoodsDetail && params.goods_id) || ''
  const hotInfo = hotTrendRes?.info || {}
  const res = {
    info: {
      language,
      hot: {
        defaultTagOn: false, // 热词无默认词标签
        hasTag: false, 
        HKTagOn: false, // 数据客户端暂时没有用到，先和pwa中间层一样返回该字段
        trace_id: hotInfo.trace_id || '-',
        keywords: (hotInfo.keywords || []).map(item => ({ ...item, trace_id: hotInfo.trace_id })),
      },
      default: { 
        defaultTagOn: defaultWordsHKTagOn,
        hasTag: false, 
        HKTagOn: false, // 数据客户端暂时没有用到，先和pwa中间层一样返回该字段
        trace_id: defaultRes?.info?.trace_id,
        keywords: defaultWords.map(item => ({ ...item, trace_id: defaultRes?.info?.trace_id })),
      },
      scene: params.scene,
      store_code: params.store_code,
      paramsGoodsId: goodsId,
      abtData: usBff ? abtInfo : languageABtRes?.info?.abtData,
      trendHome: !goodsId ? dealWithTrendData({ data: trendData, type_id: abtInfoSearchTrendNew.Home, language }) : [],
      trendDetails: goodsId ? dealWithTrendData({ data: trendData, type_id: abtInfoSearchTrendNew.GoodsDetail, language }) : [],
      trendBoardData: {
        trendLeaderBoardList: hotTrendRes?.info?.trendLeaderBoardList,
        trendLeaderBoardExtraConfig: hotTrendRes?.info?.trendLeaderBoardExtraConfig || {},
      }
    }
  }
  return res
}

function fetchSearchPageDataPwa(params) {
  if( usBff) {
    return Promise.resolve({})
  }
  return schttp({
    url: '/api/searchwords/searchNecessaryData/get',
    isPreLoad: true,
    params
  })
}

async function fetchSearchPageData(params) {
  let res = {}
  res =  await fetchSearchPageDataBff(params)
  const { trendDetails, trendHome, hot } = res?.info || {}
  const hotWordList = (hot?.keywords || []).map(i => i.imgSrc).filter( i => !!i)
  const trendDList = [...(trendDetails || []), ...(trendHome || [])]
  // 取两个趋势的列表下的前三个商品进行预取图片
  if (trendDList.length) {
    const trendDListFirstTwo = trendDList.slice(0, 2)
    const trendDListFirstTwoGoods = trendDListFirstTwo.reduce((acc, cur) => {
      const urls = (cur?.info || []).slice(0, 3).map(item => item?.pageUrl || item?.word_image )
      return [...acc, ...urls].filter(item => item)
    }, [])
    if (trendDListFirstTwoGoods.length || hotWordList.length) {
      const imgs = [...trendDListFirstTwoGoods, ...rankBgIconTop3Map, trendDefaultSearchIcon, trendTitielIcon, ...hotWordList]
      preloadImg(imgs)
    }
  }
  return res
}

const apiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    return await fetchSearchPageData(params)
  },
  notCacheKeys: [],
})

let preRequestParams = {
  goods_id: '',
  cate_id: '',
  scene: ''
}
const handlePreSeachRouterTriggerQuickRequestBefore = () => {
  requestIdleCallback(() => {
    apiCache.request(preRequestParams)
  })
}

/* 空闲时间触发预请求 */ // 修改成首页空闲的时候请求 handlePreSearchBeforeEachRoute
// ;(function flushPreSeachFetchApi() {
//     if (typeof window === 'undefined') return
//     setTimeout(() => {
//       requestIdleCallback(() => {
//         const isTopTrendSearch = /presearch/.test(location.pathname) && getQueryString({ key: 'pageType' }) === 'pageTrend'
//         const isList = /.+?-(c|sc)-(\d+).html$/.test(location.pathname) || /.+?-picks.html$/.test(location.pathname) 
//         if (!/\/.+-p-(\d+)(-cat-(\d+))?\.html/.test(location.pathname) && !isTopTrendSearch && !isList) {
//           // 因为入参cate_id 商详预请求和 在商详预搜页发起的请求两边数据不一致，因此商详页不再预获取预搜页内容
//           handlePreSeachRouterTriggerQuickRequestBefore()
//         }
//       })
//     }, 1000)
// })()

export const handlePreSearchBeforeEachRoute = (to, from) => {
  // 商详页去预搜页有可能携带goods_id
  let goods_id = [to.name, from.name].includes('page_goods_detail') ? !IS_RW && (to.params?.['1'] || (!to.params?.['1'] && from.params?.['1'])) || '' : ''
  let cate_id = ''
  if (to?.name === 'page_goods_detail') {
    cate_id = '' // 商详页面现在拿不到cate_id 了，因此商详页面预请求预搜页数据，商详相似会无数据
  } else if (to?.name === 'pre_search') {
    cate_id = to.query?.cate_id || '' // 商详跳预搜页会有cate_id
    goods_id = to.query?.goods_id || ''
  }
  Object.assign(preRequestParams, { goods_id, cate_id })
  // 店铺选品页进入预搜页带上店铺code请求底纹词
  if ([to.name, from.name, to?.query?.pagefrom, from?.query?.pagefrom].includes('page_store') || (to?.query?.store_code && to?.query?.searchExpand === '1')) {
    const store_code = to?.query?.store_code || from?.query?.store_code || getStoreCodeFromUrl()
    Object.assign(preRequestParams, { scene: 'store', store_code })
  } else if ([to?.query?.pagefrom, from?.query?.pagefrom].includes('page_top_trend') || ['trend_landing', 'top_trend' ].includes(to?.name)) {
    delete preRequestParams?.cate_id
    delete preRequestParams?.goods_id
    Object.assign(preRequestParams, { scene: 'trendChannel' })
  } else if (goods_id && !!Number(goods_id) ) {
    preRequestParams = { goods_id, cate_id, scene: 'detail' }
  } else {
    preRequestParams = { goods_id, cate_id, scene: 'home' }
  }
  if (to?.name === 'config_index') {
    const loadComponent = prefetchResource.importAsyncComponent({
      chunkName: 'pre_search', // 跟webpackChunkName保持一致
      componentFactory: () => import( /* webpackChunkName: 'pre_search' */ 'public/src/pages/common/search_words/search.vue'),
    })
    loadComponent?.()
  }
  handlePreSeachRouterTriggerQuickRequestBefore()
}

export const getSearchNecessaryData = async (params) => {
  let result = await apiCache.request(params)
  try {
    // 修复Observer数据污染的问题
    result = JSON.parse(JSON.stringify(result))
  } catch (error) {
    result = {}
  }
  return result
}

// 获取预搜页实验
export const fetchPresearchAbt = () => {
  return schttp({
    url: '/abt/merge/get_abt_by_poskey',
    params: {
      posKeys: abtPosKeys.join()
    },
    useBffApi: true
  })
}

// 获取预搜页多语言
export const fetchPresearchLang = () => {
  return schttp({
    url: '/system/configs/multi_language',
    method: 'POST',
    data: {
      languageKeys,
    },
    useBffApi: true
  })
}

// 获取 底纹词/热搜词、趋势榜
function fetchPresearchWord(data = {}) {
  const { word_type, goods_id, scene, store_code, cate_id: cat_id, recent_search_word_ids = '', recent_search_words = '' } = data
  const isGoodsDetail = goods_id && !!Number(goods_id) // pick 页面等 拿到的不是goodsId
  const requsetScene = ['store', 'trendChannel'].includes(scene) ? scene : isGoodsDetail ? 'detail' : 'home'
  const params = {
    goods_id: (isGoodsDetail && word_type === 1 && goods_id) || '',
    cat_id: word_type === 1 ? cat_id : '',
    scene: requsetScene,
    word_type: word_type,
    store_code: store_code,
    ...(!['store', 'brand'].includes(getQueryString({ key: 'pageType' })) && ['detail', 'home'].includes(requsetScene) ? { recent_search_word_ids, recent_search_words, biz_scene: requsetScene === 'detail' ? 'GoodsDetail' : 'HomePage', } : null),
  }
  return schttp({
    url: '/product/search/v3/get_keywords',
    useBffApi: true,
    isPreLoad: true,
    params: cleanNullValueObjectFirstLevel(params || {}),
    method: !['store', 'brand'].includes(getQueryString({ key: 'pageType' })) && ['detail', 'home'].includes(requsetScene) ? 'POST' : 'GET',
    ...(!['store', 'brand'].includes(getQueryString({ key: 'pageType' })) && ['detail', 'home'].includes(requsetScene) ? { featureKey: 'search_association_words_cycle' } : null),
  })
}


// 处理趋势榜数据
function dealWithTrendData({ data, type_id = '', language = {} }) {
  if (!data || !Array.isArray(data)) return []
  return data.map((v, vi) => {
    v.trend_name = v.trend_name ? `${v.trend_name} ${language.SHEIN_KEY_PWA_24660}` : language.SHEIN_KEY_PWA_24658
    const result_content = []
    const trend_list_content = []
    
    // 埋点相关参数处理
    v.info = v.info.map((item, index) => {
      item.type_id = type_id
      item.position = index + 1
      item.result_content = `11\`${item.word}\`${item.position}\`${type_id}\`${item.new_flag}\`${item.wordLabel?.labelType || '-'}\`${item.word_type || 'common'}`
      item.trend_list_content = `${v.cate_flag}\`${v.trend_name}\`${v.cate_id}\`${vi + 1}\`${item.word}\`${index + 1}\`${item.new_flag}`
      item.trend_list_content_click = item.trend_list_content + '`1'

      // 埋点方需要展示3分2以上才上报，所以将指令式埋点塞到3分2的位置
      item.isExpose = Math.ceil(v.info.length * 0.7) === index
      item.state = item.rank_change?.includes('-') ?  -1 :  item.rank_change?.includes('+') ? 1 : 0

      result_content.push(item.result_content)
      trend_list_content.push(item.trend_list_content)

      return item
    })

    v.result_content = result_content.join()
    v.trend_list_content = trend_list_content.join()
    return v
  })
}

export async function fetchAbtDisableApolloConfig() {
  if (typeof window !== 'undefined') return window?.gbCommonInfo?.SEARCH_ABT_COMPLETE_V2 === 'on'
  const res = await schttp({
    method: 'POST',
    url: '/api/config/apolloConfig/query',
    data: { apolloKeys: 'SEARCH_ABT_COMPLETE_V2' }
  })
  return res?.info?.SEARCH_ABT_COMPLETE_V2 === 'on'
}
