import { TMGTriggerForRenderPage, TMGTriggerForLandingRenderPage } from 'public/src/pre_requests/modules/trend.js'
import { isSwitchBffSsr } from 'public/src/pages/common/bff-new/index.js'
const switchTrendLandingComp = (useBff = false) => {
  const isClient = typeof window !== 'undefined'
  const bffDebugger = isClient ? localStorage.getItem('bffDebugger') === '1' : false
  const switchBff = isClient ? bffDebugger ? bffDebugger : isSwitchBffSsr(['trend_landing'])?.['trend_landing'] : useBff
  if (!switchBff)
    return () => import( /* webpackChunkName: "trend_landing" */ '../../trend/pages/TrendLanding.vue')
  return () => import( /* webpackChunkName: "trend_landing_bff" */ '../../trend/pages/bff/TrendLanding.vue')
}

export const createTredRoute = (langPath = '', useBff = false) => {
  return [
    {
      path: `${langPath}/top-trend`,
      name: 'top_trend',
      meta: {
        keepAlive: true,
        pageType: 'top_trend',
        monitorTag: 'top_trend',
        isCssInVue: true,
        notNeedCommonHeader: true,
      },
      component: () => import( /* webpackChunkName: "user_news" */ '../../trend/pages/TopTrend.vue'),
      beforeEnter: (to, from, next) => {
        if(typeof window !== 'undefined'){
          if(from.name && from.name != 'top_trend' && to.query.earlyTmgRequest1210 === '1'){
            TMGTriggerForRenderPage(to)
          }
        }
        next()
      },
    },
    {
      path: `${langPath}/trend-landing`,
      name: 'trend_landing',
      meta: {
        keepAlive: true,
        pageType: 'trend_landing',
        monitorTag: 'trend_landing',
        isCssInVue: true,
        notNeedCommonHeader: true,
      },
      component: switchTrendLandingComp(useBff),
      beforeEnter: (to, from, next) => {
        if(typeof window !== 'undefined'){
          if(from.name && from.name != 'trend_landing' && to.query.earlyTmgRequest1210 === '1'){
            TMGTriggerForLandingRenderPage(to)
          }
        }
        next()
      },
    },
    {
      path: `${langPath}/trend-outfits`,
      name: 'trend_outfits',
      meta: {
        keepAlive: true,
        pageType: 'trend_outfits',
        monitorTag: 'trend_outfits',
        isCssInVue: true,
        notNeedCommonHeader: true,
      },
      component: () => import( /* webpackChunkName: "user_news" */ '../../trend/pages/TrendOutfits.vue'),
    }
  ]
}
