import Vue from 'vue'
import { appValidBranch } from 'public/src/pages/common/analysis/branch.js'
import {
  BANNER_EXPORATION_TIME,
  removeApp,
  emitClearApp,
  handleEmitApp,
} from './utils.js'
import { branchCookie, setBranchCookie } from './branchCookie.js' 
/**
 * branch逻辑展示：通过当前页是否展示， 展示后保存当前页的type到cookie中；
 * */
export const installAppBranch = async ({ router, store }) => {
  window.JOURNEY_BRANCH && (window.JOURNEY_BRANCH.hasTopBranch = false)
  const { cookieValue, branchCookieKey } = branchCookie()

  if (cookieValue === 'c') {
    // 用户关闭后时间还没有过期，不再展示。
    document.getElementById('branch-app')?.remove()
    return
  }

  const showPages = (cookieValue ? cookieValue.split('_') : []).filter(item => item)
  let href = location.pathname
    .replace(`/${gbCommonInfo.lang}`, '')
    .toLowerCase()
  if (href === '/') {
    href = '-indexHome-'
  }

  const isCache = showPages.some(v => new RegExp(v).test(href)) && !document.querySelector('.branch-first')

  const valid = await appValidBranch()
  if (!valid) {
    document.getElementById('branch-app')?.remove()
    return
  }

  const journeyBranchCompoment = await import(
    /* webpackChunkName: "journey-branch-async" */ 'public/src/pages/components/journey-branch'
  )

  const branchDispach = ({ el, forced }) => {
    const strs = [
      'vue-journey-fade-slide-top-enter',
      'vue-journey-fade-slide-top-enter-active',
      'v-enter',
      'v-enter-active',
    ] // 存储html字段删除动画style
    return handleEmitApp({
      type: 'branch',
      el,
      forced,
      cb: outerHTML => {
        outerHTML = strs.reduce((prev, cur) => prev.replace(cur, ''), outerHTML)
        return outerHTML.replace('position: fixed', 'position: absolute')
      },
    })
  }

  return new Vue({
    el: '#shein-branch',
    router,
    store,
    render: h => {
      return h(journeyBranchCompoment.default, {
        props: {
          cacheBranch: isCache,
        },
        on: {
          showDone(el, { showHostPages = [] } = {}) {
            window.JOURNEY_BRANCH.hasTopBranch = true
            removeApp('branch-site', { removeHeight: false }) // 只删除占位元素， 不删除父容器的占位的高度，组件内部自行控制
            setBranchCookie({
              key: branchCookieKey,
              value: showHostPages.join('_'),
              end: BANNER_EXPORATION_TIME,
            })
            branchDispach({ el })
          },
          /**
           * 点击关闭x按钮进行关闭时触发
           * expirationTime 毫秒
           */
          eventHide: expirationTime => {
            document.getElementById('branch-app')?.remove()
            setBranchCookie({
              key: branchCookieKey,
              value: 'c',
              end: expirationTime / 1000,
            })
            emitClearApp({ type: 'branch' })
          },

          updateDom(el) {
            branchDispach({ el, forced: true })
          },
        },
      })
    },
  })
}
