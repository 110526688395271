<template>
  <SPopover
    v-model="isPopoverShow"
    class="store-header__more"
    trigger="unset"
    trigger-type="user"
    theme="light"
    placemen="bottom-end"
    :prop-style="{
      zIndex: 301,
      transform: 'translate3d(0, 0, 301px)'
    }"
    append-to-body
  >
    <template slot="reference">
      <div 
        ref="headerMoreIcon" 
        class="store-header__more-wrapper"
        :class="{ 'red-dot': isShowRedDot }"
        @click.stop="handleHeaderMoreIcon"
      >
        <i 
          class="suiiconfont sui_icon_more_20px store-header__more-icon"
        ></i>
      </div>
    </template>
    <ul class="store-header__more-list">
      <li
        v-for="(item, index) in navList"
        :key="index"
        class="header-more__list-item haptics-feedback"
        :class="{ 'red-dot': isShowRedDot && item.trackName === 'contact' }"
        @click.stop="handleListItem(item, index)"
      >
        <Icon 
          :name="item.icon"
          :is-rotate="GB_cssRight"
          size="18px" 
        />
        <span class="header-more__item-text">{{ item.name }}</span>
      </li>
    </ul>
  </SPopover>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StoreHeaderMore',
})
</script>
<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { Popover as SPopover } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { DEFAULT_CONFIG } from './storeHeaderUtils.js'
import { useStorePageData } from '../../hooks/useStoreHeader.js'
import { hasRobotEntrance } from 'public/src/pages/common/robotEntrance'
import schttp from 'public/src/services/schttp'

const { language, langPath, router, routerGoWish, storeCode, storeData, GB_cssRight } = useStorePageData()
const isPopoverShow = ref(false)
const headerMoreIcon = ref(null)
const switchByTrackName  = ref({ // 动态开关
  support: false,
  contact: false,
})
const isShowRedDot = ref(false)

const navList = computed(()=>{
  return DEFAULT_CONFIG
    .filter(v => {
      return v.show || switchByTrackName.value[v.trackName]
    })
    .map((item)=>{
      const { i18nKey, defaultName, ...rest } = item || {}
      return {
        ...rest,
        name: language.value[i18nKey] || defaultName,
      }
    })
})

const handleClosePopver = ()=>{
  isPopoverShow.value = false
}
const touchOutside = (e)=>{
  if(headerMoreIcon.value && headerMoreIcon.value.contains(e.target)) return
  handleClosePopver()
}

const bindEvnets = ()=>{
  window.addEventListener('scroll', handleClosePopver, false)
  window.addEventListener('touchend', touchOutside, false)
}


const cleanEvents = ()=>{
  window.removeEventListener('scroll', handleClosePopver, false)
  window.removeEventListener('touchend', touchOutside, false)
}

watch(()=>isPopoverShow.value, (val)=>{
  if(val){
    bindEvnets()
  }else{
    cleanEvents()
  }
})

const handleHeaderMoreIcon = ()=>{
  isPopoverShow.value = !isPopoverShow.value
}
const handleListItem = (item)=>{
  const { fnName } = item
  if(listItemEvents[fnName]){
    listItemEvents[fnName](item)
  }
  isPopoverShow.value = false
}
// 店铺关注页
const handleLinkToFollowedShops = ()=>{
  daEventCenter.triggerNotice({
    daId: '2-9-13',
  })
  const login = isLogin()
  if (!login){
    return SHEIN_LOGIN.show({
      show: true,
      cb: () => {
        handleLinkToFollowedShops()
      }
    })
  }
  router.push({
    path: `${langPath}/store/following`,
    query: {
      page_from: 'page_store',
      entrance: 'following',
    },
  })
}
// 收藏
const handleWishlistClick = ()=>{
  markPoint('toNextPageClick', 'public')
  daEventCenter.triggerNotice({
    daId: '2-9-14',
  })
  if(routerGoWish){
    routerGoWish({ pf: 'topbar' }, true)
  }
    
}
// 足迹
const handleLinkToRecentViewed = ()=>{
  daEventCenter.triggerNotice({
    daId: '2-9-10',
  })
  router.push(`${langPath}/user/index?rt=rv`)
}
// 分享
const handleShareClick = ()=>{
  daEventCenter.triggerNotice({
    daId: '2-9-16',
  })
  window.appEventCenter &&  window.appEventCenter.$emit('handleHeaderShareForStore')
}
// 平台客服
const handleLinkToSupport = ()=>{
  daEventCenter.triggerNotice({
    daId: '2-9-17',
  })
  const login = isLogin()
  if (!login) {
    return SHEIN_LOGIN.show({
      show: true,
      toRobot: true,
      cb: () => {
        handleLinkToSupport()
      }
    })
  }
  router.push({
    path: `${langPath}/robot`,
    query: {
      storecode: storeCode.value,
      page: 'storePage',
      page_name: 'page_store',
      business: +storeData.value.storeType === 1 ? '1' : '0', // 自营-0；平台-1
    },
  })
}

// 商家客服
const handleLinkToContactSeller = () => {
  daEventCenter.triggerNotice({
    daId: '2-9-24',
    extraData: {
      storeCode: storeCode.value,
    }
  })
  const login = isLogin()
  if (!login) {
    return SHEIN_LOGIN.show({
      show: true,
      toRobot: true,
      cb: () => {
        handleLinkToContactSeller()
      }
    })
  }
  router.push({
    path: `${langPath}/robot/business`,
    query: {
      storecode: storeCode.value,
      page: 'storePage',
      page_name: 'page_store',
      business: +storeData.value.storeType === 1 ? '1' : '0', // 自营-0；平台-1
    },
  })
}

const listItemEvents = {
  handleLinkToFollowedShops,
  handleWishlistClick,
  handleLinkToRecentViewed,
  handleShareClick,
  handleLinkToSupport,
  handleLinkToContactSeller,
}

const getSupportSwitch = ()=>{
  hasRobotEntrance('storePage', (status) => {
    switchByTrackName.value.support = status
  })
}

const getContactSwitch = async ()=>{
  try {
    const resData = await schttp({ 
      url: '/navigation-api/store/customer/config',
      params: {
        storeCode: storeCode.value,
      },
      useBffApi: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    const { code, info } = resData || {}
    if(code === '0'){
      const { storeUnReadMsgTotal = 0, storeEntranceList = [] } = info || {}
      const isOpenContact = storeEntranceList?.some(v => v.position === 'storePage' && v.open === 1)
      switchByTrackName.value.contact = isOpenContact
      isShowRedDot.value = isOpenContact && +storeUnReadMsgTotal > 0
    }
  } catch (error) {
    // console.error('getContactSwitch error:', error)
  }
}

onMounted(() => {
  getSupportSwitch()
  getContactSwitch()
})
</script>
<style lang="less">
@vw: 37.5rem;
.store-header__more{
  display: block;
}
.store-header__more-wrapper {
  padding-left: 12/@vw;
  &.red-dot {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 6/@vw;
      height: 6/@vw;
      border-radius: 50%;
      background: #FE3B30;
      top: 4/@vw;
      right: -2/@vw;
    }
  }
}
.store-header__more-icon{
  font-size: 24/@vw!important;
}
.store-header__more-list {
  font-size: 14/@vw;
  padding: 2/@vw 0;
  position: relative;
  left: -12/@vw;

  .header-more__list-item {
    display: flex;
    align-items: center;
    height: 44/@vw;
    padding: 4/@vw 0 4/@vw 12/@vw;
    color: #666;
    position: relative;
    .sui-icon-common__wrap {
      margin-right: 8/@vw;
    }
    &.red-dot {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 6/@vw;
        height: 6/@vw;
        border-radius: 50%;
        background: #FE3B30;
        top: 9/@vw;
        left: 28/@vw;
      }
    }
  }
  .header-more__item-text {
    max-width: 118/@vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

</style>
