<template>
  <div
    ref="marqueeRef"
    class="marquee"
    :style="{ maxWidth: maxWidth + 'px' }"
  >
    <div
      ref="marqueeContentRef"
      class="marquee-content"
      :class="{ 'is-marquee': isMarquee }"
    >
      <div><slot></slot></div>
      <div v-if="isMarquee">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
  maxWidth: {
    type: Number,
    default: 52,
  },
})

const isMarquee = ref(false)

const marqueeRef = ref()
const checkWidth = () => {
  const marqueeContentWidth = marqueeRef.value.firstElementChild.offsetWidth
  if (marqueeContentWidth > props.maxWidth) {
    isMarquee.value = true
  }
}

onMounted(() => {
  checkWidth()
})
</script>

<style lang="less" scoped>
.marquee {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-content {
  display: inline-flex;
  align-items: center;

  > div {
    padding-right: 4px;
  }

  > div + div {
    padding-right: 0;
  }
}

.marquee-content.is-marquee {
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
</style>
