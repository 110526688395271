/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */
import { removeSessionStorage, setSessionStorage, getSessionStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { getCookie } from '@shein/common-function'
import { getBffVersion } from 'public/src/pages/user/child_pages/user_bff_version/index.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'

class UserKey {
  static for({ key }) {
    const userKeyClass = UserKey.classes.find(candidate => candidate.canHandle(key))

    return new userKeyClass({ key })
  }

  static canHandle(key) {
    return true
  }

  constructor({ key }) {
    this.key = key
    this.cache = new Map()
  }

  get() {
    return getSessionStorage(this.key)
  }

  set(value, options) {
    setSessionStorage({ key: this.key, value: value })
  }

  clearStorage() {
    removeSessionStorage(this.key)
  }

  /**
   * 对内提供给 UserKeys 判断
   * @returns Boolean
   */
  isLogin() {
    return !!getCookie('memberId')
  }

  /**
   * 对内提供给 UserKeys 获取 memberId
   * @returns Boolean
   */
  getMemberId () {
    return getCookie('memberId') || ''
  }

  async getIpCountryApi() {
    try {
      let res = {}
      if (getBffVersion('userTop20_v1')) {
        res = await schttp({
          method: 'POST',
          useBffApi: true,
          url: '/user-api/address/update_country'
        })
      } else {
        res = await schttp({
          method: 'GET',
          url: '/api/common/userInfoManager/country/get'
        })
      }

      if (res.code == 0) {
        const { countryId = '', countryAbbr = '' } = res?.info || {}

        return {
          countryId,
          countryAbbr
        }
      } else {
        return {}
      }
    } catch (e) {
      return {}
    }
  }

  async getUgidApi() {
    try {
      const BFF_UGID_KEY_NAME = 'ugid_v2'
      const isOpenBff = isSwitchBffApiVersion([BFF_UGID_KEY_NAME])?.[BFF_UGID_KEY_NAME]
      return await (isOpenBff ? getUgidBffApi() : getNodeApi())
    } catch (e) {
      return ''
    }
  }
}

async function getUgidBffApi() {
  const res = await schttp({
    method: 'get',
    useBffApi: true,
    url: '/user-api/common/userinfo_ugid'
  })
  return res?.info?.ugid || ''
}

async function getNodeApi() {
  const res = await schttp({
    method: 'post',
    url: '/api/common/userInfoManager/ugid',
  })
  return res?.info || ''
}

export default UserKey
