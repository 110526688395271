import popoverManager from './PopoverManager.js'
import cartGuideManager from 'public/src/pages/cart_v2/components/cartGuideManager/index.js'
import debug from './useDebug'

export function useUpdateGuideState () {
  const initGuideState = () => {
    cartGuideManager.isCompletedForAll().then(() => {
      popoverManager.setGlobalCondition('isGuideOpen', false)
      popoverManager.scheduleTasks()
      debug && console.warn('气泡全局状态更新注册关闭所有引导，重新调用异步队列')
    })
  }
  const resetGuideState = () => {
    popoverManager.setGlobalCondition('isGuideOpen', true)
    debug && console.warn('气泡全局状态重置引导状态')
  }
  return {
    initGuideState,
    resetGuideState,
  }
}
