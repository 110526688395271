// 只要客户端编译运行

import Vue from 'vue'
import infiniteScroll from '../../vendor/vue_infinte_scroll'
import analysisDirective from 'public/src/pages/common/analysis/directive.js'
import { loadCSS } from 'public/src/pages/common/utils/index.js'

/* mixin */
import './mixin/origin_user_mixin'

import '../../services/eventCenter/index'

/* components */
import { Toast } from '@shein/sui-mobile'
import { quickAdd } from '../components/product/quick_add_v2/index'
import wishGroupDrawer  from '../components/wishGroupDrawer'
import schttp from 'public/src/services/schttp'
import dayjs from 'dayjs'
import { getBffVersion } from 'public/src/pages/user/child_pages/user_bff_version/index.js'

const { PUBLIC_CDN, is_include_critical_css, CSS_LINK } = gbCommonInfo
const { langPath } = gbCommonInfo
__webpack_public_path__ = `${PUBLIC_CDN}/pwa_dist/assets/`

/* extend */
Vue.prototype.$instanceName = 'product'
Vue.prototype.$_WD = wishGroupDrawer // 新的分组弹框
// TODO Nolan Hu
Vue.prototype.$quickAdd = quickAdd

Vue.prototype.$toast = Toast
Vue.use(analysisDirective)
Vue.use(infiniteScroll)

window.SaPageInfo = {
  page_param: {}
}

const mixin = {
  data () {
    return {
      isFirstPageLoad: true, // 页面落地首次加载,
      isBlackListUser: false // 是否黑名单用户
    }
  },
  watch: {
    '$route' (to) {
      const { meta } = to
      this.checkIsNeedCommonHeader({ to })
      // 专题的tab显示逻辑在内部单独处理
      if (meta && meta.pageType !== 'activity') this.checkIsNeedFootTab({ to })
      appEventCenter.$off('exposeBranch')
      window.branchIsbindExpose = false
    }
  },
  mounted () {
    !['cart', 'cartAddOnItem'].includes(this.$route.name) && this.$store.dispatch('fetchCartInfo', {}, { root: true })
    this.fetchFirstLevelList()
    this.listenerEvent()
  },
  methods: {
    // 获取专题的一级路径并缓存， 一级路径配置半小时过期一次 重新发请求
    fetchFirstLevelList() {
      let data = ''
      let list = null
      let expires = 0
      try {
        data = JSON.parse(localStorage.getItem('campaigns_tiny_url_list'))
        list = data?.value
        expires = data?.expires
      } catch(e) {
        console.log(e)
      }
      if (!list || dayjs().valueOf() > expires) {
        schttp({ url: '/api/ccc/campaignsTinyUrlList/get' }).then(list => {
          if (!list?.length) return
          localStorage.setItem('campaigns_tiny_url_list', JSON.stringify({
            value: list,
            expires: dayjs().add(0.5, 'hour').valueOf()
          }))
        })
      }
    },
    listenerEvent () {
      const self = this

      appEventCenter.$on('pageOnload', () => {
        this.removeSkeleton()
      })

      appEventCenter.$on('checkBlackListUser', (cb) => {
        // 禁用页面
        const { pathname } = location
        const disabledPage = [
          /\/presearch$/
        ]
        const isInDisabledPage = disabledPage.find(v => v.test(pathname))

        if (this.isBlackListUser || isInDisabledPage) return
        
        // 爆破黑名单，场景已不在开放，暂时注释下线
        if (getBffVersion('userTop20_v1')) {
          this.isBlackListUser = false
          cb && cb(false)
        } else {
          this.isBlackListUser = true
          schttp({
            url: '/api/user/center/fetchUserVaild/get',
            params: {
              url: location.href
            }
          }).then((res) => {
            if (res && res.info && res.info.redirection) {
              console.log('Redirect to ' + res.info.redirection)

              cb && cb(true)

              //个人中心不跳转爆破黑名单
              if( location.pathname.indexOf('/user/index') > -1 ){
                //
              } else {
                location.href = langPath + '/user/blacklist_pwreset?redirection=' + res.info.redirection
              }
            } else {
              cb && cb(false)
            }

            this.isBlackListUser = false
          })
        }
      })

      // 首屏 兜底
      if (!self.$route.meta.isCssInVue && is_include_critical_css) {
        $(() => {
          requestIdleCallback(() => {
            loadCSS(CSS_LINK['spa-' + self.$route.meta.pageType + '_style'])
          })
        })
      }

      // 退出页面
      window.onbeforeunload = function () {
        sa('send', 'pageLeave', { end_time: new Date().getTime() })
      }
    },
    removeSkeleton () {
      $('.j-skeleton-container').remove()
      $('.j-fix-skeleton').remove()
    },
    checkIsNeedCommonHeader ({ to }) {
      const { meta } = to
      this.$store.commit('assignRootState', {
        isNeedCommonHeader: !meta.notNeedCommonHeader
      })
    },
    toggleIndexFooter (isShow) {
      let footerEle = document.querySelector('.j-index-footer')
      if (footerEle) {
        footerEle.style.display = isShow ? '' : 'none'
      }
      footerEle = null      
    },
    // 检查是否需要展示底部tab
    checkIsNeedFootTab ({ to }) {
      const { meta, name } = to
      if (meta && meta.pageType == 'userPage') {
        if (name == 'UserIndex') {
          this.toggleIndexFooter(true)
          $('.c-quick-register').addClass('c-quick-register-footer')
          window.welcomePrivacyVue && (window.welcomePrivacyVue.bottomPostion = '50px')
        } else {
          this.toggleIndexFooter(false)
          $('.c-quick-register').removeClass('c-quick-register-footer')
          window.welcomePrivacyVue && (window.welcomePrivacyVue.bottomPostion = '0px')
        }
      } else {
        if (meta && ['index', 'category'].includes(meta.pageType)) {
          this.toggleIndexFooter(true)
          window.welcomePrivacyVue && (window.welcomePrivacyVue.bottomPostion = '50px')
        } else {
          this.toggleIndexFooter(false)
          window.welcomePrivacyVue && (window.welcomePrivacyVue.bottomPostion = ['productDetail'].includes(meta.pageType) ? '1.4rem' : '0px')
        }
      }
    }
  }
}

export {
  mixin
}
