import { useMapState, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'
import { CardType } from 'public/src/pages/checkout_new/pages/prime_saver/utils/constant.js'

export default () => {
  const { coupon_list, couponListState } = useMapState(['checkout.coupon_list', 'couponListState'])
  const { updateCouponListState } = useMapMutations(['updateCouponListState'])

  const filterCoupon = (coupons = coupon_list.value, filterType) => {
    switch (filterType) {
      case CardType.PRIME:
        // 53: 付费会员券, 58: 付费会员会费券
        return coupons.filter(item => ![53, 58].includes(+item.type_id))
      case CardType.SAVE:
        // 55: 超省卡券, 59: 超省卡会费券
        return coupons.filter(item => ![55, 59].includes(+item.type_id))
      default:
        return coupons
    }
  }

  /**
   * @description: 存quantList接口返回的数据更新到store
   * @param {res} res为券列表接口返回数据
   * @return {null}
   */
  const useUpdateCouponState = (res) => {
    updateCouponListState({
      cmpAddCouponCoupons: res?.cmpAddCouponCoupons || [],
      cmpBestCombinationCoupons: res?.cmpBestCombinationCoupons || [],
      cmpDisableCoupons: res?.cmpDisableCoupons || [],
      cmpUsableCoupons: res?.cmpUsableCoupons || [],
    })
  }

  /**
   * @description: 通过下沉组件ref调用下沉组件方法获取券列表数据，并通过cb把数据更新到store
   * @param {params} handleType: 'onlyGetList', // 仅获取券列表数据
   *                             'applyBestCoupon', // 使用最佳优惠券
   *                             'newApplyCoupin', //手动用券
   *                             'clickSelectCoupon', // 点击选择优惠券
   *                 item: 点击优惠券列表项数据
   *                 opt: { cb: (res) => {}}
   * @return {null}
   */
  const useGetCouponList = (params = {}) => {
    const {
      isApplyFailThenFetchCoupons = false,
      handleType = '',
      item = {},
      add_coupon = '',
      cb = (res) => {
        !isApplyFailThenFetchCoupons && useUpdateCouponState(res)
        params?.payCb?.(res)
      }
    } = params
    couponListState?.value?.BCouponListRef?.getCouponList({
      isApplyFailThenFetchCoupons,
      handleType,
      item,
      add_coupon,
      cb,
    })
  }

  const useScrollToCoupon = (code) => {
    couponListState?.value?.BCouponListRef?.scrollToTargeCoupon(code)
  }

  // 传入的券列表是否全部在当前券列表中
  const useGetIsHadCoupon = (couponList) => {
    const nowCouponsList = getAllListCoupons(couponListState.value).map(item => item?.coupon?.toLocaleUpperCase())
    return (couponList || []).every(item => nowCouponsList.includes(item?.toLocaleUpperCase()))
  }

  // 获取当前券列表中所有券
  const getAllListCoupons = (couponListInfo) => {
    return [
      ...(couponListInfo?.cmpUsableCoupons || []),
      ...(couponListInfo?.cmpAddCouponCoupons || []),
      ...(couponListInfo?.cmpBestCombinationCoupons || []),
      ...(couponListInfo?.cmpDisableCoupons || []),
    ]
  }

  // 获取当前券列表中所有可用券
  const useGetAllUsableCoupons = () => {
    return [
      ...(couponListState.value?.cmpUsableCoupons || []),
      ...(couponListState.value?.cmpAddCouponCoupons || []),
      ...(couponListState.value?.cmpBestCombinationCoupons || []),
    ]
  }

  // 获取当前券列表中最优券和普通可用券
  const useGetCommonAndBestCoupons = () => {
    return [
      ...(couponListState.value?.cmpUsableCoupons || []),
      ...(couponListState.value?.cmpBestCombinationCoupons || []),
    ]
  }

  return {
    filterCoupon,
    useGetCouponList,
    useScrollToCoupon,
    useUpdateCouponState,
    useGetIsHadCoupon,
    getAllListCoupons,
    useGetCommonAndBestCoupons,
    useGetAllUsableCoupons
  }
}

