import { daEventCenter } from 'public/src/services/eventCenter/index'

/**
 * 自定义埋点事件插件
 */
export const definableEventPlugin = () => {
  const eventMap = {
    'click_add_bag': 'click_add_bag.comp_quick-add-cart-standard',
    'click_add_collect': 'click_add_collect.comp_quick-add-cart-standard',
  }

  const eventList = [
    eventMap.click_add_bag,
    eventMap.click_add_collect,
  ]

  return {
    name: 'definableEvent',
    beforeTriggerNotice: async ({ id = '', data = {} }) => {
      // 点击收藏
      if (id === eventMap.click_add_collect) {
        daEventCenter.triggerNotice({
          daId: '1-8-1-2',
          target: data.target || null,
          extraData: data
        }) 
      } 

      // 点击加车
      if (id === eventMap.click_add_bag) {
        // if(location?.hash === '#comment-all') data.page_name = 'page_goods_reviews'
        daEventCenter.triggerNotice({
          daId: '1-8-1-1',
          target: data.target || null,
          extraData: data
        })
      }
      // 匹配到拦截event，覆盖加车组件默认上报
      return eventList.includes(id)
    },
  }
}
