import {
  setBagsInfo,
  setIsMultiMall,
  setLayoutMode,
  setQsFreightStatus
} from '@/public/src/pages/checkout_new/store/mutations/dataMapping/shoppingBags'

export default {
  /**
   * @description qs跨境运费Radio状态
   * @returns {1} 1表示勾选
   * @returns {2} 2表示不勾选
   * */
  updateQsFreightStatus({ shoppingBagsState }, payload) {
    shoppingBagsState.qsFreightStatus = payload
  },
  resetQsFreightStatus(state, { checkout }) {
    setQsFreightStatus(state, { checkout })
  },

  // 更新巴西50美金税费弹窗状态
  updateBr50DollarsTaxDrawerState({ shoppingBagsState }, payload) {
    shoppingBagsState.visibleBr50DollarsTaxDrawer = payload
  },

  // 更新订单详情弹窗状态
  updateVisibleOrderDetailDrawerState({ shoppingBagsState }, payload) {
    shoppingBagsState.visibleOrderDetailDrawer = {
      ...shoppingBagsState.visibleOrderDetailDrawer,
      ...payload
    }
  },

  // 更新删除购物袋商品项的二次确认弹窗之状态
  updateShoppingBagsItemDeleteState({ shoppingBagsState }, payload) {
    shoppingBagsState.isShowShoppingBagsItemDeleteDialog = payload
  },

  // 更改qs商品的数量时，是否超过了当前库存，超过则需要弹窗
  updateShoppingBagsItemOverStockDialog({ shoppingBagsState }, payload) {
    shoppingBagsState.isShowShoppingBagsItemOverStockDialog = payload
  },

  // 更新删除购物袋商品项的二次确认弹窗之状态
  updateShoppingBagsItemIndex({ shoppingBagsState }, index) {
    shoppingBagsState.shoppingBagsItemIndex = index
  },

  // 重置商品项的quantity的数据
  resetShoppingBagsItemValue({ shoppingBagsState }, payload) {
    shoppingBagsState.isResetShoppingBagsItemValue = payload
  },

  // 更新购物袋数据【处理数据映射关系】
  updateShoppingBagsData(state, { cartsInfo, checkout }) {
    // isOnlyUpdateCartsInfo为true时，仅仅更新购物袋
    const isOnlyUpdateCartsInfo = checkout === undefined
    const checkoutRenderData = isOnlyUpdateCartsInfo ? state.checkout : checkout
    const { abtInfo } = state
    setIsMultiMall(state, cartsInfo?.is_multi_mall)
    setLayoutMode(state, { cartsInfo: cartsInfo, abtInfo })
    setBagsInfo(state, { cartsInfo, checkout: checkoutRenderData, abtInfo })
  },

  // 保存编辑商品接口返回的 originalPriceGoodsIdList
  updateOriginalPriceGoodsIdList({ shoppingBagsState }, payload) {
    shoppingBagsState.originalPriceGoodsIdList = payload
  },

  // 是否禁用inputNumber输入框
  disabledInput({ shoppingBagsState }, payload) {
    shoppingBagsState.isDisabledInput = payload
  },

  // 阻止排序购物袋商品
  blockingSort({ shoppingBagsState }, payload) {
    shoppingBagsState.isBlockingSort = payload
  },

  // 阻止排序购物袋商品
  displayedBr50DollarDrawer({ shoppingBagsState }, payload) {
    shoppingBagsState.isDisplayedBr50DollarDrawer = payload
  }
}
