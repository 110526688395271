const components = {
  // 单图、三图
  StaticImageCardController: () =>
    import(/* webpackChunkName: "ccc-static-image" */ '../components/StaticImage/Index.vue'),
  // 倒计时
  Countdown: () =>
    import(/* webpackChunkName: "ccc-countdown" */ '../components/CountDown/Index.vue'),
}

export default components
