<template>
  <div class="benefit-floor">
    <Main
      class="benefit-floor__main"
      :title="guideData.title"
      :total-value-tip="guideData.totalValueTip"
      :end-time="guideData.endTime"
      @click.native="emits('openDrawer')"
    />
    <Benefits
      :reward-list="guideData.rewardList"
      :benefit-component-type="type"
    />
    <BenefitTag
      v-if="guideData.tag"
      class="benefit-floor__tag"
      v-bind="guideData.tag"
    />
  </div>
</template>
<script name="BenefitFloor" setup lang="ts">
import { computed } from 'vue'
import Main from './Main.vue'
import Benefits from './Benefits.vue'
import BenefitTag from '../BenefitTag.vue'
import type { E_BenefitFloor, C_BenefitFloor } from '../../../../types'

const props = defineProps<{
  type: E_BenefitFloor.BenefitComponentType,
  data: C_BenefitFloor.Data,
}>()

const emits = defineEmits(['openDrawer'])

const guideData = computed(() => props.data.guide || {})

</script>
<style lang="less" scoped>
.benefit-floor {
  width: 100%;
  padding: 16/75rem 0;
  background-color: #fff;
  background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/11/19/ea/1731986778ad2faf9910acc4148570e2de975ddf2e.webp');
  background-size: 100% 100%;
  position: relative;
  box-sizing: border-box;

  &__main {
    margin-bottom: 16/75rem;
  }

  &__tag {
    position: absolute;
    left: -16/75rem;
    top: 0;
    transform: translateY(-50%);
  }
}
</style>
