// 用于单页水合

// 单页水合开关
// 触发请求单页水合
// 采集点位
// 拦截页面组件加载控制， 用一个promise来进行控制
// 阻止触发的click请求   用一个方法获取状态
// 请求后开启骨架屏，请求完成后关闭骨架屏

import { generateRouterParams, defaultRequestInfo, setCurrentToRoute, getPdeParams, PointerOverGoodsRequest } from '../utils.js'
import generateParams from '../generateParams.js'
import schttp from 'public/src/services/schttp'
import { PRODUCT_LIST_API_URL_MAP } from '../constant.js'
import { isOpenSpaSsrApp, invokePreloadImg, handleSpaSsrFetchData } from './tools.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { changePreHtmlUpdate } from '../index.js' 
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { listenFetchSpaSsrPromise } from 'public/src/pre_requests/utlis/spaHydration.js'

const MARK_SPA_SSR_NAME = 'SpaHydrationSsrPage' // 采集spa切换的时间
let currentFetchSsrPromise = null
let _products_ssr_data = null // ssr数据  用于水合
let _products_prerender_el = null  // 渲染dom元素
let fristPageFlag = false
let skeletonEl = null

function fetchDone() {
  markPoint({ eventName: MARK_SPA_SSR_NAME, tag: 'end' })
  skeletonEl && (skeletonEl.style.display = 'none')
}

function fetchListComponentStyleCompile() {
  return new Promise((resolve) => {
    let timer = null
    prefetchResource.listen({
      el: document.querySelector('#prerender-products-skeleton'),
      prefetchList: [
        {
          chunkName: 'plv2_container',
          relType: 'prefetch'
        },
      ],
      delay: 0,
      prefetchCallback: () => {
        const loadComponent = prefetchResource.importAsyncComponent({
          chunkName: 'plv2_container', // 跟webpackChunkName保持一致
          componentFactory: () => import( /* webpackChunkName: 'plv2_container' */ 'public/src/pages/product_list_v2/container.vue'),
        })
        loadComponent?.()
        resolve()
        clearTimeout(timer)
      },
    })
    // 用于异常情况下的处理
    timer = setTimeout(() => {
      resolve()
    }, 2000)
  })
}

const preloadGoodsRequestControl = new PointerOverGoodsRequest({
  fetch: async function (url) {
    const isCurrentSpaSsr = listenFetchSpaSsrPromise({ name: 'product-list-v2' })
    window.__FSP_LIST_PAGE_FROM_NAME__ = ''
    if (fristPageFlag) return
    currentFetchSsrPromise = null
    _products_ssr_data = null
    _products_prerender_el = null
    skeletonEl && (skeletonEl.style.display = 'none') // 防止异常情况下骨架屏一直显示

    if (!isOpenSpaSsrApp()) return  // 已经初始化过了
    const toRouteParams = generateRouterParams(url)
    if (!toRouteParams) return

    if (gbCommonInfo.isDebug) {
      console.log('%c list page spa hydration start.', 'color: #00c663;font-size: 16px; background: #000; padding: 2px 4px;')
    }
    this.abortionInstance?.abort?.()
    setCurrentToRoute(toRouteParams)
    const params = generateParams(defaultRequestInfo(toRouteParams.query))
    params.preloadHtmlUrl = url // 预取html的url，也是标识
    this.abortionInstance = new SchttpAbortCon()
    markPoint({ eventName: MARK_SPA_SSR_NAME, tag: 'begin' })
    changePreHtmlUpdate(true)
    currentFetchSsrPromise = Promise.all([schttp({
      url: PRODUCT_LIST_API_URL_MAP[toRouteParams.name],
      params,
      headers: getPdeParams(),
      signal: this.abortionInstance.signal,
    }).then(res => {
      return new Promise((resolve) => {
        const { contextForSSR, preloadImgs } = res || {}
        if (!contextForSSR || !isCurrentSpaSsr()) { // 异常情况直接取消
          fetchDone()
          resolve()
          return
        }
        window.__FSP_LIST_PAGE_FROM_NAME__ = 'spaHydrationListPage'
        const imgPromise = invokePreloadImg(preloadImgs)
        _products_ssr_data = contextForSSR
        requestAnimationFrame(() => {
          Promise.all([handleSpaSsrFetchData(res), imgPromise]).then(([el]) => {
            _products_prerender_el = el
            fetchDone()
            requestAnimationFrame(() => {
              resolve()
            })
          })
        })
      })
    }).catch(() => {
      fetchDone()
    }).finally(() => {
      changePreHtmlUpdate(false)
    }), fetchListComponentStyleCompile()])
  },
  cancelToken: function() {
    this.abortionInstance?.abort?.()
  },
})

function handlePointerChange({ path } = {}) {
  const isSearchResultPage = /pdsearch\/.+/.test(path)
  if (isSearchResultPage) {
    return  // 暂时不支持搜索结果页， 还有两个问题： 头部和闪屏的问题解决
  }
  preloadGoodsRequestControl.triggerNotice(path)
}

function handleClickChange({ path }) {
  const isPreloadFetch = preloadGoodsRequestControl.requestUrl
  preloadGoodsRequestControl.click()

  // 如果是单页水合，将开启骨架屏
  if (getFetchSpaSsrPromise() && isPreloadFetch) {
    // const isSearchResultPage = /pdsearch\/.+/.test(path)
    window._gb_app_.$store.state.rootStatus.exchangingSpaSceneInApp = true // 隐藏页面的app, 等真实页面渲染完成后再设置false
    skeletonEl = document.querySelector('#prerender-products-skeleton')
    let headerApp = null
    
    // if (isSearchResultPage) {
    //   // 在预搜索页面刷新，此时header组件没有渲染， 直接跳转搜索结果页此时header组件是空的。 所以要进行渲染  
    //   // todo: 
    //   headerApp = document.querySelector('#header-app')
    //   skeletonEl = document.querySelector('#prerender-search-skeleton')
    // }

    ;[headerApp, skeletonEl].forEach(e => {
      e && (e.style.display = 'block')
    })

    // header头部可能会在吸顶状态, 进行复位
    window.forceUpdateHeaderFixed?.({ forceFixed: false, routeName: 'product-list-v2' })
    requestIdleCallback(() => window.JOURNEY_BRANCH?.updateSync?.(path))
  }
}

// 获取单页水合请求Promise
function getFetchSpaSsrPromise() {
  return currentFetchSsrPromise
}

// 页面组件获取数据，创建的时候初始化. 只能获取一次
function getOneSpaSsrContextForSSR() {
  if (typeof window === 'undefined' || fristPageFlag) return {}
  const res = {
    contextForSSR: _products_ssr_data,
    el: _products_prerender_el
  }
  if (res.el) {
    if (gbCommonInfo.isDebug) {
      console.log('%c list page hydration succeed.', 'color: #00c663;font-size: 16px; background: #000; padding: 2px 4px;')
    }
  }
  fristPageFlag = true // 已经获取过了
  currentFetchSsrPromise = null
  _products_ssr_data = null
  _products_prerender_el = null

  // 判断当前水合是否晚于页面spa的检测，如果晚于将当前
  // in-app元素下有product-list-v2这个元素，说明是在spa的页面中
  if (document.querySelector('#in-app #product-list-v2')) {
    if (window?._gb_app_?.$store?.state?.rootStatus?.exchangingSpaSceneInApp) {
      window._gb_app_.$store.state.rootStatus.exchangingSpaSceneInApp = false // 显示页面的app
    }
    const el = document.querySelector('#prerender-in-app #product-list-v2')
    el && el.remove() // 删除元素
    return {}
  }
  return res 
}

export {
  handlePointerChange,
  handleClickChange,
  getFetchSpaSsrPromise,
  getOneSpaSsrContextForSSR,
}
