export default {
  'BS_KEY_PAY_1001': 'SHEIN_KEY_PWA_30875',
  'BS_KEY_PAY_1002': 'SHEIN_KEY_PWA_25002',
  'BS_KEY_PAY_1003': 'SHEIN_KEY_PWA_16631',
  'BS_KEY_PAY_1004': 'SHEIN_KEY_PWA_17706',
  'BS_KEY_PAY_1005': 'SHEIN_KEY_PWA_17707',
  'BS_KEY_PAY_1006': 'SHEIN_KEY_PWA_16129',
  'BS_KEY_PAY_1007': 'SHEIN_KEY_PWA_17704',
  'BS_KEY_PAY_1008': 'SHEIN_KEY_PWA_20682',
  'BS_KEY_PAY_1009': 'SHEIN_KEY_PWA_20683',
  'BS_KEY_PAY_1010': 'SHEIN_KEY_PWA_17170',
  'BS_KEY_PAY_1011': 'SHEIN_KEY_PWA_17703',
  'BS_KEY_PAY_1012': 'SHEIN_KEY_PWA_25036',
  'BS_KEY_PAY_1013': 'SHEIN_KEY_PWA_23430',
  'BS_KEY_PAY_1014': 'SHEIN_KEY_PWA_30725',
  'BS_KEY_PAY_1015': 'SHEIN_KEY_PWA_23440',
  'BS_KEY_PAY_1016': 'SHEIN_KEY_PWA_19690',
  'BS_KEY_PAY_1017': 'SHEIN_KEY_PWA_14830',
  'BS_KEY_PAY_1018': 'SHEIN_KEY_PWA_14847',
  'BS_KEY_PAY_1019': 'SHEIN_KEY_PWA_19689',
  'BS_KEY_PAY_1020': 'SHEIN_KEY_PWA_16207',
  'BS_KEY_PAY_1021': 'SHEIN_KEY_PWA_19681',
  'BS_KEY_PAY_1022': 'SHEIN_KEY_PWA_24866',
  'BS_KEY_PAY_1023': 'SHEIN_KEY_PWA_24937',
  'BS_KEY_PAY_1024': 'SHEIN_KEY_PWA_14988',
  'BS_KEY_PAY_1025': 'SHEIN_KEY_PWA_30872',
  'BS_KEY_PAY_1026': 'SHEIN_KEY_PWA_29362',
  'BS_KEY_PAY_1027': 'SHEIN_KEY_PWA_25526',
  'BS_KEY_PAY_1028': 'SHEIN_KEY_PWA_19684',
  'BS_KEY_PAY_1029': 'SHEIN_KEY_PWA_30873',
  'BS_KEY_PAY_1030': 'SHEIN_KEY_PWA_30049',
  'BS_KEY_PAY_1031': 'SHEIN_KEY_PWA_31887',
  'BS_KEY_PAY_1032': 'SHEIN_KEY_PWA_23556',
  'BS_KEY_PAY_1033': 'SHEIN_KEY_PWA_30068',
  'BS_KEY_PAY_1034': 'SHEIN_KEY_PWA_31368',
  'BS_KEY_PAY_1035': 'SHEIN_KEY_PWA_29576',
  'BS_KEY_PAY_1036': 'SHEIN_KEY_PWA_19686',
  'BS_KEY_PAY_1037': 'SHEIN_KEY_PWA_19687',
  'BS_KEY_PAY_1038': 'SHEIN_KEY_PWA_19685',
  'BS_KEY_PAY_1039': 'SHEIN_KEY_PWA_19683',
  'BS_KEY_PAY_1040': 'SHEIN_KEY_PWA_19692',
  'BS_KEY_PAY_1041': 'SHEIN_KEY_PWA_26144',
  'BS_KEY_PAY_1042': 'SHEIN_KEY_PWA_26145',
  'BS_KEY_PAY_1043': 'SHEIN_KEY_PWA_24447',
  'BS_KEY_PAY_1044': 'SHEIN_KEY_PWA_24525',
  'BS_KEY_PAY_1045': 'SHEIN_KEY_PWA_24526',
  'BS_KEY_PAY_1046': 'SHEIN_KEY_PWA_24453',
  'BS_KEY_PAY_1047': 'SHEIN_KEY_PWA_24448',
  'BS_KEY_PAY_1048': 'SHEIN_KEY_PWA_28692',
  'BS_KEY_PAY_1049': 'SHEIN_KEY_PWA_28691',
  'BS_KEY_PAY_1050': 'SHEIN_KEY_PWA_31535',
  'BS_KEY_PAY_1051': 'SHEIN_KEY_PWA_16733',
  'BS_KEY_PAY_1052': 'SHEIN_KEY_PWA_16753',
  'BS_KEY_PAY_1053': 'SHEIN_KEY_PWA_16734',
  'BS_KEY_PAY_1054': 'SHEIN_KEY_PWA_20331',
  'BS_KEY_PAY_1055': 'SHEIN_KEY_PWA_24508',
  'BS_KEY_PAY_1056': 'SHEIN_KEY_PWA_26043',
  'BS_KEY_PAY_1057': 'SHEIN_KEY_PWA_17728',
  'BS_KEY_PAY_1058': 'SHEIN_KEY_PWA_25686',
  'BS_KEY_PAY_1059': 'SHEIN_KEY_PWA_25690',
  'BS_KEY_PAY_1060': 'SHEIN_KEY_PWA_25691',
  'BS_KEY_PAY_1061': 'SHEIN_KEY_PWA_17358',
  'BS_KEY_PAY_1062': 'SHEIN_KEY_PWA_18953',
  'BS_KEY_PAY_1063': 'SHEIN_KEY_PWA_30637',
  'BS_KEY_PAY_1064': 'SHEIN_KEY_PWA_18954',
  'BS_KEY_PAY_1065': 'SHEIN_KEY_PWA_15145',
  'BS_KEY_PAY_1066': 'SHEIN_KEY_PWA_24844',
  'BS_KEY_PAY_1067': 'SHEIN_KEY_PWA_15698',
  'BS_KEY_PAY_1068': 'SHEIN_KEY_PWA_30982',
  'BS_KEY_PAY_1069': 'SHEIN_KEY_PWA_23117',
  'BS_KEY_PAY_1070': 'SHEIN_KEY_PWA_30981',
  'BS_KEY_PAY_1071': 'SHEIN_KEY_PWA_24897',
  'BS_KEY_PAY_1072': 'SHEIN_KEY_PWA_31885',
  'BS_KEY_PAY_1073': 'SHEIN_KEY_PWA_24922',
  'BS_KEY_PAY_1074': 'SHEIN_KEY_PWA_24923',
  'BS_KEY_PAY_1075': 'SHEIN_KEY_PWA_31886',
  'BS_KEY_PAY_1076': 'SHEIN_KEY_PWA_15097',
  'BS_KEY_PAY_1077': 'SHEIN_KEY_PWA_15135',
  'BS_KEY_PAY_1078': 'SHEIN_KEY_PWA_30050',
  'BS_KEY_PAY_1079': 'SHEIN_KEY_PWA_17172',
  'BS_KEY_PAY_1080': 'SHEIN_KEY_PWA_17173',
  'BS_KEY_PAY_1081': 'SHEIN_KEY_PWA_15104',
  'BS_KEY_PAY_1082': 'SHEIN_KEY_PWA_30047',
  'BS_KEY_PAY_1083': 'SHEIN_KEY_PWA_31565',
  'BS_KEY_PAY_1084': 'SHEIN_KEY_PWA_30879',
  'BS_KEY_PAY_1085': 'SHEIN_KEY_PWA_30878',
  'BS_KEY_PAY_1086': 'SHEIN_KEY_PWA_30881',
  'BS_KEY_PAY_1087': 'SHEIN_KEY_PWA_30880',
  'BS_KEY_PAY_1088': 'SHEIN_KEY_PWA_30882',
  'BS_KEY_PAY_1089': 'SHEIN_KEY_PWA_30883',
  'BS_KEY_PAY_1090': 'SHEIN_KEY_PWA_30877',
  'BS_KEY_PAY_1091': 'SHEIN_KEY_PWA_15965',
  'BS_KEY_PAY_1092': 'SHEIN_KEY_PWA_30845',
  'BS_KEY_PAY_1093': 'SHEIN_KEY_PWA_16988',
  'BS_KEY_PAY_1094': 'SHEIN_KEY_PWA_17404',
  'BS_KEY_PAY_1095': 'SHEIN_KEY_PWA_16985',
  'BS_KEY_PAY_1096': 'SHEIN_KEY_PWA_16986',
  'BS_KEY_PAY_1097': 'SHEIN_KEY_PWA_15143',
  'BS_KEY_PAY_1098': 'SHEIN_KEY_PWA_29741',
  'BS_KEY_PAY_1099': 'SHEIN_KEY_PWA_15092',
  'BS_KEY_PAY_1100': 'SHEIN_KEY_PWA_21350',
  'BS_KEY_PAY_1101': 'SHEIN_KEY_PWA_31338',
  'BS_KEY_PAY_1102': 'SHEIN_KEY_PWA_31339',
  'BS_KEY_PAY_1103': 'SHEIN_KEY_PWA_23787',
  'BS_KEY_PAY_1104': 'SHEIN_KEY_PWA_31383',
  'BS_KEY_PAY_1105': 'SHEIN_KEY_PWA_31349',
  'BS_KEY_PAY_1106': 'SHEIN_KEY_PWA_31350',
  'BS_KEY_PAY_1107': 'SHEIN_KEY_PWA_31351',
  'BS_KEY_PAY_1108': 'SHEIN_KEY_PWA_24493',
  'BS_KEY_PAY_1109': 'SHEIN_KEY_PWA_26428',
  'BS_KEY_PAY_1110': 'SHEIN_KEY_PWA_26440',
  'BS_KEY_PAY_1111': 'SHEIN_KEY_PWA_31738',
  'BS_KEY_PAY_1112': 'SHEIN_KEY_PWA_31739',
  'BS_KEY_PAY_1113': 'SHEIN_KEY_PWA_31740',
  'BS_KEY_PAY_1114': 'SHEIN_KEY_PWA_31741',
  'BS_KEY_PAY_1115': 'SHEIN_KEY_PWA_16968',
  'BS_KEY_PAY_1116': 'SHEIN_KEY_PWA_15150',
  'BS_KEY_PAY_1117': 'SHEIN_KEY_PWA_30560',
  'BS_KEY_PAY_1118': 'SHEIN_KEY_PWA_30420',
  'BS_KEY_PAY_1119': 'SHEIN_KEY_PWA_30561',
  'BS_KEY_PAY_1120': 'SHEIN_KEY_PWA_30421',
  'BS_KEY_PAY_1121': 'SHEIN_KEY_PWA_20119',
  'BS_KEY_PAY_1122': 'SHEIN_KEY_PWA_30419',
  'BS_KEY_PAY_1123': 'SHEIN_KEY_PWA_31533',
  'BS_KEY_PAY_1124': 'SHEIN_KEY_PWA_30559',
  'BS_KEY_PAY_1125': 'SHEIN_KEY_PWA_15741',
  'BS_KEY_PAY_1126': 'SHEIN_KEY_PWA_16237',
  'BS_KEY_PAY_1127': 'SHEIN_KEY_PWA_30718',
  'BS_KEY_PAY_1128': 'SHEIN_KEY_PWA_30719',
  'BS_KEY_PAY_1129': 'SHEIN_KEY_PWA_30720',
  'BS_KEY_PAY_1130': 'SHEIN_KEY_PWA_30721',
  'BS_KEY_PAY_1131': 'SHEIN_KEY_PWA_23431',
  'BS_KEY_PAY_1132': 'SHEIN_KEY_PWA_23432',
  'BS_KEY_PAY_1133': 'SHEIN_KEY_PWA_30730',
  'BS_KEY_PAY_1134': 'SHEIN_KEY_PWA_23520',
  'BS_KEY_PAY_1135': 'SHEIN_KEY_PWA_30729',
  'BS_KEY_PAY_1136': 'SHEIN_KEY_PWA_30722',
  'BS_KEY_PAY_1137': 'SHEIN_KEY_PWA_30723',
  'BS_KEY_PAY_1138': 'SHEIN_KEY_PWA_30724',
  'BS_KEY_PAY_1139': 'SHEIN_KEY_PWA_30130',
  'BS_KEY_PAY_1140': 'SHEIN_KEY_PWA_30149',
  'BS_KEY_PAY_1141': 'SHEIN_KEY_PWA_15146',
  'BS_KEY_PAY_1142': 'SHEIN_KEY_PWA_30148',
  'BS_KEY_PAY_1143': 'SHEIN_KEY_PWA_30131',
  'BS_KEY_PAY_1144': 'SHEIN_KEY_PWA_32236',
  'BS_KEY_PAY_1145': 'SHEIN_KEY_PWA_22740',
  'BS_KEY_PAY_1146': 'SHEIN_KEY_PWA_25753',
  'BS_KEY_PAY_1147': 'SHEIN_KEY_PWA_25754',
  'BS_KEY_PAY_1148': 'SHEIN_KEY_PWA_25755',
  'BS_KEY_PAY_1149': 'SHEIN_KEY_PWA_25756',
  'BS_KEY_PAY_1150': 'SHEIN_KEY_PWA_34476',
  'BS_KEY_PAY_1151': 'SHEIN_KEY_PWA_34474',
  'BS_KEY_PAY_1152': 'SHEIN_KEY_PWA_34477',
  'BS_KEY_PAY_1153': 'SHEIN_KEY_PWA_30846',
  'BS_KEY_PAY_1154': 'SHEIN_KEY_PWA_24458',
  'BS_KEY_PAY_1155': 'SHEIN_KEY_PWA_24483',
  'BS_KEY_PAY_1156': 'SHEIN_KEY_PWA_24484',
  'BS_KEY_PAY_1157': 'SHEIN_KEY_PWA_28760',
  'BS_KEY_PAY_1158': 'SHEIN_KEY_PWA_23438',
  'BS_KEY_PAY_1159': 'SHEIN_KEY_PWA_23439',
  'BS_KEY_PAY_1160': 'SHEIN_KEY_PWA_32148',
  'BS_KEY_PAY_1161': 'SHEIN_KEY_PWA_34432',
  'BS_KEY_PAY_1162': 'SHEIN_KEY_PWA_34433',
  'BS_KEY_PAY_1163': 'SHEIN_KEY_PWA_34431',
  'BS_KEY_PAY_1164': 'SHEIN_KEY_PWA_34430',
  'BS_KEY_PAY_1165': 'SHEIN_KEY_PWA_34429',
  'BS_KEY_PAY_1166': 'SHEIN_KEY_PWA_32151',
  'BS_KEY_PAY_1167': 'SHEIN_KEY_PWA_25758',
  'BS_KEY_PAY_1168': 'SHEIN_KEY_PWA_25751',
  'BS_KEY_PAY_1169': 'SHEIN_KEY_PWA_25750',
  'BS_KEY_PAY_1170': 'SHEIN_KEY_PWA_25752',
  'BS_KEY_PAY_1171': 'SHEIN_KEY_PWA_25749',
  'BS_KEY_PAY_1172': 'SHEIN_KEY_PWA_25745',
  'BS_KEY_PAY_1173': 'SHEIN_KEY_PWA_25744',
  'BS_KEY_PAY_1174': 'SHEIN_KEY_PWA_24595',
  'BS_KEY_PAY_1175': 'SHEIN_KEY_PWA_24594',
  'BS_KEY_PAY_1176': 'SHEIN_KEY_PWA_25748',
  'BS_KEY_PAY_1177': 'SHEIN_KEY_PWA_25747',
  'BS_KEY_PAY_1178': 'SHEIN_KEY_PWA_32147',
  'BS_KEY_PAY_1179': 'SHEIN_KEY_PWA_31583',
  'BS_KEY_PAY_1180': 'SHEIN_KEY_PWA_21347',
  'BS_KEY_PAY_1181': 'SHEIN_KEY_PWA_23518',
  'BS_KEY_PAY_1182': 'SHEIN_KEY_PWA_23519',
  'BS_KEY_PAY_1183': 'SHEIN_KEY_PWA_23521',
  'BS_KEY_PAY_1184': 'SHEIN_KEY_PWA_29739',
  'BS_KEY_PAY_1185': 'SHEIN_KEY_PWA_25761',
  'BS_KEY_PAY_1186': 'SHEIN_KEY_PWA_25762',
  'BS_KEY_PAY_1187': 'SHEIN_KEY_PWA_25764',
  'BS_KEY_PAY_1188': 'SHEIN_KEY_PWA_25763',
  'BS_KEY_PAY_1189': 'SHEIN_KEY_PWA_25746',
  'BS_KEY_PAY_1190': 'SHEIN_KEY_PWA_17537',
  'BS_KEY_PAY_1191': 'SHEIN_KEY_PWA_16191',
  'BS_KEY_PAY_1192': 'SHEIN_KEY_PWA_34582',
  'BS_KEY_PAY_1193': 'SHEIN_KEY_PWA_23787',
  'BS_KEY_PAY_1194': 'SHEIN_KEY_PWA_23117',
  'BS_KEY_PAY_1195': 'SHEIN_KEY_PWA_15816',
  'BS_KEY_PAY_1196': 'SHEIN_KEY_PWA_31350',
  'BS_KEY_PAY_1197': 'SHEIN_KEY_PWA_31339',
  'BS_KEY_PAY_1198': 'SHEIN_KEY_PWA_34734',
  'BS_KEY_PAY_1199': 'SHEIN_KEY_PWA_34700',
  'BS_KEY_PAY_1200': 'SHEIN_KEY_PWA_34702',
  'BS_KEY_PAY_1201': 'SHEIN_KEY_PWA_34701',
  'BS_KEY_PAY_1202': 'SHEIN_KEY_PWA_34660',
  'BS_KEY_PAY_1203': 'SHEIN_KEY_PWA_34661',
  'BS_KEY_PAY_1204': 'SHEIN_KEY_PWA_31342',
  'BS_KEY_PAY_1205': 'SHEIN_KEY_PWA_31343',
  'BS_KEY_PAY_1206': 'SHEIN_KEY_PWA_31344',
  'BS_KEY_PAY_1207': 'SHEIN_KEY_PWA_31342',
  'BS_KEY_PAY_1208': 'SHEIN_KEY_PWA_31341',
  'BS_KEY_PAY_1209': 'SHEIN_KEY_PWA_34952',
  'BS_KEY_PAY_1210': 'SHEIN_KEY_PWA_15752',
  'BS_KEY_PAY_1211': 'SHEIN_KEY_PWA_14896',
  'BS_KEY_PAY_1212': 'SHEIN_KEY_PWA_34931',
  'BS_KEY_PAY_1213': 'SHEIN_KEY_PWA_20120',
  'BS_KEY_PAY_1214': 'SHEIN_KEY_PWA_16236',
  'BS_KEY_PAY_1215': 'SHEIN_KEY_PWA_15068',
  'BS_KEY_PAY_1216': 'SHEIN_KEY_PWA_27571',
  'BS_KEY_PAY_1217': 'SHEIN_KEY_PWA_30050',
  'BS_KEY_PAY_1218': 'SHEIN_KEY_PWA_35709',
  'BS_KEY_PAY_1219': 'SHEIN_KEY_PWA_35627',
}
