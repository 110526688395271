export default {
  commentData: {
    type: Object,
    default() {
      return {}
    }
  },
  /* 
    outer: 商详评论楼层
    inner: 评论详情页
  */
  position: {
    type: String,
    default: 'outer'
  },
  /* 
    控制评论图片展示类型：
      Smallpic: 小图模式
      Smallicon: 小icon模式
      Varypic: 混合尺寸
      None: 正常图片模式
  */
  reviewPhotoType: {
    type: String,
    default: 'None'
  },
  reviewText: {
    type: String,
    default: 'None'
  },
  // 是否折叠评论详情页身材信息
  innerSizeIsFold: {
    type: Boolean,
    default: false
  },
  showBraSize: {
    type: Boolean,
    default: false
  },
  index: {
    type: Number,
    default: 0
  },
  language: {
    type: Object,
    default() {
      return {}
    }
  },
  // commentTranslateConfig: {
  //   type: Object,
  //   default() {
  //     return {}
  //   }
  // },
  translateLanguage: {
    type: Array,
    default() {
      return []
    }
  },
  currTranslateLang: {
    type: String,
    default: ''
  },
  translateLoading: {
    type: Array,
    default() {
      return []
    }
  },
  // sizeConfig: {
  //   type: Array,
  //   default() {
  //     return []
  //   }
  // },
  hasFit: {
    type: Number,
    default: 0
  },
  isBatchTranslate: {
    type: Boolean,
    default: false
  },
  isAutoTranslate: {
    type: Boolean,
    default: true
  },
  showMemberSize: {
    type: Boolean,
    default: true
  },
  showLike: {
    type: Boolean,
    default: true
  },
  showReply: {
    type: Boolean,
    default: true
  },
  showGoodsId: {
    type: Boolean,
    default: false
  },
  analysis: {
    type: [Object, String],
    default: ''
  },
  showReport: {
    type: Boolean,
    default: false
  },
  skc: {
    type: String,
    default: ''
  },
  catId: {
    type: String,
    default: '',
  },
  hideMoreText: {
    type: Boolean,
    default: false
  },
  showPointProgram: {
    type: Boolean,
    default: false
  },
  scrollContainer: {
    type: String,
    default: ''
  },
  // 评论在页面中的排序位置，从1开始
  // * 221208版本，仅在商详页注入，店铺评论页暂无
  commentPosition: {
    type: [Number, String],
    default: 0,
  },
  // 单条评论是否需要展示本地标记
  markSingleLocalComment: {
    type: Boolean,
    default: false,
  },
  // 同款聚合
  showSameFlag: {
    type: Boolean,
    default: true
  },
  // 评论总数
  totalComment: {
    type: Number,
    default: 0
  },
  // 商详页评论区图片水印配置
  // * 231114版本，仅在商详页注入，店铺评论页暂无
  watermarkConfig: {
    type: Object,
    default: () => {
      return {}
    }
  },
  // 翻译
  showTranslate: {
    type: Boolean,
    default: true
  },
  // isDetailBffv1: {
  //   type: Boolean,
  //   default: false,
  // },
  // 是否是滑动样式，目前只在商详页有
  isSlideStyle: {
    type: Boolean,
    default: false
  },
  // 评论时间展示新样式
  commentDateDisplayNew: {
    type: Boolean,
    default: false
  },
  isShowAddToCart: {
    type: Boolean,
    default: false
  },
  displayLimit: {
    type: Boolean,
    default: false
  }
}
