<script lang="jsx">
import { SHEINCountingStarSuiicon } from 'public/src/pages/goods_detail_v2/utils/common.js'

export default {
  name: 'ProductItemStarComment',
  functional: true,
  props: {
    curData: {
      type: Object,
      default: () => ({})
    },
    showStarRating: {
      type: Boolean,
      default: false
    },
    starRatingStyle: {
      type: Object,
      default: () => {}
    }
  },
  render(h, { props }) {
    const commentRank = props.curData?.starComment?.comment_rank_average
    const commentNum = props.curData?.starComment?.comment_num_str

    return props.curData.starComment 
      ? (
        props.showStarRating 
          ? (
            <div class="product-item__comment">
              <i class="comment__new-star suiiconfont sui_icon_star_5_15px_2"></i>
              <span 
                class="comment__rank"
                style={props.starRatingStyle}
              >
                {commentRank}
              </span>
            </div>
          ) : (
            <div class="product-item__comment">
              <span
                class="comment__star"
                domPropsInnerHTML={SHEINCountingStarSuiicon(commentRank, 15, 2)}
              ></span>
              <span class="comment__num">({commentNum})</span>
            </div>
          )
      ) : null
  },
}
</script>

<style lang="less">
.product-item {
  &__comment {
    .flexbox();
    .align-center();
    min-height: 0.4rem;
    height: 0.4rem;
    line-height: 0.4rem;
    margin-top: 0.1067rem;
    .suiiconfont {
      color: @sui_color_honor;
    }
    .comment {
      &__star {
        display: inline-block;
        min-width: 75px;
        margin-right: 0.0267rem;
      }
      &__new-star {
        margin-right: 0.0533rem;
      }
      &__num {
        color: @sui_color_gray_light1;
      }
      &__rank {
        font-size: 0.2933rem;
        color: @sui_color_gray_dark1;
      }
    }
    .comment__rank {
      &.bold {
        font-weight: 700;
      }
    }
  }
}
.comment__new-star {
  width: 15px;
}
</style>
