<script lang="tsx" name="DefaultAddBtn">
export default {
    functional: true,
    props: {
        submitText: {
            type: String,
            required: false,
        },
        mainBtnFullWidth: {
            type: Boolean,
            required: false,
        },
        visibleFavoriteButton: {
            type: Boolean,
            required: false,
        },
    },
    render (h, { props, listeners }) {
        const { mainBtnFullWidth, submitText, visibleFavoriteButton } = props
        return (
            <div class={{
                'add-button-box': true,
                'with-save-btn': visibleFavoriteButton,
            }}>
                <button
                    class={{
                    'bsc-quick-add-cart-standard__button_black': true,
                    'fullWidth': mainBtnFullWidth,
                    }}
                    role="button"
                    aria-label={submitText}
                    onClick={(e) => {
                        e.preventDefault()
                        if (listeners.onClick) {
                            (listeners.onClick as Function)()
                        }

                    }}>
                    { submitText }
                </button>
        </div>
        )
    },
}
</script>
