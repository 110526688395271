import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { handleGoodsDetailTrigger } from 'public/src/pre_requests/modules/goods_detail.js'

const initPrefetchResource = (vm) => {
  if (vm.$el && vm.$el?.tagName) {
    prefetchResource.listen({
      el: vm.$el,
      prefetchList: [
        {
          chunkName: 'goods_detail',
          relType: 'prefetch',
        },
        {
          chunkName: 'middlecontent',
          relType: 'prefetch',
        },
        {
          chunkName: 'bottomcontent',
          relType: 'prefetch',
        },
      ],
      delay: 5000,
      prefetchCallback: ({ status, info }) => {
        console.log('prefetchCallback', status, info)
      },
    })
  }
}

const loadDetailComponent = (vm, { exposeGoodIds }) => {
  handleGoodsDetailTrigger({ goods: vm.goods, exposeGoodIds })
  if(!vm.hasPreFetchedGoodsDetail) {
    vm.hasPreFetchedGoodsDetail = true
    const loadComponent = prefetchResource.importAsyncComponent({
      chunkName: 'goods_detail', // 跟webpackChunkName保持一致
      componentFactory: () => import( /* webpackChunkName: 'goods_detail' */ '../../goods_detail_v2/container'),
    })
    loadComponent()
  }
}

export {
  initPrefetchResource,
  loadDetailComponent,
}
