<!--p65化学危险品标识-->
<template>
  <div
    v-expose="{
      id: 'expose_p65warning:simple',
      data: { sku_code: goodsSn }
    }"
  >
    <div
      :class="prefixCls('common-p65_warning')"
      @click.stop="showP65Warning()"
    >
      <img
        :class="prefixCls('common-p65_warning-img')"
        :src="locals?.IMG_LINK?.['warning']"
        alt=""
      />
      <span :class="prefixCls('common-p65_warning-txt')">
        {{ language.SHEIN_KEY_PWA_20813 }}
      </span>
    </div>
    <ClientOnly>
      <!-- 加州警告弹窗 -->
      <s-dialog
        :visible.sync="dialogInfo.visible"
        :append-to-body="true"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <template slot="title">
          {{ language.SHEIN_KEY_PWA_20813 }}
        </template>
        <div>
          <span
            tabindex="0"
            v-html="dialogInfo.txt"
          ></span>
        </div>
        <template slot="footer">
          <s-button
            :type="['primary']"
            :width="'100%'"
            @click="closeDialog"
          >
            {{ language.SHEIN_KEY_PWA_15146 }}
          </s-button>
        </template>
      </s-dialog>
    </ClientOnly>
  </div>
</template>

<script setup name="P65Warning">
import { computed, reactive } from 'vue'
import ClientOnly from 'vue-client-only'
import { Dialog as SDialog, Button as SButton } from '@shein/sui-mobile'
import { template } from '@shein/common-function'

import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { fetchChemicalsIdsApi } from '@/public/src/services/api/pay/checkout.js'

const dialogInfo = reactive({
  visible: false,
  txt: ''
})

const props = defineProps({
  goodsSn: {
    type: String,
    default: ''
  }
})

// --------- useMap_**** --------
const { checkout, language, locals } = useMapState(['checkout', 'language', 'locals'])

// ---------- computed ----------

// ----------- method -----------
const showP65Warning = async () => {
  const p65Text = await fetchChemicalsIds()
  dialogInfo.txt = p65Text || defaultP65Text()
  dialogInfo.visible = true

  daEventCenter.triggerNotice({
    id: 'click_p65warning:simple',
  })
  daEventCenter.triggerNotice({
    id: 'expose_p65warningdetails:simple',
    data: {
      sku_code: props.goodsSn
    }
  })
}

// 商品sku集合
const listFn = computed(() => {
  const list = checkout.value?.p65_flag || []
  return list.filter(item => item.flag === 1)
})

const fetchChemicalsIds = async () => {
  let p65Text = ''

  if (listFn.value?.length) {
    let tagsId = listFn.value?.[0]?.tags || []
    // 商品tag集合
    if (tagsId?.length) {
      tagsId = tagsId?.sort((prev, next) => Number(prev) - Number(next))
      let matchTagIds = tagsId?.[tagsId.length - 1]
      p65Text = await fetchChemicalsIdsApi({
        matchTagIds: matchTagIds
      })
    }
  }

  return p65Text
}

const defaultP65Text = () => {
  const aDom =
    '<a href="https://www.p65warnings.ca.gov/" target="_blank">www.P65Warnings.ca.gov</a>'
  return template(aDom, language.value?.SHEIN_KEY_PWA_20812)
}

const closeDialog = () => {
  dialogInfo.visible = false
}
</script>

<style lang="less">
@import '../../variables.less';

.@{prefixCls}common-p65 {
  &_warning {
    width: 1.707rem;
    margin-bottom: 0.08rem;
    padding-left: 0.053rem;
    background: rgba(255, 231, 231, 0.7);
    border: 1px solid rgba(255, 65, 28, 0.6);
    display: flex;
    align-items: center;
    height: 0.4rem;

    &-img {
      height: 0.267rem;
      width: auto;
      margin-right: 1px;
      vertical-align: sub;
    }

    &-txt {
      height: 0.7rem;
      display: inline-block;
      text-transform: uppercase;
      font-size: 0.533rem;
      font-weight: bold;
      color: #ff411c;
      transform: scale(0.5);
      transform-origin: left;
    }
  }
}
</style>
