<template>
  <div :class="prefixCls('price-container')">
    <div :class="prefixCls('price-section')">
      <div :class="[prefixCls('ps-main')]">
        <!-- 有付费会员标签，不展示其他标签 -->
        <img
          v-if="!!renderData.primeGoodImgUrl"
          :class="prefixCls('ps__member-tag')"
          :src="renderData.primeGoodImgUrl"
          alt=""
        />
        <template v-else>
          <Icon
            v-if="!!otherIconInfo.icon"
            :name="otherIconInfo.icon"
            size="16px"
            :color="otherIconInfo.color"
          />
          <Icon
            v-else-if="isBrandFlash"
            name="sui_icon_brandsale_15px"
            size="16px"
            color="#FFCD94"
          />
        </template>
        <em
          v-if="isVip"
          class="vip-exclusive-tag_color-black"
          :class="[
            GB_cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag'
          ]"
        ></em>

        <!-- 价格 -->
        <div
          :class="[
            prefixCls('ps-detail'),
            prefixCls('ps-detail-' + renderData?.id),
            isShowPricePre ? prefixCls('ps-detail-pre') : ''
          ]"
        >
          <span :class="prefixCls('ps-price')">
            <!-- 价格表达 -->
            <span
              v-if="isInDrawer &&!!renderData?.priceLabel"
              :class="prefixCls('ps-pre')"
              :style="{ color: renderData?.priceLabel?.text_color }"
            >{{ renderData?.priceLabel?.text }}</span>
            <!-- 主价格 -->
            <span
              :class="prefixCls('ps-unit')"
              :style="{ color: price.color }"
            >
              <span>{{ price.txt }}</span>
            </span>
          </span>
          <!-- 划线价 -->
          <span
            v-if="delPrice.txt"
            :style="{ color: delPrice.color }"
            :class="prefixCls('ps-delete')"
          >
            <del :style="{ textDecoration: delPrice.textDecoration }">
              {{ delPrice.txt }}
            </del>
            {{ delPrice.description }}
          </span>
          <s-popover
            v-if="!!delPrice.tip.imgUrl && !!delPrice.tip.label"
            :append-to-body="true"
            :prop-style="{
              width: '6.4rem'
            }"
            placemen="bottom"
            show-close-icon
            :content="delPrice.tip.desc"
          >
            <Icon
              slot="reference"
              :name="delPrice.tip.imgUrl"
              size="12px"
              color="#959595"
            />
            <div>
              {{ delPrice.tip.label }}
            </div>
          </s-popover>
        </div>
      </div>
      <div
        v-if="lowestPrice.txt"
        :class="[prefixCls('ps-lowest')]"
        :style="lowestPrice.style"
      >
        <span :style="{ textDecoration: lowestPrice.textDecoration }">
          {{ lowestPrice.txt }}
        </span>
        {{ lowestPrice.description }}
      </div>
    </div>
    <QuantityInput
      v-if="isQuantityEditable"
      :data="data || {}"
      @emitInputCurrent="inputNumberChange"
    />

    <div
      v-else
      :class="[
        prefixCls('ps-quantity'),
        { 'quantity-welfare': +(data?.quantity >= 2) }
      ]"
    >
      <span class="value">x{{ data?.quantity }}</span>
    </div>
  </div>
</template>
<script setup name="ProductPriceSection">
import { computed, onMounted, nextTick, inject } from 'vue'
import { Popover as SPopover } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import usePrice from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/usePrice.js'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import QuantityInput from '../../common/QuantityInput.vue'
import useAttribute from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useAttribute.js'

const { GB_cssRight } = useMapState(['GB_cssRight'])
const changeDelectBubble = inject('changeDelectBubble', () => {})

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  // 数量是否可编辑
  quantityEditable: {
    type: Boolean,
    default: true
  },
  // 是否在弹窗内使用
  isInDrawer: {
    type: Boolean,
    default: false
  },
})

const price = computed(() => {
  // 组合式API === 处理公共业务
  const { price } = usePrice(props?.data)
  return price.value
})

const { isGift, isAdditionGood, isOutStock } = useAttribute(props?.data)

// ---------- computed ----------
const isQuantityEditable = computed(() => {
  if (!props.quantityEditable) {
    return false
  } else {
    return !(isGift.value || isAdditionGood.value || isOutStock.value)
  }
})

/**
 * @description 渲染数据
 * @returns {primeGoodImgUrl} 付费会员icon
 * */
const renderData = computed(() => {
  const {
    data: { product, aggregateProductBusiness, id }
  } = props

  // 付费会员
  let primeGoodImgUrl = ''
  const activityCommunionsItem =  aggregateProductBusiness?.activityCommunions?.find(item => +item.type_id === 29)
  if(activityCommunionsItem){
    primeGoodImgUrl = activityCommunionsItem?.primeGoodImgUrl || ''
  }else{
    primeGoodImgUrl = aggregateProductBusiness?.primeGoodImgUrl || ''
  }
  return {
    primeGoodImgUrl,
    goodsSn: product?.goods_sn || '',
    priceLabel: aggregateProductBusiness?.shoppingBagAggregateInfo?.price_label,
    id,
  }
})

// 是否展示价格前缀
const isShowPricePre = computed(() => {
  return props.isInDrawer && !!renderData.value?.priceLabel
})

// 非付费会员icon信息
const otherIconInfo = computed(() => {
  const {
    data: {
      aggregateProductBusiness: { type_id }
    }
  } = props
  let res = { icon: '', color: '' }
  switch (+type_id) {
    case 10:
    case 11:
      res = {
        icon: isShowPricePre.value ? '' : 'sui_icon_flashsale_15px',
        color: '#facf19'
      }
      break
    case 8:
      res = {
        icon: isShowPricePre.value ? '' : 'sui_icon_phone_15px',
        color: '#fa6338'
      }
      break
    case 3:
      res = {
        icon: isShowPricePre.value ? '' : 'sui_icon_time_15px',
        color: '#fa6338'
      }
      break
    default:
  }

  return res
})

const isBrandFlash = computed(() => {
  const {
    data: {
      aggregateProductBusiness: { flash_type }
    }
  } = props
  return +flash_type === 6
})

/**
 * @description 划线格信息
 * @returns {txt} 文本
 * @returns {color} 颜色
 * */
const delPrice = computed(() => {
  const {
    data: {
      aggregateProductBusiness: { priceData }
    }
  } = props
  const {
    price,
    color = '',
    description = '',
    crossed
  } = priceData?.originalPrice || {}
  const { amountWithSymbol = '', tip } = price || {}
  let res = {
    txt: amountWithSymbol,
    description,
    tip: {
      desc: tip?.desc || '',
      imgUrl: tip?.imgUrl || '',
      label: description || ''
    },
    color,
    textDecoration: crossed === '1' ? 'line-through' : 'none'
  }
  return res
})

/**
 * @description 最低价信息
 * @returns {txt} 价格
 * @returns {description} 价格后的描述
 * @returns {style} 样式
 * */
const lowestPrice = computed(() => {
  const {
    data: {
      aggregateProductBusiness: { priceData }
    }
  } = props
  const {
    price,
    color = '',
    description = '',
    crossed
  } = priceData?.lowestPrice || {}
  const { amountWithSymbol = '' } = price || {}
  let res = {
    txt: amountWithSymbol,
    description,
    style: { color },
    textDecoration: crossed === '1' ? 'line-through' : 'none'
  }
  return res
})

const isVip = computed(() => {
  const {
    data: {
      aggregateProductBusiness: { type_id }
    }
  } = props
  return type_id === 12
})

/**
 * @description 存在价格前缀时计算价格区域布局
 * 单行放不下时优先隐藏划线价
 * 再缩小前缀字号12->11
 * 再对前缀进行打点省略，确保价格完全展示
 */
const adjustPriceLayout = async () => {
  await nextTick()
  const priceContainer = document.querySelector('.checkout-sbs__ps-detail-' + renderData.value?.id)
  const mainPrice = priceContainer.querySelector('.checkout-sbs__ps-price')
  const delPrice = priceContainer.querySelector('.checkout-sbs__ps-delete')
  const pricePre = priceContainer.querySelector('.checkout-sbs__ps-pre')
  // 当前缀 + 价格 + 划线价超出，优先隐藏划线价
  if (delPrice?.scrollWidth > delPrice?.offsetWidth) {
    delPrice.style.display = 'none'
  }
  // 当前缀 + 价格仍然超出，缩小前缀字体为11px
  if (mainPrice?.scrollWidth > mainPrice?.offsetWidth) {
    pricePre.style.fontSize = '11px'
  }
  // 当前缀缩小至11px后，前缀 + 价格仍然超出，对前缀进行打点省略
  if (mainPrice?.scrollWidth > mainPrice?.offsetWidth) {
    pricePre.style.textOverflow = 'ellipsis'
    pricePre.style.overflow = 'hidden'
  }
}

const inputNumberChange = (currentValue, oldValue, type) => {
  if((type === 'decrease' || type === 'input') && currentValue > 0 && currentValue < oldValue){
    setTimeout(() => {
      changeDelectBubble(true, props.data.id)
    }, 300)  
  }
}

onMounted(() => {
  adjustPriceLayout()
})
</script>

<style lang="less">
@import '../../../variables.less';

@s3_icon: '/pwa_dist/images/sheinvip2/s3_icon-7c5e305bc1.png';
@s3_icon_ar: '/pwa_dist/images/sheinvip2/s3_icon_ar-827f0ce0d6.png';
.@{prefixCls}price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #222;
  .font-dpr(24px);
  margin-top: 0.16rem;

  .@{prefixCls}price-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - 1.974rem);
    flex: 1;
    padding-right: 8/75rem;
  }

  .@{prefixCls}ps-main {
    display: inline-flex;
    align-items: center;
    .margin-r(12/75rem);
    width: 100%;

    .@{prefixCls}ps {
      &__member-tag {
        width: 14px;
        height: 14px;
      }
    }
    .vip-exclusive-tag,
    .vip-exclusive-tag__ar {
      display: inline-block;
      background-repeat: no-repeat;
      background-size: 3.23rem .77rem;
      width: .85rem;
      height: .27rem;
      &_color-yellow {
        background-position: -1.45rem -.25rem;
      }
      &_color-black {
        background-position: -.48rem -.25rem;
      }
    }
    .vip-exclusive-tag {
      background-image: url(@s3_icon);
    }

    .vip-exclusive-tag__ar {
      background-image: url(@s3_icon_ar);
      vertical-align: -0.026667rem;
    }
  }

  .@{prefixCls}ps-quantity {
    white-space: nowrap;
    .value {
      font-weight: 400;
      .font-dpr(24px);
      color: #333333;
    }

    &.quantity-welfare {
      .value {
        color: @sui_color_welfare;
      }
    }
  }

  .@{prefixCls}ps-detail {
    color: #222222;
    .font-dpr(28px);
    font-weight: bold;
    line-height: 0.453rem;
    min-width: 0;
    display: inline-flex;
    flex-shrink: 0;
    align-items: baseline;
    margin-left: 2px;

    &.@{prefixCls}ps-detail-pre {
      max-width: 100%;
      flex: 1;
    }

    .@{prefixCls}ps-price {
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      overflow: hidden;

      .@{prefixCls}ps-pre {
        font-size: 12px;
        white-space: nowrap;
        margin-right: 4/75rem;
      }

      .@{prefixCls}ps-unit {
        font-size: 15px;
        white-space: nowrap;
        word-break: break-all;
        flex: 1;
      }
    }

    .@{prefixCls}ps-delete {
      color: @sui_color_gray_dark3;
      font-size: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      .padding-l(4/75rem);
    }
  }

  .@{prefixCls}ps-lowest {
    color: @sui_color_gray_dark3;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    .padding-l(4/75rem);
  }
}
</style>
