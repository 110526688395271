import { computed, toRef, onMounted, watch, nextTick, ref } from 'vue'
import { useStore } from '../../hooks/useStore'
import { useItemViewportDisplay } from 'public/src/pages/cart_v2/hooks/useItemViewportDisplay.js'
import { useCacheClose } from './useCacheClose'

export const useCartLowStockPopoverFlag = (cartItem) => {
  const { state, getters, commit } = useStore()
  const { isCacheClose } = useCacheClose()
  const cartItemId = toRef(cartItem, 'id')
  const cartCheckoutStatus = toRef(cartItem, 'checkoutStatus')
  const cartLowStockExposeFlagIds = computed(() => state.cartLowStockExposeFlagIds)
  const cartLowStockFlagIds = computed(() => getters['cart_v2/cartLowStockFlagIds'])
  const cartLowStockFlagId = computed(() => getters['cart_v2/cartLowStockFlagId'])
  const isLowStockItem = computed(() => cartLowStockFlagId.value === cartItem.id)
  const showCartLowStockPopover = computed(() => isLowStockItem.value && !isCacheClose.value)
  const cartItemWrapClassname = computed(() => `.cart-list .j-cart-item-${cartItemId.value}`)
  const batchActive = computed(() => state.batchActive)
  const disabled = computed(() => !cartLowStockFlagIds.value.includes(cartItem.id) || batchActive.value || isCacheClose.value)
  const { isViewportDisplay, calcDisplay } = useItemViewportDisplay({ cartItemWrapClassname, disabled })

  watch(() => disabled.value, (newVal, oldVal) => {
    if (!newVal && oldVal) {
      calcDisplay()
    }
  })
  watch(() => isViewportDisplay.value, (isDisplay) => {
    if (isDisplay) {
      if (cartLowStockExposeFlagIds.value.includes(cartItemId.value)) return
      commit('cart_v2/updateState', {
        key: 'cartLowStockExposeFlagIds',
        value: [
          ...cartLowStockExposeFlagIds.value,
          cartItemId.value
        ]
      })
    } else {
      const index = cartLowStockExposeFlagIds.value.findIndex(id => id === cartItemId.value)
      if (index === -1) return
      const newCartLowStockExposeFlagIds = [...cartLowStockExposeFlagIds.value]
      newCartLowStockExposeFlagIds.splice(index, 1)
      commit('cart_v2/updateState', {
        key: 'cartLowStockExposeFlagIds',
        value: newCartLowStockExposeFlagIds,
      })
    }
  })

  onMounted(() => {
    nextTick(() => {
      calcDisplay()
    })
  })

  return {
    showCartLowStockPopover
  }
}
