// global variable : PageGroup , SHEIN_COMMON
import { abtservice } from 'public/src/services/abt'
// import { parseQueryString } from '@shein/common-function'

/**
 * 获取解码后的参数
*/
// const getEventParams = (val = '') => {
//   let param = ''
//   try {
//     param = decodeURIComponent(val)
//   } catch (error) {
//     param = val
//   }
//   return param
// }

// const getPageSource = () => {
//   let pageStr = PageGroup || ''
//   const productsource = parseQueryString(location.search)?.productsource

//   if (productsource) {
//     const pageGroups = PageGroup.split('&')

//     if (pageGroups.indexOf('-') == 1) {
//       pageGroups[1] = productsource
//       pageStr = pageGroups.join('&')
//     }
//   }
//   return getEventParams(pageStr)
// }

const pageInfoMapping = (code) => {
  let info = {}
  switch (code) {
    case 'recommend':
      info['scenes'] = '推荐列表'
      break
    case 'vipExclusiveHome':
      info['overview'] = '会员权益页'
      info['scenes'] = '会员权益页'
      break
    case 'vipBirthGiftList':
      info['overview'] = '生日礼商品列表页'
      info['scenes'] = '生日礼商品列表页'
      break
    case 'realProductListRecommend':
      info['overview'] = '分类页'
      info['scenes'] = '推荐列表'
      break
    case 'searchProductListRecommend':
      info['overview'] = '搜索页'
      info['scenes'] = '推荐列表'
      break
    case 'trackRecommend':
      info['overview'] = info['scenes'] = '物流轨迹页'
      break
    case 'cart':
      info['overview'] = info['scenes'] = '购物车页'
      break
    case 'cartRecommend':
    case 'emptyCart':
      info['overview'] = '空购物车'
      info['scenes'] = '推荐列表'
      break
    case 'detailRecommend':
    case 'relationGoods':
    case 'recommendDetailRecommend':
    case 'oftenBoughtWithDetailRecommend':
    case 'detailSimilarItems':
    case 'detailBestSeller':
    case 'goodsDetail':
      info['overview'] = info['scenes'] = '商品详情页'
      break
    case 'similarDetailRecommend':
    case 'getTheLookDetailRecommend':
      info['overview'] = '商品详情页'
      info['scenes'] = 'GetTheLook'
      break
    case 'oftenBoughtWithDetailRecommendNewStyle':
    case 'youmayalsolikeDetailRecommend':
      info['overview'] = '商品详情页'
      info['scenes'] = '推荐列表'
      break
    case 'similarItems':
    case 'similarRecommend':
      info['overview'] = '相似推荐结果页'
      info['scenes'] = '推荐列表'
      break
    case 'cardRecommend':
      info['overview'] = info['scenes'] = '推荐列表'
      break
    case 'productList':
    case 'flashSale':
      info['overview'] = info['scenes'] = '列表页'
      break
    case 'recentlyViewed':
      info['overview'] = info['scenes'] = '最近浏览'
      break
    case 'wishlist':
    case 'wishlistUser':
      info['overview'] = info['scenes'] = '收藏夹'
      break
    case 'orderDetail':
      info['overview'] = info['scenes'] = '订单详情页'
      break
    case 'orderDetailCccRecommend':
      info['overview'] = '订单详情页'
      info['scenes'] = '推荐列表'
      break
    case 'cartBuyGifts':
    case 'cartFullGifts':
      info['overview'] = '购物车页'
      info['scenes'] = '满赠'
      break
    case 'cartAddBuys':
      info['overview'] = '购物车加价购页'
      info['scenes'] = '加价购'
      break
    case 'cartAddItem':
      info['overview'] = '购物车凑单页'
      info['scenes'] = '凑单页'
      break
    case 'getTheLook':
      info['overview'] = '商品详情页'
      info['scenes'] = 'GetTheLook'
      break
    case 'otherOptions':
      info['overview'] = '商品详情页'
      info['scenes'] = 'OtherOptions'
      break
    case 'activity':
      info['overview'] = info['scenes'] = '专题页'
      break
    case 'visualSearch':
      info['overview'] = info['scenes'] = '以图搜图结果页'
      break
    case 'PaymenSuccessRecommend':
      info['overview'] = info['scenes'] = '支付成功页'
      break
    case 'set_details':
      info['overview'] = info['scenes'] = '套装详情页'
      break
    case 'group_share':
      info['overview'] = '收藏分享页'
      break
    case 'collection_boards':
      info['overview'] = '收藏分组商品页'
      break
    case 'couponProduct':
      info['overview'] = '券可用商品承接页'
      info['scenes'] = '列表页'
      break
    // 买家秀
    case 'featuredreviews':
      info['overview'] = '买家秀大图页'
      info['scenes'] = '买家秀大图页'
      break
    case 'similarItemsRecommend':
      info['overview'] = '商品详情页'
      info['scenes'] = 'SimilarItems'
      break
    // case 'frequentlyBoughtRecommend':
    //   info['overview'] = '商品详情页'
    //   info['scenes'] = 'frequentlyBoughtTogether'
    //   break
    default:
      info['overview'] = info['scenes'] = 'other'
      break
  }
  return info
}

/**
 * 从祖先元素获取信息
 * @param {target} dom
 *
 * @return
 * @param {Dom} container 匹配到.j-da-event-box 最近祖先容器
 * @param {String} title 推荐位名称，没有不传即可
 * @param {String} poskey 实验poskey
 * @param {String} code 列表标识
 * @param {String} isFault 是否走了容错 1 / 0
 * @param {String} dataType 数据来源，own, emarsys
 * @param {String} style 点击商品之后是跳详情页面还是 弹出其他弹窗，popup, detail
 * @param {String} traceId 自有购物流程id
 * @param {String} activityFrom  这个点击事件发生在哪个页面上（根据需求文档），sa使用
 * @param {Boolean} isModule  区分是推荐商品列表 还是 普通商品列表 (如果是普通列表则不用传)
 * @param {Number} pageNum  页码不传默认是1
 * @param {String} tagIds  列表-标签云id
 * @param {String} attrStr  列表-属性
 * @param {String} cat  列表-分类
 * @param {String} minPri  列表-最低价格
 * @param {String} maxPri  列表-最高价格
 * @param {String} request_ext  列表-算法跟踪
*/
const getAttrFromContainer = (target) => {
  if(!target) return
  const box = $(target).closest('.j-da-event-box')[0]
  const infoObj = {
    container: box,
    parentGoodsId: box && box.getAttribute('data-parent-goods-id') || '', // 父级/入口商品id
    goodsType: box && box.getAttribute('data-goods-type') || '', // 意图搜图增加一个商品是来自默认推荐还是裁剪之后的推荐
    title: box && box.getAttribute('data-title') || '',
    poskey: box && box.getAttribute('data-poskey') || '',
    code: box && box.getAttribute('code') || '',
    isFault: box && box.getAttribute('data-is-fault-tolerant') || 0,
    dataType: box && box.getAttribute('data-dataType') || '',
    style: box && box.getAttribute('data-style') || 'detail',
    traceId: box && box.getAttribute('data-traceid') || '',
    dimension: box && box.getAttribute('data-dimension') || '',
    activityFrom: box && box.getAttribute('data-active-from') || '',
    isModule: box && box.getAttribute('data-module') || false,
    pageNum: box && box.getAttribute('data-page-num') || 1,
    cateIndex: box && box.getAttribute('data-cate-index') || '',
    cateId: box && box.getAttribute('data-cate-id') || '',
    cateName: box && box.getAttribute('data-cate-name') || '',
    tabList: box && box.getAttribute('data-tab-list') || '',
    tab: box && box.getAttribute('data-tab') || '',
    // 普通列表才需要
    tagIds: box && box.getAttribute('data-tag_ids') || '',
    attrStr: box && box.getAttribute('data-attr_str') || '',
    cat: box && box.getAttribute('data-cat') || '',
    minPri: box && box.getAttribute('data-min-pri') || 0,
    maxPri: box && box.getAttribute('data-max-pri') || 0,
    request_ext: box && box.getAttribute('data-request_ext') || '',
    // ccc 推荐位用到
    _gaImpList: box && box.getAttribute('data-imp-list') || 0,
    ruleId: box && box.getAttribute('data-rule-id') || 0,
    pageId: box && box.getAttribute('data-page-id') || 0,
    floorId: box && box.getAttribute('data-floor-id') || 0,
    comId: box && box.getAttribute('data-com-id') || 0,
    cateNm: box && box.getAttribute('data-cate-name') || 0,
    componentType: box && box.getAttribute('data-component-type') || 0,
    refresh: box && box.getAttribute('data-refresh') || '', // 商品列表是不是刷新之后的列表
    //套装详情页需要
    extraPramas: box && box.getAttribute('data-extra-pramas') || '',
    // 付费会员页需要
    location: box && box.getAttribute('data-location') || '',
    reviewLocation: box && box.getAttribute('data-review-location') || '',
    primeTp: box && box.getAttribute('data-prime_tp') || '',

    srcModule: box && box.getAttribute('data-src-module') || '',
    srcIdentifier: box && box.getAttribute('data-src-identifier') || '',
    srcTabPageId: box && box.getAttribute('data-src-tab-page-id') || '',

    // 榜单内容体组件埋点
    rankingFrom: box && box.getAttribute('data-ranking-from') || '',
    rankingContentId: box && box.getAttribute('data-ranking-contentId') || '',
    rankingCarriersubtype: box && box.getAttribute('data-ranking-carriersubtype') || '',
    boardGenerateType: box && box.getAttribute('data-ranking-boardGenerateType') || '',

    // recommendpopup组件用到
    similarFrom: box && box.getAttribute('data-similar_from') || '',
    // 闪购列表
    type: box && box.getAttribute('data-type') || '',
    time: box && box.getAttribute('data-time') || '',
    activity_id: box && box.getAttribute('data-activity-id') || '',
    // 商详
    isAddMore: box && box.getAttribute('data-is-add-more') || '',
    pageView: box && box.getAttribute('data-page-view') || '',
    feed_type: box && box.getAttribute('data-feed_type') || '',
    tsp_abtest: box && box.getAttribute('data-tsp-abtest') || '',
    series_no: box && box.getAttribute('data-series_no') || '',
    query_ts: box && box.getAttribute('data-query-ts') || '',
  }
  return infoObj
}

/**
 * 从当前target 获取信息
 * @param {target} dom
 * @returns
 * @param {Number} index
 * @param {String} spu
 * @param {String} sku
 * @param {String} catId 分类id
 * @param {Number} goodsId 商品id
 * @param {Number} price 价格
 * @param {Number} reducePrice 降价标示
 * @param {Number} operId 运营位置(首页底部是-1，其它默认是1)
 * @param {String} recMark 流量标示
 * @param {String} extraMark 置顶标示
 * @param {String} otherExtMark 其他ext标示
 * @param {String} otherDExtMark 其他dynamic_ext标示
 * @param {String} exclusivePrice s3专属折扣价
 * @param {String} seriesOrBrand 商品系列 shein高端（premium）、romwe设计师款（#ROMWEcreators）、romwe单独款（#ROMWEoriginals） 或者 品牌  shein/romwe
 * @param {String} sellingPointLabel 卖点
 * @param {String} cccTspBadges 角标统一文案
 * @param {String} recommendInfo 点后推商品用到
 * @param {String} realtimePosition 点后推商品用到
 * @param {String} realtimeFromCateId 点后推商品用到
 * @param {String} realtimeFromGoodsId 点后推商品用到
 * @param {String} behaviorInfo 用户行为落地页商品用
 * 
*/
const getAttrFromTarget = (target) => {
  let index = 0,
      spu = '',
      sku = '',
      catId = '',
      goodsId = '',
      price = 0,
      originPrice = 0,
      reducePrice = 0,
      operId = 1,
      recMark = '',
      soldoutMark = 0,
      soldOutSign = 0,
      extraMark = '',
      otherExtMark = '',
      otherDExtMark = '',
      extMarks = '',
      promotionInfo = '',
      exclusivePrice = '',
      lable = '',
      cccTspBadges = '',
      seriesOrBrand = '',
      extraPramas = '',
      sellingPointLabel = '',
      brand = '',
      brandCode = '',
      videoIcon = '',
      saleAttr = '',
      spuImg = '',
      mallCode = '',
      storeCode = '',
      priceCut = '',
      promotionId = '',
      typeId = '',
      quickShip = '',
      localShip = '',
      bestDeal = '',
      followLabel = '',
      promoLabel = '',
      userBehaviorLabel = '',
      userPreferencesLabel = '',
      userCommentKeywordsLabel = '',
      beltLabel = '',
      rankingList = '',
      decisionAttr = '',
      locateLabels = '',
      discountSpecial = '',
      discountTabs = '',
      historicalPrice = '',
      followPrice = '',
      visitorLabel = '',
      labelCarousels = '',
      findSimilarMode = '',
      estimatedPriceInfo = [],
      showLabelHot = '',
      labelLooping = '',
      serviceLabel = '',
      titleLabel = '',
      horseRaceLabel = '',
      rrpPrice = '',
      discountCountDown = '',
      unitPrice = '',
      extraTag = '',
      salesLabel = '',
      saleCnt = '',
      showSellerLimitedLabel = '',
      hypernymGoods = '',
      isABPrice = '',
      oneClickPayAddBad = '',
      showNewuseronlyPriceLabel = '',
      trendWordId = '',
      trendProductSelectId = '',
      trendShopCode = '',
      addCartBtnType = '',
      lookType = '',
      lookName = '',
      recommendInfo = '',
      realLeftStock = '',
      maskLayer = '',
      realtimePosition = '',
      realtimeFromCateId = '',
      realtimeFromGoodsId = '',
      isRefresh = '',
      recommendRefresh = '',
      trendIsReviewBubble = '',
      behaviorInfo = '',
      actionId  = '',
      goodsRank = '',
      divideTime = '',
      isPlayingVideo = '',
      hasVideo = ''
      
  if (target) {
    // v3商卡才有的标签 start
    const v3CardlabelLooping = target.getAttribute('data-label-looping')
    const v3CardlServiceLabel = target?.getAttribute('data-service-label')
    const v3CardTitleLabel = target?.getAttribute('data-title-label')
    const v3CardHorseRaceLabel = target?.getAttribute('data-horse-race-first')
    const v3CardRRPPrice = target?.getAttribute?.('data-rrp-price') ?? ''
    const v3CardDiscountCountDown = target?.getAttribute?.('data-discount-count-down') ?? '' 
    const v3CardUnitPrice = target?.getAttribute('data-unit-price') || ''

    // 需要额外上报的标签
    const v3ExtraTagProposition = target?.getAttribute('data-extra-tag-proposition')
    // v3商卡才有的标签 end
    
    showLabelHot = target.getAttribute('data-show-label-hot') || ''
    index = target.getAttribute('data-index') || target.getAttribute('index') || 0
    spu = target.getAttribute('data-spu') || ''
    sku = target.getAttribute('data-sku') || ''
    catId = target.getAttribute('data-cat_id') || ''
    goodsId = target.getAttribute('data-goods_id') || target.getAttribute('data-id')
    price = target.getAttribute('data-us-price') || 0
    originPrice = target.getAttribute('data-us-origin-price') || 0
    reducePrice = target.getAttribute('data-reduce-price') || 0
    operId = target.getAttribute('data-operId') || 1
    recMark = target.getAttribute('data-rec_mark') || ''
    extraMark = target.getAttribute('data-extra_mark') || ''
    otherExtMark = target.getAttribute('data-other_ext_mark') || ''
    otherDExtMark = target.getAttribute('data-other_d_ext_mark') || ''
    extMarks = target.getAttribute('data-ext_marks') || ''
    soldoutMark = target.getAttribute('data-soldout') || ''
    soldOutSign = target.getAttribute('data-sold-out') || ''
    promotionInfo = target.getAttribute('data-show-promot-info') || ''
    exclusivePrice = target.getAttribute('data-show-exclusive-price') || ''
    lable = target.getAttribute('data-lable') || ''
    seriesOrBrand = target.getAttribute('data-series-brand') || ''
    extraPramas = target.getAttribute('data-extra-pramas')
    sellingPointLabel = target.getAttribute('data-selling-point') || ''
    cccTspBadges = target.getAttribute('data-badges') || ''
    brand = target.getAttribute('data-brand') || ''
    brandCode = target.getAttribute('data-brand-code') || ''
    videoIcon = target.getAttribute('data-video') || ''
    saleAttr = target.getAttribute('data-sale-attr') || ''
    spuImg = target.getAttribute('data-spu-img') || ''
    mallCode = target.getAttribute('data-mall_tag_code')?.split('_')?.[1] || ''
    storeCode = target.getAttribute('data-store_code') || ''
    priceCut = target.getAttribute('data-price-cut') || ''
    promotionId = target.getAttribute('data-promotion-id')?.split('`') || []
    typeId = target.getAttribute('data-type-id')?.split('`') || []
    quickShip = target.getAttribute('data-quick-ship') || ''
    localShip = target.getAttribute('data-local-ship') || ''
    bestDeal = target.getAttribute('data-best-deal') || ''
    followLabel = target.getAttribute('data-follow-label') || ''
    promoLabel = target.getAttribute('data-promo-label') || ''
    userBehaviorLabel = target.getAttribute('data-user-behavior-label') || ''
    userPreferencesLabel = target.getAttribute('data-user-preferences-label') || ''
    showNewuseronlyPriceLabel = target.getAttribute('data-show-newuseronly-price-label') || ''
    userCommentKeywordsLabel = target.getAttribute('data-user-comment-keywords-label') || ''
    beltLabel = target.getAttribute('data-belt-label') || ''
    rankingList = target.getAttribute('data-ranking-list') || ''
    estimatedPriceInfo = target.getAttribute('data-estimated-price')?.split('`') || []
    decisionAttr = target.getAttribute('data-decision-attr') || ''
    locateLabels = target.getAttribute('data-locate-labels') || ''
    discountSpecial = target.getAttribute('data-discount-special') || ''
    discountTabs = target.getAttribute('data-discount-tabs') || ''
    historicalPrice = target.getAttribute('data-historical-price') || ''
    followPrice = target.getAttribute('data-follow-price') || ''
    visitorLabel = target.getAttribute('data-visitor-label') || ''
    labelCarousels = target.getAttribute('data-label-carousel') || ''
    findSimilarMode = target.getAttribute('data-find-similar-mode') || ''
    labelLooping = v3CardlabelLooping
    serviceLabel = v3CardlServiceLabel
    titleLabel = v3CardTitleLabel
    extraTag = v3ExtraTagProposition
    horseRaceLabel = v3CardHorseRaceLabel
    rrpPrice = v3CardRRPPrice
    discountCountDown = v3CardDiscountCountDown
    unitPrice = v3CardUnitPrice

    salesLabel = target.getAttribute('data-sales-label') || ''
    saleCnt = target.getAttribute('data-sale-cnt') || ''
    showSellerLimitedLabel = target.getAttribute('data-show-seller-limited-label') || ''
    hypernymGoods = target.getAttribute('data-hypernym-goods') || ''
    isABPrice = target.getAttribute('data-is-ab-price') || ''
    oneClickPayAddBad = target.getAttribute('data-add-bag-one-click') || ''
    trendWordId = target.getAttribute('data-trend-word-id') || ''
    trendProductSelectId = target.getAttribute('data-trend-product-select-id') || ''
    trendShopCode = target.getAttribute('data-trend-shop-code') || ''
    addCartBtnType = target.getAttribute('data-add-cart-type') || ''
    lookType = target.getAttribute('data-look_type') || ''
    lookName = target.getAttribute('data-look_name') || ''
    isRefresh = target.getAttribute('data-is-refresh') || ''
    goodsRank = target.getAttribute('data-goods-rank') || ''
    recommendRefresh = target.getAttribute('data-recommend-refresh') || ''
    isPlayingVideo = target?.getAttribute?.('data-video-playing')
    hasVideo = target?.getAttribute?.('data-has-video')

    // 点推商品用的
    recommendInfo = target.getAttribute('data-recommend-info') || ''
    realtimePosition = target.getAttribute('data-realtime-position') || ''
    realtimeFromCateId = target.getAttribute('data-realtime-from-cate-id') || ''
    realtimeFromGoodsId = target.getAttribute('data-realtime-from-goods-id') || ''
    // 趋势频道评论标签
    const isReviewBubble = target.getAttribute('data-is-review-bubble') || ''
    if (isReviewBubble) trendIsReviewBubble = isReviewBubble

    const isStore = target.getAttribute('data-store-index') || false

    if (isStore) index = target.getAttribute('data-store-index') || index
    realLeftStock = target.getAttribute('data-real-left-stock') || ''
    maskLayer = target.getAttribute('data-mask-layer') || ''

    // 唯一的行为id，用来去重
    actionId = target.getAttribute('data-action-id') || ''
    // wiki: 1477051455 新增（用户行为落地页）
    behaviorInfo = target.getAttribute('data-behavior-info') || ''
    divideTime = target.getAttribute('data-divide-time') || ''
  }

  return {
    index,
    spu,
    sku,
    catId,
    goodsId,
    price,
    originPrice,
    reducePrice,
    operId,
    recMark,
    extraMark,
    otherExtMark,
    otherDExtMark,
    extMarks,
    soldoutMark: Number(soldoutMark),
    soldOutSign: Number(soldOutSign),
    promotionInfo,
    exclusivePrice,
    lable,
    cccTspBadges,
    seriesOrBrand,
    extraPramas,
    sellingPointLabel,
    brand,
    brandCode,
    videoIcon,
    saleAttr,
    spuImg,
    mallCode,
    storeCode,
    priceCut,
    promotionId,
    typeId,
    quickShip,
    localShip,
    bestDeal,
    followLabel,
    promoLabel,
    userBehaviorLabel,
    userPreferencesLabel,
    userCommentKeywordsLabel,
    showNewuseronlyPriceLabel,
    beltLabel,
    rankingList,
    decisionAttr,
    locateLabels,
    discountSpecial,
    discountTabs,
    historicalPrice,
    followPrice,
    visitorLabel,
    labelCarousels,
    findSimilarMode,
    estimatedPriceInfo,
    showLabelHot,
    labelLooping,
    serviceLabel,
    titleLabel,
    extraTag,
    rrpPrice,
    discountCountDown,
    unitPrice,
    horseRaceLabel,
    salesLabel,
    saleCnt,
    showSellerLimitedLabel,
    hypernymGoods,
    isABPrice,
    oneClickPayAddBad,
    trendWordId,
    trendProductSelectId,
    trendShopCode,
    addCartBtnType,
    lookType,
    lookName,
    recommendInfo,
    realtimePosition,
    realtimeFromCateId,
    realtimeFromGoodsId,
    realLeftStock,
    maskLayer,
    trendIsReviewBubble,
    isRefresh,
    recommendRefresh,
    behaviorInfo,
    actionId,
    goodsRank,
    divideTime,
    isPlayingVideo,
    hasVideo
  }
}

/**
 * 获取abt信息
 * @param {String} posKeys
 * 
*/
const getAnalysisAbtInfo = (posKeys) => {
  let abtInfo = {},
      saAbt = ''
  if (posKeys) {
    // eslint-disable-next-line @shein-aidc/abt/abt
    abtInfo = abtservice.getUserAbtResultForAnalysis({ posKeys })
    // 自有的abt信息拼接
    saAbt = abtInfo.sa || ''
  }
  return {
    abtInfo,
    saAbt,
  }
}

/**
 * 获取ga list属性
*/
// const getGaList = ({ isModule = false, code = '', title = '', isFault = 0, dataType = '', gaAbt = '', tagIds = '', attrStr = '', cat = '', minPri = 0, maxPri = 0, extraPramas = '', _gaImpList = '' } = {}) => {
//   let list = ''
//   if (isModule) {
//     // 推荐列表
//     // {页面名称}-推荐列表-{推荐位名称}-{Is/NoFaultTolerant}-{推荐来源}-{实验poskey_实验分支类型}
//     list = [
//       pageInfoMapping(code).overview || 'other', '推荐列表', title
//     ]
//     //
//     // 兼容 getTheLook/otherOption 的埋点不需要 {Is/NoFaultTolerant}-{推荐来源}-{实验poskey_实验分支类型}
//     dataType && (list = list.concat([isFault == 0 ? 'NoFaultTolerant' : 'IsFaultTolerant', mapDataType({ dataType }), gaAbt]))
//     list = list.join('-')
//   } else {
//     if (_gaImpList) return _gaImpList
//     // 普通列表的list
//     // {分类类型 id/名称}&{来源}&{排序方式}${tag_ids=}&{attr_str=}&{Cat=分类}&{top1}&{top2}&{PriceRange={1_100}};
//     let params = [getPageSource()]
//     if(extraPramas) params[0] += `-${extraPramas}` //套装详情页ID
//     tagIds && params.push(`tag_ids=${tagIds}`)
//     attrStr && params.push(`attr_str=${attrStr}`)
//     cat && params.push(`Cat=${cat}`);
//     (minPri || maxPri) && params.push(`PriceRange=${minPri}_${maxPri}`)
//     list = params.join('&')
//   }
//   return list
// }

// const mapDataType = ({ dataType = '' } = {}) => {
//   if (dataType === 'own') return '自有推荐'
//   if (dataType === 'emarsys') return 'emarsys'
//   if (dataType === 'syte') return 'syte'
//   if (dataType === 'operating') return 0
//   return ''
// }

/**
 * 获取ga电商代码
*/
const getGaEcommerceCode = ({ isModule = false, index = 0, spu = '', sku = '', catId = '', price = 0, list = '' } = {}) => {
  let ecommerceCode = {
    id: spu || sku,
    name: sku,
    category: catId,
    price: price,
    position: isModule ? 1000 + Number(index) : Number(index),
  }
  if (list) ecommerceCode['list'] = list
  return ecommerceCode
}

/**
 * 获取sa goodsList
 * wiki.pageId=904754267
*/
const getSaGoodsList = ({
  goodsId = '', sku = '', spu = '', index = 0, realIndex = null, pageNum = 1, request_ext = '', operId = 0,
  recMark = '', extraMark = '', otherExtMark = '', otherDExtMark = '', extMarks = '', price = 0, originPrice = 0, reducePrice = 0, soldoutMark = 0, soldOutSign = 0,
  historicalPrice = '', followPrice = '', visitorLabel = '', labelCarousels = '',
  locateLabels = '', discountSpecial = '', discountTabs = '', cccTspBadges = '', promotionInfo = '', exclusivePrice = '', lable = '', seriesOrBrand = '', sellingPointLabel = '', videoIcon = '', saleAttr = '', spuImg = '', mallCode = '', priceCut = '', promotionId = '', typeId = '', brand = '', brandCode = '', quickShip = '', localShip = '', bestDeal = '', followLabel = '', promoLabel = '', userBehaviorLabel = '', userPreferencesLabel = '', userCommentKeywordsLabel = '', showNewuseronlyPriceLabel = '', beltLabel = '', rankingList = '', decisionAttr = '', findSimilarMode = '', estimatedPriceInfo = '', showLabelHot = '', labelLooping = '', serviceLabel = '', titleLabel = '', horseRaceLabel = '', rrpPrice = '', discountCountDown = '', unitPrice = '', extraTag = '', salesLabel = '', saleCnt = '', showSellerLimitedLabel = '', hypernymGoods, isABPrice, oneClickPayAddBad = '', trendWordId = '', trendProductSelectId = '', trendShopCode = '', addCartBtnType = '', recommendInfo = '', lookType = '', lookName = '', realLeftStock = '', maskLayer = '',
  realtimePosition = '', realtimeFromCateId = '', realtimeFromGoodsId = '', goodsRank = '',
  useFrom = '', trendIsReviewBubble = '', behaviorInfo = '', isRefresh = '', recommendRefresh = '', isPlayingVideo, hasVideo
}  = {}) => {
  const prices = [`pri_${price}`, `pri_${originPrice}`]
  if (estimatedPriceInfo[1]) prices.push(`estimated_pri_${estimatedPriceInfo[1]}`)
  let list = [
    goodsId, 
    sku, 
    spu, 
    realIndex ?? Number(index) + 1,
    pageNum, 
    operId, 
    recMark, 
    extraMark, 
    prices.join('|')
  ]
  // 其他标识 - S
  const otherMark = []
  if (reducePrice) otherMark.push(`reduced_${reducePrice}`)
  if (soldoutMark) otherMark.push('stock_soldout')
  if (!soldoutMark && soldOutSign) otherMark.push('show_sold_out') // 列表页的售罄标识另外定义
  if (promotionInfo) otherMark.push('show_promotioninfo')
  if (exclusivePrice) otherMark.push('show_s3vipprice')
  if (lable) otherMark.push(lable)
  if (seriesOrBrand) otherMark.push(seriesOrBrand)
  if (otherExtMark) otherMark.push(otherExtMark)
  if (sellingPointLabel) otherMark.push(sellingPointLabel)
  if (cccTspBadges) otherMark.push(cccTspBadges)
  if (videoIcon) otherMark.push(videoIcon)
  if (spuImg) otherMark.push(spuImg)
  if (priceCut) otherMark.push(`reduced_${priceCut}`)
  if (localShip) {
    otherMark.push('localship_1')
  } else if (quickShip) {
    otherMark.push(quickShip)
  }
  if (bestDeal) otherMark.push('show_label_Betention')
  if (promoLabel) otherMark.push('show_icon')
  if (userBehaviorLabel) otherMark.push(userBehaviorLabel)
  if (userPreferencesLabel) otherMark.push(userPreferencesLabel)
  if (userCommentKeywordsLabel) otherMark.push(userCommentKeywordsLabel)
  if (showNewuseronlyPriceLabel) otherMark.push(showNewuseronlyPriceLabel)
  if (beltLabel) otherMark.push(beltLabel)
  if (followLabel) otherMark.push('show_same_label')
  if (decisionAttr) otherMark.push(decisionAttr)
  if (locateLabels) otherMark.push(locateLabels)
  if (discountSpecial) otherMark.push(discountSpecial)
  if (discountTabs) otherMark.push(discountTabs)
  if (historicalPrice) otherMark.push(historicalPrice) 
  if (followPrice) otherMark.push(followPrice)
  if (visitorLabel) otherMark.push(`Recent_Viewers_${visitorLabel}_viewed`)
  if (labelCarousels) otherMark.push(labelCarousels)
  if (rankingList) otherMark.push(rankingList)
  if (estimatedPriceInfo[0]) otherMark.push(`estimated_price_${estimatedPriceInfo[0]}`)
  if (isABPrice) otherMark.push('estimated_price_3')
  if (findSimilarMode) otherMark.push(findSimilarMode)
  if (showLabelHot) otherMark.push(showLabelHot)
  if (labelLooping) otherMark.push(labelLooping)
  if (serviceLabel) otherMark.push(serviceLabel)
  if (titleLabel) otherMark.push(titleLabel) 
  if (horseRaceLabel) otherMark.push(horseRaceLabel)
  if (rrpPrice) otherMark.push(rrpPrice)
  if (discountCountDown) otherMark.push(discountCountDown)
  if (unitPrice) otherMark.push(unitPrice)
  if (extraTag) otherMark.push(extraTag)
  if (salesLabel) otherMark.push(salesLabel)
  if (saleCnt) otherMark.push(`sale_cnt_${saleCnt}`)
  if (showSellerLimitedLabel) otherMark.push(showSellerLimitedLabel)
  if (hypernymGoods) otherMark.push('search_query_upper')
  if (oneClickPayAddBad) otherMark.push(oneClickPayAddBad)
  if (trendWordId) otherMark.push(`trend_tag_${trendWordId}`)
  if (trendProductSelectId) otherMark.push(`product_select_id_${trendProductSelectId}`)
  if (trendShopCode) otherMark.push(`trend_shop_code_${trendShopCode}`)
  if (extMarks) otherMark.push(extMarks)
  if (addCartBtnType) otherMark.push(addCartBtnType) 
  if (lookType) otherMark.push(lookType) 
  if (lookName) otherMark.push(lookName) 
  if (realLeftStock) otherMark.push(realLeftStock)
  if (maskLayer) otherMark.push(maskLayer)
  if (isRefresh) otherMark.push(`is_refresh_${+isRefresh ? 1 : 0}`)
  if (recommendRefresh) otherMark.push(recommendRefresh)
  if (behaviorInfo) otherMark.push(behaviorInfo) // wiki: 1477051455 落地页用的 用户行为标签信息
  if (goodsRank) otherMark.push(`goods_rank=${goodsRank}`)

  // 趋势频道评论标签
  if (useFrom === 'trend' && +trendIsReviewBubble) otherMark.push('is_review_bubble')
  // 列表点推商品卖点
  if(realtimePosition) otherMark.push(realtimePosition) // 点推商品用
  if(realtimeFromCateId) otherMark.push(realtimeFromCateId) // 点推商品用
  if(realtimeFromGoodsId) otherMark.push(realtimeFromGoodsId) // 点推商品用

  // 其他标识 - E
  // 信息流的 点推商品卖点
  if(recommendInfo) list.push(recommendInfo) // 点推商品用
  
  const playVideoStatus = isPlayingVideo ? 'playing' : hasVideo === '1' ? 'still' : 'null'
  if (hasVideo) otherMark.push(`is_show_video_${hasVideo}`)
  if (hasVideo) otherMark.push(`video_status_${playVideoStatus}`)
  

  // 销售或促销属性 - S
  const salePromotion = []
  salePromotion.push(saleAttr || '') // 销售属性
  if (promotionId && typeId) {
    const is_brand = brand ? 1 : 0
    promotionId.forEach((pId, index) => {
      salePromotion.push(['sale', typeId[index], pId, is_brand, brandCode].join('_')) // 促销属性
    })
  }

  // 销售或促销属性 - E
  list.push(otherMark.join('|'), salePromotion.join('|'))

  list.push(`${mallCode ? 'mall_' + mallCode : '-'}`) // mall
  
  list.push([otherDExtMark, request_ext].filter(_ => _).join('|')) // FR-9925
  return list.join('`')
}

/**
 * 设置sa traceId
*/
const setSaTraceId = ({ goodsId = '', traceId = '', isModule = false } = {}) => {
  gbExposeTraceid('setProduct', {
    goods_id: goodsId,
    traceid: traceId
  })

  // 1.3 旧代码copy, 具体业务功能未知
  if (typeof gbAddBagTraceFrom == 'function') {
    gbAddBagTraceFrom('setProductFrom', {
      from: isModule ? 'recommend' : 'list',
      goods_id: goodsId
    })
  }
}

const getSaInfo = ({
  triggerType = 'click', activityFrom = '', isModule = false, isFault = 0, style = '', traceId = '', dimension = '', saAbt = '',
  goodsList = [], cateIndex = '', cateId = '', cateName = '', tabList = '', refresh, location = '', srcModule = '', srcIdentifier = '',
  reviewLocation = '', tab = '', similarFrom = '', primeTp = '', type = '', time = '', activity_id = '', isAddMore = '',
  rankingFrom = '', rankingContentId = '', rankingCarriersubtype = '', boardGenerateType = '', pageView = '', parentGoodsId = '', feed_type = '',
  series_no = '', tsp_abtest = '', actionId = '', query_ts = '', hasDivideTimeRec
}  = {}) => {
  let sa = {
    name: '',
    param: {}
  }
  // 1.1 判断上报类型
  // click_module_goods_list \ expose_module_goods_list \ click_goods_list \ expose_goods_list
  sa.name = isModule ? `${triggerType}_module_goods_list` : `${triggerType}_goods_list`

  if (activityFrom === 'goods_list') {
    sa.name = triggerType === 'click' ? 'click_goods_list' : 'expose_goods_list'
  }
  sa.param.goods_list = goodsList,
  sa.param.abtest = saAbt
  sa.param.activity_from = activityFrom
  sa.param.style = style
  sa.param.traceid = traceId
  sa.param.similar_from = similarFrom // 实时反馈弹窗用到该字段
  sa.param.is_add_more = isAddMore // 商详加车后出凑单推荐弹窗
  if (pageView) sa.param.page_view = pageView
  if (location) sa.param.location = location
  if (reviewLocation) sa.param.review_location = reviewLocation
  if (refresh) sa.param.is_refresh = 1
  if (dimension) sa.param.dimension = dimension
  if (rankingFrom) sa.param.ranking_from = rankingFrom
  if (rankingContentId) sa.param.content_id = rankingContentId
  if (rankingCarriersubtype) sa.param.carriersubtype = rankingCarriersubtype
  if (boardGenerateType) sa.param.board_generate_type = boardGenerateType
  if (srcModule) sa.param.src_module = srcModule
  if (srcIdentifier) sa.param.src_identifier = srcIdentifier
  if (tab) sa.param.tab = +tab ? tab : '-'
  if (tsp_abtest) sa.param.abtest = tsp_abtest // 圈品实验上报
  if (query_ts) {
    if (!hasDivideTimeRec) { // 点后推商品不上报
      sa.param.query_ts = query_ts // abt interleaving实验上报
    }
  }
  // if (tabList) 
  sa.param.tab_list = tabList || '-'
  if (activityFrom === 'best_sellers') {
    sa.param.tab_list = `${cateIndex}\`${cateId}\`1\`${cateName}`
  }
  if (activityFrom === 'similar_sold_out_pre') {
    sa.param.page_from = window.SaPageInfo?.page_name
    sa.param.similar_from = 'similar_sold_out_pre'
  }
  // 闪购列表
  if (activityFrom === 'goods_list' && activity_id) {
    sa.param.type = type || ''
    sa.param.time = time || ''
    sa.param.activity_id = activity_id
  }
  // 跟着旧的hack逻辑实现，有机会可以考虑优化（放到业务里处理不要在这）
  if (activityFrom === 'similar_items_added') {
    sa.param.page_from = window.SaPageInfo?.page_name
    sa.param.similar_from = 'similar_items_added'
  }
  if (activityFrom === 'reduced_price_items_added') {
    sa.param.page_from = window.SaPageInfo?.page_name
  }

  if (activityFrom === 'buy_box') {
    sa.param.goods_id = parentGoodsId
    sa.param.feed_type = feed_type
  }

  if(activityFrom === 'gift_box_goods_list') {
    sa.param.feed_type = feed_type
  }
  if(activityFrom === 'newoutfit') {
    sa.param.series_no = series_no || '-'
  }

  // 处理同类推荐半屏列表中的相似推荐曝光信息
  if (similarFrom) {
    sa.param.similar_from = similarFrom
  }
  // 会员列表商品曝光、点击曝光信息
  if (primeTp) {
    sa.param.prime_tp = primeTp
  }
  isModule && (sa.param.fault_tolerant = isFault)  // 推荐位容错

  if (actionId) sa.param._actionId = actionId

  return sa
}

// const getGaInfo = ({
//   triggerType = 'click', code = '', list = '',
//   addProduct = {},
//   addImpressionList = [],
//   isModule = false
// }) => {
//   let ga = {}
//   ga.eventCategory = isModule ? '推荐列表' : pageInfoMapping(code).overview || window.PageGroupOverview || 'other'
//   ga.eventLabel = list

//   if (triggerType == 'click') {
//     ga.setAction = {
//       type: 'click',
//       param: {
//         list: list
//       }
//     },
//     ga.addProduct = addProduct
//   } else {
//     ga.addImpressionList = addImpressionList
//   }
//   return ga
// }

export {
  getSaGoodsList,
  pageInfoMapping,
  getAnalysisAbtInfo,
  getAttrFromContainer,
  getAttrFromTarget,
  // getGaList,
  getGaEcommerceCode,
}

/**
 * 商品点击出发的埋点上报函数
 * @param {*} param0
 * @param {*} param1
 * @returns
 */
const clickItemHandler = ({ sa, report }, { target = null, extraData } = {}, extendsHandler) => {
  if (!target) return
  // 获取最近一个类名为含有j-da-event-box的祖先
  let { rankingFrom, rankingContentId, rankingCarriersubtype, boardGenerateType, poskey, isFault, style, traceId, dimension = '', activityFrom, isModule, pageNum, request_ext, cateIndex, cateId, cateName, tabList, refresh, location, srcModule, srcIdentifier, reviewLocation, tab, similarFrom, primeTp, type, time, activity_id, isAddMore, pageView, feed_type, tsp_abtest, query_ts } = getAttrFromContainer(target)
  // abt信息
  let { saAbt = '' } = getAnalysisAbtInfo(poskey)
  // 电商代码的list信息
  // let gaImpList = getGaList({ isModule, code, title, isFault, dataType, gaAbt, tagIds, attrStr, cat, minPri, maxPri, extraPramas, _gaImpList })

  let goodsList = null // sa

  // item 本身取的数据
  let { historicalPrice, followPrice, locateLabels, discountSpecial, discountTabs, visitorLabel, labelCarousels, index, spu, sku, goodsId, price, originPrice, operId, recMark, extraMark, otherExtMark, otherDExtMark, extMarks, reducePrice, soldOutSign, soldoutMark, promotionInfo, exclusivePrice, seriesOrBrand, sellingPointLabel, cccTspBadges, brand, videoIcon, saleAttr, spuImg, mallCode, storeCode, priceCut, promotionId, typeId, brandCode, quickShip, bestDeal, followLabel, promoLabel, userBehaviorLabel, userPreferencesLabel, userCommentKeywordsLabel, showNewuseronlyPriceLabel, beltLabel, rankingList, decisionAttr, findSimilarMode, estimatedPriceInfo, showLabelHot, labelLooping, serviceLabel, titleLabel, horseRaceLabel, rrpPrice, discountCountDown, unitPrice, extraTag, salesLabel, saleCnt, showSellerLimitedLabel, hypernymGoods, isABPrice, oneClickPayAddBad, trendWordId, trendProductSelectId, trendShopCode, addCartBtnType, recommendInfo, lookType, lookName, realLeftStock, maskLayer, realtimePosition, realtimeFromCateId, realtimeFromGoodsId, behaviorInfo, isRefresh, actionId, goodsRank, divideTime, isPlayingVideo, hasVideo } = getAttrFromTarget(target)
  goodsList = getSaGoodsList({ historicalPrice, followPrice, visitorLabel, labelCarousels, locateLabels, discountSpecial, discountTabs, goodsId, sku, spu, index, pageNum, request_ext, operId, recMark, extraMark, otherExtMark, otherDExtMark, extMarks, price, originPrice, reducePrice, soldOutSign, soldoutMark, promotionInfo, exclusivePrice, seriesOrBrand, sellingPointLabel, cccTspBadges, videoIcon, saleAttr, spuImg, mallCode, storeCode, priceCut, promotionId, typeId, brand, brandCode, quickShip, bestDeal, followLabel, promoLabel, userBehaviorLabel, userPreferencesLabel, userCommentKeywordsLabel, showNewuseronlyPriceLabel, beltLabel, rankingList, decisionAttr, findSimilarMode, estimatedPriceInfo, showLabelHot, labelLooping, serviceLabel, titleLabel, horseRaceLabel, rrpPrice, discountCountDown, unitPrice, extraTag, salesLabel, saleCnt, showSellerLimitedLabel, hypernymGoods, isABPrice, oneClickPayAddBad, trendWordId, trendProductSelectId, trendShopCode, addCartBtnType, recommendInfo, lookType, lookName, realLeftStock, maskLayer, realtimePosition, realtimeFromCateId, realtimeFromGoodsId, behaviorInfo, isRefresh, goodsRank, divideTime, isPlayingVideo, hasVideo })
  // addProduct = getGaEcommerceCode({ isModule, index, spu, sku, catId, price })

  // 设置sa
  let saInfo = getSaInfo({ triggerType: 'click', rankingFrom, rankingContentId, rankingCarriersubtype, boardGenerateType, activityFrom, isModule, isFault, style, traceId, dimension, saAbt, goodsList, cateIndex, cateId, cateName, tabList, refresh, location, srcModule, srcIdentifier, reviewLocation, tab, similarFrom, primeTp, type, time, activity_id, isAddMore, pageView, feed_type, tsp_abtest, actionId, query_ts, hasDivideTimeRec: divideTime === 'recommend' })
  // 设置sa traceid
  setSaTraceId({ goodsId, traceId, isModule })
  sa = {
    ...sa,
    ...saInfo,
    ...sa.name ? { name: sa.name } : {},  // 优先使用写死的name
  }
  if (typeof extraData === 'object') {
    sa.param = {
      ...(sa?.param || {}),
      ...extraData
    }
  }
  typeof extendsHandler === 'function' && extendsHandler(sa)

  // 设置ga
  // let gaInfo = getGaInfo({ triggerType: 'click', code, list: gaImpList, addProduct, isModule })
  // ga = {
  //   ...ga,
  //   ...gaInfo
  // }
  let delAbtestEveName = ['click_recently_viewed']
  if(delAbtestEveName.includes(sa.name)) {
    delete sa.param?.abtest
  }
  report({
    ...{ sa },
  })
}

function exposeItemFindSimilar({ report }, { goods_id, activity_from, isSoldOut }) {
  const sa = {
    name: 'expose_findsimilar',
    param: {
      goods_id: '',
      activity_from: '',
      similar_from: '',
      style: 'popup'
    }
  }
  // sa
  sa.param.goods_id = goods_id
  sa.param.activity_from = activity_from
  sa.param.similar_from = isSoldOut ? 'out_of_stock' : 'other'
  sa.param.item_sold_out = Number(isSoldOut)
  sa.param.findsimilar_method = isSoldOut ? 'SoldOut' : 'ClickMore'
  report({ sa, ga })
}

/**
 * 商品曝光触发的埋点上报函数
 * @param {*} param0
 * @param {*} param1
 * @returns
 */
const exposeItemHandler = ({ sa, report }, { target = null } = {}, extendsHandler) => {
  if (!target || !Array.isArray(target) || target.length <= 0) return
  // 获取最近一个类名为含有j-da-event-box的祖先
  let { rankingFrom, rankingContentId, rankingCarriersubtype, boardGenerateType, poskey, isFault, style, traceId, dimension, activityFrom, isModule, pageNum, request_ext, cateIndex, cateId, cateName, tabList, refresh, location, srcModule, srcIdentifier, reviewLocation, tab, similarFrom, primeTp, type, time, activity_id, isAddMore, parentGoodsId, feed_type, series_no, tsp_abtest, query_ts } = getAttrFromContainer(target[0])
  // abt信息
  let { saAbt = '' } = getAnalysisAbtInfo(poskey)

  // 电商代码的list信息
  // let gaImpList = getGaList({ isModule, code, title, isFault, dataType, gaAbt, tagIds, attrStr, cat, minPri, maxPri })

  let goodsList = [] // sa
  // addImpressionList = [] // ga

  let hasDivideTimeRec = ''

  target.forEach((targetDom) => {
    // item 本身取的数据
    let { historicalPrice, followPrice, locateLabels, discountSpecial, discountTabs, visitorLabel, labelCarousels, index, spu, sku, goodsId, price, originPrice, operId, recMark, extraMark, otherExtMark, otherDExtMark, extMarks, reducePrice, soldOutSign, soldoutMark, promotionInfo, exclusivePrice, lable, seriesOrBrand, sellingPointLabel, cccTspBadges, brand = '', videoIcon, saleAttr, spuImg, mallCode, storeCode, priceCut, promotionId, typeId, brandCode, quickShip, bestDeal, followLabel, promoLabel, userBehaviorLabel, userPreferencesLabel, userCommentKeywordsLabel, showNewuseronlyPriceLabel, beltLabel, rankingList, decisionAttr, findSimilarMode, estimatedPriceInfo, showLabelHot, labelLooping, serviceLabel, titleLabel, horseRaceLabel, rrpPrice, discountCountDown, unitPrice, extraTag, salesLabel, saleCnt, showSellerLimitedLabel, hypernymGoods, isABPrice, oneClickPayAddBad, trendWordId, trendProductSelectId, trendShopCode, addCartBtnType, recommendInfo, lookType, lookName, realLeftStock, maskLayer, realtimePosition, realtimeFromCateId, realtimeFromGoodsId, behaviorInfo, isRefresh, goodsRank, divideTime, isPlayingVideo, hasVideo } = getAttrFromTarget(targetDom)
    goodsList.push(getSaGoodsList({ historicalPrice, visitorLabel, labelCarousels, followPrice, locateLabels, discountSpecial, discountTabs, goodsId, sku, spu, index, pageNum, request_ext, operId, recMark, extraMark, otherExtMark, otherDExtMark, extMarks, price, originPrice, reducePrice, soldOutSign, soldoutMark, promotionInfo, exclusivePrice, lable, seriesOrBrand, sellingPointLabel, cccTspBadges, videoIcon, saleAttr, spuImg, mallCode, storeCode, priceCut, promotionId, typeId, brand, brandCode, quickShip, bestDeal, followLabel, promoLabel, userBehaviorLabel, userPreferencesLabel, userCommentKeywordsLabel, showNewuseronlyPriceLabel, beltLabel, rankingList, decisionAttr, findSimilarMode, estimatedPriceInfo, showLabelHot, labelLooping, serviceLabel, titleLabel, horseRaceLabel, rrpPrice, discountCountDown, unitPrice, extraTag, salesLabel, saleCnt, showSellerLimitedLabel, hypernymGoods, isABPrice, oneClickPayAddBad, trendWordId, trendProductSelectId, trendShopCode, addCartBtnType, recommendInfo, lookType, lookName, realLeftStock, maskLayer, realtimePosition, realtimeFromCateId, realtimeFromGoodsId, behaviorInfo, goodsRank, isRefresh, isPlayingVideo, hasVideo }))
    // addImpressionList.push(getGaEcommerceCode({ isModule, index, spu, sku, catId, price, list: gaImpList }))
    if (divideTime === 'recommend') {
      hasDivideTimeRec = true
    }
    if (['wishlist', 'collection_boards', 'page_select_items'].includes(activityFrom) && targetDom.querySelector('.j-item-find-similar')) { // 接入场景：收藏
      exposeItemFindSimilar({ report }, { goods_id: goodsId, activity_from: activityFrom, isSoldOut: soldOutSign })
    }
  })
  

  // 设置sa
  let saInfo = getSaInfo({ triggerType: 'expose', rankingFrom, rankingContentId, rankingCarriersubtype, boardGenerateType, activityFrom, parentGoodsId, isModule, isFault, style, traceId, dimension, saAbt, goodsList: goodsList.join(','), cateIndex, cateId, cateName, tabList, refresh, location, srcModule, srcIdentifier, reviewLocation, tab, similarFrom, primeTp, type, time, activity_id, isAddMore, feed_type, series_no, tsp_abtest, query_ts, hasDivideTimeRec })
  sa = {
    ...sa,
    ...saInfo,
    ...sa.name ? { name: sa.name } : {},  // 优先使用写死的name
  }
  typeof extendsHandler === 'function' && extendsHandler(sa)

  // 设置ga
  // let gaInfo = getGaInfo({ triggerType: 'expose', code, list: gaImpList, addImpressionList, isModule })
  // ga = {
  //   ...ga,
  //   ...gaInfo
  // }

  // ignore ga: window.WEB_CLIENT == 'shein' ? null : ga
  report({
    ...{ sa },
  })
}

export default {
  '2-3-1': {
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      }
    },
    handler: clickItemHandler
  },
  // 商品资源位的曝光
  '2-3-2': {
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      }
    },
    handler: exposeItemHandler
  },
  // 点击展开蒙层
  '2-3-3': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_more',
      param: {
        goods_id: '',
        activity_from: '',
        item_sold_out: ''
      }
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      let goods_id = ''
      let activeFrom = ''
      let item_sold_out = ''

      if (extraData.goods_id) {
        goods_id = extraData.goods_id
        item_sold_out = extraData.item_sold_out
        activeFrom = extraData.activeFrom
      }

      sa.param.goods_id = goods_id
      sa.param.activity_from = activeFrom
      sa.param.item_sold_out = item_sold_out

      report({ sa })
    }
  },
  // 删除收藏夹里收藏的商品
  '2-3-4': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_delete_goods',
      param: {
        goods_id: '',
        activity_from: '',
        item_sold_out: ''
      }
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      const { notReportSa = false, activity_from, goods_id, item_sold_out } = extraData

      sa.param.goods_id = goods_id
      sa.param.activity_from = activity_from
      sa.param.item_sold_out = Number(!!item_sold_out)

      report({
        sa: notReportSa ? null : sa,
      })
    }
  },
  // 点击加车唤起快速加车弹窗
  '2-3-5': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_goods_list_addcar',
      param: null
    },
    // isLiseEmpty = true => goods_list为空
    handler({ sa, report }, { target, extraData = {} } = {}) {
      const { activityFrom, poskey, location, tabList, isFault, reviewLocation, rankingFrom, srcModule, srcIdentifier, srcTabPageId, isAddMore, feed_type, tsp_abtest,  parentGoodsId } = getAttrFromContainer(target)
      const { button_type = 'add_to_bag', isLiseEmpty = false, goodsListParams = '', review_location = '', tab_list = '', src_module: _srcModule = '', src_identifier: _srcIdentifier = '', src_tab_page_id: _srcTabPageId = '', interestpoints = '' } = extraData // 商详
      const targetInfo = {
        ...getAttrFromTarget(target),
        ...goodsListParams, // 商详的goodsList覆盖原Dom上，可能取数逻辑不同于商品项默认的曝光参数
      }
      const goodsList = isLiseEmpty ? '' : getSaGoodsList(targetInfo)

      // sa
      let { saAbt = '' } = getAnalysisAbtInfo(poskey)
      const params = {
        abtest: saAbt,
        goods_list: goodsList,
        style: extraData.style || 'popup',
        activity_from: activityFrom || extraData.activity_from,
        review_location: reviewLocation || review_location || location || '',
        fault_tolerant: isFault,
        src_module: _srcModule || srcModule || '',
        src_identifier: _srcIdentifier || srcIdentifier || '',
        src_tab_page_id: _srcTabPageId || srcTabPageId || '',
        ranking_from: rankingFrom || '',
        is_add_more: isAddMore,
        interestpoints,
      }
      parentGoodsId && activityFrom === 'buy_box' && (params.goods_id = parentGoodsId)
      // 可覆盖父节点location参数
      const _location = extraData.location || location
      if (_location) params.location = _location

      const tabListParams = tab_list || tabList
      if (tabListParams) params.tab_list = tabListParams

      if (targetInfo.oneClickPayAddBad) {
        params.button_type = 'one_tap_pay'
      } else if (button_type) {
        params.button_type = button_type
      }
      if (extraData.threshold) {
        params.threshold = extraData.threshold
      }
      if (feed_type) {
        params.feed_type = feed_type
      }
      if (tsp_abtest) {
        params.abtest = tsp_abtest  // 圈品实验上报
      }

      sa.param = params
      report({ sa })
    }
  },
  '2-3-7': {
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      }
    },
    handler({ sa, report }, { target = null, extraData = {} } = {}) {
      if(target) {
        const box = $(target).closest('.j-da-event-box')[0]
        const saReportName = box.getAttribute('da-sa-name')
        // @example click_saved
        saReportName && (sa.name = `click_${saReportName}`)
      }
      clickItemHandler({ sa, report }, { target, extraData })
    }
  },
  // 商品资源位的曝光
  '2-3-8': {
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      }
    },
    handler({ sa, report }, { target = null, extraData = {} } = {}) {
      if(target && target[0]) {
        const box = $(target[0]).closest('.j-da-event-box')[0]
        const saReportName = box.getAttribute('da-sa-name')
        saReportName && (sa.name = `expose_${saReportName}`)
      }
      exposeItemHandler({ sa, report }, { target, extraData })
    }
  },
  // 负反馈弹窗展示
  '2-3-9': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_popup_negative_feedback',
      param: {
        goods_id: '',
        activity_param: '',
      }
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      sa.param = extraData
      report({ sa })
    }
  },
  // 点击负反馈选项
  '2-3-10': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_popup_negative_feedback',
      param: {
        goods_id: '',
        feedback_type: '',
      }
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      sa.param = extraData
      report({ sa })
    }
  },
  // 点击负反馈选项
  '2-3-11': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_findsimilar',
      param: {
        goods_id: ''
      }
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      sa.param = extraData
      report({ sa })
    }
  },
  // 点击负反馈选项
  '2-3-12': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_findsimilar',
      param: {
        goods_id: ''
      }
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      sa = JSON.parse(JSON.stringify(sa))
      sa.param = extraData
      report({ sa })
    }
  },
  '2-3-13': { // 找相似商品点击
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      }
    },
    handler({ sa, report }, { target = null, extraData = {} } = {}) {
      const extendsHandler = (saObj) => {
        if (saObj && saObj.param) {
          let similarFrom = 'other'
          if (target) {
            const similarScrollContainer = $(target).closest('.j-expose__similar-scroll-container')
            similarScrollContainer && (similarFrom = similarScrollContainer.attr('similar-from'))
          }

          saObj.param.similar_from = similarFrom
          saObj.param.page_from = window.SaPageInfo?.page_name || 'other'
        }
      }
      clickItemHandler({ sa, report }, { target, extraData }, extendsHandler)
    }
  },
  '2-3-14': { // 找相似商品曝光
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      }
    },
    handler({ sa, report }, { target = null, extraData = {} } = {}) {
      const extendsHandler = (saObj) => {
        if (saObj && saObj.param) {
          let similarFrom = 'other'
          if (target && target.length) {
            const similarScrollContainer = $(target[0]).closest('.j-expose__similar-scroll-container')
            similarScrollContainer && (similarFrom = similarScrollContainer.attr('similar-from'))
          }

          saObj.param.similar_from = similarFrom
          saObj.param.page_from = window.SaPageInfo?.page_name || 'other'
        }
      }
      exposeItemHandler({ sa, report }, { target, extraData }, extendsHandler)
    }
  },
  // 兼容指令式曝光
  // 指令式绑定在商品外层，曝光信息在元素内层
  // 需要额外加逻辑获取元素
  '2-3-15': {
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: '',
        page_view: ''
      }
    },
    handler({ sa, report }, { target = [], bindData = [], extraData = {} }) {
      const { name, page_view } = { ...(bindData?.[0] || {}), ...extraData }
      if (target.length > 0) {
        // 适配一行两图三图，直接拿item
        // 一行一图获取product-item-ctn元素
        const towMoreItem = target[0].getAttribute('data-goods_id') || target[0].getAttribute('data-id')

        const targets = towMoreItem ? target : target.reduce((arr, item) => {
          const el = item.querySelector('.product-item-ctn')
          el && arr.push(el)
          return arr
        }, [])
        
        exposeItemHandler({ sa, report }, { target: targets }, (sa) => {
          if (page_view) {
            sa.param = {
              ...sa.param,
              page_view
            }
          }
          if (name) {
            // @example expose_saved
            sa.name = name.includes('expose_') ? name : 'expose_' + name
            // abtest下线 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1173659210
            if (sa.name === 'expose_saved') {
              const page_list = [
                'page_goods_detail', // 商品详情页
                'page_picked', // 精选页
                'page_real_class', // 真实分类页
                'page_select_class', // 选品分类页
                'page_store', // 店铺页
                'page_search'
              ]
              const curPageName = window?.SaPageInfo?.page_name
              if (page_list.includes(curPageName)) {
                sa.param = {
                  ...sa.param
                }
                delete sa.param.abtest
              }
            }
          }
        }) 
      }
    }
  },
  // find similar按钮曝光
  '2-3-16': {
    sendType: ['event', 'user'],
    handler({ report }, { extraData = {} } = {}) {
      const { goods_id, activity_from, isSoldOut } = extraData
      exposeItemFindSimilar({ report }, { goods_id, activity_from, isSoldOut })
    }
  },
  // 点击分布式筛选词
  '2-3-17': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_distributed_filter',
      param: {
        distributed_filter: ''
      }
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      const { itemIndex, filterIndex, dataSource, id } = extraData 
      sa.param.distributed_filter = [itemIndex, filterIndex, dataSource, id].join('`')
      report({ sa })
    }
  },
  // 曝光分布式筛选词
  '2-3-18': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_distributed_filter',
      param: {
        distributed_filter: ''
      }
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      const { searchFilter, target } = extraData
      const targetInfo = getAttrFromTarget(target)
      const goodsList = getSaGoodsList(targetInfo)
      
      sa.param.distributed_filter = searchFilter
      sa.param.goods_list = goodsList
      report({ sa })
    }
  },
  // 曝光实时反馈弹窗标题
  '2-3-19': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_feedback_title',
      param: {
        activity_from: '',
        src_module: '',
        src_identifier: ''
      }
    },
    handler({ sa, report }, { target }) {
      let { activityFrom, srcModule, srcIdentifier } = getAttrFromContainer(target)

      sa.param.activity_from = activityFrom
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier
      report({ sa })
    }
  },
  // 点击实时反馈弹窗标题
  '2-3-20': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_feedback_title',
      param: {
        activity_from: '',
        src_module: '',
        src_identifier: ''
      }
    },
    handler({ sa, report }, { target }) {
      let { activityFrom, srcModule, srcIdentifier } = getAttrFromContainer(target)

      sa.param.activity_from = activityFrom
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier
      report({ sa })
    }
  },
  // 曝光实时反馈弹窗view more按钮
  '2-3-21': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_feedback_view_more',
      param: {
        activity_from: '',
        src_module: '',
        src_identifier: ''
      }
    },
    handler({ sa, report }, { target }) {
      let { activityFrom, srcModule, srcIdentifier } = getAttrFromContainer(target)

      sa.param.activity_from = activityFrom
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier
      report({ sa })
    }
  },
  // 点击实时反馈弹窗view more按钮
  '2-3-22': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_feedback_view_more',
      param: {
        activity_from: '',
        src_module: '',
        src_identifier: ''
      }
    },
    handler({ sa, report }, { target }) {
      let { activityFrom, srcModule, srcIdentifier } = getAttrFromContainer(target)

      sa.param.activity_from = activityFrom
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier
      report({ sa })
    }
  },
  // 曝光实时反馈弹窗关闭icon
  '2-3-23': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_feedback_close',
      param: {
        activity_from: '',
        goods_id: '',
        src_module: '',
        src_identifier: '',
      }
    },
    handler({ sa, report }, { extraData }) {
      let { activityFrom, goodsId, srcModule, srcIdentifier } = extraData
      sa.param.activity_from = activityFrom
      sa.param.goods_id = goodsId
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier
      report({ sa })
    }
  },
  // 点击实时反馈弹窗关闭icon
  '2-3-24': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_feedback_close',
      param: {
        activity_from: '',
        goods_id: '',
        src_module: '',
        src_identifier: '',
      }
    },
    handler({ sa, report }, { extraData }) {
      let { activityFrom, goodsId, srcModule, srcIdentifier } = extraData

      sa.param.activity_from = activityFrom
      sa.param.goods_id = goodsId
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier

      report({ sa })
    }
  },
  // 营销人群差异化承接页钩子商品曝光
  '2-3-25': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_goods_list',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: 'goods_list',
        style: '',
        traceid: ''
      }
    },
    handler({ sa, report }, { target = null, extraData = {} } = {}) {
      const extendsHandler = (sa) => {
        if (sa?.param) {
          const { goods_list, traceid, style } = sa.param
          
          sa.param = {
            activity_from: 'goods_list',
            goods_list,
            style,
            traceid,
          }
        }
      }
      exposeItemHandler({ sa, report }, { target, extraData }, extendsHandler)
    }
  },
  // 营销人群差异化承接页钩子商品点击
  '2-3-26': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_goods_list',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: 'goods_list',
        style: '',
        traceid: ''
      }
    },
    handler ({ sa, report }, { target = null, extraData = {} } = {}) {
      const extendsHandler = (sa) => {
        if (sa?.param) {
          const { goods_list, traceid, style } = sa.param
          
          sa.param = {
            activity_from: 'goods_list',
            goods_list,
            style,
            traceid,
          }
        }
      }
      clickItemHandler({ sa, report }, { target, extraData }, extendsHandler)
    }
  },
  // 商品点击后曝光搜索推荐词
  '2-3-29': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_goods_search',
      param: {
        goods_id: '',
        goods_pos: '',
        rcmd_word: '',
        user_query: ''
      }
    },
    handler({ sa, report }, { extraData }) {
      let { goodsId, goodsPos, rcmdWord, userQuery } = extraData

      sa.param = {
        goods_id: goodsId,
        goods_pos: goodsPos,
        rcmd_word: rcmdWord,
        user_query: userQuery
      }

      report({ sa })
    }
  },
  // 商品点击后推荐词点击	
  '2-3-28': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_goods_search',
      param: {
        goods_id: '',
        goods_pos: '',
        rcmd_word: '',
        user_query: ''
      }
    },
    handler({ sa, report }, { extraData }) {
      let { goodsId, goodsPos, rcmdWord, userQuery } = extraData

      sa.param = {
        goods_id: goodsId,
        goods_pos: goodsPos,
        rcmd_word: rcmdWord,
        user_query: userQuery
      }

      report({ sa })
    }
  },
  // 点击找相似弹窗
  '2-3-30': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_findsimilar',
      param: {
        goods_id: ''
      }
    },
    handler({ sa, report }, { bindData = {} } = {}) {
      sa.param = bindData
      report({ sa })
    }
  },
  // 找相似弹窗曝光
  '2-3-31': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_findsimilar',
      param: {
        goods_id: ''
      }
    },
    handler({ sa, report }, { bindData = [] } = {}) {
      const params = bindData?.[0] || {}
      sa.param = params
      report({ sa })
    }
  },
  // 商铺入口曝光
  '2-3-34': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_store_name',
      param: {
        store_code: '',
        goods_id: '',
      }
    },
    handler({ sa, report }, { bindData = [] } = {}) {
      const storeCode = bindData?.[0]?.storeCode
      const goodsId = bindData?.[0]?.goodsId
      sa.param.store_code = storeCode
      sa.param.goods_id = goodsId
      report({ sa })
    }
  },
  // 商铺入口点击
  '2-3-35': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_store_name',
      param: {
        store_code: '',
        goods_id: '',
      }
    },
    handler({ sa, report }, { bindData = null } = {}) {
      const storeCode = bindData?.storeCode
      const goodsId = bindData?.goodsId
      sa.param.store_code = storeCode
      sa.param.goods_id = goodsId
      report({ sa })
    }
  },
  // 点击找相似弹窗 -- 同类推荐 TR17442
  '2-3-32': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_similar_added',
      param: {
        goods_id: ''
      }
    },
    handler({ sa, report }, { bindData = [] } = {}) {
      const params = bindData || {}
      sa.param = params
      report({ sa })
    }
  },
  // 找相似弹窗曝光 -- 同类推荐 TR17442
  '2-3-33': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_similar_added',
      param: {
        goods_id: ''
      }
    },
    handler({ sa, report }, { bindData = [] } = {}) {
      const params = bindData?.[0] || {}
      sa.param = params
      report({ sa })
    }
  },
  // 收藏页降价置顶view_more曝光事件
  '2-3-36': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_reduced_price_added',
      param: {}
    },
    handler({ sa, report }, { } = {}) {
      report({ sa })
    }
  },
  // 收藏页降价置顶view_more点击事件
  '2-3-37': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_reduced_price_added',
      param: {
        goods_id: ''
      }
    },
    handler({ sa, report }, { bindData = [] } = {}) {
      const params = bindData?.[0] || {}
      sa.param = params
      report({ sa })
    }
  },
  // 点击榜单标签跳转榜单
  '2-3-38': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_ranking_list_label',
    },
    handler({ sa, report }, { bindData = {} }) {
      sa.param = bindData
      report({ sa })
    }
  },
  /**
   * 商品点击勾选/取消按钮上报 允许透传字段，新组合购场景
   */
  '2-3-39': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_select_goods',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      }
    },
    handler ({ sa, report }, { target = null, extraData = {} } = {}) {

      const extendsHandler = (sa) => {
        sa.param = {
          ...(sa?.param || {}),
          ...extraData
        }
      }
      clickItemHandler({ sa, report }, { target, extraData }, extendsHandler)
    }
  },
  // 大图加车, 参见wiki：pageId=1305103382
  '2-3-41': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_goods_list_addcar',
      param: {
        activity_from: '',
        src_module: '',
        src_identifier: '',
      }
    },
    handler({ sa, report }, { extraData }) {
      let { activityFrom, srcModule, srcIdentifier } = extraData
      sa.param.activity_from = activityFrom
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier
      report({ sa })
    }
  },
  '2-3-42': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_list_sorting_tag',
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      sa.param = {
        ...(sa?.param || {}),
        ...extraData
      }
      report({ sa })
    },
  },
  // 点击末级类目词反馈弹窗关闭icon
  '2-3-43': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_goods_category_close',
      param: {
        src_module: '',
        src_identifier: '',
      }
    },
    handler({ sa, report }, { extraData }) {
      let { srcModule, srcIdentifier } = extraData
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier

      report({ sa })
    }
  },
  // 末级类目词反馈弹窗曝光
  '2-3-44': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_goods_category',
      param: {
        src_module: '',
        src_identifier: '',
      }
    },
    handler({ sa, report }, { extraData }) {
      let { srcModule, srcIdentifier } = extraData
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier

      report({ sa })
    }
  },
  // 末级类目词反馈弹窗类目组件点击
  '2-3-45': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_goods_category',
      param: {
        src_module: '',
        src_identifier: '',
      }
    },
    handler({ sa, report }, { extraData }) {
      let { srcModule, srcIdentifier } = extraData
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier

      report({ sa })
    }
  },
  // buy box 曝光
  '2-3-46': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_buy_box_block',
      param: {
        buy_box_style: 2,
      }
    },
    handler ({ sa, report }, { extraData = {} } = {}) {
      sa.param = extraData
      report({ sa })
    }
  },
  // buy box 点击
  '2-3-47': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_buy_box_block',
      param: {
        buy_box_style: 2,
      }
    },
    handler ({ sa, report }, { extraData = {} } = {}) {
      sa.param = extraData
      report({ sa })
    }
  },
  // ! 不上报了
  // // 趋势词曝光
  // '2-3-48': {
  //   sendType: ['event', 'user'],
  //   sa: {
  //     name: 'expose_trend_tag',
  //     param: {
  //       trend_label: '',
  //     }
  //   },
  //   handler({ sa, report }, { bindData = [] }) {
  //     const extraData = bindData?.[0]?.getExtraData()
  //     sa.param.goods_id = extraData?.goodsId
  //     sa.param.page_number = extraData?.pageNumber
  //     sa.param.product_select_id = extraData?.product_select_id
  //     sa.param.result_order = extraData?.resultOrder
  //     if(extraData?.trend_word_id !== '-'){
  //       sa.param.src_identifier = `trend=${extraData?.trend_word_id}`
  //     }else{
  //       sa.param.src_identifier = `store=${extraData?.trend_shop_code}`
  //     }
  //     sa.param.src_module = 'top_trend'
  //     sa.param.trend_word_id = extraData?.trend_word_id
  //     sa.param.trend_shop_code = extraData.trend_shop_code
  //     report({ sa })
  //   }
  // },
  // 趋势词标签点击
  '2-3-49': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_trend_tag',
      param: {
        goods_id: '',
      }
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      sa.param.goods_id = extraData?.goodsId
      sa.param.page_number = extraData?.pageNumber
      sa.param.product_select_id = extraData?.product_select_id
      sa.param.result_order = extraData?.resultOrder
      if(extraData?.trend_word_id !== '-'){
        sa.param.src_identifier = `trend=${extraData?.trend_word_id}`
      }else{
        sa.param.src_identifier = `store=${extraData?.trend_shop_code}`
      }
      sa.param.src_module = extraData?.src_module || ''
      sa.param.trend_word_id = extraData?.trend_word_id
      sa.param.trend_shop_code = extraData.trend_shop_code
      report({ sa })
    }
  },
  // 搜索词推荐反馈类型关闭按钮曝光
  '2-3-50': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_goods_search_close',
      param: {
        src_module: '',
        src_identifier: '',
      }
    },
    handler({ sa, report }, { extraData }) {
      let { srcModule, srcIdentifier } = extraData
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier

      report({ sa })
    }
  },
  // 搜索词推荐反馈类型关闭按钮点击
  '2-3-51': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_goods_search_close',
      param: {
        src_module: '',
        src_identifier: '',
      }
    },
    handler({ sa, report }, { extraData }) {
      let { srcModule, srcIdentifier } = extraData
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier

      report({ sa })
    }
  },
  // 搜索词推荐反馈类型曝光
  '2-3-52': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_goods_search',
      param: {
        src_module: '',
        src_identifier: '',
      }
    },
    handler({ sa, report }, { target }) {
      let { srcModule, srcIdentifier } = getAttrFromContainer(target)
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier

      report({ sa })
    }
  },
  // 搜索词推荐反馈类型点击	
  '2-3-53': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_goods_search',
      param: {
        src_module: '',
        src_identifier: '',
      }
    },
    handler ({ sa, report }, { extraData = {} } = {}) {
      // sa.param = extraData
      // hack code, 不知道为啥这里会触发两次，所以用extraData.srcModule来过滤多的一次
      if(!extraData?.srcModule) {
        return
      }
      let { srcModule, srcIdentifier } = extraData
      sa.param.src_module = srcModule
      sa.param.src_identifier = srcIdentifier
      report({ sa })
    }
  },
  // wiki: pageId=1492946922, 点后推需求新增的
  // 点击搜索词点推关闭按钮
  '2-3-54': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_after_search_close',
      param: {
        after_card_info: '', // 坑位'组件内曝光的商品ID'商品类目ID '搜索词（多个枚举值用英文,隔开）
        source_goods_id: '', // 点后推来源的坑位商品id
      }
    },
    handler ({ sa, report }, { extraData = {} } = {}) {
      let { after_card_info, source_goods_id } = extraData
      sa.param.after_card_info = after_card_info
      sa.param.source_goods_id = source_goods_id
      report({ sa })
    }
  },
  // 点击搜索词点推（// wiki: pageId=1492946922, 点后推需求新增的）
  '2-3-55': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_top_site_search',
      param: {
        abtest: '',
        result_content: '',
        search_content: '',
        trace_id: '',
        after_card_info: '', // 坑位'组件内曝光的商品ID'商品类目ID '搜索词（多个枚举值用英文,隔开）
        source_goods_id: '', // 点后推来源的坑位商品id
      }
    },
    handler ({ sa, report }, { extraData = {} } = {}) {
      let { after_card_info, source_goods_id, abtest, result_content, search_content, trace_id } = extraData
      sa.param.abtest = abtest
      sa.param.result_content = result_content
      sa.param.search_content = search_content
      sa.param.trace_id = trace_id
      sa.param.after_card_info = after_card_info
      sa.param.source_goods_id = source_goods_id
      report({ sa })
    }
  },
  // 搜索词点推曝光（// wiki: pageId=1492946922, 点后推需求新增的）
  '2-3-56': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_top_site_search',
      param: {
        abtest: '',
        result_content: '',
        search_content: '',
        trace_id: '',
        after_card_info: '', // 坑位'组件内曝光的商品ID'商品类目ID '搜索词（多个枚举值用英文,隔开）
        source_goods_id: '', // 点后推来源的坑位商品id
      }
    },
    handler ({ sa, report }, { extraData = {} } = {}) {
      let { after_card_info, source_goods_id, abtest, result_content, search_content, trace_id } = extraData
      sa.param.abtest = abtest
      sa.param.result_content = result_content
      sa.param.search_content = search_content
      sa.param.trace_id = trace_id
      sa.param.after_card_info = after_card_info
      sa.param.source_goods_id = source_goods_id
      report({ sa })
    }
  },

  // 商卡视频的曝光
  '2-3-57': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_playing_video',
      param: {
        goods_info: '', // goods_id`skc`spu_id`坑位
        info_flow_details: '', // 信息流内容体详情。当前包含内容体id（scene_id）
      }
    },
    handler ({ sa, report }, { extraData = {} } = {}) {
      const index = extraData.index || 0
      let { contentCarrierId, goods_id, goods_sn, productRelationID } = extraData.itemInfos || {}
      let position = index + 1
      sa.param = {
        goods_info: [goods_id, goods_sn, productRelationID, position, ].join('`'),
        info_flow_details: contentCarrierId || '-',
      }
      report({ sa })
    }
  },
}
