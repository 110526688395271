import type { LowStockLabel } from '../../../../types/mobile/index.ts'

export function getProps({ data }):LowStockLabel.Props {
  const info = data.value.aggregateProductBusiness?.productTips?.find(tag => tag.type === 'lowStock') || null
  return {
    type: '',
    isShow: !!info,
    isShowMask: false,
    icon: info?.view?.prefixIcon || '',
    text: info?.view?.text || '',
    subText: '',
    bgColor: info?.view?.bgColor || '',
    textColor: info?.view?.textColor || '#FA6338',
    replayTrigger: 0,
  }
}
