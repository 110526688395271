<template>
  <div class="credit-list">
    <swiper-container
      ref="creditSwiperRef"
      class="credit-list__swiper"
      init="false"
      destroy-on-disconnected="false"
    >
      <swiper-slide
        v-for="(creditItem, index) in returnCredit.creditList"
        :key="index"
        class="credit-list__slide"
      >
        <CreditIcon
          class="credit-list__icon"
          :reward-item="{ returnCredit: creditItem }"
        />
      </swiper-slide>
    </swiper-container>
    <span
      v-if="returnCredit.lockTip"
      class="credit-list__tips"
    >
      <img
        class="credit-list__tips-lock"
        src="https://img.ltwebstatic.com/images3_ccc/2024/11/19/06/17320006954e1d44f9bf66cb7bf57aba6dc0f4bb6a.webp"
      />
      <span class="credit-list__tips-text">
        {{ returnCredit.lockTip }}
      </span>
    </span>
  </div>
</template>
<script name="CreditList" setup lang="ts">
import { type Ref, ref, onMounted } from 'vue'
import { register } from 'swiper/element'
import CreditIcon from './CreditIcon.vue'
import type { C_BenefitFloor } from '../../../../types'

typeof window !== 'undefined' && register()

defineProps<{
  returnCredit: C_BenefitFloor.PopUpReturnCredit
}>()

const creditSwiperRef: Ref = ref(null)
const initSwiper = () => {
  if (typeof window === 'undefined') return
  const swiper = creditSwiperRef.value
  if (!swiper) return
  const swiperParams = {
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    allowTouchMove: true,
    enabled: true,
    spaceBetween: 12,
    observeParents: true,
    observeSlideChildren: true,
    observer: true,
  }
  Object.assign(swiper, swiperParams)
  swiper.initialize()
}

onMounted(() => {
  initSwiper()
})
</script>
<style lang="less" scoped>
.credit-list {

  &__slide {
    width: auto;
  }

  &__tips {
    display: inline-flex;
    align-items: center;
    border-radius: 200/75rem;
    background: rgba(142, 205, 167, 0.10);
    padding: 8/75rem 20/75rem;
    margin: 14/75rem 20/75rem 0 0;
    line-height: 1.2;
  }

  &__tips-lock {
    width: 20/75rem;
    height: 26/75rem;
    margin-right: 12/75rem;
  }

  &__tips-text {
    color: #2B4B38;
    font-size: 12px;
  }
}
</style>
