/**
 * 标签根据ccc配置排序&展示/隐藏
 * @param {*} param0 
 * @returns 
 */
export function handleCloudTagsByCccConfig ({
  cloudTags = [],
  productTypeSortConfig = [],
  filterType = 'isExposed'
}) {
  if (!cloudTags.length) return []

  // 防止后端返回数据排序变化，前端根据tagid排序
  const toNumber = (str = '') => Number((String(str).match(/\d+/g) ?? [])?.map(Number).join(''))
  const sortCloudTags = cloudTags.sort((tag1, tag2) => {
    if (tag1.tag_type !== tag2.tag_type) return false

    return toNumber(tag1.tag_id || tag1.value) - toNumber(tag2.tag_id || tag2.value)
  })
  
  if (!productTypeSortConfig.length) return sortCloudTags

  // 标签云外露接ccc配置
  const map = {}
  const list = []
  sortCloudTags.forEach((item) => (map[item.tag_id] = item))

  // 兼容ccc无样式配置
  const addCccConfigMark = (obj, cccConfig) => {
    cccConfig.displayContent && (obj.cccConfig = cccConfig)
    if (obj?.tag_id === 'quickship' && obj.quickShipText) {
      obj.label = obj.quickShipText
    }
    // 处理多语言
    if ([1, 2].includes(obj.type)) return obj
    
    const label = cccConfig?.displayContent?.labelLang || obj.label || obj.tag_name || cccConfig?.tagName
    obj.label = label
    
    return obj
  }

  productTypeSortConfig
    .filter(item => Number(item[filterType]))
    .forEach(item => {
      switch(item.tagType) {
        // mall
        case 11:
          list.push(...sortCloudTags.filter(obj => obj.type == 1).map(obj => addCccConfigMark(obj, item)))
          break
        // qs
        case 12:
          list.push(...sortCloudTags.filter(obj => obj.type == 2).map(obj => addCccConfigMark(obj, item)))
          break
        // 促销
        case 13:
          list.push(...sortCloudTags.filter(obj => obj.tag_type == 100).map(obj => addCccConfigMark(obj, item)))
          break
        //  其他标签
        default:
          item.tagId && map[item.tagId] && list.push(addCccConfigMark(map[item.tagId], item))
          break
      }
    })

  return list
}

/**
 * 是否优选卖家
 * @param {*} tagId 
 * @returns 
 */
export function isPreferredSeller(tagId, abtResult) {
  return tagId == '60006881' && abtResult?.AllListPreferredSeller?.p == 'type=B'
}

/**
 * 过滤展示的cloudTags
 * item.type:
 *   0:原标签
 *   1:mall
 *   2:quickship
 * item.tag_type: 
 *   4: 推荐 
 *   6: 人工 
 *   7:功能标签 
 *   100:促销标签
 */
export function cloudTagsFilterInner (item, abtResult) {
  if (Number(item.type) !== 0) return true
  
  return item.tag_type == 6
  || item.tag_type == 7
  || item.tag_type == 100
    || isPreferredSeller(item.tag_id, abtResult)
}

/**
* 存在属性图文导航时，将该属性过滤
*/
export function attrFilterByPicTopNav (attrs, picTopNav) {
  if (picTopNav.length < 2) return attrs

  const attrIds = picTopNav.reduce((ids, item) => {
    item.tabType == 2 && ids.push(item.tabId)

    return ids
  }, [])

  return attrs.filter(item => !attrIds.includes(item.id))
}

// 批量将ref对象转为普通对象
export function unrefs (obj) {
  const newObj = {}
  Object.keys(obj).forEach(key => {
    const target = obj[key]
    newObj[key] = target?.__v_isRef && target?.hasOwnProperty('value')
      ? target.value
      : target
  }) 

  return newObj
}

export function sleep (time, loopCb) {
  return new Promise(resolve => {
    const start = Date.now()
    const loop = () => requestAnimationFrame(() => { 
      if (Date.now() - start > time) return resolve()
      loopCb && loopCb()
      loop()
    })
    loop()
  })
}

export function getIsLock () {
  if (typeof window === 'undefined') return false

  let bodyTop = -parseInt($('body').css('top'))
  const lock = !!bodyTop
  
  return lock
}

// 根据当前视口宽度获取实际px
export function getRealPx (px) {
  if (typeof window === 'undefined') return px
  const fontSize = parseFloat(document?.documentElement?.style?.fontSize || 0)
  // 优先取html上的fontSize，优化重排
  const clientWidth = fontSize ? fontSize * 10 : document.body.clientWidth
  return px / 375 * clientWidth
}

// 侧边筛选和标签云筛选面板 的source信息
export function getSourceInsideFilterOrCloudTagPanel({ sourceFrom, active }) {
  const isSideFilter = sourceFrom === 'sideFilter'
  const isCloudTagPanel = sourceFrom === 'cloudTagPanel'

  let sourceInfo = {}
  const source = isSideFilter 
    ? 'insideFilter' 
    : isCloudTagPanel 
      ? 'cloudTagPanel' 
      : ''
  if (source) {
    sourceInfo = { source, source_status: active ? 1 : 0 }
  }

  return sourceInfo
}

// 过滤标签，未返回ccc多语言，则过滤【qs/mall/sale除外】【融合后，过滤由bff中间层处理，可删除，web中间层筛选时，未请求ccc配置，故无法做过滤】
/**
 * 过滤标签，未返回ccc多语言
 * qs/mall/sale除外
 * web中间层筛选时，未请求ccc配置，故无法做过滤,融合后，过滤由bff中间层处理，可删除，
 * @param {*} cloudTags 
 * @returns 
 */
export function cloudTagsFilterByCccLanguage(cloudTags) { 
  return cloudTags.filter(tag => {
    if (+tag.type === 1 || +tag.type === 2 || tag.is_sale_tag) return true
    return !!tag?.cccConfig?.displayContent?.labelLang
  })
}

// split异常
export function safeSplit(str = '', symbol = '-') {
  if (typeof str !== 'string' || !str) return []

  return str?.split(symbol) ?? []
}
