import { cartTagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'


/**
 * 加车弹窗同步购物车数据插件(全局使用)
 * @description 用于同步购物车数据
 * @description 购物车场景下，走他们内部实现，不需要引入该插件
 */
export const syncCartInfoPlugin = () => {

  return {
    name: 'syncCartInfo',
    callbacks: {
      onAddCartFinished: (data) => {
        if (data?.isSuccess) {
          // 更新购物车数量（保证悬浮购物车能拿到最新购物车数量）
          window?._gb_app_?.$store?.commit('changeCartNum', data?.cartTotalQuantity, { root: true })
        }
      },
      onCartInfoReceived: ({ code = '', info = {} }) => {
        if (code === '0' && info?.cartSumQuantity) {
          // 更新旧购物车
          window?._gb_app_?.$store?.commit(
            'changeCartInfo',
            {
              sum: info?.cartSumQuantity || 0,
              carts: info.carts || [],
              info: info || {}
            },
            { root: true }
          )
          // 更新下沉购物车组件
          cartTagTip.update({
            cartInfo: info,
          }) 
        }
      },
    }
  }
}
