<script lang="jsx">
import { containerRatio } from 'public/src/pages/components/CropImageContainer/index.js'
import videojs from 'video.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { checkNetworkIsWifi, metricRatioSize, monitorVideoStuck, monitorVideoInitLoadTime } from './utils'
import { Loading as SLoading } from '@shein/sui-mobile'

export default {
  name: 'ProductCardAutoplayVideo',
  components: {
    SLoading,
  },
  inject: {
    labelsFromKey: {
      default: '',
    },
    constantData: {
      from: 'constantData',
      default: () => ({}),
    },
    item: {
      from: 'item',
      default: () => ({}),
    },
    setAttrForAnalysis: {
      default: () => {},
    },
    config: {
      default: () => {},
    },
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    mainImg: {
      type: String,
      default: '',
    },
    column: {
      type: [String, Number],
      default: 2,
    },
    // @example '1-1'
    cropRate: {
      type: String,
      default: '',
    },
    autoCropImage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      player: null,
      playingVideo: false,
      loadingVideo: false,
      hasReported: false, // 是否已经上报过曝光
      hasInit: false,
      loadEndVideo: false,
      hasReportedMetricRatioSize: false,
      stuckTimes: 0,
      initStartTime: 0,
    }
  },
  computed: {
    videoUrl() {
      return this.item?.video_infos?.[0]?.url
    },
    ratioSize() {
      const { mainImg, cropRate, autoCropImage } = this
      const videoInfo = this.item?.video_infos?.[0] ?? {}
      const { url, width, height } = videoInfo
      if (!url || !width || !height) {
        return 'noSize'
      }

      const fixedRatio = cropRate ? cropRate : autoCropImage ? '' : '3-4'

      // 主图尺寸比例
      const mainImgRatio = containerRatio(fixedRatio, mainImg)
      // 视频尺寸比例
      const videoRatio = (url && width && height && height / width) || 0
      if (mainImgRatio === videoRatio) {
        return 'consistent'
      }

      // 比例接近
      const recRatioNearly =
        mainImgRatio === 4 / 3 && videoRatio <= 1.5 && videoRatio >= 1.25
      const squareRatioNearly =
        mainImgRatio === 1 && videoRatio <= 1.167 && videoRatio >= 0.857
      const isRatioNearly = recRatioNearly || squareRatioNearly
      return isRatioNearly ? 'similar' : 'noSimilar'
    },
    showVideo() {
      const isRatioNearly = ['consistent', 'similar'].includes(this.ratioSize)
      return isRatioNearly
    },
  },
  watch: {
    $route(from, to) {
      if (from.fullPath !== to.fullPath) {
        this.hasReported = false
      }
    },
  },
  deactivated() {
    // 页面切换时，重新上报视频
    this.hasReported = false
  },
  mounted() {
    if (this.videoUrl) {
      this.setAttrForAnalysis(el => {
        el?.setAttribute('data-has-video', '1')
      })
      this.initVideoPlayer()
    }
  },
  methods: {
    playVideo() {
      this.player?.play()
      this.initStartTime = new Date().getTime()
    },
    pauseVideo() {
      this.player?.pause()
    },
    playVideoExpose() {
      if (this.playingVideo && this.hasInit && !this.hasReported) {
        this.hasReported = true
        if (this.config.videoDAEventExposeId) {
          // 曝光埋点
          daEventCenter.triggerNotice({
            daId: this.config.videoDAEventExposeId,
            extraData: {
              target: this.$el,
              itemInfos: this.item,
              index: this.index,
            },
          })
        }
      }
    },
    videoLoadEnd() {
      // 视频资源已加载完
      if (!this.loadEndVideo) {
        this.monitorReportVideoStuck()
        this.loadEndVideo = true
      }
    },
    initVideoPlayer() {
      if (this.$refs.autoplayVideo) {
        this.player = videojs(this.$refs.autoplayVideo, {
          playsinline: true,
          autoplay: false,
          preload: false,
          // loop: true,
          controls: false, // 显示播放的控件
          controlBar: false,
          bigPlayButton: false,
          titleBar: false,
          textTrackSettings: false,
          textTrackDisplay: false,
          userActions: {
            click: false,
            doubleClick: false,
          },
        })
        this.player.on('waiting', () => {
          // 加载完成后，不再显示loading
          if (this.loadEndVideo || !this.hasInit) {
            return
          }
          console.warn('视频资源不足，加载中')
          if (!checkNetworkIsWifi()) {
            this.playingVideo = false
          } else {
            this.loadingVideo = true
            this.stuckTimes++
          }
        })
        this.player.on('canplay', () => {
          if (!this.hasInit) {
            const endLoadTime = new Date().getTime()
            monitorVideoInitLoadTime(
              {
                page_name: window?.SaPageInfo?.page_name,
                video_url: this.videoUrl,
                goods_id: String(this?.item?.goods_id),
                size_style: this.ratioSize,
              },
              this.initStartTime ? endLoadTime - this.initStartTime : 0,
            )
          }
          this.hasInit = true
          this.loadingVideo = false
          this.playVideoExpose()
        })
        // 资源不足也会触发play事件
        this.player.on('play', () => {
          this.playingVideo = true
          this.setAttrForAnalysis(el => {
            el?.setAttribute('data-video-playing', '1')
          })
          this.playVideoExpose()
        })
        this.player.on('pause', () => {
          this.playingVideo = false
          this.setAttrForAnalysis(el => {
            el?.setAttribute('data-video-playing', '')
          })
          this.monitorReportVideoStuck()
        })
        if (this.showVideo) {
          this.$emit('videoMounted', {
            videoIns: this,
            videoEl: this.$refs.autoplayVideo,
            videoPlayer: this.player,
          })
        }
      }
    },
    metricReportRatioSize() {
      if (!this.hasReportedMetricRatioSize && this.videoUrl) {
        this.hasReportedMetricRatioSize = true
        metricRatioSize({
          page_name: window?.SaPageInfo?.page_name,
          video_url: this.videoUrl,
          goods_id: String(this?.item?.goods_id),
          size_style: this.ratioSize,
        })
      }
    },
    monitorReportVideoStuck() {
      if (!this.loadEndVideo && this.hasInit) {
        monitorVideoStuck(
          {
            page_name: window?.SaPageInfo?.page_name,
            video_url: this.videoUrl,
            goods_id: String(this?.item?.goods_id),
            size_style: this.ratioSize,
            loading_count: String(this.stuckTimes),
          },
        )
        this.stuckTimes = 0
      }
    },
  },
  render() {
    if (this.showVideo) {
      return (
        <div
          class="product-item__autoplay-container"
          style={{ opacity: this.playingVideo && this.hasInit ? 1 : 0 }}
        >
          <div
            class="product-item__autoplay-point"
            name="autoplayPoint"
          ></div>
          <video
            ref="autoplayVideo"
            class="video-js"
            muted
            loop
          ></video>
          {this.loadingVideo && (
            <SLoading
              show={true}
              mode="dark"
              class="product-card__loading-icon"
            />
          )}
        </div>
      )
    }
    return <div></div>
  },
}
</script>

<style lang="less" scoped>
.product-item {
  &__autoplay-container {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: flex;
    background: #ffffff;
    z-index: 1;
    transition: opacity 0.2s;
    /deep/ .video-js {
      width: 100%;
      height: 100%;
      display: flex;
      .vjs-hidden,
      .vjs-loading-spinner {
        display: none;
      }
    }
    video {
      max-width: 100%;
      max-height: 100%;
    }
    .product-card__loading-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__autoplay-point {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
