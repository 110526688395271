<script lang="jsx">
export default {
  name: 'PlayVideoIcon',
  render() {
    return (
      <img
        src="https://shein.ltwebstatic.com/svgicons/2024/12/26/17351986063317088293.svg"
        class="product-card__video-icon"
      />
    )
  },
}
</script>

<style lang="less">
.product-card__video-icon {
  position: absolute;
  left: 8px;
  top: 8px;
}
</style>
