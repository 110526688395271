<template>
  <div
    ref="adapterTextRef"
    class="benefit-adapter-text"
    v-html="text"
  >
  </div>
</template>

<script name="BenefitBarMain" setup lang="ts">
import { type Ref, ref, watch, nextTick } from 'vue'

const props = defineProps<{
  text: string,
  defaultSize: string
  minSize: string
}>()

const adapterTextRef: Ref = ref(null)

watch(
  () => adapterTextRef.value,
  (newVal, oldVal) => {
    if (!newVal || typeof window === 'undefined') return
    if (newVal?.innerText === oldVal?.innerText) return
    adapterTextRef.value.style.textOverflow = 'unset'
    adapterTextRef.value.style.fontSize = `${props.defaultSize}px`
    nextTick(() => {
      const { clientWidth, scrollWidth } = adapterTextRef.value
      if (scrollWidth > clientWidth) {
        adapterTextRef.value.style.fontSize = `${props.minSize}px`
      }
      adapterTextRef.value.style.textOverflow = 'ellipsis'
    })
  },
  { immediate: true, deep: true },
)
</script>

<style lang="less" scoped>
.benefit-adapter-text {
  white-space: nowrap;
  overflow: hidden;
}
</style>
