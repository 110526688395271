const { PUBLIC_CDN, SA_SDK_CONFIG, SiteUID, PERSONALIZED_SWITCH = {} } = gbCommonInfo
// const SA_SDK_CONFIG = {
//   loading: true, // 是否引入新SDK
//   sampling: true, // 是否开启采样
// }

import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import manifest from './file-manifest'
const { sdk_analysis } = manifest

export class SheinAnalytics {
  static name = 'SheinAnalytics'

  init() {
    // 个性化开关
    const showInfo = PERSONALIZED_SWITCH?.[SiteUID] ?? 1
    // 添加埋点拦截器
    // async 不能删掉 ！！！一定要 promise 返回，否则会有大问题！！！
    window.analysisBeforeInterceptor.use(async function (result) {
      // result == { data: {} }
      try {
        if (result.data) {
          const [currency, country, memberId, ugid] = await Promise.all([
            UserInfoManager.get({ key: 'currency' }),
            typeof window.global_sa_device_country !== 'undefined' ? { formatCountryAbbr: window.global_sa_device_country, } : UserInfoManager.get({ key: 'Country' }),
            UserInfoManager.get({ key: 'memberId' }),
            typeof window.global_sa_ugid !== 'undefined' ? window.global_sa_ugid : UserInfoManager.get({ key: 'ugid' }),
          ])
          Object.assign(result.data, {
            member_id: memberId,
            login: Number(Boolean(memberId)),
            money_type: currency,
            device_country: country && country.formatCountryAbbr || '', // eslint-disable-line
            showInfo,
            ugid,
          })
        }
        return result
      } catch (e) {
        return result
      }
    })
    
    const scrips = []
    // 营销页面已经提前引入sdk，不需要重复引入
    if (window.global_html_sa) return scrips
    scrips.push({
      TPM_Ele_Type: 'script',
      TPM_Config: {
        CDN: true
      },
      // 资源来源：npm @shein-aidc/basis-sa CDN 地址
      src: 
        SA_SDK_CONFIG.loading ? 
          '//cjs.ltwebstatic.com/unpkg/@shein-aidc/basis-sa@1.0.68/dist/web-sa.umd.js' 
          : `${PUBLIC_CDN}/pwa_dist/js/${sdk_analysis}`,
    })
    return scrips
  }
}
