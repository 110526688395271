var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'nav-bar__filter': true,
  },attrs:{"aria-label":_setup.language.SHEIN_KEY_PWA_15044,"role":"tab"},on:{"click":_setup.onClick,"focus":function($event){return _vm.$emit('navFocus', $event)}}},[_c('span',{class:{
      'nav-bar__filter-label': true,
      'nav-bar__filter-label-highlight': _setup.filterNum,
    }},[_vm._v("\n    "+_vm._s(_setup.language.SHEIN_KEY_PWA_15044)+"\n  ")]),_vm._v(" "),_c('span',{staticClass:"nav-bar__filter-icon",style:({
      color: _setup.filterNum ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.6)'
    })},[_c(_setup.sui_icon_filter_16px,{attrs:{"size":"12px"}})],1),_vm._v(" "),(_setup.filterNum)?_c('span',{staticClass:"nav-bar__filter-filter-num"},[_vm._v("\n    "+_vm._s(_setup.filterNum)+"\n  ")]):_vm._e(),_vm._v(" "),(_setup.isLoadSlide)?_c(_setup.NavBarFilterDrawer,{ref:"refNavBarFilterDrawer",attrs:{"visible":_setup.visible,"menu-bar-config":_setup.menuConfig.menuBarConfig,"menu-items-config":_setup.menuConfig.menuItemsConfig},on:{"update:visible":function($event){_setup.visible=$event},"sidePanelChange":_setup.onSidePanelChange,"resetAll":_setup.onResetAll,"closeSidePanel":_setup.close,"close":_setup.close,"clickDone":_setup.close}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }