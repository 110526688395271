export const DEFAULT_CONFIG = (
  [
    {
      i18nKey: 'SHEIN_KEY_PWA_32311',
      defaultName: 'Following',
      icon: 'sui_icon_follow_18px',
      fnName: 'handleLinkToFollowedShops',
      trackName: 'follow',
      entrance: 'following',
      show: true,
    },
    {
      i18nKey: 'SHEIN_KEY_PWA_26742',
      name: 'Wishlist',
      icon: 'sui_icon_nav_save_24px',
      fnName: 'handleWishlistClick',
      trackName: 'wishlist',
      show: true,
    },
    {
      i18nKey: 'SHEIN_KEY_PWA_28633',
      defaultName: 'Recently viewed',
      icon: 'sui_icon_viewed_18px',
      fnName: 'handleLinkToRecentViewed',
      trackName: 'footprint',
      show: true,
    },
    {
      i18nKey: 'SHEIN_KEY_PWA_14817',
      defaultName: 'Share',
      icon: 'sui_icon_share_20px',
      fnName: 'handleShareClick',
      trackName: 'share',
      show: true,
    },
    {
      i18nKey: 'SHEIN_KEY_PWA_35429',
      defaultName: 'Contact Seller',
      icon: 'sui_icon_comment_24px',
      fnName: 'handleLinkToContactSeller',
      trackName: 'contact',
      show: false,
    },
    {
      i18nKey: 'SHEIN_KEY_PWA_34288',
      defaultName: 'Customer Service',
      icon: 'sui_icon_pay_support_24px',
      fnName: 'handleLinkToSupport',
      trackName: 'support',
      show: false,
    }
  ]
)

  
