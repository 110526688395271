var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"j-header-bag j-footer-cart ft-li waves-btn waves-btn-auto waves-effect waves-classic",class:_vm.bagInfo.class,attrs:{"data-href":"","tabindex":"0","role":"link","aria-label":_vm.bagInfo.label},on:{"click":_vm.onClick,"pointerdown":_vm.onClick}},[_c('div',{staticClass:"footer-cart__icon"},[(_vm.product)?_c('img',{ref:"productRef",staticClass:"footer-cart__product",attrs:{"src":_vm.product}}):_vm._e(),_vm._v(" "),_c('div',{ref:"iconRef",staticClass:"footer-cart__icon-warp"},[(_vm.bagInfo.defaultImgSrc && _vm.bagInfo.selectedImgSrc)?[_c('div',{staticClass:"index-footer__ccc-skin-warp"},[_c('div',{staticClass:"index-footer__ccc-skin-icon",style:({
              backgroundImage: `url(${_vm.bagInfo.defaultImgSrc})`
            }),attrs:{"tabindex":"-1","aria-hidden":"true","role":"none"}}),_vm._v(" "),_c('div',{staticClass:"index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active",style:({
              backgroundImage: `url(${_vm.bagInfo.selectedImgSrc})`
            }),attrs:{"tabindex":"-1","aria-hidden":"true","role":"none"}})])]:[_c('i',{staticClass:"tabbar-icon"},[_c('sui_icon_tab_cart_30px',{attrs:{"size":"26px","color":"#767676"}})],1),_vm._v(" "),_c('i',{staticClass:"tabbar-icon_active"},[_c('sui_icon_tab_cart_selected_30px',{attrs:{"size":"26px","color":"#222"}})],1)]],2)]),_vm._v(" "),(_vm.label)?_c('CartLabel',{ref:"labelRef",attrs:{"label":_vm.label}}):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.label),expression:"!label"}],staticClass:"footer-cart__text-default"},[_vm._v("\n    "+_vm._s(_vm.bagInfo.label)+"\n  ")]),_vm._v(" "),(_vm.showSum && _vm.formatSum)?_c('em',{staticClass:"cart-num"},[_vm._v("\n    "+_vm._s(_vm.formatSum)+"\n  ")]):_vm._e(),_vm._v(" "),_c('ClientOnly',[_c('FooterCartTips',{ref:"refFooterCartTips"}),_vm._v(" "),_c('FooterCartPopover',{ref:"refFooterCartPopover",attrs:{"app-style-control-style":_vm.appStyleControlStyle,"style-config":{
        x: _vm.GB_cssRight ? '32%' : '-32%',
      }},on:{"close":_vm.onClosePopover}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }