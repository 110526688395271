<template>
  <div class="not-fsp-element">
    <SSticky
      class="sticky-index"
      type="sticky"
      :offset="0"
      :fixed-work="onTabFixChanged"
    >
      <STab
        ref="footerSectionTabRef"
        v-model="goodsTabActive"
        class="my-wishlist-tab"
        show-line-ever
        type="equally"
        @input="switchGoodsTab"
      >
        <STabItem
          id="save"
          v-enterkey
        >
          {{ language.SHEIN_KEY_PWA_15733 || 'Wishlist' }}
        </STabItem>
        <STabItem
          id="recent"
          v-enterkey
        >
          {{ language.SHEIN_KEY_PWA_15010 || 'Recently Viewed' }}
        </STabItem>
      </STab>
    </SSticky>
    <div
      ref="listEleRef"
      :class="listClasses"
    >
      <WishProducts
        v-show="goodsTabActive === 'save'"
        class="j-da-event-box"
        code="wishlistUser"
        data-title="wishlist"
        data-active-from="wishlist"
        da-expose-code="userWishlistProductContainer"
        da-sa-name="saved"
        :show="goodsTabActive === 'save'"
        :is-login="isLogin"
        :footer-rcmd-list-resolve-status="footerRcmdListResolveStatus"
      />

      <RecentView
        v-show="goodsTabActive === 'recent'"
        class="j-da-event-box"
        code="recentlyViewed"
        data-title="Recently Viewed"
        data-active-from="recently_viewed"
        da-expose-code="userRecentlyViewedProductContainer"
        da-sa-name="recently_viewed"
        :props-language="language"
        :show="goodsTabActive === 'recent'"
        :is-login="isLogin"
        :footer-rcmd-list-resolve-status="footerRcmdListResolveStatus"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { Tab as STab, TabItem as STabItem, Sticky as SSticky } from '@shein/sui-mobile'
import { FooterRcmdListResolveStatus } from 'public/src/pages/user_index/service/consts'
import WishProducts from 'public/src/pages/user_index/components/wishlist_v2/index.vue'
import RecentView from 'public/src/pages/user_index/components/recent_view/index.vue'

const props = defineProps({
  isLogin: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  footerRcmdListResolveStatus: {
    type: Number,
    default: -1,
  },
})
const tabFixed = ref(false)
const listEleRef = ref(null)
const goodsTabActive = ref('save')
const listClasses = computed(() => {
  const classes = ['index-me-list']
  if (props.footerRcmdListResolveStatus === FooterRcmdListResolveStatus.HAS_RCMD_LIST) {
    classes.push('followed-by-rcmdlist')
  }
  return classes
})

const onTabFixChanged = (fixed) => tabFixed.value = fixed

const switchGoodsTab = (tabVal) => {
  goodsTabActive.value = tabVal
  changeTitleFixed()
  Vue.nextTick(() => {
    window.dispatchEvent(new Event('scroll')); // 触发滚动事件
  })

  const isRecent = tabVal == 'recent'
  sa('send', {
    activity_name: isRecent ? 'click_recent_view_tab' : 'click_wishlist_tab',
    activity_param: ''
  })
  // 新埋点
  // sa('send', {
  //   activity_name: isRecent ? 'click_recently_viewed' : 'click_wishlist_view_all',
  //   activity_param: {
  //     goods_list: getReportGoodsList()
  //   }
  // })
}
const changeTitleFixed = () => {
  if (!tabFixed.value) return
  const listEl = listEleRef.value
  if (!listEl) return
  const [paddingTop] = getStyles(listEl, ['paddingTop'])
  window.scrollTo(0, listEl.offsetTop - paddingTop * 3)
}
const getStyles = (el, options) => {
  const styles = window.getComputedStyle(el, null)
  return options.reduce((arr, styleKey) => (arr.push(styles[styleKey] && Number(styles[styleKey].replace('px', ''))), arr), [])
}

onMounted(()=> {
  goodsTabActive.value = 'save'
})
</script>

<style lang="less">
.my-wishlist-tab {
  border-bottom: none;
}
.sticky-index {
  z-index: @zindex-header;
}

.index-me-list {
  background: #fff;
  padding: 0.32rem 0 0.64rem;
  min-height: 370/37.5rem;
  &.followed-by-rcmdlist {
    display: flex;
    flex-direction: column;
    padding-top: 0.32rem;
    padding-bottom: 0;
    border-bottom: .26666667rem solid #f6f6f6;
    .wishlist-center-page {
      min-height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      /* stylelint-disable-next-line selector-max-specificity */
      .wishlist-center-empty {
        padding: 40/37.5rem 0 80/37.5rem;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .wish-list-comp {
        flex: 1;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .wishlist-center-page__view {
        height: 40/37.5rem;
        font-weight: normal;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .wish-list-comp__list {
        /* stylelint-disable-next-line declaration-no-important */
        background-color: #fff !important;
      }
    }
    .recently-page {
      min-height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      /* stylelint-disable-next-line selector-max-specificity */
      .wish-list-comp {
        flex: 1;
      }
      &__empty {
        padding: 40/37.5rem 0 80/37.5rem;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .wish-list-comp__list {
        /* stylelint-disable-next-line declaration-no-important */
        background-color: #fff !important;
      }
    }
  }
}
</style>
