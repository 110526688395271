<template>
  <Box :type="boxType">
    <div class="default-search-box">
      <div
        v-if="showSearchIcon"
        class="default-search-box__search-icon"
        @click="handleSearchIconClick">
        <slot
          v-if="$slots['search-icon']"
          name="search-icon"
        >
        </slot>
        <SearchIcon v-else />
      </div>
      <div class="default-search-box__content">
        <SearchTerms
          ref="searchTermsRef"
          :search-terms="searchTerms"
          :emptySearchTermsText="emptySearchTermsText"
          :showTermIcon="showTermIcon"
          :hotTermIndex="hotTermIndex"
          @searchTermClick="handleSearchTermClick"
          @searchTermTransitionEnd="handleSearchTermTransitionEnd" />
      </div>
      <div
        v-if="showCameraIcon"
        class="default-search-box__camera"
        @click="handleCameraIconClick">
        <slot
          v-if="$slots.cameraIcon"
          name="cameraIcon"></slot>
        <CameraIcon v-else />
      </div>
      <div
        v-if="showSearchButton"
        :class="['default-search-box__button', searchButtonType === 'round' ? 'round-gap' : '']"
        @click="handleSearchButtonClick"
      >
        <SearchButton :type="searchButtonType">
          <slot name="search-button-icon"></slot>
        </SearchButton>
      </div>
    </div>
  </Box>
</template>

<script name="DefaultSearchBox" setup lang="ts">
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../../../common/dataSource'
import type { AS_SearchBox, DS_SearchBox } from '../../../../types'

import Box from '../Box'
import CameraIcon from '../CameraIcon'
import SearchButton from '../SearchButton'
import SearchIcon from '../SearchIcon'
import SearchTerms from '../SearchTerms'
import { SearchTerm } from '../SearchTerms/SearchTermType'
import { ref, watchEffect } from 'vue'

const status = ref('idle')
const searchTerms = ref<Partial<SearchTerm>[]>([])
const searchTermsRef = ref<InstanceType<typeof SearchTerms>>()

const syncSearchTermForSearchIconIndex = ref<number>(-1)

type DefaultSearchBoxProps = {
  // UI 控制
  slideItems?: Partial<SearchTerm>[]
  boxType?: 'round' | 'square' | 'none'
  searchButtonType?: 'round' | 'square' | 'text'
  showSearchIcon?: boolean
  showSearchButton?: boolean
  showCameraIcon?: boolean
  emptySearchTermsText?: string
  showTermIcon?: boolean
  hotTermIndex?: number // 前index个搜索词出现前置icon

  // 数据源
  autoFetch?: boolean
  fixedFirstTerm?: boolean
  fetchSearchTermsParams?: FetchSearchTermsParams
  fsData?: DS_SearchBox.FS_DATA
  dataSource?: DS_SearchBox.DataSource
  analysisSource?: AS_SearchBox.AnalysisSource,
  httpConfig?: {method?: 'GET' | 'POST', featureKey?: string }
}

const props = withDefaults(defineProps<DefaultSearchBoxProps>(), {
  // UI 控制
  slideItems: () => [],
  boxType: 'none',
  showSearchIcon: false,
  showSearchButton: false,
  showCameraIcon: false,
  showTermIcon: true,
  hotTermIndex: -1,

  // 数据源
  autoFetch: true,
  // 是否固定展示第一个搜索词
  fixedFirstTerm: false,
})

const appConfigs = useAppConfigs()
const apis = getSource({ appConfigs, dataSource: props.dataSource })

type FetchSearchTermsParams = Partial<{
  goods_id: string
  word_type: number
  cat_id: string
  select_id: string
  list_scene: string
  scene: string
}>

const defaultFetchSearchTermsParams: FetchSearchTermsParams = {
  word_type: 2,
  scene: 'home',
}

const fetchSearchTerms = async (params: FetchSearchTermsParams = defaultFetchSearchTermsParams) => {
  status.value = 'pending'
  try {
    const res = await apis.getKeywordsWithBff(params, props.httpConfig)
    const keywords = (res?.info?.keywords || []).map(item => ({ trace_id: res.info.trace_id || '-', ...item }))

    if (props.fixedFirstTerm) {
      searchTerms.value = [keywords?.[0]].filter(Boolean)
    } else {
      searchTerms.value = keywords
    }

    emit('resolveSearchTerms', keywords, res?.info)

    status.value = 'resolve'
  } catch (error) {
    status.value = 'reject'
  }
}

const previousFetchSearchTermsParams = ref<string | null>(null)

watchEffect(() => {
  // ssr 不需要调用接口
  if (typeof window === 'undefined') {
    return
  }

  const isAutoFetch = props.autoFetch

  if (isAutoFetch) {
    if (previousFetchSearchTermsParams.value === JSON.stringify(props.fetchSearchTermsParams)) {
      return
    }
    fetchSearchTerms(props.fetchSearchTermsParams).then(() => {
      previousFetchSearchTermsParams.value = JSON.stringify(props.fetchSearchTermsParams)
    })
  } else {
    searchTerms.value = props.slideItems
  }
})

type SearchTermsEmits = {
  (e: 'searchTermClick', item: Partial<SearchTerm> | null, index: number): void
  (e: 'searchIconClick', item: Partial<SearchTerm>, index: number): void
  (e: 'cameraIconClick'): void
  (e: 'searchButtonClick', item: Partial<SearchTerm>, index: number): void
  (e: 'searchTermTransitionEnd', item: Partial<SearchTerm>, index: number): void
  (e: 'resolveSearchTerms', keywords: Partial<SearchTerm>[], info: any): void
}

const emit = defineEmits<SearchTermsEmits>()
const handleSearchTermClick = (item: Partial<SearchTerm> | null, index: number) => {
  emit('searchTermClick', item, index)
}

const handleSearchIconClick = () => {
  const index = syncSearchTermForSearchIconIndex.value === -1 ? 0 : syncSearchTermForSearchIconIndex.value
  const item = searchTerms.value[index]

  emit('searchIconClick', item, index)
}
const handleCameraIconClick = () => {
  emit('cameraIconClick')
}
const handleSearchButtonClick = () => {
  const index = syncSearchTermForSearchIconIndex.value === -1 ? 0 : syncSearchTermForSearchIconIndex.value
  const item = searchTerms.value[index]
  emit('searchButtonClick', item, index)
}

const handleSearchTermTransitionEnd = (item: Partial<SearchTerm>, index: number) => {
  syncSearchTermForSearchIconIndex.value = index
  emit('searchTermTransitionEnd', item, index)
}

defineExpose({
  updateSwiper: () => {
    searchTermsRef.value?.updateSwiper()
  },
  fetchSearchTerms,
})
</script>

<style scoped>
.default-search-box {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: var(--search-box-padding-left, 12px);
  padding-right: var(--search-box-padding-right, 0);
}
.default-search-box__search-icon {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: var(--search-box-search-icon-padding-right, 6px);
}
.default-search-box__content {
  flex: 1;
  display: grid;
  /* 超出内容隐藏 */
  overflow: hidden;
  height: 100%;
}
.default-search-box__camera {
  padding-left: 5px;
  padding-right: 10px;
}
.default-search-box__button {
  height: 100%;
}
.default-search-box__button.round-gap {
  padding: 2px;
}
</style>
