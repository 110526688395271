<template>
  <a
    data-href=""
    class="j-header-bag j-footer-cart ft-li waves-btn waves-btn-auto waves-effect waves-classic"
    :class="bagInfo.class"
    tabindex="0"
    role="link"
    :aria-label="bagInfo.label"
    @click="onClick"
    @pointerdown="onClick"
  >
    <div class="footer-cart__icon">
      <img
        v-if="product"
        ref="productRef"
        :src="product"
        class="footer-cart__product"
      />
      <div
        ref="iconRef"
        class="footer-cart__icon-warp"
      >
        <template v-if="bagInfo.defaultImgSrc && bagInfo.selectedImgSrc">
          <div class="index-footer__ccc-skin-warp">
            <div
              tabindex="-1"
              aria-hidden="true"
              role="none"
              class="index-footer__ccc-skin-icon"
              :style="{
                backgroundImage: `url(${bagInfo.defaultImgSrc})`
              }"
            ></div>
            <div
              tabindex="-1"
              aria-hidden="true"
              role="none"
              class="index-footer__ccc-skin-icon index-footer__ccc-skin-icon_active"
              :style="{
                backgroundImage: `url(${bagInfo.selectedImgSrc})`
              }"
            ></div>
          </div>
        </template>
        <template v-else>
          <i
            class="tabbar-icon"
          >
            <sui_icon_tab_cart_30px
              size="26px"
              color="#767676"
            />
          </i>
          <i
            class="tabbar-icon_active"
          >
            <sui_icon_tab_cart_selected_30px
              size="26px"
              color="#222"
            />
          </i>
        </template>
      </div>
    </div>

    <CartLabel
      v-if="label"
      ref="labelRef"
      :label="label"
    />

    <span
      v-show="!label"
      class="footer-cart__text-default"
    >
      {{ bagInfo.label }}
    </span>

    <em
      v-if="showSum && formatSum"
      class="cart-num"
    >
      {{ formatSum }}
    </em>

    <ClientOnly>
      <FooterCartTips ref="refFooterCartTips" />
      <FooterCartPopover
        ref="refFooterCartPopover"
        :app-style-control-style="appStyleControlStyle"
        :style-config="{
          x: GB_cssRight ? '32%' : '-32%',
        }"
        @close="onClosePopover"
      />
    </ClientOnly>
  </a>
</template>

<script>
import { mapState } from 'vuex'
import { gsap } from 'gsap'
import ClientOnly from 'vue-client-only'
import { registerCart, unregisterCart } from '@shein-aidc/bs-sdk-cart-tag-tip'
import CartLabel from './components/cart-label/index.vue'
import { daEventCenter } from '@/public/src/services/eventCenter'
import { isNormalType, jumpCart } from './utils'

import { sui_icon_tab_cart_30px,  sui_icon_tab_cart_selected_30px } from '@shein-aidc/icon-vue2'
import { web_cart_entry_click } from 'public/src/pages/cart_v2/utils/metricTagsConfig.js'
import { markPoint } from 'public/src/services/mark/index.js'
const { GB_cssRight } = gbCommonInfo

export default {
  name: 'AppFooterCart',
  components: {
    ClientOnly,
    CartLabel,
    FooterCartTips: () => import(/* webpackChunkName: "footer-cart-tips" */'./tips.vue'),
    FooterCartPopover: () => import(/* webpackChunkName: "footer-cart-popover" */'./popover/index.vue'),
    sui_icon_tab_cart_30px,
    sui_icon_tab_cart_selected_30px,
  },
  props: {
    bagInfo: {
      required: true,
      type: Object,
    },
    footerData: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {
      cartListener: null,
      targetElement: null,
      observer: null,

      tl: null,
      callback: null,
      showSum: true,
      excludeScroll: false,
      label: null,
      bubble: null,
      showProduct: false,
      product: '',
      appStyleControlStyle: null,
      dataByBff: null,

      GB_cssRight,
      isClick: false,
    }
  },
  computed: {
    ...mapState(['cartInfo']),
    IS_RW() {
      return this.footerData?.IS_RW
    },
    formatSum() {
      const sum = this.cartInfo?.sum || 0
      if (sum && sum > 0) {
        return sum > 99 ? '99+' : sum
      }
      return false
    }
  },
  mounted() {
    if (typeof window === 'undefined') return
    this.cartListener = {
      showtimeByBff: (dataByBff, config) => {
        this.showtime(dataByBff, config)
      },
      endtime: () => {
        this.endtime()
      },
    }

    this.intersectionObserver()

    this.onExpose()
    appEventCenter.$on('pageOnload', () => this.onExpose())
  },
  beforeDestroy() {
    unregisterCart('footer-cart', this.cartListener)
    if (this.observe && this.targetElement) {
      this.observer.unobserve(this.targetElement)
    }
  },
  methods: {
    getIsNewPopover(appStyleControl) {
      // 常驻气泡
      if (isNormalType(appStyleControl.bubble.type)) {
        if (appStyleControl.bubble.type === 'gift') return !!appStyleControl.bubble.lurePointItem
        if (appStyleControl.style === '1') return !!appStyleControl.bubble.lurePointItem
        return false
      }
      return true
    },
    async showtime(dataByBff, config = {}) {
      this.endtime(true)
      this.callback = config.callback
      this.excludeScroll = !!config.excludeScroll
      this.appStyleControlStyle = dataByBff?.appStyleControl?.style
      this.dataByBff = dataByBff
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async r => {
        const callback = () => {
          this.endtime(true)
          r()
        }
        const { appStyleControl } = dataByBff
        // 没有数据，不展示标签
        if (!appStyleControl) {
          this.label = null
          return callback()
        }
        // 不展示利诱气泡，直接展示最终的标签
        if (config.noTip) {
          this.label = appStyleControl.showLabel
          return callback()
        }

        // 没有气泡和新标签，直接展示最终的标签
        if (!appStyleControl.bubble && !appStyleControl.newLabel) {
          this.label = appStyleControl.showLabel
          return callback()
        }

        let labelDone = false
        let hasBubble = false
        let product
        let position // newLabel 标签停留时间
        let productPosition
        if (appStyleControl.bubble) {
          hasBubble = true
          this.bubble = appStyleControl.bubble
          const isNewPopover = this.getIsNewPopover(appStyleControl)
          if (isNewPopover) {
            position = '+=4.5'
            this.$refs.refFooterCartPopover?.open(appStyleControl.bubble, {
              callback: () => {
                if (labelDone) callback()
              },
            })
            product = this.getProduct(appStyleControl)
            if (product) {
              productPosition = '+=4.5'
              position = '-=0.5'
            }
          } else {
            position = '+=2.2'
            this.$refs.refFooterCartTips?.open(appStyleControl.bubble, {
              callback: () => {
                if (labelDone) callback()
              },
            })
          }
        }

        // null & null
        // A & A
        // 没有可翻转的标签
        if (appStyleControl.newLabel?.type === appStyleControl.showLabel?.type) {
          this.label = appStyleControl.showLabel
          if (hasBubble) {
            labelDone = true
            return // 存在气泡时，由气泡执行 callback()
          }
          return callback()
        }

        if (!this.label) this.label = appStyleControl.showLabel
        await this.$nextTick()
        this.tl = gsap.timeline()

        // 有常驻标签 有翻转标签
        if (this.label && appStyleControl.newLabel) {
          // A & B
          this.tl.to(this.$refs.labelRef.$el, {
            duration: 0.3,
            rotationY: 90,
            opacity: 0,
            repeat: 1,
            yoyo: true,
            onRepeat: () => {
              this.label = appStyleControl.newLabel
            },
          })
        } else {
          // null & B
          // A & null
          this.label = appStyleControl.newLabel
          await this.$nextTick()
        }

        // 展示商品图片
        if (product) {
          this.product = product
          await this.$nextTick()
          this.tl.to(this.$refs.productRef, {
            y: 26,
            duration: 0.3,
            eaes: 'power2.out',
            repeat: 1,
            yoyo: true,
            repeatDelay: 3,
            onStart: () => {
              this.showSum = false
              this.showProduct = true
            },
            onComplete: () => {
              this.showSum = true
              this.showProduct = false
            },
          }, productPosition)
          this.tl.to(this.$refs.iconRef, {
            y: 26,
            duration: 0.3,
            eaes: 'power2.in',
            repeat: 1,
            yoyo: true,
            repeatDelay: 3,
          }, '<')
        }

        if (this.label) {
          // null & B
          // A & B
          // 还原常驻标签
          this.tl.to(this.$refs.labelRef.$el, {
            duration: 0.3,
            opacity: 0,
            repeat: 1,
            yoyo: true,
            onRepeat: () => {
              this.label = appStyleControl.showLabel
            },
            onComplete: () => {
              callback()
            },
          }, position)
        } else {
          // A & null
          this.tl.to(null, {
            onComplete: () => {
              this.label = appStyleControl.showLabel
              callback()
            },
          }, position)
        }
      })
    },
    endtime(finish) {
      if (!finish && this.excludeScroll) return
      this.tl?.seek(0).kill()
      this.tl = null
      this.callback?.()
      this.callback = null
      this.showSum = true
      this.excludeScroll = false
      if (this.dataByBff) this.label = this.dataByBff?.appStyleControl?.showLabel ?? null
      this.bubble = null
      this.showProduct = false
      this.product = ''
      this.appStyleControlStyle = null
      this.dataByBff = null

      this.$refs.refFooterCartTips?.clear?.()
      this.$refs.refFooterCartPopover?.clear?.()
    },
    getProduct(appStyleControl) {
      let res = ''
      if (appStyleControl.bubble?.lurePointItem?.bubble.goods?.length) {
        res = appStyleControl.bubble?.lurePointItem?.bubble.goods[0].goods_img
      }
      return res
    },
    async getShowLabel() {
      const lure = await window?._gb_cart_tag_tips_?.getLure()
      this.label = lure?.showLabel
    },
    intersectionObserver() {
      if (this.observer) return

      // 选择要观察的目标元素
      const targetElement = document.getElementsByClassName('j-index-footer')[0]
      if (!targetElement || typeof IntersectionObserver === 'undefined') return
      this.targetElement = targetElement

      // 观察器选项
      const options = {
        root: null, // 使用视口作为根元素
        rootMargin: '0px', // 根元素的边距
        threshold: 0.1 // 阈值，表示目标元素的可见比例
      }

      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            registerCart('footer-cart', this.cartListener)
            this.getShowLabel()
            window?._gb_cart_tag_tips_?.onceTip()
          } else {
            unregisterCart('footer-cart', this.cartListener)
          }
        })
      }, options)

      this.observer.observe(targetElement)
    },
    onClick() {
      if(this.isClick) return
      this.isClick = true
      web_cart_entry_click('2')

      window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
        daEventCenter.triggerNotice({
          daId: '2-15-1',
          extraData,
          bindData: {
            index: 4,
            tab_name: 'Bag',
          }
        })
      })

      if (this.showProduct) {
        jumpCart(this.bubble, this.appStyleControlStyle, false)
        setTimeout(() => {
          this.isClick = false
        }, 200)
        return
      }

      if (window._gb_app_) {
        markPoint('toNextPageClick', 'public')
        _gb_app_.$router.push(this.bagInfo.href)
      } else {
        window.location.href = this.bagInfo.href
      }
      setTimeout(() => {
        this.isClick = false
      }, 200)
    },
    onExpose() {
      const footerDom = document.querySelector('.j-index-footer')
      if (!footerDom) return
      if (footerDom.style.display === 'none') return
      window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
        daEventCenter.triggerNotice({
          daId: '2-15-4',
          extraData,
          bindData: {
            index: 4,
            tab_name: 'Bag',
          }
        })
      })
    },
    onClosePopover() {
      this.endtime()
    },
  }
}
</script>

<style lang="less">
.footer-cart {
  &__text-default {
    padding: 0;
    white-space: initial;
  }

  &__icon {
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    overflow: hidden;
  }

  &__product {
    position: absolute;
    top: -100%;
    width: 26px;
    height: 26px;
    object-fit: cover;
    border-radius: 3px;
  }
}

.index-footer {
  a {
   position: relative;
   overflow: visible;
  }
}
</style>
