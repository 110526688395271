<template>
  <div class="prices-info__suggested-sale-price">
    <s-popover
      v-if="renderPopover"
      v-model="popoverActive"
      :content="pricePopoverLang"
      :placemen="popoverPlacemen"
      size="lg"
      trigger-type="user"
      show-close-icon
      
      :prop-style="{
        maxWidth: '6.4rem',
        zIndex: '999999',
        transform: 'translate3d(0px, 0px, 999999px)'
      }"
      @handleCloseIconClick="popoverClose"
    >
      <div 
        slot="reference"
        class="suggested-popover" 
        @click.stop
        @touchstart.stop="popoverClickTouchstart"
      >
        <SuggestedSalePrice
          :value="priceValue"
          :label-text="lowestPriceLabelLang"
          :price-delete-style="priceDeleteStyle"
        />
        <i 
          v-if="renderIconSuggested"
          ref="iconCaution"
          class="icon-suggested-caution sh_m_sui_icon_caution_12px_2"
          :data-id="id"
          :style="{
            opacity: showIconSuggested ? 1 : 0
          }"
        ></i>
      </div>
    </s-popover>
    <SuggestedSalePrice
      v-else
      :value="priceValue"
      :label-text="lowestPriceLabelLang"
      :price-delete-style="priceDeleteStyle"
    />
  </div>
</template>

<script setup name="SuggestedSalePriceContainer">
import SuggestedSalePrice from './SuggestedSalePrice.vue'
import { Popover as SPopover } from '@shein/sui-mobile'

import { useCardPopover } from '../../js/hooks/useCardPopover.js'

import { toRefs } from 'vue'

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {},
  },
  priceData: {
    type: Object,
    default: () => {},
  }
})

const { goodsInfo, priceData } = toRefs(props)

const lowestPriceLabelLang = priceData.value?.labelText

const renderPopover = priceData.value?.showRetailPricePopover ?? false
const pricePopoverLang = priceData.value?.popoverLang ?? ''
const priceDeleteStyle = priceData.value?.priceDeleteStyle ?? false

// 德国展示原价  法国挪威展示合规价
const priceValue = priceData.value?.priceData

const {
  id,
  popoverActive,
  popoverPlacemen,
  iconCaution,
  renderIconSuggested,
  showIconSuggested,
  popoverClose,
  popoverClickTouchstart
} = renderPopover ? useCardPopover({
  needDomCalculation: true,
  goodsInfo: goodsInfo.value
}) : {}

</script>

<style lang="less" scoped>
.prices-info__suggested-sale-price {
  width: fit-content;
}
.suggested-popover {
  display: flex;
  align-items: center;
}
.icon-suggested-caution {
  position: relative;
  top: -1px;

  margin-left: 1px;
  width: .32rem;
  height: .32rem;

  opacity: 0;
}
</style>
