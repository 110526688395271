<template>
  <div class="footer-cart-label">
    <component
      :is="is"
      :label="label"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import CartLabelNormal from './CartLabelNormal.vue'
import CartLabelMarquee from './CartLabelMarquee.vue'
import { isNormalType } from '../../utils'

const props = defineProps({
  label: {
    type: Object,
    required: true,
  },
})

const is = computed(() => {
  if (!isNormalType(props.label.type)) return CartLabelMarquee
  return CartLabelNormal
})
</script>

<style lang="less">
.footer-cart-label {
  margin-top: 2px;
}
</style>
