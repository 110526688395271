// 预取数据的路由名称
const PREFETCH_DATA_ROUTES_NAME = [
  'config_index',
  'config_activity',
  'category'
]

// 其他页面预取数据的等待时间
const PREFETCH_WAIT_TIME = 3000

/**
 * Cache Key
 */
const CACHEKEY_DASHBOARD_BASE_PREFIX = 'DashboardBase#'
const CACHEKEY_USERINFO = 'UserInfo'
const CACHEKEY_IMG_PRELOAD_PREFIX = 'ImgPreload#'

const UserRightsCardType = {
  MEMBER_CARD: 1,
  SUPER_SAVE: 2,
}
const SuperSaveCouponStatus = {
  // 待领取
  TO_COLLECT: 0,
  // 待使用
  TO_USE: 1,
}

const FooterRcmdListResolveStatus = {
  PENDING: 0,
  HAS_RCMD_LIST: 1,
  NO_RCMD_LIST: 2,
}

// 页面多语言
const LanguageKeys = [
  'SHEIN_KEY_PWA_15733',
  'SHEIN_KEY_PWA_15010',
  'SHEIN_KEY_PWA_30734',
  'SHEIN_KEY_PWA_31336',
]

export {
  UserRightsCardType,
  SuperSaveCouponStatus,
  FooterRcmdListResolveStatus,
  LanguageKeys,
  PREFETCH_DATA_ROUTES_NAME,
  PREFETCH_WAIT_TIME,
  CACHEKEY_DASHBOARD_BASE_PREFIX,
  CACHEKEY_USERINFO,
  CACHEKEY_IMG_PRELOAD_PREFIX
}
