<template>
  <div
    class="credit-icon"
    :class="{
      'credit-icon_mini': isMini,
      'credit-icon_locked': isLocked
    }"
  >
    <div class="credit-icon__title">
      <img
        v-if="isLocked"
        class="credit-icon__title-lock"
        src="https://img.ltwebstatic.com/images3_ccc/2024/11/18/f0/17319302064555d4e50d3993bc746b5c54ce36bea1.webp"
      />
      <div
        ref="creditTextRef"
        class="credit-icon__title-text"
        :style="{ fontSize: `${defaultFontSize}px` }"
      >
        {{ returnCredit.displayText[0] }}
      </div>
    </div>
    <div
      v-if="!isMini"
      class="credit-icon__subtitle"
      :style="{
        fontSize: isFuzzyCredit ? '12px' : '8px',
        fontWeight: isFuzzyCredit ? 900 : 590
      }"
    >
      {{ returnCredit.displayText[1] }}
    </div>
  </div>
</template>
<script name="CreditIcon" setup lang="ts">
import { type Ref, ref, computed, watch, nextTick } from 'vue'
import { E_BenefitFloor, type C_BenefitFloor } from '../../../../types'

const props = withDefaults(
  defineProps<{
    isMini?: boolean,
    rewardItem: C_BenefitFloor.RewardItem
  }>(),
  {
    isMini: false,
    rewardItem: () => ({
      type: E_BenefitFloor.BenefitType.ReturnCredit,
      desc: '',
      returnCredit: {} as C_BenefitFloor.ReturnCredit,
    }),
  },
)

const creditTextRef: Ref = ref(null)
const returnCredit = computed(() => props.rewardItem?.returnCredit || {} as C_BenefitFloor.ReturnCredit)
// 模糊返现，不展示具体金额
const isFuzzyCredit = computed(() => returnCredit.value.hasCreditMoneyDisplay === '0')
const defaultFontSize = computed(() => {
  let fontSize = 18
  if (props.isMini) {
    fontSize = 12
    if (isFuzzyCredit.value) fontSize = 10
  }
  return fontSize
})
const minFontSize = computed(() => {
  let minFontSize = 12
  if (props.isMini && isFuzzyCredit.value) minFontSize = 10
  return minFontSize
})
const isLocked = computed(() => returnCredit.value.isLock === '1')

const updateTextStyle = () => {
  const { clientWidth, scrollWidth } = creditTextRef.value
  let fontSize = defaultFontSize.value
  if (fontSize === minFontSize.value) return
  // scrollWidth 会四舍五入，容易导致计算得到的字号过大
  const handledScrollWidth = scrollWidth + 1
  if (handledScrollWidth > clientWidth) {
    // 微调因子，避免1px的误差
    const adjustmentFactor = 0.98
    const scaleRatio = clientWidth / handledScrollWidth * adjustmentFactor
    const adaptableFontSize = Math.floor(fontSize * scaleRatio)
    const isTooSmall = adaptableFontSize < minFontSize.value
    fontSize = isTooSmall ? minFontSize.value : adaptableFontSize
  }
  creditTextRef.value.style.fontSize = `${fontSize}px`
  creditTextRef.value.style.textOverflow = 'ellipsis'
}

watch(
  () => creditTextRef.value,
  (newVal, oldVal) => {
    if (!newVal || typeof window === 'undefined') return
    if (newVal?.innerText === oldVal?.innerText) return
    creditTextRef.value.style.textOverflow = 'unset'
    nextTick(() => updateTextStyle())
  },
  { immediate: true, deep: true },
)

</script>
<style lang="less" scoped>
.credit-icon {
  width: 128/75rem;
  height: 72/75rem;
  padding: 0 8/75rem;
  background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/11/15/bc/1731650174fe34f32614365b8752130a08be39272c.webp');
  background-size: 100% 100%;
  box-shadow: 0px 0 8/75rem 0 rgba(0, 95, 38, 0.26);
  border-radius: 12/75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.1;
  text-align: center;
  flex-shrink: 0;
  transition: inherit;

  &_mini {
    width: 84/75rem;
    height: 48/75rem;
  }

  &_locked {
    opacity: 0.6;
  }

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }

  &__title-lock {
    width: 18/75rem;
    height: 24/75rem;
  }

  &__title-text {
    flex: 1;
    font-size: 18px;
    font-family: system-ui;
    font-weight: 900;
    background-image: linear-gradient(182deg, #FFF 33.36%, #FFDF8C 75.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    transition: inherit;
  }

  &__subtitle {
    max-width: 100%;
    font-family: system-ui;
    font-size: 8px;
    font-weight: 590;
    background-image: linear-gradient(182deg, #FFF 33.36%, #FFDF8C 75.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: inherit;
  }
}
</style>
