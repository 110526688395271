<template>
  <div 
    class="item-best-choice"
    :class="{ 'is-coupon': isCoupon }"
    :style="styleInfo"
  >
    <CornerTag
      v-if="item.rightCornerIcon"
      :img-src="item.rightCornerIcon"
      :text="item.rightCornerTip"
    />
    <div class="top">
      <template v-if="isCoupon">
        <div class="left-coupon">
          <SAdapterText
            class="coupon-face"
            min-size="12"
            :text="item.thresholdTip"
          />
          <p 
            class="ellipsis coupon-threshold"
          >
            {{ item.applyTip }}
          </p>
        </div>
      </template>
      <template v-else>
        <img
          class="left-promotion"
          width="16"
          height="16"
          :src="item.icon"
        />
      </template>
      <div class="middle">
        <p
          ref="tips"
          class="tips"
          :class="{
            'overflow-padding': isSheinClub && isOverflow,
          }"
        >
          <span v-html="item.tip"></span>
          <Icon 
            v-if="isSheinClub"
            class="ico"
            name="sui_icon_doubt_12px_2"
            color="#828282"
            size="12px"
            @click="showNotice"
          />
        </p>
        <CountDown
          v-if="item.endTimeStamp"
          class="end-in"
          :prefix="item.endTip"
          :timeStamp="item.endTimeStamp"
          :styleConfig="countDownStyle"
        />
        <p 
          class="full-tip ellipsis"
          v-html="item.fullTip"
        >
        </p>
      </div>
      <div
        v-if="item.buttonTip && !isAccessory"
        class="right-action" 
        @click="handleAdd"
      >
        <span class="ellipsis">{{ item.buttonTip }}</span>
        <Icon 
          name="sui_icon_more_right_12px_2" 
          size="12px" 
          :is-rotate="GB_cssRight"
        />
      </div>
    </div>
    <div 
      v-if="goods.length"
      class="bottom"
    >
      <div class="goods-list">
        <div
          v-for="(x, index) in goods"
          :key="index"
          class="goods"
        >
          <img 
            :src="cutImg(x.goodsImg)" 
            width="100%"
            height="100%"
          />
          <span
            class="goods-bottom"
            :style="{ '--bottom-bg-color': x.productBottomBtnBgColor }"
          >
            <span
              class="tips"
              :style="{ 'color': x.productBottomTipColor }"
            >
              {{ x.productBottomTip }}
            </span>
          </span>
        </div>
        <div 
          v-if="goods.length === 1"
          class="goods-desc"
        >
          <p class="ellipsis">
            {{ goods[0].goodsName }}
          </p>
          <p class="attr">
            {{ goods[0].goodsAttr }}
          </p>
        </div>
      </div>
      <span
        v-if="isAccessory && item.buttonTip"
        class="repick" 
        @click="handleRepick"
      >
        <span class="ellipsis">{{ item.buttonTip }}</span>
        <Icon 
          name="sui_icon_more_right_12px_2" 
          size="12px" 
          :is-rotate="GB_cssRight"
        />
      </span>
    </div>

    <s-dialog
      class="shein-club-notice"
      :visible.sync="dialogShow"
      show-close
      append-to-body
    >
      <template slot="title">
        {{ dialogInfo.title }}
      </template>
      <div v-html="dialogInfo.content">
      </div>
      <template
        slot="footer"
      >
        <s-button 
          :type="['primary', 'H72PX']"
          width="100%"
          @click="dialogShow = false"
        > 
          {{ dialogInfo.btnText }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import CountDown from './CountDown.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'

const { GB_cssRight } = gbCommonInfo
import CornerTag from './CornerTag.vue'
import { useCutImg } from 'public/src/pages/cart_v2/hooks/useCutImg.js'

export default {
  name: 'ItemBestChoice',
  setup() {
    const { cutImg } = useCutImg()
    return {
      cutImg
    }
  },
  components: { 
    CountDown, 
    Icon, 
    SAdapterText,
    CornerTag,
  },
  inject: ['openAppendageDrawer', 'openAddOn'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      GB_cssRight,
      isOverflow: false,
      dialogShow: false,
    }
  },
  computed: {
    goods() {
      return this.item?.products || []
    },
    dialogInfo() {
      return this.item?.questionPopupInfo || {}
    },
    isCoupon() {
      return this.item?.type === 'coupon'
    },
    isSheinClub() {
      return this.item?.type === 'creditReward'
    },
    isFreeShipping() {
      return this.item?.type === 'freeShipping'
    },
    isAccessory() {
      return [4, 28, 2, 22, 13].includes(+this.item?.typeId)
    },
    styleInfo() {
      return {
        '--box-bg': '#fff',
        '--box-border': this.isFreeShipping ? '#BFDBCF' : '#FDC1A5',
      }
    },
    countDownStyle() {
      if (this.isFreeShipping) {
        return {
          '--font-color': '#198055',
          '--num-bg-color': 'rgba(25, 128, 85, 0.08)',
        }
      }
      if (this.isSheinClub) {
        return {
          '--font-color': '#873C00',
        }
      }
      return {
        '--font-color': '#FA6338',
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isOverflow = this.checkOverflow(this.$refs.tips)
    })
  },
  methods: {
    handleAdd() {
      this.openAddOn(this.item)
    },
    handleRepick() {
      this.openAppendageDrawer?.(this.item?.promotionId)
    },
    checkOverflow(el) {
      // 多让是否省略用高度判断
      return el?.clientHeight < el?.scrollHeight
    },
    showNotice() {
      this.dialogShow = true
    }
  },
}
</script>


<style lang="less" scoped>
@BorderWidth: 1/37.5rem;
@CircleLeft: 84/37.5rem;
@CircleRadius: 4/37.5rem;
@CircleBgColor: #F3F2F3;
.item-best-choice {
  width: 100%;
  padding: 8/37.5rem 12/37.5rem;
  min-height: 60/37.5rem;
  position: relative;
  border: @BorderWidth solid var(--box-border);
  border-radius: 4/37.5rem;
  background: var(--box-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.is-coupon {
    &::before {
      content: '';
      position: absolute;
      width: @CircleRadius * 2;
      height: @CircleRadius;
      background-color: @CircleBgColor;
      border-radius: 0 0 @CircleRadius @CircleRadius;
      border: @BorderWidth solid var(--box-border);
      border-top: none;
      left: @CircleLeft - @CircleRadius;
      top: -@BorderWidth;
    }
    &::after {
      content: '';
      position: absolute;
      width: @CircleRadius * 2;
      height: @CircleRadius;
      background-color: @CircleBgColor;
      border-radius: @CircleRadius @CircleRadius  0 0;
      border: @BorderWidth solid var(--box-border);
      border-bottom: none;
      left: @CircleLeft - @CircleRadius;
      bottom: -@BorderWidth;
    }
  }
  .top {
    display: inline-flex;
    align-items: stretch;
  }
  .left-coupon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 72.5/37.5rem;
    margin-right: 8/37.5rem;
    padding-right: 8/37.5rem;
    overflow: hidden;
    border-right: 1px dashed #FDC1A5;
    .coupon-face {
      line-height: 20px;
      font-size: 16px;
      color: #FA6338;
      font-weight: bold;
    }
    .coupon-threshold {
      width: 100%;
      color: rgba(250, 99, 56, 0.70);
      text-align: center;
      font-size: 10px;
      line-height: 12px;
    }
  }
  .left-promotion {
    align-self: center;
    margin-right: 4/37.5rem;
  }
  .middle {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    min-height: 30/37.5rem;
    .tips {
      line-height: 15px;
      font-size: 12px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      position: relative;
      &.overflow-padding { /* stylelint-disable-line selector-max-specificity */
        padding-right: 12/37.5rem;
        .ico { /* stylelint-disable-line selector-max-specificity */
          position: absolute;
          right: 0;
          margin-left: 0;
        }
      }
      .ico { /* stylelint-disable-line selector-max-specificity */
        position: relative;
        bottom: 1px;
        margin-left: 2/37.5rem;
      }
    }
    .end-in {
      margin-top: 2/37.5rem;
    }
    .full-tip {
      color: @sui_color_gray_light1;
      font-size: 10px;
      line-height: 12px;
      margin-top: 2/37.5rem;
    }
  }
  .right-action {
    flex-shrink: 0;
    max-width: 54/37.5rem;
    font-size: 12px;
    margin-left: 8/37.5rem;
    display: inline-flex;
    align-items: center;
    margin: auto 0;
    font-weight: bold;
  }
  
  .bottom {
    padding-top: 6/37.5rem;
    padding-left: 20/37.5rem;
    display: flex;
    align-items: center;
    .repick {
      max-width: 54/37.5rem;
      font-size: 12px;
      margin-left: 8/37.5rem;
      display: inline-flex;
      align-items: center;
    }
    .goods-list {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;
      .goods { /* stylelint-disable-line selector-max-specificity */
        position: relative;
        margin-right: 8/37.5rem;
        width: 40/37.5rem;
        height: 40/37.5rem;
        border: 0.5px solid #FDC1AF;
        border-radius: 3/37rem;
        flex-shrink: 0;
        .goods-bottom { /* stylelint-disable-line selector-max-specificity */
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
          height: 14/37.5rem;
          line-height: 14/37.5rem;
          .tips { /* stylelint-disable-line selector-max-specificity */
            position: relative;
            font-size: 10px;
            z-index: 1;
          }
          &::before { /* stylelint-disable-line selector-max-specificity */
            content: ' ';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0.85;
            background-color: var(--bottom-bg-color);
          }
        }
      }
      .goods-desc { /* stylelint-disable-line selector-max-specificity */
        flex: 1;
        overflow: hidden;
        .attr { /* stylelint-disable-line selector-max-specificity */
          padding-top: 2/37.5rem;
          font-size: 10px;
          color: #999;
        }
      }
    }
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.shein-club-notice /deep/ .S-dialog__title {
  padding-bottom: 12/37.5rem;
}
</style>
