<template>
  <s-drawer
    type="full"
    :visible.sync="show"
    direction="rtl"
    append-to-body
    no-header
    immediately-render
    :close-on-click-modal="false"
  >
    <div class="j-address-box">
      <s-title-nav
        class="c-address-header"
        :title="language.SHEIN_KEY_PWA_34774"
      >
        <template slot="preblock">
          <s-title-nav-item
            ref="backBtn"
            icon-class="suiiconfont sui_icon_nav_back_24px"
            @click.native="clickCancel"
          />
        </template>
      </s-title-nav>
      <div class="c-address-content">
        <!-- <p class="c-title-tips" v-if="adrTitleTipsShow ">
                <i class="icon-zhuyi- iconfont she-fl"></i>
                <span>應台灣海關實名認證要求，請務必填寫真實姓名。</span>
                <i class="icon-close iconfont she-fr" @click="adrTitleTipsShow = false"></i>
            </p> -->
        <div class="address-bottom">
          <p
            class="c-name-tips"
            tabindex="0"
          >
            <template v-if="!isForbiddenEditName">
              {{ language.SHEIN_KEY_PWA_19575 }}
            </template>
            <template v-else>
              <p>
                {{ forbiddenTips[0] || '' }}
                <a
                  href="javascript:;"
                  style="color: #2d68a8; text-decoration: none"
                  @click="handleOpenRobot"
                >{{ language.SHEIN_KEY_PWA_19577 }}</a>
                {{ forbiddenTips[1] || '' }}
              </p>
            </template>
          </p>
          <input-vue
            :type="'fname'"
            :value="fname"
            :show="true"
            :label="getFieldLabel('fname')"
            :placeholder="language.SHEIN_KEY_PWA_34776"
            :error-show="error.fname.show"
            :error-value="error.fname.value"
            :sensitive-note="sensitiveNote.fname"
            :disabled="disabledTypes"
            :language="language"
            @change="inputChange"
            @focus="inputFocus"
            @blur="inputBlur"
            @clear="clearInput"
          />

          <certificate-tw
            v-if="certificateTwShow"
            ref="certificateTw"
            :language="language"
            :type="certificateType"
            :national-id-label="nationalIdLabelTw"
            :passport-number-label="passportNumberLabelTw"
            :disabled="disabledTypes"
            @select="selectModalShow = true"
            @change="changeCertificateType"
          />
          <input-vue
            :type="'tel'"
            :value="tel"
            :show="true"
            :label="getFieldLabel('tel')"
            :text="language.SHEIN_KEY_PWA_34787"
            :error-show="error.tel.show"
            :error-value="error.tel.value"
            :sensitive-note="sensitiveNote.tel"
            :tel-en="'TW'"
            :tel-num="'+886'"
            :language="language"
            :disabled="disabledTypes"
            @change="inputChange"
            @focus="inputFocus"
            @blur="inputBlur"
            @clear="clearInput"
          />
          <input-vue
            :type="'standbyTel'"
            :value="standbyTel"
            :show="true"
            :label="getFieldLabel('standbyTel')"
            :error-show="error.standbyTel.show"
            :error-value="error.standbyTel.value"
            :sensitive-note="sensitiveNote.standbyTel"
            :tel-en="'TW'"
            :tel-num="'+886'"
            :disabled="disabled"
            :language="language"
            @change="inputChange"
            @focus="inputFocus"
            @blur="inputBlur"
            @clear="clearInput"
          />
        </div>

        <div class="address-bottom store-type">
          <div class="addr-select-place">
            <label
              class="label-title"
              for="inputError"
              tabindex="0"
            >*{{ language.SHEIN_KEY_PWA_34789 }}:</label>
            <s-radio-group
              :value="Number(storeType)"
              @input="value => storeTypeChange(value)"
            >
              <s-radio :label="1">
                {{ language.SHEIN_KEY_PWA_34790 }}
              </s-radio>
              <s-radio :label="2">
                {{ language.SHEIN_KEY_PWA_34791 }}
              </s-radio>
            </s-radio-group>
          </div>
        </div>

        <div
          v-show="storeType"
          class="address-bottom store-address"
        >
          <div
            v-enterkey
            class="address-item"
            :class="{ 'focus-value': storeDetailAddress, 'check-false': error.storeAddress.show }"
            tabindex="0"
            @click="onClickSelectStore"
          >
            <label class="item-title">*{{ language.SHEIN_KEY_PWA_17828 || '' }}</label>
            <div class="item-input">
              <div
                class="address-input"
                v-html="storeDetailAddress || `*${language.SHEIN_KEY_PWA_17828 || ''}`"
              ></div>
              <i class="suiiconfont sui_icon_nav_back_24px_mir"></i>
            </div>
            <p
              v-if="error.storeAddress.show"
              class="text-error"
              tabindex="0"
              v-html="error.storeAddress.value || language.SHEIN_KEY_PWA_34801"
            ></p>
          </div>
        </div>

        <div
          v-if="keyShow.email"
          class="address-bottom email"
        >
          <input-vue
            :type="'email'"
            :value="email"
            show
            :label="`*${language.SHEIN_KEY_PWA_18384}`"
            :placeholder="language.SHEIN_KEY_PWA_14641"
            :error-show="error.email.show"
            :error-value="error.email.value"
            :disabled="disabledTypes"
            :language="language"
            @change="inputChange"
            @blur="inputBlur"
            @clear="clearInput"
          />
        </div>

        <auxiliary-policy :language="language" />

        <div class="address-save">
          <slot name="bottom">
          </slot>
          <div class="address-save__btn">
            <s-button
              :disabled="isDisabled"
              :loading="isSaveLoading"
              :type="['primary']"
              @click="clickSubmit"
            >
              {{ language.SHEIN_KEY_PWA_34792 }}
            </s-button>
          </div>
        </div>

        <p class="policy-link">
          <a
            href="javascript:;"
            @click="clickPrivacy"
          >{{ language.SHEIN_KEY_PWA_34793 }}</a>
        </p>
      </div>
    </div>
    <LazyMount mount-prop-name="addrshow">
      <address-cascader-store
        ref="addressCascaderStoreRef"
        :addrshow="addrshow"
        :type="storeConfig.type"
        :options="storeConfig.options"
        :is-shortcut="storeConfig.type !== 'store'"
        :tabsConfig="cascaderTabs"
        :store-type="storeType"
        :tab-select.sync="storeTabSelect"
        :language="language"
        @store-select="handleStoreSelect"
        @change="changeTabItem"
        @select="selectStore"
        @cancel="addrshow = false"
      />
    </LazyMount>
    <!-- 选择 滑出层-->
    <LazyMount>
      <s-drawer
        :visible.sync="selectModalShow"
        :size="2"
        append-to-body
      >
        <div class="j-certificate-select">
          <div class="header">
            <div
              ref="certificateTitle"
              class="title"
              tabindex="0"
            >
              {{ language.SHEIN_KEY_PWA_34777 }}
            </div>
            <div
              v-enterkey
              class="close"
              :aria-label="language.SHEIN_KEY_PWA_15567"
              role="button"
              tabindex="0"
              @click="selectModalShow = false"
            >
              <i class="iconfont icon-close"></i>
            </div>
          </div>
          <div class="content">
            <p
              class="tips"
              tabindex="0"
            >
              {{ language.SHEIN_KEY_PWA_34778 }}
            </p>
            <p
              v-enterkey
              class="select-item"
              :class="{ checked: certificateType === nationalIdLabelTw }"
              role="button"
              tabindex="0"
              :aria-checked="certificateType === nationalIdLabelTw"
              :aria-label="nationalIdLabelTw"
              @click="selectCertificateItem(nationalIdLabelTw)"
            >
              <span class="name">{{ language.SHEIN_KEY_PWA_34779 }}</span>
              <i class="iconfont icon-normal-checked"></i>
            </p>
            <p
              v-enterkey
              class="select-item"
              :class="{ checked: certificateType === passportNumberLabelTw }"
              role="button"
              tabindex="0"
              :aria-checked="certificateType === passportNumberLabelTw"
              :aria-label="passportNumberLabelTw"
              @click="selectCertificateItem(passportNumberLabelTw)"
            >
              <span class="name">{{ language.SHEIN_KEY_PWA_34780 }}</span>
              <i class="iconfont icon-normal-checked"></i>
            </p>
          </div>
        </div>
      </s-drawer>
    </LazyMount>
  </s-drawer>
</template>

<script>
import Vue from 'vue'
import addressCascaderStore from '../child_components/address_cascader_store.vue'
import auxiliaryPolicy from '../child_components/auxiliaryPolicy.vue'
import certificateTw from './certificate_tw.vue'
import inputVue from '../input.vue'
import addressStoreMixins from '../store_mixins.js'
import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import {
  Drawer,
  RadioGroup,
  Radio,
  TitleNav,
  TitleNavItem,
  Drawer as SDrawer,
  Button as SButton,
} from '@shein/sui-mobile'
import { TW_NAME_VERIFIED_ABT_KEY } from '../config'
import schttp from 'public/src/services/schttp'
import orderLogic from 'public/src/pages/common/orderLogic'
import { robotLinkCommon } from 'public/src/pages/common/utils/index.js'
import { LazyMount } from '@shein/sui-mobile'

const { langPath, TW_SERIVE_STORE_SEARCH_SWITCH } = gbCommonInfo
// 店配级联选择字段
const storeTypes = ['city', 'district', 'street', 'store']
Vue.use(Drawer)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(TitleNav)
Vue.use(TitleNavItem)
daEventCenter.addSubscriber({ modulecode: '2-5' })

export default {
  // async created () {
  //   await this.fetchData()
  //   await this.getAddressCheckRule()
  // },
  components: {
    addressCascaderStore,
    certificateTw,
    inputVue,
    auxiliaryPolicy,
    SDrawer,
    LazyMount,
    SButton,
  },
  mixins: [addressStoreMixins],
  props: {
    pageType: {
      type: String,
      default: 'user',
    },
  },
  data: function () {
    return {
      isSaveLoading: false,
      countryId: 209,
      disabled: [],
      language: {},
      langPath,
      show: false,
      ajaxUrl: '',
      ajaxType: '',
      formData: {},
      addrData: {},
      extra: [],
      addrshow: false,
      addrUpperType: 0,
      operateType: 'add',
      keyShow: {
        fname: true,
        lname: false,
        fatherName: false,
        englishName: false,
        tel: true,
        storeAddress: true,
        standbyTel: true,
        email: false
      },
      store_cache: {
        city: {},
        district: {},
        street: {},
        store: {}
      },
      error: {
        fname: {
          value: '',
          show: false
        },
        tel: {
          value: '',
          show: false
        },
        standbyTel: {
          value: '',
          show: false
        },
        storeAddress: {
          value: '',
          show: false
        },
        email: {
          value: '',
          show: false
        }
      },
      storeType: 1,
      fname: '',
      lname: '',
      tel: '09',
      standbyTel: '',
      city: '',
      district: '',
      street: '',
      store: '',
      email: '',
      cityList: [],
      districtList: [],
      streetList: [],
      storeList: [],
      adrTitleTipsShow: true,
      certificateTw: false,
      selectModalShow: false,
      certificateType: '',
      nationalIdLabelTw: '身份證',
      passportNumberLabelTw: '護照',
      certificateTwShow: false,
      storeAddress: '',
      storeCache: {},
      storeConfig: {
        type: 'city',
        tabs: [
          {
            key: 'storeId',
            title: 'SHEIN_KEY_PWA_34794'
          },
          {
            key: 'address',
            title: 'SHEIN_KEY_PWA_34795'
          }
        ],
        options: {
          city: {
            id: 'city',
            value: '',
            placeholder: 'SHEIN_KEY_PWA_34796',
            search: 'SHEIN_KEY_PWA_34854',
            subs: []
          },
          district: {
            id: 'district',
            value: '',
            placeholder: 'SHEIN_KEY_PWA_34804',
            search: 'SHEIN_KEY_PWA_34797',
            subs: []
          },
          street: {
            id: 'street',
            value: '',
            placeholder: 'SHEIN_KEY_PWA_34805',
            search: 'SHEIN_KEY_PWA_34798',
            subs: []
          },
          store: {
            id: 'store',
            value: '',
            placeholder: 'SHEIN_KEY_PWA_34799',
            search: 'SHEIN_KEY_PWA_34800',
            subs: []
          }
        }
      },
      isAccountEmailDone: false,
      isForbiddenEditName: false,
      storeTabSelect: '', // 选择门店/选择街道
      serviceStoreId: '', // 线下门店ID
      extraParams: {},
      storeInitData: {},
      extraData: {},
      transportType: '',
      extraRuleFlag: true,
      isSameTransportType: true
    }
  },
  computed: {
    certificateFocus() {
      return {
        show: this.selectModalShow,
        first: () => {
          setTimeout(() => {
            this.$refs.certificateTitle.focus()
          }, 500)
        }
      }
    },
    isDisabled() {
      return (!(
        this.fname &&
        this.tel &&
        this.storeType &&
        this.storeDetailAddress &&
        this.isAccountEmailDone &&
        (!this.keyShow.email || this.email)
      )) || this.isSaveLoading || !this.isSameTransportType
    },
    storeDetailAddress() {
      if (!(this.city && this.district && this.street && this.storeName && this.storeAddress)) {
        return ''
      }
      return `${this.city} / ${this.district} / ${this.street} / ${this.storeAddress} ${
        this.storeName
      }${this.serviceStoreId ? `-${this.serviceStoreId}` : ''}`
    },
    storeName: {
      get() {
        var storeName = ''
        if (this.storeList.length) {
          for (var i = 0; i < this.storeList.length; i++) {
            if (this.storeList[i].store_id == this.store) {
              storeName = this.storeList[i].store_name
              break
            }
          }
        }
        return storeName
      },
      set(name) {
        return name
      }
    },
    forbiddenTips() {
      if (!this.isForbiddenEditName) return []
      return this.language?.SHEIN_KEY_PWA_19660?.split('{0}') || []
    },
    disabledTypes() {
      return [...this.disabled, ...(this.isForbiddenEditName ? ['fname', 'tel'] : [])]
    },
    // 使用阿波罗开关来控制门店是否可以搜索
    cascaderTabs() {
      if (TW_SERIVE_STORE_SEARCH_SWITCH !== 1) {
        return this.storeConfig.tabs.filter(v => v.key !== 'storeId')
      }
      return [...this.storeConfig.tabs]
    }
  },
  watch: {
    isForbiddenEditName(nv) {
      if (nv) {
        // 联系客服按钮曝光埋点
        daEventCenter.triggerNotice({
          daId: '2-5-20',
          extraData: {
            tw_address: this.getEditScene()
          }
        })
      }
    }
  },
  methods: {
    async updateUI(opts) {
      this.sensitiveNote = {
        fname: '',
        lname: '',
        fatherName: '',
        englishName: '',
        tel: '',
        standbyTel: ''
      }
      if (!this.addressCheck) {
        const parmas = {
          countryId: this.countryId,
          transport_type: opts.extraData?.mallList?.[0]?.transportType,
          billno: opts?.extraData?.billno,
        }

        this.storeInitData =  await this.getStoreInitData(parmas)
        this.transportType = this.storeInitData?.storeOrderInfo?.info?.transport_type
        this.fetchData()
        this.getAddressCheckRule()
        this.getSensitiveCheckRule()
      }
      const orderAddress = await this.getOrderAddress({
        billno: opts?.extraData?.riskData?.order_no,
        countryId: this.countryId,
      })
      const {
        show = true,
        type,
        url,
        ajaxType = 'post',
        formData = {},
        extra = [],
        disabled = [],
        extraParams = {},
        extraData = {},
      } = opts
      const addrData = Object.assign(opts?.addrData, orderAddress)
      this.show = show
      this.operateType = type
      this.ajaxUrl = url
      this.ajaxType = ajaxType
      this.formData = formData
      this.addrData = addrData
      this.extra = extra
      this.extraParams = extraParams
      this.disabled = disabled
      this.extraData = extraData
      this.clearError()
      this.hideExtra()
      this.clearData(['email'])
      this.fname = addrData.fname
      this.lname = addrData.lname
      this.tel = addrData.tel
      this.standbyTel = addrData.standbyTel
      this.nationalId = addrData.nationalId
      this.getAccountEmail()
      // 新增时重置是否实名状态
      this.isForbiddenEditName = false
      this.storeTabSelect = this.cascaderTabs?.[0]?.key
      if (type == 'add') {
        this.bindStoreData('city')
        if (this.addressSensitiveCheck) {
          this.$nextTick(() => {
            for (let i in this.keyShow) {
              if (this.keyShow[i]) {
                if (typeof this.addressSensitiveCheck?.[i] === 'function') {
                  this.addressSensitiveCheck[i]()
                }
              }
            }
          })
        }
      } else if (type == 'edit') {
        this.storeType = addrData.storeType
        await this.bindStoreData('city')
        await this.checkTwNameVerified()
        this.city = addrData.city
        this.bindStoreData('district')
        this.district = addrData.district
        this.bindStoreData('street')
        this.street = addrData.street
        await this.bindStoreData('store')
        this.store = addrData.storeId
        this.storeName = addrData.storeName
        await this.getStoreAddress(this.store)
        this.checkBeforSubmit()
        if(addrData?.transportDiffAddress == '1') {
          this.handleError('storeAddress', true, this.language.SHEIN_KEY_PWA_31586)
          this.extraRuleFlag = false
          this.isSameTransportType = false
        }
      }
      this.$nextTick(() => {
        this.certificateTwShow && this.$refs.certificateTw.initData(addrData)
        // 风控初始化
        if(this.isNeedRiskControl){
          this.ininRiskControl()
        }
      })
    },
    async fetchData() {
      const data = this.storeInitData?.storeLanguage
      this.language = (data && data.language) || {}
    },
    clearEmailData() {
      this.isAccountEmailDone = false
      this.email = ''
      this.keyShow.email = false
    },
    async getStoreInitData(parmas){
      const res = await schttp({
        method: 'POST',
        url: '/api/user/addressbook/getStoreInitData/get',
        data: parmas
      })

      return res
    },
    getAccountEmail() {
      this.clearEmailData()
      this.getAccountAndOrderEmail()
      if (this.operateType !== 'add') {
        this.$nextTick(() => {
          this.addressCheck?.email()
        })
      }
      this.isAccountEmailDone = true
    },
    getAccountAndOrderEmail() {
      const res = this.storeInitData?.storeAccountAndOrderEmail
      const { info: { order_email, register_email } = {} } = res || {}
      this.keyShow.email = !register_email
      this.email = order_email
    },
    async getStoreAddress(storeId) {
      if (this.storeCache[storeId]) {
        this.storeAddress = this.storeCache[storeId].address1
      } else {
        const res = await schttp({
          url: '/api/user/addressbook/storeInfoById/get',
          params: {
            storeId
          }
        })
        if (res.code == 0) {
          this.storeCache[storeId] = res.info.res
          this.storeAddress = res.info.res.address1
          this.serviceStoreId = res.info.res.serviceStoreId || ''
        }
      }
    },
    hideExtra() {
      const extra = this.extra
      this.certificateTwShow = !extra.length || !extra.includes('certificateTw')
    },
    itemFocus(type) {
      this.$refs[type].focus()
    },
    clearData(extra = []) {
      for (const i in this.keyShow) {
        if (this[i] && extra.indexOf(i) == -1) {
          this[i] = ''
        }
      }
      this.tel = '09'
      this.storeType = 1
    },
    clearError() {
      for (var i in this.error) {
        this.error[i].show = false
      }
    },
    inputFocus(type) {
      if (this.sensitiveNote) {
        this.sensitiveNote[type] = ''
      }
    },
    inputBlur(type) {
      this.addressCheck[type] && this.addressCheck[type]()
      this.addressSensitiveCheck?.[type]?.()
    },
    inputChange(type, value) {
      this[type] = value
      type == 'tel' && this.inputTel()
    },
    clearInput(type) {
      this[type] = type == 'tel' ? '09' : ''
    },
    inputTel() {
      const value = this.tel
      this.$nextTick(() => {
        this.tel = value ? '09' + value.substr(2) : '09'
      })
    },
    storeTypeChange(type) {
      this.storeType = type
      this.bindStoreData('city')
      this.storeAddress = ''
      storeTypes.forEach(type => {
        this[type] = ''
        this.storeConfig.options[type] = {
          ...this.storeConfig.options[type],
          value: '',
          subs: []
        }
        if (type !== 'city') {
          this[`${type}List`] = []
        }
      })
    },
    async onClickSelectStore() {
      if (this.city && this.district && this.street && this.store) {
        storeTypes.forEach(async type => {
          this.storeConfig.options[type].value =
            type === 'store'
              ? `${this.storeAddress}***${this.storeName}${
                  this.serviceStoreId ? `-${this.serviceStoreId}` : ''
                }`
              : this[type]
          await this.bindStoreData(type)
        })
        await this.getStoreAddress(this.store)
        this.storeConfig.type = 'store'
      } else {
        await this.bindStoreData('city')
        storeTypes.forEach(type => {
          this.storeConfig.options[type].value = ''
        })
        this.storeConfig.type = 'city'
      }
      this.addrshow = true
    },
    changeTabItem(type) {
      this.storeConfig.type = type
    },
    getConfigItemValue(item, type) {
      return type === 'store'
        ? `${item.address1}***${item.store_name}${
            item.service_store_id ? `-${item.service_store_id}` : ''
          }`
        : item[type]
    },
    updateStoreConfig(type) {
      const cacheKey = `${type}List`
      this.storeConfig.options = {
        ...this.storeConfig.options,
        [type]: {
          ...this.storeConfig.options[type],
          subs: this[cacheKey]
            ? this[cacheKey].map(item => this.getConfigItemValue(item, type))
            : []
        }
      }
      // this.storeConfig.type = type
    },
    async selectStore({ type = '', value = '' }) {
      if (type === 'store') {
        const [storeAddress, storeNameAndServiceStoreId] = value.split('***')
        const [storeName, serviceStoreId] = storeNameAndServiceStoreId.split('-')
        this.store = this.getStoreIdByName(storeName)
        this.storeAddress = storeAddress
        this.serviceStoreId = serviceStoreId || ''
        storeTypes.forEach(item => {
          if (item == 'store') {
            this.storeName = storeName
          } else {
            this[item] = this.storeConfig.options[item].value
          }
        })
        this.addrshow = false
        this.inputBlur('storeAddress')
        return
      }
      this.storeConfig.options[type].value = value
      const index = storeTypes.findIndex(item => item === type)
      storeTypes.forEach((type, typeIndex) => {
        if (typeIndex > index) {
          this.storeConfig.options[type] = {
            ...this.storeConfig.options[type],
            value: '',
            subs: []
          }
        }
      })
      const nextType = storeTypes[index + 1]
      this.storeConfig.type = nextType
      await this.bindStoreData(nextType, true)
      const nexCacheKey = `${nextType}List`
      if (this[nexCacheKey].length === 1) {
        const nextValue = this.getConfigItemValue(this[nexCacheKey][0], nextType)
        this.selectStore({ type: nextType, value: nextValue })
      }
      this.extraRuleFlag = true
      this.isSameTransportType = true
      this.handleError('storeAddress', false, '')
    },
    getStoreIdByName: function (name) {
      var id = ''
      for (var i = 0; i < this.storeList.length; i++) {
        if (this.storeList[i].store_name == name) {
          id = this.storeList[i].store_id
          break
        }
      }
      return id
    },
    async bindStoreData(level, isTabSelect = false) {
      const toType = level
      const type = this.storeType
      let cacheId = ''
      const param = {
        countryId: 209,
        type,
        toType: toType == 'store' ? 'store_name' : toType
      }

      const city = isTabSelect ? this.storeConfig.options.city.value : this.city
      const district = isTabSelect ? this.storeConfig.options.district.value : this.district
      const street = isTabSelect ? this.storeConfig.options.street.value : this.street
      if (toType == 'city') {
        cacheId = `${toType}_${type}`
      } else if (toType == 'district') {
        cacheId = `${toType}_${type}_${city}`
        param.city = encodeURIComponent(city)
      } else if (toType == 'street') {
        cacheId = `${toType}_${type}_${city}_${district}`
        param.city = encodeURIComponent(city)
        param.district = encodeURIComponent(district)
      } else if (toType == 'store') {
        cacheId = `${toType}_${type}_${city}_${district}_${street}`
        param.city = encodeURIComponent(city)
        param.district = encodeURIComponent(district)
        param.street = encodeURIComponent(street)
      }
      if (!this.store_cache[level][cacheId]) {
        try {
          const json = await schttp({
            url: '/api/user/addressbook/storeAddressByParentName/get',
            params: param
          })
          if (json.code == 0) {
            this.store_cache[level][cacheId] = json && json.info && json.info.res // 缓存city
            this.updateList(level, cacheId)
          }
        } catch (error) {
          return
        }
      } else {
        this.updateList(level, cacheId)
      }
    },
    updateList: function (level, cacheId) {
      this[level + 'List'] = this.store_cache[level][cacheId]
        ? this.store_cache[level][cacheId]
        : []
      this.updateStoreConfig(level)
    },
    getData(){
      const data = {
        countryId: 209,
        fname: this.fname,
        lname: this.lname,
        tel: this.tel,
        storeId: this.store,
        nationalId: this.nationalId,
        transport_type: this.extraData?.mallList?.[0]?.transportType || this.transportType
      }
      const standByName = this.pageType === 'order' ? 'standby_tel' : 'standbyTel'
      data[standByName] = this.standbyTel
      if (this.certificateTwShow) {
        data.nationalId = this.$refs.certificateTw.getNationalId()
        data.passport_number = this.$refs.certificateTw.getPassportNumber()
      }
      if (this.keyShow.email) {
        data.order_email = this.email
      }
      if (this.riskIdForKafka) {
        data.riskIdForKafka = this.riskIdForKafka
        this.riskIdForKafka = ''
      }
      return data
    },
    async postSubmitData() {
      this.$emit('loading', true)
      const data = this.getData()
      try {
        const res = await schttp({
          method: this.ajaxType,
          url: this.ajaxUrl,
          data: $.extend(data, this.formData, this.extraParams),
          params:
              this.ajaxUrl?.toUpperCase() === 'GET'
                ? $.extend(data, this.formData, this.extraParams)
                : {}
        })
        this.$emit('loading', false)
        if (res.code == 0) {
          const storeItem = this.storeList.filter(item => {
            return item.store_id == this.store
          })
          const storeName = storeItem && storeItem.length && storeItem[0].store_name
          res.formData = $.extend(data, {
            storeType: this.storeType,
            city: this.city,
            district: this.district,
            street: this.street,
            storeName
          })
          this.show = false
        } else if (res.code == 402001) {
          this.$toast(this.language.SHEIN_KEY_PWA_31586)
          this.handleError('storeAddress', true, this.language.SHEIN_KEY_PWA_31586)
          this.extraRuleFlag = false
          this.isSameTransportType = false
          return
        }
        this.$emit('save', res)
      } catch (error) {
        console.error(error)
      }
    },
    handleError(field, show, error){
      if(!(this.error?.[field] != null)) return
      this.error[field].value = error
      this.error[field].show = show
    },
    async clickSubmit() {
      var { editAddressFlag } = this.addrData || {}
      if (this.checkBeforSubmit()) {
        const data = this.getData()
        if (editAddressFlag && !orderLogic.hasDiffParamsValue(this.addrData, data)) {
          this.show = false
          this.$emit('save', {
            code: 0,
            formData: $.extend(this.addrData, data)
          })
          return false
        }
        const riskFlag = await this.handleRiskControl()
        if(!riskFlag){
          return
        }
        await this.postSubmitData()
      }
    },
    cancelClick: function () {
      this.$emit('cancel')
    },
    checkBeforSubmit: function () {
      let flag = true
      for (let i in this.keyShow) {
        if (this.keyShow[i]) {
          if (typeof this.addressCheck[i] === 'function') {
            flag = this.addressCheck[i]() && flag
          }
          if (typeof this.addressSensitiveCheck?.[i] === 'function') {
            this.addressSensitiveCheck[i]()
          }
        }
      }
      // 台湾实名认证校验
      this.certificateTwShow && (flag = this.$refs.certificateTw.checkBeforSubmit() && flag)
      return flag
    },
    clickCancel() {
      this.show = false
      this.$emit('cancel')
    },
    clickPrivacy() {
      window.SHEIN_GLOBAL_PRIVACY.show({ type: 'privacy' })
      // this.$emit('privacy')
    },
    changeCertificateType(value) {
      this.certificateType = value
    },
    selectCertificateItem(type) {
      this.certificateType = type
      this.selectModalShow = false
      this.$refs.certificateTw.selectItem(type)
    },
    /**
     * @description 台湾 - 判断地址信息是否已实名，仅当开启实验才需要判断是否实名，已实名则不允许修改姓名和电话，否则默认可以修改
     * @date 2021-12-13 19:03
     */
    async checkTwNameVerified() {
      try {
        // eslint-disable-next-line @shein-aidc/abt/abt
        const abtInfo = await abtservice.getUserAbtResult({ posKeys: TW_NAME_VERIFIED_ABT_KEY })
        const istwMustNameVerifiedAbt = abtInfo?.[TW_NAME_VERIFIED_ABT_KEY]?.param === 'on'
        // 账单地址不涉及
        if (istwMustNameVerifiedAbt) {
          const checkTwNameInfo = await this.postCheckTwName()
          // 已验证，姓名、电话表单置灰不可修改
          this.isForbiddenEditName = checkTwNameInfo === '1'
        } else {
          this.isForbiddenEditName = false
        }
      } catch (e) {
        this.isForbiddenEditName = false
      }
    },
    async postCheckTwName() {
      const params = {
        country_id: this.countryId,
        fname: this.fname,
        lname: this.lname,
        tel: this.tel,
        scene: '3' // 1 - 前端修改订单地址；2 - 下单页查询是否实名；3 - 前端用户地址修改
      }
      const data = await schttp({
        url: '/api/user/addressbook/checkTwName/get',
        params
      })
      if (data.code == 0) {
        return data.info?.result?.name_verified
      } else {
        return data.info
      }
    },
    getEditScene() {
      if (typeof window !== 'undefined') {
        const { page_name } = SaPageInfo || {}
        switch (page_name) {
          case 'page_order_detail': // 订单详情页
          case 'page_abnormal_orderlist': // 海关拦截页
          case 'page_return_item_new': // 退货页
          case 'page_payment_successful': // 支付成功页
          case 'page_checkout_again': // 二次下单页
            return 'store_order_address'
          default:
            return 'store_user_address'
        }
      }
      return ''
    },
    handleOpenRobot() {
      daEventCenter.triggerNotice({
        daId: '2-5-19',
        extraData: {
          tw_address: this.getEditScene()
        }
      })
      this.$routerPush(robotLinkCommon())
    },
    /**
     * @description FR-7799 门店列表选择门店后的逻辑处理
     * @date 2022-02-10 11:00
     */
    async handleStoreSelect(info) {
      this.storeType = info.store_type
      this.store = info.store_id
      this.city = info.city
      this.district = info.district
      this.street = info.street
      // TODO 因为旧逻辑会有一个校验是否在storeList中，因此需要先更新一下storeList
      await this.bindStoreData('store')
      this.storeName = info.store_name
      this.storeAddress = info.address1
      this.serviceStoreId = info.service_store_id
      this.$refs?.addressCascaderStoreRef?.resetInfo?.()
      this.addrshow = false
      this.inputBlur('storeAddress')
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable */
.j-address-box {
  .c-address-content {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 1.2rem;
    .disable-gray {
      opacity: 0.3;
    }
    .address-bottom {
      margin: 0;
      border-top: 0.32rem solid #f6f6f6;
      background-color: #fff;
      &.supplement-border {
        border: none;
      }
      .c-name-tips {
        color: #666;
        background: #f6f6f6;
        padding: 0 0.32rem 0.05rem;
      }
      .address-item {
        position: relative;
        background-color: #fff;
        margin-top: 0.32rem;
        padding: 0 0.32rem;
        .item-title {
          display: none;
          position: absolute;
          .font-dpr(24px);
          color: #767676;
          top: 0;
          text-indent: 0;
          z-index: 1; 
          transform: translate3d(0, 0, 1px);
          .icon-pro {
            .font-dpr(28px);
          }
        }
        .item-input {
          position: relative;
          .flexbox;
          .align-center;
          .border-dpr(border-bottom, 2px, #e5e5e5);
          &.no-border {
            border: none;
          }
          .new_tel_num {
            display: inline-block;
            padding: 0.346666rem 0;
            .font-dpr(28px);
            box-sizing: content-box;
            &::after {
              content: '';
              position: relative;
              top: 2px;
              display: inline-block;
              width: 1px;
              height: 0.35333rem;
              margin: 0 0.21333rem;
              background: #e5e5e5;
            }
            select {
              border: none;
              background: none;
            }
          }
          .sui_icon_more_down_16px,
          .sui_icon_nav_back_24px_mir {
            color: @sui_color_gray_light1;
          }
          .input-area {
            flex: 1;
          }
          .address-input {
            flex: 1;
            width: 100%;
            border: none;
            .font-dpr(28px);
            margin: 0.426666rem 0;
            .padding-r(0.5rem);
            color: #222;
            .txt-l();
            &.font-bold {
              font-weight: bold;
            }
            &.strong-tip {
              &::-webkit-input-placeholder {
                color: #222 !important; 
              }
              &:-moz-placeholder {
                color: #222 !important; 
              }
              &::-moz-placeholder {
                color: #222 !important; 
              }
              &:-ms-input-placeholder {
                color: #222 !important; 
              }
            }
            &.textarea-input {
              padding: 0.326666rem 0;
            }
            &::-webkit-input-placeholder {
              /* WebKit browsers*/
              color: #ccc !important; 
            }
            &::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #ccc !important; 
              opacity: 1 !important; 
            }
            &:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #ccc !important; 
              opacity: 1 !important; 
            }
            &:-ms-input-placeholder {
              /* Internet Explorer 10+*/
              color: #ccc !important; 
            }
            &:disabled {
              background: transparent;
            }
          }
          .sui_icon_more_down_16px,
          .sui_icon_nav_back_24px_mir {
            position: absolute;
            .right(0);
            top: 0.34rem;
            color: @sui_color_gray_light1;
          }
          .icon-clear1 {
            position: absolute;
            z-index: 2000; 
            .right(0.13333rem);
            bottom: 0.16rem;
            color: #ccc;
            .font-dpr(32px);
          }
        }
        .text-error {
          .font-dpr(24px);
          padding: 0.10666rem 0 0.32rem;
          color: @sui_color_unusual;
          &.gray-color {
            color: #767676;
          }
          & + .search-area {
            margin-top: -0.32rem;
          }
          & + .text-error {
            margin-top: -0.213rem;
          }
        }
        .text-placeholer {
          .flexbox;
          .align-center;
          position: absolute;
          height: 1.28rem;
          top: 0;
          color: #999;
          .font-dpr(24px);
          line-height: 1.5;
          width: 100%;
          overflow: hidden;
          word-break: break-all;
          .title {
            white-space: nowrap;
            color: #767676;
          }
          .gray-text {
            .margin-l(0.213rem);
            color: #bbbbbb;
            word-break: break-word;
          }
          .icon-pro {
            color: #222;
            .font-dpr(28px);
            margin: 0 0.1rem;
          }
        }
        &.focus-value {
          .item-title {
            display: inline-block;
          }
          .item-input {
            .new_tel_num {
              padding: 0.383333rem 0 0;
            }
            .address-input {
              margin: 0.613333rem 0 0.24rem;
              .font-dpr(28px);
            }
          }
          .text-placeholer {
            display: none;
          }
        }
        &.check-false {
          .item-input {
            border-bottom: 1px solid @sui_color_unusual;
          }
        }
        .text-placeholer-fixed {
          position: fixed;
          bottom: 0;
          width: 100%;
          background: #e5e5e5;
          z-index: 99999; 
          display: block;
          transform: translate3d(0, 0, 99999px);
          left: 0;
          padding: 0.12rem 0.36rem;
        }
        .recommend-phone {
          position: absolute;
          top: 1.28rem;
          .left(0.32rem);
          transform: translateZ(10px);
          padding: 0 0.267rem;
          .font-dpr(28px);
          height: 1.213rem;
          line-height: 1.213rem;
          box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.1);
          width: calc(100% - 0.64rem);
          border-radius: 1px;
          background: #fff;
        }
        .search {
          position: relative;
          &__input {
            display: flex;
            height: 0.853rem;
            line-height: 0.853rem;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.267rem;
            background: #fff;
            .border-dpr(border, 2px, #e5e5e5);
            .content {
              &::-webkit-input-placeholder {
                /* WebKit browsers*/
                .font-dpr(24px);
                color: #ccc !important; 
              }
              &::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                .font-dpr(24px);
                color: #ccc !important; 
              }
              &:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                .font-dpr(24px);
                color: #ccc !important; 
              }
              &:-ms-input-placeholder {
                /* Internet Explorer 10+*/
                .font-dpr(24px);
                color: #ccc !important; 
              }
              flex: 1;
              padding: 0 0.187rem;
              height: 100%;
              .font-dpr(28px);
              border: none;
              background: #fff;
            }
            .sui_icon_nav_search_24px {
              color: #999;
            }
            .icon-clear1 {
              color: #ccc;
            }
          }
          .list-panel {
            position: absolute;
            z-index: 100; 
            top: 1rem;
            width: 9.36rem;
            background: #fff;
            box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.1);
            .adr-search-list {
              padding: 0 0.213rem;
              max-height: 5.1rem;
              overflow-y: scroll;
              > li {
                position: relative;
                .font-dpr(28px);
                line-height: 1.2;
                padding: 0.32rem 0;
                .padding-r(0.267rem);
                .border-dpr(border-bottom, 2px, #e5e5e5);
                .gray {
                  color: #999;
                  .padding-r(0.32rem);
                }
                .sui_icon_nav_back_24px_mir {
                  position: absolute;
                  right: 0.25rem;
                  top: 50%;
                  margin-top: -6px;
                  color: #999;
                  font-weight: bold;
                }
                &:last-child {
                  border-bottom: none;
                }
              }
            }
            .manual {
              display: flex;
              justify-content: center;
              padding: 0.213rem 0.32rem;
              .border-dpr(border-top, 2px, #e5e5e5);
              background: #fff;
              .btn {
                color: @sui_color_link;
              }
            }
          }
        }
        .manual-btn {
          display: inline-block;
          padding: 0.32rem 0;
          color: @sui_color_link;
        }
        .search-area {
          display: block;
          padding-top: 0.106rem;
          color: #999;
          .btn {
            color: #1860a7;
            text-decoration: none;
            & when(@IS_RW) {
              color: #47a2f2;
            }
          }
        }
        .c-adr-tip {
          position: absolute;
          top: calc(100% + 0.213rem);
          .left(0.32rem);
          padding: 0.32rem;
          width: calc(100% - 0.64rem);
          color: #666;
          .border-dpr(border, 2px, #e5e5e5);
          background: #fff;
          z-index: 2; 
          transform: translate3d(0, 0, 2px);
          box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
          &:before {
            position: absolute;
            top: -0.1rem;
            .left(50%);
            background: #fff;
            display: block;
            content: '';
            width: 0.213rem;
            height: 0.213rem;
            transform: rotate(45deg);
          }
        }
      }
      .passport-wrap {
        .passport-title {
          padding: 0.32rem;
          .passport-value {
            position: relative;
            color: #262626;
            .font-dpr(28px);
            [class*='iconfont'] {
              position: absolute;
              .right(0);
            }
          }
          .passport-tips {
            color: #999;
            .font-dpr(24px);
            a {
              color: #999;
              text-decoration: none;
            }
          }
        }
        .address-item {
          &:first-child {
            margin-top: 0;
          }
          .date-title {
            color: #999;
          }
          .adr-select {
            border-radius: 0;
            box-sizing: border-box;
            display: block;
            width: 9.2rem;
            padding: 0.36rem 0.266667rem;
            background: #fff;
            border: none;
            color: #222;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            -ms-appearance: none;
            background-image: url(/pwa_dist/images/down-arrow-6b9adfb1d9.png);
            background-size: 0.33333rem 0.33333rem;
            background-position: 100% center;
            background-repeat: no-repeat;
          }
          .icon-pro {
            .font-dpr(36px);
          }
        }
      }
      .adr-nearest {
        padding: 0.16rem 0.32rem 0.26666rem;
        > span {
          .font-dpr(28px);
          color: @sui_color_link;
          text-decoration: underline;
        }
      }
      &.find {
        position: relative;
        z-index: @zindex-out;
        transform: translate3d(0, 0, 2px);
        & + .address-bottom {
          border-top: 0.32rem solid #fff;
        }
      }
    }

    .addr-select-place {
      .flexbox;
      .align-center;
      height: 1.17333rem;
      padding: 0 0.32rem;
      .label-title {
        .font-dpr(26px);
        margin-right: 0.53333rem;
      }
      .input-icon {
        .flexbox;
        label {
          .flexbox;
          .margin-r(0.21333rem);
          margin-bottom: 0;
          .font-dpr(28px);
          .name {
            .flexbox;
            .align-center;
            .pack-center;
            min-width: 1.57333rem;
            height: 0.66666rem;
            border: 1px solid #e5e5e5;
            color: #222;
            margin-right: 0;
            margin-left: 0;
            padding: 0 0.2rem;
            box-sizing: border-box;
            & when (@IS_RW) {
              height: 0.72rem;
              color: #999;
              background: #f6f6f6;
              border: none;
            }
          }
          .j-address-type {
            display: none;
          }
          .j-address-type:checked + .name {
            .border-dpr(border, 2px, #222);
            & when (@IS_RW) {
              color: @color_brand;
              background: #ffefed;
              .border-dpr(border, 2px, @color_brand);
            }
          }
        }
      }
    }
    .address-bottom {
      &.store-type {
        height: auto;
      }
      &.store-address {
        border-top: none;
        .address-item {
          margin-top: 0;
          .border-dpr(border-top, 2px, #e5e5e5);
          &.focus-value {
            .item-title {
              margin-top: 0.24rem;
            }
            .item-input .address-input {
              margin-top: 0.85333rem;
            }
          }
        }
      }
      .addr-select-place {
        display: block;
        padding-top: 0.32rem;
        padding-bottom: 0.28rem;
        height: auto;
        .radio-wrapper {
          display: flex;
          label {
            align-items: center;
            .margin-r(0);
            &:first-child {
              .margin-r(2.187rem);
            }
          }
          .name {
            .padding-r(0);
            border: none;
          }
        }
      }
    }
    .address-save {
      margin: 0.267rem 0 0.82666rem;
      button {
        width: 100%;
      }
      &__btn {
        padding: 0 0.32rem;
      }
    }
    .policy-link {
      text-align: center;
      margin-bottom: 0.5rem;
      color: #767676;
      a {
        text-decoration: underline;
      }
    }
  }
}
.j-certificate-select {
  .header {
    .flexbox();
    .align-center();
    height: 1.06666rem;
    padding: 0 0.52rem;
    .border-dpr(border-bottom, 2px, #e5e5e5);
    .title {
      .font-dpr(28px);
      color: #999;
      flex: 1;
      text-align: center;
    }
    .close {
      position: absolute;
      .right(0.2rem);
      top: 0.2rem;
      .icon-close {
        color: #999;
      }
    }
  }
  .content {
    max-height: 10rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 0.32rem;
    .tips {
      .font-dpr(24px);
      color: #999;
      line-height: 1.4;
      padding: 0.32rem 0.32rem 0.64rem;
      text-align: center;
    }
    .select-item {
      position: relative;
      .font-dpr(28px);
      color: #222;
      padding: 0.32rem 0.533333rem;
      .icon-normal-checked {
        display: none;
        position: absolute;
        .right(0.37rem);
        .font-dpr(28px);
      }
      &.checked {
        font-weight: bold;
        .icon-normal-checked {
          display: inline-block;
        }
      }
    }
  }
}
</style>
