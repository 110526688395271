import { computed } from 'vue'
import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'

/**
 * @description 头部轮播标题
 * @param {mallIndex} 当前 mall 索引
 * @returns {swiperTitleConfig} 轮播标题
 * */
export default function useHeaderTitle(mallIndex) {
  const { cartsInfo, shoppingBagsState } = useMapState(['cartsInfo', 'shoppingBagsState'])
  const { shoppingBagHeadInfoMap } = useMapGetters(['shoppingBagHeadInfoMap'])

  // const swiperTitleConfig_mock = [
  //   {
  //       "text": {
  //           "label": "4 items cheaper than added 4 items cheaper than added",
  //           "color": "#FA6338"
  //       },
  //       "icon": {
  //           "name": "",
  //           "color": ""
  //       },
  //       "img": {
  //           "url": "https://img.ltwebstatic.com/images3_ccc/2024/08/06/b2/1722950302b5a4cb3821c7c46781aeb62e9d64442f.png",
  //           "style": {
  //               "width": "12px"
  //           }
  //       }
  //   },
  //   {
  //       "text": {
  //           "label": "3 items are almost soldout!",
  //           "color": "#FA6338"
  //       },
  //       "icon": {
  //           "name": "",
  //           "color": ""
  //       },
  //       "img": {
  //           "url": "https://img.ltwebstatic.com/images3_ccc/2024/08/06/93/17229502797fa39b7866719ef2e0edb3e3c397883e.png",
  //           "style": {
  //               "width": "12px"
  //           }
  //       }
  //   }
  // ]

  const swiperTitleConfig = computed(() => {
    const result = (
      cartsInfo?.value?.good_by_mall?.[mallIndex]?.shoppingBagScrollBarTips || []
    ).map(item => {
      const shoppingBagHeadInfo = shoppingBagHeadInfoMap.value?.[shoppingBagsState.value?.bagsInfo?.mall?.[mallIndex]?.mall_code]
      if (['3', '5'].includes(shoppingBagHeadInfo?.type)) return

      return {
        text: {
          label: item?.displayText || '',
          color: item?.textColor || ''
        },
        icon: {
          name: '',
          color: ''
        },
        img: {
          url: item?.imgIconUrl || '',
          style: { width: '12px' }
        }
      }
    }).filter(Boolean)
    return result
  })
  return { swiperTitleConfig }
}
