<template>
  <div
    v-if="label"
    class="footer-cart-label__normal"
    :class="[
      `footer-cart-label__normal_${label.type}`,
      labelType === 'save' && labelText !== 'SAVE' && 'save-price',
    ]"
    :style="cssVars"
  >
    <svg
      v-if="label.type === 'freeshipping'"
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.70418 1.95992V0.5H0.505127V2.82253V5.64669V7.72576H1.38061C1.61999 6.88755 2.39173 6.27393 3.30679 6.27393C4.22185 6.27393 4.99359 6.88755 5.23297 7.72576H5.80847C6.04786 6.88755 6.81959 6.27393 7.73465 6.27393C8.6497 6.27393 9.42144 6.88754 9.66083 7.72574H10.2849V2.8036L7.70418 1.95992ZM7.70418 4.72558V3.01201L9.28455 3.52858V4.72558H7.70418Z"
        fill="white"
      />
      <circle
        cx="7.7583"
        cy="8.27686"
        r="1"
        fill="white"
      />
      <circle
        cx="3.30688"
        cy="8.27686"
        r="1"
        fill="white"
      />
    </svg>
    <svg
      v-if="label.type === 'gift'"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.16068 3.33313L8.33708 2.25969L7.66304 1.521L6.00506 3.03387L4.39965 1.52585L3.715 2.25472L4.86306 3.33313H1.16675L1.16681 6.49976H1.83341V10.9998H10.1667V6.49976H10.8335L10.8334 3.33313H7.16068ZM9.16675 6.49976H2.83341V9.99976H9.16675V6.49976ZM9.83348 5.49976L9.83342 4.33313H2.16675L2.16681 5.49976H9.83348Z"
        fill="white"
      />
    </svg>
    <svg
      v-if="labelText === 'SAVE'"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M1.08385 3.82435L3.89191 6.6324L6.1298 4.39451L10.1931 8.45767"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M10.5219 4.59961L10.5219 9.00049L6.12103 9.00049"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
    <img
      v-if="labelIcon"
      :src="labelIcon"
      class="footer-cart-label__normal-img"
    />
    <span class="footer-cart-label__normal-text">{{ labelText }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  label: {
    type: Object,
    default: null,
  },
})

const labelType = computed(() => props.label?.type)
const labelIcon = computed(() => props.label?.icon)

const labelText = computed(() => {
  if (!props.label) return ''
  if (labelType.value === 'save' && props.label.text.length > 7) {
    return 'SAVE'
  }
  return props.label.text
})

const cssVars = computed(() => {
  if (!props.label) return {}
  let defaultBgColor = '#fa6338'
  if (labelType.value === 'freeshipping') defaultBgColor = '#198055'
  return {
    '--label-color': props.label.textColor || '#fff',
    '--label-background-color': props.label.bgColor || defaultBgColor,
  }
})
</script>

<style lang="less">
.footer-cart-label {
  &__normal {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 2px;
    max-width: 52px;
    height: 15px;
    font-size: 12px;
    white-space: nowrap;
    color: #fff;
    color: var(--label-color, #fff);
    background-color: #fa6338;
    background-color: var(--label-background-color, #fa6338);
    border-radius: 8px;
    overflow: hidden;

    &.save-price {
      justify-content: center;
      padding: 0 6px;
      height: 20px;
      transform: scale(0.75);
      border-radius: 10px;
      transform-origin: top;
      .footer-cart-label__normal-text {
        margin: 0;
        transform: scale(1);
      }
    }
  }

  &__normal_freeshipping {
    padding: 0 2px 0 4px;
  }

  &__normal_gift {
    padding: 0 2px 0 4px;
  }

  &__normal-text {
    margin: 0 -5px 0 1px;
    transform: scale(0.75);
    transform-origin: left;
  }
}
</style>
