<template>
  <span
    :class="[
      'benefit-tag',
      `benefit-tag_${typeMap[type]}`,
    ]"
  >
    {{ text }}
  </span>
</template>
<script name="BenefitTag" setup lang="ts">
import { E_BenefitFloor } from '../../../types'

const typeMap = {
  [E_BenefitFloor.TagType.NewUser]: 'new',
  [E_BenefitFloor.TagType.Prime]: 'prime',
}

defineProps<{
  type: E_BenefitFloor.TagType,
  text: string,
}>()

</script>
<style lang="less" scoped>
.benefit-tag {
  display: inline-flex;
  align-items: center;
  height: 28/75rem;
  margin-left: 16/75rem;
  padding: 0 8/75rem;
  border-radius: 4/75rem 0;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  color: #fff;

  &_new {
    border: 1/75rem solid #FF3351;
    background: linear-gradient(92deg, #FF858F 1.06%, #FF6A76 1.07%, #FF3351 42.94%, #FF3351 90.62%, #FF6A76 99.85%);
  }

  &_prime {
    background: #873C00;
    border: 1/75rem solid #873C00;
  }
}
</style>
