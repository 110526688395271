import { computed } from 'vue'
import { hexToRgb } from '@shein/common-function'
import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'
import {
  remToPx,
  getActualWidthOfChars
} from '@/public/src/pages/checkout_new/utils'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'
import useAttribute from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useAttribute.js'

/**
 * @description 是否渲染P65Warning
 * return {true} 渲染
 * return {false} 不渲染
 * */

export default function useBeltInfo(props) {
  // --------- useMap_**** --------
  const { language } = useMapState(['language'])
  const { beltTextMinFontSize } = useMapGetters(['beltTextMinFontSize'])
  const { isClient } = useIsClient()
  const { isOutStock, seaLandBelt, isClubGift, isGift, isAdditionGood } =
    useAttribute(props?.data)

  // 计算渲染文字区域的宽度
  const domWidth = computed(() => {
    if (isClient.value) {
      const { renderWidth } = props
      return (renderWidth * window.innerWidth) / 10 // 换算成px
    }
    return 0
  })

  /**
   * @description 腰带信息
   * @returns1 {isShow} 是否展示
   * @returns2 {text} 文本
   * @returns3 {cls} 类名
   * */
  const beltInfo = computed(() => {
    let content = { cls: '', text: '', style: {}, isShow: false }
    const { data, isInDrawer } = props

    // 促销卖点
    // 目前仅促销卖点腰带在 shoppingBagBelt 中返回，后续所有腰带统一从该字段返回
    const saleBelt = data?.aggregateProductBusiness?.shoppingBagBelt

    // 腰带优先级：售罄 > 海陆运大件 > 会员赠品 > 活动赠品 > 附属品 > 促销卖点
    if (isOutStock.value) {
      // 是否无库存
      content = {
        text: language.value.SHEIN_KEY_PWA_14987,
        cls: prefixCls('common_belt-stock')
      }
    } else if (!!seaLandBelt.value?.visible && !isInDrawer) {
      // 是否海陆运
      content = {
        text: seaLandBelt.value?.data?.outsideBeltTip,
        cls: prefixCls('common_belt-transport')
      }
    } else if (isClubGift.value) {
      // 付费会员赠品
      content = {
        text: language.value.SHEIN_KEY_PWA_31169,
        cls: prefixCls('common_belt-gift')
      }
    } else if (isGift.value) {
      // 是否赠品
      content = {
        text: language.value.SHEIN_KEY_PWA_25536,
        cls: prefixCls('common_belt-gift-tips')
      }
    } else if (isAdditionGood.value) {
      // 是否附属品
      content = {
        text: language.value.SHEIN_KEY_PWA_28687,
        cls: prefixCls('common_belt-addition-good')
      }
    } else if (!!saleBelt) {
      // 促销卖点腰带
      const {
        bgColor,
        bgColorAlpha,
        displayType,
        endTime,
        icon,
        text,
        textColor
      } = saleBelt
      content = {
        icon,
        isCountDown: +displayType === 1,
        endTimeStamp: +endTime * 1000,
        text,
        style: {
          background: hexToRgb({
            hex: bgColor,
            opa: bgColorAlpha ? +bgColorAlpha : 1
          }),
          color: textColor
        }
      }
    }

    // 是否展示
    content.isShow = content.isCountDown
      ? !!content.endTimeStamp
      : !!content.text
    if (!content.isShow) return content

    // 设定初始值
    let fontSize = 12

    // 计算【文本多行则换行，换行则字号变小】
    if (domWidth.value) {
      const topAndBottomPadding = remToPx(8 / 75) * 2
      const iconWidth = content.icon ? remToPx(24 / 75) : 0
      const width = domWidth.value - topAndBottomPadding - iconWidth
      const txtWidth = getActualWidthOfChars(content.text, { size: fontSize })
      if (txtWidth > width) {
        const scaleRatio = width / txtWidth
        const adaptableFontSize = Math.floor(fontSize * scaleRatio)
        const isTooSmall = adaptableFontSize < beltTextMinFontSize.value
        fontSize = isTooSmall ? beltTextMinFontSize.value : adaptableFontSize
      }
    } else {
      // ssr
      let ssrFontSize = fontSize
      // 大致上一行
      if (content.text?.length <= 10) {
        ssrFontSize = fontSize
      } else if (content.text?.length > 10 && content.text?.length <= 12) {
        ssrFontSize = 10
      } else {
        ssrFontSize = beltTextMinFontSize.value
      }
      fontSize = ssrFontSize
    }

    content.style = {
      ...content.style,
      fontSize: `${fontSize}px`
    }

    return content
  })

  return { beltInfo }
}
