/**
 * @file 列表插坑相关的action
 */

import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
import {
  rankListComponentApiCache,
  surveyComponentApiCache,
  discountChannelCompApiCache,
  cccCommonComponentApiCache
} from 'public/src/pre_requests/modules/productList/api/bffApi.js'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'

import { getSearchDiff } from './utils.js'

const getListPageParams = ({ catInfo, searchKeywords }) => {
  const params = {}
  if (catInfo.type === 'search') {
    Object.assign(params, {
      scene: 'search',
      word: searchKeywords?.is_suggest_res ? searchKeywords?.suggest_words : searchKeywords?.origin_words,
    })
  } else if (catInfo.type === 'entity') {
    Object.assign(params, {
      scene: 'real',
      cat_id: catInfo.entity_id,
    })
  } else if (catInfo.type === 'selection') {
    Object.assign(params, {
      scene: 'select',
      select_id: catInfo.select_id,
    })
  }
  return params
}

const getRankListComponentParams = ({ catInfo, cccConfig, goods, searchKeywords }) => {
  const end = (catInfo.page || 1) * (catInfo.limit || 10)
  const start = end - (catInfo.limit || 10)
  const deliverItemLimitObject = cccConfig?.flowRankingList?.deliverItemLimitObject || {}
  const _delivery_place = Object.keys(deliverItemLimitObject).find(num => num >= start && num <= end)
  const delivery_place = typeof _delivery_place === 'string' ? Number(_delivery_place) + 1 : undefined
  const expose_goods_id = catInfo.page <= 12 ? goods.slice(0, 240).map(item => item.goods_id).join(',') : ''
  const params = {
    sort: catInfo.sort || 0,
    delivery_place,
    expose_goods_id,
    ...getListPageParams({ catInfo, searchKeywords }),
  }
  return params
}

const adaptRankListComponent = (rankListComponentResult, { language, sheinClubInfo, catInfo }) => {
  let _rankListComponentResult = rankListComponentResult?.info
  if (_rankListComponentResult && _rankListComponentResult.title && _rankListComponentResult.products?.length) {
    const goods = _rankListComponentResult.products.map(productItem => {
      return goodsTransformation(productItem, {
        suggestedSaleType: catInfo.suggestedSaleTypeBff || '',
        language: language || {},
        lang: gbCommonInfo.lang,
        isPaid: !!sheinClubInfo?.isPaid
      })
    })

    _rankListComponentResult = Object.assign({}, _rankListComponentResult, {
      version: 'v2',
      carrierData: {
        carrierSubType: Number(_rankListComponentResult.carrierSubType),
        cateIds: _rankListComponentResult.cate_id || '-',
      },
      goods,
    })
    delete _rankListComponentResult.products
  } else {
    _rankListComponentResult = null
  }
  return _rankListComponentResult
}

const processRankListComponent = ({ sum, flowRankCcc, catInfo, ListComponent, rankListComponentResult }) => {
  // 不展示榜单组件
  const EmptyFlowRankingList = { FlowRankingList: { Data: null, Abt: null } }
  const ListComponentAbt = (catInfo.type === 'store' || ListComponent?.p?.ListComponent) ? 'FlowRankingListC' : null

  if (!ListComponentAbt || !rankListComponentResult) {
    return EmptyFlowRankingList
  }
  const { delivery_place } = rankListComponentResult
  if (!delivery_place || !flowRankCcc) {
    return EmptyFlowRankingList
  }
  const minimumProductImpressions = flowRankCcc.deliverItemLimitObject?.minimumProductImpressions
  if (minimumProductImpressions && sum < minimumProductImpressions) {
    return EmptyFlowRankingList
  }
  flowRankCcc.deliverPlaceItemsObject[delivery_place] = rankListComponentResult
  return {
    Data: getSearchDiff(catInfo)?.hideFlowRankingList ? {} : {
      flowRankingList: flowRankCcc,
    },
    Abt: {
      ListComponentAbt,
    }
  }
}

// 榜单组件插坑
export async function getRankListComponentBff ({ state, getters }) {
  const { sum, language, sheinClubInfo, catInfo, cccConfig, goods, searchKeywords, listAbtResult } = getters
  const { flowRankingList: flowRankCcc } = cccConfig
  const { ListComponent } = listAbtResult

  const params = getRankListComponentParams({ catInfo, cccConfig, goods, searchKeywords })
  if (!params.delivery_place || !params.scene) {
    return
  }
  const result = await rankListComponentApiCache.request(params)
  const rankListComponentResult = adaptRankListComponent(result, { language, sheinClubInfo, catInfo })
  // eslint-disable-next-line require-atomic-updates
  state.ComponentState.ProductList.flowDataV2.flowRank = processRankListComponent({ sum, flowRankCcc, catInfo, ListComponent, rankListComponentResult })
}

// 问卷组件插坑
export async function getSurveyComponentBff ({ state }) {
  if (!isSwitchBffApiVersion(['surveyQuestion_v1'])?.surveyQuestion_v1) {
    return
  }
  const catInfo = state.Results.cat_info || {}
  const { type, select_id, entity_id, entranceType, fromPageType  } = catInfo
  if (!['entity', 'selection', 'search', 'srctype', 'pick', 'store'].includes(type)) {
    state.ComponentState.ProductList.flowDataV2.flowRank = {} 
    return
  }
  const paramsMap = {
    entity: {
      entrance_type: entranceType || fromPageType || '',
      page_type: 'realListPage',
      cate_id: entity_id,
      cate_type: '4',
    },
    selection: {
      entrance_type: entranceType || fromPageType || '',
      page_type: 'selectListPage',
      cate_id: select_id,
      cate_type: '5',
    },
    search: {
      entrance_type: 'SEARCH',
      page_type: 'searchListPage',
      cate_type: '0',
    },
    pick: {
      entrance_type: 'INFO_FLOW',
      page_type: 'infoFlowListPage',
      cate_type: '0',
    },
    store: {
      entrance_type: 'STORE_LIST',
      page_type: 'shopListPage',
      cate_type: '0',
    },
  }
  const flowSurveyInfo = await surveyComponentApiCache.request(paramsMap[type])
  // eslint-disable-next-line require-atomic-updates
  state.ComponentState.ProductList.flowDataV2.flowSurveyInfo = flowSurveyInfo
}

const getPositionData = (positionArr, products) => {
  const positionData = {}
  let current = 0
  for (let i = 0; i < positionArr.length; i++) {
    const index = positionArr[i]
    positionData[index - 1] = products.slice(current, current + 8)
    current = current + 8
  }
  return positionData
}

const adaptDiscountChannelComp = (result, { language, sheinClubInfo, catInfo, listAbtResult, searchKeywords }) => {
  let _result = result?.info
  if (_result && _result.products?.length) {
    const ShowListDeals = listAbtResult.ShowListDeals || {}
    const positionArr = ShowListDeals.p?.DealsPosition?.split(',') || [] // 坑位

    const goods = _result.products.map(productItem => {
      return goodsTransformation(productItem, {
        suggestedSaleType: catInfo.suggestedSaleTypeBff || '',
        language: language || {},
        lang: gbCommonInfo.lang,
        isPaid: !!sheinClubInfo?.isPaid
      })
    })
    return {
      positionData: getPositionData(positionArr, goods),
      discountParams: {
        ...getListPageParams({ catInfo, searchKeywords }),
        title: _result.title,
        subTitle: _result.subTitle,
        discount_channel_v1: true,
        suggestedSalePriceType: catInfo.suggestedSaleTypeBff || '',
        DealsEnchance: ShowListDeals?.p?.DealsEnchance || 'A',
      },
    }
  }
  return null
}

// 折扣频道插坑
export async function getDiscountChannelCompBff ({ state, getters }) {
  if (!isSwitchBffApiVersion(['discount_channel_v1'])?.discount_channel_v1) {
    return
  }
  const { catInfo, searchKeywords, language, sheinClubInfo, listAbtResult } = getters
  if (!(catInfo.isRealPage || catInfo.isSelectPage || catInfo.isSearchPage)) {
    return
  }
  const params = getListPageParams({ catInfo, searchKeywords })
  const result = await discountChannelCompApiCache.request(params)
  const flowDiscountChannel = adaptDiscountChannelComp(result, { catInfo, language, sheinClubInfo, listAbtResult, searchKeywords })
  state.ComponentState.ProductList.flowDataV2.flowDiscountChannel = flowDiscountChannel
}

const adaptFlowListBanner = (cccCommonComponentResult) => {
  const _cccCommonComponentResult = cccCommonComponentResult?.info
  if (!_cccCommonComponentResult) {
    return
  }
  const cateLinks = {}
  const banners = (_cccCommonComponentResult.content || []).filter(contentItem => {
    return contentItem.blockKey === 'flowList_Banner'
  }).map(bannerItem => {

    (bannerItem.props?.items || []).forEach(item => {
      cateLinks[`${item.hrefType}_${item.hrefTarget}`] = item.webClickUrl
    })

    return {
      ...bannerItem,
      __comp_name: 'StaticImageCardController',
    }
  })

  if (banners.length === 0) {
    return
  }
  const adRecord = {
    cateLinks,
    abtBranch: _cccCommonComponentResult.abtBranch,
    channelId: _cccCommonComponentResult.channelId,
    id: _cccCommonComponentResult.id,
    pageType: _cccCommonComponentResult.pageType,
    templateId: _cccCommonComponentResult.templateId,
    templateKey: _cccCommonComponentResult.templateKey,
    content: banners,
  }
  return {
    context: adRecord,
    data: adRecord.content.reduce((total, curr) => {
      total[curr.placeHolderPosition - 1] = curr
      return total
    }, {}),
  }
}

// 包含广告流插坑
export async function getCccCommonComponentBff ({ state, getters }) {
  const { catInfo, searchKeywords } = getters
  const listPageParams = getListPageParams({ catInfo, searchKeywords })
  if (!['real', 'select'].includes(listPageParams.scene)) {
    return
  }
  const params = {
    cccPageType: 'realSelectListPage',
    ...(listPageParams.scene === 'real' ?
      { cateId: listPageParams.cat_id, cateType: 4 } :
      { cateId: listPageParams.select_id, cateType: 5 }),
  }
  const result = await cccCommonComponentApiCache.request(params)
  state.ComponentState.ProductList.flowDataV2.flowListBanner = adaptFlowListBanner(result)
}
