const { mergeQueryString } = require('@shein/common-function')
const noop = () => {}
const rRange = /(\d+)-(\d+)\/(\d+)/

function observeNetworkRequestIsCache(url) {
  return new Promise(resolve => {
    if (typeof PerformanceObserver === 'function') {
      const observer = new PerformanceObserver(list => {
        list.getEntries().forEach(entry => {
          if (entry?.name?.includes(url)) {
            observer.disconnect()
            resolve(!entry?.transferSize)
          }
        })
      })
      observer.observe({ entryTypes: ['resource'] })
    } else {
      resolve(false)
    }
  })
}

function fetchRange({
  abortController,
  from,
  to,
  url,
  onSuccess = noop,
  onError = noop,
}) {
  let begin
  let end
  let total
  let isCache
  let currentUrl = mergeQueryString({
    url: url,
    mergeObj: {
      from,
    },
  })
  const isCachePromise = observeNetworkRequestIsCache(currentUrl)
  return fetch(currentUrl, {
    headers: {
      Range: `bytes=${from}-${to}`,
    },
    signal: abortController?.signal,
  })
    .then(resp => {
      return new Promise((resolve, reject) => {
        if (![200, 206].includes(resp.status)) {
          reject({
            code: 500,
            type: 'reqestError',
            message: 'request error',
          })
        }
        ;[, begin, end, total] = resp.headers.get('Content-Range').match(rRange)
        begin = parseInt(begin, 10)
        end = parseInt(end, 10)
        total = parseInt(total, 10)
        let hasReturn = false
        isCachePromise.then(param => {
          if (hasReturn) return
          hasReturn = true
          isCache = param
          resolve(resp.arrayBuffer())
        })
        // 防止isCachePromise一直未触发
        setTimeout(() => {
          if (hasReturn) return
          hasReturn = true
          console.warn('isCachePromise timeout')
          resolve(resp.arrayBuffer())
        }, 1000)
      })
    })
    .then(bytes => onSuccess({ begin, end, bytes, total, isCache }))
    .catch(e => {
      // aborted
      if (e.code !== 20) onError(e)
    })
}

const defaultOpts = {
  url: '',
  chunkStart: 100 * 1024,
  total: 0,
  onChunk: noop,
}

export const States = {
  None: 'none',
  Loading: 'loading',
  Paused: 'paused',
  Stopped: 'stopped',
}

export class Downloader {
  constructor(opts) {
    Object.assign(this, defaultOpts, opts)

    this.state = States.None
    this.fetcher = false
    this.awaitFetch = null // 提供给队列调用
  }

  _loadChunk() {
    if (this.awaitFetch) return

    const chunkStart = this.chunkStart
    const onSuccess = ({ end, bytes, total, isCache }) => {
      this.total = total
      this.chunkStart = end

      this.awaitFetch = null
      const isEof = end === total - 1
      if (isEof) this.state = States.Stopped

      bytes.fileStart = chunkStart
      this.onChunk({ bytes, isEof, total, isCache })

      if (this.state === States.Loading) this._loadChunk()
    }
    if (this.chunkStart === this.total - 1) return
    let to = this.chunkStart + this.chunkSize - 1
    if (this.total && to >= this.total) {
      to = this.total - 1
    }
    this.awaitFetch = abortController => {
      return fetchRange({
        abortController,
        url: this.url,
        from: this.chunkStart,
        to: this.chunkStart + this.chunkSize - 1,
        onSuccess,
        onError: e => {
          if (e?.code === 500) {
            this.awaitFetch = null
          }
          console.error(e)
        },
      })
    }
  }

  start() {
    if (this.state !== States.Loading) {
      this.chunkStart = 0
      this.state = States.Loading
      this._loadChunk()
    }
  }

  resume(chunkSize) {
    if (chunkSize) this.chunkSize = chunkSize
    if (this.state !== States.Loading && this.chunkStart <= this.total - 1) {
      this.state = States.Loading
      this._loadChunk()
      return true
    }
  }

  stop(needAbort = false) {
    this.state = States.Stopped
    if (this.fetcher && needAbort) {
      this.fetcher.abort()
      this.fetcher = null
    }
  }
}
