<template>
  <div
    :class="{
      'bsc-bfp-card': true,
      'bsc-bfp-card--mini': props.isMini
    }">
    <div class="bsc-bfp-card__title">
      <img
        class=""
        :class="{
          'bsc-bfp-card__icon': true,
          'bsc-bfp-card__icon--mini': props.isMini
        }"
        src="https://img.ltwebstatic.com/images3_ccc/2024/11/14/bc/1731584091f6bbbf60f55b08da42b70a742ce94383.png"
      />
      <div
        v-if="getTitle"
        ref="pointTextRef"
        class="bsc-bfp-card__title--text"
        :style="{ fontSize: `${defaultFontSize}px` }"
      >
        {{ getTitle }}
      </div>
    </div>
    <div
      v-if="!isMini"
      class="bsc-bfp-card__subtitle"
    >
      {{ getSubTitle }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { type Ref, ref, computed, watch, nextTick } from 'vue'
import { type C_BenefitFloor } from '../../../../types'

const pointTextRef:Ref = ref(null)

const props = defineProps<{
  rewardItem: C_BenefitFloor.RewardItem
  isMini?: boolean
}>()

const defaultFontSize = computed(() => {
  let fontSize = 18
  if (props.isMini) {
    fontSize = 12
  }
  return fontSize
})

const minFontSize = computed(() => {
  let minFontSize = 12
  if (props.isMini) minFontSize = 10
  return minFontSize
})

const getTitle = computed(() => props?.rewardItem?.point?.point)
const getSubTitle = computed(() => props?.rewardItem?.point?.pointTip)

const updateTextStyle = () => {
  const { clientWidth, scrollWidth } = pointTextRef.value
  let fontSize = defaultFontSize.value
  if (fontSize === minFontSize.value) return
  // scrollWidth 会四舍五入，容易导致计算得到的字号过大
  const handledScrollWidth = scrollWidth + 1
  if (handledScrollWidth > clientWidth) {
    // 微调因子，避免1px的误差
    const adjustmentFactor = 0.98
    const scaleRatio = clientWidth / handledScrollWidth * adjustmentFactor
    const adaptableFontSize = Math.floor(fontSize * scaleRatio)
    const isTooSmall = adaptableFontSize < minFontSize.value
    fontSize = isTooSmall ? minFontSize.value : adaptableFontSize
  }
  pointTextRef.value.style.fontSize = `${fontSize}px`
  pointTextRef.value.style.textOverflow = 'ellipsis'
}


watch(
  () => pointTextRef.value,
  (newVal, oldVal) => {
    if (!newVal || typeof window === 'undefined') return
    if (newVal?.innerText === oldVal?.innerText) return
    pointTextRef.value.style.textOverflow = 'unset'
    nextTick(() => updateTextStyle())
  },
  { immediate: true, deep: true },
)
</script>

<style lang="less" scoped>
.bsc-bfp-card {
  width: 1.7067rem;
  height: .96rem;
  padding: 0 .1067rem;
  background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/11/14/1a/1731576577c80e0159fec0d5d3992072fa76934f69.webp');
  background-size: 100% 100%;
  box-shadow: 0 0 .1067rem 0 #F65900;
  border-radius: .16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.1;
  text-align: center;
  flex-shrink: 0;
  scale: 1;
  transition: inherit;
  &--mini {
    width: 1.12rem;
    height: .64rem;
    scale: .8;
  }
  &__icon {
    width: .3467rem;
    // transition: inherit;
    &--mini {
      width: .24rem;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    &--text {
      // transition: inherit;
      flex: 1;
      font-size: 18px;
      font-family: system-ui;
      font-weight: 900;
      background-image: linear-gradient(182deg,  #FFFFFF 38.39%,  #FCFF60 94.09%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__subtitle {
    max-width: 100%;
    font-family: system-ui;
    font-size: 8px;
    font-weight: 590;
    background-image: linear-gradient(182deg,  #FFFFFF 38.39%,  #FCFF60 94.09%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
