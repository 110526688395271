<!-- slide不支持异步加载, 封装一层使用 -->
<template>
  <div>
    <ClientOnly>
      <Slide
        ref="refSlide"
        :visible.sync="myVisible"
        :modal="true"
        :close-on-click-modal="!loading"
        :modal-position="true"
        @beforeOpen="onDisabledScroll"
        @beforeClose="onDisabledScroll"
        @close-from-mask="clickOverlay"
        @hook:mounted="onSlideMounted"
      >
        <!-- 自定义panel内容 -->
        <slot></slot>
      </Slide>
    </ClientOnly>
  </div>
</template>

<script>
export default {
  name: 'DropdownPanel',
}
</script>

<script setup>
import { ref, watch, onBeforeUnmount, nextTick, onMounted, getCurrentInstance } from 'vue'

import ClientOnly from 'vue-client-only'
import { Slide } from '@shein/sui-mobile'

// utils
import { throttle } from '@shein/common-function'
import { scrollFix } from 'public/src/pages/common/utils/index.js'
import { setDisabledScroll } from 'public/src/pages/components/FilterBar/utils/disabledScroll.js'
import { onUpdateDropdownPanelHeight, offUpdateDropdownPanelHeight, emitUpdateDropdownPanelHeight } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { useEventListener } from '../../hooks/useEventListener'

defineExpose({ open, close })
const emits = defineEmits(['open', 'close', 'update:visible'])
const props = defineProps({
  loading: { type: Boolean, default: false },
  visible: { type: Boolean, default: false },
})

const refSlide = ref(null)
let curInstance = ref(null)
const myVisible = ref(props.visible)
const scrollFixInstance = typeof window !== 'undefined' ? scrollFix() : () => {}

// 更新slide的top值
const updateSlideTop = async () => {
  // 非slide蒙层，不需要更新
  if (!myVisible.value || !window.isBodyFixed) return

  if (curInstance.value?.proxy?.$slots?.default?.[0]?.componentInstance?.adapterHeight) {
    await curInstance.value.proxy.$slots.default[0].componentInstance.adapterHeight()
  }
  refSlide.value?.calHeight?.()
}

// 点击遮罩层
const clickOverlay = () => {
  if (props.loading) return
  
  emits('close')
}
const onDisabledScroll = () =>  setDisabledScroll()

const onSlideMounted = () => {
  const timer = setTimeout(() => {
    clearTimeout(timer)
    updateSlideTop()
  }, 300) // 等待300ms动画后再计算高度
}

// 同步visible -> myVisible
watch(() => props.visible, (val) => {
  myVisible.value = val
})

// 监听visible变化, 锁定body, 监听onBranchHide/updatePanelTop事件
watch(myVisible,  (val) => {
  if (typeof window === 'undefined') return
  emits('update:visible', val)
  if (window?.vBus) {
    window.vBus.$off('onBranchHide')
    window.vBus.$on('onBranchHide', updateSlideTop)
  }

  if (window.appEventCenter) {
    window.appEventCenter.$off('updatePanelTop')
    window.appEventCenter.$on('updatePanelTop', updateSlideTop)
  }
  if (val) {
    onUpdateDropdownPanelHeight(updateSlideTop)
    emitUpdateDropdownPanelHeight(300)
  } else {
    offUpdateDropdownPanelHeight()
  }
  nextTick(async () => {
    scrollFixInstance(val)

    if (!val) return
    await nextTick()
    window.scrollTo(0, 0) // body被fixed后，三星S6下window.scrollY不为0，导致下拉面板显示异常
  })
})

const handleResize = throttle({
  func: updateSlideTop,
  wait: 100,
  options: { leading: false }
})
useEventListener('resize', handleResize)

onMounted(async () => {
  curInstance.value = getCurrentInstance()
  if (!myVisible.value ) return
  offUpdateDropdownPanelHeight()
  onUpdateDropdownPanelHeight(updateSlideTop)
  emitUpdateDropdownPanelHeight(300)
  scrollFixInstance(true)
  await nextTick()
  window.scrollTo(0, 0)
})

// 卸载前, 解锁body
onBeforeUnmount(() => {
  if (!props.visible) return

  scrollFixInstance(false)
  $('body').removeClass('S-popup-parent__hidden')
})
</script>
