import { template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Toast } from '@shein/sui-mobile'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { getCookie } from '@shein/common-function'
import { getNotEstimatedPriceConfig } from 'public/src/pages/goods_detail_v2/utils/ProductConfigManager/utils'
import { getDetailStaticDataApi } from 'public/src/pages/goods_detail_v2/innerComponents/recommend/apis.js'

/**
 * 格式化商品项数据支持组合购功能 BFF
 */
export const getFormatCommonCartProductItem = (item = {}) => {
  const {
    promotion_group
  } = item


  const skuCode = ''

  const isSoldOut = item.stock == 0 || item.is_on_sale == 0
  /**
   * 只有选中的sku售罄
   */
  const isOnlySkuSoldOut = false
  /**
   * 优惠券分组
   * 为1，则为优惠券商品 为空或为0： 则为折扣商品
   */
  const isPromotionGroup = promotion_group == '1'

  /**
   * 勾选状态
   */
  const selectStatus = false

  /**
   * 是否为主商品
   */
  const isMainGoods = false

  /**
   * 是否spu售罄，有整个面板置灰的专属样式，如果存在多色块需要异步获取，否则直接读取当前skc售罄状态
   */
  // const isSpuSoldOut = relatedColor.length > 1 ? false : isSoldOut
  const isSpuSoldOut = false // todo

  return {
    ...item,
    _cartInfo: {
      isMainGoods,
      isSoldOut,
      isOnlySkuSoldOut,
      isSpuSoldOut,
      /**
       * 勾选状态
       */
      selectStatus,
      /**
       * 外部勾选状态，排序用
       */
      _selectStatusOutSide: false,
      /**
       * 未选中sku警告状态
       */
      warnStatus: false,
      /**
       * 选中的skuCode
       */
      skuCode,
      // skuCombineName,
      // skcImageUrl,
      isPromotionGroup,
      /**
       * 快车弹窗回显Sku用
       */
      selectedAttrIndex: {}
    }
  }
}

/**
 * 通过skuCode更新item BFF
 */
export const setCommonCartProductSkuInfo = (skuCode, item = {}) => {

  if (!skuCode) return

  // const { multiLevelSaleAttribute, _cartInfo = {} } = item
  // const { sku_list = [] } = multiLevelSaleAttribute || {}
  // const _sku = sku_list.find(d => d.sku_code === skuCode)

  // if (!_sku) return
  // const { sku_sale_attr = [] } = _sku

  // // 快车弹窗回显用
  // const selectedAttrIndex = {}
  // if (!_cartInfo.isNotSku && sku_sale_attr.length) {
  //   sku_sale_attr.forEach(d => {
  //     selectedAttrIndex[d.attr_id] = { attr_value_id: d.attr_value_id }
  //   })
  // }

  // const isSkuSoldOut = isSkuCodeCanSale(skuCode, item) !== true
  // if (isSkuSoldOut && (!_cartInfo.isSoldOut || _cartInfo.isOnlySkuSoldOut)) {
  //   item._cartInfo.isSoldOut = item._cartInfo.isOnlySkuSoldOut = true
  //   /**
  //    * todo，修改商卡数据使其展示为售罄状态
  //    */
  //   item.is_on_sale = 0

  //   if (item._cartInfo.selectStatus) {
  //     item._cartInfo.selectStatus = false
  //   }
  // } else {
  //   // 如果曾经被设置为售罄
  //   if (item._cartInfo.isOnlySkuSoldOut) {
  //     item.is_on_sale = 1
  //     item._cartInfo.isSoldOut = item._cartInfo.isOnlySkuSoldOut = false
  //   }
  // }

  item._cartInfo.skuCode = skuCode
  // item._cartInfo.selectedAttrIndex = selectedAttrIndex
  return item
}

export const setCommonQuickAddSelectedAttrIndexData = async (item) => {
  const { _cartInfo, goods_id, mall_code } = item || {}
  const { skuCode } = _cartInfo || {}
  if (!skuCode || !goods_id) return

  if (!item.multiLevelSaleAttribute) {
    try {
      const res = await getDetailStaticDataApi({
        goods_id,
        mall_code,
      })
      // console.log('getDetailStaticDataApi', res)
      // eslint-disable-next-line require-atomic-updates
      item.multiLevelSaleAttribute = {
        sku_list: res?.saleAttr?.multiLevelSaleAttribute?.sku_list
      }
    } catch(e) { /** */ }
  }

  const _sku = item.multiLevelSaleAttribute?.sku_list?.find(d => d.sku_code === skuCode)

  if (!_sku) return
  const { sku_sale_attr = [] } = _sku

  // 快车弹窗回显用
  const selectedAttrIndex = {}
  if (sku_sale_attr.length) {
    sku_sale_attr.forEach(d => {
      selectedAttrIndex[d.attr_id] = { attr_value_id: d.attr_value_id }
    })
  }

  // eslint-disable-next-line require-atomic-updates
  item._cartInfo.selectedAttrIndex = selectedAttrIndex
}

/**
 * 移除skuCode选中状态 BFF
 */
export const setCommonCartProductSkuInfoEmpty = (item = {}) => {
  item._cartInfo.skuCode = ''
  item._cartInfo.selectedAttrIndex = {}
}

/**
 * 判断当前sku是否可售 BFF
 */
export const isSkuCodeCanSale = (skuCode) => {
  if (!skuCode) return
  return true // 不再二次校验库存
  // const { mall_code, multiLevelSaleAttribute } = item
  // const skuInfo = multiLevelSaleAttribute?.sku_list?.find?.(d => d.sku_code === skuCode)
  // if (!skuInfo) return
  // return skuInfo.mall_stock.some(d => d.mall_code == mall_code && Number(d.stock) > 0)
}

/**
 * 首次打开弹窗时自动选择sku
 * 只有一个sku或只有单sku有库存的商品 BFF
 */
export const setProductListAutoSkuInfo = (list = [], ignoreMainGoods = false) => {
  if (!list.length) return []
  list.forEach(d => {
    if (ignoreMainGoods && d._cartInfo.isMainGoods) return
    // skc维度售罄
    if (d._cartInfo.isSoldOut) return
    // bff下发了sku_code，自动勾选
    if (!d.sku_code) return
    setCommonCartProductSkuInfo(d.sku_code, d)
  })

  return list
}


/**
 * 获取套装价 BFF
 * @returns 
 */
export const getCalcBundlePrice = (bffInfo) => {

  const res = {
    // amount: 0,
    amountWithSymbol: '0.00',
    /**
     * 折扣率
     */
    discount: 0,
    /**
     * 价差
     */
    // discountAmount: 0,
    discountAmountWithSymbol: '',
    /**
     * 原价
     */
    retailAmountWithSymbol: '',
    /**
     * 券列表，包含AB伪造券
     */
    couponList: [],

    /**
     * 展示的Activity价差
     */
    activityAmountWithSymbol: '',
    /**
     * 倒计时结束时间
     */
    couponEndDate: 0
  }

  const { bundlePrice, bundleRetailPrice, bundleSavedPrice, bundleUnitDiscount, bundlePromotionDetail } = bffInfo || {}


  if (!bundlePrice || Object.keys(bundlePrice).length === 0) return res

  const _getValidAmout = (d) => {
    return !isAmountEmpty(d?.amount) ? d?.amountWithSymbol : ''
  }
  const _getFormatCouponList = (list = []) => {
    return (list || []).map(d => {
      return {
        ...d,
        isBind: d.isBind, // 是否是绑定类型,0-否，1-是
        couponCode: d.couponCode, // 券码
        bindStatus: d.bindStatus, // 绑定状态 '1' 已绑定 '0' 未绑定
        _couponName: d.couponDiscountMultiLang,
        _discountAmountWithSymbol: d.couponDiscountPrice?.amountWithSymbol,
      }
    })
  }

  res.amountWithSymbol = _getValidAmout(bundlePrice) || '0.00',
  res.discount = Number(bundleUnitDiscount)
  res.discountAmountWithSymbol = _getValidAmout(bundleSavedPrice),
  res.retailAmountWithSymbol = _getValidAmout(bundleRetailPrice)
  res.activityAmountWithSymbol = _getValidAmout(bundlePromotionDetail?.specialPrice),
  res.couponEndDate = bundlePromotionDetail?.countdownEndTime || 0 // 倒计时结束时间，10位时间戳
  res.couponList = _getFormatCouponList(bundlePromotionDetail?.bundleCouponInfos) || []
  // res.couponList = _getFormatCouponList([
  //   {
  //     "couponDiscountMultiLang": "50% OFF orders $9.90+",
  //     "couponDiscountPrice": {
  //       "amount": "6.92",
  //       "amountWithSymbol": "$6.92",
  //       "usdAmount": "6.92",
  //       "usdAmountWithSymbol": "$6.92",
  //       "priceShowStyle": "6"
  //     },
  //     "couponCode": "usdacu666",
  //     "bindStatus": "1",
  //     "isBind": "1",
  //     "isShowCopyCodeTip": null
  //   }
  // ])

  return res
}


/**
 * 批量加车
 */
export const batchAddToCart = async (list = []) => {
  const product_list = list
    .map(d => ({
      mall_code: d.mall_code,
      sku_code: d._cartInfo.skuCode,
      quantity: 1
    }))
    .filter(d => d.sku_code)

  if (!product_list.length) return

  const data = {
    product_list
  }

  try {
    const result = await schttp({
      url: '/api/cart/batch_add_mall/create',
      method: 'POST',
      data
    })
    return result
  } catch (err) {
    console.error('batchAddToCart err', err, data, '====')
    return
  }
}

/**
  加车一件商品就报一条

  图示是4件商品，就是4条

  1、前端校验出现错误时，上报加车失败（只上报前端校验失败情况）
  2、前端校验成功，待接口返回结果后，上报（可能成功，可能失败，都上报）
 */
export const handleExposeAddCart = (options = {}) => {
  const {
    list = [],
    result,
    location = 'popup',
    result_reason,
    activity_from,
    tspABTest,
    series_no
  } = options
  // console.log('handleExposeAddCart', options)

  
  const click_id = `${typeof window !== 'undefined' ? getCookie('cookieId') : ''}${Date.now()}`

  if (!list.length) {
    const extraData = {
      code: 'goodsDetail',
      result,
      postData: {
        // goods_id: '',
        // sku: '',
        // spu: '',
        // sku_code: '',
        // mall_code: '',
        location,
        click_id
        // quickShip: 0
      },
      from: activity_from,
      review_location: '',
      traceid: window.SaPageInfo?.page_param?.traceid,
      // button_type: 'one_tap_pay',
      faultReason: result_reason
    }
    if (series_no) {
      extraData.series_no = series_no
    }
    if (tspABTest) {
      extraData.tspABTest = tspABTest
    }
    daEventCenter.triggerNotice({
      daId: '1-8-1-1',
      extraData,
      target: null
    })
    return
  }

  list.forEach((d) => {
    const extraData = {
      code: 'goodsDetail',
      result,
      postData: {
        goods_id: d.goods_id,
        sku: d.goods_sn,
        spu: d.productRelationID,
        sku_code: d._cartInfo.skuCode,
        mallCode: d.mall_code,
        location,
        click_id,
        quickShip: d.quickship
      },
      from: activity_from,
      review_location: '',
      traceid: window.SaPageInfo?.page_param?.traceid,
      // button_type: 'one_tap_pay',
      faultReason: result_reason
    }
    if (series_no) {
      extraData.series_no = series_no
    }
    if (tspABTest) {
      extraData.tspABTest = tspABTest
    }
    daEventCenter.triggerNotice({
      daId: '1-8-1-1',
      extraData,
      target: d.target,
    })
  })
}

export const getFormatBatchAddErrorResult = (data = {}, language = {}) => {
  const { code, info, tips } = data

  let _errorTips = ''
  if (code == 0) {
    /**
     * 加车成功
     */
    return data
  } else {
    const limitCount = (info && info.limitCount) || ''
    if (code == '300402') {
      _errorTips = language.SHEIN_KEY_PWA_15230 || 'Out of Stock'
    } else if (['200401', '500301'].includes(code)) {
      _errorTips = language.SHEIN_KEY_PWA_14967 || 'limit quantity'
    } else if (['500306', '500302', '300417', '500303'].includes(code)) {
      _errorTips = template(limitCount, info?.resultLimit || 0, info?.remainNum || 0, tips)
    }else if (code == '300407') {
      _errorTips = tips
    } else {
      _errorTips = language.SHEIN_KEY_PWA_15282 || 'error'
    }
    return {
      ...data,
      _errorTips
    }
  }
}

/**
 * 批量加车后领券
 */
export async function autoGetCouponAtBatchAdd(couponList, language, needToast) {
  try {
    if (!isLogin()) return
    const couponCodes = couponList?.filter?.(item => item.bindStatus != 1).map(item => item.couponCode) || []
    if (!couponCodes.length) return
    const res = await schttp({
      method: 'POST',
      url: '/api/productInfo/bindCoupon/post',
      data: {
        coupon_codes: couponCodes
      }
    })
    const failure =
      !res ||
      res.code != 0 ||
      !res.info?.successList?.find?.(item => couponCodes.includes(item.couponCode))
    if (failure) {
      return false
    } else {
      needToast &&
        Toast({
          content:
            language.SHEIN_KEY_PWA_25104 ||
            'We automatically help you to claim coupons',
          iconClass: 'suiiconfont sui_icon_coupon_discount_16px'
        })
      return true
    }
  } catch(e) {
    return false
  }
}

/**
 * 检查当前商品列表是否可以加入购物车，抛出异常项
 */
export const checkCanAddToCart = (options = {}) => {
  const { list = [] } = options
  const res = {
    ok: false,
    isSoldOut: false,
    isNoSku: false,
    errList: []
  }

  list.forEach(d => {
    if (!d._cartInfo.selectStatus) return

    /**
     * 未选择sku不能加车
     */
    if (!d._cartInfo.skuCode) {
      res.isNoSku = true
      res.errList.push(d)
      return
    }

    /*
     * 售罄不能加车
     */
    if (d._cartInfo.isSoldOut) {
      res.isSoldOut = true
      res.errList.push(d)
      return
    }
  })

  res.ok = res.errList.length === 0
  return res
}


export function isAmountEmpty(amount) {
  return !amount || amount === '0.00' || Number(amount) === 0
}


/**
 * 同步两个商品状态
 */
export function setSyncItemCartInfo(item, fromItem) {
  if (!item || !fromItem) return

  // 同步sku状态
  setCommonCartProductSkuInfoEmpty(item)
  const fromItemSkuCode = fromItem._cartInfo?.skuCode
  if (fromItemSkuCode) {
    setCommonCartProductSkuInfo(
      fromItemSkuCode,
      item,
    )
  }

  // 同步主商品状态
  item._cartInfo.isMainGoods = !!fromItem._cartInfo.isMainGoods

  // 同步勾选状态
  item._cartInfo.selectStatus = false
  if (!fromItem._cartInfo.selectStatus) return
  if (item._cartInfo.isSoldOut) return
  // // 如果选择了sku，但是sku不可售，不勾选（同步主商品会有这种情况）
  // if (item._cartInfo.skuCode && !isSkuCodeCanSale(item._cartInfo.skuCode, item)) return
  item._cartInfo.selectStatus = true
}


/**
 * 设置所有商品为非选中
 */
export function setAllProductSelectEmpty(list = []) {
  list.forEach(d => {
    if (!d?._cartInfo?.selectStatus) return
    d._cartInfo.selectStatus = false
  })
}

/**
 * 非主商品不展示到手价
 */
export function getNotEstimatedProductCardConfig(itemConfig = {}, { showEstimatedPrice = false } = {}) {
  if (showEstimatedPrice) return itemConfig
  return getNotEstimatedPriceConfig(itemConfig)
}
