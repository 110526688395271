<template>
  <div
    v-if="searchCCCCard.wordType != 6"
    class="search-banner scrollfix-sub"
  >
    <div
      class="banner-inner search-banner__inner"
      da-event-click="1-5-1-24"
      da-event-expose="1-5-1-25"
      :data-poskey="poskey"
      :data-word-type="searchCCCCard.wordType"
      @click="handleJump"
    >
      <img
        v-if="searchCCCCard.entranceImage"
        ref="logo"
        :src="locals.LAZY_IMG"
        :data-src="localFuncs.transformImg({ img: searchCCCCard.entranceImage })"
        :class="{
          'banner-inner__left-wider': !isServiceType,
          'banner-inner__left-circle': isServiceType,
        }"
        class="banner-inner__left lazyload"
      />
      <div class="middle banner-inner__middle">
        <div
          class="middle__title"
          :class="{
            'middle__title-margin': isFlashSaleType,
          }"
        >
          {{ searchCCCCard.title }}
        </div>
        <div
          v-if="isFlashSaleType"
          class="middle__countdown"
        >
          <span class="middle__countdown-status">{{ language.SHEIN_KEY_PWA_14950 }}</span>
          <span class="middle__countdown-interval">{{ countDown.H }}</span>
          <span class="middle__countdown-colon">:</span>
          <span class="middle__countdown-interval">{{ countDown.M }}</span>
          <span class="middle__countdown-colon">:</span>
          <span class="middle__countdown-interval">{{ countDown.S }}</span>
        </div>
        <div
          v-else
          class="middle__desc"
        >
          {{ searchCCCCard.text }}
        </div>
      </div>
      <div class="banner-inner__right">
        {{ language.SHEIN_KEY_PWA_20627 }} >
      </div>
    </div>
    <div
      v-if="isServiceType && goodsNum"
      class="no-match-desc"
    >
      {{ language.SHEIN_KEY_PWA_20381 }}
    </div>
  </div>
  <div
    v-else
    da-event-click="1-5-1-24"
    da-event-expose="1-5-1-25"
    :data-poskey="poskey"
    :data-word-type="6"
    @click="handleJump"
  >
    <img
      v-if="searchCCCCard.entranceImage"
      ref="logo"
      class="lazyload"
      :src="locals.LAZY_IMG"
      :data-src="localFuncs.transformImg({ img: searchCCCCard.entranceImage })"
      height="100%"
      width="100%"
    />
  </div>
</template>

<script>
import { mergeQueryString } from '@shein/common-function'

// wordType: 0、服务类；1.品牌&系列类；2.阶段性活动类；3.常规专题/列表类；4.第三方店铺类；5.店铺卡片(已下线未使用)；6.纯图片类
export default {
  name: 'SearchCCCCard',
  inject: ['localFuncs'],
  props: {
    searchCCCCard: {
      type: Object,
      default: () => ({}),
    },
    locals: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    goodsNum: {
      type: Number,
      default: 0,
    },
    catInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      poskey: 'SearchCard',
    }
  },
  computed: {
    isServiceType() {
      return this.searchCCCCard.wordType === 0
    },
    isFlashSaleType() {
      return this.searchCCCCard.wordType === 3
    },
    countDown() {
      return this.searchCCCCard.countDown || { H: '00', M: '00', S: '00' }
    },
  },
  mounted() {
    this.$emit('subscribeExpose', '1-5-1-25')
  },
  methods: {
    handleJump() {
      const cccLink = this.getHrefLink()
      if (!cccLink) return
      const hrefJump = ['webLink', 'game', 'survey', 'ThirdPartyStoreList']
      const hrefType = this.searchCCCCard.hrefType
      if (hrefJump.includes(hrefType)) {
        window.location.href = cccLink
      } else {
        this.$router.push(cccLink)
      }
    },
    getHrefLink() {
      const cccLink = this.searchCCCCard.cccLink
      if (!cccLink || cccLink?.indexOf('javascript:;') > -1) return null

      const { src_module, src_identifier, src_tab_page_id } = this.catInfo
      return mergeQueryString({
        mergeObj: { src_module, src_identifier, src_tab_page_id },
        url: cccLink,
      })
    },
    resetFalconlazy() {
      this.$refs.logo?.removeAttribute('src')
      this.$refs.logo?.removeAttribute('loaded')
    },
  },
}
</script>

<style lang="less">
.search-banner {
  padding: 0.2667rem 0.2133rem 0.32rem;
  background: #f6f6f6;
  &__inner {
    .flexbox();
    .align-center();
    .space-between();
    height: 2.1333rem;
    padding: 0 0.32rem;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .banner-inner {
    &__left {
      width: 1.28rem;
      min-width: 1.28rem;
      max-width: 1.28rem;
      height: 1.28rem;
      margin-right: 0.32rem;
    }
    &__left-wider {
      width: 1.7067rem;
      min-width: 1.7067rem;
      max-width: 1.7067rem;
      height: 1.7067rem;
      margin-left: -0.1067rem;
    }
    &__left-circle {
      border-radius: 100%;
    }
    &__middle {
      margin-right: 0.7467rem;
      flex: 1 1 auto;
    }
    &__right {
      .text-overflow();
      min-width: 1.5733rem;
      max-width: 50%;
      height: 0.64rem;
      line-height: 0.64rem;
      padding: 0 0.2133rem;
      border: 1px solid #222;
      text-align: center;
      /* rw:begin */
      color: #ff696e;
      border: 1px solid #ff696e;
      border-radius: 0.5867rem;
    }
  }
  .middle {
    &__title {
      .line-camp(1);
      word-break: break-word;
      font-size: 16px;
      font-weight: bold;
      color: #000;
      margin-bottom: 0.1067rem;
    }
    &__title-margin {
      margin-bottom: 0.32rem;
    }
    &__countdown {
      .flexbox();
      .align-center();
      font-size: 13px;
    }
    &__countdown-status {
      margin-right: 0.1067rem;
    }
    &__countdown-interval {
      display: inline-block;
      width: auto;
      height: 0.48rem;
      line-height: 0.48rem;
      text-align: center;
      color: #fff;
      background: #222;
      padding: 0 .06rem;
    }
    &__countdown-colon {
      margin: 0 0.1067rem;
    }
    &__desc {
      .line-camp(3);
      line-height: 1;
      word-break: break-word;
      color: #222;
    }
  }
  .no-match-desc {
    color: #666;
    margin-top: 0.64rem;
  }
}
</style>
