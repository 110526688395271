import { computed } from 'vue'
// 埋点源：
import { useAnalysis } from '../common/analysisSource'
import { getQueryString } from '@shein/common-function'
import { throttle } from '@shein/common-function'

export function useCommon({
  appConfigs,
  props,
}) {
  const ariaLabel = computed(() => {
    return props.ariaLabel || appConfigs.$language.BPreSearchEntry?.Search || 'Search'
  })
  const { triggerNotice } = useAnalysis(props.analysisSource)

  const exposeReport: (params?: object) => void = (params) => {
    console.log('test：exposeSearchReport')
    const entrancetype = appConfigs.$getters.SaPageInfo?.page_param?.entrancetype || getQueryString({ key: 'entranceType' }) || '-'
    const extraData = {
      abtest: props.abtest,
      Searchboxform: 1,
      entrancetype,
      ...(props.extendsReport && typeof props.extendsReport === 'object' ? props.extendsReport : null),
      ...(params && typeof params === 'object' ? params : null),
    }
    triggerNotice({
      id: 'expose_search.comp_pre-search-entry',
      data: extraData,
    })
  }

  const clickSearchReport: (params?: object) => void = (params) => {
    const entrancetype = appConfigs.$getters.SaPageInfo?.page_param?.entrancetype || getQueryString({ key: 'entranceType' }) || '-'
    const extraData = {
      abtest: props.abtest,
      Searchboxform: 1,
      entrancetype,
      ...(props.extendsReport && typeof props.extendsReport === 'object' ? props.extendsReport : null),
      ...(params && typeof params === 'object' ? params : null),
    }
    triggerNotice({
      id: 'click_search.comp_pre-search-entry',
      data: extraData,
    })
  }

  const goPresearch = () => {
    if (props.goPresearchCallback && typeof props.goPresearchCallback === 'function') {
      props.goPresearchCallback()
    }
    clickSearchReport()
    const { $envs, $router } = appConfigs
    const query = {
      ...(props.routeQuery && typeof props.routeQuery === 'object' ? props.routeQuery : null),
    }
    $router.push({ path: `${$envs.langPath}/presearch`, query })
  }

  const handleClickByThrottle = throttle({
    func: goPresearch,
    wait: 1000,
    options: {
      leading: true,
      trailing: true,
    },
  })

  return {
    ariaLabel,
    goPresearch: handleClickByThrottle,
    exposeReport,
    clickSearchReport,
  }
}
