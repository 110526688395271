<template>
  <div>
    <FashionStore
      v-if="item?.isFashionStore"
      :language="language"
      :item="item"
      :config="productItemConfig"
      :index="originIndex || index"
      :constant-data="constantData"
      :analysis-data="contentAnalysisData('fashionStore', {item, index: originIndex || index })"
      :urlExtendParams="getUrlExtendParams('fashionStore', {item, index: originIndex || index })"
      @exposedCard="$emit('exposedCard', $event)"
    />
    <TrendListContent
      v-else-if="item?.isTrendContent"
      :language="language"
      :item="item"
      :config="productItemConfig"
      :index="originIndex || index"
      :constant-data="constantData"
      :urlExtendParams="getUrlExtendParams('trendList', {item, index: originIndex || index })"
      :analysis-data="contentAnalysisData('trendList', {item, index: originIndex || index })"
    />
    <template v-else>
      <NewProductItem 
        :ref="`goodsItem_${index}`"
        :key="`${item.goods_id}-${index}-${item.bffGoodsInfo ? '1' : '0'}`"
        :config="productItemConfig"
        :item="item"
        :index="originIndex || index"
        :cur-operate-index="feedbackIndex"
        :lcp="landing && (index === 0 || item.lazyClose)"
        :language="language"
        :constant-data="constantData"
        :report-metrics="reportMetrics"
        :delay-click-analysis="true"
        @mounted="$emit('cardMounted', $event, item)"
        @openQuickAdd="handleClickAddCart"
        @clickCheckout="handleClickCheckout"
        @clickItem="handleClickItem"
        @openBuyBoxDrawer="openBuyBoxDrawer"
        @longClick="$emit('longClick', $event)"
        @clickSearchFilter="$emit('clickSearchFilter', $event)"
        @exposedCard="$emit('exposedCard', $event)"
        @videoMounted="$emit('videoMounted', $event)"
      >
        <CardRecommend 
          v-if="!locals.IS_RW && useFrom !== 'RecommendDrawer'"
          ref="CardRecommend"
          :slot="slotName"
          :constant-data="constantData"
          :hasHandleFilterBar="hasHandleFilterBar"
          :language="language"
          :list-abt-result="listAbtResult"
          :feedback-rec-ccc-config="feedbackRecCccConfig"
          :item="item"
          :index="index"
          :scenes="scenes"
          :select-id="selectId"
          :cat-id="catId"
          :img-ratio="imgRatio"
          :shein-club-info="sheinClubInfo"
          @openQuickAdd="$emit('openQuickAdd', $event)"
          @operateInRecPopup="$emit('operateInRecPopup')"
          @refreshAnalysisData="$emit('refreshAnalysisData')"
          @recPopupMounted="$emit('recPopupMounted')"
          @changeSlot="changeSlot"
        />
      </NewProductItem>
    </template>
  </div>
</template>

<script>
// components
import NewProductItem from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'
import FashionStore from 'public/src/pages/components/product/carrier_ui/FashionStore/index.vue'
import TrendListContent from 'public/src/pages/components/product/carrier_ui/trend-list/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-22-7-1' })
daEventCenter.addSubscriber({ modulecode: '2-22-8-1' })

export default {
  name: 'ProductListItem',
  components: {
    NewProductItem,
    FashionStore,
    TrendListContent,
    CardRecommend: () => import(/* webpackChunkName: "plv2_CardRecommend" */'./CardRecommend/CardRecommend.vue'), // 反馈弹窗上下文组件
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    originIndex: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => ({})
    },
    productItemConfig: {
      type: Object,
      default: () => ({})
    },
    feedbackIndex: {
      type: Number,
      default: 0
    },
    landing: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => ({})
    },
    constantData: {
      type: Object,
      default: () => ({})
    },
    locals: {
      type: Object,
      default: () => ({})
    },
    cateCoupon: {
      type: Object,
      default: () => ({
        Data: {},
        Abt: {}
      })
    },
    listAbtResult: {
      type: Object,
      default: () => ({})
    },
    feedbackRecCccConfig: {
      type: Object,
      default: () => ({})
    },
    scenes: {
      type: String,
      default: ''
    },
    // 需要它来处理反馈弹窗的会员价格问题,业务层从上带下来
    sheinClubInfo: {
      type: Object,
      default: () => ({})
    },
    // 实时反馈和快加车会用到
    useFrom: {
      type: String,
      default: ''
    },
    reportMetrics: {
      type: Object,
      default: () => ({}),
    },
    // 是否存在筛选
    hasHandleFilterBar: {
      type: Boolean,
      default: false
    },
    // 当前页面的类目ID
    catId: {
      type: [Number, String],
      default: ''
    },
    // 当前页面的选品ID
    selectId: {
      type: [Number, String],
      default: ''
    },
    analysisData: {
      type: Object,
      default: () => ({})
    },
    // 是否是全局的搜索结果页
    isSearch: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      slotName: 'imgBottom',
      imgRatio: '',
    }
  },
  methods: {
    // 考虑到以后可能允许同时存在多种类型的弹窗，所以这样干
    // hack code 解决榜单类型的动态插槽问题
    async changeSlot(name = 'imgBottom') {
      // 防止后续的触发
      if(this.slotName === name) {
        return
      }
      this.slotName = name
      await this.$nextTick()
      // 动态修改插槽后重新init组件params
      this.$refs?.CardRecommend?.initRun?.(false)
    },
    handleClickItem(payload) {
      this.imgRatio = payload?.imgRatio || '3-4'
      this.$refs?.CardRecommend?.initRun?.()
      this.$emit('clickItem', payload)
    },
    handleClickColor() {
      this.$emit('clickColor')
    },
    handleClickAddCart(event) {
      this.imgRatio = event.imgRatio || '3-4'
      this.$refs?.CardRecommend?.initRun?.()
      this.$emit('openQuickAdd', event)
    },
    handleClickCheckout(emitData) {
      this.$emit('clickCheckout', emitData)
    },
    openBuyBoxDrawer(params) {
      this.$emit('openBuyBoxDrawer', params)
    },
    contentAnalysisData(type, { item, index }) {
      return { 
        exposeId: '2-22-7-1',
        clickId: '2-22-8-1',
        data: {
          type,
          item,
          index,
          isSearch: this.isSearch,
        },
        prefix: 'listContentBody',
      }
    },
    getUrlExtendParams(type, { item, index }) {
      const carrierTypeName = type === 'fashionStore' ? '时尚店铺内容体' : '趋势词内容体'
      const carrierSubTypeName = '-' //暂时获取不到，先写死’-‘ 后续内容体接入推荐后再报
      const sceneId = '-' //暂时获取不到，先写死’-‘ 后续内容体接入推荐后再报
      const { product_select_id = '-' } = item.deliveryInfo || {}
      const srcIdentifierList = [
        `tn=${this.isSearch ? 'page_search' : 'page_list'}`, 
        `ci=${product_select_id}`, 
        `ct=${carrierTypeName}`,
        `cs=${carrierSubTypeName}`, 
        `si=${sceneId}`,
        `ps=${index + 1}`,
        `jc=${carrierSubTypeName}_${product_select_id}`,
        `cate_id=-`
      ]
      return `&src_module=infoflow&src_identifier=${srcIdentifierList.join('`')}`
    }
  }
}
</script>
