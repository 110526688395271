<template>
  <div
    class="delivery-words__wrap"
  >
    <div
      class="product-card__delivery-words"
      :style="{
        '--delivery-words-display': fullWidth ? 'flex' : 'inline-flex',
        '--delivery-words-margin-top': atBottom ? '4px' : '0px',
        '--delivery-words-margin-bottom': atBottom ? '0px' : '4px',
        ...tagStyle
      }"
    >
      <img 
        v-if="deliveryInfo.img" 
        ref="iconImg"
        class="delivery-icon-img"
        :src="deliveryInfo.img"
        :style="{width: iconWidth || 'auto'}"
      />
      <div 
        v-else 
        class="delivery-icon-fallback"
      >  
        <span
          
          class="delivery-icon-text"
        >
          {{ language.SHEIN_KEY_PWA_26897 }}
        </span>
        <img
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' fill='none' viewBox='0 0 5 14'%3E%3Cpath id='Rectangle 34624868' fill='%239462FF' d='M3.424 11.03 5 0H0v14a3.46 3.46 0 0 0 3.424-2.97Z'/%3E%3C/svg%3E"
          class="trapezoid-icon"
          :class="{ 'trapezoid-icon_ar': constantData.GB_cssRight }"
        />
      </div>
      <div class="delivery-words-title">
        <template v-if="['MOD_STORE', 'CHOICE_STORE'].includes(deliveryWordsType)">
          <span 
            :style="{ margin: constantData.GB_cssRight ? '0 0 0 2px' : '0 2px 0 0', display: 'flex', alignItems: 'center' }"
          >
            <sui_icon_me_shop_15px
              size="12" 
              :color="deliveryWordsType === 'CHOICE_STORE' ? '#2d261f' : '#9462FF'"
              :is-rotate="constantData.GB_cssRight"
            />
          </span>
          <span
            ref="titleText"
            class="delivery-words-title__text"
          >{{
            storeInfo.title
          }}</span>
        </template>
        <span
          v-else
          ref="titleText"
          class="delivery-words-title__text"
        >
          {{ goodsInfo.bffGoodsInfo ? '' : '#' }}{{ deliveryInfo.display_title }}
        </span>
      </div>
      <sui_icon_more_right_12px_2
        size="12"
        :color="deliveryWordsType === 'CHOICE_STORE' ? '#2d261f' : '#9462FF'"
        style="display: inline-flex;"
        :is-rotate="constantData.GB_cssRight"
        class="delivery-words__more-icon"
      />
    </div>
    <!-- 点击热区 -->
    <div
      class="delivery-hot-zone"
      @click.stop="handleClickDeliveryWords"
    ></div>
  </div>
</template>

<script>
import { sui_icon_more_right_12px_2, sui_icon_me_shop_15px } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getQueryString } from '@shein/common-function'
import ICON_MAP from 'public/src/pages/components/product/carrier_ui/trend-list/config.js'
const { langPath } = gbCommonInfo

function getIOSVersion() {
  var ua = navigator.userAgent
  var isSafari = /^((?!chrome|android).)*safari/i.test(ua)
  if (isSafari) {
    var versionMatch = ua.match(/Version\/([0-9]+)\./)
    if (versionMatch) {
      return parseInt(versionMatch[1], 10)
    }
  }
  return false
}

let iosVersion = ''

export default {
  name: 'DeliveryWords',
  inject: {
    constantData: {
      default: () => ({})
    },
    // getPageQuery: {
    //   from: 'getPageQuery',
    //   default: () => undefined
    // },
    analysisData: {
      default: {
        entryFrom: ''
      }
    },
    config: {
      from: 'config',
      default: () => ({})
    },
    goodsInfo: {
      from: 'goodsInfo',
      default: () => ({})
    }
  },
  components: {
    sui_icon_more_right_12px_2,
    sui_icon_me_shop_15px,
  },
  props: {
    extendToChannel: {
      type: Object,
      default: () => ({})
    },
    goodsId: {
      type: [String, Number],
      default: ''
    },
    catId: {
      type: [String, Number],
      default: ''
    },
    customCateIds: {
      type: [String, Number],
      default: ''
    },
    deliveryInfo: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    atBottom: {
      type: Boolean,
      default: false
    },
    storeInfo: {
      type: Object,
      default: () => {}
    },
    isModStoreTag: {
      type: Boolean,
      default: false
    },
    isChoiceStoreTag: {
      type: Boolean,
      default: false
    },
    index: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    tagStyle() {
      if (this.deliveryWordsType === 'CHOICE_STORE') {
        return {
          '--delivery-words-bg-color': '#fef1e1',
          '--delivery-words-text-color': '#2d261f'
        }
      } else {
        return {
          '--delivery-words-bg-color': '#f4efff',
          '--delivery-words-text-color': '#9462ff'
        }
      }
    },
    attributeLabel() {
      return {
        show: this.storeInfo?.attributeLabelList?.length > 0,
        data: this.storeInfo?.attributeLabelList?.[0] ?? {}
      }
    },
    deliveryWordsType() {
      // ! 各种趋势词/店铺入口的满足条件 START
      // 趋势词
      const TREND = {
        type: 'TREND',
        flag: this.deliveryInfo?.trend_word_id
      }
      // 时尚店铺
      const MOD_STORE = {
        type: 'MOD_STORE',
        flag: this.isModStoreTag
      }
      // 品质店铺
      const CHOICE_STORE = {
        type: 'CHOICE_STORE',
        flag: this.isChoiceStoreTag
      }
      // ! END
      // 优先级排序 index越小优先级越高
      let typeSort = [TREND, MOD_STORE, CHOICE_STORE]
      // 时尚店铺置顶
      const isTrend = ['top-trend', 'trend-landing'].includes(this.config?.commonParams?.pageName)
      if (this.deliveryInfo.sortType === 'storetop' && !isTrend) {
        typeSort = [MOD_STORE, CHOICE_STORE, TREND]
      }
      // 返回第一个满足条件的type
      for (let i = 0; i < typeSort.length; i++) {
        if (typeSort[i].flag) {
          return typeSort[i].type
        }
      }
      // 默认返回趋势词
      return 'TREND'
    },
    iconWidth() {
      // 兜底 
      const backupKey = typeof window !== 'undefined' ? gbCommonInfo?.appLanguage : ''
      if (this.deliveryWordsType === 'CHOICE_STORE') {
        const tag = `${this.constantData.lang}_${this.constantData.appLanguage ?? backupKey}`
        if (tag === 'mx_es') {
          return 92 // '//shein.ltwebstatic.com/svgicons/2024/11/28/1732766582296553400.svg'的宽度
        }
      }
      const iconWidth = ICON_MAP[this.constantData.appLanguage ?? backupKey]?.imgWidth || ''
      // 取2位小数
      return iconWidth ? (iconWidth / 37.5).toFixed(2) + 'rem' : ''
    },
  },
  mounted() {
    this.$nextTick(() => {
      iosVersion = getIOSVersion()
      // 低端机需要强制渲染下 否则图片无法显示
      if (iosVersion && iosVersion < 14 && window?.IntersectionObserver) {
        const imgEl = this.$refs.iconImg
        if (imgEl) {
          // 当imgEl进入视窗后设置minWidth
          const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
              imgEl.style.minWidth = this.iconWidth
              observer.disconnect()
            }
          })
          observer.observe(imgEl)
        }
      }
    })
  },
  methods: {
    handleClickDeliveryWords() {
      const goods_id = this.goodsId
      const { storeCode, jumpHomeTab } = this.storeInfo || {}
      const {
        trend_word_id = '',
        product_select_id = '',
        product_select_url_id = ''
      } = this.deliveryInfo

      // 禁止跳转
      if (this.config.disableApartFromTheDetailsJump) return false

      // src_module
      let src_module = getQueryString({ key: 'src_module' }) || ''
      if (['top-trend', 'trend-landing'].includes(this.config?.commonParams?.pageName)) {
        src_module = 'top_trend'
      } else if (['info-flow'].includes(this.config?.commonParams?.pageName)) {
        src_module = src_module || 'page_home'
      }

      // 时尚店铺 品质店铺
      // store/home?store_code=3331888902&tab=items&tabId=allItem&adp=32164856
      // store/home?store_code=1265375321&adp=26219580&main_goods_id=26219580&main_cate_id=1773&tabId=home&isStoreTabSticky=1&src_module=list&src_identifier=store%3D1265375321&rule_poskey=ChannelShopItemList
      if (['MOD_STORE', 'CHOICE_STORE'].includes(this.deliveryWordsType)) {
        const query = {
          adp: goods_id,
          tabId: jumpHomeTab ? 'home' : 'allItem',
          store_code: storeCode,
          isStoreTabSticky: 1,
          src_tab_page_id: getSaPageInfo.tab_page_id,
          src_identifier: `store=${storeCode}`,
          src_module,
          main_goods_id: goods_id,
          main_cate_id: this.catId,
          rule_poskey: 'ListShopItemList'
        }
        this.$router.push({
          path: `${langPath}/store/home`,
          query
        })
      } else {
        // 趋势频道落地页
        // trend-landing?trendId=219&selectId=311116&selectUrlId=158311116
        const cateInfo = this.customCateIds ? { cccCatId: this.customCateIds } : { catId: this.catId }
        // channelId区分大小首页
        const channelId = getQueryString({ key: 'activeChannelId' }) || getQueryString({ key: 'channelId' }) || ''
        const query = {
          trendId: trend_word_id,
          selectId: product_select_id,
          selectUrlId: product_select_url_id,
          adp: goods_id,
          src_tab_page_id: getSaPageInfo.tab_page_id,
          src_identifier: `trend=${trend_word_id}`,
          src_module,
          entry_from: this.deliveryWordsEntryFrom(),
          channelId,
          earlyTmgRequest1210: getQueryString({ key: 'earlyTmgRequest1210' }) || '0',
          ...cateInfo,
        }
        this.$router.push({
          path: `${langPath}/trend-landing`,
          query
        })
      }

      // 埋点用
      // const joinId = [product_select_id, product_select_url_id].filter(Boolean).join('_')
      const extraData = {
        goodsId: goods_id,
        product_select_id,
        product_select_url_id,
        trend_word_id,
        trend_shop_code: '-',
        pageNumber: this.getItemPageNumber(this.index),
        resultOrder: this.getItemResultOrder(this.index),
        src_module,
        ...this.extendToChannel
      }
      if (['MOD_STORE', 'CHOICE_STORE'].includes(this.deliveryWordsType)) {
        extraData.trend_word_id = '-'
        extraData.trend_shop_code = storeCode
      }
      daEventCenter.triggerNotice({
        daId: '2-3-49',
        extraData
      })
      // TODO 这里直接埋点就好了 不用传出去
      // this.$parent.$emit('clickDeliveryWords', wingData)
    },
    getItemPageNumber() {
      return '-'
      // const { limit, pageNum } = typeof this.getPageQuery === 'function' ? this.getPageQuery() || {} : {}
      // if (typeof limit === 'undefined' && typeof pageNum === 'undefined') {
      //   return '-'
      // }
      // return typeof pageNum !== 'undefined' ? pageNum : parseInt(((index + 1) / limit)) + 1
    },
    getItemResultOrder(index) {
      return parseInt(index + 1)
    },
    deliveryWordsEntryFrom() { 
      if (this.analysisData?.entryFrom) {
        return this.analysisData.entryFrom
      }
      return `${window.SaPageInfo?.page_name || ''}\`-\`-\`trend_tag`
    },
  }
}
</script>

<style lang="less">
.delivery-words__wrap {
  position: relative;
  width: 100%;
  height: 100%;
  .delivery-hot-zone {
    position: absolute;
    height: .7467rem;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateZ(2px);
    z-index: 2;
  }
}
.product-card__delivery-words {
  display: var(--delivery-words-display, inline-flex);
  align-items: center;
  max-width: 100%;
  width: fit-content;
  margin-top: var(--delivery-words-margin-top, 4px);
  margin-bottom: var(--delivery-words-margin-bottom, 4px);
  // margin: auto 14px;
  // border: 1px solid red;
  color: var(--delivery-words-text-color,#9462ff);
  background-color: var(--delivery-words-bg-color, #f4efff);
  border-radius: 2px;
  // 隐藏兜底背景色溢出
  overflow: hidden;
  font-size: 0.2933rem;
  height: .3733rem;

  .delivery-words-title {
    padding: 1px 0 1px 2px;
    line-height: 100%;
    height: 100%;
    display: inline-flex;
    max-width: 100%;
    overflow-x: hidden;
    align-items: center;
    &__text {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      unicode-bidi: plaintext;
      line-height: 1.2;
      display: inline-block;
      overflow: hidden;
    }
  }
  .delivery-words__more-icon {
    margin-left: auto;
    padding-right: 1px;
    align-items: center;
  }
  .delivery-icon-fallback {
    position: relative;
    display: flex;
    align-items: center;
    height: .3733rem;
    line-height: .3733rem;
    font-size: .2933rem;
    font-weight: 600;
    color: #fff;
    background-color: #9462ff;
    margin-right: .1333rem;
    
    .delivery-icon-text {
      padding: 1px 0 1px 3px;
      height: 100%;
      margin-bottom: 1px;
      white-space: nowrap;
    }
  }
  .delivery-icon-img {
    height: 100%;
    width: auto;
    flex-basis: 0;
  }
  .trapezoid-icon {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(90%) scaleY(1.07);
    font-size: 0;
    width: fit-content;
    &_ar {
      transform: translateX(90%) rotateY(180deg);
    }
  }
}
</style>
