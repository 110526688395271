import type { DS_QuickAddCartStandard } from '../../../types'
import ALL_LOCATIONS from '../api/index'
import qs from 'qs'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'


/**
 * 商品详情静态数据接口
 * @returns
 */
export const getGoodDetailStaticData: DS_QuickAddCartStandard.APIS_THIS['getGoodDetailStaticData'] = async function (
  params: DS_QuickAddCartStandard.GetGoodDetailDataQueryParams,
) {
  const result = await this.$schttp<
    DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GoodsDetailStaticData>
  >({
    url: ALL_LOCATIONS.GOODS_DETAIL_STATIC_DATA,
    params,
  })
  return result.data
}

/**
 * 商品详情实时数据接口
 * @returns
 */
export const getGoodDetailRealTimeData: DS_QuickAddCartStandard.APIS_THIS['getGoodDetailRealTimeData'] =
  async function (params: DS_QuickAddCartStandard.GetGoodDetailDataQueryParams) {
    const result = await this.$schttp<
      DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GoodsDetailRealTimeData>
    >({
      url: ALL_LOCATIONS.GOODS_DETAIL_REALTIME_DATA,
      params,
    })
    return result.data
  }

/**
 * 商品详情细节图
 * @returns
 */
export const getGoodDetailImageData: DS_QuickAddCartStandard.APIS_THIS['getGoodDetailImageData'] = async function (
  params: DS_QuickAddCartStandard.GetGoodDetailDataQueryParams,
) {
  const result = await this.$schttp<
    DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GoodsDetailImageData>
  >({
    url: ALL_LOCATIONS.GOODS_DETAIL_IMAGE_DATA,
    params,
  })
  return result.data
}

export const getGoodDetailAbtInfo: DS_QuickAddCartStandard.APIS_THIS['getGoodDetailAbtInfo'] = async function (goodsId: string) {
  const result = await this.$schttp<DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GoodsDetailAbtData>>({
    url: ALL_LOCATIONS.GOODS_DETAIL_ABT_DATA,
    method: 'get',
    params: { goodsIds: goodsId },
  })
  return result.data
}

export const getLanguages: DS_QuickAddCartStandard.APIS_THIS['getLanguages'] = async function (languageKeys: string[]) {
  const result = await this.$schttp<DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.LanguageData>>({
    url: ALL_LOCATIONS.GET_LANGUAGES,
    method: 'POST',
    data: { languageKeys },
  })
  return result.data
}

export const getPageLanguages: DS_QuickAddCartStandard.APIS_THIS['getPageLanguages'] = async function (pages: string[]) {
  const result = await this.$schttp<DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.LanguageData>>({
    url: ALL_LOCATIONS.GET_PAGE_LANGUAGES,
    method: 'POST',
    data: { pages },
  })
  return result.data
}

export const getServicesTime = async function () {
  const result = await this.$schttp({
    url: `${window.location.origin}${useAppConfigs().$envs.langPath}/api/common/servicesTime/get`,
  })
  return result
}


/**
 * 加车接口
 * @returns
 */
export const postAddToCart: DS_QuickAddCartStandard.APIS_THIS['postAddToCart'] = async function (data: DS_QuickAddCartStandard.PostAddToCartParams) {
  const result = await this.$schttp<DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GoodsAddCartResponse>>({
    url: ALL_LOCATIONS.GOODS_ADD_TO_CART,
    method: 'post',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
     data: qs.stringify(data, { arrayFormat: 'brackets' }),
  })
  return result.data
}



/**
 * 收藏接口
 * @returns
 */
export const postWishlist: DS_QuickAddCartStandard.APIS_THIS['postWishlist'] = async function (data: DS_QuickAddCartStandard.PostWhishListParams, isRemove?: boolean) {
  const formData = new URLSearchParams()
  for (const [key, value] of Object.entries(data)) {
    if (value) {
      formData.append(key, value)
    }
  }
  const url = isRemove ? ALL_LOCATIONS.GOODS_WHISHLIST_REMOVE : ALL_LOCATIONS.GOODS_WHISHLIST_ADD
  const result = await this.$schttp<DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.PostWhishlistResponse>>({
    url,
    method: 'post',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
     data: formData,
  })
  return result.data
}




/**
 * 更新购物车商品属性 TODO 待联调
 * @returns
 */
export const postUpdateCart: DS_QuickAddCartStandard.APIS_THIS['postUpdateCart'] = async function (data: DS_QuickAddCartStandard.PostUpdateCartGoodsParams) {
  const result = await this.$schttp<DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.PostWhishlistResponse>>({
    url: ALL_LOCATIONS.GOODS_CART_UPDATE,
    method: 'post',
    data,
  })
  return result.data
}

/**
 * 获取购物车信息
 * @returns
 */
export const getCartInfo: DS_QuickAddCartStandard.APIS_THIS['getCartInfo'] = async function () {
  const result = await this.$schttp<DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GetCartInfoResponse>>({
    url: ALL_LOCATIONS.GOODS_CART_INFO,
    method: 'get',
  })
  return result.data
}


export const postCouponBind: DS_QuickAddCartStandard.APIS_THIS['postCouponBind'] = async function (data: DS_QuickAddCartStandard.PostCouponBind) {
  const result = await this.$schttp<any>({
    url: ALL_LOCATIONS.COUPON_POST_BIND,
    method: 'post',
    data,
  })
  return result.data
}



