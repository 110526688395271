<template>
  <div class="multi-line__half-container">
    <slot name="multiLineBg"></slot>
    <MultiLineHeader
      :css-right="cssRight"
      :prop-data="propData"
      :is-first-page="isFirstPage"
      :index="index"
      :container-index="containerIndex"
      :cate-links="cateLinks"
      :products-list="productsList"
      :home-adp-num="homeAdpNum"
      :use-front-cate-id="useFrontCateId"
      :is-early-request0903="isEarlyRequest0903"
      :early-tmg-request1210="earlyTmgRequest1210"
      :scene-data="sceneData"
      :context="context"
      :analysis-code="analysisCode"
    />
    <div
      v-if="productsList.length"
      class="multi-line__half_container-product"
    >
      <template v-for="(item, itemIndex) in productsList">
        <LazyHydrationWrapper
          :key="`${itemIndex}-multi-line_half`"
          :when-visible="true"
        >
          <component
            :is="currentComponent"
            :ref="`${templateType}-0.5-${containerIndex}`"
            :key="itemIndex"
            :product-item-config="productItemConfig"
            :context="context"
            :css-right="cssRight"
            :language="language"
            :prop-data="propData"
            :product-item="item"
            :is-first-page="isFirstPage"
            :index="index"
            :product-item-index="itemIndex"
            :container-index="containerIndex"
            :scene-data="sceneData"
            :products-list="productsList"
            :cate-links="cateLinks"
            :home-discount-new="homeDiscountNew"
            :home-adp-num="homeAdpNum"
            :use-front-cate-id="useFrontCateId"
            :crop-rate="cropRate"
            :is-early-request0903="isEarlyRequest0903"
            :early-tmg-request1210="earlyTmgRequest1210"
            :products-num="2"
            :suggested-sale-type="suggestedSaleType"
            :analysis-code="analysisCode"
            style="flex: 1"
            class="multi-line__half_container-item"
          />
        </LazyHydrationWrapper>
      </template>
    </div>
    <PlaceholderItem
      v-else
      :num="2"
      :brand="brand"
      :is-small-item="true"
      :horizontal-item-width="96"
    />
  </div>
</template>

<script>
import PlaceholderItem from 'public/src/pages/components/ccc/base/PlaceholderItem.vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { CompTemplateType } from '../constants.js'
import MultiLineHeader from '../components/Header.vue'
import Shop from '../components/Shop.vue'
import OtherTemplate from '../components/OtherTemplate.vue'
import { LazyHydrationWrapper } from '@shein-aidc/utils-lazy-hydration'

export default {
  name: 'HalfContainer',
  components: {
    MultiLineHeader,
    LazyHydrationWrapper,
    Shop,
    OtherTemplate,
    PlaceholderItem,
  },
  props: {
    ...commonProps,
    // 商品项配置
    productItemConfig: {
      type: Object,
      default: () => {}
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    isFirstPage: {
      type: Boolean,
      default: false
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0
    },
    // 置顶商品abt
    homeAdpNum: {
      type: Number,
      default: 0
    },
    // 是否使用频道所对应的cate_id
    useFrontCateId: {
      type: Boolean,
      default: false
    },
    cropRate: {
      type: String,
      default: '3-4'
    },
    // 不贵落地页早期tmg请求标志
    isEarlyRequest0903: {
      type: Boolean,
      default: false
    },
    // abt控制是否展示折扣标签，合规价
    homeDiscountNew: {
      type: String,
      default: ''
    },
    // 合规类型
    suggestedSaleType: {
      type: String,
      default: ''
    },
    // 趋势落地页参数判断
    earlyTmgRequest1210: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const {
      props: { metaData: { templateType } = {} } = {},
    } = this.propData || {}
    return {
      templateType,
    }
  },
  computed: {
    analysisCode() {
      return `multi_line_${this.index + 1}_${this.containerIndex + 1}_0.5_${this.templateType}`
    },
    currentComponent() {
      if (this.templateType == CompTemplateType.shop) {
        return 'Shop'
      }
      return 'OtherTemplate'
    },
    productsList() {
      let { products = [], flashProducts = [] } = this.propData?.props?.metaData ?? {}
      if (this.templateType === 'superDeals') {
        products = [...flashProducts, ...products]
      }
      products = products?.slice(0, 2)
      return products
    },
  },
}
</script>

<style lang="less">
.multi-line__half-container {
  width: 49.5%;
  padding: 16/75rem 8/75rem 0;
  background: #fff;
  .multi-line__half_container-product {
    display: flex;
  }
  .multi-line__half_container-item:first-child {
    margin-right: 2px;
  }
  .multi-line__half_container-item:last-child {
    margin-left: 2px;
  }
  /* 保证商品项占位的宽度与商品一致。避免与1/4同行显示占位是高低不同 */
  .product-item__horizontal {
    padding: 0;
    .product-item__horizontal-item {
      margin: 0;
      border: none;
    }
    .product-item__horizontal-item:first-child {
      margin-right: 2px;
    }
    .product-item__horizontal-item:last-child {
      margin-left: 2px;
    }
  }
}
</style>
