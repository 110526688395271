<template>
  <div>
    <s-drawer
      :visible.sync="visible"
      class="dsa_report__container"
      direction="rtl"
      type="full"
      close-icon="suiiconfont sui_icon_nav_back_24px"
      @closed="closeHandle"
    >
      <template slot="top">
        <h4
          ref="title"
          tabindex="0"
          class="nav-title"
        >
          {{ language.SHEIN_KEY_PWA_20498 }}
        </h4>
      </template>
      <div class="content">
        <div class="review-report-form__content-module">
          <div class="review-report-form__content-tit">
            <span class="require">{{ language.SHEIN_KEY_PWA_20499 }} </span>
          </div>
          <div
            class="review-report-form__content-context"
            style="margin-top: 4px;"
          >
            <s-radio-group
              v-model="formData.selectValue"
              class="select-list"
              @change="handleReasonChange"
            >
              <div
                v-for="item in options"
                :key="item.label"
                class="select-list__item"
              >
                <s-radio
                  :label="item.value"
                  :reverse-select="true"
                >
                  {{ item.label }}
                </s-radio>
              </div>
            </s-radio-group>
          </div>
          <div
            v-if="showReasonError"
            class="review-report-form__content-error"
          >
            <i class="suiiconfont sui_icon_caution_12px_2"></i>
            <span>{{ language.SHEIN_KEY_PWA_24271 || 'Please select the reason for reporting' }}</span>
          </div>
        </div>

        <div class="review-report-form__content-module">
          <div class="review-report-form__content-tit">
            <span class="require">{{ language.SHEIN_KEY_PWA_28945 }} </span>
          </div>
          <p class="review-report-form__content-info">
            {{ language.SHEIN_KEY_PWA_33172 }}
          </p>
          <div class="review-report-form__content-context">
            <Field
              ref="contentInput"
              v-model.trim="formData.content"
              :placeholder="language.SHEIN_KEY_PWA_28946"
              class="review-report-form__content-textarea"
              label=""
              :rows="6"
              input-mode="textarea"
              max-length="2000"
              show-word-limit
              focus
              @input="handleContentInput"
              @blur="handleContentBlur"
            />
          </div>
          <div
            v-if="showContentError"
            class="review-report-form__content-error"
          >
            <i class="suiiconfont sui_icon_caution_12px_2"></i>
            <span>{{ language.SHEIN_KEY_PWA_28947 }}</span>
          </div>
        </div>

        <div class="review-report-form__content-module">
          <div class="review-report-form__content-tit">
            <span class="require">{{ language.SHEIN_KEY_PWA_33173 }}</span>
          </div>
          <div class="review-report-form__content-context">
            <EmailInput
              v-model="formData.email"
              class="review-report-form__content-input"
              clearable
              label=""
              @blur="handleEmailBlur"
              @focus="handleEmailFocus"
            />
          </div>
          <div
            v-if="showEmailError"
            class="review-report-form__content-error"
          >
            <i class="suiiconfont sui_icon_caution_12px_2"></i>
            <span>{{ emailErrorText }}</span>
          </div>
        </div>

        <div
          class="review-report-form__content-agree review-report-form__content-module"
        >
          <div class="review-report-form__content-context">
            <s-checkbox v-model="isAgree">
              <span v-html="`${policyText}.`"></span>
            </s-checkbox>
          </div>
        </div>

        <s-button
          class="btn"
          :type="['primary', 'H44PX']"
          width="100%"
          :loading="loading"
          @click="submit"
        >
          {{ language.SHEIN_KEY_PWA_20497 }}
        </s-button>
      </div>
    </s-drawer>
    <StatementDrawer
      v-model="openStatementDrawer"
      :language="language"
      :loading="loading"
      @agree="handleAgree"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { Button, RadioGroup, Radio, Field } from '@shein/sui-mobile'
import { template, patternEmail } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import EmailInput from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/ShippingEnter/components/ShippingSeller/DsaReportDrawer/EmailInput.vue'
import StatementDrawer from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/ShippingEnter/components/ShippingSeller/DsaReportDrawer/StatementDrawer.vue'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

Vue.use(Radio)
Vue.use(Button)
Vue.use(RadioGroup)
const defaultConfig = {
  commentId: '',
  skc: '',
  isFree: false,
  callbacks: {
    submit: () => {}
  }
}
export default {
  name: 'ReportContainerDsa',
  components: {
    EmailInput,
    StatementDrawer,
    Field
  },
  props: {
    language: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      config: {},
      visible: false,
      formData: {
        selectValue: '',
        email: '',
        content: '',
      },
      showReasonError: false,
      showEmailError: false,
      showContentError: false,
      isAgree: false,
      openStatementDrawer: false,
      loading: false
    }
  },
  computed: {
    emailErrorText() {
      return !this.formData.email?.trim() ? this.language.SHEIN_KEY_PWA_33174 : this.language.SHEIN_KEY_PWA_33175
    },
    policyText() {
      return template(`<span class="policy" onclick="REPORT__POLICY__CLICK(event)">${this.language.SHEIN_KEY_PWA_33225}</span>`, this.language.SHEIN_KEY_PWA_33224)
    },
    options() {
      return [
        {
          label: this.language.SHEIN_KEY_PWA_21331,
          value: 19,
        },
        {
          label: this.language.SHEIN_KEY_PWA_21333,
          value: 20,
        },
        {
          label: this.language.SHEIN_KEY_PWA_21335,
          value: 21,
        },
        {
          label: this.language.SHEIN_KEY_PWA_21336,
          value: 22,
        },
        {
          label: this.language.SHEIN_KEY_PWA_21332,
          value: 23,
        },
        {
          label: this.language.SHEIN_KEY_PWA_21334,
          value: 24,
        }
      ]
    }
  },
  watch: {
    'formData.email'(val) {
      if (val?.trim()) {
        this.showEmailError = false
      }
    },
  },
  async created() {
    window.REPORT__POLICY__CLICK = (e) => {
      e.stopPropagation()
      window.open(`${gbCommonInfo?.host}${gbCommonInfo?.langPath}/Privacy-Security-Policy-a-282.html`)
    }
  },
  methods: {
    async getEmail() {
      this.formData.email = this.cacheEmail = await UserInfoManager.get({ key: 'pwa_user_email', actionType: 'dsaReport' }) || ''
    },

    submit() {
      this.reportHandle()
    },
    closeHandle() {
      if (location.hash.indexOf('#comment-report') > -1) {
        history.go(-1)
      }

      this.formData = {
        selectValue: '',
        content: '',
        email: this.cacheEmail,
      }

      this.isAgree = false
      this.showEmailError = false
      this.showContentError = false
      this.showReasonError - false
    },
    // 举报回调
    async reportHandle() {
      if (!this.formData.selectValue) {
        this.showReasonError = true
      }

      if (!this.formData.content || this.formData.content.length < 30) {
        this.$refs.contentInput.focus()
        this.showContentError = true
      }

      if (!this.formData.email || !patternEmail({ email: this.formData.email })) {
        this.showEmailError = true
      }

      if (this.showReasonError || this.showContentError || this.showEmailError) return

      if (!this.isAgree) {
        this.openStatementDrawer = true
        return
      }

      const { comment_id, isOutStoreReview, reportMemberId } = this.config

      let res = {}
      const params = {
        reportSource: 1, // 评论
        reportEntity: comment_id,
        reportDesc: this.formData.content,
        themesId: this.formData.selectValue,
        email: this.formData.email,
        commentSource: isOutStoreReview ? 1 : 0,
        brandId: gbCommonInfo?.WEB_CLIENT === 'shein' ? 7 : 9,
        reportMemberId
      }
      this.loading = true
      // TODO 目前现在先走node 接口，融合bff接口 java 中间层那边还没提供
      res = await schttp({
        url: `/api/productInfo/report/add`,
        method: 'POST',
        data: params
      })
      this.loading = false
      if (+res?.code === 0) {
        this.$toast(this.language.SHEIN_KEY_PWA_20500)
        this.visible = false
        // CallBack
        this.config.callbacks.submit({ comment_id, reason: this.formdata?.content })
      } else {
        this.$toast({
          content: this.language.SHEIN_KEY_PWA_18481 || 'report fail',
          duration: 2000,
        })
      }
    },

    open(config) {
      if (!config.comment_id) {
        console.error('缺少商品评论 comment_id!')
        return
      }
      this.config = Object.assign({}, defaultConfig, config)
      this.visible = true
      this.getEmail()
    },
    closeReport() {
      this.visible = false
    },
    handleEmailBlur() {
      if (!this.formData.email?.trim()) {
        this.showEmailError = true
      }
    },
    handleEmailFocus() {
      this.showEmailError = false
    },
    handleContentInput(val) {
      if (val && val.length >= 30) {
        this.showContentError = false
      }
    },
    handleAgree() {
      this.isAgree = true
      this.openStatementDrawer = false
      this.submit()
    },
    handleContentBlur() {
      if (!this.formData.content || this.formData.content.length < 30) {
        this.showContentError = true
      }
    },
    handleReasonChange(val) {
      this.showReasonError = !val
    }
  }
}
</script>

<style lang="less">
.dsa_report__container {
  .suiiconfont,
  .nav-title {
    font-weight: 700;
    font-size: 0.4533rem;
  }
  .content {
    padding: 0 0.4267rem;
    .title {
      padding: 0.8rem 0;
      color: #222;
      font-weight: 700;
      font-size: 0.427rem;
      line-height: 0.507rem;
      text-align: center;
    }
    margin-top: 14px;
  }
  .select-list {
    margin-bottom: 0.2rem;
  }
  .select-list__item {
    display: inline-flex;
    height: 1.28rem;
    width: 100%;
    padding: 0 0.3733rem;
    font-weight: 400;
    font-size: 0.3733rem;
    align-items: center;
    background: #f6f6f6;
    margin-bottom: 0.0533rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .btn {
    margin-top: 12px;
    margin-bottom: 1rem;
  }

  .review-report-form {
    &__content-module {
      padding-bottom: 0.5rem;
    }
    &__content-tit {
      // display: flex;
      align-items: center;
      color: #000;
      font-size: 0.37333rem;
      font-weight: 700;
      line-height: normal;
      padding-bottom: .12rem;
      .require {
        &::after {
          content: '*';
          display: inline-block;
          color: #bf4123;
          font-size: 0.37333rem;
          font-weight: 700;
        }
      }
      > span {
        padding-right: 0.1rem;
      }
      .suiiconfont {
        color: #959595;
      }
    }
    &__content-info {
      color: #959595;
      padding-bottom: 0.2rem;
      line-height: 14px;
      margin-top: -2px;
    }
    &__content-context {
      color: #000;
      font-size: 0.37333rem;
      line-height: normal;
      .kgVtzC .S-checkbox__label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 0.32rem;
      }
      .S-Field__righticon {
        z-index: 10;
        .S-Field__clear {
          margin-top: .36rem;
        }
      }
    }
    &__content-textarea {
      .S-Field__left {
        font-size: 0.37333rem;
        box-sizing: border-box;
        // padding-left: 0;
        // padding-right: 0;
        // padding-top: 0;
        padding: 0 0.32rem;
        height: 3.81333rem;
        border: 1px solid #ccc !important; /* stylelint-disable-line declaration-no-important */
        textarea {
          height: 3.0333rem;
          background: none;
        }
      }
      .S-Field {
        left: 0 /*rtl:ignore*/;
        width: 100%;
        display: none;
      }
      .S-Field__inputbox {
        margin-top: 0.2133rem;
      }
      .S-Field__counter {
        color: #bbb;
        font-size: 0.37333rem;
      }
      &.focus::after {
        display: none;
      }
    }
    &__content-input {
      .S-Field__left {
        font-size: 0.37333rem;
        box-sizing: border-box;
        padding: 0 0.32rem;
        height: 1.12rem;
        border: 1px solid #ccc !important; /* stylelint-disable-line declaration-no-important */
        input {
          height: 1.12rem;
          background: none;
        }
      }
      .S-Field__inputbox {
        margin-top: 0;
      }
      &.focus::after, .focus::after {
        display: none;
      }
    }
    &__content-agree {
      margin-top: 0.35rem;
      padding-bottom: 0;
      .policy {
        color: #2D68A8;
        text-decoration-line: underline;
      }
      .S-checkbox {
        align-items: flex-start;
      }
    }
    &__content-error {
      color: #bf4123;
      font-family: SF UI Text;
      font-size: 0.32rem;
      margin-top: 0.2rem;
      line-height: 15px;
      span {
        padding-left: 0.05rem;
      }
    }
  }
}
</style>
