import { transformImg } from '@shein/common-function'

// 轮播类型
const CAROUSELS_TYPE = {
  'GOODS_IMAGE': 'goodsImage',            // 商品图片
  'GOODS_OUTFITS': 'goodsOutfits',        // 搭配套装
  'GOODS_INTEREST': 'goodsInterest',      // 权益保障利益点
  'GOODS_SIZE_BEFORE_OUTFIT': 'goodsSizeBeforeOutfit',            // sizeguide在outfit前面
  'GOODS_SIZE_AFTER_OUTFIT': 'goodsSizeAfterOutfit',              // sizeguide在outfit后面
  'GOODS_PROPERTY_PIC': 'goodsPropertyPic',              // 属性图在主图最后一张
}

export default {
  namespaced: true,
  state: {
    current_image_id: '',                     // 当前图片ID
    is_show_big_image: false,                // 是否展示大图
    show_outfit_ymal: false  // 是否在ymal展示outfit的插坑
  },
  mutations: {
    // 更新当前页码
    update_current_image_id(state, payload = '') {
      state.current_image_id = payload
    },
    // 是否展示大图
    update_is_show_big_image(state, payload = false) {
      state.is_show_big_image = payload
    },
    // 是否在ymal展示outfit的插坑
    update_show_outfit_ymal(state, payload = false) {
      state.show_outfit_ymal = payload
    },
  },
  getters: {
    // 商品权益保障利益点
    pic_interest_info(state, getters, rootState, rootGetters) {
      const { pictureinterest, Guarantee } = rootGetters['productDetail/common/fs_abt'] || {}
      const { PUBLIC_CDN = '' } = rootGetters['productDetail/common/common_page_info']
      const goods_id = rootGetters['productDetail/common/goods_id']

      // 免费退货
      const freeReturnPolicy = rootGetters['productDetail/common/free_return_policy'] || false
      // 排行榜
      const rankingListInfo = rootGetters['productDetail/common/ranking_list_info'] || {}
      const { score, rankingTypeText, composeIdText } = rankingListInfo ?? {}
      const showRankingInfoLink = score && rankingTypeText && composeIdText
      //品牌
      const brandMapInfo = rootState.productDetail.coldModules.brandDetailInfo
      const isBrandHasTargetTag = brandMapInfo?.authenticBrand == 1
      // 安全购
      const showShippingGuarantee = Guarantee?.p?.Guarantee === 'show'

      // 是否展示权益图
      let picInterestAbt = pictureinterest?.p?.pictureinterest
      let showPicInterest = ['newlast', 'newfour', 'outfitlast'].includes(picInterestAbt)
      
      let data = [
        { key: 'ranking', value: showRankingInfoLink, type: 'rank' },
        { key: 'brand', value: isBrandHasTargetTag, type: 'brand' },
        { key: 'green', value: showShippingGuarantee, type: 'security' },
        { key: 'green', value: freeReturnPolicy, type: 'return' },
      ]
      const interestList = data.filter(item => item.value) || []
      // 主题色
      const pictureTheme = interestList[0]?.key || ''
      const cardCount = interestList.length || 0

      // 权益卡片类型
      let cardType = ''
      switch (cardCount) {
        case 3:
        case 4: 
          cardType = 'little'
          break
        case 2: 
          cardType = 'medium'
          break
        case 1: 
          cardType = 'large'
          break
        default:
          showPicInterest = false
          break
      }

      const pictureLogo = showRankingInfoLink && (cardCount === 4 ? 'picture-interest-star-8e25e0eaed' : 'picture-interest-vector-2cb542bbd9')

      // 商详页埋点设置page_name
      if(typeof window !== 'undefined' && window.SaPageInfo && window.SaPageInfo.page_param) {
        window.SaPageInfo.page_param.is_show_pictureinterest = interestList?.length ? 1 : 0 
      }
      
      return {
        showPicInterest,
        picInterestAbt,
        showRankingInfoLink,
        rankingListInfo,
        isBrandHasTargetTag,
        brandMapInfo,
        showShippingGuarantee,
        freeReturnPolicy,
        pictureTheme,
        cardType,
        pictureLogo,
        PUBLIC_CDN,
        interestList,
        key: `${goods_id}_picInter`,
      }
    },
    // 大图数据
    big_image_info(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const goods_id = rootGetters['productDetail/common/goods_id']
      const product_imgs = (rootGetters['productDetail/common/product_imgs'] || []).concat()
      const sku_code = rootGetters['productDetail/common/sku_code']
      const sale_attr_list = rootGetters['productDetail/common/main_sale_attribute_list']
      const mall_code = rootGetters['productDetail/common/mall_code']
      const add_to_bag_form = rootGetters['productDetail/common/add_to_bag_form']
      const external_size_info_index = rootGetters['productDetail/common/external_size_info_index']
      const fixed_ratio = rootGetters['productDetail/common/fixed_ratio']
      const language = rootGetters['productDetail/common/language_v2']

      const size_guide_data = getters.size_guide_data || {}
      // 是否打开加车弹窗
      const hasAttrs = sale_attr_list?.length
      const isOpenQuickView = (hasAttrs && !sku_code)
        
      // 是否显示以图搜图
      const abtPicSearchImage = fs_abt?.bigpicturesearch?.p?.bigpicturesearch === 'show'
        
      // 图搜时传的推荐规则
      const detailPicStrategy = fs_abt?.PicSearchUpgrade?.p?.PicSearchSave3 ?? ''
      let abtDetailPicStrategyRule = detailPicStrategy.includes('goodsid') ? detailPicStrategy.replace('goodsid', goods_id) : detailPicStrategy
      if(size_guide_data.isHasSizeGuide) {
        const sizeGuideInfo = {
          img_id: 'size_guide',
          is_skc_image: false,
          origin_image: size_guide_data.sizeGuideInfo.imgUrl,
          imageTips: language?.SHEIN_KEY_PWA_33731 || 'Size Guide', // 大图模式下的图片tips
        }
        product_imgs.push(sizeGuideInfo)
      }

      if (getters.property_pic_data?.data) { // 属性图在大图环节放在最后一张，在尺码图后面
        product_imgs.push(getters.property_pic_data.data)
      }

      return {
        isOpenQuickView,
        abtPicSearchImage,
        abtDetailPicStrategyRule,
        product_imgs,
        goods_id,
        mall_code,
        quantity: add_to_bag_form?.quantity || 1,
        external_size_info_index,
        fixed_ratio,
      }
    },
    // 主图角标（wiki/pageId=1091700053）
    locate_labels_data(state, getters, rootState) {
      return rootState.productDetail.hotModules.productLabel.premiumFlagList || []
    },
    // 主图角标预处理
    locate_labels_format_data(state, getters) {
      const { locate_labels_data } = getters
      if (!locate_labels_data.length) return []

      const getFormatLabel = (label) => {
        const { subscriptType, premiumFlagPosition = '' } = label || {}

        const _isFlexible = premiumFlagPosition === 'FLEXIBLE_RIGHT_LAYER' // 在右上角的灵活角标
        // 校验数据有效性
        if (subscriptType === 'image' && !label?.icon) return
        if (subscriptType === 'text' && !label?.tagName) return
        if (_isFlexible && !label?.tagName) return

        const _position = (() => {
          if (_isFlexible) {
            return {
              inUpper: true,
              inRight: true,
            }
          }

          // 根据角标方位类型添加样式
          const inUpper = premiumFlagPosition.indexOf('UPPER') > -1
          const inLower = premiumFlagPosition.indexOf('LOWER') > -1
          const inLeft = premiumFlagPosition.indexOf('LEFT') > -1
          const inRight = premiumFlagPosition.indexOf('RIGHT') > -1
          return {
            inUpper,
            inLower,
            inLeft,
            inRight,
          }
        })()

        const { inUpper, inLower, inLeft, inRight } = _position
        const keys = []
        if (inUpper) keys.push('inUpper')
        if (inLower) keys.push('inLower')
        if (inLeft) keys.push('inLeft')
        if (inRight) keys.push('inRight')

        const _positionKey = keys.length ? keys.join('_') : '-'

        return {
          _position,
          _positionKey,
          _isFlexible,
        }
      }

      // 计算角标方位
      const _locateLabelPositionMap = {}

      locate_labels_data.forEach((label => {
        const info = getFormatLabel(label)
        if (!info) return
        const { _position, _positionKey, _isFlexible } = info

        _locateLabelPositionMap[_positionKey] = _locateLabelPositionMap[_positionKey] || {
          label: null,
          flexibleLabel: null,
        }
        _locateLabelPositionMap[_positionKey][_isFlexible ? 'flexibleLabel' : 'label'] = {
          ...label,
          _position,
        }
      }))

      return Object.values(_locateLabelPositionMap)
    },
    // 页面基础数据 
    locals(state, getters, rootState, rootGetters) {
      const language = rootGetters['productDetail/common/language_v2']
      const mallCode = rootGetters['productDetail/common/mall_code']
      const pageInfo = rootGetters['productDetail/common/common_page_info']
      const isSsr = rootGetters['productDetail/common/is_ssr']

      return {
        language,
        mallCode,
        isSsr,
        ...pageInfo
      }
    },
    // 视频数据
    video_play_info(state, getters, rootState, rootGetters) {
      const videoDuration = rootState.productDetail.common.video_duration
      const isEvolushein = rootState.productDetail.coldModules.productLabel.isEvolushein
      const cccVideoPosition = rootState.productDetail.coldModules.productInfo.videoSort
      //是否展示视频按钮
      const showVimeoPlayer = rootGetters['productDetail/Main/show_native_player']
      const showNativePlayer = rootGetters['productDetail/Main/show_vimeo_player']
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const hasVideo = !!videoDuration && (showVimeoPlayer || showNativePlayer)

      const showEvolushein = fs_abt?.evolushein?.p?.evolushein === 'show'
      const checkEvolushein = isEvolushein !== '1' || (isEvolushein === '1' && showEvolushein)
      const isShowVideo = checkEvolushein && hasVideo
      
      return {
        isShowVideo,
        videoDuration,
        cccVideoPosition
      }
    },
    // 首个sku code --- 取 common sku_list ?
    first_sku_code(state, getters, rootState) {
      const sku_list = rootState.productDetail.coldModules?.saleAttr?.multiLevelSaleAttribute?.sku_list || []
      return sku_list?.[0]?.sku_code
    },
    // 是否展示 ar 试装
    show_ar_entry(state, getters, rootState, rootGetters) {
      const second_info_ready = rootState.productDetail.common.second_info_ready
      if (second_info_ready) {
        const arEntrance = rootState.productDetail.hotModules?.saleAttr?.arEntrance || false
        const isSkcSoldOut = rootGetters['productDetail/common/is_skc_sold_out']
        return !isSkcSoldOut && arEntrance
      }
      return false
    },
    // outfits | gtl
    outfits_info(state, getters, rootState, rootGetters) {
      const newCompanionInfo = rootGetters['productDetail/common/new_companion_info']
      const { GoodsdetailToppicOutfitRecommend: outfitsAbt } = rootGetters['productDetail/common/fs_abt'] || {}

      const { productRecommendDetailLabel, productNewCompanionList, ...args } = newCompanionInfo
      let outfitsInfo = productRecommendDetailLabel || {}
      // 是否存在 outfits
      let isHasOutfits = !!outfitsInfo?.url
      // 是否存在 主图展示的 gtl
      const gtlInfo = productNewCompanionList?.find(item => item.isProductDetailShow === '1') || null

      // outfits不存在时候取 gtl 图片
      if (!isHasOutfits && !!gtlInfo?.companionImageUrl) {
        outfitsInfo.url = gtlInfo?.companionImageUrl
        isHasOutfits = true
      }

      // 外露的套装是pdc就不展示锚点
      if (isHasOutfits && gtlInfo?.detailCompanionDataType === '0') {
        outfitsInfo.labels = []
      }

      const { child = [], posKey } = outfitsAbt || {}
      const { bid = '', eid = '' } = child[0] || {}
      const outfitsAbTest = bid && eid ? `${posKey}\`${eid}\`${bid}` : ''


      return {
        outfitsAbTest,
        isHasOutfits,
        outfitsInfo,
        gtlInfo: {
          ...gtlInfo,
          ...args
        },
      }
    },
    // sizeguide数据
    size_guide_data(state, getters, rootState, rootGetters) {
      const { sizeGuide } = rootState.productDetail.coldModules.productInfo.currentSkcImgInfo || {}
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const Sizepic = fs_abt?.Sizepic?.p?.Sizepic
      const sizeGuideInfo = sizeGuide
      // 是否存在 sizeguide
      const isHasSizeGuide = !!sizeGuideInfo?.imgUrl && (['A', 'B'].includes(Sizepic))
      const isSizeGuideBeforeOutfit = isHasSizeGuide && Sizepic === 'A'
      const isSizeGuideAfterOutfit = isHasSizeGuide && Sizepic === 'B'
      if(!!sizeGuideInfo?.imgUrl) {
        sizeGuideInfo.imgUrl = transformImg({ img: sizeGuideInfo.imgUrl })
      }
      return {
        isHasSizeGuide,
        isSizeGuideBeforeOutfit,
        isSizeGuideAfterOutfit,
        sizeGuideInfo
      }
    },
    property_pic_data(state, getters, rootState, rootGetters) {
      const { propertypic } = rootGetters['productDetail/product_detail_abt_info'] || {}
      const show = propertypic?.p?.propertypic === 'show'
      if (!show) return
      return getters.property_pic_data_base
    },
    // 属性图数据模型
    property_pic_data_base(state, getters, rootState, rootGetters) {
      const fixed_ratio = rootGetters['productDetail/common/fixed_ratio']
      const isRatio34 = fixed_ratio === '3-4'
      // const isRatio34 = true

      const {
        title = 'Description',
        subTitle = 'Display the multi-dimensional attributes of the product',
        fabricPatternInfos = [],
        featuresDescInfos = [],
      } = rootState.productDetail.coldModules.mainImgDescInfo || {}

      if (!fabricPatternInfos.length && !featuresDescInfos.length) return // 数据为空不展示

      const descList = fabricPatternInfos.map(item => {
        return {
          name: item.name,
          key: item.progress,
          list: item.descs.map(d => ({
            key: d.key,
            value: d.value,
          }))
        }
      })
      const attrList = featuresDescInfos.map(item => {
        return {
          attr_name: item.name,
          attr_value: item.descs?.join(', ')
        }
      })

      return {
        type: CAROUSELS_TYPE.GOODS_PROPERTY_PIC,
        key: Math.random().toString(36).substr(2, 9),
        data: {
          img_id: 'property_pic_com',
          is_skc_image: false,
          origin_image: '', // 属性图是一个组件
          hideImageSearch: true, // 大图模式隐藏图搜入口
          imageTips: title, // 图片tips
          isRatio34, // 图片比例是否为3:4 / 1:1

          title,
          subTitle,
          descList,
          attrList,
        }
      }

    },
    // 主图数据 ｜ 权益保障利益点 ｜ outfits数据
    carouse_data(state, getters, rootState, rootGetters) {
      const second_info_ready = rootState.productDetail.common.second_info_ready
      // 主图数据
      const product_imgs = rootGetters['productDetail/common/product_imgs']

      // 最后插入sizeguide 一定要在主图后，outfits前或者后
      const size_guide_data = getters.size_guide_data || {}

      const carouseData = product_imgs?.map((item) => {
        // 兼容图片非720x场景
        if (item?.origin_image?.indexOf('_thumbnail') === -1) {
          item.origin_image = item?.origin_image.replace('.webp', '_thumbnail_720x.webp')
        }
        return {
          type: CAROUSELS_TYPE.GOODS_IMAGE,
          key: Math.random().toString(36).substr(2, 9),
          data: item
        }
      })


      if (second_info_ready) {
        const { newoutfit } = rootGetters['productDetail/product_detail_abt_info'] || {}
        // 添加outfits数据
        const outfits_info = getters.outfits_info || {}
        const _showOutfits = outfits_info?.isHasOutfits && newoutfit?.p?.newoutfit === 'new'
        if (_showOutfits) {
          carouseData.push({
            type: CAROUSELS_TYPE.GOODS_OUTFITS,
            key: Math.random().toString(36).substr(2, 9),
            data: outfits_info,
          })
        }

        // 是否展示权益保障利益点
        const pic_interest_info = getters.pic_interest_info || {}
        const { showPicInterest, picInterestAbt } = pic_interest_info
        if(showPicInterest && !!product_imgs.length) {
          const interestData = {
            type: CAROUSELS_TYPE.GOODS_INTEREST,
            key: Math.random().toString(36).substr(2, 9),
            data: pic_interest_info
          }

          // 根据 abt 插入利益点
          if (picInterestAbt === 'newfour') {
            carouseData.splice(3, 0, interestData)
          } else if(size_guide_data.isHasSizeGuide) { // sizeguide存在时，需保证利益点在sizeguide前面
            if (_showOutfits) {
              carouseData.splice(carouseData.length - 1, 0, interestData)
            } else {
              carouseData.push(interestData)
            }
          } else if (_showOutfits && picInterestAbt === 'newlast' ) {
            carouseData.splice(carouseData.length - 1, 0, interestData)
          } else {
            carouseData.push(interestData)
          }
        }

        if (getters.property_pic_data?.data) { // 属性图在主图环节放在最后一张，在尺码图tab前面，在outfit前面
          // 如果存在outfits，插入到outfits前面
          if (_showOutfits) {
            carouseData.splice(carouseData.length - 1, 0, getters.property_pic_data)
          } else {
            carouseData.push(getters.property_pic_data)
          }
        }

        if(size_guide_data.isHasSizeGuide && !!product_imgs.length) {
          const sizeGuideData = {
            type: size_guide_data.isSizeGuideBeforeOutfit ? CAROUSELS_TYPE.GOODS_SIZE_BEFORE_OUTFIT : CAROUSELS_TYPE.GOODS_SIZE_AFTER_OUTFIT,
            key: Math.random().toString(36).substr(2, 9),
            data: size_guide_data
          }
          if (_showOutfits && size_guide_data.isSizeGuideBeforeOutfit) {
            carouseData.splice(carouseData.length - 1, 0, sizeGuideData)
          } else {
            carouseData.push(sizeGuideData)
          }
        }
      }

      // 图片类型
      const picType = new Set(['skc'])
      product_imgs?.forEach((item) => {
        if (item?.is_spu_image) picType.add('spu')
        if (item?.is_sku_image) picType.add('sku')
      })

      return {
        carouseData,
        CAROUSELS_TYPE,
        picType,
      }
    },
  }
}
