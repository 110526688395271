import { computed } from 'vue'
import { useViewMore } from './useViewMore'
import type { PaymentItemsProps } from '../types/props'
import { debuggerLog } from '../utils'
import { CHECKOUT_TYPE } from '@shein-aidc/bs-sdk-libs-pay'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'

export const USE_NEW_CARD_SYMBOL = Symbol('newCard')

export const usePaymentItems = (props: PaymentItemsProps) => {
  debuggerLog('usePaymentItems===props===', props)
  const { isClickViewMore, foldPositionPayments, viewMoreIconList, foldedPositionIndex, handleClickViewMore } = useViewMore(props)

  // 上次支付时用户使用的支付方式为卡token
  const nowCardPayment = computed(() => {
    debuggerLog('props.paymentInfo >>>>', props.paymentInfo, props?.paymentConfig)
    if (props?.paymentConfig?.checkoutType === CHECKOUT_TYPE?.NORMAL_AGAIN) {
      return (props.payments || []).find(payment => payment?.card_token_list?.length > 0 && payment?.code === props.paymentInfo?.paymentSuggestion) as Trade_PayToolKit.PaymentInfoItem
    }
    return {} as Trade_PayToolKit.PaymentInfoItem
  })

  const newCardInfo = computed(() => {
    return {
      isUseNewCard: true, // 使用新卡
      id: USE_NEW_CARD_SYMBOL,
      preferentialTipsOfToken: {
        paymentsPreferentialTips: nowCardPayment.value?.paymentsPreferentialTips || [],
      },
      title: nowCardPayment.value?.code === 'routepay-cardinstallment' ? props.language.BS_KEY_PAY_1218 : props.language.BS_KEY_PAY_1217,
    }
  })

  const nowCardPaymentTokenList = computed(() => {
    return [
      ...(nowCardPayment.value?.card_token_list || []),
      newCardInfo.value,
    ]
  })

  const nowCardPaymentTokenInfo = computed(() => {
    return nowCardPayment.value?.card_token || {}
  })

  const isHadTokenPay = computed(() => {
    debuggerLog('nowCardPayment.value >>>>', nowCardPayment.value)
    return nowCardPayment.value?.enabled === '1' && 'card_token_list' in (nowCardPayment.value || {})
  })

  const orderTokenId = computed(() => {
    return props?.checkoutInfo?.token_id || ''
  })

  // order 的 token_id 是否在 token list 中存在
  const isListHadTokenId = computed(() => {
    return nowCardPayment.value?.card_token_list?.length > 0 && nowCardPaymentTokenList.value.some(token => token.id === orderTokenId.value)
  })

  const paymentList = computed(() => {
    // token 平铺后，下面支付列表不展示这个支付方式
    if (isHadTokenPay.value) {
      return (props.payments || []).filter(payment => payment?.code !== nowCardPayment.value?.code)
    }
    if (!foldedPositionIndex.value || !foldPositionPayments.value?.length) return props.payments
    return props.payments.filter((_, index) => index <= foldedPositionIndex.value)
  })

  return {
    isClickViewMore,
    foldPositionPayments,
    viewMoreIconList,
    handleClickViewMore,
    paymentList,
    foldedPositionIndex,
    nowCardPayment,
    isHadTokenPay,
    nowCardPaymentTokenList,
    nowCardPaymentTokenInfo,
    isListHadTokenId,
    newCardInfo,
  }
}
