<template>
  <div class="payment-base-field">
    <div class="field-label">
      <label
        class="field-label-left"
        :for="randomId"
      >{{ label }}{{ required ? '*' : '' }}</label>
      <div class="field-label-right">
        <Icon
          v-if="showTips"
          name="sui_icon_doubt_16px_2"
          size="16px"
          color="#959595"
          :is-rotate="cssRight"
          @click.stop.prevent="handleEmit('click-tips', $event)"
        />
      </div>
    </div>
    <div
      class="field-input"
      :class="inputCustomClass"
    >
      <slot name="input-pre"></slot>
      <input
        :id="randomId"
        ref="baseInputRef"
        :value="value"
        type="text"
        :inputmode="inputmode"
        :placeholder="placeholder"
        :readonly="readonly"
        :maxlength="maxlength"
        @compositionstart="onCompositionStart"
        @compositionend="onCompositionEnd"
        @input="handleEmit('change', $event)"
        @blur="handleEmit('blur', $event)"
        @keyup="handleEmit('keyup', $event)"
        @focus="handleEmit('focus', $event)"
        @click="handleEmit('click', $event)"
      />
      <!-- sui_icon_doubt_16px_2 -->
      <slot name="input-end">
        <Icon
          v-show="showClear && value"
          class="field-clear-icon"
          name="sui_icon_cleanall_16px"
          size="16px"
          color="#959595"
          :is-rotate="cssRight"
          @click.stop.prevent="handleClear"
        />
      </slot>
    </div>
  </div>
</template>

<script setup name="BaseField" lang="ts">
import { Icon } from '@shein-aidc/icon-vue2'
import { ref, nextTick } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

// 生成随机id
const randomId = 'Payment-' + Math.random().toString(36).slice(2)

const emits = defineEmits(['input', 'change', 'blur', 'keyup', 'focus', 'click', 'click-tips', 'clear'])

defineProps({
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  inputmode: {
    type: String,
    default: 'numeric',
  },
  value: {
    type: String,
    default: '',
  },
  inputCustomClass: {
    type: String,
    default: '',
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  showClear: {
    type: Boolean,
    default: false,
  },
  showTips: {
    type: Boolean,
    default: false,
  },
  maxlength: {
    type: Number,
    default: 99,
  },
})

const { cssRight } = useAppConfigs()?.$envs || {}

const isComposing = ref(false)
const baseInputRef = ref()

// const modelValue = computed({
//   get: () => props.value,
//   set: val => emits('input', val)
// })

const onCompositionStart = () => {
  isComposing.value = true
}

const onCompositionEnd = (event) => {
  if (!isComposing.value) return
  isComposing.value = false
  emits('input', event.target.value)
  emits('change', { target: event.target })
}

const handleEmit = (event, ev) => {
  if (ev?.target?.composing == true || isComposing.value) return
  emits(event, ev)
}

const handleClear = () => {
  baseInputRef.value.value = ''
  emits('input', baseInputRef.value.value)
  emits('change', { target: baseInputRef.value })
  emits('clear', { target: baseInputRef.value })
  nextTick(() => {
    baseInputRef.value.focus()
  })
}

defineExpose({
  focus: () => baseInputRef.value.focus(),
})

</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.payment-base-field {
  display: flex;
  flex-direction: column;
  .field-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24/75rem;
    &-left {
      font-size: 14px;
      line-height: 17/14;
      color: var(---sui_color_gray_dark3, #767676);
      flex: 1;
      overflow: hidden;
    }
    &-right {
      display: flex;
      align-items: flex-start;
    }
  }
  .field-input {
    font-size: 14px;
    line-height: 17/14;
    display: flex;
    align-items: center;
    width: 100%;
    input {
      flex: 1;
      overflow: hidden;
      padding: 4/75rem 0;
      line-height: inherit;
      font-size: inherit;
      font-weight: 700;
      color: inherit;
      outline: none;
      border: none;
      filter: none;
      appearance: none;
      letter-spacing: inherit;
      &::placeholder {
        font-weight: 400;
      }
    }
    .field-clear-icon {
      line-height: inherit;
      margin-left: 8/75rem;
      cursor: pointer;
    }
  }
}
</style>
