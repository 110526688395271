import { isEqual } from '@/public/src/pages/checkout_new/utils'

/**
 * @description 处理footer数据映射
 * */
export const setFooterInfo = (state, { locals, checkout, abtInfo }) => {
  // 更新state
  Object.assign(state.footerState, {
    noRewardData: !checkout.rewardFloor?.guide
  })
}
