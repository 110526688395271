
// nickname 正则匹配
const NAME_REG = /^[\p{L}\p{M}・·\s]+$/u

// 弹窗模式
export const KIDS_MODAL_MODE = {
  UPDATE: 'update', // 更新 kid
  ADD_MULT: 'addMult', // 新增kid - 多个
  ADD_SIMPTE: 'addSimple', // 新增kid - 单个
}

// 校验 - kid名称
export const validateKidNickname = (kid, language) => {
  const { nickname } = kid

  if (!nickname || !NAME_REG.test(nickname)) {
    kid.isNameValid = false
    kid.nameErrorMsg = language.SHEIN_KEY_PWA_32971 || ''

    return false
  } 

  kid.isNameValid = true
  kid.nameErrorMsg = ''
  
  return true

}

// 校验 - kid年龄
export const validateKidBirthday = (kid, language) => {
  const { year, month } = kid

  if (!year || !month) {
    kid.isBirthdayValid = false
    kid.birthdayErrorMsg = language.SHEIN_KEY_PWA_32970 || ''
    kid.isBirthdayOver = false
    return false
  }

  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth() + 1
  const diffYear = currentYear - Number(year)

  if (diffYear > 16 || (diffYear === 16 && month <= currentMonth)) {
    kid.isBirthdayValid = false
    kid.birthdayErrorMsg = language.SHEIN_KEY_PWA_35513 || 'We can only provide personalized recommendations for kids under the age of 16. Please enter a valid date of birth.'
    kid.isBirthdayOver = true

    return false
  }
  
  kid.isBirthdayValid = true
  kid.birthdayErrorMsg = ''
  kid.isBirthdayOver = false

  return true
}

// 校验 - 单个kid
export const validateKid = (kid = {}, language) => {
  return validateKidNickname(kid, language) && validateKidBirthday(kid, language)
}

// 默认 kid 数据
export const getDefaultKid = language => ({
  id: Date.now(), // 时间戳作为id
  nickname: language.SHEIN_KEY_PWA_32905,
  month: '',
  year: '',
  sex: '2',
  isNameValid: true, // 名称校验
  nameErrorMsg: '',
  isBirthdayValid: true,
  birthdayErrorMsg: '',
  isBirthdayOver: false,
})

/**
 * toast中需要展示lottie渲染的svg，组件库lottie无法满足，所以自定义实现
 * @param {*} nickname 
 * @param {*} text 
 * @returns 
 */
export const toastInit = () => {
  let dom = document.querySelector(`.kids-toast`)
  if (dom) return
  
  dom = document.createElement('div')
  dom.classList.add('kids-toast')
  dom.setAttribute('style', `
    z-index: 999999;
    text-align: center;
    top: 50%;
    left: 50%;
    max-width: 82%;
    -webkit-transform: translate3d(-50%, -50%, 999999px);
    -ms-transform: translate3d(-50%, -50%, 999999px);
    transform: translate3d(-50%, -50%, 999999px);
    border-radius: 0.1067rem;
    position: fixed;
    background: rgba(0, 0, 0, .8);
    color: #fff;
    display: none;
  `)
  dom.innerHTML = `
      <div style="display: flex;align-items: center; justify-content: center; gap: 8px; padding: 12px 24px">
        <div class="kids-toast__icon" style="width: 20px; height: 20px;font-size: 14px;"></div>
        <div class="kids-toast__content" style="width: 211px; text-align: initial; font-size: 12px; line-height: 14px;"></div>
      </div>
    `
  document.body.appendChild(dom)
  const timer = setTimeout(async () => {
    clearTimeout(timer)
    const [lottieWeb, lottieData] =  await Promise.all([
      import('lottie-web'),
      import('public/src/pages/components/FilterBar/CloudTags/components/TagItem/TagKids/lottie.json')
    ])
    // 使用局部变量存储结果
    const lottieModule = lottieWeb.default
    const lottieJsonData = lottieData.default

    lottieModule?.loadAnimation({
      container: document.querySelector('.kids-toast .kids-toast__icon'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: lottieJsonData,
      name: 'kidPopoverIcon'
    })
  }, 1000)
}

export const toastRemove = () => {
  const timer = setTimeout(() => {
    clearTimeout(timer)
    document.querySelector(`.kids-toast`)?.remove()
  }, 1000 * 1.5) // toast 显示1.5s，所以3s后移出
}

export const toastShow = (nickname, text) => {
  const textList = text.split('{0}')
  const domKidToast = document.querySelector(`.kids-toast`)
  if (!domKidToast) return
  const domContent = document.querySelector(`.kids-toast .kids-toast__content`)
  domContent.innerHTML = `${textList[0] || ''} <b>${nickname || ''}</b> ${textList[1] || ''}`
  domKidToast.style.display = 'block'

  const tiemr = setTimeout(() => {
    clearTimeout(tiemr)
    domKidToast.style.display = 'none'
  }, 1000 * 1.5)
}
