<template>
  <swiper-container
    ref="benefitsSwiperRef"
    class="benefit-bar-benefits"
    init="false"
    destroy-on-disconnected="false"
    :autoplay="true"
    :reverseDirection="true"
  >
    <swiper-slide
      v-for="(rewardItem, index) in rewardList"
      :key="rewardItem.type"
      class="benefit-bar-benefits__slide"
    >
      <div class="benefit-bar-benefits__item">
        <div
          ref="barBenefitItemRef"
          class="benefit-bar-benefits__item-card"
        >
          <component
            :is="getComponent(rewardItem.type)"
            :reward-item="rewardItem"
            :is-mini="isMini"
            :benefit-component-type="benefitComponentType"
          />
        </div>
        <sui_icon_add_12px
          v-if="index < rewardList.length - 1"
          class="benefit-bar-benefits__item-separator"
          size="8px"
          color="#FA6338"
        />
      </div>
    </swiper-slide>
  </swiper-container>
</template>

<script name="BenefitBarBenefits" setup lang="ts">
import { type Ref, ref, onMounted, watch } from 'vue'
import { register } from 'swiper/element'
// @ts-ignore 该库未提供类型定义
import { sui_icon_add_12px } from '@shein-aidc/icon-vue2'
import CreditIcon from '../Credit/CreditIcon.vue'
import BenefitCoupon from '../BenefitCoupon/index.vue'
import BenefitFreeGift from '../BenefitFreeGift/index.vue'
import PointCard from '../BenefitPoint/Card.vue'
import { E_BenefitFloor, type C_BenefitFloor } from '../../../../types'

typeof window !== 'undefined' && register()

const props = defineProps<{
  isMini: boolean,
  benefitComponentType: E_BenefitFloor.BenefitComponentType,
  rewardList: C_BenefitFloor.RewardItem[],
}>()

const benefitsSwiperRef: Ref = ref(null)
const barBenefitItemRef: Ref = ref(null)
const getComponent = (type) => {
  switch(type) {
    case E_BenefitFloor.BenefitType.ReturnCredit:
      return CreditIcon
    case E_BenefitFloor.BenefitType.ReturnCoupon:
      return BenefitCoupon
    case E_BenefitFloor.BenefitType.FreeGift:
      return BenefitFreeGift
    case E_BenefitFloor.BenefitType.Point:
      return PointCard
    default:
      return null
  }
}

const initSwiper = () => {
  if (typeof window === 'undefined') return
  const swiper = benefitsSwiperRef.value
  if (!swiper) return
  const swiperParams = {
    spaceBetween: 2,
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    allowTouchMove: true,
    enabled: true,
    slidesOffsetAfter: 5,
    observeParents: true,
    observeSlideChildren: true,
    observer: true,
  }
  Object.assign(swiper, swiperParams)
  swiper.initialize()
}

onMounted(() => {
  initSwiper()
})

watch(
  () => props.isMini,
  () => {
    if (typeof window === 'undefined' || !benefitsSwiperRef.value) return
    // swiper 在尺寸变化时，需要更新
    let delay = 600
    if (barBenefitItemRef.value?.[0]) {
      const { transitionDuration } = window.getComputedStyle(barBenefitItemRef.value[0])
      delay = parseFloat(transitionDuration) * 1000
    }
    setTimeout(() => {
      benefitsSwiperRef.value.swiper?.update?.()
    }, delay)
  },
  { immediate: true },
)
</script>

<style lang="less" scoped>
.benefit-bar-benefits {
  width: 100%;
  height: 100%;
  z-index: 0;

  &__slide {
    width: auto;
    display: flex;
    align-items: center;
  }

  &__item {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__item-card {
    transition: all .5s linear;
  }

  &__item-separator {
    margin-left: 4/75rem;
  }
}
</style>
