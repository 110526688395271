import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

export function useItemGuideAddOnState() {
  const { state } = useStore()
  const showFloor = computed(() => state.cartItemGuideAddOnState.showFloor)
  const showPopover = computed(() => state.cartItemGuideAddOnState.showPopover)
  const currentGuideAddOnCartId = computed(() => state.cartItemGuideAddOnState.currentGuideAddOnCartId)
  return {
    currentGuideAddOnCartId,
    showCartItemGuideAddOnPopover: showPopover,
    showCartItemGuideAddOnFloor: showFloor,
  }
}
