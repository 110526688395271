<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div 
    v-if="searchStoreCard"
    :class="['banner-wrap', {'fashion-card': !!fashionBg}]"
  >
    <div 
      v-expose="{
        id: '2-27-10',
        code: exposeCode,
        delayReady: 1500,
        data: {
          store_code: searchStoreCard.store_code,
          store_banner: searchStoreCard.storeBanners?.[0]?.icon ? 1 : 0
        }
      }"
      class="search-shop-banner"
      @click="handleStoreEntryClick"
    >
      <header class="search-shop-banner__header header">
        <div class="header__left">
          <BaseImg
            class="header__logo"
            :first-screen="true"
            :is-support-webp="true"
            :img-design-width="40"
            :img-src="searchStoreCard.logo"
          />
          <div class="header__content">
            <div class="header__title">
              {{ searchStoreCard.title }}
            </div>
            <div class="header__labels-container">
              <div
                v-for="(item, index) in searchStoreCard.storeLabels"
                :key="index"
                class="header__labels"
              >
                <!-- 时尚标签 -->
                <div 
                  v-if="item.labelType == '2'"
                  class="header__label-image"
                >
                  <img
                    v-if="item?.iconVo?.imgUrl"
                    :src="item?.iconVo?.imgUrl"
                  />
                  <div 
                    v-else
                    class="header__label-fallback"
                  >
                    <span>{{ item?.labelName || '' }}</span>
                  </div>
                </div>
                <!-- 品质标签 -->
                <div 
                  v-if="item.labelType == '4'"
                  class="header__label-image"
                >
                  <img
                    :src="item?.iconVo?.imgUrl || ''"
                  />
                </div>
                <!-- 品牌店铺 -->
                <div
                  v-if="item.labelType == '1'"
                  class="header__store_brand header__label"
                >
                  <BaseImg
                    class="header__label_icon"
                    :first-screen="true"
                    :is-support-webp="true"
                    :img-design-width="12"
                    style="transform: translateY(0.4px);"
                    img-src="https://img.ltwebstatic.com/images3_ccc/2024/12/02/de/17331334597bc147648042508530bb02071e0c7e93.png"
                  />
                  <div 
                    class="header__label_txt" 
                    style="transform: translateY(0.5px);"
                  >
                    {{ language.SHEIN_KEY_PWA_24319 }}
                  </div>
                </div>
              
                <!-- 优质店铺 -->
                <div
                  v-if="item.labelType == '3'"
                  class="header__store_best header__label"
                >
                  <BaseImg
                    class="header__label_icon"
                    :first-screen="true"
                    :is-support-webp="true"
                    :img-design-width="12"
                    :img-src="`${locals.PUBLIC_CDN}/pwa_dist/images/store/store_preferred_seller-bb758e2772.png`"
                  />
                  <div class="header__label_txt header__color_best ">
                    {{ language.SHEIN_KEY_PWA_20758 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header__right">
          <div>{{ language.SHEIN_KEY_PWA_20627 }}</div>
          <i class="suiiconfont sui_icon_more_right_12px"></i>
        </div>
      </header>
      <section 
        v-if="showShopCenterInfo" 
        class="search-shop-banner__content store-data"
      >
        <div
          v-if="searchStoreCard.storeRating && searchStoreCard.storeRating !== '0'"
          class="store-data__line"
        >
          <div class="store-data__key">
            {{ language.SHEIN_KEY_PWA_20750 }}
          </div>
          <div class="store-data__mark">
            :
          </div>
          <div class="store-data__value">
            {{ formatShopScore }}
          </div>
        </div>
        <div
          v-if="searchStoreCard.storeWishCount && searchStoreCard.storeWishCount !== '0'"
          class="store-data__line"
        >
          <div class="store-data__key">
            {{ language.SHEIN_KEY_PWA_24069 }}
          </div>
          <div class="store-data__mark">
            :
          </div>
          <div class="store-data__value">
            {{ searchStoreCard.storeWishCount }}
          </div>
        </div>
        <div
          v-if="storeTags?.length"
          class="store-data__hot-labels"
        >
          <div 
            v-if="(searchStoreCard.storeRating && searchStoreCard.storeRating !== '0') || (searchStoreCard.storeWishCount && searchStoreCard.storeWishCount !== '0')" 
            class="store-data__divider-line"
          ></div>
          <StoreLabelSwiper :labelList="storeTags" />
        </div>
      </section>
      <section 
        v-if="searchStoreCard.storeBanners?.[0]?.icon"
        class="search-shop-banner__banner"
      >
        <section
          class="search-shop-banner__banner-img"
          :style="'background-image: url('+ searchStoreCard.storeBanners?.[0]?.icon +');'"
        >
        </section>
      </section>
      <section
        v-if="products.length > 3"
        class="goods-list"
      >
        <div 
          v-for="(item, index) in products"
          :key="index"
        >
          <div 
            class="goods-list__item"
            @click.stop="handleStoreDetailClick(item)"
          >
            <!-- <BaseImg
              class="goods-list__img"
              :first-screen="true"
              :is-support-webp="true"
              fit="cover"
              position="center"
              :img-design-width="80"
              :img-src="item.goods_img"
            /> -->
            <img
              class="goods-list__img"
              :src="item.goods_img"
            />
            
            <div class="goods-list__price">
              <ProductCardPriceSimple 
                :goods-info="item"
                :language="language"
                :isPaid="!!sheinClubInfo?.isPaid" 
                :config="{
                  hidePriceBottomLabel: true,
                  noCamelCasePrice: true,
                  priceColor: searchStoreCard.suggestedSaleType
                }"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { parseQueryString, stringifyQueryString } from '@shein/common-function'
import ProductCardPriceSimple from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'

daEventCenter.addSubscriber({ modulecode: '2-27' })
export default {
  name: 'SearchShopBanner',
  components: {
    BaseImg,
    ProductCardPriceSimple,
    StoreLabelSwiper: () => import('public/src/pages/components/ccc/components/shop-banner/StoreLabelSwiper.vue')
  },
  props: {
    searchStoreCard: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    sheinClubInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    locals: {
      type: Object,
      default() {
        return {}
      }
    },
    exposeCode: {
      type: String,
      default: ''
    },
    fashionBg: {
      type: String,
      default: ''
    },
    constantData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPaid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatShopScore() {
      return (+this.searchStoreCard.storeRating).toFixed(2)
    },
    storeTags () {
      return (this.searchStoreCard?.storeServiceLabels ?? []).map(item => ({
        description: 'sold',
        iconLink: item.icon,
        title: item.labelLang
      }))
    },
    showShopCenterInfo () {
      const { storeRating, storeWishCount } = this.searchStoreCard
      return !!parseFloat(storeRating) || !!parseInt(storeWishCount) || !!this.storeTags.length
    },
    products() {
      return (this.searchStoreCard?.shopRecProducts ?? []).map(item => goodsTransformation(item, {
        suggestedSaleType: this.searchStoreCard.suggestedSaleType || '',
        language: this.language ?? {},
        lang: this.constantData.lang,
        isPaid: this.isPaid
      }))
    }
  },
  methods: {
    // 跳转到店铺主页
    handleStoreEntryClick({ target }) {
      daEventCenter.triggerNotice({
        daId: '2-27-11',
        extraData: {
          store_code: this.searchStoreCard.store_code,
          store_banner: target.className.includes('banner-img') ? 1 : 0
        },
      })
      const { src_module = '', src_identifier, src_tab_page_id = '' } = parseQueryString(location.search)
      const srcData = {
        src_module,
        src_identifier,
        src_tab_page_id,
        page_from: 'PageSearchResult',
        rule_poskey: 'SearchshopitemList'
      }
      const { langPath = '' } = gbCommonInfo || {}
      const url = `${langPath}/store/home?store_code=${
        this.searchStoreCard.store_code
      }&${stringifyQueryString({ queryObj: srcData })}`
      this.$router.push(url)
    },
    // 跳转到店铺主页并置顶商品
    async handleStoreDetailClick(product = {}) {
      const { store_code } = this.searchStoreCard
      const { goods_id, goods_sn, productRelationID, salePrice, retailPrice, mall_code, cat_id } = product
      const goodsId = goods_id
      const skuId = goods_sn
      const spuId = productRelationID
      const page = 1
      const index = 0
      const operation = 1
      const recMark = ''
      const price = salePrice?.usdAmount
      const originPrice = retailPrice?.usdAmount
      const  goods_to_list = `${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|estimated_pri_${originPrice}\`\`\`mall_${mall_code}` // goods_list: goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark
      daEventCenter.triggerNotice({
        daId: '2-27-11',
        extraData: {
          goods_to_list,
          store_code: store_code
        },
      })
      
      const { langPath = '' } = gbCommonInfo || {}
      const {  src_module = '', src_identifier, src_tab_page_id = '' } = parseQueryString(location.search)
      const srcData = {
        store_code: store_code,
        adp: goods_id,
        page_from: 'PageSearchResult',
        src_tab_page_id,
        src_module,
        src_identifier,
        main_goods_id: goods_id,
        main_cate_id: cat_id,
        rule_poskey: 'SearchshopitemList',
        ...(goods_id ? { isStoreTabSticky: 1, tabId: 'items' } : null)
      }
      const url = `${langPath}/store/home?${stringifyQueryString({ queryObj: srcData })}`
      this.$router.push(url)
    }
  }
}
</script>

<style lang="less" scoped>
html[mir='rtl'] .header__store_brand .header__label_icon{ 
  transform: rotateY(180deg);
}
// 设计稿用的 375px
@unit: 375/100vw;
.banner-wrap {
  padding: 12/ @unit 8 / @unit;
  background-color: var(---gray_weak2, #F6F6F6);
  &.fashion-card {
    padding-top: 8 / @unit;
    padding-bottom: 4 / @unit;
    position: relative;
    z-index: 1;
    background-color: transparent;
    .search-shop-banner {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.60);
      backdrop-filter: blur(4px);
      border: 1px solid rgba(151, 128, 199, 0.10);
    }
    .store-data__divider-line {
      background-color: #959595;
    }
  }
}
.search-shop-banner {
  padding: 8 / @unit;
  border-radius: 8 / @unit;
  background: #FFF;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__content {
    margin-top: 8 / @unit;
    display: flex;
    align-items: center;
  }
  &__banner {
      margin-top: 8 / @unit;
      height: 100 / @unit;
      &-img {
        height: 100%;
        width: 100%;
        border-radius: 4 / @unit;
        background-repeat: no-repeat;
        background-size: cover;
      }
  }
}
.header {
  &__left {
    display: flex;
    align-items: center;
    height: 40 / @unit;
  }
  &__right {
    border-radius: 4 / @unit;
  }
  &__logo {
    width: 40 / @unit;
    height: 40 / @unit;
    border-radius: 4 / @unit;
    .border-dpr(border, 1 / @unit, #E5E5E5);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    background-color: #FFFFFF;
    > img {
      max-height: 100%;
      height: auto;
    }
  }
  &__content {
    height: 40 / @unit;
    margin-left: 8 / @unit;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    color: #222;
    font-size: 14 / @unit;
    font-weight: 700;
    line-height: 17 / @unit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 180 / @unit;
  }
  &__labels-container {
    display: flex;
    align-items: center;
    margin-top: 4 / @unit;
    flex-wrap: wrap;
    height: 16 / @unit;
    overflow: hidden;
    margin-right: 8 / @unit;
  }
  &__labels {
    display: flex;
    align-items: center;
    margin-right: 4 / @unit;
  }
  &__label {
    height: 16 / @unit;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-right: 2 / @unit;
  }
  &__store_brand {
    display: flex;
    padding: 0 5 / @unit 0 2 /@unit;
    align-items: center;
    border-radius: 2 / @unit;
    color: #265999;
    font-size: 13  / @unit;
    font-style: italic;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 2 / @unit;
    background: #E0F1FF;
    .header__label_icon  img {
      transform: translateY(-0.5 / @unit);
      width: 12 / @unit;
      height: 12 / @unit;
    }
    .header__label_txt {
      font-weight: 700;
      font-family: 'SF UI Text', 'PingFang TC', 'sans-serif';
    }
  }
  &__store_best {
    display: flex;
    padding: 0 4 / @unit 0 2 /@unit;
    align-items: center;
    border-radius: 2 / @unit;
    background: #FFF8EB;
    color: #A86104;
  }
  &__label-image {
    height: 16 / @unit;
    display: flex;
    img {
      height: 100%;
    }
  }
  &__label-fallback {
    height: 16 / @unit;
    background-color: #9462ff;
    color: #fff;
    position: relative;
    border-radius: 2 / @unit;
    padding: 0 4 / @unit;
    height: 16 / @unit;
  }
  &__label_icon {
    width: 12 / @unit;
    margin-right: 2 / @unit;
  }
  &__label_txt {
    height: 16 / @unit;
    font-size: 12 / @unit;
    line-height: 16 / @unit;
  }
  &__store_normal {
    display: flex;
    height: 100%;
    align-items: center;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5 / @unit 8 / @unit;
    background: var(---sui_color_gray_dark1, #000);
    color: var(---sui_color_white, #FFF);
    font-size: 12 / @unit;
  }
}
.store-data {
  &__line {
    display: flex;
    align-items: center;
    padding-right: 8 / @unit;
  }
  &__key {
    font-size: 11 / @unit;
    color: @sui_color_gray_dark2;
  }
  &__mark {
    color: #222;
    font-size: 11 / @unit;
    margin-right: 4 / @unit;
  }
  &__value {
    color: #222;
    font-size: 12 / @unit;
    font-weight: 500;
  }
  &__hot-labels{
    display: flex;
    padding-left: 8 / @unit;
    position: relative;
  }
  &__divider-line {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1 / @unit;
    height: 10 / @unit;
    display: inline-block;
    background: #E5E5E5;
  }
}
.goods-list {
  margin-top: 8 / @unit;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8 / @unit;
  &__item {
    position: relative;
  }
  &__img {
    width: 80 / @unit;
    height: 80 / @unit;
    border-radius: 4 / @unit;
    box-shadow: 0 0 1 / @unit 0 rgba(0, 0, 0, 0.2);
    object-fit: cover;
    object-position: center;
  }
  &__price {
    padding: 1 / @unit 3 / @unit;
    position: absolute;
    bottom: 4 / @unit;
    right: 4 / @unit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2 / @unit;
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(1 / @unit);
    box-shadow: 0 0 2 / @unit 0 rgba(0, 0, 0, 0.12);
  }
  /deep/ .product-card__price-simple .price-content{
    padding-right: 0;
  }
}

</style>
