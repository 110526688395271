<template>
  <div
    class="bsc-benefit-coupon"
    :class="{
      'bsc-benefit-coupon-mini': isMini,
      'bsc-benefit-coupon-entry': !isShowEntryBlock,
      'bsc-benefit-coupon-fold2': fold2 && !isShowEntryBlock,
      'bsc-benefit-coupon-fold3': fold3 && !isShowEntryBlock,
    }"
  >
    <div
      class="bsc-benefit-coupon__container"
      :class="{'bsc-benefit-coupon__container-mini': isMini}"
    >
      <couponItem
        v-for="(item, index) in itemsData"
        :key="index"
        :style="calcuTranslate(index, item)"
        :couponItem="item"
        :isMini="isMini"
        :isShowPlus="isShowPlus"
      />
      <div
        v-if="isShowCornerMark"
        class="bsc-benefit-coupon__container_corner-mark"
        :class="{
          'bsc-benefit-coupon__container_corner-free-mini': isMini,
          'bsc-benefit-coupon__container_corner-all-free': isAllFreeCoupon,
          'bsc-benefit-coupon__container_corner-all-discount': isAllDiscountCoupon,
          ' bsc-benefit-coupon__container_corner-free': hasFreeShippingCoupon,
        }"
      >
        {{ cornerText }}
      </div>
    </div>
    <AdapterText
      v-if="isShowEntryBlock"
      class="bsc-benefit-coupon__desc"
      :text="rewardItem?.desc"
      :lines="2"
      :min-size="11"
    />
  </div>
</template>
<script setup lang="ts">
  import {computed} from 'vue'
  import { template as _commonTemplate } from '@shein/common-function'
  import couponItem from './coupon-item.vue'
  import { AdapterText } from '@shein/sui-mobile'
  import { E_BenefitFloor, C_BenefitFloor } from '../../../../types'
  type propsType = {
    rewardItem?: C_BenefitFloor.RewardItem,
    isMini?: boolean,
    benefitComponentType?: E_BenefitFloor.BenefitComponentType,
  }

  const props = withDefaults(defineProps<propsType>(), {
    isMini: false,
    benefitComponentType: E_BenefitFloor.BenefitComponentType.Bar,
  })

  const calcuTranslate = (index, item) => {
    const translateX = props.isMini ? 38 / 37.5 : 58 / 37.5
    let firstHeight = !props.isMini ? `${36/37.5}rem` : `${24/37.5}rem`
    let secondHeight = !props.isMini ? `${33.75/37.5}rem` : `${21.818/37.5}rem`
    return `
      transform: translate3d(${-translateX * index}rem, 0, 2px) rotate(0deg);
      height: ${index == 0 ? firstHeight : secondHeight};
      z-index: ${index == 0 ? 10 : 10 - index};
      opacity: ${index == 0} ? 1 : 0.7;
      ${item.type === 'freeShipping'} ? 'flex-direction: column;' : ''
    `
  }

  const isShowEntryBlock = computed(() => {
    return props?.benefitComponentType === E_BenefitFloor.BenefitComponentType.Floor
  })

  const fold2 = computed(() => {
    return itemsData.value?.length == 2
  })

  const fold3 = computed(() => {
    return itemsData.value?.length == 3
  })

  const itemsData = computed(() => {
    return props?.rewardItem?.returnCoupon?.couponList || []
  })

  const isShowPlus = computed(() => {
    return !isAllFreeCoupon.value && !isAllDiscountCoupon.value && !isAllFreeCoupon.value && itemsData.value?.length <= 1
  })

  const cornerText = computed(() => {
    let text = props?.rewardItem?.returnCoupon?.topRightTag?.text
    // 大于99，展示+99
    // if((isAllFreeCoupon.value || isAllDiscountCoupon.value) && text) {
    //   if(!isNaN(Number(text)) && Number(text) > 99) {
    //     text = '+99'
    //   }
    // }
    return text
  })

  // 全部为免邮券
  const isAllFreeCoupon = computed(() => {
    return props?.rewardItem?.returnCoupon?.topRightTag?.type === 'allFreeShipping'
  })

  // 全部为折扣券
  const isAllDiscountCoupon = computed(() => {
    return props?.rewardItem?.returnCoupon?.topRightTag?.type === 'allDiscount'
  })

  //有免邮券
  const hasFreeShippingCoupon = computed(() => {
    return props?.rewardItem?.returnCoupon?.topRightTag?.type === 'hasFreeShipping'
  })

  //是否展示右上角角标
  const isShowCornerMark = computed(() => {
    return (isAllFreeCoupon.value || isAllDiscountCoupon.value || hasFreeShippingCoupon.value) && props?.rewardItem?.returnCoupon?.topRightTag?.text
  })
</script>
<style lang="less">
.bsc-benefit-coupon {
  display: flex;
  width: 140/37.5rem;
  padding: 10/37.5rem 8/37.5rem;
  align-items: center;
  gap: 8/37.5rem;
  flex-shrink: 0;
  border-radius: 8/37.5rem;
  // border: 1/37.5rem solid rgba(255, 255, 255, 0.90);
  background: linear-gradient(97deg, rgba(255, 145, 145, 0.20) 9.36%, rgba(255, 255, 255, 0.20) 91.53%), #FFF;
  // box-shadow: 0px 2px 4px 0px rgba(140, 8, 0, 0.08);
  &__container {
    text-align: center;
    display: flex;
    // width: calc(64/37.5rem + 8/37.5rem + 6/37.5rem);
    width: 70/37.5rem;
    position: relative;
    &_corner-mark {
      z-index: 13;
      position: absolute;
      top: -7/37.5rem;
      font-size: 8px;
      right: 0;
      padding: 1/37.5rem 3/37.5rem;
      line-height: 1;
      border-radius: 0px 6px 0px 4px;
      background: #FF8143;
      line-height: 1;
    }
    &_corner-free {
      color: #fff;
      border-radius: 100/37.5rem 100/37.5rem 100/37.5rem 0;
      padding: 0.6/37.5rem 2/37.5rem;
      border: 0.5px solid #FFF;
      background: #169E00;
      text-align: center;
      font-family: "SF Pro";
      font-size: 7px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      top: -4.1/37.5rem;
    }
    &_corner-all-free {
      line-height: 1;
      border-radius: 0 4/37.5rem;
      border: none;
      top: 0;
      background: #169E00;
      color: #FFF;
      font-size: 6px;
      font-style: normal;
      font-weight: 700;
    }
    &_corner-all-discount {
      line-height: 1;
      top: 0 !important;
      border: none;
      border-radius: 0 4/37.5rem;
      background: #FF8143;
      color: #fff;
      padding: 1px 2px;
    }
    &_corner-free-mini {
      padding: 0.7/37.5rem 2/37.5rem;
      font-size: 5px;
      top: -3/37.5rem;
    }
    .bsc-benefit-coupon__container_corner-free-mini.bsc-benefit-coupon__container_corner-all-discount,  .bsc-benefit-coupon__container_corner-free-mini.bsc-benefit-coupon__container_corner-all-free {
      top: 0;
      font-size: 7px;
    }
  }
  &__container-mini {
    width: 50/37.5rem;
  }
  &__desc {
    color: #FF344E;
    text-align: left;
    font-family: "SF Pro";
    font-style: normal;
    line-height: 1;
    font-size: 14px;
    font-weight: 860;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    width: 46/37.5rem;
    word-break: break-all;
  }
}
.bsc-benefit-coupon-entry{
  padding: 2/37.5rem 0;
  width: 64/37.5rem;
  height: 40/37.5rem;
  background: none;
}
.bsc-benefit-coupon-fold2 {
  width: 70/37.5rem;
}
.bsc-benefit-coupon-fold3 {
  width: 76/37.5rem;
}
.bsc-benefit-coupon-mini.bsc-benefit-coupon-fold2 {
  width: 50/37.5rem;
}
.bsc-benefit-coupon-mini.bsc-benefit-coupon-fold3 {
  width: 56/37.5rem;
}
.bsc-benefit-coupon-mini{
  padding: 0;
  width: 44/37.5rem;
  height: 24/37.5rem;
  box-shadow: none;
  background: none;
  border: none;
  .bsc-benefit-coupon__percent-container_identify {
    font-size: 10px;
  }
}
</style>
