<template>
  <ul :class="prefixCls('main')">
    <template
      v-for="item in handleComponentList"
    >
      <component
        :is="item.comp"
        v-if="item.show || model.isClickedMore"
        :key="item.comp.name"
        :is-folded="item.isFolded"
        :class="prefixCls('dynamic-comp-item')"
        v-bind="item.props"
      />
    </template>

    <!--显示更多-->
    <li
      v-if="isShowMore && !model.isClickedMore"
      v-expose="{
        id: 'expose_virtual_assets:simple',
      }"
      v-tap="{
        id: 'click_virtual_assets:simple',
      }"
      class="btn-more-line"
      @click="model.isClickedMore=true"
    >
      {{ language.SHEIN_KEY_PWA_21350 }}
      <Icon
        name="sui_icon_more_down_12px_2"
      />
    </li>
  </ul>
</template>
<script setup name="VirtualList">
import { reactive, computed, watch } from 'vue'
import AssetsCoupon from './assets_list/assets_coupon/index.vue'
import AssetsWallet from './assets_list/AssetWallet.vue'
import AssetsPoints from './assets_list/AssetsPoints.vue'
import AssetsGiftCard from './assets_list/assets_giftcard/index.vue'
import AssetsNewGiftCard from './assets_list/AssetsNewGiftCard.vue'
import { prefixCls } from './utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'
import { Icon } from '@shein-aidc/icon-vue2'
import { VirtualAssetsTypes } from '@/public/src/pages/checkout_new/utils/constant'

const model = reactive({
  isClickedMore: false,
  riskType: 0
})

const { isClient } = useIsClient()

const { point, wallet_balance, walletRiskInfo, giftcard, language } = useMapState([
  'checkout.wallet_balance',
  'checkout.walletRiskInfo',
  'checkout.point',
  'checkout.giftcard',
  'language',
])

const showWallet = computed(() => {
  return wallet_balance.value?.totalPrice?.some(item => +item.price.amount > 0)
})

const maxAvailablePoint = computed(() => +point.value?.maxAvailablePoint)

const showPoints = computed(() => {
  return +maxAvailablePoint.value > 0
})

const isShowMore = computed(() => { // 存在钱包或者积分为空的场景展示more
  return !showPoints.value || !showWallet.value
})

/**
 * 0 abt关，并且用户没有已经绑定的卡
 * 1 abt关，但是有已经绑定的卡
 * 2 abt开
*/
const getGiftCardAbt = computed(() => +giftcard.value?.gift_card_abt || 0)

const componentList = computed(() => {
  return [
    {
      comp: AssetsCoupon,
      type: VirtualAssetsTypes.COUPON,
      show: true,
    },
    {
      comp: AssetsWallet,
      props: {
        isFolded: !showWallet.value,
        riskType: model.riskType
      },
      type: VirtualAssetsTypes.WALLET,
      show: showWallet.value,
    },
    {
      comp: AssetsPoints,
      type: VirtualAssetsTypes.POINT,
      show: showPoints.value,
    },
    {
      comp: getGiftCardAbt.value === 0 ? AssetsGiftCard : AssetsNewGiftCard,
      type: VirtualAssetsTypes.GIFT_CARD,
      show: true,
    }
  ]
})

const handleComponentList = computed(() => {
  const TypeSortMap = {
    [VirtualAssetsTypes.COUPON]: 1,
    [VirtualAssetsTypes.WALLET]: !showWallet.value ? 20 : 2,
    [VirtualAssetsTypes.POINT]: !showPoints.value ? 30 : 3,
    [VirtualAssetsTypes.GIFT_CARD]: 4
  }

  return componentList.value.map(item => {
    let isFolded = false

    if(item.type === VirtualAssetsTypes.WALLET) {
      isFolded = !showWallet.value
    } else if(item.type === VirtualAssetsTypes.POINT) {
      isFolded = !showPoints.value
    }

    return {
      ...item,
      weight: TypeSortMap[item.type],
      isFolded: +isFolded
    }
  }).sort((a, b) => a.weight - b.weight)
})

watch(walletRiskInfo, () => {
  if(!isClient.value || !walletRiskInfo.value) {
    return false
  }

  window.appEventCenter.$emit('virtual-assets:update-wallet-risk_confirm', ({
    riskConfirmInst
  }) => {
    const value = +walletRiskInfo.value?.risk_decision === 0 && +riskConfirmInst.updateRiskStr(walletRiskInfo.value) || 1

    model.riskType = value
  })
}, {
  immediate: true
})
</script>

<style lang="less">
@import "./variables.less";

.@{prefixCls}main {
  background: #fff;
  .padding-l(.32rem);
  .padding-r(.32rem);

  .@{prefixCls}dynamic-comp-item {
    padding: .2rem 0;
    .align-center();
    .border-dpr(border-bottom, 2px, #e5e5e5);
    min-height: 1.28rem;

    &:last-child {
      border-bottom: none;
    }

    // common start
    &__coupon-right {
      .txt-r();
      .padding-r(.6rem);

      .use-total {
        color: #767676;
      }

      .use-red {
        font-weight: bold;
        color: @sui_color_discount;
      }

      // .coupon-prime {
      //   color: @sui_color_club_rosegold_dark2;
      // }
    }

    .input-icon {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .input-icon-cell {
        display: table-cell;
      }
    }

    // .stand-left {
    //   width: 100%;
    // }


    .input-coupon {
      .flexbox();
      .align-center();
      .space-between()
    }

    .icon-arrow {
      position: absolute;
      right: .12rem;
      top: 50%;
      transform: translateY(-50%);
      color: @sui_color_gray_light1;
    }
  }

  .btn-more-line {
    text-align: center;
    .font-dpr(28px);
    color: @sui_color_gray_dark3;
    border-bottom: 0;
    padding: .26666667rem 0;
  }
}
</style>
