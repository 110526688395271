import { usePriceChangeState } from './usePriceChangeState.js'

export function usePriceChange() {
  const { showPopover, updatePriceChangeState } = usePriceChangeState()

  const handlePriceChangeBefore = (item) => {
    // 如果当前气泡正在展示直接隐藏
    if (showPopover.value) {
      updatePriceChangeState({
        showPopover: false,
      })
    }
    if (item?.is_checked == 0) {
      updatePriceChangeState({
        checkBeforeItem: item,
      })
    } else {
      updatePriceChangeState({
        checkBeforeItem: null,
      })
    }
  }

  const handlePriceChangeAfter = (item) => {
    if (item?.is_checked == 1) {
      updatePriceChangeState({
        checkAfterItem: item,
      })
    } else {
      updatePriceChangeState({
        checkAfterItem: null,
      })
    }
  }

  return {
    handlePriceChangeBefore,
    handlePriceChangeAfter,
  }
}
