<template>
  <div class="checkout-price-detail">
    <PriceItem
      v-for="item in sortedPrice"
      :key="item.type"
      :item="item"
      :is-show-shipping-fee="isShowShippingFee"
      @show-tips="handleTipsShow"
      @open-details-drawer="handleDrawerOpen"
    />
    <OrderTotal
      ref="orderTotalRef"
      class="checkout-price-detail__total"
      :grand-total-title="grandTotalTitle"
      :tax-inclusive-tip="taxInclusiveTip"
      :save-price-tips="savePriceTips"
      :total-price="totalPrice"
      :gov-tax-price="govTaxPrice"
    />

    <!-- 点击icon提示文案弹窗 -->
    <TipsDialog
      :visible.sync="tipsVisible"
      :content="tipsContent"
      :btn-text="dialogBtnText"
    />

    <!-- 详情弹窗 -->
    <DetailsDrawer
      :visible.sync="detailsVisible"
      :item="itemDetailsData"
      :btn-text="drawerBtnText"
      @show-tips="handleTipsShow"
    />
  </div>
</template>

<script name="StaticCheckoutPrice" setup lang="ts">
// 数据源：
import { type C_CheckoutPrice, E_CheckoutPrice } from '../../types'
import { type Ref, computed, ref } from 'vue'
import OrderTotal from './components/order-total.vue'
import PriceItem from './components/price-item.vue'
import TipsDialog from './components/tips-dialog.vue'
import DetailsDrawer from './components/drawers/index.vue'

interface PropsDefault {
  originSortedPrice: C_CheckoutPrice.OriginSortedPrice,
  sortedPriceStyle: C_CheckoutPrice.SortedPriceStyle,
  scene: C_CheckoutPrice.Scene,
  isShowShippingFee?: C_CheckoutPrice.IsShowShippingFee,
  isInDrawer?: C_CheckoutPrice.IsInDrawer,
  totalStyle?: C_CheckoutPrice.TotalStyle,
  grandTotalTitle: C_CheckoutPrice.GrandTotalTitle,
  taxInclusiveTip?: C_CheckoutPrice.TaxInclusiveTip,
  savePriceTips?: C_CheckoutPrice.SavePriceTips,
  totalPrice: C_CheckoutPrice.TotalPrice,
  govTaxPrice?: C_CheckoutPrice.GovTaxPrice,
  dialogBtnText: C_CheckoutPrice.DialogBtnText,
  drawerBtnText?: C_CheckoutPrice.DrawerBtnText
}

const props = withDefaults( defineProps<PropsDefault>(), {
  originSortedPrice: () => ({} as PropsDefault['originSortedPrice']),
  scene: 'checkout',
  isShowShippingFee: false,
  isInDrawer: false,
  totalStyle: false,
  grandTotalTitle: '',
  taxInclusiveTip: '',
  savePriceTips: '',
  totalPrice: '',
  govTaxPrice: '',
  dialogBtnText: '',
  drawerBtnText: '',
})

const emits = defineEmits(['click-shipping-fee-icon'])

const sortedPrice = computed(() => props.originSortedPrice?.filter(_ => _.show == 1) || [])

const tipsVisible = ref(false)
const tipsContent = ref('')
const handleTipsShow = ({ event, tips }) => {
  if (event === E_CheckoutPrice.TipsClickedEvent.EmitEvent) {
    emits('click-shipping-fee-icon')
    return
  }
  tipsVisible.value = true
  tipsContent.value = tips
}

const detailsVisible = ref(false)
const itemDetailsData = ref({} as C_CheckoutPrice.PriceItemInfo)
const handleDrawerOpen = (item) => {
  detailsVisible.value = true
  itemDetailsData.value = item
}

const orderTotalRef: Ref = ref(null)
const totalRightPriceWrapperRef = computed(() => orderTotalRef.value?.$refs?.totalRightPriceWrapperRef)
defineExpose({ totalRightPriceWrapperRef })
</script>

<style lang="less" scoped>
.checkout-price-detail {
  padding: 16/75rem 24/75rem;
  background: #fff;
  color: #222;

  &__total {
    margin-top: 8/75rem;
  }

}
</style>
