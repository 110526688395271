<template>
  <div>
    <QuickRegister :from="'detail'" />

    <RecommendBottom v-if="all_data_ready || delayDestroy" />
    <UsInfo
      :onloadStatus="detail_page_onload_status"
    />

    <div style="background-color: #f6f6f6;height: 1.3rem;"></div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import lazyComponent from 'public/src/services/lazyComponent'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getQueryString } from '@shein/common-function'
daEventCenter.addSubscriber({ modulecode: '1-6-5' })
const components = [
  {
    name: 'QuickRegister',
    style: {  },
    componentFactory: () => import('public/src/pages/common/quick_register/container.vue')
  },
  {
    name: 'UsInfo',
    style: { },
    componentFactory: () => import('public/src/pages/goods_detail_v2/innerComponents/bottom/innerComponents/UsInfo.vue'),
  },
]

const componentsBff = [
  {
    name: 'RecommendBottom',
    style: {  },
    componentFactory: () => import('public/src/pages/goods_detail_v2/innerComponents/recommend/RecommendBottom/index.vue')
  },
]

export default {
  name: 'BottomContent',
  components: {
    ...([...components, ...componentsBff].reduce((componentsResult, curr) => {
      componentsResult[curr.name] = lazyComponent({
        ...curr,
        options: {
          style: curr.style || { height: '2rem' }
        }
      })
      return componentsResult
    }, {})),
  },
  computed: {
    ...mapState('newProductDetail/common', ['delayDestroy']),
    // NEW BFF
    ...mapState('productDetail/common', ['detail_page_onload_status']),
    ...mapGetters('productDetail/common', ['fs_abt', 'all_data_ready']),
  }, 
  watch: {
    all_data_ready: {
      handler(val) {
        if (val) {
          // 表示从立即购下单页返回商详页，需要下滑至推荐位
          const afterbuyroad = getQueryString({ key: 'buyNow' }) === 'afterbuyroad'
          const afterbuyroadAbt = this.fs_abt?.DetailBuynow?.p?.afterbuyroad === 'recommend'
          if(afterbuyroad && afterbuyroadAbt){
            this.$nextTick(() => {
              // 加载底部推荐位
              appEventCenter.$emit('allLazyComponentRender')
            })
            // delete路由参数buyNow
            const url = new URL(window.location)
            url.searchParams.delete('buyNow')
            window.history.replaceState({}, '', url)
            setTimeout(() => {
              this.set_goods_detail_scroll()
            }, 3500)
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('productDetail/Recommend', ['set_goods_detail_scroll']),
  },
}
</script>

<style lang="less">
</style>
