import { dprInsert } from 'public/src/pages/common/customDetailDialog/utils/tools.js'
import { transformImg } from '@shein/common-function'

const flashSaleAndDiscountLimitHandle = (goods = [])=>{
  let obj = {
    orderTotalProductSize: 0,
    skcLimitNum: {}
  }
  goods.forEach(item => {
    obj.orderTotalProductSize += item.quantity
    // 同类商品skc不同尺码sku加起来的数量
    obj.skcLimitNum[item.product.goods_sn] = (obj.skcLimitNum[item.product.goods_sn] || 0) + item.quantity
  })
  return obj
}

/**
 *
 * @param {object} product 购物车商品信息 不传则计算总数skc
 * @param {object} itemPromotionGroupInfo 商品所在促销组信息
 * @param {boolean} isOver 判断是超出还是满足或超出 默认false满足或超出 点击checkout校验时传true
 * limit_code:0超限拦截 1超限按原价
 * @param {boolean} isDetail 是否返回计数详情, 用于兼容旧的调用方式, 默认为false, 传true时, 将以对象的形式返回计数详情及超限判断结果
 * @return {*|boolean}
 */
export const handleOrderOrSingleIsMeetOrOver = (product = {}, itemPromotionGroupInfo = {}, isOver, isDetail = false) => {
  let result = 0
  let flashSaleAndDiscountLimitHandleInfo
  if(([10].includes(+itemPromotionGroupInfo.type_id) && itemPromotionGroupInfo.limit_mode != 1) || [11].includes(+itemPromotionGroupInfo.type_id)){
    let single_over = false
    let order_over = false
    let proGroupList = itemPromotionGroupInfo.list?.filter(item => item.is_checked) || []
    flashSaleAndDiscountLimitHandleInfo = flashSaleAndDiscountLimitHandle(proGroupList)
    let { member, single, order } = itemPromotionGroupInfo.cart_limit || {}
    if(+single){
      if(!product) {
        let flashSaleSkcLimitNum =  Object.values(flashSaleAndDiscountLimitHandleInfo.skcLimitNum)
        single_over = flashSaleSkcLimitNum.find(item => item > single) ? true : false
      } else {
        single_over = isOver ? flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] > single : flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] >= single
      }
    }
    let cart_limit_order = 0
    if(+member && +order){
      cart_limit_order = Math.min(+member, +order)
    }else if( +order || +member ){
      cart_limit_order = +member || +order
    }
    if(cart_limit_order) {
      order_over = isOver ? flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize > cart_limit_order : flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize >= cart_limit_order
    }
    /**
     * 将四种组合状态转换为二进制数进而转换为0-3的状态码 result
     * 0: 未超限
     * 1: 单品超限
     * 2: 整单超限, 总数超限
     * 3: 单品超限且整单超限
     * 此处状态码与transformPromotionTip中的content.tip.list索引对应
     */
    result = parseInt(`${+Boolean(order_over)}${+Boolean(single_over)}`, 2)
  }
  if([24, 30].includes(+itemPromotionGroupInfo.type_id)){
    let proGroupList = itemPromotionGroupInfo.list?.filter(item => item.is_checked) || []
    flashSaleAndDiscountLimitHandleInfo = flashSaleAndDiscountLimitHandle(proGroupList, true)
    let { single, order, member } = itemPromotionGroupInfo.cart_limit || {}
    // 同类商品不同尺码加起来的数量超过该商品的限购数时
    if(+single){
      const single_over = isOver ? flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] > single : flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] >= single
      single_over && (result = 1)
    }
    let cart_limit_order = 0
    if(+member && +order){
      cart_limit_order = Math.min(+member, +order)
    }else if( +order || +member ){
      cart_limit_order = +member || +order
    }
    if(cart_limit_order){
      const order_over = isOver ? flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize > cart_limit_order : flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize >= cart_limit_order
      order_over && (result = 2)
    }
  }
  if(isDetail) return { result, flashSaleAndDiscountLimitHandleInfo }
  else return result
}


/**
 *
 * @param [String] amountWithSymbol 带币种金额
 * @return string
 */
export const amountWithSymbolTransform = (amountWithSymbol = '', currencyDecPointSymbol = '') => {
  if ( !amountWithSymbol ) return ''
  if(!currencyDecPointSymbol && typeof window != 'undefined') {
    let { currency, currencies, lang, appLanguage } = gbCommonInfo
    let currencyObj = currencies?.[lang]?.[currency] || {}
    let { separator_multi_resp_list = [] } = currencyObj || {}
    let existSeparator = separator_multi_resp_list?.filter(item => item?.language_flag == appLanguage)
    if (existSeparator?.length) {
      currencyDecPointSymbol = existSeparator?.[0]?.dec_point_symbol || '.'
    } else {
      currencyDecPointSymbol = currencyObj?.dec_point_symbol || '.'
    }
  }
  let hasDecPointSymbol = amountWithSymbol.includes(currencyDecPointSymbol)
  let reg = hasDecPointSymbol ? new RegExp(`^(\\D*)(.*?)([\\${currencyDecPointSymbol}])(\\d*?)(\\D*)$`, 'g') : new RegExp(`^(\\D*)(.*?)(\\D*)$`, 'g')
  let result = amountWithSymbol.replace(reg, function () {
    let currencyStr = RegExp.$1 || (hasDecPointSymbol ? RegExp.$5 : RegExp.$3) ? `<span class="price-currency">${ RegExp.$1 || (hasDecPointSymbol ? RegExp.$5 : RegExp.$3) }</span>` : ''
    let str = `<span class="price-amount-decimal">`
    str = str + (RegExp.$2 ? `<span class="price-amount">${ RegExp.$2 }</span>` : '')
    str = str + (hasDecPointSymbol && RegExp.$3 ? `<span>${ RegExp.$3 }</span>` : '')
    str = str + (RegExp.$4 ? `<span class="price-decimal">${ RegExp.$4 }</span>` : '')
    str = str + `</span>`
    return RegExp.$1 ? currencyStr + str : str + currencyStr
  })
  return result
}

export function getCartItemIndex({ item, id, cartsId } = {}) {
  id = id || (item ? item.id : '')
  if (!id) return -1
  if (typeof cartsId !== 'undefined') {
    return cartsId.indexOf(id)
  } 
  if (typeof window === 'undefined' || !window._gb_app_) return -1
  cartsId = window._gb_app_.$store.state.cart.cartsId || []
  return cartsId.indexOf(id)
}

export function getMainImageUrl({ item, IS_RW, customServiceImgDomains, thirdPartyServiceImgDomains }) {
  if (item.isBlindBoxFlag) {
    return transformImg({ img: 'http://img.ltwebstatic.com/images3_acp/2024/11/22/24/1732274754479c270973cb429359284ea52acad318.png' })
  } else {
    const isCustomGood = Boolean(Object.keys(item.product?.customization_info || {}).length)
    if(isCustomGood && !IS_RW) {
      const effectUrl = dprInsert({ img: item.product?.customization_info?.preview?.effects?.[0] || item.product?.goods_img || '', customServiceImgDomains, thirdPartyServiceImgDomains }) // effects 为空则不做替换
      return transformImg({ img: effectUrl })
    } else {
      return transformImg({ img: item.product?.goods_img })
    }
  }
}


/* 判断当前活动是否是新人返券活动
* @param {*} promotionInfo 活动信息
*/
export const isNewUserReturnCoupon = (promotionInfo) => {
  if (typeof window === 'undefined') return false
  const { NEW_USER_COUPON_TYPE_ID = 0 } = gbCommonInfo || {}
  return promotionInfo?.typeId == 21 && promotionInfo?.coupons?.length && promotionInfo?.coupons?.every(item => item?.type_id == NEW_USER_COUPON_TYPE_ID)
}

/**
 * 获取商品销售属性文案
 */
export const getItemSaleText = ({ defaultlocalsize, skuSaleAttr, sizeRuleList, userLocalSizeCountry }) => {
  const formatStrategy = {
    old: {
      cartSize: ({ defaultAttr, localAttr }) => {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    new: {
      cartSize: ({ defaultAttr }) => {
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    local_size_no_default: {
      cartSize: ({ defaultAttr, localAttr }) => {
        return localAttr || defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        return localAttr || defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    default_size_no_default: {
      cartSize: ({ defaultAttr }) => {
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        return localAttr || defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
  }

  const executeStrategy = () => {
    const strategy = formatStrategy[defaultlocalsize] ?? formatStrategy.old
    const handleStrategy = userLocalSizeCountry ? userLocalSizeCountry === 'default' ? strategy.defaultSize : strategy.localSize : strategy.cartSize
    const getSizeMap = (attrSize = []) => attrSize?.reduce((acc, item) => ({ ...acc, [item.name]: item }), {})
    const attrSizeMap = getSizeMap(sizeRuleList)
    return skuSaleAttr?.map(item => {
      const text = item.is_main != 1
        ? handleStrategy({
          defaultAttr: item.attr_value_name,
          localAttr: attrSizeMap[item.attr_value_name_en]?.correspond,
        })
        : item.attr_value_name
      return text
    }) ?? []
  }

  return executeStrategy()
}

/**
 * 获取折扣文案
 */
export const getDiscountText = (lang, GB_cssRight, discount) => {
  if (discount) {
    const symbol = lang != 'il' ? '-' : ''
    return GB_cssRight ? `%${discount}${symbol}` : `${symbol}${discount}%`
  }
  return ''
}
