<template>
  <ul class="bs-color-square-image__wrapper">
    <li
      v-for="(color, index) in data"
      :key="color.goods_id"
      :fsp-key="color?.goods_image || ''"
      :class="{
        'fsp-element': index === 0,
        'bs-color-square-image__item': true,
        'sold-out': color?.isSoldOutStatus === '1',
        'active': activeGoodsId === color.goods_id,
      }"
      @click="useOnColorChange(emits, color, index)"
    >
      <!-- 前 7 个方形色块使用src直出 -->
      <CropImageContainer
        v-if="index <= 6"
        :fixedRatio="config?.fixedRatio"
        :imgSrc="useBaseCutImg(color.goods_image, 80, { max: 100 })"
      />
      <!-- 后续图片使用懒加载 -->
      <CropImageContainer
        v-else
        :fixedRatio="config?.fixedRatio"
        :imgSrc="transformImg({ img: color.goods_image || '' })"
        :imgProps="imgProps"
      />
      <!-- 售罄蒙层 -->
      <div
        v-if="color?.isSoldOutStatus === '1'"
        class="bs-color-square-image__sold-out">
      </div>
      <!-- 右上角角标 -->
      <slot
        name="UPPER_RIGHT_TAG"
        :tagInfo="color.tag"
      >
      </slot>
    </li>
  </ul>
</template>

<script name="BSColorSquareImage" setup lang="ts">
import { defineEmits } from 'vue'
import CropImageContainer from '../crop-Image-container.vue'
import { transformImg } from '@shein/common-function'
import type { DS_MainSalesAttr } from '../../../types/index'
import useOnColorChange from '../../hook/useOnColorChange'
import useBaseCutImg from '../../hook/useBaseCutImg'

interface ColorModeConfig {
  lazyImg: string
  fixedRatio: string
}

interface ColorModeProps {
  /** 色块数据 */
  data: DS_MainSalesAttr.MainSaleAttributeInfo []
  /** 色块配置 */
  config?: ColorModeConfig,
  /** 当前选中的商品ID */
  activeGoodsId: string,
}

const props = withDefaults(defineProps<ColorModeProps>(), {
  data: () => ([]),
  activeGoodsId: '',
})

const imgProps = {
  src: props.config?.lazyImg,
  dataDesignWidth: '80',
  class: 'lazyload',
  dataExp: 'max=100',
}

const emits = defineEmits<{
  (event: 'onChange', params: object): void
}>()

</script>

<style lang="less" scoped>
@vw: 375/100vw;

.bs-color-square-image {
  &__wrapper {
    display: flex;
    padding-top: 6 /@vw;
    margin-bottom: 14 /@vw;
    font-size: 0;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

  }

  &__item {
    position: relative;
    flex: 0 0 38 / @vw;
    width: 38 / @vw;
    margin-right: 12 / @vw;
    padding: 2 / @vw;
    border: 1px solid transparent;

    img {
      width: 100%;
    }

    &.active {
      border: 1px solid #222;
    }

    &.sold-out {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(-13%, -50%, 0) rotate(-55deg);
        transform-origin: center;
        width: 140%;
        height: 3px;
        background: rgba(153, 153, 153, 0.2);
        z-index: 1;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(-13%, -50%, 0) rotate(-55deg);
        transform-origin: center;
        width: 140%;
        height: 4px;
        background: rgba(255, 255, 255, 0.6);
        font-size: 0;
      }
    }
  }

  &__sold-out {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
</style>
