import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

class SafePerformanceUpload {
  SDK_NAME = ['antiin', 'main.js', 'infp']
  resourceQueue = []
  send() {
    this.resourceQueue.forEach(_ => {
      const { tags, value } = _
      SIMetric?.metricTime({
        metric_name: 'risk_sdk_js_resource_duration',
        tags,
        value
      }, {
        random: gbCommonInfo?.RISK_SDK_CONFIG?.performance?.random || 0.05
      })
    })
  }
  push(entry) {
    this.resourceQueue.push({
      tags: {
        site: gbCommonInfo?.SiteUID || '',
        name: `${entry.name}`.split('/').slice(-1)[0] || '',
        channel: 'M',
        deliverType: entry.deliveryType,
        responseStatus: entry.responseStatus,
        isMember: UserInfoManager.get({ key: 'memberId', actionType: 'performance_safe' }) ? 1 : 0,
        pageName: gbCommonInfo?.ENTRY_PAGE_NAME || '',
      },
      value: entry.duration || null
    })
  }
}

window.addEventListener('TPM_SDK_Loaded', () => {
  if (typeof window !== 'undefined') {
    const { RISK_SDK_CONFIG } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
    if (RISK_SDK_CONFIG?.performance?.open) {
      const safeUpload = new SafePerformanceUpload()
      performance.getEntriesByType('resource').forEach(_ => {
        safeUpload.SDK_NAME.map(name => {
          if (_.name.indexOf(name) > -1) {
            safeUpload.push(_)
          }
        })
      })
      safeUpload.send()
    }
  }
})
