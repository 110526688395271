import dayjs from 'dayjs'
import UserInfoManager from 'public/src/services/UserInfoManager'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

/**
 * 缓存结构
 * key:kidTag_key
 * value: {
 *  [memberId1]: {
 *    inster: timestamp, // 	时间戳 - 插入第一位置时间 【pageId=1413952776】
 *    simplePopover: true/false // 是否展示过气泡
 *    toasts: timestamp, // timestamp - 默认童装toast时间，24h内不再toast展示
 *    multKidsPopoverShowTotal: 1｜2 //  多个儿童气泡展示次数，最多展示2次 【pageId=1513846528】
 *    multKidsPopoverTimestamp: timestamp， // timestamp - 多个儿童气泡展示时间，7day内不再展示 【pageId=1513846528】
 *    defaultKid: { // 默认选中的儿童档案
 *     default_child_id: '',
 *     default_child_sex: '',
 *     default_child_birthday: ''
 *   }
 *  },
 *  [memberId2]: {
 *    ...
 *  }
 * }
 */

const LOCALSTORAGE_KEY = 'kidTag_key'

// 获取唯一标识[memberid]
function getSoleKey () {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'RECOMMENDPOP/GETKEY' })
  return memberId || gbCommonInfo?.SiteUID
}

function getKidsTagLocalStorage () {  
  const cache = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) ?? '{}')
  return cache
}

function setKidsTagLocalStorage (cache) {  
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(cache))
}

// 是否展示simple气泡
export function getIsShowKidsSimplePopoverByCache () {
  if (typeof window == 'undefined') return false

  const key = getSoleKey()
  const allCacheData = getKidsTagLocalStorage()
  const cache = allCacheData[key] ?? {}
  allCacheData[key] = cache
  if (!cache.simplePopover) {
    cache.simplePopover = true
    setKidsTagLocalStorage(allCacheData)
    return true
  }

  return false
}

// 是否展示多孩气泡
export function getIsShowMultKidsPopoverByCache () {
  if (typeof window == 'undefined') return false

  const key = getSoleKey()
  const allCacheData = getKidsTagLocalStorage()
  const cache = allCacheData[key] ?? {}
  allCacheData[key] = cache
  const { multKidsPopoverTimestamp, multKidsPopoverShowTotal } = cache
  if (multKidsPopoverShowTotal >= 2) return false // 最多展示2次
  
  // 0次
  if (!multKidsPopoverShowTotal) {
    cache.multKidsPopoverShowTotal = 1
    cache.multKidsPopoverTimestamp = Date.now()
    setKidsTagLocalStorage(allCacheData)

    return true
  }

  // 1次 & 7天内已经展示过
  if (dayjs().diff(dayjs(multKidsPopoverTimestamp), 'day') < 7) {
    return false
  }

  cache.multKidsPopoverShowTotal = 2
  setKidsTagLocalStorage(allCacheData)
  return true
}

// 获取 kids 对应的值
export const getKidValue = item => {
  const { sex } = item
  const nowDay = dayjs()
  const birthday = dayjs(item.birthday, 'YY-MM')
  const totalMonth = nowDay.diff(birthday, 'month')

  if (totalMonth <= 36) {
    return sex == 1 ? '600060714' : '600060717'
  } else if (totalMonth <= 84) {
    return sex == 1 ? '600060719' : '600060720'
  } else if (totalMonth <= 144) {
    return sex == 1 ? '600060724' : '600060725'
  } else if (totalMonth < 192) {
    return sex == 1 ? '600060731' : '600060732'
  }
  
  return ''
}

// 判断当前标签是否为 kids 标签【】
export const isFilterByKids = id => {
  return [
    '600060714', 
    '600060717',
    '600060719', 
    '600060720',
    '600060724', 
    '600060725',
    '600060731', 
    '600060732'
  ].includes(id)
}

const DEFAULT_CHILD_KEY = 'defaultChild'
const DEFAULT_CHILD_PARAMS = { 
  default_child_id: '', 
  default_child_sex: '', 
  default_child_birthday: ''
}

// 校验是否为默认儿童页面【真实/选品/搜索/店铺/shein picks】
const verifyDefaultKidsPage = (pathname) => {
  const patterns = [
    /\/.+-c-\d+\.html$/, // 真实
    /\/.+-sc-[A-Za-z0-9]+\.html$/, // 选品
    /pdsearch\/.+/, // 搜索
    /\/.*([a-z]+-picks|romwe-[a-z]+|promo-discount)\.html$/, // shein picks
    /store\/home$/, // 店铺
    /.+-store-\d{10}\.html$/ // 店铺
  ]

  return patterns.some(pattern => pattern.test(pathname || window.location.pathname))
}

// 获取默认选中的儿童信息档案
export const getDefaultKidParams = (pathname) => {
  if (
    typeof window == 'undefined'
    || !verifyDefaultKidsPage(pathname)
  ) return DEFAULT_CHILD_PARAMS

  const key = getSoleKey()
  const allCacheData = getKidsTagLocalStorage()
  return allCacheData[key]?.[DEFAULT_CHILD_KEY] || DEFAULT_CHILD_PARAMS
}

// 获取默认选中的儿童信息档案 - 通过ab判断
export const getDefaultKidParamsByAb = (pathname) => {
  if (
    typeof window == 'undefined'
    || !verifyDefaultKidsPage(pathname)
  ) return DEFAULT_CHILD_PARAMS

  const KidsRegistryChosen = window?._abt_server_provider?.abtMap?.get('KidsRegistryChosen')
  if (KidsRegistryChosen?.p?.KidsRegistryChosen !== 'yes') return DEFAULT_CHILD_PARAMS

  const key = getSoleKey()
  const allCacheData = getKidsTagLocalStorage()

  return allCacheData[key]?.[DEFAULT_CHILD_KEY] || DEFAULT_CHILD_PARAMS
}

// 设置默认选中的儿童信息档案
export const setDefaultKidParams = (kid = {}) => {
  if (typeof window == 'undefined') return

  const key = getSoleKey()
  const allCacheData = getKidsTagLocalStorage()
  const cache = allCacheData[key] ?? {}
  allCacheData[key] = cache
  
  cache[DEFAULT_CHILD_KEY] = {
    default_child_id: kid?.child_id || '', 
    default_child_sex: kid?.sex || '', 
    default_child_birthday: kid?.birthday || ''
  }

  setKidsTagLocalStorage(allCacheData)
}

// 清除默认选中的儿童信息档案-走默认kids筛选后无商品返回
export const clearDefaultKidParams = (queryParams = {}, isHit = false, defaultChild) => { 
  Object.keys(DEFAULT_CHILD_PARAMS).forEach(key => { 
    queryParams.hasOwnProperty(key) && delete queryParams[key]
  })

  if (!isHit) return 
  queryParams.kid_id = defaultChild.child_id
  queryParams.tag_ids = getKidValue({
    sex: defaultChild.default_child_sex, 
    birthday: defaultChild.default_child_birthday
  })
}

// 根据返回数据判断是否命中默认选中的儿童信息档案链路【返回defaultChild && defaultChild存在儿童信息列表中】
export const isHitDefaultChild = ({ kidsTag = {}, defaultChild = {}, }) => {
  if (!defaultChild?.child_id || !kidsTag?.child_list?.length) return false

  return !!kidsTag?.child_list.find(item => item.child_id == defaultChild.child_id)
}

// 命中默认kids筛选且有商品，是否展示toast，一天只出现一次
export const isShowDefaultKidFilterToast = () => {
  const key = getSoleKey()
  const allCacheData = getKidsTagLocalStorage()
  const cache = allCacheData[key] ?? {}
  allCacheData[key] = cache
  const toastTimestamp = cache.toasts || 0

  if (!toastTimestamp || dayjs().diff(dayjs(toastTimestamp), 'hour') >= 24) {
    cache.toasts = Date.now()
    setKidsTagLocalStorage(allCacheData)
    return true
  }

  return false
}
