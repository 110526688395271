import { onMounted, onUnmounted, ref } from 'vue'

export function useIntersectionObserver(target, callback, options = {}) {
  const isVisible = ref(false)
  const hasBeenVisible = ref(false) // 用于记录目标是否已经可见
  let observer: IntersectionObserver | null

  onMounted(() => {
    if (target.value) {
      observer = new IntersectionObserver(entries => {
        const { isIntersecting } = entries[0]
        isVisible.value = isIntersecting
        callback?.({ isVisible: isVisible.value, hasBeenVisible: hasBeenVisible.value })
        if (isIntersecting) hasBeenVisible.value = true // 设置为已可见
      }, options)
      observer.observe(target.value)
    }
  })

  onUnmounted(() => {
    observer && observer.disconnect()
    observer = null
  })

  return {
    isVisible,
    hasBeenVisible,
  }
}
