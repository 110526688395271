import { getTimestampFormProductTags } from '../../utils/common'

import type { DiscountNormalTag } from '../../../../types/mobile/index.ts'

/**
 * DiscountNormalTag 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): DiscountNormalTag.Props {
  const apb = data.value.aggregateProductBusiness || {}
  const { estimatedData = {} } = apb

  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1'
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  const isShowMask = (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem

  const estimatedPricePopUp = apb.estimatedPricePopUp && JSON.parse(JSON.stringify(apb.estimatedPricePopUp))
  if (estimatedPricePopUp?.subInfo?.items) estimatedPricePopUp.subInfo.items = estimatedPricePopUp?.subInfo.items.filter(item => item.benefitType !== 'estimatedAmount')

  let isShowEndTimestamp = !!(estimatedData.estimatedPriceEndTimeText || estimatedData.estimatedPriceEndTimeStamp)
  // 为时间戳则表明是走老逻辑, 需要判断展示优先级
  if (isShowEndTimestamp && (estimatedData.estimatedPriceEndTimeStamp && !estimatedData.estimatedPriceEndTimeText)) {
    isShowEndTimestamp = !getTimestampFormProductTags(apb.productTags)
  }

  let text = estimatedData.estimatedTip
  let isShow = !estimatedData.style && !!apb.discountSubscript && !isShowEndTimestamp && !!text
  let isClick = !isShowMask && !!estimatedPricePopUp
  // 是否显示到手价标签
  const isShowEstimatedPrice = !!estimatedData.style && !!text // EstimatedPrice 组件展示逻辑
  // 合规站点 2: 展示到原价后面 3: 展示到30天最低价后面。位置由 GoodsPrice 组件控制
  if (['2', '3'].includes(apb.discountSubscript?.style)) {
    isShow = true
    text = apb.discountSubscript?.value
    isClick = isClick && !isShowEstimatedPrice
  } else if (isShowEstimatedPrice) {
    isShow = apb.discountSubscript && !!apb.discountSubscript?.style && !!estimatedData.estimatedPrice // 折扣角标展示到价格右边
    text = apb.discountSubscript?.value
    isClick = false // 存在到手价标签时，明细入口在到手价标签上
  }

  const textColor = estimatedData.textColor

  return {
    isShow,
    isShowMask,
    isClick,
    text,
    estimatedPricePopUp,

    textColor,
    bgColor: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',
    maxWidth: '100%',
  }
}
