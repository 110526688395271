<template>
  <div class="goods-size BFF-COMPONENT__Size">
    <div v-if="showMultiLayer">
      <SizeGroup
        v-if="sizeGroupPosition === 'topSize'"
        v-bind="sizeGroupProps"
      />
      <div v-show="!hideSizeModule">
        <SizeSelectList
          :loadingAsyncComp="loadingAsyncComp"
          :defaultCountryCode="defaultCountryCode"
          :skcSaleAttr="skcSaleAttr"
          :sizeAttrCountryMap="sizeOptionalInfoMap ? sizeOptionalInfoMap.sizeAttrCountryMap : {}"
          :hasBubblePicMap="sizeOptionalInfoMap ? sizeOptionalInfoMap.hasBubblePicMap : {}"
          :hasBubbleTextMap="sizeOptionalInfoMap ? sizeOptionalInfoMap.hasBubbleTextMap : {}"
          :sizeInfoIndex="sizeInfoIndex"
          :country="country"
          :goodsId="goodsId"
          :isOnSale="isOnSale"
          :hasOneSize="sizeOptionalInfoMap.hasOneSize"
          :sizeUnit="sizeUnit"
          :isBaseSize="isBaseSize"
          :showLocalTextType="showLocalTextType"
          :lowestStockTipInfo="lowestStockTipInfo"
          :sizeGroupPosition="sizeGroupPosition"
          :saleAttrGroups="saleAttrGroups"
          :sizeRecommendInfo="sizeRecommendInfo"
          :sizeAttrInfo="sizeAttrInfo"
          :customerStyle="customerStyle"
          :language="language"
          :config="config"
          :sizeConstant="sizeConstant"
          :oneLocalSizeDesc="oneLocalSizeDesc"
          :handleClickLocalTextSelect="handleClickLocalTextSelect"
          :handleSizeClick="handleSizeClick"
          :handleCalcSize="handleCalcSize"
          :handleOpenSizeGuideFn="handleOpenSizeGuideFn"
          :handleSetSizeRefs="handleSetSizeRefs"
          :handleChooseCheck="handleChooseCheck"
          :handleSizeRecommend="handleSizeRecommend"
          :handlerOpenAdd="handlerOpenAdd"
        />
      </div>
      <ClientOnly v-if="loadingAsyncComp && showCheckMySizeAlone">
        <SizeRecommendEntrance
          :sizeRecommendInfo="sizeRecommendInfo"
          :goodsId="goodsId"
          :isFromPopup="config?.from === 'popup'"
          :hasOneSize="sizeOptionalInfoMap.hasOneSize"
          :sizeAttrInfo="sizeAttrInfo"
          :language="language"
          :handleCalcSize="handleCalcSize"
          :handleOpenSizeGuideFn="handleOpenSizeGuideFn"
          :handleSizeRecommend="handleSizeRecommend"
          position="alone"
        />
      </ClientOnly>
      <SizeGroup
        v-if="sizeGroupPosition === 'bottomSize'"
        v-bind="sizeGroupProps"
      />
      <ClientOnly v-if="loadingAsyncComp">
        <span
          v-if="showLineLowestTip"
          v-expose="{
            id: 'expose_lowstock_label.comp_sub-sales-attr',
            prefix: 'comp_sub-sales-attr',
            data: {
              ...(lowestStockTipInfo.logInfo || {}),
              location: 'attr',
            },
          }"
          aria-hidden="true"
          class="goods-size__item-tips"
        >
          {{ lowestStockTipInfo.tip }}
        </span>
        <SizeTipsContent
          :language="language"
          :sizeTipsInfo="sizeTipsInfo"
          :from="(config && config.from) || ''"
          :handleOpenSizeGuideFn="handleOpenSizeGuideFn"
        />
        <SizeQuickShip
          :showQuickShip="(config && config.showQuickShip) || false"
          :hiddenPartialQuickShip="(config && config.hiddenPartialQuickShip) || false"
          :from="(config && config.from) || ''"
          :deliveryFloorQuickshipTitle="deliveryFloorQuickshipTitle"
          :skcSaleAttr="skcSaleAttr"
          :skuList="props.realTimeSizeInfo?.realSkuList || skuList"
          :skuInfo="skuInfo"
          :language="language"
          :handleCalcSize="handleCalcSize"
          :handleUpdateQuickShip="handleUpdateQuickShip"
        />
        <SizeFeedBackEnter
          v-if="config && config.showSizeFeedBackEnter"
          :language="language"
          :goodsId="goodsId"
          :mallCode="mallCode"
          :country="country"
          :handleClickLocalTextSelect="handleClickLocalTextSelect"
          :handleOpenFindSimilar="handleOpenFindSimilar"
        />
      </ClientOnly>
      <Mall
        v-if="showMallList"
        :skuInfo="skuInfo"
        :mallList="mallInfoList"
        :language="language"
        :mallCode="mallCode"
        :isOnSale="isOnSale"
        :from="(config && config.from) || ''"
        :customerStyle="customerStyle"
        :handleChooseCheck="handleChooseCheck"
        @mallClick="mallClick"
      />
    </div>
    <div
      v-else
      v-tap="{
        id: 'click_goods_detail_select_to_buy.comp_sub-sales-attr',
      }"
    >
      <SizeClickToBuy
        :bold="titleBold"
        :title="allAttrStr"
        :active="active"
        :value="clickToBuyText"
        :handleClickToBuy="handleClickToBuy"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, defineExpose, defineProps, defineAsyncComponent, watch, nextTick, onMounted } from 'vue'
import { template } from '@shein/common-function'
import SizeSelectList from './components/SizeSelectList.vue'
import SizeGroup from './components/SizeGroup.vue'
// import SizeQuickShip from './SizeQuickShip.vue'
// import SizeFeedBackEnter from './SizeFeedBackEnter.vue'
// import SizeTipsContent from './SizeTipsContent.vue'
import Mall from './components/Mall.vue'
import ClientOnly from 'vue-client-only'
import { Toast } from '@shein/sui-mobile'
// import SizeClickToBuy from './SizeClickToBuy.vue'

// composition
import useSkuOptional from './compositions/useSkuOptional'
import { queryString } from './utils/index.js'


// 公共模块处理
import { getSource } from '../../common/dataSource'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import analysisInstance from './utils/useAnalysisSdk.js'
const { vTap, vExpose, triggerNotice, getExposeInstance } = analysisInstance || {}
const appConfigs = useAppConfigs()
const apis = getSource({ appConfigs })
// --end

// 异步组件
const SizeRecommendEntrance = defineAsyncComponent(() => import('./components/SizeRecommendEntrance.vue'))
const SizeQuickShip = defineAsyncComponent(() => import('./components/SizeQuickShip.vue'))
const SizeFeedBackEnter = defineAsyncComponent(() => import('./components/SizeFeedBackEnter.vue'))
const SizeTipsContent = defineAsyncComponent(() => import('./components/SizeTipsContent.vue'))
const SizeClickToBuy = defineAsyncComponent(() => import('./components/SizeClickToBuy.vue'))

const daEventExpose = getExposeInstance?.() || {}

const props = defineProps({
  config: {
    type: Object,
    default: () => ({}),
  },
  customerStyle: {
    type: Object,
    default: () => ({}),
  },
  sizeConstant: {
    type: Object,
    default: () => ({}),
  },
  skcSaleAttr: {
    type: Array,
    default: () => [],
  },
  skuList: {
    type: Array,
    default: () => [],
  },
  realTimeSizeInfo: {
    type: Object,
    default: () => ({}),
  },
  skuInfo: {
    type: Object,
    default: () => ({}),
  },
  sizeAttrInfo: {
    type: Object,
    default: () => ({}),
  },
  dataMapCompose: {
    type: Object,
    default: () => ({}),
  },
  goodsId: {
    type: String,
    default: '',
  },
  deliveryFloorQuickshipTitle: {
    type: String,
    default: '',
  },
  mallCode: {
    type: String,
    default: '',
  },
  country: {
    type: String,
    default: '',
  },
  sizeUnit: {
    type: String,
    default: '',
  },
  defaultCountryCode: {
    type: String,
    default: '',
  },
  isBaseSize: {
    type: Boolean,
    default: false,
  },
  isOnSale: {
    type: Boolean,
    default: true,
  },
  hideSizeModule: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  saleAttrGroups: {
    type: Object,
    default: () => {},
  },
  globalPerfectFitShow: {
    type: Boolean,
    default: false,
  },
  sizeTipsInfo: {
    type: Object,
    default: () => ({}),
  },
  mallInfoList: {
    type: Array,
    default: () => ([]),
  },
  sizeRecommendInfo: {
    type: Object,
    default: () => ({}),
  },
  showMallList: {
    type: Boolean,
    default: false,
  },
  externalSizeInfoIndex: {
    type: Object,
    default: () => ({}),
  },
  externalSkuCode: {
    type: String,
    default: '',
  },
  localSizeLanguage: {
    type: Object,
    default: () => ({}),
  },
  willSoldOutTipsInfo: {
    type: Object,
    default: () => ({}),
  },
  stockTipShowLocation: {
    type: String,
    default: '',
  },
  handleUpdateQuickShip: {
    type: Function,
    default: () => {},
  },
  handleUpdateGlobalPerfectFitShow: {
    type: Function,
    default: () => {},
  },
  mallClick: {
    type: Function,
    default: () => {},
  },
  handleCompose: {
    type: Function,
    default: () => {},
  },
  handleSizeRecommend: {
    type: Function,
    default: () => {},
  },
  handleClickSizeGroupItem: {
    type: Function,
    default: () => {},
  },
  handleOpenQuickCart: {
    type: Function,
    default: () => {},
  },
  handleClickToBuy: {
    type: Function,
    default: () => {},
  },
  handleLocalChange: {
    type: Function,
    default: () => {},
  },
  handleOpenSizeGuide: {
    type: Function,
    default: () => {},
  },
  handleOpenFindSimilar: {
    type: Function,
    default: () => {},
  },
})

const exposeCodeMap = {
  sizeGroupList: props.config?.from === 'detailPage' ? 'page_size_group_code' : 'popup_size_group_code',
}
const loadingAsyncComp = ref(false)
const sizeRefs = ref({})
const handleSetSizeRefs = (name, key) => (e) => {
  if (name === undefined || key === undefined || e === undefined) return
  if (!sizeRefs.value[name]) sizeRefs.value[name] = {}
  sizeRefs.value[name][key] = e
}

const commonStatus = reactive({
  localCountryDrawerLock: false,
  sizeScrollTimer: '',
})

const showMultiLayer = computed(() => props.config?.showMultiLayer || false)
const titleBold = computed(() => props.customerStyle?.bold || false)
const allAttrStr = computed(() => props.skcSaleAttr?.map?.(item => item?.attr_name)?.join(' / ') || '')
const clickToBuyText = computed(() => {
  const { sku_code, sku_sale_attr } = props.skuInfo || {}
  if (sku_code) {
    let str = []
    props.skcSaleAttr?.forEach?.(item => {
      const skuLev = sku_sale_attr?.find?.((_) => _.attr_id == item.attr_id) || {}
      let val = handleCalcSize(skuLev)?.renderText || ''
      val = val.length > 16 ? (val.slice(0, 15) + '...') : val
      val && str.push(val)
    })
    return str.join(' / ')
  }
  return props.language?.SHEIN_KEY_PWA_19184 || 'Click to buy'
})
const active = computed(() => !!props.skuInfo?.sku_code)
// 尺码销售属性数据枚举, 一次计算完毕
const sizeOptionalInfoMap = computed(() => {
  const skcSaleAttr = props.skcSaleAttr
  const sizeAttrCountryMap = {}
  const sizeAttrRealationMap = {}
  let hasOneSize = false
  let hasBubblePicMap = {}
  let hasBubbleTextMap = {}
  skcSaleAttr?.forEach?.(options => {
    const {
      attr_value_list,
      isSize,
      attr_id,
    } = options || {}
    attr_value_list?.forEach(size => {
      const {
        attr_value_local_size,
        attr_value_id,
        attr_value_name_en,
        size_gather_tag,
        goods_id,
        attrImg,
        attrDescPopUp,
      } = size || {}
      if (attrImg) {
        hasBubblePicMap[attr_id] = true
      }
      if (attrDescPopUp) {
        hasBubbleTextMap[attr_id] = true
      }
      if (isSize == '1') {
        hasOneSize = attr_value_name_en === 'one-size'
        attr_value_local_size?.forEach(item => {
          const {
            country_code,
            attr_local_size_value,
            attr_local_size_value_end,
            attr_local_size_value_start,
          } = item || {}
          if (country_code && attr_local_size_value) {
            // 关联商品的本地尺码映射
            if (goods_id && size_gather_tag) {
              if (!sizeAttrRealationMap[country_code]) {
                sizeAttrRealationMap[country_code] = {}
              }
              const key = `${goods_id}_${size_gather_tag}`
              sizeAttrRealationMap[country_code][key + '_start'] = attr_local_size_value_start
              sizeAttrRealationMap[country_code][key + '_end'] = attr_local_size_value_end
              return
            }
            if (!sizeAttrCountryMap[country_code]) {
              sizeAttrCountryMap[country_code] = {}
            }
            sizeAttrCountryMap[country_code][attr_value_id] = attr_local_size_value
          }
        })
      }
    })
  })
  return {
    sizeAttrCountryMap,
    sizeAttrRealationMap,
    hasOneSize,
    hasBubblePicMap,
    hasBubbleTextMap,
  }
})
// 实时sku映射
const realSkuListMap = computed(() => {
  return props.realTimeSizeInfo?.realSkuList?.reduce?.((acc, item) => {
    acc[item.sku_code] = item
    return acc
  }, {})
})
// sku计算，一次计算完毕 => 目前只有低库存展示在尺码中需要在这里计算
const skuOptionalInfoMap = computed(() => {
  let hasLowStock = false
  const willSoldOutTipMap = {}
  const willSoldOutTipKeyList = []
  Object.keys(props.dataMapCompose?.skuMap || {}).forEach(key => {
    const skuCode = props.dataMapCompose?.skuMap?.[key]
    const { selected_will_sold_out_tips, lowStockType, cartSoldOutTips } = realSkuListMap.value?.[skuCode] || {}
    if (selected_will_sold_out_tips) {
      hasLowStock = true
      willSoldOutTipMap[key] = (lowStockType == 1 ? props.language?.SHEIN_KEY_PWA_33090 : cartSoldOutTips) || ''
      willSoldOutTipKeyList.push(key)
    }
  })
  return {
    hasLowStock,
    willSoldOutTipMap,
    willSoldOutTipKeyList,
  }
})
// 1尺码描述 | 2尺码次级销售属性图片是否展示
const showSizeBubble = computed(() => {
  if (props.hideSizeModule) return {}
  const { hasBubblePicMap, hasBubbleTextMap } = sizeOptionalInfoMap.value || {}
  const { isShowAttrPicInfo } = props.config || {}
  const sizeItem = sizeInfoIndex.value?.[87]
  const { attrImg, attrDescPopUp, attr_value_id } = sizeItem || {}
  let type = 0
  if (isShowAttrPicInfo && hasBubblePicMap?.[87] && attrImg) {
    type = 2
  }else if (hasBubbleTextMap?.[87] && attrDescPopUp) {
    type = 1
  }
  return {
    type,
    attr_value_id,
  }
})
// 是否有尺码模块
const hasSizeModule = computed(() => {
  return !props.hideSizeModule && props.sizeAttrInfo?.attr_id
})

const showCheckMySizeAlone = computed(() => props.sizeRecommendInfo?.sizeGuidAlone && props.config?.showSizeRecommend)

// 展示sizeGoup位置
const sizeGroupPosition = computed(() => {
  if (!(props.saleAttrGroups?.groups?.length > 1)) return ''
  const showSizeGroupPosition = props.config?.showSizeGroupPosition
  if (showSizeGroupPosition === 'size') {
    // 命中size的时候传配置隐藏
    if (props.config?.hideSizeGroupeSize) {
      return ''
    }
    return hasSizeModule.value ? 'afterSize' : 'bottomSize'
  }
  if (['bottom', 'fold'].includes(showSizeGroupPosition)) return 'bottomSize'
  if (showSizeGroupPosition === 'mid') return 'topSize'
  return ''
})
// SizeGroup props
const sizeGroupProps = computed(() => {
  return {
    exposeCodeMap,
    language: props.language,
    saleAttrGroups: props.saleAttrGroups,
    goodsId: props.goodsId,
    sizeGroupAbt: props.config?.showSizeGroupPosition,
    isFromDetailPage: props.config?.from == 'detailPage',
    globalPerfectFitShow: props.globalPerfectFitShow,
    handleUpdateGlobalPerfectFitShow: props.handleUpdateGlobalPerfectFitShow,
    handleClickSizeGroupItem: props.handleClickSizeGroupItem,
  }
})
// 低库存提示信息
const lowestStockTipInfo = computed(() => {
  if (props.stockTipShowLocation !== 'line' || props.config?.attrLowStockTipsPlan === 'B') return {}
  const { tip, lowStockType, scene } = props.willSoldOutTipsInfo || {}
  const logInfo = {
    goods_level: scene,
    // lowStockType为2是绝对低库存，别问为什么
    label_type: lowStockType == 2 ? 1 : 2,
  }
  return {
    tip,
    logInfo,
    location: showSizeBubble.value?.type ? 'bubble' : 'line',
  }
})
// 独立展示低库存一行
const showLineLowestTip = computed(() => {
  return lowestStockTipInfo.value?.location === 'line' && lowestStockTipInfo.value?.tip && !props.config?.hideSoldOutTips
})
// 需要默认选中
// const needAutoCheck = computed(() => {
//   return props.skuList?.length == 1 || props.skcSaleAttr?.find?.((_) => _.attr_value_list?.length == 1)
// })

// 本地国家列表
const attrValueSizeCountry = computed(() => props?.sizeAttrInfo?.attr_value_size_country || [])
// 本地尺码枚举
const localSizeMap = computed(() => sizeOptionalInfoMap.value?.sizeAttrCountryMap?.[props.country] || {})
// 关联大小码本地尺码枚举
const relationLocalSizeMap = computed(() => sizeOptionalInfoMap.value?.sizeAttrRealationMap?.[props.country] || {})
// 是否展示本地尺码或者国家选择器 0: 不展示 1: 展示本地尺码, 2: 展示国家选择器 3: 只展示当前站点的国家
const showLocalTextType = computed(() => {
  const config = props.config
  const { isNewSizeLocal, showOnlyOneLocalSize } = config
  const { sizeAttrCountryMap, hasOneSize } = sizeOptionalInfoMap.value || {}
  const defaultCountryMap = sizeAttrCountryMap?.[props.defaultCountryCode] || {}
  if (hasOneSize) return 0
  if (isNewSizeLocal && attrValueSizeCountry.value?.length) {
    let type = Object.keys(sizeAttrCountryMap || {}).length ? 2 : 0
    if (showOnlyOneLocalSize && Object.keys(defaultCountryMap).length > 0) {
      return 3
    }
    return type
  }
  return Object.keys(localSizeMap.value || {}) ? 1 : 0
})
// 本地尺码描述展示
const oneLocalSizeDesc = (attrValueId, attrValueName) => {
  const config = props.config
  if (config.onlyOneLocalSizeNewOn || showLocalTextType.value !== 3 || !attrValueId || !attrValueName) return ''
  if (props.country === props.defaultCountryCode) {
    const localSize = localSizeMap.value?.[attrValueId]
    return template(`<strong>${attrValueName}</strong>`, props.country, `<strong>${localSize}</strong>`, props.language?.SHEIN_KEY_PWA_33077 || 'Size labeled on item is \'{0}\' same as {1} \'{2}\' you selected.')
  }
  return ''
}
// 销售属性旁低库存展示
const handleCalcLowTip = ({ attrId = '', attrName = '' } = {}, { attr_value_id = '' } = {}) => {
  const attrLowStockTipsPlan = props.config?.attrLowStockTipsPlan
  if (!attrId || !attrName || !attrLowStockTipsPlan || attrLowStockTipsPlan === 'off') return ''
  const str = props.dataMapCompose?.dataMapOrder?.reduce?.((acc, attr_id) => {
    if (attr_id == attrId) {
      acc += attrName + '__' + attr_value_id + ','
    }else {
      const { attr_name: otherAttrName, attr_value_id: otherAttrValueId } = sizeInfoIndex.value?.[attr_id] || {}
      if (otherAttrValueId && otherAttrName) {
        acc += otherAttrName + '__' + otherAttrValueId + ','
      }else {
        acc += '.*,'
      }
    }
    return acc
  }, '')
  const regex = new RegExp(`${str}`)
  const tipKey = skuOptionalInfoMap.value.willSoldOutTipKeyList?.find?.((_) => regex.test(_))
  let tip = ''
  if (tipKey) {
    tip = attrLowStockTipsPlan === 'B' ? skuOptionalInfoMap.value.willSoldOutTipMap?.[tipKey] : 'icon'
  }
  return tip
}

// 是否展示本地尺码
const isShowLocalSize = computed(() => {
  return showLocalTextType?.value === 3 ? (props.country === props.defaultCountryCode) : true
})

// 销售属性文案展示
const handleCalcSize = (label, {
    isFromRelation = false,
    isCalcLowTip = false,
    attrParentInfo = {},
  } = {}) => {
  const {
    attr_std_value = '',
    attr_value_name = '',
    attr_value_id = '',
    heightSizeCm = '',
    heightSizeInch = '',
  } = label || {}
  const { config, sizeUnit } = props
  const { shouldWeakenDefaultSize = false, notShowDefaultSizeText = false } = config || {}
  const defaultSizeText = attr_std_value ? `${attr_std_value} / ${attr_value_name}` : attr_value_name
  const localSizeText = (isFromRelation ? relationLocalSizeMap.value?.[attr_value_id] : localSizeMap.value?.[attr_value_id]) || ''
  const heightSizeText = sizeUnit == 'inch' ? heightSizeInch : heightSizeCm
  let mainSize = defaultSizeText
  let subSize = ''
  let isWeakSubDefaultSize = false
  if (isShowLocalSize.value && localSizeText) {
    // 本地尺码存在时，主尺码为本地尺码，副尺码受配置影响
    mainSize = localSizeText
    subSize = notShowDefaultSizeText ? '' : `(${defaultSizeText})`
    isWeakSubDefaultSize = !notShowDefaultSizeText && !!shouldWeakenDefaultSize
  }
  if (!subSize && !attr_std_value && heightSizeText) {
    // 副尺码不存在 + attr_std_value童装不存在 + 身高尺码存在（展示身高尺码为副尺码）
    subSize = `(${heightSizeText})`
  }
  const renderTextDom = () => {
    if (!mainSize) return null
    if (subSize) return `${mainSize} ${isWeakSubDefaultSize ? `<span style="color: #959595; font-size: 12px;">${subSize}</span>` : subSize}`
    return mainSize
  }
  const lowStockTip = isCalcLowTip ? handleCalcLowTip(attrParentInfo, label) : ''
  return {
    mainSize,
    subSize,
    isWeakSubDefaultSize,
    renderText: mainSize + (subSize ? ` ${subSize}` : ''),
    renderTextDom: renderTextDom(),
    lowStockTip,
  }
}
// 本地尺码切换回调
const sizeLocalChange = (val) => {
  if (props.config?.isNewSizeLocal) {
    props.handleLocalChange?.(val)
  }
  if (showLocalTextType.value === 3) return
  triggerNotice({
    id: 'click_choose_localsize.comp_sub-sales-attr',
    extraData: {
      choose_type: val,
      style_type: 'list',
     },
  })
}
// 本地尺码点击打开国家选择器
const handleClickLocalTextSelect = async ({
  changeSize = () => {},
  chooseCountry = '',
  notDrawer = false,
} = {}) => {
  if (notDrawer) {
    window.localStorage.setItem('last_select_country', chooseCountry || 'default')
    // TODO: 还不确定session要不要迁融合 + 下沉
    apis.updateBusineseCache({
      cacheData: chooseCountry || 'default',
      scanType: 'detail_local_country',
    })
    sizeLocalChange(chooseCountry || 'default')
    triggerNotice({
      id: 'click_out_localsize.comp_sub-sales-attr',
      extraData: {
        choose_type: props?.country ? 1 : 0,
        style_type: 'tab',
      },
    })
    return
  }
  if (commonStatus.localCountryDrawerLock) return
  commonStatus.localCountryDrawerLock = true
  import('./drawer/select-local-size/index.js').then(res => {
    const { goodsId, country, localSizeLanguage, config } = props
    const { isNewSizeLocal } = config || {}
    res?.default?.open({
      id: goodsId,
      value: country,
      localSize: attrValueSizeCountry.value,
      options: {
        language: localSizeLanguage,
        config: {
          appendToBody: true,
          isNewSizeLocal,
        },
        callbacks: {
          change: country => {
            sizeLocalChange(country)
            changeSize?.(country)
          },
        },
      },
    })
  }).finally(() => {
    commonStatus.localCountryDrawerLock = false
  })
  triggerNotice({
    id: 'click_out_localsize.comp_sub-sales-attr',
    extraData: {
      choose_type: props?.country ? 1 : 0,
      style_type: 'list',
    },
  })
}
// 尺码气泡上滑
const sizeScrollView = () => {
  const slideDescRef = sizeRefs.value?.bubble?.slideDesc
  if (!slideDescRef || typeof window === 'undefined') return
  nextTick(() => {
    slideDescRef?.calHeight?.()
    if (commonStatus.sizeScrollTimer) {
      clearTimeout(commonStatus.sizeScrollTimer)
    }
    const { from } = props.config || {}
    commonStatus.sizeScrollTimer = setTimeout(() => {
      const isDetailPage = from == 'detailPage'
      const win = isDetailPage
        ? document.documentElement
        : slideDescRef.$el.closest('.bsc-quick-add-cart__content')
      // const _addBagEl = isDetailPage
      //   ? document.querySelector('.product-intro__add-cart')
      //   : document.querySelector('.quick_add_footerbar')
      let addBagHeight = isDetailPage ? document.querySelector('.product-intro__add-cart')?.getBoundingClientRect?.().height : 0
      let bodyHeight = win?.getBoundingClientRect?.().height
      let sizeRec = document
        .querySelector('.goods-size__slide-common')
        ?.getBoundingClientRect?.()
      if (!sizeRec) return
      let soldoutHeight = document.querySelector('.soldout-warning.goods-detial')?.getBoundingClientRect?.()?.height || 0
      let distance = parseInt(
        sizeRec.top - (bodyHeight - addBagHeight - sizeRec.height - soldoutHeight - 50),
      )
      if (distance > 0) {
        win.scrollTo({
          top: win.scrollTop + distance,
          behavior: 'smooth',
        })
      }
    }, 200)
  })
}
// 打开sizeGuide
const handleOpenSizeGuideFn = (scene = '', fromScene = '', type) => {
  if(type){
    triggerNotice({
      id: 'click_size_guide.comp_sub-sales-attr',
      extraData: {
        from: type,
      },
    })
  }
  props.handleOpenSizeGuide?.(scene, fromScene)
}
// 打开加车
const handlerOpenAdd = (item = {}, config = {}) => {
  const { activityFrom, hideSizeGroupeSize = false } = config
  props.handleOpenQuickCart(item, {
    openPage: props.config?.from !== 'detailPage',
    hideSizeGroupeSize,
    index: 1,
    analysisConfig: {
      code: 'goodsDetail',
      sa: {
        activity_from: activityFrom || 'more_size',
      },
      from: activityFrom || 'more_size',
    },
  })
}
// 自动切换mall
const autoCheckMall = ({ isSku = false, isSkc = false }) => {
  nextTick(() => {
    const { config, skuInfo, language, mallClick, mallCode, mallInfoList } = props
    const { isAutoSwitchMall, openAutoCheckMall } = config || {}
    if (isSku && isAutoSwitchMall) {
      const { mall_stock, stock } = skuInfo || {}
      const curSkuMallStock = mall_stock?.find?.((_) => _?.mall_code == mallCode)?.stock || 0
      if (!curSkuMallStock || curSkuMallStock <= 0) {
        const hasStockMall = mall_stock?.find?.((_) => _?.stock > 0) || {}
        const { stock, mall_code } = hasStockMall || {}
        if (stock > 0 && mall_code) {
          mallClick({ mallCode: mall_code })
          const isLocalMall = mall_code == '2'
          const isOverSeaMall = mall_code == '1'
          let popoverMallTips = ''
          // 设置切换mall提示
          if(isOverSeaMall) {
            popoverMallTips = language?.SHEIN_KEY_PWA_32691 || 'The international warehouse is out of stock, we have selected the national warehouse for you.'
          }
          if(isLocalMall) {
            popoverMallTips = language?.SHEIN_KEY_PWA_32692 || 'The national warehouse is out of stock, we have selected the international warehouse for you.'
          }

          Toast?.closeAll?.()
          Toast?.({
            htmlString: `<div style="max-width:72vw;width:max-content;padding:3.2vw;text-align:left;">${popoverMallTips}</div>`,
            duration: 3000,
          })
        }
      }
    }
    if (isSkc && openAutoCheckMall && !skuInfo?.sku_code) {
      const curSkcStock = handleChooseCheck({
        attr_id: -1,
        mall_code: mallCode,
      }) || 0
      if (!curSkcStock || curSkcStock <= 0) {
        const hasStockMall = mallInfoList?.find?.((_) => handleChooseCheck({
          attr_id: -1,
          mall_code: _.mall_code,
        }) > 0) || {}
        hasStockMall?.mall_code && mallClick({ mallCode: hasStockMall?.mall_code })
      }
    }
  })
}
// tips上报埋点
const pointReport = () => {
  nextTick(() => {
    const { tipLogFrom, heelHeightRangeTips } = props.sizeTipsInfo || {}
    if (tipLogFrom) {
      triggerNotice({
        id: 'expose_size_tips_abt.comp_sub-sales-attr',
        extraData: {
          tips_from: tipLogFrom,
        },
      })
    }
    if (heelHeightRangeTips) {
      triggerNotice({
        id: 'expose_size_tips_abt.comp_sub-sales-attr',
        extraData: {
          tips_from: 'heel',
        },
      })
    }
  })
}

const {
  sizeInfoIndex,
  handleChooseCheck,
  handleSizeClick,
  defaultClick,
  initStatus,
  sizeValue,
  isInit,
} = useSkuOptional(props, { handleCalcSize })

// 检测哪些没选中
const checkSelectSize = () => {
  const unSelectedAttrs = []
  // const hasSkuCode = props.skuList?.some?.(item => item?.sku_code == (props.skuInfo?.sku_code || ''))
  if (props.skuInfo?.sku_code) {
    return { status: true }
  }
  props.skcSaleAttr?.forEach?.(item => {
    if (!sizeInfoIndex.value?.[item?.attr_id]?.attr_value_id) {
      unSelectedAttrs.push(item.attr_name)
    }
  })
  return { status: false, unSelectedAttrs }
}

const setRecommendSize = (newVal, oldVal) => {
  nextTick(() => {
    if (newVal?.currentMySize != oldVal?.currentMySize || newVal?.currentMyBraSize != oldVal?.currentMyBraSize) {
      const { currentMySize, currentMyBraSize } = props.sizeRecommendInfo || {}
      if (currentMySize || currentMyBraSize) {
        sizeValue(currentMySize || currentMyBraSize || '')
      }
    }
  })
}

const resetSizeBox = ({ isNewSpu, isMounted, isNewSkc } = {}) => {
  initStatus({ isNewSpu })
  // TODO: 接主站的时候考虑下要不要首屏
  defaultClick()
  // if (isMounted) {
  //   needAutoCheck.value && defaultClick()
  // }else {
  //   defaultClick()
  // }
  // setRecommendSize('mounted')
  isNewSkc && autoCheckMall({ isSkc: true })
  Object.keys(exposeCodeMap).forEach(name => {
    daEventExpose.reset(exposeCodeMap[name])
  })
}

const allDataReadyCallBack = () => {
  loadingAsyncComp.value = true
  // defaultClick()
  pointReport()
  if (lowestStockTipInfo.value?.tip) {
    triggerNotice({
      id: 'expose_hurry_almost_sold_out.comp_sub-sales-attr',
    })
  }
  if (!props.config?.hideSizeModule) {
    const attrValue = props.skcSaleAttr?.map((arr) => {
      return arr?.attr_value_list?.map?.((_) => _.attr_value_name)?.join(',')
    })?.join(',')
    const lowstock_type = props.realTimeSizeInfo?.realSkuList?.some?.((_) => !!_.selected_will_sold_out_tips) ? 1 : 0
    const extraData = {
      if_have_localsize_entry: showLocalTextType.value === 3 || showLocalTextType.value === 2 ? 1 : 0,
      localsize_style_type: props.config.showOnlyOneLocalSize ? 'tab' : 'list',
      localsize_choose_type: props.county ? 0 : 1,
      if_have_feedback_entry:  0,
      count: props.skcSaleAttr?.length || 0,
      attrvalue: attrValue,
      location: props.config?.from || 'page',
      lowstock_type,
    }
    if (props.config?.isAttrFold) {
      extraData.is_attr_fold = props.config?.isAttrFold
    }
    triggerNotice({
      id: 'expose_goods_detail_select_otherattr.comp_sub-sales-attr',
      extraData,
    })
  }
}

const handleExternalSizeInfoIndex = (val) => {
  const noRepeat = Object.keys(val || {})?.some?.(key => {
    return val[key]?.attr_value_id != sizeInfoIndex.value[key]?.attr_value_id
  })
  if (noRepeat) {
    defaultClick({ needSetSizeIndex: val })
  }
}

const handleExternalSkuCode = (val) => {
  if (val && val !== props.skuInfo?.sku_code) {
    defaultClick({ needSetSkuInfo: val })
  }
}

defineExpose({
  resetSizeBox,
  allDataReadyCallBack,
  checkSelectSize,
})

onMounted(() => {
  resetSizeBox({ isMounted: true })
  // 默认选中skuCode
  const { skucode = '' } = queryString.parse(location.search)
  if (skucode && props.config?.from === 'detailPage') {
    defaultClick({ needSetSkuInfo: skucode })
  }
  nextTick(() => {
    if (props.config?.needInitSizeInfoIndex) {
      if (props.externalSkuCode) {
        handleExternalSkuCode(props.externalSkuCode)
      }else if(props.externalSizeInfoIndex) {
        handleExternalSizeInfoIndex(props.externalSizeInfoIndex)
      }
    }
  })
})

watch(showSizeBubble, (val) => {
  if (val?.type === 1) {
    sizeScrollView()
  }
})

watch(() => props.externalSizeInfoIndex, handleExternalSizeInfoIndex)

watch(() => props.externalSkuCode, handleExternalSkuCode)

watch(() => props.sizeRecommendInfo, setRecommendSize)

watch(() => props.realTimeSizeInfo, (val) => {
  // 刷新实时数据
  if (val?.realSkuList?.length && isInit?.value) {
    nextTick(() => {
      defaultClick()
    })
  }
})

watch(() => props.skuInfo?.sku_code, (val) => {
  if (val) {
    autoCheckMall({ isSku: true })
  }
})
</script>

<script>
export default {
  name: 'NewSize',
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.goods-size {
  font-size: 12px;
  // content el 中最后一个元素不需要 margin-bottom
  &__content {
    > div:last-child {
      margin-bottom: 0px;
    }
  }
  &__title {
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    display: -ms-flexbox;
    -webkit-align-items: center;
    -webkit-box-align: center;
       -moz-box-align: center;
       -ms-flex-align: center;
          align-items: center;
    justify-content: space-between;
    line-height: 4.3vw;
  }
  &__standard{
    display: flex;
    margin-left: 2.667vw;
    align-items: center;
    font-size: 3.2vw;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.96);
    // background: var(---white_alpha96, rgba(255, 255, 255, 0.96));
    font-weight: 400;
    span {
      border-radius: 5.333vw 0px 0px 5.333vw;
      padding: 0.5333vw 1.6vw;
      border: 1px solid #E5E5E5;
      &:nth-child(2){
        border-radius: 0px 5.333vw 5.333vw 0px;
        text-align: center;
        min-width: 10.667vw;
      }
    }
    .goods-size__black{
      border: 1px rgba(229, 229, 229, 0.00);
      background: #222;
      color: white;
    }
  }

  .self-size-recommend #fitanalytics__button {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */
  }

  &__title-txt {
    text-transform: capitalize;
    font-style: normal;
    font-size: 12px;
    display:flex;
    align-items: center;
  }

  &__title-bold {
    font-weight: 700;
    font-size: 14px;

    /* rw:begin */
    font-family: Adieu;
  }

  &__title-local {
    display: inline-flex;
    align-items: center;
    padding: 0.5333vw 1.6vw;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 5.333vw;
    background-color: #F6F6F6;
    margin-left: 1.06vw;
  }

  &__wrapper {
    padding-bottom: 0.1vw;
  }

  &__sizes {
    min-height: 9.6vw;
    margin-top: 2.1333vw;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    display: -ms-flexbox;
    -webkit-align-items: center;
    -webkit-box-align: center;
       -moz-box-align: center;
       -ms-flex-align: center;
          align-items: center;
    flex-flow: row wrap;

    &.on-line__style {
      width: 100%;
      display: inline-flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
    }

    &.on-line__style::-webkit-scrollbar {
      height: 0;
    }
  }

  &__relate-good {

    span,
    .icon-price_px_- {
      /* stylelint-disable-line */
      display: inline-block;
    }

    span+span {
      /* stylelint-disable-line */
      margin-left: 3px
        /*rtl:ignore*/
      ;
    }

    span:first-child {
      margin-left: 0
        /*rtl:ignore*/
      ;
    }

    .icon-price_px_- {
      /* stylelint-disable-line */
      vertical-align: sub;
      line-height: 1px;
    }

    .to-right {
      transform: rotate(-90deg);
      transform-origin: center center;
    }

    .icon-box {
      display: inline;
      line-height: 0;
      font-size: 14px;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }

  &__sizes-item {
    display: inline-block;
    max-width: 100%;
    padding: 1.333vw 3.33333vw 1.333vw 3.33333vw;
    margin-right: 3.2vw;
    margin-bottom: 2.13333vw;
    border: 1px solid #e5e5e5;
    border-radius: 12.8vw;
    line-height: 4.533vw;
    font-size: 14px;

    &.size-active {
      border: 1px solid #222;
      color: #222;
    }

    &.sold-out {
      border: 1px dashed #e5e5e5;
      color: #bbb;
    }

    &.size-sold-out {
      border: 1px solid #222;
    }

    &.size-disabled {
      background: #fafafa;
      border: 1px dashed #e5e5e5;
      color: #bbb;
    }

    &.twoline-text-box {
      line-height: 0;

      & > .goods-size__sizes-item-text--two {
        display: -webkit-inline-box;
        line-height: 4.533vw;
      }
    }

    .goods-size__sizes-item-text {
      max-width: 86.4vw;
      & .low-stock-tips {
        color: #C44A01;
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 4px;
        display: inline;
        & .low-stock-tips-hourglass {
          padding-left: 4px;
          padding-bottom: 2px;
        }
      }
    }

    .goods-size__sizes-item-text--one {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }

    .goods-size__sizes-item-text--two {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__sizes-more {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color: #767676;
  }

  &__sizes-more-holder {
    height: 3.2vw;
  }

  &__slide-common {
    margin-bottom: 2.3vw;
  }

  &__item-info {
    text-align: left;
    background: #fafafa;
    padding: 1.1vw 2.1vw;
    font-size: 12px;
    padding: 10px;
    color: #666;

    >span {
      font-weight: bold;
    }

    .tips {
      color: #C44A01;
      font-weight: 400;
    }

    .mesurement-title {
      color: #959595;
    }

    .morethan3 {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .lessthan3 {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .sizeinfo-name {
      color: #222;
      font-weight: bold;
    }

    .sizeinfo-one {
      word-break: break-all;
      margin-right: 2vw;

      &::after {
        content: ',';
      }
    }

    .sizeinfo-one:last-of-type {
      &::after {
        content: '';
      }
    }

    .sizeinfo-t {
      color: #222;
    }
  }

  &__item-tips {
    display: inline-block;
    color: #C44A01;
    margin-bottom: 1.6vw;
  }
  &__tips-bold {
    font-weight: bold;
    color: #000000;
  }
  &__undersize-deviation-tips {
    display: -webkit-box;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 1.6vw;
    word-break: break-all;
  }

  &__undersize-deviation-tipsstrong {
    .tipsstrong-span {
      color: #C44A01;
    }

    .like-icon {
      margin-right: 0.533vw;
      position: relative;
      top: -1px;
    }

    .more-icon {
      vertical-align: middle;
    }

    &.new-line {
      display: block;
    }
  }

  .size-feedback {
    &_title {
      color: #666;
      line-height: 1;

      span {
        color: #2D68A8;
        cursor: pointer;
      }
    }
  }

  .capsule-box {
    unicode-bidi: normal
      /* rtl:bidi-override */
    ;
  }

  .capsule {
    font-size: 12px;
    margin-left: 1.6vw;
    vertical-align: sub;
  }
}

.goodsd-sizes {
  &__more {
    margin-top: 2.13333vw;
    padding-top: 2.13333vw;
    text-align: right;
    font-size: 3.2vw;
    color: #767676;
    border-top: 1px solid #e5e5e5;
  }
}

.fitanalytics__button {
  order: 3 !important;
  /* stylelint-disable-line declaration-no-important */
}

.fitanalytics__button-text {
  /* stylelint-disable-line */
  font-size: 12px !important;
  /* rw:begin */
  color: #ff9999 !important;
  /* stylelint-disable-line declaration-no-important */
}</style>
