<template>
  <div
    :class="['estimate-price-intro', isSheinClubEstimatedPrice && 'clube-price-intro', isExclusiveEstimatedPrice && 'exclusive-price-intro']"
  >
    <CamelCasePrice
      :class="classList"
      :show-camel-price="true"
      :currency-symbol-prefix="currencySymbolPrefix"
      :number-before-dot="numberBeforeDot"
      :number-after-dot="numberAfterDot"
      :currency-symbol-suffix="currencySymbolSuffix"
      :origin-string="orginString"
      :camel-price-style="camelPriceStyle"
    />
    <span class="price-intro_line"></span>
    <p class="price-intro_text">
      {{ estimatedTextNewStyleLabel }}
    </p>
  </div>
</template>

<script>
/**
 * @file 新到手价样式组件，参见wiki: pageId=1460905528
 * 注：针对AB价，样式走之前的到手价的只是文案发生了变化而已
 */

import { defineComponent, inject } from 'vue'
import CamelCasePrice from './CamelCasePrice.vue'
import { handlePriceHtml } from 'public/src/pages/components/product/item_v3/js/utils.js'

export default defineComponent({
  name: 'EstimatedNewStyle',
  components: {
    CamelCasePrice,
  },
  inject: ['item', 'language'],
  setup () {
    const item = inject('item', () => ({}))
    const language = inject('language', () => ({}))

    const estimatedTextNewStyleLabel = language.SHEIN_KEY_PWA_32255 || 'with Coupon'
    
    const salePrice = item.salePrice ?? {}

    // 到手价
    const { estimatedPrice, estimatedPriceType } = item.estimatedPriceInfo ?? {}
    const isSheinClubEstimatedPrice = estimatedPriceType === 2 
    const isExclusiveEstimatedPrice = estimatedPriceType === 4
    const estimatedPriceWithSymbolVal = estimatedPrice?.amountWithSymbol || ''

    const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(estimatedPriceWithSymbolVal, salePrice?.amount)

    let orginString = estimatedPriceWithSymbolVal || ''

    let classList = ['prices-info__sale-price', 'prices-info__sale-explain']

    const classTypeMap = {
      origin: 'prices-info__sale-price_promo', // 促销色
      brown: 'prices-info__sale-price_sheinclub', // 付费会员色
      darkGold: 'prices-info__sale-price_s3vip' // s3会员色
    }

    // 区分会员色以及促销色
    if (isSheinClubEstimatedPrice) {
      classList.push(classTypeMap.brown)
    } else if (isExclusiveEstimatedPrice) {
      classList.push(classTypeMap.darkGold)
    } else {
      classList.push(classTypeMap.origin)
    }

    let camelPriceStyle = {
      after: {
        'font-size': '0.266rem',
        'font-weight': 400
      },
      before: {
        'font-size': '0.373rem'
      }
    }

    return {
      isSheinClubEstimatedPrice,
      isExclusiveEstimatedPrice,
      estimatedTextNewStyleLabel,
      orginString,
      currencySymbolPrefix,
      numberBeforeDot,
      numberAfterDot,
      currencySymbolSuffix,
      classList,
      camelPriceStyle
    }
  },
})
</script>

<style lang="less">
.estimate-price-intro {
  margin-top: 1px;
  display: inline-flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  padding: 0 2px;
  background-color: var(--promo_BG, #FFF1ED);
  color: @sui_color_discount;
  align-items: center;
  max-width: 100%;
  // 用来调整价格说明中的 驼峰价样式
  >.prices-info__sale-explain {
    /* stylelint-disable-next-line declaration-no-important */
    line-height: 19px !important;
  }
  .price-intro_line {
    width: 1px;
    height: 10px;
    margin: 0 2px;
    background-color: @sui_color_discount;
  }
  .price-intro_text {
    font-size: 0.266rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
  }
  
}

// 付费会员到手价
.clube-price-intro{
  background-color: var(--SheinClub_bg, #FFF5ED);
  color: @sui_color_club_rosegold_dark1;
  .price-intro_line {
    background-color: @sui_color_club_rosegold_dark1;
  }
}

// s3会员到手价
.exclusive-price-intro {
  background-color: #FFFBED;
  color: #806208;
  .price-intro_line {
    background-color: #806208;
  }
}


// 处理ar站价格信息被反转问题
[mir=rtl] {
  .prices-info__sale-explain {
    direction: ltr;
  }
}
</style>
