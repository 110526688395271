var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"category-recommend-placeholder__container"},[(_setup.isShow && _setup.slidesData.length > 0)?_c('div',{staticClass:"category-recommend-placeholder",style:(_setup.rootStyle),attrs:{"data-background-image":_setup.transformImg({ img: _setup.compMetaData?.bgImage?.src })}},[(_setup.compMetaData.titleText)?_c('header',{staticClass:"category-recommend-placeholder__header",style:({ 'color': _setup.compMetaData.titleColor })},[_vm._v("\n      "+_vm._s(_setup.compMetaData.titleText || '')+"\n    ")]):_vm._e(),_vm._v(" "),_c('swiper-container',{ref:"swiperInstanceRef",class:_setup.swiperContainerClassName,attrs:{"init":"false","destroy-on-disconnected":"false","sbc-swiper-id":_setup.swiperId}},[_c('div',{staticClass:"category-recommend-placeholder__hydration-layer"},_vm._l((_setup.virtualData),function(col,colIndex){return _c('swiper-slide',{key:colIndex,staticClass:"category-recommend-placeholder__slide",class:{
            'category-recommend-placeholder__nocutImg': _setup.sbcInfoSummary.imageShape === 'nocut',
            'is-need-scrollbar': Number(_setup.sbcInfoSummary.spacing) % 1 === 0 // 一屏为整数列时，需要滚动条
          },style:({ width: _setup.swiperSlideWidth })},[_vm._l((col),function(item,rowIndex){return [(item)?_c('div',{key:item.cate_id,staticClass:"category-recommend-placeholder__item",staticStyle:{"position":"relative"},attrs:{"data-eid":item._FE_eid_,"data-loc-tag":item._FE_itemLoc_,"data-distribution-loc":item._FE_DistributionLoc_},on:{"click":function($event){return _setup.handleClickItem(item, colIndex, rowIndex)}}},[(item.image)?_c(_setup.BaseImg,{directives:[{name:"tap",rawName:"v-tap",value:(_setup.formatAnalysisData('2-22-2', { item, rowIndex, colIndex, if_auto_slide: _setup.isAutoPlayStarted })),expression:"formatAnalysisData('2-22-2', { item, rowIndex, colIndex, if_auto_slide: isAutoPlayStarted })"},{name:"expose",rawName:"v-expose",value:(_setup.formatAnalysisData('2-22-1', { item, rowIndex, colIndex, if_auto_slide: _setup.isAutoPlayStarted })),expression:"formatAnalysisData('2-22-1', { item, rowIndex, colIndex, if_auto_slide: isAutoPlayStarted })"}],class:{ 
                  'category-recommend-placeholder__circularImg': _setup.sbcInfoSummary.imageShape === 'circle',
                  'category-recommend-placeholder__nocutImg': _setup.sbcInfoSummary.imageShape === 'nocut',
                  'category-recommend-placeholder__nonNoCut': _setup.sbcInfoSummary.imageShape !== 'nocut',
                  'category-recommend-placeholder__oval': _setup.sbcInfoSummary.imageShape === 'oval',
                  'category-recommend-placeholder__stroke': _setup.sbcInfoSummary.stroke === 'true',
                  'category-recommend-placeholder__stroke_2': _setup.sbcInfoSummary.stroke === 'true_2.0',
                  'not-fsp-element': item._FE_isAnimationImg_,
                },style:(_setup.imageStyle),attrs:{"data-eid":item._FE_eid_,"brand":_vm.brand,"img-src":item.image.src,"placeholder":{
                  width: _setup.sbcInfoSummary.imageShape === 'nocut' ? 76 : 58,
                  height: _setup.sbcInfoSummary.imageShape === 'nocut' ? 56 : 58,
                },"first-screen":_setup.isFirstPage && (colIndex < 5),"imgDesignWidth":144,"comp-src":'category-recommend-placeholder',"ratio":_setup.sbcInfoSummary.imageShape === 'nocut' ? 1.357 : 1,"fit":"cover","imgDataExp":{
                  unCut: item._FE_isUnCut_,
                }}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.isShowBelt && item.beltText),expression:"isShowBelt && item.beltText"}],staticClass:"category-recommend-placeholder__item-belt",style:(_setup.beltStyle)},[_vm._v("\n                "+_vm._s(item.beltText)+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"category-recommend-placeholder__name",class:_setup.lineClampClass,style:(_setup.cateNameStyle)},[_vm._v("\n                "+_vm._s(item.smallTitle || '')+"\n              ")])],1):_vm._e()]})],2)}),1)])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }