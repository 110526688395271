
import Vue from 'vue'
import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'

const mutations = {
  SET_DATA (state, payload) {
    state.context = payload
  },
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  assignList(state, payload) {
    if (!payload || !payload.channelId) return

    const updateFlag = (
      !state.list?.hasOwnProperty(payload.channelId) ||
      !Object.keys(state.list?.[payload.channelId] || {}).length ||
      payload.clientRefresh
    )
    
    if (updateFlag) {
      payload.content.contentChannelSetTime = Date.now()
      payload.content.traceId = payload.traceId
      Vue.set(state.list, payload.channelId, payload.content)
    }
  },

  assignListChannelState(state, payload) {
    if (!payload || !payload.channelId) return
    Vue.set(state.list[payload.channelId], payload.key, payload.value)
  },
  // 各tab下组件刷新判断
  assignListCompChecke(state, payload) {
    payload.refreshData.forEach(infoElement => {
      const refreshCom = payload.flushContentMap.get(infoElement?.paramPropsHashCode || '')
      if (refreshCom) {
        // 组件内不监听数据刷新
        cccxEventBus?.emit?.(`ccc-content-refresh-${payload.channelId}-${refreshCom}`, infoElement)
      }
    })
  },
  // 各tab下组件数据替换
  assignListCompContent(state, payload) {
    if (!payload || !payload.channelId) return
    Vue.set(state.list[payload.channelId].content, payload.index, payload.element)
  },
  // 各tab下组件已浏览过赋值
  assignExposedList(state, payload) {
    if (!payload || !payload.channelId) return
    if (state.list[payload.channelId]?.content?.[payload.index] && !state.list[payload.channelId]?.content?.[payload.index]?.isBeenExposed) {
      state.list[payload.channelId].content[ payload.index].isBeenExposed = true
    }
  }
}

export default mutations
