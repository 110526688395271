<template>
  <div
    v-if="!isHide && !isEnd"
    class="banner-countdown"
    :class="{ hasBottomSpace: !disableBottomSpacing }"
  >
    <div
      v-for="(item, i) in items"
      :key="i"
      class="banner-countdown__item"
      :class="{ intervalSpacing }"
    >
      <BaseImg
        v-tap="{
          id: 'click_block_main.comp_top-banner',
          data: { item, index: i, compIndex: index },
        }"
        v-expose="{
          id: 'expose_block_main.comp_top-banner',
          data: { item, index: i, compIndex: index },
        }"
        :ratio="Number(item.image.ratio)"
        :src="getBaseCutImg(item.image.src)"
        :dataDesignWidth="imgDesignWidth"
        :firstScreen="true"
        :ada="item.ada"
        :alt="item.hrefTitle"
        :onImageLoad="handleBaseImageMounted"
        @click="clickItem(item, i)"
      />
      <div
        class="banner-countdown__countdown"
        :style="handlePosition()">
        <div :style="{ display: 'flex' }">
          <span :style="textStyle">{{ countdownText }}</span>
          <Flipper
            v-if="countTimer.timeId"
            :time-obj="countTimer.timeObj"
            :type="flipType"
            :time-unit="['D', ':', ':']"
            :item-style="itemStyle"
            :sign-style="signStyle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerCountdown',
}
</script>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, inject } from 'vue'
import { CountDown } from '@shein/common-function'
import { Toast } from '@shein/sui-mobile'
import { Image as BaseImg } from '@shein/sui-mobile'
import Flipper from './Flipper.vue'
import { commonProps } from '../../common/common-props.js'
import { getServerTime } from '../../common/utils.js'
import { useLink } from '@shein-aidc/bs-sdk-ccc-link'
const { vTap, vExpose } = inject('analysisInstance')
const cutImg = inject('cutImg', url => url)

const props = defineProps({
  ...commonProps,
})

const emits = defineEmits(['base-image-mounted', 'countdown-end'])

const { cccLink, jumpLink } = useLink({
  propData: props.propData,
  sceneData: props.sceneData,
})

const countTimer = ref(
  new CountDown({
    endFunc: () => handleCountdownEnd(),
  }),
)

const curTime = ref(props.context?.serverTime)
const countdownText = ref('')
const isHide = ref(false)
const isEnd = ref(false)
const countTime = ref(0)

const items = computed(() => props.propData?.props?.items || [])
const metaData = computed(() => props.propData?.props?.metaData || {})
const disableBottomSpacing = computed(
  () => metaData.value?.disableBottomSpacing,
)
const intervalSpacing = computed(() => metaData.value?.intervalSpacing || false)
const countdownSize = computed(() => metaData.value?.size || 'small')
const flipType = computed(() => (Number(countTimer.value?.timeObj?.D) ? 4 : 3))
const textStyle = computed(() => {
  const { cssRight = false } = props.context
  const map = {
    small: {
      fontSize: '0.32rem',
    },
    medium: {
      fontSize: '0.3733rem',
    },
    large: {
      fontSize: '0.4267rem',
    },
  }
  return {
    ...map[countdownSize.value],
    margin: cssRight ? '0 0 0 0.2133rem' : '0 0.2133rem 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
})
const itemStyle = computed(() => {
  let size = '0.5333rem'
  let fontSize = '0.32rem'
  let borderRadius = '0.0533rem'
  const map = {
    small: {
      size: '0.5333rem',
      fontSize: '0.32rem',
    },
    medium: {
      size: '0.64rem',
      fontSize: '0.3733rem',
    },
    large: {
      size: '0.7467rem',
      fontSize: '0.4533rem',
    },
  }
  size = map[countdownSize.value].size
  fontSize = map[countdownSize.value].fontSize
  return {
    minWidth: size,
    height: size,
    lineHeight: size,
    fontSize: fontSize,
    background: '#000',
    color: '#fff',
    '--color': '#000',
    fontWeight: 'bold',
    borderRadius,
  }
})
const signStyle = computed(() => {
  let fontSize = '0.32rem'
  const map = {
    small: '0.32rem',
    medium: '0.3733rem',
    large: '0.4267rem',
  }
  fontSize = map[countdownSize.value]
  return {
    color: '#000',
    fontSize,
    padding: '0 0.16rem',
  }
})
const imgDesignWidth = computed(() => {
  if (items.value?.length) {
    return Math.floor(750 / items.value?.length)
  }
  return 0
})

const getBaseCutImg = (imgSrc) => {
  if (!cutImg) {
    return imgSrc.replace(/^https?\:/, '')
  }

  return cutImg(imgSrc, imgDesignWidth.value)
}

const initCountdown = () => {
  const {
    countdownRange = {},
    countdownType = 1,
    loopTime,
  } = metaData.value || {}
  const { startTime, endTime } = countdownRange || {}
  if (countdownType == 1) {
    // 连续循环倒计时
    handleLoopCountdown({
      startTime: +startTime,
      endTime: +endTime,
      loopTime: +loopTime,
    })
  } else if (countdownType == 3) {
    // 非循环倒计时
    handleNoLoopCountdown({ startTime: +startTime, endTime: +endTime })
  }
  countTimer.value.start({ seconds: countTime.value, dayMode: true })
}

const handleLoopCountdown = ({ startTime, endTime, loopTime }) => {
  if (curTime.value > startTime && curTime.value < endTime) {
    const overTime = (curTime.value - startTime) / 1000 // 已经过去的时间
    const restTime = (endTime - curTime.value) / 1000 // 剩余时间
    const remainTime = ((endTime - startTime) / 1000) % (loopTime * 3600) // 循环余数
    countdownText.value = props.language?.SHEIN_KEY_PWA_21325
    if (overTime % (loopTime * 3600) && restTime > remainTime) {
      // 剩余时间 % 循环时长
      countTime.value = loopTime * 3600 - (overTime % (loopTime * 3600)) // 循环时长 - 已过去时长 % 循环时长
    } else {
      countTime.value = restTime // 剩余时长
    }
  } else if (curTime.value < startTime) {
    // 当前时间小于开始时间
    isHide.value = true
  } else if (endTime < curTime.value) {
    // 当前时间大于结束时间
    isHide.value = true
  }
}
const handleNoLoopCountdown = ({ startTime, endTime }) => {
  if (
    new Date(startTime).getTime() < curTime.value &&
    new Date(endTime).getTime() > curTime.value
  ) {
    countdownText.value = props.language?.SHEIN_KEY_PWA_21325
    countTime.value = (new Date(endTime).getTime() - curTime.value) / 1000
  } else if (new Date(startTime).getTime() > curTime.value) {
    isHide.value = true
  } else if (new Date(endTime).getTime() < curTime.value) {
    isHide.value = true
  }
}

const clickItem = async (item, i) => {
  const { countdownRange = {} } = metaData.value || {}
  const { endTime } = countdownRange || {}
  const time = await getServerTime()
  if (endTime < time) {
    Toast({ content: props.language?.SHEIN_KEY_PWA_22199 })
    setTimeout(() => {
      window.location.reload()
    }, 1000)
    return
  }
  const url = cccLink.getFullLink({
    item,
    cateLinks: props.cateLinks,
    compIndex: props.index,
    index: i,
  })

  jumpLink({ url, hrefType: item.hrefType })
}

const handlePosition = () => {
  const { position } = metaData.value || {}
  if (!position) {
    return {
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    }
  }
  const key = position.join(',').replace(',', '')
  const styleMap = {
    topleft: {
      top: '0.32rem',
      left: '0.32rem',
    },
    topcenter: {
      top: '0.32rem',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    topright: {
      top: '0.32rem',
      right: '0.32rem',
    },
    centerleft: {
      top: '50%',
      left: '0.32rem',
      transform: 'translateY(-50%)',
    },
    centercenter: {
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    },
    centerright: {
      top: '50%',
      right: '0.32rem',
      transform: 'translateY(-50%)',
    },
    bottomleft: {
      bottom: '0.32rem',
      left: '0.32rem',
    },
    bottomcenter: {
      bottom: '0.32rem',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    bottomright: {
      bottom: '0.32rem',
      right: '0.32rem',
    },
  }
  const oneImagePaddingMap = {
    small: '0.16rem 0.2133rem',
    medium: '0.2133rem 0.2667rem',
    large: '0.2667rem 0.32rem',
  }
  return {
    ...styleMap[key],
    padding: oneImagePaddingMap[countdownSize.value],
  }
}

const handleBaseImageMounted = () => {
  emits('base-image-mounted')
}

const handleCountdownEnd = () => {
  emits('countdown-end')
  isEnd.value = true
}

onMounted(async () => {
  const serverTime = await getServerTime()
  if (serverTime) {
    curTime.value = serverTime
  }
  initCountdown()
})
</script>

<style lang="less" scoped>
@vw: 375/100vw;
.banner-countdown {
  display: flex;
  justify-content: space-between;
  &.hasBottomSpace {
    padding-bottom: 8 / @vw;
  }
  &__item {
    flex: 1;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
  }
  &__countdown {
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    padding: 0.08rem 0.2133rem;
    z-index: 1;
    span {
      display: block;
      width: max-content;
      font-weight: bold;
    }
  }
  .intervalSpacing {
    margin: 0 4 / @vw;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
