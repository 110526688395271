import { storeModuleName } from '../../product_app/store/modules/search-product-list'
import { setMarkPoint } from './useMarkPoint.js'
import { optimizeGoodsData } from '@/public/src/pages/product_list_v2/js/schedulerTask'
import { clearDefaultKidParams } from '@/public/src/pages/components/FilterBar/utils/kids.js'
import { getListPageInfo } from '@/public/src/pages/product_list_v2/js/utils'
import { ListAnalytics } from '@/public/src/pages/product_list_v2/analytics/index.js'
import { updateRoute } from './useRoute.js'

const handleSetInitQuery = (vm, newData) => {
  const { page, limit } = newData.cat_info || {}
  vm.modifyRequest({ key: 'query', value: { page: +page, limit: +limit } })
  vm.$route?.query && (vm.$route.query.limit = +limit)  // 在activated中会把route的query混入到Request的query中，会导致上面的limit设置失败，所以这个地方需要更新一下route中的limit信息
  if (vm.PageState?.isSpaPage) {
    optimizeGoodsData(newData, (originalData) => {
      // 触发剩下的数据到vuex队列中
      vm.processDataAfterInitialLoad(originalData)
      vm.$refs.refCloudTags?.updateCloudTags()
      vm.$refs.refNavBar?.updateCateData()
    })
  }
}

// 开始处理数据
const startResolveData = (vm) => {
  const contextForSSR = vm.$store.state[storeModuleName].context
  vm.resetLocals(contextForSSR)

  if (contextForSSR) {
    vm.isFirstLoad = true
    vm.hydrationInitRequest()
    resolveFilterData(vm, { result: contextForSSR, isLanding: true }) // 筛选相关数据
    vm.resolveData(contextForSSR) // 商品相关数据
  }
}

// 筛选数据，非商品数据处理
const filterDataQueue = (vm) => {
  return {
    _filterRenderHandle: ['nodeRes', 'bffFilterInfo', 'bffLanguage', 'sheinClubInfo', async (result, cb) => {
      if (vm.isNextPage) return cb(null) // 翻页不处理筛选
      
      const filterInfoWithNodeRes = resolveFilterData(vm, { result })
      
      cb(null, { 
        filterInfoWithNodeRes, 
        exposeKeys: ['kidsTag', 'defaultChild', 'searchBannerCard'],
        isFilterHandler: true
      })
    }]
  }
}

const resolveFilterData = (vm, { result, isLanding }) => {
  const filterInfoWithNodeRes = {
    ...((isLanding ? result : result.nodeRes) || {}), // 直接落地和xhr的数据结构需要区分
    bffFilterInfo: result?.bffFilterInfo || {},
    ...(result.bffLanguage?._fromBff ? { language: result.bffLanguage } : {}),
    ...(Object.keys(result.sheinClubInfo || {})?.length ? { sheinClubInfo: result.sheinClubInfo } : {}),
  }
  
  if (typeof window !== 'undefined') {
    // 采集vuex\初始化、filter组件 耗时
    setMarkPoint(vm.isFirstLoad, 'filterRenderHandle')
  }

  vm.handleDefaultInitData(filterInfoWithNodeRes)

  // 筛选时，更新路由
  if (vm.isRefresh) {
    updateRoute(vm)
  }

  vm.initAdapter(filterInfoWithNodeRes).adaptInitOrFilterData()
  if (vm.isFirstLoad) {
    clearDefaultKidParams(vm.Request.query)
    vm.showPageRenderDone = false // 筛选组件提前渲染，其他组件等待商品接口返回
    vm.resetState({
      payload: filterInfoWithNodeRes,
      PageStateArgs: {
        exposeCode: ListAnalytics.containerExposeCode,
        isSpaPage: vm.PageState.isSpaPage,
        searchPageLoading: vm.PageState.searchPageLoading
      },
    })
    // 搜索结果页不需要
    const { isAllSearchType } = getListPageInfo()
    if (!isAllSearchType) {
      vm.isUseSearchPicTopNav = filterInfoWithNodeRes.list_nav_source === 'search'
    }
  }
  handleSetInitQuery(vm, filterInfoWithNodeRes)
  vm.initStateOrFilterDataNew(filterInfoWithNodeRes)

  return filterInfoWithNodeRes
}

export {
  startResolveData,
  filterDataQueue,
}

