/**
 * 个人中心根状态
 */

const setDefaultSLoadingConfig = () => ({
  containerCoverStyle: {},
  curPageMaskTopBoundSelector: '.j-common-logo-header',
  curPageScrollFix: true,
  maskTransparent: false
})

const state = {
  headerNav: {
    isHideBack: false, // 是否隐藏回退按钮
    title: '',
    subTitle: '', // 副标题
    btns: [] // 仅收藏相关页面执行，[{ type=类型（text、select、bag）, isHide=是否隐藏按钮, evt=事件 }]
  },
  rootStatus: {
    pageLoading: false,
    loading: false,
    msgModal: false,
    confirmModal: false
  },
  userRootSLoading: setDefaultSLoadingConfig(),
  userInfo: {},
  isLogin: false,
  msgModal: {
    info: '',
    type: ''
  },
  confirmModal: {
    info: '',
    okText: '',
    ok () {}
  },
  // quickAdd: {
  //   active: false,
  //   goods_id: null,
  //   selectedAttrId: null,
  //   config: {},
  //   index: 0,
  //   reducePrice: 0,
  //   transformZIndex: 1,
  //   // slefGoods, from 在config里配置
  //   // slefGoods: '',
  //   // from: ''
  // },
  blackMask: {
    showMask: false,
    text: '',
    okCallback: '',
    billno: '',
    errorCode: '',
    type: ''
  },
  // 用户登录信息储存
  accountInformation: {
    language_account: {},
    account_info: {}
  },
  pwaUserAvatar: '',
  // 点券信息
  activityInfo: {
    couponActOpen: -1,
    pointActOpen: -1,
    rewardMsg: '',
    abtResult: false
  },
  // 显示编辑状态
  isEdit: false,
  // 选中的商品id
  selectedGoods: {},
  statusType: 0,
  recordsPageFrom: '',
  // 用户中心模块多语言
  userCenterLanguage: {},
  newGiftCardBalance: {},
  isErrorGuideOn: true,
  hasGuide: false,
  // 显示恢复订单信息
  showRestoredInfo: false,
  // 过期消息信息
  couponOrPointsData: {
    pointsNum: 0,
    couponNum: 0,
    isReqData: false,
    isClickCoupon: false,
    isClickPoints: false,
    isOpenCoupon: true,
    isOpenPoints: true
  },
  needUpdateCollection: false,
  drawerContactBind: false,
  // 订阅中心弹窗类型
  popContactBindType: 'change',
  // 订阅中心绑定手机号国家区号编码
  countryCodes: [],
  showAreaPop: false,
  areaCode: { code: '', abbr: '' },
  // 订阅中心绑定sms&WhatsApp信息错误提示
  bindPhoneErr: false
}

const mutations = {
  assignRootState (state, payload) {
    Object.assign(state, payload)
  },
  changeHeaderNav (state, payload) {
    state.headerNav = { ...state.headerNav, ...payload }
  },
  changeRootStatus (state, payload) {
    state.rootStatus = { ...state.rootStatus, ...payload }
    if (typeof payload.loading !== 'undefined' && !payload.loading) {
      state.userRootSLoading = setDefaultSLoadingConfig()
    }
  },
  changeUserRootSLoadingStatus (state, payload) {
    state.userRootSLoading = { ...setDefaultSLoadingConfig(), ...payload }
  },
  showMsg (state, { timing = 1500, ...opts }) {
    state.msgModal = opts
    state.rootStatus.msgModal = true
    setTimeout(() => {
      state.rootStatus.msgModal = false
    }, timing)
  },
  showConfirm (state, payload) {
    state.rootStatus.confirmModal = true
    state.confirmModal = payload
  },
  showMaskTips (state, payload) {
    state.blackMask.showMask = true
    if (typeof payload == 'object') {
      const { text, type, billno, errorCode } = payload
      state.blackMask.text = text
      state.blackMask.billno = billno
      state.blackMask.errorCode = errorCode
      state.blackMask.type = type || ''
    } else {
      state.blackMask.text = payload
      state.blackMask.type = ''
      state.blackMask.billno = ''
      state.blackMask.errorCode = ''
    }
  },
  updateHasGuide(state, payload) {
    state.hasGuide = payload
  },
  updateIsErrorGuideOn(state, payload) {
    state.isErrorGuideOn = payload
  },
  imgLazyLoad () {
    if (typeof GB_SHEIN_lazyLoadInstance !== 'undefined') {
      GB_SHEIN_lazyLoadInstance.update()
    }
  },
  // openQuickAdd (state, { goods_id, index, reducePrice, config = null }) {    
  //   state.quickAdd.active = true
  //   state.quickAdd.goods_id = goods_id
  //   state.quickAdd.index = index
  //   state.quickAdd.reducePrice = reducePrice
  //   config && (state.quickAdd.config = config)
  // },
  // closeQuickAdd (state) {
  //   state.quickAdd.active = false
  //   state.quickAdd.goods_id = null
  // },
  changeAccountInfo (state, payload) {
    state.accountInformation = { ...state.accountInfo, ...payload }
  },
  setActivityInfo (state, payload) {
    state.activityInfo = { ...state.activityInfo, ...payload }
  },
  setUserAvatar (state, { pwaUserAvatar }) {
    state.pwaUserAvatar = pwaUserAvatar
  },
  changeCouponOrPointsData (state, payload) {
    state.couponOrPointsData = {
      ...state.couponOrPointsData,
      ...payload,
    }
  },
  toggleDrawer (state, payload) {
    const { type = '', value = true, popContactBindType = 'change' } = payload
    switch (type) {
      case 'contact-bind': {
        state.drawerContactBind = !!value
        state.popContactBindType = popContactBindType
        break
      }
    }
  },
  setAreaCode (state, data) {
    const { code, abbr } = data
    state.areaCode.code = code.replace('+', '')
    state.areaCode.abbr = abbr 
  },
  // 依靠手机号设置sms&WhatsApp国家区号,例如：86
  setAreaCodeByCode (state, data) {
    if(state.countryCodes.length > 0 && data) {
      state.countryCodes.some(item => {
        if(item.code == data) {
          state.areaCode.code = item.code
          state.areaCode.abbr = item.abbr
          return true
        }
      })
    }
  },
  setAreaCodePop (state, val) {
    state.showAreaPop = val
  },
  setCountryCodes (state, data) {
    state.countryCodes = data
  },
  setBindPhoneErr (state, val) {
    state.bindPhoneErr = val
  },
  changeBlackMaskStatus(state, payload) {
    Object.assign(state.blackMask, payload || {})
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
