import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { exposeScenesAbtAggregatorBff as exposeScenesAbtAggregator } from 'public/src/pages/checkout/tools/EventAggregator.js'

/**
 * @description 购物袋埋点上报
 * */
export default function useShoppingBagReport() {
  const { abtInfo, checkout, shoppingBagsState, cartsInfo } = useMapState(['abtInfo', 'checkout', 'shoppingBagsState', 'cartsInfo'])
  
  const formatGoodsList = (index, item) => {
    const { aggregateProductBusiness = {}, attr = {}, product = {} } = item
    const { productImgLabel = {}, productTags } = aggregateProductBusiness
    const { actTag, goods_id, goods_sn, productRelationID, salePrice, retailPrice, insurable  } = product

    // goods_id`skc`spu_id`坑位`页码`运营位置`流量标识rec_mark`置顶标识extra_mark`价格标识`其它标识`销售或促销属性`mall_mallcode`算法额外标识
    const reportKeys = [
      'goodsId',
      'skc',
      'spuId',
      'index',
      'page',
      'location',
      'recMark',
      'extraMark',
      'priceMark',
      'otherMark',
      'promotion',
      'salesLabels',
      'mallCode',
      'algorithmExtraMark'
    ]
    const otherMark = []
    if (actTag?.appTraceInfo) otherMark.push(actTag?.appTraceInfo)
    if (productImgLabel?.titleImgBiData) otherMark.push(`atmosphere_group_${productImgLabel?.titleImgBiData || ''}`)
    if (productTags?.find(tag => tag.type === 'evoluShein')) otherMark.push('show_evolushein')

    const values = {
      goodsId: goods_id,
      skc: goods_sn,
      spuId: productRelationID,
      index: index + 1,
      page: '1',
      location: '1',
      recMark: '',
      extraMark: '',
      priceMark: `pri_${salePrice.usdAmount}|pri_${retailPrice.usdAmount}`,
      otherMark: otherMark.join('|'),
      promotion: `left_${attr.stock || ''}${insurable == 1 ? '|insured_1' : ''}`,
      salesLabels: '',
      mallCode: `mall_${item?.mall_code || ''}`,
      algorithmExtraMark: ''
    }

    return reportKeys.map(key => values[key]).join('`')
  }

  // 购物袋利诱点标题曝光
  const handleTitleExposed = (scrollBarTips) => {
    const hasBenefit = scrollBarTips?.length
    const tipsAbt = abtInfo.value?.PromotionDetailBags?.param?.ShoppingBagStyle || 'Hide'
    const hasAbt = tipsAbt !== 'Hide'
    let type
    if (hasBenefit) {
      type = hasAbt ? '3' : '4'
    } else {
      type = hasAbt ? '1' : '2'
    }
    exposeScenesAbtAggregator.addEvent({ 
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'shoppingbag_headline_tips',
        type
      }
    })
  }

  // 商品行腰带曝光
  const handleBeltExposed = (goods = []) => {
    const hasBelt = !!goods?.filter(item => item.aggregateProductBusiness?.shoppingBagBelt?.shoppingBagType)?.length
    const beltAbt = abtInfo.value?.CheckoutShoppingBag?.param?.ShoppingBagItems || 'Hide'
    const hasAbt = beltAbt !== 'Hide'
    let type
    if (hasBelt) {
      type = hasAbt ? 3 : 4
      const wonderful = goods.filter(f => f.aggregateProductBusiness?.shoppingBagBelt?.shoppingBagType == 'comments_tag').map(m => m.goodId)
      if(wonderful?.length){
        // 精彩评论上报
        exposeScenesAbtAggregator.addEvent({
          id: 'expose_scenesabt:simple',
          data: {
            scenes: 'commentstag',
            type: wonderful.join(',')
          }
        })
      }
    } else {
      type = hasAbt ? 1 : 2
    }
    exposeScenesAbtAggregator.addEvent({
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'shoppingbag_product_tips',
        type
      }
    })
  }

  const getExposedData = (mallInfo) => {
    const headlineTypeList = (mallInfo?.shoppingBagScrollBarTips || [])?.map(tips => (
      `${tips.type}\`${tips.itemCount}`
    ))
    const beltList = mallInfo.all?.map(good => (
      good.aggregateProductBusiness.shoppingBagBelt?.shoppingBagType || '-'
    ))
    const data = { product_tips: beltList?.join(',') }
    if (headlineTypeList?.length) data.headline_type = headlineTypeList?.join(',')
    return data
  }

  // 外层购物袋曝光
  const handleShoppingBagExposed = (mallInfo) => {
    daEventCenter.triggerNotice({
      id: 'expose_shopping_bag:simple',
      data: getExposedData(mallInfo)
    })
  }

  // 打开购物袋弹窗
  const handleShoppingBagOpen = (mallIndex, clickFrom) => {
    const mallInfo = shoppingBagsState?.value?.bagsInfo?.mall?.[mallIndex] || {}
    const selfCarts = mallInfo.all?.filter(item => item.business_model == 0) || []
    const is_self_num = !selfCarts.length ? '0' : (selfCarts.length == mallInfo.all.length ? '1' : '0,1')
    daEventCenter.triggerNotice({
      id: 'click_shopping_bag:simple',
      data: {
        is_self_num,
        store_code: mallInfo.storeList.map(store => store.store_code).join(','),
        click_from: clickFrom
      }
    })
  }

  const handleBehaviorExposed = (mallInfo) => {
    let hasBehavior = mallInfo.all.some(cart => !!cart.product.actTag.tagName)
    // abt 已推全
    daEventCenter.triggerNotice({
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'userbehavior_tips',
        type: hasBehavior ? 3 : 1
      }
    })
  }

  const handleTrendStore = (mallInfo) => {
    // 时尚店铺标识上报
    if (mallInfo.trend_store_codes) {
      exposeScenesAbtAggregator.addEvent({
        id: 'expose_scenesabt:simple',
        data: {
          scenes: 'store_trends_logo',
          type: mallInfo.trend_store_codes
        }
      })
    }
  }

  // 购物袋弹窗曝光
  const handleShoppingBagDrawerExposed = (mallIndex) => {
    const mallInfo = shoppingBagsState?.value?.bagsInfo?.mall?.[mallIndex]
    const { icon, desc } = checkout?.value?.seaLandInfo?.shoppingBag || {}
    daEventCenter.triggerNotice({
      id: 'expose_popup_shopping_bag:simple',
      data: {
        ...getExposedData(mallInfo),
        is_sealand: !!(icon || desc) ? 1 : 0,
      }
    })
    handleBehaviorExposed(mallInfo)
    handleTrendStore(mallInfo)
  }

  // 获取 evolushein 标签信息
  const getEvoluTagExposedData = (carts, inDrawer) => {
    const tags = []
    carts?.forEach(cart => {
      if (cart.aggregateProductBusiness.productTags?.find(tag => tag.type === 'evoluShein')) {
        tags.push(`${cart.product?.sku_code}\`show_evolushein`)
      }
    })
    if (tags.length) {
      return {
        id: 'expose_shopping_bag_tag:simple',
        data: {
          postion: inDrawer ? 'popup' : 'page',
          tag_info: tags.join(',')
        }
      }
    }
  }

  return { 
    formatGoodsList,
    handleTitleExposed, 
    handleBeltExposed,
    handleShoppingBagOpen,
    handleShoppingBagExposed,
    handleShoppingBagDrawerExposed,
    getEvoluTagExposedData
  }
}
