import Middle from './middle'
import MiddleAttr from './innerComponents/MiddleAttr'
import EuRespPerson from './innerComponents/EuRespPerson'
import Description from './innerComponents/Description'
import DetailSignBoard from './innerComponents/DetailSignBoard'
import DetailsPictures from './innerComponents/DetailsPictures'
import UserManual from './innerComponents/UserManual'
import SizeGuide from './innerComponents/SizeGuide'
import Reviews from './innerComponents/Reviews/Reviews'
import RatePanel from './innerComponents/Reviews/components/RatePanel'
import ShippingEnter from './innerComponents/ShippingEnter'
import shippingDrawer from './innerComponents/shippingDrawer'
import CCCBannerBox from './innerComponents/CCCBannerBox'

export default {
  Middle,
  MiddleAttr,
  EuRespPerson,
  Description,
  DetailsPictures,
  SizeGuide,
  UserManual,
  DetailSignBoard,
  Reviews,
  RatePanel,
  ShippingEnter,
  shippingDrawer,
  CCCBannerBox
}
