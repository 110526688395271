/**
 * 职责： 记录用户的路由行为
 */

// todo ： 打开多个窗口的情况下 会读取其他 history.length < 3 表示打开新窗口 没有返回

// 功能：
//  定义有那些来源路由name， 到指定的路由name，命中就为开始记录
//  开始的次数+1：  进入+1
//  只要开始后，在命中来源路由name的时候，也进行记录但不是开始。
// 结束：返回-1， 只到为0的时候，结束记录

//  一、商详一些例子
//  1. 首页 =》 列表 （sesssion_id1） =>  商详 （推荐夹带sesssion_id1） => 列表 （sesssion_id2） => 商详（推荐夹带sesssion_id1，sesssion_id2）
// 结束点为一直返回到第一次列表的到首页
//  2. category =》 列表 （sesssion_id1） =>  商详 （推荐夹带sesssion_id1） => 列表 （sesssion_id2） => 商详（推荐夹带sesssion_id1，sesssion_id2）
// 结束点为一直返回到第一次列表的到首页

// 二、搜索 cycle的例子
//  1.首页 =》 预搜索页（sesssion_id）=> 搜索结果页 (夹带sesssion_id) => 预搜索页 =》 搜索结果页（夹带sesssion_id）
// 结束点： （1） 直接回到首页 （2）回到第一个预搜索页并到首页的时候结束

//  以下场景case需要去代办：
//  1.pwa的场景： 首页 => 列表（开始） =>  商详 =》 回到首页结束/列表到首页结束

class RouterAction {
  /***
   *  @params数说明
   *  targetNames:  目标页面name 集合
   *  clearDataNames : 清空数据的name 集合
   * onStartCallback: 开始回调
   * onEndCallback: 结束回调
   * targetTriggerCallback: 目标触发回调, 只要当前路由命中目标路由，就会触发(如果有多个目标路由，以第一个为准)
   * onWatch : 每次更新回调
   */
  constructor({ targetNames, clearDataNames, singleKey, openBeforeStartRouterClear, diffTargetUrlClear, clearHandle, notEndNames }) {
    this.startState = false // 开始记录状态
    this.userRouterActions = [] // 收集的路由行为
    this.targetNames = targetNames || []
    this.isInit = Array.isArray(targetNames) && targetNames.length > 0
    this.clearDataNames = clearDataNames || []
    this.langPath = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo.langPath : ''
    this.singleKey = singleKey || true // 默认true 每次进入目标页面就生成一个新的session_id
    this.openBeforeStartRouterClear = openBeforeStartRouterClear || false // 默认false 回到开始目标上一个路由时结束清空
    this.diffTargetUrlClear = diffTargetUrlClear || false // 默认false 不同的目标路由url不同清空数据
    this.clearHandle = clearHandle // 清空数据的回调
    this.notEndNames = notEndNames || [] // 不结束的name
    if (this.notEndNames.length && this.clearDataNames.length) {
      console.warn('notEndNames 和 clearDataNames 不能同时存在, 请检查配置')
    }
  }

  getData() {
    if (!this.isInit) return
    return this.userRouterActions
  }

  setInitData(userRouterActions) {
    if (!this.isInit) return
    this.userRouterActions = Array.isArray(userRouterActions)
      ? userRouterActions
      : []
    this.startState = this.userRouterActions.length > 0
  }

  /**
   * @description: 暴露外部路由触发方法
   */
  trigger(to = {}, from = {}) {
    if (!this.isInit) return
    if (to.name === from.name && to.path === from.path) return // 同一个页面
    if (!this.onStartCallback) {
      throw new Error('请注册开始回调')
    }
    if (this.targetNames.length === 0) {
      return
    }

    // 防止最近一条重复记录
    let _userRouterActions = this.userRouterActions
    if (!this.singleKey)  {
      const sortKeys =  Object.keys(this.userRouterActions).sort((a, b) => b - a) // 取最后一条
      const lastData = this.userRouterActions[sortKeys[0]]
      _userRouterActions = [lastData]
    }

    const hasClearHandle = !!this.clearHandle

    if (_userRouterActions.some(item => item?.to?.name === to?.name && item?.to?.path === to?.path && item.from?.name === from.name && item.from?.path === from.path) && !hasClearHandle) {
      return
    }

    this.exposeCheckEnd({ to, from })

    this.onStart(to, from)
    this.recordRouterAction(to, from)
  }

  onceSet() {}

  /**
   * @description: 暴露外部设置一些回调的方法
   */
  registerHandler({
    onStartCallback,
    onEndCallback,
    targetTriggerCallback,
    watch,
    notEndNamesTrigger,
  }) {
    if (!this.isInit) return
    this.onStartCallback = onStartCallback || (() => {})
    this.onEndCallback = onEndCallback || (() => {})
    this.targetTriggerCallback = targetTriggerCallback || (() => {})
    this.notEndNamesTrigger = notEndNamesTrigger || (() => {})
    this.onWatch = watch || (() => {}) // 每次更新回调都要更新
  }

  /**
   * @description: 开始记录, 确认标识开始记录
   * */
  onStart(to, from) {
    if (!this.isInit || this.startState) return
    // 匹配是否为开始记录的路由， name和url都匹配上才行
    const isToCurrentTarget = this.isHitTargetRouter({ to })
    if (isToCurrentTarget || this.userRouterActions.length > 0) {
      this.startState = true
      this.onStartCallback({ to, from })

      this.clearHandle && this.exposeCheckEnd({ to, from })
    }
  }

  //  是否命中目标的路由
  isHitTargetRouter({ to }) {
    const { name: toName } = to
    const isToCurrent = this.targetNames.some(item => {
      if (item.name === toName) {
        if (item.url) {
          const reg = new RegExp(this.langPath + item.url)
          return reg.test(to.path)
        } else {
          return true
        }
      }
      return false
    })
    return isToCurrent
  }

  onEnd() {
    if (this._flagEnd) {
      console.warn('出现异常，需在你的业务逻辑中onTrigger执行end, 请检查')
      return
    }
    if (!this.isInit) return
    this.startState = false
    this.onEndCallback()
    this.userRouterActions = []
    this.onWatch(this.userRouterActions)
  }

  exposeNotEndTrigger() {
    if (!this._flagEnd) return
    this._flagEnd = false
    this.onEnd()
  }

  recordRouterAction(to, from) {
    if (!this.startState) return
    const data = {
      to: {
        name: to.name,
        path: to.path
      },
      from: {
        name: from.name,
        path: from.path
      }
    }
    const limit = 30
    if (this.userRouterActions.length >= limit) {
      this.userRouterActions.shift()
    }
    this.userRouterActions.push(data)
    if (this.isEnd({ to, from })) {
      this.onEnd()
      return
    }
    if (this.isHitTargetRouter({ to })) {
      this.targetTriggerCallback({ to, from })
    }
    this.onWatch(this.userRouterActions)
  }

  /**
   * @description: 检测是否已经结束页面： 返回到第一个开始的页面
   * */
  isEnd({ to }) {
    const isBeforRouterClear = this.handleCurrentRouterIsTargetBeforeRouter({ to })
    if (isBeforRouterClear) return true // 回到开始目标上一个路由时结束清空
    return false
  }

  /**
   * @description: diffTargetUrlClear 为true的时候，不同的目标路由url不同清空数据
  */
  hanldeDiffTargetUrlClear({ to }) {
    if (!this.diffTargetUrlClear || !this.isHitTargetRouter({ to })) return false
  
    if (!this.startDiffTargetUrl) { // 第一次进入
      this.startDiffTargetUrl = to.path
      return false
    }
    // fix: /us/Wide%20Fit%20Shoes-c-5928.html 和 /us/Wide Fit Shoes-c-5928.html 要兼容这种情况
    const isDiff = decodeURIComponent(this.startDiffTargetUrl) !== decodeURIComponent(to.path)
    if (isDiff) {
      this.startDiffTargetUrl = to.path
      return true
    }

    return false
  }

  /**
   * openBeforeStartRouterClear为true时：
   * @description: 当前路由是否是起始路由的上一个路由，是就清空数据，并返回true
   */
  handleCurrentRouterIsTargetBeforeRouter({ to }) {
    if (!this.openBeforeStartRouterClear) return false

    const getTargetFlag = (arr = []) => {
      return arr.some(item => {
        if (item.name === to.name) {
          if (item.url) {
            const reg = new RegExp(this.langPath + item.url)
            return reg.test(to.path)
          } else {
            return true
          }
        }
        return false
      } )
    }
    const isNotEndNames = getTargetFlag(this.notEndNames)
    if (isNotEndNames) {
      // 此时不结束，并告知外部进行操作, 会让外部onTrigger 去执行end。
      this.notEndNamesTrigger({ to })
      this._flagEnd = true
      return false
    }
    // 判断to.name  to.path和当前配置的结束clearDataNames里面能否匹配上，匹配上就结束
    const isClearNames = getTargetFlag(this.clearDataNames)
    if (isClearNames) {
      return true
    }

    if (this.userRouterActions.length === 0) return false // 没有开始记录
    // 这个case: 首页 =》 预搜索页面  =>  搜素结果页 => 详情页 => 首页 =》 预搜索页面 =》 搜索结果页 =》预搜索页面 => 首页 => 详情页 => 搜索结果页 => 预搜索页面 => 首页 end
    const { to: { name: startToName, path: startFullPath }, from: { name: startFromName,  path: startFromFullPath } } = this.userRouterActions[0]
    const startKey = `${startToName}_${startFullPath}`
    const endKey = `${startFromName}_${startFromFullPath}`
    const startRouterKey = `${startKey}_${endKey}`
    const endRouterKey = `${endKey}_${startKey}`
    // 遍历this.userRouterActions 判断两个开始和结束数量相等说明结束
    const startData = this.userRouterActions.filter(
      item => {
        const { to: { name: toName, path }, from: { name: fromName, path: fromFullPath } } = item
        return `${toName}_${path}_${fromName}_${fromFullPath}` === startRouterKey
      }
    )
    const endData = this.userRouterActions.filter(
      item => {
        const { to: { name: toName, path }, from: { name: fromName, path: fromFullPath } } = item
        return `${toName}_${path}_${fromName}_${fromFullPath}` === endRouterKey
      }
    )
    return startData.length === endData.length
  }

  /**
   * 提前校验是否结束, 外部也可以调用
  */
  exposeCheckEnd({ to, from }) {
    if (!this.isInit || !this.targetNames.length || !this.startState) return
    const isDiffEnd = this.hanldeDiffTargetUrlClear({ to }) // 比对同样路由，url不一样，就要清空数据
    const isChangeEnd = this.clearHandle ? this.clearHandle({ route: { to, from }, clearDiffState: isDiffEnd }) : isDiffEnd
    if (isChangeEnd) {
      this.onEnd()
    }
  }
}

export { RouterAction }
