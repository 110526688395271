import { daEventCenter } from 'public/src/services/eventCenter/index'


const getActTagAppTraceInfo = (data) => {
  return data?.product?.actTag?.appTraceInfo
}
export const getSaGoodsList = (data, { index, cartAbtInfo, scene, el, actionToRefreshRecommend, daMoreInfo = false }) => {
  const goodsId = data.product.goods_id
  const skuId = data.product.goods_sn
  const spuId = data.product.productRelationID
  const page = 1
  const operation = 1
  const recMark = ''
  const price = data.aggregateProductBusiness?.priceData?.unitPrice?.price?.usdAmount
  const originPrice = ['suggestV1', 'suggestV1Special'].includes( data.aggregateProductBusiness?.priceData?.priceMode) ?  data.product?.suggested_sale_price?.usdAmount || '' : data.product?.retailPrice?.usdAmount || ''

  const isImgHigherRightContent = () => {
    if (!el) {
      return false
    }
    const imgEl = el.querySelector('.bsc-cart-item-main__left')
    const rightContentEl = el.querySelector?.('.bsc-cart-item-main__right')
    return imgEl.offsetHeight > rightContentEl.offsetHeight
  }
  const goodsStatus = scene === 'cart' ? data.aggregateProductBusiness?.productRowBiData?.goodsStatus : 'sku_switch_unavailable'

  let cartItemHeight = 0
  let cartItemRow = 0
  let labelInfo = []
  if (daMoreInfo) {
    // 上报商品行高度
    cartItemHeight = el?.offsetHeight || 0

    // 上报标签行数（无法准确计算，产品同意取高度/14大致计算）
    const rightMiddleHeight = el?.querySelector?.('.bsc-cart-item-main__right-middle')?.offsetHeight || 0
    const goodsStoreHeight = el?.querySelector?.('.bsc-cart-item-goods-store')?.offsetHeight || 0
    const goodsAttrHeight = el?.querySelector?.('.bsc-cart-item-goods-sale-attr')?.offsetHeight || 0
    const rightBottomHeight = el?.querySelector?.('.bsc-cart-item-main__right-bottom')?.offsetHeight || 0
    const otherInfoHeight = el?.querySelector?.('.bsc-cart-item-main__other')?.offsetHeight || 0
    cartItemRow = Math.floor((rightMiddleHeight - goodsStoreHeight - goodsAttrHeight + rightBottomHeight + otherInfoHeight) / 20)
    
    // 上报商品行信息
    // 用户行为标签
    const showBehaviorLabels = !!el?.querySelector?.('.bsc-cart-item-behavior-label') || false
    if (showBehaviorLabels) { labelInfo.push('userbehaviortips') }

    const productTagTypes = data.aggregateProductBusiness?.productTags?.map(tag => tag.type) ?? []
    const promotionTagData = data.aggregateProductBusiness?.productTags?.find(tag => tag.type == 'promotion')?.data?.promotion || {}
    if (productTagTypes?.includes('freeShipping')) { labelInfo.push('freeship') }
    if (productTagTypes?.includes('quickShip')) { labelInfo.push('quickship') }
    if (productTagTypes?.includes('evoluShein')) { labelInfo.push('evoluSHEIN') }
    if (productTagTypes?.includes('categoryVoucher')) { labelInfo.push('category_vouche') }
    if (productTagTypes?.includes('payMemberGift')) { labelInfo.push('shein_club_gift') }
    if (productTagTypes?.includes('insurePrice')) { labelInfo.push('insured_goods') }
    if (productTagTypes?.includes('priceReduction') && data.is_checked !== '1') { labelInfo.push('markdown') }
    if (promotionTagData?.typeId == 3) { labelInfo.push('limited_time') }
    if (promotionTagData?.typeId == 32) { labelInfo.push('special_coupon') }
    if (promotionTagData?.typeId == 11) { labelInfo.push('live_flash_sale') }
    if (promotionTagData?.typeId == 31 && promotionTagData?.promotionLogoType == 1) { labelInfo.push('new_user_exclusive') }
    if (promotionTagData?.typeId == 31 && promotionTagData?.promotionLogoType == 3) { labelInfo.push('price_drop') }
    if (promotionTagData?.typeId == 10 && promotionTagData?.flashType == 1) { labelInfo.push('flash_sale') }
    if (promotionTagData?.typeId == 10 && promotionTagData?.flashType == 5) { labelInfo.push('flash_sale_picks') }
    if (promotionTagData?.typeId == 10 && promotionTagData?.flashType == 2) { labelInfo.push('new_user_only') }
    if (promotionTagData?.typeId == 10 && promotionTagData?.flashType == 3) { labelInfo.push('exclusive_sale') }
    if (promotionTagData?.typeId == 10 && promotionTagData?.flashType == 6) { labelInfo.push('brand_sale') }
    if (['1', '9', '15'].includes(promotionTagData?.typeId)) { labelInfo.push('promotion') }
    if (data.aggregateProductBusiness?.isPresent === '1' && !productTagTypes?.includes('payMemberGift')) { labelInfo.push('freegift') }
    if (data.aggregateProductBusiness?.isAddBuy === '1') { labelInfo.push('add_on_items') }

    // 售价
    const isShowPrice = !!el?.querySelector?.('.bsc-cart-item-goods-price__sale-price') || false
    if (isShowPrice) { labelInfo.push('price') }
    // 划线价
    const isShowOriginalPrice = !!el?.querySelector?.('.bsc-cart-item-goods-price__original-price') || false
    if (isShowOriginalPrice) { labelInfo.push('lineprice') }
    // 到手价标签
    const estimatedData = data.aggregateProductBusiness?.estimatedData || {}
    const showEstimatedPrice = !!estimatedData?.style && !!estimatedData?.estimatedTip
    if (showEstimatedPrice) { labelInfo.push('estimateprice') }
    // 折扣百分比
    const discountSubscript = data?.aggregateProductBusiness?.discountSubscript
    if (['2', '3'].includes(discountSubscript?.style)) {
      // 合规站点
      labelInfo.push('discount')
    } else if (showEstimatedPrice && discountSubscript && !!discountSubscript?.style && !!estimatedData.estimatedPrice) {
      // 非合规站点有到手价
      labelInfo.push('discount')
    } else if (!!discountSubscript && !estimatedData.style && !!estimatedData?.estimatedTip) {
      // 非合规站点无到手价
      labelInfo.push('discount')
    }
    // 合规站点30天低价
    const isShowSuggestPrice = !!data.aggregateProductBusiness?.priceData?.lowestPrice
    if (isShowSuggestPrice) { labelInfo.push('30d_price') }

    // 不可退提示
    const hasNegativeInfo = !!el?.querySelector?.('.bsc-cart-item-goods-negative-info') || false
    if (hasNegativeInfo) { labelInfo.push('without_return') }
    // 商品行凑单 todo@echo
    const hasGuideAddOnFloor = !!el?.querySelector?.('.cart-selectAddOnFloor') || false
    if (hasGuideAddOnFloor) { labelInfo.push('add_on_item') }
    // 低库存凑单
    const hasLowsStockTips = !!el?.querySelector?.('.bsc-cart-item-goods-almost-entry__wrap') || false
    if (hasLowsStockTips) { labelInfo.push('lows_stock_tips') }
    // 定制商品
    const isCustomizationProduct = !!el?.querySelector?.('.bsc-cart-item-goods-custom-content') || false
    if (isCustomizationProduct) { labelInfo.push('custom_content_tips') }

    // 可切mall / 可切色块提示
    const { changeMallInfo = {}, changeGoodInfo = {} } = data.aggregateProductBusiness
    const goodsTip = changeMallInfo?.tip || changeGoodInfo?.tip || ''
    if (goodsTip) { labelInfo.push('change_skc') }
    // 附属品提示
    const additionGoodsView = data.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView
    if (!!additionGoodsView?.additionGoodsBottomTip) { labelInfo.push('belong_tips') }
  }

  const validPriceTags = data.aggregateProductBusiness?.priceData?.priceTags?.filter?.(v => v.show == '1') || []
  const otherMark = []
  const actTagAppTraceInfo = getActTagAppTraceInfo(data)
  if (actTagAppTraceInfo) {
    otherMark.push(actTagAppTraceInfo)
  }
  if (data.aggregateProductBusiness?.productImgLabel?.titleImgBiDate) {
    otherMark.push(data.aggregateProductBusiness.productImgLabel.titleImgBiDate)
  }
  if (data.aggregateProductBusiness?.productTags?.find?.(item => item.type == 'payMemberGift' && !item.style)) {
    otherMark.push('show_membergift_tag')
  }
  if (data.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'categoryVoucher') || null) {
    otherMark.push('show_voucher')
  }
  if (data.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'evoluShein') || null) {
    otherMark.push('show_evolushein')
  }
  otherMark.push(isImgHigherRightContent() ? 'goods_length_0' : 'goods_length_1')
  if (data.aggregateProductBusiness?.productRowBiData?.amountDiscounted) {
    otherMark.push(data.aggregateProductBusiness.productRowBiData.amountDiscounted)
  }
  if (actionToRefreshRecommend) {
    otherMark.push('action_to_refresh_recommend')
  }
  if (data.blind_box_flag == 1) {
    otherMark.push('mysterygift')
  }
  if (cartItemHeight) {
    otherMark.push(`high_${cartItemHeight}`)
  }
  if (cartItemRow) {
    otherMark.push(`row_${cartItemRow}`)
  }
  if (labelInfo.length) {
    otherMark.push(`label_info_${labelInfo.join('-')}`)
  }
  if (validPriceTags[0]) {
    otherMark.push(`price_pr_${validPriceTags[0].type}`)
  }

  const attrStock = data.attr.stock
  const insurable = data.product.insurable

  let unuseCouponTips = 'unused_coupon_tip_0'
  if (data?.product?.have_tag == 1) {
    unuseCouponTips = `unused_coupon_tip_${
      cartAbtInfo?.['Cartnoteligibleforcoupons']?.param == 'on'
        ? 1
        : 2
    }`
  }
  const tspId = data.aggregateProductBusiness?.productImgLabel?.titleImgBiData
  const mallCode = data.mall_code
  return `${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|pri_${originPrice}\`${otherMark.join('|')}\`left_${attrStock || ''}${insurable == 1 ? '|insured_1' : ''}|${unuseCouponTips}${tspId ? `|show_icon_${tspId}` : ''}\`mall_${mallCode}\`|${goodsStatus}` // goods_list：商品列表 goods_id`skc`spu_id`坑位`页码`运营位置`流量标识rec_mark`置顶标识extra_mark`pri_在售价格｜pri_原价（美元，保留小数点两位）`其它标识`销售或促销属性`mall_mallcode`列表服务算法额外标识
}

export const expose_goods_list = (data, callback) => {
  const defaultData = {
    scene: 'cart',
    goods_list: '',
    label_id: 'all',
    traceid: '',
    abtest: '-',
    activity_from: 'goods_list',
    style: 'detail', // 点击商品跳转到详情页上报 detail，点击商品跳转打开弹窗上报 popup，不允许点击的商品暂时上报: detail(等产品确认) 
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-4', extraData: callback?.(defaultData) ?? defaultData })
}


export const click_goods_list = (data, callback) => {
  const defaultData = {
    item: data,
    index: '',
    tspId: data.aggregateProductBusiness?.productImgLabel?.titleImgBiData || '',
    behaviorText: getActTagAppTraceInfo(data),
    goodsNameBeforeLabelId: data.aggregateProductBusiness?.productImgLabel?.titleImgBiData || '',
    scene: 'cart',
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-3', extraData: callback?.(defaultData) ?? defaultData })
}
