import schttp from 'public/src/services/schttp'

// import { mock_buyer_show_recommend_data, noresultRelatedWord } from '../../../../product_list_v2/js/mock'
import { getCccCateInfo } from '../../../../common/ccc_public_v2'
import { getCccRecommendFlowCateAndGoods, getRankingListProducts } from 'public/src/services/api/ccc.js'
import { CountDown, getQueryString, parseQueryString } from '@shein/common-function'
import { getSearchDiff, isShowSearchFeed, partitionCellSearchWords, shouldRefreshPicTopNav } from './utils'
import { getCateResult } from 'public/src/pages/components/filter_new/common/format'

import { productListCoupons } from './actionCoupon.js'
import {
  getRankListComponentBff,
  getSurveyComponentBff,
  getDiscountChannelCompBff,
  getCccCommonComponentBff,
} from './action_flow.js'

import { getFlowMap } from 'public/src/pages/product_list_v2/components/flow/flowLogic.js'
import  { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { selectionAndEntityPicNavApiCache } from 'public/src/pre_requests/modules/productList/api/bffApi.js'
import { getPicNavParams } from 'public/src/pre_requests/modules/productList/bffParamsHandle/common.js'
import { picTopNavAdapter } from 'public/src/pages/components/FilterBar/adapter/searchFilterAdapter.js'

const actions = {
  dealingNewData({ commit, getters }, payload) {
    commit('dealingPageInfos', payload)
    commit('initProductList', getters)
    commit('dealingGoods', payload)
    commit('dealingTrackings', payload) // goods
    commit('dealingFlowDataV2', payload)

    if (payload.cat_info.requestType !== 'nextpage') {
      commit('dealingOriginFilters', payload)
      commit('dealingAttributes', payload)
      commit('dealingCloudTags', payload)
      commit('dealingFilterTsps', payload)
      commit('dealingKidsTag', payload)
      // 一键购标签
      commit('dealingBannerTag', payload)
    }
  },

  // 初始化接口和filter接口
  initStateOrFilterDataNew({ commit, getters }, payload) {
    commit('dealingInitOrFilterInfoNew', payload)
    if (payload.cat_info.requestType !== 'nextpage') {
      commit('dealingOriginFilters', payload)
      commit('dealingAttributes', payload)
      commit('dealingCloudTags', payload)
      commit('dealingFilterTsps', payload)
      commit('dealingKidsTag', payload)
      // 一键购标签云
      commit('dealingBannerTag', payload)
    }

    commit('initPage', { ...getters, searchTraceId: payload.searchTraceId // 搜索场景需要记录每次主接口traceId
    })
    commit('initQueryParams', getters)
    commit('initFilterBar', getters)
    if (getters.catInfo?.search_type !== 'store') {
      commit('initSearchCCCCard', getters)
      commit('initListBottomInfo', getters)
      commit('initSearchFeedback', getters)
    }
  },
  // 初始化商品数据， 必须initStateOrFilterData之后调用
  initProductDataNew({ commit, getters }, payload) {
    const { cat_info } = payload
    commit('dealingGoodsItemInfoNew', payload)
    commit('initProductList', getters)
    commit('dealingGoods', {
      ...payload,
      cat_info: cat_info || getters.catInfo,
      promotionInfoFromServer: payload.promotionInfoFromServer || getters.promotionInfoFromServer,
    })
    commit('dealingTrackings', {
      ...payload,
      cat_info: cat_info || getters.catInfo,
      fhContext: payload.fhContext || getters.fhContext,
      googleContext: payload.googleContext || getters.googleContext,
      tracking: payload.tracking || getters.tracking,
      request_ext: payload.request_ext || getters.request_ext,
      goodsCrowId: payload.goodsCrowId || getters.goodsCrowId,
    })
    commit('dealingFlowDataV2', payload) // 依赖init接口数据

    commit('initNoResult', getters)
    commit('initFilterAbt', getters)
    if (getters.catInfo?.search_type !== 'store') {
      commit('initListTopInfo', getters)
      commit('initBrandBanner', getters)
      commit('initBgBanner', getters)
    }
    if (cat_info.requestType === 'firstload') {
      commit('initSearchBannerCard', payload)
    }
    if (cat_info.requestType === 'firstload' || cat_info.requestType === 'switchTab') {
      commit('initBreadcrumbs', payload)
    }
  },

  initState({ getters, commit }, payload) {
    commit('initPage', {
      ...getters,
      searchTraceId: payload.searchTraceId // 搜索场景需要记录每次主接口traceId
    })
    commit('initQueryParams', getters)
    commit('initFilterBar', getters)
    commit('initNoResult', getters) // goodsApi
    commit('initFilterAbt', getters)

    // 店铺搜索除了筛选和无结果和商品，其他板块都隐藏
    if (getters.catInfo?.search_type !== 'store') {
      commit('initSearchCCCCard', getters)
      commit('initListTopInfo', getters) // goodsApi
      commit('initListBottomInfo', getters)
      commit('initSearchFeedback', getters)
      commit('initBrandBanner', getters) // goodsApi
      commit('initBgBanner', getters)
    }
  },
  async fetchComponentAsyncData({ getters: { catInfo }, dispatch }) {
    // dispatch('fetchAtomicInfo')
    const dispatchList = [
      dispatch('fetchSearchRelatedData'),
      dispatch('fetchSearchCCCCardExtraData')
    ]

    // 店铺搜索不请求flowData相关
    if (catInfo?.search_type !== 'store') {
      dispatchList.push(dispatch('fetchFlowData'))
    }

    await Promise.all(dispatchList)
  },
  // 商品原子信息
  async fetchAtomicInfo({ state: { ComponentState }, getters: { atomicParams, cloudTags, picTopNav, catInfo, appendGoods, mallInfo, promotionInfoFromServer, listAbtResult: { NewPosition, LocFilterUpdate, listReview } } }) {
    if (!appendGoods.length) return
    const { config, goodsItemInfoServ } = ComponentState.ProductList

    await goodsItemInfoServ.getProductInfo({
      requestFields: atomicParams.fields,
      goods: appendGoods,
      itemConfig: config,
      pageKey: catInfo.type === 'search' ? getSearchDiff(catInfo).goodsItemInfoPageKey : catInfo.pageName,
      cccParams: atomicParams.cccParams,
      picTopNav,
      cloudTags,
      filterWords: typeof window === 'undefined' ? [] : getQueryString({ key: 'filterWords' })?.split(','),
      mallInfoForSite: mallInfo,
      // 是否只对已有的原子数据进行转换处理, 不请求接口
      // 对于泛列表场景来说, 原子数据已经跟随列表接口一起返回, 不需要再请求接口了
      onlyDealData: true,
      listAbtInfo: { NewPosition, listReview },
      promotionInfoFromServer,
      NoSellingPoint: LocFilterUpdate?.param?.NoSellingPoint === 'new',
      SameFilter: LocFilterUpdate?.param?.SameFilter === 'yes',
    })
  },

  getFlowMap( { state: { ComponentState, PageState }, getters: { goods, catInfo, isFollowed = false } }) {

    // @tips 不能使用 (page-1)*limit 计算startIndex，因为limit可以是10，也可以是20
    const startIndex = Number(catInfo.page) === 1 ? 0 : goods.length
    // const endIndex = catInfo.page === 1 ? catInfo.limit : goods.length + catInfo.limit

    // @tips 点刷需求 会导致 goods 数量删除再添加，导致 endIndex 跳过了插坑的位置
    const endIndex = goods.length + Number(catInfo.limit)

    const cateCoupon = ComponentState.ProductList.cateCoupon
    const FlowData = ComponentState.ProductList.FlowData
    const flowDataV2 = ComponentState.ProductList.flowDataV2

    const result = getFlowMap({
      startIndex,
      endIndex,
      flowData: FlowData,
      flowDataV2: flowDataV2,
      PageState: PageState || {},
      catInfo: catInfo,
      cateCoupon: cateCoupon,
      isFollowed: isFollowed,
    })
    // console.log(result, 'resultresultresultresult');
    Object.keys(result ?? {}).forEach((key) => {
      Vue.set(ComponentState.ProductList.flowMap, key, result[key])
    })
  },

  // 流式数据 (归并渲染)
  async fetchFlowData({ state: { ComponentState, }, dispatch, getters: { catInfo } }) {
    if (typeof window === 'undefined') {
      return
    }

    if (catInfo.requestType !== 'firstload') {

      // 翻页请求
      const [realCateRes] = await Promise.all([
        dispatch('_getFlowRealCate'),
        dispatch('getRankListComponentBff'),
      ])
      if (realCateRes?.FlowRealCate) {
        ComponentState.ProductList.FlowData.FlowRealCate = realCateRes.FlowRealCate
      }

      dispatch('getFlowMap')

      return
    }

    // 第一次请求
    const FlowData = await Promise.all([
      dispatch('getCccCommonComponentBff'),
      dispatch('getRankListComponentBff'),
      dispatch('getSurveyComponentBff'),
      dispatch('getDiscountChannelCompBff'),
      // 买家秀
      dispatch('_getFlowBuyerShowData'),
      // 搜索流
      dispatch('_getFlowSearchData'),
      // 类目推荐
      dispatch('_getFlowRealCate'),
      // 锦囊搜索
      dispatch('_getCellSearchData'),
      // 榜单商品
      // dispatch('_getFlowRankingListData'),
      // 列表券(出现多个地方：头部 底部 坑位 直接弹窗)
      dispatch('productListCoupons')
    ])
    ComponentState.ProductList.FlowData = {

      ...ComponentState.ProductList.FlowData,
      ...FlowData.reduce((curr, next) => {
        // 列表券需要特殊处理，不加入FlowData中
        if (next?.Key === 'productListCoupons') {
          return curr
        } else {
          return ({ ...curr, ...next })
        }
      }, {})
    }

    dispatch('getFlowMap')
  },

  getRankListComponentBff,
  getSurveyComponentBff,
  getDiscountChannelCompBff,
  getCccCommonComponentBff,

  // 广告流
  async _getFlowAdData({ getters: { listAbtResult: { Aod }, cccOperation: { mobile_list_flow }, cccxConfig: { flow_list_zone }, catInfo } }) {
    if (catInfo.requestType !== 'firstload') return

    const flowAdData = {}
    // cccx
    if (flow_list_zone?.content) {
      flowAdData.cccxFlowAd = {
        context: flow_list_zone,
        data: flow_list_zone.content.reduce((curr, next) => {
          curr[next.placeHolderPosition - 1] = next
          return curr
        }, {}),
      }
    }

    // 老ccc
    if (mobile_list_flow?.content) {
      flowAdData.FlowAd = {
        Data: mobile_list_flow.content.reduce((curr, next) => {
          curr[next.content.props.items[0].position - 1] = next
          return curr
        }, {}),
        CommonData: {
          context: mobile_list_flow,
          cccCateInfo: await getCccCateInfo({
            cccData: [{
              operations: mobile_list_flow.content
            }]
          }),
        },
        Abt: { Aod },
      }
    }

    return flowAdData
  },
  // 买家秀
  async _getFlowBuyerShowData({ getters: { language, cccOperation: { mobile_buyer_show }, listAbtResult: { Featuredreviews, FeaturedreviewsRecommend }, catInfo } }) {
    if (!mobile_buyer_show || catInfo.requestType !== 'firstload') return
    const list_id = catInfo.entity_id || catInfo.select_id

    const result = await schttp({
      method: 'POST',
      url: '/api/buyer/listData/get',
      data: {
        page: 1,
        pageSize: mobile_buyer_show.minCommentNum,
        cateId: list_id,
        ruleId: FeaturedreviewsRecommend.p.replace(/.*rule_id=|&.*/g, ''),
      }
    })
    if (!result?.data?.length || result.data.length < mobile_buyer_show.minCommentNum || !result.data[0].commentInfo?.comment_image?.[0]?.member_image_middle) return

    return {
      FlowBuyerShow: {
        Data: {
          [mobile_buyer_show.position - 1]: result.data[0].commentInfo, // (mock_buyer_show_recommend_data[0] || result.data[0]).commentInfo,
        },
        CommonData: {
          titleLang: language.SHEIN_KEY_PWA_18352,
          list_id,
        },
        Abt: { Featuredreviews, FeaturedreviewsRecommend }
      }
    }
  },
  // 搜索流
  async _getFlowSearchData({ getters: { language, cccOperation: { mobile_guided_search }, listAbtResult: { SearchFeedHotword, RelatedSearchNew }, catInfo, searchKeywords, isTopTrendResultPage } }) {

    // v.趋势搜索结果页屏蔽该功能
    if (isTopTrendResultPage) return

    let mobile_guided_search_data = mobile_guided_search?.SearchFeedHotword

    // abt控制搜了还搜位置 优先级 > CCC
    if (RelatedSearchNew?.p?.RelatedSearchLoc) {
      const { RelatedSearchLoc } = RelatedSearchNew.p
      mobile_guided_search_data = RelatedSearchLoc.split('&')
    }

    if (!isShowSearchFeed({
      RelatedSearchNewParams: RelatedSearchNew?.p,
      SearchFeedHotwordParams: SearchFeedHotword?.p,
      mobileGuidedSearch: mobile_guided_search_data,
      requestType: catInfo.requestType
    })) return

    let hotwords = []
    // 搜了还搜
    let { info, pic: hasPic } = await getQuery2QueryWords({
      rule_id: RelatedSearchNew?.p?.RelatedSearchPicandStr,
      keyword: searchKeywords.keywords,
    })
    if (hasPic) {
      // 过滤无图的词，补位下一个词语
      info = info.filter(({ thumbnail }) => !!thumbnail)
    }
    hotwords = info

    if (!hotwords?.length || hotwords.length < 2) return

    const isWaterfall = true

    // 非瀑布流 少于4个不展示；瀑布流 少于2个不展示
    const sliceNum = isWaterfall ? Math.ceil(hotwords.length / 4) : Math.floor(hotwords.length / 4)

    return {
      FlowSearch: {
        Data: getSearchDiff(catInfo)?.hideRelatedSearch ? {} : mobile_guided_search_data
          .slice(0, sliceNum) // [搜了还搜]少于 4 个不展示; 单列; 不区分是否瀑布流
          .reduce((curr, next) => {
            const currData = hotwords.splice(0, 4)
            if (currData.length >= 2) curr[next - 1] = currData
            return curr
          }, {}),
        CommonData: {
          searchLang: language.SHEIN_KEY_PWA_16836,
        },
        Abt: {
          SearchFeedHotword: RelatedSearchNew,
        },
      }
    }
  },
  // 锦囊搜索
  async _getCellSearchData({ rootState: { searchFilterWords }, getters: { language, cccOperation: { mobile_guided_search }, listAbtResult: { PwaCellSearchHot = '', CellSearchHotMore }, catInfo, searchKeywords } }) { // 下掉PwaCellSearchHot
    const abtPwaCellSearchHot = parseQueryString(PwaCellSearchHot?.p?.PwaCellSearchHot) // 下掉PwaCellSearchHot
    const abtCellSearchHotMore = {
      type: CellSearchHotMore?.p?.CellSearchHotMore || 'one'
    }

    // abt 控制是否请求锦囊搜索数据
    if (abtPwaCellSearchHot.type !== 'B' || catInfo.requestType !== 'firstload') {
      return
    }

    /// ccc 控制锦囊搜索展示在哪个坑位, 如果 ccc 没有配置坑位, 则不展示锦囊搜索
    const isDev = process.env.NODE_ENV === 'development'
    let mobile_guided_search_data
    if (isDev) {
      mobile_guided_search_data = [1, 3, 5]
    } else {
      mobile_guided_search_data = mobile_guided_search?.SelectionFeedFilter
    }
    if (!mobile_guided_search_data?.length) {
      return
    }

    // 如果点击锦囊词达到 4 次, 则不再做锦囊搜索, 就是如果出现了 5 个tag，就屏蔽锦囊; 注意第一个词是主搜词
    if (searchFilterWords.length >= 4) {
      return
    }

    // 调接口按搜索词获取锦囊搜索属性词
    const cellWords = await getCellSearchWords({
      versionType: abtPwaCellSearchHot.version_id,
      keywords: searchKeywords.keywords,
      // 用户点击锦囊词后, 下次搜索锦囊词的时候, 需要将其所属的属性项都过滤掉, 传参格式: 属性项id_属性值id-属性项id_属性值id...
      filter: searchFilterWords.map(item => item.attr_id + '_' + item.attr_value_id).join('-'),
    })

    if (!cellWords?.length) {
      return
    }

    // 是否开启了瀑布流
    const isWaterfall = true
    // 锦囊搜索是否是双列结构
    const isCellSearchTwoColumn = abtCellSearchHotMore.type === 'more'
    let Data = {}
    const DataFor4 = partitionCellSearchWords(mobile_guided_search_data, cellWords, 4, 4)
    const DataFor8 = partitionCellSearchWords(mobile_guided_search_data, cellWords, 8, 8)
    const DataFor4To8 = partitionCellSearchWords(mobile_guided_search_data, cellWords, 4, 8)
    if (isWaterfall) {
      if (isCellSearchTwoColumn) {
        // 瀑布流, 双列: 最少 4 个, 最多 8 个, 只展示偶数个, 如果是奇数个则丢弃最后一个
        Data = DataFor4To8
      } else {
        // 瀑布流, 单列: 只展示 4 个
        Data = DataFor4
      }
    } else {
      if (isCellSearchTwoColumn) {
        // 非瀑布流, 双列: 只展示 8 个
        Data = DataFor8
      } else {
        // 非瀑布流, 单列: 只展示 4 个
        Data = DataFor4
      }
    }
    // 品牌搜索锦囊搜索逻辑
    if (getSearchDiff(catInfo)?.hideFlowCellSearch) {
      Data = {}
    }
    // 将前端需要用到的数据拼接在一起返回
    return {
      FlowCellSearch: {
        Data,
        CommonData: {
          searchLang: language.SHEIN_KEY_PWA_23805,
        },
        Abt: { PwaCellSearchHot, CellSearchHotMore },
      }
    }
  },
  // 类目推荐
  async _getFlowRealCate({
    state: { ComponentState },
    getters: {
      listAbtResult: { Listcategoryscreening },
      cccConfig,
      catInfo,
      filterCates,
      sum
    },
    dispatch
  }) {
    //
    // 必须条件： 包含 category 筛选 瀑布流
    // 不展示：非瀑布流  category是最后一级（没有子级），图文导航，有排序条件，其他筛选，属于列表的最后一个item
    // multi_cat_ids

    // mock
    // const ListLoadmore = {
    //   p: 'type=waterfall'
    // }
    // const Listcategoryscreening = {
    //   p: {
    //     ListcategoryScreening: 'Default'
    //   }
    // }

    const page = catInfo.page ?? 1
    // 没有则初始化，有直接取
    let FlowRealCate = page == 1 ? {
      Data: {},
      Abt: { Listcategoryscreening },
      noMore: false   // 是否没有更多数据
    } : ComponentState.ProductList.FlowData.FlowRealCate

    // 有以下相关筛选不展示
    const disabledKey = ['attr_values', 'tsp_ids', 'tag_ids', 'min_price', 'max_price', 'sort', 'attr_ids']
    const isDisabled = disabledKey.find(v => catInfo[v])

    const abtRes = Listcategoryscreening?.p?.ListcategoryScreening

    // 没有子类目（代表是末级）的不展示
    let noChildCate = !filterCates?.length

    if (catInfo.child_cat_id) {
      const { filterData } = getCateResult({ curValue: String(catInfo.child_cat_id), childNodeList: filterCates }) || {}
      if (!filterData?.length) {
        noChildCate = true
      }
    }

    if (catInfo.pic_nav_id) {
      if (catInfo.pic_nav_type != 1) {
        noChildCate = true
      } else {
        const { filterData } = getCateResult({ curValue: String(catInfo.pic_nav_id), childNodeList: filterCates }) || {}
        if (!filterData?.length) {
          noChildCate = true
        }

      }
    }

    const { flowCategoryCCC } = cccConfig

    if (!flowCategoryCCC?.data || !abtRes || noChildCate || isDisabled || FlowRealCate.noMore) return { FlowRealCate }

    const cccCategoryData = flowCategoryCCC.data

    // 取出命中当前页的坑位，并行请求
    const limit = catInfo.limit ?? 20
    const minIndex = (page - 1) * limit
    let assemblyCccCategory = cccCategoryData.filter((item) => {
      const insertIndex = item.insertIndex
      return insertIndex >= minIndex && insertIndex < minIndex + limit && insertIndex < sum
    })

    if (assemblyCccCategory.length === 0) return { FlowRealCate }

    // 需要将展示过的类目id传给后端，缩小推荐范围
    let expose_cate_id = []

    if (page > 1) {
      const FlowRealCateData = FlowRealCate?.Data ?? {}

      const indexKeys = Object.keys(FlowRealCateData)


      indexKeys.map((key) => {
        FlowRealCateData[key].list.map(v => {
          expose_cate_id.push(v.cate_id)
        })
      })
    }

    // 已返回的cate_id, 多次并发返回的数据可能会有相同用于最后插入前去重
    const shownCateIds = []

    // 一次返回一个坑位的推荐数据
    const fetchList = await Promise.all(assemblyCccCategory.map(() => {
      return dispatch('fetchFlowRealCate', { expose_cate_id: expose_cate_id.join(',') })
    }))

    fetchList.forEach((item, index) => {
      // 去重后的数据
      const afterRemovingDuplicatesList = item?.filter((cateItem) => {
        return !shownCateIds.includes(cateItem.cate_id)
      }) ?? []

      if (afterRemovingDuplicatesList.length <= 2) {
        FlowRealCate.noMore = true
      }

      if (afterRemovingDuplicatesList.length >= 2) {
        const cccCategoryItem = assemblyCccCategory[index]

        // 最多展示4个
        const pushData = afterRemovingDuplicatesList.slice(0, 4).map((v) => {
          v.src_identifier = `${cccCategoryItem.insertIndex}\`${v.goods_id}\`real_${v.cate_id}`
          return v
        })
        FlowRealCate.Data[cccCategoryItem.insertIndex - 1] = Object.assign(cccCategoryItem, {
          list: pushData,
        })

        shownCateIds.push(...pushData.map(v => v.cate_id))
      }
    })

    return {
      FlowRealCate
    }
  },

  // 搜索无结果页热词
  async fetchSearchRelatedData({ state: { ComponentState }, getters: { locals: { IS_RW }, language, listAbtResult: { SearchNoResultFeedHotword, RelatedSearchNew }, searchKeywords, goods, appendGoods } }) {
    if (IS_RW || !SearchNoResultFeedHotword?.p?.includes('type_id=') || goods.length || appendGoods.length) return

    // 搜了还搜
    let { info: hotwords, pic: hasPic } = await getQuery2QueryWords({
      rule_id: RelatedSearchNew?.p?.RelatedSearchPicandStr,
      keyword: searchKeywords.keywords,
    })
    if (hasPic) {
      // 过滤无图的词，补位下一个词语
      hotwords = hotwords.filter(({ thumbnail }) => !!thumbnail)
    }
    if (!hotwords?.length || hotwords.length < 2) return

    ComponentState.SearchRelated = {
      hotwords,
      abtInfo: SearchNoResultFeedHotword,
      relatedLang: language.SHEIN_KEY_PWA_16836
    }
  },
  // 非商品搜索banner其他数据
  async fetchSearchCCCCardExtraData({ getters: { catInfo }, state: { ComponentState } }) {
    if (ComponentState.searchCCCCard && catInfo.requestType == 'firstload') {
      const {
        cateLinks,
        hrefType,
        hrefTarget,
        relativeUrl,
        adp,
        isInternalLink,
        mallCodes,
        categoryIds,
        additionalConfig,
        wordType,
        cardStyleType,
        flashStartTimeStamp: BffFlashStartTimeStamp,
        flashFinishTimeStamp: BffFlashFinishTimeStamp,
        flashSaleEndTime,
        isBff = false,
        clickUrl: bffClickUrl
      } = ComponentState.searchCCCCard || {}
      const isFlashSaleType = isBff ? cardStyleType == 3 : wordType === 3
      let countDown

      // 闪购倒计时
      if (isFlashSaleType) {
        if (catInfo?.type === 'search' && hrefType !== 'flashSale') {
          return ComponentState.searchCCCCard = null
        }
        let startTime = '', endTime = ''
        if (isBff) {
          startTime = BffFlashStartTimeStamp
          endTime = BffFlashFinishTimeStamp
        } else {
          const { zzz } = await schttp({ url: '/api/common/servicesTime/get' })
          startTime = zzz
          endTime = flashSaleEndTime
        }

        const countTimer = new CountDown()
        countDown = countTimer.timeObj
        countTimer.start({ seconds: endTime - startTime })
      }
      let url = '/'
      const cccLink = new CccLink({
        sceneData: {},
        propData: {}
      })
      url = cccLink.getFullLink({
        item: {
          hrefType,
          hrefTarget,
          relativeUrl,
          adp,
          isInternalLink,
          mallCodes,
          categoryIds,
          additionalConfig,
          webClickUrl: bffClickUrl
        },
        cateLinks: cateLinks,
        isBff
      })

      /* eslint-disable */
      ComponentState.searchCCCCard = {
        ...ComponentState.searchCCCCard,
        countDown,
        cccLink: url
      }
      /* eslint-enable */
    }
  },
  async fetchFlowRealCate({
    state: { Results },
    getters: {
      catInfo
    }
  }, { expose_cate_id }) {
    // parentCats?.url_cat_id 祖先级类目id

    const scUrlId = catInfo.select_id || ''

    // 搜索页取值
    const currentId = catInfo.type === 'search' ? Results?.filterAbtParam?.currentId : ''

    const cate_id = currentId || catInfo.cat_id || ''

    let filter_cate_id = catInfo.child_cat_id || ''

    if (catInfo.pic_nav_id && catInfo.pic_nav_type == 1) {
      filter_cate_id = catInfo.pic_nav_id
    }

    const params = {
      req_num: 10,
      limit: 10,
      scene: 'productListGoodsCategoryFlow',
      type: 'personalized',
      scene_id: 157,
      scUrlId: scUrlId,
      contextParams: {
        filter_cate_id,
        cate_id: cate_id ?? '',
        expose_cate_id
      }
    }

    const recommendRes = await getCccRecommendFlowCateAndGoods(params)

    return recommendRes?.info?.products ?? []
  },

  async fetchFlowRankingListData({
    getters: {
    },
  }, payload) {
    const { exposed_goods_id, cate_ids } = payload

    const params = {
      exposed_goods_id,
      cate_ids
    }

    const results = await getRankingListProducts(params)
    return results
  },

  productListCoupons,
  setDialogShowStatus({ commit }, payload){
    commit('updateDialogShowStatus', payload)
  },

  /**
   * 根据商详页返回的goodsId，重新更新图文导航
   * @param {*} param0
   */
  async refreshPicNavByGoodsId({ commit }, payload) {
    const picNavParams = getPicNavParams(payload.requestInfo, payload.type)
    if (payload.tab_id) {
      picNavParams.tab_id = payload.tab_id
    }
    const data = await selectionAndEntityPicNavApiCache.request({
      ...picNavParams,
      clickGoodsId: payload.goodsId,
      _requestType: 'firstload',
    })
    const picNav = picTopNavAdapter(data?.info || {})

    if (picNav.picTopNav && picNav.picTopNav.length && shouldRefreshPicTopNav(picNav.picTopNav, payload.routeQuery?.pic_nav_id)) {
      commit('updatePicTopNav', picNav.picTopNav)
    }
  }
}

/**
 * 获取锦囊搜索数据
 * @description wiki.pageId=852564457
 * @param {String} keyword 搜索关键词
 */
async function getCellSearchWords({ keywords, versionType, filter }) {
  if (!keywords) {
    return []
  }

  let data = await schttp({
    url: '/api/searchwords/searchQueryAttrs/get',
    params: {
      keywords,
      version_type: versionType,
      // filter,
    }
  })
  if (process.env.NODE_ENV === 'development') {
    data = [
      {
        'attr_id': '92',
        'attr_name': 'Sleeve Type',
        'attr_values': [
          {
            'attr_value_id': '892',
            'attr_value': 'Regular Sleeve'
          },
          {
            'attr_value_id': '100',
            'attr_value': 'Batwing Sleeve'
          },
          {
            'attr_value_id': '557',
            'attr_value': 'Roll Up Sleeve'
          },
          {
            'attr_value_id': '304',
            'attr_value': 'Flounce Sleeve'
          },
          {
            'attr_value_id': '541',
            'attr_value': 'Raglan Sleeve'
          }
        ]
      },
      {
        'attr_id': '66',
        'attr_name': 'Neckline',
        'attr_values': [
          {
            'attr_value_id': '8922',
            'attr_value': 'Regular Sleeve2'
          },
          {
            'attr_value_id': '89224',
            'attr_value': 'Regular Sleeve25'
          },
          {
            'attr_value_id': '89564',
            'attr_value': 'Regve25'
          },
          {
            'attr_value_id': '894456424',
            'attr_value': 'asdfe'
          },
          {
            'attr_value_id': '896456224',
            'attr_value': 'dfghdfghesger'
          },
          {
            'attr_value_id': '8923453424',
            'attr_value': 'gesrgersgresg'
          },
          {
            'attr_value_id': '89222342344',
            'attr_value': 'Rfgdhfghe25'
          },
          {
            'attr_value_id': '89234534524',
            'attr_value': 'Regiulyuikve25'
          },
          {
            'attr_value_id': '8925345624',
            'attr_value': 'Recxbgunvbcncvbeeve25'
          },
        ]
      },
      {
        'attr_id': '168',
        'attr_name': 'Top Type',
        'attr_values': [
          {
            'attr_value_id': '89222',
            'attr_value': 'Regular Sleeve24'
          },
          {
            'attr_value_id': '892246',
            'attr_value': 'Regular Sleeve253'
          },
          {
            'attr_value_id': '89224',
            'attr_value': 'Regular Sleeve25'
          },
          {
            'attr_value_id': '89276575624',
            'attr_value': 'mnbmbnve25'
          },
          {
            'attr_value_id': '8922784',
            'attr_value': 'hjkiukeeve25'
          },
          {
            'attr_value_id': '8922764',
            'attr_value': 'iupioueve25'
          },
          {
            'attr_value_id': '89264524',
            'attr_value': 'tyrtyeve25'
          },
          {
            'attr_value_id': '89234224',
            'attr_value': 'Regular Sleeve25'
          },
          {
            'attr_value_id': '89233332124',
            'attr_value': 'R23ehfgheve25'
          },
        ]
      }
    ]
  }
  const MAX_CELL_WORD_LENGTH = 16
  // 把属性 id 和属性名称混入到每个属性值对象里, 方便后面使用
  for (const datum of data) {
    for (const datumElement of datum.attr_values) {
      datumElement.attr_id = datum.attr_id
      datumElement.attr_name = datum.attr_name
    }
  }
  // 过滤掉已经点击过的属性词
  const filteredAttrIds = filter.split('-').map(item => item.split('_')[0])
  const allAttrValues = data
    .filter(group => !filteredAttrIds.includes(group.attr_id))
    .map(group => ({
      ...group,
      attr_values: group.attr_values.filter(item => item.attr_value.length <= MAX_CELL_WORD_LENGTH)
    }))
    .filter(group => group.attr_values.length >= 4)
    .map(group => group.attr_values)
  // .reduce((acc, attrValue) => [...acc, ...attrValue], [])
  // .filter(item => item.attr_value.length <= MAX_CELL_WORD_LENGTH)
  // .filter(item => !filteredAttrIds.includes(item.attr_id))

  return allAttrValues
}


/**
 * @description 搜索流词数据新接口 搜了还搜
 */
async function getQuery2QueryWords({ keyword, rule_id }) {
  const defaultResult = { info: [], pic: false }
  if (!keyword || rule_id === '') return defaultResult
  const result = await schttp({
    url: '/api/searchwords/relatedWrods/get',
    params: {
      keyword,
      rule_id,
    }
  })

  return result?.data ?? defaultResult


}



export default actions
