var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('s-button',{class:[
    _setup.prefixCls('normal-btn'),
    _setup.benefitCarousel.visible && _setup.prefixCls('normal-btn_benefit')
  ],style:({ border: 'none', backgroundColor: _setup.btnContent.backgroundColor }),attrs:{"width":"100%","type":['primary', 'H80PX'],"loading":_setup.createOrderLoading}},[(_setup.btnContent.visible)?_c('div',{class:[
      _setup.prefixCls('normal-btn_content'),
      _setup.btnContent.cls, // 横竖布局类型
      _setup.btnContent.horizontal?.reverse // 反向
    ]},[(!!_setup.btnContent?.buttonText)?_c('div',{class:_setup.prefixCls('normal-btn_content-text'),domProps:{"innerHTML":_vm._s(_setup.containerText)}}):_vm._e(),_vm._v(" "),(!!_setup.btnContent.logo)?_c(_setup.CommonImage,{class:_setup.prefixCls('normal-btn_content-logo'),attrs:{"imgUrl":_setup.btnContent.logo,"imgDesignWidth":45,"isFirstPage":""}}):_vm._e(),_vm._v(" "),(!!_setup.btnContent.vertical?.text)?_c('div',{class:_setup.prefixCls('normal-btn_content-sub-text')},[_vm._v("\n      "+_vm._s(_setup.btnContent.vertical.text)+"\n    ")]):_vm._e()],1):_c('div',{class:[_setup.prefixCls('normal-btn_content')]},[_c(_setup.SAdapterText,{attrs:{"min-size":"10","text":_setup.defaultText}})],1),_vm._v(" "),(_setup.benefitCarousel.visible)?_c(_setup.CarouselSwiper,{directives:[{name:"expose",rawName:"v-expose",value:({ id: 'expose_inner_benefits:simple' }),expression:"{ id: 'expose_inner_benefits:simple' }"}],attrs:{"config":_setup.benefitCarousel.data,"autoplayDelay":3000}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }