import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { callHook } from '../components/FooterBar/utils'

class Monitoring {
  constructor(callbacks: DS_QuickAddCartStandard.CallBacks | undefined) {
    this.instance = null
		this.callbacks = callbacks || {}
  }

	/**
	 * 监控回调
	 */
	onMonitoringTrigger = (monitorInfo: object) => {
		if (!monitorInfo?.type) return
    if (!monitorInfo?.time) {
      monitorInfo.time = new Date().getTime()
    }
		callHook(this.callbacks, 'onMonitoringTrigger', monitorInfo)
	}

  /**
   * 获取监控方法
   */
  static getInstance =(callbacks?: DS_QuickAddCartStandard.CallBacks | undefined) => {
    if (!this.instance) {
      this.instance = new Monitoring(callbacks)
    }
    return this.instance
  }
}

export default Monitoring.getInstance
