import UserInfoManager from 'public/src/services/UserInfoManager'
import { getLocalStorage, removeLocalStorage } from '@shein/common-function'
import { clickSwitchAccount } from '@login/utils/loginAnalysis/multiAccount.js'
import { removeServiceWorkerCache } from '@login/utils/clearSwCache.js'
import { USERINFO_KEY } from '@login/common/contants.js'
import { logoutPersonalSetting, loginPersonalSetting } from 'public/src/services/personalzed/index.js'

export const setAuthUserInfo = (data) => {
  const member = data.info?.member || {}
  const ugid = data.info?.ugid || ''
  removeServiceWorkerCache()
  UserInfoManager.set({ key: 'UserInfo', value: member, actionType: 'setAuthUserInfo' })
  UserInfoManager.set({ key: 'ugid', value: ugid, actionType: 'setUgidUserInfo' })
  loginPersonalSetting() // 登录成功，个性化推荐配置设置
  window.isLoginSuccessRefresh = true
}

export const removeAuthUserInfo = () => {
  try {
    if (getLocalStorage(USERINFO_KEY)) {
      removeLocalStorage(USERINFO_KEY)
    }
    // 清空ugid, 防止二次落地获取的数据为登录的数据
    UserInfoManager.clearStorage({ key: 'ugid' })
    // 退出成功，个性化推荐配置设置
    logoutPersonalSetting()
  } catch (error) {
    console.error( error + 'removeAuthUserInfo error')
  }
}

export const handleSwitchAccount = () => {
  clickSwitchAccount()
  SHEIN_LOGIN && SHEIN_LOGIN.show({
    show: true,
    bi: 'switch_account',
    isSwitchAccount: true
  })
  if (isIphoneVersion()) {
    setTimeout(() => {
      window.scrollBy(0, -1)
    }, 0)
  }
}

// 判断是否是iphone 16 pro 并且是18.1.1版本
export const isIphoneVersion = () => {
  const ua = navigator.userAgent
  console.log('isIphoneVersion')
  if (/iPhone|iPad|iPod/i.test(ua)) return true
  return false
}
export const resetSettingPadding = () => {
  const parentEl = document.querySelector('.j-setting-wrap')
  parentEl && (parentEl.style.paddingBottom = '1.3333rem')
}

export const loadRememberModal = async (config) => {
  const rememberModal = (await import('public/src/pages/login/common/logout.js')).default
  rememberModal?.$children[0]?._setupProxy?.handleShowRememberModal(config)
}
