const state = {
  // 展示气泡的商品行 id
  showPopoverId: '',
  // 当前是否展示气泡
  showPopover: false,
  // 是否永久关闭气泡展示
  closePopover: false,
  // 当前会话是否关闭气泡
  sessionClosePopover: false,
  // 自动关闭气泡时间（秒）
  autoClosePopoverSeconds: 8000,
  // 展示了几次气泡
  showPopoverCount: 0,
  // 勾选前的商品行数据
  checkBeforeItem: null,
  // 勾选后的商品行数据
  checkAfterItem: null,
}

export default state
