<template>
  <div>
    <div class="cate-new_swiper-wrapper">
      <swiper-container
        ref="swiper"
        class="cate-new_swiper"
        init="false"
        destroy-on-disconnected="false">
        <swiper-slide
          v-for="(item, i) in carouselList"
          :key="i"
          v-expose="{
            id: 'expose_second_category.comp_category',
            data: {
              item,
              topNavSa: activeChannel?.channel?.saInfo,
              oneCateContent: activeLeftContent?.cate?.saInfo,
              cateType,
            },
            code: cateType,
            delayReady: 300,
            callback:(data) => emits('expose',data)
          }"
          v-tap="{
            id: 'click_second_category.comp_category',
            data: {
              item,
              topNavSa: activeChannel?.channel?.saInfo,
              oneCateContent: activeLeftContent?.cate?.saInfo,
              cateType,
            },
          }"
          class="cate-new_swiper-slide"
          @click="event => clickSlide(event, item)"
        >
          <BaseImg
            componentSource="CateSwiper"
            class="base-img cate-swiper-img"
            :style="bgStyle"
            :ratio="getCustomRatio(item)"
            :src="item.image.src"
            :data-design-width="950"
            :ada="item.ada"
            :alt="item.hrefTitle"
            :first-screen="i < 1"
          />
        </swiper-slide>
        <div
          v-if="carouselList.length > 1"
          slot="pagination"
          :class="`j-ccc-swiper-pagination-${index}`"></div>
      </swiper-container>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, computed, onMounted, defineEmits, inject } from 'vue'
// import BaseImg from './BaseImg.vue'
import { Image as BaseImg } from '@shein/sui-mobile'
import { register } from 'swiper/element'
import { Pagination, Autoplay } from 'swiper/modules'
import injectStyle from '../../../common/swiper.css.js'
typeof window !== 'undefined' && register()

import { Expose } from '@shein-aidc/basis-expose'

const { vTap, vExpose } = inject('analysisInstance')

const props = defineProps({
  cate: {
    type: Object,
    default() {
      return {}
    },
  },
  cardStyle: {
    type: Object,
    default() {
      return {}
    },
  },
  activeChannel: {
    type: Object,
    default: () => ({}),
  },
  activeLeftContent: {
    type: Object,
    default: () => ({}),
  },
  cateType: {
    type: String,
    default: '',
  },
  compIndex: {
    type: Number,
    default: 0,
  },
  handleClose: {
    type: Function,
    default: () => {},
  },
  index: {
    type: Number,
    default: 0,
  },
})
const emits = defineEmits(['clickItem', 'expose'])
const swiper = ref(null)
const bgStyle = computed(() => {
  return {
    background: '#e5e5e5',
    borderRadius: props.cardStyle.borderRadius || '0px',
  }
})
const carouselList = computed(() => {
  let temp = props.cate?.props?.items || []
  if (props.cate?.props?.metaData?.carouselWay === 'optimization') {
    const totalWeights = temp.reduce((acc, cur) => acc + Number(cur.weights || 0), 0)
    temp.forEach(i => {
      i.weights = i.weights / totalWeights + Math.random()
    })
    temp.sort((a, b) => b.weights - a.weights)
  } else if (props.cate?.props?.metaData?.carouselWay === 'random') {
    temp.sort(() => Math.round(Math.random()) * 2 - 1)
  }
  return temp || []
})
const clickSlide = (event, imageItem) => {
  const params = {
    cate: imageItem,
    cateType: props.cateType,
    channel: props.activeChannel?.channel,
    channelIndex: props.activeChannel?.channelIndex,
    oneCateContent: props.activeLeftContent,
    compIndex: props.compIndex,
    oneCate: props.activeChannel?.oneCate,
  }
  emits(
    'clickItem',
    {
      type: 'swiper',
      item: imageItem?.categoryType,
      cateLinks: {},
      compIndex: props.compIndex,
      index: -1,
      isJump: imageItem.categoryType.hrefType != 'noJump',
    },
    params,
  )
}
const initSwiper = () => {
  if (typeof window === 'undefined') return
  const swiperEl = swiper.value
  if (!swiperEl) return
  const swiperParams = {
    modules: [Pagination, Autoplay],
    injectStyles: [injectStyle],
    loop: true,
    pagination: {
      hideOnClick: false,
    },
    spaceBetween: 0,
    speed: 300,
    slidesPerView: 1,
    slidesPerGroup: 1,
    allowTouchMove: true,
    autoplay: true,
    enabled: true,
    on: {
      init: () => {
        observeSwiper()
      },
    },
  }
  Object.assign(swiperEl, swiperParams)
  swiperEl.initialize()

  if (window.requestIdleCallback) {
    requestIdleCallback(() => {
      if (!swiperEl.initialized) {
        swiperEl.initialize()
      }
    })
  } else {
    setTimeout(() => {
      if (!swiperEl.initialized) {
        swiperEl.initialize()
      }
    }, 10)
  }
}
const getCustomRatio = item => {
  const { width, height } = item.image
  return width / height
}
// 是否展示轮播
const observeSwiper = () => {
  const obInstance = new Expose({ observeHide: true })
  const el = document.querySelector('.cate-new_swiper-wrapper')
  if (!el) return
  obInstance.observe(
    {
      elements: el,
      once: false,
    },
    ({ exposeDoms }) => {
      if (exposeDoms.length) {
        swiper?.value?.swiper?.autoplay?.start?.()
      } else {
        swiper?.value?.swiper?.autoplay?.pause?.()
      }
    },
  )
}

onMounted(() => {
  initSwiper()
})
</script>
<style lang="less" scoped>
.cate-new_swiper-wrapper {
  margin-top: 3.2vw;
  margin-top: min(3.2vw, 17.28px);
  width: 100%;
  overflow: hidden;
  margin-bottom: -1px;
  /* 设置 Swiper 容器样式 */
  .cate-new_swiper {
    display: flex;
    flex-wrap: nowrap;
  }
  /* 设置滑块 slide 样式 */
  .cate-new_swiper-slide {
    --slides-per-view: 1;
    width: calc(100% / var(--slides-per-view));
    flex-shrink: 0;
  }

  .base-img {
    width: 100%;
  }
}
</style>
