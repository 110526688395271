<template>
  <div
    v-if="!isShowPaypalChannelBtn"
    class="order-pay-btn"
  >
    <!-- pay_now -->
    <template
      v-if="orderInfo.orderStatus == 0 || orderInfo.orderStatus == 12"
    >
      <PaymentBtnNew
        v-if="isShowApplepay"
        key="paypal_pay_now"
        class="order-pay-btn--channel"
        :paypal-style="footerPaypalStyle"
        :apple-pay-style="applePayStyle"
        :store-name-space="moduleName"
        :before-start-pay="beforeStartPay"
        @create-payment="createPayment"
        @create-order="payNowOrVerifyNowEvt({ payType: 1 })"
      />
      <s-button
        v-else
        v-expose="{ 
          id: '1-19-1-139', 
          data: {
            order_no: orderInfo.billno,
            pay_button_text: payNowText,
          } 
        }"
        v-tap="{ 
          id: '1-19-1-140', 
          data: {
            order_no: orderInfo.billno,
            pay_button_text: payNowText,
          } 
        }"
        :type="['H56PX', 'primary']"
        DA-view-type="ordinary"
        DA-type="syncClick"
        @click.stop="payNowOrVerifyNowEvt({ payType: isCashPaymentType ? 2 : 1 })"
      >
        {{ payNowText }}
      </s-button>
    </template>
    <!-- verify now -->
    <s-button
      v-if="orderInfo.orderStatus == 13"
      v-expose="{ 
        id: '1-19-1-139', 
        data: {
          order_no: orderInfo.billno,
          pay_button_text: language.SHEIN_KEY_PWA_30858,
        } 
      }"
      v-tap="{ 
        id: '1-19-1-140', 
        data: {
          order_no: orderInfo.billno,
          pay_button_text: language.SHEIN_KEY_PWA_30858,
        } 
      }"
      DA-view-type="ordinary"
      DA-type="syncClick"
      :type="['H56PX', 'primary']"
      :disabled="!disabledCodPayNow"
      class="order-detail-footer__btn"
      @click.stop="disabledCodPayNow ? payNowOrVerifyNowEvt({ payType: 2 }) : ''"
    >
      {{ language.SHEIN_KEY_PWA_30858 }}
    </s-button>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { template } from '@shein/common-function'
import applePayConfig from 'public/src/pages/common/apple_pay/index.js'
import { isSafariVersionGTE15 } from 'public/src/pages/common/handlePay/utils.js'

export default {
  name: 'PayButton',
  components: {
    PaymentBtnNew: () => import(/* webpackChunkName: "OrderPayButton_Comp" */'public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/components/payment_btn/index.vue')
  },
  props: {
    moduleName: {
      type: String,
      default: 'orderDetail'
    },
    beforeStartPay: {
      type: Function,
      default: () => {}
    },
    createPayment: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      applePayStyle: {
        width: 'auto',
        height: '28px',
        justifyContent: 'flex-start'
      }
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'locals',
      'language',
      'orderInfo',
      'apolloInfo',
      'orderResultAbtInfo'
    ]),
    ...mapGetters('orderDetail', ['getCodOrderFrom']),
    ...mapGetters('orderDetail/payAgain', ['isShowApplePayBtn', 'isShowPaypalChannelBtn']),
    footerPaypalStyle () {
      return {
        layout: 'horizontal',
        color: 'black',
        shape: 'rect',
        label: 'paypal',
        height: 28
      }
    },
    // 二次支付氛围提升探索abt
    payNowAbtInfo() {
      return this.orderResultAbtInfo?.payNowAbtInfo || {}
    },
    applepayDropoffOptimizationApollo () {
      const applePayMethods = this.apolloInfo?.APPLE_PAY_CODE_LIST?.length ? this.apolloInfo.APPLE_PAY_CODE_LIST : applePayConfig?.applePayChannel || []
      return applePayMethods?.includes?.(this.orderInfo?.payment_method)
    },
    isCashPaymentType () {
      return this.orderInfo?.payment_type == 2 && (this.orderInfo?.pay_code_url || this.orderInfo?.pay_url)
    },
    payNowText() {
      if(this.isCashPaymentType) {
        return this.language?.SHEIN_KEY_PWA_15451
      }
      if (this.payNowAbtInfo.OrderdetailButtonTest === 'on' && this.orderInfo?.totalPrice?.amountWithSymbol) {
        return template(this.orderInfo?.totalPrice?.amountWithSymbol, this.language?.SHEIN_KEY_PWA_30861)
      }
      return this.language.SHEIN_KEY_PWA_15655
    },
    isShowApplepay () {
      return this.isShowApplePayBtn && isSafariVersionGTE15()
    },
    disabledCodPayNow () {
      return this.getCodOrderFrom(this.locals?.lang)?.includes(this.orderInfo?.site_from)
    }
  },
  methods: {
    payNowOrVerifyNowEvt (options = {}) {
      this.$emit('orderPayButtonNowNowEvt', options)
    }
  }
}
</script>
<style lang="less">
.order-pay-btn {
  margin-top: 16/75rem;

  .S-button {
    min-width: 300/75rem;
  }

  .order-pay-btn--channel {
    max-width: 1.8rem;
    display: inline-block; 
    margin: 0.17rem 0;
  }
}
</style>
