<template>
  <div
    :class="classnames"
    @click="onClick"
  >
    <img
      v-if="(index > -1 && index < 5) || size == 'mini'"
      :src="cutImg(src)"
    />
    <img
      v-else
      :src="LAZY_IMG"
      class="lazyload"
      :data-src="src"
      :data-design-width="dataDesignWidth"
      :data-exp="dataExp"
    />
    <img
      v-if="borderMask"
      class="product-img__border-mask"
      :src="borderMask"
    />
    <div
      v-if="belt"
      class="product-img__belt"
      :style="beltStyle"
    >
      <ResizeFont
        :is-multiline="true"
        :multiline-num="2"
      >
        <ResizeFontText
          :init-font-size="12"
          :resize-font-min="8"
          :resize-font-step="1"
        >
          {{ belt.text }}
        </ResizeFontText>
      </ResizeFont>
    </div>

    <s-checkbox
      v-if="!!productMap"
      class="product-img__checkbox"
      :value="checked"
      :disabled="checkboxDisabled"
      margin-r="0px"
      outside-check
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { hexToRgb } from '@shein/common-function'
import ResizeFont from '../../common/ResizeFont.vue'
import ResizeFontText from '../../common/ResizeFontText.vue'
import { useCutImg } from 'public/src/pages/cart_v2/hooks/useCutImg.js'
 
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: 'small', // mini
  },
  belt: {
    type: Object,
    default: null,
  },
  checkboxDisabled: {
    type: Boolean,
    default: false,
  },
  productMap: {
    type: Object,
    default: null,
  },
  cartId: {
    type: [String, Number],
    default: null,
  },
  borderMask: {
    type: String,
    default: '',
  },
  index: {
    type: Number,
    default: -1,
  },
})

const emit = defineEmits(['click'])

const classnames = computed(() => [
  'product-img',
  `product-img--${props.size}`,
  {
    'product-img_disabled': props.checkboxDisabled,
  }
])

const beltStyle = computed(() => ({
  '--bg-color': props.belt?.bgColor && hexToRgb({ hex: props.belt.bgColor, opa: +(props.belt.bgColorAlpha || 0.8) }),
  '--text-color': props.belt?.textColor,
}))

const cartItem = computed(() => props.productMap?.[props.cartId])

const checked = computed(() => {
  return !!cartItem.value
})

const {
  cutImg,
  dataDesignWidth,
  dataExp,
  LAZY_IMG
} = useCutImg()

const onClick = () => {
  if (props.checkboxDisabled) return
  emit('click', {
    id: props.cartId,
    checked: !checked.value,
  })
}
</script>

<style lang="less" scoped>
.product-img {
  flex-shrink: 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 64/37.5rem;
  height: 64/37.5rem;
  overflow: hidden;

  &--small {
    width: 64/37.5rem;
    height: 64/37.5rem;
  }

  &--mini {
    width: 32/37.5rem;
    height: 32/37.5rem;
  }

  &_disabled {
    .product-img__checkbox {
      opacity: 0.6;
    }
  }

  > img {
    width: 100%;
  }

  &__belt {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2/37.5rem 4/37.5rem;
    line-height: 1;
    text-align: center;
    color: #fff;
    color: var(--text-color, #fff);
    background: rgba(#000, 0.6);
    background: var(--bg-color, rgba(#000, 0.6));
  }

  &__checkbox {
    position: absolute;
    top: 2/37.5rem;
    right: 2/37.5rem;
    height: max-content;
  }
  &__border-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}
</style>
