import { useFindPayment } from '../../hooks/useFindPayment'
import { useGetIsHadDefaultBankPayment } from '../../hooks/useGetIsHadDefaultBankPayment'

export default {
  // 支付方式类型 NormalBtnType from 'public/src/pages/checkout_new/pages/footer/utils/constant.js'
  paymentMethodType(state) {
    return state.checkout?.placeOrderButton?.type
  },

  // 处理 button 弹窗利诱点数据
  getActivityPopoverData: ({ checkout, cartsInfo }) => {
    let popoverLurePoints = []
    if (checkout?.placeOrderLurePoints) {
      popoverLurePoints.push(...checkout.placeOrderLurePoints)
    }
    if (cartsInfo?.placeOrderLurePoints) {
      popoverLurePoints.push(...cartsInfo.placeOrderLurePoints)
    }
    return popoverLurePoints
      .filter(i => Number(i.is_display) === 1)
      .sort((a, b) => a.priority - b.priority)
  },

  isShowBottomPaymentSelector: (_, getter) => {
    return {
      show: !getter.rewardFloorConfig.rewardBarVisible &&
        getter.isShowBottomPayment.show
    }
  },
  isShowBottomPayment: (state) => {
    const { checkout, abtState } = state
    const selectedPayment =
      useFindPayment(
        checkout?.payment_info?.payments || [],
        v =>
          (v?.last_payment && +v?.enabled === 1) ||
          useGetIsHadDefaultBankPayment(v)
      ) || {}
    return {
      show: (
        (selectedPayment && abtState?.isOrderpaylistbottomstatus) ||
        (!selectedPayment && abtState?.isOrderpaylistbottomnodefaultstatus)
      )
    }
  },
  rewardFloorConfig: ({ checkout, abtInfo, footerState }, getter) => {
    const { guide } = checkout.rewardFloor || {}
    const { rewardsCheckoutType, rewardsCheckoutLocation } = abtInfo?.rewardsCheckout?.param || {}
    // 如果是单个利益点，且配置展示类型为吸底 + 楼层时，则只展示楼层
    const oneRewardFloor = guide?.rewardList?.length === 1 && +rewardsCheckoutType === 2
    let showRewardModule = !!guide
    // 与权益楼层耦合的数据
    const hasTobaccoTip = !!checkout?.tobacco_tip
    const hasOthers = getter.isShowBottomPayment || hasTobaccoTip
    // 当有数据 && 存在过没有权益楼层数据 && 存在耦合数据时，不展示权益楼层模块
    if (showRewardModule && footerState.noRewardData && hasOthers) showRewardModule = false
    // 仅配置吸底样式，但因冲突无法展示时
    if (+rewardsCheckoutType === 0 && hasTobaccoTip) showRewardModule = false
    const rewardFloorVisible = ([1, 2].includes(+rewardsCheckoutType) || oneRewardFloor) && showRewardModule
    const rewardBarVisible = [0, 2].includes(+rewardsCheckoutType) && !oneRewardFloor && showRewardModule
    return {
      rewardVisible: rewardFloorVisible || rewardBarVisible,
      // 0: 吸底; 1: 楼层; 2: 吸底 + 楼层
      rewardBarVisible,
      rewardFloorVisible,
      // 0: 下单页shoppingbag上方(地址下方); 1: 支付方式上方; 2: 价格明细的上方
      floorLocation: rewardFloorVisible ? +rewardsCheckoutLocation : -1
    }
  },
}
