import useApis from './useApis'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { useEditablePaymentInfo } from './useEditablePaymentInfo'
import type { PaymentsStore } from './usePayments'
import type { PaymentItemProps } from '../types/props'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { debuggerLog } from '../utils'

type tokenItemType = PaymentItemProps['card_token_list'][number]

export const useDeleteToken = (data: { id: string }) => {
    const appConfigs = useAppConfigs()
    const { deletePaymentSignUpApi } = useApis()

    return deletePaymentSignUpApi({
        data,
        currency: appConfigs?.$envs?.currency,
        appLanguage: appConfigs?.$envs?.appLanguage,
    })
}

export const useGetNowPay = (paymentStore: PaymentsStore) => {
    return paymentStore?.payments?.find(item => item?.code === paymentStore?.showTokenDrawerPay)
}

export const useGetDefaultToken = (paymentStore: PaymentsStore) => {
    const pay = useGetNowPay(paymentStore)
    return paymentStore?.editablePaymentInfo?.[pay?.code]?.card_token_info
}

interface useSelectedTokenParams {
    paymentStore: PaymentsStore,
    tokenItem?: tokenItemType,
    nowPay?: PaymentItemProps,
    changeCheckData?: Record<string, any>, // 用于调用 checkout 接口时，可能需要修改的数据
}

export const useSelectedToken = ({
    paymentStore,
}: useSelectedTokenParams) => {
    const { handleEditableInfoChange } = useEditablePaymentInfo(paymentStore)

    const handleSelectedToken = ({ tokenItem, nowPay, changeCheckData, extraInfo = {} }: {
        tokenItem?: tokenItemType,
        nowPay?: PaymentItemProps,
        changeCheckData?: Record<string, any>,
        extraInfo?: Trade_PayToolKit.EditablePaymentInfoExtraInfo,
    }) => {
        const pay = nowPay ? nowPay : useGetNowPay(paymentStore)

        debuggerLog('handleSelectedToken===pay===', pay, tokenItem)

        handleEditableInfoChange({
            changeInfo: {
                [pay?.code]: {
                    ...(paymentStore?.editablePaymentInfo?.[pay?.code] || {}),
                    card_token_info: tokenItem,
                    ...changeCheckData,
                    isUseNewCard: false,
                },
            },
            needEmitChangeEvent: true,
            extraInfo,
        })
    }

    return {
        handleSelectedToken,
        handleEditableInfoChange,
    }
}
