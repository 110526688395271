import { ref } from 'vue'
import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import { checkSchttp } from 'public/src/services/schttp/tools'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { CHECKOUT_TYPE, PRODUCT_TYPE } from '@shein-aidc/bs-sdk-libs-pay'
import { debuggerLog } from '@/public/src/pages/checkout_new/utils/index.js'
import {
  useMapState,
  useMapMutations,
  useMapGetters,
} from '@/public/src/pages/checkout_new/hooks/store'
import { Toast as $toast } from '@shein/sui-mobile'
import { useRouter } from '@/public/src/pages/checkout_new/hooks/useRouter.js'

import { ADDRESS_TIPS_DIALOG_TYPE, TRANSPORT_TYPE, OPERATE_TYPE, ADDRESS_ERROR_CODE_30, ADDRESS_ERROR_CODE_40, ADDRESS_ERROR_CODE_ORDER } from '@/public/src/pages/checkout_new/pages/address_bar/utils/constant.js'

import { COUNTRY_OR_AREA_CODE } from '@/public/src/pages/checkout_new/utils/constant.js'

import { usePayment } from '@/public/src/pages/checkout_new/hooks/usePayment'
import { loadTdLibJs } from 'public/src/pages/common/RiskCommon/libs/BlackBox/index.js'
import useBr50DollarsTax from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useBr50DollarsTax.js'
import useReportAnalysis from '@/public/src/pages/checkout_new/hooks/useReportAnalysis.js'
import useWakeAddressWrite from 'public/src/pages/checkout_new/pages/address_bar/hooks/useWakeAddressWrite.js'
import { useBsPayInstance } from '@/public/src/pages/checkout_new/hooks/useBsPayInstance'
import { useBenefitDrawer } from '@shein-aidc/bs-benefit-floor-mobile-vue2'

export default () => {
  const {
    checkout,
    checkoutParams,
    shoppingBagsState,
    buyNowNo,
    buyNowFromUrl,
    commonDialogState,
    createOrderLoading,
    language,
    checkoutLoading,
    paymentState
  } = useMapState([
    'checkout',
    'checkoutParams',
    'shoppingBagsState',
    'buyNowNo',
    'buyNowFromUrl',
    'commonDialogState',
    'createOrderLoading',
    'language',
    'checkoutLoading',
    'paymentState'
  ])


  const {
    updateAddressBarState,
    updateCommonDialogState,
    assignState,
    assignPaymentState,
    updateBr50DollarsTaxDrawerState,
    updateVirtualAssetsPopupState,
    changeTogetherBuy
  } = useMapMutations([
    'updateAddressBarState',
    'updateCommonDialogState',
    'assignState',
    'assignPaymentState',
    'updateBr50DollarsTaxDrawerState',
    'updateVirtualAssetsPopupState',
    'changeTogetherBuy'
  ])

  const {
    selectedPaymentInfo,
    isShopTransit,
    isNotAddressScene,
    selectedPrimeProduct,
    selectedSaverProduct,
    isShowBottomPaymentSelector,
    getDefaultAddress,
    isHasAddress,
  } = useMapGetters([
    'selectedPaymentInfo',
    'isShopTransit',
    'isNotAddressScene',
    'selectedPrimeProduct',
    'selectedSaverProduct',
    'isShowBottomPaymentSelector',
    'getDefaultAddress',
    'isHasAddress'
  ])

  const { paymentInstance } = useBsPayInstance()
  const { paymentOrderInfo, handleCreatePayment, getOrderDetailLink } = usePayment()
  const { handleAddressDrawer } = useWakeAddressWrite()
  const { isAble } = useBr50DollarsTax()
  const {
    handleCreateOrderAnalysis,
  } = useReportAnalysis()
  const { closeDrawer } = useBenefitDrawer()

  const addOrderAllParams = ref({})

  const router = useRouter()

  loadTdLibJs()

  /**
   * 创建订单校验
   * 此处注释 注意注意注意！！！！
   * 为方便后续维护，下单线与支付线分开维护！！！！！！（除下方1.有优先级）
   * 该方法分三个函数：
   * 1. validateWithUser 有优先级（return前有引导用户交互的流程，例如：弹窗、抽屉等，toast提示不算！！！）（该方法中包含下单、支付的拦截流程）
   * 2. validateByCheckout 下单拦截的函数
   * 3. validateByPay 支付拦截的函数
   * 
   * return 返回值：
   * 1.notValid是否校验通过（Boolean）
   * 2.saParams 成单埋点上报的参数(Object)
   * 3.scene 当前触发拦截的场景值
   */
  const validateCreateOrder = async function (opt = {}) {
    // 存在用户交互
    const { notValid: notValidUser, saParams: saParamsUser, scene: sceneUser } = await validateWithUser(opt)
    if (notValidUser) {
      return {
        notValid: true,
        saParams: saParamsUser,
        scene: sceneUser
      }
    }
    // 下单拦截函数
    const { notValid: notValidCheckout, saParams: saParamsCheckout, scene: sceneCheckout } = await validateByCheckout(opt)
    if (notValidCheckout) {
      return {
        notValid: true,
        saParams: saParamsCheckout,
        scene: sceneCheckout
      }
    }
    // 支付拦截函数
    const { notValid: notValidPay, saParams: saParamsPay, scene: scenePay, payAnalysisInfo } = await validateByPay(opt)
    if (notValidPay) {
      return {
        notValid: true,
        saParams: saParamsPay,
        scene: scenePay,
        payAnalysisInfo,
        prePayAnalysisInfo: payAnalysisInfo || {}
      }
    }

    return {
      notValid: false,
      saParams: {},
      scene: '',
      prePayAnalysisInfo: payAnalysisInfo || {}
    }
  }

  const validateWithUser = async function ({ checkout, buyNowNo, mixPrivacy } = {}) {
    // 16.无宅配地址场景阻塞下单流程（点击成单按钮 - 调用成单接口 中途拦截流程）
    if (!isShopTransit.value && isNotAddressScene.value) {
      SIMetric.metricCount({
        metric_name: 'fe_create_order_error', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          scene: 'no_home_address',
          productType: 'normal',
          checkoutType: buyNowNo ? 'buynow' : 'normal',
        }
      })

      return {
        notValid: true,
        saParams: {
          result: '0',
          result_reason: '6',
          order_id: '',
          check_out_scene: 'no_address',
        },
        scene: 'no_home_address'
      }
    }

    // 19.巴西50美金校验（点击成单按钮 - 调用成单接口 中途拦截流程）
    // 是否露出50美金入口,并且没有弹窗
    const isHintMsg = checkout?.mallHintInfo.some(item => item?.hintMsg)
    const showTaxHint = isHintMsg && isAble
    if (showTaxHint && !shoppingBagsState.value.isDisplayedBr50DollarDrawer) {
      SIMetric.metricCount({
        metric_name: 'fe_create_order_error', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          scene: 'br_tax_err',
          productType: 'normal',
          checkoutType: !!buyNowNo ? 'buynow' : 'normal',
        }
      })

      return {
        notValid: true,
        saParams: {
          result: '0',
          result_reason: '4_2',
          order_id: '',
        },
        scene: 'br_tax_err'
      }
    }


    // 2.成单前触发无库存校验不通过（点击成单按钮 - 调用成单接口 中途拦截流程）
    if (!!checkout?.outStockCarts?.length) {
      SIMetric.metricCount({
        metric_name: 'fe_create_order_error', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          scene: 'out_stock',
          productType: 'normal',
          checkoutType: !!buyNowNo ? 'buynow' : 'normal',
        }
      })

      return {
        notValid: true,
        saParams: {
          result: '0',
          result_reason: '4_1',
          order_id: '',
        },
        scene: 'out_stock',
      }
    }


    // 14.一站多合规校验阻塞成单流程（点击成单按钮 - 调用成单接口 中途拦截流程）
    if (mixPrivacy?.needPop) {
      SIMetric.metricCount({
        metric_name: 'fe_create_order_error', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          scene: 'mix_privacy',
          productType: 'normal',
          checkoutType: !!buyNowNo ? 'buynow' : 'normal',
        }
      })

      return {
        notValid: true,
        saParams: {
          result: '0',
          result_reason: 'other',
          order_id: '',
        },
        scene: 'mix_privacy',
      }
    }

    // 6.当前选中的是店配运输方式但不存在店配地址（点击成单按钮 - 调用成单接口 中途拦截流程）
    if (isShopTransit.value && !isHasAddress.value) {
      SIMetric.metricCount({
        metric_name: 'fe_create_order_error', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          scene: 'shop_transit_no_address',
          productType: 'normal',
          checkoutType: !!buyNowNo ? 'buynow' : 'normal',
        }
      })

      return {
        notValid: true,
        saParams: {
          result: '0',
          result_reason: '3',
          order_id: '',
        },
        scene: 'shop_transit_no_address',
      }
    }


    return {
      notValid: false,
      saParams: {},
      scene: '',
    }
  }

  const validateByCheckout = function ({ checkout, buyNowNo, createOrderLoading }) {
    // 1.当前正在请求成单接口ing（点击成单按钮 - 调用成单接口 中途拦截流程）(或者当前正在请求checkout接口，需要拦截成功后入参才会赋值)
    if (createOrderLoading || checkoutLoading.value?.requesting) {
      SIMetric.metricCount({
        metric_name: 'fe_create_order_error', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          scene: 'prev_create_loading',
          productType: 'normal',
          checkoutType: !!buyNowNo ? 'buynow' : 'normal',
        }
      })

      return {
        notValid: true,
        saParams: {
          result: '0',
          result_reason: 'other',
          order_id: '',
        },
        scene: 'prev_create_loading',
      }
    }


    // 4.未选中运输方式阻塞下单流程（点击成单按钮 - 调用成单接口 中途拦截流程）
    const noSelectShipping = hasNoSelectedShippingMethod(checkout)
    if (!!noSelectShipping?.length) {
      SIMetric.metricCount({
        metric_name: 'fe_create_order_error', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          scene: 'no_shipping_method',
          productType: 'normal',
          checkoutType: !!buyNowNo ? 'buynow' : 'normal',
        }
      })

      return {
        notValid: true,
        saParams: {
          result: '0',
          result_reason: '2-1',
          order_id: '',
        },
        scene: 'no_shipping_method',
      }
    }

    // 6.当前选中的是店配运输方式但不存在店配地址（点击成单按钮 - 调用成单接口 中途拦截流程）
    if (getDefaultAddress.value?.countryId === COUNTRY_OR_AREA_CODE.TW && isShopTransit.value) {
      updateAddressBarState({
        addressTipsDialog: {
          visible: true,
          type: ADDRESS_TIPS_DIALOG_TYPE.ADDRESS_STORE
        },
      })
      SIMetric.metricCount({
        metric_name: 'fe_create_order_error', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          scene: 'shop_transit_no_address',
          productType: 'normal',
          checkoutType: !!buyNowNo ? 'buynow' : 'normal',
        }
      })
      return {
        notValid: true,
        saParams: {
          result: '0',
          result_reason: '2-2',
          scene: 'shop_transit_no_address',
          order_id: '',
        }
      }
    }

    return {
      notValid: false,
      saParams: {},
      scene: '',
    }
  }

  const validateByPay = async function ({ extraPayInfo = {}, extraParams = {} } = {}) {
    const validRes = await paymentState.value.payInstanceEvent?.getValidateByPay?.({
      has_cvv: !!extraPayInfo?.cvv,
      lastAddOrderExtraParams: extraParams,
    })

    const { validateResult, message, metric_scene, scene = '', extraInfo = {}, saParams = {}, payAnalysisInfo = {} } = validRes || {}
    debuggerLog(validRes, validateResult, metric_scene, scene, extraInfo, '9999000')

    // 异常场景记录 - selectPaymentInfo有值，但checkout入参中不存在payment_code
    if (!checkoutParams.value?.payment_code && validateResult) {
      SIMetric.metricCount({
        metric_name: 'web_payment_error_total', // 指标名称
        tags: { // 指标维度
          sub_site: gbCommonInfo?.SiteUID || '',
          error_scene: 'checkout_params_no_payment_code',
          checkout_type: CHECKOUT_TYPE.NORMAL,
          product_type: PRODUCT_TYPE.NORMAL,
          payment_code: selectedPaymentInfo.value?.payment_code || '',
        },
      })
    }

    // 未选择支付方式
    if (!validateResult && scene === 'not-select-payment') {
      $toast(language.value.SHEIN_KEY_PWA_24844)
      assignPaymentState({ noSelectPayment: true })

      if (!isShowBottomPaymentSelector.value?.show) {
        paymentState.value.payInstanceEvent?.focusNotSelectPayment?.()
      }
    }
    if (!validateResult && metric_scene) {
      // 卡前置校验
      if (scene === 'pre_create_pay') {
        SIMetric.metricCount({
          metric_name: 'web_payment_error_total', // 指标名称
          tags: { // 指标维度
            sub_site: gbCommonInfo?.SiteUID || '',
            error_scene: metric_scene,
            checkout_type: CHECKOUT_TYPE.NORMAL,
            product_type: PRODUCT_TYPE.NORMAL,
            payment_code: selectedPaymentInfo.value?.payment_code || '',
          },
          params: {
            url: location.href,
            message: `err: ${extraInfo?.failReasonResults?.join?.(',')}, billno: ${extraInfo?.prePayCheckoutInfo?.billno || ''}`
          },
          message: `err: ${extraInfo?.failReasonResults?.join?.(',')}, billno: ${extraInfo?.prePayCheckoutInfo?.billno || ''}`
        })
      }
      SIMetric.metricCount({
        metric_name: 'fe_create_order_error', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          scene: metric_scene,
          productType: 'normal',
          checkoutType: !!buyNowNo.value ? 'buynow' : 'normal',
        }
      })
    }

    if (validateResult) {
      paymentInstance?.preCreatePayment?.({
        selectedPaymentInfo: selectedPaymentInfo.value,
        orderInfo: paymentOrderInfo.value,
      })
    }

    return {
      notValid: !validateResult,
      saParams: {
        result: '0',
        result_reason: 'other',
        order_id: '',
        payment_method_id: selectedPaymentInfo.value?.payment_id || '',
        ...saParams
      },
      scene,
      payAnalysisInfo,
    }
  }

  // 是否存在mall未勾选运输方式
  const hasNoSelectedShippingMethod = function (checkout) {
    const { shipping_methods_mall = [] } = checkout
    // 当前没有选择运输方式的mall
    let no_mall = []
    shipping_methods_mall?.forEach(mall => {
      const targetShippingMethod = mall.shipping_methods?.find(method => method.isDefault == 1)
      if (!targetShippingMethod) {
        no_mall.push({
          mallCode: mall.mall_code,
        })
      }
    })

    return no_mall
  }

  // 处理前置拦截的交互行为
  const handleValidScene = (scene) => {
    // 无宅配地址场景阻塞
    if (scene == 'no_home_address') {
      sessionStorage.setItem('showAddressDrawerSource', 'place_order')
      daEventCenter.triggerNotice({
        id: 'click_no_address_module:simple',
        data: {
          entry_source: 'place_order'
        }
      })
      handleAddressDrawer({
        status: true,
        operateType: OPERATE_TYPE.ADD,
        addressType: TRANSPORT_TYPE.HOME
      })
      return
    }

    // 无店配地址阻塞场景
    if (scene == 'shop_transit_no_address') {
      handleAddressDrawer({
        status: true,
        operateType: OPERATE_TYPE.ADD,
        addressType: TRANSPORT_TYPE.SHOP,
        addressData: checkout.value?.address
      })
      return
    }

    // 触发巴西50美金税费拦截
    if (scene == 'br_tax_err') {
      // 此处拉起50美金弹窗 Done
      updateBr50DollarsTaxDrawerState(true)
      return
    }

    // 触发无库存校验拦截
    if (scene == 'out_stock') {
      updateCommonDialogState({
        soldOutDrawer: {
          show: true,
          tip: checkout.value?.outStockCartsTip,
          soldOutList: checkout.value?.outStockCarts
        }
      })
      return
    }

    // 触发一站多合规校验拦截
    if (scene == 'mix_privacy') {
      updateCommonDialogState({
        mixPrivacy: {
          needPop: true,
          show: true
        }
      })
      return
    }

    // 触发当前运输方式未选中拦截
    if (scene == 'no_shipping_method') {
      const noSelectShipping = hasNoSelectedShippingMethod(checkout.value)

      const shippingItemEl = document.getElementsByClassName(`checkout-shipping-method__wrapper-${noSelectShipping[0].mallCode}`)?.[0]
      shippingItemEl?.scrollIntoView({ block: 'center' })
      return
    }
  }

  /**
   * 风控模块
   * 配置场景值：place_order 成单风控
   * 处罚措施：极验（下单暂无），手机验证/phone_captcha，邮箱验证/email_captcha，拦截/high_risk_copy/low_risk_copy
   * @param {*} res 创单接口返回
   * @returns void
   */
  const riskModules = ({ res }) => {
    const captcha = res.info?.risk_info?.extend_info?.actions?.[0]?.name || ''
    const { risk_decision, risk_tip_for_intercept = '' } = res.info?.risk_info || {}
    const isAsync = !selectedPaymentInfo.value.use_sync
    if (captcha == 'email_captcha' || captcha == 'phone_captcha') {
      // 邮箱验证、手机验证
      import('public/src/pages/common/RiskCommon/index.js').then(({ default: riskDialog }) => {
        res.source = 'checkout'
        riskDialog.$children[0]._setupProxy.inifConfig({
          data: res,
          confirmCb: (risk_id) => {
            if (risk_id) {
              const params = {
                ...addOrderAllParams.value.params, risk_id
              }
              addOrderAllParams.value = { ...addOrderAllParams.value, params }
            }
            if (isAsync) {
              addOrder({ ...addOrderAllParams.value })
            }
          }
        })
      })
    } else if (captcha == 'change_pwd_leak' || captcha == 'change_pwd_blasting') {
      import('public/src/pages/common/riskControlDialog/index.js').then(({ default: riskSource }) => {
        riskSource.$children[0].initConfig({
          data: res,
          language: language.value,
          loginModalSucCb: () => {
            if (isAsync) {
              addOrder({ ...addOrderAllParams.value })
            }
          }
        })
      })
    } else if(risk_decision == 2) {
      import('public/src/pages/common/riskControlDialog/index.js').then(({ default: riskSource }) => {
        riskSource.$children[0].showRiskDialogFn({
          data: res,
          language: language.value,
          riskDialogTips: risk_tip_for_intercept
        })
      })
    } else {
      // 没触发动作，则默认提示
      import('public/src/pages/common/riskControlDialog/index.js').then(({ default: riskSource }) => {
        riskSource.$children[0].showTips({
          tips: res?.info?.msg || language.value?.SHEIN_KEY_PWA_29107,
          visible: true,
        })
      })
    }
  }

  // 地址错误码处理函数
  const addressErrorFunction = (res) => {
    const { hint_type, address_title } = res?.info || {}
    // 埋点上报
    handleCreateOrderAnalysis({
      result: '0',
      result_reason: ADDRESS_ERROR_CODE_40.includes(+res?.code) && res?.code !== 401974 ? '3_2' : res.code,
      order_id: res.info?.order?.billno || '',
      uorder_id: res.info?.order?.relation_billno,
      order_id_list: res.info?.order?.order_group?.map(_ => _.billno).join(','),
      extraPayAnalysisInfo: res?.extraPayAnalysisInfo || {}
    })

    if([10112002].includes(+res.code) && +hint_type === 3) { // 地址进入地址编辑页（hint_type 限制）
      const errorCodeList = res?.info?.h5_supplier_params?.split('&') || []
      const errorHandlerFuncCode = errorCodeList?.[0]?.split('=')?.[1] || ''
      let otherData = {}

      if (errorHandlerFuncCode) {
        otherData = {
          specificFunc: ({ errorHandlerFunc }) =>
            () => setTimeout(() => {
              errorHandlerFunc?.({
                code: errorHandlerFuncCode,
                options: {
                  defaultText: language.value.SHEIN_KEY_PWA_15141 || ''
                }
              })
            }, 300)
        }
      }
      handleAddressDrawer({
        status: true,
        operateType: OPERATE_TYPE.SUPPLEMENT,
        addressType: isShopTransit.value ? TRANSPORT_TYPE.SHOP : TRANSPORT_TYPE.HOME,
        addressData: checkout.value?.address || {},
        extraData: {
          noticeContent: address_title,
          ...otherData
        }
      })
    }

    if (ADDRESS_ERROR_CODE_30.includes(+res.code)) {
      handleAddressDrawer({
        status: true,
        operateType: OPERATE_TYPE.SUPPLEMENT,
        addressType: isShopTransit.value ? TRANSPORT_TYPE.SHOP : TRANSPORT_TYPE.HOME,
        addressData: checkout.value?.address || {},
      })
    }

    // if (ADDRESS_ERROR_CODE_SHOP.includes(+res.code)) {
    //   // 店配地址报错(拉起店配地址簿)
    //   updateAddressBarState({
    //     addressTipsDialog: {
    //       visible: true,
    //       type: ADDRESS_TIPS_DIALOG_TYPE.ADDRESS_STORE
    //     },
    //   })
    // }

    if (ADDRESS_ERROR_CODE_40.includes(+res?.code)) {
      const typeAddress = [...ADDRESS_ERROR_CODE_ORDER, 402006].includes(+res.code) ? OPERATE_TYPE.SUPPLEMENT : OPERATE_TYPE.EDIT
      if (res.code == 401974) {
        // 先弹窗msg，点击按钮之后跳转地址编辑页
        updateAddressBarState({
          addressTipsDialog: {
            visible: true,
            type: ADDRESS_TIPS_DIALOG_TYPE.ADDRESS_TW
          }
        })
      } else {
        handleAddressDrawer({
          status: true,
          operateType: typeAddress === OPERATE_TYPE.SUPPLEMENT ? OPERATE_TYPE.SUPPLEMENT : OPERATE_TYPE.EDIT,
          addressType: isShopTransit.value ? TRANSPORT_TYPE.SHOP : TRANSPORT_TYPE.HOME,
          addressData: checkout.value?.address || {},
          extraData: {
            specificFunc: ({ errorHandlerFunc }) =>
              () => setTimeout(() => {
                errorHandlerFunc?.({
                  code: res.code,
                  options: {
                    defaultText: res.msg || language.value.SHEIN_KEY_PWA_15141 || ''
                  }
                })
              }, 300)
          }
        })
      }
    } else if (res.info.is_check_address_error == 1) {
      handleAddressDrawer({
        status: true,
        operateType: OPERATE_TYPE.EDIT,
        addressType: TRANSPORT_TYPE.HOME,
        addressData: checkout.value?.address || {},
        extraData: {
          specificFunc: ({ errorHandlerFunc }) =>
            () => setTimeout(() => {
              errorHandlerFunc?.({
                code: res.code,
                options: {
                  defaultText: res.msg || language.value.SHEIN_KEY_PWA_15141 || ''
                }
              })
            }, 300)
        }
      })
    }
  }

  // 埋点事件暴露到全局监听，提供给支付模块使用
  const setTempPayForCreateOrderAnalysis = (saParams) => {
    const tempSendBi = ({ result_reason }) => {
      handleCreateOrderAnalysis({
        ...saParams,
        result_reason,
        extraPayAnalysisInfo: saParams?.extraPayAnalysisInfo || {}
      })
      if (!['1_14', '1_15'].includes(result_reason)) {
        appEventCenter?.$off?.('PAYMENT_VALID_SEND_BI')
      }
    }

    appEventCenter?.$on?.('PAYMENT_VALID_SEND_BI', tempSendBi)
  }

  const handleBeforeCreateOrder = () => {
    updateVirtualAssetsPopupState({
      newGiftCardDrawer: {
        visible: false
      }
    })
    // 关闭随手购弹窗
    changeTogetherBuy({ productsDrawerVisible: false })
    // 关闭权益详情弹窗
    closeDrawer?.()
  }

  const handleCreateOrder = async ({ 
    extraParams = {}, 
    extraPayInfo = {}, 
    extraCb = {},
    payAnalysisInfo = {},
  } = {}) => {
    SIMetric.metricCount({
      metric_name: 'fe_click_place_order', // 指标名称
      tags: { // 指标维度
        SiteUID: gbCommonInfo?.SiteUID || '',
        productType: 'normal',
        checkoutType: !!buyNowNo.value ? 'buynow' : 'normal',
      }
    })

    handleBeforeCreateOrder()

    console.log('createOrderLoading.value====', createOrderLoading.value, extraCb)

    // 成单前置校验
    const { notValid, saParams, scene, prePayAnalysisInfo } = await validateCreateOrder({
      checkout: checkout.value,
      buyNowNo: buyNowNo.value,
      mixPrivacy: commonDialogState.value?.mixPrivacy,
      createOrderLoading: createOrderLoading.value,
      extraPayInfo,
      extraParams
    })

    let extraPayAnalysisInfo = {}

    // 设置关于卡分期分期埋点入参
    if (extraPayInfo?.installments) {
      extraPayAnalysisInfo.is_installment_default =  payAnalysisInfo?.is_installment_default ? '1' : '0'
      extraPayAnalysisInfo.instalment_num =  extraPayInfo.installments
    }

    if (Object.keys(prePayAnalysisInfo || {})?.length > 0) {
      extraPayAnalysisInfo.is_installment_default =  prePayAnalysisInfo?.is_installment_default ? '1' : '0'
      extraPayAnalysisInfo.instalment_num =  prePayAnalysisInfo?.instalment_num || 1
    }

    // 未通过校验上报埋点、根据scene执行对应方法
    if (notValid) {
      extraCb?.cancelCb?.(scene)
      handleValidScene(scene)
      handleCreateOrderAnalysis({
        ...saParams,
        extraPayAnalysisInfo
      })
      setTempPayForCreateOrderAnalysis({
        ...saParams,
        extraPayAnalysisInfo
      })
      return
    }

    const handleAddOrderSuccess = function ({ createRes, extraPayInfo, extraCb = {} }) {
      assignState({ createOrderLoading: false })
      if (createRes?.code == 0) {
        assignPaymentState({ createdOrderInfo: createRes?.info?.order || {} })
        extraCb?.successCb?.(createRes)
        if (!extraCb?.successCb) {
          handleCreatePayment({ extraPayInfo, extraCb })
        }

        // 埋点上报
        handleCreateOrderAnalysis({
          result: 1,
          result_reason: '',
          order_id: createRes.info?.order?.billno || '',
          uorder_id: createRes.info?.order?.relation_billno,
          order_id_list: createRes.info?.order?.order_group?.map(_ => _.billno).join(','),
          extraPayAnalysisInfo
        }) 
      } else {
        extraCb?.errorCb?.(createRes)
        addressErrorFunction({
          ...createRes,
          extraPayAnalysisInfo
        })
        paymentInstance.resetPayment()

        if (createRes?.code == 302206) {
          updateCommonDialogState({
            createOrderBinDialog: {
              show: true,
              coverData: {
                selected_bin: '',
                token_id: '',
                installment_token_id: '',
              }
            }
          })
        } else if (createRes?.code == 301302 && createRes?.info?.outStockCarts) { // 无库存
          updateCommonDialogState({
            soldOutDrawer: {
              show: true,
              tip: createRes?.info?.outStockCartsTip,
              soldOutList: createRes?.info?.outStockCarts
            }
          })
        } else if (createRes?.code == 300401) { // 空车
          updateCommonDialogState({
            nullCartDialog: {
              show: true,
              tip: createRes.msg
            }
          })
        } else if (createRes?.code == 300407) { // 商品数量超过限制
          updateCommonDialogState({
            cartLimitDialog: {
              show: true,
              tip: createRes.msg
            }
          })
        } else if (createRes?.code == 300412) { // 打折限购超过数量
          updateCommonDialogState({
            discountLimitDrawer: {
              show: true,
              overLimitIntercept: createRes.info.overLimitIntercept
            }
          })
          daEventCenter.triggerNotice({
            id: 'expose_promotion_limit_exceeded_pop:simple',
          })
          daEventCenter.triggerNotice({
            id: 'expose_checkoutpurchaselimitation:simple',
          })
        } else if (createRes?.code == 300405) { // 闪购商品超过限制
          updateCommonDialogState({
            cartLimitDialog: {
              show: true,
              tip: createRes.msg
            }
          })

          daEventCenter.triggerNotice({
            id: 'expose_reachedflashsalelimit:simple',
          })
        } else if (createRes?.code == 302202 && createRes?.info) { // hint_type 限制
          /**
           * hint_type
           * 0：不涉及商品
           * 1：删除全部商品
           * 2：删除部分商品
           * 3: [俄罗斯护照校验 | 以色列大于117美金]（中间层将错误码转为10112002）
           * 4: 南非下单特殊商品，但地址没有身份ID时的提示
           * 8: 未成年刀具（中间层将错误码转为10112006）
           */
          const { hint_type, match_carts = [], address_title } = createRes.info
          if ([1, 2, 4].includes(+hint_type)) {
            updateCommonDialogState({
              specialGoodsDrawer: {
                show: true,
                hintType: hint_type,
                tip: address_title,
                specialGoods: match_carts
              }
            })
          } else {
            daEventCenter.triggerNotice({
              id: 'expose_popup_orderlimit:simple',
            })

            updateCommonDialogState({
              commonErrDialog: {
                show: true,
                tip: createRes.msg,
                cb: () => {
                  daEventCenter.triggerNotice({
                    id: 'click_orderlimityes:simple',
                  })
                }
              }
            })
          }
        } else if (createRes?.code == 10112006) { // hint_type 限制
          const { match_carts = [], title, popup_tip, lower_button, upper_button } = createRes.info
          updateCommonDialogState({
            knivesGoodsDrawer: {
              show: true,
              goodsList: match_carts,
              title,
              popup_tip,
              lower_button,
              upper_button
            }
          })
        } else if ([302209, 300411].includes(+createRes?.code)) { // 风控
          riskModules({ res: createRes })
        } else if(createRes?.code == 301721 && createRes?.msg) { // 礼品卡
          // 如果主体不是欧盟，则改为接受再次下单
          updateCommonDialogState({
            giftcardLimitDialog: {
              show: true,
              tip: createRes.msg
            }
          })
          daEventCenter.triggerNotice({
            id: 'expose_popup_giftcard_lrd_box:simple',
          })
        } else if (createRes?.code == 301714) {
          daEventCenter.triggerNotice({
            id: 'expose_popup_giftcarddevicetrytoomanylock:simple',
            data: {
              error_scene: 1
            }
          })
          updateCommonDialogState({
            commonErrDialog: {
              show: true,
              tip: createRes.msg,
              cb: () => {
                daEventCenter.triggerNotice({
                  id: 'click_giftcarddevicetrytoomanylock_ok:simple',
                  data: {
                    error_scene: 1
                  }
                })
              }
            }
          })
          // 存在未支付订单提示
        } else if (createRes?.code == 300356) { // 展示弹窗，文案取msg，按钮是多语言
          updateCommonDialogState({
            commonErrDialog: {
              show: true,
              tip: createRes.msg,
              confirmText: language.value.SHEIN_KEY_PWA_15312,
              closeText: language.value.SHEIN_KEY_PWA_15148,
              hor: true,
              showClose: true,
              onConfirm: () => {
                window.location.href = `${gbCommonInfo?.host}${gbCommonInfo?.langPath}/user/orders/list`
              }
            }
          })
        } else if (createRes?.code == 300310) { // 缺货商品，toast提示（msg），返回上一页
          $toast(createRes?.msg)

          const redirectUrl = (!!buyNowNo.value && !!buyNowFromUrl.value) ? buyNowFromUrl.value : `${gbCommonInfo?.langPath}/cart`
          if (router) {
            router.push(redirectUrl)
          } else {
            window.location.href = redirectUrl
          }
        } else { // 其他，toast报msg，停留在原页面
          $toast(createRes?.msg)
        }
      }
    }

    const handleAddOrderError = function ({ errorInfo, extraCb }) {
      assignState({ createOrderLoading: false })
      extraCb?.errorCb?.()

      console.log('createOrder error', errorInfo)
      alert('createOrder error')
    }

    // 通过校验，走成单逻辑
    assignState({ createOrderLoading: true })

    const { giftcard_verify, adult, coverData = {} } = extraParams
    const params = {
      checkoutParams: checkoutParams.value,
      bagsInfo: [...new Set(shoppingBagsState.value?.bagsInfo?.all?.map(_ => _.business_model))] || [],
      giftcard_verify,
      adult,
      risk_id: '',
      coverData
    }
    // 缓存入参
    addOrderAllParams.value = {
      params,
      isAsync: !selectedPaymentInfo.value.use_sync,
      successCb: res => handleAddOrderSuccess({ createRes: res, extraPayInfo, extraCb }),
      errorCb: err => handleAddOrderError({ errorInfo: err, extraCb })
    }
    addOrder({
      params,
      isAsync: !selectedPaymentInfo.value.use_sync,
      successCb: res => handleAddOrderSuccess({ createRes: res, extraPayInfo, extraCb }),
      errorCb: err => handleAddOrderError({ errorInfo: err, extraCb })
    })
  }


  /**
   * 创建订单接口
   * @param {*} checkoutParams checkout接口入参
   * @param {*} bagsInfo 购物袋商品信息
   */
  const addOrder = ({ params, isAsync, successCb, errorCb } = {}) => {
    const { checkoutParams, bagsInfo, giftcard_verify, adult, risk_id, coverData = {} } = params
    const addOrderParams = Object.assign({}, {
      // 支付相关
      selected_bin: coverData?.selected_bin ?? (checkoutParams.selected_bin || ''),
      bank_code: checkoutParams.bank_code || '',
      payment_id: checkoutParams.payment_id || '',
      pay_email: checkoutParams.pay_email || '',
      payment_code_unique: checkoutParams.payment_code || '',
      payment_code: checkoutParams.payment_code || '',
      pay_phone: checkoutParams.pay_phone || '',
      cpf_number: checkoutParams.cpf_number || '',
      issuer: checkoutParams.issuer || '',
      // UG-212456 中间层新增入参
      token_id: coverData?.token_id ?? (checkoutParams.token_id || ''),
      installment_token_id: coverData?.installment_token_id ?? (checkoutParams.installment_token_id || ''),

      shipping_methods: checkoutParams.shipping_methods,
      coupon_list: checkoutParams.coupon_list,
      prime_product_code: checkoutParams.prime_product_code,
      save_card_product_code: checkoutParams.save_card_product_code,
      is_prime_product_auto_renewal: selectedPrimeProduct.value?.auto_renewal?.is_auto_renew_product ?? 0,
      is_save_card_product_auto_renewal: selectedSaverProduct.value?.saveCardProductInfo?.isAutoRenewProduct ?? 0,
      card_no: checkoutParams.card_no,
      card_pin: checkoutParams.card_pin,
      is_bind_gift_card: checkoutParams.is_bind_gift_card,
      giftcard_verify,
      points: checkoutParams.points,
      use_wallet_amount: checkoutParams.use_wallet_amount,
      use_wallet: checkoutParams.use_wallet,
      country_id: checkoutParams.country_id,
      shop_transit_country_id: checkoutParams.shop_transit_country_id,
      address_id: checkoutParams.address_id,
      is_old_version: checkoutParams.is_old_version,
      risk_id,
      blackbox: window._fmOpt?.__blackbox || '', // 同盾
      checkout_no: checkoutParams.checkout_no,
      scene: checkoutParams.scene,
      biz_mode_list: [...new Set(bagsInfo?.all?.map(_ => _.business_model))] || [],
      adult,
      userLocalSizeCountry: typeof window !== 'undefined' ? window.localStorage.getItem('last_select_country') : '',
    })


    checkSchttp({
      isAsync,
      opt: {
        method: 'POST',
        url: '/order/add_order',
        data: addOrderParams,
        useBffApi: true,
        schttp: {
          needLogin: true
        },
      },
      successCb: res => {
        successCb?.(res)

        SIMetric.metricCount({
          metric_name: 'fe_create_order_response', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            productType: 'normal',
            checkoutType: !!buyNowNo.value ? 'buynow' : 'normal',
            paymentCode: addOrderParams?.payment_code_unique || addOrderParams?.payment_code,
            errorCode: String(res.code)
          }
        })
      },
      errorCb: (err) => {
        errorCb?.(err)
      }
    })
  }

  return {
    handleCreateOrder,
    handleCreatePayment,
    getOrderDetailLink
  }
}
