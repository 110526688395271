<template>
  <div
    class="checkout-component__image"
    :style="`--background_color--:${
      isDefaultGrayGb ? 'rgba(0, 0, 0, 0.03)' : 'none'
    }`"
  >
    <TransformImg
      :img-src="imgUrl"
      :fetchpriority="fetchpriority"
      :imgClassName="['checkout-component__image_img', cls]"
      :imgDesignWidth="imgDesignWidth"
      :isFirstPage="isFirstPage"
      :usePicture="usePicture"
      :use-picture="usePicture"
      :imgDataExp="imgDataExp"
      :alt="alt"
      :imgStyle="imgStyle"
      :loadImg="loadImg"
      :useOriginImg="useOriginImg"
    />

    <img
      v-if="imageMask"
      class="common-image_container"
      :src="imageMask"
    />
    <div class="not-fsp-element">
      <slot name="others"></slot>
    </div>
  </div>
</template>

<script setup name="CommonImage">
import { computed, provide } from 'vue'
import TransformImg from 'public/src/pages/components/transformImg'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

const props = defineProps({
  // 图片Url
  imgUrl: {
    type: String,
    default: '',
  },
  // 图片加载优先级【属性值可以为：high, low, auto（默认值）】
  fetchpriority: {
    type: String,
    default: 'auto',
  },
  // classname，用于添加比如"not-fsp-element"
  cls: {
    type: String,
    default: '',
  },
  // 图片设计稿宽度
  imgDesignWidth: {
    type: Number,
    default: 100,
  },
  // 首屏图片
  isFirstPage: {
    type: Boolean,
    default: false,
  },
  // Picture标签渲染
  usePicture: {
    type: Boolean,
    default: false,
  },
  imgDataExp: {
    type: Object,
    default: () => ({}),
  },
  alt: {
    type: String,
    default: '',
  },
  imgStyle: {
    type: Object,
    default: () => ({}),
  },
  loadImg: {
    type: String,
    default: '',
  },
  useOriginImg: {
    type: Boolean,
    default: false,
  },
  // 图片加载出来之前的背景，默认灰色
  isDefaultGrayGb: {
    type: Boolean,
    default: true,
  },
  imageMask: {
    type: String,
    default: '',
  },
})

// --------- useMap_**** --------
const { locals } = useMapState(['locals'])

const cutImg = (imgUrl, designWidth = 750, exp) => {
  const {
    deviceData = '',
    isSupportWeb = '',
    isSupprotCut = false,
    sceneMap = {},
  } = RESOURCE_SDK.value || {}

  const cutData = {
    deviceData,
    isSupportWebp: Boolean(isSupportWeb),
    /**【isSupprotCut】resourceSDK拼错了，所以这里只能这么写【历史问题】*/
    isSupprotCut,
    imgUrl,
    designWidth: Number(designWidth),
    sceneMap,
    exp,
  }

  return transfromAndCutImg(cutData)
}

// ---------- computed ----------
const RESOURCE_SDK = computed(
  () => locals.value?.RESOURCE_SDK ?? gbCommonInfo?.RESOURCE_SDK,
)

const isCutImg = computed(() => {
  return props.useOriginImg ? false : cutImg
})

provide('cutImg', isCutImg.value)
</script>

<style lang="less">
.checkout-component__image {
  width: 200/75rem;
  height: 200/75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background_color--);
  overflow: hidden;
  flex-shrink: 0;

  &_img {
    width: 100%;

    &-base {
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  .common-image_container {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
  }
}
</style>
