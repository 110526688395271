<template>
  <div
    v-if="showDetailTips"
    class="orderDetail-tip-box"
    :class="{ 'net-error': !onlineStatus }"
  >
    <!-- cod审单 -->
    <s-alert
      v-if="isShowCODCheckNotice"
      v-expose="{ id: '1-19-1-108', data: { order_no: orderInfo.billno } }"
      v-tap="{ id: '1-19-1-109', data: { order_no: orderInfo.billno } }"
      type="info"
      show-icon
      jumpable
      class="check-order-notice cod-check-notice"
      @click.stop.native="openCodRiskOrderEvt"
    >
      {{ isShowCODCheckNoticeText }}
    </s-alert>
    <!-- 风控 -->
    <s-alert
      v-else-if="isShowRisk && showRiskInfo"
      class="order-notice-tip"
      type="info"
      :show-icon="true"
      jumpable
      @click.stop.native="toVerification"
    >
      {{ sensitiveInfo.tip }}
    </s-alert>
    <!-- 海关拦截绿色通道 -->
    <div
      v-else-if="showInterCeptorNotice"
      class="alert-con custom-notice-alert"
      DA-view-type="ordinary"
      DA-sa-name="orderlist_abnormalpackage"
      :DA-sa-param="JSON.stringify({ orderid: orderInfo.billno })"
    >
      <swiper-container
        ref="customNoticeSwiper"
        init="false"
        autoplay-delay="5000"
        pagination="true"
        :loop="customText.length == 1 ? false : true"
      >
        <swiper-slide
          v-for="(item, noticeIndex) in customText"
          :key="noticeIndex"
        >
          <s-alert
            :show-icon="false"
            type="warning"
            jumpable
            class="detail-custom-tip"
            @click.stop.native="jumpToCustomsInterceptionEvt"
          >
            <sui_icon_notice_16px
              size="16px"
              color="#767676"
            />
            {{ item }}
          </s-alert>
        </swiper-slide>
      </swiper-container>
    </div>
    <!-- 履约异常 -->
    <alert-comp
      v-else-if="hasSpecialOutgoingOrder"
      :show="!!hasSpecialOutgoingOrder"
      :message="specialOutgoingOrderText"
      :closable="false"
      class="order-notice-tip"
    />
    <!-- CSP异常公告 -->
    <div
      v-else-if="isShowCspNotice"
      id="detail-csp-notice"
      v-expose="{ id: '1-19-1-100', data: { billno: orderInfo.billno, num: cspNoticeList.length} }"
    >
      <swiper-container
        init="false"
        autoplay-delay="5000"
        pagination="true"
        class="csp-notice-container"
        :loop="cspNoticeList.length == 1 ? false : true"
      >
        <swiper-slide
          v-for="(item, index) in cspNoticeList"
          :key="index"
          class="csp-notice-slide"
        >
          <div class="detail-csp-notice">
            <div class="tip-container">
              <div class="tip-content">
                <sui_icon_notice_16px size="16px" class="notice-icon" :is-rotate="locals.GB_cssRight" />
                <span class="tip-text">
                  {{ item.notice_content }}
                </span>
              </div>
            </div>
            <Icon
              v-tap="{ id: '1-19-1-101', data: { billno: orderInfo.billno, num: cspNoticeList.length, type: 'pop_up'} }"
              name="sui_icon_more_right_16px_2"
              size="16px"
              class="more-icon"
              :is-rotate="locals.GB_cssRight"
              @click="openNoticeModal"
            />
          </div>
        </swiper-slide>
      </swiper-container>
      <ClientOnly>
        <CspNoticeCommon
          :show="isShowCspDrawer"
          :page-from="'order_detail'"
          :billno="orderInfo.billno"
          :language="language"
          :csp-notice-list="cspNoticeListNew"
          :package-goods-list="packageGoodsList"
          :da-click-id="'1-19-1-102'"
          :da-expose-id="'1-19-1-103'"
          @close="() => isShowCspDrawer = false"
        />
      </ClientOnly>
    </div>
    <!-- ccc配置公告 -->
    <CommonNotice
      v-else-if="!!showDelayTrackText"
      class="delay-track-text"
      :message="showDelayTrackText"
    />
    <!-- COD拒收原因 -->
    <s-alert
      v-else-if="!!isShowRejectedReason"
      v-expose="{ id: '1-19-1-57' }"
      class="order-notice-tip"
      type="warning"
      jumpable
      @click.stop.native="toRejectReason"
    >
      {{ isShowRejectedReason == 1 ? language.SHEIN_KEY_PWA_18864 : language.SHEIN_KEY_PWA_31510 }}
    </s-alert>
    <!-- COD风控审单弹框 -->
    <ClientOnly>
      <CodRiskOrderPop
        :show="showCodRiskOrderPop"
        :language="language"
        :codRiskOrderText="codRiskOrderText"
        :codRiskPreOrderList="codRiskPreOrderList"
        :curOrder="orderInfo"
        :page-from="'order_detail'"
        @closeCodRiskOrderPop="closeCodRiskOrderEvt"
      />
    </ClientOnly>
  </div>
</template>
<script>
import ClientOnly from 'vue-client-only'
import { mapState } from 'vuex'
import { tipList } from '../../common/tipConfig'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { queryCodFrontOrderInfoFun } from 'public/src/pages/user/child_pages/orders/utils'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import orderLogic from 'public/src/pages/common/orderLogic'
import schttp from 'public/src/services/schttp'
import CommonNotice from '../../../../../common/commonNotice'
import AlertComp from './alertComp.vue'
import { useCustomsInterception } from 'public/src/pages/user/child_pages/orders/common/hooks/useCustomsText.js'

import { register } from 'swiper/element'
import { Pagination, Autoplay } from 'swiper/modules'
import { sui_icon_notice_16px } from '@shein-aidc/icon-vue2'
import { Icon } from '@shein-aidc/icon-vue2'

typeof window !== 'undefined' && register()
daEventCenter.addSubscriber({ modulecode: '1-19-1' })
const { langPath, PUBLIC_CDN } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'DetailTips',
  directives: { tap, expose },
  components: {
    ClientOnly,
    AlertComp,
    CommonNotice,
    Icon,
    sui_icon_notice_16px,
    CspNoticeCommon: () => import(/* webpackChunkName: "OrderDetailTips_Comp" */'public/src/pages/common/orderLogic/cspNoticeCommon.vue'),
    CodRiskOrderPop: () => import(/* webpackChunkName: "OrderDetailTips_Comp" */'public/src/pages/common/orderLogic/codRiskOrderPop.vue'),
  },
  props: {
    onlineStatus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowCspDrawer: false,
      cspNoticeListNew: {},
      touchDiff: 0,
      swiperInstance: null,
      showCodRiskOrderPop: false,
      codRiskOrderText: '',
      codRiskPreOrderList: [],
      packageGoodsList: []
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'orderInfo',
      'language',
      'orderResultAbtInfo',
      'detailNoticeInfo',
      'locals'
    ]),
    cspNoticeList () {
      let { abnormalNoticeInfo = {} } = this.detailNoticeInfo || {}
      let { notice_content_list = [] } = abnormalNoticeInfo || {}
      return notice_content_list?.sort((a, b) => b.submit_time - a.submit_time)?.slice(0, 3) || []
    },
    specialOutgoingOrderList () {
      let { specialOutgoingOrderList = [] } = this.detailNoticeInfo || {}
      return this.formatSpecialOutgoingOrder(specialOutgoingOrderList || [])
    },
    showRiskInfo () {
      return this.orderResultAbtInfo?.showRiskInfo
    },
    showDelayTrackText () {
      return this.detailNoticeInfo?.showDelayTrackText
    },
    showInterCeptorNotice () {
      return this.detailNoticeInfo?.showInterCeptorNotice
    },
    customText() {
      let { customsInterceptionPackageList = [] } = this.detailNoticeInfo || {}
      const { customsMessages } = useCustomsInterception(
        {
          customsInterceptionInfo: customsInterceptionPackageList,
          language: this.language
        }
      )
      return customsMessages
    },
    packageType () {
      let { customsInterceptionPackageList = [] } = this.detailNoticeInfo || {}
      if (!customsInterceptionPackageList?.length) return
      let typeList = []
      customsInterceptionPackageList?.forEach(item => {
        typeList.push(item.type)
      })
      return [...new Set(typeList)].join(',')
    },
    onlyMissType () {
      let { customsInterceptionPackageList = [] } = this.detailNoticeInfo || {}
      if (!customsInterceptionPackageList?.length) return
      let typeList = []
      customsInterceptionPackageList?.forEach(item => {
        typeList.push(item.type)
      })
      let missType = typeList.filter(item => item == 6) || []
      // 如果异常包裹待处理的类型为且仅为错漏发（type==6）
      return missType.length == typeList.length
    },
    isShowRejectedReason() {
      return orderLogic.getRejectedReason(this.orderResultAbtInfo.isRejectedReasonOn, this.orderInfo)
    },
    hasSpecialOutgoingOrder () {
      let { showSpecialOutgoing } = this.orderResultAbtInfo || {}
      let { specialOutgoingOrderList = [] } = this.detailNoticeInfo || {}
      return showSpecialOutgoing && specialOutgoingOrderList?.length
    },
    specialOutgoingOrderText () {
      let { billno } = this.orderInfo || {}
      let { specialOutgoingOrderList = [] } = this.detailNoticeInfo || {}
      let { orderType } = specialOutgoingOrderList?.find(item => item.billNo == billno) || {}
      let languageKey = tipList[orderType] || tipList.default
      return this.language[languageKey]
    },
    isShowCODCheckNotice () {
      let { showCodRiskOrder } = orderLogic.checkCodRiskOrder({
        order: this.orderInfo || {},
        language: this.language || {}
      })
      return showCodRiskOrder
    },
    isShowCODCheckNoticeText () {
      let { showCodRiskOrderText } = orderLogic.checkCodRiskOrder({
        order: this.orderInfo || {},
        language: this.language || {}
      })
      return showCodRiskOrderText
    },
    isShowCspNotice() {
      let { orderStatus } = this.orderInfo || {}

      return this.cspNoticeList?.length && ![0, 12, 13, 16, 11, 9, 8, 3, 5, 14, 17].includes(+orderStatus)
    },
    sensitiveItem() {
      let { orderStatus } = this.orderInfo || {}
      let { sensitiveList = [] } = this.detailNoticeInfo || {}
      // orderStatus非0/12/13-待支付、3-已取消、8-COD已取消时显示入口
      if([0, 12, 13, 3, 8].includes(+orderStatus) || !sensitiveList?.length) return {}
      // 优先取回参Info第一条状态为”no_submit:未填写“或”review_fail:审核失败“的数据进行处理。如果Info下不存在”未填写“和”审核失败“数据，则默认取第一条数据进行一下处理
      let firstItem = sensitiveList.find(item =>(item.sensitive_status === 'no_submit' && item.show_flag == 1))
      if(!firstItem) firstItem = sensitiveList.find(item =>item.sensitive_status === 'review_fail' )
      return firstItem || sensitiveList?.[0] || {}
    },
    riskStatus() {
      let { sensitive_status: sensitiveStatus, show_flag: showFlag, scene_type: sceneType } = this.sensitiveItem
      // "no_submit": 未提交 reviewing: 审核中 review_fail: 审核失败
      let noSubmitCond = ['no_submit'].includes(sensitiveStatus) && showFlag == 1
      let reviewingCond = ['reviewing'].includes(sensitiveStatus)
      let reviewFailCond = ['review_fail'].includes(sensitiveStatus) && [3, 4]?.includes(+sceneType)
      return { noSubmitCond, reviewingCond, reviewFailCond }
    },
    isShowRisk () {
      const { noSubmitCond, reviewingCond, reviewFailCond } = this.riskStatus
      return noSubmitCond || reviewingCond || reviewFailCond
    },
    sensitiveInfo () {
      let defaultInfo = {
        tip: '',
        status: '',
        country: '',
        sceneType: ''
      }
      if (!this.isShowRisk) return defaultInfo

      let { sensitive_status: sensitiveStatus, scene_type: sceneType, country } = this.sensitiveItem
      defaultInfo = {
        ...defaultInfo,
        country: country || '',
        status: sensitiveStatus || '',
        sceneType: sceneType || ''
      }
      
      // "no_submit": 未提交 reviewing: 审核中 review_fail: 审核失败
      const { reviewingCond, reviewFailCond } = this.riskStatus
      if (reviewingCond) {
        defaultInfo.tip = this.language?.SHEIN_KEY_PWA_19676 || ''
      } else if (reviewFailCond) {
        defaultInfo.tip = this.language?.SHEIN_KEY_PWA_26873 || ''
      } else {
        let textMap = {
          1: this.language.SHEIN_KEY_PWA_26871,
          3: this.language.SHEIN_KEY_PWA_26872,
          other: this.language.SHEIN_KEY_PWA_19359,
        }
        defaultInfo.tip = textMap[sceneType] || textMap['other'] || ''
      }
      return defaultInfo
    },
    showDetailTips () {
      return (this.isShowCODCheckNotice || 
              this.isShowRisk && this.showRiskInfo) ||
              !!this.isShowRejectedReason || 
              this.hasSpecialOutgoingOrder || 
              this.showInterCeptorNotice || 
              !!this.showDelayTrackText || this.isShowCspNotice
    }
  },
  mounted () {
    this.initSwiper()
    this.initCustomSwiper()
  },
  methods: {
    formatSpecialOutgoingOrder (specialOutgoingOrder = []) {
      let list = []
      specialOutgoingOrder.forEach(order => {
        let index = list.findIndex(item => {
          return item.billNo == order.billNo
        })
        if (index != -1) {
          list[index].orderType += `,${order.orderType}`
        } else {
          list.push(order)
        }
      })
      return list
    },
    toVerification() {
      daEventCenter.triggerNotice({
        daId: '1-19-1-59',
        extraData: {
          status: this.sensitiveInfo.status == 'no_submit' ? '0' : '1'
        }
      })
      this.$router?.push(`${langPath}/user/verification?billNo=${ this.orderInfo.billno }&sceneType=${this.sensitiveInfo.sceneType}&pageFrom=orderDetail`)
    },
    toRejectReason() {
      daEventCenter.triggerNotice({
        daId: '1-19-1-58'
      })
      this.$router?.push(`${langPath}/user/orders/rejectReason/${ this.orderInfo.billno }`)
    },
    initSwiper () {
      this.$nextTick(() => {
        const swiperEl = document.querySelector(`.csp-notice-container`)
        const params = {
          modules: [Pagination, Autoplay],
          injectStylesUrls: [
            PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
          ],
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            clickable: true
          },
          injectStyles: [`
            .swiper-pagination-bullet {
              width: 6px;
              height: 6px;
              opacity: 1;
              background: #cccccc;
              vertical-align: middle;
              display: inline-block;
              border-radius: 6px;
              margin: 0 2px !important;
              border: none;
            }
            `,
            `
            .swiper-pagination-bullet-active {
              width: 6px;
              height: 6px;
              background: #222;
              border: none;
            }
            `,
            `
            .swiper-pagination-bullets {
              line-height: 1;
              bottom: 5px !important;
            }
          `],
          on: {
            touchEnd: (swiper) => {
              if (swiper?.touches?.diff != this.touchDiff) {
                daEventCenter.triggerNotice({
                  daId: '1-19-1-101',
                  extraData: {
                    billno: this.orderInfo.billno, 
                    num: this.cspNoticeList.length, 
                    type: 'roll',
                  }
                })
              }
              this.touchDiff = swiper?.touches?.diff
            }
          }
        }
        if (swiperEl) {
          Object.assign(swiperEl, params)
          swiperEl.initialize()
        }
      })
    },
    async fetchAbnormalNotice(conditionList = [{ notice_status_list: [1] }], pageCodeList = ['order_detail']) {
      let data = await schttp({
        url: '/api/orders/track/abnormalNotice/query',
        method: 'POST',
        data: {
          bill_no_list: [this.orderInfo.billno],
          page_code_list: pageCodeList,
          condition_list: conditionList
        },
      })
      return data?.info?.notice_list[0]
    },
    async openNoticeModal() {
      const conditionList = [
        {
          is_history_notice: [1],
        },
        {
          is_history_notice: [0],
          notice_status_list: [1]
        }
      ]
      const pageCodeList = ['track', 'order_list', 'order_detail']
      this.isShowCspDrawer = true
      if(!Object.keys(this.cspNoticeListNew).length || !this.packageGoodsList.length) {
        const [noticeList] = await Promise.all(
          [this.fetchAbnormalNotice(conditionList, pageCodeList), this.fetchTrack()]
        )
        this.cspNoticeListNew = noticeList
      }
    },
    async fetchTrack() {
      let data = await schttp({
        url: '/api/orders/track/orderTrack/get',
        method: 'GET',
        params: {
          billno: this.orderInfo.billno,
        },
      })

      this.packageGoodsList = data?.orderInfo?.packageGoodsList || []
    },
    async openCodRiskOrderEvt () {
      let res = await queryCodFrontOrderInfoFun(this.orderInfo)
      if (res?.code == '0' && !!res?.info?.risk_desc) {
        this.codRiskOrderText = res?.info?.risk_desc || ''
        this.codRiskPreOrderList = res?.info?.preorder_list || []
        this.showCodRiskOrderPop = true

        daEventCenter.triggerNotice({
          daId: '1-19-1-110',
          extraData: {
            order_no: this.orderInfo?.billno || ''
          }
        })
      } else {
        this.$toast(this.language?.SHEIN_KEY_PWA_25888)
      }
    },
    closeCodRiskOrderEvt () {
      this.showCodRiskOrderPop = false
    },
    initCustomSwiper() {
      this.$nextTick(() => {
        const swiperEl = this.$refs.customNoticeSwiper
        const params = {
          modules: [Pagination, Autoplay],
          injectStylesUrls: [
            PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
          ],
          injectStyles: [`
            .swiper-pagination-bullet {
              width: 4px;
              height: 4px;
              opacity: 1;
              background: #cccccc;
              vertical-align: middle;
              display: inline-block;
              border-radius: 10px;
              margin: 0 2px !important;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullet-active {
              width: 4px;
              height: 4px;
              background: #222;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullets {
              line-height: 1;
              bottom: 4px !important;
            }
          `],
        }

        if (swiperEl) {
          Object.assign(swiperEl, params)
          swiperEl.initialize()
        }
      })
    },
    jumpToCustomsInterceptionEvt() {
      const onlyReturn = this.customText.length == 1 && this.customText[0] == this.language.SHEIN_KEY_PWA_34583
      this.$router.push({
        path: `${langPath}/user/orders/customsInterception/${this.orderInfo?.billno}`,
        query: {
          source: 'orderdetail',
          customType: onlyReturn ? 2 : 1
        }
      })
      sa('send', {
        activity_name: 'click_viewinterceptionpackage',
        activity_param: {
          type: this.packageType,
        }
      })
    }
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.order-notice-tip{
	width: 100%;
	.S-alert__type-info{
		background-color: #FCF0D4;
	}
}
.orderDetail-tip-box{
  margin-bottom: 20/75rem;
  .delay-track-text{
    word-break: break-word;
  }
  &.net-error {
    margin-bottom: 0;
  }
}
.cod-check-notice,
#detail-csp-notice {
  background-color: @sui_color_prompt_warning !important; /* stylelint-disable-line declaration-no-important */
}
.detail-custom-tip {
  .S-alert__description {
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="less" scoped>
.tip-container {
  .tip-content {
    display: flex;
    align-items: center;
  }
  .notice-icon {
    margin-right: 16/75rem;
    color: #767676;
  }
  .tip-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.csp-notice-container {
  height: 120/75rem;
  display: flex;
  flex-wrap: nowrap;
}
.detail-csp-notice {
  font-size: 24/75rem;
  padding: 24/75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .more-icon {
    color: #959595;
    margin-left: 24/75rem;
  }
}
.csp-notice-slide {
  flex-shrink: 0;
  width: 100%;
}
.custom-notice-alert {
  .S-alert {
    padding: 24/75rem;
    min-height: 120/75rem;
  }
}
</style>
