import { ref, watch, computed, onUnmounted } from 'vue'
import { debuggerLog } from 'public/src/pages/checkout_new/utils/index.js'
import { template } from '@shein/common-function'
import { orderSectionId } from 'public/src/pages/checkout_new/pages/shopping_bags/utils/index.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

export const useAttributeWidth = () => {
  const childrenRef = ref(null)
  const fatherRef = ref(null)
  // true 为子元素超过父元素宽度，false 为没超过父元素宽度
  const isExceedWidth = ref(false)

  watch([childrenRef, fatherRef ], ([newChild, newFather]) => {
    if(newChild && newFather){
      isExceedWidth.value = childrenRef?.value && fatherRef.value && !(childrenRef?.value?.clientWidth < fatherRef.value?.clientWidth)
      debuggerLog('子元素是否超出父元素宽度 childrenRef', childrenRef.value?.clientWidth)
      debuggerLog('子元素是否超出父元素宽度 fatherRef', fatherRef.value?.clientWidth)
      debuggerLog('子元素是否超出父元素宽度：', isExceedWidth.value)
    }
  })

  return {
    childrenRef,
    fatherRef,
    isExceedWidth
  }
}

export const useDelectBubble = (props) => {
  const delectBubbleVisible = ref(false)
  const timer = ref(null)
  const bubbleRef = ref(null)

  const delectBubbleInfo = computed(() => {
    const bubble = props.data?.aggregateProductBusiness?.shopping_bag_delete_m2n_bubble_lure?.find(f => f?.type == 'wonderful_comment') ?? {}
    const { guideTip, appBuriedPoint } = bubble?.commentTag ?? {}
    const replaceText = guideTip?.replaceText?.[0]
    replaceText && log('bubble==', props.data)
    let result = null
    if(replaceText){
      const text = template(`<span style="color:${replaceText.color};font-weight:${replaceText.strong == 1 ? 700 : 400}">${replaceText.text}</span>`, guideTip.tip)
      result = { 
        text,
        icon: bubble.icon,
        appBuriedPoint
      }
    }
    return result
  })

  const log = (...args) => {
    debuggerLog('useDelectBubble::', args )
  }

  const changeDelectBubble = (val = false, id = 0) => {
    const orderId = orderSectionId(props.data)
    const dom = document.getElementById(orderId)
    if(val && id && delectBubbleInfo.value){
      timerBubble('end')
      delectBubbleVisible.value = true
      timerBubble('start')
      window && window.addEventListener('scroll', handleScroll)
      dom && dom.addEventListener('scroll', handleScroll)
    }else{
      debuggerLog('useDelectBubble::', '触发关闭' )
      timerBubble('end')
    }
  }

  const timerBubble = (type = 'start') => {
    const map = {
      start: () => {
        timer.value = setTimeout(() => {
          changeDelectBubble(false)
        }, 2000)
      },
      end: () => {
        delectBubbleVisible.value = false
        if(timer.value){
          log('销毁定时器')
          clearTimeout(timer.value)
          timer.value = null
        }
      }
    }
    return map[type]()
  }

  const handleScroll = () => {
    timerBubble('end')
  }

  const close = () => {
    daEventCenter.triggerNotice({
      id: 'click_ugctags_bubble:simple',
      data: {
        comments_tag: delectBubbleInfo.value.appBuriedPoint,
      }
    })
  }

  onUnmounted(() => {
    const orderId = orderSectionId(props.data)
    const dom = document.getElementById(orderId)
    window && window.removeEventListener('scroll', handleScroll)
    dom && dom.removeEventListener('scroll', handleScroll)
  })

  return {
    delectBubbleVisible, changeDelectBubble, delectBubbleInfo, bubbleRef, close
  }
}
