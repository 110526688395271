<template>
  <div class="bsc-gc-tablist">
    <STab
      v-if="hasCardList && getTabList.length > 0 && !!language"
      v-model="tabIndex"
      @change="(v) => handleTabIndex(v.val)"
    >
      <STabItem
        v-for="(tabName, index) in getTabList"
        :id="index"
        :key="tabName"
        :style="{
          fontWeight: tabIndex === index ? '600' : '400'
        }"
      >
        {{ tabName }}
      </STabItem>
    </STab>
    <slot></slot>
    <div
      v-if="props?.isShowNoCardIcon"
      class="bsc-gc-tablist__empty-list">
      <img
        class="img"
        src="https://img.ltwebstatic.com/images3_ccc/2024/12/05/c7/1733368908e195c8b58a1ae0b15ad6d8bd9cc32b77.webp"
      />
      <div class="text">
        {{ tabIndex === 0 ? language?.SHEIN_KEY_PWA_35143 : language?.SHEIN_KEY_PWA_35144 }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, computed, watch, type Ref } from 'vue'
import { Tab as STab, TabItem as STabItem } from '@shein/sui-mobile'
import { E_SCENE, type C_GiftCard } from '../../../types/index.ts'

const language: C_GiftCard.language = inject('language')!
const cardData: Ref<C_GiftCard.CardData> = inject('cardData')!
const { triggerNotice } = inject('analysisInstance') as any
const scene: Ref<`${E_SCENE}`> = inject('scene')!

const tabIndex = ref<number>(0)
const hasCardList = ref<boolean>(true)

const getTabList = computed(() => ([language?.value?.SHEIN_KEY_PWA_22586, language?.value?.SHEIN_KEY_PWA_35099]))

const props = defineProps<{
  isShowNoCardIcon: boolean
}>()

const emits = defineEmits(['updateCardList', 'updateTabIndex'])

const handleTabIndex = (index: number) => {
  tabIndex.value = index
  if (scene.value === E_SCENE.Checkout) {
    triggerNotice?.({
      id: 'click_giftcard_tab:simple',
      extraData: {
        tab_type: index === 0 ? 'available' : 'unavailable',
      },
    })
  }
}

const getCardList = (index, data) => {
  const { available_card_list = [], unavailable_card_list = [] } = data || {}
  hasCardList.value = !(available_card_list.length === 0 && unavailable_card_list.length === 0)
  return index === 0 ? available_card_list : unavailable_card_list
}

watch(() => [tabIndex.value, cardData.value], (v) => {
  const [index, data] = v
  emits('updateCardList', getCardList(index, data))
  emits('updateTabIndex', index)
}, { immediate: true })

defineExpose({
  handleTabIndex,
})

</script>

<style lang="less">
.bsc-gc-tablist {
  &__empty-list {
    margin-top: 1.7333rem;
    text-align: center;
    .img {
      width: 2rem;
    }
    .text {
      margin-top: .5333rem;
      color: #222;
      text-align: center;
      font-size: .3733rem;
      font-weight: 400;
    }
  }
}
</style>
