<template>
  <Size
    ref="SIZE_BOX"
    class="goods-size_dom"
    :customerStyle="customerStyle"
    :deliveryFloorQuickshipTitle="deliveryFloorQuickshipTitle"
    :config="config"
    :sizeConstant="sizeConstant"
    :skcSaleAttr="skcSaleAttr"
    :skuList="skuList"
    :realTimeSizeInfo="realTimeSizeInfo"
    :skuInfo="skuInfo"
    :sizeAttrInfo="sizeAttrInfo"
    :country="currentCountry"
    :sizeUnit="currentUnit"
    :isOnSale="isOnSale"
    :isBaseSize="isBaseSize"
    :dataMapCompose="dataMapCompose"
    :goodsId="goodsId"
    :mallCode="mallCode"
    :language="language"
    :localSizeLanguage="localSizeLanguage"
    :willSoldOutTipsInfo="willSoldOutTipsInfo"
    :stockTipShowLocation="stockTipShowLocation"
    :hideSizeModule="hideSizeModule"
    :saleAttrGroups="saleAttrGroups"
    :globalPerfectFitShow="salesAttrConfig.globalPerfectFitShow || false"
    :sizeTipsInfo="sizeTipsInfo"
    :showMallList="showMallList"
    :mallInfoList="mallInfoList"
    :defaultCountryCode="defaultCountryCode"
    :sizeRecommendInfo="sizeRecommendInfo"
    :externalSizeInfoIndex="externalSizeInfoIndex"
    :externalSkuCode="externalSkuCode"
    :mallClick="handleMallClick"
    :handleUpdateGlobalPerfectFitShow="handleUpdateGlobalPerfectFitShow"
    :handleUpdateQuickShip="handleUpdateQuickShip"
    :handleCompose="handleCompose"
    :handleSizeRecommend="handleSizeRecommend"
    :handleClickSizeGroupItem="handleClickSizeGroupItem"
    :handleOpenQuickCart="handleOpenQuickCart"
    :handleLocalChange="handleLocalChange"
    :handleOpenSizeGuide="handleOpenSizeGuide"
    :handleOpenFindSimilar="handleOpenFindSimilar"
  />
</template>

<script name="QuickCartSize" setup lang="ts">
import { ref, computed, defineProps, onMounted, onBeforeUnmount, watch, nextTick, defineExpose } from 'vue'
import { useModules } from '../../modules/useModules'
import { template, replaceHrefSpecChar } from '@shein/common-function'
import Size from '@shein-aidc/bs-sub-sales-attr-mobile-vue2'
import { callHook } from '../FooterBar/utils'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  salesAttrConfig: {
    type: Object,
    default: () => ({}),
  },
  analysisConfig: {
    type: Object,
    default: () => ({}),
  },
  sourceIdentifier: {
    type: String,
    default: '',
  },
  callbacks: {
    type: Object,
    default: () => ({}),
  },
  defaultCountryCode: {
    type: String,
    default: '',
  },
  currentCountry: {
    type: String,
    default: '',
  },
  currentUnit: {
    type: String,
    default: '',
  },
  stockTipShowLocation: {
    type: String,
    default: '',
  },
  skuInfo: {
    type: Object,
    default: () => ({}),
  },
  mallCode: {
    type: String,
    default: '',
  },
  willSoldOutTipsInfo: {
    type: Object,
    default: () => ({}),
  },
  closeQuickAddCart: {
    type: Function,
    default: () => { },
  },
  handleCompose: {
    type: Function,
    default: () => { },
  },
  handleMallClick: {
    type: Function,
    default: () => { },
  },
  handleLocalChange: {
    type: Function,
    default: () => { },
  },
  handleOpenSizeGuide: {
    type: Function,
    default: () => { },
  },
  handleClickSizeGroupItem: {
    type: Function,
    default: () => { },
  },
  handleUpdateQuickShip: {
    type: Function,
    default: () => { },
  },
})
const { staticSaleAttr, saleAttr, productInfo, language, mallInfo, gbCommonInfo, fsAbt } = useModules()
const customerStyle = { bold: false }
const SIZE_BOX: any = ref(null)
const currentMySize = ref('')
const currentMyBraSize = ref('')
const sizeConstant = computed(() => {
  return {
    cssRight: gbCommonInfo?.value?.cssRight || false,
  }
})
const config = computed(() => {
  // {
  //     qty: { isShow: props.qtySelectShow }, => 不需要了改成stockTipShowLocation
  //     sizeBubblePlan, => done showNewBubbleStyle
  //     attrLowStockTipsPlan, => done
  //     isAutoSwitchMall, => done
  //     defaultlocalsize, => done
  //     isNewSizeLocal: props.isNewSizeLocal, => done
  //     showSizeGroupPosition, => done
  //     showNewSizeGuide, => done
  //     showSizeAttributeEntrance, => done
  //     showMallSite: props.showMallSite, => 不需要了
  //     showQuickShip: true, => done
  //     showSizeRecommend: true, => done 商详才能打开
  //     showFeedBack, => done
  //     isAttrFold, => done
  //     isNoSkuClick, => 已经推全了
  //     showOnlyOneLocalSize, => done
  //     sizeRule: sizeRule || {}, => 没用了
  //     longSizeTowLine, => 推全了
  //     sizeUnFold, => 推全了
  //     showTaxation, => 没用了
  //     showMultiLayer: true, // 关闭折叠显示 => done
  //     from, => done
  //     globalPerfectFitShow: => done,
  //     hideOneSize: props.hideOneSize, => done hideSizeModule
  //     openAutoCheckMall, => done
  //     isShowAttrPicInfo: props.isShowAttrPicInfo, => done
  //     hideRelatedSize: !props.config?.isShowAttrPlusSize, => done
  //     hideSizeGroupeSize: props.config?.hideSizeGroupeSize, => done
  //     getSkcEstimatedInfo: props.getSkcEstimatedInfo, => 没用了
  //     getEstimatedInfo: props.getEstimatedInfo, => 没用了
  //     qtySelectShow: props.qtySelectShow, => 没用了
  //     hiddenPartialQuickShip, => done
  //     shouldWeakenDefaultSize => done
  //   }
  const isAutoSwitchMall = fsAbt?.value?.goodsdetailmall?.param?.mallautoswitch === 'on'
  const defaultlocalsize = fsAbt?.value?.defaultlocalsize?.param?.defaultlocalsize || 'old'
  const onlyonelocalsize = fsAbt?.value?.defaultlocalsize?.param?.onlyonelocalsize || 'off'
  const isNewSizeLocal = props.sourceIdentifier === 'detailPage'
  const goodsSizeGroupRelated = fsAbt?.value?.goodsSizeGroupRelated?.param?.goodsSizeGroupRelated
  const showSizeGroupPosition = ['mid', 'bottom', 'fold', 'size'].includes(goodsSizeGroupRelated) && goodsSizeGroupRelated
  // const showNewSizeGuide = !!props.salesAttrConfig?.showTitleSizeGuide
  const showSizeAttributeEntrance = fsAbt?.value?.SizeAttributeEntrance?.param?.SizeAttributeEntrance === 'on' && fsAbt?.value?.sizeguideshow?.param?.sizeguideshow === 'B'
  const { isDisplayFeedbackTips } = staticSaleAttr?.value || {}
  const showSizeFeedBackEnter = props.salesAttrConfig?.showSizeFeedBackEnter && isDisplayFeedbackTips === '1'
  const isAttrFold = props.analysisConfig?.isAttrFold || '0'
  const showOnlyOneLocalSize = ['on', 'new_on'].includes(onlyonelocalsize)
  const openAutoCheckMall = fsAbt?.value?.mallchange?.param?.mallshow === 'auto'
  const isShowAttrPicInfo = fsAbt?.value?.goodsMainAttPicShow?.param?.goodsMainAttPicShow === 'on'
  const hideSizeGroupeSize = props.salesAttrConfig?.hideSizeGroupeSize
  const hiddenPartialQuickShip = fsAbt?.value?.goodsdetailTips?.param?.quickshipshow === 'off'
  const shouldWeakenDefaultSize = fsAbt?.value?.defaultlocalsize?.param?.localstrong === 'on'
  const attrLowStockTipsPlan = fsAbt?.value?.outofstocktips?.param?.attrlowstocktips || 'off'
  const showNewBubbleStyle = fsAbt?.value?.goodsdetailTips?.param?.sizebubble === 'A'
  const hideRelatedSize = !props.salesAttrConfig?.showAttrPlusSize || false
  const onlyOneLocalSizeNewOn = fsAbt?.value?.defaultlocalsize?.param?.onlyonelocalsize === 'new_on'
  const swapSizeTabPosition = fsAbt?.value?.defaultlocalsize?.param?.size_tab_sort === 'new' && defaultlocalsize === 'old'
  const showSizeRecommend = props.salesAttrConfig?.showSizeRecommend
  const notShowDefaultSizeText = defaultlocalsize === 'local_size_no_default' || defaultlocalsize === 'default_size_no_default'
  return {
    hideRelatedSize,
    showNewBubbleStyle,
    isAutoSwitchMall,
    defaultlocalsize,
    notShowDefaultSizeText,
    isNewSizeLocal,
    showSizeGroupPosition,
    // showNewSizeGuide,
    showSizeAttributeEntrance,
    showQuickShip: true,
    showSizeRecommend,
    showSizeFeedBackEnter,
    isAttrFold,
    showOnlyOneLocalSize,
    showMultiLayer: true,
    needInitSizeInfoIndex: true,
    from: 'popup',
    openAutoCheckMall,
    isShowAttrPicInfo,
    hideSizeGroupeSize,
    hiddenPartialQuickShip,
    shouldWeakenDefaultSize,
    attrLowStockTipsPlan,
    onlyOneLocalSizeNewOn,
    swapSizeTabPosition,
  }
})
const skcSaleAttr = computed(() => staticSaleAttr?.value?.multiLevelSaleAttribute?.skc_sale_attr || [])
const skuList = computed(() => staticSaleAttr?.value?.multiLevelSaleAttribute?.sku_list || [])
const realTimeSizeInfo = computed(() => {
  const { sku_list, comboStock } = saleAttr?.value?.multiLevelSaleAttribute || {}
  const { dataMap } = comboStock || {}
  return {
    realDataMap: dataMap,
    realSkuList: sku_list,
  }
})
const sizeAttrInfo = computed(() => skcSaleAttr.value?.find?.(item => item?.isSize === '1') || {})
const isOnSale = computed(() => productInfo?.value?.is_on_sale == '1')
const isBaseSize = computed(() => staticSaleAttr?.value?.isBraRecommendSize === '1')
const dataMapCompose = computed(() => staticSaleAttr?.value?.multiLevelSaleAttribute?.comboStock || {})
const goodsId = computed(() => productInfo?.value?.goods_id || '')
const hideSizeModule = computed(() => {
  const { attr_value_list = [], isDisplayOneSize } = skcSaleAttr.value?.[0] || {}
  if (skcSaleAttr.value?.length > 1 || attr_value_list?.length > 1) return false
  const { attr_value_id } = attr_value_list?.[0] || {}
  return isDisplayOneSize === '0' && attr_value_id === '474'
})
const saleAttrGroups = computed(() => staticSaleAttr?.value?.saleAttrGroups || {})
const mallInfoList = computed(() => mallInfo?.value?.mallInfoList || [])
const showMallList = computed(() => !!(mallInfo?.value?.isShowMall == '1' && mallInfoList.value?.length))
const deliveryFloorQuickshipTitle = computed(() => {
  const Quickshipdetailfloorshow = fsAbt?.value?.quickshipdetailshow?.param?.Quickshipdetailfloorshow || ''
  const floorQuickshipStyle = Quickshipdetailfloorshow === 'quickship' // quickship强化样式
  const floorNDeliveryStyle = +(Quickshipdetailfloorshow) > 0 // n天达
  const floorNDeliveryNumber = +(Quickshipdetailfloorshow) ? +(Quickshipdetailfloorshow) : 0
  const { SHEIN_KEY_PWA_30599, SHEIN_KEY_PWA_30600 } = language?.value || {}
  let text: any = ''
  // TODO: quick_ship_time好像不需要做这个判断
  if ((floorQuickshipStyle || floorNDeliveryStyle)) { // 命中quickship增强样式 && 要有qs时效
    text = floorQuickshipStyle ? SHEIN_KEY_PWA_30599 : template(floorNDeliveryNumber, SHEIN_KEY_PWA_30600 || '{0} Days Delivery')
  }
  return text
})
const localSizeLanguage = computed(() => {
  const {
    SHEIN_KEY_PWA_16223,
    SHEIN_KEY_PWA_23345,
    SHEIN_KEY_PWA_23343,
    SHEIN_KEY_PWA_15254,
  } = language?.value || {}
  return {
    SHEIN_KEY_PWA_16223,
    SHEIN_KEY_PWA_23345,
    SHEIN_KEY_PWA_23343,
    SHEIN_KEY_PWA_15254,
  }
})
const sizeTipsInfo = computed(() => {
  const { attrModuleComponentConfig } = productInfo?.value || {}
  const { detailAttrTips } = attrModuleComponentConfig || {}
  const { heelHeightRangeTips, attr_size_tips } = staticSaleAttr?.value || {}
  const { reviewSizeFitState, trueToSizePercent } = saleAttr?.value || {}
  const tipsshow = fsAbt?.value?.goodsdetailTips?.param?.tipsshow === 'default'
  // ccc优先级 > 评论偏大偏小 > pdc
  let tipText: any = ''
  let tipType = ''
  let tipLogFrom = ''
  if (detailAttrTips) {
    tipText = detailAttrTips || ''
    tipType = 'ccc'
    tipLogFrom = 'ccc'
  } else if (trueToSizePercent) {
    tipType = 'tts'
    tipLogFrom = 'reviews'
  } else if (reviewSizeFitState) {
    tipText = reviewSizeFitState == '1' ? language?.value?.SHEIN_KEY_PWA_23773 : language?.value?.SHEIN_KEY_PWA_23774
    tipType = 'fit'
    tipLogFrom = 'reviews'
  } else if (attr_size_tips) {
    tipText = attr_size_tips || ''
    tipType = 'pdc'
    tipLogFrom = 'pdc'
  }
  return {
    heelHeightRangeTips,
    tipText,
    tipType,
    isLargeSizeTips: reviewSizeFitState == '1',
    trueToSizePercent,
    tipLogFrom,
    tipsshow,
  }
})
const sizeRecommendInfo = computed(() => {
  const { shoesRecRuleId, ruleType, size_guide_url } = staticSaleAttr?.value || {} as any
  const SelfsizerecommendedParam = fsAbt?.value?.Selfsizerecommended?.param || ''
  const isSupportSizeRecommend = SelfsizerecommendedParam !== 'type=C'
  const isSupportExternalRecommend = false
  const isSupportCustomRecommend = shoesRecRuleId > 0 && isSupportSizeRecommend
  const sizeRecommendType = isSupportCustomRecommend ? 1 : (isSupportExternalRecommend ? 2 : 0)
  const sizeguideshow = fsAbt?.value?.sizeguideshow?.param?.sizeguideshow || ''
  const sizeGuidAlone = fsAbt?.value?.sizeguideyouhua520?.param?.sizeguide_alone === 'on'

  return {
    sizeRecommendType,
    ruleType,
    showSizeGuide: !!size_guide_url && props.salesAttrConfig?.showTitleSizeGuide && sizeguideshow !== 'A',
    sizeGuidAlone,
    currentMySize: currentMySize.value,
    currentMyBraSize: currentMyBraSize.value,
  }
})
const productChange = computed(() => {
  return {
    product_relation_id: productInfo?.value?.productRelationID,
    goods_id: productInfo?.value?.goods_id,
  }
})
const externalSizeInfoIndex = computed(() => props.salesAttrConfig?.selectedAttrIndex)
const externalSkuCode = computed(() => props.salesAttrConfig.selectedAttrSkuCode)
const handleChangeRecommendSize = (val) => {
  if (!props.visible) return
  // if (!props.salesAttrConfig?.saleAttrsFoldAb) return
  // const { currentMySize, currentMyBraSize } = val || {}
  if (val?.currentMySize) {
    currentMySize.value = val?.currentMySize
  }
  if (val?.currentMyBraSize) {
    currentMyBraSize.value = val?.currentMyBraSize
  }
}
const getGoodsUrl = (name = '', id) => {
    name = (name == '-' || !name) ? 'product' : name
    const url = ('/' + name.replace('/', '-').split(' ').join('-') + '-p-' + id + '.html')
    return replaceHrefSpecChar({ href: url })
}

const handleOpenQuickCart = (item) => {
  // 加车弹窗不需要跳转，直接打开新页面
  const { langPath } = gbCommonInfo?.value || {}
  if (typeof window === 'undefined') return
  props.closeQuickAddCart?.()
  const url = `${langPath}${getGoodsUrl(item.goods_url_name, item.goods_id)}`
  const appConfig = useAppConfigs()
  if (appConfig?.$router) {
    appConfig.$router?.push?.(url)
  } else {
    window.location.href = url
  }
}

const handleUpdateGlobalPerfectFitShow = (status) => {
  callHook(props.callbacks, 'onSubAttrClick', {
    type: 'perfectFitShowStatus',
    value: status,
  })
}

const handleSizeRecommend = () => {
  callHook(props.callbacks, 'onSubAttrClick', {
    type: 'sizeRecommend',
  })
}

const handleOpenFindSimilar = (params) => {
  callHook(props.callbacks, 'onSubAttrClick', {
    type: 'findSimilar',
    value: params,
  })
}

const checkSelectSize = () => {
  return SIZE_BOX.value?.checkSelectSize?.()
}
defineExpose({
  checkSelectSize,
})
watch(() => props.salesAttrConfig?.recommendMySize, (val) => {
  currentMySize.value = val || ''
}, {
  immediate: true,
})
watch(() => props.salesAttrConfig?.recommendMyBraSize, (val) => {
  currentMyBraSize.value = val || ''
}, {
  immediate: true,
})
watch(productChange, (val, oldVal) => {
  const isNewSpu = val?.product_relation_id !== oldVal?.product_relation_id
  const isNewGood = val?.goods_id !== oldVal?.goods_id
  const isNewSkc = isNewGood && !isNewSpu
  if (isNewSkc || isNewSpu) {
    nextTick(() => {
      SIZE_BOX.value?.resetSizeBox?.({
        isNewSpu,
        isNewSkc,
      })
      SIZE_BOX.value?.allDataReadyCallBack?.()
    })
  }
})
onMounted(() => {
  // SIZE_BOX.value?.resetSizeBox?.()
  SIZE_BOX.value?.allDataReadyCallBack?.()
  const { vBus } = window as any
  vBus?.$on?.('changeRecommendSize', handleChangeRecommendSize)
})
onBeforeUnmount(() => {
  const { vBus } = window as any
  vBus?.$off?.('changeRecommendSize', handleChangeRecommendSize)
})
// ref="SIZE_BOX" => done
// class="goods-size_dom" => done
// customerStyle={customerStyle} => done
// deliveryFloorQuickshipTitle={this.delivery_floor_quickship_title} => done
// config={this.config_v2} => done
// sizeConstant={this.size_constant} => done
// skcSaleAttr={this.skc_sale_attr} => done
// skuList={this.cache_sku_list} => done
// realTimeSizeInfo={this.real_time_size_info} => done
// skuInfo={this.sku_info} => done
// sizeAttrInfo={this.size_attr_info} => done
// country={this.current_country} => done
// sizeUnit={this.current_unit} => done
// isOnSale={this.is_on_sale} => done
// isBaseSize={this.is_base_size} => done
// dataMapCompose={this.data_map_compose} => done
// goodsId={this.goods_id} => done
// mallCode={this.mall_code} => done
// language={this.language_v2} => done
// localSizeLanguage={this.local_size_language} => done
// willSoldOutTipsInfo={this.will_sold_out_tips_info} => done
// stockTipShowLocation={this.stock_tip_show_location} => done
// hideSizeModule={this.hide_one_size_module} => done
// saleAttrGroups={this.sale_attr_groups} => done
// globalPerfectFitShow={this.globalPerfectFitShow} => done
// mallClick={this.mallClickHandle} => done
// sizeTipsInfo={this.size_tips_info} => done
// showMallList={this.show_mall_list} => done
// mallInfoList={this.mall_info_list} => done
// defaultCountryCode={this.default_country_code} => done
// sizeRecommendInfo={this.size_recommend_info} => done
// externalSizeInfoIndex={this.externalSizeInfoIndex} => done
// externalSkuCode={this.externalSkuCode} => done
// handleUpdateGlobalPerfectFitShow={this.updateGlobalPerfectFitShow} => done
// handleUpdateQuickShip={this.updateQuickShip} => done
// handleCompose={this.handleCompose} => done
// handleSizeRecommend={this.handleSizeRecommend} => done
// handleClickSizeGroupItem={this.handleClickSizeGroupItem} => done
// handleOpenQuickCart={this.handleOpenQuickCart} => done
// handleClickToBuy={this.handleClickToBuy} => 不需要了
// handleLocalChange={this.updateCurrentLocalCountry} => done
// handleOpenSizeGuide={this.openSizeGuideHandle} => done
</script>
