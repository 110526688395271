import type { Ref } from 'vue'
import { getCurrentScope, onScopeDispose, watch } from 'vue'

export function useEventListener<E extends keyof HTMLElementEventMap>(
  target: Ref<HTMLElement | null | undefined> | (() => HTMLElement | null | undefined),
  event: E,
  listener: (ev: HTMLElementEventMap[E]) => void,
  options: AddEventListenerOptions = {},
) {
  let cleanup: Function = () => undefined

  const stopWatch = watch(
    target,
    (el) => {
      cleanup()
      if (!el) return

      el.addEventListener(event, listener, options)
      cleanup = () => el.removeEventListener(event, listener, options)
    },
    { immediate: true, flush: 'post' },
  )

  function stop() {
    stopWatch()
    cleanup()
  }

  if (getCurrentScope()) {
    onScopeDispose(stop)
  }

  return stop
}
