import * as standard from './sources/standard'
import { organizeDataSource, createFsDataRunnerBySource } from '@shein-aidc/utils-data-source-toolkit'
import type { DS_QuickAddCartStandard } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_QuickAddCartStandard.SourceParams): DS_QuickAddCartStandard.APIS {
  return organizeDataSource<DS_QuickAddCartStandard.APIS, DS_QuickAddCartStandard.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_QuickAddCartStandard.FS_SourceParams) {
  const { getGoodDetailStaticData } = getSource(sourceParams)
  return createFsDataRunnerBySource<DS_QuickAddCartStandard.FS_APIS, DS_QuickAddCartStandard.FS_DATA>({
    getGoodDetailStaticData,
  })
}
