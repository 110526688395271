<template>
  <div :class="prefixCls('header-title')">
    <component 
      :is="titleConfig.compName"
      v-bind="titleConfig.props"
    >
      <DefaultComponent v-if="titleConfig.showDefalutTitle" />
    </component>
  </div>
</template>
<script setup name="HeaderTitle">
import { defineComponent, h, computed } from 'vue'

import AutoScrollUp from './Auto.vue' // 滚动
import OperationScrollUp from './Operation.vue' // 手动触发

import { prefixCls } from '@/public/src/pages/checkout_new/pages/header/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

// 使用 defineComponent 定义内部组件作为默认title
const DefaultComponent = defineComponent({
  setup() {
    // 内部组件逻辑
    return () =>
      h(
        'div',
        // 设置 class
        { class: prefixCls('ht__default-title') },
        // 设置显示的内容
        language.value.SHEIN_KEY_PWA_15245 // Order Confirmation
      )
  }
})

// -------- useMapState --------
const { checkout, language, abtInfo } = useMapState(['checkout', 'language', 'abtInfo'])

// --------- computed ---------
const newTitleAbt = computed(() => checkout.value?.headlineShowStyle || '')
const isNewAutoTitle = computed(() => !!newTitleAbt.value && newTitleAbt.value !== 'Hide')
const oldTitleAbt = computed(() => {
  // 新 abt 优先级最高
  if (isNewAutoTitle.value) return ''
  return abtInfo?.value?.UserBehaviorTips?.param?.CheckoutHeadlineShow || ''
})

const isShowTwoRowsTitle = computed(() => {
  return ['Show1', 'Show2'].includes(oldTitleAbt.value) || 
    ['Show2'].includes(newTitleAbt.value)
})

// 手动滚动
const isOperationScrollUpShow = computed(() => {
  return ['Show', 'Show1'].includes(oldTitleAbt.value)
})

// 自动滚动
const isAutoScrollUpShow = computed(() => {
  return ['Show1', 'Show2'].includes(newTitleAbt.value) || 
    ['Show2'].includes(oldTitleAbt.value)
})

const titleConfig = computed(() => {
  if (isOperationScrollUpShow.value) {
    return {
      compName: OperationScrollUp,
      showDefalutTitle: isShowTwoRowsTitle.value,
      props: {
        isShowTwoRowsTitle: isShowTwoRowsTitle.value
      }
    }
  } else if (isAutoScrollUpShow.value) {
    return {
      compName: AutoScrollUp,
      showDefalutTitle: isShowTwoRowsTitle.value,
      props: {
        isShowTwoRowsTitle: isShowTwoRowsTitle.value,
        isNewAutoTitle: isNewAutoTitle.value
      }
    }
  } else {
    return {
      compName: DefaultComponent,
      showDefalutTitle: false
    }
  }
})

</script>

<style lang="less">
@import '../variables.less';

.@{prefixCls}header-title {
  .@{prefixCls}ht {
    &__default-title {
      .font-dpr(32px);
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      font-weight: 700;
      text-align: center;
    }
  }
}
</style>
