var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"prices-info__suggested-sale-price"},[(_setup.renderPopover)?_c(_setup.SPopover,{attrs:{"content":_setup.pricePopoverLang,"placemen":_setup.popoverPlacemen,"size":"lg","trigger-type":"user","show-close-icon":"","prop-style":{
      maxWidth: '6.4rem',
      zIndex: '999999',
      transform: 'translate3d(0px, 0px, 999999px)'
    }},on:{"handleCloseIconClick":_setup.popoverClose},model:{value:(_setup.popoverActive),callback:function ($$v) {_setup.popoverActive=$$v},expression:"popoverActive"}},[_c('div',{staticClass:"suggested-popover",attrs:{"slot":"reference"},on:{"click":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();return _setup.popoverClickTouchstart.apply(null, arguments)}},slot:"reference"},[_c(_setup.SuggestedSalePrice,{attrs:{"value":_setup.priceValue,"label-text":_setup.lowestPriceLabelLang,"price-delete-style":_setup.priceDeleteStyle}}),_vm._v(" "),(_setup.renderIconSuggested)?_c('i',{ref:"iconCaution",staticClass:"icon-suggested-caution sh_m_sui_icon_caution_12px_2",style:({
          opacity: _setup.showIconSuggested ? 1 : 0
        }),attrs:{"data-id":_setup.id}}):_vm._e()],1)]):_c(_setup.SuggestedSalePrice,{attrs:{"value":_setup.priceValue,"label-text":_setup.lowestPriceLabelLang,"price-delete-style":_setup.priceDeleteStyle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }