/**
 * @field 页面加载、执行流程等工具
*/
export default {
  real_time_ready(state, getters, rootState, rootGetters) {
    return rootState?.newProductDetail?.common?.realTimeReady || false
  },
  // GB_cssRight
  gb_css_right() {
    const { GB_cssRight = false } = typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo
    return GB_cssRight
  },
  is_use_bff_fs(state, getters, rootState) {
    return rootState.productDetail.MAIN_BFF_APOLLO?.vBFF_FS
  },
  common_info(state, getters, rootState) { // todo move new state
    return rootState.newProductDetail.coldModules?.commonInfo
  },
  fixed_ratio(state) {
    return state.fixed_ratio
  },
  all_data_ready(state, getters, rootState, rootGetters) { // todo move new state
    return rootGetters['newProductDetail/common/allDataReady']
  },
}
