<template>
  <RowMarquee
    v-if="label"
    class="footer-cart-label__marquee"
    :style="cssVars"
    :max-width="60"
  >
    <div class="footer-cart-label__marquee-content">
      <img
        v-if="labelIcon"
        :src="labelIcon"
        class="footer-cart-label__marquee-img"
      />
      <span class="footer-cart-label__marquee-text">{{ labelText }}</span>
    </div>
  </RowMarquee>
</template>

<script setup>
import { computed } from 'vue'
import RowMarquee from '../RowMarquee.vue'

const props = defineProps({
  label: {
    type: Object,
    default: null,
  },
})

const labelIcon = computed(() => props.label?.icon)

const labelText = computed(() => {
  return props.label?.text
})

const cssVars = computed(() => {
  if (!props.label) return {}
  return {
    '--label-color': props.label.textColor || '#fff',
    '--label-background-color': props.label.bgColor || '#fa6338',
  }
})
</script>

<style lang="less">
.footer-cart-label {
  &__marquee {
    margin: 0 auto;
    padding: 0 2px;
    height: 15px;
    line-height: 15px;
    font-size: 12px;
    white-space: nowrap;
    color: #fff;
    color: var(--label-color, #fff);
    background-color: #fa6338;
    background-color: var(--label-background-color, #fa6338);
    border-radius: 8px;
    overflow: hidden;
  }

  &__marquee-content {
    display: inline-flex;
    align-items: center;
    gap: 1px;
    height: 15px;
  }

  &__marquee-img {
    flex-shrink: 0;
    width: 13px;
    height: 13px;
  }

  &__marquee-text {
    font-size: 10px;
  }
}
</style>
