import { Toast as $toast } from '@shein/sui-mobile'
import { updateCartsAndCheckout } from '../../../utils/store'

import {
  useMapActions,
  useMapMutations
} from '@/public/src/pages/checkout_new/hooks/store'
import { isEqual } from '@/public/src/pages/checkout_new/utils'

/**
 * @description 更改购物袋商品数量
 * return {updateShoppingBagsQuantity} 实际执行函数
 * */
export default function useUpdateQuantity(conf = {}) {
  // --------- useMap_**** --------
  const { updateOriginalPriceGoodsIdList, disabledInput, blockingSort } =
    useMapMutations([
      'updateOriginalPriceGoodsIdList',
      'disabledInput',
      'blockingSort'
    ])

  const { updateGoodStatusHttp } = useMapActions(['updateGoodStatusHttp'])

  const updateShoppingBagsQuantity = async opt => {
    let result = {
      status: false,
      data: {}
    }
    try {
      const params = {
        ...opt
      }
      disabledInput(true)
      blockingSort(true)
      const data = await updateGoodStatusHttp(params)
      if (isEqual(data?.code, 0)) {
        // 超限原价购买提示
        if (data.info.update_cart_tips) $toast(data.info.update_cart_tips)
        updateOriginalPriceGoodsIdList(data.info.originalPriceGoodsIdList)
        const res = await updateCartsAndCheckout({
          isFullShippingParams: !!conf?.isUsedInShippingMethod,
          checkout: { current_action: 'update_cart_item' }
        })
        const isSucceed = res?.every(item => isEqual(item.code, '0'))
        result.status = isSucceed
        if (isSucceed) {
          result.data = { cartsInfo: res[0].info, checkout: res[1].info }
        }
      } else {
        result.status = false
        $toast(data?.msg || '')
      }
    } catch (e) {
      result.status = false
      console.error(e)
    } finally {
      disabledInput(false)
      return result
    }
  }

  return { updateShoppingBagsQuantity }
}
