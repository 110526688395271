import schttp from 'public/src/services/schttp'
import { ApiCache } from '../../ApiCache/index'
import { customMarkPoint } from 'public/src/services/mark/index.js'

/**
 * @param { url, cacheTime, eventName, notCacheKeys, fetchApiFunction } param 
 * @returns 
 */
const createApiCache = ({ url, cacheTime, eventName, notCacheKeys, fetchApiFunction } = {}) => {
  let abortionInstance = null

  const apiCache = new ApiCache({
    cacheTime: cacheTime || 1 * 60 * 1000, // 默认缓存时间 1 分钟
    request: async options => {
      if (abortionInstance) {
        abortionInstance.abort()
      }

      abortionInstance = new SchttpAbortCon()

      const markApiEnd = eventName ? customMarkPoint({ eventName }) : null

      let res = null

      if (fetchApiFunction && typeof fetchApiFunction === 'function') {
        res = await fetchApiFunction({ ...options, signal: abortionInstance.signal })
      } else {
        res = await schttp({
          url,
          params: options.params,
          signal: abortionInstance.signal,
          isPreLoad: true,
          useBffApi: true,
        })
      }

      markApiEnd && markApiEnd()

      return res
    },

    // 配置过滤掉不参加缓存的参数key
    notCacheKeys: notCacheKeys || [], // 默认不参与缓存的参数
  })

  const clear = () => apiCache.clear()

  return { apiCache, abortionInstance, clear }
}

export {
  createApiCache,
}
