<!-- eslint-disable no-unused-vars, vue/no-unused-components -->
<script>
import { defineComponent } from 'vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { useStateStore } from './store'

export default defineComponent({
  name: 'CategoryRecommendDynamicBff',
  components: {
    BaseImg,
  },
  mixins: [mixin],
  props: {
    ...commonProps
  },
  setup(props) {
    return {
      ...useStateStore(props)
    }
  },
  methods: {
    handleClickItem(item) {
      const { _FE_itemLoc_, webClickUrl, ...itemFields } = item
      this.handleClickToPage({
        item_loc: _FE_itemLoc_,
        webClickUrl,
        isBff: true,
        ...itemFields
      }, false)
    },
  },
})
</script>

<template>
  <div class="category_recommend_dynamic_bff__container">
    <div
      v-if="isShow && slidesData.length > 0"
      class="category_recommend_dynamic_bff__root"
      :style="rootStyle"
    >
      <header
        v-if="compMetaData.mainTitle"
        class="category_recommend_dynamic_bff__header"
        :style="{ 'color': compMetaData.mainTitleColor }" 
      >
        {{ compMetaData.mainTitle || '' }}
      </header>
      <swiper-container
        ref="swiperInstanceRef"
        init="false"
        :class="swiperContainerClassName"
        destroy-on-disconnected="false"
      >
        <swiper-slide
          v-for="(col, colIndex) in virtualData"
          :key="colIndex"
          class="category_recommend_dynamic_bff__slide"
          :class="{
            'category_recommend_dynamic_bff__nocutImg': sbcInfoSummary.imageShape === 'nocut',
            'is-need-scrollbar': Number(sbcInfoSummary.spacing) % 1 === 0 // 一屏为整数列时，需要滚动条
          }"
          :style="{ width: swiperSlideWidth }"
        >
          <template v-for="(item, rowIndex) in col">
            <div
              v-if="item"
              :key="rowIndex"
              style="position: relative;"
              class="category_recommend_dynamic_bff__item"
              :class="{
                'not-fsp-element': item._FE_isAnimationImg_
              }"
              :data-eid="`category_recommend_dynamic_bff__${rowIndex}-${colIndex}`"
              :data-loc-tag="item._FE_itemLoc_"
              @click="handleClickItem(item)"
            >
              <!-- 图片 -->
              <BaseImg 
                v-if="item.image"
                v-tap="getDataForMetrics(item, 'tap', rowIndex, colIndex)"
                v-expose="getDataForMetrics(item, 'expose', rowIndex, colIndex)"
                class="category_recommend_dynamic_bff__img"
                :class="{ 
                  'category_recommend_dynamic_bff__circularImg': sbcInfoSummary.imageShape === 'circle',
                  'category_recommend_dynamic_bff__nocutImg': sbcInfoSummary.imageShape === 'nocut',
                  'category_recommend_dynamic_bff__nonNoCut': sbcInfoSummary.imageShape !== 'nocut',
                  'category_recommend_dynamic_bff__stroke': sbcInfoSummary.stroke === 'true',
                  'category_recommend_dynamic_bff__stroke_2': sbcInfoSummary.stroke === 'true_2.0',
                }"
                :style="imageStyle"
                :brand="brand" 
                :img-src="item.image.src" 
                :placeholder="{
                  width: sbcInfoSummary.imageShape === 'nocut' ? 76 : 58,
                  height: sbcInfoSummary.imageShape === 'nocut' ? 56 : 58,
                }"
                :first-screen="isFirstPage && (colIndex < 5)"
                :imgDesignWidth="144"
                :comp-src="'category-recommend-placeholder'"
                :ratio="sbcInfoSummary.imageShape === 'nocut' ? 1.357 : 1"
                fit="cover"
                :imgDataExp="{
                  unCut: item._FE_isUnCut_,
                }"
              />

              <!-- 腰带 -->
              <div
                v-show="isShowBelt && item.beltText"
                class="category_recommend_dynamic_bff__belt"
                :style="beltStyle"
              >
                {{ item.beltText || '' }}
              </div>

              <!-- 角标 -->
              <div
                v-if="item.markImage"
                class="category_recommend_dynamic_bff__corner_mark"
                :style="getCornerMarkStyle(item)"
              >
              </div>

              <!-- 入口名称 -->
              <div
                :class="lineClampClass"
                :style="cateNameStyle"
                class="category_recommend_dynamic_bff__name"
              >
                {{ item.smallTitle || '' }}
              </div>
            </div>
          </template>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>

<style lang="less">
/* stylelint-disable */
@vw: 375/100vw;

.category_recommend_dynamic_bff {
  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }

  &__root {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    .line-camp();

    margin: 8 / @vw 12 / @vw;
    font-size: 13px;
    color: @sui_color_gray_dark1;
    font-weight: 600;
    line-height: 16px;
  }

  &__circularImg {
    border-radius: 50%;
  }

  &__nonNoCut {
    max-width: 84px;
  }

  &__stroke {
    border: 0.5px solid rgba(34, 34, 34, 0.1);
  }
  &__stroke_2 {
    border: 0.5px solid rgba(215, 215, 215, 0.4);
  }

  &__swiper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-left: 0;
    width: 100%;

    .swiper-slide {
      margin-right: 2 / @vw; /* 卡片左右之间间距为2dp */

      &:first-child {
        margin-top: 8 / @vw; /* 最上面的卡片顶部8dp */
      }

      &:last-child {
        margin-bottom: 4 / @vw; /* 最下面间距4dp */
      }
    }
  }

  &__slide {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;

    @margin-bottom: 4 / @vw;
    height: calc(100% - @margin-bottom);
    margin-bottom: @margin-bottom;
    margin-top: 8 / @vw;

    // 按照 SBC distribution 的默认值，
    // 提供一个默认的宽度，避免 swiper-slide 初始化时
    // 被库提供的 ::sloted(swiper-slide) 设置为 width: 100%
    width: calc(100% / 4.7);
    
    &.is-need-scrollbar {
      margin-bottom: 7px + (4 / @vw);
    }

    .base-img.category_recommend_dynamic_bff__img {
      width: 58 / @vw;
      margin: 0 auto;

      &.category_recommend_dynamic_bff__nocutImg {
        width: 76 / @vw;
        background-color: unset;
      }
    }
  }

  &__item {
    position: relative;
    margin-top: 4px;

    &[data-eid^="category_recommend_dynamic_bff__0"] {
      margin-top: 0;
    }
  }

  &__name {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    word-wrap: break-word;
    text-align: center;
    margin-top: 4 / @vw;
    margin-left: 4 / @vw;
    margin-right: 4 / @vw;

    .line-camp();

    &.two-lines {
      height: 26px;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &.three-lines {
      height: 40px;
      display: -webkit-box;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__belt {
    width: 80%;
    min-width: 1.52rem;
    text-align: center;
    position: absolute;
    min-height: 11px;
    font-weight: 400;
    line-height: normal;
    padding: 1px 4px;
    transform: translateY(-100%) translate(-50%) scale(0.75);
    left: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 9 / @vw; /* 文案大小9号 */
    font-style: normal;

    // 默认背景色和文字颜色
    background-color: #FFBD58;
    color: #000000;
  }

  &__corner_mark {
    position: absolute;
    width: 100%;
    height: 33px;
    top: 0;
    transform: scale(0.33);
    transform-origin: top right;

    > .base-img {
      width: 100%;
    }
  }
}
</style>
