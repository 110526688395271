<template>
  <div 
    @click.stop="$emit('clickDetails', true)"
  >
    <swiper-container 
      class="entry-coupon-list"
      :class="{
        'entry-coupon-list_disabled': !isMultStyle
      }"
    >
      <swiper-slide
        v-for="(coupon, index) in couponListInfo.slice(0, viewMoreCount)"
        :key="index"
        :class="{'entry-coupon-list__item': !showNewStyle}"
      >
        <component
          :is="currentComponent"
          :coupon="coupon"
          :language="language"
          :entryCouponStyle="entryCouponStyle"
          :light-effect="lightEffect"
        />
      </swiper-slide>
      <swiper-slide 
        v-if="couponListInfo.length > viewMoreCount"
        class="entry-coupon-list__last-item"
      >
        <div 
          v-expose="{ id: '2-42-7' }"
          v-tap="{ id: '2-42-8' }"
          class="entry-coupon-list__details"
        >
          <span>{{ language.SHEIN_KEY_PWA_30015 }}</span>
          <Icon 
            class="entry-coupon-list__details-icon"
            name="sui_icon_more_circle_right_16px_2" 
            size="16px" 
            color="#878989"
          />
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { template } from '@shein/common-function'

import { register } from 'swiper/element'
import { Icon } from '@shein-aidc/icon-vue2'
import ProductCouponItem from './ProductCouponItem.vue'
import ProductCouponItemNew from './ProductCouponItemNew.vue'

typeof window !== 'undefined' && register()

export default {
  name: 'XtraCouponList',
  components: {
    Icon,
    ProductCouponItem,
    ProductCouponItemNew
  },
  props: {
    couponListInfo: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => {}
    },
    entryCouponStyle: {
      type: Object,
      default: () => {}
    },
    showNewStyle: {
      type: Boolean,
      default: false
    },
    lightEffect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isInitialized: false,
    }
  },
  watch: {
    couponListInfo: {
      handler() {
        this.$nextTick(() => {
          // 更新swiper
          const xtraSwiper = document.querySelector('.entry-coupon-list')
          xtraSwiper?.swiper?.update()
          xtraSwiper?.swiper?.slideTo(0, 100)
        })
      },
      deep: true
    },
  },
  computed: {
    viewMoreCount() {
      return this.showNewStyle ? 5 : 4
    },
    isMultStyle() {
      return this.couponListInfo.length > 3
    },
    currentComponent() {
      return this.showNewStyle ? 'ProductCouponItemNew' : 'ProductCouponItem'
    },
    
  },
  mounted() {
    this.$nextTick(() => {
      this.initSwiper()
      this.isInitialized = true
    })
  },
  methods: {
    template,
    initSwiper () { 
      const xtraSwiper = document.querySelector('.entry-coupon-list')
      Object.assign(xtraSwiper, {
        direction: 'horizontal',
        slidesPerView: this.showNewStyle ? 4.168541 : 'auto',
        slidesPerGroup: 1,
        spaceBetween: this.showNewStyle ? 8 : 6,
        slidesOffsetAfter: 12
      })
      xtraSwiper.initialize()
    },
    clickDetails() {
      this.$emit('clickDetails')
    },
  },
}
</script>

<style lang="less" scoped>
.entry-coupon-list {
  z-index: 0;
  margin-right: -24/75rem;

  &_disabled {
    pointer-events: none;
  }

  &__item {
    // swiper 组件有问题，这里需要加!important才生效
    width: 200/75rem !important;
  }

  &__last-item {
    // swiper 组件有问题，这里需要加!important才生效
    width: 100/75rem !important;
    margin-top: 10/75rem;
  }

  &__details {
    width: 104/75rem;
    height: 112/75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: @sui_color_gray_dark3;
    font-family: "SF Pro";
    .font-dpr(20px);
    line-height: 1.2;
    text-align: center;
  }

  &__details-icon {
    margin-top: 8/75rem;
  }

  /deep/ .light-slide-effect::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -150%; /* 初始位置在左边外，待动画滑入 */
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.1) 100%);
    transform: skewX(-30deg); /* 倾斜角度 */
    animation: shiny 0.6s linear infinite;
  } 

  /* 定义动画关键帧实现左到右滑动 */
  @keyframes shiny {
    0% {
      left: -150%;
    }
    // 50% {
    //   left: 150%;
    // }
    100% {
      left: 150%;
    }
  }
}
</style>
