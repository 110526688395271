import { cartTagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'

/**
 * 根据 skc 获取加车数量
 * @param {String[]} skcList 商品 skc
 * @returns {Promise<Object>} { [skc]: { skc, quantity, list: [{ id, skc, sku, quantity }] } }
 */
export const getCartNumBySkc = async (skcList, config) => {
  if (typeof window === 'undefined' || !skcList?.length) return {}

  let cartList

  await cartTagTip.initPromiseActuator.promise

  if (config?.updateCartData) {
    await cartTagTip.updateCartList()
  }

  cartList = cartTagTip.cache.cartList.get()

  if (!cartList?.length) return {}

  const skcMap = cartList.reduce((pre, cur) => {
    if (!pre[cur.goods_sn]) {
      pre[cur.goods_sn] = {
        skc: cur.goods_sn,
        quantity: 0,
        list: []
      }
    }

    pre[cur.goods_sn].quantity += +cur.quantity
    pre[cur.goods_sn].list.push({
      id: cur.id || cur.goods_id,
      skc: cur.goods_sn,
      sku: cur.sku_code,
      quantity: +cur.quantity,
    })

    return {
      ...pre,
    }
  }, {})

  const res = skcList.reduce((pre, cur) => {
    if (skcMap[cur]) {
      pre[cur] = skcMap[cur]
    }
    return pre
  }, {})

  return res
}
