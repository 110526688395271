

export const IMGS = {
  arrow: 'https://img.ltwebstatic.com/images3_ccc/2024/12/06/ce/173347086473ae18fcef470aea8733e13491829cde.png',

}

export const STYLE = {
  style1: 's1',
  style2: 's2',
  style3: 's3',
  style4: 's4', // 老客
}

// 1.下单返券,2新人福袋,3下单返现,4首单可用,5免邮,6折扣,7免邮&折扣,8新人&免邮,9新人&折扣
export const STYLE_BENEFIT_TYPE = {
  3: {
    textColor: '#FF3351',
    border: '0.5px solid rgba(255, 51, 81, 0.60)',
    background: '#FFEBEE'
  },
  2: {
    textColor: '#FA6338',
    border: '0.5px solid rgba(250, 99, 56, 0.60)',
    background: '#FEEFEB',
  },
  1: {
    textColor: '#478719',
    border: '0.5px solid rgba(71, 135, 25, 0.60)',
    background: '#EFF4EB'
  },
  4: {
    textColor: '#FF3351',
    border: '0.5px solid rgba(255, 51, 81, 0.60)',
    background: '#FFEBEE'
  },
  // free shipping
  5: {
    textColor: '#198055',
    border: '0.5px solid rgba(25, 128, 85, 0.60)',
    background: '#E8F2EE',
  },
  'default': {
  
  }
}
  

// 1.下单返券,2新人福袋,3下单返现,4首单可用,5免邮,6折扣,7免邮&折扣,8新人&免邮,9新人&折扣
export const STYLE_CORNER_BENEFIT_TYPE = {
  3: {
    textColor: '#FFF',
    top: '-0.1467rem',
    right: '-0.133333rem',
    padding: '0 .1067rem',
    // border: '0.5px solid rgba(255, 51, 81, 0.60)',
    background: '#FF3351'
  },
  2: {
    textColor: '#FFF',
    top: '-0.1467rem',
    right: '-0.133333rem',
    padding: '0 .1067rem',
    // border: '0.5px solid rgba(250, 99, 56, 0.60)',
    background: '#FA6338'
  },
  1: {
    textColor: '#FFF',
    top: '-0.1467rem',
    right: '-0.133333rem',
    padding: '0 .1067rem',
    // border: '0.5px solid rgba(71, 135, 25, 0.60)',
    background: '#478719'
  },
  4: {
    textColor: '#FFF',
    top: '-0.1467rem',
    right: '-0.133333rem',
    // border: '0.5px solid rgba(255, 51, 81, 0.60)',
    background: '#FF3351'
  },
  // free shipping
  5: {
    textColor: '#FFF',
    height: '.32rem',
    // border: '0.5px solid rgba(25, 128, 85, 0.60)',
    background: '#198055',
    top: '-0.1867rem',
    right: '-0.1333rem',
    padding: '0 .1067rem'
  },
  'default': {

  }
}


export const STYLE_BENEFIT_FOOTER = {
  3: {
    color: '#D92242',
    top: '.32rem',
    right: '0',
    borderRight: '0.13333rem solid transparent', /* 右边透明 */
    borderTop: '0.13333rem solid #D92242' /* 底边为红色 */
  },
  2: {
    color: '#C93F18',
    top: '.32rem',
    right: '0',
    borderRight: '0.13333rem solid transparent', /* 右边透明 */
    borderTop: '0.13333rem solid #C93F18' /* 底边为红色 */
  },
  1: {
    color: '#2D5A0D',
    top: '.32rem',
    right: '0',
    borderRight: '0.13333rem solid transparent', /* 右边透明 */
    borderTop: '0.13333rem solid #2D5A0D' /* 底边为红色 */
  },
  4: {
    color: '#D92242',
    top: '.32rem',
    right: '0',
    borderRight: '0.13333rem solid transparent', /* 右边透明 */
    borderTop: '0.13333rem solid #D92242' /* 底边为红色 */
  },
  5: {
    color: '#16533A',
    top: '.32rem',
    right: '0',
    borderRight: '0.13333rem solid transparent', /* 右边透明 */
    borderTop: '0.13333rem solid #16533A' /* 底边为红色 */
  }
}
