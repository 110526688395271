/**
 * 获取 SBC 数据项的图片 hash
 * 
 * @param {SbcPlaceholderDataItem} item 
 */
function getSbcDataItemImageHash(item) {
  const imgUrl = item.image.src
  if (!imgUrl) return ''

  const imgFileName = imgUrl.split('/').pop()
  // 去掉扩展名
  const imgHash = imgFileName.split('.')[0]
  return imgHash
}

function setItemLoc({
  colIndex,
  rowIndex,
  dataItem,
  rowCount,
  oneGridCols,
  index,
}) {
  const mod = index % (rowCount * oneGridCols)
  const atSceenIndex = Math.floor(index / (rowCount * oneGridCols)) + (
    mod === 0 ? 0 : 1
  )
  dataItem._FE_DistributionLoc_ = `${atSceenIndex}_${index}`
  dataItem._FE_itemLoc_ = `${rowIndex + 1}_${colIndex + 1}`
}



/**
 * 按 Z 字型排列方式分配
 * 
 * @template D data item type parameter
 * @template S info summary type parameter
 * @param {{
*  rowCount: S['rowCount'],
*  columnCount: S['columnCount'],
*  distribution: 4 | 5,
*  itemsDataList: import('vue').Ref<D[]>,
*  slidesData: import('vue').Ref<D[][]>,
* }} params
*/
function styleZ({
 rowCount,
 columnCount,
 distribution,
 itemsDataList,
 slidesData,
}) {
 const slides = Array.from(
   { length: columnCount },
   () => Array.from(
     { length: rowCount },
     () => null)
 )

 // Tips: row * oneGridCols 可以称为 "一个 Grid 网格组"
 const oneGridCols = Number(distribution) // 表示 Z 型排布多少个折行
 // 计算最多能铺满多少个 Grid 网格组
 const maxGridCount = Math.floor(itemsDataList.value.length / (rowCount * oneGridCols))
 // 剩余数：即不足一个 Grid 网格组的
 const restCount = itemsDataList.value.length % (rowCount * oneGridCols)

 let index = 0
 const itemsDataCopy = itemsDataList.value.slice()

 // 先填充完整能铺满的网格组
 for (let i = 0; i < maxGridCount; i++) {
   for (let r = 0; r < rowCount; r++) {
     const startColIndex = i * oneGridCols
     for (let c = startColIndex; c < startColIndex + oneGridCols; c++) {
       const dataItem = itemsDataCopy.shift()
       if (!dataItem) break
       
       index += 1
       setItemLoc({
         colIndex: c,
         rowIndex: r,
         dataItem, rowCount, oneGridCols, index,
       })

       slides[c][r] = dataItem
     }
   }
 }

 // #region Z 型排布 对 rest 部分的结构示意图
 // -----------------------------------
 // 例子1：（按 listLen = 17, distribution = 4）：
 // restCount = 5, restCols = 2
 // ┌ ┌1 ┐ ┌2 ┐ ┌3 ┐ ┌4 ┐ ┌13┐ ┌16┐ ┐
 // │ │5 │ │6 │ │7 │ │8 │ │14│ │17│ │
 // └ └9 ┘ └10┘ └11┘ └12┘ └15┘ └  ┘ ┘
 // - 不够 Z字排 3x4 => 也不够 Z字排 3x3 => 也不够 Z字排 3x2
 // - 只够 Z字排 3x1，剩余 2 个放到末列

 // 例子2：（按 listLen = 16, distribution = 4）：
 // restCount = 4, restCols = 2
 // ┌ ┌1 ┐ ┌2 ┐ ┌3 ┐ ┌4 ┐ ┌13┐ ┌16┐ ┐
 // │ │5 │ │6 │ │7 │ │8 │ │14│ │  │ │
 // └ └9 ┘ └10┘ └11┘ └12┘ └15┘ └  ┘ ┘
 // - 不够 Z字排 3x4 => 也不够 Z字排 3x3 => 也不够 Z字排 3x2 
 // - 同只够 Z字排 3x1，剩余 1 个放到末列

 // 例子3：（按 listLen = 22, distribution = 4）：
 // restCount = 10, restCols = 4
 // ┌ ┌1 ┐ ┌2 ┐ ┌3 ┐ ┌4 ┐ ┌13┐ ┌14┐ ┌15┐ ┌22┐ ┐
 // │ │5 │ │6 │ │7 │ │8 │ │16│ │17│ │18│ │  │ │
 // └ └9 ┘ └10┘ └11┘ └12┘ └19┘ └20┘ └21┘ └  ┘ ┘
 // - 不够 Z字排 3x4
 // - 只够 Z字排 3x3，剩余 1 个放到末列

 // 例子4：（按 listLen = 23, distribution = 4）：
 // restCount = 11, restCols = 4
 // ┌ ┌1 ┐ ┌2 ┐ ┌3 ┐ ┌4 ┐ ┌13┐ ┌14┐ ┌15┐ ┌22┐ ┐
 // │ │5 │ │6 │ │7 │ │8 │ │16│ │17│ │18│ │23│ │
 // └ └9 ┘ └10┘ └11┘ └12┘ └19┘ └20┘ └21┘ └  ┘ ┘
 // - 不够 Z字排 3x4
 // - 只够 Z字排 3x3，剩余 2 个放到末列

 // #endregion
 
 // 先计算剩余项最多能 Z字排 rowCount * 多少列
 let restZCols = oneGridCols
 let requiredForZ = rowCount * oneGridCols
 for (
   ;restCount < requiredForZ
   ;requiredForZ -= rowCount // 减去 1 列
 ) {
   restZCols -= 1
 }

 const restColStartIndex = maxGridCount * oneGridCols
 const lastColStartIndex = restColStartIndex + restZCols

 // Z字排
 if (requiredForZ > 0) {
   // rest 的起始列索引
   for (let r = 0; r < rowCount; r++) {
     for (let c = 0; c < restZCols; c++) {
       const dataItem = itemsDataCopy.shift()
       if (!dataItem) break

       const colIndex = restColStartIndex + c
       index += 1
       setItemLoc({ 
         colIndex: c,
         rowIndex: r,
         dataItem, rowCount, oneGridCols, index,
       })

       slides[colIndex][r] = dataItem
     }
   }
 }

 // 剩余项放末列
 for (let r = 0; r < rowCount; r++) {
   for (let c = lastColStartIndex; c < columnCount; c++) {
     const dataItem = itemsDataCopy.shift()
     if (!dataItem) break

     index += 1
     setItemLoc({
       colIndex: c,
       rowIndex: r,
       dataItem, rowCount, oneGridCols, index,
     })

     slides[c][r] = dataItem
   }
 }

 slidesData.value = slides
}


/**
 * 按 N 字型排列方式分配
 * 
 * @template D data item type parameter
 * @template S info summary type parameter
 * @param {{
 *   rowCount: S['rowCount'],
 *   columnCount: S['columnCount'],
 *   itemsDataList: import('vue').Ref<D[]>,
 * }} params
 */
function styleN({
  rowCount,
  columnCount,
  itemsDataList,
}) {
  // N 型排布 结构示意图：
  // ┌ ┌1┐ ┌5┐ ┌9 ┐ ┐
  // │ │2│ │6│ │10│ │
  // │ │3│ │7│ │11│ │
  // └ └4┘ └8┘ └  ┘ ┘
  const slides = Array.from(
    { length: columnCount },
    () => Array.from(
      { length: rowCount },
      () => null)
  )

  let index = 0
  const itemsDataCopy = itemsDataList.value.slice()
  for (let c = 0; c < slides.length; c++) {
    for (let r = 0; r < rowCount; r++) {
      const dataItem = itemsDataCopy.shift()
      if (!dataItem) break

      index += 1
      dataItem._FE_itemLoc_ = `${r + 1}_${c + 1}`
      dataItem._FE_DistributionLoc_ = String(index)
      dataItem._FE_eid_ = `category-recommend-placeholder-${r}-${c}-${getSbcDataItemImageHash(dataItem)}`

      slides[c][r] = dataItem
    }
  }

  return slides
}

/**
 * 根据 SBC 信息，将 itemsDataList 
 * 按既定排列方式分配到 slidesData 中
 * 
 * @template D data item type parameter
 * @template S info summary type parameter
 * @param {{
 *   sbcInfoSummary: import('vue').Ref<S>,
 *   itemsDataList: import('vue').Ref<D[]>,
 *   slidesData: import('vue').Ref<D[][]>,
 * }} params 
 */
export function distributeItemsToSlides({
  sbcInfoSummary,
  itemsDataList,
  slidesData
}) {
  const {
    rowCount,
    columnCount,
    distribution
  } = sbcInfoSummary.value

  const params = {
    rowCount,
    columnCount,
    itemsDataList,
    slidesData,
    distribution: distribution ? Number(distribution) : 4,
  }
  
  // 2024-1210 - SBC 新组件 排布逻辑迁移到中间层，全部采用 N 字形排布
  // return styleN(params)
  return distribution === 'N'
  ? styleN(params)
  : styleZ(params)
}
