import schttp from 'public/src/services/schttp'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'

// 是否走bff
function isUseBffApi () { 
  return isSwitchBffApiVersion(['cart_v6'])?.cart_v6
}
/**
 * 查车接口
 * 透传南京查车接口,无逻辑处理
 */
export const getCartBaseInfo = () => {
  const { currency, appLanguage } = window.gbCommonInfo

  if(isUseBffApi()){
    return schttp({
      method: 'POST',
      url: '/cart-api/mall/cart/base_info',
      headers: {
        'AppCurrency': currency,
        'AppLanguage': appLanguage,
      },
      useBffApi: true
    }).catch(() => {
      return {
        code: -1
      }
    })
  } else {
    return schttp({
      url: '/api/cart/checkcart/get'
    }).catch(() => {
      return {
        code: -1
      }
    })
  }
}
