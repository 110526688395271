import qs from 'qs'
import { getRouterCopy } from 'public/src/pre_requests/utlis'

export const reg = /\/.+-p-(\d+)(-cat-(\d+))?\.html/

export const VERSION = '1.0.4'

export const getDetailParamsByUrl = () => {
  const beforeRouter = getRouterCopy()
  let url = beforeRouter?.path
  // 兼容ssr 时，没有beforeRouter的情况
  if (!url && typeof window !== 'undefined') {
    url = window.location.href
  }
  const paths = url.match(reg)
  const goods_id = paths[1]
  let expData = beforeRouter?.query
  if (!expData) {
    expData = qs.parse(location.search.slice(1))
  }
  return {
    goods_id,
    expData,
  }
}

export const getGoodsDetailParams = ({ withI18n } = {}) => {
  const { goods_id, expData } = getDetailParamsByUrl()
  const exp = {
    mallCode: expData.mallCode,
    showFeedbackRec: expData.showFeedbackRec,
  }
  const { currency } = gbCommonInfo

  return {
    version: VERSION,
    goods_id,
    fromSpa: 1,
    withI18n: withI18n || 0,
    currency,
    template: 0,
    ...exp,
  }
}
