<template>
  <LazyMount>
    <SDialog
      :visible="isShow"
      :append-to-body="true"
      class="no-installment-dialog"
      @close="closeDialog"
      @close-from-icon="cancel"
      @close-from-mask="cancel"
    >
      <div class="title">
        {{ language.BS_KEY_PAY_1199 }}
      </div>
      <template #footer>
        <SButton
          :type="['primary', 'H72PX']"
          width="100%"
          class="pay-btn"
          @click="handlerPay">
          {{ language.BS_KEY_PAY_1201 }}
        </SButton>
        <SButton
          :type="['H72PX']"
          width="100%"
          @click="closeDialog">
          {{ language.BS_KEY_PAY_1200 }}
        </SButton>
      </template>
    </SDialog>
  </LazyMount>
</template>

<script setup lang="ts">
/*
 * 支付方式组件: 路由卡分期没有分期信息提示弹窗
 *
 * */
import { Dialog as SDialog, Button as SButton, LazyMount } from '@shein/sui-mobile'
import { ref, computed, inject } from 'vue'
import { usePaymentsStore } from '../../../hooks/usePayments'
import { debuggerLog } from '../../../utils'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import type { AS_PayToolkit } from '../../../types'
const { triggerNotice } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

const props = defineProps({
  language: {
    type: Object,
    default: () => {},
  },
  nowCvv: {
    type: String,
    default: '',
  },
  isNowMaestro: {
    type: Boolean,
    default: false,
  },
})

const paymentStore = usePaymentsStore()

const emits = defineEmits(['cvv-validate', 'pay-now'])

const isShow = ref(false)
const isConfirm = ref(false)
const coverData = ref({})

const selectedPayment = computed(() => paymentStore?.selectedPayment || {})
const nowPayStore = computed(() => paymentStore.editablePaymentInfo?.[selectedPayment.value?.code] || {})
const installmentOptions = computed(() => nowPayStore.value?.installments || [])
const cardTokenInfo = computed(() => {
  return nowPayStore.value?.card_token_info || {}
})

// 区分是路由卡还是路由卡分期
const nowUseTokenData = computed(() => {
  return paymentStore?.editablePaymentInfo?.[selectedPayment.value?.code]?.card_token_info || {}
})
const isShowCvvDrawer = computed(() => {
  return nowUseTokenData.value?.need_cvv === '1'
})

const isHadCvv = computed(() => {
  const nowIsHadCvv = props.isNowMaestro ? !props.isNowMaestro : props.nowCvv === ''
  return isShowCvvDrawer.value ? nowIsHadCvv : true
})

const closeDialog = () => {
  isShow.value = false
}
const cancel = () => {
  coverData.value = {}
  isShow.value = false
}
const handlerPay = () => {
  triggerNotice?.({
    id: 'click_token_installment.comp_installment_list_drawer',
  })
  isShow.value = false

  debuggerLog('no installment handlerPay >>>', cardTokenInfo.value)

  // 需要 cvv 校验
  if (cardTokenInfo.value?.need_cvv == '1') {
    emits('cvv-validate', {
      lastAddOrderExtraParams: {
        coverData: coverData.value,
      },
    })
    return
  }

  isConfirm.value = true
  emits('pay-now', {
    coverData: coverData.value,
  })
  coverData.value = {}
}

// 是否使用无分期弹窗
const isUseNoInstallmentDialog = () => {
  return installmentOptions.value?.length === 0 || (installmentOptions.value?.length === 1 && installmentOptions.value[0]?.stage_num == 1)
}

const validateByPay = (opts?: Trade_PayToolKit.PreCreatePaymentVerifyOptions) => {
  debuggerLog('no installment validateByPay >>>', opts)
  if (opts?.lastAddOrderExtraParams?.coverData) {
    coverData.value = opts.lastAddOrderExtraParams.coverData
  }
  if (isUseNoInstallmentDialog() && !isConfirm.value && isHadCvv.value) {
    isShow.value = true
    return {
      validateResult: false,
      scene: 'no_installment_confirm',
      metric_scene: 'no_installment_confirm_dialog',
    }
  }

  return {
    validateResult: true,
    scene: 'no_installment_confirm',
  }
}

defineExpose({
  validateByPay,
  isUseNoInstallmentDialog,
})
</script>

<style lang="less" scoped>
.no-installment-dialog {
  .title {
    color: black;
    font-size: 14px;
    font-family: SF UI Text;
    font-weight: 400;
    word-wrap: break-word;
    text-align: center;
    margin-bottom: 8 * 2/75rem;
  }

  .S-dialog__footer {
    display: flex;
    flex-direction: column;

    .pay-btn {
      margin-bottom: 12 * 2/75rem;
    }
  }
}
</style>
