import { computed, watch } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { usePopoverManager } from 'public/src/pages/cart_v2/utils/popoverManager/usePopoverManager.js'
import { PRICE_CHANGE } from 'public/src/pages/cart_v2/utils/popoverManager/popoverTypes.js'
import { useItemDisplayView } from './useItemDisplayView.js'
import { usePriceChangeCache } from './usePriceChangeCache.js'
import { usePriceChangeState } from './usePriceChangeState.js'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'

export function usePriceChangePopover() {
  const { state, getters } = useStore()
  const { isActivePopover, deactivatePopover, activatePopover, registerPopover } = usePopoverManager({ type: PRICE_CHANGE })
  registerPopover()
  const { showPopoverCount, sessionClosePopover, closePopover } = usePriceChangeCache()
  const { updatePriceChangeState, showPopover, showPopoverMax } = usePriceChangeState()
  const { itemInVisible, updateItemInVisible } = useItemDisplayView()
  const batchActive = computed(() => state.batchActive)
  const checkBeforeItem = computed(() => state.priceChangeState.checkBeforeItem)
  const checkAfterItem = computed(() => state.priceChangeState.checkAfterItem)
  const promotionDetailCoupon = computed(() => state.cartInfo?.promotionDetailPopUp?.items?.find(item => item.benefitType === 'coupon') ?? {})
  const priceChangeTips = computed(() => getters['cart_v2/priceChangeTips'])
  const isSuggested = computed(() => getters['cart_v2/isSuggested'])
  const cartPriceStyle = computed(() => getters['cart_v2/cartPriceStyle'])

  // 基础展示条件
  const baseShowConditions = computed(() => {
    const isSameItem = checkBeforeItem.value && checkAfterItem.value && checkBeforeItem.value?.id === checkAfterItem.value.id
    const notCheckedEstimatedPrice = Number(checkBeforeItem.value?.aggregateProductBusiness?.priceData?.estimatedPrice?.price?.amount || 0)
    const notCheckedItemsCouponSaved = Number(checkBeforeItem.value?.aggregateProductBusiness.estimatedPricePopUp?.subInfo?.items?.find(item => item.benefitType === 'coupon')?.data?.rightValuePrice?.amount || 0)
    const checkedEstimatedPrice = Number(checkAfterItem.value?.aggregateProductBusiness?.priceData?.estimatedPrice?.price?.amount || 0)
    const checkedTotalCouponSaved = Number(promotionDetailCoupon?.value.data?.rightValuePrice?.amount || 0)
    return !batchActive.value && isSameItem && checkAfterItem && checkBeforeItem && checkedEstimatedPrice > notCheckedEstimatedPrice && checkedTotalCouponSaved >= notCheckedItemsCouponSaved
  })
  // 其他展示条件（频控、商品行是否可见）
  const otherShowConditions = computed(() => {
    return priceChangeTips.value
    && showPopoverCount.value < showPopoverMax.value
    && !sessionClosePopover.value
    && !closePopover.value
    && !isSuggested.value
    && !cartPriceStyle.value
  })

  const isFinallyShow = computed(() => {
    return baseShowConditions.value && otherShowConditions.value
  })
  const isReportAbt = computed(() => {
    return baseShowConditions.value && itemInVisible.value
  })
  const isShow = computed(() => {
    return Boolean(isFinallyShow.value && isActivePopover.value && itemInVisible.value)
  })

  watch(() => isActivePopover.value, (val) => {
    if (val) {
      updateItemInVisible(checkAfterItem.value.id)
    }
  })
  watch(() => isFinallyShow.value, (val) => {
    if (val) {
      updateItemInVisible(checkAfterItem.value.id)
      activatePopover()
    }
  })
  watch(() => isReportAbt.value, (val) => {
    if (val) {
      exposeScenesAbtAggregator.addEvent({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'price_change',
          type: `${checkAfterItem.value?.product?.goods_id},${checkAfterItem.value.id}`,
        }
      })
    }
  })
  watch(() => isShow.value, (val) => {
    if (val) {
      updatePriceChangeState({
        showPopoverId: checkAfterItem.value.id,
        showPopover: true,
      })
    } else {
      deactivatePopover()
    }
  })
}
