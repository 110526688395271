<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import CateClassify from './CateClassifyFunctional.vue'

export default {
  name: 'CateTitleFunctional',
  functional: true,
  components: { CateClassify },
  inject: ['analysisInstance'],
  props: {
    cate: {
      type: Object,
      default: () => ({}),
    },
    cateType: {
      type: String,
      default: '',
    },
    activeChannel: {
      type: Object,
      default: () => ({}),
    },
    activeLeftContent: {
      type: Object,
      default: () => ({}),
    },
    compIndex: {
      type: Number,
      default: 0,
    },
    cateLinks: {
      type: Object,
      default: () => ({}),
    },
    pos: {
      type: String,
      default: '',
    },
    noExpand: {
      type: Boolean,
      default: false,
    },
    isChildCate: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
      default: () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    abtMap: {
      type: Object,
      default: () => ({}),
    }
  },
  emits: ['clickItem', 'jumpLink', 'expose'],
  render(_, { props, listeners, injections }) {
    const { vTap, vExpose } = injections['analysisInstance']
    const { cssRight = false } = useAppConfigs().$envs || {}
    let icon = 0
    if (props?.cateType === 'sidecat') {
      icon = -1
    }
    if (props?.cate.props?.metaData?.categoryType?.hrefType !== 'noJump') {
      icon = 1
    }

    const clickItem = () => {
      const categoryType = props.cate?.props?.metaData?.categoryType
      const params = {
        cate: props.cate?.props?.metaData,
        cateType: props.cateType,
        channel: props.activeChannel?.channel,
        channelIndex: props.activeChannel?.channelIndex,
        oneCateContent: props.activeLeftContent,
        compIndex: props.compIndex,
        oneCate: props.activeChannel?.oneCate,
      }
      listeners.clickItem?.(
        {
          type: 'title',
          item: categoryType,
          cateLinks: {},
          compIndex: props.compIndex,
          index: -1,
          isJump: categoryType.hrefType != 'noJump',
        },
        params,
      )
    }

    const aTagpreventDefault = e => {
      e.preventDefault()
    }

    return (
      <div class="bs-cate__entire-wrap expand">
        <div
          {...{
            directives: [
              {
                name: 'expose',
                def: vExpose,
                value: {
                  id: 'expose_second_category.comp_category',
                  data: {
                    item: props.cate.props,
                    index: props.compIndex,
                    topNavSa: props.activeChannel?.channel?.saInfo,
                    oneCateContent: props.activeLeftContent?.cate?.saInfo,
                    cateType: props.cateType,
                  },
                  code: props.cateType,
                  delayReady: 300,
                  callback: listeners.expose,
                },
              },
              {
                name: 'tap',
                def: vTap,
                value: {
                  id: 'click_second_category.comp_category',
                  data: {
                    item: props.cate.props,
                    index: props.compIndex,
                    topNavSa: props.activeChannel?.channel?.saInfo,
                    oneCateContent: props.activeLeftContent?.cate?.saInfo,
                    cateType: props.cateType,
                  },
                },
              },
            ],
          }}
          class={{
            'c-cate__entire-title': true,
            'c-cate__entire-title--first-line': props?.cate?.props?.metaData?.categoryType?.position == 0,
          }}
          vOn:click_stop={clickItem}
        >
          <a
            aria-hidden="true"
            style={{ color: props.cate.color }}
            role="none"
            tabindex="-1"
            vOn:click={aTagpreventDefault}
          >
            <div class="c-cate__link-font">{props.cate?.props?.metaData?.categoryLanguage}</div>
            <div class="c-cate__link-icon">
              {icon == 1 || icon == -1 ? (
                <Icon
                  class="bs-left-item_arr"
                  name="sui_icon_more_right_16px"
                  is-rotate={cssRight}
                  size="12px"
                  color="#999"
                  style={{ visibility: icon != 1 && 'hidden' }}
                />
              ) : null}
            </div>
          </a>
        </div>

        {props.cate.child && (
          <CateClassify
            class={{ 'animate-ready': !props.noExpand, 'no-animate': props.noExpand }}
            cate={props.cate?.child[0]?.props?.items}
            cate-props={props.cate}
            cate-type={props.cateType}
            abtMap={props.abtMap}
            activeChannel={props.activeChannel}
            activeLeftContent={props.activeLeftContent}
            compIndex={props.compIndex}
            is-child-cate={props.isChildCate}
            is-active={props.isActive}
            vOn:jumpLink={listeners.jumpLink}
            vOn:clickItem={clickItem}
            vOn:expose={listeners.expose}
          />
        )}
      </div>
    )
  },
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.bs-cate__entire-wrap {
  overflow: hidden;
  min-height: 12.53vw;
  min-height: min(12.53vw, 67.66px);
  .animate-ready {
    transition: 0.3s margin-top cubic-bezier(0.2, 0.63, 0.57, 0.94);
  }
  .c-cate__classify-wrap {
    opacity: 0;
    animation-name: opacity-leave;
    animation-timing-function: ease;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    // animation-play-state: running;
  }
  &.expand {
    .title-banner {
      opacity: 0;
    }
  }
  @keyframes opacity-enter {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes opacity-leave {
    0% {
      opacity: 1;
    }
    30% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }
  .c-cate {
    &__entire-title {
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      font-weight: normal;
      color: #222;
      display: flex;
      align-items: center;
      min-height: 8.55vw;
      line-height: 3.2vw;
      min-height: min(8.55vw, 46.17px);
      line-height: min(3.2vw, 17.28px);
      justify-content: space-between;
      width: 100%;
      margin-top: 0;
      padding: 0 0 0 0;
      background: #fff;
      a {
        display: flex;
        justify-content: space-between;
        flex: 1;
        overflow: hidden;
        text-decoration: none;
        color: #666;
      }
    }
    &__link-font {
      font-weight: 500;
      font-size: 3.2vw;
      font-size: min(3.2vw, 17.28px);
      color: #222;
      line-height: 8.55vw;
      line-height: min(8.55vw, 46.17px);
      transition: 0.3s all cubic-bezier(0.2, 0.63, 0.57, 0.94);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__link-name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__link-icon {
      display: flex;
      align-items: center;
    }
    &__entire-title--first-line {
      margin-top: 0;
    }
  }
}
</style>
