<template>
  <div class="terms-verify">
    <div
      v-if="isSuccess || isError"
      :class="{
        'terms-verify__tips': isSuccess,
        'terms-verify__tips--success': isSuccess,
        'terms-verify__tips--error': isError
      }"
    >
      <span class="terms-verify__tips-icon">
        <svg
          v-if="isSuccess"
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <circle
            cx="7.50033"
            cy="7.00033"
            r="5.83333"
            fill="#198055"
          />
          <path
            d="M10.4001 5.0665L6.5331 8.9335L4.59961 7"
            stroke="white"
            stroke-width="1.16667"
          />
        </svg>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M7.499 1.16699L14.0664 12.8337H0.931641L7.499 1.16699Z"
            fill="#BF4123"
          />
          <rect
            x="6.91699"
            y="5.25"
            width="1.16667"
            height="3.5"
            fill="white"
          />
          <rect
            x="6.91699"
            y="9.91699"
            width="1.16667"
            height="1.16667"
            fill="white"
          />
        </svg>
      </span>
      <span>{{ verifyTips }}</span>
    </div>

    <div
      :class="{
        'terms-verify__message': true,
        'terms-verify__message--success': isSuccess,
        'terms-verify__message--error': isError
      }"
      @click="onVisiblePopoverToggle"
    >
      <div> {{ termsMessage }} </div>

      <div
        ref="refPopoverTarget"
        class="terms-verify__message-icon"
      > 
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
        >
          <path
            d="M7.5 12.7158C10.3995 12.7158 12.75 10.3653 12.75 7.46582C12.75 4.56633 10.3995 2.21582 7.5 2.21582C4.6005 2.21582 2.25 4.56633 2.25 7.46582C2.25 10.3653 4.6005 12.7158 7.5 12.7158Z"
            stroke="#959595"
          />
          <path
            d="M8.15335 9.93159L8.15389 11.0987H6.98722L6.98727 9.93159H8.15335ZM9.8061 4.87624C10.4315 5.95953 10.0906 7.33557 9.05071 8.00608L8.95118 8.06684C8.56577 8.28936 8.29932 8.65436 8.19779 9.05688L7.01328 9.05682C7.12617 8.2743 7.56886 7.56945 8.24646 7.13074L8.36785 7.05648C8.92698 6.73366 9.11855 6.0187 8.79574 5.45957C8.47292 4.90044 7.75796 4.70887 7.19883 5.03168C6.82123 5.24969 6.61127 5.64654 6.61416 6.05347L5.44728 6.0534C5.4448 5.24367 5.86398 4.45521 6.6155 4.02132C7.73264 3.37634 9.16112 3.7591 9.8061 4.87624Z"
            fill="#959595"
          />
        </svg>
      </div>
    </div>


    <!-- suiPopover报错无法展示 -->
    <Teleport to="body">
      <div
        ref="refPopover"
        class="terms-verify__popover"
        @touchstart="onPopoverTouchstart"
      >
        <span
          class="terms-verify__popover-close"
          @click="onHidePopover"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <mask
              id="mask0_4142_10518"
              style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="12"
              height="12"
            >
              <rect
                width="12"
                height="12"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_4142_10518)">
              <path
                d="M3.17129 8.82777L8.82814 3.17092"
                stroke="white"
              />
              <path
                d="M3.17125 3.17129L8.82811 8.82814"
                stroke="white"
              />
            </g>
          </svg>
        </span>
        <i
          ref="refPopoverTriangle"
          class="terms-verify__popover-triangle"
        ></i>
        <div class="terms-verify__popover-content">
          {{ popoverContent }}
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup name="TermsVerify">
import { ref, computed } from 'vue'
import Teleport from 'public/src/pages/product_list_v2/components/common/Coupon/commonComponents/Teleport.vue'
import { useEventListener } from 'public/src/pages/components/FilterBar/hooks/useEventListener.js'

const props = defineProps({
  status: { type: String, default: '' },
  language: { type: Object, default: () => ({}) },
})

const isSuccess = computed(() => props.status === 'success')
const isError = computed(() => props.status === 'error')

const verifyTips = computed(() => isSuccess.value
  ? props.language?.SHEIN_KEY_PWA_35504 || `Age Verified!`
  : props.language?.SHEIN_KEY_PWA_35506 || `Unvalid Age`
)
const termsMessage = computed(() => {
  if (isError.value) return props.language?.SHEIN_KEY_PWA_35507 || `Unfortunately, this service is not available to minors.`

  return props.language?.SHEIN_KEY_PWA_35501 || `This won’t be shown publicly.`
})

const visiblePopover = ref(false)
const refPopover = ref(null)
const refPopoverTarget = ref(null)
const refPopoverTriangle = ref(null)
const popoverContent = computed(() => {
  if (isError.value) return props.language?.SHEIN_KEY_PWA_35508 || `Please note that only parents or legal guardians are permitted to create a child's profile. If you are underage, we kindly request that you refrain from using our services and delete your SHEIN account, as SHEIN does not provide its services to minors.`

  return props.language.SHEIN_KEY_PWA_35505 || `We use your age information to verify that you have reached the age at which you can use certain services and features, including the creation of kid's profile.`
})

const calcPopoverPosition = () => {
  if (visiblePopover.value) {
    const rectTarget = refPopoverTarget.value.getBoundingClientRect()
    const rectPopover = refPopover.value.getBoundingClientRect()
    refPopover.value.style.top = `${rectTarget.top - rectPopover.height - 6}px`
    refPopover.value.style.zIndex = 999999
    refPopoverTriangle.value.style.left = `${rectTarget.left + rectTarget.width / 2 - 29}px`
    refPopover.value.style.transform = 'translate3d(0,0,999999px)'
  } else {
    refPopover.value.style.zIndex = -1
    refPopover.value.style.transform = 'translate3d(0,0,-1px)'
  }
}

const onHidePopover = () => {
  visiblePopover.value = false
  calcPopoverPosition()
}

const onVisiblePopoverToggle = () => {
  visiblePopover.value = !visiblePopover.value
  calcPopoverPosition()
}

const onPopoverTouchstart = event => {
  event.stopPropagation()
}

useEventListener('touchstart', () => {
  onHidePopover()
})
defineExpose({
  onHidePopover,
})
</script>

<style scoped lang="less">
.terms-verify {
  padding: 8px 12px;
  text-align: center;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__tips, 
  &__message {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tips-icon {
    width: 14px;
    height: 14px;
    font-size: 14px;
    margin-right: 4px;
    display: inline-block;
  }

  &__message,
  &__tips--success,
  &_message--success {
    color: var(---sui_color_safety, #198055);
  }

  &__tips--error,
  &__message--error {
    color: var(---sui_color_unusual, #BF4123);
  }

  &__message-icon {
    width: 14px;
    height: 14px;
    font-size: 14px;
    margin-left: 4px;
    display: inline-block;
    color: rgba(149, 149, 149, 1);
    position: relative;
  }

  &__popover {
    position: fixed;
    z-index: -1;
    left: 24px;
    top: 700px;
    padding: 12px;
    width: calc(100vw - 48px);
    color: #fff;
    border-radius: 2px;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.8);
  }

  &__popover-close {
    position: absolute;
    right: 2px;
    top: 2px;
    font-size: 12px;
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__popover-triangle {
    border: 6px solid transparent;
    border-top-color: rgba(0, 0, 0, .8);
    position: absolute;
    bottom: -11px;
  }
}


</style>
