export const BANNER_EXPORATION_TIME = 60 * 10 // 分钟
export const getCookieKey = (key) => `${key}${gbCommonInfo.SiteUID}${gbCommonInfo.lang}`

export function removeApp(id, { removeHeight = true } = {}) {
  const element = document.getElementById(id)
  if (element) {
    element.parentNode.style.position = '' // 移除定位属性，防止影响页面布局

    if (removeHeight) {
      element.parentNode.style.height = '' // 移除高度。 切换页面不占位
    }
    
    element?.remove()
  }
}

/* 清空缓存 */ 
export const emitClearApp = ({ type }) => window.sheinBanner?.[type]?.Local.remove()

/**
 * @forced 强制更新
 * @return 返回值为存储数据是否成功
 * */ 
export const handleEmitApp = ({ type, el = {}, forced, cb } = {}) => {
  let outerHTML = el?.outerHTML || {}
  if (cb) {
    outerHTML = cb(outerHTML)
  }
  return window.sheinBanner?.[type]?.set(outerHTML, forced)
}
