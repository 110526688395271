import { getQueryString } from '@shein/common-function'
import { initSearchPageFirstLoadEvent, initSearchRedirectEvent } from '@/public/src/pages/common/search_words/search.js'
import { searchWordsGlobalAnalysis } from '@/public/src/pages/common/biz_helper/gb_sw_common'
import { spaJump } from '@/public/src/pages/common/utils/index.js'

const updateRoute = (vm) => {
  const data = { ...vm.Request.query }
  Object.keys(data).forEach((key) => {
    if ((key === 'min_price' || key === 'max_price') && data[key] !== '') return
    if (key === 'page' || !data[key]) return delete data[key]
    if (key === 'price_input') delete data[key]
  })

  const queryArr = Object.keys(data)
  if (!queryArr.length) return vm.$router.replace(vm.$route.path)

  // attr_values前置展示
  if (data.attr_values) {
    queryArr.splice(queryArr.indexOf('attr_values'), 1)
    queryArr.unshift('attr_values')
  }

  // 拼接替换url
  const showParams = queryArr.map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&')

  // router无法检测 history.replaceState, 侧边栏会视为同链接从而进不来
  vm.$router.replace(`${vm.$route.path}?${showParams}`)
}

const doSearchPreprocess = (vm, { newData }) => {
  const { searchKeywords } = newData || {}
  if (typeof window === 'undefined') {
    return false
  }
  const { store_code: storeCode, intent_channel_id, search_type: searchType } = vm.$route.query
  const localCacheWordKey = searchType === 'store' ? storeCode : intent_channel_id
  initSearchPageFirstLoadEvent()
  initSearchRedirectEvent()
  if (searchKeywords?.redirectUrl && !['store', 'brand'].includes(searchType)) {
    // search SPA重定向
    appEventCenter.$emit('searchRedirect', {
      searchWords: searchKeywords.keywords,
      pageType: searchType,
      localCacheWordKey,
      storeCode,
      word_id: getQueryString({ key: 'word_id' }) || 0,
    })
    searchWordsGlobalAnalysis.refreshRedir(true)

    const url = searchKeywords.redirectUrl //直达词在这跳的，
    // 直达词 走跳转
    if (/^\/\//.test(url)) {
      location.href = url
    } else {
      vm.Request.type = ''
      spaJump({
        routerVm: vm.$router,
        type: 'replace',
        url,
      })
    }

    return true
  } else if (vm.isFirstLoad && searchKeywords?.keywords) {
    // 搜索落地刷新历史记录
    appEventCenter?.$emit(
      'searchPageFirstLoad',
      Object.assign(searchKeywords, {
        pageType: searchType,
        localCacheWordKey,
        storeCode,
        word_id: getQueryString({ key: 'word_id' }) || 0,
      })
    )
    return false
  }
}

const setSilInMeta = (vm) => {
  // 商详返回时，激活实时反馈弹窗(页面被缓存，确保此时的页面mounted完成, 针对多级路由跳回，goods数据被销毁的情况)，参见需求FR-14861
  // 无用逻辑暂时注释
  // setFeedbackRecInfo({ productListInstance: vm.$refs.ProductList, path: fromRouteName })

  const { sil_type, list_cat_id, list_cat_name } = vm.$route.query
  sil_type && list_cat_id && list_cat_name
    ? (vm.$route.meta.silInfo = { sil_type, list_cat_id, list_cat_name })
    : delete vm.$route.meta.silInfo
}

export {
  updateRoute,
  doSearchPreprocess,
  setSilInMeta
}
