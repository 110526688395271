import { ref, computed, onMounted } from 'vue'
import { useStore } from './useStore'

/**
 * 用户资产弹窗
 */
export const CART_ASSETS_BENEFIT_POP = 'CartAssetsBenefitPop'

const isRenderMap = ref({
  [CART_ASSETS_BENEFIT_POP]: false,
})
const isLoadedMap = ref({
  [CART_ASSETS_BENEFIT_POP]: false,
})
const isOpenMap = ref({
  [CART_ASSETS_BENEFIT_POP]: false,
})
const popDataMap = ref({
  [CART_ASSETS_BENEFIT_POP]: {}
})

export const usePopStatus = (name) => {
  const isRender = computed(() => !!isRenderMap.value[name])
  const isLoaded = computed(() => !!isLoadedMap.value[name])
  const isOpen = computed(() => !!isOpenMap.value[name])
  const isShow = computed(() => isLoaded.value && isOpen.value)
  return {
    isRender,
    isLoaded,
    isOpen,
    isShow,
  }
}

export const usePopLoader = (name) => {
  const { commit } = useStore()

  const {
    isRender,
    isOpen,
    isShow,
  } = usePopStatus(name)

  const data = computed(() => popDataMap.value[name])

  const open = (data) => {
    if (!isRenderMap.value[name]) {
      commit('cart_v2/updateState', {
        key: 'loadingShow',
        value: true,
      })
      isRenderMap.value[name] = true
    }

    isOpenMap.value[name] = true
    if (data !== undefined) popDataMap.value[name] = data
  }
  const close = (data) => {
    isOpenMap.value[name] = false
    if (data !== undefined) popDataMap.value[name] = data
  }
  const updateData = (data) => {
    popDataMap.value[name] = data
  }

  return {
    isRender,
    isOpen,
    isShow,
    data,
    open,
    close,
    updateData,
  }
}

// 弹窗资源加载成功时执行
export const usePopLoaded = (name) => {
  const { commit } = useStore()

  onMounted(() => {
    commit('cart_v2/updateState', {
      key: 'loadingShow',
      value: false,
    })
    isLoadedMap.value[name] = true
  })
}
