import { CollectData } from './CollectData'
import { RouterAction } from './RouterAction.js'
import { TrackingController } from './TrackingController'
import userTrackerConfig from '../config/index'

function registerUserActionTracker(configs = [], { debug = false }) {
  const trackingController = new TrackingController({ debug })

  if (window.analysisBeforeInterceptor) {
    // 埋点拦截器
    window.analysisBeforeInterceptor.use(async result => {
      try {
        trackingController.triggerCollectDataHandlers(result.data)
      } catch(e) {
        console.error(e)
      }
      if (result.data?.activity_param?._actionId) {
        delete result.data.activity_param._actionId
      }
      return result
    })
  }

  function initUserActionTracker(configs = []) {
    function useUserAcitonTracker(config) {
      const collectDataInstance = new CollectData(config)
      const routerActionInstance = new RouterAction(config)
      trackingController.registerHandler({
        key: config.featureKey,
        closeSessionStorage: config.closeSessionStorage,
        collectDataInstance,
        routerActionInstance,
        notEndNames: config.notEndNames
      })
      return { collectDataInstance, routerActionInstance }
    }

    const instances = {}
    configs.forEach(config => {
      const { collectDataInstance, routerActionInstance } = useUserAcitonTracker(config)
      instances[config.featureKey] = {
        get() {
          return config.onTrigger({
            collectData: collectDataInstance.getData(),
            routerAction: routerActionInstance.getData(),
            end: () => {
              routerActionInstance.exposeNotEndTrigger()
            }
          })
        },
        set(type, value) {
          if (type === 'collectData') {
            collectDataInstance.trigger(value)
          } else if (type === 'routerAction') {
            routerActionInstance.trigger(value)
          }
        },
        onceSet(value, { to, from }) {
          return collectDataInstance.onceSet(value, { to, from })
        },
        // 开始收集
        startCollect(route) { 
          if (route) {
            routerActionInstance.onStart(route.to, route.from)
          } else {
            collectDataInstance.startCollect()
          }
        },
        // 清空数据
        clear(options) {
          collectDataInstance.endCollect(options)
        },
        // 提前校验是否结束
        preCheckEnd(to) {
          routerActionInstance.exposeCheckEnd({ to })
        },
        routerActionInstance,
        collectDataInstance,
      }
    })
    return instances
  }

  const instances = initUserActionTracker(configs)
  return {
    trackingController,
    instances
  }
}

let instances = null
function createAppUserActionTracker() {
  if (typeof window === 'undefined' || gbCommonInfo?.USER_ACTION_CLOSE == '1')  {
    // SSR
    return { triggerRouterActionHandlers: () => {} }
  }
  let _userTrackerConfig = userTrackerConfig
  let isDebug = false
  try {
    isDebug = localStorage.getItem('user_action_log')
  } catch(e) {
    // 
  }
  if (isDebug) {
    // 开启调试模式时，获取配置的特征key, 如果有就只使用配置的特征key，没有就使用全部
    // 例如： localStorage.setItem('user_action_log', 'click_refresh,search_cycle')
    const debugFeatureKey = `${isDebug}`.split(',') || []
    const debugConfigs = _userTrackerConfig.filter(config => debugFeatureKey.includes(config.featureKey))
    if (debugConfigs.length) {
      _userTrackerConfig = debugConfigs
    } else {
      console.warn('debug mode is open, but not found featureKey, use all featureKey!')
    }
  }

  const { instances: currentInstances, trackingController } = registerUserActionTracker(_userTrackerConfig, { debug: isDebug })
  instances = currentInstances
  window._userActionTracker = instances
  return {
    triggerRouterActionHandlers: (to, from) => {
      // 通知所有路由实例
      try {
        trackingController.triggerRouterActionHandlers(
          {
            name: to.name,
            path: to.path,
            query: to.query,
            params: to.params
          },
          {
            name: from.name,
            path: from.path,
            query: from.query,
            params: from.params
          }
        )
      } catch(e) {
        console.error(e)
      }
    }
  }
}

/**
 * @description: 获取用户行为数据
 * @param {String} featureKey 多个特征key，用逗号分隔
 * @return {Object | null} { value, collectData, routerAction } | null
 * */
function getUserActionTrackerData(featureKey) {
  if (!instances) {
    console.error('instances is not found')
    return 
  }
  if (!featureKey) {
    console.error('featureKey is required')
    return 
  }
  const keys = featureKey.split(',')
  return keys.reduce((acc, key) => {
    const value = instances[key]?.get?.()
    if (!value) return acc
    if (!acc) {
      acc = {}
    }
    return Object.assign({}, acc, value)
  }, null)
}

/**
 * @description: 设置用户行为数据
 * @param {String} featureKey
 */
function setUserActionTrackerData(featureKey, data) {
  if (!instances) return
  const instance = instances[featureKey]
  if (instance && data) {
    instance.set('collectData', data)
  }
}

/**
 * @description: 设置用户行为数据只使用一次, 之后数据会被清空
 * @param {String} featureKey
 * @param {Object} data 数据 
 * @param {Object} to 跳转目标 需要伪造路由
 * @param {Object} from 跳转来源 使用当前页面的路由信息即可
 * @return {string} 生成的id，用于混入数据重复时的去重
 */
function setUserActionTrackerDataOnce(featureKey, data = {}, { to, from } = {}) {
  if (!instances) {
    return
  }
  const instance = instances[featureKey]
  if (instance && data) {
    // to: { path, query, name, params } path和name必须
    return instance.onceSet(data, { to, from }) // 传递to，from时立即为开始记录，防止数据丢失
  }
}

/**
 * @description: 立即开始收集函数
 * @param {String} featureKey
 * @param {Object} to 跳转目标 需要伪造路由, { path, name }
 * @param {Object} from 跳转来源 使用当前页面的路由信息即可
 */
function handleStartCollectData(featureKey, route) {
  if (!instances) {
    return
  }
  const instance = instances[featureKey]
  if (instance) {
    instance.startCollect(route)
  }
}

/**
 * @description： 结束收集函数
 * @param {String} featureKey
 * @param {Object} options { keep: false } 默认清空数据和停止收集，keep为true时，只清空数据继续收集。
 * @return {Object} { collectData }
 */
function handleEndCollectData(featureKey, options = {}) {
  const currentInstance = instances && instances[featureKey]
  if (!currentInstance) {
    console.warn(`current featureKey: ${featureKey} is not found`)
    return null
  }
  const value = currentInstance.get()
  currentInstance.clear(options) // 清空数据
  return value
}

/**
 * @description: 提前校验是否结束，在路由跳转时调用,如果结束并执行清空操作
 * @param {Object} options.featureKey 特征key
 * @param {Object} options.to 跳转目标 需要伪造路由, { path, name
 */
function userTracekPreCheckEnd({ featureKey, to }) {
  const instance = instances?.[featureKey]
  if (!instance) return
  instance.preCheckEnd(to)
}

/**
 * @description: 特征动态配置项合并
 * @param {String} featureKey 特征key
 * @param {Object} config 动态配置项
*/
function onUserTracekConfigMerge(featureKey, config) {
  const currentInstance = instances && instances[featureKey]
  if (!currentInstance) {
    console.warn(`current featureKey: ${featureKey} is not found`)
    return null
  }
  currentInstance.collectDataInstance.onConfigMerge(config)
}

export {
  createAppUserActionTracker,
  getUserActionTrackerData,
  setUserActionTrackerData,
  setUserActionTrackerDataOnce,
  handleStartCollectData,
  handleEndCollectData,
  userTracekPreCheckEnd,
  onUserTracekConfigMerge,
}
