<template>
  <section class="advertising-page">
    <div class="advertising-page-banner">
      <!-- 顶部图 只渲染一次即可 一个站点只有一张 -->
      <Banner
        v-once
        :src="bannerImg"
      />
    </div>

    <div class="advertising-page-recommend">
      <AdvertisingRecommend
        ref="refRecommend"
        :page-type="pageType"
        :is-show-search-input="isShowSearchInput"
        :constant-data="constantData"
        :is-paid="sheinClubInfo.isPaid"
      >
        <template  
          v-if="isShowSearchInput" 
          #tab
        >
          <div 
            class="advertising-page-search"
          >
            <DefaultSearchBox
              ref="defaultSearchBoxRef"
              box-type="round"
              search-button-type="round"
              show-search-button
              :auto-fetch="isShowSearchInput"
              :fetch-search-terms-params="fetchSearchTermsParams"
              :httpConfig="httpConfig"
              @searchButtonClick="handleSearchButtonClick"
              @searchTermClick="handleSearchTermClick"
              @searchTermTransitionEnd="searchTermTransitionEnd"
              @resolveSearchTerms="resolveSearchTerms"
            />
          </div>
        </template>
      </AdvertisingRecommend>
    </div>
  
    <div
      v-once
      class="advertising-page-suspension"
    >
      <HomeIcon
        :language-home="language.SHEIN_KEY_PWA_25654"
      />
    </div>
  </section>
</template>

<script>
import storeModule, {
  storeModuleName
} from '../product_app/store/modules/advertising'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import AdvertisingRecommend from './components/AdvertisingRecommend/Index.vue'
import Banner from './components/Banner'
import HomeIcon from './components/HomeIcon'
import { DefaultSearchBox } from '@shein-aidc/bs-search-box-mobile-vue2'

import hiddenBackTopMixins from 'public/src/pages/advertising/mixins/hiddenBackTopMixins'
import advertisingAnalysis from './analysis'
import analysis from 'public/src/pages/common/search_words/analysis/index'

import { getQueryString, stringifyQueryString } from '@shein/common-function'

import { onClickProductCardInGoodsDetail } from 'public/src/pages/mounting/eventBus/eventLib/eventOn'
import { offClickProductCardInGoodsDetail } from 'public/src/pages/mounting/eventBus/eventLib/eventOff'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'
import mixin from 'public/src/pages/components/product/item_v2/js/mixin.js'
import BffAdapter from './common/bffAdapter'
let defaultWords = [] // 底纹词数组
const exposeSearchTextArr = []
export default {
  name: 'AdvertisingPage',
  components: {
    Banner,
    AdvertisingRecommend,
    HomeIcon,
    DefaultSearchBox,
  },
  mixins: [hiddenBackTopMixins, mixin],
  provide () {
    return {
      advertisingAnalysis,
      getIsSupportCropImage: () => true,
    }
  },
  asyncData ({ store, context }) {
    if (!store.state[storeModuleName]) {
      store.registerModule(storeModuleName, storeModule)
    }
    if (context?.cat_info?.pageName === 'page_advertising') {
      store.state[storeModuleName].contextForSSR = context
    }
  },
  data() {
    return {
      isFirst: true, // 首次进入
    }
  },
  computed: {
    ...mapState(storeModuleName, ['pageType', 'hooksGoods', 'locals', 'language', 'sheinClubInfo', 'catInfo', 'fetchSearchTermsParams', 'isShowSearchInput', 'httpConfig']),
    ...mapGetters(storeModuleName, ['bannerImg']),
  },
  created() {
    const { contextForSSR } = this.$store.state[storeModuleName] || {}
    if (contextForSSR) {
      const bffAdapter = new BffAdapter({
        contextForSSR,
      })
      const newContext = bffAdapter.adaptSsrData(contextForSSR)
      this.resetLocals(newContext)
      this.initData(newContext)
      advertisingAnalysis.init({
        hooksGoods: this?.hooksGoods
      })
    } else {
      // 先直接刷新页面，这样走兜底逻辑,后续在考虑优化，走spa发请求首屏
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    }
  },
  activated() {
    if (!this.isFirst) {
      advertisingAnalysis.init({
        hooksGoods: this?.hooksGoods
      })
      this.resetAnalysis()

      const userClickProductLatest = JSON.parse(
        window.sessionStorage?.getItem('userClickProductLatest') || '{}'
      )
      const { cat_id: detailBackCatId, goods_id: detailBackGoodsId } =
        userClickProductLatest || {}
      detailBackGoodsId && this.changeFetchSearchTermsParams({ key: 'goods_id', value: detailBackGoodsId })
      detailBackCatId && this.changeFetchSearchTermsParams({ key: 'cat_id', value: detailBackCatId })
      window.sessionStorage.removeItem('userClickProductLatest') // 已经请求过的清除掉，不然在页面进行category 或者 图文导航筛选的时候，因为这个时候 isBackFromDetail 是 true，所以会有问题
    }
    this.isFirst = false
    appEventCenter.$emit('pageOnload')
  },
  mounted() {
    onClickProductCardInGoodsDetail((args) => {
      window.sessionStorage?.setItem('userClickProductLatest', JSON.stringify(args))
    })
    if (this.isFirst) {
      advertisingAnalysis.init({
        hooksGoods: this?.hooksGoods
      })
      this.resetAnalysis()
    }
  },
  beforeDestroy() {
    offClickProductCardInGoodsDetail()
  },
  methods: {
    ...mapActions(storeModuleName, [
      'initState'
    ]),
    ...mapMutations(storeModuleName, [
      'assignRootState',
      'resetLocals',
      'changeFetchSearchTermsParams',
    ]),
    resolveSearchTerms(keywords) {
      // 曝光第一条数据
      defaultWords = keywords
      if(defaultWords.length === 0) {
        analysis.exposeSearch({
          defaultWords: '',
          placeholder: '',
          localIndex: 0,
        })
        return
      }
      window.sessionStorage.setItem('advertisingDefaultWords', JSON.stringify(keywords ?? []))
      // 为了处理：UG-148607
      const ref = this.$refs.defaultSearchBoxRef
      if (ref && ref.updateSwiper) {
        ref.updateSwiper()
      }
      if(exposeSearchTextArr.includes(keywords[0]?.word) || !keywords[0]?.word) {
        return
      }
      
      analysis.exposeSearch({
        defaultWords: [keywords[0]],
        placeholder: keywords[0],
        localIndex: 0,
      })
      exposeSearchTextArr.push(keywords[0]?.word)
    },
    // 轮播回调曝光
    searchTermTransitionEnd(item, index) {
      if(exposeSearchTextArr.includes(item?.word)) {
        return
      }
      analysis.exposeSearch({
        defaultWords: [item],
        placeholder: defaultWords[0],
        localIndex: index,
      })
      exposeSearchTextArr.push(item?.word)
      return
    },
    // 点击跳转搜索结果页
    handleSearchButtonClick(item, index) {
      // 如果底纹词为空，跳转预搜页
      if(!item) {
        this.handleSearchTermClick(null) // 传null, 是保证为空时和直接点击搜索框跳预搜页的传值一样
        return
      }
      let payload = { item, index }
      analysis.search({
        keyword: item?.word || '-',
        full_name: '-',
        result_type: 3,
        search_redir: '-',
        data: {
          position: payload?.index + 1,
          type: item?.type ?? '-',
        },
        crowdId: item?.crowdId || '-',
        originWord: item?.word || '-',
        pageType: 'all',
        trend_list_content: '-',
      })
      // 设置点击搜索按钮直接跳转搜索结果页pagefrom埋点问题
      searchWordsGlobalAnalysis.set({
        result_type: 3,
        result_word: item?.word,
        d: 0,
        parent_page: SaPageInfo?.page_name,
        entrancetype: SaPageInfo?.page_param?.entrancetype || '-',
      })

      const currentSearchTermWord = payload?.item?.word
      const currentSearchTermIndex = payload?.index
      const params = {
        src_identifier: `st=${3}\`sc=${currentSearchTermWord}\`sr=0\`ps=${currentSearchTermIndex + 1}`,
        src_module: 'search',
        src_tab_page_id: window?.getSaPageInfo?.tab_page_id || '',
      }
      const url = `${this.langPath}/pdsearch/${encodeURIComponent(currentSearchTermWord)}/?${stringifyQueryString({ queryObj: params })}`
      this.$router.push(url)
    },
    // 点击跳转预搜页（从header的input扣出来的跳转预搜逻辑）
    handleSearchTermClick(searchTerm) {
      let payload = {
        placeholder: searchTerm,
      }
      analysis.clickSearch(payload)
      // 将当前轮换的默认词，显示在搜索页输入框内
      if (payload?.placeholder) {
        gbCommonInfo.keywords = ''
        this.assignRootState({
          currentDefaultKeyword: payload.placeholder.word,
          searchSelectKeyWord: '',
          mixedKeyword: '',
          inputDefaultWordsIndex: defaultWords?.findIndex(i => i?.word === payload.placeholder?.word)
        })
      } else {
        gbCommonInfo.keywords = ''
        this.assignRootState({
          mixedKeyword: '',
          searchSelectKeyWord: '',
          currentDefaultKeyword: ''
        })
      }

      // 标识进入新页面
      this.$route.meta.isNewPage = true
      this.$route.meta.cameraFrom = null
      const pagefrom = window?.SaPageInfo?.page_name
      const query = {
        pageType: 'all',
        pagefrom
      }

      const src_identifier = getQueryString({ key: 'src_identifier' })
      if (src_identifier) {
        query.src_identifier = src_identifier
      }
      
      this.$router.push({
        path: `${this.langPath}/presearch`,
        query
      })
  
    },
    initData(payload) {
      payload && this.initState(payload)
    },
    resetAnalysis () {
      advertisingAnalysis.sendPV()
      this.$refs.refBigCard?.resetAnalysis?.()
      this.$refs.refRecommend?.resetAnalysis?.()
    }
  }
}
</script>
<style lang="less" scoped>
@import url( '@shein-aidc/bs-search-box-mobile-vue2/style.css');

.advertising-page-search {
  --search-box-border-color: #000000;
  --search-box-box-height: 34px;
  padding-top: .21rem;
  padding-left: .267rem /* 10/37.5 */;
  padding-right: .267rem /* 10/37.5 */;
  background-color: #fff;
}
</style>
