import './base.css'
import { h } from 'vue'

export function Price({
  price
}) {
  const { currencies, lang, currency } = gbCommonInfo
  const { mainPriceInfo } = price
  const salePrice = mainPriceInfo?.value?.value
  return (
    <div>
      <span class="quick-add__free-trial-price">
        { `${currencies[lang][currency].symbol_left}0.00` }
      </span>
      {
        salePrice ? (
          <span class="quick-add__free-trial-saleprice">{ salePrice }</span>
        ) : null
      }
    </div>
  )
}
