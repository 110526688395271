
import DETAIL_API_LOCATION from 'public/src/pages/product_app/store/modules/product_detail/api.js'
import schttp from 'public/src/services/schttp'
import qs from 'qs'
import { getQueryString } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
export const postAddToCart = async (data) => {
  return schttp({
    url: DETAIL_API_LOCATION.GOODS_ADD_TO_CART,
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(data, { arrayFormat: 'brackets' }),
    useBffApi: true,
  })
}
export const postAddToCartBatch = async (data) => {
  return schttp({
    url: DETAIL_API_LOCATION.GOODS_ADD_TO_CART_BATCH,
    method: 'POST',
    data,
    useBffApi: true,
  })
}
export const handleMsgTip = (data, config) => {
  const { code, tips, msg } = data || {}
  const { language } = config || {}
  let type = 'fail'
  let msgContent = tips || msg || language?.SHEIN_KEY_PWA_15282 || 'error'
  let isOverLimit = false
  if (code == '0') {
    type = 'success'
    msgContent = language?.SHEIN_KEY_PWA_15060 || ''
  }
  if (code == '500305') {
    type = 'limitPop'
  }
  if (code == '300402') {
    // 售罄
    msgContent = language?.SHEIN_KEY_PWA_15230 || ''
  }
  if (code == '200401' || code == '500301') {
    // 超出限购数量
    msgContent = language?.SHEIN_KEY_PWA_14967 || ''
  }
  if (code == '500306' || code == '500302' || code == '300417') {
    // 单个/全部 闪购商品加车达到上限 中间层处理 tips
    // TODO: 单个商品达到上限时需要重置加车选择器数量
    isOverLimit = true
  }
  return {
    type,
    msgContent: msgContent,
    isOverLimit,
  }
}
// 混入预搜页找相似进入的埋点参数
export const assignPreSearchSimilarAnalysis = data => {
  const isFromPreSearchSimilarItems = !!getQueryString({ key: 'preSearchSimilarItems' })
  const srcTabPageId = getQueryString({ key: 'src_tab_page_id' }) || ''
  if (isFromPreSearchSimilarItems && srcTabPageId) {
    const result = Object.assign(
      data,
      {
        src_module: 'presearch_similar',
        src_identifier: '-',
        src_tab_page_id: srcTabPageId
      },
      {}
    )
    return result
  }
  return data
}
// 加车埋点上报
const reportAddToCartSa = (data) => {
  // TODO: 后面出一个商品项转换埋点的参数的函数
  const { 
    target,
    result,
    reqParams,
    extraParams, 
    extraSaParams,
    base_info,
    store_info,
  } = data || {}
  const { 
    mall_code = '',
    customization_info = '',
  } = reqParams || {}
  const customizationInfo = customization_info && typeof customization_info == 'string' ? JSON.parse(customization_info) : {}
  const { 
    goods_sn = '',
    product_relation_id = '',
    salePrice = {},
    cat_id = '',
    unit_discount = '',
  } = base_info || {}
  const {
    business_model = '',
    store_code: storeCode = '',
  } = store_info || {}
  const traceid = typeof SaPageInfo !== 'undefined' ? (SaPageInfo?.page_param?.traceid || '') : ''
  let extraData = {
    code: 'goodsDetail',
    result,
    postData: {
      sku: goods_sn,
      spu: product_relation_id || goods_sn,
      price: salePrice?.usdAmount,
      catId: cat_id,
      discount: unit_discount,
      index: 0,
      amount: salePrice?.amount,
      businessModel: business_model,
      storeCode,
      mallCode: mall_code,
      location: 'page',
      image_tp: 'small',
      is_customize: customization_info ? 1 : 0,
      service_invocation_situation: customizationInfo?.customSpecialService?.service_invocation_situation || '',
      service_type: customizationInfo?.customSpecialService?.service_type || '',
      custominfoid: customizationInfo?.customInfoId || '',
      ...(reqParams || {}),
      ...(extraSaParams || {}), 
    },
    from: 'main',
    review_location: '',
    traceid,
    button_type: 'add_to_bag',
    ...(extraParams || {}),
  }
  extraData = assignPreSearchSimilarAnalysis(extraData)
  daEventCenter.triggerNotice({
    daId: '1-8-1-1',
    target,
    extraData
  })
}

export const addCart = async (params, config) => {
  const AddPopInstance = await import('public/src/pages/goods_detail_v2/innerComponents/drawer/AddPop/index.js').then((res) => res.default).catch(() => null)
  AddPopInstance?.open?.({
    type: 'loading',
    isFromBigImage: !!config?.isFromBigImage,
  })
  return await (postAddToCart(params)
    .then(async (data) => {
      AddPopInstance?.close?.()
      const msgInfo = handleMsgTip(data, config)
      // 主商品加车走动画,不需要展示弹窗
      const showTipsPopup = msgInfo?.type === 'fail' || (msgInfo?.type === 'success' && !config?.closeSuccessPop)
      if (showTipsPopup) {
        // 加车提示处理
        AddPopInstance?.open?.({
          ...msgInfo,
          language: config?.language,
          isFromBigImage: !!config?.isFromBigImage,
        })
      }
      // 特殊code上报日志
      if (data?.code == '200301') {
        window?.ErrorJs?.sendError('error', `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`, {
          errorMark: 'AddBagBrowserPwaError',
          errorInfo: config?.errorInfo,
        })
      }
      // 加车埋点
      reportAddToCartSa({
        result: data,
        reqParams: params,
        target: config?.target || null,
        extraParams: config?.extraParams || {},
        extraSaParams: config?.extraSaParams || {},
        base_info: config?.base_info || {},
        store_info: config?.store_info || {},
      })
      return {
        result: data,
        msgInfo,
      }
    })
    .catch((error) => {
      console.log(`加车失败`)
      window?.ErrorJs?.sendError('error', `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`, {
        errorMark: 'AddBagBrowserPwaFourError',
        errorInfo: JSON.stringify({
          err: error?.message || '',
          ...JSON.parse(config?.errorInfo || {}),
        })
      })
      if (error?.status == 403) {
        // 上报日志
        SILogger.logInfo({
          tag: 'GOODS_DETAIL_PWA_CLIENT_V3',
          message: 'add_to_cart_error',
          params: {
            scene: 'add_to_cart',
            msgData: error
          }
        })
      }
    }))
}

export const addCartBatch = async (params, config) => {
  const data = await postAddToCartBatch(params).catch(() => null)
  const msgInfo = handleMsgTip(data, config)
  return {
    result: data,
    msgInfo,
  }
}
