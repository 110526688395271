<template>
  <div
    v-if="timer"
    class="quickAddEstimatedTagNewCountdown">
    <Icon
      name="sui_icon_time_12px"
      size="12px"
      color="#FA6338"
      class="quickAddEstimatedTagNewCountdown__icon"
    />
    <div class="bsc-price__count-down">
      <p>{{ hms.H }}</p>
      <p class="quickAddEstimatedTagNewCountdown__colon">:</p>
      <p>{{ hms.M }}</p>
      <p class="quickAddEstimatedTagNewCountdown__colon">:</p>
      <p>{{ hms.S }}</p>
      <template v-if="isShowCountDownMillSec">
        <p class="quickAddEstimatedTagNewCountdown__colon">:</p>
        <p>{{ timer?.secondHundred }}</p>
      </template>
    </div>
  </div>
</template>

<script setup name="EstimatedTagNewCountdown">
/**
 * 到手价标签新样式右侧倒计时
 */
import { Icon } from '@shein-aidc/icon-vue2'
import { watch, ref, computed } from 'vue'
import { useCountDown } from '../../../../../utils/countDown'

const props = defineProps({
  endTime: {
    type: String,
    default: '',
  },
  isShowCountDownMillSec: {
    type: Boolean,
    default: false,
  },
  nowTime: {
    type: Number,
    default: 0,
  },
})

const timer = ref(null)

const hms = computed(() => {
  let { H, M, S } = timer.value?.timeObj || {}
  return { H, M, S }
})

const initTimer = () => {
  timer.value = useCountDown({
    endTime: props.endTime,
    nowTime: props.nowTime,
    isShowCountDownMillSec: props.isShowCountDownMillSec,
  })
}

watch(
  () => props.endTime,
  () => {
    if (props.endTime && props.nowTime) {
      initTimer()
    }
  },
  { immediate: true },
)
</script>

<style lang="less">
.quickAddEstimatedTagNewCountdown {
  display: flex;
  flex-wrap: nowrap;
  color: #fa6338;
  font-size: 11px;
  white-space: nowrap;
  transform: translateY(.2133rem);
  &__icon {
    font-size: 11px !important;/* stylelint-disable-line declaration-no-important */
    margin-right: 3px;
  }
  &__colon {
    margin-right: 1px;
  }
}

.bsc-price__count-down {
  display: flex;
  line-height: 1;
  direction: ltr; /* rtl ignore */
}
</style>
