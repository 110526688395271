<template>
  <div
    v-if="goodsStatusInfo.tips"
    ref="tipsWrap"
    :data-belt-available="!!availableType"
    :data-type="goodsStatusInfo.type"
    class="shopping-bag-item__tips"
    :class="goodsStatusInfo.class"
  >
    <Icon 
      v-if="goodsStatusInfo.icon"
      v-bind="goodsStatusInfo.icon"
    />
    <CheckoutCountDown
      v-if="goodsStatusInfo.endTimeStamp"
      :end-time-stamp="goodsStatusInfo.endTimeStamp"
      v-slot="{timer}"
      ref="tipsText"
      :style="{
        maxWidth: goodsStatusInfo.icon ? 'calc(100% - 12px)' : '100%',
      }"
      @finish="timerFinished = true"
    >
      {{ timer.timeObj.H }}:{{ timer.timeObj.M }}:{{ timer.timeObj.S }}
    </CheckoutCountDown>
    <span 
      v-else
      ref="tipsText"
      :style="{
        maxWidth: goodsStatusInfo.icon ? 'calc(100% - 12px)' : '100%',
      }"
      v-html="goodsStatusInfo.tips"
    >
    </span>
  </div>
</template>

<script setup>
import { useMapState, useMapGetters, useMapActions } from 'public/src/pages/checkout/hooks/index.js'
import { ref, computed, nextTick, onMounted, getCurrentInstance, watch } from 'vue'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import CheckoutCountDown from 'public/src/pages/checkout/components/CheckoutCountDown.vue'
import useLowStock from 'public/src/pages/checkout/hooks/useLowStock.js'
import { BeltType, ShoppingBagScene, beltPriority } from './config.js'

const instance = getCurrentInstance()

const props = defineProps({
  lagreShipInfo: {
    type: Object,
    default: () => {}
  },
  isOutStockGood: {
    type: Boolean,
    default: false
  },
  isGiftGoods: {
    type: Boolean,
    default: false
  },
  isSheinClubGift: {
    type: Boolean,
    default: false
  },
  isAddBuyGoods: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => {}
  },
  // 是否为购物袋弹窗
  inDrawer: {
    type: Boolean,
    default: false
  }
})

const {
  language,
} = useMapState([
  'language',
])

const { 
  outOfStockTips,
  shoppingBagBeltPriority,
  cartrowLowstockInfoAbt
} = useMapGetters([
  'outOfStockTips',
  'shoppingBagBeltPriority',
  'cartrowLowstockInfoAbt'
])

const { updateCart } = useMapActions(['updateCart'])

const timerFinished = ref(false)

const availableType = computed(() => {
  // 只上报 abt 有配置的腰带类型
  const defaultPriority = [
    BeltType.HotSale,
    BeltType.FlashSale,
    BeltType.Cheaper,
    BeltType.AlmostSoldOut
  ]
  return defaultPriority.some(type => !!getBeltConfig(type))
})

const goodsStatusInfo = computed(() => {
  let info = { tips: '', class: '' }
  let scene = ShoppingBagScene.InPage
  if(props.inDrawer) {
    scene = ShoppingBagScene.InDrawer
  }
  const priority = beltPriority(scene, shoppingBagBeltPriority.value)
  priority.find(type => {
    const config = getBeltConfig(type)
    if (config) info = { ...config, type }
    return config
  })
  return info || {}
})

const getBeltConfig = (type) => {
  switch(type) {
    case BeltType.LargeShipGood: 
      if (props.lagreShipInfo?.isLargeShipGood != 1) return
      return {
        tips: props.lagreShipInfo?.largeShipTitle,
        class: 'large-tips',
      }
    case BeltType.OutOfStock: 
      if (!props.isOutStockGood) return
      return {
        tips: language.value.SHEIN_KEY_PWA_14987,
        class: 'soldout-tips',
      }
    case BeltType.Gift: 
      if (!props.isGiftGoods) return
      return {
        tips: language.value.SHEIN_KEY_PWA_25536,
        class: 'gift-tips',
      }
    case BeltType.SheinClubGift:
      if (!props.isSheinClubGift) return
      return {
        tips: language.value.SHEIN_KEY_PWA_31169,
        class: 'club-gift-tips',
      }
    case BeltType.AddOnItem:
      if (!props.isAddBuyGoods) return
      return {
        tips: language.value.SHEIN_KEY_PWA_28687,
        class: 'add-tips',
      }
    case BeltType.HotSale: 
      return getHotSaleInfo()
    case BeltType.FlashSale: 
      return getFlashSaleInfo()
    case BeltType.Cheaper: 
      return getCheaperInfo()
    case BeltType.AlmostSoldOut: 
      return getAlmostSoldOutInfo()
    default:
      return
  }
}

const getHotSaleInfo = () => {
  const sales = +props.item.product.realTimeTspLabels?.['3451'] || 0
  if (sales <= 50) return
  const tipsRule = [
    { end: 100, tips: '50+' },
    { end: 1000, tips: `${Math.floor(sales / 100) * 100}+` },
    { end: 10000, tips: `${Math.floor(sales / 100) / 10}k+` },
    { end: -1, tips: '10k+' }
  ]
  return {
    tips: template(
      tipsRule.find(rule => sales <= rule.end || rule.end == -1).tips,
      language.value.SHEIN_KEY_PWA_33210
    )
  }
}

const getFlashSaleInfo = () => {
  let isFlashSale, endTimeStamp
  const now = Date.now()
  props.item.product.promotionInfo.forEach(item => {
    // 10常规闪购、11直播闪购
    if ([10, 11].includes(+item.typeId)) {
      isFlashSale = true
      const endTime = item.endTimestamp.length < 13 ? +item.endTimestamp * 1000 : +item.endTimestamp
      const offset = endTime - now
      // 阈值为72小时
      const reachLimit = offset > 0 && offset < 72 * 60 * 60 * 1000
      const isEndFaster = !endTimeStamp || endTime < endTimeStamp
      if (reachLimit && isEndFaster) endTimeStamp = endTime
    }
  })
  if (!isFlashSale) return
  return {
    tips: language.value.SHEIN_KEY_PWA_33206,
    endTimeStamp: endTimeStamp - Date.now() > 0 ? endTimeStamp : 0,
    icon: {
      name: 'sui_icon_flashsale_12px',
      color: '#FACF19',
      size: '12px'
    }
  }
}

const getCheaperInfo = () => {
  const { amount = 0, amountWithSymbol } = props.item.reduce_price?.reduceAmount || {}
  if (amount <= 0) return
  return { 
    tips: template(
      `${amountWithSymbol}`, 
      language.value.SHEIN_KEY_PWA_33211
    ) 
  }
}

const getAlmostSoldOutInfo = () => {
  // 低库存腰带处理
  const { lowStockProducts, isStockReason } = useLowStock({ abt: outOfStockTips.value, carts: [props.item] })
  if (!lowStockProducts.value.length) return

  const { SHEIN_KEY_PWA_33247, SHEIN_KEY_PWA_33248, SHEIN_KEY_PWA_35416, SHEIN_KEY_PWA_35417, SHEIN_KEY_PWA_35418 } = language.value || {}

  let tips = SHEIN_KEY_PWA_33247
  if (isStockReason.value) {
    tips = template(props.item.product.real_stock, SHEIN_KEY_PWA_33248)
  } else {
    // 方案一：针对 Almost Sold Out 做处理
    const { status, selldays } = cartrowLowstockInfoAbt.value || {}
    const availableSaleDays = Number(props.item?.product?.availableSaleDays || 0)
    if(status == 'off'){
      if(selldays != 'off' && availableSaleDays < +selldays || availableSaleDays == +selldays){
        tips = ( availableSaleDays < 1 || availableSaleDays == 1 ) 
          ? SHEIN_KEY_PWA_35416
          : ( availableSaleDays > 1 && (availableSaleDays < 2 || availableSaleDays == 2) ) ? SHEIN_KEY_PWA_35417 : template(Math.ceil(availableSaleDays), SHEIN_KEY_PWA_35418)
      }
    }
  }
  return { tips }
}

const handleTextFont = () => {
  nextTick(() => {
    if(typeof window == 'undefined') return

    const textDom = instance?.proxy?.$refs?.tipsText
    if(textDom) {
      const { offsetWidth, scrollWidth } = textDom
      if(scrollWidth === offsetWidth) return
      const scaleRatio = offsetWidth / scrollWidth
      const currentFontSize = parseFloat(getComputedStyle(textDom).fontSize)
      const adaptableFontSize = Math.floor(currentFontSize * scaleRatio)
      const minFontSize = 8
      const isTooSmall = adaptableFontSize < minFontSize
      const fontSize = isTooSmall ? minFontSize : adaptableFontSize
      textDom.style.fontSize = `${fontSize}px`
      // 如果计算后的字号小于最小字号，则证明需要换行展示
      textDom.parentElement.style.height = `${isTooSmall ? 48/75 : 36/75}rem`
      textDom.classList.add('text-ellipsis')
    }
  })
}

onMounted(() => {
  handleTextFont()
})

watch(
  () => goodsStatusInfo.value?.tips,
  () => {
    if (!!goodsStatusInfo.value?.tips) handleTextFont()
  }
)

watch(
  () => timerFinished.value,
  (newVal, oldVal) => {
    if (newVal != oldVal && newVal) updateCart({ noUpdataShipping: 1 })
  }
)
</script>

<style lang="less" scoped>
 .shopping-bag-item__tips {
    font-size: 12px;
    text-align: center;
    width: 100%;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    white-space: nowrap;
    color: #fff;
    background: rgba(0, 0, 0, 0.60);
    line-height: normal;

    .text-ellipsis {
      white-space: normal;
      overflow : hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    
    &.add-tips {
      background: #FCF5EF;
      color: #AD633D;
    }
    &.gift-tips {
      background: #FB7751;
      color: #fff;
    }
    &.soldout-tips {
      background: @sui_color_black;
      opacity: 0.6;
      color: #fff;
    }
    &.club-gift-tips {
      color: @sui_color_white;
      background: @sui_color_club_rosegold_dark1;
    }
    &.large-tips {
      color: @sui_color_white_alpha96;
      background: @sui_color_gray_alpha60;
    }
  }
</style>
