<template>
  <div
    v-expose="{
      id: 'expose_scenesabt.comp_pay-toolkit',
      data: {
        scenes: 'bnpl_visual_component',
        if_show_bnpl_component: options.abt_open || '-', // 0 不开启 1 开启 根据后端传参【动态化展示开关】确认是否开启
      }
    }"
    class="payment-bnpl-graph"
    :class="{
      'meet-condition': !!bnplGraphList.length,
    }"
    :style="{
      '--bnpl_color': options.color
    }"
  >
    <div
      v-if="bnplGraphList.length"
      v-expose="{
        id: 'expose_bnpl_component.comp_pay-toolkit',
        data: {
          payment_method: paymentCode,
          installments_number: options.count,
        }
      }"
      class="wrap"
      :class="itemNum"
    >
      <div
        v-for="(item, idx) of bnplGraphList"
        :key="`item-${idx}`"
        class="item"
        :class="{ 'item-last': item.isLast, 'item-start': item.isStart }"
      >
        <div class="item__percent">
          <GraphRing
            :percent="item.percent"
            :color="options.color"
          />
        </div>
        <div class="item__info-wrap">
          <div class="item__amount">
            {{ item.price }}
          </div>
          <div class="item__txt">
            {{ item.date_desc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script name="ItemInfoBnplGraph" setup lang="ts">
import { computed, inject } from 'vue'
import { template as _commonTemplate } from '@shein/common-function'
import GraphRing from './ItemInfoBnplGraphRing.vue'
import type { AS_PayToolkit } from '../../../../types'
const { vExpose } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

interface ItemInfoBnplGraphProps {
  scene: string;
  paymentCode: string;
  options: {
    cycle: {
        progress: string;
        price: string;
        date_desc: string;
    }[];
    color: string;
    count: string;
    abt_open: string;
    has_nj_data: string;
    is_show: string;
  }
}

const props = withDefaults(defineProps<ItemInfoBnplGraphProps>(), {
  scene: 'checkout',
  paymentCode: '',
  options: () => ({
    cycle: [],
    color: '',
    count: '',
    abt_open: '',
    has_nj_data: '',
    is_show: '',
  }),
})

const itemNum = computed(() => {
  const len = props.options?.cycle?.length || 0
  return `item-num-${len > 6 ? 6 : len}`
})

const bnplGraphList = computed(() => {
  if (Number(props.options?.is_show) !== 1) return []
  return props.options?.cycle?.map?.((item, idx) => {
    return {
      percent: (Number(item.progress) / Number(props.options.count)) * 100,
      price: item.price,
      date_desc: item.date_desc,
      isLast: idx === props.options.cycle.length - 1,
      isStart: idx === 0,
    }
  }) || []
})

</script>

<style scoped lang="less">
.payment-bnpl-graph {
  position: relative;
  // height: 77/37.5rem;
  &.meet-condition {
    border: 1/37.5rem solid #d9d9d9;
    border-radius: 4/37.5rem;
    margin-top: 10/37.5rem;
    &::after {
      content: ' ';
      position: absolute;
      top: -5.5/37.5rem;
      left: 35/37.5rem;
      width: 10/37.5rem;
      height: 10/37.5rem;
      border: 1/37.5rem solid #d9d9d9;
      border-top-left-radius: 1/37.5rem;
      border-bottom: none;
      border-right: none;
      background-color: #fff;
      // rotate: 45deg;
      transform: rotate(45deg);
    }
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 7/37.5rem 8/37.5rem;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .item-num-2,
  .item-num-3 {
    padding: 7/37.5rem 20/37.5rem;
  }

  // .item-num-4,
  // .item-num-5,
  // .item-num-6 {
  //   padding: 7/37.5rem 8/37.5rem;
  // }

  .item-num-2 .item {
    display: flex;
    .item__percent {
      margin-bottom: 2/37.5rem;
    }
    .item__info-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 6/37.5rem;
      margin-bottom: 2/37.5rem;
    }

  }
  .item {
    min-width: 55/37.5rem;
    flex: 0 0 auto;
    line-height: 1;
    padding: 0 4/37.5rem;
    .item__percent {
      display: flex;
      justify-content: center;
      margin-bottom: 6/37.5rem;
    }
    .item__amount {
      text-align: center;
      color: var(---sui_color_gray_dark2, #666);
      font-size: 11/37.5rem;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
    }
    .item__txt {
      text-align: center;
      color: var(---sui_color_gray_light1, #959595);
      font-size: 10/37.5rem;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'SF Pro';
    }
  }
  .item-start {
    // margin-left: 20/37.5rem;
    padding-left: 0;
    .item__amount {
      font-weight: 590;
      color: var(---sui_color_brand, #000);
    }
    .item__txt {
      font-weight: 590;
      color: var(--bnpl_color);
    }
  }
  .item-last {
    padding-right: 0;
  }

  // .triangle-icon{
  //   position: absolute;
  //   top: -5/37.5rem;
  //   left: 35/37.5rem;
  //   // width: 12/37.5rem;
  //   // height: 12/37.5rem;
  //   // border: 1/37.5rem solid #D9D9D9;
  //   // border-bottom: none;
  //   // border-right: none;
  //   color:#D9D9D9;
  //   // fill:#D9D9D9;
  //   background-color: #fff;
  // }
}
</style>
