import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_QuickAddCartStandard } from '../../types'
import { getFsDataRunner } from '../../common/dataSource'
export { default, default as BQuickAddCartStandard } from './quick-add-cart-standard.vue'
export { useQuickAddCart } from './hook/useQuickAddCart.ts'
export { PricePlugin } from './plugins/price'
export { DirectlyAddCartPlugin, validOneSkc, validOneSku, validCustomization } from './plugins/directlyAddCart'
export { SalesAttrUpdatePlugin } from './plugins/salesAttrUpdate'
export { CustomizationProductPlugin } from './plugins/customization'
export * from './plugins/index'
export type * from '../../types'

export function getQuickAddCartStandardFsDataRunner(sourceParams?: Partial<DS_QuickAddCartStandard.FS_SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
