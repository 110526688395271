import schttp from 'public/src/services/schttp'
export default {
  namespaced: true,
  state: {
    context: null
  },
  mutations: {
    update_context(state, payload) {
      state.context = payload
    }
  },
  actions: {
    async query_ccc_banner_date({ rootGetters, commit }) {
      if(rootGetters['productDetail/common/fs_abt']?.detailbanner?.p?.detailbanner === 'notshow') return false
      let result = await schttp({
        method: 'GET',
        url: '/ccc/common_component',
        useBffApi: true,
        params: {
          cccPageType: 'productDetailsPageNew',
          cateId: rootGetters['productDetail/common/goods_sn'],
          cateType: 3,
        },
      })
      commit('update_context', result.info)
    }
  },
  getters: {
    show(state, getters, rootState, rootGetters) {
      const isThirdPartyStore = rootGetters['productDetail/common/store_info']?.storeType == 1
      const { content } = getters
      if (!(content && content[0] && content[0].props.items.length)) return false
      const { isThirdPartyMerchantBanner = false } = content[0].props?.metaData || {}
      if (isThirdPartyStore && !isThirdPartyMerchantBanner) return false
      return true
    },
    context(state) {
      return state.context || {}
    },
    content(state, getters) {
      return getters.context.content || []
    },
    scene_data(state, getters) {
      const { pageType = '', id = '' } = getters.context || {}
      return {
        sceneName: pageType,
        pageType: pageType,
        pageFrom: pageType,
        id,
      }
    },
  }
}
  
