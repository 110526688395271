<template>
  <!-- 最外面这一层为了处理气泡动画DOM包的 -->
  <div
    v-if="!IS_RW"
    class="j-cart-bag-container"
  >
    <div
      class="cart-bag-wrapper"
      :class="searchFeedbackVisibility ? 'cart-bag-wrapper__has-feedback' : ''"
    >
      <!-- 利诱点推全的悬浮购物车 -->
      <a 
        ref="cartBag"
        class="cart-bag cart-bag-new"
        :da-event-expose="1-5-1-29"
        href="javascript:;"
        :data-href="`${langPath}/cart`"
        :data-cart-bag-num="cartSumQuantity"
        tabindex="0"
        role="link"
        :aria-label="'Shopping cart'"
        @click.stop="handleCartBag"
      >
        <i 
          class="cart-bag__icon suiiconfont sui_icon_nav_cart_22px"
          :class="[animateCartBagIcon && 'cart-bag__icon-animate']"
        ></i>
        <span
          :class="['cart-bag__num', 'cart-bag__num-free', animateCartBagNumCtn && beforeCartSumQuantity < 99 ? 'cart-bag__num-animate': '', beforeCartSumQuantity >= 99 && 'cart-bag__num-max']"
        >
          <span class="cart-bag__num-roll">
            <span 
              class="cart-bag__num-scroll" 
              :class="[animateCartBagNumScroll ? 'cart-bag__num-scroll-run': '']"
            >
              <span>{{ beforeCartSumQuantity < 99 ? beforeCartSumQuantity : ((beforeCartSumQuantity === postCartSumQuantity && beforeCartSumQuantity === 99) ? beforeCartSumQuantity : `99+`) }}</span>
              <span>{{ postCartSumQuantity <= 99 ? postCartSumQuantity : `99+` }}</span>
            </span>
          </span>
        </span>
        <!-- 0到1 加载圈SVG -->
        <load-circle
          :stroke="comStyleInfo?.circleStrokeColor"
          :animate-cart-bag-stroke="animateCartBagStroke"
        />
        <!-- 利诱信息, 购物车底部标签 -->
        <cart-label
          :cart-status="cartStatus"
          :save-label-text="saveLabelText"
        />
      </a>
      <!-- 丢车动画用的DOM -->
      <div
        class="transition-container"
      >
        <div
          v-for="(image,index) in images" 
          :key="index"
        >
          <transition
            v-if="isMounted"
            @before-enter="beforeDrop"
            @enter="dropping"
            @after-enter="afterDrop"
          >
            <div 
              v-show="image.show"
              :class="['transition-container__image', searchFeedbackVisibility ? 'search-feedback': '']" 
            >
              <div class="transition-inner transition-inner-hook"></div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <cart-tips
      :bubbleTipText="bubbleTipText"
      :cartTipStatus="cartTipStatus"
      :searchFeedbackVisibility="searchFeedbackVisibility"
    />
    <!-- 气泡提示 -->
    <!-- 不同气泡提示不同 -->
    <!-- <div
      class="bubble-tip"
      :class="[`bubble-tip_${cartTipStatus}`, searchFeedbackVisibility ? 'bubble-tip-feedback' : '']"
    >
      <template v-if="cartTipStatus === 'freeshipping'">
        <free-tip />
      </template>
      <template v-if="cartTipStatus === 'gift'">
        <gift-tip />
      </template>
      <p>{{ bubbleTipText }}</p>
    </div> -->
  </div>
</template>

<script>
const { langPath, IS_RW, GB_cssRight, hasTopBanner } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import gsap from 'gsap'
import { markPoint } from 'public/src/services/mark/index.js'

// sub component
import loadCircle from 'public/src/pages/product_list_v2/components/CartBag/loadCircle.vue'
// import freeTip from 'public/src/pages/product_list_v2/components/CartBag/freeTip.vue'
// import giftTip from 'public/src/pages/product_list_v2/components/CartBag/giftTip.vue'
import CartLabel from 'public/src/pages/product_list_v2/components/CartBag/CartLabel.vue'
import CartTips from 'public/src/pages/product_list_v2/components/CartBag/CartTips.vue'
import { web_cart_entry_click } from 'public/src/pages/cart_v2/utils/metricTagsConfig.js'
import { registerCart, unregisterCart } from '@shein-aidc/bs-sdk-cart-tag-tip'

let tl = gsap.timeline({
  repeatRefresh: true,
})

const IMAGE_LEN = 10
const innerClsHook = `transition-inner-hook`

function createImages() {
  const images = []
  for (let i = 0; i < IMAGE_LEN; i++) {
    images.push({ show: false })
  }
  return images
}
const infoTable = {
  freeshipping: {
    circleStrokeColor: '#1B8157',
    bubbleTipText: '',
    actualPoint: 'freeshipping'
  },
  save: {
    circleStrokeColor: '#FA6338',
    bubbleTipText: '',
    actualPoint: 'activitypoint'
  },
  gift: {
    circleStrokeColor: '#FA6338',
    bubbleTipText: '',
    actualPoint: 'freegift'
  }
}

// 记录埋点相关参数用
let exposeParams = {}

export default {
  name: 'CartBag',
  components: {
    loadCircle,
    // freeTip,
    // giftTip,
    CartLabel,
    CartTips,
  },
  props: {
    searchFeedbackVisibility: {
      type: Boolean,
      default: false
    },
    goodsId: {
      type: String,
      default: ''
    },
    // 标识当前使用悬浮购物车的地方，主要是解决半屏列表（实时反馈）悬浮购物车同时存在问题
    useFrom: {
      type: String,
      default: ''
    },
    //是否是不贵频道
    isReportCustomized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMounted: false,
      cartListener: null,
      langPath,
      images: createImages(),
      animateCartBagStroke: false,
      animateCartBagNumScroll: false,
      animateCartBagNumCtn: false,
      animateCartBagIcon: false,
      animateCartFree: false,
      beforeCartSumQuantity: 1,
      postCartSumQuantity: 1,
      listcartinfoSwitch: false, // 利诱点信息abt值，参见MR-9157

      // 针对利诱点信息需求
      cartStatus: '', // 购物车标志类型
      cartTipStatus: '', // 气泡类型
      bubbleTipText: '', // 气泡多语言文案信息
      saveLabelText: '', // 针对save类型，底部的标签内容
      IS_RW,
      GB_cssRight,
      hasTopBanner
    }
  },
  computed: {
    cartSumQuantity() {
      return this.$store.state.cartInfo.sum
    },
    isGoodsDetailScene() {
      return this.$route?.meta?.pageType === 'productDetail'
    },
    comStyleInfo() {
      return infoTable[this.cartTipStatus] || {
        circleStrokeColor: '#231f20',
        bubbleTipText: '',
      }
    },
  },
  async mounted() {
    this.registerCart()

    await this.fetchFreeInfo()
    this.isMounted = true
    window._gb_list_cart_ = this
    if(!this.useFrom) {
      appEventCenter.$on('closeRecommendDrawerList', ()=> {
        this.registerCart()
        window._gb_list_cart_ = this
        // 解决，半弹层列表中购物车还在气泡动画时，关闭半弹层弹窗，外面列表的购物车显示异常（会存在动画过渡状态）
        this.clearAnimate()
        window?._gb_cart_tag_tips_?.update()
      })
    }
    this.initExpose()
  },
  async activated() {
    // 解决从购物车页面回来数量不同步问题。（在这个店铺名称没消失前就返回，数量就会对不上）
    this.isMounted = true
    this.updateCartNum()
    // hack code 购物车列表操作完增，减 快速点击返回列表，购物车数量不同步问题
    setTimeout(()=> {
      this.updateCartNum()
    }, 800)
    if(!tl) {
      tl = gsap.timeline({
        repeatRefresh: true,
      })
    }
    this.registerCart()
    await this.enterPageInitStatus().catch(error=> {
      this.cartStatus = ''
      this.cartTipStatus = ''
      console.warn(error, 'parse cart_tag_tips fail')
    })
  },
  deactivated() {
    this.isMounted = false
    // 中断动画，垃圾回收, 千万别动
    this.clearAnimate()
    tl = null
    this.unregisterCart()
    // appEventCenter.$off('closeRecommendDrawerList')
  },
  beforeDestory() {
    this.unregisterCart()
  },
  created() {
    this.dropImages = []
    // this.fetchFreeInfo()
  },
  methods: {
    registerCart() {
      if (!this.cartListener) {
        this.cartListener = {
          showtime: (...args) => {
            this.animateProfit(...args)
          }
        }
      }
      registerCart('quick-cart', this.cartListener, { only: true })
    },
    unregisterCart() {
      unregisterCart('quick-cart', this.cartListener, { only: true })
      this.cartListener = null
    },
    // 初始进去场景获取免邮信息相关, 参见TR-8205
    async fetchFreeInfo() {
      // 获取ABT配置
      let { listcartinfoSwitch } = await getUserAbtData() // 可以不需要在场景里获取listcartinfoSwitch进行逻辑判断的(public/src/pages/product_app/util/cartTagTips.js中有相关处理)，为了好理解所以没去掉listcartinfoSwitch的相关逻辑
      this.listcartinfoSwitch = listcartinfoSwitch?.p?.list_entrance_info_switch === 'on' // 利诱点信息abt(public/src/services/abt/config/common.js 中进行的配置)
      // 利诱点逻辑，初始化进入列表，从缓存获取当前利诱点数据
      if(this.listcartinfoSwitch) {
        if(typeof window !== 'undefined') {
          await this.enterPageInitStatus().catch(error=> {
            this.cartStatus = ''
            this.cartTipStatus = ''
            console.warn(error, 'parse cart_tag_tips fail')
          })
          return
        }
      }
    },
    setDefaultCartNum() {
      this.animateCartBagStroke = false
      this.animateCartBagNumScroll = false
      this.animateCartBagNumCtn = false
      this.animateCartBagIcon = false
      this.animateCartFree = false
      this.beforeCartSumQuantity = 1
      this.postCartSumQuantity = 1
    },
    updateCartNum() {
      if (this.cartSumQuantity > 0) {
        this.beforeCartSumQuantity = this.cartSumQuantity
        this.postCartSumQuantity = this.cartSumQuantity
        this.animateCartBagStroke = true
        this.animateCartBagNumCtn = true
        this.animateCartBagIcon = true
        this.animateCartFree = true
      }
      if (this.cartSumQuantity === 0) {
        this.setDefaultCartNum()
      }
      this.isMounted = true
    },

    initExpose() {
      // 等待 cartInfo.sum 更新，无法在 nextTick 中获取，不可重复触发
      setTimeout(() => {
        this.updateCartNum()
        this.expose(this.cartSumQuantity)
      }, 2000)
    },
    async expose(cartSumValue) {
      if(this.isGoodsDetailScene) return 
      exposeParams = await window?._gb_cart_tag_tips_?.getExposeData()
      // 购物车悬浮窗icon曝光
      daEventCenter.triggerNotice({
        daId: '1-5-1-29',
        extraData: {
          bag_goods_count: cartSumValue,
          goodsId: this.goodsId,
          ...exposeParams,
          // available_point: '', // 可展示气泡类型(满足条件的)
          // actual_point: infoTable[this.cartStatus]?.actualPoint ?? '-', // 实际前端展示的气泡类型（满足气泡展示条件中最高优的类型）
        },
      })
    },
    // 商详侧用
    exposeDetailCarIcon() {
      daEventCenter.triggerNotice({
        daId: '1-6-4-76',
        extraData: {
          bag_goods_count: this.cartSumQuantity,
          goodsId: this.goodsId,
          ...exposeParams,
        },
      })
    },
    drop(el) {
      for (let i = 0; i < this.images.length; i++) {
        const image = this.images[i]
        if (!image.show) {
          image.show = true
          image.el = el
          this.dropImages.push(image)
          return
        }
      }
    },
    beforeDrop(el) {
      const image = this.dropImages[this.dropImages.length - 1]
      const rect = (image?.el?.getBoundingClientRect && image.el.getBoundingClientRect()) ?? { left: 0, right: 0, width: 0, top: 0, height: 0 } // 为了解决反馈中的购物加车和外面列表同步问题导致的报错
      const cartBagRect = this.$refs.cartBag?.getBoundingClientRect()
      const cartBagRectBottom = window.innerHeight - cartBagRect.top

      // 原来的动画起点坐标
      const originX = this.GB_cssRight ? (rect.right - cartBagRect.right) : (rect.left - cartBagRect.left) 
      const originY = -(window.innerHeight - cartBagRectBottom - rect.top - rect.height / 2) - rect.height / 2
      // 商详动画起点坐标
      const detailX = this.GB_cssRight ? rect.width : -rect.width
      const detailY = this.hasTopBanner ? -cartBagRect.top + Math.ceil($('.j-config-top-banner').height() || 0) : -cartBagRect.top
      const x = this.isGoodsDetailScene ? detailX : originX
      const y = this.isGoodsDetailScene ? detailY : originY
      el.style.display = ''
      el.style.transform = el.style.webkitTransform = `translate3d(0,${y}px,0)`

      const inner = el.getElementsByClassName(innerClsHook)[0]
      const imageElementWrapper = document.createElement('div')
      const imageElement = image.el?.cloneNode && image.el?.cloneNode(true)?.querySelector('img')
      imageElementWrapper.appendChild(imageElement || document.createElement('div'))

      inner.innerHTML = imageElementWrapper.innerHTML 
      inner.style.transform = inner.style.webkitTransform = `translate3d(${x}px,0,0)`
      inner.style.width = `${rect.width}px`
      inner.style.height = `${rect.height}px`
      inner.style.borderRadius = '0%'
    },
    dropping(el, done) {
      this._reflow = document.body.offsetHeight
      el.style.transform = el.style.webkitTransform = `translate3d(0,0,0)`
      const inner = el.getElementsByClassName(innerClsHook)[0]
      inner.style.transform = inner.style.webkitTransform = `translate3d(0,0,0)`
      inner.style.width = ''
      inner.style.height = ''
      inner.style.borderRadius = ''
      el.addEventListener('transitionend', done)
      // done()
    },
    afterDrop(el) {
      const image = this.dropImages.shift()
      if (image) {
        image.show = false
        el.style.display = 'none'
      }
      
      const hasShowedNumCtn = this.animateCartBagNumCtn
      this.beforeCartSumQuantity = hasShowedNumCtn ? this.postCartSumQuantity : this.cartSumQuantity
      this.postCartSumQuantity = this.cartSumQuantity
      // 处理最多展示99+逻辑，此时数字不再需要过渡
      if(this.cartSumQuantity > 99 && this.postCartSumQuantity >= 99) {
        this.animateCartBagNumCtn = false
      }else {
        this.animateCartBagNumCtn = true
      }
      this.animateCartBagStroke = true
      this.animateCartFree = true

      // 如果包含利诱abt打开才触发
      // if(this.listcartinfoSwitch) {
      // 组件内自行触发，因为加车后一定会触发这个方法。（为了兼容旧的抛车动画）
      window?._gb_cart_tag_tips_?.update()
      // }
      // 特殊处理,针对带利诱点的购物车，不需要体现此动画
      if(!this.listcartinfoSwitch) {
        this.animateCartBagIcon = false
        setTimeout(() => {
          this.animateCartBagIcon = true
        }, 100)
      }
      if (hasShowedNumCtn && this.beforeCartSumQuantity < 99) {
        this.animateCartBagNumScroll = false
        setTimeout(() => {
          this.animateCartBagNumScroll = true
        }, 550)
      } else {
        this.animateCartBagNumScroll = false
      }
    },
    async handleCartBag() {
      web_cart_entry_click('1')
      exposeParams = await window?._gb_cart_tag_tips_?.getExposeData()
      markPoint('toNextPageClick', 'public')
      this.$emit('click-cart')
      if (!this.isReportCustomized) {
        daEventCenter.triggerNotice({
          daId: this.isGoodsDetailScene ? '1-6-4-77' : '1-5-1-28',
          extraData: {
            goodsId: this.goodsId,
            bag_goods_count: this.cartSumQuantity,
            ...exposeParams,
            // available_point: '', // 可展示气泡类型(满足条件的)
            // actual_point: infoTable[this.cartStatus]?.actualPoint ?? '-', // 实际前端展示的气泡类型（满足气泡展示条件中最高优的类型）
          },
        })
      }
      this.$router.push(`${this.langPath}/cart`)
    },
    // 进页面执行的获取缓存，置顶标签逻辑
    async enterPageInitStatus() {
      let res = await window?._gb_cart_tag_tips_?.getCurrentTag()
      this.cartStatus = res?.type ? (res?.type === 'default' ? '' : res?.type) : ''
      this.cartTipStatus = res?.type ? (res?.type === 'default' ? '' : res?.type) : ''
      if(res?.type === 'save') {
        this.saveLabelText = ((res?.tag === 'SAVE' || res?.tag === 'save') || !res?.tag) ? '' : res?.tag
      }

      window?._gb_cart_tag_tips_?.onceTip()
    },
    /**
     * Description 这个方法是在public/src/pages/product_app/util/cartTagTips.js 中调用的
     * @param {any} step1 当前状态
     * @param {any} step2 之前状态
     * @returns {any}
     */
    animateProfit( [step1, step2], { callback } = {} ) {
      this.clearAnimate()
      // 处理页面滚动中止动画逻辑
      if(!tl) {
        tl = gsap.timeline({
          repeatRefresh: true,
        })
      }
      // totalNum 大于总次数不展示气泡
      // 原来有利诱标志，此次加车后无，重置状态为空
      if(step1?.type === 'default') {
        this.cartTipStatus = ''
        this.cartStatus = '' 
        callback && typeof callback === 'function' && callback()
        return 
      }
      // tip 文案
      this.bubbleTipText = step1?.tip || step2?.tip || ''

      // 无气泡标签变化, 无气泡&翻转动画
      // 又分， 只有step1， 和 有step1和step2的情况
      if(!this.bubbleTipText || step1?.noTips) {
        this.cartStatus = step1?.type
        this.cartTipStatus = step1?.type
        // 处理SAVE类型的标签展示
        if(step1?.type === 'save') {
          this.saveLabelText = ((step1?.tag === 'SAVE' || step1?.tag === 'save') || !step1?.tag) ? '' : step1?.tag
        }
        callback && typeof callback === 'function' && callback()
        return 
      }


      // 0~1 标志+带气泡, 有step1, 无step2， step1最高优
      if(step1 && !step2) {
        tl.to('.bubble-tip', { 
          width: 'auto', 
          visibility: 'visible',
          duration: 0.3, 
          repeat: 1, 
          yoyo: true, 
          repeatDelay: 3, 
          onStart: ()=> {
            // 处理SAVE类型的标签展示
            if(step1?.type === 'save') {
              this.saveLabelText = ((step1?.tag === 'SAVE' || step1?.tag === 'save') || !step1?.tag) ? '' : step1?.tag
            }
            // this.cartStatus = '' // 尝试修复气泡弹出时，外环颜色为默认色问题
            this.cartTipStatus = step1?.type
          },
          onComplete: ()=> {
            this.cartStatus = step1?.type
            callback && typeof callback === 'function' && callback()
            this.clearAnimate()
          },
        })
        // 清掉timeline
        // tl.kill()
        return
      }

      // 1~n 没气泡, 标签变化, 变成优先级更高的 有step1, 有step2， step2最高优
      if(step1 && step2) {
        tl.to('.label-local', { 
          onStart: ()=>{
            // 处理SAVE类型的标签展示
            if(step1?.type === 'save') {
              this.saveLabelText = ((step1?.tag === 'SAVE' || step1?.tag === 'save') || !step1?.tag) ? '' : step1?.tag
            }
            // 改为低级别的气泡
            this.cartStatus = step1?.type
            this.cartTipStatus = step1?.type
          },
          opacity: 0,
          duration: 0.2, 
        })
          .to('.bubble-tip', {
            width: 'auto',
            visibility: 'visible',
            duration: 0.3, 
            repeat: 1, 
            yoyo: true, 
            repeatDelay: 3
          })
          .from('.label-local', { 
            onComplete: ()=>{
            // 改回原状态(高优)
              this.cartStatus = step2?.type
              this.cartTipStatus = step2?.type
            },
            opacity: 1,
            rotateY: 90,
            duration: 0.6,
            repeat: 1,
            yoyo: true,
            repeatDelay: 1, 
          })
          .to('.label-local', { 
            opacity: 1, 
            rotateY: 0, 
            scale: 1, 
            clearProps: true, /* 动画结束后清理dom上的信息*/
            onComplete: ()=> {
              callback && typeof callback === 'function' && callback()
              this.clearAnimate()
            }
          })
        return
      }
    },
    // 提供给公共部分的逻辑，停止动画用
    clearAnimate() {
      if(!this.listcartinfoSwitch) return
      // 处理页面滚动中止动画逻辑
      tl?.seek(10, false)
      tl?.killTweensOf('.label-local')
      tl?.killTweensOf('.bubble-tip')
      tl?.kill()
      tl = gsap.timeline({
        repeatRefresh: true,
      })
    },
  },
}
</script>

<style lang="less">
@keyframes cart-free-fade {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes cart-bag-stroke {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes cart-num-roll {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50%);
  }
}
@keyframes cart-num-fadeup {
  from {
    opacity: 0;
    transform: translate3d(0, 5px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes cart-icon-zoomin {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.15);
  }
}
@keyframes cart-icon-zoomout {
  from {
    transform: scale(1.15);
  }
  to {
    transform: scale(1);
  }
}

.show-scroll-top .cart-bag-wrapper {
  transform: translateY(-1.436rem);
}
.show-scroll-top .product-middle__cart-bag .cart-bag-wrapper {
  transform: translateY(-0.636rem);
}
.show-scroll-top .cart-bag-wrapper__has-feedback {
  transform: translateY(-0.9067rem);
}

.cart-bag-wrapper {
  position: fixed;
  .right(0.51rem);
  bottom: 2.0267rem;
  border-radius: 100%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
  z-index: @zindex-attrmenusize;
  transform: translateY(0);
  transition: transform .3s cubic-bezier(0.4, 0.0, 0.2, 1);
  margin-bottom: env(safe-area-inset-bottom);
}
.cart-bag-wrapper__has-feedback {
  bottom: 3.2533rem;
}

.cart-bag {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 0.96rem;
  height: 0.96rem;
  border-radius: 100%;

  &__icon {
    display: block;
    color: #000000;
    z-index: @zindex-hack;
  }
  &__icon-animate {
    animation: .3s cubic-bezier(0.4, 0.0, 1, 1) cart-icon-zoomin, .15s cubic-bezier(0.4, 0.0, 1, 1) .3s cart-icon-zoomout;
  }
  &__num {
    position: absolute;
    top: -0.12rem;
    left: 0.6rem;
    display: flex;
    min-width: 20px;
		height: 16px;
    font-size: 12px;
    border-radius: 46px;
    overflow: hidden;
    justify-content: center;
    flex-wrap: nowrap;
    background: #fe3b30;
    color: #fff;
    opacity: 0;
    z-index: @zindex-attrmenusize;
  }
  &__num-free {
    border: 1px solid #fff;
    box-sizing: content-box;
  }
  &__num-animate {
    animation: .2s cubic-bezier(0.4, 0.0, 1, 1) .3s forwards cart-num-fadeup;
  }
  &__num-max {
    opacity: 1;
  }
  &__num-roll {
    height: 16px;
    display: inline-block;
    transform-origin: top center;
    width: 100%;
    text-align: center;
  }
  &__num-roll {
    transform: scale(0.9);
  }
  &__num-scroll {
    display: inline-block;
  }
  &__num-scroll span {
    display: block;
    text-align: center;
  }
  &__num-scroll-run {
    animation: .3s cubic-bezier(0.4, 0.0, 1, 1) forwards cart-num-roll;
  }
}

.transition-container {
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: @zindex-attrmenusize;
    transition: all 0.4s cubic-bezier(0.4, 0.0, 1, 1);
    & > .transition-inner {
      overflow: hidden;
      width: 0.96rem;
      height: 0.96rem;
      border-radius: 100%;
      transition: all 0.4s cubic-bezier(0.4, 0.0, 1, 1);
    }
  }
}

/* 利诱点样式 */
@colorFree: #1B8157; // 免邮
@colorSave: #FA6338; // 优惠金额
@colorGift: #FA6338;// 赠品

// 利诱标签通用样式
.label-local {
  position: absolute;
  bottom: -6px;
  display: flex;
  width: 1.12rem;
  height: 14px;
  box-sizing: content-box;
  justify-content: center;
  font-size: 9px;
  border-radius: 8px;
  color: white;
  z-index: @zindex-hack;
}
.label-local_gift {
  background-color: @colorGift;
}
.label-local_freeshipping {
  background-color: @colorFree;
}
.label-local_save {
  background-color: @colorSave;
  height: 16px;
  >.min-font {
    transform: scale(.8);
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
}
// 利诱气泡样式
// .show-scroll-top .bubble-tip{
//   transform: translateY(-1.436rem);
// }

// .show-scroll-top .bubble-tip-feedback {
//   transform: translateY(-0.9067rem);
//   bottom: 3.2533rem;
// }
// .bubble-tip {
//   width: 0;
//   opacity: 1;
//   visibility: hidden;
//   position: fixed;
//   .right(0.51rem);
//   bottom: 2.0267rem;
//   z-index: @zindex-list-wrapper;
//   overflow: hidden;
//   max-width: 9.4rem;
//   height: 0.96rem;
//   transform: translate3d(0, 0, 0);
//   font-size: 12px;
//   border-radius: 0.96rem;
//   display: flex;
//   align-items: center;
//   padding-left: 0.213rem;
//   padding-right: 0.96rem;
//   >p {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     display: -webkit-box;
//     color: #FFF;
//     font-family: SF UI Text, Helvetica, Arial, sans-serif;
//     font-size: 12px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//   }
// }
// .bubble-tip-feedback {
//   bottom: 3.2533rem;
// }
// .bubble-tip_freeshipping {
//   background-color: @colorFree;
//   gap: 3px;
//   >p {
//     max-width: calc(100% - 18px);
//   }
// }
// .bubble-tip_save {
//   background-color: @colorSave;
// }
// .bubble-tip_gift {
//   background-color: @colorGift;
//   gap: 3px;
//   >p {
//     max-width: calc(100% - 18px);
//   }
// }

</style>
