import type { HolidayDeliveryTag } from '../../../../../types/mobile/index.ts'

/**
 * HolidayDeliveryTag 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): HolidayDeliveryTag.Props {
  const info = data.value.aggregateProductBusiness?.productTags?.find(item => item.type === 'holiday')

  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  return {
    isShow: !!info,
    isShowMask: (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem,
    text: info?.view?.text || '',
    bgColor: info?.view?.bgColor || '',
    textColor: info?.view?.textColor || '',
    maxWidth: '100%',
  }
}
