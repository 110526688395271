import { remove } from 'lodash'
import { getQueryString } from '@shein/common-function'
import {
  getReview,
  getPriceAsc,
  getPriceDesc,
  getRecommend,
  getMostPopular,
  getNewArrivals,
  isRecProductsPage
} from './utils'


const getters = {
  sortAllList(state) {
    const language = state?.Language || {}
    const { pageName } = state?.Results?.cat_info || {}

    const list =  [
      getRecommend(language),
      getMostPopular(language, pageName),
      getNewArrivals(language, pageName),
      getReview(language),
      getPriceAsc(language, pageName),
      getPriceDesc(language, pageName),
    ]

    // 信息流落地页和实时反馈落地页没有
    if (isRecProductsPage({ pageName })) {
      remove(list, (item) => item.label_en === 'Review')
    }

    return list
  },
  sortMap(state) {
    const language = state?.Language || {}
    const isShowPriceBar =
      state?.ComponentState?.FilterBar?.filterConfig?.isShowPriceBar || false
    const isShowMostPopularInSort =
      state?.ComponentState?.FilterBar?.filterConfig?.isShowMostPopularInSort ||
      false
    const { pageName } = state?.Results?.cat_info || {}

    const list = [
      getRecommend(language),
      getMostPopular(language, pageName),
      getNewArrivals(language, pageName),
      getReview(language),
      getPriceAsc(language, pageName),
      getPriceDesc(language, pageName),
    ]

    // 当前是否信息流落地页列表 或 实时反馈落地页
    const isPickedInfoPage = isRecProductsPage({ pageName })
    
    if(isPickedInfoPage) {
      remove(list, (item) => item.label_en === 'Review')
    }

    // 排序外露时隐藏 Most Popular
    (!isShowMostPopularInSort) &&  remove(list, (item) => item.label_en === 'Most Popular')
    
    // 排序外露时隐藏 Price Low To High 和 Price High To Low
    isShowPriceBar && remove(list, (item) =>/price/i.test(item.label_en))
    
    return list
  },
  sortMap2(state) {
    const language = state?.Language || {}
    const isShowMostPopularInSort =
      state?.ComponentState?.FilterBar?.filterConfig?.isShowMostPopularInSort ||
      false
    const { pageName } = state?.Results?.cat_info || {}

    const list = [
      getRecommend(language),
      getMostPopular(language, pageName),
      getPriceAsc(language, pageName),
      getPriceDesc(language, pageName),
      getNewArrivals(language, pageName),
      getReview(language),
    ]

    // 当前是否信息流落地页列表 或 实时反馈落地页
    const isPickedInfoPage = isRecProductsPage({ pageName })
    isPickedInfoPage && remove(list, (item) => item.label_en === 'Review')

    // 实验控制是否展示 Most Popular
    if (!isShowMostPopularInSort) {
      remove(list, (item) => item.label_en === 'Most Popular')
    }

    return list
  },
  /**
   * new data below ↓
   */
  // S - 基础配置
  locals(state) {
    return state.Locals || {}
  },
  language(state) {
    return state.Language || {}
  },
  listAbtResult(state) {
    return state.Results.listAbtResult || {}
  },
  cccSeo: (state) => {
    return state.Results.cccSeo || {}
  },
  cccOperation: (state) => {
    return state.Results.cccOperation || {}
  },
  cccxConfig: (state) => {
    return state.Results.cccxConfig || {}
  },
  cccxFilterConfig: (state) => {
    return state.Results.cccxFilterConfig || {}
  },
  shouldUseCccxFilterChain: state => {
    return state.Results.shouldUseCccxFilterChain || false
  },
  mallInfo: (state) => {
    return state.Results.mallInfo || []
  },
  cccConfig: (state) => {
    return state?.Results?.cccConfig || {}
  },
  // 会员相关信息，注意：为undefined或者null
  sheinClubInfo: (state) => {
    return state?.Results?.sheinClubInfo || {}
  },
  // S - 页面信息
  catInfo: (state) => {
    return state.Results.cat_info || {}
  },
  currentCat: (state) => {
    return state.Results.currentCat || {}
  },
  parentCats: (state) => {
    return state.Results.parentCats || {}
  },
  searchKeywords: (state) => {
    return state.Results.searchKeywords || {}
  },
  searchCCCCard: (state, getters) => {
    
    // v.趋势搜索结果页屏蔽该功能
    if (getters.isTopTrendResultPage) {
      return null
    }
    return state.ComponentState.searchCCCCard || null
  },
  searchStoreCard: (state, getters) => {

    // v.趋势搜索结果页屏蔽该功能
    if (getters.isTopTrendResultPage) {
      return null
    }

    return state.Results.searchBannerCard?.searchStoreCard  || null
  },
  searchTrendCard: (state) => {
    return state.Results.searchBannerCard?.searchTrendCard  || null
  },
  // S - 商品数据
  sum: (state) => {
    return state.Results.sum || 0
  },
  sumForPage: (state) => {
    return state.Results.sumForPage || 0
  },
  goods: (state) => {
    return state.Results.goods || []
  },
  appendGoods: (state) => {
    return state.Results.appendGoods || []
  },
  // S - 筛选数据
  picTopNav: (state) => {
    return state.Results?.picTopNav?.length 
      ? state.Results.picTopNav
      : []
  },
  picTopNavCate: (state) => {
    return state.Results.picTopNavCate || []
  },
  cloudTags: (state) => {
    return state.Results.cloudTags || []
  },
  bannerTag: (state) => {
    return state.Results.bannerTag || {}
  },
  kidsTag: (state) => {
    return state.Results.kidsTag
  },
  dailyDates: (state) => {
    return state.Results.dailyDates || {}
  },
  filterCates: (state) => {
    return state.Results.filterCates || []
  },
  filterTsps: (state) => {
    return state.Results.filterTsps || []
  },
  filterAttrs: (state) => {
    return state.Results.filterAttrs || []
  },
  filterPrices: (state) => {
    return state.Results.filterPrices || {}
  },
  // S - 实验跟踪
  tracking: (state) => {
    return state.Results.tracking || {}
  },
  request_ext: (state) => {
    return state.Results.request_ext || {}
  },
  goodsCrowId: (state) => {
    return state.Results.goodsCrowId || {}
  },
  fhContext: (state) => {
    return state.Results.fhContext || {}
  },
  googleContext: (state) => {
    return state.Results.googleContext || {}
  },
  listFilterAbt: (state) => {
    return state.Results?.filterAbtParam?.abt || {}
  },
  promotionInfoFromServer: (state) => {
    return state.Results.promotionInfoFromServer || {}
  },
  atomicParams: (state) => {
    return state.Results.atomicParams || {}
  },
  cateCoupon: (state) => {
    return state?.ComponentState?.ProductList?.cateCoupon || {
      Data: {},
      Abt: {}
    }
  },
  couponLanguage(state) {
    return state?.ComponentState?.ProductList.couponLanguage
  },
  policyBanner(state) {
    return state.Results.policyBanner || {}
  },
  apolloConfig(state) {
    return state.Results.apolloConfig || {}
  },
  flowMap(state) {
    return state?.ComponentState?.ProductList?.flowMap ?? {}
  },
  discountPageInfo(state) {
    return state.Results.discountPageInfo || {}
  },
  cardConfig(state) {
    const cardConfig = state.Results.cardConfig

    return cardConfig || {}
  },
  // 店铺选品页展示底纹词
  showStoreDefaultWords(state, getters) {
    const { search_type, type } = getters.catInfo
    return search_type === 'store' && type === 'selection' && getQueryString({ key: 'store_code' }) && getQueryString({ key: 'searchExpand' }) === '1'
  },
  // 是否有搜索顶部卡片
  hasSearchBannerCard(state, getters) {
    return !!(getters.searchStoreCard || getters.searchTrendCard || getters.searchCCCCard)
  },
  // 容器顶部背景
  topBackgroundParam(state, getters) {
    let hasBgBanner = !!state?.ComponentState?.BgBanner?.src

    if (getters.picTopNav?.length > 1 && !getters.hasSearchBannerCard && ['search', 'entity', 'selection'].includes(getters.catInfo.type) && !hasBgBanner) {
      // v.趋势搜索结果页屏蔽该功能
      if (getters.isTopTrendResultPage || getters.isStoreResultPage || getters.isBrandResultPage) {
        return null
      }
      return {
        name: 'TabPicNav',
        changeHeaderBg: true,
        scrollTransparent: true,
        extendData: {
          catInfo: getters.catInfo
        }
      }
    } else if (getters.searchTrendCard) {
      return {
        name: 'SearchTrendCard',
        changeHeaderBg: true,
        scrollTransparent: false
      }
    } else if (getters.searchStoreCard) {
      const { searchStoreCard } = getters
      const isTrendStore = searchStoreCard?.isBff 
        ? (searchStoreCard?.storeLabels ?? []).find(item => item.labelType == '2')
        : (searchStoreCard?.storeQuality || []).find(item => item.type === 'trends')
      const fashionStyle = getters.listAbtResult?.newsearchtrendstore?.p?.newsearchtrendstore === 'new' && isTrendStore
      if (!fashionStyle) {
        return null
      }
      let imgType = ''

      const bannerImg = searchStoreCard.isBff ? searchStoreCard.storeBanners?.[0]?.icon : searchStoreCard.bannerImg
      const products = searchStoreCard.isBff ? searchStoreCard.shopRecProducts : searchStoreCard.products
      if (products.length <= 3 && !bannerImg) {
        imgType = 'type1'
      } else if (products.length > 3 && !bannerImg) {
        imgType = 'type2'
      } else if (products.length <= 3 && bannerImg) {
        imgType = 'type3'
      } else {
        imgType = 'type4'
      }
      const imgBgMap = {
        'type1': '//img.ltwebstatic.com/images3_ccc/2024/10/22/0a/172959035261376ad438aa2d9bf1c61d81735b9f2b.png', // 无商品、无banner图
        'type2': '//img.ltwebstatic.com/images3_ccc/2024/10/22/0a/17295903525a02d1ccec65af1781a23f9ce60e8ff1.png', // 有商品、无banner
        'type3': '//img.ltwebstatic.com/images3_ccc/2024/10/22/0a/1729590352be849a1ee1c45b9bcf4ddad64c863df4.png', // 无商品、有banner
        'type4': '//img.ltwebstatic.com/images3_ccc/2024/10/22/0a/1729590352f679e220260fe7d8d98c872ae5a44572.png', // 有商品、有banner图
      }
      return {
        name: 'SearchStoreCard',
        changeHeaderBg: true,
        scrollTransparent: false,
        extendData: {
          img: imgBgMap[imgType],
        }
      }
    }
    return null
  },
  hasCorrectionWord(state, getters) {
    const searchSuggestNew = (getters.apolloConfig?.searchAbtCompleteOnV2 || getters.listAbtResult?.NoResultPage?.p?.NoResultPage === 'new') && state?.ComponentState?.ListTopInfo?.showPostCorrection === 1
    const isSuggestCorrection = getters.istAbtResult?.searchcorrect?.p?.correction_type === 'suggestcorrection'
    const hasCorrectionWord = state?.ComponentState?.ListTopInfoshowPostCorrection && searchSuggestNew && !isSuggestCorrection 
    return hasCorrectionWord
  },
  ptnTabBgColor(state, getters) {
    if (getters.picTopNav?.length > 1 && getters.catInfo.type === 'search' && !!getters.searchStoreCard && !getters.hasCorrectionWord) {
      return 'linear-gradient(180deg, #f6f6f6, #ffffff)'
    }
    return 'transparent'
  },
  // 是否来自趋势搜索结果页
  isTopTrendResultPage(state, getters) {
    const { search_type, type } = getters.catInfo
    // return true
    return type === 'search' && search_type === 'pageTrend'
  },
  isStoreResultPage(state, getters) {
    return getters.catInfo.type === 'search' && getters.catInfo.search_type === 'store'
  },
  isBrandResultPage(state, getters) {
    return getters.catInfo.type === 'search' && getters.catInfo.search_type === 'brand'
  }
}
export default getters
