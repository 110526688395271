<template>
  <div
    ref="container"
    :style="customStyle"
    class="incentive-container"
  >
    <slot></slot>
    <span
      ref="scrollText"
      :style="{ color: customStyle.textColor, }"
      class="tag-text"
    >
      {{ props?.text }}
    </span>
  </div>
</template>

<script setup>
import { ref } from 'vue'

// Props
const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  customStyle: {
    type: Object,
    default: () => {}
  }
})

const container = ref(null)
const scrollText = ref(null)



</script>

<style lang="less" scoped>
.incentive-container {
  height: .4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  white-space: nowrap;
  border-radius: 0.0533rem;
  border: 0.5px solid rgba(255, 51, 81, 0.60);
  padding: 1px 2px;
  background: hsl(351, 100%, 96%);
  margin-left: 0;
}

.tag-text {
  line-height: 13px;
  height: 13px;
  display: inline-block;
  color: #FF3351;
  text-align: right;
  // font-family: "SF UI Text";
  font-size: 11px;
  font-weight: 700;
}
</style>
