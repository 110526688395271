// import { completeCouponInfo } from './_mock/hotModules.js'
import { getDefaultHotModules } from './defaultState.js'
export default {
  // 添加apollo需要去服务端加一下，不然服务端拿不到
  MAIN_BFF_APOLLO: {
    v1: false, // 0716
    v2: false, // 0730
    v3: false, // 0813
    v4: false, // 0827
    v5: false, // 0903
    v6: false, // 0910
    vBFF_FS: false, // 下线静态链路
    v6Rec: false, // 0910
    v7: false, // 1015
    v8: false, // 1104
  },

  // 页面缓存abt
  cache_screen_fs_abt: {},

  // 静态模型 CDN接口
  // 注: 精准更新
  // 接口文档: https://soapi.sheincorp.cn/application/1406/versions/test01/get_product_get_goods_detail_static_data/doc
  coldModules: {
    priceInfo: {},
    detailPromotionFloor: {},
    productInfo: {
      beauty_ingredients: '',
      goods_id: '',
      goods_name: '',
      selectedMallCode: '',
      models_info: {},
      is_on_sale: '',
      stock: '',
      cccDetailsTemplate: {},
      attrModuleComponentConfig: {},
      productDescriptionInfo: {},
      // brandDetailInfo: {},
      shoppingSecurityInfo: {},
      cat_id: '',
      isRetentionProduct: '',
      currentSkcImgInfo: {
        skcImages: [],
        skuImages: {},
        spuImages: [],
        maskLayer: {},                        // Beauty品类蒙版
      },                                      // 商品主图
      videoInfo: {},                          // 视频数据
      videoSort: '',
      allColorDetailImages: {},
      customization_attributes: {}, // 定制属性
      noticeType: '0', // (提示类型 0-无需提示 1-成人用品年龄提示弹窗)
    },
    brandDetailInfo: {
      authenticBrand: '',
    },
    mallInfo: {},
    storeInfo: {
      storeLabels: [],
      inFashionLabel: {},
      isBrandStore: '',
      storeStatus: '',
      isFashionLabel: '',
      temporaryFashionLabel: ''
    },
    productLabel: {
      isShowEvoluOnTitle: '',
      featureSubscript: [],
      trendInfo: {},
      isEvolushein: '0',                    // 是否 Evolushein 商品
    }, // 商品标签
    saleAttr: {
      mainSaleAttribute: {},
      multiLevelSaleAttribute: {},
      findMyShadeH5Url: '',
    },
    commonPageInfo: {},
    commonInfo: {},
    recShopProductReqInfo: {},
    designerInfoBO: {},
    seoInfo: {
      description: '',
      key_words: '',
      title: '',
    }, // seo信息
    configInfo: {},
    mainImgDescInfo: {}, // 主图属性图数据
  },

  // 动态模型
  // 注: 精准更新
  // 接口文档: https://soapi.sheincorp.cn/application/1406/versions/test01/get_product_get_goods_detail_realtime_data/doc
  hotModules: getDefaultHotModules(),
  // 动静之外的异步模型
  asyncModules: {
    fsAbtInfo: {}, // abt数据
    currentSkcImgInfo: {
      detailImage: []              // 细节图
    },
    getTheLookRelatedIdSeries: [],
    topRanking: {},                //  榜单数据
    newCompanionInfo: {},          //  新搭配数据（outfits ｜ gtl）
    shopRecProducts: {},           //  店铺&品牌&系列商品推荐
    buyBoxRecommendInfo: {},        // buybox推荐
    multiPropertyProducts: [],     // 多属性关联商品
    machingStyleProducts: [],
    countdownToTheStore: '', // 店铺倒计时
    whetherEligibleForTheStore: '', // 是否满足店铺闪购条件
  },
  // 存放非静态链路里，SSR直出或CSR阶段异步获取的数据
  hybridModules: {
    /**
     * 产品详情页是否展示年龄限制，用户确认过期状态，不需要展示默认false
     */
    confirmedAgeLimitExpired: false,
    /**
     * ssr直出的商品详情页Seo信息
     */
    googleSEO: null,
    /**
     * 当前商详页是否ssr直出
     */
    is_ssr: false,
    /**
     * 切换skc不需要刷新的数据
     */
    pageInfo: {
      language: {}, // 混入商详、券、商品项多语言
      /**
       * 合规价格配置
       */
      suggested_sale_price: '',
      fsAbt: {}, // 旧的fsAbt，ssr时使用
    },
  }
}
