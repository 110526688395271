import { checkSchttp } from 'public/src/services/schttp/tools'
import useVuex from 'public/src/pages/user/hooks/useVuex.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { usePayInstance } from './usePayInstance.js'
import { usePay } from 'public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/hook/usePay.js'
import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import { computed, shallowRef } from 'vue'

export function debuggerLog(...args) {
  if (gbCommonInfo?.NODE_SERVER_ENV === 'production') return
  try {
    console.info('%cOrder New Payment:', 'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff', ...args, '\n')
  } catch (e) {
    console.log(...args)
  }
}

export const useOrderPayment = ({
  storeNameSpace = 'orderDetail'
}) => {
  const { paymentInstance } = usePayInstance()
  const { useMapState, useMapGetters, useMapMutations } = useVuex(`${storeNameSpace}/payAgain`)
  const { bffOrderInfo, selectedPaymentInfo, selectedPaymentItemStatus, oldOrderInfo } = useMapState(['bffOrderInfo', 'selectedPaymentInfo', 'selectedPaymentItemStatus', 'oldOrderInfo'])
  const { updatePaymentInfo, paymentSceneInfo, customOrderType } = useMapGetters(['updatePaymentInfo', 'paymentSceneInfo', 'customOrderType'])
  const { updateSelctedPaymentInfo, updateCreateOrderBinDialog } = useMapMutations(['updateSelctedPaymentInfo', 'updateCreateOrderBinDialog'])
  const payValidate = shallowRef(null)
  const payToolkitResetStatus = shallowRef(null)

  const createPaymentOrderInfo = computed(() => {
    return bffOrderInfo.value?.is_virtual_order ? Object.assign({}, oldOrderInfo.value, bffOrderInfo.value) : bffOrderInfo.value
  })

  const {
    handleCreatePayment,
  } = usePay({ orderInfo: createPaymentOrderInfo, storeNameSpace })

  const handleSelectPayment = (paymentInfo) => {
    debuggerLog('order_new===handleSelectPayment', paymentInfo)
    updateSelctedPaymentInfo(paymentInfo?.code ? paymentInfo : {})
    if (!paymentInfo?.code) {
      paymentInstance?.resetPayment?.()
    } else {
      paymentInstance?.selectPayment?.(paymentInfo)
    }
  }

  const handleUpdatePaymentChange = (paymentInfo) => {
    debuggerLog('order_new===handleUpdatePaymentChange', paymentInfo)
    updateSelctedPaymentInfo(paymentInfo)
  }

  const handleUpdatePayment = () => {
    debuggerLog('order_new===handleUpdatePayment', updatePaymentInfo.value, paymentInstance)
    paymentInstance?.updatePayment?.(updatePaymentInfo.value)
  }

  const handleSetValidateByPayFn = (fn) => {
    payValidate.value = fn
  }

  const handleSetResetBsPayTookitStatusFn = (fn) => {
    payToolkitResetStatus.value = fn
  }

  const _handlePreCreatePayment = () => {
    paymentInstance?.preCreatePayment({
      selectedPaymentInfo: selectedPaymentInfo.value,
      orderInfo: bffOrderInfo.value,
    })
  }

  const updateOrderFn = ({
    options = {
      isAsync: true,
      _order_type: '',
      coverData: {}
    },
    successCb,
    errorCb
  }) => {
    const nowTokenId = selectedPaymentInfo?.isUseNewCard ? '' : selectedPaymentInfo.value?.card_token_info?.id || selectedPaymentInfo.value?.token_id || ''
    const nowBin = selectedPaymentInfo?.isUseNewCard ? '' : selectedPaymentInfo.value?.card_token_info?.card_bin || ''

    debuggerLog('order_new===updateOrderFn', selectedPaymentInfo.value, nowTokenId, nowBin, options)

    checkSchttp({
      isAsync: options?.isAsync,
      opt: {
        method: 'POST',
        url: '/api/checkout/updateOrder/update',
        schttp: {
          needLogin: true
        },
        params: {
          billno: bffOrderInfo.value.billno,
        },
        data: {
          payment_id: selectedPaymentInfo.value.id,
          payment_code_unique: selectedPaymentInfo.value.code,
          editType: 'editOrderPaymentMethod',
          _order_type: options._order_type,
          tokenId: nowTokenId,
          bin: nowBin,
          ...(options?.coverData || {})
        }
      },
      successCb: res => {

        const payInfoForCoverData = {}
        if (options?.coverData?.installments) {
          payInfoForCoverData.installments = options?.coverData?.installments
        }

        if (options?.coverData?.cvv) {
          payInfoForCoverData.cvv = options?.coverData?.cvv
        }

        if (res?.code == '302162') {
          updateCreateOrderBinDialog({
            show: true,
            coverData: {
              tokenId: nowTokenId,
              bin: '',
              ...payInfoForCoverData
            }
          })
        } else {
          successCb?.(res)
        }
      },
      errorCb: err => {
        errorCb?.(err)
        console.log(err)
      }
    })
  }

  const asyncUpdateOrder = async ({ coverData = {} } = {}) => {
    return new Promise((resolve, reject) => {
      updateOrderFn({
        options: {
          isAsync: true,
          _order_type: customOrderType.value || '', // 兼容历史逻辑
          coverData
        },
        successCb: res => {
          resolve(res)
        },
        errorCb: err => {
          reject(err)
        }
      })
    })
  }

  const validateByPay = async ({ extraPayInfo = {} } = {}) => {
    debuggerLog('order_new===validateByPay', selectedPaymentInfo.value, extraPayInfo)
    const validRes = await payValidate.value?.({
      has_cvv: !!extraPayInfo?.cvv || !!extraPayInfo?.coverData?.cvv,
    })

    const { validateResult, metric_scene, scene = '', extraInfo = {}, payAnalysisInfo = {} } = validRes || {}
    debuggerLog(validRes, validateResult, metric_scene, scene, extraInfo, '9999000')

    // 未选择支付方式
    // if (!validateResult && scene === 'not-select-payment') {
    //   return {
    //     notValid: true,
    //     scene,
    //   }
    // }
    if (!validateResult && metric_scene) {
      // 卡前置校验
      if (scene === 'pre_create_pay') {
        SIMetric.metricCount({
          metric_name: 'web_payment_error_total', // 指标名称
          tags: { // 指标维度
            sub_site: gbCommonInfo?.SiteUID || '',
            error_scene: metric_scene,
            checkout_type: paymentSceneInfo.value.checkoutType,
            product_type: paymentSceneInfo.value.productType,
            payment_code: selectedPaymentInfo.value?.payment_code || '',
          },
          params: {
            url: location.href,
            message: `err: ${extraInfo?.failReasonResults?.join?.(',')}, billno: ${extraInfo?.prePayCheckoutInfo?.billno || ''}`
          },
          message: `err: ${extraInfo?.failReasonResults?.join?.(',')}, billno: ${extraInfo?.prePayCheckoutInfo?.billno || ''}`
        })
      }
      // SIMetric.metricCount({
      //   metric_name: 'fe_create_order_error', // 指标名称
      //   tags: { // 指标维度
      //     SiteUID: gbCommonInfo?.SiteUID || '',
      //     scene: metric_scene,
      //     productType: 'normal',
      //     checkoutType: !!buyNowNo.value ? 'buynow' : 'normal',
      //   }
      // })
    }

    if (validateResult) {
      _handlePreCreatePayment()
    }

    return {
      notValid: !validateResult,
      saParams: {
        result: '0',
        result_reason: 'other',
        order_id: bffOrderInfo.value.billno,
        payment_method_id: selectedPaymentInfo.value?.payment_id || '',
      },
      scene,
      payAnalysisInfo,
    }
  }

  const handleStartPaymentProcess = async ({ extraPayInfo = {} } = {}) => {
    const { notValid, scene } = await validateByPay({ extraPayInfo })
    console.log('order_new===handleStartPaymentProcess', notValid, scene, extraPayInfo)
    if (notValid && scene === 'not-select-payment') {
      alert('Please select a payment method')
    }

    if (notValid) {
      if (payToolkitResetStatus.value) {
        payToolkitResetStatus.value?.()
      } else {
        extraPayInfo?.errorCb?.()
      }
      return
    }

    const { coverData = {} } = extraPayInfo

    return updateOrderFn({
      options: {
        isAsync: !selectedPaymentInfo.value?.use_sync,
        _order_type: customOrderType.value || '', // 兼容历史逻辑
        coverData: {
          ...coverData,
          installments: extraPayInfo?.installments || '',
          cvv: extraPayInfo?.cvv || '',
        }
      },
      successCb: res => {
        console.log('order_new===updateOrderFn', res)
        handleCreatePayment({ paymentInfo: selectedPaymentInfo.value, extraPayInfo })
        if (payToolkitResetStatus.value) {
          payToolkitResetStatus.value?.()
        } else {
          extraPayInfo?.errorCb?.()
        }
      },
      errorCb: err => {
        console.log('order_new===updateOrderFn', err)
        if (payToolkitResetStatus.value) {
          payToolkitResetStatus.value?.()
        } else {
          extraPayInfo?.errorCb?.()
        }
      }
    })
  }

  const reportClickPlaceOrderAnalytics = ({
    result = '',
    result_reason = '',
  } = {}) => {

    const payment_id = selectedPaymentInfo.value?.id
    const shipping_method_id = ''
    const coupon = bffOrderInfo.value?.couponPrice?.amount
    const points = bffOrderInfo.value.point
    const billno = bffOrderInfo.value.relation_billno || bffOrderInfo.value.billno

    let storeInfo = [] // 店铺类型`店铺ID`商品数量
    let mallCodeSet = new Set()
    const carts = bffOrderInfo.value?.orderGoodsList
    carts?.forEach(cartItem => {
      let store = storeInfo.find(item => item.store_code == cartItem.store_code)
      if (store) {
        store.productNum += +cartItem.quantity
      } else {
        let store_type =
          cartItem?.business_model == 0 && cartItem?.store_code == 1 && !cartItem?.store_type
            ? 2
            : cartItem.store_type
        storeInfo.push({
          store_code: cartItem.store_code,
          store_type: store_type || '',
          productNum: +cartItem.quantity
        })
      }
      mallCodeSet.add(cartItem.mall_code)
    })
    let label_id = 0
    if (typeof window != 'undefined') {
      label_id = sessionStorage.getItem('filterType')
      if (label_id == 'none' || !label_id) label_id = 0
    }

    daEventCenter.triggerNotice({
      id: 'click_place_order:simple',
      data: {
        payment_method_id: payment_id + '',
        shipping_method_id: shipping_method_id + '',
        coupon: coupon ? '1' : '0', // coupon != '' 使用
        points: +points > 0 ? '1' : '0', // points > 0   使用
        result: result,
        result_reason: result_reason,
        order_id: billno,
        store_info: storeInfo
          .map(item => `${item.store_type}\`${item.store_code}\`${item.productNum}`)
          .join(','),
        mall_code: [...mallCodeSet].join(','),
        label_id,
        ...(selectedPaymentInfo.value?.code === 'PayPal-GApaypal'
          ? {
            is_vaulting: selectedPaymentItemStatus.value.is_vaulting
          }
          : {})
      }
    })
  }

  return {
    handleSelectPayment,
    handleUpdatePaymentChange,
    handleUpdatePayment,
    // handleUpdateOrder,
    reportClickPlaceOrderAnalytics,
    updateOrderFn,
    handleStartPaymentProcess,
    handleSetValidateByPayFn,
    handleSetResetBsPayTookitStatusFn,
    asyncUpdateOrder,
    handleCreatePayment
  }
}
