import { ref } from 'vue'
import { usePayInstance } from '@/public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/hook/usePayInstance.js'

let paymentBackTipsRef = ref(null)

export const usePaymentBackTips = () => {

  const showPaymentBackTips = () => {
    paymentBackTipsRef.value?.showBackTips()
  }

  const fetchPaymentBackTipsData = (order) => {
    paymentBackTipsRef.value?.getData(order)
  }

  const handleBackTipsMounted = () => {
    // useBsPayInstance内没有响应式数据，所以这里可以按需引入
    const { paymentInstance } = usePayInstance()
    paymentInstance?.bindComponentMethods({
      showCodBackTips: {
        showMethod: showPaymentBackTips,
        fetchData: fetchPaymentBackTipsData,
      }
    })
  }


  return {
    paymentBackTipsRef,
    handleBackTipsMounted,
  }
}
