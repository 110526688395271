import { FooterRcmdListResolveStatus } from 'public/src/pages/user/child_pages/dashboard/service/constants'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new'
import { USE_BFF_API_KEYS } from 'public/src/pages/user_wishlist/utils/constant'

const createFooterRcmdMixin = () => ({
  props: {
    footerRcmdListResolveStatus: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    isBffOpenComputed() {
      return !!isSwitchBffApiVersion(USE_BFF_API_KEYS)['wish_user_index_bff']
    },
    showSkeleton() {
      return !this.listDataReady || this.footerRcmdListResolveStatus === FooterRcmdListResolveStatus.PENDING
    },
    showProducts() {
      const resolveStatus = this.footerRcmdListResolveStatus
      return resolveStatus === FooterRcmdListResolveStatus.HAS_RCMD_LIST
        ? this.products.slice(0, 2)
        : this.products
    },
  },
})

export default createFooterRcmdMixin
