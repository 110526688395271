<template>
  <div
    :class="['c-config-banner-img j-config-top-banner', 'scrollfix-sub']"
    :style="bannerStyle"
  >
    <AppCcc
      v-if="bannerContent.length"
      :scene-data="sceneData"
      :content="bannerContent"
      :language="language"
      :context="context"
      :cate-links="cateLinks"
      @base-image-mounted="handleBaseImageMounted"
      @countdown-end="handleCountdownEnd"
    />
  </div>
</template>

<script name="BTopBanner" setup>
import { ref, computed, onMounted, nextTick, provide } from 'vue'
import AppCcc from './ccc/CccContainer.vue'
import { transfromAndCutImg } from './ccc/common/utils.js'

// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'

const appConfigs = useAppConfigs()
const { RESOURCE_SDK } = appConfigs.$envs || {}
const apis = getSource({ appConfigs })
// 数据源：END

const props = defineProps({
  fsData: {
    type: Object,
    default: () => ({}),
  },
  dataSource: {
    type: Object,
    default: () => ({}),
  },
  analysisSource: {
    type: Object,
    default: () => ({}),
  },
  channelId: {
    type: [String, Number],
    default: '',
  },
  topTabList: {
    type: Array,
    default: () => [],
  },
})

// 埋点源：
import { useAnalysis } from '../../common/analysisSource'
const analysisInstance = useAnalysis(props.analysisSource)
provide('analysisInstance', analysisInstance)
// 埋点源：END

const emits = defineEmits(['cccBannerHide', 'cccBannerShow'])

const isCountdownEnd = ref(false)
const resContent = ref({})
const bannerShowTimer = ref(null)

const bannerStyle = computed(() => {
  return {
    maxHeight: '1.04rem',
    overflow: 'hidden',
    height: isCountdownEnd.value ? '0px' : '',
  }
})

const bannerContent = computed(() => {
  return (
    resContent.value?.content?.filter(
      item => item.styleType !== 'FREE_SHIPPING_COMPONENT',
    ) || []
  )
})

const language = computed(() => {
  return resContent.value?.content[0]?.props?.languageToTextMap || {}
})

const cateLinks = computed(
  () => resContent.value?.cateLinks?.cateLinkInfo || {},
)

const context = computed(
  () => appConfigs.$envs || {},
)

const sceneData = computed(() => {
  const { pageType = '' } = resContent.value || {}
  let isFirstChannel = false
  const topTabList = props.topTabList
  const channelId = props.channelId

  if (topTabList?.length) {
    const firstChannel = topTabList[0] || {}
    isFirstChannel = firstChannel.channelId === channelId
  }

  return {
    pageType,
    isFirstChannel,
    sceneName: pageType,
    tabName: pageType,
  }
})

provide('cutImg', (imgUrl, designWidth, exp) => {
  const {
    deviceData = '',
    isSupportWeb = '',
    isSupprotCut = false,
    sceneMap,
  } = RESOURCE_SDK || {}

  const cutData = {
    deviceData,
    isSupportWebp: Boolean(isSupportWeb),
    isSupprotCut,
    imgUrl,
    designWidth: Number(designWidth),
    sceneMap,
    exp,
  }

  return transfromAndCutImg(cutData)
})

const handleBaseImageMounted = () => {
  nextTick(() => {
    bannerShowTimer.value = setTimeout(() => {
      emits('cccBannerShow', { type: 'topBanner' })
    }, 500)
  })
}

const handleCountdownEnd = () => {
  isCountdownEnd.value = true
  clearTimeout(bannerShowTimer.value)
  emits('cccBannerHide', { type: 'topBanner' })
}

onMounted(async () => {
  apis.topBannerFetch(props.channelId).then(res => {
    resContent.value = res.info
    if (!res.info?.content?.length) {
    emits('cccBannerHide', { type: 'topBanner' })
  }
  })
})
</script>
