// 数据格式：下面3个字段是必须的，其他字段也可以任意传，筛选器change时，都会透传出去
// [{
//   label: 'women',
//   value: '2030',
//   children: []
// }]

<template>
  <div 
    ref="refSingleSelect"
    class="filter-ctn__single-tree-select" 
    :class="{
      'filter-ctn__single-tree-select_bt': borderTop
    }"
    :data-type="type"
  >
    <!-- 树结构 -->
    <div
      v-if="type == 'tree' && cateLinks.length"
      class="single-tree-select__header"
    >
      <HeaderLink
        ref="headerLink"
        :title="title"
        :cate-links="cateLinks"
        :language="language"
        :selected="curValue"
        :anim-item-text="animItemText"
        :disabled="loading"
        @clickItem="clickItem"
      />
    </div>
    <!-- 非树结构 -->
    <SubTitle
      v-else-if="title"
      :title="title"
    />

    <div class="single-tree-select__content">
      <SelectItem 
        v-for="(item, index) in renderDataList"
        :key="index"
        :active="item.value == curValue"
        :item="item"
        :disabled="loading"
        @clickItem="e => clickEachItem(e, item, index)"
      />

      <div
        v-if="showMoreBtn"
        class="single-tree-select__more"
        @click="showMore"
      >
        <sui_icon_more_down_16px_2 color="#959595" />
      </div>
    </div>
    <div class="single-tree-select__operation">
      <BottomOperation
        :sum="sum"
        :language="language"
        :close-left="closeBottomLeft"
        :loading="loading"
        @reset="reset"
        @done="done"
      />
    </div>
  </div>
</template>

<script>
import { sui_icon_more_down_16px_2 } from '@shein-aidc/icon-vue2'
import BottomOperation from './UI/BottomOperation'
import SelectItem from './UI/SelectItem'
import HeaderLink from './UI/HeaderLink'
import singleMixin from '../mixins/singleSelectMixin'
import SubTitle from './UI/SubTitle'
import { emitEvent } from '../utils'

export default {
  name: 'SingleSelect',
  components: {
    BottomOperation,
    SelectItem,
    SubTitle,
    HeaderLink,
    sui_icon_more_down_16px_2
  },
  mixins: [singleMixin],
  props: {
    sum: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    urlSelectedId: {
      type: [String, Number],
      default: ''
    }, // url当前选中项
    data: {
      type: [Object, Array],
      default: () => ({})
    },
    resetData: {
      type: Boolean,
      default: false
    },
    type: {
      type: String, 
      default: 'tree'
    },
    language: {
      type: Object,
      default: () => ({})
    },
    closeBottomLeft: {
      type: Boolean,
      default: false
    },
    borderTop: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    // 面板是否展开
    visible: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      cateLinks: [],
      dataList: [],
      renderDataList: [],
      calledFromProps: false,
      showMoreBtn: false,
      showNum: 0,
    }
  },
  computed: {
    isTree () {
      return this.type == 'tree'
    },
    step() {
      return this.data.step || 0
    },
    curValue () {
      return this.data.curValue
    }
  },
  watch: {
    data: {
      handler (data) {
        this.$nextTick(() => {
          if (!data?.data?.length) return
          // 回显
          if (this.urlSelectedId) {
            this.$set(this.data, 'curValue', this.urlSelectedId)
          } else {
            this.$set(this.data, 'curValue', '')
          }

          this.handleDataList()
        })
      },
      immediate: true
    },
    resetData (isReset) {
      if (isReset) {
        this.reset(true)
        this.$emit('update:resetData', false)
      }
    },
    curValue () {
      emitEvent(this.$parent, 'needHighlightNavBar', { highlight: !!this.curValue })
      
      this.handleDataList()
    },
    // visible: {
    //   handler(val) {
    //     if (!val || typeof window === 'undefined') return
    //     this.adapterHeight()
    //   },
    //   immediate: true
    // }
  },
  methods: {
    async scrollIntoView() {
      await this.$nextTick()
      // 选中元素不在可视范围内，则自动滚动到可视范围
      const domContainer = this.$refs.refSingleSelect
      const domContent = domContainer?.querySelector('.single-tree-select__content')
      const activeChild = domContainer?.querySelector('.filter-select-item__inner_active')
      if (!activeChild || !domContent) return
      const rectContent = domContent.getBoundingClientRect()
      const rectActiveChild = activeChild.getBoundingClientRect()
      // 如果选中元素在可视范围内，则不滚动
      if (rectContent.top < rectActiveChild.top &&  rectContent.bottom > rectActiveChild.bottom) return
      activeChild.scrollIntoView({ block: 'center' })
    },
    handleDataList (params) {
      const { cb } = params || {}
      this.showMoreBtn = false
      if (this.isTree) {
        this.dataList = this.handleData(this.data.data) || [] // mixin 方法
        if (cb && typeof cb == 'function') {
          cb(this.dataList)
        }
      } else {
        this.dataList = this.data.data || []
      }

      if (this.step && this.dataList.length > this.step) {
        const start = this.renderDataList.length
        const end = this.curValue 
          ? (Math.ceil((this.dataList.findIndex(item => item.value == this.curValue) + 1) / this.step)) * this.step 
          : this.step

        this.renderDataList.push(...this.dataList.slice(start, Math.max(this.showNum, end)))
        this.showNum = this.renderDataList.length
        this.showMoreBtn = this.renderDataList.length < this.dataList.length
      }else {
        this.renderDataList = this.dataList
      }

      this.adapterHeight()
    },
    clickEachItem (e, item, index) {
      if (this.isTree) {
        this.clickItem({ type: 'button', data: item, index }, e) // mixin 方法
      } else {
        let payload = {}

        if (this.curValue == item.value) {
          payload = Object.assign(payload, { data: { value: '', originalValue: item.value } })
        } else {
          payload = Object.assign(payload, { data: { ...item, originalValue: item.value } })
        }
        this.clickCateItem(payload)
      }
    },
    clickCateItem (payload) {
      const { data } = payload

      this.$set(this.data, 'curValue', data.value) // 根据地址引用原理，触发侧边筛选数计算

      this.handleDataList({
        cb: (children) => {
          Object.assign(payload, { children }) // 当前显示的子分类
        }
      })

      let _payload = { ...payload, parents: this.cateLinks } 
      if (this.calledFromProps) {
        Object.assign(_payload, { from: 'propsCalled' })
        this.calledFromProps = false
      }

      emitEvent(this.$parent, 'singleSelectChange', _payload)
    },
    reset (calledFromProps) {
      if (this.curValue) {
        this.calledFromProps = calledFromProps
        this.clickCateItem({ data: { value: '' }, operation: 'reset' })
      }
    },
    done () {
      emitEvent(this.$parent, 'clickDone')
    },
    async showMore() {
      const start = this.renderDataList.length
      const end = start + this.step
      this.renderDataList.push(...this.dataList.slice(start, end))
      this.showMoreBtn = this.renderDataList.length < this.dataList.length
      this.showNum = this.renderDataList.length
      this.adapterHeight()
    },
    async adapterHeight() {
      const el = this.$refs.refSingleSelect
      if (!el || !this.visible || typeof window === 'undefined') return Promise.resolve()

      const elContent = el.querySelector('.single-tree-select__content')
      const elOperation = el.querySelector('.single-tree-select__operation')
      const contentScrollHeight = elContent.scrollHeight
      const rectContent = elContent?.getBoundingClientRect()
      const rectOperation = elOperation.getBoundingClientRect()
      const maxHeight = rectContent.top + contentScrollHeight + rectOperation.height
      if (maxHeight < (window.innerHeight - 48)) {
        elContent.style.height = 'auto'
        elContent.style.maxHeight = '1000px'
      } else {
        elContent.style.maxHeight = '1000px'
        elContent.style.height = window.innerHeight - 48 - rectOperation.height - rectContent.y + 'px'
      }
      
      this.scrollIntoView()
    }
  }
}
</script>

<style lang="less">
  .filter-ctn {
    &__single-tree-select {
      padding: .32rem .32rem 0;
      background: #fff;
      &_bt {
        border-top: 1px solid #e5e5e5;
      }
      .single-tree-select {
        &__header {
          margin-bottom: 0.32rem;
        }
        &__content {
          position: relative;
          .flexbox();
          flex-wrap: wrap;
          align-content: flex-start;
          height: 2.86rem;
          overflow-y: auto;
          .font-dpr(24px);
        }

        &__more {
          display: flex;
          height: 0.72rem;
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border:1px solid var(---sui_color_gray_weak1, #E5E5E5);
          background: var(---white, #FFF);
          margin: 0 0.32rem 0.32rem 0;
        }
      }
    }
  }
</style>
