// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.86.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.86.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.86.0/node_modules/css-loader/dist/cjs.js??clonedRuleSet-2.use[1]!../../../../node_modules/.pnpm/@shein-aidc+bs-category-mobile-vue2@0.0.4-alpha.7_@babel+core@7.22.1_@shein-aidc+basis-expose_vx5g44svsg2a4zpx4pvnydp37u/node_modules/@shein-aidc/bs-category-mobile-vue2/dist/style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
