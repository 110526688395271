<template>
  <component
    :is="componentName"
    ref="priceContainerRef"
    v-bind="props"
    @click-shipping-fee-icon="emits('click-shipping-fee-icon')"
  />
</template>
<script setup lang="ts">
import { type Ref, computed, ref } from 'vue'
import DynamicCheckoutPrice from './checkout-price.vue'
import StaticCheckoutPrice from './static-checkout-price.vue'
import { C_CheckoutPrice, E_CheckoutPrice } from '../../types'

interface PropsDefault {
  originSortedPrice: C_CheckoutPrice.OriginSortedPrice,
  sortedPriceStyle: C_CheckoutPrice.SortedPriceStyle,
  scene: C_CheckoutPrice.Scene,
  isShowShippingFee?: C_CheckoutPrice.IsShowShippingFee,
  isInDrawer?: C_CheckoutPrice.IsInDrawer,
  totalStyle?: C_CheckoutPrice.TotalStyle,
  grandTotalTitle: C_CheckoutPrice.GrandTotalTitle,
  taxInclusiveTip?: C_CheckoutPrice.TaxInclusiveTip,
  savePriceTips?: C_CheckoutPrice.SavePriceTips,
  totalPrice: C_CheckoutPrice.TotalPrice,
  govTaxPrice?: C_CheckoutPrice.GovTaxPrice,
  dialogBtnText: C_CheckoutPrice.DialogBtnText,
  drawerBtnText?: C_CheckoutPrice.DrawerBtnText
}

const props = defineProps<PropsDefault>()

const emits = defineEmits(['click-shipping-fee-icon'])

const componentName = computed(() => {
  const isCheckoutPage = props.scene === 'checkout'
  const isStaticVersion = +props.sortedPriceStyle === E_CheckoutPrice.SortedPriceStyle.Static
  if (isCheckoutPage && isStaticVersion) return StaticCheckoutPrice
  return DynamicCheckoutPrice
})

const priceContainerRef: Ref = ref(null)
const totalRightPriceWrapperRef = computed(() => priceContainerRef.value?.totalRightPriceWrapperRef)
defineExpose({ totalRightPriceWrapperRef })
</script>
