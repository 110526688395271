var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"xtra-item xtra-item-new",class:{
    'xtra-item-multiple': +_vm.coupon.sameCouponNum > 1
  },style:({
    ..._setup.couponConfig[_setup.couponType],
    '--coupon-circle-bg-color': _vm.entryCouponStyle?.couponCircleBgColor
  })},[_c('div',{ref:"lightEffectRef",staticClass:"xtra-item__wrapper",style:({
      backgroundImage: `url(${_setup.couponBgImage})`
    })},[(_vm.coupon.selected)?_c('div',{staticClass:"xtra-item__selected",class:{
        'xtra-item__selected-multiple': +_vm.coupon.sameCouponNum > 1
      },style:({
        backgroundColor: _setup.couponType === _setup.XtraCouponType.Shipping ? '#D2ECD1' : '#FDD2DB'
      })},[_c(_setup.Icon,{attrs:{"name":"sui_icon_selected_12px","size":"12px","color":_setup.couponType === _setup.XtraCouponType.Shipping ? '#169E00' : '#F82854'}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"xtra-item__content",style:({
        color: _setup.couponType === _setup.XtraCouponType.Shipping ? '#169E00' : '#F82854'
      })},[_c('div',{staticClass:"discount-text",domProps:{"innerHTML":_vm._s(_setup.discount)}})]),_vm._v(" "),_c('div',{staticClass:"xtra-item__amount",class:{
        'new-user-amount': _vm.coupon.privilegeAttributeId && +_vm.coupon.privilegeAttributeId !== 1
      }},[_vm._v("\n      "+_vm._s(_setup.getAmountText(_vm.coupon))+"\n      ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }