import DETAIL_API_LOCATION from '../../api'
import schttp from 'public/src/services/schttp'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
export default {
  namespaced: true,
  state: {
    middle_rank_info: [],
    ymal_rank_info: {},
    pv_clicked_rank_id: '',
    protect_rank_id: []
  },
  getters: {
    show_parallel_import(state, getters, rootState) {
      return rootState.productDetail.hotModules.storeInfo?.parallelImportInfo?.isShowParallelImportArticle === '1'
    },
    matching_style_block_show(state, getters) {
      const hasData = Object.keys(getters.relation_data)?.some(series => {
        let data = getters.relation_data[series]
        return data.list?.length
      })
      return hasData

    },
    relation_data: (state, getters, rootState, rootGetters) => {
      const multi_property_products_goodsIds = rootGetters['productDetail/Recommend/multi_property_products_goodsIds'] || []
      const val = rootState.productDetail.asyncModules.machingStyleProducts || []
      const result = {}
      val.forEach(theme => {
        const { themeName, themeId } = theme
        result[themeName] = {
          list: [],
          mainImg: '',
          theme_name: themeName,
          theme_id: themeId
        }
        theme.series_info.forEach(series => {
          series.products.forEach(product => {
            result[themeName].mainImg = series.series_img
            const isSameFromMultiProperty = multi_property_products_goodsIds.includes(product.goods_id)
            !isSameFromMultiProperty && result[themeName].list.push(product)
          })
        })
      })
      return result
    },
    rank_recommend_show(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']      
      return !!getters.rank_recommend_list?.length
    },
    rank_recommend_list(state, getters, rootState, rootGetters) {
      const { lang, SiteUID, shein_suggested_sale_price } = gbCommonInfo
      const suggestedSaleType = shein_suggested_sale_price?.[SiteUID] || ''

      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const isPaidUser = rootGetters['newProductDetail/isPaidUser']
      let { middle_rank_info, pv_clicked_rank_id } = state   

      const detailrankrec = fs_abt?.detailrankrec?.param?.detailrankrec || 'none' // || 'only1'
      
      if(pv_clicked_rank_id){
        // 在商详首屏展示且被点击进入过榜单落地页的那个榜单，则该榜单过滤，不展示在本模块
        middle_rank_info = middle_rank_info.filter(rank => {
          return rank.contentCarrierId != pv_clicked_rank_id
        })
      }
      // 当前商详指的是skc维度的，从商详首屏榜单入口进去的
      // 若用户先下滑到榜单推荐模块（未进入商详首屏榜单落地页），则该模块正常展示，
      // 用户继续返回到商详首屏点击首屏的榜单入口进入落地页，也不影响，保证该榜单推荐模块继续展示
      const sliceLength = detailrankrec === 'only1' ? 1 : 3
      middle_rank_info = middle_rank_info?.slice(0, sliceLength) || []

      let curr_rank_length = middle_rank_info.length
      let productsLength = 20
      if(curr_rank_length == 2){
        productsLength = 4
      }else if(curr_rank_length == 3){
        productsLength = 3
      }
      // 商品格式转化，适配价格商卡
      middle_rank_info.forEach(rankItem => {
        rankItem.products = rankItem.products.slice(0, productsLength)
        const products = rankItem.products?.map(v => {
          const item = goodsTransformation(v, {
            suggestedSaleType,
            language: language_v2,
            lang: lang ?? 'us',
            isPaid: !!isPaidUser,
          })
          item.productMaterial = v.productMaterial
          item.rank = v.rank
          return item
        })
        rankItem.transformedProducts = products
      })
      return middle_rank_info
    },
  },
  mutations: {
    update_middle_rank_info(state, payload) {
      state.middle_rank_info = payload
    },
    update_ymal_rank_info(state, payload) {
      state.ymal_rank_info = payload
    },
    update_pv_clicked_rank_id(state, payload) {
      state.pv_clicked_rank_id = payload
    },
    update_protect_rank_id(state, payload) {
      if(payload === 'clear'){
        state.protect_rank_id = []
        return
      }
      if(state.protect_rank_id.includes(payload)){
        return
      }
      state.protect_rank_id.push(payload)
    },
    
  },
  actions: {
    /** 获取商详榜单信息(首屏榜单/榜单推荐/ymal榜单坑位） */
    async get_detail_rank_recommend_info ({ rootGetters }, payload) {
      const goods_id = rootGetters['productDetail/common/goods_id'] || ''
      const goods_sn = rootGetters['productDetail/common/goods_sn'] || ''
      const cat_id = rootGetters['productDetail/common/cat_id']
      const mall_code = rootGetters['productDetail/common/mall_code'] || ''

      const { queryType } = payload || {}

      const params = {
        catId: cat_id,
        goodsId: goods_id,
        goodsSn: goods_sn,
        mallCode: mall_code,
        sort: 0,
        scene: 'detail',
        queryType
      }

      const result = await schttp({
        method: 'GET',
        url: DETAIL_API_LOCATION.RANK_RECOMMEND_INFO,
        params: params,
        useBffApi: true
      })

      return result?.info || {}
    },
    async get_middle_rank_info({ commit, dispatch }){
      const rank_info = await dispatch('get_detail_rank_recommend_info', { queryType: 3 })
      commit('update_middle_rank_info', rank_info?.rankRecProductInfos || [])
      commit('update_ymal_rank_info', rank_info?.ymalRankListInfo || {})
    }
  }
}
