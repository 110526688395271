/**
 * 白名单内的接口及js会监控其耗时及大小
 */

/** 列表&搜索结果页bff相关接口 */
const bffWhiteList = [
  '/bff-api/search/get_aggregation_filters', // 搜索页筛选项
  '/bff-api/product/get_products_by_keywords', // 搜索页商品列表
  '/bff-api/category/get_select_product_list',
  '/bff-api/category/select_category_attr_filter',
  '/bff-api/category/select_category_tags',
  '/bff-api/category/real_category_goods_list',
  '/bff-api/category/real_category_attr_filter',
  '/bff-api/category/real_category_tags',
  '/bff-api/category/nav_tab_index',
  '/bff-api/product/recommend/information_flow_selected_products',
  '/bff-api/ccc/home_page', // 首页bff 接口
]

/** 接口白名单 */
export const apiWhiteList = [
  ...bffWhiteList,
  '/api/productInfo/realTimeData/query',
  '/products-api/get_detail_abt_info',
  '/product/get_goods_detail_static_data',
  '/product/get_goods_detail_realtime_data',
  '/api/productInfo/productDetail/get',
  '/api/home/pageConfig/get',
  '/api/home/switchChannel/get',
  '/api/category/list/get',
  '/api/category/bff/get',
  '/api/category/crowd/info/get',
  '/api/cart/postGetPromotionById/get',
  '/api/cart/postFreeMall/get',
  '/api/user/account_setting/getAccInfo/get',
  '/api/ccc/campaignsPageData/get',
  '/api/auth/initData/get',
  '/api/productList/info/get',
  '/api/user/addressbook/addressCheckRule/get',
  '/api/user/addressbook/addressById/get',
  '/api/user/addressbook/countrySensitiveList/query',
  '/api/user/addressbook/accountAndOrderEmail/get',
  '/api/user/addressbook/limitAddr/query',
  '/api/user/addressbook/addressFormConfigByInfo/get',
  '/api/orders/base/unionOrderListPageData/get',
  '/api/orders/base/orderDetail/query',
]

/** js白名单 */
export const jsWhiteList = [
  'goods_detail',
  'config_index',
  'category',
  'cart_index_new',
  'user',
  'user-index',
  'ccc-campaigns',
  'login-module',
  'plv2_container',
  'sl_container',
  'category',
]

/** html白名单[实验性用于大盘html文件体积,数据回收后会删除] origin用于归类, 先仅收集几个90差距较大的场景 */
export const htmlWhiteList = {
  pick: {
    names: ['page_picked'],
    origin: 1,
  },
  pageDetail: {
    names: ['page_goods_detail'],
    origin: 2,
  },
  pageList: {
    names: ['page_real_class', 'page_select_class'],
    origin: 3,
  },
  activity: {
    names: ['page_activity_factory'],
    origin: 4,
  },
  flashSale: {
    names: ['page_store_flashsale'],
    origin: 5,
  },
  collectionBoards: {
    names: ['page_collection_boards'],
    origin: 8,
  },
}
