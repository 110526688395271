/* eslint-disable @typescript-eslint/no-namespace */
import type { Libs } from '@shein-aidc/bs-sdk-libs-manager'

export namespace E_CheckoutPrice {

  export enum SortedPriceStyle {
    // 最老的版本，目前 br 在用
    OldStatic = 0,
    // 动态排序的版本
    Dynamic,
    // 最新的固定顺序的版本
    Static
  }

  export enum PriceItemType {
    Origin = 'origin',
    // fr、pl 30天最低价
    LowestPrice = 'lowestPrice',
    // de 30天最低价
    OriginallyPrice = 'originallyPrice',
    // fr、pl、de A类促销
    WeakDiscountDetailA = 'weakDiscountDetailA',
    // fr、pl、de Current price
    EveryBodyPrice = 'everyBodyPrice',
    // fr、pl、de B类促销
    WeakDiscountDetailB = 'weakDiscountDetailB',
    Shipping = 'shipping',
    QuickshipSurcharge = 'quickship_surcharge',
    SaveCardPrice = 'saveCardPrice',
    PrimePrice = 'primePrice',
    ShippingInsurance = 'shippingInsurance',
    // CO消费税
    RetailDeliveryFee = 'retailDeliveryFee',
    // COD Fee
    CodCharge = 'codCharge',
    HandlingFee = 'handlingFee',
    Promotion = 'promotion',
    Coupon = 'coupon',
    GiftCard = 'giftCard',
    Point = 'point',
    Wallet = 'wallet',
    Tax = 'tax'
  }

  export enum PriceStyleType {
    // 置灰
    GrayShow = 'gray_show',
    // 删除线
    DeleteLine = 'delete_line',
    // 负号展示
    NegativePrice = 'negative_price',
    // 红色
    RedShow = 'red_show',
    // 绿色
    GreenShow = 'green_show',
    // 付费会员色
    PrimeColorShow = 'prime_color_show',
    // 下划线，整个item下方有border
    UnderlineSplit = 'underline_split'
  }

  export enum TipsClickedEvent {
    // 展示提示弹窗
    ShowTips = 'showTips',
    // 通知外部
    EmitEvent = 'emitEvent'
  }

  export enum PopupType {
    Promotion = 'promotion',
    Coupon = 'coupon',
    Tax = 'tax'
  }

  export enum ReportItemType {
    Promotions = 'promotions',
    ClubDiscount = 'club_discount',
    Coupons = 'coupons',
    Points = 'points',
    Wallet = 'wallet',
    GiftCard = 'giftcard',
    Tax = 'tax'
  }
}

export declare namespace C_CheckoutPrice {

  interface PriceItemInfo {
    amount: string,
    below_desc?: Array<any>,
    color_code?: string,
    des?: string,
    des_list?: Array<string>,
    description?: string,
    description_color_code?: string,
    discount_detail_list?: Array<any>,
    display_type: string,
    display_type_for_apple_pay?: string,
    icon?: string,
    local_name: string,
    name?: string,
    origin_price_with_symbol?: string,
    price_icon?: string,
    price_sort?: string,
    price_with_symbol: string,
    show: number,
    show_type?: E_CheckoutPrice.PriceStyleType[],
    sort?: number,
    sub_price_details?: Array<any>,
    tip?: string,
    type: string,
    interaction_info?: InteractionInfo
  }

  type InteractionInfo = {
    type: E_CheckoutPrice.PopupType,
    title: string,
    total_save_title: string,
    total_save_text: string,
    item_list?: InteractionListItem[],
    tax_sub_price_details?: TaxInteractionListItem[]
    track_info?: {
      type: string,
      promotion_saved: string,
      club_saved: string,
      coupons_num: string,
      coupons_saved: string
    }
  }

  type InteractionListItem = {
    cart_id_imgs: string[],
    display_module_title: string,
    display_title?: string,
    display_price_text?: string,
    tip?: string
  }

  type TaxInteractionListItem = {
    amount: string,
    color_code?: string,
    des?: string,
    local_name: string,
    name: string,
    price_with_symbol: string,
    show: number,
    show_type: E_CheckoutPrice.PriceStyleType[]
    tip?: string,
    type: string
  }

  type OriginSortedPrice = PriceItemInfo[]
  type SortedPriceStyle = keyof typeof E_CheckoutPrice.SortedPriceStyle // 计价样式（0-老静态样式，1-动态样式，2-新静态样式）
  type Scene = 'checkout' | 'cashier' // 场景值：checkout-普通下单页、cashier收银台
  type IsShowShippingFee = boolean // 是否触发展示大件运费问号
  type IsInDrawer = boolean // 是否是抽屉内展示的价格明细
  type TotalStyle = boolean // 底部total是否展示新样式
  type GrandTotalTitle = string
  type TaxInclusiveTip = string // 包税提示文案
  type SavePriceTips = string // 节省金额提示
  type TotalPrice = string
  type GovTaxPrice = string
  type DialogBtnText = string
  type DrawerBtnText = string
}

export declare namespace DS_CheckoutPrice {
  type TYPES = 'standard'

  type APIS = {
    // TODO edit your apis
    api1: (x: number, y: number, z: number) => Promise<Array<number>>
  }

  // TODO
  type FS_APIS = Pick<APIS, 'api1'>

  type FS_DATA = Libs.MatchApisData<FS_APIS>

  type APIS_THIS = Libs.AddThis<APIS>

  type DataSource = Libs.DataSourceInterface<TYPES, APIS_THIS>

  type SourceParams = Libs.SourceParamsInterface<DataSource>

  type FS_SourceParams = Libs.SourceParamsInterface<Libs.DataSourceInterface<TYPES, Libs.AddThis<FS_APIS>>>
}
export declare namespace AS_CheckoutPrice {
  // TODO edit you Events
  type Events = 'click_your_sa_event_name.comp_checkout-price' | 'click_price_detail.comp_checkout-price'

  type AnalysisFuncs = Libs.AnalysisFuncs<Events>

  type AnalysisSource = Libs.AnalysisSourceInterface<Events>
}
