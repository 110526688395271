export default {
  data() {
    return {
      constantData: {}
    }
  },
  created() {
    const {
      LAZY_IMG,
      IS_RW,
      GB_cssRight,
      lang,
      is_include_critical_css,
      LAZY_IMG_SOLID_COLOR = ''
    } = typeof window === 'undefined' ? this.locals || {} : gbCommonInfo

    this.constantData = Object.assign({}, this.constantData, { 
      lang, 
      LAZY_IMG_SOLID_COLOR, 
      LAZY_IMG, 
      IS_RW, 
      GB_cssRight, 
      is_include_critical_css 
    })
  }
}
