import { ESTIMATED_DATA_TYPES } from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'
/**
 * @field 库存相关
 */
export default {
  // sku、skc纬度售罄状态
  is_sold_out(state, getters, rootState, rootGetters) {
    const goodsReady = rootState.newProductDetail?.common?.goodsReady
    if (!goodsReady) return false // 数据没加载完 先不返回库存

    const sku_info = rootGetters['productDetail/common/sku_info']
    const mallCode = rootGetters['productDetail/common/mall_code']
    // 下架返回true
    if (!getters?.is_on_sale) {
      return true
    }

    if (sku_info) {
      const _currMall = sku_info?.mall_stock?.find(d => d.mall_code == mallCode)
      return +_currMall?.stock > 0 ? false : true // sku纬度库存
    }
    const real_time_api_status = rootState.productDetail?.common?.real_time_api_status || false
    const mall_info_list = rootGetters['productDetail/SizeBox/mall_info_list']
    // 多mall下realtime接口没更新前先用静态接口数据判断库存
    if (!real_time_api_status && mall_info_list?.length > 1) {
      const _currMall = mall_info_list?.find(d => d.mall_code == mallCode)
      return +_currMall?.stock > 0 ? false : true
    }
    return getters.is_skc_sold_out
  },
  mall_stock(state, getters, rootState, rootGetters) {
    const goodsReady = rootState.newProductDetail?.common?.goodsReady
    if (!goodsReady) return 1 // 数据没加载完 先不返回库存

    const skcStock = rootGetters['productDetail/skc_stock']
    return +skcStock
  },
  // 是否下架状态
  is_on_sale(state, getters, rootState) {
    const { is_on_sale } = rootState.productDetail.coldModules.productInfo || {}
    return is_on_sale == '1'
  },
  // skc维度是否售罄
  is_skc_sold_out(state, getters, rootState, rootGetters) {
    const skc_stock = rootGetters['productDetail/skc_stock']
    const { is_on_sale } = rootState.productDetail.coldModules.productInfo || {}
    return !+skc_stock || !+is_on_sale
  },
  // skc维度是否低库存
  is_skc_low_stock(state, getters, rootState) {
    const { saleAttr } = rootState.productDetail.hotModules || {}
    const { multiLevelSaleAttribute } = saleAttr || {}
    const { unselected_will_sold_out_tips } = multiLevelSaleAttribute || {}
    return unselected_will_sold_out_tips ? true : false
  },
  main_sale_text(state, getters, rootState, rootGetters) {
    
  },
  will_sold_out_tips_info(state, getters, rootState) {
    // 低库存提示
    const { sku_info } = getters || {}
    const { saleAttr } = rootState.productDetail.hotModules || {}
    const { multiLevelSaleAttribute } = saleAttr || {}
    const { unselected_will_sold_out_tips, lowStockType } = multiLevelSaleAttribute || {}
    if (sku_info?.sku_code) {
      return {
        tip: sku_info?.selected_will_sold_out_tips || '',
        lowStockType: sku_info?.lowStockType,
        scene: 'sku',
      }
    }
    return {
      tip: unselected_will_sold_out_tips || '',
      lowStockType,
      scene: 'skc',
    }
  },

  will_cart_button_sold_out_tip(state, getters, rootState) {
    // 加车按钮低库存提示
    const { sku_info } = getters || {}
    if (sku_info?.sku_code) return sku_info?.cartButtonSoldOutTips

    const { saleAttr } = rootState.productDetail.hotModules || {}
    const { multiLevelSaleAttribute } = saleAttr || {}
    const { cartButtonSoldOutTips } = multiLevelSaleAttribute || {}
    return cartButtonSoldOutTips
  },

  stock_tip_show_location(state, getters, rootState, rootGetters) {
    const estimated_info = rootGetters['productDetail/price/estimated_info']
    const show_sale_attr_fold_entry = rootGetters['productDetail/SizeAttrFold/show_sale_attr_fold_entry']
    const { productInfo } = rootState.productDetail.hotModules || {}
    const { cccDetailsTemplate } = productInfo || {}
    const { content } = cccDetailsTemplate || {}
    // line: 尺码组件内部 qty: 展示在qty旁边 attrTag: 展示在加车按钮上方的提示
    // 折叠优先级最高
    if (show_sale_attr_fold_entry) return 'line'
    // TODO: 到手价库存相关展示的位置是不是也能收口到这里
    if (ESTIMATED_DATA_TYPES.N_ESTIMATED === estimated_info?.dataType) return 'qty'

    const abtInfo = rootGetters['productDetail/product_detail_abt_info']
    const sizechartshow = abtInfo?.sizechartshow?.param?.sizechartshow || ''
    const isCccTemplate = !['A', 'B'].includes(sizechartshow)
    if(sizechartshow === 'B' || (isCccTemplate && content?.[0]?.countCell == '1')) return 'qty'
    const showLowStockTag = abtInfo?.Detailsalmostsoldout?.param?.otherattrlabel_lowstock === 'on'
    if (showLowStockTag) return 'attrTag'
    return 'line'
  },
  current_stock(state, getters, rootState, rootGetters) {
    if (getters.is_sold_out) return 0
    if (getters.sku_info?.sku_code) {
      const mallCode = rootGetters['productDetail/common/mall_code']
      return Number(getters.sku_info?.mall_stock?.find(d => d.mall_code == mallCode)?.stock) || 0
    }
    return Number(rootGetters['productDetail/skc_stock']) || 0
  },

  is_spu_sold_out(state, getters, rootState, rootGetters) {
    const colors_info = rootGetters['productDetail/MainSaleAttr/colors_info']
    return colors_info?.every?.(item => !!item?.isSoldOutStatus)
  },

  is_some_sku_sold_out(state, getters, rootState, rootGetters) {
    const sku_list = rootGetters['productDetail/sku_list']
    return sku_list?.some?.(sku => +sku.stock === 0)
  }
}
