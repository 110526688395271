import { Button } from './Button.jsx'
import { Price } from './Price.jsx'

export const freeTrialApplyPlugin = ({
  btnText,
  onSubmit,
}) => {
  return {
    name: 'FreeTrialApply',
    renderAddCartButton: context => {
      const { isSoldOut, mallStock, unListed } = context
      // 非预售商品，非售罄，并且mall库存大于0
      if(!unListed && !isSoldOut && mallStock > 0) {
        return Button({
          btnText,
          context,
          onSubmit,
        })
      }
    },
    renderPrice: (price) => {
      return Price({
        price,
      })
    },
  }
}
