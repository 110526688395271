import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
import { ROUTE_REGEX } from '../constant.js'
import { getSearchRequestQueue } from './searchListPage.js'
import { getSelectRequestQueue } from './selectListPage.js'
import { getRealRequestQueue } from './realListPage.js'
import { getSellingRequestQueue } from './sellingPage.js'
import { getSheinPicksRequestQueue } from './sheinPicksPage.js'
import { getDailyNewRequestQueue } from './dailyNewPage.js'
import { getPickedRequestQueue } from './pickedListPage.js'
import { getUserBehaviorRequestQueue } from './userBehavior.js'
import { getFeedbackRecRequestQueue } from './feedbackRecPage.js'
import { getDiscountRequestQueue } from './discountListPage.js'
import { getRealHalfRequestQueue } from './realHalfDrawer.js'


const matchPageType = (toRoute) => {
  const pageType = {
    isSearch: false,
    isSelect: false,
    isReal: false,
    isPicked: false,
    isUserBehavior: false,
    isFeedBackRec: false,
    isDiscount: false,
    isDailyNew: false,
    isSheinPicks: false,
    isRealHalf: false
  }
  if (!toRoute?.path) {
    return pageType
  }
  // @tips 尽量减少正则表达式的匹配次数
  if (toRoute.path.match(ROUTE_REGEX.real) && toRoute.drawerType === 'entityDrawer') {
    // 类目反馈半屏
    pageType.isRealHalf = true
  } else if (toRoute.path.match(ROUTE_REGEX.search)) {
    pageType.isSearch = true
  } else if (toRoute.path.match(ROUTE_REGEX.select)) {
    pageType.isSelect = true
  } else if (toRoute.path.match(ROUTE_REGEX.real)) {
    pageType.isReal = true
  } else if (toRoute.path.match(ROUTE_REGEX.picked)) {
    pageType.isPicked = true
  } else if (toRoute.path.match(ROUTE_REGEX.pickedProducts)) {
    pageType.isUserBehavior = true
  } else if (toRoute.path.match(ROUTE_REGEX.feedbackRec) || toRoute.drawerType === 'feedbackRec') {
    //兼容实时反馈半屏
    pageType.isFeedBackRec = true
  } else if (toRoute.name === 'discount-channel-list') {
    pageType.isDiscount = true
  } else if (toRoute.path.match(ROUTE_REGEX.daily_new)) {
    pageType.isDailyNew = true
  } else if (toRoute.path.match(ROUTE_REGEX.sheinPicks)) {
    pageType.isSheinPicks = true
  } else if (toRoute.path.match(ROUTE_REGEX.selling)) {
    pageType.isSelling = true
  }

  return pageType
}

const getBffRequestQueue = async (requestInfo, toRoute, newSrcTabPageId) => {
  const SEARCH_V1 = 'search_v1'
  const PICKED_V1 = 'picked_v1'
  const SELECTION_V1 = 'selection_v1'
  const DISCOUNT_CHANNEL_V1 = 'discount_channel_v1'
  const BREADCRUMBS_V1 = 'breadcrumbs_v1'

  const FEEDBACK_REC_V1 = 'feedback_rec_v1'
  const SEARCH_BANNER_CARD_V1 = 'search_banner_card_v1'
  const bffSwitchVersion = isSwitchBffApiVersion([
    SEARCH_V1, PICKED_V1, SELECTION_V1, DISCOUNT_CHANNEL_V1,
    FEEDBACK_REC_V1, SEARCH_BANNER_CARD_V1, BREADCRUMBS_V1,
  ])
  const isSearchSwitchBff = bffSwitchVersion?.[SEARCH_V1]
  const isPickedSwitchBff = bffSwitchVersion?.[PICKED_V1]
  const isSelectSwitchBff = bffSwitchVersion?.[SELECTION_V1]
  const isSellingSwitchBff = bffSwitchVersion?.[DISCOUNT_CHANNEL_V1]
  const isFeedbackRecSwitchBff = bffSwitchVersion?.[FEEDBACK_REC_V1]
  const isSearchBannerCardSwitchBff = bffSwitchVersion?.[SEARCH_BANNER_CARD_V1]
  const isBreadcrumbSwitchBff = bffSwitchVersion?.[BREADCRUMBS_V1]

  // !BFF 场景，入口依赖列表的是否走 BFF，因此判断改为根据 url 的 scene 参数来判断
  // const isDiscountSwitchBff = bffSwitchVersion?.discount_channel_v1
  const isDiscountSwitchBff = requestInfo?.query?.scene

  const {
    isSelling,
    isSearch,
    isSelect,
    isReal,
    isPicked,
    isUserBehavior,
    isFeedBackRec,
    isDiscount,
    isDailyNew,
    isSheinPicks,
    isRealHalf,
  } = matchPageType(toRoute)

  // 搜索结果页
  if (isSearch && isSearchSwitchBff) {
    return getSearchRequestQueue({ requestInfo, newSrcTabPageId, isSearchBannerCardSwitchBff })
  } 
  
  // 选品列表页，店铺--只有命中bff 
  const isStore = toRoute.name === 'page_store'
  if ((isSelect && isSelectSwitchBff) || isStore) {
    return getSelectRequestQueue({ requestInfo, newSrcTabPageId, isStore, isBreadcrumbSwitchBff })
  }

  // 真实列表页
  if (isReal && isSelectSwitchBff) {
    return getRealRequestQueue({ requestInfo, newSrcTabPageId, isBreadcrumbSwitchBff })
  }

  // 卖点列表页
  if (isSelling && isSellingSwitchBff) {
    return getSellingRequestQueue({ requestInfo, newSrcTabPageId, isBreadcrumbSwitchBff })
  }

  // shein picks页
  if (isSheinPicks && isSellingSwitchBff) {
    return getSheinPicksRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // daily new列表页
  if (isDailyNew && isSellingSwitchBff) {
    return getDailyNewRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // 信息流落地页
  if (isPicked && isPickedSwitchBff) {
    return getPickedRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // 用户行为落地页（信息流用户行为内容体点击进入）
  if (isUserBehavior) {
    return getUserBehaviorRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // 实时反馈落地页
  if (isFeedBackRec && isFeedbackRecSwitchBff) {
    return getFeedbackRecRequestQueue({ requestInfo, newSrcTabPageId })
  }

  // 折扣频道落地页
  if (isDiscount && isDiscountSwitchBff) {
    return getDiscountRequestQueue({ requestInfo })
  }

  // 类目推荐半屏(就是不完全的真实分类页， 单拆出来就是怕污染真实分类页的逻辑), 以后再有半屏都可以在这扩展
  if (isRealHalf && isFeedbackRecSwitchBff) {
    return getRealHalfRequestQueue({ requestInfo, newSrcTabPageId, toRoute })
  }
  
  return {}
}

export {
  getBffRequestQueue
}
