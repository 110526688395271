import { computed } from 'vue'
import { usePaymentsStore, type PaymentsStore } from './usePayments'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { debuggerLog } from '../utils'

export const useEditablePaymentInfo = (paymentsStore?: PaymentsStore) => {
  if (!paymentsStore) {
    paymentsStore = usePaymentsStore()
  }
  const editablePaymentInfo = computed(() => paymentsStore?.editablePaymentInfo || {})

  const updateInfo = (key: Trade_PayToolKit.PAYMENT_CODE_KEY, info: Partial<Trade_PayToolKit.EditablePaymentInfo>) => {
    debuggerLog('handleEditableInfoChange updateInfo======', key, paymentsStore, info)
    paymentsStore.mergeState('editablePaymentInfo', {
      [key]: {
        ...editablePaymentInfo.value[key],
        ...info,
      },
    })
  }

  const handleEditableInfoChange = (
    handleChangeParams: {
      changeInfo: { [key: string]: Partial<Trade_PayToolKit.EditablePaymentInfo> },
      needEmitChangeEvent?: boolean,
      extraInfo?: Trade_PayToolKit.EditablePaymentInfoExtraInfo,
    },
  ) => {
    debuggerLog('handleEditableInfoChange handleChangeParams======', handleChangeParams)
    const { changeInfo, needEmitChangeEvent = true, extraInfo = {} } = handleChangeParams
    const { selectedPayment } = paymentsStore

    Object.keys(changeInfo).forEach((key) => {
      updateInfo(key as Trade_PayToolKit.PAYMENT_CODE_KEY, changeInfo[key])

      const isSelectedPayment = selectedPayment?.code === key

      if (isSelectedPayment && (needEmitChangeEvent || extraInfo?.isNeedPostCheckout)) {
        paymentsStore.paymentEventBus.paymentInfoChange.emit({
          ...changeInfo[key],
          extraInfo,
        })
      }
    })
  }

  return {
    editablePaymentInfo,
    updateInfo,
    handleEditableInfoChange,
  }
}
