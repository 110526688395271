<template>
  <div
    class="promo-guide"
    @click.stop="handleClick"
  >
    <div 
      class="promo-guide-container"
      :style="{
        'bottom': `${styleInfo.bottom}px`,
      }"
    >
      <div class="guide-info">
        <span 
          v-tap="{
            id: '1-8-1-47',
          }"
          class="btn"
        >
          {{ language.SHEIN_KEY_PWA_20715 }}
        </span>
        <div class="guide-tips">
          {{ language.SHEIN_KEY_PWA_32757 }}
        </div>
        <img
          class="guide-img"
          src="http://img.ltwebstatic.com/images3_acp/2024/08/09/23/1723198024f013957327a761ce4477143483e535d5.png" 
          width="20"
        />
      </div>
      <div 
        class="promo-guide-ele"
        :style="{
          'width': `${styleInfo.w}px`,
          'height': `${styleInfo.h}px`,
        }"
      >
      </div>
    </div>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
export default {
  name: 'PromoGuide',
  props: {
    language: {
      type: Object,
      default: () => {}  
    },
  },
  data() {
    return {
      isShow: false,
      styleInfo: {
        w: '',
        h: '',
        bottom: '',
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const dom = document.querySelector('.c-promotion-coupon-mix')
      if(!dom) return
      daEventCenter.triggerNotice({ daId: '1-8-1-46' })
      document.body.classList.add('mshe-fixed')
      const rect = dom.getBoundingClientRect()
      this.styleInfo.w = rect?.width || 0
      this.styleInfo.h = rect?.height || 0
      this.styleInfo.bottom = (window?.innerHeight - rect.bottom) || 0
    },
    handleClick() {
      document.body.classList.remove('mshe-fixed')
      this.$emit('close')
    }
  },
}
</script>

<style lang="less" scoped>
.promo-guide {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: @zindex-trans-mask1;
  transform: translate3d(0, 0, 99px);
  .promo-guide-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    .promo-guide-ele {
      position: relative;
      z-index: @zindex-hack;
      box-shadow: 0 0px 0px 99999px rgba(0, 0, 0, 0.6);
    }
    .guide-info {
      z-index: @zindex-trans-mask1;
      width: 264/37.5rem;
      display: flex;
      flex-direction: column;
      padding-bottom: 8/37.5rem;
      .btn {
        border-radius: 2/37.5rem;
        display: inline-block;
        text-align: center;
        width: 60/37.5rem;
        background-color: #000;
        line-height: 28px;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        align-self: flex-end;
        margin-bottom: 12/37.5rem;
      }
      .guide-tips {
        position: relative;
        text-align: left;
        width: 100%;
        box-sizing: border-box;
        padding: 12/37.5rem;
        border-radius: 2/37.5rem;
        background-image: url('http://img.ltwebstatic.com/images3_acp/2024/08/09/ab/1723197781cc3599a0ca6966661debd30cecd0bc27.png');
        background-size: cover;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      .guide-img {
        margin-left: 35/37.5rem;
      }
    }
  }
}
</style>
