var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.getTitleAnalysis({ id: '2-22-2' })),expression:"getTitleAnalysis({ id: '2-22-2' })"},{name:"expose",rawName:"v-expose",value:(_vm.getTitleAnalysis({ id: '2-22-1' })),expression:"getTitleAnalysis({ id: '2-22-1' })"}],staticClass:"multi-line__header",class:{ 'multi-line__header_single': _vm.isSingleContainer },style:([
    { color: _vm.titleColor },
    _vm.showRightIcon ? _vm.halfHeaderStye : null,
    _vm.isSingleContainer ? _vm.mainTitleStyle : null,
    _vm.isNotQuarterContainer
      ? {
        paddingLeft: _vm.cssRight ? 'unset' : '0.0533rem',
        paddingRight: _vm.cssRight ? '0.0533rem' : 'unset',
      }
      : null,
  ]),on:{"click":_vm.handleHeaderClick}},[_c('div',{staticClass:"multi-line__header_single-main",style:({ maxWidth: _vm.showRightIcon ? 'calc(100% - 16px)' : '100%' })},[_c('div',{staticClass:"multi-line__header_main",style:([_vm.isSingleContainer ? null : _vm.mainTitleStyle])},[(_vm.showTitleText)?_c('p',{staticClass:"multi-line__header_title-main",style:([_vm.titleCommonStyle ])},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.showTitleImage)?_c('BaseImg',{staticClass:"not-fsp-element multi-line__header_title-main",style:([_vm.titleImageSize]),attrs:{"fit":"cover","img-src":_vm.titleImage?.src,"placeholder":{
          width: _vm.getTitleImgWidth(),
          height: 16
        },"ratio":_vm.titleImage?.ratio || 1,"position":_vm.cssRight ? '100% 50%' : '0% 50%',"img-design-width":_vm.getTitleImgWidth() * 3,"first-screen":_vm.isFirstPage}}):_vm._e(),_vm._v(" "),_c('ClientOnly',[(_vm.isNotQuarterContainer && _vm.bigSaleLogo?.src)?_c('BaseImg',{staticClass:"not-fsp-element multi-line__header_promotion",style:([_vm.bigSaleLogoSize]),attrs:{"fit":"cover","img-src":_vm.bigSaleLogo?.src,"placeholder":{
            width: _vm.getTitlebigSaleWidth(),
            height: 16
          },"ratio":_vm.bigSaleLogo.ratio,"position":_vm.cssRight ? '100% 50%' : '0% 50%',"img-design-width":_vm.getTitlebigSaleWidth() * 3,"first-screen":_vm.isFirstPage}}):_vm._e()],1)],1),_vm._v(" "),(_vm.subTitle && !_vm.isSingleContainer)?_c('div',{staticClass:"multi-line__header_subTitle",style:([_vm.titleCommonStyle, _vm.subTitleStyle, { color: _vm.subTitleColor }])},[_vm._v("\n      "+_vm._s(_vm.subTitle)+"\n    ")]):_vm._e()]),_vm._v(" "),_c('ClientOnly',[(_vm.isSingleContainer && _vm.showRightIcon && _vm.subTitle)?_c('div',{staticClass:"multi-line__header_subTitle"},[_c('p',{style:([_vm.titleCommonStyle, _vm.subTitleStyle, { color: _vm.subTitleColor, marginBottom: '0' }])},[_vm._v("\n        "+_vm._s(_vm.subTitle)+"\n      ")])]):_vm._e(),_vm._v(" "),(_vm.showRightIcon)?_c('sui_icon_more_right_12px',{style:([
        _vm.isSingleContainer ? { paddingRight: '0.1067rem' } : { marginTop: '2px' },
      ]),attrs:{"size":"12px","is-rotate":_vm.cssRight}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }