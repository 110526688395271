/**
 * 功能：修复特定 iOS 版本（如 18.0.x 与18.1.x版本）在某些页面头部导航遮住问题，通过滚动 1px 解决
 */

function isIOSVersion_18_0_or_18_1() {
  if (window._debug_ios_scroll_fix) {
    return window._debug_ios_scroll_fix === '1'
  }
  const ua = navigator.userAgent
  // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.1.1 Safari/605.1.15"
  // "Mozilla/5.0 (iPhone; CPU iPhone OS 18_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.0 Mobile/15E148 Safari/604.1"
  const isIos = /iPhone|iPad|iPod/i.test(ua) || /Macintosh/i.test(ua)
  if (isIos) {
    const versionMatch = ua.match(/Version\/(\d+)\.(\d+)/)
    if (versionMatch) {
      const majorVersion = parseInt(versionMatch[1], 10) // 主版本号（18）
      const minorVersion = parseInt(versionMatch[2], 10) // 次版本号（0 或 1）
      // 检测是否符合 "18.0.x" 或 "18.1.x" 的规则
      if (majorVersion === 18 && (minorVersion === 0 || minorVersion === 1)) {
        return true
      }
    }
  }
  return false
}

/**
 * 修复滚动问题的方法
 * 如果条件满足（iOS 18.0.1），则对页面滚动位置加/减 1px 以强制触发重绘
 * @param {number} [scrollTop] - 用户提供的滚动值（可选）。如果为空，则自动使用当前页面的滚动位置。
 */
function fixIOSScrollIssue(scrollTop) {
  if (typeof window === 'undefined') return
  if (isIOSVersion_18_0_or_18_1()) {
    const handle = done => {
      const SCROLL_OFFSET = 1
      const currentScrollTop = scrollTop || document.documentElement.scrollTop
      document.documentElement.scrollTop = currentScrollTop + SCROLL_OFFSET
      setTimeout(() => {
        document.documentElement.scrollTop = currentScrollTop
        done && done()
      }, 50)
    }
    // tip: 判断当前是否是弹窗状态，弹窗状态 因为内部会将body设置为fixed，所以需要先给body上的fixed样式去掉，设置完后再加上
    const body = document.body
    const bodyStyle = window.getComputedStyle(body)
    const bodyPosition = bodyStyle.getPropertyValue('position')
    if (bodyPosition === 'fixed') {
      body.style.position = 'static'
      handle(() => {
        body.style.position = 'fixed'
      })
    } else {
      handle()
    }
  }
}

export { fixIOSScrollIssue }
