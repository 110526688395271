const EXPOSE_MODULE_GOODS_LIST_SA_NAME = 'expose_module_goods_list'

const config = {
  featureKey: 'information_flow', // 场景key
  singleKey: true, // 是否单例，如果是单例，那么只会有一个sessionId
  closeSessionStorage: false, // 是否使用 sessionStorage 存储数据
  saNames: [
    {
      saName: 'click_auto_rcmd_info_flow',
      limit: 40
    },
    {
      saName: 'click_goods_list',
      limit: 40
    },
    {
      saName: 'click_add_bag',
      limit: 40
    },
    {
      saName: EXPOSE_MODULE_GOODS_LIST_SA_NAME,
    },
  ], 
  timestampLength: 10, // 时间戳长度配置
  // 所经过的埋点name
  targetNames: [
    {
      name: 'config_index',
      url: '/'
    },
    {
      name: 'picked-info-list',
      url: '/picked-info-products.html',
    },
    // {
    //   name: 'page_goods_detail',
    //   url: '/*-p-(\\d+)-cat-(\\d+).html',
    // },
    // {
    //   name: 'page_goods_detail',
    //   url: '/*-p-(\\d+).html',
    // },
    // {
    //   name: 'page_goods_detail',
    //   url: '/*-p-(\\d+)-cat-(|).html',
    // },
  ],
  handleAction(data) { // 过滤数据, 每次埋点都会触发
    // 首页信息流点击
    const homeInformationClick = filterHomeInformationClick(data)
    if (homeInformationClick !== 'next') return homeInformationClick

    // 信息流落地页点击
    const informationPageClick = filterInformationPageClick(data)
    if (informationPageClick !== 'next') return informationPageClick

    // 信息流落地页加车
    const informationPageCart = filterInformationPageCart(data)
    if (informationPageCart !== 'next') return informationPageCart

    // 商详加车
    const detailAddCart = filterDetailAddCart(data)
    if (detailAddCart !== 'next') return detailAddCart

    // 商详推荐列表曝光
    const detailRecommendListExpose = filterDetailRecommendListExpose(data)
    if (detailRecommendListExpose !== 'next') return detailRecommendListExpose

    return null
  },
  onTrigger({ collectData }) {
    const timestamp = Object.keys(collectData)[0] // 时间戳
    const clickAction = handleClickAction(collectData, timestamp)
    const addCartAction = handleAddCartAction(collectData, timestamp)
    const detailListAction = handleDetailListAction()

    return {
      rec_user: {
        100015: clickAction, // 信息流点击行为
        100016: addCartAction, // 信息流加车行为
        100026: detailListAction, // 商详页推荐列表曝光行为
      }
    }
  }
}

const handleDetailListAction = () => {
  const infoExposeModuleGoodsList = window.info_expose_module_goods_list

  // 使用过即可清空 仅携带端智能入参一次
  window.info_expose_module_goods_list = []

  return infoExposeModuleGoodsList || []
}

const handleClickAction = (collectData, timestamp) => {
  const homeInformationClick = (collectData?.[timestamp]?.['click_auto_rcmd_info_flow'] || []).map(item => filterHomeInformationClick(item, 'trigger')) // 首页信息流点击
  const informationPageClick = collectData?.[timestamp]?.['click_goods_list']  || [] // 信息流落地页点击
  
  const result = homeInformationClick.concat(informationPageClick).sort((a, b) => b[1] - a[1]).flat(1)
  return result
}

const handleAddCartAction = (collectData, timestamp) => {
  const addCart = collectData?.[timestamp]?.['click_add_bag'] || [] // 点击加车
  
  const result = addCart.sort((a, b) => b[1] - a[1]).flat(1)
  return result
}

const filterDetailRecommendListExpose = (data) => {
  const { activity_name, activity_param, page_param, page_name } = data
  if (activity_name !== 'expose_module_goods_list') return 'next'

  const { activity_from, goods_list, tab_list = '' } = activity_param
  const { src_module, src_identifier } = page_param

  const isGoodsDetail = page_name === 'page_goods_detail'

  // 限制入口来源为首页信息流首频道
  if (src_module !== 'infoflow' || src_identifier?.split('`')?.[0] !== 'tn=all' ) return null

  // 限制页面为商详 
  if (!isGoodsDetail) return null

  if (activity_from === 'you_may_also_like' && tab_list.startsWith('0')) {
    const goodsIdList = goods_list.split(',').map(item => Number(item.split('`')?.[0])).filter(Boolean)

    window.info_expose_module_goods_list = [ ...(window.info_expose_module_goods_list || []), ...goodsIdList].slice(-40)
  }

  return null
}

const filterHomeInformationClick = (data, type) => {
  const { activity_name, activity_param, timestamp, page_param } = data
  if (activity_name !== 'click_auto_rcmd_info_flow') return 'next'

  const tab_hole = page_param?.tab_hole
  if ( tab_hole !== '1') return null // 限制首频道

  const goods_id = activity_param?.info_flow?.split('`')?.[3]?.split('_')?.[1] || ''

  if(!goods_id) return null

  return type === 'trigger' ? [Number(goods_id), Number(timestamp), 1] : data
}

const filterInformationPageClick = (data) => {
  const { activity_name, activity_param, timestamp, page_param, page_name } = data
  if (activity_name !== 'click_goods_list') return 'next'

  const { src_module, src_identifier } = page_param

  // 限制入口来源为首页信息流首频道
  if (src_module !== 'infoflow' || src_identifier?.split('`')?.[0] !== 'tn=all' ) return null

  // 限制页面为信息流落地页、趋势频道落地页
  if (page_name !== 'page_picked' && page_name !== 'page_trend_landing') return null

  const goods_id = activity_param?.goods_list?.split('`')?.[0] || ''

  if(!goods_id) return null

  return [Number(goods_id), Number(timestamp), 2]
}

const filterInformationPageCart = (data) => {
  const { activity_name, activity_param, timestamp, page_param, page_name } = data
  if (activity_name !== 'click_add_bag') return 'next'

  const { src_module, src_identifier } = page_param

  // 限制入口来源为首页信息流首频道
  if (src_module !== 'infoflow' || src_identifier?.split('`')?.[0] !== 'tn=all' ) return 'next'

  // 限制页面为信息流落地页、趋势频道落地页 限制加车成功
  if ((page_name !== 'page_picked' && page_name !== 'page_trend_landing') || activity_param?.result !== '1') return 'next'

  const goods_id = activity_param?.goods_id || ''

  if(!goods_id) return 'next'

  return [Number(goods_id), Number(timestamp)]
}

const filterDetailAddCart = (data) => {
  const { activity_name, activity_param, timestamp, page_param, page_name } = data
  if (activity_name !== 'click_add_bag') return 'next'

  const { src_module, src_identifier } = page_param

  // 限制入口来源为首页信息流首频道
  if (src_module !== 'infoflow' || src_identifier?.split('`')?.[0] !== 'tn=all' ) return null
 
  // 限制页面为商详 限制加车成功 限制为主商品加车 
  if(page_name !== 'page_goods_detail' || activity_param?.result !== '1' || activity_param?.activity_from !== 'main') return null

  const goods_id = activity_param?.goods_id || ''

  if(!goods_id) return null

  return [Number(goods_id), Number(timestamp)]
}
export default config
