<template>
  <div
    ref="tempRef"
    class="bsc-cart-item-main__wrap">
    <div class="bsc-cart-item-main__content">
      <div class="bsc-cart-item-main__left">
        <GoodsImg
          v-if="goodsImgData.isShow"
          v-bind="goodsImgData.props"
          v-on="goodsImgData.events"
        >
          <template #left>
            <GoodsDiscountFlag
              v-if="goodsDiscountFlagData.isShow && (goodsDiscountFlagData.type === 'v1' || goodsDiscountFlagData.type === '')"
              v-bind="goodsDiscountFlagData.props"
            />
            <GoodsDiscountFlagV2
              v-if="goodsDiscountFlagData.isShow && goodsDiscountFlagData.type === 'v2'"
              v-bind="goodsDiscountFlagData.props"
            />
          </template>
          <template #right>
            <GoodsSeriesFlag
              v-if="goodsSeriesFlagData.isShow"
              v-bind="goodsSeriesFlagData.props"
            />
          </template>
          <template #bottom>
            <GoodsBelt
              v-if="goodsBeltData.isShow"
              v-bind="goodsBeltData.props"
            />
          </template>
          <template #center>
            <GoodsSoldOutFlag
              v-if="goodsSoldOutFlagData.isShow"
              v-bind="goodsSoldOutFlagData.props"
            />
          </template>
        </GoodsImg>
      </div>
      <div class="bsc-cart-item-main__right">
        <div class="bsc-cart-item-main__right-top">
          <GoodsTitle
            v-if="goodsTitleData.isShow"
            class="bsc-cart-item-main__title"
            v-bind="goodsTitleData.props"
            v-on="goodsTitleData.events"
          >
            <template #left>
              <GoodsTitleIcon
                v-if="goodsTitleIconData.isShow"
                v-bind="goodsTitleIconData.props"
              />
            </template>
          </GoodsTitle>
          <GoodsCopy
            v-if="goodsCopyData.isShow"
            class="bsc-cart-item-main__title-right"
            v-bind="goodsCopyData.props"
            v-on="goodsCopyData.events"
          />
          <GoodsAddWish
            v-if="goodsAddWishData.isShow"
            class="bsc-cart-item-main__title-right"
            v-bind="goodsAddWishData.props"
            v-on="goodsAddWishData.events"
          />
          <GoodsQtyText
            v-if="goodsQtyTextData.isShow && (goodsQtyTextData.position === 'top' || goodsQtyTextData.position === '')"
            class="bsc-cart-item-main__title-right"
            v-bind="goodsQtyTextData.props"
            v-on="goodsQtyTextData.events"
          />
        </div>
        <div class="bsc-cart-item-main__right-middle">
          <GoodsStore
            v-if="goodsStoreData.isShow"
            class="bsc-cart-item-main__store"
            v-bind="goodsStoreData.props"
            v-on="goodsStoreData.events"
          />
          <div class="bsc-cart-item-main__info">
            <ChangeMall
              v-if="changeMallData.isShow"
              v-bind="changeMallData.props"
              v-on="changeMallData.events"
            />
            <GoodsSaleAttr
              v-if="goodsSaleAttrData.isShow"
              v-bind="goodsSaleAttrData.props"
              v-on="goodsSaleAttrData.events"
            />
            <FindSimilarText
              v-if="findSimilarTextData.isShow"
              v-bind="findSimilarTextData.props"
              v-on="findSimilarTextData.events"
            />
            <template
              v-if="lowStockLabelData.isShow && (lowStockLabelData.props ? lowStockLabelData.props.isShow : false ) && (lowStockLabelData.position === 'top' || lowStockLabelData.position === '')"
            >
              <ClientOnly>
                <LowStockLabel
                  v-bind="lowStockLabelData.props"
                />
              </ClientOnly>
            </template>
            <BehaviorLabel
              v-else-if="behaviorLabelData.isShow && (behaviorLabelData.position === 'top' || behaviorLabelData.position === '')"
              v-bind="behaviorLabelData.props"
              v-on="behaviorLabelData.events"
            />
          </div>
          <AddMore
            v-if="addMoreData.isShow"
            class="bsc-cart-item-main__add-more"
            v-bind="addMoreData.props"
            v-on="addMoreData.events"
          />
          <div class="bsc-cart-item-main__promotion">
            <template v-if="lowStockTagData.isShow">
              <ClientOnly>
                <LowStockTag
                  v-bind="lowStockTagData.props"
                />
              </ClientOnly>
            </template>
            <SFSTag
              v-if="sfsTagData.isShow && (sfsTagData.type === 'v1')"
              v-bind="sfsTagData.props"
            />
            <SFSTagV2
              v-if="sfsTagData.isShow && (sfsTagData.type === 'v2')"
              v-bind="sfsTagData.props"
            />
            <QuickShipTag
              v-if="quickShipTagData.isShow && (quickShipTagData.type === 'v1' || quickShipTagData.type === '')"
              v-bind="quickShipTagData.props"
            />
            <QuickShipTagV2
              v-if="quickShipTagData.isShow && quickShipTagData.type === 'v2'"
              v-bind="quickShipTagData.props"
            />
            <HolidayDeliveryTag
              v-if="holidayDeliveryTagData.isShow && (holidayDeliveryTagData.type === 'v1' || holidayDeliveryTagData.type === '')"
              v-bind="holidayDeliveryTagData.props"
            />
            <HolidayDeliveryTagV2
              v-if="holidayDeliveryTagData.isShow && holidayDeliveryTagData.type === 'v2'"
              v-bind="holidayDeliveryTagData.props"
            />
            <FreeShippingTag
              v-if="freeShippingTagData.isShow && (freeShippingTagData.type === 'v1' || freeShippingTagData.type === '')"
              v-bind="freeShippingTagData.props"
            />
            <FreeShippingTagV2
              v-if="freeShippingTagData.isShow && freeShippingTagData.type === 'v2'"
              v-bind="freeShippingTagData.props"
            />
            <EvoluTag
              v-if="evoluTagData.isShow && (evoluTagData.type === 'v1' || evoluTagData.type === '')"
              v-bind="evoluTagData.props"
            />
            <EvoluTagV2
              v-if="evoluTagData.isShow && evoluTagData.type === 'v2'"
              v-bind="evoluTagData.props"
            />
            <VoucherTag
              v-if="voucherTagData.isShow && (voucherTagData.type === 'v1' || voucherTagData.type === '')"
              v-bind="voucherTagData.props"
            />
            <VoucherTagV2
              v-if="voucherTagData.isShow && voucherTagData.type === 'v2'"
              v-bind="voucherTagData.props"
            />
            <PromotionTag
              v-if="promotionTagData.isShow && (promotionTagData.type === 'v1' || promotionTagData.type === '')"
              v-bind="promotionTagData.props"
              v-on="promotionTagData.events"
            />
            <PromotionTagV2
              v-if="promotionTagData.isShow && promotionTagData.type === 'v2'"
              v-bind="promotionTagData.props"
              v-on="promotionTagData.events"
            />
            <SharePromotionTag
              v-if="sharePromotionTagData.isShow && (sharePromotionTagData.type === 'v1' || sharePromotionTagData.type === '')"
              v-bind="sharePromotionTagData.props"
            />
            <SharePromotionTagV2
              v-if="sharePromotionTagData.isShow && sharePromotionTagData.type === 'v2'"
              v-bind="sharePromotionTagData.props"
            />
            <MorePromotionTag
              v-if="morePromotionTagData.isShow && (morePromotionTagData.type === 'v1' || morePromotionTagData.type === '')"
              v-bind="morePromotionTagData.props"
              v-on="morePromotionTagData.events"
            />
            <MorePromotionTagV2
              v-if="morePromotionTagData.isShow && morePromotionTagData.type === 'v2'"
              v-bind="morePromotionTagData.props"
              v-on="morePromotionTagData.events"
            />
          </div>
          <template
            v-if="lowStockLabelData.isShow && (lowStockLabelData.props ? lowStockLabelData.props.isShow : false ) && lowStockLabelData.position === 'bottom'"
          >
            <ClientOnly>
              <LowStockLabel
                v-bind="lowStockLabelData.props"
              />
            </ClientOnly>
          </template>
          <BehaviorLabel
            v-else-if="behaviorLabelData.isShow && behaviorLabelData.position === 'bottom'"
            class="bsc-cart-item-main__behavior-label"
            v-bind="behaviorLabelData.props"
            v-on="behaviorLabelData.events"
          />
          <GoodsAlert
            v-if="goodsAlertData.isShow"
            v-bind="goodsAlertData.props"
            v-on="goodsAlertData.events"
          />
        </div>
        <div class="bsc-cart-item-main__right-bottom">
          <ReducePriceHint
            v-if="reducePriceHintData.isShow"
            class="bsc-cart-item-main__reduce-price-hint"
            v-bind="reducePriceHintData.props"
            v-on="reducePriceHintData.events"
          />
          <div class="bsc-cart-item-main__right-bottom-above">
            <div class="bsc-cart-item-main__price">
              <GoodsPrice
                v-if="goodsPriceData.isShow"
                v-bind="goodsPriceData.props"
              >
                <template #discount-tag>
                  <DiscountNormalTag
                    v-if="discountNormalTagData.isShow && (discountNormalTagData.type === 'v1' || discountNormalTagData.type === '')"
                    class="bsc-cart-item-main__discount-normal-tag"
                    v-bind="discountNormalTagData.props"
                    v-on="discountNormalTagData.events"
                  />
                  <DiscountNormalTagV2
                    v-if="discountNormalTagData.isShow && discountNormalTagData.type === 'v2'"
                    class="bsc-cart-item-main__discount-normal-tag"
                    v-bind="discountNormalTagData.props"
                    v-on="discountNormalTagData.events"
                  />
                </template>
                <template #top-right>
                  <div class="bsc-cart-item-main__price-top-right">
                    <GoodsRepickButton
                      v-if="goodsRepickButtonData.isShow"
                      v-bind="goodsRepickButtonData.props"
                      class="bsc-cart-item-main__price-repick-button"
                      v-on="goodsRepickButtonData.events"
                    />
                    <GoodsQty
                      v-if="goodsQtyData.isShow"
                      v-bind="goodsQtyData.props"
                      v-on="goodsQtyData.events"
                    />
                    <GoodsDeleteButton
                      v-if="goodsDeleteButtonData.isShow"
                      v-bind="goodsDeleteButtonData.props"
                      v-on="goodsDeleteButtonData.events"
                    />
                    <OneClickBuy
                      v-if="oneClickBuyData.isShow"
                      v-bind="oneClickBuyData.props"
                      v-on="oneClickBuyData.events"
                    />
                    <GoodsQtyText
                      v-if="goodsQtyTextData.isShow && goodsQtyTextData.position === 'bottom'"
                      class="bsc-cart-item-main__title-right"
                      v-bind="goodsQtyTextData.props"
                      v-on="goodsQtyTextData.events"
                    />
                    <AddCart
                      v-if="addCartData.isShow"
                      v-bind="addCartData.props"
                      v-on="addCartData.events"
                    />
                  </div>
                </template>
              </GoodsPrice>
              <GoodsAddOnTip
                v-if="goodsAddOnTipData.isShow"
                v-bind="goodsAddOnTipData.props"
                class="bsc-main-cart__add-on-tip"
                v-on="goodsAddOnTipData.events"
              >
                <template #del-icon>
                  <GoodsDeleteButton
                    v-if="goodsDeleteButtonData.isShow"
                    v-bind="goodsDeleteButtonData.props"
                    v-on="goodsDeleteButtonData.events"
                  />
                </template>
              </GoodsAddOnTip>
            </div>
          </div>
          <div
            class="bsc-cart-item-main__right-bottom-below"
          >
            <DiscountLimitTag
              v-if="discountLimitTagData.isShow && (discountLimitTagData.type === 'v1' || discountLimitTagData.type === '')"
              v-bind="discountLimitTagData.props"
              v-on="discountLimitTagData.events"
            />
            <DiscountLimitTagV2
              v-if="discountLimitTagData.isShow && discountLimitTagData.type === 'v2'"
              v-bind="discountLimitTagData.props"
              v-on="discountLimitTagData.events"
            />
            <EstimatedPrice
              v-if="estimatedPriceData.isShow && (estimatedPriceData.type === 'v1' || estimatedPriceData.type === '')"
              v-bind="estimatedPriceData.props"
              v-on="estimatedPriceData.events"
            />
            <EstimatedPriceV2
              v-if="estimatedPriceData.isShow && estimatedPriceData.type === 'v2'"
              v-bind="estimatedPriceData.props"
              v-on="estimatedPriceData.events"
            />
            <IncentiveReducePrice
              v-if="incentiveReducePriceData.isShow"
              v-bind="incentiveReducePriceData.props"
            />
          </div>
        </div>
        <div
          v-if="findNewsTextData.isShow"
          class="bsc-cart-item-main__right-footer"
        >
          <FindNewsText
            v-if="findNewsTextData.isShow"
            v-bind="findNewsTextData.props"
            v-on="findNewsTextData.events"
          />
        </div>
      </div>
    </div>
    <div class="bsc-cart-item-main__other">
      <GoodsNegativeInfo
        v-if="goodsNegativeInfoData.isShow"
        class="bsc-cart-item-main__negative-info"
        v-bind="goodsNegativeInfoData.props"
      />
      <GoodsCustomContent
        v-if="goodsCustomContentData.isShow"
        class="bsc-cart-item-main__custom-content"
        v-bind="goodsCustomContentData.props"
        v-on="goodsCustomContentData.events"
      />
      <GoodsAlmostEntry
        v-if="goodsAlmostEntryData.isShow"
        class="bsc-cart-item-main__goods-almost-entry"
        v-bind="goodsAlmostEntryData.props"
        v-on="goodsAlmostEntryData.events"
      />
    </div>
  </div>
</template>

<script name="BMainCartItem" setup lang="ts">
import type { Mobile } from '../../../types/mobile'
import { onMountedOrActivated } from '../hooks/onMountedOrActivated'

// cell components
import { GoodsImg, useGoodsImg } from '../cell/goods-img'
import { GoodsDiscountFlag, GoodsDiscountFlagV2, useGoodsDiscountFlag } from '../cell/goods-discount-flag'
import { GoodsBelt, useGoodsBelt } from '../cell/goods-belt'
import { GoodsTitle, useGoodsTitle } from '../cell/goods-title'
import { GoodsCopy, useGoodsCopy } from '../cell/goods-copy'
import { GoodsTitleIcon, useGoodsTitleIcon } from '../cell/goods-title-icon'
import { GoodsSeriesFlag, useGoodsSeriesFlag } from '../cell/goods-series-flag'
import { GoodsPrice, useGoodsPrice } from '../cell/goods-price'
import { AddCart, useAddCart } from '../cell/add-cart'
import { AddMore, useAddMore } from '../cell/add-more'
import { GoodsSaleAttr, useGoodsSaleAttr } from '../cell/goods-sale-attr'
import { GoodsQty, useGoodsGoodsQty } from '../cell/goods-qty'
import { GoodsCustomContent, useGoodsCustomContent } from '../cell/goods-custom-content'
import { GoodsAddWish, useGoodsAddWish } from '../cell/goods-add-wish'
import { GoodsDeleteButton, useGoodsDeleteButton } from '../cell/goods-delete-button'
import { ReducePriceHint, useReducePriceHint } from '../cell/reduce-price-hint'
import { GoodsAlert, useGoodsAlert } from '../cell/goods-alert'
import { IncentiveReducePrice, useIncentiveReducePrice } from '../cell/incentive-reduce-price'
import { GoodsStore, useGoodsStore } from '../cell/goods-store'
import { FindSimilarText, useFindSimilarText } from '../cell/find-similar-text'
import { FindNewsText, useFindNewsText } from '../cell/find-news-text'
import { BehaviorLabel, useBehaviorLabel } from '../cell/behavior-label'
import { LowStockLabel, useLowStockLabel } from '../cell/low-stock-label'
import { LowStockTag, useLowStockTag } from '../cell/goods-tags/low-stock-tag'
import { ChangeMall, useChangeMall } from '../cell/change-mall'
import { OneClickBuy, useOneClickBuy } from '../cell/one-click-buy'
import { GoodsQtyText, useGoodsQtyText } from '../cell/goods-qty-text'
import { QuickShipTag, QuickShipTagV2, useQuickShipTag } from '../cell/goods-tags/quick-ship-tag'
import { FreeShippingTag, FreeShippingTagV2, useFreeShippingTag } from '../cell/goods-tags/free-shipping-tag'
import { EvoluTag, EvoluTagV2, useEvoluTag } from '../cell/goods-tags/evolu-tag'
import { VoucherTag, VoucherTagV2, useVoucherTag } from '../cell/goods-tags/voucher-tag'
import { SFSTag, SFSTagV2, useSFSTag } from '../cell/goods-tags/sfs-tag'
import { PromotionTag, PromotionTagV2, usePromotionTag } from '../cell/goods-tags/promotion-tag'
import { DiscountNormalTag, DiscountNormalTagV2, useDiscountNormalTag } from '../cell/discount-normal-tag'
import { DiscountLimitTag, DiscountLimitTagV2, useDiscountLimitTag } from '../cell/discount-limit-tag'
import { EstimatedPrice, EstimatedPriceV2, useEstimatedPrice } from '../cell/estimated-price'
import { GoodsNegativeInfo, useGoodsNegativeInfo } from '../cell/goods-negative-info'
import { GoodsRepickButton, useGoodsRepickButton } from '../cell/goods-repick-button'
import { GoodsAlmostEntry, useGoodsAlmostEntry } from '../cell/goods-almost-entry'
import { GoodsSoldOutFlag, useGoodsSoldOutFlag } from '../cell/goods-sold-out-flag'
import { GoodsAddOnTip, useGoodsAddOnTip } from '../cell/goods-add-on-tip'
import { SharePromotionTag, SharePromotionTagV2, useSharePromotionTag } from '../cell/share-promotion-tag'
import { MorePromotionTag, MorePromotionTagV2, useMorePromotionTag } from '../cell/goods-tags/more-promotion-tag'
import { HolidayDeliveryTag, HolidayDeliveryTagV2, useHolidayDeliveryTag } from '../cell/goods-tags/holiday-delivery-tag'
import { ClientOnly } from '@sheinfe/vue-client-only'

/**
 * 主车商品行模板
 */

// template hooks
import { useTemplate } from '../hooks/useTemplate'

// emit
const emit = defineEmits(['expose'])

// template props
const props = defineProps<{
  data?: Mobile.Data
  config?: Mobile.Config<Mobile.MainCart>
  language: Mobile.Language<Mobile.MainCart>,
  constant: Mobile.Constant<Mobile.MainCart>,
  use: Mobile.MainCart[],
}>()

// hooks
const {
  // ref
  tempRef,

  // helpers data
  helpersData: [
    goodsImgData,
    goodsDiscountFlagData,
    goodsBeltData,
    goodsTitleData,
    goodsTitleIconData,
    goodsCopyData,
    goodsSeriesFlagData,
    goodsPriceData,
    addCartData,
    addMoreData,
    goodsSaleAttrData,
    goodsQtyData,
    goodsCustomContentData,
    goodsAddWishData,
    goodsDeleteButtonData,
    reducePriceHintData,
    goodsAlertData,
    incentiveReducePriceData,
    goodsStoreData,
    findSimilarTextData,
    findNewsTextData,
    behaviorLabelData,
    changeMallData,
    oneClickBuyData,
    goodsQtyTextData,
    quickShipTagData,
    freeShippingTagData,
    evoluTagData,
    voucherTagData,
    promotionTagData,
    discountNormalTagData,
    discountLimitTagData,
    estimatedPriceData,
    goodsNegativeInfoData,
    goodsRepickButtonData,
    goodsAlmostEntryData,
    goodsSoldOutFlagData,
    sfsTagData,
    goodsAddOnTipData,
    sharePromotionTagData,
    morePromotionTagData,
    lowStockLabelData,
    lowStockTagData,
    holidayDeliveryTagData,
  ],

  // methods
  registerExpose,
} = useTemplate(props, [
  useGoodsImg,
  useGoodsDiscountFlag,
  useGoodsBelt,
  useGoodsTitle,
  useGoodsTitleIcon,
  useGoodsCopy,
  useGoodsSeriesFlag,
  useGoodsPrice,
  useAddCart,
  useAddMore,
  useGoodsSaleAttr,
  useGoodsGoodsQty,
  useGoodsCustomContent,
  useGoodsAddWish,
  useGoodsDeleteButton,
  useReducePriceHint,
  useGoodsAlert,
  useIncentiveReducePrice,
  useGoodsStore,
  useFindSimilarText,
  useFindNewsText,
  useBehaviorLabel,
  useChangeMall,
  useOneClickBuy,
  useGoodsQtyText,
  useQuickShipTag,
  useFreeShippingTag,
  useEvoluTag,
  useVoucherTag,
  usePromotionTag,
  useDiscountNormalTag,
  useDiscountLimitTag,
  useEstimatedPrice,
  useGoodsNegativeInfo,
  useGoodsRepickButton,
  useGoodsAlmostEntry,
  useGoodsSoldOutFlag,
  useSFSTag,
  useGoodsAddOnTip,
  useSharePromotionTag,
  useMorePromotionTag,
  useLowStockLabel,
  useLowStockTag,
  useHolidayDeliveryTag,
])

// methods
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('MainCartItem', callback)
}

onMountedOrActivated(handleExpose)
</script>

<style lang="less">
// 模板左侧高度
@template-left-width: 80/37.5rem;
// 模板左侧高度
@template-left-height: 80/37.5rem;
// 模板左右间距
@template-gap: 10/37.5rem;
// 模板右侧元素上下间距
@template-right-gap: 6/37.5rem;
// 模板背景色
@template-background-color: #fff;

.bsc-cart-item-main {
  &__wrap {
    display: flex;
    flex-direction: column;
    background-color: var(--template-background-color, @template-background-color);
    text-align: left;
    line-height: 1.15;
  }
  &__content {
    display: flex;
  }
  &__left {
    margin-right: var(--template-gap, @template-gap);
    width: var(--template-left-width, @template-left-width);
    height: var(--template-left-height, @template-left-height);
  }
  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: var(--template-left-height, @template-left-height);
    width: 0;
    > * + * {
      margin-top: @template-right-gap;;
    }
  }
  &__right-top {
    display: flex;
  }
  &__right-middle {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
    > * + * {
      margin-top: @template-right-gap;;
    }
  }
  &__right-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 4/37.5rem;
    > * + * {
      margin-top: 3/37.5rem;
    }
    &:empty {
      display: none;
    }
  }
  &__right-bottom-above {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__right-bottom-below {
    display: flex;
    align-items: center;
    &:empty {
      display: none;
    }
  }
  &__right-footer {
    &:empty {
      display: none;
    }
  }
  &__other {
    display: flex;
    flex-direction: column;
    margin-top: @template-right-gap;
    > * + * {
      margin-top: @template-right-gap;;
    }
    &:empty {
      display: none;
    }
  }
  &__title {
    flex: 1;
  }
  &__title-right {
    margin-left: 2/37.5rem;
    flex-shrink: 0;
  }
  &__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -@template-right-gap;
    > * {
      margin-bottom: @template-right-gap;
      margin-right: 6/37.5rem;
    }
    > *:last-child {
      margin-right: 0;
    }
    &:empty {
      display: none;
    }
  }
  &__tips {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__price {
    flex: 1;
    min-width: 0;
  }
  &__price-top-right {
    display: inline-flex;
    align-items: center;
  }
  &__price-repick-button {
    margin-right: 24/75rem;
  }

  &__discount-normal-tag {
    margin-left: 2px;
  }
  &__promotion {
    display: flex;
    gap: 4/37.5rem;
    flex-wrap: wrap;
    align-items: flex-end;
    &:empty {
      display: none;
    }
  }
  &__negative-info {
    margin-left: calc(var(--template-left-width, @template-left-width) + @template-gap);;
  }
}
</style>
