<template>
  <span
    :style="styles"
    :class="classes">
    <slot></slot>
  </span>
</template>

<script>
/**
 * @description 自适应字体大小,缩放到最小值后不再缩放溢出隐藏
 * @example <ResizeFont><ResizeFontText :init-font-size="14" :resize-font-min="10" :resize-font-step="2">内容</ResizeFontText></ResizeFont>
 */
export default {
  name: 'ResizeFont',

  provide() {
    return {
      registerComponent: this.registerComponent,
    }
  },

  props: {
    /**
     * @description 是否回退多行
     */
    isMultiline: {
      type: Boolean,
      default: false,
    },
    /**
     * @description 多行数
     */
    multilineNum: {
      type: Number,
      default: 2,
    },
    /**
     * @description 初始化的时机
     */
    trigger: {
      type: String,
      default: 'Observer',
      validator: val => ['Mounted', 'Observer'].includes(val),
    },
    /**
     * @description 文字对齐方式
     */
    textAlign: {
      type: String,
      default: 'center',
    },
    /**
     * @description 文字换行方式
     */
    wordBreak: {
      type: String,
      default: 'normal',
    },
    /**
     * @description 是否监听内容变化(只支持对比文本内容)
     */
    watchContent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      children: [],
      isOverflowing: -1,
      isResizeDone: false,
      isInited: false,
      innerText: '',
      observer: null,
    }
  },

  computed: {
    classes() {
      return [
        'bsc-cart-item-resize-font',
        this.isResizeDone && this.isMultiline && 'bsc-cart-item-resize-font_multiline',
        this.isResizeDone && !this.isMultiline && 'bsc-cart-item-resize-font_ellipsis',
      ]
    },
    styles() {
      return {
        '--multiline-num': this.multilineNum,
        '--text-align': this.textAlign,
        '--word-break': this.wordBreak,
      }
    },
  },

  mounted() {
    this.handleInit()
  },

  beforeDestroy() {
    this.handleClear()
  },

  methods: {
    /**
     * 注册子组件
     */
    registerComponent(child) {
      this.children.push(child)
    },
    /**
     * 清除
     */
    handleClear(){
      this.children = []
      this.observer && this.observer.disconnect()
      this.observer = null
    },
    /**
     * 初始化
     */
    handleInit() {
      if (this.trigger === 'Mounted') {
        this.onMounted()
      } else {
        this.onObserver()
      }
    },

    /**
     * 内容更新
     */
    onUpdate() {
      const currText = this.$el.innerText
      if (this.isInited && currText !== this.innerText && this.watchContent) {
        this.innerText = currText
        this.isResizeDone = false
        this.children.forEach(child => {
          child.resetFontSize()
        })
        this.$nextTick(() => this.handleResizeFontSize())
      }
    },

    /**
     * 挂载时时初始化
     */
    onMounted() {
      this.handleResizeFontSize()
      this.isInited = true
      this.innerText = this.$el.innerText
    },

    /**
     * 视口初始化
     */
    onObserver() {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.handleResizeFontSize()
            this.isInited = true
            this.innerText = this.$el.innerText
            this.observer.disconnect()
          }
        })
      })
      this.observer.observe(this.$el)
    },

    /**
     * 处理自适应字体大小
     */
    handleResizeFontSize(el = this.$el) {
      this.isOverflowing = el.clientWidth < el.scrollWidth
      // 溢出状态
      if (this.isOverflowing) {
        if (!this.getResizeDone()) {
          this.resizeFontSize()
          this.$nextTick(() => this.handleResizeFontSize())
        } else {
          this.isResizeDone = true
        }
      }
    },

    /**
     * 调用当前子组件的 resizeFontSize 方法
     */
    resizeFontSize() {
      this.children.forEach(child => {
        child.resizeFontSize()
      })
    },

    /**
     * 是否缩放完毕
     */
    getResizeDone() {
      return this.children.every(child => child.isResizeDone)
    },
  },
}
</script>

<style lang="less">
.bsc-cart-item-resize-font {
  display: block;
  text-align: var(--text-align);
  overflow: hidden;
  white-space: nowrap;
  &_multiline {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: var(--multiline-num, 2);
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: var(--word-break);
  }
  &_ellipsis {
    text-overflow: ellipsis;
  }
}
</style>
