<template>
  <div
    v-show="!rootStatus.exchangingSpaScene"
    id="app"
  >
    <TopBanner
      v-show="showTopBanner"
      style="position: relative; z-index: 2;"
      :channelId="channelId"
      :topTabList="topTabList"
      @cccBannerHide="handleTopBannerHide"
      @cccBannerShow="handleTopBannerShow"
    />
    <component
      :is="getHeaderRenderComponent()"
      :triggerHydration="isTriggerAppHeaderHydration"
      :when-triggered="isTriggerAppHeaderHydration"
      :mount-prop-name="'mount'"
    >
      <AppHeader
        v-show="showAppHeader"
        :mount="triggerLazyMount"
        :showAppHeader="showAppHeader"
        :cccSkinHeaderLogo="cccSkinHeaderLogo"
        :commonHeight="commonHeight"
        :commonHeaderBg="commonHeaderBg"
        :showImmersiveBanner="showImmersiveBanner"
        :isAddCommonHeaderClassName="isAddCommonHeaderClassName"
        :messageViewMode="messageViewMode"
        @changeHomeImmersiveInfo="changeHomeImmersiveInfo"
      />
    </component>

    <div id="prerender-in-app"></div>
    <div
      v-show="!rootStatus.exchangingSpaSceneInApp"
      id="in-app"
    >
      <keep-alive>
        <router-view
          v-if="$route.meta && $route.meta.keepAlive"
          :key="$route.name"
        />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
      <client-only>
        <similar-product-vue v-if="rootStatus.importSimilarVue" />

        <lazy-mount
          v-if="rootStatus"
          mount-prop-name="show"
        >
          <s-loading
            class="root-loading"
            :show="rootStatus.loading"
            type="curpage"
          />
        </lazy-mount>

        <!--
          可用以下 root mutations 变更状态, 参数格式同组件
          containerCoverStyle 和 curPageMaskTopBoundSelector 在loading关闭后自动恢复默认
          this.changeRootSLoadingStatus({
            show: false,
            containerCoverStyle: {}, // 可选
            curPageMaskTopBoundSelector: '' // 可选
            type: 'curpage',
            curPageScrollFix: true, // 滚动条是否可滚动 默认禁止滚动
          })
        -->

        <lazy-mount
          v-if="rootStatus"
          mount-prop-name="show"
        >
          <s-loading
            :show="rootSLoading.show"
            :container-cover-style.sync="rootSLoading.containerCoverStyle"
            :cur-page-mask-top-bound-selector.sync="rootSLoading.curPageMaskTopBoundSelector"
            :type="rootSLoading.type"
            :cur-page-scroll-fix="rootSLoading.curPageScrollFix"
            :mask-transparent="rootSLoading.maskTransparent"
          />
        </lazy-mount>
      </client-only>
      <component
        :is="getFooterRenderComponent()"
        mount-prop-name="mount"
      >
        <AppFooter 
          v-show="showAppFooter" 
          :mount="lazyFooter"
        />
      </component>
    </div>
  </div>
</template>
<script>
import ClientOnly from 'vue-client-only'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { install } from 'public/src/pages/common/similar_product_modal/index.js'
import { useLoading } from 'public/src/pages/common/requestLoading.js'
import { installAppBranch } from 'public/src/pages/common/banner/exampleBanner.js'
import ControlHeader from 'public/src/pages/common/banner/controlHeader.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { LazyMount } from '@shein/sui-mobile'
import { LazyHydrationWrapper } from '@shein-aidc/utils-lazy-hydration'
import AppFooter from './footer/index.vue'
import AppHeader from 'public/src/pages/components/app_header/index.vue'
import ClientRender from 'public/src/pages/components/app_header/components/ClientRender.vue'
import SILog from 'public/src/pages/common/monitor/index'
import { reportPerformance2Sls } from 'public/src/pages/common/performance/sls.js'
import { BTopBanner as TopBanner } from '@shein-aidc/bs-top-banner-mobile-vue2'
import { parseQueryString, getCookie } from '@shein/common-function'
import { useProxyHosts } from '@shein-aidc/bs-sdk-libs-manager'

const emptyWrap = {
  functional: true,
  render: (h, context) => {
    return context.children
  }
}

export default {
  name: 'ProductApp',
  components: {
    ClientOnly,
    LazyMount,
    LazyHydrationWrapper,
    AppFooter,
    AppHeader,
    similarProductVue: () => import(/* webpackChunkName: "similar-product" */ './../common/similar_product_modal/similar_product_modal.vue'),
    TopBanner,
    ClientRender,
  },
  data () {
    return {
      history: typeof history !== 'undefined' ? history : undefined,
      showCategory: false,
      appHeaderHydration: false,
      triggerLazyMount: false,
      lazyFooter: false,
      headerReaderComponentName: null,
      footerReaderComponentName: null
    }
  },
  computed: {
    // ...mapState(['redpoint', 'commonHeaderBg', 'commonHeight']),
    ...mapState(['pageTriggerAppHeaderHydration', 'rootStatus', 'cccSkinHeaderLogo', 'rootSLoading', 'commonHeaderBg', 'showImmersiveBanner', 'commonHeight', 'isAddCommonHeaderClassName', 'messageViewMode']),
    ...mapGetters('config_index', ['channelId', 'topTabList']),
    // showBanner() {
    //   return this.$route.name
    //     ? this.$route.name != 'config_index'
    //     : (typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.contextForSSR?.ssrPageType !== 'config_index')
    // }
    showAppHeader() {
      return this.getShowAppHeader(this.$route)
    },
    showAppFooter() {
      // return true
      const showSSRFooter = ['config_index', 'page_category', 'UserIndex']
      const route = this.$route
      const isNewTab = route.query.from_tabbar_new === '1' && route.name === 'campaigns'
      return showSSRFooter.includes(route.name) || isNewTab
    },
    showTopBanner() {
      return this.handleTopBannerShowByPage(this.$route)
    },
    isTriggerAppHeaderHydration({ appHeaderHydration }) {
      const routeName = ['page_goods_detail'] // 自定义水合时机的页面
      if (routeName.includes(this.$route.name)) {
        return this.pageTriggerAppHeaderHydration
      }
      return appHeaderHydration
    }
  },
  watch: {
    $route (to, from) {
      this.$nextTick(() => {
        if (from.query.from === 'popupScanResult' && window.appRouteExtData.direction === -1) {
          appEventCenter.$emit('openSearchAndPopupScanResult')
        }
        // if (from.query.from === 'popupScanResult' && window.appRouteExtData.direction !== -1 && !from.meta?.cameraFrom) {
        //   appEventCenter.$emit('hiddenMiddlePage')
        // }

        if (from.query.ici && from.query.ici.indexOf('CameraSearch') > -1 && window.appRouteExtData.direction === -1) {
          appEventCenter.$emit('openMiddlePage')
        }
        if (from.query.ici && from.query.ici.indexOf('CameraSearch') > -1 && window.appRouteExtData.direction !== -1) {
          appEventCenter.$emit('hiddenMiddlePage')
        }
      })
    },
  },
  async mounted () {
    const self = this
    // 判断当前页面是否属于营销来源
    const { url_from = '', aff_id = '', onelink = '', scene = '', page_version = '' } = parseQueryString(location.search)
    const isMarketing = url_from || aff_id || onelink || scene === 'onelink'
    const cid = getCookie('cookieId') || ''
    this.appHeaderClientRender()
    window.fspSDK.updateOptions({
      reporterHandler({ name, value, payload = {} } = {}) {
        if (!name || window.isClientBot) {
          return
        }

        const resource = payload.resource || ''
        let pageName = window?.SaPageInfo?.page_name || ''
        const PRODUCT_LIST_NAMES = ['page_select_class', 'page_real_class']
        if (!PRODUCT_LIST_NAMES.includes(pageName)) { 
          // 非商品列表页，清空 SPA_PAGE_TYPE_NAME
          window.__FSP_LIST_PAGE_FROM_NAME__ = ''
        }

        if (self.$route?.name === 'campaigns' && value > 3000) {
          try {
            const fileds = SILog.getFields()
            SILog.SIMetric.metricCount({
              metric_name: 'activity_long_fsp_total',
              tags: {
                site: window?.gbCommonInfo?.SiteUID || '',
                identity: self.$route?.params?.identity || '',
                pathname: location.pathname,
                os_name: fileds?.os_name || '',
                device_model: fileds?.device_model || '',
                fspValue: value,
              },
            }, {
              immediate: true,
            })
          } catch (error) {
            console.log('error', error)
          }
        }
        // 自定义场景，登录弹窗 dialog_login
        let customSceneName = ''
        if (resource === 'custom-scene' && payload?.customSceneContainer) {
          let customSceneContainer = payload?.customSceneContainer
          if (customSceneContainer instanceof Element || customSceneContainer instanceof HTMLDocument) {
            customSceneContainer = customSceneContainer.getAttribute('data-customSceneName')
          }
          if (customSceneContainer && typeof customSceneContainer === 'string') {
            customSceneName = customSceneContainer
          }
        }

        let pageFrom = isMarketing ? 'Marketing' : 'Home'
        if (window.__FSP_LIST_PAGE_FROM_NAME__) {
          pageFrom = window.__FSP_LIST_PAGE_FROM_NAME__
          window.__FSP_LIST_PAGE_FROM_NAME__ = ''
        }
        const pageVersion = page_version || 'Other'
        const renderType = resource === 'ssr-landing-page' ? 'ssr' : 'spa'

        const data = {
          resource,
          page_from: pageFrom,
          page_version: pageVersion,
          render_type: renderType,
          data: [
            {
              key_path: name,
              values: {
                // 弹窗组件部分有更新,默认弹出时间为 150ms
                num: customSceneName === 'dialog_login' ? value + 150 : value
              }
            },
          ],
          // 1. 固定 page_name 防止上报时 page_name 已经被篡改
          ...(pageName ? { page_name: pageName } : {}),
          // 2. 弹窗类型 替换 page_name 为 弹窗对应的 name
          ...(customSceneName ? { page_name: customSceneName } : {}),
        }

        // 上报 SLS
        const { elementClassName = '' } = payload || {}
        reportPerformance2Sls({
          config: {
            siteUID: window?.gbCommonInfo?.SiteUID,
            performanceMetricSls: window?.gbCommonInfo?.PERFORMANCE_METRIC_SLS,
          },
          fspOptions: {
            pageName,
            renderType,
            fsp: value,
            fspClass: elementClassName || '',
            pageFrom,
            cid,
          }
        })

        // 通过 TPM 上报 UEM
        window.TPM?.run({
          marketing: 'ClientAnalytics',
          method: '_defineTrack',
          params: {
            data,
            options: {
              random: 1,
              immediate: true,
            },
          }
        })
      }
    })

    window.fspSDK.observeRouteChange(this.$router)

    this.installHeaderDom()

    if (this.$route && this.$route.meta && this.$route.meta.monitorTag && typeof window !== 'undefined') {
      window.pageTypeForMonitor = this.$route.meta.monitorTag
    }
    // 【not delete】fps automated testing
    window.appVueInstance = this

    install(() => {
      this.changeRootStatus({
        importSimilarVue: true
      })
    })
    this.prefetchResources()
  },

  created() {
    useLoading(({ show, type, containerCoverStyle = {}, curPageScrollFix = true, maskTransparent = false }) => {
      this.changeRootSLoadingStatus({ show, type, containerCoverStyle, curPageScrollFix, maskTransparent })
    })
  },

  methods: {
    ...mapActions(['fetchIndividuation']),
    ...mapMutations(['changeRootStatus', 'changeRootSLoadingStatus', 'changeHomeImmersiveInfo', 'changeNewTopBannerStatus']),
    getHeaderRenderComponent(){
      if (this.headerReaderComponentName !== null) {
        return this.headerReaderComponentName
      }
      const showAppHeader = this.getShowAppHeader(this.$route)
      this.headerReaderComponentName = showAppHeader ? 'LazyHydrationWrapper' : 'ClientRender'
      return this.headerReaderComponentName
    },
    getFooterRenderComponent(){
      // 懒水合在低端机上出现 csr与ssr不一致的情况下会导致底部组件水合失败无法点击
      if (this.footerReaderComponentName) {
        return this.footerReaderComponentName
      }
      this.footerReaderComponentName = this.showAppFooter ? emptyWrap : 'ClientRender'
      return this.footerReaderComponentName
    },
    getShowAppHeader(route) {
      const mainPage = [
        'config_index',
        'page_category',
        'product-list-v2',
        'search-product-list',
        'shein-picks',
        'page_coupon_product',
        'picked-info-list',
        'page_goods_detail',
        'config_activity',
        'page_flash_sale',
        'flash_sale',
        'feedback-rec-list',
        'campaigns',
        'page_advertising',
        'wishlistGroupShare',
        'page_store',
      ]
      const isActivityDesignerPage = route.name === 'config_activity' && route.path?.indexOf('designer') >= 0
      return mainPage.includes(route.name)
        && !isActivityDesignerPage
        && !route.meta?.notNeedCommonHeader
    },
    appHeaderClientRender() {

      if(!this.showAppFooter){
        setTimeout(() => {
          this.lazyFooter = true
        }, 1000)
      }
      
      if (this.showAppHeader) {
        // 有头的页面进行懒水合，尽量在客户端网络请求前完成，避免页面水合不一致
        setTimeout(() => {
          this.appHeaderHydration = true
          // 懒水合成功后触发下埋点相关操作
          setTimeout(() => {
            useProxyHosts().CommonHeader?.trigger('pageOnload')
          })
        }, 10)
      } else {
        // 无头的页面进行懒挂载
        setTimeout(() => {
          this.triggerLazyMount = true
        }, 1000)
      }
    },
    handleTopBannerShowByPage(route) {
      const pageShow = ['index', 'productDetail', 'category', 'productList', 'page_store', 'activity', 'flash_sale', 'PTRecProductList', 'PTFeedbackRecList', 'campaigns'].includes(route.meta.pageType) || ['wishlistGroupShare'].includes(route.name)
      return pageShow
    },
    handleTopBannerHide() {
      this.changeNewTopBannerStatus({ hasNewTopBanner: false })
    },
    handleTopBannerShow() {
      this.changeNewTopBannerStatus({ hasNewTopBanner: true })
    },
    installHeaderDom() {
      // 只控制页面的展示，不控制页面的逻辑
      const controlHeader = new ControlHeader({
        components: [ // 顺序和渲染的顺序保持一致。
          {
            displayDom: '#branch-app', // 切换展示dom
            install: async () => {
              return installAppBranch({ router: this.$router, store: this.$store, app: this })
            },
            onCheck: () => {
              return true // 组件内部自己控制
            }
          },
        ],
      })
      controlHeader.init(this.$route)
      const unwatch = this.$watch('$route', (to) => {
        controlHeader.update(to)
      })
      // 在销毁组件前调用取消监听函数，防止内存泄漏
      this.$once('hook:beforeDestroy', unwatch)
    },
    prefetchResources() {
      if (this.$route.name === 'cart') return
      // el 必须是渲染完的
      requestIdleCallback(() => {
        prefetchResource.listen({
          el: this.$el,
          prefetchList: [
            {
              chunkName: 'cart_index_new',
              relType: 'prefetch'
            },
            {
              chunkName: 'cart_header_checkout',
              relType: 'prefetch'
            },
            {
              chunkName: 'cart_bottom_content',
              relType: 'prefetch'
            },
            {
              chunkName: 'pre_search',
              relType: 'prefetch'
            },
            {
              chunkName: 'plv2_container',
              relType: 'prefetch'
            },
            {
              chunkName: 'user-index',
              relType: 'prefetch'
            },
            {
              chunkName: 'cartv2_index',
              relType: 'prefetch'
            },
            {
              chunkName: 'cartv2_bottom_content',
              relType: 'prefetch'
            },
          ],
          delay: 2000, // 默认两秒
          prefetchCallback: () => {
            setTimeout(() => {
              const loadComponent = prefetchResource.importAsyncComponent({
                chunkName: 'plv2_container', // 跟webpackChunkName保持一致
                componentFactory: () => import( /* webpackChunkName: 'plv2_container' */ 'public/src/pages/product_list_v2/container.vue'),
              })
              loadComponent?.()
            }, 1000)
          },
        })
      })
    },
  }
}
</script>

<style lang="less">
// 详情页找相似 or 快速加车找相似上面的 售罄文案
.soldout-warning {
  padding: .213333rem .32rem;
  line-height: 1.2;
  background-color: #FFF1D5;
  .font-dpr(24px);
  color: #222;
  text-align: center;
  /* rw:begin */
  background-color: @sui_color_black;
  color: @sui_color_white;
  // 详情页
  &.goods-detial {
    position: absolute;
    width: 100%;
    top: 0;
    transform: translateY(-100%);
    /* rtl:begin:ignore */
    left: 0;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .28s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

// 触觉反馈优化 当前只针对shein
[brd=sh] .haptics-feedback:active {
  background: #F6F6F6;
}
</style>
