import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

const StorageKeys = {
  CLOSE_POPOVER: 'cartPriceChangeClosePopover',
  SESSION_CLOSE_POPOVER: 'cartPriceChangeSessionClosePopover',
  SHOW_POPOVER_COUNT: 'cartPriceChangeShowPopoverCount',
}

const useStorage = (isSession = false) => {
  if (typeof window === 'undefined') return {}
  const storage = isSession ? window.sessionStorage : window.localStorage 

  return {
    set: (key, value) => storage.setItem(key, value),
    get: (key) => storage.getItem(key),
  }
}

const createCacheHandler = (storage) => ({
  set: (key, value) => storage.set(key, value),
  get: (key, parseToType) => {
    const raw = storage.get(key)
    if (parseToType === 'boolean') return raw === 'true'
    if (parseToType === 'number') return Number.isNaN(Number(raw)) ? 0 : Number(raw)
    return raw
  },
})

const LocalStorageCache = createCacheHandler(useStorage(false))
const SessionStorageCache = createCacheHandler(useStorage(true))

export function usePriceChangeCache() {
  const { state, commit } = useStore()

  const showPopoverCount = computed(() => state.priceChangeState.showPopoverCount)
  const sessionClosePopover = computed(() => state.priceChangeState.sessionClosePopover)
  const closePopover = computed(() => state.priceChangeState.closePopover)

  const updateStateAndCache = ({ key, value, mutationKey, storage }) => {
    commit('cart_v2/updatePriceChangeState', { [mutationKey]: value })
    storage.set(key, value)
  }

  const initStateFromCache = ({ key, mutationKey, parseToType, storage }) => {
    const value = storage.get(key, parseToType)
    commit('cart_v2/updatePriceChangeState', { [mutationKey]: value })
  }

  // Methods
  const updatePriceChangeShowPopoverCount = (value) =>
    updateStateAndCache({
      key: StorageKeys.SHOW_POPOVER_COUNT,
      value,
      mutationKey: 'showPopoverCount',
      storage: LocalStorageCache,
    })

  const updatePriceChangeClosePopover = (value) =>
    updateStateAndCache({
      key: StorageKeys.CLOSE_POPOVER,
      value,
      mutationKey: 'closePopover',
      storage: LocalStorageCache,
    })

  const updatePriceChangeSessionClosePopover = (value) =>
    updateStateAndCache({
      key: StorageKeys.SESSION_CLOSE_POPOVER,
      value,
      mutationKey: 'sessionClosePopover',
      storage: SessionStorageCache,
    })

  const initPriceChangeShowPopoverCount = () =>
    initStateFromCache({
      key: StorageKeys.SHOW_POPOVER_COUNT,
      mutationKey: 'showPopoverCount',
      parseToType: 'number',
      storage: LocalStorageCache,
    })

  const initPriceChangeClosePopover = () =>
    initStateFromCache({
      key: StorageKeys.CLOSE_POPOVER,
      mutationKey: 'closePopover',
      parseToType: 'boolean',
      storage: LocalStorageCache,
    })

  const initPriceChangeSessionClosePopover = () =>
    initStateFromCache({
      key: StorageKeys.SESSION_CLOSE_POPOVER,
      mutationKey: 'sessionClosePopover',
      parseToType: 'boolean',
      storage: SessionStorageCache,
    })

  return {
    // state
    showPopoverCount,
    sessionClosePopover,
    closePopover,
    // methods
    updatePriceChangeShowPopoverCount,
    updatePriceChangeClosePopover,
    updatePriceChangeSessionClosePopover,
    initPriceChangeShowPopoverCount,
    initPriceChangeClosePopover,
    initPriceChangeSessionClosePopover,
  }
}
