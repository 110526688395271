import { ref } from 'vue'
import { throttle } from '@shein/common-function'
import { useResizeHeight } from './useResizeHeight'
import { useEventListener } from './useEventListener'

const topSpace = 12
const bottomSpace = 32

export const useItemViewportDisplay = ({ cartItemWrapClassname, disabled } = {}) => {
  const isViewportDisplay = ref(false)

  const { height: headerHeight } = useResizeHeight({
    el: '.j-cart-header-wrap',
    onHeightChange: () => {
      calcDisplay()
    },
  })

  const { height: footerHeight } = useResizeHeight({
    el: '.j-cart-footer',
    onHeightChange: () => {
      calcDisplay()
    },
  })

  let target = null

  const calcDisplay = () => {
    if (disabled?.value) return
    if (!cartItemWrapClassname.value) return
    if (!target) target = document.querySelector(cartItemWrapClassname.value)
    if (!target || !document.contains(target)) {
      target = null
      return
    }
    const rect = target.getBoundingClientRect()
    if (rect.top - 44 - headerHeight.value < topSpace) return isViewportDisplay.value = false
    const hasPromoPopover = document.querySelector('.j-promo-popover')
    let promoPopoverHeight = 0
    if (hasPromoPopover) promoPopoverHeight = hasPromoPopover.offsetHeight + 10
    if (window.innerHeight - footerHeight.value - promoPopoverHeight - rect.top < bottomSpace + promoPopoverHeight) return isViewportDisplay.value = false
    isViewportDisplay.value = true
  }
  const scrollHanlder = throttle({
    func() {
      calcDisplay()
    },
    wait: 300,
    options: {
      leading: false,
      trailing: true,
    },
  })

  useEventListener('scroll', () => {
    scrollHanlder()
  }, {
    target: typeof window !== 'undefined' ? window : undefined,
    passive: true,
  })

  return {
    isViewportDisplay,
    calcDisplay,
  }
}
