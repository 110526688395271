<template>
  <div
    ref="marqueeRef"
    class="marquee"
    :style="style"
  >
    <div class="marquee-content">
      <slot :start="start"></slot>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, nextTick, computed, defineExpose, onUnmounted } from 'vue'
const { GB_cssRight } = gbCommonInfo

const props = defineProps({
  speed: { // 每帧移动1px（常规浏览器每秒60帧）
    type: Number,
    default: 1,
  },
  forced: { // 强制跑马灯效果，如果内容不足会copy元素
    type: Boolean,
    default: false,
  },
  manual: {
    type: Boolean,
    default: false,
  },
  gap: {
    type: String,
    default: '36px',
  }
})

const marqueeRef = ref(null)
const intervel = ref(null)
const moveLeft = ref(0)

const style = computed(() => {
  return {
    '--gap-val': props.gap,
    '--move-val': `${GB_cssRight ? '' : '-'}${moveLeft.value}px`,
  }
})

onMounted(() => {
  if (props.manual) return
  start()
})

const start = () => {
  nextTick(() => {
    initMarquee(marqueeRef.value, props.speed)
  })
}

const initMarquee = (selector, speed) => {
  const parentSelector = selector
  const cloneEle = parentSelector.innerHTML
  const firstEle = parentSelector.children[0]

  const childWidth = firstEle.clientWidth
  const parentWidth = parentSelector.clientWidth
  if (childWidth < parentWidth) {
    if (!props.forced) return
    const insertCount = (Math.floor(parentWidth / childWidth) || 0) * 2
    for (let i = 0; i < insertCount; i++) {
      parentSelector.insertAdjacentHTML('beforeend', cloneEle)
    }
  } else {
    parentSelector.insertAdjacentHTML('beforeend', cloneEle) // 复制用于跑马灯无缝连接的效果
  }

  intervel.value?.cancel()
  intervel.value = customeInterval(function () {
    if (moveLeft.value > firstEle.clientWidth) {
      moveLeft.value = 0
    }
    moveLeft.value += speed
  }, 0)
}

const customeInterval = (callback, interval) => {
  let start = null //开始时间
  let frameId
  const loop = (timestamp) => {
    if (!start) start = timestamp // 记录开始时间
    const elapsed = timestamp - start // 计算经过的时间
    if (elapsed >= interval) {
      callback() // 执行回调
      start = timestamp // 重置开始时间
    }
    frameId = requestAnimationFrame(loop) // 请求下一帧
  }
  frameId = requestAnimationFrame(loop) // 启动循环

  return {
    cancel: () => {
      cancelAnimationFrame(frameId)
    }
  }
}

onUnmounted(() => {
  intervel.value?.cancel()
})

defineExpose({
  start
})

</script>
<style lang="less" scoped>
.marquee {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
 .marquee-content {
    height: 100%;
    width: initial;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 auto;
    flex-shrink: 0;
    overflow: hidden;
    transform: translateX(var(--move-val));
    &:not(:last-of-type) {
      padding-right: var(--gap-val);
    }
 }
}
</style>
