import { promiseAsyncAuto } from '../asyncAuto.js'
import { getFeedbackRecGenerateBffParams } from '../bffParamsHandle/index.js'
import {
  goodsApiCache,
  feedbackRecFilterApiCache,
  feedbackRecCloudTagApiCache,
} from '../api/bffApi.js'
import { generateRealSelectBffFilterInfo } from '../utils'
import { getCccFeedbackData } from './common.js'

const getFeedbackRecRequestQueue = ({ requestInfo, newSrcTabPageId }) => {
  const { goodsParams, filterParams, cloudTagParams } = getFeedbackRecGenerateBffParams(requestInfo, newSrcTabPageId)
  const queueObj = {
    // 负反馈
    ...getCccFeedbackData(),
    // 商品列表
    goods: goodsApiCache.request({
      type: 'feedbackRec',
      params: goodsParams,
      method: 'GET',
    }),
    // 筛选
    filterData: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, {})
      const result = await promiseAsyncAuto({
        bffCateAttrResult: feedbackRecFilterApiCache.request({ params: filterParams }), // 分类/属性/tsp筛选
        bffCloudTagResult: feedbackRecCloudTagApiCache.request({ params: cloudTagParams }), // 标签云
      })
      cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
    }
  }
  return queueObj
}

export {
  getFeedbackRecRequestQueue
}
