/**
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderInfoSummary} SbcPlaceholderInfoSummary
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderDataItem} SbcPlaceholderDataItem
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderMetaData} SbcPlaceholderMetaData
 */

import { DEFAULT_SBC_ENTRY_IMAGE_REFRESH_GAP } from '@modules/homepage/ccc-stuff/sbc/constants'

/**
 * 初始化 SBC 基本信息摘要
 * 
 * @param {{
 *   compMetaData: import('vue').ComputedRef<SbcPlaceholderMetaData>,
 *   itemsDataList: import('vue').ComputedRef<SbcPlaceholderDataItem[]>,
 * }} params
 */
export function getSbcInfoSummary({
  compMetaData,
  itemsDataList,
}) {
  const {
    sbcAutoSlide,
    sbcSlideSpeed,
    sbcDistribution,
    sbcFontSize,
    sbcFontThickness,
    sbcIconImageShape,
    sbcLines,
    sbcSpacing,
    sbcStroke,
    sbcIconRefreshCnt = '40'
  } = compMetaData.value.contentExtra || {}

  /** @type {SbcPlaceholderInfoSummary} */
  const summary = {}

  summary.titleText = compMetaData.value.titleText || ''
  summary.fontSize = sbcFontSize || '11'
  summary.fontThickness = sbcFontThickness || 'Regular'
  summary.textLines = sbcLines || '2'
  summary.stroke = sbcStroke || 'false'

  let rowCount = compMetaData.value?.rows || 1
  const listLength = itemsDataList.value.length

  // 设置列数
  if (sbcDistribution === 'N') {
    // 按 N 字型排列，列数为 总数除以行数
    summary.columnCount = Math.ceil(listLength / rowCount)
  } else {
    /** @deprecated 根据版本迭代计划已经废弃在前端计算 z 排布 */
    // 按 Z 字形式排布，sbcDistribution 的值为数多少个折行: '4' | '5' ...
  }

  // 设置行数
  summary.rowCount = rowCount

  // 设置排布方式
  summary.distribution = sbcDistribution || '4'

  // 设置一行几列样式
  summary.spacing = sbcSpacing || '4.7'

  // 设置是否自动滑动
  summary.isAutoSlide = sbcAutoSlide && (
    typeof sbcAutoSlide === 'boolean'
      ? sbcAutoSlide
      : typeof sbcAutoSlide === 'string'
        ? sbcAutoSlide !== 'false'
        : false
  )

  // 设置自动滑动delay
  summary.sbcAutoSlide = sbcAutoSlide

  // 设置自动滑动速度
  summary.sbcSlideSpeed = sbcSlideSpeed

  // 设置图片形状
  summary.imageShape = sbcIconImageShape || 'circle'

  // 设置入口图刷新频率时间间隔
  const iconRefreshTimeGap = Number(sbcIconRefreshCnt)
  summary.iconRefreshTimeGap = (
    Number.isNaN(iconRefreshTimeGap)
      ? DEFAULT_SBC_ENTRY_IMAGE_REFRESH_GAP
      : iconRefreshTimeGap
  )

  return summary
}
