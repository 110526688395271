import { ref, computed } from 'vue'
import { deriveFromVueProps } from './miscs'
import { getSbcInfoSummary } from './summary'
import { reportSbcHidden } from '@modules/homepage/ccc-stuff/sbc/report-sbc-hidden'
import { distributeItemsToSlides } from '@modules/homepage/ccc-stuff/sbc/distribute'
import { useSbcSwiper } from '@modules/homepage/ccc-stuff/sbc/use-swiper'
import { useRootStyle } from './miscs'
import { useSbcEntryFollowAction } from './entry-follow-action'
import { GLOBAL_SBC_SWIPER_COUNTER } from '@modules/homepage/ccc-stuff/sbc/constants'

/**
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderInfoSummary} SbcPlaceholderInfoSummary
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderDataItem} SbcPlaceholderDataItem
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderMetaData} SbcPlaceholderMetaData
 */

export function useSbcPlaceholderStore(vueProps) {
  const {
    propDataFromProps: contentData,
    indexFromProps: componentIndexInPage,
    sceneDataFromCommonProps,
    compMetaData,
    itemsDataList,
  } = deriveFromVueProps(vueProps)

  /** 是否展示组件 */
  const isShow = ref(true)
  if (itemsDataList.value.length < 5) {
    // 不满一行，不显示本组件
    isShow.value = false
    reportSbcHidden({
      vuePropsPropData: contentData,
      vuePropsSceneData: sceneDataFromCommonProps,
      vuePropsIndex: componentIndexInPage,
    })
  }

  /** 图片样式 */
  const imageStyle = computed(() => ({
    backgroundColor: compMetaData.value.transparentBackgroundColor || '#F0EEEC',
  }))

  /** 腰带样式 */
  const beltStyle = computed(() => ({
    backgroundColor: compMetaData.value.beltBackgroundColor || '#FFBD58',
    color: compMetaData.value.beltTextColor || '#000000',
  }))
  const isShowBelt = computed(() => Boolean(compMetaData.value.showBelt === '1'))

  /** 组件根元素样式 */
  const rootStyle = useRootStyle({ compMetaData })

  /** 
   * 入口单元格数据
   * @type {import('vue').Ref<SbcPlaceholderDataItem[][]>}
   */
  const slidesData = ref([])

  /**
   * SBC 占位符组件 重要信息摘要 
   * @type {import('vue').Ref<SbcPlaceholderInfoSummary>} 
   * */
  const sbcInfoSummary = ref({
    rowCount: 1,
    columnCount: 4,
    distribution: '4',
    spacing: '4.7',
    fontSize: '11',
    fontThickness: 'Regular',
    textLines: '2',
    isAutoSlide: false,
    sbcSlideSpeed: 20,
    sbcAutoSlide: false,
    imageShape: 'circle',
    iconRefreshTimeGap: 40, // 单位：秒
  })

  // ... 其他状态杂项
  const isFirstPage = computed(() => (
    Boolean(contentData.value?.isFirstPage)
  ))
  const lineClampClass = computed(() => {
    const { textLines } = sbcInfoSummary.value
    return (
      textLines === '3'
        ? 'three-lines'
        : 'two-lines'
    )
  })
  const cateNameStyle = computed(() => {
    const {
      cateNameColor,
      iconBottomColor,
    } = compMetaData.value
    const {
      fontSize = '11',
      fontThickness = 'Regular',
    } = sbcInfoSummary.value

    return {
      fontSize: `${fontSize}px`,
      fontWeight: fontThickness === 'Medium' ? '500' : '400',
      color: cateNameColor,
      iconBottomColor,
    }
  })
  const gridCount = computed(() => {
    const {
      sbcDistribution,
      rowCount,
    } = sbcInfoSummary.value

    if (
      sbcDistribution === 'N'
    ) {
      // N 字型排列，不需要计算 grid
      return
    }

    const oneGridCols = sbcDistribution ? Number(sbcDistribution) : 4
    return Math.ceil(itemsDataList.value.length / (rowCount * oneGridCols))
  })

  // #region 初始化数据

  // -- 1. 初始化 SBC 信息
  sbcInfoSummary.value = getSbcInfoSummary({
    compMetaData,
    itemsDataList,
  })

  // -- 2. 接着处理好 slidesData 给 Vue 渲染 swiper slide 时排布
  const distributedSlides = distributeItemsToSlides({
    sbcInfoSummary,
    itemsDataList,
  })
  slidesData.value = distributedSlides

  // -- 3. 从排布好的 swiper slide 切出初始渲染数据，默认是 5 个
  /** 
   * swiper 虚拟渲染数据
   * @type {import('vue').Ref<SbcPlaceholderDataItem[][]>
   * */
  const virtualData = ref([...slidesData.value.slice(0, 5)])

  // -- 4. 再完成 Swiper 配置
  GLOBAL_SBC_SWIPER_COUNTER.count += 1
  const swiperId = GLOBAL_SBC_SWIPER_COUNTER.count
  const swiperComposables = useSbcSwiper({
    swiperId,
    sbcInfoSummary,
    sceneData: sceneDataFromCommonProps,
    distributedSlides,
    virtualData,
    gridCount,
  }, {
    enableScrollBar: true,
    classNamePrefix: 'category-recommend-placeholder',
    scrollBarHorizontalClass: 'category-recommend-placeholder__swiper-scrollbar-horizontal',
    scrollBarDragClass: 'category-recommend-placeholder__swiper-scrollbar-drag',
    onReturnVisible: () => {
      // debugLog('Swiper 恢复可见，重新初始化')
    }
  })

  // 获取 SBC 入口点击后行为检测开关
  const { startSbcEntryFollowActionTrack } = useSbcEntryFollowAction({
    contentData,
    sceneDataFromCommonProps,
    componentIndexInPage,
    itemsDataList,
    slidesData,
    virtualData,
    sbcInfoSummary,
  })

  // #endregion

  return {
    ...swiperComposables,
    swiperId,
    isShow,
    isFirstPage,
    isShowBelt,
    sbcInfoSummary,
    compMetaData,
    slidesData,
    virtualData,
    rootStyle,
    cateNameStyle,
    imageStyle,
    beltStyle,
    lineClampClass,
    startSbcEntryFollowActionTrack
  }
}
