<template>
  <!-- 下沉组件 优惠券列表 抽屉 -->
  <BCouponList
    ref="BCouponListRef"
    :visible="popupState.couponDrawerVisible"
    :is-auto-fetch-api="false"
    :is-free-price="isFreePrice"
    :isCouponAutoUse="isCouponAutoUse"
    :applyCouponsSuccess="applyCouponsSuccess"
    :checkoutNo="checkoutParams?.checkout_no"
    :errinfo="errinfo"
    :couponTotalPrice="couponTotalPrice"
    :selectedPaymentInfo="selectedPaymentInfo"
    :selectedShipMethod="checkoutParams.shipping_methods"
    :saveCardProductCode="checkoutParams.save_card_product_code"
    :primeProductCode="checkoutParams.prime_product_code"
    @go-back="goBack"
    @confirm-use-coupon-handle="confirmUseCouponHandle"
    @handle-new-apply-btn="handleNewApplyBtn"
    @handle-best-apply-btn="handleBestApplyBtn"
    @handle-click-close="handleClose"
    @handle-gift--coupon-operation="handleGiftCouponOperation"
  >
    <template #title-right-side>
      <tAndc />
    </template>

    <template
      #new-coupon-item="{
        data,
        couponConfig,
        index,
        checkoutCouponIndex,
        couponType,
      }"
    >
      <new-coupon-item
        :data="{
          ...data,
          checkoutCouponIndex
        }"
        :expanded="expandedCoupon"
        :coupon-config="couponConfig"
        :language="language"
        :isForBff="true"
        @button-click="(emitParams)=>{
          newHandleApply({...emitParams, couponType: couponType, index, isUseableCoupon: true})
        }"
      />
    </template>
  </BCouponList>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import  { _sCommonController } from '@shein/common-function'
import { BCouponList } from '@shein-aidc/bs-coupon-list-mobile-vue2'

import tAndc from 'public/src/pages/components/coupon/mall/tAndc.vue'
import NewCouponItem from 'public/src/pages/common/common-coupon/CouponItemBff.vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

import {
  useMapMutations,
  useMapActions,
  useMapState,
  useMapGetters
} from '@/public/src/pages/checkout_new/hooks/store'
import useCoupon from '@/public/src/pages/checkout_new/hooks/useCoupon.js'
import { getMainImageUrl } from 'public/src/pages/cartNew/utils/index.js'

// 券组件使用  注册站点id，获取对应站点时间格式。
_sCommonController.setData('SiteUID', gbCommonInfo?.SiteUID)

const {
  language,
  checkout,
  shoppingBagsState,
  checkoutParams,
  shippingMethodState,
  isOfflineStatus,
} = useMapState(['language', 'checkout', 'shoppingBagsState', 'checkoutParams', 'shippingMethodState', 'isOfflineStatus'])

const { isFreePrice } = useMapGetters(['isFreePrice'])

const { updateCheckoutData, updateCartsInfoData } = useMapActions(['updateCheckoutData', 'updateCartsInfoData'])
const { updateShipPopupByKey, setCouponListRef, updateVirtualAssetsPopupState } = useMapMutations(['updateShipPopupByKey', 'setCouponListRef', 'updateVirtualAssetsPopupState'])
const popupState = computed(() => shippingMethodState.value.popup)
const BCouponListRef = ref(null)
const expandedCoupon = ref('')
const addedCoupon = ref('')
const goodsListData = ref(null)
const errinfo = ref('')  //计价返回错误提示
const isNeedOpenGiftDrawer = ref(false)
const needUpdateCarts = ref(false)

const { useUpdateCouponState, useGetCouponList, useGetAllUsableCoupons, useGetCommonAndBestCoupons } = useCoupon()

// computed
const isSuggested = () => {
  if (typeof window !==  'undefined') {
    return window.gbCommonInfo.IS_SUGGESTED
  }
  return false
}

const selectedPaymentInfo = computed(() => {
  return {
    payment_code: checkoutParams.value?.payment_code_unique,
    payment_id: checkoutParams.value?.payment_id
  }
})

const applyCouponsSuccess = computed(() => {
  return checkout.value?.coupon_list
})

const isCouponAutoUse = computed(() => {
  return checkout.value?.couponInfo?.isCouponAutoUse == '1'
})

// 需要确认是取coupon_price 还是coupon_total_price
const couponTotalPrice = computed(() => {
  return checkout.value?.coupon_price
})

// 获取下单页购物车商品list平铺数据
const cartsList = computed(() => {
  return Object.values(shoppingBagsState.value?.bagsInfo)?.flat() || []
})

onMounted(() => {
  setCouponListRef(BCouponListRef.value)
})

// methods
const closeModel = () => {
  updateShipPopupByKey({
    key: 'couponDrawerVisible',
    value: false
  })
}

const handleGiftCouponOperation = () => {
  if(!isNeedOpenGiftDrawer.value) {
    return
  }
  closeModel()
  updateVirtualAssetsPopupState({
    couponGiftDrawer: {
      visible: true,
    },
  })
}

const goBack = function () {
  // 关闭优惠券弹窗
  // emit('close-drawer')
  closeModel()
}

const handleClose = () => {
  // 关闭优惠券弹窗
  // emit('close-drawer')
  closeModel()
}
const confirmUseCouponHandle = () => {
  // 关闭优惠券弹窗
  // emit('close-drawer')
  closeModel()
}

const handleBestApplyBtn = (couponStr, type) => {
  isNeedOpenGiftDrawer.value = true
  const newCoupon = couponStr?.toLowerCase()
  let arr = newCoupon.split(',')
  BCouponListRef.value?.changeLoading(true) //loading
  // 点击最优券apply按钮的主站操作 请求计价接口使用券
  updateCheckoutData({
    opts: {
      add_coupon: '',
      coupon_list: arr,
      is_manual_used_coupon: 1
    },
    cb: (res) => {
      BCouponListRef.value?.changeLoading(false) //loading
      if(res.code == 0) {
        errinfo.value = ''
        sendExposeUsedCouponGoods(arr[0])
        // 优惠券使用成功
        // coupon_list为空表示已使用券为空，走报错熔断(用券),或提示取消用券成功
        if(res.info?.coupon_list?.length == 0) {
          // 此处可以增加弹窗提示错误
          return
        }
        // 待子组件props更新完毕后再执行getCouponList，否则获取的值为上一次props
        nextTick(() => {
          useGetCouponList({
            handleType: type
          })
        })
      } else {
        // 优惠券使用失败
        if (['302458', '300627'].includes(res.code)) {
          errinfo.value = ''
        } else {
          errinfo.value = res.msg
        }
      }
    },
    scene: 'coupon',
  })
}

const isStackable = (item) => {
  return Array.isArray(item?.promptInfo) && item?.promptInfo?.includes('stackable')
}

// 判断当前用户输入的券码，是否在最优券组合或other coupons组
const judgeAddCoupon = (couponCode) => {
  let isIncludeList = false
  // shein 不需再进行 ab 判断
  useGetCommonAndBestCoupons()?.forEach(item => {
    if(item.coupon?.toLowerCase() == couponCode?.toLowerCase()) isIncludeList = true
  })
  return isIncludeList
}

const isNeedUpdateCarts = (code) => {
  let result = false
  useGetAllUsableCoupons()?.map(item => {
    if(item.coupon == code) {
      if(item?.businessExtension?.order?.hasCouponGift == '1') {
        result = true
      }
    }
  })
  return result
} 

// 点击Apply按钮的主站操作
const handleNewApplyBtn = ({ codeStr, isApply, type }) => {
  needUpdateCarts.value = false
  let couponArr = applyCouponsSuccess.value?.map(item => {
    return item.couponCode || item.coupon
  })

  let judgeAddCouponExits = true
  // 判断是Apply还是Remove操作
  if(isApply) {
    isNeedOpenGiftDrawer.value = false
    couponArr = couponArr.filter(item => item != codeStr)
    if(isNeedUpdateCarts(codeStr)) {
      needUpdateCarts.value = true
    }
  } else {
    isNeedOpenGiftDrawer.value = true
    addedCoupon.value = codeStr
    let isCodeSelected = false
    let isCodeStackable = false
    useGetAllUsableCoupons()?.map(item => {
      if(item.coupon == codeStr) {
        if(item?.businessExtension?.order?.isCheck == '1') {
          isCodeSelected = true
        }
        if(isStackable(item)) {
          isCodeStackable = true
        }
      }
    })

    judgeAddCouponExits = judgeAddCoupon(codeStr)
    if(isCodeSelected) {
      return
    } else {
      if(isCodeStackable) { // 输入的券是可叠加券
        couponArr.push(codeStr)
      } else {
        couponArr = [codeStr]
      }
    }
  }

  BCouponListRef.value?.changeLoading(true) //loading
  // 走下单接口用券
  updateCheckoutData({
    opts: {
      coupon_list: couponArr,
      is_manual_used_coupon: 1,
      add_coupon: !isApply ? codeStr : ''
    },
    cb: (res) => {
      BCouponListRef.value?.changeLoading(false) //loading
      if(res.code == 0) {
        errinfo.value = ''
        sendExposeUsedCouponGoods(codeStr)
        // 优惠券使用成功
        // 待子组件props更新完毕后再执行getCouponList，否则获取的值为上一次props
        nextTick(() => {
          useGetCouponList({
            handleType: type
          })
        })
        needUpdateCarts.value && updateCartsInfoData()
      } else {
        // 优惠券使用失败
        if (['302458', '300627'].includes(res.code)) {
          errinfo.value = ''
        } else {
          errinfo.value = res.msg
        }
      }
      !judgeAddCouponExits && sendClickCouponApplyEvent(res.code, codeStr)
    },
    scene: 'coupon',
  })
}

const newHandleApply = (params) => {
  // 单选框勾选
  if(params?.from == 'couponChecked') {
    clickCoupon(params?.couponType, params?.couponInfo, params?.index)
    return
  }
  // 折叠/收起点击
  if (params?.from == 'couponAssistInformationType') {
    expandedCoupon.value = params?.assistInformationType == 1 ? '' : `${params?.couponInfo.id}`
    return
  }
  // 剔品规则券点击查看详情
  if(params?.from == 'addModule' ) {
    handleCouponListData(params?.couponInfo)
    return
  }
  // us预计优惠?点击
  if(params?.from == 'couponHelp') {
    params?.isUseableCoupon ? isShowGoodsHandle(params?.couponInfo) : isShowGoodsHandle(params?.couponInfo, '0')
  }
}

const handleCouponListData = (coupon) => {
  let goodsData = handleApplyGoods(coupon.cartIds)
  // 组装券组件的商品数据, 新增三个券组件用到的字段
  goodsData?.map((cItem) => {
    cItem.goodsImg = getMainImageUrl({ item: cItem })
    cItem.hasDiffPrice = !isSuggested.value && (cItem.unitPrice.amount != cItem.product.retailPrice.amount || cItem.product.insurable == 1)
    cItem.goodsPrice = cItem.unitPrice.amountWithSymbol
  })
  BCouponListRef.value?.insertCouponLimitGoods(coupon?.id, goodsData)
}

// 根据id匹配商品数据
const handleApplyGoods = (list) => {
  if (!list) return []
  return list.map(item => (
    cartsList.value?.find(product => item == product.id)
  ))
}

const isShowGoodsHandle = (item, is_available = '1') => {
  goodsListData.value = handleCouponGoodsList(cartsList.value, item.cartIds)

  // 组装券组件的商品数据, 新增三个券组件用到的字段
  let goodsListDataCp = JSON.parse(JSON.stringify(goodsListData.value))
  goodsListDataCp?.map((cItem) => {
    cItem.goodsImg = getMainImageUrl({ item: cItem })
    cItem.hasDiffPrice = !isSuggested.value && (cItem.unitPrice.amount != cItem.product.retailPrice.amount || cItem.product.insurable == 1)
    cItem.goodsPrice = cItem.unitPrice.amountWithSymbol
  })
  BCouponListRef.value?.insertCouponGoodsUsable(item?.id, goodsListDataCp)

  daEventCenter.triggerNotice({
    id: 'click_coupon_viewgoods:simple',
    data: {
      coupon_code: item.couponCode || item.coupon || '',
      is_available,
    }
  })
  daEventCenter.triggerNotice({
    id: 'expose_coupon_available_items:simple',
    data: {
      coupon_code: item.couponCode || item.coupon || '',
      goods_sn: goodsListData.value?.map(good => good?.product?.goods_sn)?.join(','),
    }
  })
}

const handleCouponGoodsList = (cartsData, goodsList) => {
  const newArr = []
  if (!goodsList?.length || !cartsData?.length) return newArr
  cartsData.map(item => {
    if (goodsList.some(val => val == item?.id)) {
      newArr.push(item)
    }
  })
  return newArr
}

const sendExposeUsedCouponGoods = (couponCode)=> {
  if(!couponCode || !applyCouponsSuccess.value?.length || !useGetAllUsableCoupons()) return
  if(!isCouponAutoUse.value) {
    const usedCouponData = useGetAllUsableCoupons().filter(item => item?.coupon?.toLowerCase() == couponCode.toLowerCase()) || []
    const mall_cart_coupon_goods_lists = usedCouponData?.[0]?.cartIds || []
    daEventCenter.triggerNotice({
      id: 'expose_used_coupon_goods:simple',
      data: {
        coupon_code: couponCode,
        goods_data: !mall_cart_coupon_goods_lists.length ? '0' : '1'
      }
    })
  }
}

const sendClickCouponApplyEvent = (resCode, couponCode) => {
  daEventCenter.triggerNotice({
    id: 'click_coupon_apply_button:simple',
    data: {
      apply_result: resCode == 0 ? 1 : 0,
      coupon_code: couponCode || '',
      errormessage: resCode
    }
  })
}

const clickCoupon = (type, item) => {
  needUpdateCarts.value = false
  const code = item.coupon || ''
  // isGray 数字类型 可用券-是否置灰, 0 - 不置灰, 1 - 置灰
  // isOfflineStatus 是否是下线的状态
  if (isOfflineStatus.value || item.businessExtension?.order?.isGray == 1) return

  // getConfirmModelStatus 判断优惠券确认弹框是否打开
  if (BCouponListRef.value?.getConfirmModelStatus) {
    daEventCenter.triggerNotice({
      id: 'expose_couponconfirm:simple',
      data: {
        coupon_code: code
      }
    })
  }

  let couponArr = applyCouponsSuccess.value?.map(item => {
    return item.couponCode || item.coupon
  })
  let couponListStatus = {
    status: 'cancel',
    statusGa: 'CancelSelect',
  }
  // 判断优惠券是否已被选中
  if(item?.businessExtension?.order?.isCheck == '1') {
    isNeedOpenGiftDrawer.value = false
    couponArr = couponArr.filter(i => i != item.coupon)
    daEventCenter.triggerNotice({
      id: 'click_coupon_code_cancel:simple'
    })
    if(isNeedUpdateCarts(code)) {
      needUpdateCarts.value = true
    }
  } else {
    isNeedOpenGiftDrawer.value = true
    // 是可叠加券
    if(Array.isArray(item?.promptInfo) && item?.promptInfo?.includes('stackable')) {
      couponArr.push(item.coupon)
    } else {
      let couponString = item.couponCode || item.coupon
      couponArr = couponString.split(',')
    }

    couponListStatus = {
      status: 'selected',
      statusGa: 'Select',
    }
    daEventCenter.triggerNotice({
      id: 'click_couponclick:simple',
      data: {
        type: 'choose',
        store_code: item?.store?.storeCode,
        mall_code: item?.mall?.mallCode,
        ...couponListStatus
      }
    })
  }
  // 走下单接口用券
  BCouponListRef.value?.changeLoading(true) //loading
  let hasAddedCoupon = couponArr && Array.isArray(couponArr) && couponArr.includes(addedCoupon.value?.toLowerCase())
  updateCheckoutData({
    opts: {
      add_coupon: hasAddedCoupon ? addedCoupon.value : '',
      coupon_list: couponArr,
      is_manual_used_coupon: 1
    },
    cb: (res) => {
      BCouponListRef.value?.changeLoading(false) //loading
      if(res.code == 0) {
        sendExposeUsedCouponGoods(code)
        errinfo.value = ''
        // 优惠券使用成功
        nextTick(() => {
          // 待子组件props更新完毕后再执行getCouponList，否则获取的值为上一次props
          useGetCouponList({
            handleType: 'clickSelectCoupon',
            item,
            cb: (res) => {
              useUpdateCouponState(res)
            }
          })
        })
        needUpdateCarts.value && updateCartsInfoData()
      } else {
        // 优惠券使用失败
        if (['302458', '300627'].includes(res.code)) {
          errinfo.value = ''
        } else {
          errinfo.value = res.msg
        }
      }
    },
    scene: 'coupon',
  })
}

</script>
<style lang="less">
  .checkout-coupon-drawer {
    .S-title-nav__content {
      max-width: 80%;
    }
  }
</style>
<style lang="less" scoped>
  .name{
    color: #000;
  }
</style>
