import { useMapState, useMapMutations } from './store'
import { mergeQueryString } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

export const usePaymentError = () => {
  const { language } = useMapState(['language'])
  const { updateCommonDialogState } = useMapMutations(['updateCommonDialogState'])

  const getOrderDetailLink = ({ order, extraParams = {} } = {}) => {
    const { host, langPath } = window.gbCommonInfo || {}
    const link = `${host}${langPath}/user/orders/detail/${order?.relation_billno || order?.billno}`
    return mergeQueryString({
      url: link,
      mergeObj: extraParams || {}
    })
  }

  // paymentErrorDialog
  const checkPaymentErrorVisible = ({
    paymentCode,
    result,
  }) => {
    if (
      ['PayPal-GApaypal', 'PayPal-Venmo'].includes(paymentCode) &&
      result?.info?.error_code == 7581
    ) {
      return 'normalError'
    }
    if (
      ['PayPal-GApaypal'].includes(paymentCode) &&
      ['7583', '7511', '7527'].includes(`${result?.info?.error_code || ''}`)
    ) {
      return 'addressError'
    }
    return ''
  }

  const handleVisiblePaymentError = ({
    paymentCode,
    result,
    order,
  }) => {
    const errorType = checkPaymentErrorVisible({ paymentCode, result })

    const subBillnos = order?.order_group?.map?.(_ => _.billno) || []

    daEventCenter.triggerNotice({
      id: 'expose_popup_payfailresaon:simple',
      data: {
        billno: order.billno || order.relation_billno || '',
        uorder_id: order.relation_billno || '',
        error_code: result.info?.error_code || result?.code || '',
        order_id_list: subBillnos?.join?.(',') || '',
      }
    })
    if (errorType === 'addressError') {
      daEventCenter.triggerNotice({
        id: 'expose_paypal_address:simple'
      })
    }
    updateCommonDialogState({
      commonErrDialog: {
        show: true,
        tip: `<div style="color:#f56262;word-break: break-word;display: inline-block">${result?.info?.show_error_msg || language.value?.SHEIN_KEY_PWA_30525}</div>`,
        confirmText: errorType === 'addressError' ? language.value?.SHEIN_KEY_PWA_23080 : language.value?.SHEIN_KEY_PWA_18132,
        closeText: errorType === 'addressError' ? language.value?.SHEIN_KEY_PWA_18132 : '',
        showClose: true,
        onConfirm: () => {
          let mergeOrderDetailUrl = getOrderDetailLink({
            order,
            extraParams: {
              showpaymethods: 1,
            }
          })
          daEventCenter.triggerNotice({
            id: 'click_popup_changepayment:simple.beacon',
            data: {
              billno: order?.billno || order?.relation_billno || '', 
              errorCode: result?.info?.error_code, 
              uorder_id: order.relation_billno, 
              order_id_list: subBillnos?.join?.(',') || ''
            },
          })
          if (errorType === 'addressError') {
            mergeOrderDetailUrl = getOrderDetailLink({ order })
            daEventCenter.triggerNotice({
              id: 'click_paypal_address:simple.beacon',
              data: { 
                type: 1
              },
            })
          }
          window.location.href = mergeOrderDetailUrl
        },
        onClose: errorType === 'addressError' ? () => {
          daEventCenter.triggerNotice({
            id: 'click_paypal_address:simple.beacon',
            data: { 
              type: 2
            },
          })
          const mergeOrderDetailUrl = getOrderDetailLink({
            order,
            extraParams: {
              showpaymethods: 1,
            }
          })
          window.location.href = mergeOrderDetailUrl

        } : null,
        onCloseDialog: (evt = '') => {
          if (evt === 'close-from-mask' || evt === 'close-from-icon') {
            daEventCenter.triggerNotice({
              id: 'click_payfailresaonclose:simple.beacon',
              data: { 
                billno: order?.billno || order?.relation_billno || '', 
                errorCode: result?.info?.error_code, 
                uorder_id: order.relation_billno, 
                order_id_list: subBillnos?.join?.(',') || ''
              },
            })
            if (errorType === 'addressError') {
              daEventCenter.triggerNotice({
                id: 'click_paypal_address:simple.beacon',
                data: { 
                  type: 0
                },
              })
            }
            window.location.href = getOrderDetailLink({ order })
          }
        }
      }
    })
  }

  return {
    checkPaymentErrorVisible,
    handleVisiblePaymentError
  }
}
