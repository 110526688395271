<template>
  <div class="page__login_phoneLoginPage">
    <div class="main-content-body">
      <LoginHead
        :showback="true"
        :showLogo="false"
        @back="handleBack"
      />
      <div
        v-if="phoneLoginMode == 'code'"
        class="page__phoneLogin_code"
      >
        <h2>
          <p>{{ langText?.SHEIN_KEY_PWA_34385 }}</p>
        </h2>
        <span
          class="code__tips"
          v-html="phoneCodeTips"
        ></span>
        <PhoneCodeInput 
          ref="phoneCodeInputRef"
          v-model="codeInputValue"
          :errorTips="codeInputTips.txt"
          :codeInputFocus="codeInputFocus"
          @click="handlePhoneCodeClick"
          @input="handlePhoneCodeInput"
          @blur="handlePhoneBlur"
        />
        <CountDownTimeVue
          :reSend="codeIsSended ? langText?.SHEIN_KEY_PWA_14897 : langText?.SHEIN_KEY_PWA_22165"
          :codeDownTime="codeDownTime"
          :sendType="sendType"
          @click="handleSendCode"
        />
      </div>
      <div
        v-if="phoneLoginMode == 'password'"
        class="page__phoneLogin_password"
      >
        <h2>+{{ switchAccount.area_code || newUIStates?.code }} {{ switchAccount.accountAlias || newUIStates?.desensitize_alias }}</h2>
        <div class="input-field">
          <LoginInput
            v-model="phonePassword"
            type="password"
            :tips="codePasswordTips.txt"
            :label="langText.SHEIN_KEY_PWA_15072"
            ui-type="new"
            @input="handlePasswordChange"
            @blur="handlePhoneBlur"
          />
        </div>
      </div>
      <div class="page__phoneLogin_switchMode">
        <p
          v-if="phoneLoginMode == 'code'"
          class="page__login-switch-codeMode"
        >
          <span
            da-event-click="2-8-41"
            data-type="1"
            @click="handleChangeLoginMode(2)"
          >
            <i class="suiiconfont sui_icon_change_24px"></i>
            {{ langText.SHEIN_KEY_PWA_14776 }}
          </span>
        </p>
        <p
          v-if="phoneLoginMode == 'password'"
          class="page__login-switch-codeMode page__login-switch-password"
          data-type="2"
        >
          <span
            da-event-click="2-8-43"
            @click="handleChangeLoginMode(1)"
          >
            <i class="suiiconfont sui_icon_change_24px"></i>
            {{ langText.SHEIN_KEY_PWA_18618 }}
          </span>
          <a
            href="javascript:;"
            da-event-click="2-8-55"
            data-type="phone" 
            :data-mode="1"
            @click="handleForgetPsd"
          >{{ langText.SHEIN_KEY_PWA_15582 }}</a>
        </p>
      </div>
      <div class="actions">
        <SButton
          class="button"
          :type="['primary', 'H88PX']"
          @click="handlePhoneLogin"
        >
          {{ langText?.SHEIN_KEY_PWA_15578 }}
        </SButton>
      </div>
      <div
        v-if="commonAbt.WhatsAppSetup.show == 'on' && phoneLoginMode == 'code'"
        class="page__login-switch-sendtype"
      >
        <a
          v-show="sendType == 'whatsApp' && codeDownTime == 0"
          href="javascript:void(0)"
          da-event-click="2-8-89"
          data-type="1"
          @click="handleSendChange"
        ><i
          class="suiiconfont sui_icon_phone_20px"
          style="font-size: 18px"
        ></i>{{ langText.SHEIN_KEY_PWA_22295.replace('{0}', 'SMS') }}</a>
        <a
          v-show="sendType == 'sms' && codeDownTime == 0"
          href="javascript:void(0)"
          da-event-click="2-8-89"
          data-type="2"
          @click="handleSendChange"
        ><i
          class="suiiconfont sui_icon_whatsapp_16px"
          style="font-size: 18px"
        ></i>{{ langText.SHEIN_KEY_PWA_22295.replace('{0}', 'whatsApp') }}</a>
      </div> 
    </div>
    <div class="main-content-footer">
      <WeekPrivacy
        v-if="newPrivacyAbt.type == 'no'"
        :type="'login'"
        location="phone_login"
      />
    </div>
    <TemporaryVue
      ref="temporaryRef"
      :langText="langText"
    />
    <LazyMount mount-prop-name="isShowModal">
      <LazyMountModal :isShowModal="sendTypeChangePop?.show">
        <PhoneSendTypeModal
          :is-show="sendTypeChangePop?.show"
          :send-type="sendType"   
          @change="handleSendChange"
          @resend="handleSendResend"
        />
      </LazyMountModal>
    </LazyMount>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { useStore, useEventBus, useToast } from '@login/hooks/utils'
import { showInputTips } from '@login/utils/index.js'
import { checkPw, formatErrorPhoneSa, newPrivacyCache, urlRedirection, formatNormalErrorMessage } from '@login/util'
import LoginHead from '@login/views/NewMainUI/components/head.vue'
import PhoneCodeInput from './components/PhoneCodeInput.vue'
import CountDownTimeVue from './components/loginCountDownTime'
import LoginInput from  '@login/libs/LoginInput/index.vue'
import WeekPrivacy from '@login/views/NewMainUI/components/week_privacy.vue'
import TemporaryVue from './Temporary'
import PhoneSendTypeModal from './components/PhoneSendTypeModal.vue'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { riskVerifyChallenge } from '@login/common/riskVerifyManager.js'
import { thirdLoginCodeSend, phoneLoginSer } from '@login/service'
import { template } from '@shein/common-function'
import { Metric_Login_Detail_Total, Metric_Login_Detail_Click_Total, Metric_Login_Detail_Click_Result_Total, Metric_Login_Phone_Code } from '@login/utils/monitorLog.js'
import { saSend } from '@login/analysis/util'
import analysisIndex from '@login/analysis/index'
import useCommonHandle from '@login/hooks/useCommonHandle.js'
const { sensorsSend } = analysisIndex

let downtimer = null

const store = useStore()
const evt = useEventBus()
const $toast =  useToast()

const temporaryRef = ref()
const phoneCodeInputRef = ref()
const codeInputTips = ref({
  show: false,
  txt: ''
})
const phoneLoginMode = ref('code')
const codeInputValue = ref('')
const codeInputFocus = ref(false)
const sendType = ref('sms')
const codeDownTime = ref(0)
const codeIsSended = ref(false)
const phonePassword = ref('')
const codePasswordTips = ref({
  show: false,
  txt: ''
})
const codeTips = ref({
  show: false,
  txt: ''
})

const { setCommonShowPop } = useCommonHandle()

const switchAccount = computed(() => store.state.login.switchAccount)
const langText = computed(() => store.state.login.langText)
const relatedAcc = computed(() => store.state.login.relatedAcc)
const newPrivacyAbt = computed(() => store.state.login.newPrivacyAbt)
const defaultLocation = computed(() => store.state.login.defaultLocation)
const phoneAbt = computed(() => store.state.login.phoneAbt)
const commonAbt = computed(() => store.state.login.commonAbt)
const newUIStates = computed(() => store.state.login.newUIStates)
const isLoginAccount = computed(() => store.state.login?.preloadData?.uid)
const sendTypeChangePop = computed(() => store.state.login.dialogs.sendTypeChangePop)
const publicLocalData = computed(() => store.state.login.publicLocalData)

const phoneCodeTips = computed(() => {
  const isSwitchAccount = switchAccount.value?.show 
  const { accountAlias, area_code } =  switchAccount.value
  const { desensitize_alias, code } = newUIStates.value
  const alias = isSwitchAccount ? `+${area_code} ${accountAlias}` : `+${code} ${desensitize_alias}`
  if(commonAbt.value.WhatsAppSetup.show == 'on' ){
    return langText.value?.SHEIN_KEY_PWA_22294.replace('{0}', `<strong>${sendType.value}</strong>`).replace('%x', `${alias}`)
  }
  return template(alias, langText.value?.SHEIN_KEY_PWA_20733)
})


watch(() => switchAccount.value?.show, async (val) => {
  if(val && phoneLoginMode.value == 'code'){
    await nextTick()
    handleSendCode()
  }
}, { immediate: true })

watch(() => newUIStates.value?.showType, async (val) => {
  if(val && newUIStates.value?.showType == 'encryptionPhone' && phoneLoginMode.value == 'code'){
    await nextTick()
    handleSendCode()
    saSend('2-8-105', { scene: 'login', type: 'phone', acc: relatedAcc.value })
    Metric_Login_Detail_Total({ account_type: 'phone', sub_site: publicLocalData.value?.SiteUID || '' })
  }
}, { immediate: true })

watch(() => codeTips.value?.txt, (val) => {
  if(val){
    if(phoneLoginMode.value == 'code'){
      codeInputTips.value = codeTips.value
    }
    if(phoneLoginMode.value == 'password'){
      codePasswordTips.value = codeTips.value
    }
  }
})

const handleBack = () => {
  try {
    if(switchAccount.value?.show){
      store.commit('login/setSwitchAccount', { show: false })
    return
    }
    if(newUIStates.value?.showType == 'encryptionPhone'){
      store.commit('login/setnewUIStates', { showType: 'continue' })
    }
    if (downtimer) clearTimeout(downtimer)
    showInputTips(codeInputTips.value, '', false)
    showInputTips(codePasswordTips.value, '', false)
    codeDownTime.value = 0
    phonePassword.value = ''
    codeInputValue.value = ''
    codeIsSended.value = false
    if(phoneLoginMode.value == 'code'){
      phoneCodeInputRef.value.inputValue = ''
    }
    phoneLoginMode.value == 'code'
  } catch (error) {
    console.log('error' + '')
  }
}

const handlePhoneCodeClick = () => {
  codeInputFocus.value = true
}

const handlePhoneCodeInput = () => {
  codeInputFocus.value = true
  showInputTips(codeInputTips.value, '', false)
  const v = codeInputValue.value?.replace(/\D/g, '')
  if (v !== codeInputValue.value) codeInputValue.value = v
  if (codeInputValue.value?.length > 6) codeInputValue.value = codeInputValue.value?.slice(0, 6)
}

const handlePhoneBlur = () => {
  codeInputFocus.value = false
  showInputTips(codeInputTips.value, '', false)
  if(phoneLoginMode.value == 'code' && !/\d{6}/.test(codeInputValue.value)){
    showInputTips(codeInputTips.value, langText.value.SHEIN_KEY_PWA_16043)
    return false
  }
  if(phoneLoginMode.value == 'password'){
    return checkPw(phonePassword.value, codePasswordTips.value)
  }
  showInputTips(codeInputTips.value, '', false)
  showInputTips(codePasswordTips.value, '', false)
  return true
}

const handleSendCode = async (scene = 'phone_login_register_verify', smsType, form = '') => {
  if (codeDownTime.value > 0) {
    codeIsSended.value = true
    return
  }
  const isSwitchAccount = switchAccount.value?.show 
  const { accountSecurity, area_code, area_abbr, accountAlias } = switchAccount.value
  const { encryption_alias, code, abbr } = newUIStates.value
  const sendtype = smsType ? smsType : sendType.value
  const params = {
    encryption_alias: isSwitchAccount ? accountSecurity : encryption_alias,
    alias: accountAlias,
    area_abbr: isSwitchAccount ? area_abbr : abbr,
    area_code: isSwitchAccount ? area_code : code,
    alias_type: 2,
    scene,
    send_type: sendtype == 'whatsApp' ? 1 : 0,
    third_party_type: 8,
  }
  if(typeof window !== 'undefined' && !window._fmOpt?.__blackbox){
    const { __blackbox } = await import('public/src/pages/common/RiskCommon/libs/BlackBox/index.js')
    await __blackbox
  }
  requestSendCode(params, sendtype, form)
}

const requestSendCode = async (params, smsType, from = '') => {
  const res = await geetestChallenge(thirdLoginCodeSend, params, 'send_message')
  if (!res) {
    evt.$emit('event-loading', false)
    return
  }
  const { apiResData = {}, status } = res
  if (status == 'error') {
    $toast(langText.value?.SHEIN_KEY_PWA_16274)
    return
  }
  if (status == 'close') {
    evt.$emit('event-loading', false)
    return
  }
  const data = apiResData || {}
  const { code: sendCode, tips, info } =  data 
  saSend('2-8-101', {
    from,
    scene: 'login',
    mode: 'phone',
    send_type: smsType,
    sended: codeIsSended.value,
    code: sendCode
  })
  Metric_Login_Phone_Code({ result: sendCode == 0 ? 'success' : 'failed', type: smsType, error: sendCode, sub_site: publicLocalData.value?.SiteUID || ''  })
  if (sendCode == -1) {
    evt.$emit('event-loading', false)
    return
  }
  if (sendCode == '404101' && info?.ttl > 0) {
    // 获取剩余倒计时
    startDownTime(info.ttl)
    codeIsSended.value = true
    return
  }
  const errorCodes = [
    '404109',
    '404110',
    '404111',
    '404101',
    '404102',
    '404105',
    '404106',
    '404107',
    '100102',
    '0'
  ]
  if (commonAbt.value?.WhatsAppSetup?.show == 'on' && !errorCodes.includes(sendCode)) {
    store.commit('login/changeDialogs', { key: 'sendTypeChangePop', val: { show: true } })
    sensorsSend('2-8-90', { type: smsType == 'sms' ? '2' : '1' })
    return
  }

  if (sendCode == 0) {
    startDownTime(60)
    codeIsSended.value = true
    if(newUIStates.value?.showType != 'encryptionPhone') return
    $toast(langText.value?.SHEIN_KEY_PWA_17775 + ' ')
    return 
  }
  showInputTips(codeInputTips.value, tips || langText.value?.SHEIN_KEY_PWA_14899)
  return 
}

const startDownTime = (time) => {
  const n = time <= 0 ? 0 : time
  codeDownTime.value = n
  if (downtimer) clearTimeout(downtimer)
  if (n <= 0) return
  downtimer = setTimeout(() => {
    startDownTime(n - 1)
  }, 1000)
}

const handlePasswordChange = () => {
  showInputTips(codePasswordTips.value, '', false)
}

const handleForgetPsd = () => {
  store.commit('login/setForgetPassword', { show: true, type: 'phone' })
}
const handleChangeLoginMode = () => {
  phoneLoginMode.value = phoneLoginMode.value == 'code' ? 'password' : 'code'
}

const handlePhoneLogin = () => {
  const isSwitchAccount = switchAccount.value?.show 
  const { accountAlias, accountSecurity, area_code, accountType, area_abbr, locationRiskId } = switchAccount.value
  const { desensitize_alias, encryption_alias, code, abbr, locationRiskId: loginLocationRiskId } = newUIStates.value
  Metric_Login_Detail_Click_Total({ account_type: 'phone', sub_site: publicLocalData.value?.SiteUID || '' })
  if (!handlePhoneBlur()) {
    const samsg = formatErrorPhoneSa(codeInputValue.value, phonePassword.value, phoneLoginMode.value)
    sensorsSend('2-8-108', { result_reason: samsg, acc: relatedAcc.value, authentication: phoneLoginMode.value })
    return
  }
  const params = {
    alias_type: 2,
    alias: isSwitchAccount ? accountAlias : desensitize_alias,
    encryption_alias: isSwitchAccount ? accountSecurity : encryption_alias,
    accountType: isSwitchAccount ? accountType : 8,
    area_abbr: isSwitchAccount ? area_abbr : abbr, 
    area_code: isSwitchAccount ? area_code : code,
    verification_code: codeInputValue.value,
    login_type: phoneLoginMode.value == 'code' ? 1 : 2,
    password: phonePassword.value,
    clause_flag: newPrivacyAbt.value?.type == 'yes' ? 1 : 0,
    daId: '2-8-108',
    biz_uuid: isSwitchAccount ? locationRiskId : loginLocationRiskId,
    verification_type: sendType.value == 'whatsApp' ? 1 : 0,
    send_type: sendType.value == 'whatsApp' ? 1 : 0,
  }
  requestPhoneLogin(params)
}

const requestPhoneLogin = async (params) => {
  evt.$emit('event-loading', true)
  const { daId } = params
  const { isSwitch } = newUIStates.value
  isSwitch ? params.isSwitchUid = isLoginAccount.value : ''
  if (params.clause_flag == 1 && newPrivacyCache.get(defaultLocation.value?.forceId || defaultLocation.value?.id || '')){
    params.clause_agree = 1
    // 一站多合规mix传地区id
    params.locationId = newPrivacyAbt.value?.origin == 'mix' ? defaultLocation.value?.forceId || defaultLocation.value?.id || '' : ''
  }
  if (relatedAcc.value?.is_relation == '1') { params.is_relation = 1 } 
  // 极验验证
  const res = await geetestChallenge(phoneLoginSer, params, 'login')
  if (!res) {
    evt.$emit('event-loading', false)
    return
  }
  const { apiResData = {}, status } = res
  if (status == 'error') {
    showInputTips(codeTips.value, langText.value?.SHEIN_KEY_PWA_16274)
    evt.$emit('event-loading', false)
    sensorsSend(daId, { type: phoneLoginMode.value == 'code' ? 1 : 2, success: false })
    return
  }
  if (status == 'close') {
    evt.$emit('event-loading', false)
    return
  }
  const response = apiResData || {}
  const { code, msg } = response
  evt.$emit('event-loading', false)
  sensorsSend(daId, {
    code,
    authentication: phoneLoginMode.value,
    type: phoneLoginMode.value == 'code' ? 1 : 2,
    success: code == 0,
    msg,
    mode: 'phone',
    acc: relatedAcc.value
  })
  Metric_Login_Detail_Click_Result_Total({ account_type: 'phone', sub_site: publicLocalData.value?.SiteUID || '', result: code == 0 ? 'success' : 'failed', error: code  })
  if (temporaryRef.value?.detectRiskChalleage(
    response,
    { params, message_type: 'login_confirm', scene: 'login' },
    ({ type, params }) => {
      if (type == 'close') return
      // 双重验证更新密码
      if (type == 'updatePwd') return (phonePassword.value = '')
      requestPhoneLogin(params)
    }))
    return
  if (code == 0) {
    const { info } = response || {}
    const subscribe_status = info?.member?.subscribe_status || ''
    if(subscribe_status == 1 && !setCommonShowPop() && relatedAcc.value?.from != 'order_list'){
      const value = {
        show: true,
        cb: () => {
          handleBack()
          setSendTypeCache()
          urlRedirection(false, response)
        }
      }
      store.commit('login/changeDialogs', { key: 'smsSubScribeModal', val: value })
      return
    }
    handleBack()
    setSendTypeCache()
    urlRedirection(false, response)
    return
  }

  if(code == 402922){
    evt.$emit('event-loading', false)
    riskVerifyChallenge(response, params, ({ paramsData, type }) => {
      if(type == 'error') {
        evt.$emit('event-loading', false)
        $toast(langText.value?.SHEIN_KEY_PWA_14899)
        return
      }
      if(type == 'close') {
        evt.$emit('event-loading', false)
        return
      }
      requestPhoneLogin(paramsData)
    })
    return
  }

  if (code == -410) {
    showInputTips(codeTips.value, langText.value?.SHEIN_KEY_PWA_18690)
    return
  }
  if (code == -411) {
    if (phoneAbt.value?.phoneRegisterPage == 'on' && relatedAcc.value?.global) {
      sensorsSend('2-8-38')
      return
    }
    showInputTips(codeTips.value, langText.value?.SHEIN_KEY_PWA_18725)
    return
  }
  if (code == 400544 || code == 400504) {
    showInputTips(codeTips.value, langText.value?.SHEIN_KEY_PWA_18885)
    return
  }
  if (code == 400593) {
    store.commit('login/setNewPrivacyModal', {
      from: 'Phone',
      cb: () => {
        params.clause_agree = 1
        requestPhoneLogin(params)
      }
    })
    return
  }
  const errTip = formatNormalErrorMessage(response)
  showInputTips(codeTips.value, errTip)
}
const handleSendChange = (isChange = true) => {
  let smsType = ''
  if (sendType.value == 'sms') {
    smsType = 'whatsApp'
  }
  if (sendType.value == 'whatsApp') {
    smsType = 'sms'
  }
  if (isChange) sendType.value = smsType
  store.commit('login/changeDialogs', { key: 'sendTypeChangePop', val: { show: false } })
  handleSendCode('phone_login_register_verify', smsType)
}

const handleSendResend = () => {
  store.commit('login/changeDialogs', { key: 'sendTypeChangePop', val: { show: false } })
  handleSendCode('phone_login_register_verify', sendType.value)
}

const setSendTypeCache = () => {
  if (!['sms', 'whatsApp'].includes(sendType.value)) return
  window.localStorage.setItem('l_p_s_c_used', sendType.value == 'sms' ? '1' : '2')
}

onMounted(() => {

})
// eslint-disable-next-line semi
;
</script>
<script>
export default {
  name: 'PhoneLoginPage'
}
</script>

<style lang="less" scoped>
.page__login_phoneLoginPage{
  padding: 0 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
    .main-content-body {
    flex: 1 1 100%;
  }
  .main-content-fotoer {
    flex: 0 0 auto;
  }
  h2 {
      padding: 32px 0 8px 0;
      .font-dpr(36px);
      line-height: 1;
    }
  .code__tips{
    color: @sui_color_gray_dark2;
    .font-dpr(28px);
    line-height: 1.2;
  }

  .actions {
    padding-top: 16px;
    button {
      width: 100%;
    }
  }
  .page__phoneLogin_password{
    h2 {
      padding-bottom: 8px;
    }
  }
  .page__login-switch-codeMode {
    margin-bottom: 0;
    margin-top: 13px;
  }
  .page__login-switch-password {
    padding-bottom: 16px;
  }
 /deep/.page__login-weekPrivacy {
    position: relative;
    bottom: 0;
    padding: 0;
    padding-bottom: 10px;
  }
}
</style>
