<template>
  <div
    class="benefit-bar-mini-main"
  >
    <div
      ref="scrollTextRef"
      class="benefit-bar-mini-main__scroll-text"
      :class="{
        'benefit-bar-mini-main__scroll-text_ar': GB_cssRight
      }"
    >
      <span
        v-if="prefixTitle"
        v-html="prefixTitle"
      ></span>
      <CountDown
        v-if="endTime && placeholderIndex > -1"
        v-slot="{timer}"
        class="benefit-bar-min-main__timer"
        :end-time-stamp="endTime"
      >
        {{ timer.timeObj.H }}:{{ timer.timeObj.M }}:{{ timer.timeObj.S }}
      </CountDown>
      <span
        v-if="suffixTitle"
        v-html="suffixTitle"
      ></span>
    </div>
  </div>
</template>

<script name="BenefitBarMiniMain" setup lang="ts">
import { computed, type Ref, ref, watch } from 'vue'
import CountDown from '../CountDown/Index.vue'

const { GB_cssRight = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const props = defineProps<{
  compressedTitle: string,
  endTime?: string
}>()

const scrollTextRef: Ref = ref(null)

const placeholderIndex = computed(() => props.compressedTitle.indexOf('{0}'))

const prefixTitle = computed(() => {
  if (placeholderIndex.value < 0) return props.compressedTitle
  return props.compressedTitle.slice(0, placeholderIndex.value).replaceAll(' ', '&nbsp;')
})

const suffixTitle = computed(() => {
  if (placeholderIndex.value < 0) return ''
  return props.compressedTitle.slice(placeholderIndex.value + 3, props.compressedTitle.length).replaceAll(' ', '&nbsp;')
})

const updateScrollTextStyle = () => {
  const { scrollWidth, parentElement } = scrollTextRef.value || {}
  const parentWidth = parentElement.clientWidth
  if (scrollWidth <= parentWidth) {
    scrollTextRef.value.style.animation = 'none'
  } else {
    const time = Math.floor(+scrollWidth * (3 / 100))
    scrollTextRef.value.style.animationDuration = `${time}s`
  }
}

watch(
  () => scrollTextRef.value,
  () => {
    if (!scrollTextRef.value) return
    updateScrollTextStyle()
  },
  {
    immediate: true,
    deep: true,
  },
)
</script>

<style lang="less">
.benefit-bar-mini-main {
  width: 100%;
  display: flex;
  overflow: hidden;
  height: 28/75rem;
  font-size: 12px;
  line-height: 1.2;

  &__scroll-text {
    position: relative;
    display: flex;
    white-space: nowrap;
    animation: linear 1 normal scroll-text forwards;
    font-weight: 860;
    &_ar {
      animation-name: scroll-text-ar;
    }
  }

  &__timer {
    // display: flex;
    // gap: 8/75rem;
    // color: #666;
    // font-size: 12px;
  }
}
@keyframes scroll-text {
  from {
    left: 0;
    transform: translateX(0%);
  }
  to {
    left: 100%;
    transform: translateX(-100%);
  }
}
// 阿拉伯语言滚动
@keyframes scroll-text-ar {
  from {
    left: 100%;
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
    left: 0;
  }
}
</style>
