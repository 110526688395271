import { computed } from 'vue'
import { unrefs, getRealPx } from 'public/src/pages/components/FilterBar/utils/index' 
import { HEADER_HEIGHT } from 'public/src/pages/components/FilterBar/utils/const'
import { PIC_TOP_NAV_LAYOUT } from 'public/src/pages/components/FilterBar/utils/const.js'
import { getStickyConfigZIndex } from './utils'

// 是否配置图文吸顶
export function useIsConfigSticky ({ catInfo, filterBar, list }) {
  return computed(() => {
    if (catInfo.value?.type === 'picks') return true
    return Boolean(filterBar.value?.filterConfig?.isStickyPicToNav && list.value?.length)
  })
  
}

// 是否配置图文小图模式
export function useIsConfigSmall ({ catInfo, filterBar, fixedSmallStatus }) {
  return computed(() => {
    // picks页纯文本tab，不需要small模式
    if (
      catInfo.value?.type === 'picks'
      && !filterBar.value?.filterConfig.isStickyPicksPic
    ) return false

    return fixedSmallStatus.value !== 'small'
  })
  
}
// picks图文
function getStickyConfigByPicks ({ filterBar }) {
  const {  isStickyPicksPic, isStickyCloudTags, isStickySearch } = filterBar?.filterConfig || {}
  let top = isStickySearch
    ? HEADER_HEIGHT - (isStickyPicksPic ? 2 : 0)
    : 0 // HEADER_HEIGHT - 2 避免标签点击吸顶后，下滑无法切为大图

  return {
    top: top,
    rootMarginTop: isStickyPicksPic ? (85) :  (HEADER_HEIGHT ),
    smallHeightDiff: isStickyPicksPic ? 40 : 0,
    translageY: isStickySearch ? 0 : HEADER_HEIGHT, // 下滑偏移,露出header
    zIndex: 15,
    stickyHeight: isStickyPicksPic ? 48 : HEADER_HEIGHT, // 吸顶高度，后面吸顶元素使用吸顶top= 当前top + stickyHeight
    stickyDiff: isStickyCloudTags ? (getRealPx(44) - 12) : 0 // 图文&标签云吸顶，所以滚动距离需要加上NavBar高度
  }
}

// 可跳转类目图文
function getStickyConfigByBlank ({ filterBar, isConfigSticky }) {
  const { isStickySearch } = filterBar?.filterConfig || {}
  const top = isStickySearch ? HEADER_HEIGHT - 2 : 0

  return {
    top,
    rootMarginTop: top + 146, // 146为跳转类目图文的高度
    smallHeightDiff: 40,
    translageY: isStickySearch ? 0 : HEADER_HEIGHT, // 下滑偏移,露出header
    zIndex: getStickyConfigZIndex(isConfigSticky, isStickySearch),
    stickyHeight: Math.max(48, HEADER_HEIGHT), // 吸顶高度，后面吸顶元素使用吸顶top= 当前top + stickyHeight
    stickyDiff: 0 // 图文&标签云吸顶，所以滚动距离需要加上NavBar高度
  }
}

// list图文
function getStickyConfigByList ({ catInfo, filterBar, dynamicStickyConfig, isConfigSticky }) {
  const { isStickySearch } = filterBar?.filterConfig || {}
  const { top: dynamicTop = 0 } = dynamicStickyConfig || {}
  const top = dynamicTop || (isStickySearch ? HEADER_HEIGHT - 2 : 0)
  const isStorePage = catInfo.pageName === 'page_store'
  return {
    top: isStorePage && !isConfigSticky ? 0 : top,
    rootMarginTop: top + 109, // 146为跳转类目图文的高度
    smallHeightDiff: 40,
    translageY: isStickySearch ? 0 : HEADER_HEIGHT, // 下滑偏移,露出header
    zIndex: getStickyConfigZIndex(isConfigSticky, isStickySearch),
    stickyHeight: 48, // 吸顶高度，后面吸顶元素使用吸顶top= 当前top + stickyHeight
    stickyDiff: 0 // 图文&标签云吸顶，所以滚动距离需要加上NavBar高度
  }
}

// tabs图文
function getStickyConfigByTabs ({ filterBar, isConfigSticky }) {
  const { isStickySearch } = filterBar?.filterConfig || {}
  const top = isStickySearch ? HEADER_HEIGHT - 2 : 0

  return {
    top,
    rootMarginTop: top + 145, // 146为跳转类目图文的高度
    smallHeightDiff: 40,
    translageY: isStickySearch ? 0 : HEADER_HEIGHT, // 下滑偏移,露出header
    zIndex: getStickyConfigZIndex(isConfigSticky, isStickySearch),
    stickyHeight: 89, // 吸顶高度，后面吸顶元素使用吸顶top= 当前top + stickyHeight
    stickyDiff: 12 // 图文&标签云吸顶，所以滚动距离需要加上NavBar高度
  }
}

// 卡片图文导航
function getStickyConfigByCard ({ filterBar, isConfigSticky }) {
  const { isStickySearch } = filterBar?.filterConfig || {}
  const top = isStickySearch ? HEADER_HEIGHT - 2 : 0

  return {
    top,
    rootMarginTop: top + 145, // 146为跳转类目图文的高度
    smallHeightDiff: 40,
    translageY: isStickySearch ? 0 : HEADER_HEIGHT, // 下滑偏移,露出header
    zIndex: getStickyConfigZIndex(isConfigSticky, isStickySearch),
    stickyHeight: 48, // 吸顶高度，后面吸顶元素使用吸顶top= 当前top + stickyHeight
    stickyDiff: -2, // 图文&标签云吸顶，所以滚动距离需要加上NavBar高度
    opacityTransition: true
  }
}

export function useStickyConfig ({ catInfo, filterBar, picTopNavLayout, dynamicStickyConfig, isConfigSticky }) {
  return computed(() => {
    // if (!isConfigSticky.value) return {} // 非图文吸顶

    switch (picTopNavLayout.value) {
      case PIC_TOP_NAV_LAYOUT.PICKS:
        return getStickyConfigByPicks(unrefs({ filterBar, isConfigSticky })) 
      case PIC_TOP_NAV_LAYOUT.BLANK:
        return getStickyConfigByBlank(unrefs({ filterBar, isConfigSticky }))
      case PIC_TOP_NAV_LAYOUT.TABS:
        return getStickyConfigByTabs(unrefs({ filterBar, isConfigSticky }))
      case PIC_TOP_NAV_LAYOUT.CARD:
        return getStickyConfigByCard(unrefs({ filterBar, isConfigSticky }))
      default:
        return getStickyConfigByList(unrefs({ filterBar, dynamicStickyConfig, catInfo, isConfigSticky }))
    }
  })
}
