import { ref, computed, nextTick, onActivated } from 'vue'
import { SBC_ENTRY_FOLLOW_FEATURE_KEYS } from '@modules/homepage/ccc-stuff/sbc/constants'
import { getImageRefreshedSbcEntryItems } from 'public/src/services/api/ccc'
import { handleStartCollectData, handleEndCollectData } from 'public/src/pages/common/userActionTracker/core/index.js'
import { distributeItemsToSlides } from '@modules/homepage/ccc-stuff/sbc/distribute'
import { customizedLeadingDebounce, preHandleForSbcDataItem } from './miscs'

/**
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderInfoSummary} SbcPlaceholderInfoSummary
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderDataItem} SbcPlaceholderDataItem
 */

/**
 * 追踪 SBC 入口点击后的行为，当再返回 SBC 判断是否需要刷新入口图
 * 
 * @param {{
 *   contentData: import('vue').Ref<any>
 *   sceneDataFromCommonProps: import('vue').Ref<any>
 *   componentIndexInPage: import('vue').Ref<number>
 *   itemsDataList: import('vue').Ref<SbcPlaceholderDataItem[]>
 *   slidesData: import('vue').Ref<SbcPlaceholderDataItem[][]>
 *   virtualData: import('vue').Ref<SbcPlaceholderDataItem[][]>
 *   sbcInfoSummary: import('vue').Ref<SbcPlaceholderInfoSummary>
 * }} params
 */
export function useSbcEntryFollowAction({
  contentData,
  sceneDataFromCommonProps,
  componentIndexInPage,
  itemsDataList,
  slidesData,
  virtualData,
  sbcInfoSummary,
}) {

  const markingSbcEntryIndexTuple = ref(null)

  const markingSbcEntryItem = computed(() => {
    if (!markingSbcEntryIndexTuple.value) return null

    const { colIndex, rowIndex } = markingSbcEntryIndexTuple.value
    return slidesData.value[colIndex][rowIndex] ?? null
  })

  const startSbcEntryFollowActionTrack = ({
    colIndex,
    rowIndex,
  }) => {
    // 通过 slidesData.value[colIndex][rowIndex] 获取当前点击的数据项
    markingSbcEntryIndexTuple.value = { colIndex, rowIndex }
    // 标记 sbc 入口被点击、跳转去了列表页
    markingSbcEntryItem.value.isJumpList = '1'

    // 开始收集 点击列表商品、加购物车 的行为
    handleStartCollectData(SBC_ENTRY_FOLLOW_FEATURE_KEYS.GOODS_LIST_CLICK)
    handleStartCollectData(SBC_ENTRY_FOLLOW_FEATURE_KEYS.ADD_TO_CART)
  }
  const sbcEntryImgRefreshReqParams = computed(() => {
    return {
      props: {
        items: [
          ...itemsDataList.value,
        ]
      }
    }
  })
  const endSbcEntryFollowActionTrack = async () => {
    if (!markingSbcEntryItem.value) {
      return
    }

    markingSbcEntryItem.value.isJumpCart = handleEndCollectData(SBC_ENTRY_FOLLOW_FEATURE_KEYS.ADD_TO_CART)
    markingSbcEntryItem.value.isProduceClick = handleEndCollectData(SBC_ENTRY_FOLLOW_FEATURE_KEYS.GOODS_LIST_CLICK)

    // 发送请求到中间层刷新入口图，获取到新的 sbc items
    const { code, info } = await requestRefreshSbcEntryImage(sbcEntryImgRefreshReqParams.value)
    if (
      code !== '0'
      || !info
      || !Array.isArray(info.items)
    ) {
      return
    }

    // 更新 slidesData
    const newItemsDataList = info.items
    newItemsDataList.forEach(item => preHandleForSbcDataItem({
      propData: contentData,
      sceneData: sceneDataFromCommonProps,
      index: componentIndexInPage,
      item,
    }))

    // eslint-disable-next-line require-atomic-updates
    itemsDataList.value = newItemsDataList

    slidesData.value = distributeItemsToSlides({
      sbcInfoSummary,
      itemsDataList,
    })
    markingSbcEntryIndexTuple.value = null

    nextTick(() => {
      const currentVirtualLength = virtualData.value.length
      virtualData.value = slidesData.value.slice(0, currentVirtualLength)
    })
  }
  /**
   * 请求中间层，可能刷新 SBC 入口图
   * 由于性能考量，需要限制请求频率
   */
  const requestRefreshSbcEntryImage = customizedLeadingDebounce({
    func: getImageRefreshedSbcEntryItems,
    wait: sbcInfoSummary.value.iconRefreshTimeGap * 1e3,
    options: {
      onNotCall: () => {
        // 请求被时间间隔阻断，需清除行为分析记录
        if (!markingSbcEntryItem.value) return
        markingSbcEntryItem.value.isJumpList = (void 0)
        markingSbcEntryItem.value.isJumpCart = (void 0)
        markingSbcEntryItem.value.isProduceClick = (void 0)
      }
    }
  })

  onActivated(() => {
    endSbcEntryFollowActionTrack()
  })

  return {
    startSbcEntryFollowActionTrack,
  }
}
