
<script lang="jsx">
export default {
  name: 'ImmersiveStaticBg',
  functional: true,
  props: {
    item: {
      type: Array,
      default: () => []
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    cutImg: {
      type: Function,
      default: () => {}
    },
    realBannerHeight: {
      type: Number,
      default: 0
    },
  },
  render(_,  { props = {} }) {
    const { item, activeIndex, realBannerHeight, } =  props
    return (
      <div class="c-immersiveBanner__static-swiper">
        {/* 渲染单个div */}
        <div
          class="c-immersiveBanner__static-slide"
          style={{
            transform: `translateY(-${activeIndex * realBannerHeight}rem)`
          }}
        >
          { 
            item.map((it) => (
              <div
                class={{ 
                  'c-immersiveBanner__bgImage': true, 
                }}
                style={{
                  backgroundImage: (Number(it.bannerType) === 2 && it.themeColor) ? `url(${ it.image.src }), ${ it.bannerBgColor }` : `url(${ it.image.src })`,
                  height: `${ realBannerHeight }rem`,
                }}
              >
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}
</script>

