import { isCustomizationProduct } from '../../utils/common'
import type { FindSimilarText } from '../../../../types/mobile/index.ts'
/**
 * FindSimilarText 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }): FindSimilarText.Props {
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'

  return {
    isShow: data.value.aggregateProductBusiness?.cartRowViewControl?.findSimilarView?.showNegativeFindSimilar === '1',
    isShowMask: isNewcomerItem,
    isClick: !isCustomizationProduct(data.value),
    text: data.value.aggregateProductBusiness?.cartRowViewControl?.findSimilarView?.negativeFindSimilarText || language.value.SHEIN_KEY_PWA_16789 || '',
  }
}
