<!-- eslint-disable vue/no-deprecated-slot-attribute-->
<template>
  <div
    v-if="isShow"
    ref="root"
    class="bsc-cart-item-voucher-tag"
    :style="style"
  >
    <div
      class="bsc-cart-item-voucher-tag__left"
      @click.stop="handleShowPopover"
    >
      <div class="bsc-cart-item-voucher-tag__text">
        {{ text }}
      </div>
      <template v-if="suffixIcon">
        <template v-if="tipDisabled">
          <img
            :src="suffixIcon"
            alt=""
            class="bsc-cart-item-voucher-tag__icon"
          />
        </template>
        <template v-else>
          <SPopover
            v-model="showPopover"
            trigger-type="user"
            show-close-icon
            :prop-style="propStyle"
          >
            <img
              slot="reference"
              :src="suffixIcon"
              alt=""
              class="bsc-cart-item-voucher-tag__icon"
              @click.stop="handleShowPopover"
            />
            <div class="bsc-cart-item-voucher-tag__popover-content">
              {{ tip }}
            </div>
          </SPopover>
        </template>
      </template>
    </div>
    <div
      v-if="subText"
      class="bsc-cart-item-voucher-tag__right"
    >
      <div class="bsc-cart-item-voucher-tag__line"></div>
      <div class="bsc-cart-item-voucher-tag__subtext">
        {{ subText }}
      </div>
    </div>
  </div>
</template>

<script name="VoucherTag" lang="ts" setup>
import { ref, computed } from 'vue'
import { hexToRgb } from '@shein/common-function'
import SPopover from '@shein/sui-mobile/lib/popover'
import { useScrollParent } from '../../../hooks/useScrollParent'
import { useEventListener } from '../../../hooks/useEventListener'

interface IProps {
  /**
   * 是否显示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 标签文案
   */
  text: string
  /**
   * 提示文案
   */
  tip: string
  /**
   * 是否禁用提示
   */
  tipDisabled: boolean
  /**
   * 提示是否追加到body
   */
  tipAppendToBody: boolean
  /**
   * 附加文案
   */
  subText: string
  /**
   * 后缀图标
   */
  suffixIcon: string
  /**
   * 气泡提示样式
   */
  propStyle?: any
  /**
   * 滚动的父元素
   */
    scroller?: HTMLElement
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 文字颜色
   */
  textColor: string
  /**
   * 最大宽度，需要单位
   */
  maxWidth?: string
}

// props
const props = withDefaults(defineProps<IProps>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  tip: '',
  tipDisabled: false,
  tipAppendToBody: true,
  subText: '',
  suffixIcon: '',
  propStyle() {
    return {
      width: 'max-content',
      maxWidth: '240px',
      zIndex: Number.MAX_SAFE_INTEGER,
      transform: `translate3d(0,0,${Number.MAX_SAFE_INTEGER}px)`,
    }
  },
  bgColor: '#ffe6e6',
  textColor: '#ff3f3f',
  maxWidth: '100%',
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--bg-color': props.bgColor,
  '--text-color': props.textColor,
  '--separator-color': hexToRgb({ hex: props.textColor, opa: 0.4 }),
  maxWidth: props.maxWidth,
}))

const showPopover = ref(false)
const handleShowPopover = () => {
  showPopover.value = !showPopover.value
}

const root = ref<HTMLElement>()
const scrollParent = useScrollParent(root)
const scroller = computed(() => props.scroller || scrollParent.value)

const check = () => {
  if (showPopover.value) showPopover.value = false
}

useEventListener('scroll', check, {
  target: scroller,
  passive: true,
})

</script>

<style lang="less">
.bsc-cart-item-voucher-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  max-width: 100%;
  height: 16px;
  line-height: 1;
  font-size: 10px;
  color: var(--text-color);
  background: var(--bg-color);
  border-radius: 2px;
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__left {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    gap: 2px;
  }

  &__right {
    flex-shrink: 1;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
  }

  &__text {
    font-weight: 600;
  }

  &__subtext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__line {
    margin: 0 4px;
    height: 10px;
    border-left: 1px solid var(--separator-color);
  }

  &__icon {
    width: 12px;
    height: 12px;
  }
}
</style>

<style lang="less">
.bsc-cart-item-voucher-tag__popover-content {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  white-space: normal;
}
</style>
