<template>
  <div>
    <SLazyMount>
      <SDrawer
        class="token-cvv-drawer"
        :visible.sync="isShow"
        :direction="'btt'"
        :append-to-body="true"
        @close="close"
        @close-from-icon="cancel"
        @close-from-mask="cancel"
      >
        <template #top> {{ language.BS_KEY_PAY_1142 }} </template>
        <div class="cvv-block">
          <div class="card-num">
            {{ nowUseTokenData && nowUseTokenData.card_no }}
          </div>
          <div class="input-block">
            <input
              v-model="cvvData"
              :maxlength="tokenCvvInputMax"
              icon-help="text"
              class="mshe-input"
              placeholder="CVV/CVC/CID"
              @click="tokenCvvClear"
              @blur="checkTokenCvv"
              @keyup="handlerKeyup"
            />
            <Icon
              class="cvv-tip-icon"
              name="sui_icon_doubt_16px_2"
              size="16px"
              :is-rotate="cssRight"
              @click="isShowCvvTip = true" />
          </div>
          <p
            v-if="error.length > 0"
            class="err-tip">
            {{ error }}
          </p>
        </div>
        <template #footer>
          <div class="footer-wrap">
            <SButton
              :type="['primary', 'H72PX']"
              width="100%"
              @click="handlerPay">
              {{ language.BS_KEY_PAY_1140 }}
            </SButton>
          </div>
        </template>
      </SDrawer>
    </SLazyMount>
    <!-- cvv 提示弹窗 -->
    <SLazyMount>
      <SDialog
        :visible.sync="isShowCvvTip"
        :show-close="true"
        :append-to-body="true"
        @close="closeCvvTip">
        <div class="cvv-tip-content">
          <p tabindex="0">
            {{ language.BS_KEY_PAY_1139 }}
          </p>
          <img
            :src="imgSrc"
            aria-hidden="true" />
        </div>
        <template #footer><div></div></template>
      </SDialog>
    </SLazyMount>
  </div>
</template>

<script name="CvvDrawer" setup lang="ts">
/*
 * 支付方式组件: cvv 抽屉
 *
 * */

// components
import { Drawer as SDrawer, Button as SButton, Dialog as SDialog, LazyMount as SLazyMount } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'

// utils
import { ref, computed, defineEmits, nextTick, watch } from 'vue'
import { PaymentsStore } from '../../../hooks/usePayments'
import { type LangKeys } from '../../../common/languages'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { debuggerLog } from '../../../utils'

// const VISA_ID = 'visa'
const AMEX_ID = 'amex'
const MAESTRO_ID = 'maestro'
// const MASTERCARD_ID = 'mastercard'
const SPECIAL_CVV_LIST = ['0000'] // 特殊的不支持输入的 cvv 卡号

interface CvvDrawerProps {
  language: Record<LangKeys, string>;
  paymentsStore: PaymentsStore;
}

const props = withDefaults(defineProps<CvvDrawerProps>(), {})

const isShow = ref(false)
const cvvData = ref('')
const error = ref('')
const isShowCvvTip = ref(false)
const imgSrc = ref(
  'https://img.ltwebstatic.com/images3_ccc/2024/09/02/2b/172526160143947d8be76dd214e4230f49b34adc84.jpg',
)
const isHandlerPay = ref(false) // 是否点击了支付按钮
const installments = ref(1)
const coverData = ref({})

const emit = defineEmits(['pay-now'])

const { cssRight, host } = useAppConfigs()?.$envs || {}

const selectedPayment = computed(() => {
  return props?.paymentsStore?.selectedPayment || {}
})

const cardId = computed(() => {
  return nowUseTokenData.value?.id || ''
})

const cardType = computed(() => {
  return (nowUseTokenData.value?.card_type || '')?.toLowerCase() || ''
})

const tokenCvvInputMax = computed(() => {
  return cardType.value.length == 0 || cardType.value == AMEX_ID ? 4 : 3
})

const isShowCvvDrawer = computed(() => {
  return nowUseTokenData.value?.need_cvv === '1'
})

// 区分是路由卡还是路由卡分期
const nowUseTokenData = computed(() => {
  return props?.paymentsStore?.editablePaymentInfo?.[selectedPayment.value?.code]?.card_token_info || {}
})

const isMAESTRO = computed(() => {
  return cardType.value == MAESTRO_ID // 是否是 maestro 卡
})

const handlerKeyup = () => {
  cvvData.value = cvvData.value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
}

const checkTokenCvv = () => {
  handlerKeyup()
  const cvv = cvvData.value.replace(/\s/g, '')
  error.value = ''

  const regObj = {
    AMEX: /^\d{3,4}$/,
    MAESTRO: /^(\d{3}|\s{0})$/,
    other: /^\d{3}$/,
  }

  let reg = /^\d{3,4}$/ // 默认匹配不上,卡种返回异常
  if (cardType.value) {
    reg = regObj[cardType.value.toUpperCase()] || regObj['other']
  }

  const isNumOfDigitsError = !reg.test(cvv)

  const isInvalidNumber = SPECIAL_CVV_LIST.includes(cvv)

  // 当卡种返回非AE卡（包括V/M）时，输入框CVV支持输入3位000；
  if (!isNumOfDigitsError || cvv == '000') return

  // 当卡种返回AE卡时，输入框CVV支持输入3位000或者4位0000；
  if (cardType.value == AMEX_ID && cvv == '0000') return

  if (cvv.length === 0 || isNumOfDigitsError || isInvalidNumber) {
    error.value = host?.includes('localhost') ? 'props.language.BS_KEY_PAY_1143' : props.language.BS_KEY_PAY_1143
  }
}

const tokenCvvClear = () => {
  error.value = ''
}

const closeCvvTip = () => {
  isShowCvvTip.value = false
}

const resetInfo = () => {
  cvvData.value = ''
  error.value = ''
  isHandlerPay.value = false
  coverData.value = {}
}

const handlerPay = () => {
  isHandlerPay.value = true
  checkTokenCvv()
  if (error.value?.length > 0) {
    return
  }
  isShow.value = false
  emit('pay-now', {
    cvv: (cvvData.value || '').trim(),
    installments: installments.value || 1,
    isMAESTRO: isMAESTRO.value,
    coverData: coverData.value,
    errorCb: () => {
      resetInfo()
    },
  })
  tokenCvvClear()
  nextTick(() => {
    resetInfo()
  })
}

const close = () => {
  isShow.value = false
  tokenCvvClear()
}

const cancel = () => {
  close()
  props.paymentsStore?.paymentEventBus?.paymentValidSendBi?.emit({
      result_reason: '1_10',
  })
  resetInfo()
}

const showDrawer = () => {
  isShow.value = true
}

const validateByPay = (opts?: Trade_PayToolKit.PreCreatePaymentVerifyOptions) => {
  debuggerLog('cvv validateByPay >>>', opts)
  if (opts?.installments) {
    installments.value = opts.installments as number
  }
  if (opts?.lastAddOrderExtraParams?.coverData) {
    coverData.value = opts.lastAddOrderExtraParams.coverData
  }
  if (opts?.has_cvv) return {
    validateResult: true,
    scene: 'token_cvv_drawer',
  }

  checkTokenCvv()

  const isMAESTROHandlerPay = isMAESTRO.value && isHandlerPay.value // maestro 卡点击支付按钮
  const isNeedShowByMAESTRO = isShowCvvDrawer.value && !isHandlerPay.value && isMAESTRO.value
  if ((isShowCvvDrawer.value && (error.value?.length > 0 && !isMAESTROHandlerPay)) || isNeedShowByMAESTRO) {
    error.value = ''
    showDrawer()
    return {
      validateResult: false,
      scene: 'token_cvv_drawer',
      metric_scene: 'payment_token_cvv_empty',
      saParams: {
        result_reason: '1_10',
      },
    }
  }

  return {
    validateResult: true,
    scene: 'token_cvv_drawer',
  }
}

watch(() => cardId.value, () => {
  resetInfo()
})

defineExpose({
  validateByPay,
})
</script>

<style lang="less">
.token-cvv-drawer {
  .cvv-block {
    padding: 20/75rem;

    .card-num {
      color: #222222;
      font-size: 14px;
      font-family: SF UI Text;
      font-weight: 400;
      text-transform: uppercase;
      word-wrap: break-word;
      width: 100%;
      text-align: center;
      margin-bottom: 16/75rem;
    }

    .input-block {
      width: 100%;
      position: relative;
    }
    .cvv-tip-icon {
      position: absolute;
      right: 24/75rem;
      top: 24/75rem;
      color: #959595;
    }
    .err-tip {
      color: #bf4123;
      font-size: 12px;
      font-family: Arial;
      font-weight: 400;
      word-wrap: break-word;
    }

    .mshe-input {
      border: 1px #e5e5e5 solid;
      height: 0.96rem;
      width: 100%;
      padding: 0 0.32rem;
      &::-webkit-input-placeholder {
        color: #ccc;
      }
    }
  }

  .S-drawer__normal-footer {
    margin-top: unset;
    height: unset;
    line-height: unset;
    padding: 16/75rem 24/75rem;
  }
}

.cvv-tip-content {
  text-align: center;
  p {
    color: #999;
  }
  img {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
</style>
