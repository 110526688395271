import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { getQueryString } from '@shein/common-function'
import Vue from 'vue'

const mutations = {
  setSuggestedSalePriceType(state, data) {
    state.suggestedSalePriceType = data
  },
  setHooksGoods(state, data) {
    state.hooksGoods = data
  },
  setAbtInfo(state, data) {
    state.abtInfo = data
  },
  setPageType(state, data) {
    state.pageType = data
  },
  setLanguage (state, data) {
    state.language = data
  },
  resetLocals(state, payload) {
    if (payload?.locals) return state.locals = Object.freeze(payload.locals)
    if (typeof window === 'undefined') return

    const { WEB_CLIENT, IS_RW, SiteUID, host, lang, langPath,  appLanguage, GB_cssRight, is_include_critical_css, LAZY_IMG, LAZY_IMG_PIXEL, IMG_LINK, LAZY_IMG_SOLID_COLOR, dateLangMap, RESOURCE_SDK, PUBLIC_CDN } = gbCommonInfo
    const _currency = UserInfoManager.get({ key: 'currency' })
    state.locals = {
      WEB_CLIENT,
      IS_RW,
      SiteUID,
      host,
      lang,
      langPath,
      appLanguage,
      GB_cssRight,
      is_include_critical_css,
      LAZY_IMG,
      LAZY_IMG_PIXEL,
      IMG_LINK,
      LAZY_IMG_SOLID_COLOR,
      dateLangMap,
      currency: _currency,
      RESOURCE_SDK,
      PUBLIC_CDN
    }
  },
  initItemConfig (state, payload) {
    const {  AllListStarReview, NewStarRating } = state?.abtInfo ?? {}
    const { cardAbtComplete } = state.APOLLO_CONFIG
    // const { goodsNameLine } = state.cccConfig?.mobileVerticalView || {}
    const showStarRating = NewStarRating?.p?.NewStarRating == 'StarRating'
    const isCardAbtComplete = cardAbtComplete === 'on'
    state.itemConfig = {
      ...state.itemConfig,
      ...(payload?.cardConfig ?? {}),
      itemDAEventClickId: '2-3-1',
      itemDAEventExposeId: '2-3-2',
      // 埋点
      useOwnClickExposeAnalysis: false,
      sa: {
        activity_from: 'goods_list'
      },
      configReady: true,
      // ------ 下面是商品项的配置
      showSelectOnLongClick: true,  // 长按反馈flag
      isListstarB: isCardAbtComplete ? false : AllListStarReview?.p?.liststar === 'B',
      showStarComment: !showStarRating && (isCardAbtComplete ? true : ['star', 'B'].includes(AllListStarReview?.p?.liststar)), // 星级评论
      showStarRating: showStarRating, // 星级评分
      rankingLabelClickable: true, // 排行榜标签是否可点击
    }
  },
  initCccConfig (state, data) {
    state.cccConfig = data
  },
  initSheinClubInfo (state, data) {
    state.sheinClubInfo = data
  },
  initCatInfo (state, data) {
    state.catInfo = data
  },
  initAtomicFields (state, data) {
    state.atomicFields = data
  },
  initGoodsItemInfo (state) {
    state.goodsItemInfo = new GoodsItemInfo(({
      locals: state.locals,
      itemLanguage: state.language
    }))
  },
  setApolloConfig(state, data) {
    state.APOLLO_CONFIG = data
  },
  initFetchSearchTermsParams (state) {
    state.fetchSearchTermsParams = {
      scene: 'home',
      word_type: 1,
      list_scene: (getQueryString({ key: 'goods_id' }) && 'outside_station_search') || '',
      goods_id: getQueryString({ key: 'goods_id' }) || '',
      cat_id: state?.hooksGoods?.cat_id || '',
    }
  },
  changeFetchSearchTermsParams (state, { key, value }) {
    Vue.set(state.fetchSearchTermsParams, key, value)
  },
  initSearchBox (state, data) {
    state.isShowSearchInput = data || false
  },
  changeIsBff(state, data) {
    state.isBff = data
  },
  changeCategory(state, data) {
    state.category = data
  },
  changeFirstLoadGoods(state, data) {
    state.firstLoadGoods = data
  }
}

export default mutations
