<template>
  <div
    v-expose="{
      id: 'expose_shipping_add:simple',
      data: saAddItemObj
    }"
    v-tap="{
      id: 'click_shipping_add:simple',
      data: saAddItemObj
    }"
    :class="prefixCls('add-item')"
    @click.stop="handleAddFree"
  >
    <div
      :class="prefixCls('add-item__desc')"
      v-html="hints"
    >
    </div>
    <span :class="prefixCls('add-item__btn')">
      {{ language.SHEIN_KEY_PWA_29778 }}
    </span>
  </div>
</template>

<script setup name="AddItem">
import { computed, reactive } from 'vue'
import { template } from '@shein/common-function'
import { prefixCls } from '../../utils/index'
import AddOnItemPop  from 'public/src/pages/common/addOnItem/index.js' // 促销和免邮凑单
import AddOn from 'public/src/pages/common/add-on/index.js'
import { updateCartsAndCheckout } from '@/public/src/pages/checkout_new/utils/store'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { getShippingContentInfo } from '@/public/src/pages/checkout_new/pages/shipping_method/utils/getActivityAddItem'

const { buyNowNo, quick_shipping, bagsInfo, free_mall_info } = useMapState(['buyNowNo', 'checkout.quick_shipping', 'shoppingBagsState.bagsInfo', 'checkout.free_mall_info'])

const model = reactive({
  visible: false,
  addGoodsTimes: 0,
  addFreeStatus: 0
})

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  transportType: {
    type: String,
    default: ''
  },
  freeShippingAddItem: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

const emits = defineEmits(['addSuccess'])

const isCouponType = computed(() => {
  return +props.freeShippingAddItem.add_item_type === 1
})

const goodsList = computed(() => {
  return bagsInfo.value.all?.map(item => {
    return {
      cate_id: item.product.cate_id,
      goods_id: item.product.goods_id
    }
  })
})

const saAddItemObj = computed(() => {
  const addItem = props.freeShippingAddItem || {}
  return {
    mall_code: props.mallCode || '-',
    shipping_method: props.transportType || '-',
    type: addItem.add_item_type === 1 ? 'coupon' : 'activity',
    goods_range: addItem.free_shipping_range || '-',
    progress: addItem?.add_item_popup_info?.progressPercent,
    origin_price: addItem.goodsPrice || '-'
  }
})

const propsParamsResult = async () => {
  const { freeShippingAddItem } = props

  const goodsIdList = goodsList.value.map(item => item.goods_id) // 商品ID
  const cateIdList = goodsList.value.map(item => item.cate_id) // 类目ID

  if(isCouponType.value) {
    const couponCode = freeShippingAddItem?.coupon_code || ''
    if(!couponCode) throw new Error('couponCode no Data')

    return  {
      props: {
        type: 'coupon',
        coupon: couponCode,
        config: {
          isBuyNow: !!buyNowNo.value,
          casualCheckoutNo: buyNowNo.value,
          continueBtnToClose: true,
          continueBtnUseLangKey: 'SHEIN_KEY_PWA_30444',
          isCloseBusinessCartEntry: true,
          isCloseIncentivePoint: true,
        },
        queryInfo: {
          addOnType: 6,
          sceneId: 152,
          goodsIds: goodsIdList,
          cateIds: cateIdList,
          quickship_prior: +quick_shipping.value.current_quick_ship_self_status ? 1 : 0
        },
        saInfo: {
          promotion_code: couponCode,
          activity_from: 'checkout_shipping_coupon_add',
          abtest: 'addnewhotJson',
          state: 'checkout_shipping_coupon_add'
        }
      }
    }
  }

  const freeMallInfo = getShippingContentInfo(free_mall_info.value)

  const activityFreeParams = {
    cartsApiData: {
      carts: goodsList.value.map(product => ({ product }))
    },
    mallCode: props.mallCode,
    mallShippingInfo: freeMallInfo,
    isQuickship: +quick_shipping.value.current_quick_ship_self_status ? 1 : 0,
    transportType: props.transportType,
    isBuyNow: !!buyNowNo.value,
    casualCheckoutNo: buyNowNo.value,
  }

  return AddOnItemPop.getOpts4FreeShippingFromCheckout(activityFreeParams)
}

// 免邮凑单弹窗 activity（活动免邮凑单） | coupon（优惠券免邮凑单）
const handleAddFree = async (e) => {
  e.stopPropagation()
  const propsParams  = await propsParamsResult()
  const addOnConfig = {
    ...propsParams,
    on: { // 钩子
      close: (freeInfo) => {
        model.visible = false

        model.addFreeStatus = isCouponType.value
          ?
          [1, 2].includes(+freeInfo?._data?.is_satisfied) ? 1 : 0
          :
          freeInfo.isActivityStop || freeInfo.isActivityStop ? 1 : 0

        if(model.addGoodsTimes > 0) {
          model.addGoodsTimes = 0

          emits('addSuccess', { status: model.addFreeStatus })
        }
      },
      async updateCart (cartInfo) {
        cartInfo && model.addGoodsTimes++
        await updateCartsAndCheckout({ checkout: { current_action: 'add_cart_item' } })
        const propsParams  = await propsParamsResult()
        // 仅处于打开状态才能更新, 规避券弹窗使用旧的方法更新
        AddOn?.updateOpts(propsParams)
        AddOnItemPop?.isOpened() && AddOnItemPop?.updateOpts(propsParams)
      },
      beforeOpen: () => {
      }
    },
  }

  model.visible = true

  if (isCouponType.value) {
    return AddOn.open(addOnConfig) // 券走中间层融合
  }

  AddOnItemPop.open(addOnConfig)
}


const hints = computed(() => {
  const { tip, replaceText } = props.freeShippingAddItem?.show_tip || {}
  const args = replaceText.map(item => {
    return `<span style="color:${item.color}; font-weight: ${item.bold ? 'bold' : 'normal'}">${item.text}</span>`
  })

  return template(...args, tip)
})
</script>

<style lang="less">
@import "../../variables.less";

.@{prefixCls}add-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8/75rem 16/75rem;
  background: #F3FAF6;
  .font-dpr(24px);
  font-weight: 600;
  position: relative;

  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    background: #F3FAF6;
    top: 0;
    .left(20px);
    transform: translateY(-50%) rotate(45deg);
  }

  &__desc {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__btn {
    display: inline-block;
    .margin-l(8/75rem);
    padding: 8/75rem 20/75rem;
    min-width: 88/75rem;
    max-width: 140/75rem;
    border-radius: 100px;
    border: 1px solid @sui_color_brand;

    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>
