import { nextTick } from 'vue'

export const useApplePayDialog = (paymentsStore) => {
  let applePayDialogCloseCb = () => {}

  const openApplePayDialog = ({ handleOpen, handleClose }) => {
    if (typeof handleClose === 'function') {
      applePayDialogCloseCb = handleClose
    }
    paymentsStore?.updateState('showApplePayDialog', true)
    nextTick(() => {
      handleOpen?.()
    })

    const cancelDialog = () => {
      paymentsStore?.updateState('showApplePayDialog', false)
      nextTick(() => {
        handleClose?.()
      })
    }
    return cancelDialog
  }

  const handleCloseApplePayDialog = () => {
    applePayDialogCloseCb()
    paymentsStore?.updateState('showApplePayDialog', false)
  }

  return {
    openApplePayDialog,
    handleCloseApplePayDialog,
  }
}
