import Vue from 'vue'
import coldModulesUpdate from './coldModulesUpdate'
import other from './other'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { getDetailCommonInfo } from 'public/src/pages/goods_detail_v2/utils/fs.js'
import { hotModulesAdaptersHandler } from 'public/src/pages/goods_detail_v2/utils/bff.js'
import { getDefaultHotModules } from '../state/defaultState.js'
export default {
  ...coldModulesUpdate,
  resetHotModules(state) {
    state.hotModules = getDefaultHotModules()
    this.commit('productDetail/resetHotModules', undefined, { root: true })
  },
  initCold(state, payload) {
    if (!payload.modules || !payload.modules.productInfo) return
    const modules = payload.modules || {}
    // 按照模型分发到每个函数下 每个模型不同处理形式
    this.commit('newProductDetail/updateBrandAndSeries', modules.brandAndSeries)
    this.commit('newProductDetail/updateProductInfo', modules.productInfo) // TODO
    this.commit('newProductDetail/updateOtherOptions', modules.otherOptions)
    this.commit('newProductDetail/updateGetTheLook', modules.getTheLook)
    this.commit('newProductDetail/updatePromotion', modules.promotion)
    this.commit('newProductDetail/updateRecommend', modules.recommend)
    this.commit('newProductDetail/updateCategory', modules.category)
    this.commit('newProductDetail/updatePageInfo', modules.pageInfo) // TODO
    this.commit('newProductDetail/updateShipping', modules.shipping)
    this.commit('newProductDetail/updateComment', modules.comment)
    this.commit('newProductDetail/updateCoupon', modules.coupon)
    this.commit('newProductDetail/updateOthers', modules.others)
    this.commit('newProductDetail/updateSocial', modules.social)
    this.commit('newProductDetail/updateStore', modules.store)
    this.commit('newProductDetail/updateUser', modules.user)
    this.commit('newProductDetail/updatePay', modules.pay)
    this.commit('newProductDetail/updateTsp', modules.tsp)
    this.commit('newProductDetail/updateCcc', modules.ccc)

    // 更新BFF数据链路
    this.commit('productDetail/init_cold', modules.BFF_INFO, { root: true })

    const is_use_bff_fs = this.getters?.['productDetail/common/is_use_bff_fs']
    if (is_use_bff_fs) return
    // 更新非模型信息
    this.commit('newProductDetail/initNotModules', payload)
  },
  initNotModules(state, payload) {
    const reflectKeys = [
      'ssrPageType',
      'originalUrl',
      'PUBLIC_CDN_rp',
      'SiteUID',
      'WEB_CLIENT',
      'LAZY_IMG',
      'IMG_LINK',
      'IS_RW',
      'lang_rp',
      'langPath_rp',
      'appLanguage',
      'GB_cssRight_rp',
      'host_rp',
      // 'abtInfos',
      'RESOURCE_SDK',
      // 'showSheinClub',
      'sizeTipsConfig',
      'cccSupportLargeImageList',
      'is_include_critical_css',
      'currency'
    ]
    // 如果有属性才去做相关更新
    const commonInfo = {}
    reflectKeys.forEach(key => {
      if (payload.hasOwnProperty(key)) commonInfo[key] = payload[key]
    })
    state.coldModules.commonInfo = commonInfo
  },
  updateHot(state, payload) {
    // 将bff数据转换为旧数据结构
    hotModulesAdaptersHandler({ hotModules: payload, })

    // 做精准更新 更新到接口返回的字段颗粒度
    const {
      noUpdateRecommend,
      productInfo = {},
      user = {},
      promotion = {},
      pay = {},
      coupon = {},
      getTheLook = {},
      shipping = {},
      pageInfo = {},
      ccc = {},
      recommend = {},
      // store = {},
      comment = {} // 912 之后再开启
    } = payload
    const { fsAbt = {}, confirmedAgeLimitExpired } = pageInfo
    const { skuDetail, getPrice, skuMapCompose, skcEstimatedInfo, sheinClubContent } = productInfo
    const { inventory } = skuDetail || {}
    const { isPaidUser, paidDetail, isFreeReceiveUser, saveStatus } = user
    const { exclusivePromotionPrice, newFlashPromotion, promotionBeltInfo, addOnItemCate } = promotion
    const { storeCouponList, showCouponsUnusedCommend } = coupon
    const { afterpayInfo, paymentInfo } = pay
    const { cccVideoPosition, cccBrandZoneLink, cccBannerContext = {}, cccAddCartPrompt, cccStoreGoodsRecIntervention } = ccc
    const { commentOverView, commentOverViewLocal, comments, commentsWithImg, outStoreCommentsInfo } = comment // comments commentOverViewLocal
    const { topRankingInfo, isForwardCategory, productConfig } = recommend
    const { countryInfo, goodsFreeShippingInfo, primeShipping, shippingDetail, freeReturnPolicy } = shipping
    // const { storefollowCount, storeFollowState, storeQualityTag } = store

    state.hotModules.pageInfo.fsAbt = Object.freeze(fsAbt)
    state.hotModules.pageInfo.confirmedAgeLimitExpired = confirmedAgeLimitExpired
    state.hotModules.productInfo.getPrice = getPrice
    state.hotModules.productInfo.skuMapCompose = skuMapCompose
    state.hotModules.productInfo.skcEstimatedInfo = skcEstimatedInfo
    state.hotModules.productInfo.sheinClubContent = sheinClubContent
    // 从动态接口获取真实库存
    state.hotModules.productInfo.inventory = inventory || []

    state.hotModules.pay.afterpayInfo = afterpayInfo
    state.hotModules.pay.paymentInfo = paymentInfo
    

    state.hotModules.coupon.storeCouponList = storeCouponList
    state.hotModules.coupon.showCouponsUnusedCommend = showCouponsUnusedCommend

    state.hotModules.user.isPaidUser = isPaidUser
    state.hotModules.user.isFreeReceiveUser = isFreeReceiveUser
    state.hotModules.user.paidDetail = paidDetail
    state.hotModules.user.saveStatus = saveStatus

    state.hotModules.promotion.exclusivePromotionPrice = exclusivePromotionPrice
    state.hotModules.promotion.newFlashPromotion = newFlashPromotion
    state.hotModules.promotion.promotionBeltInfo = promotionBeltInfo
    state.hotModules.promotion.addOnItemCate = addOnItemCate

    state.hotModules.shipping.countryInfo = countryInfo
    state.hotModules.shipping.goodsFreeShippingInfo = goodsFreeShippingInfo
    state.hotModules.shipping.primeShipping = primeShipping
    state.hotModules.shipping.freeReturnPolicy = freeReturnPolicy
    state.hotModules.shipping.shippingDetail = shippingDetail

    state.hotModules.getTheLook.getTheLook = getTheLook?.getTheLook
    // 评论
    state.hotModules.comment.comments = comments
    state.hotModules.comment.outStoreCommentsInfo = outStoreCommentsInfo
    state.hotModules.comment.commentsWithImg = commentsWithImg
    state.hotModules.comment.commentOverView = commentOverView
    state.hotModules.comment.commentOverViewLocal = commentOverViewLocal

    // 推荐
    // state.hotModules.recommend.unifiedRecommend = unifiedRecommend
    state.hotModules.recommend.topRankingInfo = topRankingInfo
    state.hotModules.recommend.isForwardCategory = isForwardCategory
    state.hotModules.ccc.cccVideoPosition = cccVideoPosition
    state.hotModules.ccc.cccBrandZoneLink = cccBrandZoneLink
    // state.hotModules.ccc.cccCatSelectionTag = cccCatSelectionTag
    state.hotModules.ccc.cccBannerContext = cccBannerContext

    state.hotModules.ccc.cccAddCartPrompt = cccAddCartPrompt
    state.hotModules.ccc.cccStoreGoodsRecIntervention = cccStoreGoodsRecIntervention
    
    this.commit('newProductDetail/common/updateRealTimeReady', true)
    
    // 更新BFF数据链路
    this.commit('productDetail/update_hot', payload.BFF_INFO, { root: true })

    // 店铺
    // state.hotModules.store.storefollowCount = storefollowCount
    // state.hotModules.store.storeFollowState = storeFollowState
    // state.hotModules.store.storeQualityTag = storeQualityTag
    
    // 动态数据回来后同时触发其他前端数据更新
    // 更新套装数据
    this.dispatch('newProductDetail/SerialProducts/getSerialProducts')
    Vue.nextTick(() => {
      // 更新商品优惠卷接口数据 稍作延后 先保证主数据页面性能
      setTimeout(() => {
        // 回传列表数据
        this.dispatch('newProductDetail/handleSendListData')
        this.dispatch('newProductDetail/fetchPromotionWithCenter')
        // 更新店铺关注等信息
        this.dispatch('newProductDetail/initStoreFollowInfo') // TODO 旧店铺
        this.dispatch('newProductDetail/realTimeUpdateCallBack') // 融合v8推全后删除 TODO
        this.commit('newProductDetail/common/updateRealTimeFirstReady', true)
      }, 500)
      setTimeout(() => {
        // this.dispatch('newProductDetail/getStoreRecommendInfo', { noUpdateRecommend })
        this.dispatch('newProductDetail/Signboard/getStoreCategoriesSale', { noUpdateRecommend }) // 还在使用旧链路，待融合
        // this.dispatch('newProductDetail/initRecommendRelativesInfo', { noUpdateRecommend })
        // 加载新组合购推荐数据
        // this.dispatch('newProductDetail/initComboBuyRecommendData', { noUpdateRecommend }) // v8推全后可删除
        // this.dispatch('newProductDetail/ShippingEnter/getDetailStoreSellerInfo')
        // this.dispatch('newProductDetail/fetchRelationGoodsWithCenter') // 下线推全后可删除
        // this.dispatch('newProductDetail/fetchPdeGetTheLookData', { noUpdateRecommend }) // 旧gtl和outfit数据，下线

        // 初始化商卡配置
        this.dispatch('newProductDetail/loadProductConfigManager', { productConfig })
      }, 2000)
    })
  },
  async getCacheFsAbt(state, payload){
    let cacheFsAbt = {}
    // ssr 时取直出数据取modules中的 csr用getUserAbtData
    if (!payload.modules || !payload.modules.productInfo) {
      const { goodsdetailsSKCprefix, pwapicture, goodssaleattributefold } = await getUserAbtData()
      cacheFsAbt.goodsdetailsSKCprefix = goodsdetailsSKCprefix
      cacheFsAbt.pwapicture = pwapicture
      cacheFsAbt.goodssaleattributefold = goodssaleattributefold
    } else {
      const modules = payload.modules || {}
      const { goodsdetailsSKCprefix, pwapicture, goodssaleattributefold } = modules.pageInfo?.fsAbt || {}
      cacheFsAbt.goodsdetailsSKCprefix = goodsdetailsSKCprefix
      cacheFsAbt.pwapicture = pwapicture
      cacheFsAbt.goodssaleattributefold = goodssaleattributefold
    }
    state.cacheFsAbt = Object.freeze(cacheFsAbt)
  },
  clearLastGoodsInfo(state) {
    state.hotModules.productInfo.getPrice = null
    // 切换不同spu的商品时得清空旧的库存信息
    this.commit('newProductDetail/SizeBox/updateRealMallStock', null, { root: true })
  },
  updateConfirmedAgeLimitExpired(state, payload) {
    state.hotModules.pageInfo.confirmedAgeLimitExpired = payload
  },
  /**
   * bff首屏数据获取方式
   */
  initNotModulesWithBffFs(state, payloadBySSR) {
    // 注入旧数据链路依赖基本信息
    const _isInClient = typeof window !== 'undefined'
    const _hasSSRData = payloadBySSR && typeof payloadBySSR === 'object'

    const payload = _isInClient ? getDetailCommonInfo() : _hasSSRData && payloadBySSR || {}
    const reflectKeys = [
      'ssrPageType',
      'originalUrl',
      'PUBLIC_CDN_rp',
      'SiteUID',
      'WEB_CLIENT',
      'LAZY_IMG',
      'IMG_LINK',
      'IS_RW',
      'lang_rp',
      'langPath_rp',
      'appLanguage',
      'GB_cssRight_rp',
      'host_rp',
      // 'abtInfos',
      'RESOURCE_SDK',
      // 'showSheinClub',
      'sizeTipsConfig',
      'cccSupportLargeImageList',
      'is_include_critical_css',
      'currency'
    ]
    // 如果有属性才去做相关更新
    const commonInfo = {}
    reflectKeys.forEach(key => {
      if (payload.hasOwnProperty(key)) commonInfo[key] = payload[key]
    })
    state.coldModules.commonInfo = commonInfo
  },
  ...other
}
