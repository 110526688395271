/**
 * 首页bff组件映射表
 */
const compTypes = {
  IMAGE_COMPONENT: 'StaticImageCardController', // 静态图组件（单图、双图、三图、四图）
  CODE_IMAGE_COMPONENT: 'StaticImage', // code合图组件
  HORIZONTAL_ITEMS: 'HorizontalItems', // 横向商品列表
  IMAGE_CAROUSEL_COMPONENT: 'SideslipImage', //  滑动多图组件(轮播图、横滑2.5、横滑2,3,4,5)
  FLASH_SALE: 'FlashSale', // 闪购
  FLASH_SALE_BANNER: 'FlashSaleBanner', // 闪购banner
  LEFT_FLASH_SALE: 'LeftFlashSale', // 左图闪购
  RANKING_ENTRANCE: 'RankingEntrance', // 排行榜入口
  FREE_SHIPPING_COMPONENT: 'FreeShippingInfo',   // 免邮凑单组件
  CATEGORY_RECOMMEND_DYNAMIC: 'CategoryRecommendDynamic', // 新品类热度推荐
  CATEGORY_RECOMMEND_PLACEHOLDER: 'CategoryRecommendPlaceholder', // 品类推荐sbc占位组件
  NEW_USER_HOME: 'NewUserHome', // 新客组件
  // 三档券包
  COUPON_PACKAGE: 'CouponPackage',
  // 沉浸式组件
  IMMERSIVE_BANNER: 'ImmersiveBanner',
  MULTI_LINE_CONTAINER_COMPONENT: 'MultiLine',
  // 首页凑单券
  PURCHASE_COUPON_BANNER: 'PurchaseCouponBanner',
  // 不贵组件
  SUPER_DEALS: 'SuperDeals',
  // 1/2组件
  HALF_COMPONENT_CONTAINER: 'HalfComponentContainer',
  // 新客政策融合组件
  POLICY_NEW_USER_CONTAINER: 'PolicyNewUserContainer',
  // 购物车楼层组件
  CART_INTEREST_PICS: 'CartInterresetPics',
  // 游戏专区(双图、三图、四图、4.5图)
  GAME_ZONE: 'GameZone',
}

export const CATE_TYPE = {
  TWO_CATEGORY_RECOMMEND_COMPONENT: 2,     // 一行2图
  FOUR_CATEGORY_RECOMMEND_COMPONENT: 4,    // 一行4图
  FIVE_CATEGORY_RECOMMEND_COMPONENT: 5,    // 一行5图
}

export const compMapping = ({ styleType = '', componentKey = '' }) => {
  return compTypes[styleType] || compTypes[componentKey] || ''
}
