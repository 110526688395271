import type { AS_QuickAddCartStandard } from '../../../types'
import { getClickAddBagParam } from './getClickAddBagParam'
import { getAddCollectParam } from './getAddCollectParam'
import { getSaAnalysisParams } from '../utils'

const standard: AS_QuickAddCartStandard.AnalysisFuncs = {
  // TODO
  'click_your_sa_event_name.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_qty.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_lowstock_label.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_add_bag.comp_quick-add-cart-standard': ({ extraData }) => {
    return getClickAddBagParam({ extraData })
  },
  'expose_loadingcart.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_scroll_popup.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_brand_sale_block.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'expose_sku_recommend_retail_price.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'expose_legallowestprice.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'click_s3_vipprice.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'click_estimated_price.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'expose_prime_entry.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'click_prime_entry.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'expose_pricestatus.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'expose_taxcontent.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'expose_s3_vipprice.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'click_add_collect.comp_quick-add-cart-standard': ({ extraData}) => {
    return getAddCollectParam({ extraData })
  },
  'click_get_the_look_details.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'click_other_options_details.comp_quick-add-cart-standard': ({ extraData}) => {
    return {
      ...extraData,
    }
  },
  'click_goods_list_popup_details.comp_quick-add-cart-standard': ({ extraData }) => {
    if (extraData?.target) {
      try {
        const { goodsList, srcModule, srcIdentifier } = getSaAnalysisParams(extraData?.target)
        extraData.target = undefined
        return {
          ...extraData,
          goods_list: goodsList,
          src_module: srcModule || '',
          src_identifier: srcIdentifier || '',
        }
      } catch (error) {
        console.log('click_goods_list_popup_details getSaAnalysisParams error:', error)
        return {
          ...extraData,
        }
      }
    }
    return {
      ...extraData,
    }
  },
  'click_board_toast.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_board_toast.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_size_guide_detail.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_size_information.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_popup_quick_view.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  // 'expose_goods_detail_image.comp_quick-add-cart-standard': ({ extraData }) => {
  //   return {
  //     ...extraData,
  //   }
  // },
  'click_getcoupon.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_estimated_price.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_addprice_abt.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_npicprice.comp_quick-add-cart-standard': ({ extraData }) => {
    return {
      ...extraData,
    }
  }
}

export default standard
