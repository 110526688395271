import { reactive, onDeactivated, onBeforeUnmount } from 'vue'
import { CountDown } from '@shein/common-function'

export function useCountDown({ seconds, timestamp } = {}) {
  let timer = new CountDown({
    seconds,
    timestamp,
    countFunc: (timeObj) => {
      Object.assign(current, timeObj)
    }
  })
  const current = reactive(timer.timeObj)

  const start = ({ seconds, timestamp } = {}) => {
    timer.start({
      seconds,
      timestamp,
    })
  }

  const reset = () => {
    timer.start({
      seconds,
      timestamp,
    })
  }

  const clear = () => {
    timer.clear()
    timer = null
  }

  onDeactivated(clear)
  onBeforeUnmount(clear)

  return {
    current,
    start,
    reset
  }
}
