<template>
  <s-dialog
    :visible.sync="visibleDialogArticle"
    :close-on-click-modal="true"
    :append-to-body="true"
    :show-close="true"
    class="article-dialog"
  >
    <iframe
      ref="iframeRef"
      :src="articleUrl"
      width="100%"
      :height="iframeHeight"
      frameborder="0"
    >
    </iframe>
    <template #footer>
      <s-button
        :type="['primary', 'H72PX']"
        :width="'100%'"
        @click="emit('clickFooterButton')"
      >
        {{ footerText }}
      </s-button>
    </template>
  </s-dialog>
</template>

<script setup name="ArticleDialog">
import { ref } from 'vue'
import { Dialog as SDialog } from '@shein/sui-mobile'

defineProps({
  articleUrl: {
    type: String,
    default: '',
  },
  footerText: {
    type: String,
    default: '',
  },
  iframeHeight: {
    type: String,
    default: '300px',
  }
})
const emit = defineEmits(['clickFooterButton'])

const visibleDialogArticle = ref(false)
const iframeRef = ref(null)

const openDialog = () => {
  visibleDialogArticle.value = true
}

const closeDialog = () => {
  visibleDialogArticle.value = false
}

defineExpose({ openDialog, closeDialog })

</script>

<style lang="less" scoped> 
.show-dialog {
  opacity: 1;
}
.show-dialog {
  opacity: 0;
}
.article-dialog {
  /deep/ .S-dialog__wrapper{
    max-height: 60%;
    width: calc(100% - 48px);
    max-width: inherit;
  }
  /deep/ .S-dialog__body{
    margin-top: -1px; // 修复组件中未设置top时，滚动区域存在1px缝隙
  }
  /deep/ .S-dialog__top-holder{
    padding-bottom: 0;
    position: relative;
    background: #FFF;
  }
}
</style>
