// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.86.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.86.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.86.0/node_modules/css-loader/dist/cjs.js??clonedRuleSet-2.use[1]!../../../../node_modules/.pnpm/@shein-aidc+bs-search-box-mobile-vue2@0.0.1-beta.73_@babel+core@7.22.1_@shein+vue-styled-comp_o2ks4ix56c6yo4zkiptxa7doqa/node_modules/@shein-aidc/bs-search-box-mobile-vue2/dist/style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".advertising-page-search[data-v-2666dcc0]{--search-box-border-color:#000;--search-box-box-height:34px}[mir] .advertising-page-search[data-v-2666dcc0]{padding-top:.21rem;background-color:#fff}[mir=ltr] .advertising-page-search[data-v-2666dcc0],[mir=rtl] .advertising-page-search[data-v-2666dcc0]{padding-left:.267rem;padding-right:.267rem}", "",{"version":3,"sources":["webpack://./public/src/pages/advertising/container.vue"],"names":[],"mappings":"AAA+D,0CAA0C,8BAA8B,CAAC,4BAA4B,CAAC,gDAAgD,kBAAkB,CAAC,qBAAqB,CAAC,wGAAwG,oBAAoB,CAAC,qBAAqB","sourcesContent":["@import url(\"@shein-aidc/bs-search-box-mobile-vue2/style.css\");.advertising-page-search[data-v-2666dcc0]{--search-box-border-color:#000;--search-box-box-height:34px}[mir] .advertising-page-search[data-v-2666dcc0]{padding-top:.21rem;background-color:#fff}[mir=ltr] .advertising-page-search[data-v-2666dcc0],[mir=rtl] .advertising-page-search[data-v-2666dcc0]{padding-left:.267rem;padding-right:.267rem}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
