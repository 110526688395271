import { h } from 'vue'

export function Button(props) {
  const { context, btnText, onSubmit } = props
  const handleClick = () => {
    const { checkSelected, productInfo, skuInfo } = context
    if (!checkSelected()) return
    onSubmit({
      detail: productInfo,
      skuInfo,
    })
  }
  return (
    <button
      class="mshe-btn-black"
      role="button"
      aria-label="Next Step"
      onClick={() => handleClick()}
    >
      {btnText || 'NEXT STEP'}
    </button>
  )
}
