import { computed } from 'vue'
import { TAG_CLOUD_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'

const DEFAULT_COLOR = '#000' // 默认未选中下字体颜色
const DEFAULT_BACKGROUND_COLOR = '#F6F6F6'// 默认未选中下背景颜色

// 激活状态下背景色添加0.04透明度
function addOpacity (color) {
  if (!color || color.startsWith('rgba')) return color

  let r, g, b

  if (color.startsWith('rgb')) {
    [r, g, b] = color.match(/\d+/g)
  } else {
    const hex = color.replace('#', '')

    if (hex.length == 3) {
      r = parseInt(hex.charAt(0).repeat(2), 16)
      g = parseInt(hex.charAt(1).repeat(2), 16)
      b = parseInt(hex.charAt(2).repeat(2), 16)
    } else {
      r = parseInt(hex.substring(0, 2), 16)
      g = parseInt(hex.substring(2, 4), 16)
      b = parseInt(hex.substring(4, 6), 16)
    }
  }

  return `rgba(${r}, ${g}, ${b}, 0.04)`
}

// 获取ccc text标签数据
export function useCccTextData (item, queryParams) { 
  return computed(() => {
    const { label = '', value = '', tag_name = '', tag_id = '', quickShipText = '', cccConfig = {} } = item.value || {}
    const icon = cccConfig?.displayContent?.icon?.src || ''
    const active = value == queryParams.value?.tag_ids
    const quickShipLabel = tag_id === 'quickship' && quickShipText
    const paddingLeft = icon ? '6px' : ''

    const {
      checkedBgColor = DEFAULT_BACKGROUND_COLOR,
    } = cccConfig?.displayContent || {}
    
    const styles = active ? {
      paddingLeft,
      color: checkedBgColor,
    } : {
      paddingLeft,
    }
  
    return {
      styles,
      active,
      value,
      icon,
      label: quickShipLabel || cccConfig?.displayContent?.labelLang || label || tag_name || cccConfig?.tagName
    }
  })
}

// 获取ccc image标签数据
export function useCccImageData (item, queryParams) {
  return computed(() => { 
    const { cccConfig = {} } = item.value || {}
    const active = item.value?.value == queryParams.value?.tag_ids
    const { checkedBgColor = DEFAULT_BACKGROUND_COLOR } = cccConfig?.displayContent || {}
    const { src, ratio } = cccConfig?.displayContent?.image || {}
    const label = cccConfig?.displayContent?.labelLang || item.value?.label || item.value?.tag_name || cccConfig?.tagName

    const styles = active ? {
      color: checkedBgColor,
      minWidth: '60px', // 最小宽度
    } : {}
    
    return {
      src,
      styles,
      active,
      label,
      value: item.value?.value,
      imgStyles: { height: '12px', width: `${ratio * 12}px` },
    }

  })
}

const COLOR_MAP = {
  [TAG_CLOUD_TYPE.MALL]: '#198055',
  [TAG_CLOUD_TYPE.QUICK_SHIP]: '#198055',
  [TAG_CLOUD_TYPE.SALE]: '#c44a01',
  [TAG_CLOUD_TYPE.BLACK_FRIDAY]: '#c44a01',
  [TAG_CLOUD_TYPE.OTHER]: '#222',
}

// 获取普通标签数据
export function useTextData (item, queryParams) {
  return computed(() => { 
    let tagType = TAG_CLOUD_TYPE.OTHER
    if (Number(item.value?.type) == 1) tagType = TAG_CLOUD_TYPE.MALL
    if (Number(item.value?.type) == 2) tagType = TAG_CLOUD_TYPE.QUICK_SHIP
    if (item.value?.is_sale_tag) tagType = TAG_CLOUD_TYPE.SALE
    if (item.value?.is_black_friday_tag) tagType = TAG_CLOUD_TYPE.BLACK_FRIDAY
    const { queryParamsKey = 'tag_ids' } = item.value || {}
    const active = item.value?.value == queryParams.value?.[queryParamsKey]
    
    const color = COLOR_MAP[tagType]
    const styles = active ? {
      color,
      backgroundColor: item.value?.is_black_friday_tag ? addOpacity(color) : '',
      border: `1px solid ${color}`,
    } : {
      color: DEFAULT_COLOR,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    }
    
    return {
      styles,
      active,
      label: item.value?.tag_name,
      value: item.value?.value,
      iconStyles: { color },
    }
  })
  
}

// qs标签固定新样式
export function useQsStyles ( item, queryParams ) {
  return computed(() => { 
    const color = '#198055'
    const { label = '', value = '', tag_name = '', quickShipText = '', cccConfig = {} } = item.value || {}
    const active = value == queryParams.value?.tag_ids
    
    const styles = active ? {
      color,
      backgroundColor: addOpacity(color),
      border: `1px solid ${color }`,
    } : {
      color,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    }
  
    return {
      styles,
      active,
      value,
      icon: cccConfig?.displayContent?.icon?.src || '',
      label: quickShipText || cccConfig?.displayContent?.labelLang || label || tag_name || cccConfig?.tagName
    }
  })
}

