<template>
  <div :class="['cart-item__tag-bar', { 'new-cartrow-vision': isNewCartrowVision }]">
    <SFSTag
      v-if="showSfsTag"
      class="common_tag"
      :language="language"
      :item="item"
      :showExtendText="sfsVisualAbt.visual_benefit_point"
      :is-new-cartrow-vision="isNewCartrowVision"
    />
    <!--  quick ship  -->
    <QuickShipTag
      v-if="showQuickShip"
      class="common_tag"
      :cart-qs-tag-info="cartQsTagInfo"
      :page="page"
      :is-new-cartrow-vision="isNewCartrowVision"
      :mall-code="item.mall_code"
      :current-item-no="item.id"
      :qs-mall-list="cartQsTagInfo.qsMallList"
    />
    <!-- FreeShipping -->
    <span
      v-if="showFreeShipping"
      class="common_tag free-shipping-tag"
    >
      {{ language.SHEIN_KEY_PWA_25453 }}
    </span>
    <!-- evoluSHEIN -->
    <EvoluTag
      v-if="showEvoluShein"
      :is-new-cartrow-vision="isNewCartrowVision"
      class="common_tag"
    />
    <!-- 品类津 -->
    <VoucherTag
      class="common_tag"
      :item="item"
      :language="language"
      :disable-click="batchActive"
      :page="page"
    />
    <!-- 促销 -->
    <template v-if="showPromotionTag">
      <span
        v-if="![32].includes(promotionTag.typeId)"
        v-tap="analysisData('1-8-3-14')"
        v-expose="analysisData('1-8-3-13')"
        class="common_tag promotion-tag"
        :class="{
          'block': isBlock,
          'have-time-tag': timeStamp,
          'flash': timeStamp && normalFlash,
          'quick-ship__tag': showFreeShipping,
          'free-gift': promotionTag.typeId == 4,
          'flash__tag': normalFlash,
        }"
        :da-event-expose="!batchActive && page == 'cart' && [3, 10].includes(promotionTag.typeId) ? '1-8-5-1' : ''"
        :data-typeId="promotionTag.typeId"
        :data-isFullPromotion="0"
        :data-activity-id="promotionTag.promotionId"
        :data-goods-sn="item.product.goods_sn"
        :data-is-member-gift="+isSheinClubGiftTag"
        @click="jump"
      >
        <span
          style="overflow: hidden;"
          class="tag-content"
          :da-event-expose="exposePromoTagEventId"
          :da-event-click="clickPromoTagEventId"
          :data-is_flashsale="item.flashType"
          :data-goods_id="item.product.goods_id"
          :data-is_insured_goods="item.product.insurable"
          :data-page="page"
        >
          <s-label
            type="promo"
            class="product-tag-content"
            :class="{
              'has-time': timeStamp,
              'flash-tag': normalFlash,
              'club-color': isSheinClubGiftTag,
            }"
          >
            <span class="tag-text">
              {{ promotionTag.typeIdText }} {{ promotionTag.canJump && !timeStamp && !batchActive ? ' >' : '' }}
            </span>
            <sui_icon_info_12px_2
              v-if="page == 'cart' && promotionTag.tipText"
              class="tip-icon"
              size="12px"
              color="#959595"
            />
          </s-label>
          <span
            v-if="timeStamp"
            class="tag-time"
          >
            <span
              :class="[
                'tag-time-split',
                isSheinClubGiftTag && 'club-color2',
              ]"
            >|</span>
            <ClientOnly>
              <template v-if="showDate">
                <span
                  :class="[
                    'end-in-time',
                    isSheinClubGiftTag && 'club-color'
                  ]"
                >
                  {{ template(dateStamp, language.SHEIN_KEY_PWA_31168) }}
                </span>
              </template>
              <template v-else>
                <CartTagsCountDown
                  v-if="isNewCartrowVision"
                  font-size="10px"
                  not-hide
                  not-reload
                  :time-stamp="timeStamp"
                  :class="[
                    'cart-tag-time',
                    isSheinClubGiftTag && 'club-color'
                  ]"
                />
                <count-down-time
                  v-else
                  :time-stamp="timeStamp"
                  :class="[
                    'cart-tag-time',
                    isSheinClubGiftTag && 'club-color'
                  ]"
                  font-size="10px"
                />
              </template>
            </ClientOnly>
          </span>
        </span>
        <span
          :class="{ 'club-color': isSheinClubGiftTag, }"
          style="color: #767676;"
        >
          <sui_icon_more_right_12px
            v-if="timeStamp && !batchActive && promotionTag.canJump"
            class="tag-right-icon"
            size="12px"
            :is-rotate="false"
          />
        </span>
      </span>

      <div 
        v-else
        :class="isNewCartrowVision ? 'easy-coupon-wrap' : 'common_tag'"
        :da-event-expose="!batchActive && page == 'cart' ? '1-8-5-1' : ''"
        :data-typeId="promotionTag.typeId"
        :data-isFullPromotion="0"
        :data-activity-id="promotionTag.promotionId"
        :data-goods-sn="item.product.goods_sn"
        :data-is-member-gift="+isSheinClubGiftTag"
      >
        <EasyCoupon
          :class="isNewCartrowVision ? 'common_tag' : ''"
          :coupon-code="promotionTag.typeIdText"
          :is-new-style="true"
        />
      </div>
    </template>

    <slot name="checkout-tag"></slot>
    <s-dialog
      v-if="showTip"
      :visible.sync="showTip"
      :append-to-body="true"
    >
      <template slot="title">
        {{ promotionTag.tipText }}
      </template>
      <template
        slot="footer"
      >
        <s-button
          :type="['H72PX','primary']"
          width="100%"
          @click="showTip=false"
        >
          {{ language.SHEIN_KEY_PWA_15312 }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import dayjs from 'dayjs'
import { template, timeTransformer } from '@shein/common-function'
import { Label } from '@shein/sui-mobile'
import { daEventCenter } from '@/public/src/services/eventCenter/index'
import EasyCoupon from '@/public/src/pages/cartNew/components/coupon/EasyCoupon.vue'
import QuickShipTag from './QuickShipTag.vue'
import { getCartItemIndex } from '../../../utils/index'
import { MESSAGE_DATE } from '@/public/src/pages/common/biz_helper/timer_format/dictionary.js'
import VoucherTag from './VoucherTag.vue'
import EvoluTag from './EvoluTag.vue'
import SFSTag from './SFSTag.vue'
import { sui_icon_more_right_12px, sui_icon_info_12px_2 } from '@shein-aidc/icon-vue2'
import CartTagsCountDown  from '@/public/src/pages/cartNew/components/list/CartTagsCountDown.vue'

Vue.use(Label)
export default {
  name: 'CartItemProductTag',
  components: {
    EasyCoupon,
    QuickShipTag,
    VoucherTag,
    EvoluTag,
    sui_icon_more_right_12px,
    sui_icon_info_12px_2,
    CartTagsCountDown,
    SFSTag,
  },
  props: {
    showQuickShip: {
      type: Boolean,
      default: false
    },
    showFreeShipping: Boolean,
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    page: {
      type: String,
      default: ''
    },
    batchActive: {
      type: Boolean,
      default: false
    },
    freeLang: {
      type: String,
      default: ''
    },
    abtParams: {
      type: Object,
      default() {
        return {}
      }
    },
    // index: {
    //   type: Number,
    //   default: 0
    // },
    timeStamp: {
      type: [Number, String],
      default: ''
    },
    iconPrefix: {
      type: String,
      default: 'suiiconfont'
    },
    showSfsTag: {
      type: Boolean,
      default: false,
    },
    showEvoluShein: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTip: false,
      isNewCartrowVision: false,
      cartQsTagInfo: {
        isShowNewStyle: false,  // 是否为新样式（商品行）
        text: '',               // quickship标题文案（标签前半部分）
        qsTimelinessInfo: {     // quickship时效信息
          isLocationTop: false, // 是否展示在购物车顶部
          tip: '',              // quickship时效文案（标签后半部分）
        },
        qsMallList: {}, // 商品维度的 QS 数据
        last_quickship_tag: '', // 发货时效最晚的 QS 信息
        isJp: false, // 是否为日本收货地址
      },
    }
  },
  computed: {
    sfsVisualAbt () {
      return this.abtParams?.checkoutSfsVisualAbt || { visual_benefit_point: false }
    },
    promotionTag () {
      if(this.item.promotion_type_id == '1000'){ // 下单页用券赠礼
        return { typeIdText: this.freeLang || this.language.SHEIN_KEY_PWA_18337 }
      }
      return this.item.promotionTag || {}
    },
    // 付费会员赠品 tag
    isSheinClubGiftTag() {
      return this.promotionTag.typeId === 4 && [8, 14].includes(this.promotionTag.promotionLogoType)
    },
    isBlock() {
      if (this.isSheinClubGiftTag) return false
      return this.timeStamp
    },
    showDate() {
      if (this.isSheinClubGiftTag) return dayjs(this.timeStamp * 1000).diff(dayjs(), 'hour') > 72
      return false
    },
    dateStamp() {
      if (typeof window === 'undefined') return
      return timeTransformer({
        time: this.timeStamp * 1000,
        sDateMap: MESSAGE_DATE,
      })
    },
    showUnderPricePromotionTag() {
      return this.abtParams.UnderPrice?.param?.UnderPriceShow == 'A'
    },
    showPromotionTag (){
      if(this.item.promotionTag?.typeId == 30 && !this.showUnderPricePromotionTag){
        return ''
      }
      return this.promotionTag?.typeIdText
    },
    normalFlash(){
      return (this.promotionTag.typeId == 10 && [1, 4, 5].includes(Number(this.item.flashType))) || this.promotionTag.typeId == 11
    },
    exposePromoTagEventId() {
      let eventId = ''
      if(!this.batchActive && this.page == 'cart' && [10].includes(this.promotionTag.typeId)) eventId = '1-8-5-19'
      else if(!this.batchActive && this.page == 'cart' && [11].includes(this.promotionTag.typeId)) eventId = '1-8-5-28'
      else if (!this.batchActive && this.item.product.insurable == 1) eventId = '1-8-5-21'
      return eventId
    },
    clickPromoTagEventId() {
      let eventId = ''
      if(!this.batchActive && this.page == 'cart' && [10].includes(this.promotionTag.typeId)) eventId = '1-8-5-20'
      else if(!this.batchActive && this.page == 'cart' && [11].includes(this.promotionTag.typeId)) eventId = '1-8-5-27'
      return eventId
    },
  },
  methods: {
    template,
    async jump(){
      if(this.page != 'cart' || this.batchActive) return
      if(this.promotionTag.tipText) {
        this.showTip = true
        return
      }
      if(!this.promotionTag.canJump) return
      if( this.promotionTag.typeId != 30 ){
        daEventCenter.triggerNotice({
          daId: '1-8-5-2',
          extraData: {
            typeId: this.promotionTag.typeId,
            isFullPromotion: 0,
            goods_sn: this.item.product.goods_sn,
            activity_id: this.promotionTag.promotionId,
            is_member_gift: +this.isSheinClubGiftTag,
          }
        })
      }
      if (this.isSheinClubGiftTag) {
        daEventCenter.triggerNotice({
          daId: '1-8-3-58',
          extraData: {
            goods_id: this.item.product.goods_id,
          }
        })
      }
      let url_prams = ''
      let info = this.analysisData()
      if(this.promotionTag.typeId == 30) {
        url_prams = `tab=${this.promotionTag.ruleNum}&ici=PageCart&src_tab_page_id=${info.data?.src_tab_page_id}&src_module=${info.data?.src_module}&src_identifier=${info.data?.src_identifier}`
      }
      this.$emit('jump', this.promotionTag, url_prams, info)
    },
    analysisData (id) {
      const idx = getCartItemIndex({ item: this.item })
      if (
        !this.batchActive && this.page == 'cart' &&
        (
          [30].includes(this.promotionTag.typeId) ||
          (this.promotionTag.typeId === 10 && this.promotionTag.flashType === 6) // 品牌特卖
        )
      ) {
        const promotion_id = this.promotionTag.promotionId
        const promotion_tp_id = this.promotionTag.typeId
        const content_list = `underPrice_${this.promotionTag.ruleNum || ''}`
        const src_tab_page_id = typeof window != 'undefined' ? window.getSaPageInfo?.tab_page_id || (window.SaPageInfo ? (SaPageInfo.page_name + SaPageInfo.start_time) : '') : ''

        return {
          id: id,
          once: false,
          data: {
            promotion_id,
            promotion_tp_id,
            content_list,
            src_module: 'promotion',
            src_identifier: `on=${promotion_tp_id}\`cn=${promotion_id}\`ps=${idx + 1}_1\`jc=${content_list}`,
            src_tab_page_id,
          }
        }
      }
      return {}
    },
  }
}
</script>

<style lang="less">
.cart-item__tag-bar{
  font-size: 12px;
  //transform: scale(.833334);
  //transform-origin: left;
  // display: inline-block;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  // line-height: 1.25;
  padding-bottom: 12/75rem;
  row-gap: 8/75rem;
  .club-color {
    color: @sui_color_club_rosegold_dark1 !important; /* stylelint-disable-line declaration-no-important */
  }
  .club-color2 {
    color: @sui_color_club_rosegold_dark1 !important; /* stylelint-disable-line declaration-no-important */
    opacity: 0.4;
  }
  .common_tag{
    margin-right: 12/75rem;
    max-width: 100%;
    display: inline-flex!important;/* stylelint-disable-line declaration-no-important */
    &.block {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    &.have-time-tag{
      background: linear-gradient(90deg, #FFF0EC 0%, rgba(255, 240, 236, 0.3) 100%);
    }
    &.flash{
      background: linear-gradient(89.98deg, #FDF4D0 0.02%, rgba(253, 244, 208, 0.3) 100.8%);
      /*rw:begin*/
      background: linear-gradient(89.98deg, #FFE9E7 0.02%, #FFFAF7 100.8%);
    }
    // margin-top: 4/75rem;
    &.free-shipping-tag {
      background: rgba(245, 252, 251, 1);
      color: rgba(25, 128, 85, 1);
      padding: 0 8/75rem;
      font-size: 10px;
      flex-shrink: 0;
    }
  }
  .product-tag-content{
    font-size: 10px!important;/* stylelint-disable-line declaration-no-important */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    &.flash-tag{
      background: #FDF4D0 /*rw:#FFEDEB*/;
      color: @sui_color_micro_emphasis;
      /*rw:begin*/
      color: @sui_color_flash;
    }
    .tag-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .tag-time{
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    padding: 4/75rem 0;
    .end-in-time {
      margin: 0 4/75rem 0 4/75rem;
      font-size: 10px;
      color: #c44a01;
    }
    .cart-tag-time{
      padding-left: 8/75rem;
    }
    .tag-time-split{
      color: @sui_color_gray_dark3;
    }
  }
  .tag-content{
    display: flex;
    align-items: center;
  }
  .tag-right-icon{
    padding: 4/75rem 0;
  }
}
</style>
<style lang="less" scoped>
.new-cartrow-vision {
  .common_tag {
    width: fit-content!important;
    max-width: 100%;
    height: 32/75rem;
    border-radius: 4/75rem;
    padding: 0 8/75rem;
    line-height: 32/75rem;
    display: inline-flex;
    align-items: center;
    overflow: hiddex;
    &.free-shipping-tag {
      background: #DCF5E6;
      font-weight: 400;
    }
    &.easy-coupon {
      background: #FFE7DF;
    }
  }
  .easy-coupon-wrap {
    line-height: 0;
  }
  .promotion-tag {
    background: #FFE7DF;
    .product-tag-content {
      padding: 0;
      background: #FFE7DF;
      height: 32/75rem!important;
      display: inline-flex;
      align-items: center;
      &.has-time {
        margin-right: 8/75rem;
      }
      &.flash-tag {
        background: #FFF3C2;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .tag-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    &.flash__tag {
      background: #FFF3C2;
    }
    .tag-time {
      height: 32/75rem;
      padding: 0;
      .cart-tag-time {
        height: 32/75rem;
        line-height: 0;
        font-weight: 600;
        /deep/.time-content {
          height: 32/75rem;
          line-height: 32/75rem;
          display: inline-flex;
          align-items: center
        }
      }
    }
    .tag-time-split {
      color: rgba(196, 74, 1, .4)
    }
    .cart-tag-time {
      color: #c44a01;
    }
    .tag-right-icon {
      padding: 0;
      color: #c44a01!important;
    }
    &.flash {
      background: #FFF3C2;
      .flash-tag {
        background: #FFF3C2;
        padding: 0;
        height: 32/75rem;
      }
      .tag-time-split {
        color: rgba(168, 97, 4, .4);
      }
      .cart-tag-time {
        color: rgba(168, 97, 4, 1);
      } 
      .tag-right-icon {
        color: rgba(168, 97, 4, 1)!important;
      } 
    }
    &.free-gift {
      background: #FFE7DF;
    }
  }
}
</style>
