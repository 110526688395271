import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { replaceHrefSpecChar, stringifyQueryString } from '@shein/common-function'


type ComponentProps = {
    text: string
    context: DS_QuickAddCartStandard.FooterBarContext
}

export function Button(props: ComponentProps) {
    const { productInfo, skuInfo, mallCode, fixedRatio, closeQuickAddCart } = props.context
    const { goods_id, goods_name } = productInfo || {}
    const getGoodsUrl = (name = '', id) => {
        name = name == '-' || !name ? 'product' : name
        const url = '/' + name.replace('/', '-').split(' ').join('-') + '-p-' + id + '.html'
        return replaceHrefSpecChar({ href: url })
    }
    function handleClick() {
        const params = {
            mallCode,
            imgRatio: fixedRatio,
            skucode: '',
        }
        if (skuInfo?.sku_code) params.skucode = skuInfo?.sku_code
        const { langPath } = gbCommonInfo || {}
        const rawUrl = `${langPath}${getGoodsUrl(goods_name, goods_id)}?${stringifyQueryString({ queryObj: params })}`
        closeQuickAddCart()
        useAppConfigs().$router.push(rawUrl)
    }
    return (
        <button
            class="bsc-quick-add-cart-standard__button_black"
            role="button"
            aria-label="Add To Cart"
            onClick={() => handleClick()}
        >
            {props.text || 'Add To Cart'}
        </button>
    )
}
