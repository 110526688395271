import type { Ref } from 'vue'
import { computed, getCurrentScope, onScopeDispose, ref, watch } from 'vue'
import { useEventListener } from './use-event-listener'

const elLockedMap = new WeakMap<HTMLElement, number>()

type MaybeElement = HTMLElement | SVGElement | Window | Document | null | undefined;

/**
 * Lock scrolling of the element.
 */
export function useScrollLock(
  target: Ref<MaybeElement> | (() => MaybeElement),
  initialState = false,
) {
  const locked = ref(initialState)
  const elLocked = ref<boolean>(initialState)

  let stopTouchMoveListener: Fn | null = null
  let initialOverflow: CSSStyleDeclaration['overflow'] = ''

  watch(
    target,
    (element) => {
      const el = resolveElement(element)
      if (el) {
        const elOverflow = el.style.overflow

        if (elOverflow !== 'hidden') {
          initialOverflow = elOverflow
        }

        if (locked.value) {
          lockElement(el)
        }
      }
    },
    { immediate: true },
  )

  const lock = () => {
    const el = resolveElement(toValue(target))
    if (!el || locked.value) return

    lockElement(el)
    locked.value = true
  }

  const unlock = () => {
    const el = resolveElement(toValue(target))
    if (!el || !locked.value) return

    unlockElement(el)
    locked.value = false
  }

  function canUpdateLock(el: HTMLElement) {
    return !(elLockedMap.get(el) || 0)
  }
  function increaseLockCount(el: HTMLElement, count: number) {
    elLockedMap.set(el, Math.max((elLockedMap.get(el) || 0) + count, 0))
  }

  function lockElement(el: HTMLElement) {
    if (canUpdateLock(el)) {
        stopTouchMoveListener = useEventListener(
          () => el,
          'touchmove',
          (e) => {
            preventDefault(e as TouchEvent)
          },
          { passive: false },
        )

      elLocked.value = true
      el.style.overflow = 'hidden'
    }

    increaseLockCount(el, 1)
  }
  function unlockElement(el: HTMLElement) {
    increaseLockCount(el, -1)

    if (canUpdateLock(el)) {
      stopTouchMoveListener?.()

      elLocked.value = false
      el.style.overflow = initialOverflow
    }
  }

  if (getCurrentScope()) {
    onScopeDispose(unlock)
  }

  return [
    computed({
      get() {
        return locked.value
      },
      set(v) {
        if (v) {
          lock()
          return
        }
        unlock()
      },
    }),
    elLocked,
  ] as const
}

function checkOverflowScroll(el: Element): boolean {
  const style = window.getComputedStyle(el)
  if (
    style.overflowX === 'scroll' ||
    style.overflowY === 'scroll' ||
    (style.overflowX === 'auto' && el.clientWidth < el.scrollWidth) ||
    (style.overflowY === 'auto' && el.clientHeight < el.scrollHeight)
  ) {
    return true
  }
  const parent = el.parentNode as Element

  if (!parent || parent.tagName === 'BODY') return false

  return checkOverflowScroll(parent)
}

function preventDefault(rawEvent: TouchEvent): boolean {
  const e = rawEvent || window.event

  // Do not prevent if element or parentNodes have overflow: scroll set.
  if (checkOverflowScroll(e.target as Element)) return false

  // Do not prevent if the event has more than one touch (usually meaning this is a multi touch gesture like pinch to zoom).
  if (e.touches.length > 1) return true

  if (e.preventDefault) e.preventDefault()

  return false
}

function toValue(target: Ref<MaybeElement> | (() => MaybeElement)) {
  return typeof target === 'function' ? target() : target.value
}

function resolveElement(
  el: HTMLElement | SVGElement | Window | Document | null | undefined,
): HTMLElement | null | undefined {
  if (typeof Window !== 'undefined' && el instanceof Window) return el.document.documentElement

  if (typeof Document !== 'undefined' && el instanceof Document) return el.documentElement

  return el as HTMLElement | null | undefined
}
