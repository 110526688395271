import { isObject } from '@shein/common-function'

class AddressSensitive {
  // 校验函数和后端字段映射
  #fieldNamesMap = {
    country: 'country',
    first_name: 'fname',
    last_name: 'lname',
    middle_name: 'middleName',
    father_name: 'fatherName',
    english_name: 'englishName',
    state: 'state',
    city: 'city',
    district: 'district',
    street: 'street',
    tax_number: 'taxNumber',
    address1: 'address1',
    address2: 'address2',
    postcode: 'postcode',
    tel: 'tel',
    standby_tel: 'standbyTel',
    national_id: 'nationalId',

    passport_number: 'passportNumber',
    passport_issue_place: 'passportIssuePlace',
    passport_issue_date: 'passportIssueDate',
    birthday: 'birthday',
    store_type_code: 'store_type_code',
  }

  constructor(addressObj, { fields_list = [] }) {
    ['fname', 'lname', 'fatherName', 'englishName', 'tel', 'standbyTel'].forEach(item => {
      const tempObj = AddressSensitive.prototype[item]
      if(tempObj){
        AddressSensitive.prototype[item] = null
      }
    })
    this.addressObj = addressObj
    this.#setCheckMethods({ fields_list })
  }

  // 获取地址组件值
  #getFieldValue(name) {
    return name === 'taxNumber'
      ? this.addressObj?.getTaxNumber?.()
      : this.addressObj[name]
  }

  #setCheckMethods({ fields_list = [] }) {
    const handleArr = classifyAndSortHandle(fields_list, 'sensitive_field', 'order_num')
    const handleKeys = Object.keys(handleArr)
    for (const field_name of handleKeys) {
      const fieldName = this.#fieldNamesMap[field_name]
      AddressSensitive.prototype[fieldName] = (params = {}) => {
        const handleRules = handleArr[field_name]
        for (let i = 0; i < handleRules?.length; i++) {
          const {
            language_content,
            sensitive_content = [],
            tips_field = '',
            reg_content,
            sensitive_rule = 1,
          } = handleRules[i]
          const {
            instance = this.addressObj || {},
            value = String(this.#getFieldValue(fieldName)),
          } = params
          try {
            if (
              !language_content ||
              !(sensitive_content?.length || reg_content) ||
              !fieldName ||
              !sensitive_rule
            ) {
              continue
            }
            if (!value) {
              return
            }
            let isAccord = false
            switch (sensitive_rule) {
              // 包含
              case 1:
                isAccord = sensitive_content.some((item) => {
                  return value.toLocaleLowerCase().includes(item.toLocaleLowerCase())
                })
                break
              // 等于
              case 2:
                isAccord = sensitive_content.some((item) => {
                  return value == item
                })
                break
              // 不包含
              case 3:
                isAccord = sensitive_content.every((item) => {
                  const isInclude = value.toLocaleLowerCase().includes(item.toLocaleLowerCase())
                  return !isInclude
                })
                break
              case 7:
                isAccord = senstiiveRegexCheck(value, reg_content)
                break
            }
            if (isAccord) {
              this.setAddressSensitiveNote({
                instance,
                type: this.#fieldNamesMap[tips_field],
                text: language_content,
              })
              return
            }
          } catch (error) {
            console.log(error)
          }
        }
      }
      
    }
  }

  setAddressSensitiveNote({
    instance = this.addressObj,
    text = '',
    type,
  } = {}) {
    if (!type) {
      return
    }
    if (isObject(instance?.sensitiveNote)) {
      instance.sensitiveNote[type] = text
    }
  }
}

function classifyAndSortHandle(arr, key, orderField) {
  const handle = arr.reduce((acc, item) => {
    const groupKey = item[key] // 当前项的 `key`
    // 如果容器中不存在该 key 的分组，初始化
    if (!acc[groupKey]) {
      acc[groupKey] = []
    }
    // 将当前对象添加到分组
    acc[groupKey].push(item)
    return acc
  }, {})

  Object.keys(handle).forEach((k) => {
    handle[k] = handle[k].sort((a, b) => a[orderField] - b[orderField]);
  })

  return handle
}

function senstiiveRegexCheck(
  checkValue,
  regexConf = {
    regex_list: [],
    rev_regx_list: [],
  }
) {
  const { regex_list = [], rev_regx_list = [] } = regexConf
  // 正正则
  for (let j = 0; j < regex_list.length; j++) {
    const reg = regex_list[j]
    const regExp = new RegExp(reg)
    if (checkValue && regExp.test(checkValue)) {
      return true
    }
  }

  // 反正则
  for (let j = 0; j < rev_regx_list.length; j++) {
    const reg = rev_regx_list[j]
    const regExp = new RegExp(reg)
    if (checkValue && !regExp.test(checkValue)) {
      return true
    }
  }
  return false
}

export default AddressSensitive
