import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'
import schttp from 'public/src/services/schttp'
import { isSwitchBffSsr } from 'public/src/pages/common/bff-new/index.js'
import { getQueryString } from '@shein/common-function'

const { langPath } = gbCommonInfo
let pendingding
let ready
if (/\/cart\/?$/.test(location.pathname)) {
  window.fetchCartContentInfo = null
}

const getCartComponent = () => {
  return prefetchResource.importAsyncComponent({
    chunkName: 'cartv2_index', // 跟webpackChunkName保持一致
    componentFactory: () => import( /* webpackChunkName: 'cartv2_index' */ '../../cart_v2/container.vue')
      .then(r => ready || !pendingding ? Promise.resolve(r) : pendingding.then(() => { pendingding = null; return Promise.resolve(r) }))
  })
}

const getCartShareLandingComponent = () => {
  const isBff = getQueryString({ key: 'is_bff' }) || '0'
  const isSwitchCartBffSsr = isSwitchBffSsr(['cart_v7'])?.['cart_v7'] || false
  if (isSwitchCartBffSsr || isBff === '1') {
    return () => import(/* webpackChunkName: "cart_share_bff" */ '../../cart/share-landing/App.vue')
  }
  return () => import(/* webpackChunkName: "cart_share" */ '../../cart_share_landing/container.vue')
}

const cartRoutes = [
  {
    path: `${langPath}/cart`,
    name: 'cart',
    component: getCartComponent(),
    beforeEnter: (to, from, next) => {
      if (from.name && from.name != 'cart') {
        if (window.fetchCartContentInfo !== null) {
          ready = !!window.fetchCartContentInfo
          pendingding = (window.fetchCartContentInfo || schttp({ url: `/api/cart/get?prefetch=1&gbSuiSsrData=${window.gbSuiSsrData?.join?.() || ''}` })).then((res) => {
            return new Promise(resolve => {
              let { contextForSSR, ssrHTML, ssrVueCSS, appendedSelector, ssrSuiCSS } = res || {}
              if (contextForSSR) {
                if (ssrHTML && ssrVueCSS) {
                  if (ssrSuiCSS && appendedSelector) {
                    document.querySelector('#ssrSuiCSS').textContent += ssrSuiCSS
                    window.gbSuiSsrData = [... new Set((window.gbSuiSsrData || []).concat(appendedSelector))]
                  }
                  window._gb_app_.$store.state.rootStatus.exchangingSpaScene = true;
                  [document.querySelector('#header-app'), document.querySelector('#top-banner')].forEach(ele => {
                    ele && (ele.style.display = 'none')
                  })

                  const fragment = document.createRange().createContextualFragment(ssrHTML)
                  let goodsImgs
                  let imgs = Array.from(fragment.querySelectorAll('#cart-app .j-cart-main-img')).map(_ => _.getAttribute('src')).filter(_ => _)
                  
                  if (imgs.length) goodsImgs = preloadImg([... new Set(imgs)], null, 1000)

                  document.querySelector('#prerender-app').appendChild(fragment.querySelector('#cart-app'))
                  document.querySelector('#prerender-css-cart').innerHTML = ssrVueCSS
                  document.querySelector('#prerender-app #cart-app').setAttribute('data-server-rendered', true)
                  
                  requestAnimationFrame(() => { // 上屏前
                    window.cartSsrData = contextForSSR
                    goodsImgs
                      ? goodsImgs.then(() => requestAnimationFrame(() => resolve()))
                      : requestAnimationFrame(() => resolve())
                  })
                } else {
                  window.cartSsrData = contextForSSR
                  resolve()
                }
              } else {
                resolve()
              }
            })
          }).then(() => ready && next()).catch(() => location.reload())
          window.fetchCartContentInfo = null
          ready || next()
        } else {
          next()
        }
      } else {
        next()
      }
    },
    meta: {
      keepAlive: true,
      pageType: 'cart',
      // toSavePosition: ['page_goods_detail'],
      notNeedCommonHeader: true,
      monitorTag: 'cart',
      isCssInVue: true,
    }
  },
  {
    path: `${langPath}/cart/share/select`,
    name: 'cartShareSelect',
    component: () => import(/* webpackChunkName: "cart_share_select" */ '../../cart_share_select/container.vue'),
    meta: {
      notNeedCommonHeader: true,
      keepAlive: true,
    },
  },
  {
    path: `${langPath}/cart/share/landing`,
    name: 'cartShareLanding',
    component: getCartShareLandingComponent(),
    meta: {
      notNeedCommonHeader: true,
    },
  },
  {
    path: `${langPath}/cart/add_on_item`,
    name: 'cartAddOnItem',
    component: () => import(/* webpackChunkName: "cart_add_on_item" */'../../cart_add_on_item/container.vue'),
    meta: {
      keepAlive: false,
      pageType: 'cart',
      notNeedCommonHeader: true,
      monitorTag: 'cart-add-on-item',
      isCssInVue: true,
    },
  }
]

export {
  cartRoutes
}
