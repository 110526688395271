<template>
  <div
    v-if="isShow"
    :class="[
      'bsc-cart-item-goods-qty',
      {
        'bsc-cart-item-goods-qty_mask': isShowMask,
      },
    ]"
  >
    <button class="bsc-cart-item-goods-qty__minus">
      <sui_icon_delete_18px
        v-if="value == 1"
        size="16px"
        @click="onClickDelete"
      />
      <sui_icon_min_16px
        v-else
        @click="onClickMinus"
      />
    </button>
    <input
      v-model="quantity"
      type="tel"
      role="spinbutton"
      class="bsc-cart-item-goods-qty__input"
      autocomplete="off"
      :aria-valuemax="99"
      :aria-valuemin="1"
      :aria-valuenow="value"
      :disabled="!isClick || isNewUserGoods"
      :readonly="!isClick || isNewUserGoods"
      @click="onClickInput"
      @input="onInput"
      @blur="onBlur"
    />
    <button
      :class="[
        'bsc-cart-item-goods-qty__plus',
        {
          'bsc-cart-item-goods-qty__plus_mask': isShowPlusMask,
        },
      ]"
      @click="onClickPlus"
    >
      <sui_icon_add_16px />
    </button>
  </div>
</template>

<script name="GoodsQty" setup lang="ts">
import { defineProps, ref, watch, withDefaults } from 'vue'
import sui_icon_delete_18px from '../../components/icon/sui_icon_delete_18px.vue'
import sui_icon_add_16px from '../../components/icon/sui_icon_add_16px.vue'
import sui_icon_min_16px from '../../components/icon/sui_icon_min_16px.vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'

/**
 * 数量编辑器组件V2
 */

const registerExpose = useInjectRegisterExpose()

interface IGoodsQtyProps {
  /**
   * 是否显示
   */
  isShow: boolean
  /**
   * 数量
   */
  value: string | number
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 是否展示加号遮罩
   */
  isShowPlusMask: boolean
  /**
   * 是否可点击
   */
  isClick?: boolean
  /**
   * 是否可点击加号
   */
  isClickPlus: boolean
  /**
   * 是否是新人商品
   */
  isNewUserGoods: boolean
  /**
   * 最小值
   */
  min?: number
  /**
   * 最大值
   */
  max?: number
}
const props = withDefaults(defineProps<IGoodsQtyProps>(), {
  isClick: true,
  min: 1,
  max: 99,
})

const quantity = ref<number | string>(1)
watch(
  () => props.value,
  v => {
    quantity.value = +v
  },
  {
    immediate: true,
  },
)

// events
const emit = defineEmits(['onChange', 'onDelete', 'onPlus', 'onMinus', 'onClickInput', 'onExpose'])

const onInput = (e: Event) => {
  quantity.value = (e.target as HTMLInputElement).value.replace(/\D/g, '')
  if (quantity.value === '0') {
    quantity.value = ''
  }
}
const onBlur = () => {
  if (quantity.value === '') {
    quantity.value = +props.value
    return
  }
  if (quantity.value == props.value) {
    return
  }
  onChange(Number(quantity.value), 'blur')
}

const onClickInput = () => {
  if (!props.isClick) return
  emit('onClickInput', { isNewUserGoods: props.isNewUserGoods })
}
const onClickDelete = () => {
  if (!props.isClick) return
  emit('onDelete', { isNewUserGoods: props.isNewUserGoods })
}
const onClickMinus = () => {
  if (!props.isClick) return
  emit('onMinus', { isNewUserGoods: props.isNewUserGoods })
  if (props.isNewUserGoods) {
    return
  }
  if (Number(quantity.value) <= props.min) {
    return
  }
  onChange(Number(quantity.value) - 1, 'minus')
}
const onClickPlus = () => {
  if (!props.isClick || !props.isClickPlus) {
    return
  }
  emit('onPlus', { isNewUserGoods: props.isNewUserGoods })
  if (props.isNewUserGoods) {
    return
  }
  if (Number(quantity.value) >= props.max) {
    return
  }
  onChange(Number(quantity.value) + 1, 'plus')
}

const onChange = (value: number, action: string) => {
  quantity.value = value
  emit('onChange', { quantity: value, action })
}

const handleExpose = () => {
  watch(
    () => props.isShow,
    n => {
      if (n) {
        const callback = () => {
          emit('onExpose')
        }
        registerExpose('GoodsQty', callback)
      }
    },
    {
      immediate: true,
    },
  )
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-goods-qty {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  height: 22/37.5rem;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4/37.5rem;
  overflow: hidden;
  &.bsc-cart-item-goods-qty_mask {
    opacity: 0.3;
  }
  button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
  }
  input {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    margin: 0;
  }
  .bsc-cart-item-goods-qty__minus,
  .bsc-cart-item-goods-qty__plus {
    width: 23/37.5rem;
    height: 100%;
    background: #f6f6f6;
    color: #000;
  }
  .bsc-cart-item-goods-qty__input {
    width: 23/37.5rem;
    min-width: 23/37.5rem;
    height: 100%;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 0;
    &::selection {
      background-color: #fff;
      color: #222;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .bsc-cart-item-goods-qty__plus_mask {
    color: #bbb;
  }
}
</style>
