<template>
  <RowMarquee
    ref="marqueeRef"
    manual
    :speed="0.5"
  >
    <div class="induce-items">
      <div 
        v-for="item in lureList"
        ref="induceItemRef"
        :key="item.type"
        class="item"
      >
        <img 
          width="14" 
          height="14" 
          :src="item.prefixIcon" 
          alt=""
        />
        <span
          class="tip"
          :style="{ color: item.textColor }"
        >
          {{ item.text }}
        </span>
      </div>
    </div>
  </RowMarquee>
</template>
<script setup>
import RowMarquee from 'public/src/pages/cart_v2/components/common/RowMarquee.vue'
import { ref, computed, onMounted, nextTick } from 'vue'
const marqueeRef = ref(null)
const induceItemRef = ref(null)
const isFirstElOverFlow = ref(false)

const props = defineProps({
  info: {
    type: Object,
    default: () => ({})
  },
})
const lureList = computed(() => {
  const arr = props.info?.lureInfo?.lureList || []
  return isFirstElOverFlow.value ? arr.slice(0, 1) : arr
})

onMounted(() => {
  nextTick(() => {
    // 第一个利诱横条超出宽度就只展示第一个的跑马灯
    isFirstElOverFlow.value = induceItemRef.value?.[0]?.clientWidth > marqueeRef.value?.$el?.clientWidth
    marqueeRef.value?.start()
  })
})
</script>
<style lang="less" scoped>
.induce-items {
  height: 14px;
  font-size: 12px;
  font-weight: 590;
  color: #FA6338;
  overflow: hidden;
  display: flex;
  align-items: center;
  .item {
    display: flex;
    align-items: center;
    white-space: nowrap;
   &:not(:last-child)::after {
     content: '|';
     font-size: 12px;
     font-weight: 300;
     padding-left: 7/37.5rem;
     padding-right: 4/37.5rem;
   }
   .tip {
      padding-left: 2px;
      overflow: hidden;
    }
  }
}
</style>
