export function getProductStyle(
  productCode,
  styleList = [],
  globalStyle
) {
  return styleList.find(m => m[0].includes(productCode))?.[1] || globalStyle
}

export const convertColor = colorEl => {
  if (!colorEl) return ''
  
  const { colors, direction, gradient, locations } = colorEl
  
  if (gradient) {
    return `linear-gradient(${
        direction === 0 ? 'to right' : 'to bottom'
      },${colors
        .map((color, index) => `${color} ${locations[index] * 100}%`)
        .join(',')})`
  }
  
  return colors[0]
}

export const handleTotalPriceAnimate = (newTotalPrice, oldTotalPrice, callback, currencies, currency, lang) => {
  if (typeof window === 'undefined') {
    callback(newTotalPrice)
    return
  }
  if (isNaN(newTotalPrice.amount) || isNaN(oldTotalPrice.amount)) {
    return
  }
  let currencyRule = currencies?.[lang]?.[currency] || {}
  const decimal_place = currencyRule.decimal_place
  const isAdd = +newTotalPrice.amount > +oldTotalPrice.amount
  // 完成动画花费的时长
  const duration = 300
  // 一帧的时长
  const TIME_PER_FRAME = 16.7
  // 动画帧数
  const frameCount = duration / TIME_PER_FRAME
  const priceDiff = newTotalPrice.amount - oldTotalPrice.amount
  // 每一帧的价格步长
  const priceStep = +(priceDiff / frameCount).toFixed(decimal_place)
  let currentPrice = +oldTotalPrice.amount
  const isAnimate = true
  let timer = setInterval(function () {
    if (isAnimate) {
      // 防止步长为0时导致死循环
      if (priceStep == 0) {
        currentPrice = +newTotalPrice.amount
        oldTotalPrice = newTotalPrice
      } else {
        currentPrice += priceStep
        if (isAdd) {
          if (currentPrice >= +newTotalPrice.amount) {
            currentPrice = +newTotalPrice.amount
            oldTotalPrice = newTotalPrice
          } else {
            oldTotalPrice = {
              ...oldTotalPrice,
              amount: currentPrice,
              amountWithSymbol: GB_transform_price_symbol(
                currentPrice,
                '',
                currencyRule
              )
            }
          }
        } else {
          if (currentPrice <= +newTotalPrice.amount) {
            currentPrice = +newTotalPrice.amount
            oldTotalPrice = newTotalPrice
          } else {
            oldTotalPrice = {
              ...oldTotalPrice,
              amount: currentPrice,
              amountWithSymbol: GB_transform_price_symbol(
                currentPrice,
                '',
                currencyRule
              )
            }
          }
        }
      }
    }
    callback && callback(oldTotalPrice)
    if (currentPrice == newTotalPrice.amount) {
      clearInterval(timer)
      timer = null
    }
  }, TIME_PER_FRAME)
}
