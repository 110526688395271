<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Teleport',
  props: {
    to: {
      type: String,
      required: true
    },
    disable: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      toEl: null
    }
  },
  mounted() {
    this.toEl = document.querySelector(this.to)
    this.append()
  },
  destroyed() {
    this.remove()
  },
  methods: {
    append() {
      if (!this.disable && this.toEl) {
        this.$slots.default.forEach(vnode => {
          let node = vnode.elm || this.$createElement(vnode).elm
          this.toEl.appendChild(node)
        })
      }
    },
    remove() {
      if (this.toEl) {
        this.$slots.default.forEach(vnode => {
          let node = vnode.elm || this.$createElement(vnode).elm
          if (this.toEl.contains(node)) { // 判断是否在toEl中
            this.toEl.removeChild(node)
          }
        })
      }
    }
  }
}
</script>
