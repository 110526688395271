<template>
  <div
    v-show="showFooter"
    class="kids-modal__footer"
    :class="[
      `kids-modal__footer--${kidModalMode}`,
      isTermsMode && 'kids-modal__footer--terms'
    ]"
  >
    <div
      v-if="isTermsMode"
      class="kids-modal__footer--terms"
    >
      {{ language.SHEIN_KEY_PWA_35496 || `*Kindly review the privacy policy and indicate your consent to data processing before submitting your child's profile.` }}
    </div>
    
    <div class="kids-modal__footer--btn">
      <s-button
        v-if="childId"
        class="remove-btn"
        :loading="loading"
        :type="['H80PX']"
        @click="onRemoveBtnClick"
      >
        {{ language.SHEIN_KEY_PWA_32778 }}
      </s-button>

      <s-button
        class="save-btn"
        :type="['primary', 'H80PX']"
        :loading="loading"
        @click="handleSave"
      >
        {{ language.SHEIN_KEY_PWA_32897 }}
      </s-button>
      <RemoveModal
        v-if="childId"
        v-model="showRemoveModal"
        :child-id="childId"
        @delete-success="onDeleteSuccess"
      />
    </div>
  </div>
</template>

<script>

import { defineComponent, ref, inject } from 'vue'

import { Button as SButton } from '@shein/sui-mobile'
import RemoveModal from './RemoveModal.vue'

export default defineComponent({
  name: 'ModalFooter',
  components: {
    SButton,
    RemoveModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: [String, Number],
      default: '',
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    kidModalMode: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {

    const {
      getLanguage,
      isTermsMode,
    } = inject('KIDS_CONTEXT')

    const handleSave = () => {
      emit('save')
    }

    const showRemoveModal = ref(false)

    const onRemoveBtnClick = () => {
      showRemoveModal.value = true
    }

    const onDeleteSuccess = () => {
      emit('delete-success')
    }

    return {
      isTermsMode,
      language: getLanguage(),
      showRemoveModal,
      handleSave,
      onRemoveBtnClick,
      onDeleteSuccess,
    }
  }
})
</script>

<style lang="less" scoped>
.kids-modal__footer {
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 10;

  &--addMult {
    background: #fff;
  }

  &--update {
    .remove-btn {
      margin-right: 6px;
    }

    .save-btn {
      margin-left: 6px;
    }
  }

  &--terms {
    padding-top: 8px;
    padding-bottom: 8px;
    background: #fff;
  }

  .kids-modal__footer--btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 12px;

    .remove-btn {
      flex: 1;
    }
    .save-btn {
      flex: 1;
    }
  }

  .kids-modal__footer--terms {
    margin-bottom: 8px;
    color: var(---sui_color_black_alpha60, rgba(0, 0, 0, 0.60));
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 12px;
  }
}
</style>
