
import schttp from 'public/src/services/schttp'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'

// const { WEB_CLIENT } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
// const pageUrl = WEB_CLIENT === 'romwe' ? 'romwevip' : 'sheinvip'

export const getTspLabels = async ({ labels, products }) => {
  let tspList = []
  if (!labels || !products || !products.length) {
    return Promise.resolve({
      code: -1,
      msg: 'miss params'
    })
  }
  const data = await itemSer.getAtomicInfo({
    goods: products,
    needAssign: false,
    fields: { tspLabels: labels }
  })
  if (data) {
    Object.keys(data).forEach(id => {
      tspList.push({
        goods_id: id,
        ...(data[id]?.tspLabels || {})
      })
    })
  }
  return Promise.resolve({
    code: 0,
    data: tspList
  })
  // return request({
  //   type: 'GET',
  //   url: `${langPath}/product/getTspLabel`,
  //   data: {
  //     labels,
  //     goodsIds,
  //   },
  //   timeout: 10000
  // })
}

export const getCombinedInfo = (list = []) => {
  if (!list || !list.length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  return schttp({
    method: 'POST',
    url: '/atomic/getCombinedInfo',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      productListGoods: list
    }),
    timeout: 10000
  })
}

export const getCateInfoById = ({ cateId }) => {
  if (!cateId) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  return schttp({
    method: 'GET',
    url: '/api/productInfo/cateInfo/get',
    params: {
      cat_id: cateId
    },
    timeout: 10000
  })
}

/**
 * 获取会员折扣商品
 * @param {*} list 
 * @returns 
 */
export const getExclusiveDiscount = (data) => {
  if (!data || !Object.keys(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  return schttp({
    method: 'GET',
    url: `/api/user/shein_romwe_vip/getExclusiveDiscount/query`,
    params: data,
    timeout: 10000
  })
}

/**
 * 获取推荐信息
 * @param {*} param0 
 * @returns 
 */
export const getRecFacade = async (data) => {
  if (!data || !Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  try {
    return await schttp({
      method: 'GET',
      url: '/api/recommend/facade/get',
      params: data,
      timeout: 10000
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

/* 兜底推荐容错列表接口 */
export const getRecFaultFacade = (params) => {
  return schttp({
    url: `/api/recommend/faultTolerantFacade/get`,
    params
  })
} 

/* 兜底推荐容错列表接口 */
export const fetchRecFaultFacade = (params) => {
  return schttp({
    url: `/api/recommend/faultFacade/query`,
    method: 'POST',
    data: params,
  })
} 

/**
 * @description: 商详试用报告列表
 * @param {Object} params 参数
 */
export const getFreeTrailReports = (params) => {
  return schttp({
    url: '/api/comment/freeTrailReport/query',
    params
  })
}

// 图搜用，旧筛选逻辑， 旧的走获取size筛选项接口：/listPlatform/getAttrByIds 做属性筛选商品接口：/listPlatform/getGoodsByIds
export const getRecGoodsByIds = (query) => {
  if (!query) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  return schttp({
    method: 'POST',
    url: '/api/recommend/goodsByIds/query',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(query),
    timeout: 10000
  })
}

// 图搜用，新筛选逻辑, 新的通过请求/v2/Product/recommend/camera， 不同的result_type入参来实现筛选项和筛选商品数据的获取
export const getRecData = (query) => {
  if (!query) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    method: 'POST',
    url: '/api/recommend/camera/filter',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(query),
    timeout: 10000
  })
}

/**
 * 获取商品id对应的信息
 * @param {*} id
 * @returns
 */
export const getGoodsInfo = (id) => {
  if (!id) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  return schttp({
    method: 'GET',
    url: '/api/productInfo/goodsInfo/get',
    params: { id },
    timeout: 10000
  })
}

/**
 * 获取闪购钩子商品数据
*/
export const flashSaleHookListApi = (params, loadingConfig = {}) => {
  if (!params) return
  return schttp({
    method: 'GET',
    url: '/api/flashsale/hookProductList/query',
    params: params,
    timeout: 10000,
    schttp: {
      loadingConfig
    }
  })
}

/**
 * 获取闪购专区商品分页数据
*/
export const flashSaleSpecialProductsListApi = (params, loadingConfig = {}) => {
  return schttp({
    method: 'GET',
    url: '/api/flashsale/specials/list/query',
    params: params,
    timeout: 10000,
    schttp: {
      loadingConfig,
    },
  })
}

/**
 * 获取不贵频道客户端渲染页面数据
 */
export const getThriftyFindRenderPageData = (params, loadingConfig = {}) => {
  return schttp({
    method: 'GET',
    url: '/api/thriftyFind/render-page',
    params: params,
    timeout: 10000,
    schttp: {
      loadingConfig,
    },
  })
}

// 获取店铺闪购商品及活动信息
export const getStoreFlashSalePageData = (params, loadingConfig = {}) => {
  return schttp({
    method: 'POST',
    url: '/api/store/flashsale/get-page-data',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(params),
    timeout: 10000,
    schttp: {
      loadingConfig,
    },
  })
}

// 获取店铺闪购商品及活动信息
export const getStoreFlashSalePageProducts = (params, loadingConfig = {}) => {
  return schttp({
    method: 'POST',
    url: '/api/store/flashsale/get-page-products',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(params),
    timeout: 10000,
    schttp: {
      loadingConfig,
    },
  })
}
