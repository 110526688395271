import schttp from 'public/src/services/schttp'
import { fetchTelCountryListApi } from 'public/src/services/api/pay/checkout.js'

// 推荐手机号显示定时器
let recommendPhoneShowTimer = null
// 倒计时1s显示推荐手机号
const recommendPhoneShowTime = 1000
// 推荐手机号隐藏定时器
let recommendPhoneHideTimer = null
// 倒计时4s隐藏推荐手机号
const recommendPhoneHideTime = 4000
const actions = {
  async setUserPaymentrelatedInfo(
    { commit, state },
    { orderInfo = {}, selectedPayment = {}, extraData = {} },
  ) {
    const {
      billno = '',
      countryCode = '',
      countryId = '',
      paymentCode = '',
      currencyCode = '',
      recommendPhone = '',
    } = orderInfo

    // 切换订单号时需要重置
    if(state.collectUserDialog.billno && state.collectUserDialog.billno !== billno){
      commit('initColloctUserInfoDialog')
    }

    // 缓存订单号
    billno && commit('mergeColloctUserInfoDialog', { billno })

    const dialogData = {
      ...state.collectUserDialog,
      needCollectPhoneNumber: selectedPayment?.collect_phone_number == 1,
      phoneRules: [],
      paymentCode,
      countryId,
      countryCode,
      phoneCountryNum: '',
      ...extraData
    }
    const { appLanguage } = gbCommonInfo
    try {
      const [relatedInfo, telCountryInfo] = await Promise.all([
        schttp({
          url: '/pay/payment/get_user_payment_related_info',
          method: 'POST',
          data: {
            countryCode: countryCode,
            paymentCode: paymentCode,
          },
          headers: {
            AppCurrency: currencyCode,
            AppLanguage: appLanguage,
          },
          useBffApi: true,
        })
          .then(res => (res.code === '0' ? res.info : {}))
          .catch(() => {}),
        fetchTelCountryListApi({
          country_id: countryId,
        })
          .then(res => (res.code === '0' ? res.info : {}))
          .catch(() => {}),
      ])

      dialogData.isNewPhoneShow = relatedInfo?.isNewPhoneShow
      dialogData.bubbleTip = relatedInfo?.bubbleTip
      dialogData.endorsementSafetyTips = relatedInfo?.endorsementSafetyTips
      dialogData.recommendPhone =
        relatedInfo?.paymentRelatedInfo?.phoneNumber || recommendPhone
      dialogData.phoneRules =
        relatedInfo?.phoneCheckRule?.result?.regex_list || []
      dialogData.countryCode = telCountryInfo?.value
      dialogData.phoneCountryNum = telCountryInfo?.countrynum

      // isNewPhoneShow 等于 1 ，使用新手机号推荐交互
      if (relatedInfo?.isNewPhoneShow == '1') {
        if (dialogData.recommendPhone && !dialogData.phone) {
          // 自动显示
          clearTimeout(recommendPhoneShowTimer)
          recommendPhoneShowTimer = setTimeout(() => {
            commit('mergeColloctUserInfoDialog', { recommendPhoneVisible: true })
          }, recommendPhoneShowTime)
          // 自动隐藏
          clearTimeout(recommendPhoneHideTimer)
          recommendPhoneHideTimer = setTimeout(() => {
            commit('mergeColloctUserInfoDialog', { recommendPhoneVisible: false })

          }, recommendPhoneShowTime + recommendPhoneHideTime)
        }
      } else {
        dialogData.phone = dialogData.recommendPhone || ''
      }
    } catch (error) {
      //
    }

    commit('mergeColloctUserInfoDialog', dialogData)
  },
}

export default actions
