<template>
  <div class="banner-static-image">
    <template v-for="(item, i) in itemList">
      <BaseImg
        v-if="item.image"
        :key="i"
        v-tap="{
          id: 'click_block_main.comp_top-banner',
          data: { item, index: i, compIndex: index },
        }"
        v-expose="{
          id: 'expose_block_main.comp_top-banner',
          data: { item, index: i, compIndex: index },
        }"
        :class="intervalSpacing ? 'banner-static-image__interval-spacing' : ''"
        :ratio="Number(item.image.ratio)"
        :src="getBaseCutImg(item.image.src)"
        :dataDesignWidth="750"
        :ada="item.ada"
        :firstScreen="true"
        :componentSource="'static-image'"
        :onImageLoad="handleBaseImageMounted"
        @click="clickItem(item, i)"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'StaticImage',
}
</script>

<script setup>
import { defineProps, defineEmits, computed, inject } from 'vue'
import { Image as BaseImg } from '@shein/sui-mobile'
import { commonProps } from '../../common/common-props.js'
import { useLink } from '@shein-aidc/bs-sdk-ccc-link'

const { vTap, vExpose } = inject('analysisInstance')
const cutImg = inject('cutImg', url => url)

const props = defineProps({
  ...commonProps,
})
const emits = defineEmits(['base-image-mounted'])

const { cccLink, jumpLink } = useLink({
  propData: props.propData,
  sceneData: props.sceneData,
})

const itemList = computed(() => props?.propData?.props?.items || [])
const metaData = computed(() => props.propData?.props?.metaData || {})
const intervalSpacing = computed(() => metaData.value?.intervalSpacing || false)

const getBaseCutImg = (imgSrc) => {
  if (!cutImg) {
    return imgSrc.replace(/^https?\:/, '')
  }

  return cutImg(imgSrc, 750)
}

const handleBaseImageMounted = () => {
  emits('base-image-mounted')
}

const clickItem = (item, i) => {
  const url = cccLink.getFullLink({
    item,
    cateLinks: props.cateLinks,
    compIndex: props.index,
    index: i,
  })

  jumpLink({ url, hrefType: item.hrefType })
}
</script>

<style lang="less">
@vw: 375/100vw;
.banner-static-image {
  display: flex;
  &__interval-spacing {
    margin: 0 4 / @vw;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
