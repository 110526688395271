import type { AS_HeaderCart } from '../../../types'

const standard: AS_HeaderCart.AnalysisFuncs = {
  'expose_home_bag.comp_header-cart': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_home_bag.comp_header-cart': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_equity_pop.comp_header-cart': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_equity_pop.comp_header-cart': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
