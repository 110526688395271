<template>
  <div
    ref="slideContainer"
    class="slide-expand"
  >
    <div
      class="slide-expand-content"
      :style="contentStyle"
    >
      <SlideExpandGroup
        v-for="(groupItem, groupIdx) in groups"
        :key="groupIdx"
        :lista="groupItem.lista"
        :listb="groupItem.listb"
        :index="groupIdx"
        :set-row-height="setRowHeight"
      >
        <template #default="{ items }">
          <div
            v-for="(item, idx) in items"
            :key="item.id"
            :style="getItemStyle(idx, items.length)"
            @click="handleItemClick(item)"
          >
            <PicNavItem
              :is-small-status="isSmallStatus"
              :is-active="activeValue === item.value"
              :is-config-active="isConfigActive"
              :is-show-icon="isShowIcon"
              :shape="shape"
              :label="item.label"
              :src="item.src"
              :lazy-img="item.lazyImg"
              :is-lazy-load="groupIdx !== 0"
              :analysis-data="item.analysisData"
              :cssRight="constantData.GB_cssRight"
            />
          </div>
        </template>
      </SlideExpandGroup>
      <slot name="right"></slot>
    </div>
    <SlidePagination
      v-if="groups.length > 1"
      :list="groups"
      :activeIndex="activeIndex"
    />
  </div>
</template>

<script>

import { defineComponent, ref, computed, onMounted, onUnmounted, watchEffect } from 'vue'
import { useSlideRow } from 'public/src/pages/components/FilterBar/PicTopNav/hooks'
import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import { calcRowLength, calcItemPadding } from '../tools'

import PicNavItem from 'public/src/pages/components/FilterBar/PicTopNav/components/PicNavItem.vue'
import SlideExpandGroup from './Group.vue'
import SlidePagination from './Pagination.vue'

export default defineComponent({
  name: 'SlideExpandContainer',
  components: {
    PicNavItem,
    SlideExpandGroup,
    SlidePagination,
  },
  props: {
    // SecondScreen表示第二屏，FirstScreen表示第一屏
    twoLineStyle: { type: String, default: 'SecondScreen' },
    constantData: { type: Object, default: () => ({}) },
    list: { type: Array, default: () => [] },
    originList: { type: Array, default: () => [] },
    isConfigActive: { type: Boolean, default: true }, // 是否配置激活，如可跳转图文，不需要激活，点击直接跳转页面
    isSmallStatus: { type: Boolean, default: false },
    isShowIcon: { type: Boolean, default: true },
    shape: { type: String, default: PIC_TOP_NAV_SHAPE.RECT },
    firstRendering: { type: Boolean, default: true },
    activeValue: { type: [String, Number], default: '' }, // 当前选中的值
    // 带tab的图文导航需要支持自动切换tab
    moveX: { type: Number, default: 0 },
    setMoveX: { type: Function, default: function () {} },
    showNextIcon: { type: Boolean, default: false },
    // 带tab的图文导航标记当前处于第几个tab
    tabIndex: { type: Number, default: -1 },
    activeIndex: { type: Number, default: 0 },
    setActiveIndex: { type: Function, default: function () {} },
    isCardTab: { type: Boolean, default: false }, // 是否是卡片tab布局
    isCanSlide: { type: Boolean, default: true }, // 是否可以滑动
  },
  setup (props, { emit }) {
    const slideContainer = ref(null)
    const rowHeight = ref(90) // 每行的高度
    const rowLength = ref(5) // 每行放多少个元素
    const itemStyle = ref({
      'margin-left': '16px',
    })

    const groups = computed(() => genGroupsData(props.twoLineStyle, props.list, rowLength.value))
    const cardTabPadding = 6 + 6
    const contentStyle = computed(() => {
      const idx = props.activeIndex
      const current = groups.value[idx]
      const height = current.listb.length ? 2 * rowHeight.value + 6 : rowHeight.value
      const delta = props.isCardTab ? idx * (24 + cardTabPadding) - props.moveX  : idx * 24 - props.moveX // 间距是左右6px @flag-pic-padding 改之前搜一下这个标记
      const delta2 = idx * 10 // document.body最大宽度为10rem
      const distance = props.constantData.GB_cssRight ? `${delta2}rem - ${delta}px` : `${delta}px - ${delta2}rem`

      return {
        transform: `translateX(calc(${distance}))`,
        transition: props.moveX ? `all 0.2s` : `all 0.5s`,
        height: height + 'px',
      }
    })

    const getItemStyle = (idx, length) => {
      if (props.constantData.GB_cssRight) {
        if (idx === length - 1) {
          return {}
        }
        return itemStyle.value
      }
      if (idx === 0) {
        return {}
      }
      return itemStyle.value
    }

    const setRowHeight = (height) => {
      // 每行多高由子组件的高度决定
      rowHeight.value = height
    }

    const layoutHandle = () => {
      rowLength.value = calcRowLength()
      itemStyle.value = {
        'margin-left': `${calcItemPadding({
          isCardTab: props.isCardTab,
        })}px`,
      }
    }

    const resizeHandle = () => {
      setTimeout(() => {
        layoutHandle()
      }, 300)
    }

    onMounted(() => {
      layoutHandle()
      window.addEventListener('resize', resizeHandle)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandle)
    })

    const onSlideLeft = () => {
      if (props.shape === PIC_TOP_NAV_SHAPE.RECT || !props.isCanSlide) {
        return
      }
      if (props.activeIndex >= groups.value.length - 1) {
        if (props.showNextIcon) {
          const distance = 60
          props.setMoveX(distance)
          setTimeout(() => {
            emit('slide-next', 'next')
          }, 500)
        }
        return
      }
      props.setActiveIndex(props.activeIndex + 1)
    }
    const onSlideRight = () => {
      if (props.shape === PIC_TOP_NAV_SHAPE.RECT || !props.isCanSlide) {
        return
      }
      if (props.activeIndex <= 0) {
        if (props.tabIndex > 0) {
          const distance = -60
          props.setMoveX(distance)
          setTimeout(() => {
            emit('slide-next', 'last')
          }, 500)
        }
        return
      }
      props.setActiveIndex(props.activeIndex - 1)
    }

    useSlideRow({
      container: slideContainer,
      cssRight: props.constantData.GB_cssRight,
      onSlideLeft,
      onSlideRight,
    })

    const handleItemClick = (target) => {
      const navIndex = props.list.findIndex(item => item.value === target.value)
      // 是否反选
      const isRevert = props.activeValue === target.value
      emit('change', navIndex, !isRevert)
    }

    // 监听 activeValue 变化，设置 activeIndex，确保 activeValue 对应的 item 在视图中
    watchEffect(() => {
      const idx = groups.value.findIndex(group => group.lista.concat(group.listb).some(item => item.value === props.activeValue))
      if (idx !== -1) {
        props.setActiveIndex(idx)
      }
    })

    return {
      getItemStyle,
      groups,
      slideContainer,
      contentStyle,
      setRowHeight,
      handleItemClick,
    }
  }
})

/**
 * lista表示第一行
 * listb表示第二行
 * rowLength表示每行放多少个元素
 */
const genGroupsData = (twoLineStyle, list, rowLength) => {
  if (twoLineStyle === 'SecondScreen') {
    const groups = [{ lista: list.slice(0, rowLength), listb: [] }]
    for (let i = rowLength; i < list.length; i += 2 * rowLength) {
      groups.push({
        lista: list.slice(i, i + rowLength),
        listb: list.slice(i + rowLength, i + 2 * rowLength),
      })
    }
    return groups
  }
  const groups = []
  for (let i = 0; i < list.length; i += 2 * rowLength) {
    groups.push({
      lista: list.slice(i, i + rowLength),
      listb: list.slice(i + rowLength, i + 2 * rowLength),
    })
  }
  return groups
}
</script>

<style lang="less" scoped>
.slide-expand {
  overflow: hidden;

  &-content {
    display: flex;
  }
}
</style>
