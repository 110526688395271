<template>
  <div class="benefit-coupon-list">
    <div class="benefit-coupon-list__wrap">
      <CouponItem
        v-for="(item, index) in couponList"
        :key="index"
        :isShowItemNum="true"
        :couponItem="item"
        :index="index"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
  import { template as _commonTemplate } from '@shein/common-function'
  import CouponItem from './coupon-item.vue'
  import { C_BenefitFloor } from '../../../../types'
  import {computed} from 'vue'
  type propsType = {
    couponItem?: C_BenefitFloor.PopCouponOrder,
  }

  const props = withDefaults(defineProps<propsType>(), {
    couponItem: () => ({
      showType: '',
      returnCouponNonAggregate: {
        couponList: [],
      },
      returnCouponAggregate: {
        couponList: [],
      },
    }),
  })

  const couponList = computed(() => {
    let list = []
    if(props?.couponItem?.showType === 'aggregate') {
      list = props?.couponItem?.returnCouponAggregate?.couponList || []
    } else if(props?.couponItem?.showType === 'nonAggregate') {
      list = props?.couponItem?.returnCouponNonAggregate?.couponList || []
    }
    return list
  })

</script>
<style lang="less">
.benefit-coupon-list {
  padding-right: 12/37.5rem;
  &__wrap {
    display: flex;
    gap: 6/37.5rem;
    overflow-x: auto;
  }
}
</style>
