import { daEventCenter } from 'public/src/services/eventCenter/index'
import usePaymentAbt from 'public/src/pages/checkout/hooks/usePaymentAbt'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { usePayInstance } from 'public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/hook/usePayInstance.js'
import { usePaymentBackTips } from '@/public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/hook/usePaymentBackTips.js'
import useVuex from 'public/src/pages/user/hooks/useVuex.js'
import { useOrderPayment } from './hook/useOrderPayment'
import { onBeforeUnmount, onDeactivated, defineAsyncComponent, onActivated } from 'vue'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default ({
  storeNameSpace = 'orderDetail'
}) => ({
  setup () {
    const { paymentCustomizeFlow, codFreeShow, applePayCashShow } = usePaymentAbt()
    const { useMapActions, useMapState, useMapMutations, useMapGetters } = useVuex(`${storeNameSpace}/payAgain`)

    const { fetchBffOrderInfo, resetPayAgainState } = useMapActions(['fetchBffOrderInfo', 'resetPayAgainState'])
    const { assignOldOrderInfo, updatePayAgainStatus, updateCreateOrderBinDialog } = useMapMutations(['assignOldOrderInfo', 'updateBffOrderInfo', 'updatePayAgainStatus', 'updateCreateOrderBinDialog'])
    const { selectedPaymentInfo, bffOrderInfo, payAgainStatus, createOrderBinDialog } = useMapState(['selectedPaymentInfo', 'bffOrderInfo', 'payAgainStatus', 'createOrderBinDialog'])
    const { isShowPaypalButton } = useMapGetters(['isShowPaypalButton'])

    const { paymentInstance } = usePayInstance()
    const {
      paymentBackTipsRef,
      handleBackTipsMounted
    } = usePaymentBackTips()

    const { 
      reportClickPlaceOrderAnalytics, 
      updateOrderFn,
      handleStartPaymentProcess,
      handleUpdatePayment,
      handleSelectPayment,
      handleSetValidateByPayFn,
      handleSetResetBsPayTookitStatusFn,
      handleUpdatePaymentChange,
      asyncUpdateOrder,
      handleCreatePayment: _handleCreatePayment,
    } = useOrderPayment({ storeNameSpace })

    const handleCreatePayment = ({ extraPayInfo } = {}) => {
      console.log('order_new---___handleCreatePayment', selectedPaymentInfo.value?.code)
      _handleCreatePayment({
        paymentInfo: selectedPaymentInfo.value,
        extraPayInfo
      })
    }

    let resolveFn = null

    let awaitFn = new Promise(resolve => {
      resolveFn = resolve
    })
  
    // 需要等待数据/支付方式列表组件等初始化完成后再执行后续逻辑
    const handleDirectCreatePayment = async () => {
      console.log('order_new---handleDirectCreatePayment', selectedPaymentInfo.value?.code, resolveFn)
      if (selectedPaymentInfo.value?.code && resolveFn) {
        resolveFn?.()
      }
      await awaitFn
      console.log('order_new---handleDirectCreatePayment--res', selectedPaymentInfo.value?.code)
      handleCreatePayment()
    }
    // 用于初始化选中的支付方式等数据，并处理后续逻辑
    const awaitInitSelectPaymentInfo = ({ paymentInfo }) => {
      console.log('order_new---awaitInitSelectPaymentInfo', selectedPaymentInfo.value?.code, paymentInfo?.code)
      if (selectedPaymentInfo.value?.code && selectedPaymentInfo.value?.code === paymentInfo?.code) {
        resolveFn?.()
        return
      }
      if (paymentInfo?.code) {
        handleSelectPayment(paymentInfo)
        handleUpdatePayment()
      }
      resolveFn?.()
    }

    onActivated(() => {
      resolveFn = null
      awaitFn = new Promise(resolve => {
        resolveFn = resolve
      })
    })

    onBeforeUnmount(() => {
      resetPayAgainState()
    })

    onDeactivated(() => {
      resetPayAgainState()
    })

    
    return {
      isShowPaypalButton,
      payAgainStatus,
      bffOrderInfo,
      selectedPaymentInfo,
      paymentCustomizeFlow,
      applePayCashShow,
      codFreeShow,
      paymentInstance,
      fetchBffOrderInfo,
      resetPayAgainState,
      reportClickPlaceOrderAnalytics,
      updateOrderFn,
      handleStartPaymentProcess,
      handleUpdatePayment,
      handleSelectPayment,
      handleSetValidateByPayFn,
      handleUpdatePaymentChange,
      asyncUpdateOrder,
      handleCreatePayment,
      handleDirectCreatePayment,
      awaitInitSelectPaymentInfo,
      assignOldOrderInfo,
      updatePayAgainStatus,
      paymentBackTipsRef,
      handleBackTipsMounted,
      handleSetResetBsPayTookitStatusFn,
      createOrderBinDialog,
      updateCreateOrderBinDialog
    }
  },
  components: {
    PaymentOptionsDrawer: defineAsyncComponent(() => import(/* webpackChunkName: "PaymentOptionsDrawer_Comp" */'public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/PaymentOptionsDrawer.vue')),
    // 支付挽留弹窗旧版, 后面需要干掉
    BackTips: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "checkoutv2-back-tips" */ '@/public/src/pages/common/pay_back_tips/index.vue'
      )
    ),
    CreateOrderBinDialog: defineAsyncComponent(() => import('public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/components/CreateOrderBinDialog.vue')),
  },
  computed: {
    moduleCheckoutScene () {
      if (this.nowCheckoutScene === 'XtraDetail' || this.nowCheckoutScene === 'BuyPrime') {
        return 'XtraDetail'
      }
      if (this.nowCheckoutScene === 'OrderDetail') {
        return this.nowCheckoutScene
      }
      return this.module_name === 'OrderDetail' ? 'OrderDetail' : 'OrderList'
    },
    isAsync() {
      return !this.selectedPaymentInfo?.use_sync
    }
  },
  created() {
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    }
  },
  mounted () {
    // hack
    UserInfoManager.get({ key: 'pwa_user_email', actionType: 'paymentMixin' })

    appEventCenter.$on('clear-and-pay-now', ({
      isImmediatelyPay = false,
      isClearPaymentMethod = false,
      isOpenPaymentList = false
    } = {}) => {
      // this.assignState({ payments: this.payments.map(v => ({ ...v, checked: false })) })
      /**
       *  1、isImmediatelyPay
          => 是否立即支付[true:是, false:否 ]

          2、isClearPaymentMethod
          => 是否清空选中支付方式[true:是, false:否 ]

          3、isOpenPaymentList
          => 是否拉起支付方式列表[true:是, false:否 ]
       */
      this.setUnPaidOrderPaymentStatus({
        isImmediatelyPay,
        isClearPaymentMethod,
        isOpenPaymentList
      })
      this.assignState({ unPaidOrderLoadPayment: true })
      this.handleEditPayment()

      window._PaymentMxin = this
    })
  },
  beforeDestory() {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    beforeunloadFn() {
      this.changeRootStatus?.({
        loading: false
      })
    },
    sendBi: function (
    ) {
      // if (payment_method_id == undefined) payment_method_id = ''
      // if (shipping_method_id == undefined) shipping_method_id = ''
      // coupon == undefined || coupon == '' ? (coupon = '0') : (coupon = '1')
      // points == undefined || points == '0' ? (points = '0') : (points = '1')
      // let storeInfo = [] // 店铺类型`店铺ID`商品数量
      // let mallCodeSet = new Set()
      // let carts =
      //   this.module_name === 'OrderDetail'
      //     ? this.orderInfo?.oldOrderGoodsList
      //     : this.orderInfo?.orderGoodsList
      // carts?.forEach(cartItem => {
      //   let store = storeInfo.find(item => item.store_code == cartItem.store_code)
      //   if (store) {
      //     store.productNum += +cartItem.quantity
      //   } else {
      //     let store_type =
      //       cartItem?.business_model == 0 && cartItem?.store_code == 1 && !cartItem?.store_type
      //         ? 2
      //         : cartItem.store_type
      //     storeInfo.push({
      //       store_code: cartItem.store_code,
      //       store_type: store_type || '',
      //       productNum: +cartItem.quantity
      //     })
      //   }
      //   mallCodeSet.add(cartItem.mall_code)
      // })
      // let label_id = 0
      // if (typeof window != 'undefined') {
      //   label_id = sessionStorage.getItem('filterType')
      //   if (label_id == 'none' || !label_id) label_id = 0
      // }
      // sa('send', {
      //   activity_name: 'click_place_order',
      //   activity_param: {
      //     payment_method_id: payment_method_id + '',
      //     shipping_method_id: shipping_method_id + '',
      //     coupon: coupon, // coupon != '' 使用
      //     points: points, // points > 0   使用
      //     result: result,
      //     result_reason: result_reason,
      //     order_id: order_id,
      //     store_info: storeInfo
      //       .map(item => `${item.store_type}\`${item.store_code}\`${item.productNum}`)
      //       .join(','),
      //     mall_code: [...mallCodeSet].join(','),
      //     label_id,
      //     // is_vaulting
      //     ...(extraData?.payment_code === 'PayPal-GApaypal'
      //       ? {
      //         is_vaulting: this.paypalGaVault.type === PPGA_CHECK_TYPE.CHECKED ? '1' : '0'
      //       }
      //       : {})
      //   }
      // })
    },
    checkPaypalGAExpress: function () {
      // const signAbtParams = parseQueryString(paypalSignAbtValue || '')
      let isExpress = true

      // if (
      //   signAbtParams?.unsigned === 'close' &&
      //   !ppgvAccountInfo?.signUpEmail
      // ) {
      //   isExpress = false
      // }

      // if (
      //   signAbtParams?.signed === 'close' &&
      //   ppgvAccountInfo?.signUpEmail
      // ) {
      //   isExpress = false
      // }

      // if (hasStore) {
      //   isExpress = true
      // }

      return isExpress
    }
  }
})
