export default {
  routeName: 'page_store',
  preload: true, // 是否预加载
  newPosKeys: [
    'listattributeLabels', // todo 123 推荐
    'storepdpfloat',
    'brandrecommendBrand',
    'StorePerformanceSpaHybration',
  ],
  posKeys: [
    'AllListQuickShip',
  ],
}
