<template>
  <div
    class="apple-pay-btn"
  >
    <apple-pay-button
      v-if="btnSdkComplete"
      buttonstyle="black"
      :type="buttonType"
      :locale="locale"
      @click="handleBtnClick"
    />
  </div>
</template>

<script name="BPayApplePayButton" setup lang="ts">
// import { asyncLoadFile } from '@shein/common-function'
import { ref, onMounted, nextTick, computed, default as Vue } from 'vue'
import { loadChannelSdk } from '@shein-aidc/bs-sdk-libs-pay'

import type { C_Libs_Pay } from '@shein-aidc/bs-sdk-libs-pay'
// import { _APPLE_PAY_BUTTON_JS_SDK } from '../../../common/constants'
// import { loadChannelSdk } from '../../../common/channelSdk.js'

Vue.config.ignoredElements = ['apple-pay-button']


interface BPayApplePayButtonProps {
  propsStyle?: {
    display: string
    width: string
    height: string
    direction?: string
  }
  locale?: C_Libs_Pay.ApplePayButtonLocale
  buttonType?: C_Libs_Pay.ApplePayButtonType
}


const props = withDefaults(defineProps<BPayApplePayButtonProps>(), {
  propsStyle: () => ({
    display: 'flex',
    width: '100%',
    height: '100%',
  }),
  locale: 'en-US',
  buttonType: 'pay',
})

// console.log('props=', props)

const emits = defineEmits(['click'])

const btnSdkComplete = ref(false)

const handleBtnClick = () => {
  emits('click')
  // console.log('apple pay button clicked=')
}

const randomKey = Math.random().toString(36).slice(2)

onMounted(async () => {
  console.time(`sdk_${randomKey}`)
  await loadChannelSdk.initApplePayBtnSdk()
  console.timeEnd(`sdk_${randomKey}`)
  btnSdkComplete.value = true
  nextTick(() => {
    // 找到所有标签名为 apple-pay-button 的元素
    let applePayButtons = document.getElementsByTagName('apple-pay-button')
    // 遍历所有找到的元素
    for (let i = 0; i < applePayButtons.length; i++) {
      let button = applePayButtons[i] as HTMLElement || {}
      button.removeAttribute('hidden')
      button.removeAttribute('aria-hidden')
      button.style.display = 'block'
      if (props.propsStyle.direction) {
        button.style.direction = props.propsStyle.direction
      }
    }
  })
})

const styles = computed(() => ({
  'width': props.propsStyle.width || '100%',
  'height': props.propsStyle.height || '100%',
}))

</script>

<style lang="less" scoped>
.apple-pay-btn {
  width: v-bind('styles.width');
  height: v-bind('styles.height');
  padding: 0;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

/* 为了兼容苹果支付按钮样式 */
apple-pay-button {
  --apple-pay-button-width: v-bind('styles.width');
  --apple-pay-button-height: v-bind('styles.height');
  --apple-pay-button-border-radius: 0px;
  --apple-pay-button-padding: 5px 0px;
  --apple-pay-button-box-sizing: border-box;
}
</style>
