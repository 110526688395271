import { promiseAsyncAuto } from '../asyncAuto.js'
import {
  goodsApiCache,
  selectionAndEntityFilterApiCache,
  selectionAndEntityCloudTagApiCache,
  categoryDayTagApiCache,
} from '../api/bffApi.js'
import { getSelectGenerateBffParams } from '../bffParamsHandle/index.js'
import { requestPicNav, getCommonRequestQueue } from './common.js'
import { generateRealSelectBffFilterInfo } from '../utils'

const getSelectRequestQueue = ({ requestInfo, newSrcTabPageId, isStore, isBreadcrumbSwitchBff }) => {
  const { goodsParams, filterParams, picNavParams, cloudTagParams, categoryDayTagParams, cccSeoParams, cccOperationPaeams } = getSelectGenerateBffParams(requestInfo, newSrcTabPageId)
  const requestQueue = {
    // 一些公共请求（多语言等）
    ...getCommonRequestQueue({ noNeedLanguage: isStore, cccSeoParams, cccOperationPaeams }),
    // 商品列表
    goods: goodsApiCache.request({
      type: 'selection',
      params: goodsParams,
      withoutAbort: requestInfo.query?.withoutAbort,
    }),
    // 筛选
    filterData: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, {})
      filterParams._requestType = 'selection'
      filterParams._type = 'selection'
      picNavParams._requestType = requestInfo.type  // 传入，里面判断删除
      const result = await promiseAsyncAuto({
        bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
        bffPicNavResult: requestPicNav({ requestInfo, picNavParams }), // 图文导航
        bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
        bffDailyDatesResult: categoryDayTagApiCache.request(categoryDayTagParams) // 标签云中的date标签数据  没有show_daily不会请求
      })
      cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
    },
    // 面包屑 currentCat
    currentCat: async (cb) => {
      if (!isBreadcrumbSwitchBff) {
        return cb(null, null)
      }
      if (requestInfo.type === 'nextpage') return cb(null, null)

      cb(null, {
        bffCurrentCat: true,
        _from: 'bff',
      })
    },
  }

  return requestQueue
}

export {
  getSelectRequestQueue
}
