export default {
  price_info(state, getters, rootState, rootGetters) {
    return state.hotModules.priceInfo || state.coldModules.priceInfo
  },
  detail_promotion_floor(state, getters, rootState, rootGetters) {
    return state.hotModules.detailPromotionFloor || state.coldModules.detailPromotionFloor
  },
  sku_list(state, getters, rootState, rootGetters) {
    return state.hotModules?.saleAttr?.multiLevelSaleAttribute?.sku_list || state.coldModules?.saleAttr?.multiLevelSaleAttribute?.sku_list
  },
  skc_stock(state, getters, rootState) {
    return rootState.productDetail.hotModules.productInfo.stock || rootState.productDetail.coldModules.productInfo.stock
  },
  user_info(state, getters, rootState) {
    return rootState.productDetail?.hotModules?.userInfo || {}
  },
  product_detail_abt_info(state, getters, rootState, rootGetters) {
    if (rootGetters['productDetail/common/is_use_bff_fs']) {
      return rootGetters['productDetail/common/fs_abt']
    }
    return state.hotModules?.webProductDetailAbtInfo || {}
  },
  ship_info(state, getters, rootState) {
    return rootState.productDetail?.hotModules?.shipInfo || {}
  },
  add_cart_info(state) {
    return state.hotModules.addCartInfo
  },
  detail_page_bind_data(state, getters, rootState, rootGetters) {
    const goods_id = rootGetters['productDetail/common/goods_id']
    const goods_sn = rootGetters['productDetail/common/goods_sn']
    const goods_name = rootGetters['productDetail/common/goods_name']
    const productRelationID = rootGetters['productDetail/common/product_relation_id']
    const cat_id = rootGetters['productDetail/common/cat_id']

    const price_info = rootGetters['productDetail/price_info']
    const { salePrice = {} } = price_info || {}

    return {
      'data-goods_id': goods_id,
      'data-goods_sn': goods_sn,
      'data-spu': productRelationID,
      'data-cat_id': cat_id,
      'data-goods_name': goods_name,
      'data-goods_ga_price': salePrice?.amount || '',
    }
  },
}
