var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-8-3-49',
    data: {
      coupon_type: _vm.filterData?.labelId,
      is_add: _vm.filterData?.isCanAdd ? 1 : 0
    }
  }),expression:"{\n    id: '1-8-3-49',\n    data: {\n      coupon_type: filterData?.labelId,\n      is_add: filterData?.isCanAdd ? 1 : 0\n    }\n  }"}],staticClass:"coupon-tip-wrapper"},[_c('div',{staticClass:"coupon-tip-left"},[_c('div',{ref:"couponTip",class:_setup.isOverflow ? 'coupon-tip-overflow' : 'overflow-line2'},[_c('div',{ref:"couponText",staticClass:"text",class:{ 'overflow-line2': _setup.isOverflow },domProps:{"innerHTML":_vm._s(_vm.filterData?.addTip)}}),_vm._v(" "),_c('s-popover',{ref:"popover",staticClass:"pop-icon",attrs:{"trigger-type":"click","placemen":"bottom","prop-style":_setup.couponFilterPopoverStyle,"content":_vm.filterData?.questionMark},model:{value:(_setup.showPopoverState),callback:function ($$v) {_setup.showPopoverState=$$v},expression:"showPopoverState"}},[_c(_setup.Icon,{attrs:{"slot":"reference","name":"sui_icon_doubt_12px_2","size":"12px","color":"rgba(0, 0, 0, 0.6)"},slot:"reference"})],1)],1),_vm._v(" "),(_vm.filterData?.endTimeStamp)?_c('div',{staticClass:"count-down"},[_c('span',{staticClass:"end-in"},[_vm._v(_vm._s(_vm.filterData?.endTip))]),_vm._v(" "),_c(_setup.CountDownTime,{staticClass:"time",attrs:{"notReload":"","notHide":"","font-size":20/75 + 'rem',"time-stamp":+_vm.filterData?.endTimeStamp}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"coupon-tip-right"},[(_vm.filterData?.isCanAdd == '1')?_c('div',{staticClass:"add-btn",on:{"click":_setup.handleAddOn}},[_c(_setup.SAdapterText,{attrs:{"min-size":"10","text":_vm.language.SHEIN_KEY_PWA_18957}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }