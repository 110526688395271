<template>
  <div>
    <div
      v-if="equityTip"
      class="earned-benefits"
    >
      <Icon
        name="sui_icon_selected_16px"
        size="16px"
      />
      <span
        class="earned-benefits__text"
        v-html="equityTip"
      ></span>
    </div>
    <div 
      class="common-foot"
      :class="{
        'no-border': equityTip,
      }"
    >
      <div class="left">
        <p 
          class="tips"
          v-html="progressTip"
        ></p>
        <ProgressBar
          transparent
          animation
          :thresholds="thresholds"
        />
      </div>
      <div class="right">
        <s-button
          :type="['H80PX', 'primary']"
          class="cart-btn"
          :max-width="260/75"
          @click="handleBack"
        >
          {{ btnText }}
        </s-button>
        <IncentivePoint
          class="incentive-point"
          :tags="tags"
          :bubble="bubble"
        />
      </div>
    </div>
  </div>
</template>

<script>
import incentivePoint from 'public/src/pages/common/add-on/mixins/incentivePoint.js'
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  name: 'CommonFoot',
  components: {
    Icon,
    ProgressBar: () => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/ProgressBar.vue'),
  },
  mixins: [incentivePoint],
  props: {
    progressTip: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    equityTip: {
      type: String,
      default: '',
    },
    thresholds: {
      type: Array,
      default: () => ([])
    },
    addItemType: {
      type: [String, Number],
      default: '',
    },
  },
  methods: {
    handleBack() {
      this.$emit('back')
    },
  },
}
</script>

<style lang="less" scoped>
.earned-benefits {
  display: flex;
    align-items: center;
    padding: 0 12/37.5rem;
    width: 100%;
    height: 28/37.5rem;
    border-radius: 12/37.5rem 12/37.5rem 0 0;
    background-color: #FA6338;
    color: #fff;
  &__text {
    flex: 1;
    margin-left: 4/37.5rem;
    font-size: 12/37.5rem;
    .text-overflow();
  }
}
.common-foot {
  width: 100%;
  padding: 12/37.5rem;
  background: #fff;
  border-top: 1px solid #EAEAEA;
  display: flex;
  align-items: center;
  .no-border {
    border: none;
  }
  .left {
    flex-grow: 1;
    margin-right: 6/37.5rem;
    .tips {
      margin-bottom: 4/37.5rem;
      font-size: 12px;
      line-height: 14/37.5rem;
      color: @sui_color_gray_dark1;
      word-break: break-all;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // /deep/ font {
      //   font-size: 14px;
      //   color: #FA6338;
      // }
    }
  }
  .right {
    position: relative;
    .cart-btn {
      padding: 0 12/37.5rem;
    }
    .free-ship {
      .font-dpr(18px);
      line-height: 12px;
      position: absolute;
      height: 14/37.5rem;
      right: 0;
      top: -10/37.5rem;;
      padding: 0 4/37.5rem;
      border-radius: 20/75rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border: 1px solid #fff;
      background: @sui_color_safety;
    }
    .free-ico {
      margin-right: 2/37.5rem;
      transform: rotateY(0deg)/* rtl: rotateY(180deg) */;
    }
  }

  .incentive-point {
    position: absolute;
    right: 0;
    bottom: calc(100% - 0.05333333rem);
    z-index: @zindex-hack;
  }
}
</style>
