import { cloneDeep } from 'lodash'

// checkoutParams仅当code为0时重写，服务端强制回退场景
const isCurrNotMatchLastItem = (selected, histories) => {
  const lastItem = histories[histories.length - 1]
  
  return Object.keys(lastItem).some((key) => {
    const itemA = lastItem?.[key]
    const itemB = selected?.[key]
    
    return itemA?.transport_type !== itemB?.transport_type
  })
}

/**
 * @description: 设置地址相关的信息，包括默认地址、地址总数、提示等
 * @return {*}
 */
export const setShippingMethod = (state, { checkout, isInit = false }) => {
  const { quick_shipping, mall_price_list = [], } = checkout
  
  const insuranceInfo = mall_price_list?.reduce((memo, mall) => {
    return {
      ...memo,
      [mall.mall_code]: +mall.insurance_info?.is_force_tick === 1 ? 1 : +mall.insurance_info?.is_use_insurance || 0
    }
  }, {})
  
  const selectedShippingList = checkout.shipping_methods_mall.map((item) => {
    const mall_code = +item.mall_code
    const selected = item.shipping_methods.find(item => +item.isDefault === 1)
    
    if(!selected) return null
    
    return {
      mall_code: mall_code,
      transport_type: selected.transport_type,
      last_transport_type: '',
      being_check: +selected.isDefault,
      use_insurance: insuranceInfo[mall_code] ?? 0,
      switch_qs_flag: quick_shipping?.switch_qs?.switch_qs_selected // qs跨境运费（目前仅支持单mall站点）
    }
  }).filter(Boolean)
  
  let histories = state.shippingMethodState.histories
  
  if(isInit || isCurrNotMatchLastItem(selectedShippingList, histories)) {
    histories = [
      cloneDeep(selectedShippingList)
    ]
  }
  
  Object.assign(state, {
    shippingMethodState: {
      ...state.shippingMethodState,
      histories,
      selectedShippingList
    }
  })
}
