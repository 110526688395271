import { throttle, debounce } from 'lodash'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
const { gbCommonInfo } = typeof window !== 'undefined' ? window : {}

const TYPE_MAP = {
  PIC_TOP_NAV: 'picTopNav',
  FILTER: 'filter',
  CLOUD_TAGS: 'cloudTags',
  ONE_CLICK_TAG: 'oneClickTag'
}

const TYPE_MAP_REF = {
  [TYPE_MAP.PIC_TOP_NAV]: 'refFixedPicTopNav',
  [TYPE_MAP.FILTER]: 'refFixedFilter',
  [TYPE_MAP.CLOUD_TAGS]: 'refFixedCloudTags',
  [TYPE_MAP.ONE_CLICK_TAG]: 'refFixedOneClickTag'
}

export default {
  props: {
    // scroll容器
    scrollEl: {
      type: String,
      default: ''
    },
    // 滚动多少开始吸顶
    scrollTop: {
      type: Number,
      default: -1
    },
    // 吸顶时候设置top值
    stickyTop: {
      type: Number,
      default: -1
    },
    isAutoSticky: {
      type: Boolean,
      default: true
    },
    /**
     * 固定大小图，不随滚动变化【店铺都是小图，不需要大图】
     * '': 跟随滚动切换大小图
     * small: 固定小图
     * big: 固定大图
     */
    fixedSmallStatus: {
      type: String,
      default: '' 
    },
    autoStickyParams: {
      type: Object,
      default: () => ({})
    },
  },
  data () {
    return {
      timer: 0,
      TYPE_MAP,
      isSmallPicTopNav: this.fixedSmallStatus === 'small', // 是否小图文
      isTouchInit: false, // 是否触摸初始化
      myAutoStickyParams: {},
      isAutoStickyIng: false,
      oneClickTagDynamicTop: 0, // 一键购标签吸顶高度，由于一键购标签在其他组件下方，需要动态计算高度(图文会变化高度)
      isStickySearch: false, // 是否搜索框置顶
      abtResultInfo: {},
    }
  },
  computed: {
    // 是否吸顶一键购标签
    isStickyOneClickTag() {
      return this.activedOneClickTag || this.filterConfig.isStickyOneClickTag
    },
    // 吸顶类型
    stickyType () {
      let stickyType = TYPE_MAP.FILTER
      this.filterConfig?.isStickyCloudTags && (stickyType = TYPE_MAP.CLOUD_TAGS)
      this.filterConfig?.isStickyPicToNav && !this.hidePicTopNav && (stickyType = TYPE_MAP.PIC_TOP_NAV)

      return stickyType

    },
    scrollTopMap () { 
      return {
        [TYPE_MAP.PIC_TOP_NAV]: this.getScrollTop(TYPE_MAP.PIC_TOP_NAV),
        [TYPE_MAP.FILTER]: this.getScrollTop(TYPE_MAP.FILTER, this.filterDynamicTop),
        [TYPE_MAP.CLOUD_TAGS]: this.getScrollTop(TYPE_MAP.CLOUD_TAGS, this.cloudTagsDynamicTop)
      }
    },
    stickyTopMap () { 
      return {
        [TYPE_MAP.PIC_TOP_NAV]: this.getStickyTop(TYPE_MAP.PIC_TOP_NAV),
        [TYPE_MAP.FILTER]: this.getStickyTop(TYPE_MAP.FILTER, this.filterDynamicTop),
        [TYPE_MAP.CLOUD_TAGS]: this.getStickyTop(TYPE_MAP.CLOUD_TAGS, this.cloudTagsDynamicTop)
      }
    },
    zIndexMap () {
      return {
        [TYPE_MAP.PIC_TOP_NAV]: this.getScrollFixedZIndex(TYPE_MAP.PIC_TOP_NAV),
        [TYPE_MAP.FILTER]: this.getScrollFixedZIndex(TYPE_MAP.FILTER),
        [TYPE_MAP.CLOUD_TAGS]: this.getScrollFixedZIndex(TYPE_MAP.CLOUD_TAGS),
        [TYPE_MAP.ONE_CLICK_TAG]: this.getScrollFixedZIndex(TYPE_MAP.ONE_CLICK_TAG)
      }
    }
  },
  watch: {
    isSmallPicTopNav(val) {
      if (typeof window === 'undefined' || this.fixedSmallStatus !== '') return
      if (val) this.isStickySearch = val
      // 图文吸顶后需要小图，过度时间300ms
      const start = Date.now()
      const loop = () => requestAnimationFrame(() => { 
        if (Date.now() - start > 300) return
        appEventCenter.$emit('updatePanelTop')
        loop()
      })

      loop() 
      this.$emit('picTopNavSmallChange', val)
    }
  },
  mounted () {
    this.initAbt() 
    // 店铺不需要执行此方法滚动到顶部: type:store店铺
    // if (this.catInfo.type === 'store' || !this.PageState.isSpaPage) return 
    if (this.catInfo.type === 'store') return 

    this.initFilterBarStyle()
  },
  beforeDestroy () {
    this.removeScrollEvent()
  },
  methods: {
    initScroll () {
      this.bindScrollEvent()
    },
    bindScrollEvent () {
      const dom = this.scrollEl
        ? document.querySelector(this.scrollEl)
        : document
      
      dom.addEventListener('scroll', this.handleScroll)
    },
    removeScrollEvent () {

      const dom = this.scrollEl
        ? document.querySelector(this.scrollEl)
        : document
      dom.removeEventListener('scroll', this.handleScroll)
    },
    handleProductListSearchScrollTop() {
      const {
        isStickyPicToNav,
        isStickyFilter,
        isStickyCloudTags
      } = this.filterConfig || {}
      const scrollTop = document.documentElement.scrollTop
      const { type } = this.catInfo || {}
      let res = false

      // 首页过来，命中SBC搜索框置顶实验
      const abtListshowsearch = this.abtResultInfo?.Listshowsearch?.param?.Listshowsearch === 'show' // 真实+选品 列表
      const abtRealListshowsearch = this.abtResultInfo?.Listshowsearch?.param?.CListshowsearch === 'show' // 真实 列表
      const abtSelectLsitshowsearch = this.abtResultInfo?.Listshowsearch?.param?.SCListshowsearch === 'show' // 选品 列表
      if (
        type === 'entity' && (abtListshowsearch || abtRealListshowsearch) ||
        type === 'selection' && (abtListshowsearch || abtSelectLsitshowsearch)
      ) {
        if (isStickyPicToNav || isStickyFilter || isStickyCloudTags) {
          res = true
        } else if (scrollTop > 0) {
          res = true
        }
      }
      if (type === 'picks') {
        if (this.abtResultInfo?.Listshowsearch?.param?.PListshowsearch === 'show') {
          if (isStickyPicToNav || isStickyFilter || isStickyCloudTags) {
            res = true
          } else if (scrollTop > 0) {
            res = true
          }
        }
      }
      // 搜索场景
      if (type === 'search') {
        res = true
      }
      this.isStickySearch = res
    },
    // 处理图文吸顶
    handleScrollPicTopNav () {
      const instance = this.$refs[TYPE_MAP_REF[TYPE_MAP.PIC_TOP_NAV]]
      const dom = instance?.$el
      if (!dom) return
      instance.onScroll()
      // 计算是否small
      if (!instance.isFixed) {
        this.setIsSmallPicTopNav(false)
        return
      }
      if (this.catInfo.type === 'store') {
        const domTarget = document.querySelector('.store-page-sticky') || document.querySelector('.j-common-logo-header')
        const rect = dom.getBoundingClientRect()
        const rect2 = domTarget?.getBoundingClientRect()
        this.setIsSmallPicTopNav((rect2.bottom - rect.top) > 40)
        return
      }
      const headerH = document.querySelector('.j-common-logo-header')?.clientHeight || 0
      const rect = dom.getBoundingClientRect()
      const diff = 40 - (this.isStickySearch ? headerH : 0)
      this.setIsSmallPicTopNav(rect.y < (headerH - 40) && Math.abs(rect.y) > diff)
    },
    // 处理筛选框吸顶
    handleScrollFilter () {
      const instance = this.$refs[TYPE_MAP_REF[TYPE_MAP.FILTER]]
      instance?.onScroll && instance.onScroll()
    },
    // 处理标签云吸顶
    handleScrollCloudTags () {
      const instance = this.$refs[TYPE_MAP_REF[TYPE_MAP.CLOUD_TAGS]]
      instance?.onScroll && instance.onScroll()
    },
    // 处理一键购标签吸顶
    handleScrollOneClickTag () {
      const instance = this.$refs[TYPE_MAP_REF[TYPE_MAP.ONE_CLICK_TAG]]
      instance?.onScroll && instance.onScroll()

      // 延迟处理吸顶
      setTimeout(() => {
        const sceneType = this.getSceneType()
        let top = this.isStickySearch
          ? document.querySelector('.j-common-logo-header')?.clientHeight || 0
          : 0
        // 一键购标签吸顶位置在当前吸顶组件之下
        const el = this.$refs[TYPE_MAP_REF[sceneType]]?.$el.querySelector?.('.content')
        // 当前吸顶组件的高度
        const upperComponentHeight = el?.offsetHeight || 0
        top += upperComponentHeight
  
        if (this.oneClickTagDynamicTop !== top) {
          this.handleScrollOneClickTag()
        }
        this.oneClickTagDynamicTop = top
      }, 100)
    },
    handleScroll: throttle(function () {
      // 面板激活下不处理滚动事件
      const showPanelComp = this.getAllRefPanel().find(item => item.show)

      const {
        isStickyPicToNav,
        isStickyFilter,
        isStickyCloudTags
      } = this.filterConfig || {}
      const { isStickyOneClickTag } = this
      !this.hidePicTopNav && isStickyPicToNav && this.handleScrollPicTopNav()
      isStickyFilter && this.handleScrollFilter()
      isStickyCloudTags && this.handleScrollCloudTags()
      isStickyOneClickTag && this.handleScrollOneClickTag()

      if (this.hidePicTopNav && !isStickyFilter && !isStickyCloudTags) {
        this.handleScrollFilter()
      }
      // 筛选面板激活时，不通知外面滚动方向，避免引起位移错乱（picks页）
      if (!showPanelComp && !this.isAutoStickyIng && !window?.isBodyFixed) {
        this.emitScrollDirection()
      }
      this.handleProductListSearchScrollTop()
      this.scrollEnd()
      this.updatePanelTop()
    }, 10),
    updatePanelTop () { 
      // 避免滚动中，导致最终top值不准确
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        clearTimeout(this.timer)
        appEventCenter.$emit('updatePanelTop')
      }, 200)
    },
    // TODO: 列表的滚动交互，还得改成向上滚动时，筛选向下translateY
    emitScrollDirection () {
      const nowScrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const delta = nowScrollTop - (this.lastScrollTop || 0)
      const scrollDown = delta > 0
      const scrollUp = delta < 0

      this.lastScrollTop = nowScrollTop

      // 同一方向滚动
      const isSameAsLastScrollStatus = scrollDown && this.lastScrollStatus === 'down' || scrollUp && this.lastScrollStatus === 'up'

      if (scrollDown) { // 向下滚动
        this.lastScrollStatus = 'down'
        this.$emit('scrollDown', { isSameAsLastScrollStatus })
      } else if (scrollUp) { // 向上滚动
        this.lastScrollStatus = 'up'
        this.$emit('scrollUp', { isSameAsLastScrollStatus })
      }
    },

    emitUpdatePanelTop () {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        appEventCenter.$emit('updatePanelTop')
      }, 250)
    },

    onGoodsLoadedOnPageList () {
      if (!appEventCenter) return Promise.resolve()

      // 避免goods 分块导致闪屏，设置列表高度，撑开页面
      $('.product-list-v2__list').css('min-height', '1000px')

      return new Promise(resolve => {
        appEventCenter.$once('goodsLoadedOnPageList', () => {
          $('.product-list-v2__list').css('min-height', null)
          resolve()
        })
      })
    },
    async initAbt() {
      this.abtResultInfo = await getUserAbtData()
    },
    // 进入页面或筛选后，筛选栏和头部样式重置，页面置顶
    async initFilterBarStyle ({ isFilter = false, dynamicTop = null } = {}) {

      // 处理头部header是否置顶
      this.handleProductListSearchScrollTop()

      this.emitUpdatePanelTop()

      // 切换页面
      if (!isFilter) {
        this.setIsSmallPicTopNav(false)
        this.resetScrollFixed()
        await this.onGoodsLoadedOnPageList()
        window.scrollTo(0, 0)
        appEventCenter.$emit('hidePanel')

        return
      }

      const rect = this.$refs.refFilterWrapper.getBoundingClientRect()
      const isScrolled = Math.floor(rect.y) <= this.getStickyTop(null, dynamicTop)
      if (!isScrolled || !rect.height) return

      this.scrollEl ? this.initFilterByScrollEl(dynamicTop) : this.initFilterByDocument(dynamicTop)
    },

    initFilterByScrollEl (dynamicTop) {
      document.querySelector(this.scrollEl).scrollTo(0, this.getStickyTop(null, dynamicTop))
      this.handleScroll(null, true)
    },
    // 根据配置是否切换小图，如不需要小图，则isSmallPicTopNav为true【店铺不需要大图模式】
    setIsSmallPicTopNav (val) { 
      if (this.fixedSmallStatus === 'big') {
        this.isSmallPicTopNav = false
      } else if (this.fixedSmallStatus === 'small') {
        this.isSmallPicTopNav = true
      } else {
        this.isSmallPicTopNav = val
      }
    },
    async initFilterByDocument () {
      const dom = this.$refs.refFilterWrapper
      if (!dom) return
      
      await this.onGoodsLoadedOnPageList()
      let scrollTop = 0
      let bodyTop = -parseInt($('body').css('top'))
      const lock = !!bodyTop
      const isSupportScroll = this.isSupportScroll()

      if (!isSupportScroll) { 
        lock
          ? $('body').css({ 'top': 0 }).attr('data-offset-top', 0)
          : window.scrollTo(0, 0)
        this.setIsSmallPicTopNav(false)
        this.resetScrollFixed()
        return
      }

      const { el, diff, top } = this.myAutoStickyParams
      const domTarget = el || document.querySelector(el)
      const rect = domTarget.getBoundingClientRect()
      scrollTop = Math.floor(rect.top) - top
      this.setIsSmallPicTopNav(this.stickyType === TYPE_MAP.PIC_TOP_NAV  && this.catInfo.requestType === 'refresh')
      if (lock) {
        if (Math.floor(rect.y) > top) return
        scrollTop += diff + bodyTop
        $('body').css({ 'top': -scrollTop }).attr('data-offset-top', scrollTop)

        return
      }

      scrollTop += diff + (document.body.scrollTop || document.documentElement.scrollTop)

      window.scrollTo(0, scrollTop)
      this.handleScroll(null, true)
    },

    handleFilterReset () {
      this.setIsSmallPicTopNav(false)
    },
    getScrollFixedZIndex (type) {
      const isStorePage = this.catInfo.type === 'store'
      let zIndex = this.isStickySearch ? 8 : 12
      if(isStorePage) zIndex = 8

      const {
        isStickyPicToNav,
        isStickyFilter,
        isStickyCloudTags
      } = this.filterConfig || {}
      const { isStickyOneClickTag } = this

      if (
        (type === TYPE_MAP.PIC_TOP_NAV && isStickyPicToNav)
        || (type === TYPE_MAP.FILTER && isStickyFilter)
        || (type === TYPE_MAP.CLOUD_TAGS && isStickyCloudTags)
        || (type === TYPE_MAP.ONE_CLICK_TAG && isStickyOneClickTag)
      ) {
        zIndex++
      }

      if (type === TYPE_MAP.FILTER && this.hidePicTopNav && !isStickyFilter && !isStickyCloudTags && !isStickyOneClickTag) {
        zIndex++
      }
      
      if (this.catInfo.type === 'picks' && !isStickyCloudTags && type === TYPE_MAP.CLOUD_TAGS) zIndex = 10
      if (this.catInfo.type === 'picks' && !isStickyOneClickTag && type === TYPE_MAP.ONE_CLICK_TAG) zIndex = 10

      return zIndex
    },
    getScrollTop (type, dynamicTop) {
      if (this.scrollTop !== -1) {
        return this.scrollTop
      }
      
      return this.getDefaultTop(type, dynamicTop)
    },
    getStickyTop (type, dynamicTop) {
      if (this.stickyTop !== -1) {
        return this.stickyTop
      }
  
      return this.getDefaultTop(type, dynamicTop)
    },

    getDefaultTop (type, dynamicTop) {
      if (typeof window === 'undefined') { // 没有触摸初始化，返回0
        return 0
      }

      if (!type) {
        type = this.getSceneType()
      }

      let top = this.isStickySearch
        ? document.querySelector('.j-common-logo-header')?.clientHeight || 0
        : 0
        
      return dynamicTop || (+dynamicTop === 0 && dynamicTop !== null) ? dynamicTop : top
    },
    /**
     * 获取当前吸顶场景类型，即以下三种吸顶场景
     * PIC_TOP_NAV、FILTER、CLOUD_TAGS
     */
    getSceneType() {
      let type = undefined
      this.filterConfig.isStickyPicToNav && (type = TYPE_MAP.PIC_TOP_NAV)
      this.filterConfig.isStickyFilter && (type = TYPE_MAP.FILTER)
      this.filterConfig.isStickyCloudTags && (type = TYPE_MAP.CLOUD_TAGS)
      return type
    },
    resetScrollFixed () {
      Object.values(TYPE_MAP_REF).forEach(ref => this.$refs[ref]?.reset?.())
    },
    scrollEnd: debounce(function () {
      if (gbCommonInfo?.WEB_CLIENT !== 'shein') return
      // 更新loading吸顶状态
      if (this?.filterConfig?.isStickyPicToNav) return
      if (this.isStickySearch) return
      if (!this.curPageRefresh) return
      const el = this.$refs[TYPE_MAP_REF[TYPE_MAP.FILTER]]?.$el
      if (!this.fetchLoadingEl) {
        this.fetchLoadingEl = document.querySelector('.S-loading.fetch-loading')
        this.originFetchLoadingTop = Number(this.fetchLoadingEl.style.top.replace('px', ''))
        if (isNaN(this.originFetchLoadingTop) || this.originFetchLoadingTop === 0) {
          this.originFetchLoadingTop = 43.875
        }
      }
      if (el) {
        const top = el.getBoundingClientRect().top
        if (top <= 0) {
          if (this.loadingTop === 0) return
          this.loadingTop = 0
        } else if (top >= this.originFetchLoadingTop) {
          if (this.loadingTop === this.originFetchLoadingTop) return
          this.loadingTop = this.originFetchLoadingTop
        } else {
          if (this.loadingTop === top) return
          this.loadingTop = top
          
        }
        this.fetchLoadingEl.style.top = `${this.loadingTop}px`
      }
    }, 100),
    async initSticky (type) {
      await this.onGoodsLoadedOnPageList()
      this.$refs[TYPE_MAP_REF[TYPE_MAP[type]]]?.scrollIntoView?.()
      this.resetScrollFixed()
    },
    // 页面高度是否支持滚动
    isSupportScroll () {
      const rect = document.querySelector('#app').getBoundingClientRect()
      return document.body.clientHeight < (Math.abs(rect.top) + rect.height)
    },
    async setAutoStickyParams () { 
      if (!this.filterConfig) return

      if (this.autoStickyParams?.el || this.autoStickyParams?.className) {
        this.myAutoStickyParams = this.autoStickyParams
        return
      }

      await this.$nextTick()
      let stickyType = this.stickyType
      const instance = this.$refs[TYPE_MAP_REF[stickyType]]
      let diff = 0
      if (stickyType === TYPE_MAP.PIC_TOP_NAV) {
        diff = instance?.$children[0]?.isJump ? 110 : 42 // 有跳转按钮，需要加上 title 高度
      }
      let top = instance?.scrollTop
      // 避免搜索结果页吸顶后无法下滑切换大图
      this.catInfo.type === 'search' && this.isStickySearch && (top -= 1)

      this.myAutoStickyParams = {
        stickyType,
        el: instance?.$el,
        top,
        diff,
        delay: (this.catInfo.type === 'picks' || stickyType === TYPE_MAP.PIC_TOP_NAV)
          ? 300
          : 100 // 图文吸顶后需要小图，所以延迟300ms
      }
    },
    onAutoStickyBefore () { 
      this.isAutoStickyIng = true
      if (this.myAutoStickyParams.stickyType === TYPE_MAP.PIC_TOP_NAV) {
        // 页面高度不足时，不需要吸顶
        this.setIsSmallPicTopNav(this.isSupportScroll())
      }
      this.$emit('autoStickyBefore')
    },
    onAutoStickyAfter() {
      this.isAutoStickyIng = false
      this.$emit('autoStickyAfter')
    }
  }
}
