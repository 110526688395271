<template>
  <div :class="[prefixCls('mp__price')]">
    <!-- 价格 -->
    <span
      v-if="isClient"
      :class="prefixCls('mp__price-info')"
      :style="{ color: renderData.isMode ? '#222222' : renderData.price.color }"
    >
      {{ renderData.price.txt }}
    </span>
    <div
      v-else
      :class="prefixCls('mp__price-info')"
      :style="{ maxWidth: '74px' }"
    >
      <SAdapterText
        :class="`${prefixCls('mp__price-info')}_${data.id}`"
        min-size="10"
        :text="renderData.price.txt"
        :style="{ color: renderData.price.color }"
      />
    </div>

    <!-- 折扣角标 -->
    <Discount
      v-if="!renderData.isPriceTxtTooLong && !renderData.isMode"
      :data="data"
      isMultiple
      :class="[prefixCls('mp__discount')]"
    />
  </div>
</template>

<script setup name="ProductPrice">
import { computed } from 'vue'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import Discount from '@/public/src/pages/checkout_new/pages/shopping_bags/components/common/discount.vue'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import usePrice from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/usePrice.js'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

const { isClient } = useIsClient()

const renderData = computed(() => {
  // 组合式API === 处理公共业务
  const { price, isPriceTxtTooLong, isMode } = usePrice(props?.data)
  return {
    price: price.value,
    isPriceTxtTooLong: isPriceTxtTooLong.value,
    isMode: isMode.value,
  }
})
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}mp {
  &__price {
    width: 148/75rem;
    height: 30/75rem;
    text-align: center;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #222222;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8/75rem;

    &-info {
      .font-dpr(24px);
      font-weight: bold;
    }
  }

  &__discount {
    display: inline-block;
    border-radius: 2px;
    padding: 2/75rem 4/75rem;
    .font-dpr(20px);
    .margin-l(4/75rem);
  }
}
</style>
