
<template>
  <!--付费会员优惠信息-->
  <div
    v-if="autoUseCouponTip || primeFreeTip"
    :class="prefixCls('prime__wrapper')"
  >
    <div
      v-if="autoUseCouponTip"
      :class="prefixCls('prime__free-ship')"
    >
      <span>
        {{ autoUseCouponTip }}
      </span>
      <span
        @click="goCouponPage"
      >
        {{ language.SHEIN_KEY_PWA_22734 }}
      </span>
    </div>

    <!--引导加入付费会员-->
    <div
      v-else-if="primeFreeTip"
      v-expose="{
        id: 'expose_prime_entry:simple',
        data: saObj
      }"
      :class="prefixCls('prime__join-member')"
    >
      <span :class="prefixCls('prime__join-member__label')">
        <div :class="prefixCls('prime__join-member__img-box')">
          <img
            :class="prefixCls('prime__join-member__img')"
            :src="primeFreeTip.prime_icon_url"
            alt=""
          />
        </div>
        <span v-html="primeFreeTip.free_shipping_tip"></span>
      </span>
      <span
        v-tap="{
          id: 'click_prime_entry:simple',
          data: saObj
        }"
        role="button"
        :class="prefixCls('prime__join-member__btn-group')"
        @click="onClickJoinPrime"
      >
        <span>
          {{ primeFreeTip.button_text }}
        </span>
      </span>
    </div>
  </div>
</template>

<script setup name="ShippingPrime">
import { computed, watch } from 'vue'
import { prefixCls } from './utils/index'
import { useMapState, useMapGetters, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'
import { PrimeDrawerLocation } from 'public/src/pages/checkout_new/pages/prime_saver/utils/constant.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

const { shipping_methods_mall, mall_price_list, coupon_list } = useMapState(['checkout.shipping_methods_mall', 'checkout.mall_price_list', 'checkout.coupon_list'])
const { updateShipPopupByKey, updatePrimeSaverState } = useMapMutations(['updateShipPopupByKey', 'updatePrimeSaverState'])
const { fullShippingMethodsSelectedInfo } = useMapGetters(['fullShippingMethodsSelectedInfo'])

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  autoUseCouponTip: {
    type: String,
    default: ''
  },
  primeFreeTip: {
    type: Object,
    default: null
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

const saObj = computed(() => {
  return { texttype: props.primeFreeTip.prime_free_shipping_type, prime_level: '0', total_saving: '0', location: 'page', prime_promotion: '0', expiring_flag: '-', renew_flag: '0' }
})

// 加入付费会员弹窗
const onClickJoinPrime = () => {
  updatePrimeSaverState({
    primeDetailsDrawerVisible: true,
    primeDetailsDrawerLocation: PrimeDrawerLocation.SHIPPING
  })
}

const couponTpSa = computed(() => {
  const selectedShipItem = fullShippingMethodsSelectedInfo.value[props.mallCode]
  const mall = shipping_methods_mall.value.find(item => +item.mall_code === +props.mallCode)

  if(+selectedShipItem.shipping_price_symbol_amount !== 0 && mall.used_prime_shipping_coupon) {
    return 1
  }

  if(+selectedShipItem.shipping_price_symbol_amount < +selectedShipItem.originNoFreeShippingPriceAmount &&  selectedShipItem.originNoFreeShippingPriceAmount > 0 && +mall.used_prime_shipping_coupon === 1) {
    return 2
  }

  return 0
})

const actualShippingPrice = computed(() => {
  const selectedShipItem = fullShippingMethodsSelectedInfo.value[props.mallCode]
  const currMall = mall_price_list.value.find(item => +item.mall_code === +props.mallCode)
  const target = currMall?.shipping_price_all?.find(item => item.transport_type === selectedShipItem?.transport_type)

  return target?.price?.amount
})

// 打开使用优惠券弹窗
const goCouponPage = () => {
  updateShipPopupByKey({
    key: 'couponDrawerVisible',
    value: true
  })

  daEventCenter.triggerNotice({
    id: 'click_shipping_coupon:simple',
    data: {
      mall_code: props.mallCode,
      coupon_tp: couponTpSa.value,
    }
  })
}

watch(() => fullShippingMethodsSelectedInfo.value[props.mallCode]?.shipping_price_symbol_amount, () => {
  daEventCenter.triggerNotice({
    id: 'click_shipping_coupon:simple',
    data: {
      mall_code: props.mallCode,
      coupon_tp: couponTpSa.value,
    }
  })
})

const primeCoupons = computed(() => {
  return coupon_list.value?.filter(item => +item.applyFor === 9 && +item.typeId === 53 && (+item.coupon_dimension === 1 || +item.mall_code === props.mallCode)) || []
})

watch(actualShippingPrice, (newVal, oldVal) => {
  if (newVal !== oldVal && props.autoUseCouponTip && primeCoupons.value.length > 0) {
    daEventCenter.triggerNotice({
      id: 'expose_shipping_coupon:simple',
      data: {
        coupon_tp: couponTpSa.value,
        mall_code: props.mallCode,
      }
    })
  }
}, {
  immediate: true,
})
</script>


<style lang="less">
@import "./variables";

.@{prefixCls} {
  &prime {
    &__wrapper {
      margin-top: 12/75rem;
    }

    &__free-ship {
      margin: 0 @g_ship_pad_hor_offset;
      background: @sui_color_promo_bg;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20/75rem;
      .font-dpr(24px);

      > :first-child {
        color: @sui_color_club_rosegold_dark1;
      }

      > :last-child {
        color: @sui_color_link;
        .margin-l(24/75rem);
      }
    }

    &__join-member {
      margin: 0.16rem @g_ship_pad_hor_offset 0.16rem @g_ship_pad_hor_offset;
      display: flex;
      padding: 20/75rem;
      justify-content: space-between;
      align-items: center;
      .font-dpr(24px);
      background: #FFF2E7;
      color: @sui_color_club_rosegold_dark1;

      &__label {
        flex: 1;
        display: inline-flex;
        align-items: center;
        i {
          .padding-r(16/75rem);
        }
      }


      &__img-box {
        width: 30/75rem;
        height: 30/75rem;
        margin-right: .21333333rem;
      }

      &__img {
        width: 30/75rem;
      }

      &__btn-group {
        padding: 10/75rem 16/75rem;
        border-radius: 34px;
        flex-shrink: 0;
        min-width: 80/75rem;
        max-width: 208/75rem;
        .margin-l(24/75rem);
        border: 1px solid @sui_color_club_rosegold_dark1;
        text-align: center;

        > span {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
