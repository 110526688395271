import { PaymentProcessor, CHECKOUT_TYPE, PRODUCT_TYPE } from '@shein-aidc/bs-sdk-libs-pay'
import bsUtils from 'public/src/pages/common/handlePay/bsLib.js'
import { onBeforeUnmount, onDeactivated } from 'vue'

let paymentInstance

// 获取支付实例
export const usePayInstance = () => {
  if (typeof window === 'undefined') return { paymentInstance }

  if (!paymentInstance) {
    paymentInstance = new PaymentProcessor({
      checkoutType: CHECKOUT_TYPE.NORMAL_AGAIN, // 普通商品二次下单
      productType: PRODUCT_TYPE.NORMAL, // 普通商品
      useInline: false,
      environment: ['debug', 'localhost'].includes(
        gbCommonInfo?.NODE_SERVER_ENV,
      )
        ? 'TEST'
        : 'PRODUCITON',
      bsUtils,
    })
  }

  const enableDebugLog = sessionStorage?.getItem?.('__bs__enable_debug_log')
  if (enableDebugLog) {
    window.__PAY_INSTANCE__ = paymentInstance
  }

  onBeforeUnmount(() => {
    paymentInstance?.destroy?.()
    paymentInstance = null
  })

  onDeactivated(() => {
    paymentInstance?.destroy?.()
    paymentInstance = null
  })

  return {
    paymentInstance,
  }
}
