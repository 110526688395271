<template>
  <div
    class="bsc-gc-card"
  >
    <div
      class="card-prime"
      :style="{
        ...getGrayStyle,
        height: props.cardHeight
      }">
      <div
        v-if="isShowSeal"
        class="card-prime__fail">
        <img
          class="img"
          :src="getItem.unavailable_seal_url" />
        <span
          class="text"
          :style="{
            transform: `translate(${GB_cssRight? '50%' : '-50%'}, -50%) rotate(-15deg)`
          }"
        >
          {{ getItem.unavailable_seal_tip }}
        </span>
      </div>
      <div
        class="card-prime__header"
        :style="getGrayStyle">
        <img
          class="card-prime__img"
          :src="getCardImg" />
        <div class="card-prime__content">
          <div
            v-if="getItem.card_no_tip"
            class="content__id">
            <span>{{ getItem.card_no_tip || '' }}</span>
            <Icon
              v-if="props.enableCopy"
              class="copy-btn"
              name="sui_icon_copy_14px"
              size="14px"
              :data-clipboard-text="getItem.card_no"
              @click="handleCopyId"
            />
          </div>
          <div class="content__val">
            {{ getPriceTitle }}
            <div
              v-if="props.enableGoDetail"
              class="content__val--more"
              @click="() => emits('goDetail', getItem.card_no)">
              <span>{{ language.SHEIN_KEY_PWA_35136 }}</span>
              <Icon
                name="sui_icon_more_right_16px"
                size="12px"
                color="#fff"
                :is-rotate="GB_cssRight"
              />
            </div>
          </div>
        </div>
        <div
          v-if="getItem?.bind_card_tip"
          class="card-prime__tag"
        >
          <img
            src="https://shein.ltwebstatic.com/svgicons/2024/11/21/17321768361225399327.svg"
            alt="svg" />
          <div class="tip">{{ getItem.bind_card_tip }}</div>
        </div>

      </div>
      <div
        class="card-prime__expire"
        @click="!props.readonly && emits('click')"
      >
        <div
          v-if="!props.readonly"
          class="expire__checked">
          <SRadio
            margin-r="0"
            :value="isSelect"
            :label="true"
          />
        </div>
        <div class="expire__tip">
          {{ getItem.card_status_tip }}
        </div>
        <div class="expire__footer">
          <div class="time">
            {{ getExpTitle }}
          </div>
          <div
            class="balance"
            :style="{
              direction: 'ltr',
              display: 'flex',
              alignItems: 'baseline'
            }"
          >
            {{ getBalanceText.beforeText }}&nbsp;
            <span
              v-if="getBalanceSignBefore"
              class="balance__small">
              {{ getPriceSign }}
            </span>
            <TextAdaptation
              class="balance__large"
              :text="getPriceInteger"
              :size-limits="[14, 18]"
              max-width="1.7867rem"
            />
            <span class="balance__small">
              {{ getPriceDecimal }}
            </span>
            <span
              v-if="!getBalanceSignBefore"
              class="balance__small">
              {{ getPriceSign }}
            </span>
            {{ getBalanceText.afterText }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="getItem.unavailable_reason"
      class="bsc-gc-card__footer">
      <CollapsibleText
        :text="getItem.unavailable_reason"
      >
        <template #left-icon>
          <Icon
            name="sui_icon_caution_18px"
            size="12px" />
        </template>
      </CollapsibleText>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, computed } from 'vue'
import { template } from '@shein/common-function'
import Clipboard from 'clipboard'
import { Toast as SToast, Radio as SRadio } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { timeTransformer } from '@shein/common-function'
import CollapsibleText from './CollapsibleText.vue'
import TextAdaptation from './TextAdaptation.vue'
import { MESSAGE_DATE_PWA } from '../../../common/time'
import { type C_GiftCard } from '../../../types/index.ts'

const { GB_cssRight } = gbCommonInfo

const props = withDefaults(defineProps<{
    backgroundGray?: boolean
    enableCopy?: boolean
    enableGoDetail?: boolean
    readonly?: boolean
    isSelect?: boolean
    item: C_GiftCard.CardItem
    cardHeight?: string,
  }>(),
  {
    backgroundGray: false,
    enableCopy: false,
    enableGoDetail: false,
    readonly: false,
  })

const emits = defineEmits(['click', 'goDetail'])

const language: C_GiftCard.language = inject('language')!

const getItem = computed(() => props.item)
const isShowSeal = computed(() => getItem.value.unavailable_seal_url && getItem.value.unavailable_seal_tip)
const getGrayStyle = computed(() => (props.backgroundGray && isShowSeal.value) ? {
  filter: 'grayscale(100%)',
} : undefined)
const getPriceTitle = computed(() => template(getItem.value?.shop_price?.amountWithSymbol, language?.value?.SHEIN_KEY_PWA_35102) || '')
const getCardImg = computed(() => getItem.value?.image_url || 'https://img.ltwebstatic.com/images3_ccc/2024/11/21/95/173217579574e3e2ccd14b9be72301ceed2c4c1b17.png')
const getExpTitle = computed(() =>{
  const isPreStatus = [0, 1].includes(+getItem.value?.card_status)
  const expTime: number = +(isPreStatus ? getItem.value?.pre_active_time : getItem.value?.expire_time) || 0
  const time = expTime ? `${timeTransformer({
    time: expTime * 1000,
    sDateMap: MESSAGE_DATE_PWA,
    multiLang: false,
  })} ${timeTransformer({
    time: expTime * 1000,
    defaultCode: 'G-1',
    multiLang: false,
  })}` : 0
  const preTimeTip = time ? template(time, language?.value?.SHEIN_KEY_PWA_35137) : ''
  const expireTimeTip = getItem.value.expire_time === 0 ? language?.value?.SHEIN_KEY_PWA_35105 : template(time, language?.value?.SHEIN_KEY_PWA_35104)
  return isPreStatus ? preTimeTip : expireTimeTip
})
const getBalanceText = computed(() => {
  const matches = (language?.value?.SHEIN_KEY_PWA_35106 || '')?.match(/(.*?)\{0\}(.*?)/)
  return matches ?
  {
    beforeText: matches?.[1]?.trim() || '',
    afterText: matches?.[2]?.trim() || '',
  } :
  {
    beforeText: '',
    afterText: '',
  }
})

const getBalanceSignBefore = computed(() => `${parseInt(getItem.value?.balance?.amountWithSymbol)}` === 'NaN')

const getPriceSign = computed(() => {
  const regExp = new RegExp(`(?<left>.*?)(${getItem.value?.balance?.priceShowStyle})(?<right>.*)`)
  const { groups } = getItem.value?.balance?.amountWithSymbol.match(regExp) || {}
  return groups?.left || groups?.right || ''
})
const getPriceInteger = computed(() => getItem.value?.balance?.priceShowStyle)
const getPriceDecimal = computed(() => {
  try {
    const regExp = new RegExp(`(?<left>.*?)(${getItem.value?.balance?.priceShowStyle})(?<right>.*)`)
    const { groups } = getItem.value?.balance?.amountWithSymbol.match(regExp) || {}
    const val = `${groups?.left}${groups?.right}`
    const reg = new RegExp(`\\${getPriceSign.value}`,'g')
    return val?.replace(reg, '')
  } catch(e) {
    return ''
  }
})

const handleCopyId = () => {
  if (!getItem.value.card_no) return
  try {
    const clipboard = new Clipboard('.copy-btn')
    clipboard.on('success', (e) => {
      SToast(language?.value?.SHEIN_KEY_PWA_16466 || 'copy success')
      e.clearSelection()
      clipboard.destroy()
    })
  } catch(err) {
    console.error('Failed to copy: ', err)
  }
}
</script>

<style lang="less">
.bsc-gc-card {
  color: #000;
  position: relative;
  .card-prime {
    position: relative;
    border-radius: .32rem;
    overflow: hidden;
    background: url('https://img.ltwebstatic.com/images3_ccc/2024/12/16/e4/1734341496156e41f159b07e35a9169fe7b498ab50.webp') no-repeat;
    background-size: 100%;
    background-position: bottom;
    &__fail {
      position: absolute;
      z-index: 10;
      right: .32rem;
      top: .32rem;
      .img {
        width: 1.8933rem;
      }
      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.6rem;
        color: #666;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: .32rem;
        font-weight: 400;
      }
    }
    &__header {
      display: flex;
      align-items: center;
      padding: .24rem;
      background: url('https://img.ltwebstatic.com/images3_ccc/2024/11/21/b1/1732189594694982d6ce27685aafcd38f12e82c8d6.webp') no-repeat;
      background-size: 100%;
    }
    &__img {
      width: 1.3333rem;
      height: .8rem;
      margin-right: .16rem;
    }
    &__content {
      color: #FFF;
      white-space: nowrap;
      line-height: 1.2;
      .content {
        &__id {
          display: flex;
          align-items: center;
          margin-bottom: .08rem;
          span {
            overflow: hidden;
            max-width: 5.8933rem;
            text-overflow: ellipsis;
            font-size: .3733rem;
            font-weight: 590;
            margin-right: .08rem;
          }
        }
        &__val {
          display: flex;
          align-items: center;
          font-size: .2933rem;
          font-weight: 400;
          &--more {
            margin-left: .24rem;
            padding-left: .24rem;
            display: flex;
            border-left: .0267rem solid #fff;
            align-items: center;
            span {
              display: block;
              overflow: hidden;
              max-width: 3.2267rem;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    &__tag {
      position: absolute;
      right: .24rem;
      top: .16rem;
      display: flex;
      align-items: center;
      img {
        position: relative;
        top: -0.0533rem;
        width: .24rem;
        height: .24rem;
      }
      .tip {
        overflow: hidden;
        max-width: 1.12rem;
        white-space: nowrap;
        color: #FFD0CC;
        text-align: right;
        text-overflow: ellipsis;
        font-size: .24rem;
        font-weight: 400;
        margin-left: .0533rem;
      }
    }
    &__expire {
      padding: .24rem .24rem .8267rem;
      .expire {
        &__checked {
          position: relative;
          display: flex;
          justify-content: flex-end;
          right: 0;
          text-align: right;
          .S-radio__input {
            border-color: #222222;
            border-width: .0533rem;
          }
        }
        &__tip {
          overflow: hidden;
          color: #3CBD45;
          text-overflow: ellipsis;
          font-size: .32rem;
          font-weight: 590;
          max-width: 4.4267rem;
          white-space: nowrap;
          line-height: 1.5;
        }
        &__footer {
          box-sizing: border-box;
          position: absolute;
          bottom: .32rem;
          left: 0;
          width: 100%;
          padding: 0 .24rem;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          line-height: 1;
          .time {
            color: #525252;
            font-size: .2933rem;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 4.4533rem;
          }
          .balance {
            font-size: .32rem;
            font-weight: 400;
            display: flex;
            align-items: baseline;
            overflow: hidden;
            white-space: nowrap;
            max-width: 4.4533rem;
            &__large {
              position: relative;
              bottom: 0;
              line-height: 1;
              font-weight: 600;
            }
            &__small {
              font-size: .3733rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  &__footer {
    color:#BF4123;
    margin-top: .08rem;
    font-weight: 400;
  }
}
</style>
