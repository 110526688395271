import { computed, onMounted } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'

let isInit = false
let isUpdate = false
export const useCacheClose = (cacheKey = 'cart-cartLowStockPopover__isClose') => {
  const { state, commit } = useStore()
  const isCacheClose = computed(() => state.cartLowStockHasClose)

  function getStartOfTodayTimestamp() {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    return now.getTime()
  }

  const nowTime = getStartOfTodayTimestamp()
  
  const updateCacheClose = () => {
    if (isUpdate) return
    localStorage.setItem(cacheKey, nowTime)
    commit('cart_v2/updateState', {
      key: 'cartLowStockHasClose',
      value: true,
    })
    isUpdate = true
  }

  const initCache = () => {
    if (isInit) return
    const prevTime = localStorage.getItem(cacheKey)
    commit('cart_v2/updateState', {
      key: 'cartLowStockHasClose',
      value: Boolean(prevTime && +prevTime === nowTime),
    })
    isInit = true
  }

  onMounted(() => {
    initCache()
  })

  return {
    isCacheClose,
    updateCacheClose,
  }
}
