import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'

// 业务监控日志 - 页面打开成功次数累加
const monitorPageOpen = (vm, { newData }) => {
  const { pageName, fromPageType, pageFrom, entranceType, src_module } = newData.cat_info || {}
  metricPageSuccess(
    {
      page: pageName,
      status: newData?.goods?.length > 0 ? '1' : '0',
    }, 
    undefined, 
    {
      ...newData.cat_info,
      originalUrl: vm.$route.fullPath,
      isSpaPage: vm.PageState?.isSpaPage
    }
  )
  const pageSource = 
    fromPageType === 'category' 
      ? pageFrom 
      : (entranceType || fromPageType || pageFrom || src_module || 'otherss')
  metricPageSuccess({
    page: pageName,
    status: newData?.goods?.length > 0 ? '1' : '0',
    pageSource, // 页面来源
  }, 'page_success_total_with_source')
}

export {
  monitorPageOpen
}
