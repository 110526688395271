import { getLangs } from '@shein-aidc/bs-sdk-libs-manager'
import type { C_BenefitFloor, DS_BenefitFloor } from '../../../types'

const langsMap = {
  CountDownText: 'SHEIN_KEY_PWA_35211',
  ValueText: 'SHEIN_KEY_PWA_35212',
}

export const langs: DS_BenefitFloor.APIS_THIS['langs'] = async function() {
  const result = await getLangs<C_BenefitFloor.LangKeys>(langsMap, this.$schttp)
  sessionStorage.setItem('benefit_floor_langs', JSON.stringify(result))
  return result
}
