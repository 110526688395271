/**
 * 需调用前置路由的非卡支付路由
 */
export const PRE_ROUTING_PAYMENT_METHODS = [
  'routepay-konbini',
  'routepay-pix',
  'routepay-applepay',
  'routepay-googlepay',
  'routepay-oxxo',
  'routepay-spei',
  'routepay-mercadopago',
]

/**
 * dlocal相关渠道的支付方式
 */
export const DLOCAL_PAYMENT_METHODS = [
  'dlocal-oxxo',
  'dlocal-boleto',
  'dlocal-pix',
  'dlocal-eft',
  'dlocal-pse',
  'dlocal-nupay',
  'dlocal-spei',
  'dlocal-mercadopago',
]
