<template>
  <!-- used vue -->
  <div
    :class="containerClassObj"
  >
    <!-- header -->
    <section
      ref="orderDetailHeader"
      class="fixed-common-header detail-container-z-header"
    >
      <s-title-nav
        :title="language.SHEIN_KEY_PWA_15210"
        class="order-detail-header"
      >
        <template
          slot="preblock"
        >
          <sui_icon_nav_back_24px
            size="24px"
            :is-rotate="locals.GB_cssRight"
            @click.native="goBack"
          />
        </template>
        <template
          slot="endblock"
        >
          <div class="service-continer">
            <div
              v-if="robotShow && isShowSellerRobot"
              @click="reportServiceClick(1)"
            >
              <s-popover
                content="SHEIN UI"
                custom-class="service-popover"
                theme="light"
              >
                <div>
                  <div 
                    class="service-context" 
                    @click="toSellerRobot"
                  >
                    <div class="service-continer">
                      <div 
                        v-if="isShowSellerHint" 
                        class="server-hint"
                      ></div> 
                      <sui_icon_me_review_24px
                        slot="reference" 
                        :is-rotate="locals.GB_cssRight"
                        size="24px"
                      /> 
                    </div>
                    <span class="service-text">
                      {{ language.SHEIN_KEY_PWA_35429 }}
                    </span> 
                  </div>
                  <div 
                    class="service-context" 
                    @click="handleToRobot('orderDetailPage')"
                  >
                    <sui_icon_pay_support_24px
                      slot="reference" 
                      :is-rotate="locals.GB_cssRight"
                      size="24px"
                    /> 
                    <span class="service-text">
                      {{ language.SHEIN_KEY_PWA_34288 }}
                    </span>
                  </div>
                </div>
                <sui_icon_pay_support_24px
                  slot="reference" 
                  :is-rotate="locals.GB_cssRight"
                  size="24px"
                />  
              </s-popover>
            </div>
            <div
              v-else-if="isShowSellerRobot || robotShow"
              @click="intoRobot"
            >
              <SupportIcon 
                :un-read-chat-num="unReadChatNum"
              >
                <sui_icon_pay_support_24px
                  :is-rotate="locals.GB_cssRight"
                  size="24px"
                />
              </SupportIcon>
            </div>
            <div 
              v-if="isShowSellerHint" 
              class="server-hint"
            ></div>
          </div>
        </template>
      </s-title-nav>
    </section>

    <div
      :class="['detail-container-top-pad macc-order-detail', { 'order-detail-bottom': isUnPaidOrder }]"
    >
      <!-- 页面loading -->
      <lazy-mount mount-prop-name="show">
        <s-loading
          type="newpage"
          :show="pageLoading"
          :container-cover-style="{
            position:'fixed',
            height: '100%',
            width: '100%',
            background: '#fff'
          }"
        />
      </lazy-mount>

      <div
        v-if="!pageLoading && orderInfo.billno"
      >
        <!-- 详情页提示 -->
        <detail-tips
          :online-status="onlineStatus"
        />

        <!-- 无网alert -->
        <SNetworkErrorAlert
          class="network-error"
          :description="language.SHEIN_KEY_PWA_31743"
          @online-change="(val) => onlineStatus = val.online"
        />

        <!-- 有验证码订阅 -->
        <whats-app-verify-subscribe
          v-if="subscribed > -1 && showWhatsappSubscribe == 'VerifySubscribe' && !isUnPaidOrder"
          :language="language"
        />

        <!-- 详情页新倒计时&到货时间 -->
        <div
          v-if="isNewCountDownStatus && orderInfo.transport_time"
          ref="topCountDown"
          class="detail-top new-detail-count-down"
          :data-status="orderInfo.orderStatus"
        >
          <div class="top-content">
            <div class="status-title">
              {{ ordersStatus[orderInfo.orderStatus] }}
            </div>
            <div
              v-if="+countDown.h || +countDown.m || +countDown.s"
              ref="contdownNum"
              class="count-down-time"
            >
              {{ `${countDown.h} : ${countDown.m} : ${countDown.s}` }}
            </div>
          </div>
         
          <text-ellipsis
            v-if="showOrderTransportTime && showTransportTimeInfo"
            :isNeedShrink="true"
          >
            <template #default="{ className }">
              <div
                class="time-large__text"
                :class="className"
              >
                <!-- 普通时效 -->
                <template v-if="timeEffectsList.includes(1)|| timeEffectsList.includes(4) ">
                  <p class="count-down-day">
                    <span v-html="countDownTimeText"></span>
                    <span
                      v-if="showOrderDayPercentDes"
                      class="percent-time new-percent__time"
                    >
                      <span v-html="getOrderDayPercentInfo.day_percent_desc"></span>
                      <sui_icon_doubt_16px_2
                        v-if="getOrderDayPercentInfo.transport_time_detail_list && !!getOrderDayPercentInfo.transport_time_detail_list.length"
                        size="16px"
                        :is-rotate="locals.GB_cssRight"
                        @click.native.stop="showDayPercentList({ method: orderInfo.shipping_method, percentList: getOrderDayPercentInfo.transport_time_detail_list, percentDes: getOrderDayPercentInfo.day_percent_desc })"
                      />
                    </span>
                  </p>
                </template>
                <!-- qs时效 -->
                <p
                  v-if="(timeEffectsList.includes(2) || timeEffectsList.includes(4))"
                  v-expose="{ id: '1-19-1-86', data: { quickship_tp: timeEffectsList.includes(4)? 2 : 1 } }"
                  class="count-down-day"
                  v-html="getQuickShipTransportTime"
                >
                </p>
                <!-- 海陆运时效 -->
                <template v-if="timeEffectsList.includes(3)">
                  <p class="count-down-day">
                    <span v-html="largeSeaAndLandText"></span>
                    <span
                      v-if="showOrderDayLargePercentDes"
                      class="percent-time new-percent__time"
                    >
                      <span v-html="getOrderDayLargePercentInfo.day_percent_desc"></span>
                      <sui_icon_doubt_16px_2
                        v-if="getOrderDayLargePercentInfo.time_detail_list && !!getOrderDayLargePercentInfo.time_detail_list.length"
                        size="16px"
                        :is-rotate="locals.GB_cssRight"
                        @click.native.stop="showDayPercentList({ method: orderInfo.shipping_method, percentList: getOrderDayLargePercentInfo.time_detail_list, percentDes: getOrderDayLargePercentInfo.day_percent_desc })"
                      />
                    </span>
                  </p>
                </template>
              </div>
            </template>
          </text-ellipsis>

          <ClientOnly>
            <OrderPayButton 
              v-if="payNowAbtInfo.OrderdetailButtonStyleTest === 'on'"
              :module-name="moduleName"
              :beforeStartPay="asyncUpdateOrder"
              :createPayment="handleCreatePayment"
              @orderPayButtonNowNowEvt="orderPayButtonNowNowEvt"
            />
          </ClientOnly>
          <i class="iconfont icon-iconx"></i>
        </div>

        <!-- 订单优惠券 -->
        <return-coupons v-if="returnCouponNum > 1" />

        <!-- 旧到货时间 -->
        <div
          v-if="isLogisticstimeShow && showOrderTransportTime"
          class="detail-top detail-time"
        >
          <div class="detail-time-ctn">
            <template v-if="orderInfo.transport_time && !isNewCountDownStatus && showTransportTimeInfo">
              <div class="detail-time-item">
                <div>
                  <span>{{ language.SHEIN_KEY_PWA_17359 }}</span>
                  <span
                    v-if="showSFSTip" 
                    class="sfs-tip"
                  >
                    {{ language.SHEIN_KEY_PWA_33962 }}
                  </span>
                </div>
                
                <text-ellipsis :isNeedShrink="true">
                  <template #default="{ className }">
                    <div
                      class="time-large__text"
                      :class="className"
                    >
                      <!-- 普通时效 -->
                      <template v-if="timeEffectsList.includes(1) || timeEffectsList.includes(4)">
                        <span
                          class="prescription-text"
                        >
                          <span v-html="transportTimeText"></span>
                          <sui_icon_doubt_16px_2
                            v-if="orderInfo.showCombinedShow"
                            size="16px"
                            :is-rotate="locals.GB_cssRight"
                            @click.native="showCombinedTipsModal = true"
                          />
                        </span>
                  
                        <span
                          v-if="showOrderDayPercentDes"
                          class="prescription-time__text"
                        >
                          <span v-html="getOrderDayPercentInfo.day_percent_desc"></span>
                          
                          <sui_icon_doubt_16px_2
                            v-if="
                              getOrderDayPercentInfo.transport_time_detail_list &&
                                !!getOrderDayPercentInfo.transport_time_detail_list.length
                            "
                            size="16px"
                            class="icon"
                            :is-rotate="locals.GB_cssRight"
                            @click="
                              showDayPercentList({
                                method: orderInfo.shipping_method,
                                percentList: getOrderDayPercentInfo.transport_time_detail_list,
                                percentDes: getOrderDayPercentInfo.day_percent_desc
                              })
                            "
                          />
                        </span>
                      </template>
                      <!-- qs -->
                      <template v-if="(timeEffectsList.includes(2) || timeEffectsList.includes(4))">
                        <div
                          v-expose="{ id: '1-19-1-86', data: { quickship_tp: timeEffectsList.includes(4)? 2 : 1 } }"
                          class="prescription-text"
                        >
                          <span v-html="getQuickShipTransportTime"></span>
                          <sui_icon_doubt_16px_2
                            v-if="orderInfo.showCombinedShow"
                            size="16px"
                            :is-rotate="locals.GB_cssRight"
                            @click.native="showCombinedTipsModal = true"
                          />
                        </div>
                      </template>
                      <!-- 海陆运时效 -->
                      <div v-if="timeEffectsList.includes(3)">
                        <span
                          class="prescription-text"
                          v-html="transportLargeSeaAndLandText"
                        >
                        </span>
                        <span
                          v-if="showOrderDayLargePercentDes"
                          class="prescription-time__text"
                        >
                          <span v-html="getOrderDayLargePercentInfo.day_percent_desc"></span>
                          <sui_icon_doubt_16px_2
                            v-if="
                              getOrderDayLargePercentInfo.time_detail_list &&
                                !!getOrderDayLargePercentInfo.time_detail_list.length
                            "
                            size="16px"
                            class="icon"
                            :is-rotate="locals.GB_cssRight"
                            @click="
                              showDayPercentList({
                                method: orderInfo.shipping_method,
                                percentList: getOrderDayLargePercentInfo.time_detail_list,
                                percentDes: getOrderDayLargePercentInfo.day_percent_desc
                              })
                            "
                          />
                        </span>
                      </div>
                    </div>
                  </template>
                </text-ellipsis>
              </div>
            </template>
            <!-- 换货时效 -->
            <template v-if="orderInfo.exchange_shipping_time && orderInfo.exchange_shipping_time.transport_time">
              <div class="detail-time-item">
                <div>
                  <span>{{ language.SHEIN_KEY_PWA_18133 }}:</span>
                </div>
                <text-ellipsis>
                  <template #default="{ className }">
                    <p
                      class="time-text deatil-time__text"
                      :class="className"
                      v-html="exchangeTimeText"
                    >
                    </p>
                  </template>
                </text-ellipsis>
                <div
                  v-if="showExchangePercentDes"
                  class="percent-time"
                >
                  <span v-html="getExchangeDayPercentInfo.day_percent_desc"></span>
                  <sui_icon_doubt_16px_2
                    v-if="getExchangeDayPercentInfo.transport_time_detail_list && !!getExchangeDayPercentInfo.transport_time_detail_list.length"
                    size="16px"
                    :is-rotate="locals.GB_cssRight"
                    @click.native.stop="showDayPercentList({ method: orderInfo.shipping_method, percentList: getExchangeDayPercentInfo.transport_time_detail_list, percentDes: getExchangeDayPercentInfo.day_percent_desc })"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- 订单信息 -->
        <template v-if="!showUseNewPackage || (showUseNewPackage && [0, 12, 13, 16].includes(+orderInfo.orderStatus))">
          <detail-user-info 
            v-if="!isWaitingforPayment"
            ref="detailUserInfo"
            :deliveryEditAddressCond="deliveryEditAddressCond"
            :overFrontCondition="overFrontCondition"
            :overB2B2CEditAddress="overB2B2CEditAddress"
            :editAddressFlag="editAddressFlag"
            :merchant-order-can-edit="merchantOrderCanEdit"
            :toClickEditAddress="toClickEditAddress"
            @showEditAddressDialog="showEditAddressDialogEvt"
          />
          <detail-payment
            :toEditPayment="toEditPayment"
            :editAddressFlag="editAddressFlag"
            :deliveryEditAddressCond="deliveryEditAddressCond"
            :overB2B2CEditAddress="overB2B2CEditAddress"
            :editBillAddressFlag="editBillAddressFlag"
            :overFrontCondition="overFrontCondition"
            :toClickEditAddress="toClickEditAddress"
            :isShowCardBinDiscountABT="isShowCardBinDiscountABT"
            :toClickEditBillAddress="toClickEditBillAddress"
            :methodTabSelected="methodTabSelected"
            :newOrderInfo="newOrderInfo"
            :isUnPaiMoreAddressOrder="isUnPaiMoreAddressOrder"
            @tabChange="tabChange"
            @showEditAddressDialog="showEditAddressDialogEvt"
          />
        </template>

        <!-- 订单详情页返券 -->
        <order-return-coupon v-if="returnCouponNum === 1" />

        <!-- cod订单取消异常 -->
        <div
          v-if="showCodOrderCancelTips"
          class="cod-order-cancel-tips"
        >
          {{ htmlDecode(showCodOrderCancelTips) }}
        </div>

        <!-- 退款详情 -->
        <div
          v-if="orderInfo._showRefundDetail && showOldReturnsHistory"
          class="detail-userinfo detail-refund-record"
          @click="refundDetailFn"
        >
          <p>
            {{ language.SHEIN_KEY_PWA_14916 }} <sui_icon_more_right_16px_2 size="16px" :is-rotate="locals.GB_cssRight" />
          </p>
        </div>
        
        <!-- 商品信息 -->
        <div class="detail-inner">
          <div
            v-if="!showUseNewPackage"
            class="detail-products-contain detail-container-flexbetween"
            :class="{ 'no-bottom-border': showTabItem }"
          >
            <span
              class="title"
              :class="{ 'font': locals.IS_RW }"
            >{{ language.SHEIN_KEY_PWA_15184 }}
              <template v-if="splitParcelTip">
                <i
                  class="iconfont icon-pro"
                  @click="showSplitParcelTip=true"
                ></i>
              </template>
              <template v-if="isUnPaidOrder && orderInfo.orderGoodsSum">
                <span class="order-product-sum-item"> ({{ orderInfo.orderGoodsSum }} {{ orderInfo.orderGoodsSum > 1 ? language.SHEIN_KEY_PWA_15456 : language.SHEIN_KEY_PWA_16328 }})</span>
              </template>
            </span>
            <span
              v-if="showReturnRefundBtn"
              class="go-to-record"
              DA-type="syncClick"
              DA-sa-name="refund_return_record"
              DA-sa-beacon="1"
              DA-view-type="ordinary"
              @click="jumpReturnRefundList(orderInfo)"
            >
              <span class="content text-ellipsis">{{ language.SHEIN_KEY_PWA_17013 }}</span> <sui_icon_more_right_16px_2 size="16px" :is-rotate="locals.GB_cssRight" />
            </span>
          </div>

          <!-- cod拆包提示 -->
          <cod-split-package
            v-if="orderInfo.payment_method == 'cod' && codSplitPackageLen > 1"
            :cod-split-package-len="codSplitPackageLen"
            :language="language"
          />

          <!-- 多包裹分包tab -->
          <s-tab
            v-if="showTabItem"
            v-model="tabSelected"
            :type="'auto'"
            class="s-tab-new"
            @change="handleTabChange"
          >
            <s-tab-item
              v-for="(tab, i) in orderInfo._allOrderGoodsList"
              :id="i"
              :key="i"
              v-expose="{ id: '1-19-1-84', data: { package_id: getPackageIdByTab(tab), quickship_tp: getQuickShipTp(tab) } }"
            >
              <span
                v-if="i == orderInfo.processingTabIndex && (orderInfo._isShppedType == 1 || orderInfo._isShppedType == 3)"
                class="package-item"
              >
                {{ language.SHEIN_KEY_PWA_15215 }}
              </span>
              <span
                v-else
                class="package-item"
              >
                {{ language.SHEIN_KEY_PWA_15757 }} {{ i + (orderInfo.processingTabIndex == 0 ? 0: 1) }}
                <QuickShipLabel
                  v-if="isShowPackageTag(tab) || commonDurationText[i]"
                  class="package-quick-ship" 
                  size="mini"
                  :language="language"
                  :abt-info="quickShipAbt"
                  :customText="commonDurationText[i]"
                  :mall-info="getPackageMallInfo(tab)"
                  :show-arrow="true"
                />
                <LargeTimeLabel 
                  v-if="showSeaOrLandTypeText(tab)" 
                  class="package-large-label"
                  :isTab="true"
                  :language="language" 
                  :text="showSeaOrLandTypeText(tab)"
                />
                <span
                  v-show="combinedFlag[i] && i != tabSelected"
                  class="package-tab-remind"
                ></span>
              </span>
            </s-tab-item>
          </s-tab>

          <!-- 合单提示 -->
          <div
            v-if="combinedFlag[tabSelected]"
            v-expose="{ id: '1-19-1-52', data: { package_no: getPackageReferenceNumber, billno : orderInfo.billno } }"
            class="order-table-combined"
          >
            <s-alert
              type="info"
              jumpable
              @click.native="navToCombined"
            >
              {{ language.SHEIN_KEY_PWA_14591 }}
            </s-alert>
          </div>

          <!-- 包裹轨迹 -->
          <order-detail-track-info
            v-if="showUseNewPackage && orderInfo.trackList.length > 0"
            :tabSelected="tabSelected"
          />

          <!-- 商品展示区域 -->
          <div
            class="package-ctn"
            :class="{ 'package-ctn-tab': showTabItem }"
          >
            <template v-if="deepOrderGoodsList">
              <div
                v-for="(packageCtn, packageIndex) in newGoodsList(orderInfo.orderGoodsList[0])"
                :key="packageIndex"
                class="detail-goods"
                :class="{ 'fsp-element': packageIndex == 0 }"
              >
                <detail-goods-info
                  :item="packageCtn"
                  :mallOrStoreGoodsFlag="mallOrStoreGoodsFlag"
                  :tabSelected="tabSelected"
                  :methodTabSelected="methodTabSelected"
                  :isUnPaiMoreAddressOrder="isUnPaiMoreAddressOrder"
                  :isCanRefundAbt="isCanRefundAbt"
                  :isShowQuickShipTag="isShowQuickShipTag"
                  :orderStatusTextList="orderStatusTextList"
                  :jumpReturnRefundList="jumpReturnRefundList"
                  @handle-track="handleTrack"
                  @urging-goods="urgingGoods"
                  @handleShowSuccessPanel="handleShowSuccessPanel"
                />
              </div>
            </template>
            <template v-else>
              <div
                v-for="(packageCtn, packageIndex) in newGoodsList(orderInfo.orderGoodsList)"
                :key="packageIndex"
                class="detail-goods"
                :class="{ 'fsp-element': packageIndex == 0 }"
              >
                <detail-goods-info
                  :item="packageCtn"
                  :order-info="orderInfo"
                  :methodTabSelected="methodTabSelected"
                  :isUnPaiMoreAddressOrder="isUnPaiMoreAddressOrder"
                  :mallOrStoreGoodsFlag="mallOrStoreGoodsFlag"
                  :tabSelected="tabSelected"
                  :jumpReturnRefundList="jumpReturnRefundList"
                  @handle-track="handleTrack"
                  @urging-goods="urgingGoods"
                  @handleShowSuccessPanel="handleShowSuccessPanel"
                />
              </div>
            </template>
            <ReturnTimeTip
              v-if="isShowReturnTime"
              :package-return-time="packageReturnTime"
              :order-return-time="orderReturnTime"
              :order-info="orderInfo"
              :language="language"
            />
          </div>
        </div>

        <template v-if="showUseNewPackage && ![0, 12, 13, 16].includes(+orderInfo.orderStatus)">
          <detail-user-info 
            v-if="!isWaitingforPayment"
            ref="detailUserInfo"
            :deliveryEditAddressCond="deliveryEditAddressCond"
            :overFrontCondition="overFrontCondition"
            :overB2B2CEditAddress="overB2B2CEditAddress"
            :editAddressFlag="editAddressFlag"
            :merchant-order-can-edit="merchantOrderCanEdit"
            :toClickEditAddress="toClickEditAddress"
            @showEditAddressDialog="showEditAddressDialogEvt"
          />
          <detail-payment
            :toEditPayment="toEditPayment"
            :editAddressFlag="editAddressFlag"
            :deliveryEditAddressCond="deliveryEditAddressCond"
            :overB2B2CEditAddress="overB2B2CEditAddress"
            :editBillAddressFlag="editBillAddressFlag"
            :overFrontCondition="overFrontCondition"
            :toClickEditAddress="toClickEditAddress"
            :isShowCardBinDiscountABT="isShowCardBinDiscountABT"
            :toClickEditBillAddress="toClickEditBillAddress"
            :methodTabSelected="methodTabSelected"
            :isUnPaiMoreAddressOrder="isUnPaiMoreAddressOrder"
            :newOrderInfo="newOrderInfo"
            @tabChange="tabChange"
            @showEditAddressDialog="showEditAddressDialogEvt"
          />
        </template>

        <!-- 订单价格明细 todo -->
        <template v-if="isWaitingforPayment && orderInfo.billno">
          <order-price-detail
            entryType="awaitPay"
          />
        </template>
        
        <!-- 等待支付文案 -->
        <div
          v-if="(orderInfo.payment_type == 2 && [0, 12].includes(+orderInfo.orderStatus)) || orderInfo.orderStatus == 16"
          class="detail-oxxo-wrap"
        >
          <div
            class="detail-oxxo j-detail-oxxo"
            DA-view-type="ordinary"
            :DA-sa-name="orderInfo.orderStatus == 16 ? 'orderdetail_whypending' : 'orderdetail_alreadypaid'"
            :DA-sa-param="JSON.stringify({'paymethod': orderInfo.payment_method})"
          >
            <template v-if="orderInfo.payment_type == 2 && [0, 12].includes(orderInfo.orderStatus)">
              {{ waitingForUpdateTips(language, orderInfo.payment_method) }}
            </template>
            <template v-else-if="orderInfo.orderStatus == 16">
              <template v-if="orderInfo.payment_method == 'PayPal-paypal'">
                {{ locals.IS_RW ? language.SHEIN_KEY_PWA_17312 : language.SHEIN_KEY_PWA_17206 }}
              </template>
              <template v-else>
                {{ locals.IS_RW ? language.SHEIN_KEY_PWA_17314 : language.SHEIN_KEY_PWA_17207 }}
              </template>
            </template>
          </div>
        </div>

        <!-- 保价险服务 -->
        <my-service
          v-if="showMyService"
        />

        <!-- 基础信息展示区 -->
        <detailInfo :showOrderTransportTime="showOrderTransportTime" />

        <!-- 销售信息 -->
        <SellerInfo v-if="showSellerInfo" />

        <!-- 保价险 -->
        <MoreServices />

        <!-- faq -->
        <OrderHelp v-if="showfaqContent" />
      </div>
    </div>

    <ClientOnly>
      <!-- 未支付订单倒计时 -->
      <order-countdown
        v-if="orderInfo.billno"
        :btn-exp-show="btnExpShow && !['on1', 'on2'].includes(payNowAbtInfo.OrderdetailPromotionTest) && !(applepayDropoffOptimizationAbt && applepayDropoffOptimizationApollo)"
        :count-down="countDown"
        @updateCountDown="updateCountDown"
      />

      <!-- 底部按钮 -->
      <detail-footer
        ref="detailFooter"
        :order-info="orderInfo"
        :newOrderInfo="newOrderInfo"
        :page-not-loading="!pageLoading"
        :detailNewFnAbt="detailNewFnAbt"
        :count-down="countDown"
        :module-name="moduleName"
        :beforeStartPay="asyncUpdateOrder"
        :createPayment="handleCreatePayment"
        @toggle-expired-modal="toggleOrderExpiredModal"
        @handelcacncelbtnClick="handelCacncelBtnClick"
        @handleDeleteOrder="handleDeleteOrder"
        @handleCancelOrder="toCancelOrder"
        @cancelItemShow="cancelItemShow"
        @handleShowSuccessPanel="handleShowSuccessPanel"
        @showEditAddressDialog="showEditAddressDialogEvt"
        @resume-shipment="resumeShipment"
        @payNowOrVerifyNowEvt="payNowOrVerifyNowEvt"
      />

      <!-- 推荐位 -->
      <RecommendContainer 
        v-if="locals.WEB_CLIENT === 'shein' && !isUnPaidOrder && !pageLoading"
        class="orderDetail_recommend_module"
        showSceneType="orderDetail"
        :show="cccShow"
      />

      <!-- 地址 -->
      <address-vue
        ref="addressVue"
        :show="addressShow"
        :page-name="'orderDetails'"
        :page-type="editAddressType"
        :extra="extraAddress"
        :disabled="disabledAddress"
        @edit="addressEdit"
        @cancel="addressCancel"
        @error="updateError"
      />

      <store-address-vue
        v-if="orderInfo.subsidiary && TRANSPORT_TYPE_SHOP.includes(orderInfo.subsidiary.transport_type)"
        ref="storeAddress"
        :page-type="'order'"
        @save="saveStoreAddressEvt"
        @cancel="storeAddressCancel"
      />
      <retry-address 
        ref="retryAddress"
        :language="language"
        @newAddressShippingEdit="newAddressShippingEdit"
        @addressShippingEdit="addressShippingEdit"
        @retryConfirm="retryConfirm"
        @postStroeAddressData="postStroeAddressData"
      />

      <!-- 支付详情 -->
      <payment-detail />

      <!-- 退款历史记录 -->
      <refund-detail
        :refund-detail="refundDetail"
      />

      <!-- 取消订单 -->
      <cancel-mask
        :cancel-form="cancelForm"
        cancel-type="detail"
        :orders-all="[orderInfo]"
        @showmsg="showMsg"
        @clearChannelPayInfoEvt="clearChannelPayInfoEvt"
      />

      <!--支付方式-->
      <PaymentOptionsDrawer
        ref="paymentOptionsDrawerRef"
        :visible="pageStatus.showPaymentsDrawer"
        :module-name="moduleName"
        :is-direct-pay="!!unPaidOrderPaymentStatus.isImmediatelyPay"
        :order-payment-token-prefix-abt="orderPaymentTokenPrefixAbt"
        :beforeStartPay="asyncUpdateOrder"
        :createPayment="handleCreatePayment"
        :pay-now-hide-paypal-btn="true"
        @select-payment="handleSelectPaymentInfo"
        @confirm-payment="handleConfirmPayment"
        @pay-now="handlePayToolkitPayNow"
        @init-select-payment="handleInitSelectPayment"
        @select-payment-change="handleSelectPaymentChange"
        @close="handleCloseDrawer"
        @open="handleOpenDrawer"
        @manual-close="handleManualCloseDrawer"
        @bs-payment-options-init="handleBsPaymentOptionsInit"
      />
      
      <LazyMount mount-prop-name="show">
        <expired-modal
          :show="expiredModalConfig.show"
          :language="language"
          @toggle="toggleOrderExpiredModal"
        />
      </LazyMount>

      <modify-subscription-panel
        :modify-subscribe="modifySubscribe"
        :subscribe-area-code="subscribeAreaCode"
        :subscribe-phone-number="subscribePhoneNumber"
        :language="language"
        :subscribed="subscribed"
        :shipping-country="orderInfo.shipping_country_name"
        @confirmUnsubscribe="() => showUnsubscribeMask = true"
      />
      <LazyMount mount-prop-name="show">
        <SuccessPanel
          :show.sync="status.showSuccessPanel"
          :billno="orderInfo.billno"
          from="orderDetail"
        />
      </LazyMount>
      
      <!-- 订单提示弹框 -->
      <OrderMaskDialog />

      <!-- 订单提示loading -->
      <UserLoading />

      <!-- 多主体站点跳转 -->
      <orderCheckJumpMask
        ref="orderCheckJumpMask"
        :language="language"
      />

      <!-- 取消商品时的提示 -->
      <LazyMount>
        <cancel-item-tip
          :visible.sync="refundNotCodMask"
          :language="language"
          @confirm="orderRefundMask"
          @cancel="cancelRefundMask"
        />
      </LazyMount>

      <ShippingDayPercent 
        ref="shippingDayPercent" 
        :language="language"
        :dayPercentInfo="dayPercentInfo"
        :source="'order'"
        @closePercentList="closePercentList"
      />
      
      <!-- 卡bin优惠文章展示 -->
      <LazyMount>
        <CardBinDiscountArticle
          :visible.sync="visibleCardBinDiscountArticle"
        />
      </LazyMount>

      <!-- 推荐地址 -->
      <CustomerConfirmAddress
        v-if="showConfirmDialog"
        :showConfirmDialog.sync="showConfirmDialog"
        :orderData="newOrderInfo"
        :language="language"
      />

      <!-- 批量修改地址确认弹窗 -->
      <LazyMount>
        <edit-address-confirm-dialog
          :visible.sync="showEditAddressConfirmDialog" 
          :language="language"
          :billno="orderInfo.billno"
          :batch-modify-address="batchModifyAddressData"
          @confirm="onConfirmModifyAddress"
        />
      </LazyMount>

      <!-- 信息提示 -->
      <lazy-mount>
        <s-dialog
          :visible.sync="pageStatus.msg"
          :show-close="false"
          :close-on-click-modal="false"
          class="c-tip-modal tip-icon-dialog"
        >
          <template slot="title">
            <i
              v-if="msgBox.type == 'succ'"
              class="iconfont icon-success"
            ></i>
            <template v-if="msgBox.type == 'err'">
              <i
                v-if="!locals.IS_RW"
                class="iconfont icon-fail"
              ></i>
              <i
                v-else
                class="suiiconfont sui_img_nonetwork_75px"
              ></i>
            </template>
            <div>{{ msgBox.info }}</div>
          </template>
        </s-dialog>
      </lazy-mount>
      <!-- 地址编辑成功提示 -->
      <lazy-mount>
        <CommonTipsDialog
          :visible.sync="showBatchAddressMessage"
          :append-to-body="true"
          :close-on-click-modal="false"
          :show-close="false"
          :content="batchAddressMessage"
          :btn-type="['primary', 'H56PX']"
          :btn-text="language.SHEIN_KEY_PWA_15312"
          @ok="closeBatchAddressDialog"
        />
      </lazy-mount>
      <!-- return mask -->
      <lazy-mount>
        <s-dialog
          :visible.sync="inReturnItem.mask"
          :show-close="false"
          :close-on-click-modal="false"
          @close="inReturnItem.mask = false"
        >
          <template slot="title">
            <div tabindex="0">
              Have you received your package? Please confirm if you have, then you can make return request.
            </div>
          </template>
          <template slot="footer">
            <s-button-group
              :width="'100%'"
              hor
              :num="2"
            >
              <s-button-group-item
                :type="['primary','H80PX']"
                @click="returnItemMask"
              >
                {{ language.SHEIN_KEY_PWA_15252 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['H80PX']"
                @click="inReturnItem.mask = false"
              >
                {{ language.SHEIN_KEY_PWA_15253 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 地址接口成功与错误弹窗 -->
      <lazy-mount>
        <s-dialog
          :visible.sync="orderAddress.show"
          :show-close="false"
          :close-on-click-modal="false"
          class="c-tip-modal c-modal-address"
          append-to-body
        >
          <template slot="title">
            <div>
              <p class="icon-item">
                <i
                  v-if="orderAddress.type == 1"
                  class="iconfont icon-success"
                ></i>
                <i
                  v-if="orderAddress.type == 2"
                  class="iconfont icon-fail"
                ></i>
              </p>
              <p
                class="tips-item"
                v-html="orderAddress.text"
              ></p>
            </div>
          </template>
          <template slot="footer">
            <s-button
              :type="['primary']"
              :width="'100%'"
              @click="orderAddressTipsOk"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <lazy-mount mount-prop-name="show">
        <s-loading
          type="curpage"
          :show="deleteOrderStatus.loading"
          :mask-transparent="true"
        />
      </lazy-mount>

      <!-- delete order -->
      <lazy-mount>
        <s-dialog
          :visible="deleteOrderStatus.show"
          :show-close="false"
          @close="closeDeleteDialog"
        >
          <template slot="title">
            {{ language.SHEIN_KEY_PWA_16070 }}
          </template>
          <div v-if="!showDeleteHistory">
            {{ language.SHEIN_KEY_PWA_16071 }}
          </div>
          <template
            slot="footer"
          >
            <s-button-group
              hor
              :num="2"
              :width="'100%'"
            >
              <s-button-group-item
                :type="['H72PX']"
                @click="closeDeleteDialog('no')"
              >
                {{ language.SHEIN_KEY_PWA_15253 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['primary','H72PX']"
                @click="deleteOrder"
              >
                {{ language.SHEIN_KEY_PWA_15252 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 挽留订单 -->
      <lazy-mount>
        <s-dialog
          :visible.sync="retainOrder.mask"
          :show-close="false"
          :close-on-click-modal="false"
        >
          <template slot="title">
            <div tabindex="0">
              {{ getRetainTips(retainOrder.order.payment_method) }}
            </div>
          </template>
          <template slot="footer">
            <s-button-group
              :width="'100%'"
              :num="2"
            >
              <s-button-group-item
                :type="['H80PX']"
                @click="() => { toggleRetainOrder('ok'); handelCacncelBtnClick(retainOrder.order) }"
              >
                {{ language.SHEIN_KEY_PWA_16826 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['primary','H80PX']"
                @click="toggleRetainOrder('cancel')"
              >
                {{ language.SHEIN_KEY_PWA_16825 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </lazy-mount>

      <lazy-mount>
        <s-dialog
          class="single-btn-modal"
          :visible.sync="showSplitParcelTip"
          :show-close="false"
          :close-on-click-modal="false"
          @close="showSplitParcelTip=false"
        >
          <template slot="title">
            <div
              class="split-package-tip"
              tabindex="0"
            >
              {{ language.SHEIN_KEY_PWA_16964 }}
            </div>
          </template>
          <template slot="footer">
            <s-button
              :width="'100%'"
              :type="['primary','H80PX']"
              @click="showSplitParcelTip=false"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 订单取消，重新加回购物车 -->
      <lazy-mount>
        <s-dialog
          class="cart-add-modal"
          :visible.sync="status.showAddCart"
          :show-close="false"
          :close-on-click-modal="false"
          @close="handleCancelAddCartModal(false)"
        >
          <template slot="title">
            <div>{{ language.SHEIN_KEY_PWA_17377 }}</div>
          </template>
          <template slot="footer">
            <s-button-group
              :width="'100%'"
              :num="2"
            >
              <s-button-group-item
                :type="['primary','H80PX']"
                @click="handleCancelAddCartModal(true)"
              >
                {{ language.SHEIN_KEY_PWA_15018 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['H80PX']"
                @click="handleCancelAddCartModal(false)"
              >
                {{ language.SHEIN_KEY_PWA_17389 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </lazy-mount>

      <lazy-mount>
        <s-dialog
          :visible.sync="showUnsubscribeMask"
          :show-close="false"
          :close-on-click-modal="false"
          @close="showUnsubscribeMask=false"
        >
          <template slot="title">
            <div
              class="unsubscript-tip"
              v-html="language.SHEIN_KEY_PWA_17770"
            ></div>
          </template>
          <template slot="footer">
            <s-button-group
              :width="'100%'"
              hor
              :num="2"
            >
              <s-button-group-item
                :type="['H80PX']"
                @click="closeShowUnsubscribeMask"
              >
                {{ language.SHEIN_KEY_PWA_15253 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['primary', 'H80PX']"
                @click="unsubscribe"
              >
                {{ language.SHEIN_KEY_PWA_15252 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 合单时效变晚提示 -->
      <lazy-mount>
        <s-dialog
          :visible.sync="showCombinedTipsModal"
          :close-on-click-modal="false"
        >
          <template slot="title">
            {{ orderInfo.showCombinedTips }}
          </template>
          <template slot="footer">
            <s-button
              :type="['primary']"
              :width="'100%'"
              @click="showCombinedTipsModal = false"
            >
              {{ language.SHEIN_KEY_PWA_15312 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <lazy-mount>
        <s-dialog
          :visible.sync="relationParamError"
          :close-on-click-modal="false"
        >
          <template slot="title"></template>
          <div>Parameter error</div>
          <template slot="footer">
            <s-button
              :type="['primary']"
              :width="'100%'"
              @click="jumpToOrderList"
            >
              {{ language.SHEIN_KEY_PWA_15312 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <lazy-mount>
        <s-dialog :visible.sync="combinedShippedFlag">
          <div v-if="orderInfo.combined_shipped_flag == 1">
            {{ language.SHEIN_KEY_PWA_21421 }}
          </div>
          <div v-else>
            {{ language.SHEIN_KEY_PWA_21422 }}
          </div>
          <template slot="footer">
            <s-button
              width="100%"
              :type="['primary', 'H72PX']"
              @click="shippedEditAddress"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 支付失败提示 -->
      <lazy-mount>
        <s-dialog
          :visible.sync="showFailType.show"
          :close-on-click-modal="false"
          :append-to-body="true"
        >
          <template slot="title">
            {{ showFailType.text }}
          </template>
          <template slot="footer">
            <s-button
              :type="['primary']"
              :width="'100%'"
              @click="showFailType.show = false"
            >
              {{ language.SHEIN_KEY_PWA_15312 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>
      <!-- 添加购物车成功提示 -->
      <lazy-mount>
        <AddCartSuccessDialog
          :visible.sync="addCartStatus.showDrawer"
          :language="language"
          :info="addCartStatus.cartInfo"
        />
      </lazy-mount>
      <!-- 自主标记确认弹窗 -->
      <lazy-mount>
        <ResumeShipmentDialog
          :visible.sync="showResumeShipmentDialog"
          :language="language"
          :async-callbacks="asyncCallbacks"
          :info="resumeShipmentInfo"
          :order-info="orderInfo"
        />
      </lazy-mount>
      <!-- cod挽留弹窗 -->
      <BackTips
        v-if="selectedPaymentInfo?.payment_code === 'cod'"
        ref="paymentBackTipsRef"
        @back-tips-mounted="handleBackTipsMounted"
      />

      <CreateOrderBinDialog
        :language="language"
        :beforeStartPay="asyncUpdateOrder"
        @pay-now="handlePayToolkitPayNow"
      />
    </ClientOnly>
  </div>
</template>

<script>
import schttp from 'public/src/services/schttp'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { getQueryString, template, htmlDecode, timeTransformer } from '@shein/common-function'
import { abtservice } from 'public/src/services/abt'
import { jumpToRobot } from '@customerService/botLogic'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { hasRobotEntrance } from 'public/src/pages/common/robotEntrance'
import { isRelationBillno } from 'public/src/pages/pay_success/orderBillno'
import orderLogic from 'public/src/pages/common/orderLogic'
import orderFactory from '../common/orderFactory.js'
import { waitingForUpdateTips, retainPayMethodLang, getOrderStatusText, transformPercentList } from '../utils'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { formatDoubleTime, formatTime, formatQuickShipDesc, getNewTransportField, getSellerServer, jumpToSellerRobot } from 'public/src/pages/user/child_pages/orders/common/utils'
import Clipboard from 'clipboard'

import mixin from '../common/mixin'
import addressMixin from '../common/addressMixin'
import paymentMixin from 'public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/paymentMixin.js'
import { getCommonDurationText } from 'public/src/pages/user/child_pages/orders/common/utils'

import orderDetailStoreModule from '../../../../product_app/store/modules/user/order_detail'
import cancelItemStoreModule from '../../../../product_app/store/modules/user/order-cancel-item'
import { uniq } from 'lodash'
import components from './components.js'
import { convertAddress } from 'public/src/pages/components/address/config/tools.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { switchPaymentSer } from 'public/src/pages/user/child_pages/orders_bff/list/service.js'

const { langPath } = gbCommonInfo 

daEventCenter.addSubscriber({ modulecode: '2-30' })
daEventCenter.addSubscriber({ modulecode: '1-19-1' })
daEventCenter.addSubscriber({ modulecode: '1-20-1' })

let SaPageInfo = {
  page_id: 10,
  page_name: 'page_order_detail',
  page_param: { order_id: '', order_type: '' },
  start_time: new Date().getTime()
}

let routeFrom = {}
let initSsrDataPromise = null

export default {
  name: 'OrderDetail',
  directives: { expose },
  components,
  mixins: [mixin, addressMixin, paymentMixin({ storeNameSpace: 'orderDetail' })],
  async asyncData ({ store, context, route, from = {} } = {}) {
    if (!store.state['orderDetail']) store.registerModule('orderDetail', orderDetailStoreModule)
    if (!store.state['cancelItemModule']) store.registerModule('cancelItemModule', cancelItemStoreModule)
    // ssrPageType: ordersDetail
    initSsrDataPromise = store.dispatch('orderDetail/initSsrData', {
      from,
      route,
      context
    })
  },
  data () {
    return {
      addressBillno: '', // 地址billno
      methodTabSelected: 0, // 选中的地址索引
      moduleName: 'orderDetail', // 模块名称
      module_name: 'OrderDetail', // 模块名称
      TRANSPORT_TYPE_SHOP,
      waitingForUpdateTips,
      showFailType: {
        show: false,
        type: ''
      },
      showConfirmDialog: false,
      now_address: {},
      status: {
        trackDrawer: false,
        cancelConfirm: false,
        showAddCart: false,
        showSuccessPanel: false
      },
      tabSelected: 0,
      cancelForm: {
        billno: '',
        payment: '',
        reason: '',
        type: 1,
        show: false,
        order: {},
        status: '',
        order_type: ''
      },
      refundDetail: {
        show: false,
        data: {},
        text: {}
      },
      returnHistoryForm: {
        show: false,
        history: false,
        historyTracking: false,
        type: 0,
        data: [],
        mode: 0,
        modeType: 0,
        status: {},
        imgSrc: '',
        channelValue: '',
        trackingValue: '',
        trackingErr: '',
        returnId: '',
        imgMask: false,
        imgMaskSrc: ''
      },
      refundNotCodMask: false,
      deleteOrderStatus: {
        loading: false,
        show: false,
        billNo: ''
      },
      showSplitParcelTip: false,
      robotShow: false,
      isShowSellerRobot: false,
      isShowSellerHint: false,
      sellerStoreCode: '',
      modifySubscribe: {
        showModifyDrawer: false
      },
      showUnsubscribeMask: false,
      showCombinedTipsModal: false,
      isChangeShippingAddressName: false, // 是否修改了订单地址中的姓名
      detailNewFnAbt: '',
      btnExpShow: false,
      countDown: {
        h: '00',
        m: '00',
        s: '00',
      },
      relationParamError: false,
      deliveryEditAddressCond: '',
      showCombinedShippedFlag: false,
      combinedShippedFlag: false,
      deliveryUpdateInfo: {
        successText: '',
        errorText: ''
      },
      visibleCardBinDiscountArticle: false, // 卡bin优惠文章展示
      cccShow: false,
      onlineStatus: true,
      showAddCartTipsDialog: false,
      asyncCallbacks: [],
      resumeShipmentInfo: {},
      showResumeShipmentDialog: false,
      expiredModalConfig: {
        show: false,
        order: null
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    routeFrom = from
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.SaPageInfo = SaPageInfo
    document.removeEventListener('scroll', this.calCountDownShow)
    this.resetSsrState()
  
    next()
  },
  computed: {
    ...mapState(['unReadChatNum']),
    ...mapState('orderDetail', [
      'isSSR',
      'pageLoading',
      'paymentMethod',
      'orderInfo',
      'language',
      'apolloInfo',
      'orderType',
      'pageStatus',
      'msgBox',
      'inReturnItem',
      'review',
      'selectedIdealCode',
      'paymentOption',
      'showPartOperatorBtn',
      'updateShippedAddress',
      'showShippingDayPercent',
      'dayPercentInfo',
      'binDiscountList',
      'unPaidOrderLoadPayment',
      'orderResultAbtInfo',
      'codOrderOverDueInfo',
      'faqInfo',
      'enterType',
      'fromRecycle',
      'enterFailType',
      'extraBaseInfo',
      'SHOP_TRANSIT',
      'subscribePhoneNumber',
      'subscribed',
      'showWhatsappSubscribe',
      'editAddressNewConfigList',
      'fullPriceCouponPromotion',
      'locals',
      'orderStatusTextList',
      'addCartStatus',
      'currencySupport',
      'unPaidOrderPaymentStatus'
    ]),
    ...mapGetters('orderDetail', [
      'orderGoodsStatus',
      'ordersStatus'
    ]),
    markOrderParams() {
      return this.orderResultAbtInfo.markOrderParams || {}
    },
    commonDurationText() {
      return this.orderInfo._allOrderGoodsList.map(tabGoods => {
        return getCommonDurationText({
          language: this.language,
          abtInfo: this.quickShipAbt,
          mallInfo: this.getPackageMallInfo(tabGoods),
          localData: this.locals
        })
      })
    },
    orderPaymentTokenPrefixAbt() {
      return this.orderResultAbtInfo?.orderPaymentTokenPrefixAbt
    },
    showSFSTip () {
      let { SFSTipShow = {} } = this.orderResultAbtInfo || {}
      let { shipping_country_id = '', stock_mode = '', business_model = '', sales_area = '', orderStatus = '' } = this.orderInfo || {}
      let abtOpenAbt = SFSTipShow?.param?.[`${shipping_country_id}_sfs`] == 'on'

      return abtOpenAbt && ![0, 12, 13].includes(+orderStatus) && business_model == 1 && stock_mode == 2 &&  sales_area == 1
    },
    returnCouponNum () {
      const { couponNum } = this.fullPriceCouponPromotion || {}
      return couponNum || 0
    },
    applePayCodeListApollo() {
      return this.apolloInfo?.APPLE_PAY_CODE_LIST || [] 
    },
    applepayDropoffOptimizationAbt () {
      return this.orderResultAbtInfo?.showApplepayDropoffOptimization
    },
    applepayDropoffOptimizationApollo () {
      return this.applePayCodeListApollo?.includes(this.orderInfo?.payment_method)
    },
    codSplitPackageLen () {
      const packageList = this.orderInfo?.order_package_info_list || []
      const filteredPackageList = packageList.filter(item => item?.wrap_status != 1)

      return filteredPackageList.length || 0
    },
    isCanEditShoppingAddress() {
      return ((this.editAddressFlag(this.orderInfo) == 1 && this.orderType != 'oldorder' && this.showPartOperatorBtn) || [2, 3].includes(this.deliveryEditAddressCond)) || this.orderResultAbtInfo?.showEditOrderAddress 
    }, 
    isUnPaidOrder () {
      return [0, 12, 13]?.includes(+this.orderInfo?.orderStatus)
    },
    showShippingMethodTabs() {
      return this.isUnPaidOrder && this.orderInfo.address_list?.length > 1
    },
    // * 未支付多地址场景
    isUnPaiMoreAddressOrder(){
      return this.isUnPaidOrder && this.orderInfo?.address_list?.length > 1
    },
    // * 未支付多地址场景使用新order数据
    newOrderInfo(){
      return this.isUnPaiMoreAddressOrder && this.addressBillno ? { ...this.orderInfo, billno: this.addressBillno } : this.orderInfo
    },
    containerClassObj () {
      let { isCanConfirmDelivery, isCanBeHiddenByUser } = this.orderInfo || {}
      let baseOrderDetail = this.isUnPaidOrder || isCanConfirmDelivery || isCanBeHiddenByUser || this.orderType != 'oldorder'
      let caclOrderDetail = this.isCanRevokeByUser(this.orderInfo) || this.isReturnableInfo(this.orderInfo) || this.isCommonOrderCanPartRefund(this.orderInfo)
      return {
        'new-order-detail': true,
        'order-detail-top-contain': baseOrderDetail || caclOrderDetail
      }
    },
    detailFooterDom () {
      return this.$refs?.detailFooter
    },
    detailFooterDomConfig () {
      return this.orderResultAbtInfo?.abTestConfig || {}
    },
    getPackageReferenceNumber () {
      let { orderGoodsList = [] } = this.orderInfo || {}
      return orderGoodsList?.flat(Infinity)?.[0]?.reference_number || ''
    },
    showEditOrderAddress () {
      return this.orderResultAbtInfo?.showEditOrderAddress
    },
    timePercentAbt () {
      return !!this.orderResultAbtInfo?.timePercentAbt
    },
    // 二次支付氛围提升探索abt
    payNowAbtInfo() {
      return this.orderResultAbtInfo?.payNowAbtInfo || {}
    },
    combinedFlag () {
      return this.orderInfo?.combinedFlag || []
    },
    isShowQuickShipTag () {
      return this.orderResultAbtInfo?.isShowQuickShipTag || ''
    },
    isLogisticstimeShow () {
      return !!this.orderResultAbtInfo?.isLogisticstimeShow
    },
    showOldReturnsHistory () { 
      return !!this.orderResultAbtInfo?.showOldReturnsHistory
    },
    isInfoFrontAbt () {
      return !!this.orderResultAbtInfo?.isInfoFrontAbt
    },
    isCanRefundAbt () {
      return this.orderResultAbtInfo?.isCanRefundAbt
    },
    quickShipAbt() {
      return this.orderResultAbtInfo?.quickShipAbt || {}
    },
    isShippedAddressEnable () {
      return !!this.orderResultAbtInfo?.isShippedAddressEnable
    },
    isShowCardBinDiscountABT () {
      return !!this.orderResultAbtInfo?.isShowCardBinDiscountABT
    },
    customerItemSyncToUserAddressAbt () {
      return this.orderResultAbtInfo?.customerItemSyncToUserAddressAbt || {}
    },
    showUseNewPackage () {
      return !!this.orderResultAbtInfo?.useNewPackage
    },
    showDeleteHistory () {
      return !!this.orderResultAbtInfo?.showDeleteHistory
    },
    // 双时效开关
    showDoubleShippingTime() {
      return !!this.orderResultAbtInfo?.showDoubleShippingTime
    },
    showReturnRefundRecord () {
      return !!this.orderInfo?.showReturnRefundRecord
    },
    showReturnRefundBtn () {
      return this.showReturnRefundRecord && this.showDeleteHistory
    },
    batchModifyAddressAbt () {
      return !!this.orderResultAbtInfo?.batchModifyAddressAbt
    },
    showCodOrderCancelTips () {
      let { codOrderOverdueRevokedType: type = '', skuList = '' } = this.codOrderOverDueInfo || {}
      let typeTextMap = {
        1: this.template(skuList, this.language.SHEIN_KEY_PWA_18604),
        2: this.template(skuList, this.language.SHEIN_KEY_PWA_18605),
        3: this.template(skuList, this.language.SHEIN_KEY_PWA_15361)
      }
      return typeTextMap[type] || ''
    },
    showfaqContent () {
      return !!this.faqInfo?.faqTags?.length
    },
    deepOrderGoodsList () {
      return this.orderInfo?.orderGoodsList?.[0]?.[0]?.[0]
    },
    showTabItem() {
      return (
        !this.pageLoading &&
        this.orderInfo?._showOrderDetailTab == 1 &&
        Object.keys(this.orderInfo?._allOrderGoodsList).length > 1
      )
    },
    detailDateLang(){
      let language = ''
      let orderStatus = +this.orderInfo.orderStatus
      if( [0, 12].includes(orderStatus) ) {
        language = this.language.SHEIN_KEY_PWA_20080
      } else if( [13].includes(orderStatus) ){
        language = this.language.SHEIN_KEY_PWA_20081
      }
      return language
    },
    isNewCountDownStatus () {
      let { newCountDownAbt } = this.orderResultAbtInfo || {}
      return this.isUnPaidOrder && newCountDownAbt
    },
    isWaitingforPayment () {
      return [0, 12, 13].indexOf(this.orderInfo?.orderStatus) >= 0
    },
    splitParcelTip () {
      if ([10, 18].indexOf(this.orderInfo?.orderStatus) == -1) {
        return false
      }
      const splitParcel = {}
      this.orderInfo?.oldOrderGoodsList?.forEach(item => {
        if (item.reference_number) {
          splitParcel[item.reference_number] = item.reference_number
        }
      })
      return Object.keys(splitParcel).length > 1 ? true : false
    },
    transport_time () {
      return (this.orderInfo?.transport_time || '0-0').split('-')
    },
    exchange_transport_time() {
      return (this.orderInfo?.exchange_shipping_time?.transport_time || '0-0').split('-')
    },
    // 判断是否满足面单前置条件
    overFrontCondition() {
      if (!this.isInfoFrontAbt || !orderLogic.isWillShipOrder(this.orderInfo)) return 0
      return orderLogic.existFrontFlag(this.orderInfo)
    },
    // 判断是否满足待发货订单支持修改地址
    overB2B2CEditAddress() {
      return orderLogic.orderDetailEditAddressCond(this.orderInfo, this.isCanRefundAbt)
    },
    // 是否是单mall
    isSingleMall() {
      return this.orderInfo?.is_multi_mall != 1
    },
    // 是否所有的mall都有mallName
    allMallHasName() {
      let { mall_list = [] } = this.orderInfo || {}
      let existMallList = mall_list?.filter(mall => !!mall.mall_name)
      return existMallList?.length == mall_list?.length
    },
    // 是否所有的store都有storeName
    allStoreHasName() {
      let { _allOrderGoodsList = [] } = this.orderInfo || {}
      let orderGoodsList = _allOrderGoodsList?.flat(Infinity)
      let noPrimeGoodsList = orderGoodsList?.filter(goodsObj => goodsObj.store_code != '0')
      let existStoreList = noPrimeGoodsList?.filter(goodsObj => !!goodsObj.store_name)
      return existStoreList?.length == noPrimeGoodsList?.length
    },
    // 获取商品展示的数据结构
    mallOrStoreGoodsFlag() {
      let { billno = '', sub_billnos = [], orderStatus } = this.orderInfo
      let showUnpaidCondition = sub_billnos.length > 1 && [0, 12, 13].includes(+orderStatus)
      let showPaidCondition = sub_billnos.length > 1 && ![0, 12, 13].includes(+orderStatus)
      // 1 商品聚合 2 店铺+商品 3 mall+商品 4 mall+店铺+商品
      if (isRelationBillno(billno)) {
        // 关联单号查询
        if (this.isUnPaidOrder) {
          // 未支付
          if (this.isSingleMall) {
            // 单mall
            return this.allStoreHasName ? 2 : 1
          } else {
            // 多mall
            if (this.allMallHasName) {
              return this.allStoreHasName ? 4 : 3
            } else {
              return this.allStoreHasName ? 2 : 1
            }
          }
        } else {
          // 已支付
          if (this.isSingleMall) {
            // 单mall
            if (sub_billnos?.length == 1 || showUnpaidCondition || showPaidCondition) {
              if (showPaidCondition) this.assignState({ showPartOperatorBtn: false })
              return this.allStoreHasName ? 2 : 1
            }
          } else {
            // 多mall
            if (sub_billnos?.length == 1 || showUnpaidCondition || showPaidCondition) {
              if (showPaidCondition) this.assignState({ showPartOperatorBtn: false })
              if (this.allMallHasName) {
                return this.allStoreHasName ? 4 : 3
              } else {
                return this.allStoreHasName ? 2 : 1
              }
            }
          }
        }
      } else {
        // 非关联单号查询
        if (this.isSingleMall) {
          // 单mall
          return this.allStoreHasName ? 2 : 1
        } else {
          // 多mall
          if (this.allMallHasName) {
            return this.allStoreHasName ? 4 : 3
          } else {
            return this.allStoreHasName ? 2 : 1
          }
        }
      }
      return 1
    },
    showOrderTransportTime() {
      let { mall_list = [], _allOrderGoodsList = [] } = this.orderInfo || {}
      let type = this.mallOrStoreGoodsFlag
      let orderGoodsList = _allOrderGoodsList?.flat(Infinity) || []
      let storeCodeList = [...new Set(orderGoodsList?.map(goods => goods.store_code))]
      let mallCodeList = [...new Set(mall_list?.map(mall => mall.mall_code))]
      if (!this.isUnPaidOrder) return true
      if (type == 1 || type == 2) return true
      if (type == 3 && mallCodeList?.length == 1) return true
      if (type == 4 && mallCodeList?.length == 1 && storeCodeList?.length == 1) return true
      return false
    },
    rootGoodsList() {
      return this.orderInfo?.oldOrderGoodsList || []
    },
    showTransformType() {
      let { _allOrderGoodsList = [] } = this.orderInfo || {}
      let orderGoodsList = _allOrderGoodsList?.flat(Infinity) || []
      let tempOrderGoodsList = orderGoodsList?.filter(goodsObj => goodsObj.store_code != '0')
      let storeList = [...new Set(tempOrderGoodsList.map(store => store.store_code))]
      let nullStoreList = storeList.filter(storeCode => !storeCode)
      return !(storeList?.length <= 1 || nullStoreList?.length)
    },
    showTransportTimeInfo() {
      let type = this.mallOrStoreGoodsFlag
      let condition =
        !this.isUnPaidOrder ||
        (this.isUnPaidOrder && ([1, 3, 4].includes(type) || (type == 2 && !this.showTransformType)))
      return condition
    },
    showQuickShipTransportTime() {
      let { _allOrderGoodsList, is_multi_mall } = this.orderInfo || {}
      if (is_multi_mall == 1) return 0
      return orderLogic?.getQuickShipTransportTimeType(_allOrderGoodsList)
    },
    getQuickShipTransportTime() {
      let { mall_list = [] } = this.orderInfo || {}
      mall_list = mall_list?.filter(mallOrStore => mallOrStore.store_code != '0')
      const item = mall_list?.[0] || {}
      let quickShipDesc = item?.quick_ship_desc || ''

      // 订单详情“时效类型”=2，格式化时间戳
      if (
        item?.quick_ship_time &&
        item?.origin_desc_quick_ship_time
      ) {
        const timeStr = formatTime(item.quick_ship_time, this.locals)
        quickShipDesc = item.origin_desc_quick_ship_time.replace('%s', timeStr)
      }
      
      if (this.showQuickShipTransportTime == 2 && this.supplyTransportTimeText) {
        quickShipDesc = `${quickShipDesc} ${this.supplyTransportTimeText}`
      }

      return formatQuickShipDesc({
        quickShipDesc: quickShipDesc, 
        language: this.language, 
        quickShipAbt: this.quickShipAbt, 
        mallInfo: item,
        localData: this.locals
      })
    },
    // *海路运时效类型文案
    seaOrLandTypeText() {
      return orderLogic?.seaOrLandTypeText(this.orderInfo, this.language)
    },
    // *海陆运时效文案
    largeSeaAndLandText() {
      let timeListItem =  this.orderInfo.mall_list?.[0]?.shipped_transport_time_list?.find(item => item?.type == 2)
      if(!timeListItem) {
        timeListItem = this.orderInfo.mall_list?.[0]?.transport_time_list?.find(item => item?.type == 2) || {}
      }
      if (timeListItem.transport_time_type == 2) {
        // 如果配置的是时间范围
        const formatDate = formatTime(timeListItem.transport_time, this.locals)
        return `${this.seaOrLandTypeText}: ${this.template(formatDate, this.language.SHEIN_KEY_PWA_26651)}`
        
      } 
      if(timeListItem.transport_time_type == 1){
        if (timeListItem.transport_date_type == 0) {
          return `${this.seaOrLandTypeText}: ${this.template(timeListItem.transport_time, this.language.SHEIN_KEY_PWA_28881)}`
           
        } 
        if(timeListItem.transport_date_type == 1) {
          return `${this.seaOrLandTypeText}: ${this.template(timeListItem.transport_time, this.language.SHEIN_KEY_PWA_24764)}`
        }
      }
      return ''
    },
    // *海路运时效百分比对象
    getOrderDayLargePercentInfo(){
      let { mall_list = [] } = this.orderInfo || {}
      mall_list = mall_list?.filter(mallOrStore => mallOrStore.store_code != '0')
      let timeListItem = mall_list?.[0]?.shipped_transport_time_list?.find(item => item?.type == 2)
      if(!timeListItem) {
        timeListItem = mall_list?.[0]?.transport_time_list?.find(item => item?.type == 2) || {}
      }
      const {
        delivery_day_percent_desc = '',
        time_detail_list = []
      } = timeListItem
      let day_percent_desc = ''
      if (delivery_day_percent_desc) {
        day_percent_desc = delivery_day_percent_desc
      } 

      return { day_percent_desc, time_detail_list }

    },
    // * 是否为多mall
    isMultiMall() {
      let { is_multi_mall } = this.orderInfo || {}
      if(is_multi_mall == 1){
        return true
      }
      return false
    },
    // *判断展示什么时效
    timeEffectsList(){
      let list = []
      const handle = this.orderInfo?.mall_list?.[0]?.shipped_transport_time_list
      if(handle?.length > 0) {
        // 根据时效类型map
        const typeMap = {
          0: 1,
          1: 2,
          2: 3,
          3: 2
        }
        handle.forEach(i=> {
          if(i.type == 3 && !((i?.transport_time_type == 1 && i?.origin_shipping_desc) || (i?.transport_time_type == 2 && i?.origin_delivery_desc))) return
          list.push(typeMap[i.type])
        })
        if(list.length > 0) {
          return list
        }
      }
      const goodList = orderLogic?.getAllOrderGoodsList(this.orderInfo || {})
   
      goodList.forEach(item => {
        if((item?.quick_ship == 1 || item?.quick_ship == 2)  && (item?.large_sea_and_land == 1 || item?.large_sea_and_land == 2)){
          list.push(2)
        }else if(item?.quick_ship == 0 && item?.large_sea_and_land == 0){
          list.push(1)
        }else if(item?.quick_ship == 1){
          if(this.isMultiMall){
            list.push(1)
          }else {
            list.push(2)
          } 
        } else if(item?.large_sea_and_land == 1 || item?.large_sea_and_land == 2){
          list.push(3)
        } else if(item?.quick_ship == 2){
          if(this.isMultiMall){
            list.push(1)
          }else {
            list.push(4)
          }     
        }
      })
      return uniq(list) 
    },

    // *是否展示海路运时效百分比
    showOrderDayLargePercentDes() {
      return this.timePercentAbt && this.getOrderDayLargePercentInfo?.day_percent_desc
    },
    // *海路运时效百分比文案
    transportLargeSeaAndLandText() {
      const { mall_list = [] } = this.orderInfo
      let mallItem = getNewTransportField(mall_list?.[0] || {}, 2)
  
      const { transport_time = '', origin_delivery_desc = '', origin_shipping_desc = '', transport_time_type, transport_date_type, sub_transport_time, title = '' } = mallItem
      let titleText = title ? title : this.seaOrLandTypeText
      let transportTime = transport_time || ''
      if (!transportTime) return ''
 
      const originDeliveryDesc = origin_delivery_desc || ''
      const originShippingDesc = origin_shipping_desc || ''


      const subTransportTime = sub_transport_time || ''  
      let text = ''
      //满足双时效展示条件
      if (
        this.showDoubleShippingTime &&
        !this.isUnPaidOrder &&
        originDeliveryDesc &&
        originShippingDesc &&
        subTransportTime
      ) {
        text = formatDoubleTime({
          originDeliveryDesc,
          originShippingDesc,
          subTransportTime,
          transportTime,
          transportTimeType: transport_time_type,
          useEnum: true,
          localData: this.locals
        })
      } else if (transport_time_type == 2) {
        const formatDate = formatTime(transportTime, this.locals)
        if (this.isUnPaidOrder || !originDeliveryDesc) {
          if(this.isUnPaidOrder){
            text = `${this.seaOrLandTypeText}: ${this.template(formatDate, this.language.SHEIN_KEY_PWA_26651)}`
          }else {
            text = `${titleText}: ${this.template(formatDate, this.language.SHEIN_KEY_PWA_26651)}`
          }
          
        } else {
          text = originDeliveryDesc?.replace('%s', formatDate)
        }
      } else if (transport_time_type == 1) {
        const isShow = !originShippingDesc  || this.isUnPaidOrder
        if(transport_date_type == 0) {
          if(isShow){
            if(this.isUnPaidOrder){
              text = `${this.seaOrLandTypeText}: ${this.template(transportTime, this.language.SHEIN_KEY_PWA_28881)}`
            }else{
              text = `${titleText}: ${this.template(transportTime, this.language.SHEIN_KEY_PWA_28881)}`
            }
            
          }else{
            text = originShippingDesc?.replace('%s', transportTime)
          }
        }
        if(transport_date_type == 1){
          if(isShow){
            if(this.isUnPaidOrder){
              text = `${this.seaOrLandTypeText}: ${this.template(transportTime, this.language.SHEIN_KEY_PWA_24764)}`
            }else {
              text = `${titleText}: ${this.template(transportTime, this.language.SHEIN_KEY_PWA_24764)}`
            }
              
          }else{
            text = originShippingDesc?.replace('%s', transportTime)
          }
        }
      }    
      return text
    },
    getOrderDayPercentInfo() {
      let { mall_list = [] } = this.orderInfo || {}
      mall_list = mall_list?.filter(mallOrStore => mallOrStore.store_code != '0')
      const {
        delivery_day_percent_desc = '',
        transport_time_detail_list = []
      } = mall_list?.[0] || {}
      const newPercent = getNewTransportField(mall_list?.[0] || {}, 0)
      const {
        delivery_day_percent_desc: newDesc = '',
        time_detail_list: newDetail = []
      } = newPercent
      let day_percent_desc = ''
      if (delivery_day_percent_desc) {
        day_percent_desc = newDesc ? newDesc : delivery_day_percent_desc
      } 
      return { day_percent_desc, transport_time_detail_list: newDetail ? newDetail : transport_time_detail_list }
    },
    showOrderDayPercentDes() {
      return this.timePercentAbt && this.getOrderDayPercentInfo?.day_percent_desc
    },
    getExchangeDayPercentInfo() {
      let { mall_list = [] } = this.orderInfo || {}
      mall_list = mall_list?.filter(mallOrStore => mallOrStore.store_code != '0')
      const {
        delivery_day_percent_desc = '',
        day_percents = []
      } = mall_list?.[0]?.exchange_shipping_time || {}
      let day_percent_desc = ''
      if (delivery_day_percent_desc) {
        day_percent_desc = delivery_day_percent_desc
      } 

      return { day_percent_desc, transport_time_detail_list: day_percents }
    },
    showExchangePercentDes() {
      return this.timePercentAbt && this.getExchangeDayPercentInfo?.day_percent_desc
    },
    showMyService() {
      const { insurance_type, orderExtend = {} } = this.orderInfo
      const { insurancePrice = {} } = orderExtend || {}
      if (insurance_type == 3 && !this.isWaitingforPayment && insurancePrice?.amount > 0) {
        return true
      }
      return false
    },
    showSellerInfo() {
      let { orderStatus } = this.orderInfo
      let { showSellerInfo } = this.orderResultAbtInfo || {}
      return ![9, 11, 8, 3, 0, 12, 13, 16].includes(+orderStatus) && showSellerInfo
    },
    supplyTransportTimeText() {
      // 大件运输时效文案
      const { mall_list = [] } = this.orderInfo
      return mall_list?.find(item => item?.supple_desc != null)?.supple_desc || ''
    },
    countDownTimeText() {
      const mallItem = this.orderInfo?.mall_list?.[0] || {}
      let handle =  mallItem?.shipped_transport_time_list
      if(!handle?.length) {
        handle =  mallItem?.transport_time_list || []
      }
      let info = handle.find(item => item.type == 0) || this.orderInfo
      if (info.transport_time_type == 1) {
        // 如果配置的是时间范围
        if (/^\d{10,13}-\d{10,13}$/.test(info.transport_time)) {
          const formatDate = formatTime(info.transport_time, this.locals)
          return this.template(formatDate, this.language.SHEIN_KEY_PWA_26651)
        } else {
          // 配置的单时间戳
          return this.template(formatTime(info.transport_time, this.locals), this.detailDateLang)
        }
      } else if (mallItem.date_type == 0) {
        return this.template(info.transport_time, this.language.SHEIN_KEY_PWA_29049)
      } else {
        return this.template(info.transport_time, this.language.SHEIN_KEY_PWA_17357)
      }
    },
    transportTimeText() {
      const { mall_list = [], transport_time_type: oldDesc_type, transport_time = '' } = this.orderInfo

      const handle = getNewTransportField(mall_list?.[0] || {}, 0)
      
      const { 
        transport_time: newTransport_time,
        origin_delivery_desc: newOrigin_delivery_desc,
        origin_shipping_desc: newOrigin_shipping_desc,
        sub_transport_time: newSub_transport_time,
        transport_time_type: newDesc_type,
        supple_desc: newSupple_desc
      } = handle
      const desc_type = newDesc_type ? newDesc_type : oldDesc_type 

      let transportTime = newTransport_time ? newTransport_time : transport_time || ''
      if (!transportTime) return ''
      const mallItem = mall_list?.find(item => item?.sub_transport_time != null) || {}
      const originDeliveryDesc =
      newOrigin_delivery_desc ? newOrigin_delivery_desc : mall_list?.find(item => item?.origin_delivery_desc != null)?.origin_delivery_desc || ''
      const originShippingDesc =
      newOrigin_shipping_desc ? newOrigin_shipping_desc : mall_list?.find(item => item?.origin_shipping_desc != null)?.origin_shipping_desc || ''
      const subTransportTime = newSub_transport_time ? newSub_transport_time : mallItem?.sub_transport_time || '' 
      
      let text = ''
      //满足双时效展示条件
      if (
        this.showDoubleShippingTime &&
        !this.isUnPaidOrder &&
        originDeliveryDesc &&
        originShippingDesc &&
        subTransportTime
      ) {
        text = formatDoubleTime({
          originDeliveryDesc,
          originShippingDesc,
          subTransportTime,
          transportTime,
          transportTimeType: desc_type,
          localData: this.locals
        })
      } else if (desc_type == 1) {
        const formatDate = formatTime(transportTime, this.locals)
        if (this.isUnPaidOrder) {
          text = this.template(formatDate, this.language.SHEIN_KEY_PWA_26651)
        } else {
          const originDesc = `${this.language.SHEIN_KEY_PWA_15627} ${formatDate}`
          text = originDeliveryDesc ? originDeliveryDesc?.replace('%s', formatDate) : originDesc
        }
      } else if (desc_type == 0) {
        const isShow = (!originShippingDesc && !originDeliveryDesc) || this.isUnPaidOrder
        if (isShow && mallItem.date_type == 0) {
          text = this.template(transportTime, this.language.SHEIN_KEY_PWA_28881)
        } else if (this.isUnPaidOrder && mallItem.date_type == 1) {
          text = this.template(transportTime, this.language.SHEIN_KEY_PWA_24764)
        } else if (originShippingDesc) {
          text = originShippingDesc?.replace('%s', transportTime)
        } else {
          text = this.template(transportTime, this.language.SHEIN_KEY_PWA_24764)
        }
      }
      const handleSupplyText = newSupple_desc ? newSupple_desc : this.supplyTransportTimeText
      if (handleSupplyText) {
        text = `${text} ${handleSupplyText}`
      }

      return text
    },
    exchangeTimeText() {
      const { exchange_shipping_time } = this.orderInfo
      if (!exchange_shipping_time || !exchange_shipping_time?.transport_time) return ''
      const originDeliveryDesc = exchange_shipping_time.origin_delivery_desc || ''
      const originShippingDesc = exchange_shipping_time.origin_shipping_desc || ''
      const subTransportTime = exchange_shipping_time.sub_transport_time || ''  
      //满足双时效展示条件
      if (
        this.showDoubleShippingTime &&
        !this.isUnPaidOrder &&
        originDeliveryDesc &&
        originShippingDesc &&
        subTransportTime
      ) {
        return formatDoubleTime({
          originDeliveryDesc,
          originShippingDesc,
          subTransportTime,
          transportTime: exchange_shipping_time.transport_time,
          transportTimeType: exchange_shipping_time.desc_type,
          localData: this.locals
        })
      } else if (exchange_shipping_time.desc_type == 1) {
        return `${this.language.SHEIN_KEY_PWA_15627} ${formatTime(exchange_shipping_time.transport_time, this.locals)}`
      } else {
        const isShow = (!originShippingDesc && !originDeliveryDesc) || this.isUnPaidOrder
        if (isShow && exchange_shipping_time.date_type == 0) {
          return this.template(
            exchange_shipping_time.transport_time,
            this.language.SHEIN_KEY_PWA_28881
          )
        } else {
          return this.template(
            exchange_shipping_time.transport_time,
            this.language.SHEIN_KEY_PWA_17357
          )
        }
      }
    },
    subscribeAreaCode () {
      return this.item_cates?.filter(item => item.id == this.orderInfo?.shipping_country_id)?.[0]?.phone_code || ''
    },
    // 卡bin随机立减展示
    isShowBinRandomDiscountABT () {
      return !!this.orderResultAbtInfo?.showBinRandomDiscount
    },
    merchantOrderCanEdit() {
      let { subOrderStatus = [], business_model, order_package_info_list = [] } = this.orderInfo || {}
      let processOrStockPackage = subOrderStatus?.filter(item => ['processing', 'out_of_stock'].includes(item?.packageState))

      if (business_model == 1 && order_package_info_list?.length) {
        return orderLogic.checkShipEdit({
          order: this.orderInfo,
          isInfoFrontAbt: this.isInfoFrontAbt,
          isCanRefundAbt: this.isCanRefundAbt,
          processOrStockPackage
        })
      }

      return true
    },
    orderStatusText() {
      if (!this.isCanRefundAbt.includes('BBC=on')) return ''
      let { orderGoodsList = {}, orderTrackInfo = {} } = this.orderInfo || {}
      let packStatus = ''
      const packageNo = orderTrackInfo?.package_no || ''
      if (this.isProcessing) {
        packStatus = orderGoodsList?.flat(Infinity)?.find(item => item?.reference_number == packageNo)?.pack_detail_status
      } else {
        packStatus = orderGoodsList?.flat(Infinity)?.find(item => item?.pack_detail_status == '0' || !!item?.pack_detail_status)?.pack_detail_status
      }
      let cccText = ''

      let matchObj = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
        ...this.orderInfo,
        order_or_package_status: packStatus
      })
      cccText = matchObj?.statusText

      return cccText || ''
    },
    currentPackageNo() {
      const { orderGoodsList = [] } = this.orderInfo

      return orderGoodsList.flat(Infinity)?.[0]?.reference_number || ''
    },
    returnTime() {
      return orderLogic.getReturnTimeInfo({
        language: this.language,
        orderInfo: this.orderInfo,
        localData: this.locals
      })
    },
    orderReturnTime() {
      return this.returnTime?.orderReturnTime || {}
    },
    packageReturnTime() {
      return this.returnTime.packageReturnTime?.find(item => item.packageNo == this.currentPackageNo)
    },
    isProcessing() {
      const { processingTabIndex, _isShppedType, order_package_info_list = [], _allOrderGoodsList = [] } = this.orderInfo
      if (this.showTabItem) {
        return this.tabSelected == processingTabIndex
          || _allOrderGoodsList?.[this.tabSelected]?.some(item => item.orderGoodsStauts == 2)
      }
      return _isShppedType == 1
        || _isShppedType == 3
        || [11, 13, 23, 49].includes(order_package_info_list?.[0]?.status)
        || _allOrderGoodsList[0].some(item => item.orderGoodsStauts == 2)
    },
    isShowReturnTime() {
      const isShowReturn = orderLogic.isReturnableInfo(this.orderInfo)
      
      return isShowReturn
        && this.packageReturnTime?.timeDesc
        && this.orderResultAbtInfo.showReturnExpireTime
        && !this.isProcessing
    }
  },
  watch: {
    showShippingDayPercent(v) {
      if (v) this.$refs?.shippingDayPercent?.init()
    }
  },
  updated () {
    this.$nextTick(() => {
      $(window).scroll()

      let isAuto = location.search.indexOf('showpaymethods=1') >= 0
      if (isAuto) {
        appEventCenter.$emit('clear-and-pay-now', {
          isImmediatelyPay: true,
          isClearPaymentMethod: true,
          isOpenPaymentList: true
        })
        history.replaceState(null, null, location.href.replace(/\?showpaymethods=1$|&?showpaymethods=1&?/, ''))
      }
    })
  },
  created () {
    this.assignState({ ...this.newFetchPageParams() })
  },
  async mounted () {
    await Promise.resolve(initSsrDataPromise)
    this.$nextTick(() => {
      markPoint('setPageDataNextTick', 'setPageData')
    })
    this.initPageTypeData()
    this.initOtherPageEvent()
    if (this.isUnPaiMoreAddressOrder) {
      this.tabChange({ val: this.methodTabSelected })
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.calCountDownShow)
    this.resetSsrState()
  },
  methods: {
    ...mapMutations('orderDetail', [
      'assignState',
      'showMsg',
      'changePageStatus',
      'handleEditPayment',
      'handleChangePayment',
      'resetSsrState',
      'setUnPaidOrderPaymentStatus',
      'resetUnPaidOrderPaymentStatus'
    ]),
    ...mapMutations('root', [
      'changeRootStatus',
      'showMaskTips',
      'updateIsErrorGuideOn',
      'updateHasGuide',
      'changeUserRootSLoadingStatus'
    ]),
    ...mapMutations('cancelItemModule', [
      'assignCancelItemState'
    ]),
    ...mapActions(['getCsChatMsgNumFirst']),
    template,
    getQueryString,
    htmlDecode: text => htmlDecode({ text }),
    isReturnableInfo: orderLogic.isReturnableInfo,
    isCanRevokeByUser: orderLogic.isCanRevokeByUser,
    isCommonOrderCanPartRefund: orderLogic.isCommonOrderCanPartRefund,
    // 刷新订单详情信息
    async refreshOrderInfo() {
      const res = await schttp({
        method: 'GET',
        url: `/user/orders/detail/${this.orderInfo?.billno}`,
      })
      if (res && res.code == 0) {
        const { order = {} } = res.info || {}
        this.assignState({
          orderInfo: {
            ...this.orderInfo,
            ...order,
          }
        })
      }
    },
    async initSellerRobot(){
      const orderGoods = this.orderInfo?.orderGoodsList?.flat(Infinity) || []
      const handleStoreCode = orderGoods.reduce((prevArr, curr)=>{
        const storeCode = curr?.store_code
        if(storeCode && !prevArr.includes(storeCode)) prevArr.push(storeCode)
        return prevArr
      }, [])
      if(handleStoreCode?.length != 1) return
      this.sellerStoreCode = handleStoreCode[0]
      const {
        isShowSellerServer = false,
        isShowSellerHint = false
      } = await getSellerServer({
        shunt_store_code: this.sellerStoreCode,
        page: 'orderDetailPage'
      })

      this.isShowSellerRobot = isShowSellerServer
      this.isShowSellerHint = isShowSellerHint
    },
    intoRobot(){
      if(this.robotShow) {
        this.reportServiceClick(0)
        this.toRobot('orderDetailPage')
      } 
      if(this.isShowSellerRobot) {
        this.toSellerRobot()
      }
    },
    reportServiceClick(scene){
      daEventCenter.triggerNotice({
        daId: '1-19-1-163',
        extraData: {
          is_sheinbot: scene
        }
      })
    },
    
    toSellerRobot() { 
      /**
       * 订单接口business_model。    给客服传
        0-自营                         0
        1-商家                         1
        2-二手                       本次不涉及
        4-1P                           0
       */
      daEventCenter.triggerNotice({
        daId: '1-19-1-162',
        extraData: {
          store_code: this.sellerStoreCode
        }
      })
      const businessMap = {
        '0': 0,
        '4': 0,
        '1': 1
      }
      const business = businessMap[this.orderInfo?.business_model] !== undefined ? businessMap[this.orderInfo?.business_model] : ''
      jumpToSellerRobot({
        storecode: this.sellerStoreCode,
        page: 'orderDetailPage',
        page_name: 'page_order_detail',
        business,
        billno: this.orderInfo?.billno
      })
    },
    // 编辑订单地址后展示订单标记问询
    addresShowResumeShipment(res = {}) {
      const orderInfoList = res.info?.order_info_list || []
      const orderInfo = orderInfoList.find(i => i.billno === this.orderInfo.billno)
      const orderMarkList = orderInfo?.order_mark_list || []
      return (
        this.markOrderParams.orderaddress_show == 'on' && 
        orderMarkList.some(i => i.mark_type == 1 && i.mark_type_status == 1 && i.trouble_reason == '1')
      )
    },
    // 订单详情用户点击自主标记按钮
    async resumeShipment() {
      const res = await this.resumeShipmentSleep({ scene: 'order_detail' })
      if (res) {
        this.refreshOrderInfo()
      }
    },
    // 等待用户选择自主标记的结果
    resumeShipmentSleep(info = {}) {
      return new Promise(resolve => {
        this.resumeShipmentInfo = info
        this.showResumeShipmentDialog = true
        this.asyncCallbacks = [resolve]
      })
    },
    handleShowSuccessPanel() {
      this.status.showSuccessPanel = true
    },
    tabChange(data){
      this.methodTabSelected = data?.val || 0
      const info = this.orderInfo?.address_list?.[this.methodTabSelected] || {}
      const billnoList = info?.billno_list || []
      const addressInfo = convertAddress({ from: info, type: 'orderToSubsidiary' }) || {}
      this.addressBillno = billnoList[0] || this.orderInfo.billno,
      this.assignState({
        orderInfo: {
          ...this.orderInfo,
          ...info,
          shipping_address_1: info.shipping_address1 || '',
          shipping_address_2: info.shipping_address2 || '',
          tax_number: info.shipping_tax_number || '',
          nationalId: info.shipping_national_id || '',
          subsidiary: {
            ...(this.orderInfo?.subsidiary || {}),
            ...addressInfo
          }
        }
      })
    },
    // * 是否展示海路运标签
    showSeaOrLandTypeText(data){
      const list = data.flat(Infinity)
      if(list.some(item =>  item?.large_sea_and_land == 1 )){
        return this.language?.SHEIN_KEY_PWA_32176 || ''
      }
      if(list.some(item =>  item?.large_sea_and_land == 2 )){
        return this.language?.SHEIN_KEY_PWA_32175 || ''
      }
      return ''
    },
    async updateOrderAuthentication() {
      if (!this.orderResultAbtInfo?.showBrTaxNumberNameCheck) return
      const res = await schttp({
        method: 'GET',
        url: `/user/orders/detail/${this.orderInfo.billno}`,
      })
      if (res && res.code == 0) {
        const { order = {} } = res.info || {}
        const { real_name_authentication } = order
        if (real_name_authentication != this.orderInfo.real_name_authentication) {
          this.assignState({
            orderInfo: {
              ...this.orderInfo,
              real_name_authentication,
            }
          })
        }
      }
    },
    initOtherPageEvent () {
      this.initSellerRobot()
      this.getCsChatMsgNumFirst()
      this.mittEvent()
    },
    newFetchPageParams () {
      let { billno = '' } = this.$route?.params || {}
      let { orderType = '', from_type = '', fromRecycle = '', type = '', fail = '' } = this.$route?.query || {}

      return {
        billno,
        orderType,
        fromRecycle,
        fromType: from_type,
        enterType: type,
        enterFailType: fail
      }
    },
    initPageTypeData () {
      this.initPageInfo()
    },
    async initPageInfo () {
      console.log('ssr_output', this.isSSR)
      // 订单异常拦截重定向
      let { path = '', message = '', errorCode = '' } = this.errorRenderEvt(this.orderInfo, this.language)
      if (path) {
        message && this.$toast(message, 3000)
        setTimeout(() => {
          this.$router.push({ path, query: errorCode ? { errorCode } : {} })
        }, 3000)
        return
      }
      this.pageViewEvt(this.orderInfo)

      this.changeRootStatus({
        loading: true
      })
      await Promise.all([
        this.initExtraRequestEvt(),
        this.getOrderStatusTextEvt()
      ])
      this.changeRootStatus({
        loading: false
      })
      this.orderEventRequest()
    },
    async initExtraRequestEvt () {
      console.log('this.orderInfo >>>', this.orderInfo)
      let extraRequestList = [
        this.updateDeliveryEditAddressCond(this.orderInfo).catch(() => {}),
        this.fetchBffOrderInfo({
          billno: this.orderInfo?.billno || '',
          is_virtual_order: false,
          tokenId: this.orderInfo?.token_id || '',
        })
      ]
      
      await Promise.all(extraRequestList)
    },
    beforeEnterShoppingAddress(){
      if(!this.isCanEditShoppingAddress) return

      if (this.orderResultAbtInfo?.showEditOrderAddress) {
        this.$refs.detailUserInfo?.handleEditAddressEvt?.()
      } else {
        this.toClickEditAddress()
      }
    },
    // 机器人跳转过来自动点击取消订单按钮
    autoRequestCancelOrdeEvt () {
      let orderOperationSortedButtons = this.$refs?.detailFooter?.orderOperationSortedButtons || []
      if (orderOperationSortedButtons.includes('cancel_item')) {
        this.$refs?.detailFooter?.cancelItemShow()
        return
      }
      if (orderOperationSortedButtons.includes('revoke_item')) {
        this.$refs?.detailFooter?.revokeItemEvt()
        return
      }

      this.$toast(this.language.SHEIN_KEY_PWA_34475)
    },
    orderEventRequest () {
      window.gbOrderDetail = this
      let lazyLoadList = [
        () => this.handlerCalCountDownShow(),
        () => this.initSpecialPaymentEvt(),
        () => this.initRobotEntraceEvt(),
        () => this.initRefundDetailEvt(),
        () => this.initPaymentFailEvt(),
        () => this.selectedTabEvt(this.orderInfo),
        () => this.payNowDataEvt(),
        () => this.handleMethod(),
        () => this.$refs?.detailFooter?.toDeliveryMask()
      ]
      
      // query参数'type'对应的方法
      const actionMap = {
        'editAddress': () => this.beforeEnterShoppingAddress(),
        'auto_edit_address': () => this.beforeEnterShoppingAddress(),
        'urge_delivery': () => this.$refs?.detailFooter?.showUrgeDelivery(),
        'urge_ship': () => this.$refs?.detailFooter?.urgeShipment(),
        'view_invoice': () => this.$refs?.detailFooter?.navToViewInvoices(this.orderInfo.billno),
        'cancel_order': () => this.autoRequestCancelOrdeEvt(),
      }

      if (this.enterType in actionMap) {
        lazyLoadList.push(actionMap[this.enterType])
      }

      this.requestAnimationFrameEvt(lazyLoadList)
    },
    requestAnimationFrameEvt (lazyLoadList = []) {
      if (!lazyLoadList?.length) return

      let lazyLoad = () => {
        let module = lazyLoadList.shift()
        if (module) {
          this.$nextTick(module)
          window.requestAnimationFrame(lazyLoad)
        } else {
          return
        }
      }
      window.requestAnimationFrame(lazyLoad)
    },
    pageViewEvt (orderInfo = {}) {
      let { fromType = '', orderType = '' } = this.newFetchPageParams()
      let referrer = document.referrer
      let payFailure = referrer?.includes('result/fail') || referrer?.includes('shein.co') || referrer == ''
      let pageParam = payFailure ? { page_from: 'pay_failure' } : {}
      
      window.SaPageInfo = {
        ...SaPageInfo,
        page_param: {
          ...SaPageInfo.page_param,
          ...pageParam,
          order_id: orderInfo.billno || '',
          order_status: orderInfo.orderStatus || '',
          order_type: orderType || '',
          from_type: fromType ? Number(fromType) : '-',
          buy_tp: orderLogic.getBuyTpInfo(orderInfo)
        }
      }
      appEventCenter.$emit('pageOnload')
    },
    errorRenderEvt (orderInfo = {}, languageInfo = {}) {
      let { orderCode = '' } = orderInfo || {}
      let { langPath = '' } = this.locals || {}
      let errorCodeMap = {
        300103: {
          path: `${langPath}/user/orders/detailPaid`,
          orderCode: 300103
        },
        300601: {
          path: `${langPath}/user/orders/detailPaid`
        }
      }
      let orderTypeMap = {
        5: {
          path: `${langPath}/user/orders/virtualDetail/${orderInfo?.billno}`
        },
        6: {
          path: `${langPath}/user/orders/virtualDetail/${orderInfo?.billno}`
        }
      }
      let orderNotExistMap = {
        path: `${langPath}/user/orders/list`,
        message: languageInfo?.SHEIN_KEY_PWA_15409 || ''
      }
      // 如果订单不存在 或者 订单返货error 退出订单详情页
      let orderNotExistCond = !(orderCode == 0 && !!orderInfo)

      if (errorCodeMap[orderCode]) return errorCodeMap[orderCode]
      if (orderNotExistCond) return orderNotExistMap
      if (orderTypeMap[orderInfo?.order_type]) return orderTypeMap[orderInfo?.order_type]

      return {}
    },
    mittEvent () {
      this.cccShow = true
      
      window?.appEventCenter?.$on('hide-one-click-pay-status', () => {
        this.assignState({ orderInfo: { ...this.orderInfo, showOneClickPay: false } })
      })

      window?.appEventCenter?.$on?.('recomend-load-finished', () => {
        let recommend = this.getQueryString({ key: 'recommend' })
        let initScroll = recommend == 1 && this.orderInfo?.showOneClickPay
        if (initScroll) {
          setTimeout(() => {
            window?.appEventCenter?.$emit?.('one-click-recommend-scroll')
          }, 500)
        }
      })
    },
    initSpecialPaymentEvt() {
      this.$nextTick(() => {
        let showErrorGuidePayment = this.getQueryString({ key: 'show_error_guide_payment' })
        let isUnCodeOrder = this.orderInfo?.payment_method != 'cod'
        if (isUnCodeOrder && this.isUnPaidOrder && showErrorGuidePayment == 1) {
          appEventCenter.$emit('clear-and-pay-now', {
            isImmediatelyPay: true,
            isClearPaymentMethod: false,
            isOpenPaymentList: true
          })
          history.replaceState(null, null, location.href.replace(/\?show_error_guide_payment=1$|&?show_error_guide_payment=1&?/, ''))
        }
      })
    },
    initPaymentFailEvt () {
      if (!this.enterFailType) return

      let failText = ''
      if (this.enterFailType == 2) {
        failText = this.language.SHEIN_KEY_PWA_15461
      } else {
        failText = this.language.SHEIN_KEY_PWA_16079
      }

      this.showFailType = {
        show: true,
        text: failText
      }
    },
    initRobotEntraceEvt () {
      orderLogic.setToRobotPage({
        mall_list: this.orderInfo?.mall_list || [],
        pageName: 'orderDetailPage'
      })
      hasRobotEntrance('orderDetailPage', status => this.robotShow = status)
    },
    payNowDataEvt () {
      let { orderStatus, notSupportCodTipsCode, billno } = this.orderInfo || {}
      // eslint-disable-next-line @shein-aidc/abt/abt
      let abtest = abtservice.getUserAbtResultForAnalysis({ posKeys: this.orderResultAbtInfo?.orderDetailNewFunctionPosKey }).sa
      
      this.detailNewFnAbt = abtest	
      let daId = [0, 12].includes(+orderStatus) ? '1-19-1-68' : (orderStatus == 13 ? '1-19-1-70' : '')
      daId && daEventCenter?.triggerNotice({
        daId,
        extraData: {
          abtest
        }
      })

      if (!!notSupportCodTipsCode) {
        daEventCenter.triggerNotice({
          daId: '1-19-1-3',
          extraData: {
            order_id: billno,
            fail_reason: notSupportCodTipsCode
          }
        })
      }
    },
    initRefundDetailEvt () {
      if (!this.refundDetailInfo?.show) return

      this.refundDetail = {
        ...this.refundDetailInfo,
        text: {
          refund_path1: this.language?.SHEIN_KEY_PWA_14929,
          refund_path2: this.language?.SHEIN_KEY_PWA_14927,
          refund_path31: this.language?.SHEIN_KEY_PWA_14928,
          refund_path32: this.language?.SHEIN_KEY_PWA_14930,
          refund_path33: this.language?.SHEIN_KEY_PWA_14931,
          refund_path34: 'Понадобится 10-30 рабочих дней, чтобы денежные средсва вернулись к Вам на карту.',
          refund_path35: this.language?.SHEIN_KEY_PWA_14926,
          refund_path36: this.language?.SHEIN_KEY_PWA_14932,
          refund_path37: this.language?.SHEIN_KEY_PWA_14933
        }
      }
    },
    needConfirmDialog() {
      let { syncToUseraddressAbt = {} } = this.orderResultAbtInfo || {}
      return this.orderInfo?.address_id && syncToUseraddressAbt?.is_sync_switch === 'on'
    },
    confirmDialogHandle(){
      daEventCenter.triggerNotice({
        daId: '2-30-1',
        extraData: {
          name: 'expose_sync_to_useraddress_pop',
        }
      })
      this.showConfirmDialog = true
    },
    closePercentList() {
      this.assignState({
        showShippingDayPercent: false,
        dayPercentInfo: {}
      })
    },
    showDayPercentList({ method, percentList, percentDes }) {
      let list = transformPercentList(percentList)
      this.assignState({
        showShippingDayPercent: true,
        dayPercentInfo: { method, percentList: list, percentDes }
      })
      daEventCenter.triggerNotice({
        daId: '1-19-1-89',
        extraData: {
          bill_no: this.orderInfo.billno
        }
      })
    },
    handleToRobot(page){
      daEventCenter.triggerNotice({
        daId: '1-19-1-161',
        extraData: {
          store_code: gbCommonInfo.robotStoreData?.storeCode
        }
      })
      this.toRobot(page)
    },
    toRobot (page) {
      jumpToRobot(page, (data) => {
        let billno = this.orderInfo?.billno || this.$route?.params?.billno || ''
        let paramsStr = billno ? `${data}&billno=${billno}` : data
        this.$routerPush(paramsStr)
      })
    },
    isShowPackageTag(tabGoods = []) {
      return orderLogic?.showPackageQuickShipTag(this.orderInfo, tabGoods, this.isShowQuickShipTag)
    },
    // 获取当前包裹的mall信息
    getPackageMallInfo(tabGoods = []) {
      const mallList = this.orderInfo?.mall_list || []
      const goodsList = tabGoods.flat(Infinity)
      const item = goodsList[0] || {}
      const currentMall = mallList.find(mall => {
        return mall.mall_code == item.mall_code && mall.store_code == item.store_code
      })
      return currentMall || {}
    },
    handlerCalCountDownShow () {
      this.calCountDownShow()
      document.addEventListener('scroll', this.calCountDownShow)
    },
    //顶部倒计时和下面倒计时隐藏
    calCountDownShow () {
      let { newCountDownAbt } = this.orderResultAbtInfo || {}
      if(!newCountDownAbt){
        this.btnExpShow = true
        return
      }
      let headerTop = this.$refs?.orderDetailHeader?.clientHeight || 0
      let elInfo = this.$refs?.contdownNum?.getBoundingClientRect() || {}
      let { height = 0, top = 0 } = elInfo || {}

      //如果上面的倒计时因滚动被遮住，展示下面的按钮倒计时
      let dis = top - headerTop + height
      this.btnExpShow = dis < 0
    },
    clearChannelPayInfoEvt () {
      this.resetPayAgainState()
    },
    handlerDate(date) {
      return orderLogic.orderDateFormat(date, false, this.locals)
    },
    navToCombined() {
      const reference_number =
        this.orderInfo.orderGoodsList?.flat(Infinity)[0]?.reference_number || ''
      daEventCenter.triggerNotice({
        daId: '1-19-1-51',
        extraData: {
          billno: this.orderInfo.billno,
          package_no: reference_number
        }
      })
      this.$router.push(`${this.locals.langPath}/user/orders/combinedOrder/${this.orderInfo.billno}`)
    },
    handleMethod () {
      this.handleSave = orderFactory.createOrderToCart(this, 'order_detail')
    },
    toEditPayment () {
      if (this.detailFooterDomConfig?.['PaymentMethodEdit'] === 'on') {
        this.detailFooterDom?.toggleUnavailableModal()
        return
      }

      this.handleEditPayment()
    },
    copyOrderNumber: function () {
      const self = this
      if (typeof Clipboard !== 'undefined') {
        const clipboard = new Clipboard('.copy')

        clipboard.on('success', function (e) {
          console.info('Text:', e.text)
          self.$toast(self.language.SHEIN_KEY_PWA_16286)

          e.clearSelection()
        })
      }
    },
    goBack() {
      // 链接query中存在goBack，则说明需返回上一级
      const isGoBack = this.$route?.query?.goBack == 1
      const isFindOrder = this.$route?.query?.goBack == 2
      if(isFindOrder) {
        location.href = `${langPath}/user/orders/findorder`
        return
      }
      markPoint('toNextPageClick', 'public')
      if (['UserOrdersRecycle', 'UserOrdersList', 'cart', 'UserReviewCenter'].includes(routeFrom.name) || isGoBack) {
        this.$router.back()
      } else {
        if (this.fromRecycle == 'recycleOrderList') {
          this.$router.push(`${this.locals.langPath}/user/orders/recycle`)
        } else {
          this.$router.push(`${this.locals.langPath}/user/orders/list`)
        }
      }
    },
    selectedTabEvt (order) {
      if (this.showUseNewPackage) return

      const source = this.$route.query.source || ''
      const len = (order._allOrderGoodsList && order._allOrderGoodsList.length) || 0
      if (source == 'selfExchange' && len) {
        this.uploadGoodsList(len - 1)
        this.updateTrackInfo(len - 1)
      }
    },
    toClickEditAddress() {
      if (!window.navigator.onLine) {
        this.$toast(this.language.SHEIN_KEY_PWA_31762)
        return
      }
      if (orderLogic.disabledEditAddress(this.orderInfo)) {
        this.$refs.detailFooter.showFrontConditionText = this.htmlDecode(
          this.language.SHEIN_KEY_PWA_21411
        )
        this.$refs.detailFooter.showFrontConditionDialog = true
        return
      }
      if (this.overFrontCondition > 0) {
        daEventCenter.triggerNotice({
          daId: '1-19-1-72',
          extraData: {
            operation_from: 3
          }
        })
        this.$refs.detailFooter.showFrontConditionText = this.htmlDecode(
          this.language.SHEIN_KEY_PWA_20123
        )
        this.$refs.detailFooter.showFrontConditionDialog = true
        return
      }
      if (this.deliveryEditAddressCond == 2) {
        this.$refs.detailFooter.showFrontConditionText = this.htmlDecode(
          this.language?.SHEIN_KEY_PWA_21420
        )
        this.$refs.detailFooter.showFrontConditionDialog = true
        return
      }
      if (this.overB2B2CEditAddress) {
        this.$refs.detailFooter.showFrontConditionText = this.htmlDecode(
          this.language.SHEIN_KEY_PWA_21411
        )
        this.$refs.detailFooter.showFrontConditionDialog = true
        return
      }
      if (this.detailFooterDomConfig?.['ShippingAddressEdit'] === 'on') {
        this.detailFooterDom?.toggleUnavailableModal()
        return
      }
      if (this.deliveryEditAddressCond == 3 && this.showCombinedShippedFlag) {
        this.combinedShippedFlag = true
        return
      }
      if (!this.merchantOrderCanEdit) {
        return
      }
      this.fetchBatchModifyAddress(this.orderInfo.billno)
    },
    shippedEditAddress() {
      this.combinedShippedFlag = false
      this.fetchBatchModifyAddress(this.orderInfo.billno)
    },
    toClickEditBillAddress () {
      if (this.detailFooterDomConfig?.['BillingAddressEdit'] === 'on') {
        this.detailFooterDom?.toggleUnavailableModal()
        return
      }
      this.clickEditBillAddress()
    },
    async showCancelAddCartModal(orders, billno) {
      // 巴西平台化, 要根据是否符合弹框条件弹框(关联订单， 多个子订单)
      let subBillnoListRes = []
      // eslint-disable-next-line @shein-aidc/abt/abt
      let { UnpaidOrderPage } = await abtservice.getUserAbtResult({ posKeys: 'UnpaidOrderPage' })
      let unpaidOrderPageAbt = UnpaidOrderPage?.param && UnpaidOrderPage?.param != 'none'
      if (isRelationBillno(billno)) {
        let subBillnosInfo = await schttp({
          url: `/api/orders/base/getSubBillnos/get?relation_billno=${billno}`
        })
        subBillnoListRes = subBillnosInfo?.info?.result?.[0]?.sub_billno_list_res || []
      }
      let showAddCart = !(subBillnoListRes?.length > 1)
      if (!showAddCart) {
        if (unpaidOrderPageAbt) {
          location.href = `${this.locals.langPath}/user/orders/state?type=unpaid&status=1&billno=${billno}`
        } else {
          this.$routerPush(`${this.locals.langPath}/user/orders/list`)
        }
        return
      }
      this.status.showAddCart = showAddCart
      this.cancelForm.order = orders.filter(item => {
        return item.billno == billno
      })[0]

      daEventCenter.triggerNotice({
        daId: '1-19-1-25',
        extraData: this.cancelOrderData()
      })
    },
    cancelOrderData() {
      const order = this.cancelForm.order
      let goods_id = []
      order.oldOrderGoodsList.forEach(item => {
        goods_id.push(item.goods_id)
      })
      goods_id = goods_id.join(',')
      return {
        order_id: order.billno,
        goods_id: goods_id,
        order_type: order.payment_method == 'cod' ? 1 : 2,
        order_status: [0, 12, 13].includes(order.initOrderStatus) ? 1 : 2,
        orderStatus: order.initOrderStatus
      }
    },
    async handleCancelAddCartModal(result) {
      let { billno = '' } = this.orderInfo || {}
      let subBillnosList = []
      if (isRelationBillno(billno)) {
        let subBillnosInfo = await schttp({
          url: `/api/orders/base/getSubBillnos/get?relation_billno=${billno}`
        })
        subBillnosList = subBillnosInfo?.info?.result?.[0]?.sub_billno_list_res || []
      }
      if (result) {
        if (subBillnosList?.length > 1) {
          this.relationParamError = true
          return
        }
        let tempBillno = subBillnosList?.length == 1 ? subBillnosList?.[0]?.billno : billno
        this.handleSave(
          tempBillno,
          res => {
            if (res.code == 0) {
              this.$routerPush(`${this.locals.langPath}/cart`)
              this.$toast(this.language.SHEIN_KEY_PWA_15021)
            }
          },
          'cancel'
        )
        daEventCenter.triggerNotice({
          daId: '1-19-1-26',
          extraData: this.cancelOrderData()
        })
      } else {
        /* close */
        daEventCenter.triggerNotice({
          daId: '1-19-1-27',
          extraData: this.cancelOrderData()
        })
        // 确认取消订单（未支付订单取消成功页面）
        this.toCancelOrderSuccessPanel(subBillnosList)
      }
      this.status.showAddCart = false
    },
    async toCancelOrderSuccessPanel(subBillnosList = []) {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const { UnpaidOrderPage } = await abtservice.getUserAbtResult({ posKeys: 'UnpaidOrderPage' })
      if (UnpaidOrderPage?.param && UnpaidOrderPage?.param != 'none') {
        location.href = `${this.locals.langPath}/user/orders/state?type=unpaid&billno=${this.orderInfo.billno}`
      } else {
        if (subBillnosList?.length > 1) {
          this.jumpToOrderList()
        } else {
          location.reload()
        }
      }
    },
    jumpToOrderList() {
      this.$routerPush(`${this.locals.langPath}/user/orders/list`)
    },
    getRetainTips(methods) {
      return retainPayMethodLang(this.language, methods)
    },
    toCancelOrder(order, type) {
      const { payment_type, orderStatus } = order
      if ([0, 12].includes(orderStatus) && payment_type == 2) {
        this.retainOrder.order = order
        return this.toggleRetainOrder('popup')
      }
      this.handelCacncelBtnClick(order, type)
      sa('send', {
        activity_name: 'expose_popup_unpaidorder_cancel_confirmation',
        activity_param: {}
      })
    },
    toggleRetainOrder(type) {
      const saActivityName = {
        popup: 'expose_popup_cancelorderretentionbox',
        ok: 'click_confirmcancel',
        cancel: 'click_donotcancelnow'
      }
      sa('send', {
        activity_name: saActivityName[type],
        activity_param: {}
      })
      this.retainOrder.mask = !this.retainOrder.mask
    },
    async refundDetailFn () {
      this.changeRootStatus({
        loading: true
      })
      let res = await schttp({
        url: '/api/orders/base/refund_detail/get',
        params: {
          billno: this.orderInfo.billno
        }
      })
      this.changeRootStatus({ loading: false })

      if (res?.info?.refund_bills) {
        this.refundDetail = {
          show: true,
          data: res?.info?.refund_bills.map(item => {
            this.$set(item, 'showDetail', false)
          }),
          text: {
            refund_path1: this.language?.SHEIN_KEY_PWA_14929,
            refund_path2: this.language?.SHEIN_KEY_PWA_14927,
            refund_path31: this.language?.SHEIN_KEY_PWA_14928,
            refund_path32: this.language?.SHEIN_KEY_PWA_14930,
            refund_path33: this.language?.SHEIN_KEY_PWA_14931,
            refund_path34: 'Понадобится 10-30 рабочих дней, чтобы денежные средсва вернулись к Вам на карту.',
            refund_path35: this.language?.SHEIN_KEY_PWA_14926,
            refund_path36: this.language?.SHEIN_KEY_PWA_14932,
            refund_path37: this.language?.SHEIN_KEY_PWA_14933
          }
        }
      }
    },
    handleTabChange({ val }) {
      this.uploadGoodsList(val)
      this.updateTrackInfo(val)
    },
    uploadGoodsList (key) {
      var self = this
      self.orderInfo.orderGoodsList = []
      if (self.orderInfo._allOrderGoodsList[key] && self.orderInfo._allOrderGoodsList[key][0]) {
        self.orderInfo.orderGoodsList.push(self.orderInfo._allOrderGoodsList[key])
      }
      self.tabSelected = key
      this.clickPackageTab()
    },
    updateTrackInfo (val) {
      // Processing状态Tab不需要显示track信息
      if (!this.showUseNewPackage || val === this.orderInfo?.processingTabIndex) {
        this.orderInfo.orderTrackInfo = {}
        return
      }
      let goodsItem = this.orderInfo?.orderGoodsList?.flat(Infinity)?.find(item => !!item.reference_number)
      let tempTrackInfo = this.orderInfo?.trackList?.find(child => child.package_no == goodsItem?.reference_number) || {}
      this.orderInfo.orderTrackInfo = tempTrackInfo || {}
    },
    clickPackageTab() {
      daEventCenter?.triggerNotice({
        daId: '1-19-1-85',
        extraData: {
          package_id: this.getPackageIdByTab(),
          quickship_tp: this.getQuickShipTp()
        }
      })
    },
    getPackageIdByTab(tabGoodsList) {
      tabGoodsList = tabGoodsList ? tabGoodsList : this.orderInfo?.orderGoodsList
      let goodsList = tabGoodsList?.flat(Infinity)
      return goodsList?.[0]?.reference_number || ''
    },
    getQuickShipTp(tabGoodsList) {
      tabGoodsList = tabGoodsList ? tabGoodsList : this.orderInfo?.orderGoodsList
      let qsMap = {
        1: 2,
        2: 1,
        0: 0
      }
      let res = orderLogic?.getQuickShipTransportTimeType(tabGoodsList)
      return qsMap?.[res]
    },
    handleTrack(id) {
      if (id) {
        this.$router.push(`${this.locals.langPath}/user/orders/track/${this.orderInfo.billno}?id=${id}`)
      }
    },
    async urgingGoods (e) {
      let billno = this.orderInfo.billno
      let $target = e.target

      if ($target.getAttribute('sended')) {
        return this.showMaskTips(this.language?.SHEIN_KEY_PWA_15241)
      }

      this.changeRootStatus({
        loading: true
      })
      let msg = await schttp({
        url: '/api/orders/base/remindToShip/get',
        params: {
          billno
        }
      })
      this.changeRootStatus({
        loading: false
      })
      if (msg.code == 0) {
        this.showMaskTips(this.language?.SHEIN_KEY_PWA_15239)
        $target.setAttribute('sended', true)
      } else {
        if (msg.code == 666666 || msg.code == 777777) {
          this.$toast(this.language?.SHEIN_KEY_PWA_17115)
        } else {
          this.showMaskTips(this.language?.SHEIN_KEY_PWA_15241)
        }
      }
    },
    async returnItemMask () {
      var billno = this.inReturnItem.billno
      this.assignState({
        inReturnItem: {
          mask: false,
          billno
        }
      })
      this.changeRootStatus({
        loading: true
      })
      let data = await schttp({
        method: 'POST',
        url: '/api/orders/base/complete/update',
        data: { billno: billno }
      })
      this.changeRootStatus({
        loading: false
      })
      if (data.code == 0) {
        this.$router.push({
          path: `${this.locals.langPath}/orders/return/${billno}`
        })
      } else {
        if (data.code == 300399) {
          this.showMsg({
            type: 'err',
            msg: this.language?.SHEIN_KEY_PWA_14909
          })
        } else {
          this.showMsg({
            type: 'err',
            msg: this.language?.SHEIN_KEY_PWA_15330
          })
        }
      }
    },
    handleDeleteOrder(billNo) {
      this.deleteOrderStatus.show = true
      this.deleteOrderStatus.billNo = billNo
      daEventCenter.triggerNotice({
        daId: '1-19-1-28'
      })
    },
    async closeDeleteDialog(type) {
      if (!this.deleteOrderStatus.show) return
      this.deleteOrderStatus.show = false
      if (type == 'no') {
        daEventCenter.triggerNotice({
          daId: '1-19-1-31'
        })
      } else {
        daEventCenter.triggerNotice({
          daId: '1-19-1-29'
        })
      }
    },
    async deleteOrder() {
      daEventCenter.triggerNotice({
        daId: '1-19-1-30'
      })
      this.deleteOrderStatus.show = false
      this.deleteOrderStatus.loading = true
      let data = await schttp({
        url:
          '/api/orders/base/recycleOrder/delete?recycleType=2&billno=' +
          this.deleteOrderStatus.billNo
      })
      this.deleteOrderStatus.loading = false
      if (data && data.code == 0) {
        if (window.localStorage) {
          //回收站功能上线后首次删除订单则展示入口提示气泡
          try {
            let isFirstDeleteOrder = window.localStorage.getItem('firstDeleteOrder')
            if (!isFirstDeleteOrder) {
              window.localStorage.setItem('firstDeleteOrder', 1)
            }
          } catch (e) {
            console.info(e)
          }
        }
        setTimeout(() => {
          this.showMsg({
            msg: this.htmlDecode(this.language?.SHEIN_KEY_PWA_16109),
            type: 'succ',
            timer: 1500
          })
          setTimeout(() => {
            this.$router.push({
              path: `${this.locals.langPath}/user/orders/list`,
              query: { deletedBillNo: this.deleteOrderStatus.billNo }
            })
          }, 1600)
        }, 200)
      } else {
        if (data.code == 300399) {
          setTimeout(() => {
            this.showMsg({
              msg: this.htmlDecode(this.language?.SHEIN_KEY_PWA_14909),
              type: 'err',
              timer: 1500
            })
          }, 200)
        } else if (data.code == 666666 || data.code == 777777) {
          this.$toast(this.language?.SHEIN_KEY_PWA_17115)
        } else {
          setTimeout(() => {
            this.showMsg({
              msg: this.htmlDecode(this.language?.SHEIN_KEY_PWA_16110),
              type: 'err',
              timer: 1500
            })
          }, 200)
        }
      }
    },
    orderRefundMask() {
      this.refundNotCodMask = false
      daEventCenter.triggerNotice({
        daId: '1-19-1-32',
        extraData: {
          billno: this.orderInfo.billno,
          order_type: this.orderInfo.payment_method == 'cod' ? 1 : 2
        }
      })
      this.assignCancelItemState({
        visibleMainDrawer: true,
        orderInfo: this.orderInfo,
        language: this.language
      })
    },
    cancelRefundMask() {
      daEventCenter.triggerNotice({
        daId: '1-19-1-41',
        extraData: {
          billno: this.orderInfo.billno,
          order_type: this.orderInfo.payment_method == 'cod' ? 1 : 2
        }
      })
      this.refundNotCodMask = false
    },
    cancelItemShow() {
      daEventCenter.triggerNotice({
        daId: '1-19-1-40',
        extraData: {
          billno: this.orderInfo.billno,
          order_type: this.orderInfo.payment_method == 'cod' ? 1 : 2
        }
      })
      this.jumpRefundItem(this.orderInfo).then(() => {
        this.refundNotCodMask = true
      })
    },
    /*
    *
    * 前端新增展示订单商品状态“Allocating”
      * 1.订单商品状态为2（Processing）时，取结构 subOrderStatus下的goods_status字段值
      * 1.1 goods_status字段值不为49，订单商品状态为2，仍展示文案“Processing”
      *1.2 goods_status字段值为49，combinedPackage有值展示“Allocating”（转运中）；无值展示“Processing”
    *
    *
    * */
    newGoodsList (goodsList) {
      let goodsListTemp = []
      let subGoodsList = []
      let splitCond = orderLogic.orderListOrderStatusCond(this.orderInfo, this.isCanRefundAbt)
      if (goodsList?.length && this.orderInfo?.subOrderStatus) {
        const processingOrderProducts = this.orderInfo.subOrderStatus.filter(item => item.packageState == 'processing')
        if (processingOrderProducts.length && processingOrderProducts[0].goodsList) {
          subGoodsList = processingOrderProducts[0].goodsList
          const combinedPackageIds = []
          subGoodsList.forEach(subItem => {
            if (
              subItem.goods_status == 49 &&
              typeof subItem.combinedPackage != 'undefined' &&
              (subItem.combinedPackage == 0 || subItem.combinedPackage == 1)
            ) {
              combinedPackageIds.push(subItem.id)
            }
          })
          goodsList.forEach(list => {
            let allocatingGoodsList = []
            let processingGoodsList = []
            let itemObj = { processing: [] }
            if (list[0].orderGoodsStauts == 2) {
              list.forEach(item => {
                if (splitCond) {
                  let { pack_detail_status: packStatus } = item
                  let existText = orderLogic.getOrderStatusTextByOrderList(
                    this.orderStatusTextList,
                    {
                      ...this.orderInfo,
                      order_or_package_status: packStatus
                    }
                  )
                  if (!(packStatus == 3 && existText) && combinedPackageIds.includes(item.id)) {
                    item.isAllocating = true
                    allocatingGoodsList.push(item)
                  } else {
                    if (typeof packStatus == 'number' && existText) {
                      if (itemObj[packStatus]) {
                        itemObj[packStatus].push(item)
                      } else {
                        itemObj[packStatus] = [item]
                      }
                    } else {
                      itemObj.processing.push(item)
                    }
                  }
                } else {
                  if (combinedPackageIds.includes(item.id)) {
                    item.isAllocating = true
                    allocatingGoodsList.push(item)
                  } else {
                    processingGoodsList.push(item)
                  }
                }
              })
              let orderGoodsStatusList = Object.values(itemObj)
              orderGoodsStatusList?.length && goodsListTemp.push(...orderGoodsStatusList)
              processingGoodsList.length && goodsListTemp.push(processingGoodsList)
              allocatingGoodsList.length && goodsListTemp.push(allocatingGoodsList)
            } else if (list[0].orderGoodsStauts == 4) {
              const refundedGoodsList = []
              const needRefundGoodsList = []
              list.forEach(item => {
                const refund_record_status_list = item.refund_record_status_list || []
                const isExistRefundRecordStatus = refund_record_status_list.filter(
                  item => item.status == '8'
                )
                if (isExistRefundRecordStatus.length) {
                  refundedGoodsList.push(item)
                } else {
                  needRefundGoodsList.push(item)
                }
              })
              refundedGoodsList.length && goodsListTemp.push(refundedGoodsList)
              needRefundGoodsList.length && goodsListTemp.push(needRefundGoodsList)
            } else {
              goodsListTemp.push(list)
            }
          })
        } else {
          goodsListTemp = goodsList
        }
      } else {
        goodsListTemp = goodsList
      }
      // 换货商品数据提取
      const selfExchange = []
      const noSelfExchange = []
      goodsListTemp?.forEach(goodsList => {
        if (goodsList.length) {
          // 且过滤掉付费会员虚拟商品
          const selfExchangeGoodsList = goodsList.filter(goods => goods.is_exchange)
          const noSelfExchangeGoodsList = goodsList.filter(goods => !goods.is_exchange)
          selfExchangeGoodsList.length &&
            selfExchangeGoodsList.forEach(list => {
              selfExchange.push([list])
            })
          noSelfExchangeGoodsList.length && noSelfExchange.push(noSelfExchangeGoodsList)
        }
      })
      return [...selfExchange, ...noSelfExchange]
    },
    closeShowUnsubscribeMask () {
      this.showUnsubscribeMask = false
      this.modifySubscribe.showModifyDrawer = true
      sa('send', {
        activity_name: 'click_whatsapp_unsubscribe_no',
        activity_param: {}
      })
    },
    async unsubscribe() {
      this.changeRootStatus({
        loading: true
      })
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/unSubWhatsApp/update',
        data: {
          billno: this.orderInfo.billno,
          sceneType: 12
        }
      })
      this.changeRootStatus({
        loading: false
      })
      if (res.code == 0) {
        this.modifySubscribe.showModifyDrawer = false
        this.showUnsubscribeMask = false
        this.assignState({ subscribed: 0 })
        this.$toast(this.language?.SHEIN_KEY_PWA_17765)
        sa('send', {
          activity_name: 'click_whatsapp_unsubscribe_yes',
          activity_param: {
            result: 0
          }
        })
      } else {
        this.assignState({ subscribed: 1 })
        this.$toast(this.language?.SHEIN_KEY_PWA_17766)
        sa('send', {
          activity_name: 'click_whatsapp_unsubscribe_yes',
          activity_param: {
            result: 1
          }
        })
      }
    },
    handleShowModifyDrawer(type) {
      this.modifySubscribe.showModifyDrawer = true
      sa('send', {
        activity_name:
          type == 'edit' ? 'expose_whatsapp_unsubscribe' : 'expose_whatsapp_subscribe_confirm',
        activity_param: {}
      })
    },
    updateCountDown({ h, m, s } = {}){
      this.countDown.h = h || ''
      this.countDown.m = m || ''
      this.countDown.s = s || ''
    },
    // 已发货修改收货地址判断条件
    async deliveryGoodsUpdateAddressCond(order = {}) {
      // 默认0 1不可修改(不展示按钮) 2不可修改(置灰按钮) 3支持修改(展示按钮)
      let updateStatus = 0
      let { subOrderStatus = [], billno } = order || {}

      const { type, urgeResList } = await orderLogic.checkEditShippingOrder({
        orders: [order],
        isShippedAddressEnable: this.isShippedAddressEnable,
        isInfoFrontAbt: this.isInfoFrontAbt,
        isCanRefundAbt: this.isCanRefundAbt
      })
      const urgeRes = urgeResList.filter(i => i.billno == billno) || {}
      // display_flag  1: 支持 2: 支持但是已经申请 3: 不支持
      const statusMap = {
        1: 3,
        2: 2,
        3: 1
      }
      const displayFlag = urgeRes?.[0]?.display_flag
      updateStatus = statusMap[displayFlag]

      // 存在已发货包裹满足修改地址条件，则需要弹框提示
      let shipPackage = subOrderStatus?.filter(item => ['shipped'].includes(item.packageState))
      let unShipCondition = orderLogic.checkShipEdit({
        order,
        isInfoFrontAbt: this.isInfoFrontAbt,
        isCanRefundAbt: this.isCanRefundAbt
      })
      let isSupportEdit = updateStatus == 3 && this.isShippedAddressEnable && shipPackage?.length
      this.showCombinedShippedFlag = isSupportEdit

      if (type == 1) {
        this.deliveryUpdateInfo = {
          errorText: this.language?.SHEIN_KEY_PWA_21424,
          successText: this.language?.SHEIN_KEY_PWA_21423
        }
      } else if (type == 2) {
        updateStatus = updateStatus == 2 ? 1 : updateStatus
        this.deliveryUpdateInfo = {
          errorText: isSupportEdit && !unShipCondition ? this.language?.SHEIN_KEY_PWA_21424 : '',
          successText:
            isSupportEdit && unShipCondition
              ? this.language?.SHEIN_KEY_PWA_21426
              : isSupportEdit
                ? this.language?.SHEIN_KEY_PWA_21423
                : this.language?.SHEIN_KEY_PWA_21427
        }
      }
      return updateStatus
    },
    async getOrderStatusTextEvt() {
      let result = await getOrderStatusText()
      this.assignState({
        orderStatusTextList: result || []
      })
    },
    orderPayButtonNowNowEvt (options = {}) {
      this.detailFooterDom?.sendPayNowEventData('top')
      this.payNowOrVerifyNowEvt(options)
    },
    async payNowOrVerifyNowEvt ({ payType } = {}) {
      let { billno = '', shipping_country_id = '', shipping_country_name = '',  payment_type, expireCountdown } = this.orderInfo || {}
      let overExpireTime = +expireCountdown  <= (Date.parse(new Date()) / 1000)

      // 是否过了未支付时间
      if (+payment_type == 2 && overExpireTime) {
        daEventCenter.triggerNotice({ daId: '1-19-1-4' })
        this.expiredModalConfig = {
          show: true,
          order: this.orderInfo
        }
        return
      }

      // fix 历史逻辑兼容
      if (this.selectedPaymentInfo.code !== 'PayPal-Venmo') {
        // 是否触发切站
        let result = await this.checkSiteFn(this.orderInfo)
        if (result != 1) {
          return this.jumpMask({
            url: `/user/orders/detail/${billno}`,
            result,
            shipping_country_name,
            shipping_country_id
          })
        }
      }

      // console.log('order_new==payNowOrVerifyNowEvt==', payType, this.selectedPaymentInfo?.code)
      if (!this.selectedPaymentInfo?.code) {
        this.handleEditPayment()
        return
      }

      if (payType == 1) {
        this.handleConfirmPayNow()
      } else {
        this.handleDirectCreatePayment()
      }
    },
    async toggleOrderExpiredModal({ type = '' } = {}) {
      let eventMap = {
        'cancel': '1-19-1-5',
        'ok': '1-19-1-6',
      }
      daEventCenter.triggerNotice({ daId: eventMap[type] })

      this.expiredModalConfig.show = false

      if (type === 'cancel') {
        let { billno = '' } = this.expiredModalConfig?.order || {}
        await this.fetchBffOrderInfo({ billno })
        this.handleEditPayment()
      }
    },
    handleCloseDrawer () {
      this.changePageStatus({ showPaymentsDrawer: false })
      this.assignState({ unPaidOrderLoadPayment: false })
      this.resetUnPaidOrderPaymentStatus()
    },
    handleManualCloseDrawer () {
      this.changePageStatus({ showPaymentsDrawer: false })
      // 普通场景下，用户主动关闭支付方式列表弹窗时，需重置当前支付方式选中态为真实选中的
      if (!this.unPaidOrderPaymentStatus?.isImmediatelyPay) {
        this.$refs?.paymentOptionsDrawerRef?.resetBackPrevSelectedPayment?.()
      }
    },
    getIsCancalSelectedTokenPay({ paymentInfo }) {
      if (!('card_token_list' in paymentInfo)) return false
      const { token_id = '' } = this.bffOrderInfo || {}
      const { card_token_list = [] } = paymentInfo || {}
      return !card_token_list?.some(item => item.id == token_id)
    },
    handleOpenDrawer () {
      // 清空支付方式选中态并自动打开支付方式列表时，需执行此方法，用于重置支付方式列表弹窗组件选中状态
      if (this.unPaidOrderPaymentStatus.isOpenPaymentList && this.unPaidOrderPaymentStatus.isClearPaymentMethod) {
        this.$refs?.paymentOptionsDrawerRef?.resetInitStatus?.()
      }
    },
    handleBsPaymentOptionsInit () {
      console.log('handleBsPaymentOptionsInit==')
      // 清空支付方式选中态并自动打开支付方式列表时，需执行此方法，用于重置支付方式列表的选中状态
      if (this.unPaidOrderPaymentStatus.isOpenPaymentList && this.unPaidOrderPaymentStatus.isClearPaymentMethod) {
        this.$refs?.paymentOptionsDrawerRef?.resetSelectedPayment?.()
      }
    },
    /**
     * 初始化支付方式选择
     * 
     * {
     *    paymentInfo: PaymentInfoItem, // 当前选择的支付方式信息
     * }
     */
    handleInitSelectPayment ({
      paymentInfo = {}
    } = {}) {
      // 方法源于useOrderPayment.js
      if (paymentInfo?.code) {
        this.awaitInitSelectPaymentInfo({
          paymentInfo,
        })
        this.handleSetPayToolkitFn()
        const isOpenPaymentList = this.unPaidOrderPaymentStatus.isOpenPaymentList
        setTimeout(() => {
          if (isOpenPaymentList && this.getIsCancalSelectedTokenPay({ paymentInfo })) {
            this.$refs?.paymentOptionsDrawerRef?.resetSelectedPayment?.()
          }
        })
      }
    },
    /**
     * 当前选择的支付方式
     * 
     * {
     *   paymentInfo: PaymentInfoItem, // 当前选择的支付方式信息
     *   isChange: boolean, // 是否切换了支付方式
     * }
     */
    async switchPaymentMethodEvt ({ paymentInfo = {} } = {}, { autoClose = true } = {}) {
      let { billno = '', orderCurrency = {} } = this.bffOrderInfo || {}
      let { code = '', id = '', enabled, card_token_info = {}, token_id = '', isUseNewCard = false } = paymentInfo || {}

      const nowTokenId = isUseNewCard ? '' : card_token_info?.id || token_id || ''
      const nowBin = isUseNewCard ? '' : card_token_info?.card_bin || ''

      if (!enabled) return
      this.changeRootStatus({ loading: true })

      const paymentOptionsDrawerRef = this.$refs?.paymentOptionsDrawerRef

      let resInfo = await switchPaymentSer({
        billno,
        editType: 'editOrderPaymentMethod',
        payment_id: id,
        payment_code_unique: code,
        appCurrency: orderCurrency?.code || '',
        tokenId: nowTokenId,
        bin: nowBin,
      })

      if (resInfo?.code == '0') {
        // switchPayment成功后更新支付数据
        this.handleSelectPayment(paymentInfo)
        this.handleSetPayToolkitFn()

        // 更新旧订单信息
        this.assignState({
          orderInfo: {
            ...this.orderInfo,
            ...(resInfo?.info || {}),
            payment_type: paymentInfo?.payment_type || this.orderInfo?.payment_type,
            onlinePayDiscountInfo: resInfo?.info?.onlinePayDiscount ? {
              ...(resInfo?.info?.onlinePayDiscount || {}),
              discount_price: resInfo?.info?.onlinePayDiscount?.discountPrice
            } : null,
            addTime: resInfo?.info?.addTime ? timeTransformer({
              time: resInfo?.info?.addTime * 1000,
              defaultCode: 'A-3',
              multiLang: false
            }) : this.orderInfo?.addTime,
            orderExtend: {
              ...(this.orderInfo.orderExtend),
              codPrice: resInfo?.info?.codPrice ? resInfo?.info?.codPrice : {}
            },
            card_bin_discount_amount_price: resInfo?.info?.card_bin_pay_discount?.card_bin_discount_price || {},
            token_id: nowTokenId,
            token_text: nowTokenId && resInfo?.info?.card_bin_pay_discount?.card_bin_discount_price?.amount > 0 ? template(resInfo?.info?.card_bin_pay_discount?.card_bin_discount_price?.amountWithSymbol, this.language?.SHEIN_KEY_PWA_31382) : ''
          }
        })
        // 更新BFF订单信息
        await Promise.all([
          this.fetchBffOrderInfo({ billno, tokenId: nowTokenId, }).catch(() => {})
        ])
        this.handleChangePayment({
          paymentLogo: paymentInfo.logo_url,
          paymentTitle: paymentInfo.payment_title,
          paymentMethod: paymentInfo.code,
          paymentType: paymentInfo?.payment_type,
          orderInfo: this.orderInfo
        })
        this.handleUpdatePayment()
        this.changeRootStatus({ loading: false })
        if (autoClose) {
          this.changePageStatus({ showPaymentsDrawer: false })
        }
      } else {
        paymentOptionsDrawerRef.resetBackPrevSelectedPayment()
        this.$toast(resInfo?.msg)
        this.changeRootStatus({ loading: false })
        // return Promise.reject(resInfo)
      }
      return resInfo
    },
    handleSelectPaymentInfo (data = {}) {
      console.log('order_new==handleSelectPayment==', data)

      // 处理paynow逻辑，即需要执行切换支付方式逻辑
      if (this.unPaidOrderPaymentStatus.isImmediatelyPay || data?.isNowSwitchPay) {
        if (data?.paymentInfo?.code) {
          this.switchPaymentMethodEvt(data, { autoClose: false })
        }
      }
    },
    /**
     * 立即支付，即用户点了ok按钮
     * 
     * {
     *   paymentInfo: PaymentInfoItem, // 当前选择的支付方式信息
     *   isChange: boolean, // 是否切换了支付方式
     *   extraInfo: any, // 额外的支付信息，透传即可
     * }
     */
    async handleConfirmPayment (data) {
      console.log('order_new==handleConfirmPayment==', data, this.unPaidOrderPaymentStatus.isImmediatelyPay)

      // 处理自动弹出支付方式选择逻辑，此时为paynow逻辑，即点击时需要执行更新订单及支付流程
      if (this.unPaidOrderPaymentStatus.isImmediatelyPay) {
        this.changePageStatus({ showPaymentsDrawer: false })
        if (data.paymentInfo?.code) {
          // 此时应让用户主动触发点击按钮，不能在弹窗内渲染按钮，因为多个按钮高度只能一致，无法区分内外按钮
          if (this.isShowPaypalButton) {
            this.changeRootStatus({ loading: false })
            return
          }
          this.handleConfirmPayNow()
        } else {
          this.changeRootStatus({ loading: false })
        }
      } else {
        if (data.isChange) {
          this.switchPaymentMethodEvt(data)
        } else {
          this.changePageStatus({ showPaymentsDrawer: false })
          this.changeRootStatus({ loading: false })
        }
      }
    },
    handleSelectPaymentChange (data) {
      console.log('order_new==handleSelectPaymentChange==', data)
      this.handleUpdatePaymentChange(data.paymentInfo)
      this.handleSetPayToolkitFn()
    },
    async handlePayToolkitPayNow ({
      paymentInfo = {},
      isChange = false,
      extraInfo = {}
    } = {}) {
      console.log('order_new==handlePayToolkitPayNow==', paymentInfo)
      if (isChange) {
        await this.switchPaymentMethodEvt({ paymentInfo })
        this.handleStartPaymentProcess({ extraPayInfo: extraInfo })
      } else {
        this.handleStartPaymentProcess({ extraPayInfo: extraInfo })
      }
    },
    // 支付方法
    async handleConfirmPayNow () {
      console.log('order_new==handleConfirmPayNow==')
      this.handleStartPaymentProcess()
    },
    handleSetPayToolkitFn () {
      const fn = this.$refs?.paymentOptionsDrawerRef?.getValidatedPayment?.()
      const resetFn = this.$refs?.paymentOptionsDrawerRef?.getResetBsPayTookitStatusFn?.()
      this.handleSetValidateByPayFn(fn)
      this.handleSetResetBsPayTookitStatusFn(resetFn)
    }
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
@w375: 375/100vw;
* {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
  height: 100vh;
}
body {
  font-family: Helvetica, Arial, sans-serif;
  color: #222;
  width: 10rem;
  height: 100%;
  line-height: 1.5;
  margin: 0 auto;
  background: #f6f6f6;
  -webkit-user-select: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
a {
  color: #666;
  text-decoration: underline;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
  font-weight: bold;
  color: #222;
}
h1 {
  font-size: 20px;
}
h2 {
  font-size: 17px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 11px;
}
i,
em {
  font-style: normal;
}
input,
button,
select,
textarea {
  font-family: inherit;
  -webkit-appearance: none;
  outline: none;
}
[tabindex] {
  outline: none;
}
img {
  vertical-align: middle;
}
img[src=""],
img:not([src]) {
  opacity: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
li {
  list-style: none;
}
textarea {
  overflow: auto;
  resize: none;
}
.mshe-flexbetween {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
}
.server-hint {
  position: absolute;
  right: -4/75rem;
  top: -4/75rem;
  width: 12/75rem;
  height: 12/75rem;
  background-color: red;
  border-radius: 50%;
}
.service-continer {
  position: relative;
}
.service-popover {
  z-index: 15 !important;
  .S-popover__main-content {
  padding: 16/75rem 24/75rem !important;
  }
}
.service-text {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 12/75rem;
}
.service-context {
  display: flex;
  align-items: center;
  padding: 24/75rem 0;
}
.detail-shipping-flexbetween {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
}
.new-order-detail{
  .fixed-common-header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 10rem;
    margin: 0 auto;
  }
}
.detail-container-z-header {
  .zindex-translatez(@zindex-header,translate3d(0,0,@tranlatez-header));
}
.detail-container-hide {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}
.detail-container-top-pad {
  padding-top:1.17rem;
}
.detail-container-flexbetween {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
}
.orderDetail_recommend_module{
  width: 100%;
}
.detail-time {
  background: #fff;
  .font-dpr(28px);
  .detail-time-ctn {
    .margin-l(24/75rem);
  }
  .detail-time-item {
    border-bottom: 1/75rem solid #E5E5E5;
    padding: 24/75rem 0;
    .padding-r(24/75rem);
    em,
    .qs-time {
      color: #767676;
      .font-dpr(24px);
    }
  }
  .detail-time-item:last-child {
    border: none;
  }
  .sfs-tip{
    color: #198055;
    background: #ECFCF3;
    padding: 0 8/75rem;
    .margin-l(8/75rem);
    .font-dpr(24px);
  }
}
.order-detail-top-contain{
	padding-bottom: 1.1733rem;
  margin-bottom: 1.1733rem;
	.order-detail-footer{
		border-top: 1px solid #e5e5e5;
		padding: 0.1rem .32rem;
    min-height: 1.17rem;
		.padding-l(1rem);
		position: fixed;
		bottom: 0;
		.left(0);
		background: #fff;
		width: 100%;
		.direction-r();
		font-size: 0;
    z-index: 10;
    > a {
      margin: 0.1rem 0.18rem;
      text-transform: capitalize;
    }
    button {
      margin: 0.1rem 0 .1rem 0.18rem;
    }
		.delete-order-ope{
			position: relative;
			display: inline-block;
			.fl();
			a{
			text-decoration: none;
			[class*="iconfont"]{
				.font-dpr(48px);
			}
			}
		}
		.my-btn-more-list {
			position: absolute;
      top: 50%;
      transform: translateY(-50%);
			.left(.32rem);
			.txt-l();
			a{
				text-decoration: none;
				.font-dpr(20px);
				.point{
					width:.1rem;
					height:.1rem;
          margin: 1px;
					background:@sui_color_link;
					border-radius:.07rem;
					border:2px solid @sui_color_link;
					display: inline-block;
				}
			}
      .more-text {
        color: @sui_color_link;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
			.btn-more-list {
				position: absolute;
				background: #fff;
				padding: .1rem .3rem;
				color: #222;
				text-transform: capitalize;
				bottom: .7rem;
				.left(-0.12rem);
				white-space: nowrap;
				z-index: 10; /* stylelint-disable-line declaration-property-value-blacklist */
				.font-dpr(28px);
				color: #666;
				border: 1px solid #e5e5e5;
				box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
				&::after{
					content: ' ';
					display: block;
					width: .3rem;
					height: .3rem;
					border: 1px solid #e5e5e5;
					position: absolute;
					.left(.25rem);
					bottom: -0.15rem;
					-webkit-transform: rotate(135deg);
					-ms-transform: rotate(135deg);
					transform: rotate(135deg);
					background: #fff;
					border-bottom: #fff;
					border-left: #fff;
				}
				.btn-more {
					padding: .06rem 0;
					.font-dpr(24px);
          &.opacity-item {
            background: #fff !important; /* stylelint-disable-line declaration-no-important */
            color: #ccc !important; /* stylelint-disable-line declaration-no-important */
            opacity: 0.7;
            filter: alpha(opacity=70);
          }
				}
        .repurchase-label, .resume-shipment-alert {
          display: none;
        }
        .free_tips,
        .review_tips{
          display: none;
        }
			}
		}
	}
  .order-detail-footer > button:nth-child(2),
  .order-detail-footer > div:nth-child(2) button {
    background: @color_brand !important;
    color: #ffffff !important;
    border: none;
  }
  .order-detail-footer button[disabled] {
    background: #999 !important;
    color: #e5e5e5 !important;
  }
  .detail-userinfo {
    background: #fff;
    padding: .2rem .4rem;
    margin-bottom: 20/75rem;
    .font-dpr(26px);
    color: #333333;
    &.detail-refund-record {
      position: relative;
      p {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .sui_icon_nav_back_24px_mir {
        position: absolute;
        .right(0);
        .font-dpr(32px);
        top: 50%;
        margin-top: -12px;
        color: #999999;
      }
    }
  }
  .detail-subscribe {
    .flexbox();
    .align-center();
    .space-between();
    padding: 0.32rem;
    background: #ffffff;
    margin-bottom: 16/75rem;
    .detail-subscribe-tip {
      .flexbox();
      .align-center();
      color: #222222;
      .font-dpr(28px);
      .padding-r(24/75rem);
      i {
        .font-dpr(36px);
        color: #000000;
        .padding-r(16/75rem);
        display: inline-block;
      }
    }
    .subscribe-btn {
      overflow: initial;
      min-width: auto;
      // height: 48/75rem;
      // line-height: 48/75rem;
      // .font-dpr(24px);
      // color: #222222;
      // font-weight: 400;
      // text-transform: capitalize;
      // & when (@IS_RW) {
      // 	border-radius: 0.26667rem;
      // 	border-color: #ccc!important;/* stylelint-disable-line declaration-no-important */
      // }
    }
  }
  .macc-order-detail {
    height: auto;
    .detail-info-nav {
      background: rgba(0, 0, 0, 0.8);
      padding: 24/75rem;
      color: #fff;
      .font-dpr(28px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20/75rem;
      span {
        display: inline-block;
        .margin-r(12/75rem);
      }
      img {
        width: 48/75rem;
        height: 48/75rem;
      }
    }
    .detail-top {
      margin-bottom: 20/75rem;
      background: #fff;
      .detail-list {
        .list-con {
          padding-top: 0;
        }
        .return-list-item {
          display: block;
          .return-id {
            .font-dpr(28px);
          }
          .return-desc {
            padding: 0.213rem;
            margin: 0;
            color: #666;
            background: #fff1d5;
            & when (@IS_RW) {
              background: #fdefcd;
            }
          }
          &:not(:first-child) {
            .border-dpr(border-top, 2px, #e5e5e5);
            padding-top: 0.4rem;
          }
          .order-img {
            display: flex;
            align-items: center;
            overflow-y: scroll;
            margin: 0.32rem 0;
            img {
              flex: 0 0 auto;
              width: 1.7rem;
              height: 2.267rem;
              .margin-r(0.213rem);
            }
          }
          .color-blue {
            color: @color_blue;
            & when (@IS_RW) {
              color: @color_brand;
            }
            text-decoration: none;
          }
          .title-tip {
            display: flex;
            .info-title {
              flex: 0 0 40%;
              .margin-r(0.213rem);
              color: #999;
            }
            .info-desc {
              color: #222;
              word-break: break-all;
              &.color-blue {
                color: @color_blue;
                & when (@IS_RW) {
                  color: @color_brand;
                }
                text-decoration: none;
              }
            }
          }

          .return-tracking-edit {
            display: flex;
            width: 100%;
            .tracking-title {
              flex: 0 0 40%;
              .margin-r(0.213rem);
              text-transform: capitalize;
              color: #999;
            }
            .tracking-conter {
              overflow: hidden;
              .color-dark-blue {
                color: #093c6e;
                & when (@IS_RW) {
                  color: @color_brand;
                }
              }
              .tracking-edit {
                text-decoration: underline;
              }
              .tracking-edit-btn {
                color: @color_blue;
                & when (@IS_RW) {
                  color: @color_brand;
                }
                > span {
                  color: @color_blue;
                  & when (@IS_RW) {
                    color: @color_brand;
                  }
                }
              }
              img {
                width: 2rem;
              }
            }
          }
          .return-history-info {
            overflow: hidden;
            li {
              overflow: hidden;
              button {
                text-transform: inherit;
                border-color: #333 !important; /* stylelint-disable-line declaration-no-important */
                color: #333;
              }
              span {
                color: #666;
              }
              &:not(:nth-child(1)) {
                margin-top: 5px;
              }
              .status-blue {
                color: #39f;
              }
              .status-grey {
                color: #ccc;
              }
            }
          }
        }
      }
      .cod-unpaid {
        background: fade(#7dc383, 80%);
        color: #fff;
        padding: 0.2rem 0.4rem;
      }
      > p {
        color: #999;
        text-align: center;
        padding: 0.2rem 0.4rem;
      }
      .detail-email {
        padding: 24/75rem;
        .font-dpr(28px);
        color: #222;
        em {
          color: #666;
          display: block;
        }
      }
      .detail-title {
        padding: 0 0.4rem;
        height: 1.173rem;
        .title-left {
          .flexbox();
          flex-flow: row wrap;
          .padding-r(0.21rem);
          .title-order-no {
            line-height: normal;
            .margin-r(0.21rem);
            padding-bottom: 0.08rem;
          }
          .title-tag {
            line-height: normal;
            border: 1px solid #ff7300;
            border-radius: 1px;
            .free-trial-tag {
              .font-dpr(20px);
              color: #ff7300;
              padding: 0.05333rem 0.106rem;
              font-weight: normal;
              text-transform: capitalize;
            }
          }
        }
        .brithday-gift {
          .margin-l(0.1rem);
          color: #bf9f53;
          .brithday-gift_tag {
            width: 0.38rem;
            height: 0.34rem;
            display: inline-block;
            border: 0;
            vertical-align: initial;
            .margin-r(0.05rem);
          }
        }
        .detail-edit-payment {
          .font-dpr(24px);
          line-height: 1.6;
          > i {
            .margin-l(0);
          }
        }
        > span {
          font-weight: 400;
          .font-dpr(28px);
          color: #333;
        }
        .gray {
          color: #999;
        }
        > em {
          display: flex;
          align-items: center;
          .font-dpr(22px);
          color: @sui_color_gray_light1;
          > i {
            .margin-l(0.2rem);
            color: @sui_color_gray_light1;
            font-size: 16px;
          }
        }
        &.detail-title-order-no-tag {
          padding: 0.32rem 0.4rem;
          height: initial !important; /* stylelint-disable-line declaration-no-important */
          line-height: initial !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
    .new-detail-count-down {
      padding: 30/75rem 0.32rem;
      background: #fff8eb;
      position: relative;
      .top-content {
        display: flex;
        justify-content: space-between;
        line-height: 25 / @w375;
        .status-title {
          font-weight: bold;
          .font-dpr(42px);
          .margin-r(0.64rem);
        }
        .count-down-time {
          min-width: 79 / @w375;
          color: @sui_color_highlight;
          font-weight: bold;
          white-space: nowrap;
          .font-dpr(32px);
        }
      }
      .transport-content {
        margin-top: 0.2rem;
      }
      .count-down-day {
        .txt-l();
        width: 250 / @w375;
        color: @sui_color_gray_dark3;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        padding: 0;
        .new-percent__time {
          color: @sui_color_gray_dark3;
          font-size: 12px;
          font-weight: 400;
          i {
            color: @sui_color_gray_dark3;
           }
         }
      }
      .icon-iconx {
        color: #fa6338;
        opacity: 0.07;
        .font-dpr(96px);
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        .right(0.4rem);
      }
    }
    .tax-number-tips {
      font-size: 0.32rem;
      color: @sui_color_micro_emphasis;
      margin-top: 8/75rem;
    }
  }
  &.giftcard {
    .unpaid-wrap {
      display: block;
      .unpaid-list {
        display: flex;
        align-items: center;
        padding: 0;
        .unpaid-icon .icon-iconx {
          .font-dpr(24px);
        }
        .unpaid-time {
          .padding-l(0.08rem);
        }
      }
    }
  }
}
.new-order-detail {
  .detail-inner {
    margin-top: 0.21333333rem;
    .package-tab {
      background: #fff;
      .package-tab-wrap {
        background: #fff;
        height: 1.17rem;
        .nav-container {
          padding: 0 0.4rem;
          width: auto;
          background: #fff;
          color: #666666;
          font-weight: bold;
          a {
            margin: 0 0.2rem;
            width: auto;
            text-decoration: none;
            color: #222;
            .font-dpr(26px);
            .flexbox();
            &.title-active {
              font-weight: bold;
              .border-dpr(border-bottom,6px,@color_brand);
            }
            // .package-tab-remind {
            // 	display: inline-block;
            // 	width: 12/75rem;
            // 	height: 12/75rem;
            // 	background: #FE3B30;
            // 	border-radius: 50%;
            // 	vertical-align: top;
            // }
          }
        }
        .swiper-slide-active {
          color: #222222 !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
    .s-tab-new {
      height: 1.3rem;
      line-height: 1.3rem;
      overflow: visible;
      .S-tab-item__inner {
        overflow: unset;
      }
    }
    .package-item {
      position: relative;
    }
    .package-quick-ship {
      position: absolute;
      .left(0);
      top: -17px;
    }
    .package-large-label {
      position: absolute;
      .left(0);
      top: -15px;
    }
    .order-table-combined {
      background: #fff;
    }
    .package-ctn {
      margin-bottom: 16/75rem;
      background-color: #fff;
      display: inline-block;
      .detail-goods {
        padding: 0.32rem;
        &:last-child {
          border-bottom: none;
        }
      }
      .operate-btn-banner {
        background-color: #fff;
        padding: 0 0.32rem;
        .operate-banner-bts {
          padding: 0.2666667rem 0;
          border-top: 1px solid #e5e5e5;
          button {
            color: #222;
            font-weight: 400;
            .font-dpr(26px);
            text-transform: capitalize;
            height: 0.64rem;
            line-height: 100%;
            & when (@IS_RW) {
              border-radius: 0.3rem;
              border-color: #ccc !important; /* stylelint-disable-line declaration-no-important */
            }
          }
        }
      }
    }
    .return-policy-tips {
      color: #767676;
      margin-top: 16/75rem;
      .font-dpr(20px);
      i {
        .font-dpr(24px);
        .padding-r(3px);
      }
      a {
        text-decoration: none;
        color: @sui_color_link;
      }
    }
    .list-title {
      color: #666;
      .font-dpr(28px);
      background: #fff;
      &.new-list-title {
        padding-bottom: 24/75rem;
      }
      .tip-spec-wrap {
        display: inline-block;
        position: relative;
        padding: 0.1rem;
        .icon-pro {
          font-size: 16px;
          vertical-align: middle;
        }
        .tip-spec {
          padding: 0.4rem;
          color: #999;
          border: 1px solid #e5e5e5;
          position: absolute;
          top: -2.4rem;
          left: -2.6rem;
          right: 0.4rem;
          background: #fff;
          width: 7rem;
          z-index: 2; /* stylelint-disable-line declaration-property-value-blacklist */
        }
      }
      .info {
        display: inline-flex;
        align-items: center;
        .font-dpr(24px);
        > span {
          &.order-status-txt {
            color: #333333;
            .font-dpr(24px);
            font-weight: bold;
          }
          &.order-status {
            width: 0.16rem;
            height: 0.16rem;
            display: inline-block;
            border-radius: 50%;
            .margin-r(0.1rem);
          }
          &.order-status-processing,
          &.order-status-returned,
          &.order-status-verified,
          &.order-status-refunded,
          &.order-status-shipped,
          &.order-status-paid,
          &.order-status-green,
          &.order-status.order-status-green {
            color: @sui_color_success;
          }
          &.order-status-canceled,
          &.order-status-expired,
          &.order-status-revoked,
          &.order-status-red,
          &.order-status.order-status-red {
            color: @sui_color_unusual;
          }
          &.order-status-waitting,
          &.order-status-verifty,
          &.order-status-blue,
          &.order-status.order-status-blue {
            color: @sui_color_link;
          }
          &.order-status-delivered,
          &.order-status-grey,
          &.order-status.order-status-grey {
            color: @sui_color_gray_light2;
          }

          &.order-status.order-status-green {
            background: @sui_color_success;
          }
          &.order-status.order-status-red {
            background: @sui_color_unusual;
          }
          &.order-status.order-status-blue {
            background: @sui_color_link;
          }
          &.order-status.order-status-grey {
            background: @sui_color_gray_light2;
          }
        }
      }
      .j-urging-btn {
        .font-dpr(24px);
        color: #999999;
        display: flex;
        align-items: center;
      }
      > em {
        color: #222;
        &.orders-track {
          font-weight: 400;
          .font-dpr(24px);
          color: #999999;
        }
        > i {
          .margin-l(0.2rem);
          color: #999999;
        }
      }
    }
    .detail-products-contain {
      padding: 24/75rem;
      background: #ffffff;
      border-bottom: 1px solid #e5e5e5;
      position: sticky;
      top: 0;
      z-index: @zindex-header;
      &.no-bottom-border {
        border-bottom: none;
        padding: 24/75rem 24/75rem 6/75rem 24/75rem;
      }
      .title {
        font-weight: bold;
        .font-dpr(28px);
        color: #333333;
        i {
          font-weight: 400;
          color: #999999;
        }
      }
      .order-product-sum-item {
        font-weight: 400;
        text-transform: lowercase;
      }
    }
    .go-to-record {
      color: #666;
      .font-dpr(24px);
      position: relative;
      top: 0;
      line-height: 1;
      .content {
        display: inline-block;
        max-width: 5rem;
        position: relative;
        top: 0.1rem;
      }
    }
  }
  .detail-oxxo-wrap{
    padding: .32rem;
    background: #fff;
  }
  .order-detail-footer {
    .report-disabled {
      background: #999 !important; /* stylelint-disable-line declaration-no-important */
      color: #bbb !important; /* stylelint-disable-line declaration-no-important */
      border: none !important; /* stylelint-disable-line declaration-no-important */
      & when (@IS_RW) {
        background: #fec5d4 !important; /* stylelint-disable-line declaration-no-important */
        color: #ffeded !important; /* stylelint-disable-line declaration-no-important */
      }
    }
    .comment-btn-box {
      position: relative;
      overflow: visible;
    }
    .comment-btn-op {
      position: absolute;
      bottom: 105%;
      .left(50%);
      transform: translateX(-50%);
      .points-tip-bottom {
        display: block;
        background: #ffffff;
        box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.1);
        padding: 0.1rem 0.2rem;
        color: #666;
        text-transform: capitalize;
        top: 0;
        .right(-0.12rem);
        white-space: nowrap;
        .zindex-translatez(8,translate3d(0,0,8px));
        .font-dpr(24px);
        border: 1px solid #fff;
        line-height: 1;
        [class*='iconfont'] {
          position: absolute;
          .font-dpr(24px);
          transform: scale(0.4);
          .right(-0.05rem);
          top: -0.05rem;
        }
        & when (@IS_RW) {
          top: -0.75rem;
          background: @sui_color_brand;
          border-color: transparent;
          border-radius: 0.053rem;
          height: 0.53rem;
          line-height: 100%;
          color: #ffffff;
          [class*='iconfont'] {
            top: -0.05rem;
          }
        }
        &.hide-points-tip {
          display: none;
        }
      }
      .points-tip-arrow {
        display: block;
        width: 0.3rem;
        height: 0.3rem;
        border-bottom: none;
        border-right: 0.2rem solid transparent;
        border-left: 0.2rem solid transparent;
        border-top: 0.2rem solid #fff;
        margin: 0 auto;
        .zindex-translatez(8, translate3d(0, 0, 8px));
        & when (@IS_RW) {
          border-top: 0.2rem solid @sui_color_brand;
        }
      }
    }
    .over-time,
    .opacity-item {
      border: none;
      background: #ccc !important; /* stylelint-disable-line declaration-no-important */
      color: #fbfbfb !important; /* stylelint-disable-line declaration-no-important */
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
  }
  .payment-aside {
    background-color: #f6f6f6 !important; /* stylelint-disable-line declaration-no-important */
  }
  .detail-container-flexbetween {
    .align-center();
    &.price-flex-start {
      align-items: flex-start;
    }
  }
  .detail-container-btn-sec {
    padding: 0 0.2rem !important; /* stylelint-disable-line declaration-no-important */
  }
}
.c-modal-address {
  .modal-content {
    width: 7.46667rem;
    .icon-item {
      [class*='iconfont'] {
        .font-dpr(120px);
        margin: -0.4rem auto 0rem;
      }
    }
    .tips-item {
      color: #666;
      a {
        color: #101010;
        text-decoration: underline;
      }
    }
  }
}
.order-ticketnum {
  position: relative;
  > i {
    .font-dpr(32px);
  }
  > span {
    position: absolute;
    top: -0.12rem;
    min-width: 0.22rem;
    height: 0.36rem;
    line-height: 0.36rem;
    margin: 0 0.2rem;
    text-align: center;
    padding: 0 0.07rem;
    box-sizing: content-box;
    .font-dpr(20px);
    .border-dpr(border,2px,#e5e5e5);
    border-radius: 0.58rem;
    color: #999;
    right: -0.55rem;
  }
}
.single-btn-modal {
  .self_exchange_title {
    & when (@IS_RW) {
      font-family: 'Adieu';
    }
  }
  .modal-content {
    width: 7.6rem;
    //min-height: 4.34rem;
    .modal-body {
      padding: 0.6rem 0.32rem 0.533rem;
      .font-dpr(28px);
      color: #333333;
      font-weight: 700;
      .split-package-tip {
        line-height: 34/75rem;
        .red {
          color: #e64545;
          font-weight: bold;
        }
      }
      .icon-container {
        text-align: center;
        color: @sui_color_main;
        i {
          .font-dpr(140px);
          font-weight: 400;
          display: inline;
        }
      }
    }
    .modal-footer {
      padding: 0 0.32rem 0.32rem;
      .font-dpr(28px);
      flex-direction: column;
      .modal-btn {
        flex: none;
        height: 0.96rem;
        line-height: 0.96rem;
        font-weight: bold;
      }
      .modal-btn-white {
        margin-top: 0.32rem;
        border: 1px solid #cccccc;
      }
    }
    .single-btn-modal-footer {
      .flexbox();
      .align-center();
      padding-bottom: 40/75rem;
      justify-content: space-around !important; /* stylelint-disable-line declaration-no-important */
      button {
        width: 241/75rem;
        margin-top: 0;
        height: 0.96rem;
        line-height: 0.96rem;
        &.unsubscript-btn {
          .font-dpr(28px);
        }
      }
      .btn-white {
        background: #fff;
        border: 1px solid #cccccc;
        color: @color_brand;
        & when (@IS_RW) {
          border-radius: 0.58667rem;
          border: 1px solid @color_brand;
        }
      }
    }
    .unsubscript-tip {
      .font-dpr(32px);
    }
  }
}
.new-order-detail {
  .du-list {
    align-items: flex-start;
    -webkit-box-align: start;
    .du-list-icon {
      margin-right: 15/75rem;
      width: 30/75rem;
      height: auto;
    }
  }
  .mt-6 {
    margin-top: 12/75rem;
  }
  .order-ticketnum {
    position: relative;
    > i {
      .font-dpr(32px);
    }
    > span {
      position: absolute;
      top: -0.08rem;
      min-width: 0.22rem;
      height: 0.36rem;
      line-height: 0.36rem;
      margin: 0 0.2rem;
      text-align: center;
      padding: 0 0.07rem;
      box-sizing: content-box;
      .font-dpr(20px);
      .border-dpr(border,2px,#e5e5e5);
      border-radius: 0.58rem;
      color: #999;
      right: -0.55rem;
    }
  }
}
.upload-identity-alert {
  margin-bottom: 20/75rem;
  padding: 25/75rem 30/75rem;
  &__content {
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
    .content-left {
      flex: 1 1 0;
      .content-left-header {
        .flexbox();
        align-items: flex-start;
        img {
          margin-right: 19/75rem;
          width: 30/75rem;
          height: auto;
        }
      }
      p {
        margin-left: 49/75rem;
      }
    }
    .content-right {
      // padding: 0 11px;
      color: #666;
      overflow: hidden;
    }
    .content-title {
      font-size: 14px;
      line-height: 1.2;
      text-transform: initial;
      margin-bottom: 7/75rem;
    }
    p {
      color: #767676;
      font-size: 12px;
      line-height: 1.2;
    }
  }
  &__warning {
    padding: 12/75rem;
    margin-top: 16/75rem;
    background-color: #f6f6f6;
    font-size: 10px;
    color: #666666;
    line-height: 1.2;
    display: flex;
    align-items: center;
    .content-left {
      padding-right: 10/75rem;
      color: #999;
      overflow: hidden;
    }
    .content-right {
      flex: 1 1 0;
      text-align: left;
    }
  }
}
.order-detail-header {
  a {
    text-decoration: none;
    color: @sui_color_gray_dark1;
  }
}
.c-tip-modal {
  i {
    .font-dpr(96px);
    font-weight: 400;
  }
  .suiiconfont {
    color: @sui_color_main;
  }
}
.font {
  font-family: Adieu-Regular, Adieu;
  font-weight: 400;
}
.package-tab-remind {
  position: absolute;
  display: inline-block;
  width: 12/75rem;
  height: 12/75rem;
  background: @sui_color_guide;
  border-radius: 50%;
  top: 0;
  .right(-8px);
}
.tip-icon-dialog {
  .S-dialog__body {
    padding-bottom: 0.59rem;
  }
}
.order-discount-reward {
  position: fixed;
  bottom: 1.17rem;
  left: 0;
  width: 100%;
}
.order-detail-bottom {
  margin-bottom: 160/75rem;
}
.percent-time {
  span {
    .font-dpr(24px);
    color: @sui_color_gray_dark1;
  }
  i {
    color: @sui_color_gray_light1;
  }
}
.time-container {
  width: fit-content;
  position: relative;
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  overflow: hidden;
}
.prescription-text {
  color: @sui_color_gray_dark3;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
.prescription-time__text {
  color: @sui_color_gray_dark3;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  .icon{
    @sui_color_gray_dark3;
  }
}
.time-text {
  color: #767676;
  .font-dpr(28px);
  line-height: 1.5em;
  max-height: 3em;
  &.expanded {
    max-height: none;
  }
}
.deatil-time__text{
  font-size: 12px;
}
.time-large__text {
  color: #767676;
  .font-dpr(28px);
  line-height: normal;
  max-height: 100/75rem;
  &.expanded {
    max-height: none;
  }
}
.over-large__text {
  max-width: 614/75rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.over-text {
  max-width: 614/75rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.icon-container {
  position: relative;
  width: 64/75rem;
  .handle-icon {
    position: absolute;
    bottom: 0;
    .right(0);
  }
}
.wp-qiwi-modal {
  .zindex-translatez(999, translate3d(0,0,999px));
  .c-qiwi-phone {
    .icon-close {
      position: absolute;
      right: 0.2rem;
      top: 0.5rem;
      .font-dpr(32px);
    }
    .title {
      color: #222;
      margin: 0.2rem 0;
      .font-dpr(24px);
    }
    .phone {
      .flexbox();
      border: 1px solid #ccc;
      height: 1.17rem;
      line-height: 1.17rem;
      margin-top: 0.4rem;
      .phone-bum {
        border-right: 1px solid #ccc;
        height: 100%;
        display: inline-block;
        width: 1.17rem;
        text-align: center;
        background: #ccc;
      }
      .phone_val {
        border: 0;
        margin: 0;
        height: 100%;
        font-weight: 700;
        flex: 1;
        .padding-l(0.1rem);
      }
    }
    .bank-title {
      color: #999;
      font-weight: 400;
      position: relative;
      top: -0.2rem;
    }
    .error-tip {
      color: #e64545;
      margin-top: 0.2rem;
      text-align: left;
    }
  }
}
.apple-pay-c {
  width: 2.04rem;
  margin: 0.48rem auto 0.52rem;
  height: 1.27rem;
}
.applepaybtn {
  background: #000;
  color: #fff;
  background-image: url(/pwa_dist/images/applepaybtn-70df1f479a.png);
  background-repeat: no-repeat;
  width: 5.1rem;
  height: 0.96rem;
  border: 0;
  background-position: center;
  background-size: 1.4rem;
  margin: 0 auto;
  display: block;
}
</style>
<style lang="less" scoped>
.network-error {
  position: sticky;
  top: 1.1733rem;
  z-index: @zindex-header;
}
</style>
