<script lang="jsx">
export default {
  name: 'ActiveIcon',
  functional: true,
  render(_, { props }) {
    const { color = '#000', cssRight = false, show = false } = props
    return (
      <div
        style={{
          display: show ? 'inline-flex' : 'none',
          position: 'absolute',
          top: '6px',
          right: 0,
        }}
        class={{
          'cloud-tag__icon-ar': cssRight
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M0 0H16V16L7.5 7.5L0 0Z"
            fill={color}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.3536 6.06066L13 7.70711L13.7071 7L12.0607 5.35355L13.7071 3.70711L13 3L11.3536 4.64645L9.70711 3L9 3.70711L10.6464 5.35355L9 7L9.70711 7.70711L11.3536 6.06066Z"
            fill="white"
          />
        </svg>
      </div>
    )
  }
}
</script>

<style scoped lang="less">
.cloud-tag {
  &__icon-ar {
    transform: rotate(-270deg);
  }
}
</style>
