<template>
  <div
    v-expose="{ id: 'expose_s3_vipprice.comp_quick-add-cart-standard', data: { from: 1 } }"
    class="productS3TagNew">
    <img src="https://img.ltwebstatic.com/images3_ccc/2024/08/28/6a/172484636141edc189bbd178dea02113e2ee1d0b32.webp" />
  </div>
</template>

<script setup name="S3TagNew">
import { inject } from 'vue'
const { vExpose } = inject('analysisInstance')

</script>

<style lang="less">
.productS3TagNew {
  display: flex;
  align-self: flex-end;
  width: .64rem;
  & > img {
    width: 100%;
  }
}
</style>
