<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-lowStockLabel"
    :style="style"
  >
    <div
      ref="iconAnimationRef"
      class="bsc-cart-item-lowStockLabel__icon"
    >
<!--       <img
        v-if="icon"
        :src="icon"
        alt=""
        class="bsc-cart-item-lowStockLabel__icon-img"
      /> -->
    </div>
    <div class="bsc-cart-item-lowStockLabel__text">
      {{ text }}
    </div>
  </div>
</template>
<script setup name="LowStockLabel" lang="ts">
import { ref, computed, onMounted, onUnmounted, watch, nextTick, defineExpose } from 'vue'
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'

const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
// import lotteryJsonData from './HourGlassOrange.json'

interface IProps {
  /**
   * 类型
   */
  type: string
  /**
   * 是否显示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 静态icon
   */
  icon: string
  /**
   * 标签文案
   */
  text: string
  /**
   * 拼接文案
   */
  subText: string
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 文字颜色
   */
  textColor: string
  /**
   * 变化时重新播放动画
   */
  replayTrigger: number
}

const props = withDefaults(defineProps<IProps>(), {
  type: '',
  isShow: false,
  isShowMask: false,
  text: '',
  icon: '',
  bgColor: '',
  textColor: '',
  subText: '',
  replayTrigger: 0,
})

const style = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
    '--bg-color': props.bgColor,
    '--text-color': props.textColor,
  }
})

const lottieInstance = ref(null)
const iconAnimationRef = ref(null)
function initAnimation(lottieWebModule, path) {
  nextTick(() => {
    const params = {
      container: iconAnimationRef.value,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path,
      // path: `${PUBLIC_CDN}/pwa_dist/vap/hourGlass.json`,
      // path: 'https://shein.ltwebstatic.com/appjson/2024/12/16/17343552483000151096.json',
      // animationData,
    }
    lottieInstance.value = lottieWebModule?.loadAnimation(params)
  })
}

const isExposed = ref(false)
function handleExpose(params) {
  if(params.isVisible && !params.hasBeenVisible) {
    isExposed.value = true // 首次曝光触发
  }
}
useIntersectionObserver(iconAnimationRef, handleExpose, {
  root: null, // 默认值：视口
  rootMargin: '0px',
  threshold: 0.1 // 目标元素 10% 可见时触发回调
})
const isLoadResource = ref(false)

onMounted(async () => {
  const [ lottieWeb ] = await Promise.all([
    import('lottie-web'),
    nextTick(),
  ]).catch((err) => {
    console.log(err)
  })
  initAnimation(lottieWeb?.default, props.icon)
  isLoadResource.value = true
})

watch(() => isExposed.value && isLoadResource.value, (val) => {
  if (val) {
    lottieInstance.value?.stop()
    lottieInstance.value?.play()
  }
})

watch(() => props.replayTrigger, (val) => {
  if (val) {
    lottieInstance.value?.stop()
    lottieInstance.value?.play()
  }
})

onUnmounted(() => {
  lottieInstance.value?.destroy()
})

defineExpose({
  isExposed,
  isLoadResource,
  // lotteryJsonData,
  iconAnimationRef,
  lottieInstance,
})

</script>
<style lang="less">
.bsc-cart-item-lowStockLabel {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  line-height: normal;
  color: var(--text-color);
  background: var(--bg-color);
  opacity: var(--mask-opacity);
  &__text {
    font-size: 10px;
    font-weight: 700;
  }
  &__icon {
    width: 24/75rem;
    height: 24/75rem;
    margin-right: 4/75rem;
  }
}
</style>
