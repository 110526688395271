import { transfromAndCutImg } from 'public/src/services/resource/index'
import { ref } from 'vue'

/**
 * 图片裁剪函数
 * 默认设计稿宽度750，裁切到240，最大显示240
 * @param {Object} locals ssr直出需要提供
 * @returns {Object} cutImg 图片裁剪函数ssr直出使用
 * @returns {Object} dataDesignWidth 默认750  图片懒加载使用
 * @returns {Object} dataExp 默认'cutTo=240#max=240' 图片懒加载使用
 */
export function useCutImg(locals) {
  const dataDesignWidth = ref(750)
  const dataExp = ref('cutTo=240#max=240')
  const { LAZY_IMG } = locals || gbCommonInfo || {}
  const cutImg = (imgUrl, designWidth = 750, exp = { cutTo: 240, max: 240 }) => {
    const { RESOURCE_SDK = {} } = locals || gbCommonInfo || {}
    const { deviceData = '', isSupportWeb = '', isSupprotCut = false, sceneMap = {} } = RESOURCE_SDK || {}

    const cutData = {
      deviceData,
      isSupportWebp: Boolean(isSupportWeb),
      isSupprotCut,
      imgUrl,
      designWidth: Number(designWidth),
      sceneMap,
      exp,
    }

    return transfromAndCutImg(cutData)
  }

  return {
    cutImg,
    dataDesignWidth,
    dataExp,
    LAZY_IMG
  }
}
