import type { DS_SearchBox } from '../../../types'

export const getKeywordsWithBff: DS_SearchBox.APIS_THIS['getKeywordsWithBff'] = async function (params: any, httpConfig: any) {
  const config = {
    url: '/product/search/v3/get_keywords',
    method: httpConfig?.method || 'GET',
    params,
    ...(httpConfig?.method === 'POST' ? null : {headers: {'Content-Type': 'multipart/form-data'}}),
    featureKey: httpConfig?.featureKey || '',
  }
  const result = await this.$schttp(config)

  return result.data
}

export const getAssociationWordsWithBff: DS_SearchBox.APIS_THIS['getAssociationWordsWithBff'] = async function (params: any) {
  const result = await this.$schttp<any>({
    url: '/product/association_words',
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return result.data
}
