import schttp from 'public/src/services/schttp'
import { template, cloneDeep } from '@shein/common-function'
import { createComparionFun } from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/ShippingEnter/components/ShippingMainDrawer/util.js'

export default {
  namespaced: true,
  state: {
    shippingDetail: {},
  },
  getters: {
    language(state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/common/language_v2']
    },
    mallCode (state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/common/mall_code']
    },
    quickShipContent(state, getters, rootState, rootGetters){
      return rootGetters['productDetail/ShippingEnter/quick_ship_content']
    },
    quickShipType(state, getters, rootState, rootGetters){
      return rootGetters['productDetail/ShippingEnter/quick_ship_type']
    },
    quickShipDate(state, getters, rootState, rootGetters){
      return rootGetters['productDetail/ShippingEnter/quick_ship_date']
    },
    skuList(state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/common/sku_list']
    },
    skuInfo(statem, getters, rootState, rootGetters) {
      return rootGetters['productDetail/common/sku_info']
    },
    // quickship强化实验 start
    quickshipStrongAbt(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const showQuick = fsAbt?.DetailPageQuickShipShow?.param == 'DetailShowQuick'
      const realTimeReady = rootGetters['productDetail/common/real_time_ready']
      if(!showQuick || !realTimeReady) {
        return {
          beltQuickshipStyle: false,
          beltNDeliveryStyle: false,
          floorQuickshipStyle: false,
          floorNDeliveryStyle: false,
          showBuynowquickship: false,
          showQuickshipshowloc: false,
          floorNDeliveryNumber: '',
          beltNDeliveryNumber: ''
        }
      }
      let { Quickshipdetailbannershow = '', Quickshipdetailfloorshow = '', Quickshipshowloc = '', Buynowquickship = '' } = fsAbt?.quickshipdetailshow?.p || {}
      // 初始化实验值  腰带N达具体值||腰带强化qs实验命中结果 和独立qs楼层互斥||腰带N达实验命中结果 和独立qs楼层互斥||腰带强化qs实验命中结果 和独立qs楼层共存||腰带N达实验命中结果 和独立qs楼层共存
      let beltNDeliveryNumber = 0, beltQuickshipInde = false, beltQuickshipNindex = false, beltQuickshipCoex = false, beltQuickshipNCoex = false
      // 实验结果提取
      beltQuickshipInde = Quickshipdetailbannershow === 'quickshipinde'
      beltQuickshipCoex = Quickshipdetailbannershow === 'quickshipcoex'
      const beltQuickshipIndeArr = Quickshipdetailbannershow.split('_index')
      if(beltQuickshipIndeArr.length > 1) {
        beltQuickshipNindex = true
        beltNDeliveryNumber = +(beltQuickshipIndeArr[0])
      }
      const beltQuickshipcoexArr = Quickshipdetailbannershow.split('_coex')
      if(beltQuickshipcoexArr.length > 1) {
        beltQuickshipNCoex = true
        beltNDeliveryNumber = +(beltQuickshipcoexArr[0])
      }
      const quickShipContent = getters.quickShipContent
      const floorQuickshipStyle = Quickshipdetailfloorshow === 'quickship' // 物流quickship强化样式
      const floorNDeliveryStyle = +(Quickshipdetailfloorshow) > 0 // 腰带n天达
      const showQuickshipshowloc = Quickshipshowloc === 'Independfloorslight' // 展示独立qs楼层
      const showBuynowquickship = Buynowquickship === 'Show'
      if(quickShipContent) {
        return {
          beltQuickshipStyle: beltQuickshipInde ||  beltQuickshipCoex,
          beltNDeliveryStyle: beltQuickshipNindex || beltQuickshipNCoex,
          floorQuickshipStyle,
          floorNDeliveryStyle,
          showBuynowquickship,
          showQuickshipshowloc,
          hideBeltQuickship: (beltQuickshipInde || beltQuickshipNindex) && showQuickshipshowloc, // 命中与qs独立楼层 互斥
          floorNDeliveryNumber: quickShipContent,
          beltNDeliveryNumber: quickShipContent,
          isNDayDelivery: getters.quickShipType == 2
        }
      }
      return {
        beltQuickshipStyle: beltQuickshipInde ||  beltQuickshipCoex, // 腰带 qs强化最终结果
        beltNDeliveryStyle: beltQuickshipNindex || beltQuickshipNCoex, // 腰带 N达最终结果
        hideBeltQuickship: (beltQuickshipInde || beltQuickshipNindex) && showQuickshipshowloc, // 命中与qs独立楼层 互斥
        // 0730简易需求：识别Quickshipdetailbannershow 改成识别Quickshipdetailfloorshow
        // beltNDeliveryNumber,
        beltNDeliveryNumber: +(Quickshipdetailfloorshow) ? +(Quickshipdetailfloorshow) : 0, // 腰带 N达 最终具体值
        floorQuickshipStyle, // 物流楼层qs 实验命中结果
        floorNDeliveryStyle, // 物流楼层 N达 实验命中结果
        floorNDeliveryNumber: +(Quickshipdetailfloorshow) ? +(Quickshipdetailfloorshow) : 0, // 物流楼层 N达 具体值
        showQuickshipshowloc, // 展示独立qs楼层 实验命中结果
        showBuynowquickship // 展示立即购买 实验命中结果
      }
    },
    qsTimelinessText(state, getters) {
      const { quickShipContent } = getters
      if(quickShipContent){
        return quickShipContent
      }
    },
    // 选中sku的状态信息
    currentSkuStatus(state, getters) {
      const skuInfo = getters.skuInfo || {}
      const skuCode = skuInfo?.sku_code
      return {
        hasSelectedSku: !!skuCode, // 是否选中sku
        hasSelectedSkuSurpportQuickship: skuInfo?.isSupportQuickShip == 1,  // 选中的sku是否支持quickship
        skuLargeShip: skuInfo?.largeShip || 0 // 选中的sku是否支持海陆运
      }
    },
    isSupportQuickship(state, getters, rootState, rootGetters) {
      const { qsTimelinessText, currentSkuStatus, isJpQuickShip } = getters
      const { hasSelectedSku, hasSelectedSkuSurpportQuickship } = currentSkuStatus
      const skuList = getters.skuList || []
      // 部分sku支持quickship
      const isSomeSupportQuickshipBase = skuList.some(sku => sku?.isSupportQuickShip == '1')
      // 部分sku支持quickship 同时物流接口要返回qs时效
      const isSomeSupportQuickship = isSomeSupportQuickshipBase && !!qsTimelinessText
      // 所有sku支持quickship
      const isAllSupportQuickship = skuList.length ? skuList.every(sku => sku?.isSupportQuickShip == '1') : false
      // 当前选中的sku的quickship状态
      const getDynamicQuickship = rootGetters['newProductDetail/common/getDynamicQuickship']
      // 精确到sku是否支持quickship
      const supportQuickshipSence = !!qsTimelinessText && (isAllSupportQuickship || hasSelectedSkuSurpportQuickship || (isSomeSupportQuickship && !hasSelectedSku))
      return {
        isSomeSupportQuickshipBase: isSomeSupportQuickshipBase || isJpQuickShip,
        isSomeSupportQuickship: isSomeSupportQuickship || isJpQuickShip,
        isAllSupportQuickship: isAllSupportQuickship || isJpQuickShip,
        hasSelectedSku,
        hasSelectedSkuSurpportQuickship: hasSelectedSkuSurpportQuickship || isJpQuickShip,
        supportQuickshipSence: supportQuickshipSence || isJpQuickShip,
        hasPartSupportQuickship: (isSomeSupportQuickship && !isAllSupportQuickship) || isJpQuickShip, // 此字段满足首次落地 skc下的qs状态不一样，并且未选中sku的业务场景判断
        currentQuickship: getDynamicQuickship == 1  // 这个字段首次落地就是1 不能满足 skc下的qs状态不一样， 并且未选中sku的业务场景判断
      }
    },
    isJpQuickShip(state, getters){
      const { quickShipContent, quickshipStrongAbt } = getters
      const { SiteUID } = gbCommonInfo
      const { floorNDeliveryStyle, beltNDeliveryStyle } = quickshipStrongAbt 
      return !!(SiteUID === 'mjp' && (floorNDeliveryStyle || beltNDeliveryStyle) && quickShipContent)
    },
    // 物流弹窗 quickship强化 N达文案 N命中状态
    deliveryDialogNInfo(state, getters) {
      const { language, quickshipStrongAbt, isSupportQuickship } = getters
      const { isSomeSupportQuickship } = isSupportQuickship
      const { floorNDeliveryStyle, floorNDeliveryNumber } = quickshipStrongAbt // 物流楼层quickship强化样式 || 物流楼层n天达
      const { SHEIN_KEY_PWA_30599, SHEIN_KEY_PWA_30600, SHEIN_KEY_PWA_30897, SHEIN_KEY_PWA_31681 } = language
      const quickShipContent = getters.quickShipContent
      // 这里前端不再判断abt，直接读quickShipContent
      if(quickShipContent) return {
        deliveryDialogNTitle: quickShipContent,
        deliveryDialogNTips: template(`<span style="color:#198055">${quickShipContent}</span>`, SHEIN_KEY_PWA_31681)
      } 
      let deliveryDialogNTitle = SHEIN_KEY_PWA_30599
      let deliveryDialogNTips = template(`<span style="color:#198055">${SHEIN_KEY_PWA_30599}</span>`, SHEIN_KEY_PWA_31681) // 默认qs tips的文案
      if(isSomeSupportQuickship && floorNDeliveryStyle) { // 是否命中n天达 也要有qs时效
        deliveryDialogNTitle = template(floorNDeliveryNumber, SHEIN_KEY_PWA_30600 || '{0} Days Delivery')
        deliveryDialogNTips = template(`<span style="color:#198055">${deliveryDialogNTitle}</span>`, SHEIN_KEY_PWA_30897 || 'This item is eligible for {0}')
      }
      return {
        deliveryDialogNTitle,
        deliveryDialogNTips, 
      }
    },
    // 按照最低运费排序物流方式
    lowestPricesShippingMethods (state, getters) {
      let shippingMethods = getters.shippingDetail?.shippingMethods || []
      let result = JSON.parse(JSON.stringify(shippingMethods))
      result.forEach(item => {
        if (item.starting_price_obj && item.last_price_obj) {
          item.lowestShippingPrices = Number(item.starting_price_obj.amount) > Number(item.last_price_obj.amount) ? item.last_price_obj : item.starting_price_obj
        }
      })
      /* 
        待解决TODO：
        1. 外露使用shippingMethods，物流详情使用shippingPolicy，但是他们俩的排序方式有差异，需要统一
        2. 如果存在多个运费相同的，app获取时效最快的，但是这里没有做处理，需要统一
      */
      result.sort(createComparionFun('lowestShippingPrices', 'amount'))
      return result
    },
    shippingAbtState (state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const { deliveryDialogNInfo = {}, shippingDetail = {} } = getters
      const { deliveryDialogNTips } = deliveryDialogNInfo
      const {
        localwarehouse,
        moresellerinfo,
        DetailPageQuickShipShow,
        ShippingLogisticsTime,
        ReportItem,
        detailshowsellerinfo,
        obligationsseller,
        Deliverytooptimize,
        shipping,
        Environprotect,
        freeinquire,
        allshiptimeshow,
        Deletemess,
        Guarantee
      } = fsAbt
      const all_ship_time_show_map = shippingDetail.all_ship_time_show_map || {}
      const mallCode = getters.mallCode
      const showSellerIcon = moresellerinfo?.p?.moresellerinfo === 'show'
      const showQuickShip = DetailPageQuickShipShow?.param == 'DetailShowQuick'
      const showLocalwarehouse = localwarehouse?.param?.localwarehouse == 'show'
      const showShippingDayPercent = ShippingLogisticsTime?.param?.LogisticsTime == 'B'
      const showSellerInfo = detailshowsellerinfo?.param?.detailshowsellerinfo == 'show'
      const showCostTopTime = Deliverytooptimize?.param?.Deliverytooptimize == 'optimize'
      const showAllShipTimeShow = allshiptimeshow?.param?.allshiptimeshow === 'B' && all_ship_time_show_map[mallCode] === 1
      const showTransportaop = shipping?.p === 'Transportaop'
      const showFreeinQuire = freeinquire?.p?.freeinquire === 'new'
      const showEnvironprotect = Environprotect?.p?.Environprotect === 'new'
      const showDeletemess =  Deletemess?.p?.Deletemess === 'show'
      const showObligationsseller =  obligationsseller?.p?.obligationsseller === 'show'
      let showReport = false
      let { Reportshow, limitstore } = ReportItem?.p || {}
      if (Reportshow === 'show') {
        const { storeType } = rootGetters['productDetail/common/store_info'] || {}
        showReport = limitstore === 'no' || (limitstore === 'yes' && storeType == 1)
      }
      const showReportCall = ReportItem?.p?.call === 'yes'
      const showShippingGuarantee = Guarantee?.p?.Guarantee === 'show'
      let result = {
        showLocalwarehouse,
        showQuickShip,
        showShippingDayPercent,
        showReport,
        showSellerIcon,
        showSellerInfo,
        showReportCall,
        showCostTopTime,
        showAllShipTimeShow,
        showObligationsseller,
        showFreeinQuire,
        shippingAbt: shipping,
        showTransportaop,
        showEnvironprotect,
        showDeletemess,
        deliveryDialogNTips,
        showShippingGuarantee
      }
      result['3Psellerdelivery'] = fsAbt['3Psellerdelivery'] || {} // 兜底
      return result
    },
    shippingDetail(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const { currentSkuStatus } = getters
      const {  hasSelectedSkuSurpportQuickship, skuLargeShip } = currentSkuStatus
      const hasQuickShip = fsAbt?.DetailPageQuickShipShow?.param == 'DetailShowQuick' && hasSelectedSkuSurpportQuickship
      const res =  state.shippingDetail
      const { aging_extends } = res || {}
      if(!hasQuickShip && skuLargeShip && aging_extends?.length){
        let data = res.shippingMethods?.filter(item => item.transport_type === 'standard_express')
        let shippingMethods = cloneDeep(data)
        shippingMethods = shippingMethods.length ? shippingMethods : [res.shippingMethods?.[0]]
        if(shippingMethods[0]) {
          shippingMethods[0].desc_type = aging_extends[0].time_type === 2 ? 2 : 0
          shippingMethods[0].transport_interval = aging_extends[0].transport_time
          shippingMethods[0].expect_time = aging_extends[0].transport_time
          shippingMethods[0] = {
            ...shippingMethods[0],
            ...aging_extends[0]
          }
          shippingMethods[0].delivery_day_percent_desc = aging_extends[0].day_percent_desc
          shippingMethods[0].date_type = aging_extends[0].date_type
          shippingMethods[0].aging_date = aging_extends[0].aging_date
        } 
        return {
          ...res,
          largeShipStatus: skuLargeShip, // 0或者空不支持，1-海运，2-陆运
          shippingMethods: shippingMethods
        }
      }
      return {
        ...res,
        largeShipStatus: 0
      }
    },
  },
  mutations: {
    setShippingDetail (state, payload) {
      state.shippingDetail = payload
    },
  },
  actions: {
    async updateShippingDetail ({ commit, rootGetters }, params) {
      let store_info = rootGetters['productDetail/common/store_info']
      const goodsFreeShippingInfoRes = await schttp({
        method: 'GET',
        url: '/api/productInfo/goodsFreeShippingInfo/get',
        params: {
          business_model: store_info.business_model || '',
          store_code: store_info.store_code,
          cat_id: rootGetters['productDetail/common/cat_id'],
          goods_sn: rootGetters['productDetail/common/goods_sn'],
          country_id: rootGetters['productDetail/ShippingEnter/common_info'].countryId,
          mallCode: rootGetters['productDetail/common/mall_code'],
        },
      })
      let { rule_id: productFreeId, join_transport_types: joinTransportTypes } = goodsFreeShippingInfoRes || {}
      const data = await schttp({
        method: 'GET',
        url: '/api/productInfo/shippingDetail/get',
        params: { ...params, productFreeId, joinTransportTypes },
      })
      commit('setShippingDetail', { ...data, params })
      return data
    }
  }
}
