<template>
  <div>
    <slot :timer="timer"></slot>
  </div>
</template>

<script name="CountDown" setup lang="ts">
import { watch, ref } from 'vue'
import { CountDown } from '@shein/common-function'

const emits = defineEmits(['finish'])

const props = defineProps({
  endTimeStamp: {
    type: [Number, String],
    default: 0,
  },
})

const timer = ref(new CountDown())

watch(
  () => props.endTimeStamp,
  () => {
    const endTime = props.endTimeStamp.toString().length < 13 ? +props.endTimeStamp * 1000 : +props.endTimeStamp
    timer.value.start({
      seconds: Math.floor((endTime - Date.now()) / 1000),
      endFunc: () => {
        emits('finish')
        timer.value.clear()
      },
    })
  },
  { immediate: true },
)
</script>
