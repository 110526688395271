import { getCartNumBySkc } from '@/public/src/pages/product_app/util/getCartNum.js'
import { getFeedbackRecInSession } from '@/public/src/pages/product_list_v2/js/utils'

const handleListCartAbt = (vm) => {
  const addedCartAbtValue = vm.listAbtResult.SearchAddedCart?.p?.SearchAddedCart || ''
  const added = addedCartAbtValue.includes('Added') ? 'Added' : ''
  const checkout = addedCartAbtValue.includes('CheckOut') ? 'CheckOut' : ''

  // mock abt
  // return ['Added', 'CheckOut']
  return [added, checkout].filter(Boolean)
}

const setAddCartTypeAna = (activedGoodIndex, checkoutAbt, addedAbt) => {
  this.$refs?.ProductList?.setAddCartType(
    activedGoodIndex, 
    checkoutAbt 
      ? 'add_cart_type_checkout' 
      : addedAbt 
        ? 'add_cart_type_added' 
        : 'add_cart_type_add'
  )
}



// 处理加车按钮逻辑
const handleCheckoutBtn = async (vm, { goods_sn, index, addSuccess }) => {
  const addedAbt = handleListCartAbt(vm).includes('Added') 
  const checkoutAbt = handleListCartAbt(vm).includes('CheckOut') 
  if (!addedAbt && !checkoutAbt) return

  let checkoutedInfo = await getCartNumBySkc([ goods_sn ], { updateCartData: addSuccess }) || {}
  if (checkoutedInfo[goods_sn]) {
    const showCheckout = checkoutAbt && vm.cartInfo?.isFreeShipping
    vm.setGoodsCheckoutNum({ checkoutedInfo, showCheckout, showCheckoutNum: addedAbt })
    // 埋点
    setAddCartTypeAna(index, checkoutAbt, addedAbt)
  }
}

// 检查商品是否已经加车
const checkoutGoodsInfo = async (vm) => {
  const addedAbt = handleListCartAbt(vm).includes('Added') 
  if (!addedAbt) return

  const skcList = vm.goods.map(item => item.goods_sn)
  const checkoutedInfo = await getCartNumBySkc(skcList) || {}

  vm.setGoodsCheckoutNum({ checkoutedInfo, showCheckoutNum: addedAbt })
}

const handleChekcoutBtnBackFromDetail = async (vm, { isBackFromDetail }) => {
  const addedAbt = handleListCartAbt(vm).includes('Added') 
  const checkoutAbt = handleListCartAbt(vm).includes('CheckOut') 
  if (!addedAbt && !checkoutAbt) return

  if (isBackFromDetail) {
    try {
      const detailAddCartInfo = getFeedbackRecInSession()
      if (detailAddCartInfo.goodsId) {
        let activedGoodIndex = 0
        const hitGoods = vm.goods.map((item, index) => {
          if (String(item.goods_id) === String(detailAddCartInfo.goodsId)) {
            activedGoodIndex = index
            return item
          }
        }).filter(Boolean)

        const skcList = hitGoods.map(item => item.goods_sn)

        const checkoutedInfo = await getCartNumBySkc(skcList) || {}

        const addedStatus = hitGoods?.[0]?.checkoutNum || (!!detailAddCartInfo.addBagStatus)
        const showCheckout = checkoutAbt && vm.cartInfo?.isFreeShipping && addedStatus
        vm.setGoodsCheckoutNum({ checkoutedInfo, showCheckout: !!showCheckout, showCheckoutNum: addedAbt })

        if (skcList[0] && checkoutedInfo[skcList[0]]) {
          // 埋点
          setAddCartTypeAna(activedGoodIndex, checkoutAbt, addedAbt)
        }
      }
    } catch (error) {
      console.log('handleChekcoutBtnBackFromDetail:', error)
    }
  }
}

export {
  handleCheckoutBtn,
  checkoutGoodsInfo,
  handleChekcoutBtnBackFromDetail
}
