/* eslint-disable require-atomic-updates */
import { getQueryString } from '@shein/common-function'
import { updatePageData, onSubscribeListExpose } from './useAnalysis'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index.js'
import { bffHypernymGoods } from '@/public/src/pages/product_list_v2/js/bffHypernymGoods.js'
import { requestProductListApi } from '@/public/src/pre_requests/modules/productList/index.js'
import { dealProductsPretreatInfo } from '@/public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import { getListPageInfo } from '@/public/src/pages/product_list_v2/js/utils.js'

// 上位词查询商品Info
const hypernymProductInfo = {
  list: [],
  page: 1,
  limit: 20,
  sum: null, // null:默认 number: 上位词请求回来后的总数
  keywords: '',
  loading: '',
  noMoreData: false,
  request_ext: {},
  emptyLang: '',
  noEmptyLang: '',
  useBffApi: false,
}

const initHypernymProductInfo = (vm) => {
  vm.hypernymProductInfo = {
    list: [],
    page: 1,
    limit: 20,
    sum: null, // null:默认 number: 上位词请求回来后的总数
    loading: '',
    noMoreData: false,
  }
}

// 处理上位词
const handleHypernymFetch = (vm, { newData }) => {
  if (vm.isFirstLoad) {
    vm.hypernymProductInfo.keywords = newData.searchKeywords?.origin_words
  }
  // v.趋势搜索结果页屏蔽该功能
  if (
    vm.hypernymProductInfo.sum === 0 || 
    vm.catInfo.type !== 'search' || 
    ['store', 'brand', 'pageTrend'].includes(vm.catInfo.search_type)
  ) {
    // 这里表示firstload的时候用searchword请求结果为0，因此不再重复请求
    // 非搜索结果页面不去获取上位词商品
    vm.$set(vm.hypernymProductInfo, 'noMoreData', true)
    return
  }
  const { page, limit } = vm.Request.query
  const lastPaged = parseInt(page) >= Math.ceil(newData.sum / limit)
  if (!lastPaged || !vm.hypernymProductInfo.keywords || newData.sum > 200) {
    vm.$set(vm.hypernymProductInfo, 'noMoreData', true)
    return
  }
  fetchHypernyData(vm)
}

// 请求上位词数据
const fetchHypernyData = async (vm, { requestType } = {}) => {
  if (requestType === 'nextpage') {
    vm.hypernymProductInfo.page = parseInt(vm.hypernymProductInfo.page) + 1
  }

  const { limit, keywords, page, list } = vm.hypernymProductInfo
  let hPKeywords = keywords
  if (!hPKeywords) return
  vm.hypernymProductInfo.loading = 'nextpage'
  const useBffApi = isSwitchBffApiVersion(['hypernymProduct_v1'])?.hypernymProduct_v1
  try {
    let goods = []
    let searchResultInterfaceCode = ''
    let request_ext = null
    let hypernymProduct = null
    if (useBffApi) {
      const { hypernymProduct: hypernymProductData, request_ext: reqExt, buriedPoint, products } = await bffHypernymGoods({
        toRoute: vm.$route,
        query: vm.hypernymProductInfo,
        catInfo: vm.catInfo,
        language: vm.language,
        goods: vm.goods,
        lang: vm.lang,
        sheinClubInfo: vm.sheinClubInfo
      })
      goods = products || []
      searchResultInterfaceCode = buriedPoint || 1
      request_ext = reqExt
      hypernymProduct = Object.assign(hypernymProductData, { useBffApi: true })

      vm.updateSpecificAttr({
        key: 'request_ext',
        value: {
          ...reqExt,
          ...vm.request_ext
        }
      })
    } else {
      const { 
        goods: products, 
        hypernymProduct: hypernymProductData = {}, 
        request_ext: reqExt, 
        searchResultInterfaceCode: buriedPoint, 
        promotionInfoFromServer 
      }  = await requestProductListApi({ 
        requestInfo: {
          type: 'nextpage',
          query: {
            isHypernymGoods: true,
            page,
            limit,
            hPKeywords,         
            market_code: getQueryString({ key: 'market_code' }),
          },
        }, 
        newSrcTabPageId: vm.newSrcTabPageId, 
        toRoute: undefined, 
        useNodeApi: true 
      })
      searchResultInterfaceCode = buriedPoint
      request_ext = reqExt
      hypernymProduct = Object.assign(hypernymProductData, { useBffApi: false })
      goods = dealProductsPretreatInfo({
        products: products || [],
        itemConfig: vm.ComponentState.ProductList,
        promotionInfoFromServer,
        language: vm.language,
      })
    }
    // 更新埋点数据
    updatePageData(vm, { 
      search_rec_request_status: searchResultInterfaceCode || '4_1001'
    })
    hypernymProduct.sum = +hypernymProduct.sum
    Object.assign(vm.hypernymProductInfo, hypernymProduct, { list: [...list, ...goods] })
    if(hypernymProduct.page == 1) {
      updatePageData(vm, { 
        query_infor: getRequestExtForAna(vm.request_ext, request_ext),
        rec_result_count: getHypernymProductSumForAna(vm)
      })
    }

    await vm.$nextTick()

    if (requestType === 'nextpage') {
      initHypernymListExpose(vm)
    }

  } finally {
    vm.hypernymProductInfo.loading = ''
    vm.showPageRenderDone = true
  }
}

const initHypernymListExpose = (vm) => {
  const subscribeFn = () => {
    vm.$refs.HypernymProductWrap?.setAnalysisData()
    const { config } = vm.ComponentState.ProductList || {}
    onSubscribeListExpose(vm, config.itemDAEventExposeId)
  }
  if (vm.ListAnalytics) {
    subscribeFn()
  } else {
    vm.$once('ListAnalyticsReady', () => {
      subscribeFn()
    })
  }
}

const getRequestExtForAna = (mainProductsRequestExt, hypernymRequestExt) => {
  const requestExt = Object.assign({}, hypernymRequestExt || {}, mainProductsRequestExt || {})
  return Object.entries(requestExt)
    .map(([key, value]) => `${key}_${value ?? ''}`)
    .join('|')
}

const getHypernymProductSumForAna = (vm) => {
  const { isSearch } = getListPageInfo(vm.catInfo)
  return isSearch ? vm.hypernymProductInfo.sum : {}
}

export {
  hypernymProductInfo,
  initHypernymProductInfo,
  handleHypernymFetch,
  fetchHypernyData,
  initHypernymListExpose
}
