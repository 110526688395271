import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { isEqual } from '@/public/src/pages/checkout_new/utils'

/**
 * @description 商品属性hooks
 * */
export default function useAttribute(data = {}) {
  // --------- useMap_**** --------
  const { checkout } = useMapState(['checkout'])
  const { outStockCarts, cartList } = checkout.value

  // 无库存
  const isOutStock = computed(() => {
    return outStockCarts?.some(item => item.id === data?.id)
  })

  // 海陆运大件
  const seaLandBelt = computed(() => {
    const belt = cartList?.find(item => item.cartId === data?.id)
    const isShow = !!belt?.outsideBeltTip
    return {
      visible: isShow,
      data: isShow ? belt : {},
    }
  })

  // 付费会员赠品
  const isClubGift = computed(() => {
    const memberVal = 'payMemberGift'
    return isEqual(data?.aggregateProductBusiness?.productType, memberVal)
  })

  // 赠品
  const isGift = computed(() => {
    const giveawayVal = '1'
    const couponValue = 1000
    return (
      isEqual(data?.aggregateProductBusiness?.isPresent, giveawayVal) ||
      isEqual(data?.promotion_type_id, couponValue)
    )
  })

  // 附属品
  const isAdditionGood = computed(() => {
    const accessoryVal = '1'
    return isEqual(
      data?.aggregateProductBusiness?.isAdditionGoods,
      accessoryVal,
    )
  })
  return { isOutStock, seaLandBelt, isClubGift, isGift, isAdditionGood }
}
