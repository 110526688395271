export const BENEFIT_BENEFI_MAP = {
  inventory: 'inventory',
  countdown: 'countdown',
  sameitemprice: 'sameitemprice',
  historyprice: 'historyprice',
  normaldiscount: 'normaldiscount',
}

/**
 * @description 打开快速加车场景
 */
export const OPEN_QUICK_ADD_TYPE = {
  NORMAL: 'NORMAL', // (default) 主按钮 - 主按钮等同
  BUY_NOW: 'BUY_NOW', // 立即购买唤起
  ATTRS_FOLD: 'ATTRS_FOLD', // 折叠销售属性
  BUY_MULTIPLE: 'BUY_MULTIPLE', // N 件到手价，点击到手价，或promotion 弹窗重的 buy N ，主按钮不算
  LARGE_IMAGE_SWITCH: 'LARGE_IMAGE_SWITCH', // 色块大图唤起
}
