import * as standard from './sources/standard'
import { organizeDataSource } from '@shein-aidc/utils-data-source-toolkit'
import type { DS_SizeGuide } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_SizeGuide.SourceParams): DS_SizeGuide.APIS {
  return organizeDataSource<DS_SizeGuide.APIS, DS_SizeGuide.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}
