import { markPoint } from 'public/src/services/mark/index.js'

const setMarkPoint = (isFirstLoad, eventName) => {
  if (!isFirstLoad) return

  switch (eventName) {
    case 'waitPageData':
      markPoint({ eventName: 'waitPageData', measureFrom: 'routeChangeEnd' })
      break
    case 'pageWaitInit':
      markPoint({ eventName: 'PageWaitInit', measureFrom: 'RouterEndTrigger' })
      break
    case 'filterRenderHandle': // 采集vuex\初始化、filter组件 耗时
      markPoint({ eventName: 'FilterRenderHandle', measureTo: 'XHRLoadGoods' })
      break
    case 'apiResponseGoods':
      markPoint({ eventName: 'ApiResponseGoods', measureTo: 'XHRLoadGoods' })
      break
    case 'pageDataReady':
      markPoint({ eventName: 'pageDataReady', measureFrom: 'waitPageData' })
      break
    case 'setPageData':
      markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
      break
    case 'pageResolveData':
      markPoint({ eventName: 'PageResolveData', measureFrom: 'setPageData' })
      break
    case 'setPageDataNextTick':
      markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })
      break
  }
}

export {
  setMarkPoint
}
