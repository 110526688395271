import LowStockTag from './low-stock-tag.vue'
import { getProps } from './helper.ts'
import { createHelper } from '../../../hooks/useHelper.ts'

const useLowStockTag = createHelper('LowStockTag', getProps)

export {
  LowStockTag,
  useLowStockTag,
}
