import { initMonitor, monitor, monitorLog } from 'public/src/pages/components/product/quick_add_v2/quick_add_monitor/index.js'
/**
 * 业务监控
 */
export const onMonitoringPlugin = () => {
  let monitorData = null
  let scene = ''
  let version = 'v1'
  // 是否销售属性选择插件
  let hasSalesAttrPlugin = false
  return {
    name: 'onMonitoring',
    beforeOpen: async context => {
      const { sourceIdentifier = '', analysisConfig = {}, plugins } = context?.op || {}
      scene = sourceIdentifier || analysisConfig?.code || 'other'
      hasSalesAttrPlugin = !!plugins.find(plugin => plugin.name === 'SalesAttrUpdate')
      return false
    },
    callbacks: {
      onMonitoringTrigger: (info = {}) => {
        const { type = '', status = '', msg = '' } = info 
        if (!type || hasSalesAttrPlugin)return 
        switch (type) {
          case 'init-start':
            // 监控初始化
            monitorData = initMonitor({ scene, version })
            break
          case 'open-end':
            // 加车弹窗成功唤起
            monitor({ type: 2, params: { ...monitorData, status: status === 'success' ? 'success' : 'fail' } })
            break
          case 'get-data-error':
            // 数据获取失败
            monitorLog('get-data-error', msg)
            break
          case 'add-to-cart-request-start':
            // 开始加车
            monitor({ type: 5, params: monitorData })
            break
          case 'add-to-cart-error':
            // 加车失败 (链路层面)
            monitorLog('add-to-cart-error', msg)
            break
          case 'add-to-cart-request-end':
            if (status !== 'success') {
              // 加车失败 (业务层面)
              monitorLog('add-to-cart-request-end', msg)
            }
            // 加车成功
            monitor({ type: 3, params: { ...monitorData, status: status === 'success' ? 'success' : 'fail' } })
            break
          case 'close':
            if (!status) {
              // 跳出率
              monitor({ type: 4, params: monitorData })
            }
            break
          default:
            break
        }
      },
    }
  }
}
