<template>
  <SoldOutBtn 
    v-if="is_sold_out"
    :language="language_v2"
    :unListed="un_listed"
    :showNewFindSimilar="!!find_similar_float_info.show"
    :isCurMallSoldout="is_cur_mall_soldout"
    :isHalfStyle="show_scription_btn"
    @showSimilar="handleShowSimilar"
  />
  <button 
    v-else-if="show_scription_btn"
    v-ada="{
      level: 0, 
      pos: 0,
    }"
    class="mshe-btn-black mshe-btn-block"
    @click="clickNotifyMe"
  >
    {{ language_v2.SHEIN_KEY_PWA_16012 }}
  </button>
  <div
    v-else
    class="add-cart__normal-btn-box"
    :style="btnBoxWidth"
  >
    <BuyNowBtn
      :buy-now-info="buy_now_info"
      :is-review="isReview"
      :style="calcBtnWidth"
    />
    <AddBtn
      ref="refAddBtn"
      :add-btn-info="add_btn_info"
      :is-review="isReview"
      :style="calcBtnWidth"
      @clickAddBtn="changeAddBag"
    />
    <OneClickPayBox
      v-if="one_click_pay_info"
      :style="calcBtnWidth"
      @changeAddBag="changeAddBag"
    />
    <FreeShipTip
      v-if="free_shipping_tag_info.show"
      :free-shipping-tag-info="free_shipping_tag_info"
    />
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import SoldOutBtn from './SoldOutBtn.vue'
import {
  useMapGetters,
  useMapStateV2,
  useMapActions,
  useMapMutations,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import { daEventCenter } from 'public/src/services/eventCenter'
import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import recommendExposureListInstance from 'public/src/pages/goods_detail_v2/utils/recommendExposureList.js'
import AddBtn from './AddBtn.vue'
import BuyNowBtn from './BuyNowBtn.vue'
import FreeShipTip from './FreeShipTip.vue'
import OneClickPayBox from './OneClickPay/OneClickPayBox.vue'

const props = defineProps({
  isReview: {
    type: Boolean,
    default: false,
  },
})

const refAddBtn = ref(null)
const isWaitGoodsReady = ref(false)

const { exposeGoods, goodsReady } = useMapStateV2('newProductDetail/common', ['exposeGoods', 'goodsReady'])
const { find_similar_float_info } = useMapStateV2('productDetail/Recommend', ['find_similar_float_info'])
const {
  is_sold_out,
  language_v2,
  un_listed,
  goods_id,
  goods_sn,
  base_info,
} = useMapGetters('productDetail/common', [
  'is_sold_out',
  'language_v2',
  'un_listed',
  'goods_id',
  'goods_sn',
  'base_info',
])
const {
  show_scription_btn,
  is_cur_mall_soldout,
  add_btn_info,
  free_shipping_tag_info,
  one_click_pay_info,
  buy_now_info,
  show_store_btn
} = useMapGetters('productDetail/addBag', [
  'show_scription_btn',
  'is_cur_mall_soldout',
  'add_btn_info',
  'free_shipping_tag_info',
  'one_click_pay_info',
  'buy_now_info',
  'show_store_btn'
])
const { updateNotifyMe } = useMapMutations('newProductDetail/common', ['updateNotifyMe'])
const { updateRecommendGoods } = useMapActions('newProductDetail', ['updateRecommendGoods'])
const { add_to_bag } = useMapActions('productDetail/addBag', ['add_to_bag'])

const handleShowSimilar = async () => {
  SimilarInstance.showModal(base_info.value, 
    {
      similarFrom: 'out_of_stock',
      getExposeTarget: async (params) => {
        if (exposeGoods.value?.status) {
          const targetsGoods = await updateRecommendGoods({ targets: params?.targets, type: 'similar' })
          recommendExposureListInstance.pushQueen({
            targets: targetsGoods,  
            isDomList: false,
          })
          return
        }
        recommendExposureListInstance.pushQueen({
          targets: params?.targets || [],
        })
      },
    })
  // 点击找相似 数据上报
  daEventCenter.triggerNotice({
    daId: '1-7-1-3',
    extraData: {
      sku: goods_sn.value,
      goods_id: goods_id.value,
      activeFrom: 'detail',
      code: 'goodsDetail',
      isOutOfStock: 1,
    },
  })
}
// 缺货通知
// 弹窗已经不展示了，TODO: 感觉可以删了吧？产品也没法确定
const clickNotifyMe = () => {
  updateNotifyMe(true)
  const timer = setTimeout(() => {
    clearTimeout(timer)
    updateNotifyMe(false)
  }, 500)
}
const changeAddBag = (target, interestpoints, btnType) => {
  if (!goodsReady.value) {
    isWaitGoodsReady.value = true
    return
  }
  add_to_bag({
    interestpoints,
    target: refAddBtn.value?.$el,
    btnType,
    isReview: !!props.isReview,
  })
}

const btnBoxWidth = computed(() => {
  if (show_store_btn.value) return { width: '7.0133rem' }
  return { width: '8.1867rem' }
})

const calcBtnWidth = computed(() => {
  let width = '8.1867rem'
  if (show_store_btn.value) width = '7.0133rem'
  if (buy_now_info.value.show || one_click_pay_info.value?.showOneClickPayBtn) {
    width = '3.9467rem'
    if (show_store_btn.value) width = '3.36rem'
  }
  return { width }
})

watch(goodsReady, (val) => {
  if (val && isWaitGoodsReady.value) {
    isWaitGoodsReady.value = false
    changeAddBag()
  }
})
</script>

<style lang="less" scoped>
.add-cart__normal-btn-box {
  position: relative;
  .flexbox();
  .space-between();
}
</style>
