var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SimpleProductItem',{staticClass:"product-bff",class:{
    'product-bff__view_more': _vm.showViewMore,
    'product-bff__border_out':
      _vm.item.homeBorder?.image?.src || _vm.item.maskLayer?.image,
  },attrs:{"item":_vm.item,"goods-item-info":_vm.goodsItemInfo,"language":_vm.language,"index":_vm.index,"lcp":_vm.lcp,"customConfig":_vm.config,"column":_vm.column,"constant-data":_vm.constantData,"comp-src":_vm.compSrc,"cropRate":_vm.cropRate,"comp-img-design-width":_vm.compImgDesignWidth},on:{"clickItem":_vm.clickItem,"openQuickAdd":_vm.openQuickAdd}},[(_vm.item.maskLayer?.image)?_c('div',{staticClass:"product-bff__border",style:(_vm.borderStyle(_vm.webpCheck(_vm.item.maskLayer?.image))),attrs:{"slot":"imgTopLeft"},slot:"imgTopLeft"}):_vm._e(),_vm._v(" "),(_vm.item.homeBorder?.image?.src)?_c('div',{staticClass:"product-bff__border",style:(_vm.borderStyle(_vm.webpCheck(_vm.item.homeBorder?.image?.src))),attrs:{"slot":"imgTopLeft"},slot:"imgTopLeft"}):_vm._e(),_vm._v(" "),(_vm.item.homeBadge?.text)?_c('div',{staticClass:"product-bff__badge",attrs:{"slot":"imgTopLeft"},slot:"imgTopLeft"},[_c('div',{staticClass:"product-bff__badgetext",style:(_vm.badgeTextStyle(_vm.item.homeBadge))},[(_vm.item.homeBadge?.icon?.src)?_c('BaseImg',{staticClass:"not-fsp-element",class:{
          'product-bff__badgeicon_rotate':
            (_vm.GB_cssRight || _vm.cssRight) && !_vm.item.homeBadge?.icon?.iconNoRotate,
        },style:(_vm.itemIconStyle(_vm.item.homeBadge)),attrs:{"fit":"cover","placeholder":{
          width: _vm.item.homeBadge?.icon?.width,
          height: _vm.item.homeBadge?.icon?.height,
        },"img-src":_vm.webpCheck(_vm.item.homeBadge?.icon?.src),"img-design-width":Number(_vm.item.homeBadge?.icon?.width) * 2,"first-screen":false}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"product-bff__badgetext_content",class:{
          'product-bff__badgetext_rotate':
            (_vm.GB_cssRight || _vm.cssRight) && _vm.item.homeBadge?.textRotate,
        }},[_vm._v("\n        "+_vm._s(_vm.item.homeBadge?.text)+"\n      ")])],1),_vm._v(" "),(_vm.item.homeBadge?.bgImage?.src)?_c('BaseImg',{staticClass:"not-fsp-element product-bff__badgebg",attrs:{"fit":"cover","placeholder":{
        width: _vm.item.homeBadge?.bgImage?.width,
        height: _vm.item.homeBadge?.bgImage?.height,
      },"img-src":_vm.webpCheck(_vm.item.homeBadge?.bgImage?.src),"img-design-width":Number(_vm.item.homeBadge?.bgImage?.width) * 2,"first-screen":false}}):_vm._e()],1):(_vm.config.showNormalDiscountLabel)?_c('div',{staticClass:"product-bff__normalbadge",attrs:{"slot":"imgTopLeft"},slot:"imgTopLeft"},[(Number(_vm.item?.unit_discount) > 0)?_c('div',{staticClass:"product-bff__normalbadgetext"},[_c('span',{staticClass:"product-bff__normalbadgetext_content",class:{
          'product-bff__normalbadgetext_rotate': _vm.GB_cssRight || _vm.cssRight,
        }},[_vm._v("\n        -"+_vm._s(_vm.item?.unit_discount)),_c('span',{staticStyle:{"display":"inline-block"}},[_vm._v("%")])])]):_vm._e()]):(_vm.config.showFlashSaleLabelInMainImg)?_c('div',{staticClass:"product-bff__normalbadge",attrs:{"slot":"imgTopLeft"},slot:"imgTopLeft"},[(Number(_vm.item?.unit_discount) > 0)?_c('div',{staticClass:"product-bff__normalbadgetext flash-sale"},[_c('i',{staticClass:"suiiconfont sui_icon_flashsale_15px"}),_vm._v(" "),_c('span',{staticClass:"product-bff__normalbadgetext_content",class:{
          'product-bff__normalbadgetext_rotate': _vm.GB_cssRight || _vm.cssRight,
        }},[_vm._v("\n        -"+_vm._s(_vm.item?.unit_discount)+"%\n      ")])]):_vm._e()]):_c('template',{slot:"imgTopLeft"},[_vm._t("imgTopLeft")],2),_vm._v(" "),(_vm.showViewMore)?_c('div',{staticClass:"product-bff__viewmore",attrs:{"slot":"imgTopLeft"},slot:"imgTopLeft"},[_c('div',{staticClass:"product-bff__viewmore_content",on:{"click":_vm.handleClickMore}},[_c('span',{staticClass:"product-bff__viewmore_icon"},[_c('img',{attrs:{"src":_vm.viewMoreSvg,"alt":"view more"}})]),_vm._v(" "),_c('div',{staticClass:"product-bff__viewmore_txt"},[_vm._v("\n        "+_vm._s(_vm.viewMoreText)+"\n      ")])])]):_vm._e(),_vm._v(" "),(_vm.item.homeBelt?.text)?_c('div',{staticClass:"product-bff__belt",attrs:{"slot":"imgBottom"},slot:"imgBottom"},[_c('div',{staticClass:"product-bff__belttext",style:(_vm.beltTextStyle(_vm.item.homeBelt))},[_c('div',{staticClass:"product-bff__belttex_relative"},[_c('div',{staticClass:"product-bff__belttex_absolute"},[(_vm.item.homeBelt?.icon?.src)?_c('BaseImg',{staticClass:"not-fsp-element",class:{
              'product-bff__belticon_rotate':
                (_vm.GB_cssRight || _vm.cssRight) &&
                !_vm.item.homeBelt?.icon?.iconNoRotate,
            },style:(_vm.itemIconStyle(_vm.item.homeBelt)),attrs:{"fit":"cover","img-src":_vm.webpCheck(_vm.item.homeBelt?.icon?.src),"placeholder":{
              width: _vm.item.homeBelt?.icon?.width,
              height: _vm.item.homeBelt?.icon?.height,
            },"img-design-width":Number(_vm.item.homeBelt?.icon?.width) * 2,"first-screen":false}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"product-bff__belttext_content"},[_vm._v("\n            "+_vm._s(_vm.item.homeBelt?.text)+"\n          ")])],1)])]),_vm._v(" "),(_vm.item.homeBelt?.bgImage?.src)?_c('BaseImg',{staticClass:"not-fsp-element product-bff__beltbg",class:{
        'product-bff__beltbg_rotate':
          (_vm.GB_cssRight || _vm.cssRight) && !_vm.item.homeBelt?.bgImage?.bgNoRotate,
      },attrs:{"fit":"contain","img-src":_vm.webpCheck(_vm.item.homeBelt?.bgImage?.src),"placeholder":{
        width: _vm.item.homeBelt?.bgImage?.width,
        height: _vm.item.homeBelt?.bgImage?.height,
      },"img-design-width":Number(_vm.item.homeBelt?.bgImage?.width) * 2,"first-screen":false}}):_vm._e()],1):_c('template',{slot:"imgBottom"},[_vm._t("imgBottom")],2),_vm._v(" "),_c('template',{slot:"price"},[(!_vm.hiddenPrice)?_c('HomeBffPrice',{attrs:{"goods-info":_vm.item,"config":_vm.config,"suggested-sale-type":_vm.suggestedSaleType,"price-font-size":_vm.priceFontSize}}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }