import { parseQueryString } from '@shein/common-function'
import { LIST_PAGE_PATHS, SEARCH_PAGE_PATH } from 'public/src/pages/product_app/router/constants.js'
import { preloadImg, cutImg } from '../../utlis/prefetch'
import { ROUTE_REGEX } from './constant.js'


let currentToRoute = null

function getCurrentToRoute() {
  return currentToRoute
}

function setCurrentToRoute(route) {
  currentToRoute = route
}

function getPdeParams() {
  const headers = {}
  const date = new Date()
  headers['screen-pixel'] = window.screen.width + 'X' + window.screen.height
  headers['local-time'] = `${date.getFullYear()}/${
    date.getMonth() + 1
  }/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  return headers
}

class PointerOverGoodsRequest {
  constructor({ cancelToken, fetch, TIME }) {
    if (!fetch) throw new Error('update is required')
    this.cancelToken = cancelToken // 取消请求的实例
    this.fetch = fetch
    this.TIME = TIME || 500 // 如果没有click事件的话，默认500ms后取消请求 
  }

  triggerNotice(url, options = {}) {
    if (url === this.requestUrl) return // 请求的url和上一次的url一样，不做处理(防止取消上次请求，发起新请求)
    this._cancel()
    this.fetch(url)
    this.requestUrl = url
    const { isCloseCancelTime } = options 

    if (!isCloseCancelTime) {
      this.timer = setTimeout(() => {
        this._cancel()
      }, this.TIME)
    }
  }

  // 清除定时器，说明当前请求是有效的。（必须调用）
  click() {
    this._clear()
  }

  _clear() {
    this.requestUrl = null
    clearTimeout(this.timer)
  }

  _cancel() {
    this.cancelToken && this.cancelToken()
    this._clear()
  }
}

function defaultRequestInfo(query = {}) {
  return {
    type: 'firstload',
    query: {
      page: 1,
      limit: 10,
      ...query
    }
  }
}

// 处理链接成请求参数
function generateRouterParams(path) {
  const isProductsPage = LIST_PAGE_PATHS.some((reg) => new RegExp(reg).test(path))
  if (!isProductsPage) return

  let routeName = 'product-list-v2'

  if (gbCommonInfo.newSearchContainer) {
    // 搜索结果页
    if (new RegExp(SEARCH_PAGE_PATH).test(path)) {
      routeName = 'search-product-list'
    }
  }

  const [pathname, searchSrc] = path.split('?')
  const toParams = {
    name: routeName,
    path: pathname,
    query: parseQueryString(searchSrc),
  }
  return toParams
}

const GOODS_DESIGN_WIDTH = 338
const GOODS_THUMBNAIL_WIDTH = 100

/**
 * @description: 处理商品项的商品图
 * @param {Array} mainImages 商品图 [String]
 * @param {Array} prefetchGoods 预取商品数据 [Object]
*/
async function handleGoodsImgLessen({ mainImages, prefetchGoods }) {
  try {
    if (!prefetchGoods?.length) return
    const lessenImgs = mainImages.map((item, index) => {
      const _imgThumb = cutImg(item, GOODS_THUMBNAIL_WIDTH)
      if (prefetchGoods[index] && _imgThumb) {
        prefetchGoods[index]._imgThumb = _imgThumb // 保存缩略图地址
      }
      return _imgThumb
    })
    // 优先加载缩略图
    await preloadImg({ imgs: lessenImgs, original: true, timeout: 1000 })
    preloadImg({ imgs: mainImages, designWidth: GOODS_DESIGN_WIDTH })
  } catch(e) {
    // console.error(e)
  }
}

// 真实/选品聚合接口返回值 -> bffFilterInfo
// 修改请同步到中间层ssr
function generateRealSelectBffFilterInfo ({
  bffCateAttrResult,
  bffPicNavResult,
  bffCloudTagResult,
  bffDailyDatesResult,
}, requesInfoQuery = {}) {
  const bffCateAttrInfo = bffCateAttrResult?.code === '0' ? bffCateAttrResult.info : {}
  const bffPicNavInfo = bffPicNavResult?.code === '0' ? bffPicNavResult.info : {}
  const bffCloudTagInfo = bffCloudTagResult?.code === '0' ? bffCloudTagResult.info : {}
  const bffDailyDatesInfo = bffDailyDatesResult?.code === '0' ? bffDailyDatesResult.info : {}

  const bffFilterInfo = {
    cateId: bffCateAttrInfo?.cateId ?? '',
    show_adult_tip: bffCateAttrInfo?.show_adult_tip || bffCateAttrInfo?.showAdultTip,
    tagSlotConfigs: bffCloudTagInfo?.tagSlotConfigs ?? [],
    tags: bffCloudTagInfo?.tags ?? [],
    filterList: bffCateAttrInfo?.filterList ?? [],
    filterSortConfigs: bffCateAttrInfo?.filterSortConfigs ?? [],
    attribute: bffCateAttrInfo?.attribute ?? [],
    navs: bffPicNavInfo?.navs ?? [],
    list_nav_source: bffPicNavInfo?.list_nav_source ?? '',
    tabs: bffPicNavInfo?.tabs ?? [],
    min_price: bffCateAttrInfo?.min_price ?? 0,
    max_price: bffCateAttrInfo?.max_price ?? 0,
    cat_path: bffCateAttrInfo?.cat_path ?? [],
    categories: bffCateAttrInfo?.categories ?? [],
    all_categories: bffCateAttrInfo?.all_categories ?? [],
    bannerTag: bffCloudTagInfo?.bannerTag ?? {},
    tagChild: bffCloudTagInfo?.tagChild ?? {},
    dailyDates: bffDailyDatesInfo?.daily ?? [],
  }

  if (requesInfoQuery?.type === 'picks') {
    // shein picks 当前的真实或选品 id
    bffFilterInfo.picksCatId = bffCateAttrInfo.selectId
  }

  return bffFilterInfo
}

/**
 * 图文导航的首屏图片预取
 */
const preloadPicTopNav = ({ nodeRes, toRoute, bffFilterInfo = {}, isBff = false }) => {
  const listAbtResult = nodeRes?.listAbtResult || {}
  const isSearchPage = toRoute?.path?.match(ROUTE_REGEX.search)
  const typePageAbt = isSearchPage ?  listAbtResult.SearchNavigationTwoLine?.p?.SearchNavigationTwoLine : listAbtResult.ListNavigationTwoLine?.p?.ListNavigationTwoLine
  const count =  typePageAbt === 'FirstScreen' ? 10 : 5
  const navs = isBff ? bffFilterInfo.navs : nodeRes?.picTopNav?.[0]?.navs
  const picTopNavImageItems = (navs || []).slice(0, count).filter(item => item?.nav_img)
  if (!picTopNavImageItems.length) return
  const picTopNavImages = picTopNavImageItems.map(item => item.nav_img).filter(Boolean)
  preloadImg({ imgs: picTopNavImages, designWidth: 100 } )
}

// 精简字段返回
function deadcodeCCCCleaner(result) {
  const mobile_buyer_show = result['mobile_buyer_show']?.content?.[0]?.content?.props?.items?.[0]
  result['mobile_buyer_show'] = mobile_buyer_show && {
    minCommentNum: mobile_buyer_show.minCommentNum,
    position: mobile_buyer_show.position,
  }
  const mobile_feedback_entry = result['mobile_feedback_entry']?.content?.[0]?.content?.props?.items?.[0]
  result['mobile_feedback_entry'] = mobile_feedback_entry && {
    hrefTarget: mobile_feedback_entry.hrefTarget,
    text: mobile_feedback_entry.text,
  }
  result['mobile_seo_text'] = result['mobile_seo_text']?.content?.[0]?.content?.props?.items?.[0]?.content

  /**
   * GuidedSearch 这个 CCC 场景下, 有如下几种内容类型 (contentType)
   * SelectclassRecommend  选品推荐
   * SelectionFeedFilter 搜索页信息流筛选
   * SearchFeedHotword 搜了还搜
   */
  result['mobile_guided_search'] = result['mobile_guided_search']?.content
    ?.reduce((curr, _) => {
      const next = _?.content?.props?.items?.[0]
      curr[next?.contentType]?.push(next.position)
      return curr
    }, { SearchFeedHotword: [], SelectionFeedFilter: [], Listcategoryscreening: [] })
  result['mobile_list_nav'] = result['mobile_list_nav']?.content?.[0]?.content?.props?.items?.[0]
  result['mobile_list_nav']?.attrBlackList && (result['mobile_list_nav'].attrBlackList = result['mobile_list_nav'].attrBlackList.join())
  result['promotion_label'] = result['promotion_label']?.content?.[0]?.content?.props?.items?.[0]?.switchTag === '1'
  return result
}

export {
  getCurrentToRoute,
  setCurrentToRoute,
  getPdeParams,
  PointerOverGoodsRequest,
  defaultRequestInfo,
  generateRouterParams,
  handleGoodsImgLessen,
  generateRealSelectBffFilterInfo,
  preloadPicTopNav,
  GOODS_DESIGN_WIDTH,
  GOODS_THUMBNAIL_WIDTH,
  deadcodeCCCCleaner,
}
