<!-- 个人中心-收藏列表 新 -->
<template>
  <ListSkeletonV2
    v-if="showSkeleton"
    page="mePageForWishlist"
    :style="{
      'z-index': 1,
      'transform': 'translateZ(12px)'
    }"
  />
  <div
    v-else
    class="wishlist-center-page"
  >
    <Empty
      v-if="!products.length"
      :language="language"
    />

    <template v-else>
      <Products
        :show-water-fall-style="true"
        :products="showProducts"
        :column="2"
        :language="language"
        :config="config"
        use-scene="user_wishlist"
        :show-filter-empty="false"
        @afterDelete="handleDelete"
      />

      <!-- 按钮 -->
      <div
        v-if="showViewMoreBtn"
        class="wishlist-center-page__view"
        @click="toWishList"
      >
        {{ htmlDecode({text: language.SHEIN_KEY_PWA_15040}) }} 
        <i class="suiiconfont sui_icon_more_right_12px"></i>
      </div>
    </template>
  </div>
</template>

<script>
import Empty from './Empty.vue'
import Products from 'public/src/pages/user/child_pages/wishlist_v2/components/Products/index.vue'
import { htmlDecode } from '@shein/common-function'
import { getWishListsApi, getBffWishProductsApi } from 'public/src/services/api/user/wishlist_v2/index.js'
import { getUserWishCfg } from 'public/src/pages/user/child_pages/wishlist_v2/configs'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import ListSkeletonV2 from 'public/src/pages/product_list_v2/components/ListSkeletonV2/index.vue'
import createFooterRcmdMixin from 'public/src/pages/user_index/mixins/footer-rcmd-mixin'
import { FooterRcmdListResolveStatus } from 'public/src/pages/user_index/service/consts'
const { language: gbLanguage, lang } = gbCommonInfo
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'

export default {
  name: 'WishCenterPage',
  components: {
    Empty,
    Products,
    ListSkeletonV2,
  },
  mixins: [
    createFooterRcmdMixin(),
  ],
  props: {
    show: Boolean,
    isLogin: Boolean,
  },
  data() {
    return {
      language: {},
      config: {
        sa: {
          name: 'saved',
          from: 'wishlist',
          activity_from: 'wishlist',
        },
        itemDAEventClickId: '2-3-7',
        itemDAEventExposeId: '2-3-15',
        autoCropImage: true,  // 开启方图
        showItemV3: true, // 使用新商卡
      },
      listDataReady: false,
      products: [],
      total: 0,
      promotionInfoFromServer: null,
      sheinClubInfo: {},
    }
  },
  computed: {
    showViewMoreBtn() {
      const resolveStatus = this.footerRcmdListResolveStatus
      return this.total > (resolveStatus === FooterRcmdListResolveStatus.HAS_RCMD_LIST ? 2 : 10)
    }
  },
  watch: {
    isLogin(v) { // 登录后请求一次
      if (v && !this.products.length && this.show) {
        this.listDataReady = false
        this.fetchData()
      }
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      const response  = await this.getWishList()
      let { wishlist = [], total = 0, language = {}, abtData = {}, sheinClubState, promotionInfoFromServer, standardPrice } = response
      this.language = { ...language, ...gbLanguage }
      this.sheinClubInfo = sheinClubState || {}
      this.promotionInfoFromServer = promotionInfoFromServer
      const { sheinClubType, isPaid } = sheinClubState || {}
      const showOldSheinClubTag = `${sheinClubType}` === '1'
      const showNewSheinClubTag = `${sheinClubType}` === '2'

      this.config = getUserWishCfg({
        ...this.config,
        ...{
          showBadge: false,
          showSellingPoint: false,
          showQuickShip: false,
        },
        showSheinClubDiscountValue: showOldSheinClubTag,
        showSheinClubDiscountValueAfterPrice: showNewSheinClubTag,
        showSheinClubPriceOnSale: !!(showNewSheinClubTag && isPaid),
        standardPrice,
      }, abtData || {}, { isPaid, sheinClubType, standardPrice })

      if (wishlist?.length) {
        // 融合
        if(this.isBffOpenComputed) {
          wishlist = (wishlist || []).map(v => {
            // 兼容个人中心收藏不展示售罄找相似弹窗
            delete v.similar_products
            return goodsTransformation(v, {
              language: this.language,
              lang: lang ?? 'us',
              isPaid: !!sheinClubState?.isPaid,
              suggestedSaleType: gbCommonInfo.IS_SUGGESTED
            })
          })
        } else {
          // 非融合
          dealProductsPretreatInfo({
            products: wishlist,
            itemConfig: this.config,
            language: this.language,
            promotionInfoFromServer,
          })
        }
      }
      this.products = wishlist ?? []
      this.total = total
      this.listDataReady = true
    },
    async handleDelete(delteItem) {
      let { wishlist = [], total } = await this.getWishList()
      wishlist.filter(item => item.goods_id != delteItem.goods_id)
      if (wishlist?.length) {
        // 融合
        if(this.isBffOpenComputed) {
          wishlist = (wishlist || []).map(v => {
            // 兼容个人中心收藏不展示售罄找相似弹窗
            delete v.similar_products
            return goodsTransformation(v, {
              language: this.language,
              lang: lang ?? 'us',
              isPaid: !!this.sheinClubInfo?.isPaid,
              suggestedSaleType: gbCommonInfo.IS_SUGGESTED
            })
          })
        } else {
          // 非融合
          dealProductsPretreatInfo({
            products: wishlist,
            itemConfig: this.config,
            language: this.language,
            promotionInfoFromServer: this.promotionInfoFromServer,
          })
        }
      }
      this.products = wishlist ?? []
      this.total = total
    },
    async getWishList() {
      try {
        let res = null
        if(this.isBffOpenComputed) {
          let resultPromiseArr = await Promise.all([
            getWishListsApi({
              page: 1,
              size: 10,
              notFilter: 1,
              firstPage: 1,
              isBff: true
            }),
            getBffWishProductsApi({
              page: 1,
              size: 10,
              firstPage: 1,
              // 加上scene会导致中间层不返回售罄商品和人气氛围标签，为了保证和旧的node接口一致，暂时不加(我也不太清楚旧的node接口返回了相关数据是不是BUG)
              // scene: 'personalPage',
            })
          ])
          // 拼凑数据，基于旧的node接口获取abt，多语言，会员等信息
          let { language = {}, abtData = {}, sheinClubState, promotionInfoFromServer, standardPrice } = resultPromiseArr[0]
          let resWish = resultPromiseArr[1]?.info || {}
          let { productsTotal: total, products: wishlist } = resWish

          res = {
            language,
            abtData, 
            sheinClubState,
            promotionInfoFromServer, 
            standardPrice,
            total,
            wishlist
          }
        } else {
          res = await getWishListsApi({
            page: 1,
            size: 10,
            notFilter: 1,
            firstPage: 1
          })
        }
        return res || {}
      }catch(e) {
        console.log(e)
        return {}
      }
    },
    toWishList() {
      this.$routerGoWish({ pf: 'me' })
    },
    htmlDecode,
  }
}
</script>

<style lang="less">
.wishlist-center-page {
  min-height: 8rem;

  &__view {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
    color: #999;
  }
}
</style>
