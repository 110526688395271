import { isSwitchBffSsr } from 'public/src/pages/common/bff-new/index.js'
const switchBffComp = (useBff = false) => {
  const isClient = typeof window !== 'undefined'
  const bffDebugger = isClient ? localStorage.getItem('bffDebugger') === '1' : false
  const switchBff = isClient ? bffDebugger ? bffDebugger : isSwitchBffSsr(['brand_sale'])?.['brand_sale'] : useBff
  if (!switchBff)
    return () => import( /* webpackChunkName: "brand_zone_promotion" */ '../../brand_zone_promotion/index.vue')
  return () => import( /* webpackChunkName: "brand_zone_promotion_bff" */ '../../brand_zone_promotion/bff.index.vue')
}

export const createBrandZonePromotionRoute = (langPath = '', useBff = false) => {
  return {
    path: `${langPath}/brand-zone-promotion`,
    name: 'brand_zone_promotion',
    meta: {
      keepAlive: true,
      pageType: 'brand_zone_promotion',
      monitorTag: 'brand_zone_promotion',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: switchBffComp(useBff),
  }
}
