<template>
  <div class="coupon-content">
    <div
      v-for="(item, index) in productList"
      :key="index"
      class="coupon-content__item"
    >
      <div class="coupon-content__title">
        <span class="coupon-content__title-coupon">
          {{ item.display_title }}
        </span>
        <div
          class="coupon-content__title-saved"
          :class="{'show-saver-icon': item.display_icon}"
          v-html="savedText(item)"
        ></div>
      </div>
      <div class="coupon-content__products">
        <img
          v-for="(img, imgIndex) in item.cart_id_imgs"
          :key="imgIndex"
          class="coupon-content__products-img"
          :src="transformImg({ img })"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { C_CheckoutPrice } from '../../../../types'
import { transformImg } from '@shein/common-function'

defineProps<{
  productList: C_CheckoutPrice.InteractionListItem[]
}>()

const savedText = (item) => {
  let desc = ''
  let icon = ''
  if (item.display_icon) {
    icon += `
    <div style="
      display: inline-block;
      height: 0.5334rem;
      border-radius: 0.06rem;
      padding: 0 4px"
    >
      <img style="height: 0.5334rem;" src="${item.display_icon}"/>
    </div>`
  }
  if (item.display_price_text) {
    desc += ` | ${icon} &nbsp <font color="#FA6338">${item.display_price_text}</color>`
  } else {
    desc += icon
  }
  return desc
}

</script>
<style lang="less" scoped>
.coupon-content {
  background-color: #F6F6F6;

  &__item {
    padding: 18/75rem 24/75rem 16/75rem;

    &:not(:last-child) {
      padding-bottom: 0;

      &::after {
        content: '';
        display: block;
        height: 1/75rem;
        width: 664/75rem;
        background-color: #E5E5E5;
        margin-top: 16/75rem;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 14px;
    margin-bottom: 16/75rem;
  }

  &__title-coupon {
    padding: 4/75rem 8/75rem 4/75rem 20/75rem;
    margin-right: 8/75rem;
    background-repeat: no-repeat;
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/09/09/17/17258814476461505fc769f877a2d0fb323d68a6b5.png');
    background-size: 100% 100%;
    font-size: 12px;
    color: #FA6338;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title-saved {
    display: flex;
    align-items: center;
    height: 20/37.5rem;
    color: #767676;
    font-family: "SF Pro";
    font-size: 12px;
  }

  &__products {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;

    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style: none;
    /*火狐下隐藏滚动条*/
    scrollbar-width: none;
    /*Chrome下隐藏滚动条，溢出可以透明滚动*/
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__products-img {
    width: 128/75rem;
    height: 128/75rem;
    object-fit: cover;
    margin-left: 12/75rem;

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
