// import { fetchIndexData } from '../../../api'
import { getCccIndexPageContent } from 'public/src/pre_requests/modules/config_index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import TMG from '@shein/time-management-guru'
import schttp from 'public/src/services/schttp'

const refreshHomeComponentAction = {
  /**
   * @param {*} payload 
   *    @param {*} webDynamicAbt 首页ABT配置
   *    @param {*} from 路由跳转来源
   *    @param {*} contentChannelSetTime 当前频道最后刷新时间
   * @returns 
   */
  judgeRefreshComp(payload) {
    const { webDynamicAbt = {}, name, srcIdentifier = '', contentChannelSetTime } = payload || {}
    // 1. 判断是否达到刷新时间
    let { HomePageBlockRefresh = '', HomePageBlockRefreshTimeLag = 120 } = webDynamicAbt || {}
    if (!HomePageBlockRefresh) return false// 空值不刷新
    HomePageBlockRefreshTimeLag = HomePageBlockRefreshTimeLag === '' ? 120 : HomePageBlockRefreshTimeLag // 空值默认120s
    if (contentChannelSetTime + Number(HomePageBlockRefreshTimeLag) * 1000 >= Date.now()) return false// 时间未到不刷新
    if (!name) return false // from.name 为空时，首次进入首页，不刷新 - 方案A
    let fromQueryOn = ''
    // 2. 判断刷新方案
    const srcIdentifierList = srcIdentifier.split('`')
    fromQueryOn = srcIdentifierList.find(item => {
      return item.indexOf('on=') !== -1
    })
    if (fromQueryOn) fromQueryOn = fromQueryOn.replace(/on=/ig, '')
    // 2.1 方案 B
    if (HomePageBlockRefresh === 'B') {
      if (name !== 'pre_search') {
        if (fromQueryOn !== 'CATEGORY_RECOMMEND_COMPONENT' && fromQueryOn !== 'FLEXIBLE_LAYOUT_COMPONENT') return false// 非搜索页返回，判断sbc\灵活布局刷新 - 方案B
      } // 搜索页返回，刷新 - 方案B
    }
    // 2.2 方案 C
    if (HomePageBlockRefresh === 'C') {
      if (fromQueryOn !== 'FLEXIBLE_LAYOUT_COMPONENT') return false // 判断灵活布局刷新 - 方案C
    }
    return true
  },
  /**
   * 
   * @param {*} currentContentList 
   * @returns 
   */
  geFlushContentData(currentContentList, channelId, newCccAppReference) {
    const flushContentList = []
    const flushContentMap = new Map()
    const resRefreshStyles = ['MULTI_LINE_CONTAINER_COMPONENT'] // 当前仅频道区灵活布局
    let resRefs = {}
    for (const key in newCccAppReference) {
      // 根据channelId获取refs
      if (Object.hasOwnProperty.call(newCccAppReference, key)) {
        const element = newCccAppReference[key]
        if (element.channelId == channelId) {
          resRefs = element.$refs?.newCccReference?.$refs
          break
        }
      }
    }
    currentContentList.forEach((element, index) => {
      if (element.isBeenExposed && resRefreshStyles.includes(element.styleType)) { // 组件已曝光被浏览过
        let flushElement = element
        const resRefsComp = resRefs[`comp-${element.styleType}-${channelId}-${index}`]?.[0]?.$children?.[0]
        if (resRefsComp && resRefsComp?.currentRefreshedPropData) { // 组件内部存在已更新的数据，取已跟新的数据作为入参
          flushElement = resRefsComp.currentRefreshedPropData
        }
        let flushItem = {
          propsHashCode: element.propsHashCode,
          styleType: element.styleType
        }
        if (element.styleType === 'MULTI_LINE_CONTAINER_COMPONENT') {
          // 灵活布局特殊处理
          const content = []
          element.content.forEach(mulitElement => {
            content.push({
              propsHashCode: mulitElement.propsHashCode || '',
              goodsHashCode: mulitElement.goodsHashCode || '',
              styleType: mulitElement.styleType,
              templateType: mulitElement.props?.metaData?.templateType || ''
            })
          })
          flushItem.content = content
        }
        flushContentMap.set(element.propsHashCode, `${index}`) // 字符型数据，可判断字符0为真
        flushContentList.push(flushItem)
      }
    })
    return { flushContentList, flushContentMap }
  },
}

const actions = {
  fetchHomePageBffData: async ({ state, commit }, payload) => {
    try {
      if (Object.keys(state.context).length) return state.context
      const res = await getCccIndexPageContent({ url: '/api/home/bffPageConfig/get', channelId: Number(payload.activeChannelId), contentId: payload.contentId })
      markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
      res.clientRender = true
      const channelId = res.content.channelId
      commit('assignList', { channelId, content: res.content })
      commit('SET_DATA', res)
      state.hasRequestChannelIds.add(channelId)
      // 把 res 返回
      return Promise.resolve(res)
    } catch (error) {
      console.log(error)
      return Promise.resolve()
    }
  },
  // tab 切换时，获取新的数据
  fetchHomeChannelData: async ({ state, commit }, payload) => {
    try {
      const { channelId, position, channelName } = payload
      state.hasRequestChannelIds.add(channelId)
      let res = {}
      res = await TMG.useQuickRequest(`bffConfigIndex/getBffIndexPageContent/${channelId}`, {
        url: '/ccc/home_page',
        channel_id: channelId,
        country_id: state.context.countryId,
        position,
        tab_id: channelId,
        tab_name: channelName,
      })
      res.content = res?.info || {}
      // NOTE: 这个判断有点多余
      if (res && res.content) {
        // eslint-disable-next-line require-atomic-updates
        state.context.clientRender = true
        // eslint-disable-next-line require-atomic-updates
        state.context.content = res.content
        commit('assignList', { 
          channelId, 
          traceId: res.traceId || '',
          content: res.content,
          clientRefresh: true, // TODO
        })
      }
      return Promise.resolve(res)
    } catch (error) {
      console.log(error)
      return Promise.resolve(null)
    }

  },
  // 预取下一个 tab 数据  
  /**
   * 
   * @param {*} param0 
   * @param {*} channelId dangqian 
   * @returns 
   */
  prefetchNextChannelData: ({ state }, channelId) => {
    try {
      if (!channelId) return
      const topTabList = state.context?.topTabList || []
      if (topTabList.length <= 1) return
      const switchTabIndex = topTabList.findIndex(i => i.channelId === channelId)
      if (switchTabIndex === -1) return
      const preRequestTabIndex = switchTabIndex === topTabList.length - 1 ? switchTabIndex - 1 : switchTabIndex + 1
      const preRequestTabData = topTabList[preRequestTabIndex]
      // 获取下一个 tabid
      if (!preRequestTabData) return
      requestIdleCallback(() => {
        const nextChannelId = preRequestTabData.channelId
        
        if (state.hasRequestChannelIds.has(nextChannelId)) return
        state.hasRequestChannelIds.add(nextChannelId)
        // 在控制台打印信息
        TMG.triggerQuickRequests('bffConfigIndex', {
          quickRequestNames: [`getBffIndexPageContent/${nextChannelId}`],
          triggerParams: { preRequestTabData, countryId: state.context.countryId }
        })
        
      })
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * 更新首页数据
   * @param {*} param0 
   * @param {*} payload 
   * @returns 
   */
  refreshHomeComponent: async ({ commit, state }, payload) => {
    const { channelId, channelName, position, from, newCccAppReference } = payload
    const channelContext = state.list[channelId]
    if (!channelContext) return
    // 正在请求中，不刷新
    if (channelContext.contentChannelRefreshLoading) return

    // 1. 判断是否刷新
    // state
    const refresh = refreshHomeComponentAction.judgeRefreshComp({
      webDynamicAbt: channelContext?.homePageExtra?.webDynamicAbt || {}, 
      name: from?.name,
      srcIdentifier: from?.query?.src_identifier || '',
      contentChannelSetTime: channelContext.contentChannelSetTime
    })
    if (!refresh) return

    // 2. 判断需要刷新的组件
    const { flushContentList, flushContentMap } = refreshHomeComponentAction.geFlushContentData(channelContext.content, channelId, newCccAppReference)
    if (flushContentList.length === 0) return

    // 3. 请求刷新
    commit('assignListChannelState', { channelId, key: 'contentChannelRefreshLoading', value: 1 })
    const res = await schttp({
      method: 'POST',
      url: '/api/home/homeComponent/flush',
      data: {
        channel_id: channelId,
        country_id: state.context.countryId,
        position, // 主路由时，无position默认1
        tab_id: channelId,
        tab_name: channelName,
        flushContentList
      }
    })

    // 4. 请求更新的组件数据
    const data = res?.info?.content || []
    commit('assignListChannelState', { channelId, key: 'contentChannelRefreshLoading', value: 0 })
    commit('assignListChannelState', { channelId, key: 'contentChannelSetTime', value: Date.now() })

    if (data.length === 0) return
    // 5. 更新数据
    commit('assignListCompChecke', { 
      refreshData: data, 
      channelId, 
      flushContentMap
    })
  },
}

export default actions
