<template>
  <div
    v-expose="{
      id: '1-8-3-49',
      data: {
        coupon_type: filterData?.labelId,
        is_add: filterData?.isCanAdd ? 1 : 0
      }
    }"
    class="coupon-tip-wrapper"
  >
    <div class="coupon-tip-left">
      <div
        ref="couponTip"
        :class="isOverflow ? 'coupon-tip-overflow' : 'overflow-line2'"
      >
        <div
          ref="couponText"
          class="text"
          :class="{ 'overflow-line2': isOverflow }"
          v-html="filterData?.addTip"
        ></div>
        <s-popover
          ref="popover"
          v-model="showPopoverState"
          trigger-type="click"
          class="pop-icon"
          placemen="bottom"
          :prop-style="couponFilterPopoverStyle"
          :content="filterData?.questionMark"
        >
          <Icon
            slot="reference"
            name="sui_icon_doubt_12px_2"
            size="12px"
            color="rgba(0, 0, 0, 0.6)"
          />
        </s-popover>
      </div>
      <div
        v-if="filterData?.endTimeStamp"
        class="count-down"
      >
        <span class="end-in">{{ filterData?.endTip }}</span>
        <count-down-time
          class="time"
          notReload
          notHide
          :font-size="20/75 + 'rem'"
          :time-stamp="+filterData?.endTimeStamp"
        />
      </div>
    </div>
    <div class="coupon-tip-right">
      <div
        v-if="filterData?.isCanAdd == '1'"
        class="add-btn"
        @click="handleAddOn"
      >
        <SAdapterText
          min-size="10"
          :text="language.SHEIN_KEY_PWA_18957"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouponTips'
}
</script>

<script setup>
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { defineProps, onMounted, ref, computed, watch, nextTick, onBeforeUnmount } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { throttle } from '@shein/common-function'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
import CountDownTime from './../common/CartCountDownTime.vue'
import { usePopoverManager } from 'public/src/pages/cart_v2/utils/popoverManager/usePopoverManager.js'
import { TOP_COUPON_FILTER_ADD_ON } from 'public/src/pages/cart_v2/utils/popoverManager/popoverTypes.js'

const AddItem = useCartAddItem()
const { isActivePopover, activatePopover, deactivatePopover, registerPopover } = usePopoverManager({ type: TOP_COUPON_FILTER_ADD_ON })
registerPopover()

const props = defineProps({
  filterData: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  }
})

const couponFilterPopoverStyle = computed(() => {
  return {
    maxWidth: 240 / 37.5 + 'rem',
  }
})

const popover = ref(null)
const couponTip = ref(null)
watch(() => props.filterData?.addTip, async(n, o) => {
  await nextTick()
  const popoverRect = popover.value?.$el.getBoundingClientRect()
  const couponTipRect = couponTip.value?.getBoundingClientRect()
  isOverflow.value = popoverRect.bottom > couponTipRect.bottom
})

// 达到最高门槛，触发一次气泡自动弹出
watch(() => props.filterData?.isCanAdd, (n, o) => {
  if (o == '1' && n !== '1') {
    if (window?.sessionStorage.getItem('CartCouponSatisfiedPop')) return
    autoShowPopover()
    window?.sessionStorage.setItem('CartCouponSatisfiedPop', true)
  }
})

let isOverflow = ref(false)
let showPop = ref(false)
let intervalId = ref(false)

const showPopoverState = computed({
  get() {
    return showPop.value && isActivePopover.value
  },
  set(val) {
    if(!val) {
      showPop.value = false
    }
  }
})
watch(() => showPop.value, (val) => {
  if (val) {
    activatePopover()
  } else {
    deactivatePopover()
  }
})
watch(() => showPopoverState.value, (val) => {
  if (val) {
    intervalId.value = setTimeout(() => {
      clearTimeout(intervalId.value)
      showPop.value = false
      intervalId.value = null
    }, 3000)
  }
})

onMounted(async() => {
  await nextTick()
  if (window?.sessionStorage.getItem('CartCouponFirstPop')) return
  autoShowPopover()
  window?.sessionStorage.setItem('CartCouponFirstPop', true)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

/**
 * 气泡自动弹出逻辑
 * 一次会话最多弹两次，一次是选中标签时的， 一次是达到最高门槛时
 */
const autoShowPopover = () => {
  showPop.value = true
}
// 页面发生滚动时，不显示气泡
const handleScroll = () => throttle({ 
  func: function() {
    if (showPop.value && !intervalId.value) {
      showPop.value = false
    }
  },
  wait: 300,
  options: {
    trailing: false
  }
})

const handleAddOn = () => {
  const options = {
    coupon: props.filterData?.coupon,
    state: 'cart_filter_coupon_add',
    config: {
      isClickToDetail: false,
    }
  }
  AddItem.show(options)
  daEventCenter.triggerNotice({
    daId: '1-8-3-50',
    extraData: {
      coupon_type: props.filterData?.labelId,
      level: props.filterData?.level
    }
  })
}
</script>

<style lang="less" scoped>
.coupon-tip-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 16/75rem 12/75rem 0;
  min-height: 100/75rem;
  max-height: 128/75rem;
  border-radius: 8px;
  border: 0.5px solid #FFF;
  background-image:
    url('/pwa_dist/images/cart/coupon-filter-bg-cf614cc17e.png'),
    linear-gradient(90deg, #FFF0F1 30.7%, #FFE1E3 57.37%, #FFE4D4 84.96%, #FFDED7 100%);
  background-size: 88/37.5rem 42/37.5rem, cover;
  background-position: 188/37.5rem bottom, top;
  background-repeat: no-repeat;
  &::before, &::after {
    content: '';
    display: block;
    width: 16/75rem;
    height: 16/75rem;
    position: absolute;
    right: 140/75rem;
    border-radius: 50%;
    background: #f6f6f6;
    border-left: 0.5px solid #FFF;
    border-bottom: 0.5px solid #FFF;
    border-top: 0.5px solid #f6f6f6;
    border-right: 0.5px solid #f6f6f6;
  }
  &::before {
    top: -8/75rem;
    transform: rotate(-45deg);
  }
  &::after {
    bottom: -8/75rem;
    transform: rotate(135deg);
  }

  .coupon-tip-left {
    box-sizing: border-box;
    width: 288/37.5rem;
    padding: 16/75rem 16/75rem 16/75rem 24/75rem;
    .text {
      display: inline;
      font-family: "SF Pro";
      font-size: 24/75rem;
      font-weight: 510;
      line-height: 14/37.5rem;
    }
    .pop-icon {
      line-height: 14/37.5rem;
      margin-left: 4/75rem;
    }
    .overflow-line2 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .coupon-tip-overflow{
      display: inline-flex;
      align-items: flex-end;
      .text {
        flex: 1;
      }
      .pop-icon {
        width: 12px;
      }
    }
    .count-down {
      margin-top: 6/75rem;
      height: 36/75rem;
      line-height: 36/75rem;
      .end-in {
        font-size: 20/75rem;
        font-style: normal;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.80);
      }
      .time {
        display: inline-block;
        margin-left: 8/75rem;
        color: #FA6338;
        ::v-deep .count-number {
          height: 32/75rem;
          width: 32/75rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
        }
      }
    }
  }
  .coupon-tip-right {
    flex: 1;
    min-height: 68/75rem;
    max-height: 96/75rem;
    padding: 16/75rem;
    border-left: dashed 0.5px #F3B9A1;
    .add-btn {
      display: block;
      width: fit-content;
      min-width: 92/75rem;
      max-width: 116/75rem;
      height: 40/75rem;
      text-align: center;
      border-radius: 20/75rem;
      background: #FA6338;
      font-size: 24/75rem;
      font-weight: 700;
      line-height: 40/75rem;
      padding: 0 8/75rem;
      margin: 0 auto;
      color: #fff;
    }
  }
}
</style>
