/**
 * 临时用于存储 fsp 之后的点位，用于自己手动上报过程埋点
 */
import { getCookie } from '@shein/common-function'
let dateTimeMarkMap = Object.create(null)
if (typeof window !== 'undefined' && window.__pendingMarkPoints) {
  window.__pendingMarkPoints.forEach(item => {
    dateTimeMarkMap[item.eventName] =  {
      eventName: item.eventName,
      timestamp: item.timestamp
    }
  })
}
let isOpenVueDebug
const getIsOpenVueDebug = () => {
  if (isOpenVueDebug !== undefined) {
    return isOpenVueDebug
  }
  const vueDebugCookieVal = getCookie('vueDebug') || ''
  return vueDebugCookieVal === '1'
}

function getPointName({ eventName, measureFrom, measureTo }) {
  let res = eventName
  if (measureFrom) {
    res += `__${measureFrom}`
  }
  if (measureTo) {
    res += `__${measureTo}`
  }
  return res
}

export function markPoint({ eventName, measureFrom }) {
  if (typeof window === 'undefined') {
    return
  }
  if (!eventName) {
    return
  }
  const pointName = getPointName({ eventName, measureFrom })
  if (dateTimeMarkMap[pointName]) {
    console.warn(`markPoint: ${pointName} already exists`)
  }
  const isDebug = getIsOpenVueDebug()
  if (isDebug && window.performance) {
    window.performance.mark(eventName)
  }
  const metric = {
    eventName,
    measureFrom,
    timestamp: Date.now(),
  }
  dateTimeMarkMap[pointName] = metric
  dateTimeMarkMap[eventName] = metric
}

export function measurePoints() {
  const keys = Object.keys(dateTimeMarkMap)
  const results = []
  keys.forEach(key => {
    if (key.indexOf('__') === -1) {
      return
    }
    const metric = dateTimeMarkMap[key]
    if (metric.measureFrom) {
      try {
        if (getIsOpenVueDebug() && window.performance) {
          window.performance.measure(
            key,
            metric.measureFrom,
            metric.eventName,
          )
        }
      } catch(e) {
        console.error(e)
      }

      const point = dateTimeMarkMap[metric.eventName]
      const fromPoint = dateTimeMarkMap[metric.measureFrom]
      if (!point || !fromPoint) {
        return
      }
      const duration = Math.round(point.timestamp - fromPoint.timestamp)
      if (duration > 0) {
        results.push({
          key,
          value: duration,
        })
      }
    }
  })
  dateTimeMarkMap = Object.create(null)
  return results
}
