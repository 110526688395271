<template>
  <div class="shopping-bag-options__item-wrap">
    <div>
      <li
        :class="['bag-item-effiency']"
      >
        <img 
          v-if="fromType == 'shoppingMall'"
          class="i-img" 
          :class="{'fsp-element': index == 0}"
          :fsp-key="index == 0 ? `checkout_ShoppingBagItemV2_1_${baseCutImg(true)}` : null"
          :src="baseCutImg(true)" 
          fetchpriority="high"
        />
        <img
          v-else
          class="i-img"
          :src="baseCutImg()"
        />

        <img 
          v-if="item.product.maskLayerImage && item.product.maskLayerImage['oneOne']"
          :src="item.product.maskLayerImage['oneOne']" 
          class="common-image_container"
        />

        <img
          v-if="isSensitiveGood"
          class="product-sensitive"
          :src="`${locals.PUBLIC_CDN}/pwa_dist/images/checkout/sui_icon_sensitive-f1bf4c1570.svg`"
        />
        <ShoppingBagItemBelt 
          class="shopping-bag-options__item-tips"
          :item="item"
          :lagreShipInfo="lagreShipInfo"
          :isOutStockGood="isOutStockGood"
          :isGiftGoods="isGiftGoods"
          :isSheinClubGift="isSheinClubGift"
          :isAddBuyGoods="isAddBuyGoods"
        />
      </li>

      <!-- 商品价格 -->
      <Price
        :source="source"
        class="shopping-bag-options__item-price"
        :item="item"
        :is-shein-club-gift="isSheinClubGift"
      />
      <div 
        v-if="chemicalsIds.includes(item.product.goods_sn)" 
        class="shopping-bag-options__item-warning" 
        @click.stop="showP65Warning(item.product.goods_sn)" 
      >
        <img :src="locals.IMG_LINK['warning']" />
        <span>{{ language.SHEIN_KEY_PWA_20813 }}</span>
      </div>
    </div>

    <InputNumber 
      class="shopping-bag-options__item-number" 
      :item="item"
      :isLastGoods="isLastGoods"
      :isPromitionGoods="isPromitionGoods(item)"
      :isOutStockGood="isOutStockGood"
      :source="source"
      :isSheinClubGiftItem="isSheinClubGift"
      @click.native.stop
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { transformImg } from '@shein/common-function'

import Price from './ShoppingBagItemPrice.vue'
import InputNumber from 'public/src/pages/checkout/vue_tpls/shopping_bag/InputNumber.vue'
import ShoppingBagItemBelt from '../ShoppingBagItemBelt.vue'

import { isPromitionGoods, handleLargeShipInfo } from 'public/src/pages/checkout/config/tools.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getMainImageUrl } from 'public/src/pages/cartNew/utils/index.js'


export default {
  name: 'ShoppingBagItem',
  components: {
    Price,
    InputNumber,
    ShoppingBagItemBelt,
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    isSheinClubGift: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    isOutStockGood: {
      type: Boolean,
      default: false
    },
    fromType: {
      type: String,
      default: ''
    },
    isSensitiveGood: {
      type: Boolean,
      default: false
    },
    isLastGoods: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: ''
    }
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  computed: {
    ...mapGetters('checkout', [
      'chemicalsIds',
      'customServiceImgDomains',
      'thirdPartyServiceImgDomains',
      'isSiteMallList',
      'largeShipExtendsInfo',
    ]),
    ...mapState('checkout', [
      'language', 
      'checkout', 
      'locals', 
    ]),
    isAddBuyGoods() {
      console.log('当前位置，购物袋===', this.item)
      return this.item?.product?.product_promotion_info?.some(promo => [2].includes(+promo.promotion_product_type)) ? true : false
    },
    isGiftGoods() {
      const cartGift = this.item?.product?.product_promotion_info?.some(promo => [1].includes(+promo.promotion_product_type)) ? true : false
      const checkoutGift = this.item?.promotion_type_id == '1000'

      return cartGift || checkoutGift
    },
    lagreShipInfo() {
      const { isLargeShipGood = 0, largeShipTimeDesc = '', largeShipTitle = '' } = handleLargeShipInfo({ 
        isSiteMallList: this.isSiteMallList, 
        largeShipExtendsInfo: this.largeShipExtendsInfo, 
        mallCode: this.item.mall_code, 
        item: this.item, 
      })
      return {
        isLargeShipGood,
        largeShipTimeDesc,
        largeShipTitle,
      }
    },
    goodsStatusInfo() {
      let info = {
        tips: '',
        class: '',
      }
      if(this.lagreShipInfo.isLargeShipGood == 1) {
        info = {
          tips: this.lagreShipInfo.largeShipTitle,
          class: 'large-tips',
        }
      } else if(this.isOutStockGood) {
        info = {
          tips: this.language.SHEIN_KEY_PWA_14987,
          class: 'soldout-tips',
        }
      } else if(this.isGiftGoods) {
        info = {
          tips: this.language.SHEIN_KEY_PWA_25536,
          class: 'gift-tips',
        }
      } else if(this.isSheinClubGift) {
        info = {
          tips: this.language.SHEIN_KEY_PWA_31169,
          class: 'club-gift-tips',
        }
      } else if(this.isAddBuyGoods) {
        info = {
          tips: this.language.SHEIN_KEY_PWA_28687,
          class: 'add-tips',
        }
      }
      return info
    },
  },
  methods: {
    ...mapMutations('checkout', ['assignState']),
    transformImg,
    isPromitionGoods,
    async showP65Warning (goods_sn) {
      let caculate_info = this.checkout?.mall_caculate_info || {}
      let { defaultP65TipsContent, chemicalsIdsEvt } = await import('public/src/pages/checkout/tools/p65util.js')
      let p65Text = await chemicalsIdsEvt(caculate_info, goods_sn)
      this.assignState({
        p65TipsShowText: p65Text || defaultP65TipsContent(this.language),
        p65TipsShow: true
      })
      sa('send', { activity_name: 'click_p65warning' })
      daEventCenter.triggerNotice({
        daId: '1-11-1-238',
        extraData: {
          sku_code: goods_sn
        }
      })
    },
    baseCutImg(needReplace) {
      const url = getMainImageUrl({ 
        item: this.item, 
        IS_RW: false,
        customServiceImgDomains: this.customServiceImgDomains,
        thirdPartyServiceImgDomains: this.thirdPartyServiceImgDomains
      })

      if (!this?.cutImg) {
        return needReplace ? url.replace(/^https?\:/, '').replace(/\b(\.jpg|\.gif|\.png)\b/, '.webp') : url
      }
      return this.cutImg(url, 750, { cutTo: 240, max: 240 })
    },
  }
}
</script>

<style lang="less" scoped>
.shopping-bag-options__item-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .margin-r(8/75rem);
  &:last-child {
    .margin-r(24/75rem);
  }
}
.bag-item-effiency {
  position: relative;
  border-bottom: none;
  padding: 0;
  margin-bottom: 0;
  width: 148/75rem;
  height: 148/75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .03);
  overflow: hidden;
  flex-shrink: 0;
  .common-image_container{
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
  }

  >img{
    width: 100%;
  }
  .product-sensitive {
    width: 32/75rem;
    height: 32/75rem;
    position: absolute;
    top: 8/75rem;
    right: 8/75rem;
  }
  .shopping-bag-options__item-tips {
    position: absolute;
    bottom: 0;
    .left(0);
    width: 100%;
  }
  // .shopping-bag-options__item-tips {
  //   padding: 4/75rem 8/75rem;
  //   position: absolute;
  //   bottom: 0;
  //   .left(0);
  //   width: 100%;
  //   .font-dpr(20px);
  //   text-align: center;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;

  //   &.add-tips {
  //     background: #FCF5EF;
  //     color: #AD633D;
  //   }
  //   &.gift-tips {
  //     background: #FB7751;
  //     color: #fff;
  //   }
  //   &.soldout-tips {
  //     background: @sui_color_black;
  //     opacity: 0.6;
  //     color: #fff;
  //   }
  //   &.club-gift-tips {
  //     color: @sui_color_white;
  //     background-color: @sui_color_club_rosegold_dark1;
  //   }
  //   &.large-tips {
  //     color: @sui_color_white_alpha96;
  //     background: @sui_color_gray_alpha60;
  //   }
  // }
}
.shopping-bag-options__item-price {
  margin-top: 8/75rem;
}
.shopping-bag-options__item-warning {
  line-height: 1;
  margin-top: 8/75rem;
	width: 148/75rem;
  border: 1px solid #D39A8E;
  background: #FFEEEB;
  padding: 3/75rem 8/75rem;
	img {
		height: 20/75rem;
		width: auto;
    .margin-r(4/75rem);
	}
	span {
    color: @color_red_warning;
    text-transform: uppercase;
    .font-dpr(20px);
    font-weight: 700;
	}
}
.shopping-bag-options__item-number {
  margin-top: 8/75rem;
}
</style>
