import { BasicPayManager, type BasicPayManagerConstructor, type AbstractMethods } from '../BasicPayManager'
import { loadChannelSdk } from '../../channel/initSdk'
import { debuggerLog } from '../../utils'
import type { Trade_PayLibs } from '@shein-aidc/types-trade'
import { parseQueryString, extractQueryString, mergeQueryString } from '@shein/common-function'
import { MonitorReportAbnormal } from '../helpers/MonitorReport'
// import { CHECKOUT_TYPE, PRODUCT_TYPE } from '../../../types'

// export interface PaidyPayManagerConstructor extends BasicPayManagerConstructor { }
interface RedirectParams {
  callbackUrl?: string;
  paymentMethod?: string;
  countryCode?: string;
  payCode?: string;
  cbUrl?: string;
  [key: string]: any; // 允许其他任意属性
}

interface InitializeForCashAppPay {
  params?: {
    token?: string,
    countryCode?: string,
    scene?: string,
  };
  extraParams?: {
    gatewayPayNo?: string,
  };
  requestOnFileGrant?: boolean;
  onFail?: (data?: any) => void;
  onCancel?: (data?: any) => void;
  onComplete?: (data?: any) => void;
  onSuccess?: (data?: any) => void;
}

interface GetCashAppPayListenerOptionsReturn {
  onError: () => void;
  onComplete: (event: any) => void;
  requestOnFileGrant?: boolean;
  eventListeners: {
    'CUSTOMER_INTERACTION': (data: any) => void;
    'CUSTOMER_REQUEST_DECLINED': () => void;
    'CUSTOMER_REQUEST_APPROVED': () => void;
    'CUSTOMER_REQUEST_FAILED': () => void;
    'CUSTOMER_DISMISSED': () => void;
  };
}

export class AfterPayCashAppManager extends BasicPayManager implements AbstractMethods {

  public static PAYMENT_CODE = 'afterpay-cashapp'

  public paymentCode: string = AfterPayCashAppManager.PAYMENT_CODE

  private resolve

  private reject

  private firstFail = 1 // 首次失败，状态更改0，不回调失败函数

  private static beginCashAppPay

  private static destroyCashAppPay

  constructor(params: BasicPayManagerConstructor) {
    super(params)

    this.resolve = null
    this.reject = null

  }

  private static _sendReport = (scene = '', params = {}) => {
    debuggerLog('useBsPay===_sendReport===', scene, params)
  }

  public static async initSDK (option?: { environment: Trade_PayLibs.BsPayConfig['environment'] }) {
    await loadChannelSdk.initAfterPayCashAppSdk({ isSandBox: option?.environment === 'TEST' })

    function createMainEle () {
      if (!document.querySelector('#cash-app-pay')) {
        const cashAppPay = document.createElement('div')
        cashAppPay.setAttribute('id', 'cash-app-pay')
        document.body.appendChild(cashAppPay)
      }
    }

    createMainEle()
    AfterPayCashAppManager.restartCashAppPay?.()
    debuggerLog('initSDK---', option)
  }

  getCashAppPayListenerOptions ({
    onCancel,
    onFail,
    onSuccess,
    onComplete,
    requestOnFileGrant,
    extraParams,
  }: Partial<InitializeForCashAppPay> = {}): GetCashAppPayListenerOptionsReturn {
    return {
      requestOnFileGrant,
      onError: () => {
        this.firstFail && onFail && onFail({ msg: 'onError' })
        this.firstFail = 0
        debuggerLog('onError')
        MonitorReportAbnormal.metric({
          scene: 'afterpay_cashapp_on_error',
          extraTags: {
            payment_code: this.unifiedPayParams?.paymentCode,
          },
          extraParams: {
            signUpFlag: this.unifiedPayParams?.signUpFlag,
            billno: this.unifiedPayParams?.relation_billno || this.unifiedPayParams?.billno,
            requestOnFileGrant: requestOnFileGrant ? '1' : '0',
          },
        })
      },
      onComplete: (event: any) => {
        onComplete && onComplete(event)
        debuggerLog('onComplete|||: ', event.data)
        const { status, orderToken, callbackBaseUrl = '', grantId, id, cashtag, expiresAt, token } = event.data || {}
        const baseUrl = requestOnFileGrant ? this.unifiedPayParams.callbackUrl : callbackBaseUrl
        if (requestOnFileGrant && !grantId || !requestOnFileGrant && (!callbackBaseUrl || !orderToken)) {
          this.firstFail && onFail && onFail({ msg: `onComplete：${baseUrl ? '' : 'callbackBaseUrl is empty'}${orderToken ? '' : ' orderToken is empty'}` })
          this.firstFail = 0
          MonitorReportAbnormal.metric({
            scene: 'afterpay_cashapp_on_complete_error',
            extraTags: {
              payment_code: this.unifiedPayParams?.paymentCode,
            },
            extraParams: {
              requestOnFileGrant: requestOnFileGrant ? '1' : '0',
              baseUrl,
              orderToken,
              grantId,
              signUpFlag: this.unifiedPayParams?.signUpFlag,
              billno: this.unifiedPayParams?.relation_billno || this.unifiedPayParams?.billno,
            },
          })
          return
        }

        const redirectParams = parseQueryString(extractQueryString(baseUrl))
        // payCode及cbUrl fix afterpay渠道url不能太长，尽量缩短长度
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const { callbackUrl = '', paymentMethod, countryCode, ...channelParams } = (redirectParams || {}) as RedirectParams

        const tokenParams = requestOnFileGrant && grantId ? {
          token,
          orderToken: orderToken || token,
          grantId,
          expiresAt: expiresAt instanceof Date ? expiresAt.toISOString() : expiresAt,
          cashTag: cashtag,
          customerId: id,
          status: 'SUCCESS',
          gatewayPayNo: extraParams?.gatewayPayNo || '',
          paymentCode: this.paymentCode,
        } : {}

        const redirectUrl = mergeQueryString({
          url: callbackUrl,
          mergeObj: { status, orderToken, ...tokenParams, ...channelParams },
        })
        debuggerLog('onComplete===redirectUrl==', redirectParams, redirectUrl, { requestOnFileGrant })
        if (!callbackUrl) {
          onFail?.({ msg: 'callbackUrl is empty' })
          MonitorReportAbnormal.metric({
            scene: 'afterpay_cashapp_on_complete_no_cburl',
            extraTags: {
              payment_code: this.unifiedPayParams?.paymentCode,
            },
            extraParams: {
              requestOnFileGrant: requestOnFileGrant ? '1' : '0',
              signUpFlag: this.unifiedPayParams?.signUpFlag,
              billno: this.unifiedPayParams?.relation_billno || this.unifiedPayParams?.billno,
            },
          })
          return
        }

        onSuccess?.(redirectUrl)
      },
      eventListeners: {
        'CUSTOMER_INTERACTION': ({ isMobile }: { isMobile: boolean }) => {
          debuggerLog(`CUSTOMER_INTERACTION is on Mobile: ${isMobile}`)
        },
        'CUSTOMER_REQUEST_DECLINED': () => {
          this.firstFail && onFail && onFail({ msg: 'CUSTOMER_REQUEST_DECLINED' })
          this.firstFail = 0
          debuggerLog('CUSTOMER_REQUEST_DECLINED')
        },
        'CUSTOMER_REQUEST_APPROVED': () => {
          debuggerLog('CUSTOMER_REQUEST_APPROVED')
        },
        'CUSTOMER_REQUEST_FAILED': () => {
          this.firstFail && onFail && onFail({ msg: 'CUSTOMER_REQUEST_FAILED' })
          this.firstFail = 0
          debuggerLog('CUSTOMER_REQUEST_FAILED')
        },
        'CUSTOMER_DISMISSED': () => {
          onCancel && onCancel()
          debuggerLog('CUSTOMER_DISMISSED')
        },
      },
    }
  }

  private initializeForCashAppPay (options: InitializeForCashAppPay = {}) {
    const cashAppPayOptions = this.getCashAppPayListenerOptions(options) as GetCashAppPayListenerOptionsReturn
    (window as any)?.AfterPay?.initializeForCashAppPay({
      countryCode: options?.params?.countryCode,
      token: options?.params?.token,
      cashAppPayOptions,
    })
    AfterPayCashAppManager.beginCashAppPay?.()
  }

  initializeCashAppPayListeners (options: InitializeForCashAppPay = {}) {
    const cashAppPayListenerOptions = this.getCashAppPayListenerOptions(options)
    try {
      (window as any)?.AfterPay.initializeCashAppPayListeners({ countryCode: options?.params?.countryCode, cashAppPayListenerOptions })
    } catch (e) {
      options.onCancel?.()
    }
  }

  static restartCashAppPay () {
    (window as any)?.AfterPay?.restartCashAppPay?.()
    AfterPayCashAppManager.destroyCashAppPay?.()
    AfterPayCashAppManager.beginCashAppPay = null
    AfterPayCashAppManager.destroyCashAppPay = null
  }

  private renderCashAppPay (options = {
    countryCode: '',
  }) {
    debuggerLog('renderCashAppPay---', (window as any)?.AfterPay, AfterPayCashAppManager.beginCashAppPay)

    return new Promise((resolve, reject) => {
      if (AfterPayCashAppManager.beginCashAppPay) {
        resolve(null)
        return
      }

      (window as any)?.AfterPay?.renderCashAppPayButton({
        countryCode: options.countryCode,
        cashAppPayButtonOptions: {
          button: false,
          manage: false,
          onBegin: ({ begin, destroy }: any) => {
            AfterPayCashAppManager.beginCashAppPay = begin // store begin for subsequent calls
            AfterPayCashAppManager.destroyCashAppPay = destroy
            resolve(null)
            debuggerLog('onBegin----', begin)
          },
        },
      })
      // 兜底处理
      setTimeout(() => {
        if (!AfterPayCashAppManager.beginCashAppPay) {
          reject()
          MonitorReportAbnormal.metric({
            scene: 'afterpay_cashapp_render_begin_error',
            extraParams: { countryCode: options.countryCode },
          })
        }
      }, 10000)
    })
  }

  private async cashAppUnPay () {
    const { status, result } = await this.handleUnifiedPay()
    const { countryCode = '' } = this.payData.channelExtraInfo || {}
    if (status === 'continue') {
      const { paramList = {}, gatewayPayNo = '' } = result?.info || {}
      return { token: paramList.token || '', countryCode, gatewayPayNo }
    } else {
      return { token: '', countryCode }
    }
  }

  private async handleSuccess (cbUrl = '') {
    const params = parseQueryString(extractQueryString(cbUrl)) as unknown as Trade_PayLibs.UnifiedPayCbParams
    if (params.combineGatewayPayNo && Array.isArray(params.combineGatewayPayNo)) {
      params.combineGatewayPayNo = params.combineGatewayPayNo?.[0]
    }
    const res = await this.handleUnifiedCallback(params)
    if (res.status === 'success') {
      this.onSuccess?.(res.result as Trade_PayLibs.UnifiedPayRes)
      this.resolve?.(res.result)
    } else {
      this.onFail?.(res.result as Trade_PayLibs.UnifiedPayRes)
      this.resolve?.(res.result)
    }
  }

  private createPromise (resolveHandle = (_) => {}, rejectHandle = (_) => {}) {
    return new Promise((resolve, reject) => {
      this.resolve = (res) => {
        resolveHandle(res)
        resolve(res)
      }
      this.reject = (error) => {
        rejectHandle(error)
        reject(error)
      }
    })
  }
  /**
   * 处理渠道支付流程
   *
   * @private
   * @memberof AfterPayCashAppManager
   */
  private handleChannelUnPay = async () => {
    try {
      const { token, countryCode, gatewayPayNo = '' } = await this.cashAppUnPay()
      if (!token) {
        this.onError?.({ msg: 'token is empty' } as any)
        return
      }
      this.renderCashAppPay({ countryCode }).then(() => {
        this.initializeForCashAppPay({
          params: {
            token,
            countryCode,
          },
          extraParams: {
            gatewayPayNo,
          },
          requestOnFileGrant: +this.unifiedPayParams?.signUpFlag === 1 ? true : false,
          onComplete: (info) => {
            AfterPayCashAppManager.restartCashAppPay()
            this.onRequestCompleted?.(info as any)
          },
          onCancel: () => {
            AfterPayCashAppManager.restartCashAppPay()
            this.onCancel?.()
            this.resolve?.({ status: 'cancel' })
          },
          onFail: () => {
            AfterPayCashAppManager.restartCashAppPay()
            this.onError?.({ msg: 'onFail' } as any)
            this.reject?.({ status: 'error' })
          },
          onSuccess: this.handleSuccess.bind(this),
        })
      })
    } catch (e) {
      AfterPayCashAppManager.restartCashAppPay()
    }
  }
  /**
   * 处理签约支付
   *
   * @private
   * @memberof AfterPayCashAppManager
   */
  private handleSignUnPay = async () => {
    const { status, result } = await this.handleUnifiedPay()
    if (status === 'success') {
      this.onSuccess?.(result as Trade_PayLibs.UnifiedPayRes)
    } else {
      this.onError?.(result as Trade_PayLibs.UnifiedPayRes)
    }
    return result
  }

  public createPayment = async (): Promise<any> => {
    if (this.unifiedPayParams?.signUpId) {
      return this.handleSignUnPay()
    } else {
      await this.handleChannelUnPay()
      return this.createPromise()
    }
  }
}

