<template>
  <div class="terms-agree">
    <div class="terms-agree__checkbox">
      <span
        class="terms-agree__checkbox-icon"
        @click="onAgreeChange"
      >
        <!-- agree & disable -->
        <svg
          v-if="isAgree && isDisabled"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <circle
            opacity="0.3"
            cx="10.0003"
            cy="10.0003"
            r="8.33333"
            fill="black"
          />
          <path
            opacity="0.6"
            d="M13.9774 7.34835L8.67411 12.6517L6.02246 10"
            stroke="white"
            stroke-width="1.25"
          />
        </svg>
        <!-- disabled -->
        <svg
          v-else-if="isDisabled"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <circle
            cx="10.0003"
            cy="10.0003"
            r="7.70833"
            fill="#F6F6F6"
            stroke="#E5E5E5"
            stroke-width="1.25"
          />
        </svg>
        <!-- agree -->
        <svg
          v-else-if="isAgree"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <circle
            cx="10.0003"
            cy="10.0003"
            r="8.33333"
            fill="#222222"
          />
          <path
            d="M13.9774 7.34835L8.67411 12.6517L6.02246 10"
            stroke="white"
            stroke-width="1.25"
          />
        </svg>
        <!-- default -->
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <circle
            cx="10.0003"
            cy="10.0003"
            r="7.83333"
            fill="white"
            stroke="#BBBBBB"
          />
        </svg>
      </span>
    </div>
    <div class="terms-agree__text">
      <span>
        {{ consent.prefix }}
      </span>
      <span
        class="terms-agree__title"
        @click="onShowArticleDialog"
      >
        {{ ` ${childTerms.title || ''} ` }}
      </span>
      <span>
        {{ consent.suffix }}
      </span>
    </div>

    <ArticleDialog
      ref="refArticleDialog"
      :article-url="articleUrl"
      :iframe-height="iframeHeight"
      :footer-text="language.SHEIN_KEY_PWA_35509 || `Got it`"
      @clickFooterButton="onCloseArticleDialog"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import ArticleDialog from 'public/src/pages/article_spa/component/articleDialog.vue'
import { mergeQueryString } from '@shein/common-function'
import { PICKER_STATUS } from './const.js'
import { Toast as $toast } from '@shein/sui-mobile'

const emits = defineEmits(['change'])
const props = defineProps({
  status: { type: String, default: '' },
  isAgree: { type: Boolean, default: false },
  language: { type: Object, default: () => ({}) },
  childTerms: { type: Object, default: () => ({}) },
})

const consent = computed(() => {
  const splits = props.childTerms.consent?.split?.('{0}') ?? []

  return {
    prefix: splits[0] || '',
    suffix: splits[1] || '',
  }
})
const isDisabled = computed(() => props.status !== PICKER_STATUS.SUCCESS)
const onAgreeChange = () => {
  if (isDisabled.value) {
    $toast(props.language.SHEIN_KEY_PWA_35623 || `Sorry, the age is invalid.`)
    return
  }

  emits('change', !props.isAgree)
}
const refArticleDialog = ref(null)
const articleUrl = computed(() => mergeQueryString({
  mergeObj: { back: 'hide' },
  url: props.childTerms.url || ''
}))
const iframeHeight = ref('0')
const onShowArticleDialog = () => refArticleDialog.value?.openDialog()
const onCloseArticleDialog = () => refArticleDialog.value?.closeDialog()

onMounted(()  => {
  iframeHeight.value = `${window.innerHeight * 0.6  - 120}px`
})
</script>

<style lang="less" scoped>
.terms-agree {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;

  .terms-agree__checkbox {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    position: relative;
  }
  .terms-agree__checkbox-icon {
    width: 44px;
    height: 44px;
    position: absolute;
    left: -12px;
    top: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .terms-agree__text {
    flex: 1;
    color: var(---sui_color_black_alpha60, rgba(0, 0, 0, 0.60));
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .terms-agree__title {
    color: var(---sui_color_link, #2D68A8);
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    position: relative;

    &::after {
      content: ' ';
      height: 44px;
      width: 100%;
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }
}
</style>
