import {
  goodsApiCache,
  searchFilterApiCache,
  searchBannerCardApiCache,
} from '../api/bffApi.js'
import { getSearchGenerateBffParams } from '../bffParamsHandle/index.js'
import { getCommonRequestQueue } from './common.js'

const getSearchRequestQueue = ({ requestInfo, newSrcTabPageId, isSearchBannerCardSwitchBff }) => {
  const { goodsParams, filterParams, bannerCardParams, cccSeoParams, cccOperationPaeams } = getSearchGenerateBffParams(requestInfo, newSrcTabPageId)
  const requestQueue = {
    // 一些公共请求（多语言等）
    ...getCommonRequestQueue({ cccSeoParams, cccOperationPaeams }),
    // 商品列表
    goods: goodsApiCache.request({
      type: 'search',
      params: goodsParams,
      withoutAbort: requestInfo.query?.withoutAbort,
    }),
    // 筛选
    filterData: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, {})
      const res = await searchFilterApiCache.request({ type: 'search', params: filterParams }) // 图文导航，分类/属性/tsp筛选，标签云
      cb(null, res)
    },
  }
  if (requestInfo.type !== 'nextpage' && isSearchBannerCardSwitchBff && !['store', 'brand'].includes(requestInfo?.query?.search_type)) {
    // 搜索页顶部 ccc/店铺/趋势卡片
    requestQueue.searchBannerCard = searchBannerCardApiCache.request({
      params: bannerCardParams
    })
  }

  return requestQueue
}

export {
  getSearchRequestQueue
}
