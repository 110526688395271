export const HeaderTitleType = {
  // 普通标题
  Normal: 'Normal', // 静态就一个多语言KEY
  // 最快送达时间
  Shipping: 'Shipping', // 动态 中间层的 deliveryFastTime 最快送达天数
  // 支付安全保障
  Payment: 'securityGuarantee', // 静态 中间层的 securityGuarantee 支付安全保障的提示语
  // 组合优惠信息
  Saved: 'saveTotalPrice', // 动态对应 中间层的 saveTotalPrice 组合优惠信息
  // 免费退货
  FreeReturn: 'freeReturns', // 这个动态 {0} returns , Free  SHEIN_KEY_PWA_29733 SHEIN_KEY_PWA_29734 对应中间层是那个？
  // 丢件必赔
  SecureShipping: 'SecureShipping', // 静态就一个多语言KEY
  // 免邮
  FreeShipping: 'freeShipping',
  // 限时折扣
  LimitOffer: 'limitOffer',
  // 闪购
  FlashSale: 'flashSale'
}

// 只适用于新版标题，由 CheckoutHeadlinenew（abt）控制
export const titleColorConfig = {
  [HeaderTitleType.FreeReturn]: '#198055',
  [HeaderTitleType.FreeShipping]: '#198055',
  [HeaderTitleType.Saved]: '#FA6338',
  [HeaderTitleType.Payment]: '#198055',
  [HeaderTitleType.LimitOffer]: '#FA6338',
  [HeaderTitleType.FlashSale]: '#FA6338',
}

export const TitleConfig = {
  // 静态
  [HeaderTitleType.Normal]: { // 普通标题
    languageKey: 'SHEIN_KEY_PWA_15245' // Order Confirmation
  },
  // https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1394268049
  [HeaderTitleType.Shipping]: { // deliveryFastTime 最快送达时间
    icon: {
      name: 'sui_icon_shipping_18px',
      color: '#198055',
      size: '18px'
    },
    languageKey: 'SHEIN_KEY_PWA_30754', // Delivered in as Fast as {0}
    animation: {
      languageKey: 'SHEIN_KEY_PWA_27757', // {0} Days
      color: '#198055'
    },
    abtTypeLanguageKey: {
      work: 'SHEIN_KEY_PWA_30752', // {0} business days
      percent: 'SHEIN_KEY_PWA_30753' // {0} delivery within {1}
    },
    typeCLanguageKey: {
      singleTimeStamp: 'SHEIN_KEY_PWA_31490', // Arrives at {0}
      rangeTimeStamp: 'SHEIN_KEY_PWA_31559', // Arrives between {0} 
      workDay: 'SHEIN_KEY_PWA_31488', // Arrives between {0} Business days
      naturalDay: 'SHEIN_KEY_PWA_31489' // Arrives between {0} days
    }
  },
  // 静态
  [HeaderTitleType.Payment]: {  // 支付安全保障
    icon: {
      name: 'sui_icon_guarantee_18px',
      color: '#198055',
      size: '18px'
    },
    highlight: { 
      languageKey: 'SHEIN_KEY_PWA_27695', // Payment information is protected  中间层type securityGuarantee
      color: '#198055'
    }
  },
  [HeaderTitleType.Saved]: { // 组合优惠信息
    icon: {
      name: 'sui_icon_random_discount_16px',
      color: '#FA6338',
      size: '18px'
    },
    languageKey: 'SHEIN_KEY_PWA_27696', // {0} on this order  中间层type saveTotalPrice
    animation: {
      languageKey: 'SHEIN_KEY_PWA_27697', // Save {0}
      color: '#FA6338'
    }
  },
  [HeaderTitleType.FreeReturn]: { // 免费退货
    icon: {
      name: 'sui_icon_shipping_18px',
      color: '#198055',
      size: '18px'
    },
    languageKey: 'SHEIN_KEY_PWA_29733', // {0} returns
    highlight: {
      languageKey: 'SHEIN_KEY_PWA_29734', // Free
      color: '#198055'
    }
  },
  // 静态
  [HeaderTitleType.SecureShipping]: { // 丢件必赔
    icon: {
      name: 'sui_icon_shipping_18px',
      color: '#198055',
      size: '18px'
    },
    languageKey: 'SHEIN_KEY_PWA_29735' // Secure Shipment Guarantee
  }
}

export const abtMap = {
  'freereturn': HeaderTitleType.FreeReturn,
  'secure': HeaderTitleType.SecureShipping
}

export const SaTypeMap = {
  [HeaderTitleType.Normal]: 'default_status',
  [HeaderTitleType.Shipping]: 'shipping_time',
  [HeaderTitleType.Payment]: 'payment_protect',
  [HeaderTitleType.Saved]: 'saving_discount',
  [HeaderTitleType.FreeReturn]: 'free_return',
  [HeaderTitleType.SecureShipping]: 'secure_shipment'
}

export const newSaTypeMap = {
  [HeaderTitleType.Normal]: 'default_status',
  [HeaderTitleType.FreeShipping]: 'free_shipping',
  [HeaderTitleType.FreeReturn]: 'free_return',
  [HeaderTitleType.Saved]: 'saving_promotion',
  [HeaderTitleType.LimitOffer]: 'limit_offer',
  [HeaderTitleType.FlashSale]: 'flash_sale',
  [HeaderTitleType.Payment]: 'payment_security'
}

const ReturnFlag = {
  // 客服不可退
  CustomerServiceNoReturn: 1,
  // 商品不可退
  ProductNoReturn: 2,
  // 活动不可退
  ActivityNoReturn: 3,
}
// 不可退货的场景
export const noReturnScenes = [
  ReturnFlag.CustomerServiceNoReturn,
  ReturnFlag.ProductNoReturn,
  ReturnFlag.ActivityNoReturn
]

// headerTitleType to middle layer type

export const middleLayerType = {
  'deliveryFastTime': 'deliveryFastTime',
  'securityGuarantee': 'securityGuarantee',
  'saveTotalPrice': 'saveTotalPrice',
}

/*
  中间层返回的类型如果能对应上原有的类型则展示逻辑按中间层处理
  如果中间层没有返回的类型则按原来的处理逻辑
*/ 
export const htt2mlt = {
  [HeaderTitleType.Shipping]: middleLayerType.deliveryFastTime,
  [HeaderTitleType.Payment]: middleLayerType.securityGuarantee,
  [HeaderTitleType.Saved]: middleLayerType.saveTotalPrice,
}

const getAllIcons = () => {
  const icons = []
  Object.values(TitleConfig).forEach(item => {
    if (!item.icon) return
    if (icons.find(iconItem => iconItem.name === item.icon.name)) return
    icons.push(item.icon)
  })
  return icons
}

export const allIcons = getAllIcons()
