<template>
  <div 
    class="trends-material"
    :style="containerStyle"
  >
    <div 
      class="trends-material__image-container"
      :style="{ backgroundColor: bannerItem.trendBgColor }"
    >
      <!-- eslint-disable-next-line @shein-aidc/cccx/notUseImgTag -->
      <img 
        :src="bannerItem.titleIconImage?.src"
        :style="{ width: 'auto', height: '.4533rem' }"
      />
    </div>
    <div class="trends-material__word">
      <p class="trends-material__word-text">
        {{ bannerItem.trendWord }}
      </p>
      <Icon 
        name="sui_icon_more_circle_up_12px_2" 
        size="12px" 
        color="#FFFFFF"
        class="trends-material__word-icon"
        :style="iconStyle"
      />
      <BImage
        :src="trendWordBackground"
        :ratio="9.82"
        :firstScreen="true"
        style="position: absolute;width: 2.88rem;height: .2933rem;"
        class="trends-material__word-background"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onActivated } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { Image as BImage } from '@shein/sui-mobile'


const props = defineProps({
  bannerItem: {
    type: Object,
    default: () => ({})
  },
  cssRight: {
    type: Boolean,
    default: false
  },
  lastHtmlFontSize: {
    type: Number,
    default: 0
  }
})

const isTreandWordWrapped = ref(false)
const firstLineWidth = ref(0)
const secondLineWidth = ref(0)

const trendWordBackground = props.cssRight ? 'https://img.ltwebstatic.com/images3_ccc/2025/01/15/78/1736912528d88e32b7dbd33361fa49acb2ca6ca453.webp' : 'https://img.ltwebstatic.com/images3_ccc/2025/01/09/a0/17364159335699ad2c086a9535a07a301ef46e69ea.webp'

const containerStyle = {
  ...props.cssRight ? { left: `${props.bannerItem.trendRightOffset / 37.5}rem` } : { right: `${props.bannerItem.trendRightOffset / 37.5}rem` },
}

const iconStyle = computed(() => ({
  display: 'block',
  transform: props.cssRight ? 'rotate(-90deg)' : 'rotate(90deg)',
  ...isTreandWordWrapped.value ? 
    { bottom: '.08rem', ...props.cssRight ? { right: `${secondLineWidth.value + 4}px` } : { left: `${secondLineWidth.value + 4}px` } } 
    : { bottom: '.1067rem', ...props.cssRight ? { right: `${firstLineWidth.value + 4}px` } : { left: `${firstLineWidth.value + 4}px` } }
}))

const handleTrendWordLineWidth = () => {
  const text = document.querySelector('.trends-material__word-text')
  if (!text) return
  const style = getComputedStyle(text)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  
  // 设置与原文本相同的字体
  ctx.font = `${style.fontSize} ${style.fontFamily}`
  
  const word = props.bannerItem?.trendWord
  const maxWidth = text.offsetWidth
  let secondLine = ''
  let testLine = ''
  // 逐个拼接文案，判断是否超出最大宽度, 超出则换行,且哪些文案在第二行
  for (let i = 0; i < word.length; i++) {
    const metrics = ctx.measureText(testLine + word[i])
    if (metrics.width > maxWidth) {
      secondLine = secondLine + word[i]
    } else {
      testLine = testLine + word[i]
    }
  }

  const secondLineWordWidth = parseInt(ctx.measureText(secondLine).width)
  firstLineWidth.value = maxWidth
  secondLineWidth.value = secondLine ? (secondLineWordWidth < maxWidth ? secondLineWordWidth : maxWidth - 4) : 0
}

const checkTextWrap = () => {
  const word = document.querySelector('.trends-material__word-text')
  const wordContainer = document.querySelector('.trends-material__word')
  if (word && wordContainer) {
    // 比较文本高度是否大于行高来判断是否换行
    const isWrapped = word.offsetHeight > Math.ceil(parseFloat(getComputedStyle(word).lineHeight))
    isTreandWordWrapped.value = isWrapped
  }
}

watch(() => props.lastHtmlFontSize, (newVal, oldVal) => {
  if (oldVal !== 0 && newVal !== oldVal) { 
    requestAnimationFrame(() => {
      checkTextWrap()
      handleTrendWordLineWidth()
    })
  }
})

onMounted(() => {
  requestAnimationFrame(() => {
    checkTextWrap()
    handleTrendWordLineWidth()
  })
})

onActivated(() => {
  requestAnimationFrame(() => {
    checkTextWrap()
    handleTrendWordLineWidth()
  })
})
</script>

<style lang="less">
.trends-material {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 5.68rem;

  &__image-container {
    align-self: flex-start/*rtl:flex-end*/;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: .6667rem;
    padding: .1067rem .2667rem;
    border-radius: .5333rem;
    margin-bottom: .2133rem;
  }

  &__word {
    display: inline-flex;
    position: relative;
    max-width: 5.0933rem;
    direction: ltr;
    align-self: flex-start/*rtl:flex-end*/;

    &-text {
      z-index: 4;
      color: #fff;
      word-break: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      font-size: .48rem;
      font-style: normal;
      font-weight: 700;
      max-width: 5.0933rem;
      line-height: .64rem;
      .line-camp(2);
    }
    &-icon {
      position: absolute;
    }
    &-background {
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
}
</style>
