import { getCookie } from '@shein/common-function'
import { SIMetric } from 'public/src/pages/common/monitor/index'
import { isString } from '@shein/common-function'
/**
 * 防抖函数
 * @param {Function} func - 需要防抖的函数
 * @param {number} wait - 等待时间（毫秒）
 * @returns {Function} - 防抖后的函数
 */
export function debounce(func, wait) {
  let timeout

  return function (...args) {
    const context = this

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      func.apply(context, args)
    }, wait)
  }
}
/**
 * 判断是否可以滚动
 * @returns {boolean} - 是否可以滚动
 */
export function canScroll() {
  const scrollHeight = document.documentElement.scrollHeight
  const clientHeight = document.documentElement.clientHeight
  return scrollHeight > clientHeight
}

/**
 * 检查网络是否为wifi
 */
export function checkNetworkIsWifi() {
  // 限制非wifi环境下不自动播放视频
  if (typeof navigator !== 'undefined') {
    const connection =
      navigator?.connection ||
      navigator?.mozConnection ||
      navigator?.webkitConnection
    if (connection?.type === 'wifi') {
      return true
    }
    if (Number(getCookie('card_video_mock_wifi'))) {
      // todo delete
      return true
    }
  }
  return false
}

/**
 * 尺寸比例监控
 * @param {string} tags.page_name
 * @param {string} tags.video_url
 * @param {string} tags.goods_id
 * @param {string} tags.size_style
 */
export function metricRatioSize(
  tags,
  metricName = 'goods_card_video_size_style',
  params,
) {
  const { goods_id, page_name, video_url, size_style } = tags || {}

  if (
    !isString(page_name) ||
    !isString(video_url) ||
    !isString(size_style) ||
    !isString(goods_id)
  )
    return console.warn(
      'page_name or video_url or size_style or  is not string',
    )

  SIMetric.metricCount({
    metric_name: metricName,
    tags,
    params,
    message: `card ${goods_id} in ${page_name} page video size metrics: ${size_style}`,
  })
}

/**
 * 尺寸比例监控
 * @param {string} tags.page_name
 * @param {string} tags.video_url
 * @param {string} tags.goods_id
 * @param {string} tags.size_style
 * @param {string} tags.loading_count
 */
export function monitorVideoStuck(
  tags = {},
  metric_name = 'goods_card_video_loading_show',
) {
  const { goods_id, page_name, video_url, size_style } = tags || {}

  if (
    !isString(page_name) ||
    !isString(video_url) ||
    !isString(size_style) ||
    !isString(goods_id)
  )
    return console.warn(
      'page_name or video_url or size_style or goods_id is not string',
    )

  SIMetric.metricCount({
    metric_name, // 指标名称
    tags,
    message: `card ${goods_id} in ${page_name} page video stuck times: ${tags.loading_count}`,
  })
}

/**
 * 初始化加载时间监控
 * @param {string} tags.page_name
 * @param {string} tags.video_url
 * @param {string} tags.goods_id
 * @param {string} tags.size_style
 */
export function monitorVideoInitLoadTime(
  tags = {},
  value,
  metric_name = 'goods_card_video_load_duration_time',
) {
  const { goods_id, page_name, video_url, size_style } = tags || {}

  if (
    !isString(page_name) ||
    !isString(video_url) ||
    !isString(size_style) ||
    !isString(goods_id)
  )
    return console.warn(
      'page_name or video_url or size_style or goods_id is not string',
    )

  SIMetric.metricTime({
    metric_name, // 指标名称
    tags,
    value,
    message: `card ${goods_id} in ${page_name} page video init load time: ${value}ms`,
  })
}

/**
 * 上报流量消耗监控
 * @param {string} tags.page_name
 */
export function monitorVideoSize(
  tags = {},
  value,
  metric_name = 'goods_card_video_load_size',
) {
  const { page_name } = tags || {}

  if (!isString(page_name))
    return console.warn(
      'page_name is not string',
    )
  if (typeof value !== 'number') {
    return console.warn(
      'value not number',
    )
  }
  if (!value) {
    return
  }
  const size = (value / 1024).toFixed(2)

  SIMetric.metricTime({
    metric_name, // 指标名称
    tags,
    value: size,
    message: `video had loaded size: ${value}kb as ${size}mb`,
  })
}
