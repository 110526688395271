/* globals shem_caculate_data */
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'
import { handleSwitchCurrency, getXtraDisplayData, autoPrimeProducfilter, updateXtraCouponList } from 'public/src/pages/checkout/utils'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import analysisCoupon from 'public/src/pages/checkout/components/analysis_coupon.js'
import { 
  mallMerge, 
  handleMallInsuranceChange, 
  setCheckoutCacheCookie, 
  handleShipInterceptGoods,
  handleTspLabels,
  handleShipFreeMall,
  handleCartShipFreeMall,
  isShippingMethodDisabled,
} from 'public/src/pages/checkout/config/tools.js'
import schttp from 'public/src/services/schttp'
import { template, mergeQueryString } from '@shein/common-function'
import { Toast } from '@shein/sui-mobile'
import * as api from './api'
import apiReport from 'public/src/pages/common/apiReport'
import promotionInfo from 'public/src/pages/checkout/hooks/promotionInfo.js'
import { shippingCenter } from 'public/src/pages/common/shipping'
import { payCardPrefetchFn } from 'public/src/pages/checkout/page_tpls/payment_options_item_prefetch'
import { exposeScenesAbtAggregator } from 'public/src/pages/checkout/tools/EventAggregator.js'

let couponAbortController
// 是否展示卡bin优惠
function isShowCardBinDiscount (state) {
  // 是否展示卡bin优惠abt
  const { BinDiscountdiscount = {} } = state.checkout?.abtInfo || {}
  const isShowCardBinDiscountABT = BinDiscountdiscount?.param?.BinDiscountShow === 'Show'

  // 支付方式列表
  const paymentMethods = state.checkout?.results?.paymentMethods || []

  // 当前支付方式是否支持卡bin优惠
  let result = false
  for(let i = 0; i < paymentMethods.length; i++){
    const item = paymentMethods[i]
    if (state.checkout?.results?.bin_discount_list?.length > 1) {
      let is_discount_type = false
      let is_match_payments = state.checkout?.results?.bin_discount_list
        .map(binDiscountItem => {
          const { discount_type = 0, match_payments = [] } =
            binDiscountItem || {}
          const matchPayment = (match_payments || []).some(
            v => v === item.code
          )
          if (discount_type != 0) {
            is_discount_type = true
          }
          return matchPayment
        })
        .some(isMatchPayment => isMatchPayment)
      if (
        item.enabled &&
        is_discount_type &&
        is_match_payments &&
        isShowCardBinDiscountABT
      ) {
        result = true
        break
      }
    }
  }

  return result
}

// 是否展示支付方式优惠
function isShowOnlinePayDiscount(state) {
  // 支付方式列表
  const paymentMethods = state.checkout?.results?.paymentMethods || []
  const randomDiscountAbt = state.checkout?.abtInfo?.PaymentRandomDiscount?.param
    ?.PaymentRandomShow == 'Show'

  let result = false
  for(let i = 0; i < paymentMethods.length; i++){
    const item = paymentMethods[i]
    const onlinePayDiscountValue = state.checkout.mall_caculate_info?.onlinePayDiscountInfo?.onlinePayDiscountValue?.[
      item?.code
    ] || {}

    const isShowOnlineRandomDiscount = 
      onlinePayDiscountValue.discountType == 2 &&
      +onlinePayDiscountValue?.random_max?.amount != 0 &&
      randomDiscountAbt
    
    if (
      item.enabled &&
      !!Object.keys(onlinePayDiscountValue)?.length &&
      ((onlinePayDiscountValue.discountType != 2 &&
        onlinePayDiscountValue?.min &&
        onlinePayDiscountValue?.min?.amount != 0) ||
        (onlinePayDiscountValue.discountType == 1 &&
          onlinePayDiscountValue?.limit_value &&
          onlinePayDiscountValue?.limit_value?.amount != 0) ||
          isShowOnlineRandomDiscount)
    ) {
      result = true
      break
    }
  }

  return result
}

// 用户权益埋点
function userBenefit(state){
  if(typeof window == 'undefined') return
  
  const interests = []
  if (isShowOnlinePayDiscount(state) || isShowCardBinDiscount(state)) {
    interests.push('pay_promotion')
  }
  if (isShowCardBinDiscount(state)) {
    interests.push('bin_promotion')
  }
  if(interests.length) {
    daEventCenter.triggerNotice({
      daId: '1-11-1-203',
      extraData: {
        interests: interests.join('`')
      }
    })
  }
}

function getBinsReqData({ state, getters }) {
  let binsOpt = {}
  if (getters.isUsePrePayForCheckout) return binsOpt
  if (state.isShowTokenPay || state.isShowTokenFrontInstallment) {
    const routerPayCardData = (state.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-card')
    const installmentRouterPayCardData = (state.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-cardinstallment')
    let tempList = []
    if (state.isShowTokenPay) {
      tempList = [...(routerPayCardData?.card_token_list || [])]
    }
    if (state.isShowTokenFrontInstallment) {
      tempList = [...tempList, ...(installmentRouterPayCardData?.card_token_list || [])]
    }

    binsOpt.bins = [...new Set(tempList.map(item => item?.card_bin).filter(item => item && item?.length > 0))]
    binsOpt.need_select = ['routepay-card', 'routepay-cardinstallment'].includes(state?.status?.cardPay) ? 1 : 0
    binsOpt.selected_bin = ''
    if (state?.status?.cardPay === 'routepay-card') {
      binsOpt.selected_bin = (getters?.isSelectTokenPay && binsOpt.need_select === 1) ? state?.handlerSelectBin : ''
    } 
    if (state?.status?.cardPay === 'routepay-cardinstallment') {
      binsOpt.selected_bin = (getters?.isSelectInstallmentTokenPay && !state?.isUseNewCard) ? state?.installmentSelectBin : ''
    }
  }
  return binsOpt
}

const actions = {
  initCheckoutSSR ({ commit, dispatch, state }) {
    commit('updateState', { key: 'mallCacuTime', value: new Date().getTime() })
    commit('updateState', { key: 'price', value: state.checkout.mall_caculate_info })
    commit('updateState', { key: 'cardBinDiscountInfo', value: state.checkout.mall_caculate_info?.cardBinDiscountInfo || {} })
    dispatch('handlePaymentSuggestion')
    dispatch('handleCod', state.checkout.mall_caculate_info)
    dispatch('updateWalletPrice')
    dispatch('handleNotSupportCodTipsCode')
    dispatch('updateFreeShipTip', { info: state.checkout.mall_caculate_info })
    dispatch('handlePointAndWallet')
    dispatch('handleAutoUseCoupon')
    dispatch('handleAutoUseWallet')
    dispatch('handleMallCartsList')
    dispatch('handleRapidInfo')
    userBenefit(state)
  },
  handleMallCartsList({ commit, state }) {
    let cartsList = state.checkout?.results?.carts?.carts || []
    let mallCarts = {}
    cartsList.forEach(item => {
      if (!mallCarts['c_' + item.mall_code]) {
        mallCarts['c_' + item.mall_code] = []
      }
      mallCarts['c_' + item.mall_code].push(item)
    })
    let res = []
    if(state.togetherBuy?.mallLacation?.length && state.togetherBuy.togetherBuyType){
      const keys = Object.keys(mallCarts)
      if(keys.length > state.togetherBuy.mallLacation.length){
        const find = keys.find(key => !state.togetherBuy.mallLacation.includes(key))
        find && commit('changeTogetherBuy', {
          mallLacation: [...state.togetherBuy.mallLacation, find]
        })
      }
      state.togetherBuy?.mallLacation.forEach((key)=>{
        res.push(mallCarts[key])
      })
    } else {
      state.togetherBuy?.togetherBuyType && commit('changeTogetherBuy', {
        mallLacation: Object.keys(mallCarts)
      })
      
      res = Object.values(mallCarts)
    }

    commit('assignState', { mallCartsList: res || [] })
  },
  handlePaymentSuggestion({ commit, state }) {
    let { id, code } = state.checkout.selectedLastPayment || {}
    if(id && code){
      commit('updateState', { key: 'noSelectPayment', value: false })
      commit('changeParamsStatus', { type: 'status', params: { cardPay: code } })
      if(typeof window !== 'undefined'){
        shem_caculate_data.payment_id = id || ''
        shem_caculate_data.payment_code_unique = code || ''
        appEventCenter.$emit('analysis-send', { paymentCode: code })
      }
    }
  },
  handleNotSupportCodTipsCode({ state }) {
    if(typeof window !== 'undefined'){
      const notSupportCodTipsCode = state.checkout.mall_caculate_info.notSupportCodTips?.code || ''
      if (notSupportCodTipsCode !== '' && state.checkout.results.paymentMethods.some(item => item.code == 'cod')) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-8',
          extraData: {
            fail_reason: notSupportCodTipsCode
          }
        })
      }
    }
  },
  // 商详立即购
  handleRapidInfo({ state, commit }) {
    commit('assignState', { buyNowNo: state.checkout?.checkout_no || '' })

    if(typeof window == 'undefined') return
    shem_caculate_data.checkout_no = state.checkout?.checkout_no || ''
    commit('assignState', { buyNowFromUrl: sessionStorage?.getItem('buyNowFromUrl') || '' })
  },
  handlePointAndWallet({ state }) {  
    if(typeof window == 'undefined') return
    let { points = 0, walletPrice = {} } = state.checkout.mall_caculate_info || {}
    shem_caculate_data.points = points
    const orderAutoPointsAbtInfo = state.checkout.abtInfo?.['orderautopoints']?.param?.isAutoPoint || '0'
    const isAutoPoint = orderAutoPointsAbtInfo != 0
    if (!('auto_use_point' in shem_caculate_data) && isAutoPoint) {
      shem_caculate_data.auto_use_point = 1
      shem_caculate_data.auto_use_point_threshold = orderAutoPointsAbtInfo?.split('_')?.[1] || '0'
    }
    // const orderAutoPointsAbtInfo = state.checkout?.abtInfo?.['orderautopoints']?.param?.isAutoPoint
    // const isAutoPoint = orderAutoPointsAbtInfo != 0
    // shem_caculate_data.auto_use_point = isAutoPoint ? 1 : 0
    // shem_caculate_data.auto_use_point_threshold = orderAutoPointsAbtInfo?.split('_')?.[1] || '0'
    

    exposeScenesAbtAggregator.addEvent({
      daId: '1-11-1-172',
      extraData: {
        scenes: 'autopoint', // 自动使用积分的场景才会上报
        max_available_point: state.checkout?.mall_caculate_info?.maxAvailablePoint2,
        auto_point_result: isAutoPoint && state.checkout?.mall_caculate_info?.pointPrice?.amount > 0 ? 1 : 0,
        is_below_auto_use_point_threshold: state.checkout?.mall_caculate_info?.is_below_auto_use_point_threshold
      }
    })

    if(walletPrice.amount > 0){
      shem_caculate_data.use_wallet = 1
      shem_caculate_data.use_wallet_amount = walletPrice.amount
    } else {
      shem_caculate_data.use_wallet = 0
      shem_caculate_data.use_wallet_amount = ''
    }
  },
  handleAutoUseCoupon({ commit, state }) {
    const { coupon = [], couponGift = null, couponPrice } = state.checkout.mall_caculate_info || {}
    const applyCoupons = coupon && coupon.map(item => item.couponCode.toLowerCase()) || []
    const couponLth = coupon?.length || 0
    const applyFor = coupon && coupon.filter(item => ['5', '6', '9'].includes(item.applyFor) )
    const tempCost = applyFor.length ? state.language.SHEIN_KEY_PWA_15807 : couponPrice && couponPrice.amountWithSymbol ? '-' + couponPrice.amountWithSymbol : ''

    // 更新使用成功的券列表
    commit('changeParamsStatus', {
      type: 'coupon',
      params: {
        lastCoupon: '',
        success: couponLth ? applyCoupons.join() : '',
        applyCouponsSuccess: coupon || [],
        applyCoupons,
        costShow: couponLth,
        cost: couponLth ? tempCost : '' // 免邮 减邮
      }
    })
    commit('assignState', { couponGift: couponGift })

    if(typeof window == 'undefined') return
    shem_caculate_data.coupon = applyCoupons.join()
  },
  handleResetAssets({ state }, { isHadCoupon, isHadPoint, isHadWallet, isHadGiftcard }) {
    // 券＞积分＞钱包＞礼品卡
    let assetsList = []
    if(isHadCoupon) assetsList.push(state.language.SHEIN_KEY_PWA_28776)
    if(isHadPoint) assetsList.push(state.language.SHEIN_KEY_PWA_28778)
    if(isHadWallet) assetsList.push(state.language.SHEIN_KEY_PWA_28809)
    if(isHadGiftcard) assetsList.push(state.language.SHEIN_KEY_PWA_28777)
    return assetsList
  },
  // 二次请求计价
  async resetMallCacuTotal({ dispatch, state, commit }, { errCb = null } = {}) {
    // 修改商品触发的失败回调需要删除优惠券，支付方式(此处直接使用运输方式限制处触发判断),用券报错，删券后再次请求(此次用户无感知，不进行报错提示)，删券后请求再报错
    let toastText = ''
    const isHadPayment = shem_caculate_data.payment_id?.length > 0
    const isHadCoupon = shem_caculate_data.coupon?.length > 0
    const isHadPoint = +shem_caculate_data.points > 0
    const isHadWallet = +shem_caculate_data.use_wallet_amount > 0
    const isHadGiftcard = shem_caculate_data.card_no?.length > 0
    const assetsList = await dispatch('handleResetAssets', { isHadCoupon, isHadPoint, isHadWallet, isHadGiftcard })

    const assetsTip = !assetsList.length ? '' : (assetsList.length > 2 ? ` ${state.language.SHEIN_KEY_PWA_28727} ${state.language.SHEIN_KEY_PWA_28728}` : assetsList.slice(0, 2).reduce((prev, next) => {
      return prev + ` ${state.language.SHEIN_KEY_PWA_28727} ` + next
    }, ''))

    if(isHadPayment) {
      let tip = !!assetsTip ? (state.language.SHEIN_KEY_PWA_28775 + assetsTip) : state.language.SHEIN_KEY_PWA_28775
      toastText = template(tip, state.language.SHEIN_KEY_PWA_28726)
    } else {
      toastText = !!assetsTip ? template(assetsTip.replace(` ${state.language.SHEIN_KEY_PWA_28727} `, ''), state.language.SHEIN_KEY_PWA_28726) : ''
    }

    // eslint-disable-next-line require-atomic-updates
    shem_caculate_data.payment_code_unique = ''
    // eslint-disable-next-line require-atomic-updates
    shem_caculate_data.payment_id = ''
    commit('changeParamsStatus', {
      type: 'status',
      params: {
        cardPay: ''
      }
    })

    // eslint-disable-next-line require-atomic-updates
    shem_caculate_data.coupon = ''

    // eslint-disable-next-line require-atomic-updates
    shem_caculate_data.use_wallet = 0
    // eslint-disable-next-line require-atomic-updates
    shem_caculate_data.use_wallet_amount = ''

    // eslint-disable-next-line require-atomic-updates
    shem_caculate_data.points = ''

    // eslint-disable-next-line require-atomic-updates
    shem_caculate_data.card_no = ''
    // eslint-disable-next-line require-atomic-updates
    shem_caculate_data.card_pin = ''

    apiReport.report({
      apiPath: '/checkout/remove_goods'
    })

    if(isHadCoupon) {
      appEventCenter.$emit('update-coupon', { 
        isModifyCart: true,
        type: 'originCallBack',
        cb: res => {
          if (res.code == 0) {
            !!toastText && Toast(toastText)
          } else {
            Toast(res.tips)
            errCb && errCb()
          }
        }
      })
    } else {
      dispatch('mallCacuTotal', {
        opt: shem_caculate_data,
        type: 'modifyCart',
        cb: res => {
          if (res.code == 0) {
            !!toastText && Toast(toastText)
          } else {
            Toast(res.tips)
            errCb && errCb()
          }
        },
        loadingInfo: {
          show: true,
          duration: 800
        },
        notTips: true, 
      })
    }
  },
  mallCacuTotal({ dispatch, getters, state }, opts) {
    let binsOpt = getBinsReqData({ state, getters })

    opts.opt = {
      ...opts.opt,
      ...binsOpt
    }

    const { opt, type } = opts
    const isAutoUseCoupon = opt?.is_coupon_auto_use == 1 || getters.isCouponAutoUse
    // 自动用券、修改商品均需要重新调用券列表
    if (isAutoUseCoupon || type == 'modifyCart') {
      appEventCenter.$emit('update-coupon', { isAuto: isAutoUseCoupon, isModifyCart: type == 'modifyCart', ...opts })
    } else {
      dispatch('orignMallCacuTotal', opts)
    }
  },
  orignMallCacuTotal ({ commit, dispatch, state, getters }, opts) {
    // notTips 用券错误、用礼品卡错误，是否不提示toast
    let { opt, cb, type = '', mallUrl = '', cancellation, notTips, loadingInfo = {} } = opts
    const wallet = state.checkout.results.wallet
    let url = mallUrl || `/api/checkout/mallCacuTotal/get`
    const methodCode = shem_caculate_data.payment_code_unique
    const { newPrimeProductList } = state?.virtualCouponInfo || {}

    const vip_order_source = newPrimeProductList.find(f => f.product_code === shem_caculate_data?.prime_product_code)?.primeProductList?.product_activity_type == 1 ? 1 : 0
    const save_card_vip_order_source = state?.xtraAllProductBagInfo[state?.usedXtraCode || shem_caculate_data?.save_card_product_code]?.activityType == 1 ? 1 : 0

    let showDialog = false // 是否在接口请求前展示弹窗提示

    /**
     * 获取强制切换币种
     */
    let orderCurrency =  state.checkout.mall_caculate_info?.orderCurrency?.code || gbCommonInfo.currency || ''
    let { isChangeCurrency, currentPayment } = handleSwitchCurrency({
      currencySupport: getters.currencySupport,
      currency: orderCurrency,
      countryCode: state.checkout.default_address.value,
      paymentCode: methodCode
    })
    opt.countryCode = state.checkout.default_address.value
    console.log('need change currency: ', isChangeCurrency, currentPayment)

    //如果使用钱包后总价为0，则不走转换货币
    if( state.checkout.mall_caculate_info.grandTotalPrice && 
				+state.checkout.mall_caculate_info.grandTotalPrice.amount == 0 &&
				state.checkout.mall_caculate_info.wallet && 
				state.checkout.mall_caculate_info.wallet.subtractPrice && 
				+state.checkout.mall_caculate_info.wallet.subtractPrice.amount > 0 && 
				isChangeCurrency
    ){
      console.log('useing fulled wallet does not change currency')
      isChangeCurrency = false
      commit('assignState', { fullWalletNoCurr: true })
    }

    /**
     * 设置强制切换币种
     */
    if (isChangeCurrency) {
      if (!state.beforeChangedCurrency) {
        // 只在第一次发生转化时，运行一次
        opt.sub_currency_code = orderCurrency
      }
      // 只有发生币种转换时 更新前币种beforeChangedCurrency
      state.beforeChangedCurrency = orderCurrency
      const { defaultCurrency } = currentPayment
      commit('assignState', { now_currency: defaultCurrency })
      commit('changeParamsStatus', {
        type: 'currencyChangeTips',
        params: {
          show: true,
          content: template(defaultCurrency, state.language.SHEIN_KEY_PWA_17722)
        }
      })
      url = mergeQueryString({
        mergeObj: { currency: defaultCurrency }, 
        url: url,
      })
      daEventCenter.triggerNotice({ daId: '1-11-1-12' })

      showDialog = true
    }
    // 当发生过币种转换，就要一直传sub_currency_code
    if (state.checkout.mall_caculate_info.subGrandTotalPrice) {
      opt.sub_currency_code = state.beforeChangedCurrency
    }

    // cod支付后端转换货币后前端跟随转换对应货币
    if (methodCode && 
      methodCode.toLowerCase() == 'cod' &&
      state.checkout.mall_caculate_info.changeCodLocalCurrency && 
      state.checkout.mall_caculate_info.changeCodLocalCurrency.isChange == 1 && 
      state.now_currency != state.checkout.mall_caculate_info.changeCodLocalCurrency.codLocalCurrency 
    ) {
      const curCode = state.checkout.mall_caculate_info.changeCodLocalCurrency.codLocalCurrency
      commit('assignState', { now_currency: curCode })

      url = mergeQueryString({
        mergeObj: { currency: curCode }, 
        url: url,
      })
      isChangeCurrency = true
    }

    // 当险种为退货险，且所有商品不可退货(isDisplayShippingInsurance)时，取消勾选险种
    const { isChange = false, insuranceCheck } = handleMallInsuranceChange({ mallInsuranceCheck: state.mallInsuranceCheck, insuranceMallData: state.checkout.results.insuranceMallData, mall_caculate_info: state.checkout?.mall_caculate_info })
   
    if (isChange) {
      commit('assignState', { mallInsuranceCheck: insuranceCheck })
      
      const defaultShipping = state.defaultShipping || []
      let switchQsFlag = getters.switchQsFlagByAbt

      let mall_params = []
      defaultShipping.forEach(mall => {
        let filterInsurance = insuranceCheck.filter(insurance => insurance.mall_code == mall.mall_code) || []

        let switch_qs_flag = 0
        if (mall?.qs_freight_status == 1) switch_qs_flag = switchQsFlag || 0

        mall_params.push({ mall_code: mall.mall_code, transport_type: mall?.shipping_method?.transport_type || '', use_insurance: filterInsurance[0]?.use_insurance || 0, switch_qs_flag })
      })
      opt.mall_params = mall_params
    }

    // 货币切换钱包使用清空
    if (isChangeCurrency) {
      // abt为自动使用钱包
      if (state.checkout?.results?.autoWalletBalanceAbt?.param?.isAuto == 1) {
        opt.use_wallet = 1
        opt.use_wallet_amount = ''
      } else {
        opt.use_wallet = 0
        opt.use_wallet_amount = ''
      }

      commit('changeParamsStatus', {
        type: 'wallet',
        params: {
          costShow: false,
          err: '',
          apply: false
        }
      })
    }

    // 运输方式是否为店配
    opt.is_shop_transit = getters.isShopTransit ? 1 : 0
    opt.shop_transit_country_id = getters.isShopTransit ? state.checkout.default_address.countryId : 0

    // 店配地址为空不走价格计算接口
    if (opt.is_shop_transit === 1 && (!state.storeData || !state.storeData?.storeId)) {
      return
    }

    state.updatingPrice = true
    state.calCbHasErrTip = false
    state.showMarkedPrice = 0
    const actualLoading = loadingInfo.show && !showDialog
    if(actualLoading) commit('showPageLoading', { duration: loadingInfo.duration })

    if (cancellation) {
      couponAbortController?.abort()
      couponAbortController = new SchttpAbortCon()
    }

    state.virtualCouponInfo.apiLock++
    
    // 无地址的时候，增加州省、国家、邮编字段
    if (!opt.address_id) {
      opt.state = state.checkout.default_address?.state || '', // 州省
      opt.postcode = state.checkout.default_address?.postcode || '', // 邮编
      opt.country_id = state.checkout.default_address?.countryId
    }

    schttp({
      method: 'POST',
      url,
      data: {
        ...opt,
        mall_params: JSON.stringify(opt.mall_params),
        vip_order_source,
        save_card_vip_order_source
      },
      needLogin: true,
      signal: cancellation ? couponAbortController?.signal : null
    }).then( async function(res){

      state.virtualCouponInfo.apiLock--

      if(actualLoading) commit('hidePageLoading')

      // 券模块需要串行请求价格，完整参数作为newRes转发出去
      let newRes
      if (type == 'coupon' && mallUrl) {
        newRes = res
        res = res.calculate
      }

      state.updatingPrice = false
        
      if (state.codConfirm.applyType == 'point' && state.codConfirm.pointCb == '') {
        commit('changeParamsStatus', { type: 'codConfirm', params: { pointCb: cb } })
      }

      dispatch('handleCod', res.info)

      gbCommonInfo.currency = state.now_currency

      //是否有未在范围内的错误码
      let elseErrCode = false

      // 增加 纯计价接口赋值，回调 只处理成功，失败不做下一步处理，统一tost提示
      if(opt?.pureCallBack){
        typeof opt.pureCallBack === 'function' && opt.pureCallBack(res)
        if(res.code == 0) {
          commit('changeParamsStatus', { type: 'checkout', params: { mall_caculate_info: res.info } })
          commit('updateState', { key: 'price', value: state.checkout.mall_caculate_info })
          dispatch('getShippingFreeMall')
        }
        return
      }
      if (res.code == 0) {
        commit('assignState', { mallCacuTime: new Date().getTime() })
        commit('changeParamsStatus', { type: 'checkout', params: { mall_caculate_info: res.info } })
        commit('updateState', { key: 'price', value: state.checkout.mall_caculate_info })
        commit('assignState', { cardBinDiscountInfo: res.info?.cardBinDiscountInfo || {} })
        commit('assignState', { prevCalcuMallParams: opt.mall_params })

        setCheckoutCacheCookie({ opt, cacheAbtValid: state.checkout.cacheAbtValid, isClickedQsFreight: state.isClickedQsFreight, default_address: state.checkout.default_address })
        userBenefit(state)

        dispatch('handleNotSupportCodTipsCode')
        // 只在强制币种转换才会触发自动使用钱包
        if (isChangeCurrency) {
          dispatch('handleAutoUseWallet')
        }
        if (Object.keys(wallet).length) {
          dispatch('updateWalletPrice', res.info)
        }


        // 判断免邮提示展示地方
        dispatch('updateFreeShipTip', { info: res.info, type })
        dispatch('getShippingFreeMall')
          

        if(res.info){
          dispatch('handlePointAndWallet')
          dispatch('updateRewardFloorInfo', res.info)

          //如果原本钱包0元，操作后导致不到0元，则重新请求
          if( state.fullWalletNoCurr && res.info.grandTotalPrice && +res.info.grandTotalPrice.amount != 0 ){
            commit('assignState', { fullWalletNoCurr: false })
            dispatch('orignMallCacuTotal', {
              opt: shem_caculate_data,
            })
          }
        }

        // 是否请求分期的接口
        const isRequestInstallmant = !gbCommonInfo.IS_RW && !!state.status.cardPay && 
          [2, 1].includes(Number(state.checkout?.abtInfo?.bnplPlaceOrder?.param?.bnplPlaceOrderStats)) || state.checkout?.abtInfo?.PlaceOrderButtonPay?.param?.PlaceOrderButtonPayStatus == 1
          
        if (isRequestInstallmant) {
          dispatch('handleInstallment', { info: res.info })
        }

      } else if (res.code == 300516 || res.code == 300512 || res.code == 300509 || res.code == 300510 || res.code == 300518 || res.code == 300513 || res.code == 300541) {
        shem_caculate_data.payment_id = ''
        shem_caculate_data.payment_code_unique = ''
        dispatch('orignMallCacuTotal', {
          opt: shem_caculate_data,
        })
      } else if (res.code == 300511 && state.status.cardPay == 'cod') {
        notTips = true

        let codConfirmTxt = state.codConfirm.applyType == 'coupon' ? state.language.SHEIN_KEY_PWA_16090 : state.language.SHEIN_KEY_PWA_16091
        const resInfo = res.info && res.info.notSupportCodTips && res.info.notSupportCodTips.params || {}

        codConfirmTxt = codConfirmTxt.replace('{min}', resInfo.min).replace('{max}', resInfo.max)

        commit('assignState', { codDisplay: 1, loading: false })
        commit('changeParamsStatus', {
          type: 'codConfirm',
          params: {
            show: true,
            res: res,
            content: codConfirmTxt,
            codCoupon: shem_caculate_data.coupon,
          }
        })
      } else if (res.code == 300412) {
        // 打折限购超过数量
        commit('assignState', {
          showDiscountLimitTip: true
        })

        sa('send', { 'activity_name': 'expose_checkoutpurchaselimitation' })
      } else if (res.code == 300627) {
        // 取 可用支付方式 和 支付优惠券限定的支付方式 交集
        let arr = []
        if (res.info && res.info.paymentLimit && res.info.paymentLimit.length) {
          arr = state.checkout.results.paymentMethods.filter(item => {
            if (item.enabled && res.info.paymentLimit.includes(item.code)) {
              return item
            }
          })
        }
        commit('assignState', { paymentCouponList: arr })
        if (arr.length) {
          if (opt.latest_coupon) {
            // 使用券提醒切支付方式 如果是使用优惠券不匹配当前支付，则弹出优惠券对应的可使用的支付方式
            commit('changeParamsStatus', { type: 'pageStatus', params: { chooseCouponDrawer: false } })
            commit('assignState', { nowPaymentCoupon: state.coupon.val })
            commit('updateState', { key: 'showPaymentCoupon', value: true })
            analysisCoupon.SelectAvailablePayMethod()
          } else if (state.coupon.applyCouponsSuccess.length) {
            // 计算价格 切支付方式 如果原来已成功使用支付优惠券，从可用支付方式切换至不可用支付方式
            commit('updateState', { key: 'confirmUsePaymentCouponShow', value: true })
          }
        }
      } else if (res.code == 302458 && !state.pageStatus.chooseCouponDrawer) { // 入参支付方式和卡bin优惠优惠券限制的支付方式一致，但是卡bin不一致
        if (state.coupon.applyCouponsSuccess.length) {
          if (!opt?.isPrePay) {
            // 计算价格 切支付方式 如果原来已成功使用支付优惠券，从可用支付方式切换至不可用支付方式
            commit('updateState', { key: 'confirmUsePaymentCouponShow', value: true })
          }

          commit('assignState', {
            isSelectCouponsBinError: true
          })
        }
      } else if (res.code == 300625) {
        if (state.language && state.language.SHEIN_KEY_PWA_17202 && type != 'applyCoupon') {
          commit('showMaskTips', state.language.SHEIN_KEY_PWA_17202)
        }
      } else if (res.code == 300401) { //物车为空
        commit('updateState', { key: 'showCartNullDialog', value: true })
        commit('updateState', { key: 'nullDialogTip', value: res.tips })
      } else if (res.code == 300407) {
        commit('assignState', {
          showCartLimitTip: res.tips
        })
      } else {
        elseErrCode = true
      }

      cb && cb(res, newRes, { notTips })

      res.code == 0 && dispatch('payLimit', res.info) 
      res.code == 0 && appEventCenter.$emit('payinline-reload-widget', opt.isClickPayment || '')

      const couponErrorCodes = [300601, 300605, 300606, 300612, 300613, 300614, 300615, 300618, 300619, 300620, 300621, 300623, 301108, 300632, 300633, 302451, 302452, 302453]
        
      // 如果 切换支付方式 券需要更新一下券列表以及买券，让支持的可选 不支持的置灰
      if (res.code == 0 && (state.coupon_payment_code_unique != shem_caculate_data.payment_code_unique) && shem_caculate_data.payment_code_unique) {
        // 修复切换货币的情况下重复请求券列表接口
        if (!isChangeCurrency) {
          appEventCenter.$emit('update-coupon', { onlyCoupon: true })
        }
        commit('assignState', { coupon_payment_code_unique: shem_caculate_data.payment_code_unique })
      }
      // 切换货币后， 需要用新货币更新一次券列表,更新购物车货币,更新付费会员产品包，更新支付方式，更新超省卡产品包,更新付费会员已省金额
      if (res.code == 0 && isChangeCurrency) {
        appEventCenter.$emit('update-coupon', { onlyCoupon: true })
        dispatch('updateCart', { noUpdataShipping: 1, noUpdateXtraPayments: true })
        appEventCenter.$emit('update-shipping-insurance')
        if (state.primeProductList.length) {
          dispatch('updatePrimeList')
          dispatch('updatePrimeSavingSummary')
        }
        appEventCenter.$emit('checkout-update-payment', { currency: state.now_currency })
        if (getters.showXtra) dispatch('updateXtra')
        // 下单页币种转换后需更新活动信息，用于订单返券包金额展示
        dispatch('getCartPromotionInfo', { forceRequest: true })
        if (state.togetherBuy.togetherBuyType) appEventCenter.$emit('update-recommend-data')
        dispatch('getPromotionInfoBySiteUid')
      } else if (res.code && couponErrorCodes.includes(+res.code) && type != 'coupon' && type != 'modifyCart') {
        // 非券列表请求 导致券失败的，删券在请求价格
        appEventCenter.$emit('update-coupon', { res } )
      } else {
        //如果cb里未处理错误code，且有其它未处理的报错，则弹出提示，is_coupon_auto_use的判断是进入页面时会自动使用优惠券，可能会报错，但无需提示
        if( !state.calCbHasErrTip && elseErrCode && res.tips && type != 'coupon' && type != 'modifyCart' ){
          Toast(res.tips)
          state.calCbHasErrTip = false
        }
      }
        
    }).catch(() => {
      state.updatingPrice = false
      if(actualLoading) commit('hidePageLoading')
    })
  },
  payLimit ({ commit, state }, info) {
    info = info || state.checkout.mall_caculate_info

    // 有金额限制的支付方式，若不满足则置灰
    if (info?.paymentMethodLimitInfo) {
      const newPayList = state.checkout.results.paymentMethods.slice(0)
      newPayList.forEach(function (item) {
        if (+info.paymentMethodLimitInfo[item.code] == 0) {
          // 首次记录时，记录金额限制前该支付方式是否enabled
          if (item.beforeLimitEnabled === undefined) {
            item.beforeLimitEnabled = item.enabled
          }
          item.enabled = 0

          if (state.status.cardPay == item.code) {
            commit('assignState', { cardPay: '' })
            shem_caculate_data.payment_code_unique = ''
            shem_caculate_data.payment_id = ''
          }
        } else if (+info.paymentMethodLimitInfo[item.code] == 1 && item.beforeLimitEnabled !== undefined) {
          // 取消限制后，恢复原来的enabled
          item.enabled = item.beforeLimitEnabled
        }

      })
      Vue.set(state.checkout.results, 'paymentMethods', newPayList)
    }

    // abt/没有快捷 => 自动续费包过滤掉
    const paymentMethods = state.checkout.results.paymentMethods.slice(0) || []
    // paylimit 先于更新查询付费包接口情况下，取state?.primeProductList
    const primeProductList = autoPrimeProducfilter( 
      { 
        sheinClubRenew: state?.checkout?.abtInfo?.sheinClubRenew?.param?.sheinClubRenewStatus || '', 
        primeProductInfo: {
          ...state?.checkout.results?.primeProductInfo || {},
          primeProductInfo: state?.primeProductList
        }, paymentMethods  
      })
    // 两个值都更新，防止其他地方直接用的 state.checkout.results.primeProductInfo
    Vue.set(state, 'primeProductList', primeProductList)
    Vue.set(state.checkout.results.primeProductInfo, 'primeProductList', primeProductList)
  },
  updateFreeShipTip ({ commit, state }, opts) {
    let { info = {}, type = 'coupon' } = opts
    const { mall_list = [] } = info
    let mall_list2 = state.checkout.results?.carts?.mall_list || []
    // 过滤掉1，2单独在各种模块展示
    let allFreeTips = [],
        couponFreeTips = [],
        pointFreeTips = [],
        mergeMallList = mallMerge(mall_list, mall_list2)

    mergeMallList.forEach(item => {
      let freeType = item.not_free_shipping_tips
      if (+item.not_free_shipping_diff.amount > 0 && freeType) {
        if (freeType == 1) {
          couponFreeTips.push(item)
        } else if (freeType == 2) {
          pointFreeTips.push(item)
        } else {
          allFreeTips.push(item)
        }
      }
    })

    commit('changeParamsStatus', { 
      type: 'freeShipTip', 
      params: { 
        coupon: couponFreeTips,
        point: pointFreeTips,
        other: allFreeTips,
        otherType: type
      }
    })

  },
  // 获取分期金额信息
  handleInstallment({ state, commit }, { info }){
    const currency = state.checkout.mall_caculate_info?.orderCurrency?.code || gbCommonInfo.currency || ''
    const country = state.checkout?.default_address?.value
    const amount = info?.grandTotalPrice?.amount
    const cacheKey = `${country}_${currency}_${amount}`

    if (!!sessionStorage?.getItem(cacheKey)) {
      commit('changeParamsStatus', { type: 'checkout', params: { installmentInfo: (JSON.parse(sessionStorage?.getItem(cacheKey)) || {}) } })
      return
    }
    schttp({
      method: 'POST',
      url: '/api/checkout/installmentDetail/get',
      data: {
        country,
        amount,
        currency,
      }
    }).then(res => {
      if (res && res.code == 0 && res.info) {
        sessionStorage?.setItem(cacheKey, JSON.stringify(res.info))
        commit('changeParamsStatus', { type: 'checkout', params: { installmentInfo: { ...res.info, originData: res } } })
      }
    })
  },
  // 处理cod逻辑
  handleCod ({ commit, dispatch, state }, info) {
    if (info) {
      if (typeof (info.is_display) !== 'undefined') {
        dispatch('handleNotSupportCodTip', info)
        commit('assignState', { codDisplay: info.is_display || info.isDisabledCodPayment })
      }

      if (info.isDisabledCodPayment) {
        if (typeof window !== 'undefined' && shem_caculate_data && shem_caculate_data.is_shop_transit) {
          commit('assignState', { codDisplay: '' })
          shem_caculate_data.payment_code_unique = ''
          shem_caculate_data.payment_id = ''
        }
        commit('assignState', { notSupportCodReason: state.language.SHEIN_KEY_PWA_15161 })
        dispatch('codZeroPaymentSelect')
      }
    }
  },
  handleNotSupportCodTip ({ commit, state }, info) {
    const { not_support_cod_reason = '' } = info
    let notSupportCodTip = ''
    let valid

    if (!not_support_cod_reason && info.isSupportCod == 1) {
      valid = 1

    } if (not_support_cod_reason && info.isSupportCod != 1) {
      valid = 0

      notSupportCodTip = not_support_cod_reason
    }
    if (valid !== undefined && state.checkout?.results?.paymentMethods) {
      const originPayments = state.checkout.results.paymentMethods.slice(0) || []
      originPayments.forEach((item) => {
        if (item.code == 'cod') {
          item.enabled = valid ? item.originEnabled : 0
        }
      })
      commit('updatePaymentMethods', { list: originPayments })
    }
    commit('assignState', { notSupportCodReason: notSupportCodTip })
  },
  // cod金额为0时默认选择支付方式
  codZeroPaymentSelect ({ dispatch, state }) {
    if(typeof window == 'undefined') return
    setTimeout(function () {
      let _item = null
      const _payment_method = $('input[name=paymentmethod]:checked').parents('.j-payment-item').data('method') || ''
      let _code = state.checkout.paymentSuggestion

      if (_payment_method && _payment_method != 'cod') return

      if (state.checkout.mall_caculate_info.paymentMethodLimitInfo && state.checkout.mall_caculate_info.paymentMethodLimitInfo[_code] == 0) return

      if (state.checkout.paymentSuggestion == 'cod') {
        _item = state.checkout.results.paymentMethods.filter(function (item) { return item.code != 'cod' })
        if (_item && _item.length) {
          _code = _item[0].code
        }
      }
      if( _code ){
        const $payment = $('.j-payment-item[data-method=' + _code + ']')
        if ($payment.css('display') != 'none' && _item && _item.length) {
          dispatch('clickPayment', { id: _item[0].id, code: _item[0].code, item: _item[0], isPaymentSuggestionTriggerClick: true })
        }
      }
    }, 300)
  },
  // 该isPaymentSuggestionTriggerClick字段用于判断clickPayment是否由默认支付方式触发，阻止apacpayBank支付方式自动弹出银行选择框
  clickPayment ({ commit, dispatch, state, getters }, opts) {
    let { id, code, item, isPaymentSuggestionTriggerClick, isClickPaymentCoupon, iSautoPay, Index, isdont, isDoNeedMallCalculateTotal = false, paypalGaVault, afterPayVault, idx, cardPay, onlinePayDiscountInfoValue, afterCallBack } = opts || {}
    const { limitedPayTypeList, currentProduct, currentProductCycleDays } = getters.productAutoInfo || {} 
    const { xtraAutoPayLimitInfo, currentXtraIsAuto, sheinSaverRenewAbt } = getters.xtraAutoController || {}

    const specialPayment = ['PayPal-GApaypal', 'afterpay-card'].includes(code)
    const specialPaypal = paypalGaVault?.type == 0
    const specialAftepay = afterPayVault?.type == 0 || !afterPayVault?.switch
    let currentAutoProductDwType = 'prime'
    
    let specialResult = false
    if(code == 'PayPal-GApaypal'){
      specialResult = specialPaypal
    } else {
      specialResult = specialAftepay
    }

    const limitedPayTypeListResult = {
      currentPrime: limitedPayTypeList.some( s => s.code === window?.shem_caculate_data?.payment_code_unique), // 当前支付方式是否是快捷
      nextPrime: !limitedPayTypeList.some(s => s.code === opts.code), // 要点击的支付方式是否是快捷
      currentXtra: xtraAutoPayLimitInfo.includes(window?.shem_caculate_data?.payment_code_unique),
      nextXtra: !xtraAutoPayLimitInfo.includes(opts.code),
      currentPrimeIsAuto: currentProduct?.product_activity_type == 1 && currentProductCycleDays,
      currentXtraIsAuto
    }
    let nextPaymentPrimeResult = specialPayment ? specialResult : limitedPayTypeListResult.nextPrime
    let nextPaymentXtraResult = specialPayment ? specialResult : limitedPayTypeListResult.nextXtra
    if(specialPayment && !limitedPayTypeList.some(s => s.code == code)){
      nextPaymentPrimeResult = true
    }
    if(specialPayment && !xtraAutoPayLimitInfo.includes(code)){
      nextPaymentXtraResult = true
    }

    const primeResult = limitedPayTypeListResult.currentPrimeIsAuto && nextPaymentPrimeResult
    const xtraResult = !!sheinSaverRenewAbt && limitedPayTypeListResult.currentXtraIsAuto && nextPaymentXtraResult

    const limitPaymentsSwitchProduct = primeResult || xtraResult
    currentAutoProductDwType = primeResult && xtraResult ? 'common' : primeResult ? 'prime' : 'xtra'

    // 支付方式是否符合调起切包弹窗
    if ( !iSautoPay && limitPaymentsSwitchProduct && item?.enabled != 0 ) {
      // const currentAutoProductDwType = limitedPayTypeListResult.currentPrimeIsAuto && limitedPayTypeListResult.currentXtraIsAuto ? 'common' : limitedPayTypeListResult.currentXtraIsAuto ? 'xtra' : 'prime'
      appEventCenter.$emit('payment-choosepayment-event', { ...opts, currentAutoProductDwType })
      isDoNeedMallCalculateTotal = true
    }

    if (item && item.enabled != 1) return

    if ((!isPaymentSuggestionTriggerClick) && state.clickTwice) return
    commit('assignState', { clickTwice: 1 })
    setTimeout(function () {
      commit('assignState', { clickTwice: 0 })
    }, 300)

    if (code == 'cod') {
      // cod 支付，防止默认不可用地址切换到其它地址时弹出原不可用原因
      if (state.notSupportCodReason && !isPaymentSuggestionTriggerClick) {
        commit('assignState', { showtip: true })
      }
      if (state.notSupportCodReason ) {
        return
      }
    }

    var _item = state.checkout.results.paymentMethods.filter(item => item.code == shem_caculate_data.payment_code_unique)
    // 记录切换前的支付方式, 切换支付方式的时候不再记录上一次记录的卡 bin, 避免影响卡 bin 优惠券报错处理
    commit('assignState', { 
      lastSelectePayment: _item[0] || null,
      lastSelectBin: '',
      lastSelectInstallmentBin: '', 
    })

    $('.j-payment-tips').addClass('mshe-hide')
    commit('assignState', { noSelectPayment: false })

    commit('changeParamsStatus', { type: 'status', params: { cardPay: code } })

    shem_caculate_data.payment_id = id
    shem_caculate_data.payment_code_unique = code

    appEventCenter.$emit('payinline-load-widget', code)
    appEventCenter.$emit('analysis-send', { paymentCode: code, checkout: state.checkout, item, idx, cardPay, onlinePayDiscountInfoValue })

    // 切包逻辑，切换完支付方式，再通知包更新
    if(iSautoPay) {
      let frLocation = 3  //选包模块：location (1-随单购，2-弹窗，3-支付方式，4-下单页挽留弹窗)
      return afterCallBack && afterCallBack(  { Index, isdont, frLocation })
    }

    // 如果是由支付优惠券列表选择的支付方式，由后面的使用优惠步骤来继续触发价格计算
    if (isClickPaymentCoupon) return

    if (isDoNeedMallCalculateTotal) return // 不需要触发计价

    dispatch('mallCacuTotal', {
      opt: { 
        ...shem_caculate_data,
        isClickPayment: true,
      },
      loadingInfo: {
        show: true,
        duration: 800
      },
      cb: function (res) {
        if (res.code == 0 && res.info) {
          const info = res.info
          // cod支付，后端控制强制转换货币逻辑
          if ( 
            info.changeCodLocalCurrency && 
            info.changeCodLocalCurrency.isChange == 1 && 
            state.now_currency != info.changeCodLocalCurrency.codLocalCurrency 
          ) {
            if(isPaymentSuggestionTriggerClick){
              commit('changeParamsStatus', { type: 'checkout', params: { paymentSuggestion: '' } })
              commit('changeParamsStatus', { type: 'status', params: { cardPay: '' } })
              shem_caculate_data.payment_id = ''
              shem_caculate_data.payment_code_unique = ''
            } else {
              const txt = template(info.changeCodLocalCurrency.codLocalCurrency, state.language.SHEIN_KEY_PWA_17358)
              commit('assignState', { codCurrencyTip: txt })

              // cod支付后端转换货币后前端跟随转换对应货币
              dispatch('mallCacuTotal', {
                opt: shem_caculate_data,
              })
            }
          } else {
            commit('assignState', { codCurrencyTip: '' })
          }
        }
      }
    })
    payCardPrefetchFn(item?.payment_type)
  },
  // 更新钱包的价格
  updateWalletPrice ({ commit, state }) {
    const wallet = state.checkout.results.wallet || []
    const filterWallet = wallet.filter(function (item) {
      return item.currency_code == state.now_currency
    })
    const _walletInfo = filterWallet.length ? filterWallet[0] : {}
	
    // 是否有其它货币钱包
    let hasElseWallet = false
    // 当钱包总货币大于1个且有当前货币钱包时，或者当前货币钱包没有但钱包总货币有
    if ((wallet.length > 1 && filterWallet.length) || (!filterWallet.length && wallet.length)) {
      hasElseWallet = true
    }

    commit('assignState', {
      hasElseWallet,
      walletInfo: _walletInfo
    })
  },
  updateStock ({ commit }, outStockCarts = []) {
    // 判断库存
    if (!!outStockCarts.length) {
      commit('assignState', {
        GB_out_stock: 1,
        soldoutType: 0,
        newOutStockCarts: outStockCarts
      })
      commit('updateState', { key: 'soldOutModalShow', value: true })
    } else {
      commit('assignState', {
        GB_out_stock: 0,
        soldOutModalShow: false,
        newOutStockCarts: []
      })
    }
  },
  updateCart({ getters, state, dispatch }, opts = {}) {
    const { noUpdataShipping, noUpdateXtraPayments } = opts
    const prime_product_code = shem_caculate_data.prime_product_code

    schttp({
      method: 'GET',
      url: '/api/cart/getCartsWithCouponGift/get',
      params: { 
        prime_product_code: prime_product_code, 
        auto_use_coupon: 0, 
        checkout_no: state.buyNowNo,
        userLocalSizeCountry: getters.cacheLocalCountry,
        countryId: +state.checkout.default_address?.countryId
      } 
    }).then(res => {
      const cartList = res?.info?.carts || []
      const cartInfo = res?.info || {}
      const cartMallList = res?.info?.mall_list || []
      const mallShippingMethodsList = state.mallShippingMethods?.mall_list || []
      const caculateMallListLth = state.checkout.mall_caculate_info.mall_list?.length || 0

      if (res.code == 0 && res.info && cartList) {
        dispatch('checkoutPreHandlePromotion', {
          cartInfo,
          cartList,
        }).finally(() => {
          // 获取tsp标签
          dispatch('handleCartsTspLabels')
        })
        state.checkout.results.carts.carts = cartList
        state.checkout.results.carts.mall_list = cartMallList
        state.checkout.results.carts.cartInfo = cartInfo
        opts.fn && opts.fn()

        // 购物车数据变动后，应重新请求支付方式列表，并重新判断一下valuting逻辑
        appEventCenter.$emit('checkout-update-payment', { updatePaypalGaVaultStatus: true })
        // 如果可以购买超省卡，购物车数据变更需要重新调用超省卡试算。
        if(getters.showXtra && !noUpdateXtraPayments) dispatch('updateXtraPaymentsRight')
          
        // 如果存在 更新购物车 购物车里面的mall code不包含当前运输方式里面的mall code 就去更新运输方式
        if (cartMallList.length && mallShippingMethodsList.length && !noUpdataShipping) {
          let newCartMallList = cartMallList.map(item => item.mall_code)
          let status = true
          mallShippingMethodsList.forEach(item => {
            if (!newCartMallList.includes(item.mall_code)) status = false
          })
          // mall长度不一样 更新运输方式， 长度一样，mall有不一样 更新运输方式
          if (!status || (cartMallList.length != mallShippingMethodsList.length || cartMallList.length != caculateMallListLth)) appEventCenter.$emit('update-shipping-method', { address: state.checkout.default_address })
        }

        // 购物车接口回调
        opts.cartCallback && opts.cartCallback(res)

        // 不是日本，重调购物车，需要重新计算qs时效，日本的会在更新运输方式重新调取更新qs
        if( state.checkout.default_address.countryId != '108'){
          dispatch('newQuickShip')
        }
      }
    })
  },
  async getCartPromotionInfo ({ state, dispatch }, opts = {}) {
    let cartInfo = state.checkout?.results?.carts?.cartInfo || {}
    let cartList = state.checkout?.results?.carts?.carts || []
    dispatch('checkoutPreHandlePromotion', {
      cartInfo,
      cartList,
      forceRequest: !!opts?.forceRequest
    })
  },
  async checkoutPreHandlePromotion ({ state, getters }, opts) {
    const { cartInfo, cartList } = opts
    let [{ promotionCenterNew }, { cartPromotionMapHandle }] = await Promise.all([import('public/src/pages/common/promotion/indexNew'), import('public/src/pages/cartNew/utils/promotionMapHandle')])
      
    const { integrityPromotionInfo } = await promotionCenterNew.preHandlePromotion({ 
      goods: cartList, 
      promotion_range: cartInfo.promotion_range, 
      page: 'checkout', 
      forceRequest: !!opts?.forceRequest,
      isSuggested: getters.isSuggested
    })
    state.integrityPromotionInfo = integrityPromotionInfo
    state.cartPromotionMap = cartPromotionMapHandle({ integrityPromotionInfo: integrityPromotionInfo }) // 判断活动是否满足 埋点用
    state.isInitPromotion = true
    const { promotionList } = promotionInfo({
      language: state.language,
      cartInfo: state.checkout?.results?.carts?.cartInfo,
      underPriceAbtValue: getters.underPriceAbtValue,
      flashSaleGroupList: getters.flashSaleGroupList,
      integrityPromotionInfo: integrityPromotionInfo
    })
    state.promotionList = promotionList
  },
  handlePrimeErrCb({ state, dispatch, commit }, { flag }) {
    // 价格接口调用失败，取消/勾选,还原选择
    if (flag) {
      commit('assignState', { 
        'usedPrimeIndex': -1,
        'usedPrimeCode': ''
      })
      shem_caculate_data.prime_product_code = state.usedPrimeCode
      dispatch('changePrimeUpdateCalcu', { primeSeleted: false, alreadyReset: true })
    } else {
      commit('assignState', { 
        'usedPrimeIndex': state.selectdPrimeIndex,
        'usedPrimeCode': state.primeProductList[state.selectdPrimeIndex].product_code
      })
      shem_caculate_data.prime_product_code = state.usedPrimeCode
    }
  },
  handleXtraErrCb({ state, dispatch, commit }, { flag }) {
    // 价格接口调用失败，取消/勾选,还原选择
    if (flag) {
      commit('assignState', { 
        usedXtraCode: '',
        xtraProductCodeInDrawer: '', // 多产品包的时候用
      })
      shem_caculate_data.save_card_product_code = state.usedXtraCode
      shem_caculate_data.payment_rights = [
        {
          is_buy: 0,
          product_code: state.usedXtraCode,
          rights_type: 1
        }
      ]
      dispatch('changeXtraUpdateCalcu', { xtraSeleted: false, alreadyReset: true })
    } else {
      commit('assignState', { 
        usedXtraCode: state.xtraProductInfo.product_code
      })
      shem_caculate_data.save_card_product_code = state.usedXtraCode
      shem_caculate_data.payment_rights = [
        {
          is_buy: 1,
          product_code: state.usedXtraCode,
          rights_type: 1
        }
      ]
      dispatch('changeXtraUpdateCalcu', { xtraSeleted: true, alreadyReset: true })
    }
  },
  handleAutoUsePrimeCoupon({ state }, cancelAutoUse) {
    if (!!state.is_manual_usedCoupon || cancelAutoUse) return false
    const right_config_list = state.primeProductList?.[state.usedPrimeIndex || 0]?.right_config_list || []
    const right_type_code = right_config_list?.map(item => item.right_type_code)?.join(',') || ''
    let hasFree = right_type_code.indexOf('FREE_SHIPPING_TIMES') != -1
    return hasFree
  },
  filterCouponParams({ state }, { scene = '' } = {}) {
    const { applyCouponsSuccess } = state.coupon
    let usedCoupon = []
    if(scene == 'prime') {
      usedCoupon = applyCouponsSuccess.filter(item => [53, 58].includes(item.typeId)) || []
    } else if(scene == 'xtra') {
      usedCoupon = applyCouponsSuccess.filter(item =>  [55, 59].includes(item.typeId)) || []
    }
    if (usedCoupon.length && shem_caculate_data.coupon) {
      const filterCoupon = usedCoupon.map(item => item.couponCode.toLowerCase()) || []
      let couponParam = shem_caculate_data.coupon.split(',')
      let newCouponParam = []
      couponParam.forEach(item => {
        if (!filterCoupon.includes(item)) newCouponParam.push(item)
      })
      shem_caculate_data.coupon = newCouponParam.join()
    }
  },
  async changePrimeUpdateCalcu({ state, getters, dispatch, commit }, { primeSeleted, alreadyReset, cancelAutoUse, recommendProductCode  }) {
    // 判断选中还是取消，选中的话增加自动用券，取消的话取消之前自动使用的付费会员优惠券 
   
    if(primeSeleted){
      shem_caculate_data.prime_product_code = state.usedPrimeCode
      shem_caculate_data.trial_prime_product_code = ''
    } else {
      shem_caculate_data.prime_product_code = ''
      shem_caculate_data.trial_prime_product_code = recommendProductCode
    }

   
    // 切换产品包需要更新支付方式
    appEventCenter.$emit('checkout-update-payment')
    // 如果可以购买超省卡，更改付费会员包需要重新调用超省卡试算
    if(getters.showXtra) dispatch('updateXtraPaymentsRight')

    let canAutoUsePrime = false
    if (primeSeleted) { 
      // 选中产品包增加自动使用会员优惠券
      canAutoUsePrime = await dispatch('handleAutoUsePrimeCoupon', cancelAutoUse)
    } else { 
      // 取消选中产品包过滤之前自动使用的会员优惠券
      dispatch('filterCouponParams', { scene: 'prime' })
    }
    commit('assignState', { 
      canAutoUsePrime
    })

    dispatch('getPrimeRightCouponInfo')
    appEventCenter.$emit('update-coupon', { 
      isPrime: true,
      isAuto: true,
      noLoading: true,
      loadingInfo: {
        show: true,
        duration: 800
      },
      cb: opt => {
        const { errCode } = opt
        if (errCode && !alreadyReset) {
          dispatch('handlePrimeErrCb', { flag: primeSeleted })
        }
      }
    })
  },
  async changeXtraUpdateCalcu({ state, dispatch, commit, getters }, { xtraSeleted, alreadyReset }) {
    shem_caculate_data.save_card_product_code = state.usedXtraCode
    shem_caculate_data.payment_rights = [
      {
        is_buy: xtraSeleted ? 1 : 0,
        product_code: state.usedXtraCode,
        rights_type: 1
      }
    ]
    commit('assignState', { 
      isCancelXtra: !xtraSeleted
    })

    appEventCenter.$emit('checkout-update-payment')

    // 取消选中产品包过滤之前自动使用的超省卡优惠券
    if(!xtraSeleted && !getters.isCouponAutoUse) dispatch('filterCouponParams', { scene: 'xtra' })

    appEventCenter.$emit('update-coupon', { 
      isXtra: true,
      noLoading: true,
      loadingInfo: {
        show: true,
        duration: 800
      },
      cb: opt => {
        const { errCode } = opt
        
        if (errCode && !alreadyReset) {
          dispatch('handleXtraErrCb', { flag: xtraSeleted })
        }
      }
    })

  },

  // 更新付费会员和超省卡计价
  async changeXtraPrimeUpDateCalcu({ state, dispatch, commit, getters }, { xtraSeleted, primeSeleted, cancelAutoUse, alreadyReset }){
    // 付费会员
    shem_caculate_data.prime_product_code = state.usedPrimeCode
    appEventCenter.$emit('checkout-update-payment')
    if(getters.showXtra) dispatch('updateXtraPaymentsRight')
    let canAutoUsePrime = false
    if (primeSeleted) { 
      canAutoUsePrime = await dispatch('handleAutoUsePrimeCoupon', cancelAutoUse)
    } else { 
      dispatch('filterCouponParams', { scene: 'prime' })
    }
    commit('assignState', { 
      canAutoUsePrime
    })
    dispatch('getPrimeRightCouponInfo')

    // 超省卡
    shem_caculate_data.save_card_product_code = state.usedXtraCode
    shem_caculate_data.payment_rights = [
      {
        is_buy: xtraSeleted ? 1 : 0,
        product_code: state.usedXtraCode,
        rights_type: 1
      }
    ]
    commit('assignState', { 
      isCancelXtra: !xtraSeleted
    })
    appEventCenter.$emit('checkout-update-payment')
    if(!xtraSeleted && !getters.isCouponAutoUse) dispatch('filterCouponParams', { scene: 'xtra' })

    // 触发券更新计价接口
    appEventCenter.$emit('update-coupon', { 
      isXtra: true,
      isPrime: true,
      isAuto: true,
      noLoading: true,
      loadingInfo: {
        show: true,
        duration: 800
      },
      cb: opt => {
        const { errCode } = opt
        if (errCode && !alreadyReset) {
          dispatch('handlePrimeErrCb', { flag: primeSeleted })
        }
      }
    })
  },

  async updatePrimeList({ state, commit, dispatch }){
    const memberBuyProducts = await api.queryMemberBuyProduct()
    commit('updateState', { key: 'primeProductList', value: memberBuyProducts })
    commit('updateState', { key: 'lowPrimeProduct', value: JSON.parse(JSON.stringify(state.primeProductList.filter(item => +item.product_price_info?.special_price > 0) || [])).sort((a, b) =>  +a.product_price_info?.special_price - +b.product_price_info?.special_price)[0] || {} })
    // abt/没有快捷 => 自动续费包过滤掉
    const paymentMethods = state.checkout.results.paymentMethods.slice(0) || []
    const primeProductList = autoPrimeProducfilter( 
      { 
        sheinClubRenew: state?.checkout?.abtInfo?.sheinClubRenew?.param?.sheinClubRenewStatus || '', 
        primeProductInfo: {
          ...state?.checkout.results?.primeProductInfo || {},
          primeProductList: memberBuyProducts
        }, paymentMethods  
      })
    // 两个值都更新，防止其他地方直接用的 state.checkout.results.primeProductInfo
    Vue.set(state, 'primeProductList', primeProductList)
    Vue.set(state.checkout.results.primeProductInfo, 'primeProductList', primeProductList)
    // 更新会费券信息
    if(!state.virtualCouponInfo.apiLock) {  
      commit('upDatePrimeSceneStatus')
    }

    // 更新产品包后处理用户选择过的产品包情况
    // 用户使用了产品包，默认展示当前产品，用户未使用产品包，展示推荐产品
    let selectdPrimeIndex = -1
    if (state.usedPrimeCode) {
      const usedPrimeIndex = state.primeProductList.findIndex(item => item.product_code == state.usedPrimeCode)
      commit('updateState', { key: 'usedPrimeIndex', value: usedPrimeIndex })
      if (usedPrimeIndex == -1) {
        commit('updateState', { key: 'usedPrimeCode', value: '' })
        selectdPrimeIndex = state.primeProductList.findIndex(item => +item.is_recommend)

        dispatch('changePrimeUpdateCalcu', { primeSeleted: false })

      } else {
        selectdPrimeIndex = usedPrimeIndex
      }
    } else {
      selectdPrimeIndex = state.primeProductList.findIndex(item => +item.is_recommend)
    }
    dispatch('updateSelectedPrime', { index: selectdPrimeIndex == -1 ? 0 : selectdPrimeIndex, isUpdateRightList: true })
  },
  async updateXtra({ state, commit, dispatch }) {
    const xtraData = await api.queryProductsByMember()
    const limitedPayTypeList = xtraData?.auto_renewal_limit_pay_type_list || []
    const xtraAutoPayLimitInfo = state.checkout?.results?.paymentMethods.filter(item => limitedPayTypeList.some( s => s.code == item.code && item?.enabled != 0)).map(m => m.code)
    let xtraProductBagInfo =  xtraData?.product_info_list || [] // 当前所有的产品包数据

    if(!xtraAutoPayLimitInfo.length){
      xtraProductBagInfo = xtraProductBagInfo.filter( item => item.product_activity_type != 1)
    }

    let xtraProductData = {}
    const findSelectedData = xtraProductBagInfo?.find(_ => _.product_code == state.usedXtraCode)
    const isShowSaverRenewStatus = state?.checkout?.abtInfo?.SheinSaverRenew?.param?.SheinSaverRenewStatus == 1

    // 寻找选择中的
    if (findSelectedData) {
      xtraProductData = findSelectedData
    } else if (isShowSaverRenewStatus) {
      xtraProductData = xtraProductBagInfo?.[0] ?? {} // abt是1的话，默认取第一个
    } else if (!isShowSaverRenewStatus) { 
      xtraProductData = xtraProductBagInfo?.find(_ => _.product_activity_type != 1) // abt没有打开的时候，需要取接口返回的产品包数据中第一个 非自动续费产品包
    }

    const productCodeArr = xtraProductBagInfo.map(_ => _.product_code) || []

    // 更新checkout，防止其他地方使用state中的checkout
    const checkout = {
      ...state.checkout,
      results: {
        ...state.checkout.results,
        xtraProductInfo: {
          limitedPayTypeList,
          xtraProductBagInfo,
          xtraProductData,
        }
      }
    }
    
    commit('assignState', {
      checkout,
      xtraProductInfo: xtraProductData,
      xtraProductBagInfo,
      xtraCouponList: [],
    })
    // 更新会费券信息
    if(!state.virtualCouponInfo.apiLock) {  
      commit('upDateSceneStatusNew')
    }
    
    dispatch('updateXtraPaymentsRight')

    if(state.usedXtraCode && !productCodeArr.includes(state.usedXtraCode)) {
      commit('assignState', { 
        usedXtraCode: ''
      })
      dispatch('changeXtraUpdateCalcu', { xtraSeleted: false })
    }
  },
  async updateXtraPaymentsRight({ state, commit }) {
    const xtraDiscountData = await api.queryXtraPaymentrights({ xtraProductBagInfo: state.xtraProductBagInfo, primeCode: state.usedPrimeCode, checkout_no: state.buyNowNo })
    const xtraDisplayCouponData = await getXtraDisplayData({ xtraProductBagInfo: state.xtraProductBagInfo, xtraDiscountInfo: xtraDiscountData })
    const res = await updateXtraCouponList(state.xtraProductBagInfo)

    // 超省卡信息归纳
    const xtraAllProductBagInfo = {}
    state.xtraProductBagInfo?.forEach(item => {
      const productCode = item.product_code
      const activityType = item.product_activity_type
      xtraAllProductBagInfo[productCode] = {
        ...state.xtraAllProductBagInfo[productCode],
        productInfo: item,
        discountInfo: xtraDiscountData?.allPaymentRightInfo?.[productCode] || {},
        couponListInfo: res?.[productCode],
        displayCouponInfo: xtraDisplayCouponData?.data?.[productCode],
        activityType
      }
    })
    const currentDefaultXtraProductCode = state.xtraProductInfo?.product_code
    
    // 更新checkout，防止其他地方使用state中的checkout
    const checkout = {
      ...state.checkout,
      results: {
        ...state.checkout.results,
        xtraCouponList: res?.[currentDefaultXtraProductCode] || {},
        xtraDiscountInfo: xtraDiscountData,
        xtraDisplayCouponInfo: { ...xtraDisplayCouponData, data: xtraDisplayCouponData?.data?.[currentDefaultXtraProductCode] || {} }
      }
    }

    const xtraDiscountInfo = xtraDiscountData?.allPaymentRightInfo?.[currentDefaultXtraProductCode] || {}
    const xtraDisplayCouponInfo = xtraDisplayCouponData?.data?.[currentDefaultXtraProductCode] || {}
    const xtraCouponList = res?.[currentDefaultXtraProductCode]?.couponList || {}
    const xtraAggregatedCouponList = res?.[currentDefaultXtraProductCode]?.aggregatedCouponList || {} 

    commit('assignState', {
      checkout,
      xtraAllProductBagInfo,
      xtraDiscountInfo,
      xtraDisplayCouponInfo,
      xtraCouponList,
      xtraAggregatedCouponList
    })
    
    // 更新会费券信息
    if(!state.virtualCouponInfo.apiLock) {  
      commit('upDateSceneStatusNew')
    }
  },
  // 处理自动使用钱包
  handleAutoUseWallet({ state }) {
    if(typeof window !== 'undefined'){
      let extraData = {
        scenes: 'walletBalance',
        type: state.price?.wallet?.subtractPrice?.amount > 0 ? 1 : 0,
        auto_wallet_result: state.price?.walletPrice?.amount > 0 ? 1 : 0,
      }
      exposeScenesAbtAggregator.addEvent({
        daId: '1-11-1-172',
        extraData
      })
    }
  },
  async updatePrimeSavingSummary({ commit }) {
    const params = { query_dimension: 1 }
    const primeSavingSummary = await api.getPrimeSavingSummary(params)
    commit('assignState', { 
      primeSavingSummary
    })
  },
  async getPrimeRightCouponInfo({ getters, commit }) {
    let primeRightCouponList = []
    if(!!getters.primeRightCouponCodeList.length) {
      const codes = getters.primeRightCouponCodeList.map((_) => _.coupon_code)
      const res = await api.getCouponByCodes(codes)
      if(res.code == 0) primeRightCouponList = res?.info?.list || []
    }
    commit('assignState', { 
      primeRightCouponList
    })
  },
  async getSecondLanguage ({ commit, state }, params = {}) {
    if (typeof window == 'undefined') return
    const SECOND_LANGUAGE_COUNTRIES = gbCommonInfo?.SECOND_LANGUAGE_COUNTRIES || []
    let baseSecondLanguage = {
      state: '',
      city: '',
      district: ''
    }
    if (state.isLoadingSecondLanguage) return
    commit('assignState', { isLoadingSecondLanguage: true })
    const { countryId, stateId, cityId, districtId } = params || {}
    if (SECOND_LANGUAGE_COUNTRIES.includes(+countryId) && (stateId || cityId || districtId)) {
      const cacheKey = `${countryId}_${stateId || '0'}_${cityId || '0'}_${districtId || '0'}`
      const secondLanguageCache = state.secondLanguageCache || {}
      const cacheValue = secondLanguageCache?.[cacheKey]
      if (cacheValue) {
        commit('assignState', { secondLanguage: cacheValue })
        commit('assignState', { isLoadingSecondLanguage: false })
        return cacheValue
      } else {
        try {
          const data = await api.getStateCitySecondLanguage({ countryId, stateId, cityId, districtId })
          if (data?.code == 0 && data?.info?.res) {
            const { stateSecondLanguage = '', citySecondLanguage = '', districtSecondLanguage = '' } = data.info.res || {}
            secondLanguageCache[cacheKey] = {
              state: stateSecondLanguage,
              city: citySecondLanguage,
              district: districtSecondLanguage
            }
            commit('assignState', { secondLanguage: Object.assign({}, baseSecondLanguage, secondLanguageCache[cacheKey]) })
            commit('assignState', { secondLanguageCache })
          }
          commit('assignState', { isLoadingSecondLanguage: false })
          return secondLanguageCache[cacheKey] || {}
        } catch (e) {
          commit('assignState', { isLoadingSecondLanguage: false })
          return {}
        }
      }
    }
  },
  // 处理下单限制前置（运输方式9类型限制）弹窗展示
  async handleShipInterceptModal({ commit, state, getters }, { hintType, shippingMethod, mallCode }) {
    if(typeof window == 'undefined') return

    // 商品类
    if([1, 2].includes(+hintType)) {
      const limitGoods = await handleShipInterceptGoods(shippingMethod?.match_carts || [])
      commit('updateState', { key: 'showShippingLimitGoods', value: true })
      commit('changeParamsStatus', { 
        type: 'shippingLimitInfo', 
        params: { 
          hint: shippingMethod?.rule_info?.msg || '', 
          goodsList: limitGoods, 
          shippingMethod: shippingMethod, 
          mallCode, 
          orderLimitType: +hintType,
        } 
      })
    }
    // 地址类
    if (+hintType == 3) {
      // 运输方式是否为店配
      const isStoreAddress = TRANSPORT_TYPE_SHOP.includes(shippingMethod.transport_type)
      commit('updateState', { key: 'showShippingLimitAddress', value: true })
      commit('changeParamsStatus', { 
        type: 'shippingLimitInfo', 
        params: { 
          mallCode,
          shippingMethod,
          checkUpdateShippingMethod: false, // 是否需要在更新地址后重新请求运输方式等相关逻辑
          isStoreAddress,
          hint: shippingMethod?.rule_info?.msg || '',
          orderLimitType: +hintType
        } 
      })
    }
    // 店配地址不匹配
    if(+hintType == 7) {
      const mallShippingMethodsList = state.mallShippingMethods?.mall_list || []
      const shippingMethods = mallShippingMethodsList?.find(mall => mall.mall_code == mallCode)?.shipping_methods || []
      // 当前mall可用的店配运输方式（寻找第一个）
      const avaliableStoreInx = shippingMethods?.findIndex(_ => {
        const isDisabled = isShippingMethodDisabled({ item: _, orderInterceptByAbt: getters.orderInterceptByAbt })
        // 非当前运输方式、店配运输方式、可用、未走到hint_type=7类型
        return _.transport_type != shippingMethod.transport_type && TRANSPORT_TYPE_SHOP.includes(_.transport_type) && !isDisabled && !(_.is_available_msg == 9 && _.rule_info?.hint_type == 7)
      })
      const avaliableStoreInfo = avaliableStoreInx != -1 ? shippingMethods[avaliableStoreInx] : {}

      if(avaliableStoreInx == -1) { // 当前mall不存在可切换的可用店配运输方式，拉起地址
        commit('changeParamsStatus', { 
          type: 'shippingLimitInfo', 
          params: { 
            mallCode,
            shippingMethod,
            avaliableStoreTransportInfo: {
              shippingMethod: avaliableStoreInfo,
              index: avaliableStoreInx
            },
            checkUpdateShippingMethod: true, // 是否需要在更新地址后重新请求运输方式等相关逻辑
            hint: shippingMethod?.rule_info?.msg || '',
            orderLimitType: +hintType
          } 
        })

        appEventCenter.$emit('edit-store-address', { type: 'edit', params: { mall_code: mallCode, transport_type: shippingMethod?.transport_type } })

        return
      } else { // 当前mall存在可切换的可用店配运输方式，拉起切换弹窗
        daEventCenter.triggerNotice({
          daId: '1-11-1-251',
        })

        commit('updateState', { key: 'showShippingLimitStore', value: true })
        commit('changeParamsStatus', { 
          type: 'shippingLimitInfo', 
          params: { 
            mallCode,
            shippingMethod,
            avaliableStoreTransportInfo: {
              shippingMethod: avaliableStoreInfo,
              index: avaliableStoreInx
            },
            checkUpdateShippingMethod: false, // 是否需要在更新地址后重新请求运输方式等相关逻辑
            hint: shippingMethod?.rule_info?.msg || '',
            orderLimitType: +hintType
          } 
        })
      }
    }

    commit('changeParamsStatus', { 
      type: 'shippingLimitInfo', 
      params: { 
        shippingLimitModalCount: ++state.shippingLimitInfo.shippingLimitModalCount
      } 
    })
    daEventCenter.triggerNotice({
      daId: '1-11-1-176',
      extraData: {
        rules_type: hintType,
        no: state.shippingLimitInfo.shippingLimitModalCount,
      }
    })
  },
  async handleCartsTspLabels({ state, getters, dispatch }) {
    const behaviorShowAbt = 'fivestar,adds,sales2,buyagain,lowestprice'
    const isLowestPriceDays = getters.lowestPriceDays?.lowest_price_days == 'new'
    const goods = state.checkout.results.carts.carts
    await handleTspLabels(goods, behaviorShowAbt, isLowestPriceDays)
    dispatch('handleMallCartsList')
  },
  async getShippingFreeMall({ state, getters, commit }) {
    let shippingInfoParams
    // 无地址时使用购物车数据传参，有地址后使用计价数据传参
    if(state.checkout?.default_address?.addressId == 0) {
      shippingInfoParams = handleCartShipFreeMall(state.checkout?.results?.carts?.cartInfo)
    } else {
      shippingInfoParams = handleShipFreeMall(state.price?.cart_free_info_list)
    }

    // 当前选中的运输方式是店配且当前存在店配地址，传店配信息，否则传宅配信息
    const addressData = (getters.isShopTransit && !!state.storeData?.countryId) ? {
      country_id: state.storeData?.countryId,
      address: state.storeData,
    } : {
      country_id: state.checkout?.default_address?.countryId,
      address: state.checkout?.default_address?.addressId == 0 ? null : state.checkout?.default_address,
    }
    const shippingContentInfo = await shippingCenter.getMallShippingContent({
      mall: shippingInfoParams,
      country_id: addressData.country_id,
      address: addressData.address,
      fromCheckout: true
    })

    commit('assignState', { 
      shippingContentInfo
    })
  },

  handleSelectTokenPay({ commit }, defaultSelectTokenPay) {
    commit('updateDefaultSelectTokenPay', defaultSelectTokenPay)
  },

  handleUpdateIsShowTokenPay({ commit }, isShowTokenPay) {
    commit('updateIsShowTokenPay', isShowTokenPay)
  },

  async getTogetherBuy({ state, commit }){
    const carts = state?.checkout?.results?.carts?.carts
    const abt = {}
    const needAbtPoskey = ['AddToOrderGoodsLocation', 'AddToOrderGoodsShow', 'listwithS3', 'listRRP', 'detailrrpprice', 'AddToOrderGoods', 'listquickship', 'listquickshipKey', 'PickupRec', 'buyNewCard', 'detailgoodsCard', 'FeaturePriorWeb', 'FastAddWeb', 'recmultiCard', 'listquickshipLanguage']
    Object.entries(state.checkoutAbtInfo).forEach(([key, value]) => {
      if(needAbtPoskey.includes(key)){
        abt[key] = value
      }
    })
    const params = { 
      carts: carts.map(item=> {
        const { real_quick_ship, mall_code, product } = item
        return { real_quick_ship, mall_code, product: { goods_id: product.goods_id } }
      }), 
      abt,
      countryId: state.checkout.default_address.countryId
    }
    const togetherInfo = await schttp({
      method: 'POST',
      url: '/api/checkout/togetherModules/get',
      data: params
    })
    if(togetherInfo.code == 0){
      commit('assignState', { 
        togetherBuy: togetherInfo.info
      })
      return true
    } else {
      console.error('togetherInfo error')
      return false
    }
  },

  getPromotionInfoBySiteUid({ state, commit }) {
    api.getPromotionInfoBySiteUid(state).then(res => {
      if (res.code == 0) {
        commit('assignState', {
          checkout: {
            ...state.checkout,
            productPackageInfo: {
              ...state.checkout.productPackageInfo,
              freeGiftInfo: res
            }
          }
        })
      }
    })
  },

  postBFFLimitCart({ state, getters }, addOrderResult) {
    return schttp({
      url: '/api/checkout/limitCarts/post',
      method: 'POST',
      data: {
        addOrderResult,
        country_id: state.checkout.default_address?.countryId, // 	地址国家id
        prime_product_code: shem_caculate_data.prime_product_code,
        scene: !shem_caculate_data.checkout_no ? '' : 'buy_now',
        checkout_no: shem_caculate_data.checkout_no,
        userLocalSizeCountry: getters.cacheLocalCountry || '',
      }
    })
  },
  // 获取qs时效接口
  async newQuickShip({ state, commit }, qsParmas){
    try {
      const address = state.checkout.default_address
      const carts = state.checkout.results.carts.carts
      const mall_list = (carts) => {
        // id 标识，goods_sn => skc, sku_code => sku
        return carts.map(item => {
          const result = {
            mall_code: item.mall_code,
            store_code: item.store_code,
            item_no: item.id,
            skc_code: item.product.goods_sn,
            sku_code: item.product.sku_code
          }
          return result
        })
      }    
      const params = {
        addressInfoOther: address,
        has_shop_transit_address: !!state.storeData ? 1 : 0, 
        checkout_no: state.buyNowNo, scene: 'checkout',
        mall_list: mall_list(carts)
      }
      const transport_type = state?.defaultShipping?.[0]?.shipping_method?.transport_type || ''
      if(state.checkout.default_address.countryId == '108' && transport_type){
        Object.assign(params, { transport_type, ...qsParmas })
      }
      const qsTimelinessInfo = await schttp({
        url: '/api/common/getNewQsTimeLineInfoSerCon/get',
        // url: '/api/common/getAgingInfo/get',
        method: 'POST',
        data: params
      })
      if(qsTimelinessInfo.code == 0){
        commit('assignState',  { qsMallList: qsTimelinessInfo.qs_mall_list })
      } else {
        console.error(`newQuickShip err`)
      }
    } catch (err) {
      console.error(`[cart getQsTimelinessInfo] 获取qs信息失败: ${err}`)
    }
  },
  async getNewGiftCardInfo({ state, commit }, { cardNo, cardPin } = {}) {
    const res = await schttp({
      method: 'POST',
      url: '/trade-api/order/checkout/gift-card',
      data: {
        card_no: cardNo,
        country_id: state.checkout.default_address?.countryId,
        card_pin: cardPin
      },
      useBffApi: true
    })
    const { code, info } = res
    if (+code === 0) {
      commit('assignState', {
        checkout: {
          ...state.checkout,
          newGiftcard: info
        }
      })
    }
    return {
      ...res,
      cardInfo: info?.card_info
    }
  },
  updateRewardFloorInfo({ state, getters, commit }, calculateData) {
    schttp({
      url: '/api/checkout/rewardFloorInfo/post',
      method: 'POST',
      data: {
        calculate: calculateData,
        prime_product_code: shem_caculate_data?.prime_product_code || '',
        is_prime_user: state.isPrimeMember ? 1 : 0,
        cart_img_list: state.checkout?.results?.carts?.carts?.map(cart => ({
          cart_id: cart.id,
          img: cart.product.goods_img
        })) || [],
        co_brand_level: getters.selectedCoBrandLevel
      }
    }).then((res) => {
      if (+res.code === 0 && res.info) {
        commit('assignState', {
          checkout: {
            ...state.checkout,
            rewardFloorInfo: res
          },
          noRewardData: !res.info?.rewardFloor?.guide
        })
        const { satisfiedRewardList, satisfiedRewardValue } = res.info.rewardFloor?.buriedPoint || {}
        if (!satisfiedRewardList?.length) return
        daEventCenterV2.triggerNotice({
          id: 'expose_order_rewards:simple',
          data: {
            orderRewardsType: satisfiedRewardList?.join('_'),
            orderRewardsDetailValue: satisfiedRewardValue
          }
        })
      } else {
        commit('assignState', {
          noRewardData: true
        })
      }
    })
  },
}

export default actions
