<template>
  <component
    :is="renderComponent"
    class="benefit-container"
    v-bind="props"
    :language="defaultLangs"
    @openDrawer="handleDrawerOpen"
  />
</template>

<script name="BBenefitFloor" setup lang="ts">
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { defineProps, computed, reactive, useSlots, onMounted } from 'vue'
import { getFsDataRunner } from '../../common/dataSource'
import BenefitBar from './components/BenefitBar/Index.vue'
import BenefitFloor from './components/BenefitFloor/Index.vue'
import useBenefitDrawer from './hooks/useBenefitDrawer'
import { E_BenefitFloor, type C_BenefitFloor } from '../../types'


const props = withDefaults(
  defineProps<{
    type: E_BenefitFloor.BenefitComponentType,
    data: C_BenefitFloor.Data,
    config?: C_BenefitFloor.Config
    language?: C_BenefitFloor.LanguageType,
  }>(),
  {
    type: E_BenefitFloor.BenefitComponentType.Bar,
    data: () => ({} as C_BenefitFloor.Data),
    config: () => ({}),
  },
)

const emits = defineEmits(['afterDrawerOpen'])

const renderComponent = computed(() => {
  switch (props.type) {
    case E_BenefitFloor.BenefitComponentType.Floor:
      return BenefitFloor
    case E_BenefitFloor.BenefitComponentType.Bar:
    case E_BenefitFloor.BenefitComponentType.MiniBar:
    default:
      return BenefitBar
  }
})

const defaultLangs: C_BenefitFloor.LanguageType = reactive(
  props.language || {
    CountDownText: '',
    ValueText: '',
  },
)

const appConfigs = useAppConfigs()
const slots = useSlots()
const { openDrawer } = useBenefitDrawer()

const handleDrawerOpen = () => {
  openDrawer({
    data: props.data.popUp,
    footer: slots.footer,
    drawerBottom: props.config?.drawerBottom,
    language: defaultLangs,
  })
  emits('afterDrawerOpen')
}

onMounted(() => {
  // 多语言可支持传入，传入则不另外请求多语言
  if (props.language) return
  const { fsRunner } = getFsDataRunner({}, appConfigs)
  fsRunner({}).then(({ langs = {} }) => {
    if (langs) Object.assign(defaultLangs, langs)
  })
})

</script>
<style lang="less" scoped>
.benefit-container {
  box-sizing: border-box;

  /deep/ * {
    box-sizing: border-box;
  }
}
</style>
