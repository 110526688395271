import { ref, toRef } from 'vue'

/**
 * 商品行是否可见
 * @param {String} id 
 */
export function useItemDisplayView() {
  const itemInVisible = ref(false)

  function updateItemInVisible(id) {
    const itemWrapRect = document.querySelector(`.j-cart-list .j-cart-item-${id} .cart-list__item-content`)?.getBoundingClientRect() ?? {}
    const headerHeight = document.querySelector('.j-cart-header-wrap')?.offsetHeight || 0
    const footerHeight = document.querySelector('.j-cart-footer')?.offsetHeight || 0
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight
    const filterSticky = document.querySelector('.filter-sticky')?.offsetHeight || 0
  
    itemInVisible.value = itemWrapRect.top >= headerHeight + filterSticky && itemWrapRect.bottom <= viewportHeight - footerHeight;
  }
  return {
    itemInVisible,
    updateItemInVisible,
  }
}