<template>
  <div class="checkout-order-total">
    <div class="checkout-order-total__left">
      {{ totalName }}
    </div>
    <div
      ref="totalRightPriceWrapperRef"
      class="checkout-order-total__right"
    >
      <div class="checkout-order-total__right-amount">
        <span class="checkout-order-total__right-amount-all">
          {{ totalPrice }}
        </span>
        <span
          v-if="!!govTaxPrice"
          class="checkout-order-total__right-amount-gov"
        >
          +{{ govTaxPrice }}
        </span>
      </div>
      <div
        v-if="!!savePriceTips"
        class="checkout-order-total__right-save"
      >
        {{ savePriceTips }}
      </div>
    </div>
  </div>
</template>

<script name="BCheckoutPrice" setup lang="ts">
import type { C_CheckoutPrice } from '../../../types'
import { computed } from 'vue'

interface PropsDefault {
  grandTotalTitle: C_CheckoutPrice.GrandTotalTitle,
  taxInclusiveTip?: C_CheckoutPrice.TaxInclusiveTip,
  savePriceTips?: C_CheckoutPrice.SavePriceTips,
  totalPrice: C_CheckoutPrice.TotalPrice,
  govTaxPrice?: C_CheckoutPrice.GovTaxPrice,
}

const props = withDefaults( defineProps<PropsDefault>(), {
  grandTotalTitle: '',
  taxInclusiveTip: '',
  savePriceTips: '',
  totalPrice: '',
  govTaxPrice: '',
})

const totalName = computed(() => {
  let name = props.grandTotalTitle
  if (props.taxInclusiveTip) name += props.taxInclusiveTip
  return name
})

</script>

<style lang="less" scoped>
.checkout-order-total {
  padding-top: 12/75rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  border-top: 1/75rem solid #E5E5E5;

  &__left {
    font-size: 14px;
    font-weight: 600;
    flex-shrink: 0;
    max-width: 128px;
    margin-right: 24/75rem;
  }

  &__right {
    color: #fa6338;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__right-amount {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    font-size: 24px;
    font-weight: 700;
  }

  &__right-amount-all {
    font-size: 24px;
  }
}
</style>
